import { createStyles, keyframes, rem } from '@mantine/core';


const fadeId = keyframes({
  from: { opacity: 0, transform: `translateX(${rem(20)}) scale(1)` },
  to: { opacity: 1, transform: 'translateY(0) scale(1) rotate(0)' },
});


interface StylesProps {
  shouldAnimate: boolean
  tabSize: number | string
  tabSizeMobile: number | string
  orientation: "horizontal" | "vertical"
  backgroundColor?: string
  colorScheme: "dark" | "light"
}


export default createStyles((
  theme, { shouldAnimate, tabSize, tabSizeMobile, orientation, backgroundColor, colorScheme }: StylesProps
) => ({
  root: {
    position: "relative",
    paddingTop: rem(32),
    paddingBottom: rem(32),
    backgroundColor: backgroundColor || theme.colors.teal[9],

    // [theme.fn.smallerThan('sm')]: {
      // paddingTop: rem(60),
      // paddingBottom: rem(60),
    // },
  },

  controls: {
    position: 'relative',
  },

  controlsIndicator: {
    pointerEvents: 'none',
    position: 'absolute',
    left: 0,
    right: 0,
    backgroundColor: colorScheme === 'dark' ? theme.colors.gray[0] : theme.white,
    border: `${rem(1)} solid ${
      theme.colors.gray[3]
    }`,
    boxShadow: theme.shadows.md,
    borderRadius: theme.radius.md,
    transition: 'transform 250ms ease',
    zIndex: 2,
  },

  control: {
    [orientation === "vertical" ? "width" : "height"]: '100%',

    padding: `${theme.spacing.md} ${theme.spacing.lg}`,
    borderRadius: theme.radius.md,
    position: 'relative',
    [orientation === "vertical" ? "height" : "width"]: tabSize,
    color: colorScheme === "light" ? theme.colors.gray[6] : theme.colors.gray[4],

    '&:hover': theme.fn.hover({
      color: theme.colors.gray[9],
      backgroundColor:
        colorScheme === 'dark'
          ? theme.colors.gray[2]
          : theme.colors.gray[2],
    }),

    '&[data-active]': {
      color: theme.colors.gray[9],
    },

    [theme.fn.smallerThan('sm')]: {
      [orientation === "vertical" ? "height" : "width"]: tabSizeMobile,
      padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    },
  },

  controlInner: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: 3,
  },

  controlTitle: {
    fontWeight: 700,
    zIndex: 5,
    fontSize: `calc(1.2 * ${theme.fontSizes.xl})`,
    lineHeight: 1.3,
    marginBottom: theme.spacing.xs,

    [theme.fn.smallerThan('sm')]: {
      fontSize: theme.fontSizes.sm,
    },
  },

  controlStep: {
    fontSize: theme.fontSizes.sm,
  },

  controlDescription: {
    fontSize: theme.fontSizes.md,
    fontWeight: 300,
    [theme.fn.smallerThan('sm')]: {
      fontSize: theme.fontSizes.xs,
    },
  },

  controlIcon: {
    color: theme.colors.blue[6],
    marginRight: theme.spacing.md,
  },

  demo: {
    paddingLeft: `calc(${theme.spacing.xl} * 2)`,
    animation: shouldAnimate ? `${fadeId} 400ms ease` : 'none',

    [theme.fn.smallerThan('md')]: {
      paddingLeft: 0,
      paddingTop: theme.spacing.xl,
    },
  },
}));
