import {
  Paper,
  createStyles,
  Title,
  Text,
  Anchor,
  rem,
  Container,
  Stack,
} from '@mantine/core';

import { useNavigate } from 'react-router-dom';

import LoginForm from '../../components/forms/Login';
import CreateAccountForm from '../../components/forms/CreateAccount';
import ResetPasswordForm from '../../components/forms/ResetPassword';


const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: '100vh',
    backgroundSize: 'cover',
  },
  controls: {
    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column-reverse',
    },
  },

  control: {
    [theme.fn.smallerThan('xs')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
}));


export default function LoginPage(props: { action: 'login' | 'signup' | 'reset' }) {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const onClickCreateAccount = () => {
    navigate('/calculator');
  }

  const onClickLogIn = () => {
    navigate('/login');
  }

  const titleText = {
    'login': 'Welcome back!',
    'reset': 'Forgot your password?',
    'signup': 'Create an account'
  }[props.action];

  const subtitle = {
    'login': <>
      Don't have an account yet?{'  '}
      <Anchor size="md" component="button" onClick={onClickCreateAccount}>
        Get started
      </Anchor>
    </>,
    'reset': 'Enter your email to get a reset link',
    'signup': <>
      Already have an account?{'  '}
      <Anchor size="md" component="button" onClick={onClickLogIn}>
        Log in
      </Anchor>
    </>,
  }[props.action];

  const form = {
    'login': <LoginForm handleSuccess={() => navigate("/dashboard/main")}/>,
    'signup': <CreateAccountForm handleSuccess={() => navigate("/dashboard/main")}/>,
    'reset': <ResetPasswordForm/>
  }[props.action];

  return (
    <div className={classes.wrapper}>
      <Container size={420} sx={{paddingTop: rem(100)}}>
        <Stack spacing={"xs"}>
          <Title
            align="center"
            sx={(theme) => ({ fontWeight: 500 })}
          >
            {titleText}
          </Title>
          <Text color="dimmed" size="md" align="center" mt={5}>
            {subtitle}
          </Text>
        </Stack>
        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          {form}
        </Paper>
      </Container>
    </div>
  );
}
