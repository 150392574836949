import { Group, Stack, Text, createStyles, useMantineTheme } from "@mantine/core";

import { COUNTRIES } from "../utils/countries";
import { selectCurrentUserId, useAppSelector } from "../../../redux/hooks";
import { useGetCalculatorDefaultsQuery, useGetCalculatorInputsQuery, useUpdateCalculatorInputsMutation } from "../../../api/calculator";
import CalculatorButton from "../../../components/calculator/CalculatorButton";
import { convertDistanceUnits, getUserDistanceUnit } from "../utils/units";
import ArrowNumberInput from "../../../components/calculator/ArrowNumberInput";


const useStyles = createStyles((theme) => ({
  tipText: {
    color: theme.colors.teal[6],
  }
}));


export default function SelectFlights() {
  const {classes} = useStyles();
  const theme = useMantineTheme();

  const userId = useAppSelector(selectCurrentUserId) as string;

  const {data} = useGetCalculatorInputsQuery(userId);
  const [updateCalculator] = useUpdateCalculatorInputsMutation();

  const defaultsApi = useGetCalculatorDefaultsQuery(userId);

  const inputs = data?.inputs;
  const countryCode = inputs?.countryCode;
  const storedDistUnits = "miles";
  const userDistUnits = getUserDistanceUnit(countryCode);
  const emoji = countryCode ? COUNTRIES[countryCode].emoji : "";
  const shortFlights = inputs?.numShortHaulFlights || 0;
  const medFlights = inputs?.numMediumHaulFlights || 0;
  const longFlights = inputs?.numLongHaulFlights || 0;

  const flightsPerYear = defaultsApi.data?.defaults.flightsPerYear;
  const flightsMiPerYear = defaultsApi.data?.defaults.flightsMiPerYear || 0;
  const flightsDistPerYear = convertDistanceUnits(flightsMiPerYear, storedDistUnits, userDistUnits);

  const updateFlights = (key: "numShortHaulFlights" | "numMediumHaulFlights" | "numLongHaulFlights", value: number | "") => {
    updateCalculator({userId, inputs: { [key]: (value === "") ? 0 : value }});
  }

  const enterMyself = inputs?.useCustomFlights === true;

  const setEnterMyself = (on: boolean) => {
    if (on !== inputs?.useCustomFlights) {
      updateCalculator({userId, inputs: { useCustomFlights: on }});
    }
  }

  return (
    <Stack>
      <Group>
        <CalculatorButton selected={inputs?.useCustomFlights === false} onClick={() => setEnterMyself(false)}>
          Use my country's average
        </CalculatorButton>
        <CalculatorButton selected={enterMyself} onClick={() => setEnterMyself(true)}>
          Enter myself
        </CalculatorButton>
      </Group>
      {
        (!enterMyself && flightsPerYear && flightsDistPerYear) ?
          <Text className={classes.tipText}>
            <i>The average person in {emoji}{" "}
              flies <strong>{flightsPerYear.toLocaleString("default", { maximumFractionDigits: 2 })} times
              per year</strong></i>
          </Text>
        : undefined
      }
      {
        enterMyself ? <>
        <Group grow>
          <Stack spacing={0}>
          <Text fw={600} fz={"lg"}>Short flights</Text>
            <Text c={theme.colors.yellow[7]} fz={"sm"}>(Less than 3 hours)</Text>
          </Stack>
          <ArrowNumberInput
            value={shortFlights}
            min={0}
            max={50}
            defaultValue={0}
            onChange={(value) => updateFlights("numShortHaulFlights", value)}
          />
        </Group>
        <Group grow>
          <Stack spacing={0}>
            <Text fw={600} fz={"lg"}>Medium flights</Text>
            <Text c={theme.colors.orange[7]} fz={"sm"}>(Between 3 and 6 hours)</Text>
          </Stack>
          <ArrowNumberInput
            value={medFlights}
            min={0}
            max={50}
            defaultValue={0}
            onChange={(value) => updateFlights("numMediumHaulFlights", value)}
          />
        </Group>
        <Group grow>
          <Stack spacing={0}>
            <Text fw={600} fz={"lg"}>Long flights</Text>
            <Text c={theme.colors.red[7]} fz={"sm"}>(Longer than 6 hours)</Text>
          </Stack>
          <ArrowNumberInput
            value={longFlights}
            min={0}
            max={50}
            defaultValue={0}
            onChange={(value) => updateFlights("numLongHaulFlights", value)}
          />
        </Group>
      </> : undefined}
    </Stack>
  );
}
