import { createStyles, rem, Indicator } from '@mantine/core';
import { selectCurrentUserId, useAppSelector } from '../../../redux/hooks';
import { useGetContinuePathQuery } from '../../../api/tracking';
import { ENABLE_CALCULATOR } from '../../../utils/flags';
import { useNavigateTop } from '../../../utils/hooks';
import ArrowButton from '../ArrowButton/ArrowButton';

const useStyles = createStyles((theme) => ({
  label: {
    marginRight: rem(5),
    fontSize: theme.fontSizes.md,
    fontWeight: 600,
    [theme.fn.smallerThan("sm")]: {
      fontSize: theme.fontSizes.md,
    }
  },
  actionButton: {
    backgroundColor: theme.colors.teal[6],
    height: "34px",
    '&:hover': {
      transition: '0.3s',
      backgroundColor: theme.fn.lighten(theme.colors.teal[6], 0.1)
    },
  },
}));


export default function TakeActionButton(props: { close: () => void }) {
  const { classes, cx } = useStyles();
  const navigate = useNavigateTop();

  const userId = useAppSelector(selectCurrentUserId);
  const {data} = useGetContinuePathQuery({ userId: userId as string }, { skip: !userId });

  // See if there's a URL that the user can resume their progress at.
  const continuePath = data?.pathname || null;

  const buttonText = (continuePath && ENABLE_CALCULATOR) ? "Continue" : "Take action";

  // The /continue path will handle navigation logic.
  const onClick = () => {
    navigate("/continue");
    // Close the overlay on mobile.
    props.close();
  }

  const hideIndicator = true; // !continuePath || !ENABLE_CALCULATOR;

  return (
    <Indicator
      position='top-end'
      processing={true}
      color="teal"
      disabled={hideIndicator}
    >
      <ArrowButton onClick={onClick} className={classes.actionButton}>
        <span className={classes.label}>{buttonText}</span>
      </ArrowButton>
    </Indicator>
  )
}
