
import { Autocomplete, AutocompleteItem, SelectItemProps, Text, createStyles } from "@mantine/core";
import { useGetCalculatorInputsQuery, useUpdateCalculatorInputsMutation } from "../../../api/calculator";
import { forwardRef, useEffect, useState } from "react";
import { selectCurrentUserId, useAppSelector } from "../../../redux/hooks";
import { ZIPCODES, isSupportedZipCode } from "../utils/zipcodes";


const useStyles = createStyles((theme) => ({
  autocomplete: {
    input: {
      paddingLeft: "1rem",
      display: "flex",
      border: "2px solid lightgray",
      transitionDuration: ".2s",
    }
  },
}));


interface ItemProps extends SelectItemProps {
  city: string;
  state: string;
}


const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ city, state, value, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <div>
        <Text>{value}</Text>
        <Text size="xs" color="dimmed">
          {city}, {state}
        </Text>
      </div>
    </div>
  )
);


export default function SelectZipCode() {
  const {classes} = useStyles();

  const [value, setValue] = useState<string>("");

  const userId = useAppSelector(selectCurrentUserId) as string;

  const {data} = useGetCalculatorInputsQuery(userId);
  const [updateCalculator] = useUpdateCalculatorInputsMutation();

  const inputs = data?.inputs;
  const zipcode = inputs?.zipcode;

  useEffect(() => {
    if (isSupportedZipCode(zipcode)) {
      setValue(zipcode);
    }
  }, [zipcode]);

  const onItemSubmit = (item: AutocompleteItem) => {
    setUserValue(item.value);
  }

  const setUserValue = (zip: string) => {
    if (zip.length !== 5) {
      console.error("Invalid zip code, rejecting");
      return;
    }
    updateCalculator({userId, inputs: { zipcode: zip }});
  };

  const dropdownData = Object.entries(ZIPCODES).map(([zipcode, meta]) => ({
    value: zipcode,
    city: meta.city,
    state: meta.stateCode
  }));

  return (
    <Autocomplete
      size="lg"
      radius="md"
      value={value}
      className={classes.autocomplete}
      placeholder="Please enter a U.S. zipcode"
      data={dropdownData}
      itemComponent={AutoCompleteItem}
      filter={(value, item) =>
        item.value.toLowerCase().includes(value.toLowerCase().trim())
      }
      onChange={setValue}
      onItemSubmit={onItemSubmit}
    />
  );
}
