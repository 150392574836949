import { Title, Container, createStyles, Stack, Image, Divider, Text, Grid, useMantineTheme, Group } from "@mantine/core";
import HeaderTitle from "../../shared/HeaderTitle";


const useStyles = createStyles((theme) => ({
  citation: {
    fontSize: 10,
    color: theme.colors.gray[6],
    p: {
      margin: 0,
      padding: "0.25rem"
    }
  },
}));


function FullHeightImage(props: { src: string }) {
  return (
    <Image
      src={props.src}
      height={"100%"}
      radius={"sm"}
      sx={{filter: "drop-shadow(0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.1))"}}
      styles={{ root: {height: "100%"}, figure: {height: "100%"},
      imageWrapper: {height: "100%"}}}
    />
  );
}


export default function ClimateOverview() {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();

  return (
    <Container size="lg">
      <Grid gutter={"2rem"}>
        <Grid.Col sm={5}>
          <FullHeightImage src="/assets/webp/small/climate.min.webp"/>
          </Grid.Col>
        <Grid.Col sm={7}>
          <Stack align="left" spacing="xl">
            <HeaderTitle
              header={"The Challenge"}
              align="left"
            />
            <Title order={1} fw={500}>
              To solve the climate crisis, we need to do two things:
            </Title>
            <Divider w={"100%"} size={"sm"}/>
            <Group spacing={"0.5rem"}>
              <div style={{width: "24px"}}>
                <Text color={theme.colors.teal[6]} align="left" fz={"lg"} fw={600}>1</Text>
              </div>
              <div style={{width: "calc(100% - 24px - 1rem)"}}>
                <Text color={theme.colors.gray[9]} fz={"xl"} fw={600} align="left" lh={1.5}>Reduce our emissions as quickly as possible.</Text>
              </div>
            </Group>
            <Group spacing={"0.5rem"}>
              <div style={{width: "24px"}}>
                <Text color={theme.colors.teal[6]} align="left" fz={"lg"} fw={600}>2</Text>
              </div>
              <div style={{width: "calc(100% - 24px - 1rem)"}}>
                <Text color={theme.colors.gray[9]} fz={"xl"} fw={600} align="left" lh={1.5}>Remove CO₂ from the atmosphere to undo emissions already causing climate change.</Text>
              </div>
            </Group>
            <Text className={cx(classes.citation)} align="left">
              IPCC, 2022: Climate Change 2022: Impacts, Adaptation, and Vulnerability. Contribution of Working Group II to the Sixth Assessment Report of the Intergovernmental Panel on Climate Change. Cambridge University Press, Cambridge, UK and New York, NY, USA, 3056 pp.
            </Text>
          </Stack>
        </Grid.Col>
      </Grid>
    </Container>
  )
}
