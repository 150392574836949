import { Container, createStyles, BackgroundImage, useMantineTheme } from "@mantine/core";

import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import HomeFAQ from "../../components/public/Home/FAQs";
import HowItWorks from "../../components/public/Home/HowItWorks";
import WhyFood from "../../components/public/Home/WhyFood";
import CallToAction1 from "../../components/public/Home/CallToAction1";
import OurApproach from "../../components/public/Home/Approach/OurApproach";
import ImpactTeaser from "../../components/public/Home/ImpactTeaser";
import CallToAction2 from "../../components/public/Home/CallToAction2";
import BannerFood from "../../components/public/Home/Banner2";

const useStyles = createStyles((theme) => ({
  imageContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: -1,
    display: "block",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
  },
  darkSection: {
    paddingTop: '10vh',
    paddingBottom: '10vh',
    backgroundColor: theme.colors.teal[9],
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',
  },
  lightSection: {
    paddingTop: '4rem',
    paddingBottom: '4rem',
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: 'white',
  },
  graySection: {
    paddingTop: '4rem',
    backgroundColor: theme.colors.gray[0],
    paddingLeft: 0,
    paddingRight: 0,
  },
}));


function HomeRoute() {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();

  const [opened, { close, open }] = useDisclosure(false);

  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  useEffect(() => {
    setTimeout(open, 1000);
  }, [open]);

  // Always scroll to the top of this page.
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <>
      {/* <Banner/> */}
      <BannerFood/>

      <Container className={classes.lightSection} fluid>
        <WhyFood/>
      </Container>

      <Container className={classes.graySection} fluid>
        <CallToAction1/>
      </Container>

      <Container className={classes.graySection} fluid>
        <OurApproach/>
      </Container>

      <Container fluid className={classes.graySection}>
        <HowItWorks/>
      </Container>

      <Container className={classes.darkSection} fluid>
        <ImpactTeaser/>
      </Container>

      <BackgroundImage
        src={"/assets/svg/landscape.svg"}
        component="div"
        style={{border: 'none'}}
      >
        <HomeFAQ/>
        <CallToAction2/>
      </BackgroundImage>
    </>
  );
}

export default HomeRoute;
