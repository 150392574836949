import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import * as Sentry from "@sentry/react";

import { Provider } from 'react-redux';
import { store } from './store';

import { MantineProvider } from '@mantine/core';

import './index.css'

import AppRouter from './router';


declare global {
  interface Window {
    analytics: {
      track: (eventName: string, properties?: any) => void;
      page: (pageName: string) => void;
      identify: (userId: number, properties: any) => void;
    };
    firebaseApp: any
  }
}


const mountSentry = () => {
  Sentry.init({
    dsn: "https://25a80ddfe45a45fd99eb4458a2649534@o4505511060635648.ingest.sentry.io/4505511068499968",
    tracesSampleRate: 0.05, // Capture 100% of the transactions, reduce in production!
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// Only initialize Sentry in production so that we don't spam ourselves with
// development errors. The `NODE_ENV` variable will be set to `development`
// if you started the app with `yarn start`, and `production` if you ran a build.
// https://create-react-app.dev/docs/adding-custom-environment-variables/
if (process.env.NODE_ENV === 'production') {
  setTimeout(mountSentry, 0);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


if (window.analytics) {
  window.analytics.page(window.location.pathname);
}


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider
        theme={{
          fontFamily: 'Inter,Proxima Nova,proxima-nova,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;',
          headings: {
            fontFamily: 'Recoleta,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;',
            fontWeight: 500,
          },
          primaryColor: 'teal',
          defaultGradient: { from: "teal", to: "cyan" },
          black: "#212529"
        }}
      >
        <AppRouter/>
      </MantineProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
