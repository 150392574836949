import { Container, createStyles, Space, Stack, Text, useMantineTheme } from "@mantine/core";
import HeaderTitle from "../../components/shared/HeaderTitle";
import { useEffect } from "react";
import ContactForm from "../../components/public/ForCompanies/ContactForm";
import { useMediaQuery } from "@mantine/hooks";


const useStyles = createStyles((theme) => ({
  darkSection: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: '3rem',
    paddingBottom: '3rem',
    backgroundColor: theme.colors.teal[9],
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',

    [theme.fn.smallerThan("sm")]: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    }
  },
  lightSection: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
    backgroundColor: 'white',
    paddingLeft: 0,
    paddingRight: 0,
    [theme.fn.smallerThan("sm")]: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    }
  },
  graySection: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
    backgroundColor: theme.colors.gray[0],
    [theme.fn.smallerThan("sm")]: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    }
  },
  imageSection: {
    backgroundImage: "url(/assets/companies/deforestation2.jpeg)",
    height: "min(100vh, 600px)",
    backgroundSize: "cover",
    padding: 0,
  },
  gradient: {
    background: theme.fn.linearGradient(0, "transparent", "white"),
    width: "100%",
    height: "100%",
    opacity: 1,
  },
  subtitle: {
    color: theme.colors.gray[9],
    textAlign: "left",
    [theme.fn.smallerThan("xs")]: {
      textAlign: "left"
    }
  },
  alignLeftOnMobile: {
    [theme.fn.smallerThan("xs")]: {
      marginRight: "auto"
    }
  }
}));


function ForCompaniesRoute() {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();

  const onGetInTouch = () => {
    window.open("mailto:info@climaterefarm.com", "_blank");
  }

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <>
      <Container className={classes.lightSection} fluid>
        <Container size="md">
          <Stack align="flex-start" spacing={"xl"}>
            <HeaderTitle
              header="Fund the sustainable food transition"
              title={"Contribute to climate solutions that benefit people, animals, and the planet."}
              align="left"
            />
            <Text fw={500} fz={"xl"} className={classes.subtitle}>
              The food sector is one of the most impactful, yet under-funded areas
              of climate mitigation. We've developed the first carbon credit that
              enables companies to lead the way in transitioning to a more
              environmentally sustainable, healthy, and humane food system.
            </Text>
            <Space h="2rem"/>
          </Stack>
        </Container>
      </Container>
      <Container className={classes.imageSection} fluid>
        <div className={classes.gradient}/>
      </Container>
      <Container className={classes.darkSection} fluid>
        <ContactForm/>
      </Container>
    </>
  );
}

export default ForCompaniesRoute;
