import { Title, Container, createStyles, Button, Stack, Text, useMantineTheme, Grid, TextInput, Alert, Image } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconArrowLeft, IconCheck } from "@tabler/icons-react";
import { useTrackUserReachedContinuePath } from "../../redux/hooks";
import { useNavigateTop } from "../../utils/hooks";
import { useEmailFootprintResultsLinkMutation } from "../../api/automation";

const useStyles = createStyles((theme) => ({
  form: {
    width: '100%',
  },

  imageContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: -1,
    display: "block",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
    backgroundImage: "url(/assets/webp/mangroves.webp)"
  },

  contentContainer: {
    paddingTop: '10vh',
    paddingBottom: '10vh',
  },
}));


function CalculatorFinished() {
  const { classes, cx } = useStyles();
  const navigate = useNavigateTop();
  const theme = useMantineTheme();

  useTrackUserReachedContinuePath();

  const [submitForm, result] = useEmailFootprintResultsLinkMutation();

  const regexValidEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  const form = useForm({
    initialValues: { email: '' },
    validate: {
      email: (value) => (regexValidEmail.test(value) ? null : 'Please enter a valid email'),
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    console.debug(values);
    if (result.isSuccess) {
      console.warn("Already submitted, skipping")
      return;
    }
    submitForm({...values });
    setTimeout(() => navigate("/choose-plan"), 1000);
  };

  const handleError = (errors: typeof form.errors) => {
    console.error(errors);
  };

  return (
    <>
      <Container fluid pos={"relative"} p={0}>
        <Container size="lg" pt={"3rem"}>
          <Grid gutterSm={"2rem"}>
            <Grid.Col md={3}>
              <Button
                variant="white"
                leftIcon={<IconArrowLeft size={"1rem"}/>}
                size="md"
                onClick={() => navigate("/calculator/natural-gas")}
              >
                Back to calculator
              </Button>
            </Grid.Col>
            <Grid.Col sm={6} md={5}>
              <Stack align="left">
                <Text color="teal" fw={600}>All done!</Text>
                <Title order={1} color={theme.colors.gray[8]} fw={500}>
                  Enter your email to save and view your results
                </Title>
                <Text color={theme.colors.gray[6]} fz={"lg"} fw={500}>On the next page you'll learn more about your carbon footprint and how to reduce it.</Text>
                <form onSubmit={form.onSubmit(handleSubmit, handleError)} className={classes.form}>
                  <TextInput size="md" w={"100%"} placeholder="Your email" {...form.getInputProps('email')}/>
                  <Text color="gray" size={"xs"} pt={"xs"}>We won't share your email, and you can unsubscribe at any time.</Text>
                  <Button
                    mt={"xl"}
                    loading={result.isLoading}
                    leftIcon={result.isSuccess ? <IconCheck/> : null}
                    size='md'
                    type="submit"
                    w={"100%"}
                  >
                      {result.isSuccess ? "Done!" : "Continue"}
                  </Button>
                  <Button variant="white" size="sm" onClick={() => navigate("/choose-plan")} w={"100%"}>Skip this for now</Button>
                </form>
              </Stack>
            </Grid.Col>
            <Grid.Col sm={6} md={4} display={"flex"} sx={{alignItems: "center"}}>
              <Stack>
                <Image src="/assets/process/city.png" radius="sm"/>
                <Alert
                  color="teal"
                  title={"Everyone can make a difference!"}
                  sx={{justifyContent: "center", display: "flex"}}
                  styles={{title: {justifyContent: "center"}}}
                >
                  <Stack align="left">
                    <Text color="black" fw={500} align="left">
                      For the cost of a few coffees each week, you can <b>offset 100% of your emissions</b> and fund high-impact climate solutions around the world.
                    </Text>
                  </Stack>
                </Alert>
              </Stack>
            </Grid.Col>
          </Grid>
        </Container>
      </Container>
    </>
  );
}

export default CalculatorFinished;
