import { createStyles, Paper, Text, Stack, useMantineTheme, Anchor } from "@mantine/core";
import { URL_WIKI_CALCULATOR, URL_WIKI_HOME } from "../../utils/links";


const useStyles = createStyles((theme) => ({
  paper: {
    backgroundColor: theme.colors.blue[0]
  }
}));


export default function MoreInformationCard() {
  const {classes} = useStyles();
  const theme = useMantineTheme();

  return (
    <Paper p="md" radius="sm" mt="lg" className={classes.paper}>
      <Stack align="left">
        <Text c="gray" fz="sm" align="left">
          We calculate your emissions using the latest data from
          the World Bank, FAO, IPCC, EPA, and IEA. <Anchor fz={"sm"} href={URL_WIKI_CALCULATOR} style={{color: theme.colors.blue[6]}} target="_blank" rel="noreferrer" underline>Learn more</Anchor>
        </Text>
      </Stack>
    </Paper>
  )
}
