import { useNavigate } from "react-router-dom"


export const useNavigateTop = () => {
  const navigate = useNavigate();
  return (path: string) => {
    window.scrollTo({ top: 0 });
    navigate(path);
  }
}
