import { useEffect, useState } from "react";

import { Accordion, ActionIcon, Alert, Box, Button, Container, Divider, Grid, Group, Image, LoadingOverlay, Paper, Skeleton, Space, Stack, Text, Title, Tooltip, createStyles, useMantineTheme } from "@mantine/core";
import { IconAdjustmentsDollar, IconAlertCircle, IconCheck, IconCreditCard, IconEdit, IconInfoCircleFilled, IconPlus, IconQuestionMark } from "@tabler/icons-react";

import LoginForm from "../../components/forms/Login";
import CreateAccountForm from "../../components/forms/CreateAccount";
import { selectCurrentUser, selectCurrentUserId, selectIsLoggedIn, useAppSelector, useTrackUserReachedContinuePath } from "../../redux/hooks";
import { IUser } from "../../redux/userSlice";
import { useCreateCheckoutSessionMutation, useGetSelectedPlanQuery, useListStripeSubscriptionsQuery, useUpdateSubscriptionMutation } from "../../api/payment";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useNavigateTop } from "../../utils/hooks";
import { CancelledNotification, CreatedNotification, UpdatedNotification } from "../../components/checkout/Notifications";


const useStyles = createStyles((theme) => ({
  lineHeight: {
    lineHeight: 1.0,
  },
  price: {
    fontWeight: 600,
  },
  muted: {
    color: theme.colors.gray[6],
    fontSize: "14px",
  },
  dark: {
    color: theme.colors.gray[8]
  },
  header: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 600
  }
}));


function FAQs() {
  const theme = useMantineTheme();
  return (
    <Accordion
      chevron={<IconPlus size="1rem" />}
      styles={{
        chevron: {
          '&[data-rotate]': {
            transform: 'rotate(45deg)',
          },
        },
        label: {
          fontWeight: 500,
          color: theme.colors.gray[6],
        }
      }}
    >
      <Accordion.Item value="what-happens-when-i-subscribe">
        <Accordion.Control>What happens once I subscribe?</Accordion.Control>
        <Accordion.Panel>
          Each month, your subscription funds a portfolio of projects that reduce emissions and remove carbon from the atmosphere.
          You can learn more about how we designed this portfolio <a href="/our-approach" style={{color: theme.colors.blue[8], fontWeight: "bold"}}>here</a>.
          <br/><br/>
          We'll also send you monthly project updates so you can track their progress and see the impact you're having.
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value="change-or-pause-subscription">
        <Accordion.Control>Can I change or pause my subscription?</Accordion.Control>
        <Accordion.Panel>Yes! You can pause your subscription or can change the amount at any time by logging in and going to the <b style={{color: theme.colors.blue[8]}}>Settings</b> menu.</Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value="how-is-my-funding-used">
        <Accordion.Control>How is my funding used?</Accordion.Control>
        <Accordion.Panel>
          75% of your funding goes directly to sustainble food, land restoration, and conservation projects.<br/><br/>

          The remaining 25% keeps Climate Refarm running: paying team salaries, developing sustainable food projects, sourcing the best nature-based carbon credits, and marketing to reach more people.
          We're fully-transparent, and will publish a yearly report that shows how our funding was used.<br/><br/>

          Finally, a small processing fee is added to cover processing costs from our payment provider, Stripe.
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value="tax-deductible">
        <Accordion.Control>Is this tax deductible?</Accordion.Control>
        <Accordion.Panel>
          The cost of your membership is not tax deductible.<br/><br/>

          This is because Climate Refarm is a Public Benefit Corporation (PBC), and not a nonprofit.
          A PBC is a new legal structure that allows companies to balance mission and profits. This means
          that they can raise outside funding from investors, while making impact a legally-binding
          part of their charter. We chose to become a PBC for exactly this reason – raising investment
          allows us to scale while staying true to our mission and values.
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}


function OrderSummary(props: { interval: 'month' | 'year', costPerBillingPeriod: number, loading: boolean }) {
  const theme = useMantineTheme();
  const {classes, cx} = useStyles();

  // https://stripe.com/pricing
  const processingFee = props.costPerBillingPeriod*0.029 + 0.30;

  return (
    <Stack align="left">
      <Group w={"100%"}>
        <Box w={"48px"} h={"48px"}>
          <Image src={"/assets/icons/product.svg"} radius={"sm"}/>
        </Box>
        <Stack spacing={"xs"}>
          <Text fz={"md"} fw={"bold"} className={cx(classes.lineHeight, classes.dark)}>Climate Refarm Membership</Text>
          {
            props.loading ? <Skeleton h="1rem" w="100px"/> :
            <Text className={cx(classes.lineHeight, classes.muted)}>Bills {props.interval == "month" ? "monthly" : "yearly"}</Text>
          }
        </Stack>
        <Stack align="right" ml={"auto"}>
          {
            props.loading ? <Skeleton h={"1rem"} w={"60px"}/> :
            <Text fz={"md"} className={cx(classes.price, classes.lineHeight)} align="right">
              {props.costPerBillingPeriod.toLocaleString("default", { style: "currency", currency: "USD" })}
            </Text>
          }
          {
            props.loading ? <Skeleton h={"1rem"} w={"30px"} ml={"auto"}/> :
            <Text fz={"xs"} className={cx(classes.lineHeight)} align="right" color={theme.colors.gray[8]}>
              <a href="/calculator" className={classes.muted}>Redo calculator</a>
            </Text>
          }
        </Stack>
      </Group>
      <Divider w={"100%"} variant="dashed"/>
      <Group align="left">
        <Group>
          <Text className={classes.muted}>Stripe processing fee</Text>
          <Tooltip
            multiline
            width={220}
            transitionProps={{ duration: 200 }}
            color={"white"}
            label={<Text>
              This is the cost of processing your payment via Stripe, our secure payments provider.
              Yearly subscriptions will have lower processing fees because they are billed less
              frequently.
            </Text>}
            styles={{tooltip: { color: "black", border: `1px solid ${theme.colors.gray[4]}`}, arrow: {borderColor: theme.colors.gray[4]} }}
          >
            <ActionIcon radius={"50%"} variant="filled" size={"xs"}>
              <IconQuestionMark size={12}/>
            </ActionIcon>
          </Tooltip>
        </Group>
        {
          props.loading ? <Skeleton h="1rem" w={"50px"} ml={"auto"}/> :
          <Text className={classes.muted} ml={"auto"}>{processingFee.toLocaleString("default", { style: "currency", currency: "USD" })}</Text>
        }
        <Divider w={"100%"}/>
      </Group>
      <Group align="left">
        <Text className={classes.price}>Total</Text>
        {
          props.loading ? <Skeleton h="1rem" w={"50px"} ml={"auto"}/> :
          <Text ml={"auto"} className={cx(classes.price)}>{(props.costPerBillingPeriod + processingFee).toLocaleString("default", { style: "currency", currency: "USD" })}</Text>
        }
      </Group>
    </Stack>
  );
}


function LoginOrCreateAccountForm(props: { loading: boolean }) {
  const [mode, setMode] = useState<"signup" | "login">("signup");

  const theme = useMantineTheme();
  const {classes, cx} = useStyles();

  return (
    <Paper radius="sm" shadow="xl" p={"xl"} withBorder pos={"relative"}>
      <LoadingOverlay visible={props.loading}/>
      <Stack align="center" spacing={"xl"}>
        {
          mode === "signup" ?
            <Title order={2} className={cx(classes.dark)} fw={500}>Create an account</Title> :
            <Title order={2} className={cx(classes.dark)} fw={500}>Log in</Title>
        }
        {
          mode === "signup" ?
            <CreateAccountForm/> :
            <LoginForm/>
        }
        {
          mode === "signup" ?
            <Text color={theme.colors.gray[6]}>
              Already have an account?<Button variant="white" onClick={() => setMode("login")}>Log in instead</Button>
            </Text> :
            <Text color={theme.colors.gray[6]}>
              Don't have an account yet?<Button variant="white" onClick={() => setMode("signup")}>Create account</Button>
            </Text>
        }
      </Stack>
    </Paper>
  );
}


function PaymentForm(props: { loading: boolean, user: IUser }) {
  const theme = useMantineTheme();
  const params = useParams();
  const navigate = useNavigate();
  const {classes, cx} = useStyles();

  const disable = params.status === "success" || params.status === "updated";

  const [createCheckoutSession, createCheckoutResponse] = useCreateCheckoutSessionMutation();
  const [updateSubscription, updateSubscriptionResponse] = useUpdateSubscriptionMutation();

  const userId = useAppSelector(selectCurrentUserId);

  // If the user has a subscription, skip the email collection step.
  const {data, isLoading, isFetching} = useListStripeSubscriptionsQuery(
    { userId: userId as string }, { skip: !userId });

  const fetching = !userId || isLoading || isFetching;

  const userHasSubscription = (data?.data || []).length > 0;

  // Tell the backend to create a checkout session.
  const onCheckout = () => {
    createCheckoutSession({
      successUrl: window.location.origin + "/checkout/payment/success",
      cancelUrl: window.location.origin + "/checkout/payment/cancelled",
      email: user.email,
    });
  }

  const onUpdate = () => {
    updateSubscription();
  }

  // If we receive a checkout redirect URL, go there!
  useEffect(() => {
    const stripeUrl = createCheckoutResponse.data?.redirectUrl;
    if (stripeUrl) {
      window.location.href = stripeUrl;
    }
  }, [createCheckoutResponse.data?.redirectUrl]);

  // If we receive an update redirect:
  useEffect(() => {
    if (updateSubscriptionResponse.isSuccess) {
      navigate("/checkout/payment/updated");
    }
  }, [updateSubscriptionResponse.isSuccess]);

  const loading = createCheckoutResponse.isLoading || updateSubscriptionResponse.isLoading;
  const error = createCheckoutResponse.error || updateSubscriptionResponse.error;

  let errorMsg = "Something went wrong, and our team has been notified.";
  // @ts-ignore
  if (error?.data.detail === "errors/too-many-updates") {
    errorMsg = "We don't allow subscription updates more than once per hour. Please wait and try again later."
  }

  const user = useAppSelector(selectCurrentUser);

  const icon = (createCheckoutResponse.isSuccess || updateSubscriptionResponse.isSuccess)
    ? <IconCheck size={"1.2rem"}/> : <IconCreditCard size={"1.2rem"}/>;

  return (
    <Paper radius="sm" shadow="xl" p={"xl"} withBorder pos={"relative"}>
      {
        fetching ?
          <Stack>
            <Skeleton h="2rem"/>
            <Skeleton h="1rem"/>
            <Skeleton h="2rem"/>
            <Skeleton h="2rem"/>
            <Skeleton h="4rem"/>
          </Stack> :
          <Stack>
            <Text className={cx(classes.dark, classes.header)}>
              {
                userHasSubscription ? `Welcome back, ${user.firstName}!` : `Welcome, ${user.firstName}!`
              }
            </Text>
            {
              userHasSubscription ?
                <Text className={classes.muted} fz={"lg"}>
                  Your subscription will be <strong>updated</strong> to the amount shown at the next billing cycle.

              </Text> :
              <Text className={classes.muted} fz={"lg"}>
                You can check out via Stripe by clicking below.
              </Text>
            }
            {
              userHasSubscription ?
                <Button size="md" leftIcon={icon} onClick={onUpdate} loading={loading} disabled={disable}>
                  Update subscription
                </Button> :
                <Button size="md" leftIcon={icon} onClick={onCheckout} loading={loading} disabled={disable}>
                  Checkout
                </Button>
            }
            {
              error ?
              <Alert color="orange" icon={<IconAlertCircle/>}>
                {errorMsg}
              </Alert> : undefined
            }
            {
              userHasSubscription ?
                <Alert icon={<IconInfoCircleFilled/>} color="blue">
                  If you're downgrading your plan, we'll apply a credit to your next invoice. If
                  you're upgrading your plan, you'll be refunded for the time left on your current
                  plan and then charged for the higher plan.
                </Alert> : undefined
            }
          </Stack>
      }
    </Paper>
  )
}


export default function CheckoutRoute() {
  const theme = useMantineTheme();
  const navigate = useNavigateTop();
  const {classes, cx} = useStyles();

  const params = useParams();

  const user = useAppSelector(selectCurrentUser);
  const userId = useAppSelector(selectCurrentUserId);

  const {data, isLoading, isFetching, isError, error} = useGetSelectedPlanQuery({ userId: userId || "NO_USER_ID" });

  if (isError) {
    console.error(error);
    // @ts-ignore
    if (error.data.detail === "errors/no-plan-selected") {
      console.debug("The user tried to go to the checkout page with no plan currently selected. Sending them back.");
      navigate("/choose-plan");
    }
  }

  useTrackUserReachedContinuePath();

  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  const loading = isLoading || isFetching || !user;

  const interval = data?.interval || "month";
  const pricePerTon = data?.pricePerTon || 25;
  const tonsPerYear =  data?.tonsPerYear || 10;
  const costPerBillingPeriod = (pricePerTon * tonsPerYear) / (interval === "month" ? 12 : 1);

  const status = params.status as "updated" | "success" | "cancelled" | undefined;

  const notification = status ? {
    "updated": <UpdatedNotification/>,
    "success": <CreatedNotification/>,
    "cancelled": <CancelledNotification/>
  }[status] : undefined;

  return (
    <Container size={"md"} pt={notification ? theme.spacing.lg : "4rem"} pb={"xl"}>
      {
        notification
      }
      <Space h="2rem"/>
      <Grid gutter={"2rem"}>
        <Grid.Col sm={6}>
          {
            isLoggedIn ?
              <PaymentForm user={user} loading={loading}/> :
              <LoginOrCreateAccountForm loading={loading}/>
          }
        </Grid.Col>
        <Grid.Col sm={6}>
          <Stack spacing="xl">
            <Text className={cx(classes.dark, classes.header)}>Your Order</Text>
            <OrderSummary interval={interval} costPerBillingPeriod={costPerBillingPeriod} loading={loading}/>
            <Text className={cx(classes.dark, classes.header)}>FAQs</Text>
            <FAQs/>
          </Stack>
        </Grid.Col>
      </Grid>
    </Container>
  );
}
