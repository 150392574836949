import { createStyles, Stack, Image, Divider, Text, Grid, Space, useMantineTheme, Box, Spoiler } from "@mantine/core";

import { useMediaQuery } from '@mantine/hooks';
import { TabSection } from "../../shared/TabSection/TabSection";

const useStyles = createStyles((theme) => ({
  subtitle: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 600
  },
  citation: {
    fontSize: 10,
    p: {
      margin: 0,
      padding: "0.25rem"
    }
  },
  cardImage: {
    // height: "100%",
    [theme.fn.smallerThan("lg")]: {
      height: "300px",
      // aspectRatio: "0.5",
    },
    // root: {height: "100%"}, figure: {height: "100%"},
    // imageWrapper: {height: "100%"}
  }
}));


function FullHeightImage(props: { src: string }) {
  const theme = useMantineTheme();
  const {classes} = useStyles();
  return (
    <Image
      src={props.src}
      radius={"sm"}
      className={classes.cardImage}
      height={"100%"}
      sx={{
        [theme.fn.largerThan("md")]: {
          filter: "drop-shadow(0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.1))"
        }
      }}
      styles={{
        root: {height: "100%"}, figure: {height: "100%"},
        imageWrapper: {height: "100%"}
      }}
    />
  );
}


const FoodEmissionsSection = () => {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <Box>
      <Grid gutter={"xl"}>
        <Grid.Col lg={6} order={1} orderLg={2}>
          <FullHeightImage src="/assets/webp/small/cattle.min.webp"/>
        </Grid.Col>
        <Grid.Col lg={6} order={2} orderLg={1}>
          <Stack>
            <Text className={classes.subtitle}>Even if we stopped using fossil fuels today, the food sector alone would cause 1.5°C of warming.</Text>
            <Spoiler
                maxHeight={mobile ? 120 : 1000}
                showLabel={<Text variant="subtle" fw={600} pt={"sm"}>Show more</Text>}
                hideLabel={<Text variant="subtle" fw={600} pt={"sm"}>Hide</Text>}
              >
            <Stack>
            <Text>The food sector is responsible for 30-34% of global greenhouse gas emissions. This is nearly 2X that of the transportation sector.</Text>
            <Text>While every food produces some emissions, most of these emissions come from livestock. Animal food products produce two-thirds of food emissions, despite producing only 18% of the world’s calories and 37% of our protein supply.</Text>
            <Divider size="sm"/>
            <Text className={cx(classes.citation)} align="left" c="gray">
              <p>Clark, M. A., et al. (2020). Global food system emissions could preclude achieving the 1.5C and 2C climate change targets. Science, 370(6517), 705–708. https://doi.org/10.1126/science.aba7357.</p>
              <p>Crippa, M., et al. (2021). Food systems are responsible for a third of global anthropogenic GHG emissions. Nature Food, 2(3), 198–209. https://doi.org/10.1038/s43016-021-00225-9.</p>
              <p>Xu, X., et al. Global greenhouse gas emissions from animal-based foods are twice those of plant-based foods. Nat Food 2, 724–732 (2021). https://doi.org/10.1038/s43016-021-00358-x.</p>
              <p>Ritchie, H. Half of the world’s habitable land is used for agriculture. Our World in Data (2019).</p>
            </Text>
            </Stack>
            </Spoiler>
          </Stack>
        </Grid.Col>
      </Grid>
    </Box>
  )
}


const DeforestationSection = () => {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <Box>
      <Grid gutter="xl">
        <Grid.Col lg={6} order={2} orderLg={1}>
          <Stack>
            <Text className={classes.subtitle}>Livestock are the single largest driver of deforestation and ecosystem loss.</Text>
            <Spoiler
                maxHeight={mobile ? 120 : 1000}
                showLabel={<Text variant="subtle" fw={600} pt={"sm"}>Show more</Text>}
                hideLabel={<Text variant="subtle" fw={600} pt={"sm"}>Hide</Text>}
              >
            <Stack>
            <Text>Escosystem loss is a climate risk because it causes the stored carbon in forests, grasslands, and other vegetation to be released.</Text>
            <Text>Livestock production is by far the largest driver of both historical and present ecosystem loss.
              This is because it's extremely land-intensive. Today, 77% of agricultural land, and one-third of Earth's habitable land is used for livestock.
              The problem is particularly urgent in tropical regions, where livestock farming is responsible for 54% of deforestation.
            </Text>
            <Text>To protect carbon sinks and natural habitats for wildlife, we need to switch to less land-intensive foods.</Text>
            <Divider size="sm"/>
            <Text className={cx(classes.citation)} align="left" c="gray">
              <p>Ritchie, H. The world has lost one-third of its forest, but an end of deforestation is possible. Our World in Data (2021).</p>
              <p>Ritchie, H. Half of the world’s habitable land is used for agriculture. Our World in Data (2019).</p>
              <p>Ritchie, H., and Roser, M. Drivers of deforestation. Our World in Data (2021).</p>
              <p>Pendrill F., et al. (2019). Agricultural and forestry trade drives large share of tropical deforestation emissions, Global Environmental Change,Volume 56, 2019, Pages 1-10.</p>
            </Text>
            </Stack>
            </Spoiler>
          </Stack>
        </Grid.Col>
        <Grid.Col lg={6} order={1} orderLg={2}>
          <FullHeightImage src="/assets/approach/deforestation2.jpeg"/>
        </Grid.Col>
      </Grid>
    </Box>
  );
}


const LandGapSection = () => {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <Box>
      <Grid gutter="xl">
        <Grid.Col lg={6} order={1} orderLg={2}>
          <FullHeightImage src="/assets/approach/cattle_farms.jpeg"/>
        </Grid.Col>
        <Grid.Col lg={6} order={2} orderLg={1}>
          <Stack>
            <Text className={classes.subtitle}>
              Most of the space we need for carbon removal is occupied by livestock.
            </Text>
            <Spoiler
                maxHeight={mobile ? 120 : 1000}
                showLabel={<Text variant="subtle" fw={600} pt={"sm"}>Show more</Text>}
                hideLabel={<Text variant="subtle" fw={600} pt={"sm"}>Hide</Text>}
            >
            <Stack>
            <Text>Climate scientists predict that we'll need to remove up to 10 gigatons of CO₂ from the atmosphere each year by 2050 to keep warming under 1.5C. Reforestation is one of the most cost-effective ways to remove carbon, and many countries are depending on it to meet their climate goals.</Text>
            <Text>The problem is that the world has pledged to plant more trees than it has space for. This problem, known as the carbon removal “land gap”, doesn’t get nearly as much attention as it deserves.</Text>
            <Text>The underlying issue is that one-third of Earth’s land is used to grow land-intensive livestock. This has a carbon opportunity cost of about 772 gigatons of CO₂, which is how much carbon removal would occur if we let native biomass regrow.</Text>
            <Divider size="sm"/>
            <Text className={cx(classes.citation)} align="left" c="gray">
              <p>Carbon removal. (2023). World Resources Institute. https://www.wri.org/initiatives/carbon-removal</p>
              <p>IPCC, 2022: Climate Change 2022: Impacts, Adaptation, and Vulnerability. Contribution of Working Group II to the Sixth Assessment Report of the Intergovernmental Panel on Climate Change. Cambridge University Press, Cambridge, UK and New York, NY, USA, 3056 pp., doi:10.1017/9781009325844.</p>
              <p>Dooley K., et al. (2022). The Land Gap Report. Available at: https://www.landgap.org/</p>
              <p>Tightening the net: Net zero climate targets – implications for land and food equity. Oxfam International (2021).</p>
            </Text>
            </Stack>
            </Spoiler>
          </Stack>
        </Grid.Col>
      </Grid>
    </Box>
  )
}


const data = [
  {
    demo: FoodEmissionsSection,
    header: "Problem 1",
    name: 'Food emissions',
    description: 'The food sector is responsible for one-third of emissions.',
  },
  {
    demo: DeforestationSection,
    header: "Problem 2",
    name: 'Deforestation and biodiversity loss',
    description: 'Food is the largest driver of deforestation and biodiversity loss.',
  },
  {
    demo: LandGapSection,
    header: "Problem 3",
    name: 'The carbon removal land gap',
    description: 'Most of the land we need for carbon removal is currently used for agriculture.',
  },
];


export default function ProblemTabs() {
  const theme = useMantineTheme();
  return (
    <TabSection
      data={data}
      backgroundColor={theme.colors.gray[0]}
      colorScheme="light"
      tabSize={180}
    />
  )
}
