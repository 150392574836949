import { createStyles, rem, keyframes } from "@mantine/core";


export const HEADER_HEIGHT = rem(64);


const fadeIn = keyframes({
  "0%": {
    opacity: 0,
  },
  "100%": {
    opacity: 1,
  }
});


export interface StylesProps {
  color: string
  backgroundColor: string
  position: "absolute" | "fixed" | "static" | "relative" | "sticky"
  placeholder?: boolean
  opened?: boolean
}


export const useStyles = createStyles((theme, { position, color, backgroundColor, placeholder, opened } : StylesProps) => ({
  placeholder: {
    height: HEADER_HEIGHT,
    display: placeholder ? "block" : "none"
  },
  header: {
    position: position,
    background: opened ? theme.colors.teal[9] : backgroundColor,
    borderBottom: (position === "fixed" && !opened) ? `1px solid ${theme.colors.gray[4]}` : 0,
    boxShadow: (position === "fixed") ? "0rem 0.2rem 0.3rem rgba(0, 0, 0, 0.05)" : undefined
  },
  logo: {
    animation: `${fadeIn} 200ms ease-in-out 1`,
  },
  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'transparent',
    borderBottom: 0,
    paddingLeft: "3rem",
    paddingRight: "3rem",
    [theme.fn.smallerThan("sm")]: {
      paddingLeft: theme.spacing.lg,
      paddingRight: theme.spacing.lg,
    }
  },
  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
  link: {
    display: 'block',
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: color,
    fontSize: theme.fontSizes.md,
    fontWeight: 600,

    [theme.fn.smallerThan("sm")]: {
      fontSize: theme.fontSizes.md,
    },

    '&:hover': {
      backgroundColor: theme.colors.gray[2],
      color: theme.colors.gray[9],
      transition: '0.3s'
    },
  },
  linkLabel: {
    marginRight: rem(5),
    fontSize: theme.fontSizes.md,
    fontWeight: 600,

    [theme.fn.smallerThan("sm")]: {
      fontSize: theme.fontSizes.md,
    }
  },
  white: {
    color: 'white',
  },
  black: {
    color: 'black'
  },
  divider: {
    paddingLeft: theme.spacing.xs,
    alignSelf: "center",
    height: "24px",
  },
  menuItem: {
    fontSize: theme.fontSizes.md,
    fontWeight: 600,

    [theme.fn.smallerThan("sm")]: {
      fontSize: theme.fontSizes.md,
    }
  },
  menuLink: {
    color: 'black',
    textDecoration: 'none'
  },
}));