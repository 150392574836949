import { useEffect, useRef } from 'react';
import { NumberInput, Group, NumberInputHandlers, UnstyledButton, createStyles, Text, useMantineTheme } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
  group: {
    border: `2px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.md,
    height: "56px",
    alignItems: "center",
    justifyItems: "center"
  },
  button: {
    width: "1rem"
  },
  buttonText: {
    lineHeight: 1,
    color: theme.colors.gray[4],
    padding: theme.spacing.xs,
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: 500,
  },
  input: {
    border: "none",
    // width: "4rem"
  }
}));


interface ArrowNumberInputProps {
  value?: number
  step?: number
  min?: number
  max?: number
  defaultValue?: number
  onChange?: (value: number) => void
}

export default function ArrowNumberInput({ value, step, min, max, defaultValue, onChange }: ArrowNumberInputProps) {
  const {classes} = useStyles();
  const theme = useMantineTheme();
  const handlersRef = useRef<NumberInputHandlers>(null);

  const [_value, _setValue] = useDebouncedState<number | undefined>(value || defaultValue, 500);

  // If the local value changes, use the callback.
  useEffect(() => {
    if (onChange && _value !== value) {
      onChange(_value as number);
    }
  }, [_value]);

  // Keep the local copy of the value up-to-date with the higher source of truth.
  useEffect(() => {
    _setValue(value);
  }, [value]);

  return (
    <Group className={classes.group} grow>
      <UnstyledButton onClick={() => handlersRef.current?.decrement()} className={classes.button}>
        <Text className={classes.buttonText}>-</Text>
      </UnstyledButton>
      <NumberInput
        value={_value}
        onChange={(v) => _setValue(v as number)}
        className={classes.input}
        styles={{input: { border: "none", textAlign: "center", color: theme.colors.gray[9], paddingLeft: 0, paddingRight: 0 }}}
        handlersRef={handlersRef}
        step={step}
        min={min}
        max={max}
        defaultValue={defaultValue}
        size="lg"
        hideControls
      />
      <UnstyledButton onClick={() => handlersRef.current?.increment()}>
        <Text className={classes.buttonText}>+</Text>
      </UnstyledButton>
    </Group>
  )
}
