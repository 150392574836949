import { ActionIcon, Alert, Button, Card, Center, Container, CopyButton, Divider, Flex, Spoiler, Stack, Text, createStyles, useMantineTheme } from "@mantine/core";
import { IconCheck, IconCopy, IconExternalLink, IconInfoCircle } from "@tabler/icons-react";

import HeaderTitle from "../../components/shared/HeaderTitle";
import { useEffect } from "react";

const useStyles = createStyles((theme) => ({
  showMore: {
    button: {
      color: theme.colors.teal[6],
      paddingTop: '0.5rem',
      fontSize: '0.8rem',
    }
  },
  imageContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: -1,
    display: "block",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
  },

  contentContainer: {
    paddingTop: '15vh',
    paddingBottom: '15vh',
  },

  topImageContainer: {
    backgroundImage: "url(/assets/webp/field.jpeg)",
    backgroundPosition: "bottom",
    filter: "brightness(80%)",
  },

  gradient: {
    position: "absolute",
    left: 0,
    height: "100%",
    width: "100%",
    background: theme.fn.linearGradient(180, "transparent", "white")
  },

  sectionTitle: {
    fontWeight: 600,
    fontSize: "1.6rem",
    color: theme.colors.teal[6],
  },

  claim: {
    fontWeight: 600,
    fontSize: theme.fontSizes.xl,
  }
}));


function Reference(props: { href?: string, children: any }) {
  return (
    <Flex gap={"sm"} align={"center"} justify={"flex-start"}>
      <Text color="black" sx={{
        fontSize: "0.8rem"
      }}>
        {props.children}
      </Text>
      <CopyButton value={props.children}>
        {({ copied, copy }) => (
          <ActionIcon onClick={copy} size={"xs"} variant="transparent">
            {copied ? <IconCheck/> : <IconCopy/>}
          </ActionIcon>
        )}
      </CopyButton>
      {
        props.href ?
          <a style={{height: '16px', width: '16px'}} href={props.href} target="_blank" rel="noreferrer">
            <IconExternalLink color="gray" size={16}/>
          </a> : ''
      }
    </Flex>
  )
}


function Clarification(props: { children: any, title: string }) {
  const {classes} = useStyles();

  return (
    <Alert color="teal" title={props.title} icon={<IconInfoCircle size="1rem" />}>
      <Spoiler maxHeight={64} showLabel="Show more" hideLabel="Hide" className={classes.showMore}>
        {props.children}
      </Spoiler>
    </Alert>
  );
}


function ReferencesRoute() {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();

  // Always scroll to the top of this page.
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <>
      <Container fluid pos={"relative"}>
        <div className={cx(classes.imageContainer, classes.topImageContainer)}/>
        <div className={classes.contentContainer}>
        <Container size={"sm"}>
          <Stack spacing={"xl"} align="center" pb={"4rem"} pt={"4rem"}>
            <HeaderTitle
              color="white"
              title="References Cited"
            />
          </Stack>
        </Container>
        </div>
      </Container>
      <Container size={"sm"}>
        <Stack spacing={"xl"} pt={60} pb={60}>
          <Text className={classes.sectionTitle}>Climate change & the food system</Text>
          <Text className={classes.claim}>30-34% of emissions come from the food sector</Text>
          <Reference href="https://www.science.org/doi/10.1126/science.aaq0216">
            Poore, J., & Nemecek, T. (2018). Reducing food's environmental impacts through producers and consumers. Science. Vol 360, Issue 6392.
          </Reference>
          <Reference href="https://doi.org/10.1038/s43016-021-00225-9">
            Crippa, M., et al. (2021). Food systems are responsible for a third of global anthropogenic GHG emissions. Nature Food, 2(3), 198–209. https://doi.org/10.1038/s43016-021-00225-9.
          </Reference>
          <Reference href="https://doi.org/10.1038/s43016-021-00358-x">
            Xu, X., Sharma, P., Shu, S. et al. Global greenhouse gas emissions from animal-based foods are twice those of plant-based foods. Nat Food 2, 724–732 (2021). https://doi.org/10.1038/s43016-021-00358-x.
          </Reference>
          <Clarification title="Emissions from the food sector">
            The share of emissions attributed to the food sector ranges from 20%-35% in
            published literature. The estimate from Poore & Nemecek (13.6 Gt CO₂e; 26%) does not include
            post-retail emissions from cooking and food waste, which is estimated to be 2.1 Gt CO₂e by Crippa et al.
            With post-retail emissions included, the figure from Poore & Nemecek rises to 15.7 Gt CO₂e, which
            equals 30% of global emissions. The approach of Xu et al. yields the highest estimate of 35%.
            For an in-depth explanation of the methodological differences that lead to these disparate estimates,
            we recommend this <a href="https://ourworldindata.org/greenhouse-gas-emissions-food">article</a> by Hannah Ritchie of Our World in Data.
            It is worth noting that the 30% estimate derived from Poore & Nemecek is likely conservative
            due to the relatively low fraction of land use change attributed to agriculture.
          </Clarification>
          <Text className={classes.claim}>Even if we stop using fossil fuels today, food emissions alone will cause 1.5°C of warming.</Text>
          <Reference href="https://doi.org/10.1126/science.aba7357">
            Clark, M. A., et al. (2020). Global food system emissions could preclude achieving the 1.5C and 2C climate change targets. Science, 370(6517), 705–708. https://doi.org/10.1126/science.aba7357.
          </Reference>
          <Text className={classes.claim}>Half of the world's habitable land is used for agriculture</Text>
          <Reference href="https://ourworldindata.org/land-use">
            Ritchie, H., and Roser, M. Land Use. Our World in Data. https://ourworldindata.org/land-use
          </Reference>
          <Text className={classes.claim}>Climate scientists predict that we will need to remove up to 10 gigatons of CO₂ from the atmosphere each year by 2050 to keep warming under the 1.5C Paris Agreement target.</Text>
          <Reference href="https://www.wri.org/initiatives/carbon-removal">
            Carbon removal. (2023). World Resources Institute. https://www.wri.org/initiatives/carbon-removal
          </Reference>
          <Divider size={"sm"}/>

          {/* --- */}
          <Text className={classes.sectionTitle}>Animal agriculture</Text>
          <Text className={classes.claim}>... is the largest contributor to food emissions and represents 20% of global emissions</Text>
          <Reference href="https://doi.org/10.1038/s43016-021-00358-x">
            Xu, X., Sharma, P., Shu, S. et al. Global greenhouse gas emissions from animal-based foods are twice those of plant-based foods. Nat Food 2, 724–732 (2021). https://doi.org/10.1038/s43016-021-00358-x.
          </Reference>
          <Clarification title="Emissions from animal agriculture">
            Similar to food sector as a whole, attribution of emissions to animal agriculture varies across the published literature.
            While the most widely cited estimate from the FAO is 14.5%, it has been superceded by more recent estimates from the FAO. Furthermore, it has been pointed out
            that this estimate should be <a href="https://doi.org/10.3390/su13116276" target="_blank" rel="noreferrer">revised upward to at least 16.5%</a>.
            The most up-to-date analysis, from Xu et al., estimates that animal-based food products account for 57% of food system emissions and 19.8% of total global emissions.
          </Clarification>
          <Text className={classes.claim}>... is the largest driver of tropical deforestation (54%)</Text>
          <Reference href="https://ourworldindata.org/drivers-of-deforestation">
            Ritchie, H., and Roser, M. Drivers of deforestation. Our World in Data. https://ourworldindata.org/drivers-of-deforestation.
          </Reference>
          <Clarification title={"Tropical deforestation due to livestock"}>
            Note that the 54% statistic on our website is a combination of deforestation due to pasture expansion and feed crops, obtained from the source above.
            41% of deforestation is driven by pasture expansion for beef. Deforestation due to cropland expansion must
            be allocated between human, livestock, and non-food uses.
            18.4% of deforestation is driven by cropland expansion for oilseeds (e.g, soybeans and palm oil), with 12% coming
            from crops other than palm oil, which we assign to non-livestock sources. Another 9.6% of deforestation is due to cereal crops. <a href="https://ourworldindata.org/drivers-of-deforestation">77% of global soy</a> and <a href="https://ourworldindata.org/grapher/cereal-distribution-to-uses">37.5% of cereals</a> are fed to livestock.
            Therefore, we attribute 41% + 9.2% + 3.6% = 53.8% of deforestation to livestock.
          </Clarification>
          <Text className={classes.claim}>... is the largest driver of biodiversity loss</Text>
          <Reference href="https://doi.org/10.1038/s41893-020-00656-5">
            Williams, D.R., Clark, M., Buchanan, G.M. et al. Proactive conservation to prevent habitat losses to agricultural expansion. Nat Sustain 4, 314–322 (2021). https://doi.org/10.1038/s41893-020-00656-5.
          </Reference>
          <Text className={classes.claim}>1/3 of land use is for grazing and feed crops</Text>
          <Reference href="https://ourworldindata.org/land-use">
            Ritchie, H., and Roser, M. Land Use. Our World in Data. https://ourworldindata.org/land-use
          </Reference>
          <Text className={classes.claim}>Livestock produces 18% of the world's calories and 37% of total protein</Text>
          <Reference href="https://ourworldindata.org/global-land-for-agriculture">
            Ritchie, H. Half of the world’s habitable land is used for agriculture. Our World in Data (2019).
          </Reference>
          <Divider size={"sm"}/>

          {/* --- */}
          <Text className={classes.sectionTitle}>Plant-based diets</Text>
          <Text className={classes.claim}>... use 49% less emissions and 76% less land</Text>
          <Reference href="https://www.science.org/doi/10.1126/science.aaq0216">
            Poore, J., & Nemecek, T. (2018). Reducing food's environmental impacts through producers and consumers. Science. Vol 360, Issue 6392.
          </Reference>
          <Text className={classes.claim}>A plant-based food system would free up an area the size of Africa for restoration</Text>
          <Reference href="">
            Poore, J., & Nemecek, T. (2018). Reducing food’s environmental impacts through producers and consumers. Science. Vol 360, Issue 6392.
          </Reference>
          <Clarification title="How is that possible?">
            Based on the study by Poore et al., transitioning to a plant-based food system would free up 3.1 billion hectares of land. The size of Africa is 3.037 billion hectares.
            While this amount of land is hard to fathom, keep in mind that livestock currently occupy one-third of Earth's habitable land (3.5 billion hectares).
          </Clarification>
          <Text className={classes.claim}>Transitioning to a plant-based food system would offset 68% of our emissions this century and freeze global warming for 30 years</Text>
          <Reference href="https://doi.org/10.1371/journal.pclm.0000010">
            Eisen, Michael B., and Patrick O. Brown. "Rapid global phaseout of animal agriculture has the potential to stabilize greenhouse gas levels for 30 years and offset 68 percent of CO₂ emissions this century." PLoS Climate 1.2 (2022).
          </Reference>
          <Text className={classes.claim}>Restoring land used for livestock would remove 772 Gt CO₂e emissions</Text>
          <Reference href="https://www.nature.com/articles/s41893-020-00603-4">
            Hayek, M.N., Harwatt, H., Ripple, W.J. et al. The carbon opportunity cost of animal-sourced food production on land. Nature Sustainability 4, 21-24 (2021).
          </Reference>
          <Clarification title="A note on this figure">
            This figure includes the paper's estimate of carbon sequestration in biomass (e.g, trees and grasslands) as well carbon stored in soil.
          </Clarification>
          <Divider size={"sm"}/>

          {/* --- */}
          <Text className={classes.sectionTitle}>Ecosystem conservation & restoration</Text>
          <Text className={classes.claim}>Without freeing up land, carbon removal poses a risk for food scarcity</Text>
          <Reference href="https://www.oxfamamerica.org/explore/research-publications/tightening-the-net/">
            Oxfam International (2021). Tightening the net: Net zero climate targets – implications for land and food equity.
          </Reference>
          <Text className={classes.claim}>
            Countries have pledged to reforest more forest area than they have available.
          </Text>
          <Reference href="https://www.landgap.org">
            Dooley K., Keith H., Larson A., Catacora-Vargas G., Carton
            W., Christiansen K.L., Enokenwa Baa O., Frechette A., Hugh
            S., Ivetic N., Lim L.C., Lund J.F., Luqman M., Mackey B.,
            Monterroso I., Ojha H., Perfecto I., Riamit K., Robiou du Pont
            Y., Young V., 2022. The Land Gap Report 2022. Available at:
            https://www.landgap.org/
          </Reference>
          <Divider size={"sm"}/>

          {/* <Card withBorder sx={{width: 'fit-content'}} mt={32}>
            <Stack align="flex-start">
              <Text className={classes.claim}>Do you have questions about these resources?</Text>
              <a href="mailto:info@climaterefarm.com" target="_blank" rel="noreferrer">
              <Button>Send us an email</Button>
              </a>
            </Stack>
          </Card> */}
        </Stack>
      </Container>
    </>
  )
}


export default ReferencesRoute;
