import { ENABLE_CALCULATOR } from '../../../utils/flags';
import { HEADER_HEIGHT, useStyles } from './TopHeader.styles';
import { Header, Container, Group, Center, Menu, MediaQuery, Burger, useMantineTheme, Image, Divider, Avatar } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { selectCurrentUser, selectIsInitialized, selectIsLoggedIn, useAppSelector } from '../../../redux/hooks';
import { URL_PROJECT_DATABASE, URL_WIKI_HOME } from '../../../utils/links';
import { useDisclosure, useMediaQuery, useWindowScroll } from '@mantine/hooks';
import MobileDrawer from './MobileDrawer';
import TakeActionButton from './TakeActionButton';


interface TopHeaderProps {
  defaultPosition: "absolute" | "fixed" | "static" | "relative" | "sticky"
  defaultLogoColor: string
  defaultTextColor: string
  defaultBackgroundColor: string
  becomeStickyOnScroll?: boolean
}


export default function TopHeader({
  defaultPosition,
  defaultLogoColor,
  defaultTextColor,
  defaultBackgroundColor,
  becomeStickyOnScroll
} : TopHeaderProps) {
  const theme = useMantineTheme();

  const user = useAppSelector(selectCurrentUser);
  const isInitialized = useAppSelector(selectIsInitialized);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  const [opened, { toggle, close }] = useDisclosure(false);
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);
  const [{ y }] = useWindowScroll();

  const fixed = (defaultPosition === "fixed") || (becomeStickyOnScroll && y > window.innerHeight);
  const placeholder = (becomeStickyOnScroll && y > window.innerHeight) &&
    (!["fixed", "absolute"].includes(defaultPosition));

  const position = fixed ? "fixed" : defaultPosition;

  let color = defaultTextColor;
  let logoColor = defaultLogoColor;
  if (opened) {
    color = "white";
    logoColor = "white";
  } else if (fixed) {
    color = theme.colors.gray[9];
    logoColor = theme.colors.gray[9];
  }

  const backgroundColor = fixed ? "white" : defaultBackgroundColor;

  const { classes, cx } = useStyles({
    position,
    color,
    backgroundColor,
    placeholder,
    opened,
  });

  const initials = (user.firstName || "A")[0] + (user.lastName || "B")[0];
  const userButton = isLoggedIn ? {
    link: '/dashboard',
    label: <Avatar radius={"50%"} size={"32px"}>{initials}</Avatar>,
    links: [
      {link: '/dashboard', label: '🌲 Impact Dashboard'},
      {link: '/calculator', label: '📏 Footprint Calculator'},
      {link: '/dashboard/settings', label: '⚙️ Settings'},
      {link: '/logout', label: 'Logout'},
    ]
  } : {
    link: '/login', label: 'Log in'
  }

  const links = [
    { link: '/home', label: 'Overview' },
    { link: '/our-approach', label: 'Our Approach' },
    { link: '/learn-more', label: 'Learn More', links: [
      {link: '/for-companies', label: 'For Companies'},
        {link: '/about-us', label: 'About Us'},
        {link: URL_WIKI_HOME, label: '📖 Wiki'},
        {link: URL_PROJECT_DATABASE, label: '🌱 Projects'},
        {link: '/references', label: '📓 References'},
      ]
    },
    ...(ENABLE_CALCULATOR ? [{ link: null, label: 'Divider' }] : []),
    ...((isInitialized && ENABLE_CALCULATOR) ? [userButton] : [])
  ];

  const items = links.map((link) => {
    if (!link.link) {
      return <Divider
        className={classes.divider}
        orientation='vertical'
        size={"sm"}
        key={"divider"}
      />
    }

    const menuItems = link.links?.map((item) => (
      <Menu.Item className={classes.menuItem} key={item.link} component={"a"} href={item.link}>
        {item.label}
      </Menu.Item>
    ));

    if (menuItems) {
      return (
        <Menu
          key={link.link}
          trigger="hover"
          offset={16}
          position="bottom-end"
          transitionProps={{ exitDuration: 300 }}
          shadow="lg"
          closeOnItemClick={false}
        >
          <Menu.Target>
            <a
              href={link.link}
              className={classes.link}
              onClick={(event) => event.preventDefault()}
            >
              <Center>
                <span className={cx(classes.linkLabel)}>{link.label}</span>
                <IconChevronDown size="1rem" stroke={2}/>
              </Center>
            </a>
          </Menu.Target>
          <Menu.Dropdown>
            {menuItems}
          </Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <a
        key={link.link}
        href={link.link}
        className={classes.link}
      >
        {link.label}
      </a>
    );
  });

  const actionButton = <TakeActionButton close={close}/>;

  return (
    <Header
      height={HEADER_HEIGHT}
      className={classes.header}
    >
      <MobileDrawer opened={opened} close={close}/>
      <Container className={classes.inner} fluid>
        <Group>
          <a href="/">
            <Image
              src={
                (logoColor === "white" || opened) ?
                  `/assets/logo/${mobile ? "mobile_" : ""}white.svg` :
                  `/assets/logo/${mobile ? "mobile_" : ""}black.svg`
              }
              height={38}
              width={mobile ? 28 : 100}
              fit='fill'
              className={classes.logo}
            />
          </a>
        </Group>
        <Group
          spacing={5}
          className={classes.links}
          position="right"
          ml={"auto"}
        >
          {[...items]}
          {actionButton}
        </Group>
        <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
          <Burger
            opened={opened}
            onClick={toggle}
            size="md"
            color={opened ? "white" : color}
          />
        </MediaQuery>
      </Container>
    </Header>
  );
}
