import { useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import type { RootState, AppDispatch } from '../store'
import { usePostUserReachedPathMutation } from '../api/tracking'
import { useEffect } from 'react'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const selectCurrentUser = (state: RootState) => state.user.currentUser;
export const selectCurrentUserId = (state: RootState) => state.user.currentUser.userId;
export const selectIsInitialized = (state: RootState) => state.user.currentUser.userId !== null;
export const selectIsAnonymous = (state: RootState) => state.user.currentUser.isAnonymous;
export const selectIsLoggedIn = (state: RootState) => (!state.user.currentUser.isAnonymous && state.user.currentUser.email !== null);


export function useTrackUserReachedContinuePath() {
  const [track, _] = usePostUserReachedPathMutation();
  const userId = useAppSelector(selectCurrentUserId);

  useEffect(() => {
    if (userId) {
      const checkoutStep = { name: "calculator", pathname: window.location.pathname };
      track({userId: userId, step: checkoutStep});
    }
  }, [window.location.pathname]);
}
