import { useGetCalculatorDefaultsQuery, useGetCalculatorInputsQuery, useUpdateCalculatorInputsMutation } from "../../../api/calculator";
import CustomNumberInput from "../../../components/calculator/CustomNumberInput";
import MultipleChoice, { IChoiceData, isCustomNumericOptionSelected } from "../../../components/calculator/MultipleChoice";
import { selectCurrentUserId, useAppSelector } from "../../../redux/hooks";
import { almostEqual } from "../../../utils/helpers";


type AreaUnit = "m²" | "ft²";


const maybeConvertUnits = (value: number, from: AreaUnit, to: AreaUnit) => {
  if (from === to) {
    return value;
  // ft² to m2
  } else if (to === "m²") {
    return value * 0.092903;
  // m2 to ft²
  } else {
    return value * 10.764;
  }
}


export default function SelectHomeSize(props: { goToNext?: () => void }) {
  const userId = useAppSelector(selectCurrentUserId) as string;

  const {data} = useGetCalculatorInputsQuery(userId);
  const [updateCalculator] = useUpdateCalculatorInputsMutation();

  const defaultsApi = useGetCalculatorDefaultsQuery(userId);
  const loading = !userId || defaultsApi.isLoading || defaultsApi.isFetching;

  const inputs = data?.inputs;
  const storedUnits = "ft²";
  const storedValue = inputs?.homeSizeFt2 || null;

  const userUnits = inputs?.countryCode !== "USA" ? "m²" : "ft²";
  const userValue = storedValue === null ? null : maybeConvertUnits(storedValue, storedUnits, userUnits);

  const setStoredValue = (value: number | string | null) => {
    updateCalculator({userId, inputs: { homeSizeFt2: maybeConvertUnits(value as number, userUnits, storedUnits) }});
  };

  const sharedOnClick = (data: IChoiceData) => {
    setStoredValue(data.value);

    if (props.goToNext) {
      props.goToNext();
    }
  }

  // TODO(milo): Set these multiples.
  const meanValue = maybeConvertUnits(defaultsApi.data?.defaults.homeSizeFt2 || 1000, "ft²", userUnits);
  const lowValue = meanValue * 0.75;
  const highValue = meanValue * 3.0;

  const choices = [
    {
      value: lowValue,
      label: "Small apartment",
      detail: `${lowValue.toLocaleString("default", { maximumFractionDigits: 0 })} ${userUnits}`,
      selected: almostEqual(userValue, lowValue, 0.5),
      onClick: sharedOnClick,
    },
    {
      value: meanValue,
      label: "Average home",
      detail: `${meanValue.toLocaleString("default", { maximumFractionDigits: 0 })} ${userUnits}`,
      selected: almostEqual(userValue, meanValue, 0.5),
      onClick: sharedOnClick
    },
    {
      value: highValue,
      label: "Larger home",
      detail: `${highValue.toLocaleString("default", { maximumFractionDigits: 0 })} ${userUnits}`,
      selected: almostEqual(userValue, highValue, 0.5),
      onClick: sharedOnClick
    }
  ];

  const customIsSelected = isCustomNumericOptionSelected(choices, userValue, 0.5);

  const customInput =
    <CustomNumberInput
      onChange={setStoredValue}
      units={userUnits}
      initialValue={customIsSelected ? userValue : null}
      selected={customIsSelected}
      min={0}
      max={10000}
    />;

  return (
    <MultipleChoice
      loading={loading}
      choices={choices}
      customInputElement={customInput}
    />
  );
}
