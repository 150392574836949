import { DietPattern, useGetCalculatorInputsQuery, useUpdateCalculatorInputsMutation } from "../../../api/calculator";
import MultipleChoice, { IChoiceData } from "../../../components/calculator/MultipleChoice";
import { selectCurrentUserId, useAppSelector } from "../../../redux/hooks";


export default function SelectDiet() {
  const userId = useAppSelector(selectCurrentUserId) as string;

  const {data} = useGetCalculatorInputsQuery(userId);
  const [updateCalculator] = useUpdateCalculatorInputsMutation();

  const inputs = data?.inputs;
  const userValue = inputs?.dietPattern;

  const setUserValue = (value: number | string | null) => {
    updateCalculator({userId, inputs: { dietPattern: value as DietPattern }});
  }

  const sharedOnClick = (data: IChoiceData) => {
    setUserValue(data.value);
  }

  const choices = [
    { value: "vegan", label: "Vegan", detail: "(No animal products)", selected: userValue === "vegan", onClick: sharedOnClick },
    { value: "vegetarian", label: "Vegetarian", detail: "(No meat, but dairy and eggs)", selected: userValue === "vegetarian", onClick: sharedOnClick },
    { value: "neither", label: "None of the above", selected: userValue === "neither", onClick: sharedOnClick }
  ]

  return (
    <MultipleChoice
      choices={choices}
    />
  );
}
