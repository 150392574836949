import { Group, Skeleton, Stack, Text, createStyles, useMantineTheme } from "@mantine/core";
import CalculatorButton from "./CalculatorButton";
import { scalarOrNull } from "../../utils/helpers";
import { useMediaQuery } from "@mantine/hooks";


const useStyles = createStyles((theme) => ({
  label: {
    color: theme.colors.gray[9],
    lineHeight: 1,
    // fontWeight: 500,
  },
  detail: {
    fontSize: "0.9rem",
    fontWeight: 400,
    color: theme.colors.gray[6],
    textAlign: "right",
    marginLeft: "auto"
  },
  buttonInnerGroup: {
    width: "100%"
  }
}));


export interface IChoiceData {
  value: string | number | null
  label: string
  detail?: string
  selected: boolean
  onClick: (data: IChoiceData) => void
}


export interface IMultipleChoiceProps {
  // exclusive: boolean,
  orientation?: "vertical" | "horizontal"
  choices: IChoiceData[]
  customInputElement?: JSX.Element
  loading?: boolean
}


// Check that (1) the user has specified a value and (2) none of the choices match the user's.
export function isCustomNumericOptionSelected(
  choices: IChoiceData[], userValue: string | number | null | undefined, tol: number = 0.1
) {
  const userValueDefined = scalarOrNull(userValue);
  if (userValueDefined === null) {
    return false;
  }
  return choices.findIndex((choice) => {
    return (choice.value !== null) &&
           ((Math.abs((choice.value as number) - (userValueDefined as number))) < tol)
  }) < 0;
}


export function isCustomOptionSelected(choices: IChoiceData[], userValue: string | number | null | undefined) {
  const userValueDefined = scalarOrNull(userValue);
  return userValueDefined !== null && choices.findIndex((choice) => {
    return choice.value === userValueDefined;
  }) < 0;
}


export default function MultipleChoice(
  {
    orientation = "vertical",
    choices,
    customInputElement,
    loading,
  } : IMultipleChoiceProps
) {
  const {classes} = useStyles();
  const theme = useMantineTheme();

  const presetChoices = choices.map((choice) => (
    <CalculatorButton
      selected={choice.selected}
      onClick={() => choice.onClick(choice)}
      key={choice.value}
    >
      <Group className={classes.buttonInnerGroup}>
        <Text className={classes.label}>{choice.label}</Text>
        {
          choice.detail
            ? <Text className={classes.detail}>{choice.detail}</Text>
            : undefined
        }
      </Group>
    </CalculatorButton>
  ));

  const WrapperElement = orientation === "vertical" ? Stack : Group;
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <WrapperElement spacing={mobile ? "xs" : "xs"}>
      {
        loading ?
          <>
            <Skeleton h={"2.6rem"}/>
            <Skeleton h={"2.6rem"}/>
            <Skeleton h={"2.6rem"}/>
            <Skeleton h={"2.6rem"}/>
          </>
          :
        <>
          {presetChoices}
          {customInputElement ? customInputElement : undefined}
        </>
      }
    </WrapperElement>
  );
}
