const data = {
  "01001": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "AGAWAM"
  },
  "01002": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "AMHERST"
  },
  "01005": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BARRE"
  },
  "01007": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BELCHERTOWN"
  },
  "01008": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BLANDFORD"
  },
  "01010": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BRIMFIELD"
  },
  "01011": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CHESTER"
  },
  "01012": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CHESTERFIELD"
  },
  "01013": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CHICOPEE"
  },
  "01020": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CHICOPEE"
  },
  "01022": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CHICOPEE"
  },
  "01026": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CUMMINGTON"
  },
  "01027": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "EASTHAMPTON"
  },
  "01028": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "EAST LONGMEADOW"
  },
  "01029": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "EAST OTIS"
  },
  "01030": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "FEEDING HILLS"
  },
  "01031": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "GILBERTVILLE"
  },
  "01032": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "GOSHEN"
  },
  "01033": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "GRANBY"
  },
  "01034": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "GRANVILLE"
  },
  "01035": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HADLEY"
  },
  "01036": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HAMPDEN"
  },
  "01037": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HARDWICK"
  },
  "01038": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HATFIELD"
  },
  "01039": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HAYDENVILLE"
  },
  "01040": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HOLYOKE"
  },
  "01050": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HUNTINGTON"
  },
  "01053": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LEEDS"
  },
  "01054": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LEVERETT"
  },
  "01056": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LUDLOW"
  },
  "01057": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MONSON"
  },
  "01060": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTHAMPTON"
  },
  "01062": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "FLORENCE"
  },
  "01068": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "OAKHAM"
  },
  "01069": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "PALMER"
  },
  "01070": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "PLAINFIELD"
  },
  "01071": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "RUSSELL"
  },
  "01072": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SHUTESBURY"
  },
  "01073": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOUTHAMPTON"
  },
  "01075": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOUTH HADLEY"
  },
  "01077": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOUTHWICK"
  },
  "01080": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "THREE RIVERS"
  },
  "01081": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WALES"
  },
  "01082": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WARE"
  },
  "01083": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WARREN"
  },
  "01084": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST CHESTERFIELD"
  },
  "01085": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WESTFIELD"
  },
  "01088": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST HATFIELD"
  },
  "01089": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST SPRINGFIELD"
  },
  "01092": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST WARREN"
  },
  "01095": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WILBRAHAM"
  },
  "01096": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WILLIAMSBURG"
  },
  "01098": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WORTHINGTON"
  },
  "01103": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SPRINGFIELD"
  },
  "01104": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SPRINGFIELD"
  },
  "01105": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SPRINGFIELD"
  },
  "01106": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LONGMEADOW"
  },
  "01107": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SPRINGFIELD"
  },
  "01108": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SPRINGFIELD"
  },
  "01109": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SPRINGFIELD"
  },
  "01118": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SPRINGFIELD"
  },
  "01119": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SPRINGFIELD"
  },
  "01128": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SPRINGFIELD"
  },
  "01129": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SPRINGFIELD"
  },
  "01151": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "INDIAN ORCHARD"
  },
  "01201": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "PITTSFIELD"
  },
  "01220": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ADAMS"
  },
  "01222": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ASHLEY FALLS"
  },
  "01223": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BECKET"
  },
  "01225": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CHESHIRE"
  },
  "01226": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "DALTON"
  },
  "01230": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "GREAT BARRINGTON"
  },
  "01235": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HINSDALE"
  },
  "01236": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HOUSATONIC"
  },
  "01237": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LANESBORO"
  },
  "01238": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LEE"
  },
  "01240": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LENOX"
  },
  "01242": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LENOX DALE"
  },
  "01245": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MONTEREY"
  },
  "01247": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTH ADAMS"
  },
  "01253": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "OTIS"
  },
  "01254": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "RICHMOND"
  },
  "01255": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SANDISFIELD"
  },
  "01256": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SAVOY"
  },
  "01257": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SHEFFIELD"
  },
  "01258": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOUTH EGREMONT"
  },
  "01259": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOUTHFIELD"
  },
  "01262": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "STOCKBRIDGE"
  },
  "01264": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "TYRINGHAM"
  },
  "01266": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST STOCKBRIDGE"
  },
  "01267": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WILLIAMSTOWN"
  },
  "01301": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "GREENFIELD"
  },
  "01330": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ASHFIELD"
  },
  "01331": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ATHOL"
  },
  "01337": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BERNARDSTON"
  },
  "01338": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BUCKLAND"
  },
  "01339": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CHARLEMONT"
  },
  "01340": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "COLRAIN"
  },
  "01341": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CONWAY"
  },
  "01342": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "DEERFIELD"
  },
  "01344": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ERVING"
  },
  "01346": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HEATH"
  },
  "01349": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MILLERS FALLS"
  },
  "01350": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MONROE BRIDGE"
  },
  "01351": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MONTAGUE"
  },
  "01355": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NEW SALEM"
  },
  "01360": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTHFIELD"
  },
  "01364": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ORANGE"
  },
  "01366": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "PETERSHAM"
  },
  "01367": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ROWE"
  },
  "01368": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ROYALSTON"
  },
  "01370": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SHELBURNE FALLS"
  },
  "01373": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOUTH DEERFIELD"
  },
  "01375": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SUNDERLAND"
  },
  "01376": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "TURNERS FALLS"
  },
  "01378": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WARWICK"
  },
  "01379": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WENDELL"
  },
  "01380": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WENDELL DEPOT"
  },
  "01420": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "FITCHBURG"
  },
  "01430": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ASHBURNHAM"
  },
  "01431": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ASHBY"
  },
  "01432": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "AYER"
  },
  "01436": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BALDWINVILLE"
  },
  "01438": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "EAST TEMPLETON"
  },
  "01440": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "GARDNER"
  },
  "01450": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "GROTON"
  },
  "01451": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HARVARD"
  },
  "01452": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HUBBARDSTON"
  },
  "01453": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LEOMINSTER"
  },
  "01460": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LITTLETON"
  },
  "01462": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LUNENBURG"
  },
  "01463": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "PEPPERELL"
  },
  "01464": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SHIRLEY"
  },
  "01467": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "STILL RIVER"
  },
  "01468": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "TEMPLETON"
  },
  "01469": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "TOWNSEND"
  },
  "01473": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WESTMINSTER"
  },
  "01474": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST TOWNSEND"
  },
  "01475": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WINCHENDON"
  },
  "01501": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "AUBURN"
  },
  "01503": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BERLIN"
  },
  "01504": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BLACKSTONE"
  },
  "01505": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOYLSTON"
  },
  "01506": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BROOKFIELD"
  },
  "01507": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CHARLTON"
  },
  "01510": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CLINTON"
  },
  "01515": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "EAST BROOKFIELD"
  },
  "01516": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "DOUGLAS"
  },
  "01518": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "FISKDALE"
  },
  "01519": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "GRAFTON"
  },
  "01520": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HOLDEN"
  },
  "01521": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HOLLAND"
  },
  "01522": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "JEFFERSON"
  },
  "01523": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LANCASTER"
  },
  "01524": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LEICESTER"
  },
  "01527": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MILLBURY"
  },
  "01529": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MILLVILLE"
  },
  "01531": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NEW BRAINTREE"
  },
  "01532": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTHBOROUGH"
  },
  "01534": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTHBRIDGE"
  },
  "01535": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTH BROOKFIELD"
  },
  "01536": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTH GRAFTON"
  },
  "01537": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTH OXFORD"
  },
  "01540": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "OXFORD"
  },
  "01541": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "PRINCETON"
  },
  "01542": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ROCHDALE"
  },
  "01543": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "RUTLAND"
  },
  "01545": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SHREWSBURY"
  },
  "01550": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOUTHBRIDGE"
  },
  "01560": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOUTH GRAFTON"
  },
  "01562": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SPENCER"
  },
  "01564": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "STERLING"
  },
  "01566": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "STURBRIDGE"
  },
  "01568": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "UPTON"
  },
  "01569": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "UXBRIDGE"
  },
  "01570": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEBSTER"
  },
  "01571": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "DUDLEY"
  },
  "01581": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WESTBOROUGH"
  },
  "01583": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST BOYLSTON"
  },
  "01585": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST BROOKFIELD"
  },
  "01588": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WHITINSVILLE"
  },
  "01590": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SUTTON"
  },
  "01602": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WORCESTER"
  },
  "01603": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WORCESTER"
  },
  "01604": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WORCESTER"
  },
  "01605": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WORCESTER"
  },
  "01606": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WORCESTER"
  },
  "01607": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WORCESTER"
  },
  "01608": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WORCESTER"
  },
  "01609": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WORCESTER"
  },
  "01610": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WORCESTER"
  },
  "01611": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CHERRY VALLEY"
  },
  "01612": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "PAXTON"
  },
  "01701": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "FRAMINGHAM"
  },
  "01702": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "FRAMINGHAM"
  },
  "01718": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "VILLAGE OF NAGOG WOODS"
  },
  "01719": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOXBOROUGH"
  },
  "01720": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ACTON"
  },
  "01721": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ASHLAND"
  },
  "01730": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BEDFORD"
  },
  "01731": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HANSCOM AFB"
  },
  "01740": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOLTON"
  },
  "01741": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CARLISLE"
  },
  "01742": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CONCORD"
  },
  "01745": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "FAYVILLE"
  },
  "01746": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HOLLISTON"
  },
  "01747": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HOPEDALE"
  },
  "01748": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HOPKINTON"
  },
  "01749": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HUDSON"
  },
  "01752": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MARLBOROUGH"
  },
  "01754": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MAYNARD"
  },
  "01756": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MENDON"
  },
  "01757": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MILFORD"
  },
  "01760": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NATICK"
  },
  "01770": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SHERBORN"
  },
  "01772": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOUTHBOROUGH"
  },
  "01773": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LINCOLN"
  },
  "01775": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "STOW"
  },
  "01776": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SUDBURY"
  },
  "01778": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WAYLAND"
  },
  "01801": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WOBURN"
  },
  "01803": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BURLINGTON"
  },
  "01810": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ANDOVER"
  },
  "01821": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BILLERICA"
  },
  "01824": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CHELMSFORD"
  },
  "01826": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "DRACUT"
  },
  "01827": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "DUNSTABLE"
  },
  "01830": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HAVERHILL"
  },
  "01832": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HAVERHILL"
  },
  "01833": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "GEORGETOWN"
  },
  "01834": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "GROVELAND"
  },
  "01835": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HAVERHILL"
  },
  "01840": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LAWRENCE"
  },
  "01841": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LAWRENCE"
  },
  "01843": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LAWRENCE"
  },
  "01844": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "METHUEN"
  },
  "01845": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTH ANDOVER"
  },
  "01850": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LOWELL"
  },
  "01851": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LOWELL"
  },
  "01852": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LOWELL"
  },
  "01854": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LOWELL"
  },
  "01860": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MERRIMAC"
  },
  "01862": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTH BILLERICA"
  },
  "01863": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTH CHELMSFORD"
  },
  "01864": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTH READING"
  },
  "01867": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "READING"
  },
  "01876": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "TEWKSBURY"
  },
  "01879": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "TYNGSBORO"
  },
  "01880": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WAKEFIELD"
  },
  "01886": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WESTFORD"
  },
  "01887": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WILMINGTON"
  },
  "01890": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WINCHESTER"
  },
  "01902": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LYNN"
  },
  "01904": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LYNN"
  },
  "01905": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LYNN"
  },
  "01906": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SAUGUS"
  },
  "01907": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SWAMPSCOTT"
  },
  "01908": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NAHANT"
  },
  "01913": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "AMESBURY"
  },
  "01915": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BEVERLY"
  },
  "01921": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOXFORD"
  },
  "01922": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BYFIELD"
  },
  "01923": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "DANVERS"
  },
  "01929": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ESSEX"
  },
  "01930": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "GLOUCESTER"
  },
  "01938": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "IPSWICH"
  },
  "01940": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LYNNFIELD"
  },
  "01944": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MANCHESTER"
  },
  "01945": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MARBLEHEAD"
  },
  "01949": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MIDDLETON"
  },
  "01950": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NEWBURYPORT"
  },
  "01951": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NEWBURY"
  },
  "01952": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SALISBURY"
  },
  "01960": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "PEABODY"
  },
  "01966": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ROCKPORT"
  },
  "01969": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ROWLEY"
  },
  "01970": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SALEM"
  },
  "01982": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOUTH HAMILTON"
  },
  "01983": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "TOPSFIELD"
  },
  "01984": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WENHAM"
  },
  "01985": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST NEWBURY"
  },
  "02019": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BELLINGHAM"
  },
  "02021": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CANTON"
  },
  "02025": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "COHASSET"
  },
  "02026": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "DEDHAM"
  },
  "02030": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "DOVER"
  },
  "02032": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "EAST WALPOLE"
  },
  "02035": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "FOXBORO"
  },
  "02038": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "FRANKLIN"
  },
  "02043": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HINGHAM"
  },
  "02045": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HULL"
  },
  "02048": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MANSFIELD"
  },
  "02050": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MARSHFIELD"
  },
  "02052": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MEDFIELD"
  },
  "02053": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MEDWAY"
  },
  "02054": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MILLIS"
  },
  "02056": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORFOLK"
  },
  "02061": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORWELL"
  },
  "02062": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORWOOD"
  },
  "02066": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SCITUATE"
  },
  "02067": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SHARON"
  },
  "02071": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOUTH WALPOLE"
  },
  "02072": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "STOUGHTON"
  },
  "02081": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WALPOLE"
  },
  "02090": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WESTWOOD"
  },
  "02093": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WRENTHAM"
  },
  "02108": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02109": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02110": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02111": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02113": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02114": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02115": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02116": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02118": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02119": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02120": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02121": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02122": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02124": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02125": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02126": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MATTAPAN"
  },
  "02127": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02128": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02129": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CHARLESTOWN"
  },
  "02130": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "JAMAICA PLAIN"
  },
  "02131": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ROSLINDALE"
  },
  "02132": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST ROXBURY"
  },
  "02134": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ALLSTON"
  },
  "02135": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BRIGHTON"
  },
  "02136": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HYDE PARK"
  },
  "02138": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CAMBRIDGE"
  },
  "02139": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CAMBRIDGE"
  },
  "02140": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CAMBRIDGE"
  },
  "02141": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CAMBRIDGE"
  },
  "02142": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CAMBRIDGE"
  },
  "02143": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOMERVILLE"
  },
  "02144": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOMERVILLE"
  },
  "02145": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOMERVILLE"
  },
  "02148": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MALDEN"
  },
  "02149": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "EVERETT"
  },
  "02150": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CHELSEA"
  },
  "02151": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "REVERE"
  },
  "02152": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WINTHROP"
  },
  "02155": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MEDFORD"
  },
  "02163": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02169": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "QUINCY"
  },
  "02170": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "QUINCY"
  },
  "02171": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "QUINCY"
  },
  "02176": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MELROSE"
  },
  "02180": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "STONEHAM"
  },
  "02184": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BRAINTREE"
  },
  "02186": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MILTON"
  },
  "02188": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEYMOUTH"
  },
  "02189": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "EAST WEYMOUTH"
  },
  "02190": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOUTH WEYMOUTH"
  },
  "02191": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTH WEYMOUTH"
  },
  "02199": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02210": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02215": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BOSTON"
  },
  "02301": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BROCKTON"
  },
  "02302": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BROCKTON"
  },
  "02322": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "AVON"
  },
  "02324": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BRIDGEWATER"
  },
  "02330": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CARVER"
  },
  "02332": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "DUXBURY"
  },
  "02333": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "EAST BRIDGEWATER"
  },
  "02338": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HALIFAX"
  },
  "02339": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HANOVER"
  },
  "02341": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HANSON"
  },
  "02343": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HOLBROOK"
  },
  "02346": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MIDDLEBORO"
  },
  "02347": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LAKEVILLE"
  },
  "02351": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ABINGTON"
  },
  "02356": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTH EASTON"
  },
  "02358": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTH PEMBROKE"
  },
  "02359": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "PEMBROKE"
  },
  "02360": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "PLYMOUTH"
  },
  "02364": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "KINGSTON"
  },
  "02367": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "PLYMPTON"
  },
  "02368": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "RANDOLPH"
  },
  "02370": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ROCKLAND"
  },
  "02375": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOUTH EASTON"
  },
  "02379": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST BRIDGEWATER"
  },
  "02382": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WHITMAN"
  },
  "02420": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LEXINGTON"
  },
  "02421": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "LEXINGTON"
  },
  "02445": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BROOKLINE"
  },
  "02446": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BROOKLINE"
  },
  "02451": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WALTHAM"
  },
  "02452": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WALTHAM"
  },
  "02453": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WALTHAM"
  },
  "02458": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NEWTON"
  },
  "02459": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NEWTON CENTER"
  },
  "02460": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NEWTONVILLE"
  },
  "02461": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NEWTON HIGHLANDS"
  },
  "02462": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NEWTON LOWER FALLS"
  },
  "02464": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NEWTON UPPER FALLS"
  },
  "02465": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST NEWTON"
  },
  "02466": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "AUBURNDALE"
  },
  "02467": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CHESTNUT HILL"
  },
  "02468": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WABAN"
  },
  "02472": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WATERTOWN"
  },
  "02474": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ARLINGTON"
  },
  "02476": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ARLINGTON"
  },
  "02478": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BELMONT"
  },
  "02481": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WELLESLEY HILLS"
  },
  "02482": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WELLESLEY"
  },
  "02492": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NEEDHAM"
  },
  "02493": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WESTON"
  },
  "02494": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NEEDHAM HEIGHTS"
  },
  "02532": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BUZZARDS BAY"
  },
  "02534": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CATAUMET"
  },
  "02535": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CHILMARK"
  },
  "02536": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "EAST FALMOUTH"
  },
  "02537": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "EAST SANDWICH"
  },
  "02538": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "EAST WAREHAM"
  },
  "02539": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "EDGARTOWN"
  },
  "02540": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "FALMOUTH"
  },
  "02542": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BUZZARDS BAY"
  },
  "02543": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WOODS HOLE"
  },
  "02554": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NANTUCKET"
  },
  "02556": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTH FALMOUTH"
  },
  "02558": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ONSET"
  },
  "02559": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "POCASSET"
  },
  "02563": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SANDWICH"
  },
  "02568": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "VINEYARD HAVEN"
  },
  "02571": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WAREHAM"
  },
  "02575": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST TISBURY"
  },
  "02576": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST WAREHAM"
  },
  "02601": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HYANNIS"
  },
  "02630": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BARNSTABLE"
  },
  "02631": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BREWSTER"
  },
  "02632": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CENTERVILLE"
  },
  "02633": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CHATHAM"
  },
  "02635": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "COTUIT"
  },
  "02638": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "DENNIS"
  },
  "02639": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "DENNIS PORT"
  },
  "02642": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "EASTHAM"
  },
  "02644": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "FORESTDALE"
  },
  "02645": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HARWICH"
  },
  "02646": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HARWICH PORT"
  },
  "02647": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "HYANNIS PORT"
  },
  "02648": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MARSTONS MILLS"
  },
  "02649": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MASHPEE"
  },
  "02650": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTH CHATHAM"
  },
  "02652": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTH TRURO"
  },
  "02653": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ORLEANS"
  },
  "02655": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "OSTERVILLE"
  },
  "02657": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "PROVINCETOWN"
  },
  "02659": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOUTH CHATHAM"
  },
  "02660": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOUTH DENNIS"
  },
  "02661": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOUTH HARWICH"
  },
  "02664": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOUTH YARMOUTH"
  },
  "02666": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "TRURO"
  },
  "02667": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WELLFLEET"
  },
  "02668": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST BARNSTABLE"
  },
  "02669": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST CHATHAM"
  },
  "02670": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST DENNIS"
  },
  "02671": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST HARWICH"
  },
  "02672": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST HYANNISPORT"
  },
  "02673": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WEST YARMOUTH"
  },
  "02675": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "YARMOUTH PORT"
  },
  "02702": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ASSONET"
  },
  "02703": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ATTLEBORO"
  },
  "02713": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "CUTTYHUNK"
  },
  "02715": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "DIGHTON"
  },
  "02717": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "EAST FREETOWN"
  },
  "02718": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "EAST TAUNTON"
  },
  "02719": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "FAIRHAVEN"
  },
  "02720": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "FALL RIVER"
  },
  "02721": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "FALL RIVER"
  },
  "02723": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "FALL RIVER"
  },
  "02724": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "FALL RIVER"
  },
  "02725": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOMERSET"
  },
  "02726": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOMERSET"
  },
  "02738": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MARION"
  },
  "02739": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "MATTAPOISETT"
  },
  "02740": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NEW BEDFORD"
  },
  "02743": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ACUSHNET"
  },
  "02744": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NEW BEDFORD"
  },
  "02745": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NEW BEDFORD"
  },
  "02746": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NEW BEDFORD"
  },
  "02747": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTH DARTMOUTH"
  },
  "02748": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SOUTH DARTMOUTH"
  },
  "02760": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTH ATTLEBORO"
  },
  "02762": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "PLAINVILLE"
  },
  "02763": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ATTLEBORO FALLS"
  },
  "02764": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTH DIGHTON"
  },
  "02766": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "NORTON"
  },
  "02767": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "RAYNHAM"
  },
  "02769": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "REHOBOTH"
  },
  "02770": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "ROCHESTER"
  },
  "02771": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SEEKONK"
  },
  "02777": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "SWANSEA"
  },
  "02779": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "BERKLEY"
  },
  "02780": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "TAUNTON"
  },
  "02790": {
    stateCode: "MA",
    stateName: "Massachusetts",
    city: "WESTPORT"
  },
  "02804": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "ASHAWAY"
  },
  "02806": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "BARRINGTON"
  },
  "02807": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "BLOCK ISLAND"
  },
  "02808": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "BRADFORD"
  },
  "02809": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "BRISTOL"
  },
  "02812": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "CAROLINA"
  },
  "02813": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "CHARLESTOWN"
  },
  "02814": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "CHEPACHET"
  },
  "02815": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "CLAYVILLE"
  },
  "02816": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "COVENTRY"
  },
  "02817": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "WEST GREENWICH"
  },
  "02818": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "EAST GREENWICH"
  },
  "02822": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "EXETER"
  },
  "02825": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "FOSTER"
  },
  "02827": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "GREENE"
  },
  "02828": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "GREENVILLE"
  },
  "02830": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "HARRISVILLE"
  },
  "02831": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "HOPE"
  },
  "02832": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "HOPE VALLEY"
  },
  "02833": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "HOPKINTON"
  },
  "02835": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "JAMESTOWN"
  },
  "02836": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "KENYON"
  },
  "02837": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "LITTLE COMPTON"
  },
  "02838": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "MANVILLE"
  },
  "02839": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "MAPLEVILLE"
  },
  "02840": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "NEWPORT"
  },
  "02842": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "MIDDLETOWN"
  },
  "02852": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "NORTH KINGSTOWN"
  },
  "02857": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "NORTH SCITUATE"
  },
  "02858": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "OAKLAND"
  },
  "02859": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "PASCOAG"
  },
  "02860": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "PAWTUCKET"
  },
  "02861": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "PAWTUCKET"
  },
  "02863": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "CENTRAL FALLS"
  },
  "02864": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "CUMBERLAND"
  },
  "02865": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "LINCOLN"
  },
  "02871": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "PORTSMOUTH"
  },
  "02874": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "SAUNDERSTOWN"
  },
  "02875": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "SHANNOCK"
  },
  "02878": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "TIVERTON"
  },
  "02879": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "WAKEFIELD"
  },
  "02881": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "KINGSTON"
  },
  "02882": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "NARRAGANSETT"
  },
  "02885": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "WARREN"
  },
  "02886": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "WARWICK"
  },
  "02888": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "WARWICK"
  },
  "02889": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "WARWICK"
  },
  "02891": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "WESTERLY"
  },
  "02892": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "WEST KINGSTON"
  },
  "02893": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "WEST WARWICK"
  },
  "02894": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "WOOD RIVER JUNCTION"
  },
  "02895": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "WOONSOCKET"
  },
  "02896": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "NORTH SMITHFIELD"
  },
  "02898": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "WYOMING"
  },
  "02903": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "PROVIDENCE"
  },
  "02904": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "PROVIDENCE"
  },
  "02905": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "PROVIDENCE"
  },
  "02906": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "PROVIDENCE"
  },
  "02907": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "PROVIDENCE"
  },
  "02908": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "PROVIDENCE"
  },
  "02909": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "PROVIDENCE"
  },
  "02910": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "CRANSTON"
  },
  "02911": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "NORTH PROVIDENCE"
  },
  "02914": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "EAST PROVIDENCE"
  },
  "02915": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "RIVERSIDE"
  },
  "02916": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "RUMFORD"
  },
  "02917": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "SMITHFIELD"
  },
  "02919": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "JOHNSTON"
  },
  "02920": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "CRANSTON"
  },
  "02921": {
    stateCode: "RI",
    stateName: "Rhode Island",
    city: "CRANSTON"
  },
  "03031": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "AMHERST"
  },
  "03032": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "AUBURN"
  },
  "03033": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "BROOKLINE"
  },
  "03034": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CANDIA"
  },
  "03036": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CHESTER"
  },
  "03037": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "DEERFIELD"
  },
  "03038": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "DERRY"
  },
  "03042": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "EPPING"
  },
  "03043": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "FRANCESTOWN"
  },
  "03044": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "FREMONT"
  },
  "03045": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "GOFFSTOWN"
  },
  "03047": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "GREENFIELD"
  },
  "03048": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "GREENVILLE"
  },
  "03049": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "HOLLIS"
  },
  "03051": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "HUDSON"
  },
  "03052": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "LITCHFIELD"
  },
  "03053": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "LONDONDERRY"
  },
  "03054": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "MERRIMACK"
  },
  "03055": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "MILFORD"
  },
  "03057": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "MONT VERNON"
  },
  "03060": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NASHUA"
  },
  "03062": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NASHUA"
  },
  "03063": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NASHUA"
  },
  "03064": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NASHUA"
  },
  "03070": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NEW BOSTON"
  },
  "03071": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NEW IPSWICH"
  },
  "03076": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "PELHAM"
  },
  "03077": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "RAYMOND"
  },
  "03079": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "SALEM"
  },
  "03082": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "LYNDEBOROUGH"
  },
  "03084": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "TEMPLE"
  },
  "03086": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WILTON"
  },
  "03087": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WINDHAM"
  },
  "03101": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "MANCHESTER"
  },
  "03102": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "MANCHESTER"
  },
  "03103": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "MANCHESTER"
  },
  "03104": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "MANCHESTER"
  },
  "03106": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "HOOKSETT"
  },
  "03109": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "MANCHESTER"
  },
  "03110": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "BEDFORD"
  },
  "03215": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WATERVILLE VALLEY"
  },
  "03216": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "ANDOVER"
  },
  "03217": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "ASHLAND"
  },
  "03218": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "BARNSTEAD"
  },
  "03220": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "BELMONT"
  },
  "03221": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "BRADFORD"
  },
  "03222": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "BRISTOL"
  },
  "03223": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CAMPTON"
  },
  "03224": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CANTERBURY"
  },
  "03225": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CENTER BARNSTEAD"
  },
  "03226": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CENTER HARBOR"
  },
  "03227": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CENTER SANDWICH"
  },
  "03229": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CONTOOCOOK"
  },
  "03230": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "DANBURY"
  },
  "03233": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "ELKINS"
  },
  "03234": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "EPSOM"
  },
  "03235": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "FRANKLIN"
  },
  "03237": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "GILMANTON"
  },
  "03240": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "GRAFTON"
  },
  "03241": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "HEBRON"
  },
  "03242": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "HENNIKER"
  },
  "03243": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "HILL"
  },
  "03244": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "HILLSBOROUGH"
  },
  "03246": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "LACONIA"
  },
  "03251": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "LINCOLN"
  },
  "03253": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "MEREDITH"
  },
  "03254": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "MOULTONBOROUGH"
  },
  "03255": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NEWBURY"
  },
  "03256": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NEW HAMPTON"
  },
  "03257": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NEW LONDON"
  },
  "03259": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NORTH SANDWICH"
  },
  "03260": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NORTH SUTTON"
  },
  "03261": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NORTHWOOD"
  },
  "03262": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NORTH WOODSTOCK"
  },
  "03263": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "PITTSFIELD"
  },
  "03264": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "PLYMOUTH"
  },
  "03266": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "RUMNEY"
  },
  "03268": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "SALISBURY"
  },
  "03269": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "SANBORNTON"
  },
  "03273": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "SOUTH SUTTON"
  },
  "03275": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "SUNCOOK"
  },
  "03276": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "TILTON"
  },
  "03278": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WARNER"
  },
  "03279": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WARREN"
  },
  "03280": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WASHINGTON"
  },
  "03281": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WEARE"
  },
  "03282": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WENTWORTH"
  },
  "03284": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "SPRINGFIELD"
  },
  "03287": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WILMOT"
  },
  "03290": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NOTTINGHAM"
  },
  "03291": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WEST NOTTINGHAM"
  },
  "03301": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CONCORD"
  },
  "03303": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CONCORD"
  },
  "03304": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "BOW"
  },
  "03307": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "LOUDON"
  },
  "03431": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "KEENE"
  },
  "03440": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "ANTRIM"
  },
  "03441": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "ASHUELOT"
  },
  "03442": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "BENNINGTON"
  },
  "03443": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CHESTERFIELD"
  },
  "03444": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "DUBLIN"
  },
  "03445": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "SULLIVAN"
  },
  "03446": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "SWANZEY"
  },
  "03447": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "FITZWILLIAM"
  },
  "03448": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "GILSUM"
  },
  "03449": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "HANCOCK"
  },
  "03450": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "HARRISVILLE"
  },
  "03451": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "HINSDALE"
  },
  "03452": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "JAFFREY"
  },
  "03455": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "MARLBOROUGH"
  },
  "03456": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "MARLOW"
  },
  "03457": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NELSON"
  },
  "03458": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "PETERBOROUGH"
  },
  "03461": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "RINDGE"
  },
  "03462": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "SPOFFORD"
  },
  "03464": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "STODDARD"
  },
  "03465": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "TROY"
  },
  "03466": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WEST CHESTERFIELD"
  },
  "03467": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WESTMORELAND"
  },
  "03470": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WINCHESTER"
  },
  "03561": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "LITTLETON"
  },
  "03570": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "BERLIN"
  },
  "03574": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "BETHLEHEM"
  },
  "03576": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "COLEBROOK"
  },
  "03579": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "ERROL"
  },
  "03580": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "FRANCONIA"
  },
  "03581": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "GORHAM"
  },
  "03582": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "GROVETON"
  },
  "03583": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "JEFFERSON"
  },
  "03584": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "LANCASTER"
  },
  "03585": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "LISBON"
  },
  "03588": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "MILAN"
  },
  "03590": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NORTH STRATFORD"
  },
  "03592": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "PITTSBURG"
  },
  "03595": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "TWIN MOUNTAIN"
  },
  "03598": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WHITEFIELD"
  },
  "03601": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "ACWORTH"
  },
  "03602": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "ALSTEAD"
  },
  "03603": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CHARLESTOWN"
  },
  "03605": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "LEMPSTER"
  },
  "03607": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "SOUTH ACWORTH"
  },
  "03608": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WALPOLE"
  },
  "03609": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NORTH WALPOLE"
  },
  "03741": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CANAAN"
  },
  "03743": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CLAREMONT"
  },
  "03745": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CORNISH"
  },
  "03748": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "ENFIELD"
  },
  "03750": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "ETNA"
  },
  "03751": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "GEORGES MILLS"
  },
  "03752": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "GOSHEN"
  },
  "03753": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "GRANTHAM"
  },
  "03755": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "HANOVER"
  },
  "03765": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "HAVERHILL"
  },
  "03766": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "LEBANON"
  },
  "03768": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "LYME"
  },
  "03770": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "MERIDEN"
  },
  "03771": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "MONROE"
  },
  "03773": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NEWPORT"
  },
  "03774": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NORTH HAVERHILL"
  },
  "03777": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "ORFORD"
  },
  "03779": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "PIERMONT"
  },
  "03780": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "PIKE"
  },
  "03781": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "PLAINFIELD"
  },
  "03782": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "SUNAPEE"
  },
  "03784": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WEST LEBANON"
  },
  "03785": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WOODSVILLE"
  },
  "03801": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "PORTSMOUTH"
  },
  "03809": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "ALTON"
  },
  "03810": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "ALTON BAY"
  },
  "03811": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "ATKINSON"
  },
  "03812": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "BARTLETT"
  },
  "03813": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CENTER CONWAY"
  },
  "03814": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CENTER OSSIPEE"
  },
  "03815": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CENTER STRAFFORD"
  },
  "03816": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CENTER TUFTONBORO"
  },
  "03817": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CHOCORUA"
  },
  "03818": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "CONWAY"
  },
  "03819": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "DANVILLE"
  },
  "03820": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "DOVER"
  },
  "03824": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "DURHAM"
  },
  "03825": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "BARRINGTON"
  },
  "03826": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "EAST HAMPSTEAD"
  },
  "03827": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "EAST KINGSTON"
  },
  "03830": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "EAST WAKEFIELD"
  },
  "03832": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "EATON CENTER"
  },
  "03833": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "EXETER"
  },
  "03835": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "FARMINGTON"
  },
  "03836": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "FREEDOM"
  },
  "03837": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "GILMANTON IRON WORKS"
  },
  "03838": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "GLEN"
  },
  "03839": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "ROCHESTER"
  },
  "03840": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "GREENLAND"
  },
  "03841": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "HAMPSTEAD"
  },
  "03842": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "HAMPTON"
  },
  "03844": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "HAMPTON FALLS"
  },
  "03845": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "INTERVALE"
  },
  "03846": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "JACKSON"
  },
  "03848": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "KINGSTON"
  },
  "03849": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "MADISON"
  },
  "03852": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "MILTON MILLS"
  },
  "03853": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "MIRROR LAKE"
  },
  "03854": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NEW CASTLE"
  },
  "03855": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NEW DURHAM"
  },
  "03856": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NEWFIELDS"
  },
  "03857": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NEWMARKET"
  },
  "03858": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NEWTON"
  },
  "03860": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NORTH CONWAY"
  },
  "03862": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "NORTH HAMPTON"
  },
  "03864": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "OSSIPEE"
  },
  "03865": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "PLAISTOW"
  },
  "03867": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "ROCHESTER"
  },
  "03868": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "ROCHESTER"
  },
  "03869": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "ROLLINSFORD"
  },
  "03870": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "RYE"
  },
  "03872": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "SANBORNVILLE"
  },
  "03873": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "SANDOWN"
  },
  "03874": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "SEABROOK"
  },
  "03875": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "SILVER LAKE"
  },
  "03878": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "SOMERSWORTH"
  },
  "03882": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "EFFINGHAM"
  },
  "03883": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "SOUTH TAMWORTH"
  },
  "03884": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "STRAFFORD"
  },
  "03885": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "STRATHAM"
  },
  "03886": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "TAMWORTH"
  },
  "03887": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "UNION"
  },
  "03894": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WOLFEBORO"
  },
  "03897": {
    stateCode: "NH",
    stateName: "New Hampshire",
    city: "WONALANCET"
  },
  "03901": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BERWICK"
  },
  "03902": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CAPE NEDDICK"
  },
  "03903": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ELIOT"
  },
  "03904": {
    stateCode: "ME",
    stateName: "Maine",
    city: "KITTERY"
  },
  "03905": {
    stateCode: "ME",
    stateName: "Maine",
    city: "KITTERY POINT"
  },
  "03906": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NORTH BERWICK"
  },
  "03907": {
    stateCode: "ME",
    stateName: "Maine",
    city: "OGUNQUIT"
  },
  "03908": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SOUTH BERWICK"
  },
  "03909": {
    stateCode: "ME",
    stateName: "Maine",
    city: "YORK"
  },
  "04001": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ACTON"
  },
  "04002": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ALFRED"
  },
  "04003": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BAILEY ISLAND"
  },
  "04005": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BIDDEFORD"
  },
  "04008": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BOWDOINHAM"
  },
  "04009": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BRIDGTON"
  },
  "04010": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BROWNFIELD"
  },
  "04011": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BRUNSWICK"
  },
  "04015": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CASCO"
  },
  "04017": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CHEBEAGUE ISLAND"
  },
  "04019": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CLIFF ISLAND"
  },
  "04020": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CORNISH"
  },
  "04021": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CUMBERLAND CENTER"
  },
  "04022": {
    stateCode: "ME",
    stateName: "Maine",
    city: "DENMARK"
  },
  "04024": {
    stateCode: "ME",
    stateName: "Maine",
    city: "EAST BALDWIN"
  },
  "04027": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LEBANON"
  },
  "04029": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SEBAGO"
  },
  "04030": {
    stateCode: "ME",
    stateName: "Maine",
    city: "EAST WATERBORO"
  },
  "04032": {
    stateCode: "ME",
    stateName: "Maine",
    city: "FREEPORT"
  },
  "04037": {
    stateCode: "ME",
    stateName: "Maine",
    city: "FRYEBURG"
  },
  "04038": {
    stateCode: "ME",
    stateName: "Maine",
    city: "GORHAM"
  },
  "04039": {
    stateCode: "ME",
    stateName: "Maine",
    city: "GRAY"
  },
  "04040": {
    stateCode: "ME",
    stateName: "Maine",
    city: "HARRISON"
  },
  "04041": {
    stateCode: "ME",
    stateName: "Maine",
    city: "HIRAM"
  },
  "04042": {
    stateCode: "ME",
    stateName: "Maine",
    city: "HOLLIS CENTER"
  },
  "04043": {
    stateCode: "ME",
    stateName: "Maine",
    city: "KENNEBUNK"
  },
  "04046": {
    stateCode: "ME",
    stateName: "Maine",
    city: "KENNEBUNKPORT"
  },
  "04047": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PARSONSFIELD"
  },
  "04048": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LIMERICK"
  },
  "04049": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LIMINGTON"
  },
  "04050": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LONG ISLAND"
  },
  "04051": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LOVELL"
  },
  "04055": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NAPLES"
  },
  "04061": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NORTH WATERBORO"
  },
  "04062": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WINDHAM"
  },
  "04064": {
    stateCode: "ME",
    stateName: "Maine",
    city: "OLD ORCHARD BEACH"
  },
  "04066": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ORRS ISLAND"
  },
  "04068": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PORTER"
  },
  "04069": {
    stateCode: "ME",
    stateName: "Maine",
    city: "POWNAL"
  },
  "04071": {
    stateCode: "ME",
    stateName: "Maine",
    city: "RAYMOND"
  },
  "04072": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SACO"
  },
  "04073": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SANFORD"
  },
  "04074": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SCARBOROUGH"
  },
  "04076": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SHAPLEIGH"
  },
  "04079": {
    stateCode: "ME",
    stateName: "Maine",
    city: "HARPSWELL"
  },
  "04083": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SPRINGVALE"
  },
  "04084": {
    stateCode: "ME",
    stateName: "Maine",
    city: "STANDISH"
  },
  "04085": {
    stateCode: "ME",
    stateName: "Maine",
    city: "STEEP FALLS"
  },
  "04086": {
    stateCode: "ME",
    stateName: "Maine",
    city: "TOPSHAM"
  },
  "04087": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WATERBORO"
  },
  "04088": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WATERFORD"
  },
  "04090": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WELLS"
  },
  "04091": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WEST BALDWIN"
  },
  "04092": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WESTBROOK"
  },
  "04093": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BUXTON"
  },
  "04095": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WEST NEWFIELD"
  },
  "04096": {
    stateCode: "ME",
    stateName: "Maine",
    city: "YARMOUTH"
  },
  "04097": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NORTH YARMOUTH"
  },
  "04101": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PORTLAND"
  },
  "04102": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PORTLAND"
  },
  "04103": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PORTLAND"
  },
  "04105": {
    stateCode: "ME",
    stateName: "Maine",
    city: "FALMOUTH"
  },
  "04106": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SOUTH PORTLAND"
  },
  "04107": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CAPE ELIZABETH"
  },
  "04108": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PEAKS ISLAND"
  },
  "04110": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CUMBERLAND FORESIDE"
  },
  "04210": {
    stateCode: "ME",
    stateName: "Maine",
    city: "AUBURN"
  },
  "04216": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ANDOVER"
  },
  "04217": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BETHEL"
  },
  "04219": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BRYANT POND"
  },
  "04220": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BUCKFIELD"
  },
  "04221": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CANTON"
  },
  "04222": {
    stateCode: "ME",
    stateName: "Maine",
    city: "DURHAM"
  },
  "04224": {
    stateCode: "ME",
    stateName: "Maine",
    city: "DIXFIELD"
  },
  "04226": {
    stateCode: "ME",
    stateName: "Maine",
    city: "EAST ANDOVER"
  },
  "04228": {
    stateCode: "ME",
    stateName: "Maine",
    city: "EAST LIVERMORE"
  },
  "04231": {
    stateCode: "ME",
    stateName: "Maine",
    city: "STONEHAM"
  },
  "04236": {
    stateCode: "ME",
    stateName: "Maine",
    city: "GREENE"
  },
  "04237": {
    stateCode: "ME",
    stateName: "Maine",
    city: "HANOVER"
  },
  "04238": {
    stateCode: "ME",
    stateName: "Maine",
    city: "HEBRON"
  },
  "04239": {
    stateCode: "ME",
    stateName: "Maine",
    city: "JAY"
  },
  "04240": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LEWISTON"
  },
  "04250": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LISBON"
  },
  "04252": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LISBON FALLS"
  },
  "04253": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LIVERMORE"
  },
  "04254": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LIVERMORE FALLS"
  },
  "04255": {
    stateCode: "ME",
    stateName: "Maine",
    city: "GREENWOOD"
  },
  "04256": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MECHANIC FALLS"
  },
  "04257": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MEXICO"
  },
  "04258": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MINOT"
  },
  "04259": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MONMOUTH"
  },
  "04260": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NEW GLOUCESTER"
  },
  "04261": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NEWRY"
  },
  "04263": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LEEDS"
  },
  "04265": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NORTH MONMOUTH"
  },
  "04268": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NORWAY"
  },
  "04270": {
    stateCode: "ME",
    stateName: "Maine",
    city: "OXFORD"
  },
  "04274": {
    stateCode: "ME",
    stateName: "Maine",
    city: "POLAND"
  },
  "04275": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ROXBURY"
  },
  "04276": {
    stateCode: "ME",
    stateName: "Maine",
    city: "RUMFORD"
  },
  "04280": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SABATTUS"
  },
  "04281": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SOUTH PARIS"
  },
  "04282": {
    stateCode: "ME",
    stateName: "Maine",
    city: "TURNER"
  },
  "04284": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WAYNE"
  },
  "04285": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WELD"
  },
  "04287": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BOWDOIN"
  },
  "04289": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WEST PARIS"
  },
  "04290": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PERU"
  },
  "04292": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SUMNER"
  },
  "04294": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WILTON"
  },
  "04330": {
    stateCode: "ME",
    stateName: "Maine",
    city: "AUGUSTA"
  },
  "04342": {
    stateCode: "ME",
    stateName: "Maine",
    city: "DRESDEN"
  },
  "04344": {
    stateCode: "ME",
    stateName: "Maine",
    city: "FARMINGDALE"
  },
  "04345": {
    stateCode: "ME",
    stateName: "Maine",
    city: "GARDINER"
  },
  "04346": {
    stateCode: "ME",
    stateName: "Maine",
    city: "RANDOLPH"
  },
  "04347": {
    stateCode: "ME",
    stateName: "Maine",
    city: "HALLOWELL"
  },
  "04348": {
    stateCode: "ME",
    stateName: "Maine",
    city: "JEFFERSON"
  },
  "04349": {
    stateCode: "ME",
    stateName: "Maine",
    city: "KENTS HILL"
  },
  "04350": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LITCHFIELD"
  },
  "04351": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MANCHESTER"
  },
  "04352": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MOUNT VERNON"
  },
  "04353": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WHITEFIELD"
  },
  "04354": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PALERMO"
  },
  "04355": {
    stateCode: "ME",
    stateName: "Maine",
    city: "READFIELD"
  },
  "04357": {
    stateCode: "ME",
    stateName: "Maine",
    city: "RICHMOND"
  },
  "04358": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SOUTH CHINA"
  },
  "04360": {
    stateCode: "ME",
    stateName: "Maine",
    city: "VIENNA"
  },
  "04363": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WINDSOR"
  },
  "04364": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WINTHROP"
  },
  "04401": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BANGOR"
  },
  "04406": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ABBOT"
  },
  "04408": {
    stateCode: "ME",
    stateName: "Maine",
    city: "AURORA"
  },
  "04410": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BRADFORD"
  },
  "04411": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BRADLEY"
  },
  "04412": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BREWER"
  },
  "04413": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BROOKTON"
  },
  "04414": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BROWNVILLE"
  },
  "04416": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BUCKSPORT"
  },
  "04417": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BURLINGTON"
  },
  "04418": {
    stateCode: "ME",
    stateName: "Maine",
    city: "GREENBUSH"
  },
  "04419": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CARMEL"
  },
  "04421": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CASTINE"
  },
  "04422": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CHARLESTON"
  },
  "04424": {
    stateCode: "ME",
    stateName: "Maine",
    city: "DANFORTH"
  },
  "04426": {
    stateCode: "ME",
    stateName: "Maine",
    city: "DOVER FOXCROFT"
  },
  "04427": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CORINTH"
  },
  "04428": {
    stateCode: "ME",
    stateName: "Maine",
    city: "EDDINGTON"
  },
  "04429": {
    stateCode: "ME",
    stateName: "Maine",
    city: "HOLDEN"
  },
  "04430": {
    stateCode: "ME",
    stateName: "Maine",
    city: "EAST MILLINOCKET"
  },
  "04431": {
    stateCode: "ME",
    stateName: "Maine",
    city: "EAST ORLAND"
  },
  "04434": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ETNA"
  },
  "04435": {
    stateCode: "ME",
    stateName: "Maine",
    city: "EXETER"
  },
  "04438": {
    stateCode: "ME",
    stateName: "Maine",
    city: "FRANKFORT"
  },
  "04441": {
    stateCode: "ME",
    stateName: "Maine",
    city: "GREENVILLE"
  },
  "04442": {
    stateCode: "ME",
    stateName: "Maine",
    city: "GREENVILLE JUNCTION"
  },
  "04443": {
    stateCode: "ME",
    stateName: "Maine",
    city: "GUILFORD"
  },
  "04444": {
    stateCode: "ME",
    stateName: "Maine",
    city: "HAMPDEN"
  },
  "04448": {
    stateCode: "ME",
    stateName: "Maine",
    city: "HOWLAND"
  },
  "04449": {
    stateCode: "ME",
    stateName: "Maine",
    city: "HUDSON"
  },
  "04450": {
    stateCode: "ME",
    stateName: "Maine",
    city: "KENDUSKEAG"
  },
  "04451": {
    stateCode: "ME",
    stateName: "Maine",
    city: "KINGMAN"
  },
  "04453": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LAGRANGE"
  },
  "04455": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LEE"
  },
  "04456": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LEVANT"
  },
  "04457": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LINCOLN"
  },
  "04459": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MATTAWAMKEAG"
  },
  "04460": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MEDWAY"
  },
  "04461": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MILFORD"
  },
  "04462": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MILLINOCKET"
  },
  "04463": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MILO"
  },
  "04464": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MONSON"
  },
  "04468": {
    stateCode: "ME",
    stateName: "Maine",
    city: "OLD TOWN"
  },
  "04471": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ORIENT"
  },
  "04472": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ORLAND"
  },
  "04473": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ORONO"
  },
  "04474": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ORRINGTON"
  },
  "04475": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PASSADUMKEAG"
  },
  "04476": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PENOBSCOT"
  },
  "04478": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ROCKWOOD"
  },
  "04479": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SANGERVILLE"
  },
  "04487": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SPRINGFIELD"
  },
  "04488": {
    stateCode: "ME",
    stateName: "Maine",
    city: "STETSON"
  },
  "04490": {
    stateCode: "ME",
    stateName: "Maine",
    city: "TOPSFIELD"
  },
  "04493": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WEST ENFIELD"
  },
  "04495": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WINN"
  },
  "04496": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WINTERPORT"
  },
  "04497": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WYTOPITLOCK"
  },
  "04530": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BATH"
  },
  "04535": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ALNA"
  },
  "04537": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BOOTHBAY"
  },
  "04538": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BOOTHBAY HARBOR"
  },
  "04539": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BRISTOL"
  },
  "04541": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CHAMBERLAIN"
  },
  "04543": {
    stateCode: "ME",
    stateName: "Maine",
    city: "DAMARISCOTTA"
  },
  "04544": {
    stateCode: "ME",
    stateName: "Maine",
    city: "EAST BOOTHBAY"
  },
  "04547": {
    stateCode: "ME",
    stateName: "Maine",
    city: "FRIENDSHIP"
  },
  "04548": {
    stateCode: "ME",
    stateName: "Maine",
    city: "GEORGETOWN"
  },
  "04551": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BREMEN"
  },
  "04553": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NEWCASTLE"
  },
  "04554": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NEW HARBOR"
  },
  "04555": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NOBLEBORO"
  },
  "04556": {
    stateCode: "ME",
    stateName: "Maine",
    city: "EDGECOMB"
  },
  "04558": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PEMAQUID"
  },
  "04562": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PHIPPSBURG"
  },
  "04563": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CUSHING"
  },
  "04564": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ROUND POND"
  },
  "04568": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SOUTH BRISTOL"
  },
  "04572": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WALDOBORO"
  },
  "04573": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WALPOLE"
  },
  "04574": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WASHINGTON"
  },
  "04576": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SOUTHPORT"
  },
  "04578": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WISCASSET"
  },
  "04579": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WOOLWICH"
  },
  "04605": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ELLSWORTH"
  },
  "04606": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ADDISON"
  },
  "04607": {
    stateCode: "ME",
    stateName: "Maine",
    city: "GOULDSBORO"
  },
  "04609": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BAR HARBOR"
  },
  "04611": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BEALS"
  },
  "04612": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BERNARD"
  },
  "04613": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BIRCH HARBOR"
  },
  "04614": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BLUE HILL"
  },
  "04616": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BROOKLIN"
  },
  "04617": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BROOKSVILLE"
  },
  "04619": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CALAIS"
  },
  "04622": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CHERRYFIELD"
  },
  "04623": {
    stateCode: "ME",
    stateName: "Maine",
    city: "COLUMBIA FALLS"
  },
  "04624": {
    stateCode: "ME",
    stateName: "Maine",
    city: "COREA"
  },
  "04625": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CRANBERRY ISLES"
  },
  "04626": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CUTLER"
  },
  "04627": {
    stateCode: "ME",
    stateName: "Maine",
    city: "DEER ISLE"
  },
  "04628": {
    stateCode: "ME",
    stateName: "Maine",
    city: "DENNYSVILLE"
  },
  "04629": {
    stateCode: "ME",
    stateName: "Maine",
    city: "EAST BLUE HILL"
  },
  "04630": {
    stateCode: "ME",
    stateName: "Maine",
    city: "EAST MACHIAS"
  },
  "04631": {
    stateCode: "ME",
    stateName: "Maine",
    city: "EASTPORT"
  },
  "04634": {
    stateCode: "ME",
    stateName: "Maine",
    city: "FRANKLIN"
  },
  "04635": {
    stateCode: "ME",
    stateName: "Maine",
    city: "FRENCHBORO"
  },
  "04640": {
    stateCode: "ME",
    stateName: "Maine",
    city: "HANCOCK"
  },
  "04642": {
    stateCode: "ME",
    stateName: "Maine",
    city: "HARBORSIDE"
  },
  "04643": {
    stateCode: "ME",
    stateName: "Maine",
    city: "HARRINGTON"
  },
  "04645": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ISLE AU HAUT"
  },
  "04646": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ISLESFORD"
  },
  "04648": {
    stateCode: "ME",
    stateName: "Maine",
    city: "JONESBORO"
  },
  "04649": {
    stateCode: "ME",
    stateName: "Maine",
    city: "JONESPORT"
  },
  "04650": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LITTLE DEER ISLE"
  },
  "04652": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LUBEC"
  },
  "04653": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BASS HARBOR"
  },
  "04654": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MACHIAS"
  },
  "04655": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MACHIASPORT"
  },
  "04657": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MEDDYBEMPS"
  },
  "04658": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MILBRIDGE"
  },
  "04660": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MOUNT DESERT"
  },
  "04662": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NORTHEAST HARBOR"
  },
  "04664": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SULLIVAN"
  },
  "04666": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PEMBROKE"
  },
  "04667": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PERRY"
  },
  "04668": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PRINCETON"
  },
  "04669": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PROSPECT HARBOR"
  },
  "04671": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ROBBINSTON"
  },
  "04672": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SALSBURY COVE"
  },
  "04673": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SARGENTVILLE"
  },
  "04674": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SEAL COVE"
  },
  "04675": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SEAL HARBOR"
  },
  "04676": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SEDGWICK"
  },
  "04677": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SORRENTO"
  },
  "04679": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SOUTHWEST HARBOR"
  },
  "04680": {
    stateCode: "ME",
    stateName: "Maine",
    city: "STEUBEN"
  },
  "04681": {
    stateCode: "ME",
    stateName: "Maine",
    city: "STONINGTON"
  },
  "04683": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SUNSET"
  },
  "04684": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SURRY"
  },
  "04685": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SWANS ISLAND"
  },
  "04691": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WHITING"
  },
  "04693": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WINTER HARBOR"
  },
  "04694": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BAILEYVILLE"
  },
  "04730": {
    stateCode: "ME",
    stateName: "Maine",
    city: "HOULTON"
  },
  "04732": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ASHLAND"
  },
  "04733": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BENEDICTA"
  },
  "04735": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BRIDGEWATER"
  },
  "04736": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CARIBOU"
  },
  "04739": {
    stateCode: "ME",
    stateName: "Maine",
    city: "EAGLE LAKE"
  },
  "04740": {
    stateCode: "ME",
    stateName: "Maine",
    city: "EASTON"
  },
  "04742": {
    stateCode: "ME",
    stateName: "Maine",
    city: "FORT FAIRFIELD"
  },
  "04743": {
    stateCode: "ME",
    stateName: "Maine",
    city: "FORT KENT"
  },
  "04745": {
    stateCode: "ME",
    stateName: "Maine",
    city: "FRENCHVILLE"
  },
  "04746": {
    stateCode: "ME",
    stateName: "Maine",
    city: "GRAND ISLE"
  },
  "04747": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ISLAND FALLS"
  },
  "04750": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LIMESTONE"
  },
  "04756": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MADAWASKA"
  },
  "04757": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MAPLETON"
  },
  "04758": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MARS HILL"
  },
  "04760": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MONTICELLO"
  },
  "04762": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NEW SWEDEN"
  },
  "04763": {
    stateCode: "ME",
    stateName: "Maine",
    city: "OAKFIELD"
  },
  "04764": {
    stateCode: "ME",
    stateName: "Maine",
    city: "OXBOW"
  },
  "04765": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PATTEN"
  },
  "04766": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PERHAM"
  },
  "04768": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PORTAGE"
  },
  "04769": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PRESQUE ISLE"
  },
  "04772": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SAINT AGATHA"
  },
  "04773": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SAINT DAVID"
  },
  "04774": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SAINT FRANCIS"
  },
  "04776": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SHERMAN"
  },
  "04777": {
    stateCode: "ME",
    stateName: "Maine",
    city: "STACYVILLE"
  },
  "04779": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SINCLAIR"
  },
  "04780": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SMYRNA MILLS"
  },
  "04781": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WALLAGRASS"
  },
  "04783": {
    stateCode: "ME",
    stateName: "Maine",
    city: "STOCKHOLM"
  },
  "04785": {
    stateCode: "ME",
    stateName: "Maine",
    city: "VAN BUREN"
  },
  "04786": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WASHBURN"
  },
  "04787": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WESTFIELD"
  },
  "04841": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ROCKLAND"
  },
  "04843": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CAMDEN"
  },
  "04847": {
    stateCode: "ME",
    stateName: "Maine",
    city: "HOPE"
  },
  "04848": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ISLESBORO"
  },
  "04849": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LINCOLNVILLE"
  },
  "04851": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MATINICUS"
  },
  "04852": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MONHEGAN"
  },
  "04853": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NORTH HAVEN"
  },
  "04854": {
    stateCode: "ME",
    stateName: "Maine",
    city: "OWLS HEAD"
  },
  "04856": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ROCKPORT"
  },
  "04858": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SOUTH THOMASTON"
  },
  "04859": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SPRUCE HEAD"
  },
  "04860": {
    stateCode: "ME",
    stateName: "Maine",
    city: "TENANTS HARBOR"
  },
  "04861": {
    stateCode: "ME",
    stateName: "Maine",
    city: "THOMASTON"
  },
  "04862": {
    stateCode: "ME",
    stateName: "Maine",
    city: "UNION"
  },
  "04863": {
    stateCode: "ME",
    stateName: "Maine",
    city: "VINALHAVEN"
  },
  "04864": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WARREN"
  },
  "04901": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WATERVILLE"
  },
  "04910": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ALBION"
  },
  "04911": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ANSON"
  },
  "04912": {
    stateCode: "ME",
    stateName: "Maine",
    city: "ATHENS"
  },
  "04915": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BELFAST"
  },
  "04917": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BELGRADE"
  },
  "04920": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BINGHAM"
  },
  "04921": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BROOKS"
  },
  "04922": {
    stateCode: "ME",
    stateName: "Maine",
    city: "BURNHAM"
  },
  "04923": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CAMBRIDGE"
  },
  "04924": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CANAAN"
  },
  "04925": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CARATUNK"
  },
  "04927": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CLINTON"
  },
  "04928": {
    stateCode: "ME",
    stateName: "Maine",
    city: "CORINNA"
  },
  "04929": {
    stateCode: "ME",
    stateName: "Maine",
    city: "DETROIT"
  },
  "04930": {
    stateCode: "ME",
    stateName: "Maine",
    city: "DEXTER"
  },
  "04932": {
    stateCode: "ME",
    stateName: "Maine",
    city: "DIXMONT"
  },
  "04936": {
    stateCode: "ME",
    stateName: "Maine",
    city: "EUSTIS"
  },
  "04937": {
    stateCode: "ME",
    stateName: "Maine",
    city: "FAIRFIELD"
  },
  "04938": {
    stateCode: "ME",
    stateName: "Maine",
    city: "FARMINGTON"
  },
  "04939": {
    stateCode: "ME",
    stateName: "Maine",
    city: "GARLAND"
  },
  "04941": {
    stateCode: "ME",
    stateName: "Maine",
    city: "FREEDOM"
  },
  "04942": {
    stateCode: "ME",
    stateName: "Maine",
    city: "HARMONY"
  },
  "04943": {
    stateCode: "ME",
    stateName: "Maine",
    city: "HARTLAND"
  },
  "04945": {
    stateCode: "ME",
    stateName: "Maine",
    city: "JACKMAN"
  },
  "04947": {
    stateCode: "ME",
    stateName: "Maine",
    city: "KINGFIELD"
  },
  "04949": {
    stateCode: "ME",
    stateName: "Maine",
    city: "LIBERTY"
  },
  "04950": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MADISON"
  },
  "04951": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MONROE"
  },
  "04952": {
    stateCode: "ME",
    stateName: "Maine",
    city: "MORRILL"
  },
  "04953": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NEWPORT"
  },
  "04954": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NEW PORTLAND"
  },
  "04955": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NEW SHARON"
  },
  "04956": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NEW VINEYARD"
  },
  "04957": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NORRIDGEWOCK"
  },
  "04958": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NORTH ANSON"
  },
  "04961": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NEW PORTLAND"
  },
  "04962": {
    stateCode: "ME",
    stateName: "Maine",
    city: "NORTH VASSALBORO"
  },
  "04963": {
    stateCode: "ME",
    stateName: "Maine",
    city: "OAKLAND"
  },
  "04964": {
    stateCode: "ME",
    stateName: "Maine",
    city: "OQUOSSOC"
  },
  "04965": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PALMYRA"
  },
  "04966": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PHILLIPS"
  },
  "04967": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PITTSFIELD"
  },
  "04969": {
    stateCode: "ME",
    stateName: "Maine",
    city: "PLYMOUTH"
  },
  "04970": {
    stateCode: "ME",
    stateName: "Maine",
    city: "RANGELEY"
  },
  "04971": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SAINT ALBANS"
  },
  "04973": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SEARSMONT"
  },
  "04974": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SEARSPORT"
  },
  "04976": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SKOWHEGAN"
  },
  "04978": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SMITHFIELD"
  },
  "04979": {
    stateCode: "ME",
    stateName: "Maine",
    city: "SOLON"
  },
  "04981": {
    stateCode: "ME",
    stateName: "Maine",
    city: "STOCKTON SPRINGS"
  },
  "04982": {
    stateCode: "ME",
    stateName: "Maine",
    city: "STRATTON"
  },
  "04983": {
    stateCode: "ME",
    stateName: "Maine",
    city: "STRONG"
  },
  "04984": {
    stateCode: "ME",
    stateName: "Maine",
    city: "TEMPLE"
  },
  "04985": {
    stateCode: "ME",
    stateName: "Maine",
    city: "WEST FORKS"
  },
  "04986": {
    stateCode: "ME",
    stateName: "Maine",
    city: "THORNDIKE"
  },
  "04987": {
    stateCode: "ME",
    stateName: "Maine",
    city: "TROY"
  },
  "04988": {
    stateCode: "ME",
    stateName: "Maine",
    city: "UNITY"
  },
  "04989": {
    stateCode: "ME",
    stateName: "Maine",
    city: "VASSALBORO"
  },
  "05001": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WHITE RIVER JUNCTION"
  },
  "05031": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BARNARD"
  },
  "05032": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BETHEL"
  },
  "05033": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BRADFORD"
  },
  "05034": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BRIDGEWATER"
  },
  "05035": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BRIDGEWATER CORNERS"
  },
  "05036": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BROOKFIELD"
  },
  "05037": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BROWNSVILLE"
  },
  "05038": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "CHELSEA"
  },
  "05039": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "CORINTH"
  },
  "05040": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "EAST CORINTH"
  },
  "05041": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "EAST RANDOLPH"
  },
  "05042": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "EAST RYEGATE"
  },
  "05043": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "EAST THETFORD"
  },
  "05045": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "FAIRLEE"
  },
  "05046": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "GROTON"
  },
  "05048": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "HARTLAND"
  },
  "05051": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NEWBURY"
  },
  "05052": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NORTH HARTLAND"
  },
  "05053": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NORTH POMFRET"
  },
  "05054": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NORTH THETFORD"
  },
  "05055": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NORWICH"
  },
  "05056": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "PLYMOUTH"
  },
  "05058": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "POST MILLS"
  },
  "05059": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "QUECHEE"
  },
  "05060": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "RANDOLPH"
  },
  "05061": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "RANDOLPH CENTER"
  },
  "05062": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "READING"
  },
  "05065": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SHARON"
  },
  "05067": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SOUTH POMFRET"
  },
  "05068": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SOUTH ROYALTON"
  },
  "05069": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SOUTH RYEGATE"
  },
  "05070": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SOUTH STRAFFORD"
  },
  "05071": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SOUTH WOODSTOCK"
  },
  "05072": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "STRAFFORD"
  },
  "05073": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "TAFTSVILLE"
  },
  "05074": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "THETFORD"
  },
  "05075": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "THETFORD CENTER"
  },
  "05076": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "TOPSHAM"
  },
  "05077": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "TUNBRIDGE"
  },
  "05079": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "VERSHIRE"
  },
  "05081": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WELLS RIVER"
  },
  "05083": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WEST FAIRLEE"
  },
  "05084": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WEST HARTFORD"
  },
  "05085": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WEST NEWBURY"
  },
  "05086": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WEST TOPSHAM"
  },
  "05088": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WILDER"
  },
  "05089": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WINDSOR"
  },
  "05091": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WOODSTOCK"
  },
  "05101": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BELLOWS FALLS"
  },
  "05142": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "CAVENDISH"
  },
  "05143": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "CHESTER"
  },
  "05146": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "GRAFTON"
  },
  "05148": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "LONDONDERRY"
  },
  "05149": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "LUDLOW"
  },
  "05150": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NORTH SPRINGFIELD"
  },
  "05151": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "PERKINSVILLE"
  },
  "05152": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "PERU"
  },
  "05153": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "PROCTORSVILLE"
  },
  "05154": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SAXTONS RIVER"
  },
  "05155": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SOUTH LONDONDERRY"
  },
  "05156": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SPRINGFIELD"
  },
  "05158": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WESTMINSTER"
  },
  "05161": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WESTON"
  },
  "05201": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BENNINGTON"
  },
  "05250": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "ARLINGTON"
  },
  "05251": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "DORSET"
  },
  "05252": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "EAST ARLINGTON"
  },
  "05253": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "EAST DORSET"
  },
  "05254": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "MANCHESTER"
  },
  "05255": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "MANCHESTER CENTER"
  },
  "05257": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NORTH BENNINGTON"
  },
  "05260": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NORTH POWNAL"
  },
  "05261": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "POWNAL"
  },
  "05262": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SHAFTSBURY"
  },
  "05301": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BRATTLEBORO"
  },
  "05340": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BONDVILLE"
  },
  "05341": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "EAST DOVER"
  },
  "05342": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "JACKSONVILLE"
  },
  "05343": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "JAMAICA"
  },
  "05344": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "MARLBORO"
  },
  "05345": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NEWFANE"
  },
  "05346": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "PUTNEY"
  },
  "05350": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "READSBORO"
  },
  "05351": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SOUTH NEWFANE"
  },
  "05352": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "STAMFORD"
  },
  "05353": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "TOWNSHEND"
  },
  "05354": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "VERNON"
  },
  "05355": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WARDSBORO"
  },
  "05356": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WEST DOVER"
  },
  "05358": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WEST HALIFAX"
  },
  "05359": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WEST TOWNSHEND"
  },
  "05360": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WEST WARDSBORO"
  },
  "05361": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WHITINGHAM"
  },
  "05362": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WILLIAMSVILLE"
  },
  "05363": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WILMINGTON"
  },
  "05401": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BURLINGTON"
  },
  "05403": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SOUTH BURLINGTON"
  },
  "05404": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WINOOSKI"
  },
  "05440": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "ALBURGH"
  },
  "05441": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BAKERSFIELD"
  },
  "05442": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BELVIDERE CENTER"
  },
  "05443": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BRISTOL"
  },
  "05444": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "CAMBRIDGE"
  },
  "05445": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "CHARLOTTE"
  },
  "05446": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "COLCHESTER"
  },
  "05448": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "EAST FAIRFIELD"
  },
  "05450": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "ENOSBURG FALLS"
  },
  "05452": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "ESSEX JUNCTION"
  },
  "05454": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "FAIRFAX"
  },
  "05455": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "FAIRFIELD"
  },
  "05456": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "FERRISBURGH"
  },
  "05457": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "FRANKLIN"
  },
  "05458": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "GRAND ISLE"
  },
  "05459": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "HIGHGATE CENTER"
  },
  "05461": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "HINESBURG"
  },
  "05462": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "HUNTINGTON"
  },
  "05463": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "ISLE LA MOTTE"
  },
  "05464": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "JEFFERSONVILLE"
  },
  "05465": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "JERICHO"
  },
  "05468": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "MILTON"
  },
  "05471": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "MONTGOMERY CENTER"
  },
  "05472": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NEW HAVEN"
  },
  "05473": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NORTH FERRISBURGH"
  },
  "05474": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NORTH HERO"
  },
  "05476": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "RICHFORD"
  },
  "05477": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "RICHMOND"
  },
  "05478": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SAINT ALBANS"
  },
  "05482": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SHELBURNE"
  },
  "05483": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SHELDON"
  },
  "05486": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SOUTH HERO"
  },
  "05487": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "STARKSBORO"
  },
  "05488": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SWANTON"
  },
  "05489": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "UNDERHILL"
  },
  "05491": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "VERGENNES"
  },
  "05492": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WATERVILLE"
  },
  "05494": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WESTFORD"
  },
  "05495": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WILLISTON"
  },
  "05602": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "MONTPELIER"
  },
  "05640": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "ADAMANT"
  },
  "05641": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BARRE"
  },
  "05647": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "CABOT"
  },
  "05648": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "CALAIS"
  },
  "05649": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "EAST BARRE"
  },
  "05650": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "EAST CALAIS"
  },
  "05651": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "EAST MONTPELIER"
  },
  "05652": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "EDEN"
  },
  "05653": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "EDEN MILLS"
  },
  "05654": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "GRANITEVILLE"
  },
  "05655": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "HYDE PARK"
  },
  "05656": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "JOHNSON"
  },
  "05657": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "LAKE ELMORE"
  },
  "05658": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "MARSHFIELD"
  },
  "05660": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "MORETOWN"
  },
  "05661": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "MORRISVILLE"
  },
  "05663": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NORTHFIELD"
  },
  "05664": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NORTHFIELD FALLS"
  },
  "05666": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NORTH MONTPELIER"
  },
  "05667": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "PLAINFIELD"
  },
  "05669": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "ROXBURY"
  },
  "05672": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "STOWE"
  },
  "05673": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WAITSFIELD"
  },
  "05674": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WARREN"
  },
  "05675": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WASHINGTON"
  },
  "05676": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WATERBURY"
  },
  "05677": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WATERBURY CENTER"
  },
  "05678": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WEBSTERVILLE"
  },
  "05679": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WILLIAMSTOWN"
  },
  "05680": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WOLCOTT"
  },
  "05681": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WOODBURY"
  },
  "05682": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WORCESTER"
  },
  "05701": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "RUTLAND"
  },
  "05730": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BELMONT"
  },
  "05732": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BOMOSEEN"
  },
  "05733": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BRANDON"
  },
  "05734": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BRIDPORT"
  },
  "05735": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "CASTLETON"
  },
  "05736": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "CENTER RUTLAND"
  },
  "05737": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "CHITTENDEN"
  },
  "05738": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "CUTTINGSVILLE"
  },
  "05739": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "DANBY"
  },
  "05742": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "EAST WALLINGFORD"
  },
  "05743": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "FAIR HAVEN"
  },
  "05744": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "FLORENCE"
  },
  "05746": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "GAYSVILLE"
  },
  "05747": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "GRANVILLE"
  },
  "05748": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "HANCOCK"
  },
  "05750": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "HYDEVILLE"
  },
  "05751": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "KILLINGTON"
  },
  "05753": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "MIDDLEBURY"
  },
  "05757": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "MIDDLETOWN SPRINGS"
  },
  "05758": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "MOUNT HOLLY"
  },
  "05759": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NORTH CLARENDON"
  },
  "05760": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "ORWELL"
  },
  "05761": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "PAWLET"
  },
  "05762": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "PITTSFIELD"
  },
  "05763": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "PITTSFORD"
  },
  "05764": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "POULTNEY"
  },
  "05765": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "PROCTOR"
  },
  "05766": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "RIPTON"
  },
  "05767": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "ROCHESTER"
  },
  "05769": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SALISBURY"
  },
  "05770": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SHOREHAM"
  },
  "05772": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "STOCKBRIDGE"
  },
  "05773": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WALLINGFORD"
  },
  "05774": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WELLS"
  },
  "05775": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WEST PAWLET"
  },
  "05776": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WEST RUPERT"
  },
  "05777": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WEST RUTLAND"
  },
  "05778": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WHITING"
  },
  "05819": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SAINT JOHNSBURY"
  },
  "05820": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "ALBANY"
  },
  "05821": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BARNET"
  },
  "05822": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BARTON"
  },
  "05824": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "CONCORD"
  },
  "05825": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "COVENTRY"
  },
  "05826": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "CRAFTSBURY"
  },
  "05827": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "CRAFTSBURY COMMON"
  },
  "05828": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "DANVILLE"
  },
  "05829": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "DERBY"
  },
  "05830": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "DERBY LINE"
  },
  "05832": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "EAST BURKE"
  },
  "05833": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "EAST CHARLESTON"
  },
  "05836": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "EAST HARDWICK"
  },
  "05837": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "EAST HAVEN"
  },
  "05839": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "GLOVER"
  },
  "05840": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "GRANBY"
  },
  "05841": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "GREENSBORO"
  },
  "05842": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "GREENSBORO BEND"
  },
  "05843": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "HARDWICK"
  },
  "05845": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "IRASBURG"
  },
  "05846": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "ISLAND POND"
  },
  "05847": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "LOWELL"
  },
  "05851": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "LYNDONVILLE"
  },
  "05853": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "MORGAN"
  },
  "05855": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NEWPORT"
  },
  "05857": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NEWPORT CENTER"
  },
  "05858": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NORTH CONCORD"
  },
  "05859": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NORTH TROY"
  },
  "05860": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "ORLEANS"
  },
  "05866": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SHEFFIELD"
  },
  "05867": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "SUTTON"
  },
  "05868": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "TROY"
  },
  "05871": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WEST BURKE"
  },
  "05872": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WEST CHARLESTON"
  },
  "05873": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WEST DANVILLE"
  },
  "05874": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WESTFIELD"
  },
  "05875": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "WEST GLOVER"
  },
  "05902": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "BEECHER FALLS"
  },
  "05903": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "CANAAN"
  },
  "05904": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "GILMAN"
  },
  "05905": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "GUILDHALL"
  },
  "05906": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "LUNENBURG"
  },
  "05907": {
    stateCode: "VT",
    stateName: "Vermont",
    city: "NORTON"
  },
  "06001": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "AVON"
  },
  "06002": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BLOOMFIELD"
  },
  "06010": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BRISTOL"
  },
  "06013": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BURLINGTON"
  },
  "06016": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BROAD BROOK"
  },
  "06018": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "CANAAN"
  },
  "06019": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "CANTON"
  },
  "06020": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "CANTON CENTER"
  },
  "06021": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "COLEBROOK"
  },
  "06022": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "COLLINSVILLE"
  },
  "06023": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "EAST BERLIN"
  },
  "06024": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "EAST CANAAN"
  },
  "06026": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "EAST GRANBY"
  },
  "06027": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "EAST HARTLAND"
  },
  "06029": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "ELLINGTON"
  },
  "06031": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "FALLS VILLAGE"
  },
  "06032": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "FARMINGTON"
  },
  "06033": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "GLASTONBURY"
  },
  "06035": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "GRANBY"
  },
  "06037": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BERLIN"
  },
  "06039": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "LAKEVILLE"
  },
  "06040": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "MANCHESTER"
  },
  "06043": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BOLTON"
  },
  "06051": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NEW BRITAIN"
  },
  "06052": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NEW BRITAIN"
  },
  "06053": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NEW BRITAIN"
  },
  "06057": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NEW HARTFORD"
  },
  "06058": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NORFOLK"
  },
  "06059": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NORTH CANTON"
  },
  "06060": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NORTH GRANBY"
  },
  "06062": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "PLAINVILLE"
  },
  "06063": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BARKHAMSTED"
  },
  "06065": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "RIVERTON"
  },
  "06066": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "VERNON ROCKVILLE"
  },
  "06067": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "ROCKY HILL"
  },
  "06068": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "SALISBURY"
  },
  "06069": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "SHARON"
  },
  "06070": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "SIMSBURY"
  },
  "06071": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "SOMERS"
  },
  "06073": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "SOUTH GLASTONBURY"
  },
  "06074": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "SOUTH WINDSOR"
  },
  "06076": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "STAFFORD SPRINGS"
  },
  "06078": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "SUFFIELD"
  },
  "06081": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "TARIFFVILLE"
  },
  "06082": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "ENFIELD"
  },
  "06084": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "TOLLAND"
  },
  "06085": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "UNIONVILLE"
  },
  "06088": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "EAST WINDSOR"
  },
  "06089": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WEATOGUE"
  },
  "06090": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WEST GRANBY"
  },
  "06091": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WEST HARTLAND"
  },
  "06092": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WEST SIMSBURY"
  },
  "06093": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WEST SUFFIELD"
  },
  "06095": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WINDSOR"
  },
  "06096": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WINDSOR LOCKS"
  },
  "06098": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WINSTED"
  },
  "06103": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "HARTFORD"
  },
  "06105": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "HARTFORD"
  },
  "06106": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "HARTFORD"
  },
  "06107": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WEST HARTFORD"
  },
  "06108": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "EAST HARTFORD"
  },
  "06109": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WETHERSFIELD"
  },
  "06110": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WEST HARTFORD"
  },
  "06111": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NEWINGTON"
  },
  "06112": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "HARTFORD"
  },
  "06114": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "HARTFORD"
  },
  "06117": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WEST HARTFORD"
  },
  "06118": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "EAST HARTFORD"
  },
  "06119": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WEST HARTFORD"
  },
  "06120": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "HARTFORD"
  },
  "06226": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WILLIMANTIC"
  },
  "06231": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "AMSTON"
  },
  "06232": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "ANDOVER"
  },
  "06234": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BROOKLYN"
  },
  "06235": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "CHAPLIN"
  },
  "06237": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "COLUMBIA"
  },
  "06238": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "COVENTRY"
  },
  "06239": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "DANIELSON"
  },
  "06241": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "DAYVILLE"
  },
  "06242": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "EASTFORD"
  },
  "06247": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "HAMPTON"
  },
  "06248": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "HEBRON"
  },
  "06249": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "LEBANON"
  },
  "06250": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "MANSFIELD CENTER"
  },
  "06254": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NORTH FRANKLIN"
  },
  "06255": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NORTH GROSVENORDALE"
  },
  "06256": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NORTH WINDHAM"
  },
  "06259": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "POMFRET CENTER"
  },
  "06260": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "PUTNAM"
  },
  "06262": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "QUINEBAUG"
  },
  "06264": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "SCOTLAND"
  },
  "06266": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "SOUTH WINDHAM"
  },
  "06268": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "STORRS MANSFIELD"
  },
  "06269": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "STORRS MANSFIELD"
  },
  "06277": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "THOMPSON"
  },
  "06278": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "ASHFORD"
  },
  "06279": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WILLINGTON"
  },
  "06280": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WINDHAM"
  },
  "06281": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WOODSTOCK"
  },
  "06282": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WOODSTOCK VALLEY"
  },
  "06320": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NEW LONDON"
  },
  "06330": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BALTIC"
  },
  "06331": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "CANTERBURY"
  },
  "06333": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "EAST LYME"
  },
  "06334": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BOZRAH"
  },
  "06335": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "GALES FERRY"
  },
  "06336": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "GILMAN"
  },
  "06339": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "LEDYARD"
  },
  "06340": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "GROTON"
  },
  "06351": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "JEWETT CITY"
  },
  "06354": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "MOOSUP"
  },
  "06355": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "MYSTIC"
  },
  "06357": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NIANTIC"
  },
  "06359": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NORTH STONINGTON"
  },
  "06360": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NORWICH"
  },
  "06365": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "PRESTON"
  },
  "06370": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "OAKDALE"
  },
  "06371": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "OLD LYME"
  },
  "06373": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "ONECO"
  },
  "06374": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "PLAINFIELD"
  },
  "06375": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "QUAKER HILL"
  },
  "06376": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "SOUTH LYME"
  },
  "06377": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "STERLING"
  },
  "06378": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "STONINGTON"
  },
  "06379": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "PAWCATUCK"
  },
  "06380": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "TAFTVILLE"
  },
  "06382": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "UNCASVILLE"
  },
  "06384": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "VOLUNTOWN"
  },
  "06385": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WATERFORD"
  },
  "06390": {
    stateCode: "NY",
    stateName: "New York",
    city: "FISHERS ISLAND"
  },
  "06401": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "ANSONIA"
  },
  "06403": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BEACON FALLS"
  },
  "06405": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BRANFORD"
  },
  "06409": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "CENTERBROOK"
  },
  "06410": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "CHESHIRE"
  },
  "06412": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "CHESTER"
  },
  "06413": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "CLINTON"
  },
  "06415": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "COLCHESTER"
  },
  "06416": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "CROMWELL"
  },
  "06417": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "DEEP RIVER"
  },
  "06418": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "DERBY"
  },
  "06419": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "KILLINGWORTH"
  },
  "06420": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "SALEM"
  },
  "06422": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "DURHAM"
  },
  "06423": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "EAST HADDAM"
  },
  "06424": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "EAST HAMPTON"
  },
  "06426": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "ESSEX"
  },
  "06437": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "GUILFORD"
  },
  "06438": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "HADDAM"
  },
  "06441": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "HIGGANUM"
  },
  "06442": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "IVORYTON"
  },
  "06443": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "MADISON"
  },
  "06447": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "MARLBOROUGH"
  },
  "06450": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "MERIDEN"
  },
  "06451": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "MERIDEN"
  },
  "06455": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "MIDDLEFIELD"
  },
  "06457": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "MIDDLETOWN"
  },
  "06460": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "MILFORD"
  },
  "06468": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "MONROE"
  },
  "06469": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "MOODUS"
  },
  "06470": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NEWTOWN"
  },
  "06471": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NORTH BRANFORD"
  },
  "06472": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NORTHFORD"
  },
  "06473": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NORTH HAVEN"
  },
  "06475": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "OLD SAYBROOK"
  },
  "06477": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "ORANGE"
  },
  "06478": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "OXFORD"
  },
  "06479": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "PLANTSVILLE"
  },
  "06480": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "PORTLAND"
  },
  "06481": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "ROCKFALL"
  },
  "06482": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "SANDY HOOK"
  },
  "06483": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "SEYMOUR"
  },
  "06484": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "SHELTON"
  },
  "06488": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "SOUTHBURY"
  },
  "06489": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "SOUTHINGTON"
  },
  "06492": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WALLINGFORD"
  },
  "06498": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WESTBROOK"
  },
  "06510": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NEW HAVEN"
  },
  "06511": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NEW HAVEN"
  },
  "06512": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "EAST HAVEN"
  },
  "06513": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NEW HAVEN"
  },
  "06514": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "HAMDEN"
  },
  "06515": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NEW HAVEN"
  },
  "06516": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WEST HAVEN"
  },
  "06517": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "HAMDEN"
  },
  "06518": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "HAMDEN"
  },
  "06519": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NEW HAVEN"
  },
  "06524": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BETHANY"
  },
  "06525": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WOODBRIDGE"
  },
  "06604": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BRIDGEPORT"
  },
  "06605": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BRIDGEPORT"
  },
  "06606": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BRIDGEPORT"
  },
  "06607": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BRIDGEPORT"
  },
  "06608": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BRIDGEPORT"
  },
  "06610": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BRIDGEPORT"
  },
  "06611": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "TRUMBULL"
  },
  "06612": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "EASTON"
  },
  "06614": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "STRATFORD"
  },
  "06615": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "STRATFORD"
  },
  "06702": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WATERBURY"
  },
  "06704": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WATERBURY"
  },
  "06705": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WATERBURY"
  },
  "06706": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WATERBURY"
  },
  "06708": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WATERBURY"
  },
  "06710": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WATERBURY"
  },
  "06712": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "PROSPECT"
  },
  "06716": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WOLCOTT"
  },
  "06750": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BANTAM"
  },
  "06751": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BETHLEHEM"
  },
  "06752": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BRIDGEWATER"
  },
  "06754": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "CORNWALL BRIDGE"
  },
  "06755": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "GAYLORDSVILLE"
  },
  "06756": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "GOSHEN"
  },
  "06757": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "KENT"
  },
  "06759": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "LITCHFIELD"
  },
  "06762": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "MIDDLEBURY"
  },
  "06763": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "MORRIS"
  },
  "06770": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NAUGATUCK"
  },
  "06776": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NEW MILFORD"
  },
  "06777": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NEW PRESTON MARBLE DALE"
  },
  "06778": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NORTHFIELD"
  },
  "06779": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "OAKVILLE"
  },
  "06782": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "PLYMOUTH"
  },
  "06783": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "ROXBURY"
  },
  "06784": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "SHERMAN"
  },
  "06785": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "SOUTH KENT"
  },
  "06786": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "TERRYVILLE"
  },
  "06787": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "THOMASTON"
  },
  "06790": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "TORRINGTON"
  },
  "06791": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "HARWINTON"
  },
  "06793": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WASHINGTON"
  },
  "06794": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WASHINGTON DEPOT"
  },
  "06795": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WATERTOWN"
  },
  "06796": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WEST CORNWALL"
  },
  "06798": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WOODBURY"
  },
  "06801": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BETHEL"
  },
  "06804": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "BROOKFIELD"
  },
  "06807": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "COS COB"
  },
  "06810": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "DANBURY"
  },
  "06811": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "DANBURY"
  },
  "06812": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NEW FAIRFIELD"
  },
  "06820": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "DARIEN"
  },
  "06830": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "GREENWICH"
  },
  "06831": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "GREENWICH"
  },
  "06840": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NEW CANAAN"
  },
  "06850": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NORWALK"
  },
  "06851": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NORWALK"
  },
  "06853": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NORWALK"
  },
  "06854": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NORWALK"
  },
  "06855": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "NORWALK"
  },
  "06870": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "OLD GREENWICH"
  },
  "06877": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "RIDGEFIELD"
  },
  "06878": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "RIVERSIDE"
  },
  "06880": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WESTPORT"
  },
  "06883": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WESTON"
  },
  "06896": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "REDDING"
  },
  "06897": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "WILTON"
  },
  "06901": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "STAMFORD"
  },
  "06902": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "STAMFORD"
  },
  "06903": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "STAMFORD"
  },
  "06905": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "STAMFORD"
  },
  "06906": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "STAMFORD"
  },
  "06907": {
    stateCode: "CT",
    stateName: "Connecticut",
    city: "STAMFORD"
  },
  "07001": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "AVENEL"
  },
  "07002": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BAYONNE"
  },
  "07003": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BLOOMFIELD"
  },
  "07004": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FAIRFIELD"
  },
  "07005": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BOONTON"
  },
  "07006": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CALDWELL"
  },
  "07008": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CARTERET"
  },
  "07009": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CEDAR GROVE"
  },
  "07010": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CLIFFSIDE PARK"
  },
  "07011": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CLIFTON"
  },
  "07012": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CLIFTON"
  },
  "07013": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CLIFTON"
  },
  "07014": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CLIFTON"
  },
  "07016": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CRANFORD"
  },
  "07017": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "EAST ORANGE"
  },
  "07018": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "EAST ORANGE"
  },
  "07020": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "EDGEWATER"
  },
  "07021": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ESSEX FELLS"
  },
  "07022": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FAIRVIEW"
  },
  "07023": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FANWOOD"
  },
  "07024": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FORT LEE"
  },
  "07026": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "GARFIELD"
  },
  "07027": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "GARWOOD"
  },
  "07028": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "GLEN RIDGE"
  },
  "07029": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HARRISON"
  },
  "07030": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HOBOKEN"
  },
  "07031": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NORTH ARLINGTON"
  },
  "07032": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "KEARNY"
  },
  "07033": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "KENILWORTH"
  },
  "07034": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LAKE HIAWATHA"
  },
  "07035": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LINCOLN PARK"
  },
  "07036": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LINDEN"
  },
  "07039": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LIVINGSTON"
  },
  "07040": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MAPLEWOOD"
  },
  "07041": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MILLBURN"
  },
  "07042": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MONTCLAIR"
  },
  "07043": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MONTCLAIR"
  },
  "07044": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "VERONA"
  },
  "07045": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MONTVILLE"
  },
  "07046": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MOUNTAIN LAKES"
  },
  "07047": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NORTH BERGEN"
  },
  "07050": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ORANGE"
  },
  "07052": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WEST ORANGE"
  },
  "07054": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PARSIPPANY"
  },
  "07055": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PASSAIC"
  },
  "07057": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WALLINGTON"
  },
  "07058": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PINE BROOK"
  },
  "07059": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WARREN"
  },
  "07060": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PLAINFIELD"
  },
  "07062": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PLAINFIELD"
  },
  "07063": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PLAINFIELD"
  },
  "07064": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PORT READING"
  },
  "07065": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RAHWAY"
  },
  "07066": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CLARK"
  },
  "07067": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "COLONIA"
  },
  "07068": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ROSELAND"
  },
  "07070": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RUTHERFORD"
  },
  "07071": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LYNDHURST"
  },
  "07072": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CARLSTADT"
  },
  "07073": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "EAST RUTHERFORD"
  },
  "07074": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MOONACHIE"
  },
  "07075": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WOOD RIDGE"
  },
  "07076": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SCOTCH PLAINS"
  },
  "07077": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SEWAREN"
  },
  "07078": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SHORT HILLS"
  },
  "07079": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SOUTH ORANGE"
  },
  "07080": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SOUTH PLAINFIELD"
  },
  "07081": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SPRINGFIELD"
  },
  "07082": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TOWACO"
  },
  "07083": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "UNION"
  },
  "07087": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "UNION CITY"
  },
  "07088": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "VAUXHALL"
  },
  "07090": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WESTFIELD"
  },
  "07092": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MOUNTAINSIDE"
  },
  "07093": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WEST NEW YORK"
  },
  "07094": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SECAUCUS"
  },
  "07095": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WOODBRIDGE"
  },
  "07102": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEWARK"
  },
  "07103": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEWARK"
  },
  "07104": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEWARK"
  },
  "07105": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEWARK"
  },
  "07106": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEWARK"
  },
  "07107": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEWARK"
  },
  "07108": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEWARK"
  },
  "07109": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BELLEVILLE"
  },
  "07110": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NUTLEY"
  },
  "07111": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "IRVINGTON"
  },
  "07112": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEWARK"
  },
  "07114": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEWARK"
  },
  "07201": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ELIZABETH"
  },
  "07202": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ELIZABETH"
  },
  "07203": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ROSELLE"
  },
  "07204": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ROSELLE PARK"
  },
  "07205": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HILLSIDE"
  },
  "07206": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ELIZABETH"
  },
  "07208": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ELIZABETH"
  },
  "07302": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "JERSEY CITY"
  },
  "07304": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "JERSEY CITY"
  },
  "07305": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "JERSEY CITY"
  },
  "07306": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "JERSEY CITY"
  },
  "07307": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "JERSEY CITY"
  },
  "07310": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "JERSEY CITY"
  },
  "07401": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ALLENDALE"
  },
  "07403": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BLOOMINGDALE"
  },
  "07405": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BUTLER"
  },
  "07407": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ELMWOOD PARK"
  },
  "07410": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FAIR LAWN"
  },
  "07416": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FRANKLIN"
  },
  "07417": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FRANKLIN LAKES"
  },
  "07418": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "GLENWOOD"
  },
  "07419": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HAMBURG"
  },
  "07420": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HASKELL"
  },
  "07421": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HEWITT"
  },
  "07422": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HIGHLAND LAKES"
  },
  "07423": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HO HO KUS"
  },
  "07424": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LITTLE FALLS"
  },
  "07428": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MC AFEE"
  },
  "07430": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MAHWAH"
  },
  "07432": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MIDLAND PARK"
  },
  "07435": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEWFOUNDLAND"
  },
  "07436": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "OAKLAND"
  },
  "07438": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "OAK RIDGE"
  },
  "07439": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "OGDENSBURG"
  },
  "07440": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PEQUANNOCK"
  },
  "07442": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "POMPTON LAKES"
  },
  "07444": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "POMPTON PLAINS"
  },
  "07446": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RAMSEY"
  },
  "07450": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RIDGEWOOD"
  },
  "07452": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "GLEN ROCK"
  },
  "07456": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RINGWOOD"
  },
  "07457": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RIVERDALE"
  },
  "07458": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SADDLE RIVER"
  },
  "07460": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "STOCKHOLM"
  },
  "07461": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SUSSEX"
  },
  "07462": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "VERNON"
  },
  "07463": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WALDWICK"
  },
  "07465": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WANAQUE"
  },
  "07470": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WAYNE"
  },
  "07480": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WEST MILFORD"
  },
  "07481": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WYCKOFF"
  },
  "07501": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PATERSON"
  },
  "07502": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PATERSON"
  },
  "07503": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PATERSON"
  },
  "07504": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PATERSON"
  },
  "07505": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PATERSON"
  },
  "07506": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HAWTHORNE"
  },
  "07508": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HALEDON"
  },
  "07512": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TOTOWA"
  },
  "07513": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PATERSON"
  },
  "07514": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PATERSON"
  },
  "07522": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PATERSON"
  },
  "07524": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PATERSON"
  },
  "07601": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HACKENSACK"
  },
  "07603": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BOGOTA"
  },
  "07604": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HASBROUCK HEIGHTS"
  },
  "07605": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LEONIA"
  },
  "07606": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SOUTH HACKENSACK"
  },
  "07607": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MAYWOOD"
  },
  "07608": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TETERBORO"
  },
  "07620": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ALPINE"
  },
  "07621": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BERGENFIELD"
  },
  "07624": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CLOSTER"
  },
  "07626": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CRESSKILL"
  },
  "07627": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "DEMAREST"
  },
  "07628": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "DUMONT"
  },
  "07630": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "EMERSON"
  },
  "07631": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ENGLEWOOD"
  },
  "07632": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ENGLEWOOD CLIFFS"
  },
  "07640": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HARRINGTON PARK"
  },
  "07641": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HAWORTH"
  },
  "07642": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HILLSDALE"
  },
  "07643": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LITTLE FERRY"
  },
  "07644": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LODI"
  },
  "07645": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MONTVALE"
  },
  "07646": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEW MILFORD"
  },
  "07647": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NORTHVALE"
  },
  "07648": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NORWOOD"
  },
  "07649": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ORADELL"
  },
  "07650": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PALISADES PARK"
  },
  "07652": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PARAMUS"
  },
  "07656": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PARK RIDGE"
  },
  "07657": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RIDGEFIELD"
  },
  "07660": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RIDGEFIELD PARK"
  },
  "07661": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RIVER EDGE"
  },
  "07662": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ROCHELLE PARK"
  },
  "07663": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SADDLE BROOK"
  },
  "07666": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TEANECK"
  },
  "07670": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TENAFLY"
  },
  "07675": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WESTWOOD"
  },
  "07701": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RED BANK"
  },
  "07702": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SHREWSBURY"
  },
  "07703": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FORT MONMOUTH"
  },
  "07704": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FAIR HAVEN"
  },
  "07711": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ALLENHURST"
  },
  "07712": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ASBURY PARK"
  },
  "07716": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ATLANTIC HIGHLANDS"
  },
  "07717": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "AVON BY THE SEA"
  },
  "07718": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BELFORD"
  },
  "07719": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BELMAR"
  },
  "07720": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BRADLEY BEACH"
  },
  "07721": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CLIFFWOOD"
  },
  "07722": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "COLTS NECK"
  },
  "07723": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "DEAL"
  },
  "07724": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "EATONTOWN"
  },
  "07726": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ENGLISHTOWN"
  },
  "07727": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FARMINGDALE"
  },
  "07728": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FREEHOLD"
  },
  "07730": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HAZLET"
  },
  "07731": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HOWELL"
  },
  "07732": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HIGHLANDS"
  },
  "07733": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HOLMDEL"
  },
  "07734": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "KEANSBURG"
  },
  "07735": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "KEYPORT"
  },
  "07737": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LEONARDO"
  },
  "07738": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LINCROFT"
  },
  "07739": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LITTLE SILVER"
  },
  "07740": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LONG BRANCH"
  },
  "07746": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MARLBORO"
  },
  "07747": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MATAWAN"
  },
  "07748": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MIDDLETOWN"
  },
  "07750": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MONMOUTH BEACH"
  },
  "07751": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MORGANVILLE"
  },
  "07753": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEPTUNE"
  },
  "07755": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "OAKHURST"
  },
  "07756": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "OCEAN GROVE"
  },
  "07757": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "OCEANPORT"
  },
  "07758": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PORT MONMOUTH"
  },
  "07760": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RUMSON"
  },
  "07762": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SPRING LAKE"
  },
  "07764": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WEST LONG BRANCH"
  },
  "07801": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "DOVER"
  },
  "07803": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MINE HILL"
  },
  "07821": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ANDOVER"
  },
  "07822": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "AUGUSTA"
  },
  "07823": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BELVIDERE"
  },
  "07825": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BLAIRSTOWN"
  },
  "07826": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BRANCHVILLE"
  },
  "07827": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MONTAGUE"
  },
  "07828": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BUDD LAKE"
  },
  "07830": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CALIFON"
  },
  "07832": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "COLUMBIA"
  },
  "07833": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "DELAWARE"
  },
  "07834": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "DENVILLE"
  },
  "07836": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FLANDERS"
  },
  "07838": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "GREAT MEADOWS"
  },
  "07840": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HACKETTSTOWN"
  },
  "07842": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HIBERNIA"
  },
  "07843": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HOPATCONG"
  },
  "07846": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "JOHNSONBURG"
  },
  "07847": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "KENVIL"
  },
  "07848": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LAFAYETTE"
  },
  "07849": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LAKE HOPATCONG"
  },
  "07850": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LANDING"
  },
  "07851": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LAYTON"
  },
  "07852": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LEDGEWOOD"
  },
  "07853": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LONG VALLEY"
  },
  "07856": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MOUNT ARLINGTON"
  },
  "07857": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NETCONG"
  },
  "07860": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEWTON"
  },
  "07863": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "OXFORD"
  },
  "07865": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PORT MURRAY"
  },
  "07866": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ROCKAWAY"
  },
  "07869": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RANDOLPH"
  },
  "07871": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SPARTA"
  },
  "07874": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "STANHOPE"
  },
  "07876": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SUCCASUNNA"
  },
  "07878": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MOUNT TABOR"
  },
  "07882": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WASHINGTON"
  },
  "07885": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WHARTON"
  },
  "07901": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SUMMIT"
  },
  "07920": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BASKING RIDGE"
  },
  "07921": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BEDMINSTER"
  },
  "07922": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BERKELEY HEIGHTS"
  },
  "07924": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BERNARDSVILLE"
  },
  "07927": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CEDAR KNOLLS"
  },
  "07928": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CHATHAM"
  },
  "07930": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CHESTER"
  },
  "07931": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FAR HILLS"
  },
  "07932": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FLORHAM PARK"
  },
  "07933": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "GILLETTE"
  },
  "07934": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "GLADSTONE"
  },
  "07935": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "GREEN VILLAGE"
  },
  "07936": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "EAST HANOVER"
  },
  "07940": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MADISON"
  },
  "07945": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MENDHAM"
  },
  "07946": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MILLINGTON"
  },
  "07950": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MORRIS PLAINS"
  },
  "07960": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MORRISTOWN"
  },
  "07970": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MOUNT FREEDOM"
  },
  "07974": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEW PROVIDENCE"
  },
  "07976": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEW VERNON"
  },
  "07977": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PEAPACK"
  },
  "07979": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "POTTERSVILLE"
  },
  "07980": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "STIRLING"
  },
  "07981": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WHIPPANY"
  },
  "08001": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ALLOWAY"
  },
  "08002": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CHERRY HILL"
  },
  "08003": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CHERRY HILL"
  },
  "08004": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ATCO"
  },
  "08005": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BARNEGAT"
  },
  "08006": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BARNEGAT LIGHT"
  },
  "08007": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BARRINGTON"
  },
  "08008": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BEACH HAVEN"
  },
  "08009": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BERLIN"
  },
  "08010": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BEVERLY"
  },
  "08011": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BIRMINGHAM"
  },
  "08012": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BLACKWOOD"
  },
  "08014": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BRIDGEPORT"
  },
  "08015": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BROWNS MILLS"
  },
  "08016": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BURLINGTON"
  },
  "08019": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CHATSWORTH"
  },
  "08020": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CLARKSBORO"
  },
  "08021": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CLEMENTON"
  },
  "08022": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "COLUMBUS"
  },
  "08023": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "DEEPWATER"
  },
  "08026": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "GIBBSBORO"
  },
  "08027": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "GIBBSTOWN"
  },
  "08028": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "GLASSBORO"
  },
  "08029": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "GLENDORA"
  },
  "08030": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "GLOUCESTER CITY"
  },
  "08031": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BELLMAWR"
  },
  "08032": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "GRENLOCH"
  },
  "08033": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HADDONFIELD"
  },
  "08034": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CHERRY HILL"
  },
  "08035": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HADDON HEIGHTS"
  },
  "08036": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HAINESPORT"
  },
  "08037": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HAMMONTON"
  },
  "08038": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HANCOCKS BRIDGE"
  },
  "08039": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HARRISONVILLE"
  },
  "08041": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "JOBSTOWN"
  },
  "08042": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "JULIUSTOWN"
  },
  "08043": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "VOORHEES"
  },
  "08045": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LAWNSIDE"
  },
  "08046": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WILLINGBORO"
  },
  "08048": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LUMBERTON"
  },
  "08049": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MAGNOLIA"
  },
  "08050": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MANAHAWKIN"
  },
  "08051": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MANTUA"
  },
  "08052": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MAPLE SHADE"
  },
  "08053": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MARLTON"
  },
  "08054": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MOUNT LAUREL"
  },
  "08055": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MEDFORD"
  },
  "08056": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MICKLETON"
  },
  "08057": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MOORESTOWN"
  },
  "08059": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MOUNT EPHRAIM"
  },
  "08060": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MOUNT HOLLY"
  },
  "08061": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MOUNT ROYAL"
  },
  "08062": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MULLICA HILL"
  },
  "08063": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NATIONAL PARK"
  },
  "08064": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEW LISBON"
  },
  "08065": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PALMYRA"
  },
  "08066": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PAULSBORO"
  },
  "08067": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PEDRICKTOWN"
  },
  "08068": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PEMBERTON"
  },
  "08069": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PENNS GROVE"
  },
  "08070": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PENNSVILLE"
  },
  "08071": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PITMAN"
  },
  "08072": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "QUINTON"
  },
  "08073": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RANCOCAS"
  },
  "08074": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RICHWOOD"
  },
  "08075": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RIVERSIDE"
  },
  "08077": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RIVERTON"
  },
  "08078": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RUNNEMEDE"
  },
  "08079": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SALEM"
  },
  "08080": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SEWELL"
  },
  "08081": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SICKLERVILLE"
  },
  "08083": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SOMERDALE"
  },
  "08084": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "STRATFORD"
  },
  "08085": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SWEDESBORO"
  },
  "08086": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "THOROFARE"
  },
  "08087": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TUCKERTON"
  },
  "08088": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "VINCENTOWN"
  },
  "08089": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WATERFORD WORKS"
  },
  "08090": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WENONAH"
  },
  "08091": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WEST BERLIN"
  },
  "08092": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WEST CREEK"
  },
  "08093": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WESTVILLE"
  },
  "08094": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WILLIAMSTOWN"
  },
  "08095": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WINSLOW"
  },
  "08096": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WOODBURY"
  },
  "08097": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WOODBURY HEIGHTS"
  },
  "08098": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WOODSTOWN"
  },
  "08102": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CAMDEN"
  },
  "08103": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CAMDEN"
  },
  "08104": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CAMDEN"
  },
  "08105": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CAMDEN"
  },
  "08106": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "AUDUBON"
  },
  "08107": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "OAKLYN"
  },
  "08108": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "COLLINGSWOOD"
  },
  "08109": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MERCHANTVILLE"
  },
  "08110": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PENNSAUKEN"
  },
  "08201": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ABSECON"
  },
  "08202": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "AVALON"
  },
  "08203": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BRIGANTINE"
  },
  "08204": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CAPE MAY"
  },
  "08210": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CAPE MAY COURT HOUSE"
  },
  "08212": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CAPE MAY POINT"
  },
  "08215": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "EGG HARBOR CITY"
  },
  "08217": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ELWOOD"
  },
  "08221": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LINWOOD"
  },
  "08223": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MARMORA"
  },
  "08224": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEW GRETNA"
  },
  "08225": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NORTHFIELD"
  },
  "08226": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "OCEAN CITY"
  },
  "08230": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "OCEAN VIEW"
  },
  "08232": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PLEASANTVILLE"
  },
  "08234": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "EGG HARBOR TOWNSHIP"
  },
  "08241": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PORT REPUBLIC"
  },
  "08242": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RIO GRANDE"
  },
  "08243": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SEA ISLE CITY"
  },
  "08244": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SOMERS POINT"
  },
  "08245": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SOUTH DENNIS"
  },
  "08247": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "STONE HARBOR"
  },
  "08248": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "STRATHMERE"
  },
  "08251": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "VILLAS"
  },
  "08252": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WHITESBORO"
  },
  "08260": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WILDWOOD"
  },
  "08270": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WOODBINE"
  },
  "08302": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BRIDGETON"
  },
  "08310": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BUENA"
  },
  "08311": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CEDARVILLE"
  },
  "08312": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CLAYTON"
  },
  "08313": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "DEERFIELD STREET"
  },
  "08314": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "DELMONT"
  },
  "08316": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "DORCHESTER"
  },
  "08317": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "DOROTHY"
  },
  "08318": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ELMER"
  },
  "08319": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ESTELL MANOR"
  },
  "08321": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FORTESCUE"
  },
  "08322": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FRANKLINVILLE"
  },
  "08323": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "GREENWICH"
  },
  "08324": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HEISLERVILLE"
  },
  "08326": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LANDISVILLE"
  },
  "08327": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LEESBURG"
  },
  "08328": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MALAGA"
  },
  "08329": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MAURICETOWN"
  },
  "08330": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MAYS LANDING"
  },
  "08332": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MILLVILLE"
  },
  "08340": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MILMAY"
  },
  "08341": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MINOTOLA"
  },
  "08343": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MONROEVILLE"
  },
  "08344": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEWFIELD"
  },
  "08345": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEWPORT"
  },
  "08346": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEWTONVILLE"
  },
  "08348": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PORT ELIZABETH"
  },
  "08349": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PORT NORRIS"
  },
  "08350": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RICHLAND"
  },
  "08352": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ROSENHAYN"
  },
  "08353": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SHILOH"
  },
  "08360": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "VINELAND"
  },
  "08361": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "VINELAND"
  },
  "08401": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ATLANTIC CITY"
  },
  "08402": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MARGATE CITY"
  },
  "08403": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LONGPORT"
  },
  "08406": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "VENTNOR CITY"
  },
  "08501": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ALLENTOWN"
  },
  "08502": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BELLE MEAD"
  },
  "08505": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BORDENTOWN"
  },
  "08510": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MILLSTONE TOWNSHIP"
  },
  "08511": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "COOKSTOWN"
  },
  "08512": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CRANBURY"
  },
  "08514": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CREAM RIDGE"
  },
  "08515": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CHESTERFIELD"
  },
  "08518": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FLORENCE"
  },
  "08520": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HIGHTSTOWN"
  },
  "08525": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HOPEWELL"
  },
  "08527": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "JACKSON"
  },
  "08528": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "KINGSTON"
  },
  "08530": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LAMBERTVILLE"
  },
  "08533": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEW EGYPT"
  },
  "08534": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PENNINGTON"
  },
  "08535": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MILLSTONE TOWNSHIP"
  },
  "08536": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PLAINSBORO"
  },
  "08540": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PRINCETON"
  },
  "08542": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PRINCETON"
  },
  "08550": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PRINCETON JUNCTION"
  },
  "08551": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RINGOES"
  },
  "08553": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ROCKY HILL"
  },
  "08554": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ROEBLING"
  },
  "08555": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ROOSEVELT"
  },
  "08558": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SKILLMAN"
  },
  "08559": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "STOCKTON"
  },
  "08560": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TITUSVILLE"
  },
  "08561": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WINDSOR"
  },
  "08562": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WRIGHTSTOWN"
  },
  "08608": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TRENTON"
  },
  "08609": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TRENTON"
  },
  "08610": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TRENTON"
  },
  "08611": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TRENTON"
  },
  "08618": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TRENTON"
  },
  "08619": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TRENTON"
  },
  "08620": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TRENTON"
  },
  "08628": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TRENTON"
  },
  "08629": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TRENTON"
  },
  "08638": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TRENTON"
  },
  "08640": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FORT DIX"
  },
  "08641": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TRENTON"
  },
  "08648": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LAWRENCE TOWNSHIP"
  },
  "08690": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TRENTON"
  },
  "08691": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TRENTON"
  },
  "08701": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LAKEWOOD"
  },
  "08720": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ALLENWOOD"
  },
  "08721": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BAYVILLE"
  },
  "08722": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BEACHWOOD"
  },
  "08723": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BRICK"
  },
  "08724": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BRICK"
  },
  "08730": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BRIELLE"
  },
  "08731": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FORKED RIVER"
  },
  "08732": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ISLAND HEIGHTS"
  },
  "08733": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LAKEHURST"
  },
  "08734": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LANOKA HARBOR"
  },
  "08735": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LAVALLETTE"
  },
  "08736": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MANASQUAN"
  },
  "08738": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MANTOLOKING"
  },
  "08740": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "OCEAN GATE"
  },
  "08741": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PINE BEACH"
  },
  "08742": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "POINT PLEASANT BEACH"
  },
  "08750": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SEA GIRT"
  },
  "08751": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SEASIDE HEIGHTS"
  },
  "08752": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SEASIDE PARK"
  },
  "08753": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TOMS RIVER"
  },
  "08755": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TOMS RIVER"
  },
  "08757": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "TOMS RIVER"
  },
  "08758": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WARETOWN"
  },
  "08759": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MANCHESTER TOWNSHIP"
  },
  "08801": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ANNANDALE"
  },
  "08802": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ASBURY"
  },
  "08804": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BLOOMSBURY"
  },
  "08805": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BOUND BROOK"
  },
  "08807": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "BRIDGEWATER"
  },
  "08809": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "CLINTON"
  },
  "08810": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "DAYTON"
  },
  "08812": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "DUNELLEN"
  },
  "08816": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "EAST BRUNSWICK"
  },
  "08817": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "EDISON"
  },
  "08820": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "EDISON"
  },
  "08821": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FLAGTOWN"
  },
  "08822": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FLEMINGTON"
  },
  "08823": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FRANKLIN PARK"
  },
  "08824": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "KENDALL PARK"
  },
  "08825": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FRENCHTOWN"
  },
  "08826": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "GLEN GARDNER"
  },
  "08827": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HAMPTON"
  },
  "08828": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HELMETTA"
  },
  "08829": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HIGH BRIDGE"
  },
  "08830": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "ISELIN"
  },
  "08831": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MONROE TOWNSHIP"
  },
  "08832": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "KEASBEY"
  },
  "08833": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "LEBANON"
  },
  "08835": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MANVILLE"
  },
  "08836": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MARTINSVILLE"
  },
  "08837": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "EDISON"
  },
  "08840": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "METUCHEN"
  },
  "08846": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MIDDLESEX"
  },
  "08848": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MILFORD"
  },
  "08850": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MILLTOWN"
  },
  "08852": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "MONMOUTH JUNCTION"
  },
  "08853": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NESHANIC STATION"
  },
  "08854": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PISCATAWAY"
  },
  "08857": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "OLD BRIDGE"
  },
  "08858": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "OLDWICK"
  },
  "08859": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PARLIN"
  },
  "08861": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PERTH AMBOY"
  },
  "08863": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "FORDS"
  },
  "08865": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PHILLIPSBURG"
  },
  "08867": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "PITTSTOWN"
  },
  "08869": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "RARITAN"
  },
  "08872": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SAYREVILLE"
  },
  "08873": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SOMERSET"
  },
  "08876": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SOMERVILLE"
  },
  "08879": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SOUTH AMBOY"
  },
  "08880": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SOUTH BOUND BROOK"
  },
  "08882": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SOUTH RIVER"
  },
  "08884": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "SPOTSWOOD"
  },
  "08886": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "STEWARTSVILLE"
  },
  "08887": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "THREE BRIDGES"
  },
  "08889": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "WHITEHOUSE STATION"
  },
  "08901": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NEW BRUNSWICK"
  },
  "08902": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "NORTH BRUNSWICK"
  },
  "08904": {
    stateCode: "NJ",
    stateName: "New Jersey",
    city: "HIGHLAND PARK"
  },
  "10001": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10002": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10003": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10004": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10005": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10006": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10007": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10009": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10010": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10011": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10012": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10013": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10014": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10016": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10017": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10018": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10019": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10021": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10022": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10023": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10024": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10025": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10026": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10027": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10028": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10029": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10030": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10031": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10032": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10033": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10034": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10035": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10036": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10037": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10038": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10039": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10040": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10044": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10069": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10128": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10162": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10165": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10280": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10282": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK"
  },
  "10301": {
    stateCode: "NY",
    stateName: "New York",
    city: "STATEN ISLAND"
  },
  "10302": {
    stateCode: "NY",
    stateName: "New York",
    city: "STATEN ISLAND"
  },
  "10303": {
    stateCode: "NY",
    stateName: "New York",
    city: "STATEN ISLAND"
  },
  "10304": {
    stateCode: "NY",
    stateName: "New York",
    city: "STATEN ISLAND"
  },
  "10305": {
    stateCode: "NY",
    stateName: "New York",
    city: "STATEN ISLAND"
  },
  "10306": {
    stateCode: "NY",
    stateName: "New York",
    city: "STATEN ISLAND"
  },
  "10307": {
    stateCode: "NY",
    stateName: "New York",
    city: "STATEN ISLAND"
  },
  "10308": {
    stateCode: "NY",
    stateName: "New York",
    city: "STATEN ISLAND"
  },
  "10309": {
    stateCode: "NY",
    stateName: "New York",
    city: "STATEN ISLAND"
  },
  "10310": {
    stateCode: "NY",
    stateName: "New York",
    city: "STATEN ISLAND"
  },
  "10312": {
    stateCode: "NY",
    stateName: "New York",
    city: "STATEN ISLAND"
  },
  "10314": {
    stateCode: "NY",
    stateName: "New York",
    city: "STATEN ISLAND"
  },
  "10451": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10452": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10453": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10454": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10455": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10456": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10457": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10458": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10459": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10460": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10461": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10462": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10463": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10464": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10465": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10466": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10467": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10468": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10469": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10470": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10471": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10472": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10473": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10474": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10475": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONX"
  },
  "10501": {
    stateCode: "NY",
    stateName: "New York",
    city: "AMAWALK"
  },
  "10502": {
    stateCode: "NY",
    stateName: "New York",
    city: "ARDSLEY"
  },
  "10503": {
    stateCode: "NY",
    stateName: "New York",
    city: "ARDSLEY ON HUDSON"
  },
  "10504": {
    stateCode: "NY",
    stateName: "New York",
    city: "ARMONK"
  },
  "10506": {
    stateCode: "NY",
    stateName: "New York",
    city: "BEDFORD"
  },
  "10507": {
    stateCode: "NY",
    stateName: "New York",
    city: "BEDFORD HILLS"
  },
  "10509": {
    stateCode: "NY",
    stateName: "New York",
    city: "BREWSTER"
  },
  "10510": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRIARCLIFF MANOR"
  },
  "10511": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUCHANAN"
  },
  "10512": {
    stateCode: "NY",
    stateName: "New York",
    city: "CARMEL"
  },
  "10514": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHAPPAQUA"
  },
  "10516": {
    stateCode: "NY",
    stateName: "New York",
    city: "COLD SPRING"
  },
  "10518": {
    stateCode: "NY",
    stateName: "New York",
    city: "CROSS RIVER"
  },
  "10519": {
    stateCode: "NY",
    stateName: "New York",
    city: "CROTON FALLS"
  },
  "10520": {
    stateCode: "NY",
    stateName: "New York",
    city: "CROTON ON HUDSON"
  },
  "10522": {
    stateCode: "NY",
    stateName: "New York",
    city: "DOBBS FERRY"
  },
  "10523": {
    stateCode: "NY",
    stateName: "New York",
    city: "ELMSFORD"
  },
  "10524": {
    stateCode: "NY",
    stateName: "New York",
    city: "GARRISON"
  },
  "10526": {
    stateCode: "NY",
    stateName: "New York",
    city: "GOLDENS BRIDGE"
  },
  "10527": {
    stateCode: "NY",
    stateName: "New York",
    city: "GRANITE SPRINGS"
  },
  "10528": {
    stateCode: "NY",
    stateName: "New York",
    city: "HARRISON"
  },
  "10530": {
    stateCode: "NY",
    stateName: "New York",
    city: "HARTSDALE"
  },
  "10532": {
    stateCode: "NY",
    stateName: "New York",
    city: "HAWTHORNE"
  },
  "10533": {
    stateCode: "NY",
    stateName: "New York",
    city: "IRVINGTON"
  },
  "10535": {
    stateCode: "NY",
    stateName: "New York",
    city: "JEFFERSON VALLEY"
  },
  "10536": {
    stateCode: "NY",
    stateName: "New York",
    city: "KATONAH"
  },
  "10537": {
    stateCode: "NY",
    stateName: "New York",
    city: "LAKE PEEKSKILL"
  },
  "10538": {
    stateCode: "NY",
    stateName: "New York",
    city: "LARCHMONT"
  },
  "10541": {
    stateCode: "NY",
    stateName: "New York",
    city: "MAHOPAC"
  },
  "10543": {
    stateCode: "NY",
    stateName: "New York",
    city: "MAMARONECK"
  },
  "10546": {
    stateCode: "NY",
    stateName: "New York",
    city: "MILLWOOD"
  },
  "10547": {
    stateCode: "NY",
    stateName: "New York",
    city: "MOHEGAN LAKE"
  },
  "10548": {
    stateCode: "NY",
    stateName: "New York",
    city: "MONTROSE"
  },
  "10549": {
    stateCode: "NY",
    stateName: "New York",
    city: "MOUNT KISCO"
  },
  "10550": {
    stateCode: "NY",
    stateName: "New York",
    city: "MOUNT VERNON"
  },
  "10552": {
    stateCode: "NY",
    stateName: "New York",
    city: "MOUNT VERNON"
  },
  "10553": {
    stateCode: "NY",
    stateName: "New York",
    city: "MOUNT VERNON"
  },
  "10560": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORTH SALEM"
  },
  "10562": {
    stateCode: "NY",
    stateName: "New York",
    city: "OSSINING"
  },
  "10566": {
    stateCode: "NY",
    stateName: "New York",
    city: "PEEKSKILL"
  },
  "10567": {
    stateCode: "NY",
    stateName: "New York",
    city: "CORTLANDT MANOR"
  },
  "10570": {
    stateCode: "NY",
    stateName: "New York",
    city: "PLEASANTVILLE"
  },
  "10573": {
    stateCode: "NY",
    stateName: "New York",
    city: "PORT CHESTER"
  },
  "10576": {
    stateCode: "NY",
    stateName: "New York",
    city: "POUND RIDGE"
  },
  "10577": {
    stateCode: "NY",
    stateName: "New York",
    city: "PURCHASE"
  },
  "10578": {
    stateCode: "NY",
    stateName: "New York",
    city: "PURDYS"
  },
  "10579": {
    stateCode: "NY",
    stateName: "New York",
    city: "PUTNAM VALLEY"
  },
  "10580": {
    stateCode: "NY",
    stateName: "New York",
    city: "RYE"
  },
  "10583": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCARSDALE"
  },
  "10588": {
    stateCode: "NY",
    stateName: "New York",
    city: "SHRUB OAK"
  },
  "10589": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOMERS"
  },
  "10590": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOUTH SALEM"
  },
  "10591": {
    stateCode: "NY",
    stateName: "New York",
    city: "TARRYTOWN"
  },
  "10594": {
    stateCode: "NY",
    stateName: "New York",
    city: "THORNWOOD"
  },
  "10595": {
    stateCode: "NY",
    stateName: "New York",
    city: "VALHALLA"
  },
  "10597": {
    stateCode: "NY",
    stateName: "New York",
    city: "WACCABUC"
  },
  "10598": {
    stateCode: "NY",
    stateName: "New York",
    city: "YORKTOWN HEIGHTS"
  },
  "10601": {
    stateCode: "NY",
    stateName: "New York",
    city: "WHITE PLAINS"
  },
  "10603": {
    stateCode: "NY",
    stateName: "New York",
    city: "WHITE PLAINS"
  },
  "10604": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST HARRISON"
  },
  "10605": {
    stateCode: "NY",
    stateName: "New York",
    city: "WHITE PLAINS"
  },
  "10606": {
    stateCode: "NY",
    stateName: "New York",
    city: "WHITE PLAINS"
  },
  "10607": {
    stateCode: "NY",
    stateName: "New York",
    city: "WHITE PLAINS"
  },
  "10701": {
    stateCode: "NY",
    stateName: "New York",
    city: "YONKERS"
  },
  "10703": {
    stateCode: "NY",
    stateName: "New York",
    city: "YONKERS"
  },
  "10704": {
    stateCode: "NY",
    stateName: "New York",
    city: "YONKERS"
  },
  "10705": {
    stateCode: "NY",
    stateName: "New York",
    city: "YONKERS"
  },
  "10706": {
    stateCode: "NY",
    stateName: "New York",
    city: "HASTINGS ON HUDSON"
  },
  "10707": {
    stateCode: "NY",
    stateName: "New York",
    city: "TUCKAHOE"
  },
  "10708": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRONXVILLE"
  },
  "10709": {
    stateCode: "NY",
    stateName: "New York",
    city: "EASTCHESTER"
  },
  "10710": {
    stateCode: "NY",
    stateName: "New York",
    city: "YONKERS"
  },
  "10801": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW ROCHELLE"
  },
  "10803": {
    stateCode: "NY",
    stateName: "New York",
    city: "PELHAM"
  },
  "10804": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW ROCHELLE"
  },
  "10805": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW ROCHELLE"
  },
  "10901": {
    stateCode: "NY",
    stateName: "New York",
    city: "SUFFERN"
  },
  "10913": {
    stateCode: "NY",
    stateName: "New York",
    city: "BLAUVELT"
  },
  "10916": {
    stateCode: "NY",
    stateName: "New York",
    city: "CAMPBELL HALL"
  },
  "10917": {
    stateCode: "NY",
    stateName: "New York",
    city: "CENTRAL VALLEY"
  },
  "10918": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHESTER"
  },
  "10919": {
    stateCode: "NY",
    stateName: "New York",
    city: "CIRCLEVILLE"
  },
  "10920": {
    stateCode: "NY",
    stateName: "New York",
    city: "CONGERS"
  },
  "10921": {
    stateCode: "NY",
    stateName: "New York",
    city: "FLORIDA"
  },
  "10923": {
    stateCode: "NY",
    stateName: "New York",
    city: "GARNERVILLE"
  },
  "10924": {
    stateCode: "NY",
    stateName: "New York",
    city: "GOSHEN"
  },
  "10925": {
    stateCode: "NY",
    stateName: "New York",
    city: "GREENWOOD LAKE"
  },
  "10926": {
    stateCode: "NY",
    stateName: "New York",
    city: "HARRIMAN"
  },
  "10927": {
    stateCode: "NY",
    stateName: "New York",
    city: "HAVERSTRAW"
  },
  "10928": {
    stateCode: "NY",
    stateName: "New York",
    city: "HIGHLAND FALLS"
  },
  "10930": {
    stateCode: "NY",
    stateName: "New York",
    city: "HIGHLAND MILLS"
  },
  "10931": {
    stateCode: "NY",
    stateName: "New York",
    city: "HILLBURN"
  },
  "10940": {
    stateCode: "NY",
    stateName: "New York",
    city: "MIDDLETOWN"
  },
  "10941": {
    stateCode: "NY",
    stateName: "New York",
    city: "MIDDLETOWN"
  },
  "10950": {
    stateCode: "NY",
    stateName: "New York",
    city: "MONROE"
  },
  "10952": {
    stateCode: "NY",
    stateName: "New York",
    city: "MONSEY"
  },
  "10954": {
    stateCode: "NY",
    stateName: "New York",
    city: "NANUET"
  },
  "10956": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW CITY"
  },
  "10958": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW HAMPTON"
  },
  "10960": {
    stateCode: "NY",
    stateName: "New York",
    city: "NYACK"
  },
  "10962": {
    stateCode: "NY",
    stateName: "New York",
    city: "ORANGEBURG"
  },
  "10963": {
    stateCode: "NY",
    stateName: "New York",
    city: "OTISVILLE"
  },
  "10964": {
    stateCode: "NY",
    stateName: "New York",
    city: "PALISADES"
  },
  "10965": {
    stateCode: "NY",
    stateName: "New York",
    city: "PEARL RIVER"
  },
  "10968": {
    stateCode: "NY",
    stateName: "New York",
    city: "PIERMONT"
  },
  "10969": {
    stateCode: "NY",
    stateName: "New York",
    city: "PINE ISLAND"
  },
  "10970": {
    stateCode: "NY",
    stateName: "New York",
    city: "POMONA"
  },
  "10973": {
    stateCode: "NY",
    stateName: "New York",
    city: "SLATE HILL"
  },
  "10974": {
    stateCode: "NY",
    stateName: "New York",
    city: "SLOATSBURG"
  },
  "10975": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOUTHFIELDS"
  },
  "10976": {
    stateCode: "NY",
    stateName: "New York",
    city: "SPARKILL"
  },
  "10977": {
    stateCode: "NY",
    stateName: "New York",
    city: "SPRING VALLEY"
  },
  "10980": {
    stateCode: "NY",
    stateName: "New York",
    city: "STONY POINT"
  },
  "10983": {
    stateCode: "NY",
    stateName: "New York",
    city: "TAPPAN"
  },
  "10984": {
    stateCode: "NY",
    stateName: "New York",
    city: "THIELLS"
  },
  "10985": {
    stateCode: "NY",
    stateName: "New York",
    city: "THOMPSON RIDGE"
  },
  "10986": {
    stateCode: "NY",
    stateName: "New York",
    city: "TOMKINS COVE"
  },
  "10987": {
    stateCode: "NY",
    stateName: "New York",
    city: "TUXEDO PARK"
  },
  "10989": {
    stateCode: "NY",
    stateName: "New York",
    city: "VALLEY COTTAGE"
  },
  "10990": {
    stateCode: "NY",
    stateName: "New York",
    city: "WARWICK"
  },
  "10992": {
    stateCode: "NY",
    stateName: "New York",
    city: "WASHINGTONVILLE"
  },
  "10993": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST HAVERSTRAW"
  },
  "10994": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST NYACK"
  },
  "10996": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST POINT"
  },
  "10998": {
    stateCode: "NY",
    stateName: "New York",
    city: "WESTTOWN"
  },
  "11001": {
    stateCode: "NY",
    stateName: "New York",
    city: "FLORAL PARK"
  },
  "11003": {
    stateCode: "NY",
    stateName: "New York",
    city: "ELMONT"
  },
  "11004": {
    stateCode: "NY",
    stateName: "New York",
    city: "GLEN OAKS"
  },
  "11005": {
    stateCode: "NY",
    stateName: "New York",
    city: "FLORAL PARK"
  },
  "11010": {
    stateCode: "NY",
    stateName: "New York",
    city: "FRANKLIN SQUARE"
  },
  "11020": {
    stateCode: "NY",
    stateName: "New York",
    city: "GREAT NECK"
  },
  "11021": {
    stateCode: "NY",
    stateName: "New York",
    city: "GREAT NECK"
  },
  "11023": {
    stateCode: "NY",
    stateName: "New York",
    city: "GREAT NECK"
  },
  "11024": {
    stateCode: "NY",
    stateName: "New York",
    city: "GREAT NECK"
  },
  "11030": {
    stateCode: "NY",
    stateName: "New York",
    city: "MANHASSET"
  },
  "11040": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW HYDE PARK"
  },
  "11050": {
    stateCode: "NY",
    stateName: "New York",
    city: "PORT WASHINGTON"
  },
  "11096": {
    stateCode: "NY",
    stateName: "New York",
    city: "INWOOD"
  },
  "11101": {
    stateCode: "NY",
    stateName: "New York",
    city: "LONG ISLAND CITY"
  },
  "11102": {
    stateCode: "NY",
    stateName: "New York",
    city: "ASTORIA"
  },
  "11103": {
    stateCode: "NY",
    stateName: "New York",
    city: "ASTORIA"
  },
  "11104": {
    stateCode: "NY",
    stateName: "New York",
    city: "SUNNYSIDE"
  },
  "11105": {
    stateCode: "NY",
    stateName: "New York",
    city: "ASTORIA"
  },
  "11106": {
    stateCode: "NY",
    stateName: "New York",
    city: "ASTORIA"
  },
  "11201": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11203": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11204": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11205": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11206": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11207": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11208": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11209": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11210": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11211": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11212": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11213": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11214": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11215": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11216": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11217": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11218": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11219": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11220": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11221": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11222": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11223": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11224": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11225": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11226": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11228": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11229": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11230": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11231": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11232": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11233": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11234": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11235": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11236": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11237": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11238": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11239": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKLYN"
  },
  "11354": {
    stateCode: "NY",
    stateName: "New York",
    city: "FLUSHING"
  },
  "11355": {
    stateCode: "NY",
    stateName: "New York",
    city: "FLUSHING"
  },
  "11356": {
    stateCode: "NY",
    stateName: "New York",
    city: "COLLEGE POINT"
  },
  "11357": {
    stateCode: "NY",
    stateName: "New York",
    city: "WHITESTONE"
  },
  "11358": {
    stateCode: "NY",
    stateName: "New York",
    city: "FLUSHING"
  },
  "11360": {
    stateCode: "NY",
    stateName: "New York",
    city: "BAYSIDE"
  },
  "11361": {
    stateCode: "NY",
    stateName: "New York",
    city: "BAYSIDE"
  },
  "11362": {
    stateCode: "NY",
    stateName: "New York",
    city: "LITTLE NECK"
  },
  "11363": {
    stateCode: "NY",
    stateName: "New York",
    city: "LITTLE NECK"
  },
  "11364": {
    stateCode: "NY",
    stateName: "New York",
    city: "OAKLAND GARDENS"
  },
  "11365": {
    stateCode: "NY",
    stateName: "New York",
    city: "FRESH MEADOWS"
  },
  "11366": {
    stateCode: "NY",
    stateName: "New York",
    city: "FRESH MEADOWS"
  },
  "11367": {
    stateCode: "NY",
    stateName: "New York",
    city: "FLUSHING"
  },
  "11368": {
    stateCode: "NY",
    stateName: "New York",
    city: "CORONA"
  },
  "11369": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST ELMHURST"
  },
  "11370": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST ELMHURST"
  },
  "11372": {
    stateCode: "NY",
    stateName: "New York",
    city: "JACKSON HEIGHTS"
  },
  "11373": {
    stateCode: "NY",
    stateName: "New York",
    city: "ELMHURST"
  },
  "11374": {
    stateCode: "NY",
    stateName: "New York",
    city: "REGO PARK"
  },
  "11375": {
    stateCode: "NY",
    stateName: "New York",
    city: "FOREST HILLS"
  },
  "11377": {
    stateCode: "NY",
    stateName: "New York",
    city: "WOODSIDE"
  },
  "11378": {
    stateCode: "NY",
    stateName: "New York",
    city: "MASPETH"
  },
  "11379": {
    stateCode: "NY",
    stateName: "New York",
    city: "MIDDLE VILLAGE"
  },
  "11385": {
    stateCode: "NY",
    stateName: "New York",
    city: "RIDGEWOOD"
  },
  "11411": {
    stateCode: "NY",
    stateName: "New York",
    city: "CAMBRIA HEIGHTS"
  },
  "11412": {
    stateCode: "NY",
    stateName: "New York",
    city: "SAINT ALBANS"
  },
  "11413": {
    stateCode: "NY",
    stateName: "New York",
    city: "SPRINGFIELD GARDENS"
  },
  "11414": {
    stateCode: "NY",
    stateName: "New York",
    city: "HOWARD BEACH"
  },
  "11415": {
    stateCode: "NY",
    stateName: "New York",
    city: "KEW GARDENS"
  },
  "11416": {
    stateCode: "NY",
    stateName: "New York",
    city: "OZONE PARK"
  },
  "11417": {
    stateCode: "NY",
    stateName: "New York",
    city: "OZONE PARK"
  },
  "11418": {
    stateCode: "NY",
    stateName: "New York",
    city: "RICHMOND HILL"
  },
  "11419": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOUTH RICHMOND HILL"
  },
  "11420": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOUTH OZONE PARK"
  },
  "11421": {
    stateCode: "NY",
    stateName: "New York",
    city: "WOODHAVEN"
  },
  "11422": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROSEDALE"
  },
  "11423": {
    stateCode: "NY",
    stateName: "New York",
    city: "HOLLIS"
  },
  "11426": {
    stateCode: "NY",
    stateName: "New York",
    city: "BELLEROSE"
  },
  "11427": {
    stateCode: "NY",
    stateName: "New York",
    city: "QUEENS VILLAGE"
  },
  "11428": {
    stateCode: "NY",
    stateName: "New York",
    city: "QUEENS VILLAGE"
  },
  "11429": {
    stateCode: "NY",
    stateName: "New York",
    city: "QUEENS VILLAGE"
  },
  "11430": {
    stateCode: "NY",
    stateName: "New York",
    city: "JAMAICA"
  },
  "11432": {
    stateCode: "NY",
    stateName: "New York",
    city: "JAMAICA"
  },
  "11433": {
    stateCode: "NY",
    stateName: "New York",
    city: "JAMAICA"
  },
  "11434": {
    stateCode: "NY",
    stateName: "New York",
    city: "JAMAICA"
  },
  "11435": {
    stateCode: "NY",
    stateName: "New York",
    city: "JAMAICA"
  },
  "11436": {
    stateCode: "NY",
    stateName: "New York",
    city: "JAMAICA"
  },
  "11501": {
    stateCode: "NY",
    stateName: "New York",
    city: "MINEOLA"
  },
  "11507": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALBERTSON"
  },
  "11509": {
    stateCode: "NY",
    stateName: "New York",
    city: "ATLANTIC BEACH"
  },
  "11510": {
    stateCode: "NY",
    stateName: "New York",
    city: "BALDWIN"
  },
  "11514": {
    stateCode: "NY",
    stateName: "New York",
    city: "CARLE PLACE"
  },
  "11516": {
    stateCode: "NY",
    stateName: "New York",
    city: "CEDARHURST"
  },
  "11518": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST ROCKAWAY"
  },
  "11520": {
    stateCode: "NY",
    stateName: "New York",
    city: "FREEPORT"
  },
  "11530": {
    stateCode: "NY",
    stateName: "New York",
    city: "GARDEN CITY"
  },
  "11542": {
    stateCode: "NY",
    stateName: "New York",
    city: "GLEN COVE"
  },
  "11545": {
    stateCode: "NY",
    stateName: "New York",
    city: "GLEN HEAD"
  },
  "11547": {
    stateCode: "NY",
    stateName: "New York",
    city: "GLENWOOD LANDING"
  },
  "11548": {
    stateCode: "NY",
    stateName: "New York",
    city: "GREENVALE"
  },
  "11550": {
    stateCode: "NY",
    stateName: "New York",
    city: "HEMPSTEAD"
  },
  "11552": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST HEMPSTEAD"
  },
  "11553": {
    stateCode: "NY",
    stateName: "New York",
    city: "UNIONDALE"
  },
  "11554": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST MEADOW"
  },
  "11557": {
    stateCode: "NY",
    stateName: "New York",
    city: "HEWLETT"
  },
  "11558": {
    stateCode: "NY",
    stateName: "New York",
    city: "ISLAND PARK"
  },
  "11559": {
    stateCode: "NY",
    stateName: "New York",
    city: "LAWRENCE"
  },
  "11560": {
    stateCode: "NY",
    stateName: "New York",
    city: "LOCUST VALLEY"
  },
  "11561": {
    stateCode: "NY",
    stateName: "New York",
    city: "LONG BEACH"
  },
  "11563": {
    stateCode: "NY",
    stateName: "New York",
    city: "LYNBROOK"
  },
  "11565": {
    stateCode: "NY",
    stateName: "New York",
    city: "MALVERNE"
  },
  "11566": {
    stateCode: "NY",
    stateName: "New York",
    city: "MERRICK"
  },
  "11568": {
    stateCode: "NY",
    stateName: "New York",
    city: "OLD WESTBURY"
  },
  "11569": {
    stateCode: "NY",
    stateName: "New York",
    city: "POINT LOOKOUT"
  },
  "11570": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCKVILLE CENTRE"
  },
  "11572": {
    stateCode: "NY",
    stateName: "New York",
    city: "OCEANSIDE"
  },
  "11575": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROOSEVELT"
  },
  "11576": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROSLYN"
  },
  "11577": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROSLYN HEIGHTS"
  },
  "11579": {
    stateCode: "NY",
    stateName: "New York",
    city: "SEA CLIFF"
  },
  "11580": {
    stateCode: "NY",
    stateName: "New York",
    city: "VALLEY STREAM"
  },
  "11581": {
    stateCode: "NY",
    stateName: "New York",
    city: "VALLEY STREAM"
  },
  "11590": {
    stateCode: "NY",
    stateName: "New York",
    city: "WESTBURY"
  },
  "11596": {
    stateCode: "NY",
    stateName: "New York",
    city: "WILLISTON PARK"
  },
  "11598": {
    stateCode: "NY",
    stateName: "New York",
    city: "WOODMERE"
  },
  "11691": {
    stateCode: "NY",
    stateName: "New York",
    city: "FAR ROCKAWAY"
  },
  "11692": {
    stateCode: "NY",
    stateName: "New York",
    city: "ARVERNE"
  },
  "11693": {
    stateCode: "NY",
    stateName: "New York",
    city: "FAR ROCKAWAY"
  },
  "11694": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCKAWAY PARK"
  },
  "11697": {
    stateCode: "NY",
    stateName: "New York",
    city: "BREEZY POINT"
  },
  "11701": {
    stateCode: "NY",
    stateName: "New York",
    city: "AMITYVILLE"
  },
  "11702": {
    stateCode: "NY",
    stateName: "New York",
    city: "BABYLON"
  },
  "11703": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORTH BABYLON"
  },
  "11704": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST BABYLON"
  },
  "11705": {
    stateCode: "NY",
    stateName: "New York",
    city: "BAYPORT"
  },
  "11706": {
    stateCode: "NY",
    stateName: "New York",
    city: "BAY SHORE"
  },
  "11709": {
    stateCode: "NY",
    stateName: "New York",
    city: "BAYVILLE"
  },
  "11710": {
    stateCode: "NY",
    stateName: "New York",
    city: "BELLMORE"
  },
  "11713": {
    stateCode: "NY",
    stateName: "New York",
    city: "BELLPORT"
  },
  "11714": {
    stateCode: "NY",
    stateName: "New York",
    city: "BETHPAGE"
  },
  "11715": {
    stateCode: "NY",
    stateName: "New York",
    city: "BLUE POINT"
  },
  "11716": {
    stateCode: "NY",
    stateName: "New York",
    city: "BOHEMIA"
  },
  "11717": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRENTWOOD"
  },
  "11718": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRIGHTWATERS"
  },
  "11719": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKHAVEN"
  },
  "11720": {
    stateCode: "NY",
    stateName: "New York",
    city: "CENTEREACH"
  },
  "11721": {
    stateCode: "NY",
    stateName: "New York",
    city: "CENTERPORT"
  },
  "11722": {
    stateCode: "NY",
    stateName: "New York",
    city: "CENTRAL ISLIP"
  },
  "11724": {
    stateCode: "NY",
    stateName: "New York",
    city: "COLD SPRING HARBOR"
  },
  "11725": {
    stateCode: "NY",
    stateName: "New York",
    city: "COMMACK"
  },
  "11726": {
    stateCode: "NY",
    stateName: "New York",
    city: "COPIAGUE"
  },
  "11727": {
    stateCode: "NY",
    stateName: "New York",
    city: "CORAM"
  },
  "11729": {
    stateCode: "NY",
    stateName: "New York",
    city: "DEER PARK"
  },
  "11730": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST ISLIP"
  },
  "11731": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST NORTHPORT"
  },
  "11732": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST NORWICH"
  },
  "11733": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST SETAUKET"
  },
  "11735": {
    stateCode: "NY",
    stateName: "New York",
    city: "FARMINGDALE"
  },
  "11738": {
    stateCode: "NY",
    stateName: "New York",
    city: "FARMINGVILLE"
  },
  "11740": {
    stateCode: "NY",
    stateName: "New York",
    city: "GREENLAWN"
  },
  "11741": {
    stateCode: "NY",
    stateName: "New York",
    city: "HOLBROOK"
  },
  "11742": {
    stateCode: "NY",
    stateName: "New York",
    city: "HOLTSVILLE"
  },
  "11743": {
    stateCode: "NY",
    stateName: "New York",
    city: "HUNTINGTON"
  },
  "11746": {
    stateCode: "NY",
    stateName: "New York",
    city: "HUNTINGTON STATION"
  },
  "11747": {
    stateCode: "NY",
    stateName: "New York",
    city: "MELVILLE"
  },
  "11751": {
    stateCode: "NY",
    stateName: "New York",
    city: "ISLIP"
  },
  "11752": {
    stateCode: "NY",
    stateName: "New York",
    city: "ISLIP TERRACE"
  },
  "11753": {
    stateCode: "NY",
    stateName: "New York",
    city: "JERICHO"
  },
  "11754": {
    stateCode: "NY",
    stateName: "New York",
    city: "KINGS PARK"
  },
  "11755": {
    stateCode: "NY",
    stateName: "New York",
    city: "LAKE GROVE"
  },
  "11756": {
    stateCode: "NY",
    stateName: "New York",
    city: "LEVITTOWN"
  },
  "11757": {
    stateCode: "NY",
    stateName: "New York",
    city: "LINDENHURST"
  },
  "11758": {
    stateCode: "NY",
    stateName: "New York",
    city: "MASSAPEQUA"
  },
  "11762": {
    stateCode: "NY",
    stateName: "New York",
    city: "MASSAPEQUA PARK"
  },
  "11763": {
    stateCode: "NY",
    stateName: "New York",
    city: "MEDFORD"
  },
  "11764": {
    stateCode: "NY",
    stateName: "New York",
    city: "MILLER PLACE"
  },
  "11765": {
    stateCode: "NY",
    stateName: "New York",
    city: "MILL NECK"
  },
  "11766": {
    stateCode: "NY",
    stateName: "New York",
    city: "MOUNT SINAI"
  },
  "11767": {
    stateCode: "NY",
    stateName: "New York",
    city: "NESCONSET"
  },
  "11768": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORTHPORT"
  },
  "11769": {
    stateCode: "NY",
    stateName: "New York",
    city: "OAKDALE"
  },
  "11770": {
    stateCode: "NY",
    stateName: "New York",
    city: "OCEAN BEACH"
  },
  "11771": {
    stateCode: "NY",
    stateName: "New York",
    city: "OYSTER BAY"
  },
  "11772": {
    stateCode: "NY",
    stateName: "New York",
    city: "PATCHOGUE"
  },
  "11776": {
    stateCode: "NY",
    stateName: "New York",
    city: "PORT JEFFERSON STATION"
  },
  "11777": {
    stateCode: "NY",
    stateName: "New York",
    city: "PORT JEFFERSON"
  },
  "11778": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCKY POINT"
  },
  "11779": {
    stateCode: "NY",
    stateName: "New York",
    city: "RONKONKOMA"
  },
  "11780": {
    stateCode: "NY",
    stateName: "New York",
    city: "SAINT JAMES"
  },
  "11782": {
    stateCode: "NY",
    stateName: "New York",
    city: "SAYVILLE"
  },
  "11783": {
    stateCode: "NY",
    stateName: "New York",
    city: "SEAFORD"
  },
  "11784": {
    stateCode: "NY",
    stateName: "New York",
    city: "SELDEN"
  },
  "11786": {
    stateCode: "NY",
    stateName: "New York",
    city: "SHOREHAM"
  },
  "11787": {
    stateCode: "NY",
    stateName: "New York",
    city: "SMITHTOWN"
  },
  "11788": {
    stateCode: "NY",
    stateName: "New York",
    city: "HAUPPAUGE"
  },
  "11789": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOUND BEACH"
  },
  "11790": {
    stateCode: "NY",
    stateName: "New York",
    city: "STONY BROOK"
  },
  "11791": {
    stateCode: "NY",
    stateName: "New York",
    city: "SYOSSET"
  },
  "11792": {
    stateCode: "NY",
    stateName: "New York",
    city: "WADING RIVER"
  },
  "11793": {
    stateCode: "NY",
    stateName: "New York",
    city: "WANTAGH"
  },
  "11795": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST ISLIP"
  },
  "11796": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST SAYVILLE"
  },
  "11797": {
    stateCode: "NY",
    stateName: "New York",
    city: "WOODBURY"
  },
  "11798": {
    stateCode: "NY",
    stateName: "New York",
    city: "WYANDANCH"
  },
  "11801": {
    stateCode: "NY",
    stateName: "New York",
    city: "HICKSVILLE"
  },
  "11803": {
    stateCode: "NY",
    stateName: "New York",
    city: "PLAINVIEW"
  },
  "11804": {
    stateCode: "NY",
    stateName: "New York",
    city: "OLD BETHPAGE"
  },
  "11901": {
    stateCode: "NY",
    stateName: "New York",
    city: "RIVERHEAD"
  },
  "11930": {
    stateCode: "NY",
    stateName: "New York",
    city: "AMAGANSETT"
  },
  "11932": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRIDGEHAMPTON"
  },
  "11933": {
    stateCode: "NY",
    stateName: "New York",
    city: "CALVERTON"
  },
  "11934": {
    stateCode: "NY",
    stateName: "New York",
    city: "CENTER MORICHES"
  },
  "11935": {
    stateCode: "NY",
    stateName: "New York",
    city: "CUTCHOGUE"
  },
  "11937": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST HAMPTON"
  },
  "11939": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST MARION"
  },
  "11940": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST MORICHES"
  },
  "11941": {
    stateCode: "NY",
    stateName: "New York",
    city: "EASTPORT"
  },
  "11942": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST QUOGUE"
  },
  "11944": {
    stateCode: "NY",
    stateName: "New York",
    city: "GREENPORT"
  },
  "11946": {
    stateCode: "NY",
    stateName: "New York",
    city: "HAMPTON BAYS"
  },
  "11947": {
    stateCode: "NY",
    stateName: "New York",
    city: "JAMESPORT"
  },
  "11948": {
    stateCode: "NY",
    stateName: "New York",
    city: "LAUREL"
  },
  "11949": {
    stateCode: "NY",
    stateName: "New York",
    city: "MANORVILLE"
  },
  "11950": {
    stateCode: "NY",
    stateName: "New York",
    city: "MASTIC"
  },
  "11951": {
    stateCode: "NY",
    stateName: "New York",
    city: "MASTIC BEACH"
  },
  "11952": {
    stateCode: "NY",
    stateName: "New York",
    city: "MATTITUCK"
  },
  "11953": {
    stateCode: "NY",
    stateName: "New York",
    city: "MIDDLE ISLAND"
  },
  "11954": {
    stateCode: "NY",
    stateName: "New York",
    city: "MONTAUK"
  },
  "11955": {
    stateCode: "NY",
    stateName: "New York",
    city: "MORICHES"
  },
  "11956": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW SUFFOLK"
  },
  "11957": {
    stateCode: "NY",
    stateName: "New York",
    city: "ORIENT"
  },
  "11958": {
    stateCode: "NY",
    stateName: "New York",
    city: "PECONIC"
  },
  "11959": {
    stateCode: "NY",
    stateName: "New York",
    city: "QUOGUE"
  },
  "11960": {
    stateCode: "NY",
    stateName: "New York",
    city: "REMSENBURG"
  },
  "11961": {
    stateCode: "NY",
    stateName: "New York",
    city: "RIDGE"
  },
  "11962": {
    stateCode: "NY",
    stateName: "New York",
    city: "SAGAPONACK"
  },
  "11963": {
    stateCode: "NY",
    stateName: "New York",
    city: "SAG HARBOR"
  },
  "11964": {
    stateCode: "NY",
    stateName: "New York",
    city: "SHELTER ISLAND"
  },
  "11965": {
    stateCode: "NY",
    stateName: "New York",
    city: "SHELTER ISLAND HEIGHTS"
  },
  "11967": {
    stateCode: "NY",
    stateName: "New York",
    city: "SHIRLEY"
  },
  "11968": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOUTHAMPTON"
  },
  "11970": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOUTH JAMESPORT"
  },
  "11971": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOUTHOLD"
  },
  "11972": {
    stateCode: "NY",
    stateName: "New York",
    city: "SPEONK"
  },
  "11975": {
    stateCode: "NY",
    stateName: "New York",
    city: "WAINSCOTT"
  },
  "11976": {
    stateCode: "NY",
    stateName: "New York",
    city: "WATER MILL"
  },
  "11977": {
    stateCode: "NY",
    stateName: "New York",
    city: "WESTHAMPTON"
  },
  "11978": {
    stateCode: "NY",
    stateName: "New York",
    city: "WESTHAMPTON BEACH"
  },
  "11980": {
    stateCode: "NY",
    stateName: "New York",
    city: "YAPHANK"
  },
  "12008": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALPLAUS"
  },
  "12009": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALTAMONT"
  },
  "12010": {
    stateCode: "NY",
    stateName: "New York",
    city: "AMSTERDAM"
  },
  "12015": {
    stateCode: "NY",
    stateName: "New York",
    city: "ATHENS"
  },
  "12017": {
    stateCode: "NY",
    stateName: "New York",
    city: "AUSTERLITZ"
  },
  "12018": {
    stateCode: "NY",
    stateName: "New York",
    city: "AVERILL PARK"
  },
  "12019": {
    stateCode: "NY",
    stateName: "New York",
    city: "BALLSTON LAKE"
  },
  "12020": {
    stateCode: "NY",
    stateName: "New York",
    city: "BALLSTON SPA"
  },
  "12022": {
    stateCode: "NY",
    stateName: "New York",
    city: "BERLIN"
  },
  "12023": {
    stateCode: "NY",
    stateName: "New York",
    city: "BERNE"
  },
  "12025": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROADALBIN"
  },
  "12027": {
    stateCode: "NY",
    stateName: "New York",
    city: "BURNT HILLS"
  },
  "12028": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUSKIRK"
  },
  "12029": {
    stateCode: "NY",
    stateName: "New York",
    city: "CANAAN"
  },
  "12031": {
    stateCode: "NY",
    stateName: "New York",
    city: "CARLISLE"
  },
  "12032": {
    stateCode: "NY",
    stateName: "New York",
    city: "CAROGA LAKE"
  },
  "12033": {
    stateCode: "NY",
    stateName: "New York",
    city: "CASTLETON ON HUDSON"
  },
  "12035": {
    stateCode: "NY",
    stateName: "New York",
    city: "CENTRAL BRIDGE"
  },
  "12036": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHARLOTTEVILLE"
  },
  "12037": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHATHAM"
  },
  "12041": {
    stateCode: "NY",
    stateName: "New York",
    city: "CLARKSVILLE"
  },
  "12042": {
    stateCode: "NY",
    stateName: "New York",
    city: "CLIMAX"
  },
  "12043": {
    stateCode: "NY",
    stateName: "New York",
    city: "COBLESKILL"
  },
  "12045": {
    stateCode: "NY",
    stateName: "New York",
    city: "COEYMANS"
  },
  "12046": {
    stateCode: "NY",
    stateName: "New York",
    city: "COEYMANS HOLLOW"
  },
  "12047": {
    stateCode: "NY",
    stateName: "New York",
    city: "COHOES"
  },
  "12051": {
    stateCode: "NY",
    stateName: "New York",
    city: "COXSACKIE"
  },
  "12052": {
    stateCode: "NY",
    stateName: "New York",
    city: "CROPSEYVILLE"
  },
  "12053": {
    stateCode: "NY",
    stateName: "New York",
    city: "DELANSON"
  },
  "12054": {
    stateCode: "NY",
    stateName: "New York",
    city: "DELMAR"
  },
  "12056": {
    stateCode: "NY",
    stateName: "New York",
    city: "DUANESBURG"
  },
  "12057": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAGLE BRIDGE"
  },
  "12058": {
    stateCode: "NY",
    stateName: "New York",
    city: "EARLTON"
  },
  "12059": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST BERNE"
  },
  "12060": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST CHATHAM"
  },
  "12061": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST GREENBUSH"
  },
  "12062": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST NASSAU"
  },
  "12063": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST SCHODACK"
  },
  "12064": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST WORCESTER"
  },
  "12065": {
    stateCode: "NY",
    stateName: "New York",
    city: "CLIFTON PARK"
  },
  "12066": {
    stateCode: "NY",
    stateName: "New York",
    city: "ESPERANCE"
  },
  "12067": {
    stateCode: "NY",
    stateName: "New York",
    city: "FEURA BUSH"
  },
  "12068": {
    stateCode: "NY",
    stateName: "New York",
    city: "FONDA"
  },
  "12070": {
    stateCode: "NY",
    stateName: "New York",
    city: "FORT JOHNSON"
  },
  "12071": {
    stateCode: "NY",
    stateName: "New York",
    city: "FULTONHAM"
  },
  "12072": {
    stateCode: "NY",
    stateName: "New York",
    city: "FULTONVILLE"
  },
  "12074": {
    stateCode: "NY",
    stateName: "New York",
    city: "GALWAY"
  },
  "12075": {
    stateCode: "NY",
    stateName: "New York",
    city: "GHENT"
  },
  "12076": {
    stateCode: "NY",
    stateName: "New York",
    city: "GILBOA"
  },
  "12077": {
    stateCode: "NY",
    stateName: "New York",
    city: "GLENMONT"
  },
  "12078": {
    stateCode: "NY",
    stateName: "New York",
    city: "GLOVERSVILLE"
  },
  "12083": {
    stateCode: "NY",
    stateName: "New York",
    city: "GREENVILLE"
  },
  "12084": {
    stateCode: "NY",
    stateName: "New York",
    city: "GUILDERLAND"
  },
  "12086": {
    stateCode: "NY",
    stateName: "New York",
    city: "HAGAMAN"
  },
  "12087": {
    stateCode: "NY",
    stateName: "New York",
    city: "HANNACROIX"
  },
  "12090": {
    stateCode: "NY",
    stateName: "New York",
    city: "HOOSICK FALLS"
  },
  "12092": {
    stateCode: "NY",
    stateName: "New York",
    city: "HOWES CAVE"
  },
  "12093": {
    stateCode: "NY",
    stateName: "New York",
    city: "JEFFERSON"
  },
  "12094": {
    stateCode: "NY",
    stateName: "New York",
    city: "JOHNSONVILLE"
  },
  "12095": {
    stateCode: "NY",
    stateName: "New York",
    city: "JOHNSTOWN"
  },
  "12106": {
    stateCode: "NY",
    stateName: "New York",
    city: "KINDERHOOK"
  },
  "12108": {
    stateCode: "NY",
    stateName: "New York",
    city: "LAKE PLEASANT"
  },
  "12110": {
    stateCode: "NY",
    stateName: "New York",
    city: "LATHAM"
  },
  "12115": {
    stateCode: "NY",
    stateName: "New York",
    city: "MALDEN BRIDGE"
  },
  "12116": {
    stateCode: "NY",
    stateName: "New York",
    city: "MARYLAND"
  },
  "12117": {
    stateCode: "NY",
    stateName: "New York",
    city: "MAYFIELD"
  },
  "12118": {
    stateCode: "NY",
    stateName: "New York",
    city: "MECHANICVILLE"
  },
  "12120": {
    stateCode: "NY",
    stateName: "New York",
    city: "MEDUSA"
  },
  "12121": {
    stateCode: "NY",
    stateName: "New York",
    city: "MELROSE"
  },
  "12122": {
    stateCode: "NY",
    stateName: "New York",
    city: "MIDDLEBURGH"
  },
  "12123": {
    stateCode: "NY",
    stateName: "New York",
    city: "NASSAU"
  },
  "12125": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW LEBANON"
  },
  "12130": {
    stateCode: "NY",
    stateName: "New York",
    city: "NIVERVILLE"
  },
  "12131": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORTH BLENHEIM"
  },
  "12134": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORTHVILLE"
  },
  "12136": {
    stateCode: "NY",
    stateName: "New York",
    city: "OLD CHATHAM"
  },
  "12137": {
    stateCode: "NY",
    stateName: "New York",
    city: "PATTERSONVILLE"
  },
  "12138": {
    stateCode: "NY",
    stateName: "New York",
    city: "PETERSBURG"
  },
  "12139": {
    stateCode: "NY",
    stateName: "New York",
    city: "PISECO"
  },
  "12140": {
    stateCode: "NY",
    stateName: "New York",
    city: "POESTENKILL"
  },
  "12143": {
    stateCode: "NY",
    stateName: "New York",
    city: "RAVENA"
  },
  "12144": {
    stateCode: "NY",
    stateName: "New York",
    city: "RENSSELAER"
  },
  "12147": {
    stateCode: "NY",
    stateName: "New York",
    city: "RENSSELAERVILLE"
  },
  "12148": {
    stateCode: "NY",
    stateName: "New York",
    city: "REXFORD"
  },
  "12149": {
    stateCode: "NY",
    stateName: "New York",
    city: "RICHMONDVILLE"
  },
  "12150": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROTTERDAM JUNCTION"
  },
  "12151": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROUND LAKE"
  },
  "12153": {
    stateCode: "NY",
    stateName: "New York",
    city: "SAND LAKE"
  },
  "12154": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCHAGHTICOKE"
  },
  "12155": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCHENEVUS"
  },
  "12156": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCHODACK LANDING"
  },
  "12157": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCHOHARIE"
  },
  "12158": {
    stateCode: "NY",
    stateName: "New York",
    city: "SELKIRK"
  },
  "12159": {
    stateCode: "NY",
    stateName: "New York",
    city: "SLINGERLANDS"
  },
  "12160": {
    stateCode: "NY",
    stateName: "New York",
    city: "SLOANSVILLE"
  },
  "12164": {
    stateCode: "NY",
    stateName: "New York",
    city: "SPECULATOR"
  },
  "12165": {
    stateCode: "NY",
    stateName: "New York",
    city: "SPENCERTOWN"
  },
  "12166": {
    stateCode: "NY",
    stateName: "New York",
    city: "SPRAKERS"
  },
  "12167": {
    stateCode: "NY",
    stateName: "New York",
    city: "STAMFORD"
  },
  "12168": {
    stateCode: "NY",
    stateName: "New York",
    city: "STEPHENTOWN"
  },
  "12169": {
    stateCode: "NY",
    stateName: "New York",
    city: "STEPHENTOWN"
  },
  "12170": {
    stateCode: "NY",
    stateName: "New York",
    city: "STILLWATER"
  },
  "12173": {
    stateCode: "NY",
    stateName: "New York",
    city: "STUYVESANT"
  },
  "12175": {
    stateCode: "NY",
    stateName: "New York",
    city: "SUMMIT"
  },
  "12176": {
    stateCode: "NY",
    stateName: "New York",
    city: "SURPRISE"
  },
  "12180": {
    stateCode: "NY",
    stateName: "New York",
    city: "TROY"
  },
  "12182": {
    stateCode: "NY",
    stateName: "New York",
    city: "TROY"
  },
  "12183": {
    stateCode: "NY",
    stateName: "New York",
    city: "TROY"
  },
  "12184": {
    stateCode: "NY",
    stateName: "New York",
    city: "VALATIE"
  },
  "12185": {
    stateCode: "NY",
    stateName: "New York",
    city: "VALLEY FALLS"
  },
  "12186": {
    stateCode: "NY",
    stateName: "New York",
    city: "VOORHEESVILLE"
  },
  "12187": {
    stateCode: "NY",
    stateName: "New York",
    city: "WARNERVILLE"
  },
  "12188": {
    stateCode: "NY",
    stateName: "New York",
    city: "WATERFORD"
  },
  "12189": {
    stateCode: "NY",
    stateName: "New York",
    city: "WATERVLIET"
  },
  "12190": {
    stateCode: "NY",
    stateName: "New York",
    city: "WELLS"
  },
  "12192": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST COXSACKIE"
  },
  "12193": {
    stateCode: "NY",
    stateName: "New York",
    city: "WESTERLO"
  },
  "12194": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST FULTON"
  },
  "12196": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST SAND LAKE"
  },
  "12197": {
    stateCode: "NY",
    stateName: "New York",
    city: "WORCESTER"
  },
  "12198": {
    stateCode: "NY",
    stateName: "New York",
    city: "WYNANTSKILL"
  },
  "12202": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALBANY"
  },
  "12203": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALBANY"
  },
  "12204": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALBANY"
  },
  "12205": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALBANY"
  },
  "12206": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALBANY"
  },
  "12207": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALBANY"
  },
  "12208": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALBANY"
  },
  "12209": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALBANY"
  },
  "12210": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALBANY"
  },
  "12211": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALBANY"
  },
  "12302": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCHENECTADY"
  },
  "12303": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCHENECTADY"
  },
  "12304": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCHENECTADY"
  },
  "12305": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCHENECTADY"
  },
  "12306": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCHENECTADY"
  },
  "12307": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCHENECTADY"
  },
  "12308": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCHENECTADY"
  },
  "12309": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCHENECTADY"
  },
  "12401": {
    stateCode: "NY",
    stateName: "New York",
    city: "KINGSTON"
  },
  "12404": {
    stateCode: "NY",
    stateName: "New York",
    city: "ACCORD"
  },
  "12405": {
    stateCode: "NY",
    stateName: "New York",
    city: "ACRA"
  },
  "12406": {
    stateCode: "NY",
    stateName: "New York",
    city: "ARKVILLE"
  },
  "12407": {
    stateCode: "NY",
    stateName: "New York",
    city: "ASHLAND"
  },
  "12409": {
    stateCode: "NY",
    stateName: "New York",
    city: "BEARSVILLE"
  },
  "12410": {
    stateCode: "NY",
    stateName: "New York",
    city: "BIG INDIAN"
  },
  "12411": {
    stateCode: "NY",
    stateName: "New York",
    city: "BLOOMINGTON"
  },
  "12412": {
    stateCode: "NY",
    stateName: "New York",
    city: "BOICEVILLE"
  },
  "12413": {
    stateCode: "NY",
    stateName: "New York",
    city: "CAIRO"
  },
  "12414": {
    stateCode: "NY",
    stateName: "New York",
    city: "CATSKILL"
  },
  "12416": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHICHESTER"
  },
  "12418": {
    stateCode: "NY",
    stateName: "New York",
    city: "CORNWALLVILLE"
  },
  "12419": {
    stateCode: "NY",
    stateName: "New York",
    city: "COTTEKILL"
  },
  "12420": {
    stateCode: "NY",
    stateName: "New York",
    city: "CRAGSMOOR"
  },
  "12421": {
    stateCode: "NY",
    stateName: "New York",
    city: "DENVER"
  },
  "12422": {
    stateCode: "NY",
    stateName: "New York",
    city: "DURHAM"
  },
  "12423": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST DURHAM"
  },
  "12424": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST JEWETT"
  },
  "12427": {
    stateCode: "NY",
    stateName: "New York",
    city: "ELKA PARK"
  },
  "12428": {
    stateCode: "NY",
    stateName: "New York",
    city: "ELLENVILLE"
  },
  "12429": {
    stateCode: "NY",
    stateName: "New York",
    city: "ESOPUS"
  },
  "12430": {
    stateCode: "NY",
    stateName: "New York",
    city: "FLEISCHMANNS"
  },
  "12431": {
    stateCode: "NY",
    stateName: "New York",
    city: "FREEHOLD"
  },
  "12433": {
    stateCode: "NY",
    stateName: "New York",
    city: "GLENFORD"
  },
  "12434": {
    stateCode: "NY",
    stateName: "New York",
    city: "GRAND GORGE"
  },
  "12435": {
    stateCode: "NY",
    stateName: "New York",
    city: "GREENFIELD PARK"
  },
  "12439": {
    stateCode: "NY",
    stateName: "New York",
    city: "HENSONVILLE"
  },
  "12440": {
    stateCode: "NY",
    stateName: "New York",
    city: "HIGH FALLS"
  },
  "12442": {
    stateCode: "NY",
    stateName: "New York",
    city: "HUNTER"
  },
  "12443": {
    stateCode: "NY",
    stateName: "New York",
    city: "HURLEY"
  },
  "12444": {
    stateCode: "NY",
    stateName: "New York",
    city: "JEWETT"
  },
  "12446": {
    stateCode: "NY",
    stateName: "New York",
    city: "KERHONKSON"
  },
  "12448": {
    stateCode: "NY",
    stateName: "New York",
    city: "LAKE HILL"
  },
  "12449": {
    stateCode: "NY",
    stateName: "New York",
    city: "LAKE KATRINE"
  },
  "12450": {
    stateCode: "NY",
    stateName: "New York",
    city: "LANESVILLE"
  },
  "12451": {
    stateCode: "NY",
    stateName: "New York",
    city: "LEEDS"
  },
  "12454": {
    stateCode: "NY",
    stateName: "New York",
    city: "MAPLECREST"
  },
  "12455": {
    stateCode: "NY",
    stateName: "New York",
    city: "MARGARETVILLE"
  },
  "12456": {
    stateCode: "NY",
    stateName: "New York",
    city: "MOUNT MARION"
  },
  "12457": {
    stateCode: "NY",
    stateName: "New York",
    city: "MOUNT TREMPER"
  },
  "12458": {
    stateCode: "NY",
    stateName: "New York",
    city: "NAPANOCH"
  },
  "12459": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW KINGSTON"
  },
  "12460": {
    stateCode: "NY",
    stateName: "New York",
    city: "OAK HILL"
  },
  "12461": {
    stateCode: "NY",
    stateName: "New York",
    city: "OLIVEBRIDGE"
  },
  "12463": {
    stateCode: "NY",
    stateName: "New York",
    city: "PALENVILLE"
  },
  "12464": {
    stateCode: "NY",
    stateName: "New York",
    city: "PHOENICIA"
  },
  "12466": {
    stateCode: "NY",
    stateName: "New York",
    city: "PORT EWEN"
  },
  "12468": {
    stateCode: "NY",
    stateName: "New York",
    city: "PRATTSVILLE"
  },
  "12469": {
    stateCode: "NY",
    stateName: "New York",
    city: "PRESTON HOLLOW"
  },
  "12470": {
    stateCode: "NY",
    stateName: "New York",
    city: "PURLING"
  },
  "12471": {
    stateCode: "NY",
    stateName: "New York",
    city: "RIFTON"
  },
  "12472": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROSENDALE"
  },
  "12473": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROUND TOP"
  },
  "12474": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROXBURY"
  },
  "12477": {
    stateCode: "NY",
    stateName: "New York",
    city: "SAUGERTIES"
  },
  "12480": {
    stateCode: "NY",
    stateName: "New York",
    city: "SHANDAKEN"
  },
  "12481": {
    stateCode: "NY",
    stateName: "New York",
    city: "SHOKAN"
  },
  "12482": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOUTH CAIRO"
  },
  "12484": {
    stateCode: "NY",
    stateName: "New York",
    city: "STONE RIDGE"
  },
  "12485": {
    stateCode: "NY",
    stateName: "New York",
    city: "TANNERSVILLE"
  },
  "12486": {
    stateCode: "NY",
    stateName: "New York",
    city: "TILLSON"
  },
  "12487": {
    stateCode: "NY",
    stateName: "New York",
    city: "ULSTER PARK"
  },
  "12489": {
    stateCode: "NY",
    stateName: "New York",
    city: "WAWARSING"
  },
  "12491": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST HURLEY"
  },
  "12492": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST KILL"
  },
  "12493": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST PARK"
  },
  "12494": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST SHOKAN"
  },
  "12495": {
    stateCode: "NY",
    stateName: "New York",
    city: "WILLOW"
  },
  "12496": {
    stateCode: "NY",
    stateName: "New York",
    city: "WINDHAM"
  },
  "12498": {
    stateCode: "NY",
    stateName: "New York",
    city: "WOODSTOCK"
  },
  "12501": {
    stateCode: "NY",
    stateName: "New York",
    city: "AMENIA"
  },
  "12502": {
    stateCode: "NY",
    stateName: "New York",
    city: "ANCRAM"
  },
  "12503": {
    stateCode: "NY",
    stateName: "New York",
    city: "ANCRAMDALE"
  },
  "12507": {
    stateCode: "NY",
    stateName: "New York",
    city: "BARRYTOWN"
  },
  "12508": {
    stateCode: "NY",
    stateName: "New York",
    city: "BEACON"
  },
  "12513": {
    stateCode: "NY",
    stateName: "New York",
    city: "CLAVERACK"
  },
  "12514": {
    stateCode: "NY",
    stateName: "New York",
    city: "CLINTON CORNERS"
  },
  "12515": {
    stateCode: "NY",
    stateName: "New York",
    city: "CLINTONDALE"
  },
  "12516": {
    stateCode: "NY",
    stateName: "New York",
    city: "COPAKE"
  },
  "12517": {
    stateCode: "NY",
    stateName: "New York",
    city: "COPAKE FALLS"
  },
  "12518": {
    stateCode: "NY",
    stateName: "New York",
    city: "CORNWALL"
  },
  "12520": {
    stateCode: "NY",
    stateName: "New York",
    city: "CORNWALL ON HUDSON"
  },
  "12521": {
    stateCode: "NY",
    stateName: "New York",
    city: "CRARYVILLE"
  },
  "12522": {
    stateCode: "NY",
    stateName: "New York",
    city: "DOVER PLAINS"
  },
  "12523": {
    stateCode: "NY",
    stateName: "New York",
    city: "ELIZAVILLE"
  },
  "12524": {
    stateCode: "NY",
    stateName: "New York",
    city: "FISHKILL"
  },
  "12525": {
    stateCode: "NY",
    stateName: "New York",
    city: "GARDINER"
  },
  "12526": {
    stateCode: "NY",
    stateName: "New York",
    city: "GERMANTOWN"
  },
  "12528": {
    stateCode: "NY",
    stateName: "New York",
    city: "HIGHLAND"
  },
  "12529": {
    stateCode: "NY",
    stateName: "New York",
    city: "HILLSDALE"
  },
  "12531": {
    stateCode: "NY",
    stateName: "New York",
    city: "HOLMES"
  },
  "12533": {
    stateCode: "NY",
    stateName: "New York",
    city: "HOPEWELL JUNCTION"
  },
  "12534": {
    stateCode: "NY",
    stateName: "New York",
    city: "HUDSON"
  },
  "12538": {
    stateCode: "NY",
    stateName: "New York",
    city: "HYDE PARK"
  },
  "12540": {
    stateCode: "NY",
    stateName: "New York",
    city: "LAGRANGEVILLE"
  },
  "12542": {
    stateCode: "NY",
    stateName: "New York",
    city: "MARLBORO"
  },
  "12543": {
    stateCode: "NY",
    stateName: "New York",
    city: "MAYBROOK"
  },
  "12545": {
    stateCode: "NY",
    stateName: "New York",
    city: "MILLBROOK"
  },
  "12546": {
    stateCode: "NY",
    stateName: "New York",
    city: "MILLERTON"
  },
  "12547": {
    stateCode: "NY",
    stateName: "New York",
    city: "MILTON"
  },
  "12548": {
    stateCode: "NY",
    stateName: "New York",
    city: "MODENA"
  },
  "12549": {
    stateCode: "NY",
    stateName: "New York",
    city: "MONTGOMERY"
  },
  "12550": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEWBURGH"
  },
  "12553": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW WINDSOR"
  },
  "12561": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW PALTZ"
  },
  "12563": {
    stateCode: "NY",
    stateName: "New York",
    city: "PATTERSON"
  },
  "12564": {
    stateCode: "NY",
    stateName: "New York",
    city: "PAWLING"
  },
  "12565": {
    stateCode: "NY",
    stateName: "New York",
    city: "PHILMONT"
  },
  "12566": {
    stateCode: "NY",
    stateName: "New York",
    city: "PINE BUSH"
  },
  "12567": {
    stateCode: "NY",
    stateName: "New York",
    city: "PINE PLAINS"
  },
  "12569": {
    stateCode: "NY",
    stateName: "New York",
    city: "PLEASANT VALLEY"
  },
  "12570": {
    stateCode: "NY",
    stateName: "New York",
    city: "POUGHQUAG"
  },
  "12571": {
    stateCode: "NY",
    stateName: "New York",
    city: "RED HOOK"
  },
  "12572": {
    stateCode: "NY",
    stateName: "New York",
    city: "RHINEBECK"
  },
  "12575": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCK TAVERN"
  },
  "12577": {
    stateCode: "NY",
    stateName: "New York",
    city: "SALISBURY MILLS"
  },
  "12578": {
    stateCode: "NY",
    stateName: "New York",
    city: "SALT POINT"
  },
  "12580": {
    stateCode: "NY",
    stateName: "New York",
    city: "STAATSBURG"
  },
  "12581": {
    stateCode: "NY",
    stateName: "New York",
    city: "STANFORDVILLE"
  },
  "12582": {
    stateCode: "NY",
    stateName: "New York",
    city: "STORMVILLE"
  },
  "12583": {
    stateCode: "NY",
    stateName: "New York",
    city: "TIVOLI"
  },
  "12585": {
    stateCode: "NY",
    stateName: "New York",
    city: "VERBANK"
  },
  "12586": {
    stateCode: "NY",
    stateName: "New York",
    city: "WALDEN"
  },
  "12589": {
    stateCode: "NY",
    stateName: "New York",
    city: "WALLKILL"
  },
  "12590": {
    stateCode: "NY",
    stateName: "New York",
    city: "WAPPINGERS FALLS"
  },
  "12592": {
    stateCode: "NY",
    stateName: "New York",
    city: "WASSAIC"
  },
  "12594": {
    stateCode: "NY",
    stateName: "New York",
    city: "WINGDALE"
  },
  "12601": {
    stateCode: "NY",
    stateName: "New York",
    city: "POUGHKEEPSIE"
  },
  "12603": {
    stateCode: "NY",
    stateName: "New York",
    city: "POUGHKEEPSIE"
  },
  "12701": {
    stateCode: "NY",
    stateName: "New York",
    city: "MONTICELLO"
  },
  "12719": {
    stateCode: "NY",
    stateName: "New York",
    city: "BARRYVILLE"
  },
  "12720": {
    stateCode: "NY",
    stateName: "New York",
    city: "BETHEL"
  },
  "12721": {
    stateCode: "NY",
    stateName: "New York",
    city: "BLOOMINGBURG"
  },
  "12723": {
    stateCode: "NY",
    stateName: "New York",
    city: "CALLICOON"
  },
  "12724": {
    stateCode: "NY",
    stateName: "New York",
    city: "CALLICOON CENTER"
  },
  "12725": {
    stateCode: "NY",
    stateName: "New York",
    city: "CLARYVILLE"
  },
  "12726": {
    stateCode: "NY",
    stateName: "New York",
    city: "COCHECTON"
  },
  "12727": {
    stateCode: "NY",
    stateName: "New York",
    city: "COCHECTON CENTER"
  },
  "12729": {
    stateCode: "NY",
    stateName: "New York",
    city: "CUDDEBACKVILLE"
  },
  "12732": {
    stateCode: "NY",
    stateName: "New York",
    city: "ELDRED"
  },
  "12733": {
    stateCode: "NY",
    stateName: "New York",
    city: "FALLSBURG"
  },
  "12734": {
    stateCode: "NY",
    stateName: "New York",
    city: "FERNDALE"
  },
  "12736": {
    stateCode: "NY",
    stateName: "New York",
    city: "FREMONT CENTER"
  },
  "12737": {
    stateCode: "NY",
    stateName: "New York",
    city: "GLEN SPEY"
  },
  "12738": {
    stateCode: "NY",
    stateName: "New York",
    city: "GLEN WILD"
  },
  "12740": {
    stateCode: "NY",
    stateName: "New York",
    city: "GRAHAMSVILLE"
  },
  "12741": {
    stateCode: "NY",
    stateName: "New York",
    city: "HANKINS"
  },
  "12742": {
    stateCode: "NY",
    stateName: "New York",
    city: "HARRIS"
  },
  "12743": {
    stateCode: "NY",
    stateName: "New York",
    city: "HIGHLAND LAKE"
  },
  "12746": {
    stateCode: "NY",
    stateName: "New York",
    city: "HUGUENOT"
  },
  "12747": {
    stateCode: "NY",
    stateName: "New York",
    city: "HURLEYVILLE"
  },
  "12748": {
    stateCode: "NY",
    stateName: "New York",
    city: "JEFFERSONVILLE"
  },
  "12749": {
    stateCode: "NY",
    stateName: "New York",
    city: "KAUNEONGA LAKE"
  },
  "12751": {
    stateCode: "NY",
    stateName: "New York",
    city: "KIAMESHA LAKE"
  },
  "12752": {
    stateCode: "NY",
    stateName: "New York",
    city: "LAKE HUNTINGTON"
  },
  "12754": {
    stateCode: "NY",
    stateName: "New York",
    city: "LIBERTY"
  },
  "12758": {
    stateCode: "NY",
    stateName: "New York",
    city: "LIVINGSTON MANOR"
  },
  "12759": {
    stateCode: "NY",
    stateName: "New York",
    city: "LOCH SHELDRAKE"
  },
  "12760": {
    stateCode: "NY",
    stateName: "New York",
    city: "LONG EDDY"
  },
  "12762": {
    stateCode: "NY",
    stateName: "New York",
    city: "MONGAUP VALLEY"
  },
  "12763": {
    stateCode: "NY",
    stateName: "New York",
    city: "MOUNTAIN DALE"
  },
  "12764": {
    stateCode: "NY",
    stateName: "New York",
    city: "NARROWSBURG"
  },
  "12765": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEVERSINK"
  },
  "12766": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORTH BRANCH"
  },
  "12768": {
    stateCode: "NY",
    stateName: "New York",
    city: "PARKSVILLE"
  },
  "12770": {
    stateCode: "NY",
    stateName: "New York",
    city: "POND EDDY"
  },
  "12771": {
    stateCode: "NY",
    stateName: "New York",
    city: "PORT JERVIS"
  },
  "12775": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCK HILL"
  },
  "12776": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROSCOE"
  },
  "12777": {
    stateCode: "NY",
    stateName: "New York",
    city: "FORESTBURGH"
  },
  "12778": {
    stateCode: "NY",
    stateName: "New York",
    city: "SMALLWOOD"
  },
  "12779": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOUTH FALLSBURG"
  },
  "12780": {
    stateCode: "NY",
    stateName: "New York",
    city: "SPARROW BUSH"
  },
  "12783": {
    stateCode: "NY",
    stateName: "New York",
    city: "SWAN LAKE"
  },
  "12784": {
    stateCode: "NY",
    stateName: "New York",
    city: "THOMPSONVILLE"
  },
  "12785": {
    stateCode: "NY",
    stateName: "New York",
    city: "WESTBROOKVILLE"
  },
  "12786": {
    stateCode: "NY",
    stateName: "New York",
    city: "WHITE LAKE"
  },
  "12787": {
    stateCode: "NY",
    stateName: "New York",
    city: "WHITE SULPHUR SPRINGS"
  },
  "12788": {
    stateCode: "NY",
    stateName: "New York",
    city: "WOODBOURNE"
  },
  "12789": {
    stateCode: "NY",
    stateName: "New York",
    city: "WOODRIDGE"
  },
  "12790": {
    stateCode: "NY",
    stateName: "New York",
    city: "WURTSBORO"
  },
  "12791": {
    stateCode: "NY",
    stateName: "New York",
    city: "YOUNGSVILLE"
  },
  "12792": {
    stateCode: "NY",
    stateName: "New York",
    city: "YULAN"
  },
  "12801": {
    stateCode: "NY",
    stateName: "New York",
    city: "GLENS FALLS"
  },
  "12803": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOUTH GLENS FALLS"
  },
  "12804": {
    stateCode: "NY",
    stateName: "New York",
    city: "QUEENSBURY"
  },
  "12808": {
    stateCode: "NY",
    stateName: "New York",
    city: "ADIRONDACK"
  },
  "12809": {
    stateCode: "NY",
    stateName: "New York",
    city: "ARGYLE"
  },
  "12810": {
    stateCode: "NY",
    stateName: "New York",
    city: "ATHOL"
  },
  "12812": {
    stateCode: "NY",
    stateName: "New York",
    city: "BLUE MOUNTAIN LAKE"
  },
  "12814": {
    stateCode: "NY",
    stateName: "New York",
    city: "BOLTON LANDING"
  },
  "12815": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRANT LAKE"
  },
  "12816": {
    stateCode: "NY",
    stateName: "New York",
    city: "CAMBRIDGE"
  },
  "12817": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHESTERTOWN"
  },
  "12819": {
    stateCode: "NY",
    stateName: "New York",
    city: "CLEMONS"
  },
  "12821": {
    stateCode: "NY",
    stateName: "New York",
    city: "COMSTOCK"
  },
  "12822": {
    stateCode: "NY",
    stateName: "New York",
    city: "CORINTH"
  },
  "12823": {
    stateCode: "NY",
    stateName: "New York",
    city: "COSSAYUNA"
  },
  "12824": {
    stateCode: "NY",
    stateName: "New York",
    city: "DIAMOND POINT"
  },
  "12827": {
    stateCode: "NY",
    stateName: "New York",
    city: "FORT ANN"
  },
  "12828": {
    stateCode: "NY",
    stateName: "New York",
    city: "FORT EDWARD"
  },
  "12831": {
    stateCode: "NY",
    stateName: "New York",
    city: "GANSEVOORT"
  },
  "12832": {
    stateCode: "NY",
    stateName: "New York",
    city: "GRANVILLE"
  },
  "12833": {
    stateCode: "NY",
    stateName: "New York",
    city: "GREENFIELD CENTER"
  },
  "12834": {
    stateCode: "NY",
    stateName: "New York",
    city: "GREENWICH"
  },
  "12835": {
    stateCode: "NY",
    stateName: "New York",
    city: "HADLEY"
  },
  "12836": {
    stateCode: "NY",
    stateName: "New York",
    city: "HAGUE"
  },
  "12837": {
    stateCode: "NY",
    stateName: "New York",
    city: "HAMPTON"
  },
  "12838": {
    stateCode: "NY",
    stateName: "New York",
    city: "HARTFORD"
  },
  "12839": {
    stateCode: "NY",
    stateName: "New York",
    city: "HUDSON FALLS"
  },
  "12841": {
    stateCode: "NY",
    stateName: "New York",
    city: "HULETTS LANDING"
  },
  "12842": {
    stateCode: "NY",
    stateName: "New York",
    city: "INDIAN LAKE"
  },
  "12843": {
    stateCode: "NY",
    stateName: "New York",
    city: "JOHNSBURG"
  },
  "12844": {
    stateCode: "NY",
    stateName: "New York",
    city: "KATTSKILL BAY"
  },
  "12845": {
    stateCode: "NY",
    stateName: "New York",
    city: "LAKE GEORGE"
  },
  "12846": {
    stateCode: "NY",
    stateName: "New York",
    city: "LAKE LUZERNE"
  },
  "12847": {
    stateCode: "NY",
    stateName: "New York",
    city: "LONG LAKE"
  },
  "12849": {
    stateCode: "NY",
    stateName: "New York",
    city: "MIDDLE GRANVILLE"
  },
  "12850": {
    stateCode: "NY",
    stateName: "New York",
    city: "MIDDLE GROVE"
  },
  "12851": {
    stateCode: "NY",
    stateName: "New York",
    city: "MINERVA"
  },
  "12852": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEWCOMB"
  },
  "12853": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORTH CREEK"
  },
  "12855": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORTH HUDSON"
  },
  "12856": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORTH RIVER"
  },
  "12857": {
    stateCode: "NY",
    stateName: "New York",
    city: "OLMSTEDVILLE"
  },
  "12858": {
    stateCode: "NY",
    stateName: "New York",
    city: "PARADOX"
  },
  "12859": {
    stateCode: "NY",
    stateName: "New York",
    city: "PORTER CORNERS"
  },
  "12860": {
    stateCode: "NY",
    stateName: "New York",
    city: "POTTERSVILLE"
  },
  "12861": {
    stateCode: "NY",
    stateName: "New York",
    city: "PUTNAM STATION"
  },
  "12863": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCK CITY FALLS"
  },
  "12865": {
    stateCode: "NY",
    stateName: "New York",
    city: "SALEM"
  },
  "12866": {
    stateCode: "NY",
    stateName: "New York",
    city: "SARATOGA SPRINGS"
  },
  "12870": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCHROON LAKE"
  },
  "12871": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCHUYLERVILLE"
  },
  "12873": {
    stateCode: "NY",
    stateName: "New York",
    city: "SHUSHAN"
  },
  "12874": {
    stateCode: "NY",
    stateName: "New York",
    city: "SILVER BAY"
  },
  "12878": {
    stateCode: "NY",
    stateName: "New York",
    city: "STONY CREEK"
  },
  "12883": {
    stateCode: "NY",
    stateName: "New York",
    city: "TICONDEROGA"
  },
  "12885": {
    stateCode: "NY",
    stateName: "New York",
    city: "WARRENSBURG"
  },
  "12886": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEVERTOWN"
  },
  "12887": {
    stateCode: "NY",
    stateName: "New York",
    city: "WHITEHALL"
  },
  "12901": {
    stateCode: "NY",
    stateName: "New York",
    city: "PLATTSBURGH"
  },
  "12903": {
    stateCode: "NY",
    stateName: "New York",
    city: "PLATTSBURGH"
  },
  "12910": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALTONA"
  },
  "12911": {
    stateCode: "NY",
    stateName: "New York",
    city: "KEESEVILLE"
  },
  "12912": {
    stateCode: "NY",
    stateName: "New York",
    city: "AU SABLE FORKS"
  },
  "12913": {
    stateCode: "NY",
    stateName: "New York",
    city: "BLOOMINGDALE"
  },
  "12914": {
    stateCode: "NY",
    stateName: "New York",
    city: "BOMBAY"
  },
  "12916": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRUSHTON"
  },
  "12917": {
    stateCode: "NY",
    stateName: "New York",
    city: "BURKE"
  },
  "12918": {
    stateCode: "NY",
    stateName: "New York",
    city: "CADYVILLE"
  },
  "12919": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHAMPLAIN"
  },
  "12920": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHATEAUGAY"
  },
  "12921": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHAZY"
  },
  "12922": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHILDWOLD"
  },
  "12923": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHURUBUSCO"
  },
  "12926": {
    stateCode: "NY",
    stateName: "New York",
    city: "CONSTABLE"
  },
  "12927": {
    stateCode: "NY",
    stateName: "New York",
    city: "CRANBERRY LAKE"
  },
  "12928": {
    stateCode: "NY",
    stateName: "New York",
    city: "CROWN POINT"
  },
  "12930": {
    stateCode: "NY",
    stateName: "New York",
    city: "DICKINSON CENTER"
  },
  "12932": {
    stateCode: "NY",
    stateName: "New York",
    city: "ELIZABETHTOWN"
  },
  "12934": {
    stateCode: "NY",
    stateName: "New York",
    city: "ELLENBURG CENTER"
  },
  "12935": {
    stateCode: "NY",
    stateName: "New York",
    city: "ELLENBURG DEPOT"
  },
  "12936": {
    stateCode: "NY",
    stateName: "New York",
    city: "ESSEX"
  },
  "12937": {
    stateCode: "NY",
    stateName: "New York",
    city: "FORT COVINGTON"
  },
  "12941": {
    stateCode: "NY",
    stateName: "New York",
    city: "JAY"
  },
  "12942": {
    stateCode: "NY",
    stateName: "New York",
    city: "KEENE"
  },
  "12943": {
    stateCode: "NY",
    stateName: "New York",
    city: "KEENE VALLEY"
  },
  "12944": {
    stateCode: "NY",
    stateName: "New York",
    city: "KEESEVILLE"
  },
  "12945": {
    stateCode: "NY",
    stateName: "New York",
    city: "LAKE CLEAR"
  },
  "12946": {
    stateCode: "NY",
    stateName: "New York",
    city: "LAKE PLACID"
  },
  "12950": {
    stateCode: "NY",
    stateName: "New York",
    city: "LEWIS"
  },
  "12952": {
    stateCode: "NY",
    stateName: "New York",
    city: "LYON MOUNTAIN"
  },
  "12953": {
    stateCode: "NY",
    stateName: "New York",
    city: "MALONE"
  },
  "12955": {
    stateCode: "NY",
    stateName: "New York",
    city: "LYON MOUNTAIN"
  },
  "12956": {
    stateCode: "NY",
    stateName: "New York",
    city: "MINEVILLE"
  },
  "12957": {
    stateCode: "NY",
    stateName: "New York",
    city: "MOIRA"
  },
  "12958": {
    stateCode: "NY",
    stateName: "New York",
    city: "MOOERS"
  },
  "12959": {
    stateCode: "NY",
    stateName: "New York",
    city: "MOOERS FORKS"
  },
  "12960": {
    stateCode: "NY",
    stateName: "New York",
    city: "MORIAH"
  },
  "12961": {
    stateCode: "NY",
    stateName: "New York",
    city: "MORIAH CENTER"
  },
  "12962": {
    stateCode: "NY",
    stateName: "New York",
    city: "MORRISONVILLE"
  },
  "12964": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW RUSSIA"
  },
  "12965": {
    stateCode: "NY",
    stateName: "New York",
    city: "NICHOLVILLE"
  },
  "12966": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORTH BANGOR"
  },
  "12967": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORTH LAWRENCE"
  },
  "12969": {
    stateCode: "NY",
    stateName: "New York",
    city: "OWLS HEAD"
  },
  "12970": {
    stateCode: "NY",
    stateName: "New York",
    city: "PAUL SMITHS"
  },
  "12972": {
    stateCode: "NY",
    stateName: "New York",
    city: "PERU"
  },
  "12974": {
    stateCode: "NY",
    stateName: "New York",
    city: "PORT HENRY"
  },
  "12979": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROUSES POINT"
  },
  "12980": {
    stateCode: "NY",
    stateName: "New York",
    city: "SAINT REGIS FALLS"
  },
  "12981": {
    stateCode: "NY",
    stateName: "New York",
    city: "SARANAC"
  },
  "12983": {
    stateCode: "NY",
    stateName: "New York",
    city: "SARANAC LAKE"
  },
  "12985": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCHUYLER FALLS"
  },
  "12986": {
    stateCode: "NY",
    stateName: "New York",
    city: "TUPPER LAKE"
  },
  "12987": {
    stateCode: "NY",
    stateName: "New York",
    city: "UPPER JAY"
  },
  "12989": {
    stateCode: "NY",
    stateName: "New York",
    city: "VERMONTVILLE"
  },
  "12992": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST CHAZY"
  },
  "12993": {
    stateCode: "NY",
    stateName: "New York",
    city: "WESTPORT"
  },
  "12996": {
    stateCode: "NY",
    stateName: "New York",
    city: "WILLSBORO"
  },
  "13021": {
    stateCode: "NY",
    stateName: "New York",
    city: "AUBURN"
  },
  "13026": {
    stateCode: "NY",
    stateName: "New York",
    city: "AURORA"
  },
  "13027": {
    stateCode: "NY",
    stateName: "New York",
    city: "BALDWINSVILLE"
  },
  "13028": {
    stateCode: "NY",
    stateName: "New York",
    city: "BERNHARDS BAY"
  },
  "13029": {
    stateCode: "NY",
    stateName: "New York",
    city: "BREWERTON"
  },
  "13030": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRIDGEPORT"
  },
  "13031": {
    stateCode: "NY",
    stateName: "New York",
    city: "CAMILLUS"
  },
  "13032": {
    stateCode: "NY",
    stateName: "New York",
    city: "CANASTOTA"
  },
  "13033": {
    stateCode: "NY",
    stateName: "New York",
    city: "CATO"
  },
  "13034": {
    stateCode: "NY",
    stateName: "New York",
    city: "CAYUGA"
  },
  "13035": {
    stateCode: "NY",
    stateName: "New York",
    city: "CAZENOVIA"
  },
  "13036": {
    stateCode: "NY",
    stateName: "New York",
    city: "CENTRAL SQUARE"
  },
  "13037": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHITTENANGO"
  },
  "13039": {
    stateCode: "NY",
    stateName: "New York",
    city: "CICERO"
  },
  "13040": {
    stateCode: "NY",
    stateName: "New York",
    city: "CINCINNATUS"
  },
  "13041": {
    stateCode: "NY",
    stateName: "New York",
    city: "CLAY"
  },
  "13042": {
    stateCode: "NY",
    stateName: "New York",
    city: "CLEVELAND"
  },
  "13044": {
    stateCode: "NY",
    stateName: "New York",
    city: "CONSTANTIA"
  },
  "13045": {
    stateCode: "NY",
    stateName: "New York",
    city: "CORTLAND"
  },
  "13052": {
    stateCode: "NY",
    stateName: "New York",
    city: "DE RUYTER"
  },
  "13053": {
    stateCode: "NY",
    stateName: "New York",
    city: "DRYDEN"
  },
  "13054": {
    stateCode: "NY",
    stateName: "New York",
    city: "DURHAMVILLE"
  },
  "13057": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST SYRACUSE"
  },
  "13060": {
    stateCode: "NY",
    stateName: "New York",
    city: "ELBRIDGE"
  },
  "13061": {
    stateCode: "NY",
    stateName: "New York",
    city: "ERIEVILLE"
  },
  "13062": {
    stateCode: "NY",
    stateName: "New York",
    city: "ETNA"
  },
  "13063": {
    stateCode: "NY",
    stateName: "New York",
    city: "FABIUS"
  },
  "13066": {
    stateCode: "NY",
    stateName: "New York",
    city: "FAYETTEVILLE"
  },
  "13068": {
    stateCode: "NY",
    stateName: "New York",
    city: "FREEVILLE"
  },
  "13069": {
    stateCode: "NY",
    stateName: "New York",
    city: "FULTON"
  },
  "13071": {
    stateCode: "NY",
    stateName: "New York",
    city: "GENOA"
  },
  "13072": {
    stateCode: "NY",
    stateName: "New York",
    city: "GEORGETOWN"
  },
  "13073": {
    stateCode: "NY",
    stateName: "New York",
    city: "GROTON"
  },
  "13074": {
    stateCode: "NY",
    stateName: "New York",
    city: "HANNIBAL"
  },
  "13076": {
    stateCode: "NY",
    stateName: "New York",
    city: "HASTINGS"
  },
  "13077": {
    stateCode: "NY",
    stateName: "New York",
    city: "HOMER"
  },
  "13078": {
    stateCode: "NY",
    stateName: "New York",
    city: "JAMESVILLE"
  },
  "13080": {
    stateCode: "NY",
    stateName: "New York",
    city: "JORDAN"
  },
  "13081": {
    stateCode: "NY",
    stateName: "New York",
    city: "KING FERRY"
  },
  "13082": {
    stateCode: "NY",
    stateName: "New York",
    city: "KIRKVILLE"
  },
  "13083": {
    stateCode: "NY",
    stateName: "New York",
    city: "LACONA"
  },
  "13084": {
    stateCode: "NY",
    stateName: "New York",
    city: "LA FAYETTE"
  },
  "13088": {
    stateCode: "NY",
    stateName: "New York",
    city: "LIVERPOOL"
  },
  "13090": {
    stateCode: "NY",
    stateName: "New York",
    city: "LIVERPOOL"
  },
  "13092": {
    stateCode: "NY",
    stateName: "New York",
    city: "LOCKE"
  },
  "13101": {
    stateCode: "NY",
    stateName: "New York",
    city: "MC GRAW"
  },
  "13104": {
    stateCode: "NY",
    stateName: "New York",
    city: "MANLIUS"
  },
  "13108": {
    stateCode: "NY",
    stateName: "New York",
    city: "MARCELLUS"
  },
  "13110": {
    stateCode: "NY",
    stateName: "New York",
    city: "MARIETTA"
  },
  "13111": {
    stateCode: "NY",
    stateName: "New York",
    city: "MARTVILLE"
  },
  "13112": {
    stateCode: "NY",
    stateName: "New York",
    city: "MEMPHIS"
  },
  "13114": {
    stateCode: "NY",
    stateName: "New York",
    city: "MEXICO"
  },
  "13116": {
    stateCode: "NY",
    stateName: "New York",
    city: "MINOA"
  },
  "13118": {
    stateCode: "NY",
    stateName: "New York",
    city: "MORAVIA"
  },
  "13120": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEDROW"
  },
  "13122": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW WOODSTOCK"
  },
  "13124": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORTH PITCHER"
  },
  "13126": {
    stateCode: "NY",
    stateName: "New York",
    city: "OSWEGO"
  },
  "13131": {
    stateCode: "NY",
    stateName: "New York",
    city: "PARISH"
  },
  "13132": {
    stateCode: "NY",
    stateName: "New York",
    city: "PENNELLVILLE"
  },
  "13135": {
    stateCode: "NY",
    stateName: "New York",
    city: "PHOENIX"
  },
  "13136": {
    stateCode: "NY",
    stateName: "New York",
    city: "PITCHER"
  },
  "13140": {
    stateCode: "NY",
    stateName: "New York",
    city: "PORT BYRON"
  },
  "13141": {
    stateCode: "NY",
    stateName: "New York",
    city: "PREBLE"
  },
  "13142": {
    stateCode: "NY",
    stateName: "New York",
    city: "PULASKI"
  },
  "13143": {
    stateCode: "NY",
    stateName: "New York",
    city: "RED CREEK"
  },
  "13144": {
    stateCode: "NY",
    stateName: "New York",
    city: "RICHLAND"
  },
  "13145": {
    stateCode: "NY",
    stateName: "New York",
    city: "SANDY CREEK"
  },
  "13146": {
    stateCode: "NY",
    stateName: "New York",
    city: "SAVANNAH"
  },
  "13147": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCIPIO CENTER"
  },
  "13148": {
    stateCode: "NY",
    stateName: "New York",
    city: "SENECA FALLS"
  },
  "13152": {
    stateCode: "NY",
    stateName: "New York",
    city: "SKANEATELES"
  },
  "13155": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOUTH OTSELIC"
  },
  "13156": {
    stateCode: "NY",
    stateName: "New York",
    city: "STERLING"
  },
  "13158": {
    stateCode: "NY",
    stateName: "New York",
    city: "TRUXTON"
  },
  "13159": {
    stateCode: "NY",
    stateName: "New York",
    city: "TULLY"
  },
  "13160": {
    stateCode: "NY",
    stateName: "New York",
    city: "UNION SPRINGS"
  },
  "13164": {
    stateCode: "NY",
    stateName: "New York",
    city: "WARNERS"
  },
  "13165": {
    stateCode: "NY",
    stateName: "New York",
    city: "WATERLOO"
  },
  "13166": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEEDSPORT"
  },
  "13167": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST MONROE"
  },
  "13202": {
    stateCode: "NY",
    stateName: "New York",
    city: "SYRACUSE"
  },
  "13203": {
    stateCode: "NY",
    stateName: "New York",
    city: "SYRACUSE"
  },
  "13204": {
    stateCode: "NY",
    stateName: "New York",
    city: "SYRACUSE"
  },
  "13205": {
    stateCode: "NY",
    stateName: "New York",
    city: "SYRACUSE"
  },
  "13206": {
    stateCode: "NY",
    stateName: "New York",
    city: "SYRACUSE"
  },
  "13207": {
    stateCode: "NY",
    stateName: "New York",
    city: "SYRACUSE"
  },
  "13208": {
    stateCode: "NY",
    stateName: "New York",
    city: "SYRACUSE"
  },
  "13209": {
    stateCode: "NY",
    stateName: "New York",
    city: "SYRACUSE"
  },
  "13210": {
    stateCode: "NY",
    stateName: "New York",
    city: "SYRACUSE"
  },
  "13211": {
    stateCode: "NY",
    stateName: "New York",
    city: "SYRACUSE"
  },
  "13212": {
    stateCode: "NY",
    stateName: "New York",
    city: "SYRACUSE"
  },
  "13214": {
    stateCode: "NY",
    stateName: "New York",
    city: "SYRACUSE"
  },
  "13215": {
    stateCode: "NY",
    stateName: "New York",
    city: "SYRACUSE"
  },
  "13219": {
    stateCode: "NY",
    stateName: "New York",
    city: "SYRACUSE"
  },
  "13224": {
    stateCode: "NY",
    stateName: "New York",
    city: "SYRACUSE"
  },
  "13301": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALDER CREEK"
  },
  "13302": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALTMAR"
  },
  "13303": {
    stateCode: "NY",
    stateName: "New York",
    city: "AVA"
  },
  "13304": {
    stateCode: "NY",
    stateName: "New York",
    city: "BARNEVELD"
  },
  "13308": {
    stateCode: "NY",
    stateName: "New York",
    city: "BLOSSVALE"
  },
  "13309": {
    stateCode: "NY",
    stateName: "New York",
    city: "BOONVILLE"
  },
  "13310": {
    stateCode: "NY",
    stateName: "New York",
    city: "BOUCKVILLE"
  },
  "13312": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRANTINGHAM"
  },
  "13313": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRIDGEWATER"
  },
  "13314": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKFIELD"
  },
  "13315": {
    stateCode: "NY",
    stateName: "New York",
    city: "BURLINGTON FLATS"
  },
  "13316": {
    stateCode: "NY",
    stateName: "New York",
    city: "CAMDEN"
  },
  "13317": {
    stateCode: "NY",
    stateName: "New York",
    city: "CANAJOHARIE"
  },
  "13318": {
    stateCode: "NY",
    stateName: "New York",
    city: "CASSVILLE"
  },
  "13319": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHADWICKS"
  },
  "13320": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHERRY VALLEY"
  },
  "13322": {
    stateCode: "NY",
    stateName: "New York",
    city: "CLAYVILLE"
  },
  "13323": {
    stateCode: "NY",
    stateName: "New York",
    city: "CLINTON"
  },
  "13324": {
    stateCode: "NY",
    stateName: "New York",
    city: "COLD BROOK"
  },
  "13325": {
    stateCode: "NY",
    stateName: "New York",
    city: "CONSTABLEVILLE"
  },
  "13326": {
    stateCode: "NY",
    stateName: "New York",
    city: "COOPERSTOWN"
  },
  "13327": {
    stateCode: "NY",
    stateName: "New York",
    city: "CROGHAN"
  },
  "13328": {
    stateCode: "NY",
    stateName: "New York",
    city: "DEANSBORO"
  },
  "13329": {
    stateCode: "NY",
    stateName: "New York",
    city: "DOLGEVILLE"
  },
  "13331": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAGLE BAY"
  },
  "13332": {
    stateCode: "NY",
    stateName: "New York",
    city: "EARLVILLE"
  },
  "13333": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST SPRINGFIELD"
  },
  "13334": {
    stateCode: "NY",
    stateName: "New York",
    city: "EATON"
  },
  "13335": {
    stateCode: "NY",
    stateName: "New York",
    city: "EDMESTON"
  },
  "13337": {
    stateCode: "NY",
    stateName: "New York",
    city: "FLY CREEK"
  },
  "13338": {
    stateCode: "NY",
    stateName: "New York",
    city: "FORESTPORT"
  },
  "13339": {
    stateCode: "NY",
    stateName: "New York",
    city: "FORT PLAIN"
  },
  "13340": {
    stateCode: "NY",
    stateName: "New York",
    city: "FRANKFORT"
  },
  "13341": {
    stateCode: "NY",
    stateName: "New York",
    city: "FRANKLIN SPRINGS"
  },
  "13342": {
    stateCode: "NY",
    stateName: "New York",
    city: "GARRATTSVILLE"
  },
  "13343": {
    stateCode: "NY",
    stateName: "New York",
    city: "GLENFIELD"
  },
  "13346": {
    stateCode: "NY",
    stateName: "New York",
    city: "HAMILTON"
  },
  "13348": {
    stateCode: "NY",
    stateName: "New York",
    city: "HARTWICK"
  },
  "13350": {
    stateCode: "NY",
    stateName: "New York",
    city: "HERKIMER"
  },
  "13353": {
    stateCode: "NY",
    stateName: "New York",
    city: "HOFFMEISTER"
  },
  "13354": {
    stateCode: "NY",
    stateName: "New York",
    city: "HOLLAND PATENT"
  },
  "13355": {
    stateCode: "NY",
    stateName: "New York",
    city: "HUBBARDSVILLE"
  },
  "13357": {
    stateCode: "NY",
    stateName: "New York",
    city: "ILION"
  },
  "13360": {
    stateCode: "NY",
    stateName: "New York",
    city: "INLET"
  },
  "13361": {
    stateCode: "NY",
    stateName: "New York",
    city: "JORDANVILLE"
  },
  "13363": {
    stateCode: "NY",
    stateName: "New York",
    city: "LEE CENTER"
  },
  "13365": {
    stateCode: "NY",
    stateName: "New York",
    city: "LITTLE FALLS"
  },
  "13367": {
    stateCode: "NY",
    stateName: "New York",
    city: "LOWVILLE"
  },
  "13368": {
    stateCode: "NY",
    stateName: "New York",
    city: "LYONS FALLS"
  },
  "13402": {
    stateCode: "NY",
    stateName: "New York",
    city: "MADISON"
  },
  "13403": {
    stateCode: "NY",
    stateName: "New York",
    city: "MARCY"
  },
  "13406": {
    stateCode: "NY",
    stateName: "New York",
    city: "MIDDLEVILLE"
  },
  "13407": {
    stateCode: "NY",
    stateName: "New York",
    city: "MOHAWK"
  },
  "13408": {
    stateCode: "NY",
    stateName: "New York",
    city: "MORRISVILLE"
  },
  "13409": {
    stateCode: "NY",
    stateName: "New York",
    city: "MUNNSVILLE"
  },
  "13411": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW BERLIN"
  },
  "13413": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW HARTFORD"
  },
  "13416": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEWPORT"
  },
  "13417": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEW YORK MILLS"
  },
  "13418": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORTH BROOKFIELD"
  },
  "13420": {
    stateCode: "NY",
    stateName: "New York",
    city: "OLD FORGE"
  },
  "13421": {
    stateCode: "NY",
    stateName: "New York",
    city: "ONEIDA"
  },
  "13424": {
    stateCode: "NY",
    stateName: "New York",
    city: "ORISKANY"
  },
  "13425": {
    stateCode: "NY",
    stateName: "New York",
    city: "ORISKANY FALLS"
  },
  "13428": {
    stateCode: "NY",
    stateName: "New York",
    city: "PALATINE BRIDGE"
  },
  "13431": {
    stateCode: "NY",
    stateName: "New York",
    city: "POLAND"
  },
  "13433": {
    stateCode: "NY",
    stateName: "New York",
    city: "PORT LEYDEN"
  },
  "13436": {
    stateCode: "NY",
    stateName: "New York",
    city: "RAQUETTE LAKE"
  },
  "13437": {
    stateCode: "NY",
    stateName: "New York",
    city: "REDFIELD"
  },
  "13438": {
    stateCode: "NY",
    stateName: "New York",
    city: "REMSEN"
  },
  "13439": {
    stateCode: "NY",
    stateName: "New York",
    city: "RICHFIELD SPRINGS"
  },
  "13440": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROME"
  },
  "13450": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROSEBOOM"
  },
  "13452": {
    stateCode: "NY",
    stateName: "New York",
    city: "SAINT JOHNSVILLE"
  },
  "13454": {
    stateCode: "NY",
    stateName: "New York",
    city: "SALISBURY CENTER"
  },
  "13456": {
    stateCode: "NY",
    stateName: "New York",
    city: "SAUQUOIT"
  },
  "13459": {
    stateCode: "NY",
    stateName: "New York",
    city: "SHARON SPRINGS"
  },
  "13460": {
    stateCode: "NY",
    stateName: "New York",
    city: "SHERBURNE"
  },
  "13461": {
    stateCode: "NY",
    stateName: "New York",
    city: "SHERRILL"
  },
  "13464": {
    stateCode: "NY",
    stateName: "New York",
    city: "SMYRNA"
  },
  "13468": {
    stateCode: "NY",
    stateName: "New York",
    city: "SPRINGFIELD CENTER"
  },
  "13469": {
    stateCode: "NY",
    stateName: "New York",
    city: "STITTVILLE"
  },
  "13470": {
    stateCode: "NY",
    stateName: "New York",
    city: "STRATFORD"
  },
  "13471": {
    stateCode: "NY",
    stateName: "New York",
    city: "TABERG"
  },
  "13473": {
    stateCode: "NY",
    stateName: "New York",
    city: "TURIN"
  },
  "13475": {
    stateCode: "NY",
    stateName: "New York",
    city: "VAN HORNESVILLE"
  },
  "13476": {
    stateCode: "NY",
    stateName: "New York",
    city: "VERNON"
  },
  "13477": {
    stateCode: "NY",
    stateName: "New York",
    city: "VERNON CENTER"
  },
  "13478": {
    stateCode: "NY",
    stateName: "New York",
    city: "VERONA"
  },
  "13480": {
    stateCode: "NY",
    stateName: "New York",
    city: "WATERVILLE"
  },
  "13483": {
    stateCode: "NY",
    stateName: "New York",
    city: "WESTDALE"
  },
  "13485": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST EDMESTON"
  },
  "13486": {
    stateCode: "NY",
    stateName: "New York",
    city: "WESTERNVILLE"
  },
  "13489": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST LEYDEN"
  },
  "13490": {
    stateCode: "NY",
    stateName: "New York",
    city: "WESTMORELAND"
  },
  "13491": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST WINFIELD"
  },
  "13492": {
    stateCode: "NY",
    stateName: "New York",
    city: "WHITESBORO"
  },
  "13493": {
    stateCode: "NY",
    stateName: "New York",
    city: "WILLIAMSTOWN"
  },
  "13495": {
    stateCode: "NY",
    stateName: "New York",
    city: "YORKVILLE"
  },
  "13501": {
    stateCode: "NY",
    stateName: "New York",
    city: "UTICA"
  },
  "13502": {
    stateCode: "NY",
    stateName: "New York",
    city: "UTICA"
  },
  "13601": {
    stateCode: "NY",
    stateName: "New York",
    city: "WATERTOWN"
  },
  "13602": {
    stateCode: "NY",
    stateName: "New York",
    city: "FORT DRUM"
  },
  "13603": {
    stateCode: "NY",
    stateName: "New York",
    city: "WATERTOWN"
  },
  "13605": {
    stateCode: "NY",
    stateName: "New York",
    city: "ADAMS"
  },
  "13606": {
    stateCode: "NY",
    stateName: "New York",
    city: "ADAMS CENTER"
  },
  "13607": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALEXANDRIA BAY"
  },
  "13608": {
    stateCode: "NY",
    stateName: "New York",
    city: "ANTWERP"
  },
  "13612": {
    stateCode: "NY",
    stateName: "New York",
    city: "BLACK RIVER"
  },
  "13613": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRASHER FALLS"
  },
  "13614": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRIER HILL"
  },
  "13615": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROWNVILLE"
  },
  "13616": {
    stateCode: "NY",
    stateName: "New York",
    city: "CALCIUM"
  },
  "13617": {
    stateCode: "NY",
    stateName: "New York",
    city: "CANTON"
  },
  "13618": {
    stateCode: "NY",
    stateName: "New York",
    city: "CAPE VINCENT"
  },
  "13619": {
    stateCode: "NY",
    stateName: "New York",
    city: "CARTHAGE"
  },
  "13620": {
    stateCode: "NY",
    stateName: "New York",
    city: "CASTORLAND"
  },
  "13621": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHASE MILLS"
  },
  "13622": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHAUMONT"
  },
  "13624": {
    stateCode: "NY",
    stateName: "New York",
    city: "CLAYTON"
  },
  "13625": {
    stateCode: "NY",
    stateName: "New York",
    city: "COLTON"
  },
  "13626": {
    stateCode: "NY",
    stateName: "New York",
    city: "COPENHAGEN"
  },
  "13630": {
    stateCode: "NY",
    stateName: "New York",
    city: "DE KALB JUNCTION"
  },
  "13633": {
    stateCode: "NY",
    stateName: "New York",
    city: "DE PEYSTER"
  },
  "13634": {
    stateCode: "NY",
    stateName: "New York",
    city: "DEXTER"
  },
  "13635": {
    stateCode: "NY",
    stateName: "New York",
    city: "EDWARDS"
  },
  "13637": {
    stateCode: "NY",
    stateName: "New York",
    city: "EVANS MILLS"
  },
  "13638": {
    stateCode: "NY",
    stateName: "New York",
    city: "FELTS MILLS"
  },
  "13639": {
    stateCode: "NY",
    stateName: "New York",
    city: "FINE"
  },
  "13640": {
    stateCode: "NY",
    stateName: "New York",
    city: "WELLESLEY ISLAND"
  },
  "13642": {
    stateCode: "NY",
    stateName: "New York",
    city: "GOUVERNEUR"
  },
  "13646": {
    stateCode: "NY",
    stateName: "New York",
    city: "HAMMOND"
  },
  "13648": {
    stateCode: "NY",
    stateName: "New York",
    city: "HARRISVILLE"
  },
  "13650": {
    stateCode: "NY",
    stateName: "New York",
    city: "HENDERSON"
  },
  "13652": {
    stateCode: "NY",
    stateName: "New York",
    city: "HERMON"
  },
  "13654": {
    stateCode: "NY",
    stateName: "New York",
    city: "HEUVELTON"
  },
  "13655": {
    stateCode: "NY",
    stateName: "New York",
    city: "HOGANSBURG"
  },
  "13656": {
    stateCode: "NY",
    stateName: "New York",
    city: "LA FARGEVILLE"
  },
  "13658": {
    stateCode: "NY",
    stateName: "New York",
    city: "LISBON"
  },
  "13659": {
    stateCode: "NY",
    stateName: "New York",
    city: "LORRAINE"
  },
  "13660": {
    stateCode: "NY",
    stateName: "New York",
    city: "MADRID"
  },
  "13661": {
    stateCode: "NY",
    stateName: "New York",
    city: "MANNSVILLE"
  },
  "13662": {
    stateCode: "NY",
    stateName: "New York",
    city: "MASSENA"
  },
  "13664": {
    stateCode: "NY",
    stateName: "New York",
    city: "MORRISTOWN"
  },
  "13665": {
    stateCode: "NY",
    stateName: "New York",
    city: "NATURAL BRIDGE"
  },
  "13667": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORFOLK"
  },
  "13668": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORWOOD"
  },
  "13669": {
    stateCode: "NY",
    stateName: "New York",
    city: "OGDENSBURG"
  },
  "13670": {
    stateCode: "NY",
    stateName: "New York",
    city: "OSWEGATCHIE"
  },
  "13672": {
    stateCode: "NY",
    stateName: "New York",
    city: "PARISHVILLE"
  },
  "13673": {
    stateCode: "NY",
    stateName: "New York",
    city: "PHILADELPHIA"
  },
  "13675": {
    stateCode: "NY",
    stateName: "New York",
    city: "PLESSIS"
  },
  "13676": {
    stateCode: "NY",
    stateName: "New York",
    city: "POTSDAM"
  },
  "13679": {
    stateCode: "NY",
    stateName: "New York",
    city: "REDWOOD"
  },
  "13680": {
    stateCode: "NY",
    stateName: "New York",
    city: "RENSSELAER FALLS"
  },
  "13681": {
    stateCode: "NY",
    stateName: "New York",
    city: "RICHVILLE"
  },
  "13682": {
    stateCode: "NY",
    stateName: "New York",
    city: "RODMAN"
  },
  "13684": {
    stateCode: "NY",
    stateName: "New York",
    city: "RUSSELL"
  },
  "13685": {
    stateCode: "NY",
    stateName: "New York",
    city: "SACKETS HARBOR"
  },
  "13687": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOUTH COLTON"
  },
  "13690": {
    stateCode: "NY",
    stateName: "New York",
    city: "STAR LAKE"
  },
  "13691": {
    stateCode: "NY",
    stateName: "New York",
    city: "THERESA"
  },
  "13693": {
    stateCode: "NY",
    stateName: "New York",
    city: "THREE MILE BAY"
  },
  "13694": {
    stateCode: "NY",
    stateName: "New York",
    city: "WADDINGTON"
  },
  "13697": {
    stateCode: "NY",
    stateName: "New York",
    city: "WINTHROP"
  },
  "13730": {
    stateCode: "NY",
    stateName: "New York",
    city: "AFTON"
  },
  "13731": {
    stateCode: "NY",
    stateName: "New York",
    city: "ANDES"
  },
  "13732": {
    stateCode: "NY",
    stateName: "New York",
    city: "APALACHIN"
  },
  "13733": {
    stateCode: "NY",
    stateName: "New York",
    city: "BAINBRIDGE"
  },
  "13734": {
    stateCode: "NY",
    stateName: "New York",
    city: "BARTON"
  },
  "13736": {
    stateCode: "NY",
    stateName: "New York",
    city: "BERKSHIRE"
  },
  "13739": {
    stateCode: "NY",
    stateName: "New York",
    city: "BLOOMVILLE"
  },
  "13740": {
    stateCode: "NY",
    stateName: "New York",
    city: "BOVINA CENTER"
  },
  "13743": {
    stateCode: "NY",
    stateName: "New York",
    city: "CANDOR"
  },
  "13744": {
    stateCode: "NY",
    stateName: "New York",
    city: "CASTLE CREEK"
  },
  "13746": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHENANGO FORKS"
  },
  "13748": {
    stateCode: "NY",
    stateName: "New York",
    city: "CONKLIN"
  },
  "13750": {
    stateCode: "NY",
    stateName: "New York",
    city: "DAVENPORT"
  },
  "13752": {
    stateCode: "NY",
    stateName: "New York",
    city: "DELANCEY"
  },
  "13753": {
    stateCode: "NY",
    stateName: "New York",
    city: "DELHI"
  },
  "13754": {
    stateCode: "NY",
    stateName: "New York",
    city: "DEPOSIT"
  },
  "13755": {
    stateCode: "NY",
    stateName: "New York",
    city: "DOWNSVILLE"
  },
  "13756": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST BRANCH"
  },
  "13757": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST MEREDITH"
  },
  "13760": {
    stateCode: "NY",
    stateName: "New York",
    city: "ENDICOTT"
  },
  "13774": {
    stateCode: "NY",
    stateName: "New York",
    city: "FISHS EDDY"
  },
  "13775": {
    stateCode: "NY",
    stateName: "New York",
    city: "FRANKLIN"
  },
  "13776": {
    stateCode: "NY",
    stateName: "New York",
    city: "GILBERTSVILLE"
  },
  "13777": {
    stateCode: "NY",
    stateName: "New York",
    city: "GLEN AUBREY"
  },
  "13778": {
    stateCode: "NY",
    stateName: "New York",
    city: "GREENE"
  },
  "13780": {
    stateCode: "NY",
    stateName: "New York",
    city: "GUILFORD"
  },
  "13782": {
    stateCode: "NY",
    stateName: "New York",
    city: "HAMDEN"
  },
  "13783": {
    stateCode: "NY",
    stateName: "New York",
    city: "HANCOCK"
  },
  "13786": {
    stateCode: "NY",
    stateName: "New York",
    city: "HARPERSFIELD"
  },
  "13787": {
    stateCode: "NY",
    stateName: "New York",
    city: "HARPURSVILLE"
  },
  "13788": {
    stateCode: "NY",
    stateName: "New York",
    city: "HOBART"
  },
  "13790": {
    stateCode: "NY",
    stateName: "New York",
    city: "JOHNSON CITY"
  },
  "13795": {
    stateCode: "NY",
    stateName: "New York",
    city: "KIRKWOOD"
  },
  "13796": {
    stateCode: "NY",
    stateName: "New York",
    city: "LAURENS"
  },
  "13797": {
    stateCode: "NY",
    stateName: "New York",
    city: "LISLE"
  },
  "13801": {
    stateCode: "NY",
    stateName: "New York",
    city: "MC DONOUGH"
  },
  "13802": {
    stateCode: "NY",
    stateName: "New York",
    city: "MAINE"
  },
  "13803": {
    stateCode: "NY",
    stateName: "New York",
    city: "MARATHON"
  },
  "13804": {
    stateCode: "NY",
    stateName: "New York",
    city: "MASONVILLE"
  },
  "13807": {
    stateCode: "NY",
    stateName: "New York",
    city: "MILFORD"
  },
  "13808": {
    stateCode: "NY",
    stateName: "New York",
    city: "MORRIS"
  },
  "13809": {
    stateCode: "NY",
    stateName: "New York",
    city: "MOUNT UPTON"
  },
  "13810": {
    stateCode: "NY",
    stateName: "New York",
    city: "MOUNT VISION"
  },
  "13811": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEWARK VALLEY"
  },
  "13812": {
    stateCode: "NY",
    stateName: "New York",
    city: "NICHOLS"
  },
  "13813": {
    stateCode: "NY",
    stateName: "New York",
    city: "NINEVEH"
  },
  "13815": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORWICH"
  },
  "13820": {
    stateCode: "NY",
    stateName: "New York",
    city: "ONEONTA"
  },
  "13825": {
    stateCode: "NY",
    stateName: "New York",
    city: "OTEGO"
  },
  "13827": {
    stateCode: "NY",
    stateName: "New York",
    city: "OWEGO"
  },
  "13830": {
    stateCode: "NY",
    stateName: "New York",
    city: "OXFORD"
  },
  "13832": {
    stateCode: "NY",
    stateName: "New York",
    city: "PLYMOUTH"
  },
  "13833": {
    stateCode: "NY",
    stateName: "New York",
    city: "PORT CRANE"
  },
  "13834": {
    stateCode: "NY",
    stateName: "New York",
    city: "PORTLANDVILLE"
  },
  "13835": {
    stateCode: "NY",
    stateName: "New York",
    city: "RICHFORD"
  },
  "13838": {
    stateCode: "NY",
    stateName: "New York",
    city: "SIDNEY"
  },
  "13839": {
    stateCode: "NY",
    stateName: "New York",
    city: "SIDNEY CENTER"
  },
  "13841": {
    stateCode: "NY",
    stateName: "New York",
    city: "SMITHVILLE FLATS"
  },
  "13842": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOUTH KORTRIGHT"
  },
  "13843": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOUTH NEW BERLIN"
  },
  "13844": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOUTH PLYMOUTH"
  },
  "13846": {
    stateCode: "NY",
    stateName: "New York",
    city: "TREADWELL"
  },
  "13849": {
    stateCode: "NY",
    stateName: "New York",
    city: "UNADILLA"
  },
  "13850": {
    stateCode: "NY",
    stateName: "New York",
    city: "VESTAL"
  },
  "13856": {
    stateCode: "NY",
    stateName: "New York",
    city: "WALTON"
  },
  "13859": {
    stateCode: "NY",
    stateName: "New York",
    city: "WELLS BRIDGE"
  },
  "13861": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST ONEONTA"
  },
  "13862": {
    stateCode: "NY",
    stateName: "New York",
    city: "WHITNEY POINT"
  },
  "13863": {
    stateCode: "NY",
    stateName: "New York",
    city: "WILLET"
  },
  "13864": {
    stateCode: "NY",
    stateName: "New York",
    city: "WILLSEYVILLE"
  },
  "13865": {
    stateCode: "NY",
    stateName: "New York",
    city: "WINDSOR"
  },
  "13901": {
    stateCode: "NY",
    stateName: "New York",
    city: "BINGHAMTON"
  },
  "13903": {
    stateCode: "NY",
    stateName: "New York",
    city: "BINGHAMTON"
  },
  "13904": {
    stateCode: "NY",
    stateName: "New York",
    city: "BINGHAMTON"
  },
  "13905": {
    stateCode: "NY",
    stateName: "New York",
    city: "BINGHAMTON"
  },
  "14001": {
    stateCode: "NY",
    stateName: "New York",
    city: "AKRON"
  },
  "14004": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALDEN"
  },
  "14005": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALEXANDER"
  },
  "14006": {
    stateCode: "NY",
    stateName: "New York",
    city: "ANGOLA"
  },
  "14008": {
    stateCode: "NY",
    stateName: "New York",
    city: "APPLETON"
  },
  "14009": {
    stateCode: "NY",
    stateName: "New York",
    city: "ARCADE"
  },
  "14011": {
    stateCode: "NY",
    stateName: "New York",
    city: "ATTICA"
  },
  "14012": {
    stateCode: "NY",
    stateName: "New York",
    city: "BARKER"
  },
  "14013": {
    stateCode: "NY",
    stateName: "New York",
    city: "BASOM"
  },
  "14020": {
    stateCode: "NY",
    stateName: "New York",
    city: "BATAVIA"
  },
  "14024": {
    stateCode: "NY",
    stateName: "New York",
    city: "BLISS"
  },
  "14025": {
    stateCode: "NY",
    stateName: "New York",
    city: "BOSTON"
  },
  "14026": {
    stateCode: "NY",
    stateName: "New York",
    city: "BOWMANSVILLE"
  },
  "14028": {
    stateCode: "NY",
    stateName: "New York",
    city: "BURT"
  },
  "14030": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHAFFEE"
  },
  "14031": {
    stateCode: "NY",
    stateName: "New York",
    city: "CLARENCE"
  },
  "14032": {
    stateCode: "NY",
    stateName: "New York",
    city: "CLARENCE CENTER"
  },
  "14033": {
    stateCode: "NY",
    stateName: "New York",
    city: "COLDEN"
  },
  "14034": {
    stateCode: "NY",
    stateName: "New York",
    city: "COLLINS"
  },
  "14036": {
    stateCode: "NY",
    stateName: "New York",
    city: "CORFU"
  },
  "14037": {
    stateCode: "NY",
    stateName: "New York",
    city: "COWLESVILLE"
  },
  "14039": {
    stateCode: "NY",
    stateName: "New York",
    city: "DALE"
  },
  "14040": {
    stateCode: "NY",
    stateName: "New York",
    city: "DARIEN CENTER"
  },
  "14042": {
    stateCode: "NY",
    stateName: "New York",
    city: "DELEVAN"
  },
  "14043": {
    stateCode: "NY",
    stateName: "New York",
    city: "DEPEW"
  },
  "14047": {
    stateCode: "NY",
    stateName: "New York",
    city: "DERBY"
  },
  "14048": {
    stateCode: "NY",
    stateName: "New York",
    city: "DUNKIRK"
  },
  "14051": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST AMHERST"
  },
  "14052": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST AURORA"
  },
  "14054": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST BETHANY"
  },
  "14055": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST CONCORD"
  },
  "14057": {
    stateCode: "NY",
    stateName: "New York",
    city: "EDEN"
  },
  "14058": {
    stateCode: "NY",
    stateName: "New York",
    city: "ELBA"
  },
  "14059": {
    stateCode: "NY",
    stateName: "New York",
    city: "ELMA"
  },
  "14060": {
    stateCode: "NY",
    stateName: "New York",
    city: "FARMERSVILLE STATION"
  },
  "14062": {
    stateCode: "NY",
    stateName: "New York",
    city: "FORESTVILLE"
  },
  "14063": {
    stateCode: "NY",
    stateName: "New York",
    city: "FREDONIA"
  },
  "14065": {
    stateCode: "NY",
    stateName: "New York",
    city: "FREEDOM"
  },
  "14066": {
    stateCode: "NY",
    stateName: "New York",
    city: "GAINESVILLE"
  },
  "14067": {
    stateCode: "NY",
    stateName: "New York",
    city: "GASPORT"
  },
  "14068": {
    stateCode: "NY",
    stateName: "New York",
    city: "GETZVILLE"
  },
  "14069": {
    stateCode: "NY",
    stateName: "New York",
    city: "GLENWOOD"
  },
  "14070": {
    stateCode: "NY",
    stateName: "New York",
    city: "GOWANDA"
  },
  "14072": {
    stateCode: "NY",
    stateName: "New York",
    city: "GRAND ISLAND"
  },
  "14075": {
    stateCode: "NY",
    stateName: "New York",
    city: "HAMBURG"
  },
  "14080": {
    stateCode: "NY",
    stateName: "New York",
    city: "HOLLAND"
  },
  "14081": {
    stateCode: "NY",
    stateName: "New York",
    city: "IRVING"
  },
  "14082": {
    stateCode: "NY",
    stateName: "New York",
    city: "JAVA CENTER"
  },
  "14083": {
    stateCode: "NY",
    stateName: "New York",
    city: "JAVA VILLAGE"
  },
  "14085": {
    stateCode: "NY",
    stateName: "New York",
    city: "LAKE VIEW"
  },
  "14086": {
    stateCode: "NY",
    stateName: "New York",
    city: "LANCASTER"
  },
  "14091": {
    stateCode: "NY",
    stateName: "New York",
    city: "LAWTONS"
  },
  "14092": {
    stateCode: "NY",
    stateName: "New York",
    city: "LEWISTON"
  },
  "14094": {
    stateCode: "NY",
    stateName: "New York",
    city: "LOCKPORT"
  },
  "14098": {
    stateCode: "NY",
    stateName: "New York",
    city: "LYNDONVILLE"
  },
  "14101": {
    stateCode: "NY",
    stateName: "New York",
    city: "MACHIAS"
  },
  "14102": {
    stateCode: "NY",
    stateName: "New York",
    city: "MARILLA"
  },
  "14103": {
    stateCode: "NY",
    stateName: "New York",
    city: "MEDINA"
  },
  "14105": {
    stateCode: "NY",
    stateName: "New York",
    city: "MIDDLEPORT"
  },
  "14108": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEWFANE"
  },
  "14111": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORTH COLLINS"
  },
  "14113": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORTH JAVA"
  },
  "14120": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORTH TONAWANDA"
  },
  "14125": {
    stateCode: "NY",
    stateName: "New York",
    city: "OAKFIELD"
  },
  "14127": {
    stateCode: "NY",
    stateName: "New York",
    city: "ORCHARD PARK"
  },
  "14129": {
    stateCode: "NY",
    stateName: "New York",
    city: "PERRYSBURG"
  },
  "14131": {
    stateCode: "NY",
    stateName: "New York",
    city: "RANSOMVILLE"
  },
  "14132": {
    stateCode: "NY",
    stateName: "New York",
    city: "SANBORN"
  },
  "14136": {
    stateCode: "NY",
    stateName: "New York",
    city: "SILVER CREEK"
  },
  "14138": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOUTH DAYTON"
  },
  "14139": {
    stateCode: "NY",
    stateName: "New York",
    city: "SOUTH WALES"
  },
  "14141": {
    stateCode: "NY",
    stateName: "New York",
    city: "SPRINGVILLE"
  },
  "14143": {
    stateCode: "NY",
    stateName: "New York",
    city: "STAFFORD"
  },
  "14145": {
    stateCode: "NY",
    stateName: "New York",
    city: "STRYKERSVILLE"
  },
  "14150": {
    stateCode: "NY",
    stateName: "New York",
    city: "TONAWANDA"
  },
  "14167": {
    stateCode: "NY",
    stateName: "New York",
    city: "VARYSBURG"
  },
  "14170": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST FALLS"
  },
  "14171": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST VALLEY"
  },
  "14172": {
    stateCode: "NY",
    stateName: "New York",
    city: "WILSON"
  },
  "14174": {
    stateCode: "NY",
    stateName: "New York",
    city: "YOUNGSTOWN"
  },
  "14201": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14202": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14203": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14204": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14206": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14207": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14208": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14209": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14210": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14211": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14212": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14213": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14214": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14215": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14216": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14217": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14218": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14219": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14220": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14221": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14222": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14223": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14224": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14225": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14226": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14227": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14228": {
    stateCode: "NY",
    stateName: "New York",
    city: "BUFFALO"
  },
  "14301": {
    stateCode: "NY",
    stateName: "New York",
    city: "NIAGARA FALLS"
  },
  "14303": {
    stateCode: "NY",
    stateName: "New York",
    city: "NIAGARA FALLS"
  },
  "14304": {
    stateCode: "NY",
    stateName: "New York",
    city: "NIAGARA FALLS"
  },
  "14305": {
    stateCode: "NY",
    stateName: "New York",
    city: "NIAGARA FALLS"
  },
  "14411": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALBION"
  },
  "14414": {
    stateCode: "NY",
    stateName: "New York",
    city: "AVON"
  },
  "14415": {
    stateCode: "NY",
    stateName: "New York",
    city: "BELLONA"
  },
  "14416": {
    stateCode: "NY",
    stateName: "New York",
    city: "BERGEN"
  },
  "14418": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRANCHPORT"
  },
  "14420": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROCKPORT"
  },
  "14422": {
    stateCode: "NY",
    stateName: "New York",
    city: "BYRON"
  },
  "14423": {
    stateCode: "NY",
    stateName: "New York",
    city: "CALEDONIA"
  },
  "14424": {
    stateCode: "NY",
    stateName: "New York",
    city: "CANANDAIGUA"
  },
  "14425": {
    stateCode: "NY",
    stateName: "New York",
    city: "FARMINGTON"
  },
  "14427": {
    stateCode: "NY",
    stateName: "New York",
    city: "CASTILE"
  },
  "14428": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHURCHVILLE"
  },
  "14432": {
    stateCode: "NY",
    stateName: "New York",
    city: "CLIFTON SPRINGS"
  },
  "14433": {
    stateCode: "NY",
    stateName: "New York",
    city: "CLYDE"
  },
  "14435": {
    stateCode: "NY",
    stateName: "New York",
    city: "CONESUS"
  },
  "14437": {
    stateCode: "NY",
    stateName: "New York",
    city: "DANSVILLE"
  },
  "14441": {
    stateCode: "NY",
    stateName: "New York",
    city: "DRESDEN"
  },
  "14445": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST ROCHESTER"
  },
  "14450": {
    stateCode: "NY",
    stateName: "New York",
    city: "FAIRPORT"
  },
  "14454": {
    stateCode: "NY",
    stateName: "New York",
    city: "GENESEO"
  },
  "14456": {
    stateCode: "NY",
    stateName: "New York",
    city: "GENEVA"
  },
  "14462": {
    stateCode: "NY",
    stateName: "New York",
    city: "GROVELAND"
  },
  "14464": {
    stateCode: "NY",
    stateName: "New York",
    city: "HAMLIN"
  },
  "14466": {
    stateCode: "NY",
    stateName: "New York",
    city: "HEMLOCK"
  },
  "14467": {
    stateCode: "NY",
    stateName: "New York",
    city: "HENRIETTA"
  },
  "14468": {
    stateCode: "NY",
    stateName: "New York",
    city: "HILTON"
  },
  "14469": {
    stateCode: "NY",
    stateName: "New York",
    city: "BLOOMFIELD"
  },
  "14470": {
    stateCode: "NY",
    stateName: "New York",
    city: "HOLLEY"
  },
  "14471": {
    stateCode: "NY",
    stateName: "New York",
    city: "HONEOYE"
  },
  "14472": {
    stateCode: "NY",
    stateName: "New York",
    city: "HONEOYE FALLS"
  },
  "14475": {
    stateCode: "NY",
    stateName: "New York",
    city: "IONIA"
  },
  "14476": {
    stateCode: "NY",
    stateName: "New York",
    city: "KENDALL"
  },
  "14477": {
    stateCode: "NY",
    stateName: "New York",
    city: "KENT"
  },
  "14478": {
    stateCode: "NY",
    stateName: "New York",
    city: "KEUKA PARK"
  },
  "14480": {
    stateCode: "NY",
    stateName: "New York",
    city: "LAKEVILLE"
  },
  "14481": {
    stateCode: "NY",
    stateName: "New York",
    city: "LEICESTER"
  },
  "14482": {
    stateCode: "NY",
    stateName: "New York",
    city: "LE ROY"
  },
  "14485": {
    stateCode: "NY",
    stateName: "New York",
    city: "LIMA"
  },
  "14487": {
    stateCode: "NY",
    stateName: "New York",
    city: "LIVONIA"
  },
  "14489": {
    stateCode: "NY",
    stateName: "New York",
    city: "LYONS"
  },
  "14502": {
    stateCode: "NY",
    stateName: "New York",
    city: "MACEDON"
  },
  "14504": {
    stateCode: "NY",
    stateName: "New York",
    city: "MANCHESTER"
  },
  "14505": {
    stateCode: "NY",
    stateName: "New York",
    city: "MARION"
  },
  "14506": {
    stateCode: "NY",
    stateName: "New York",
    city: "MENDON"
  },
  "14507": {
    stateCode: "NY",
    stateName: "New York",
    city: "MIDDLESEX"
  },
  "14510": {
    stateCode: "NY",
    stateName: "New York",
    city: "MOUNT MORRIS"
  },
  "14512": {
    stateCode: "NY",
    stateName: "New York",
    city: "NAPLES"
  },
  "14513": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEWARK"
  },
  "14514": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORTH CHILI"
  },
  "14516": {
    stateCode: "NY",
    stateName: "New York",
    city: "NORTH ROSE"
  },
  "14517": {
    stateCode: "NY",
    stateName: "New York",
    city: "NUNDA"
  },
  "14519": {
    stateCode: "NY",
    stateName: "New York",
    city: "ONTARIO"
  },
  "14521": {
    stateCode: "NY",
    stateName: "New York",
    city: "OVID"
  },
  "14522": {
    stateCode: "NY",
    stateName: "New York",
    city: "PALMYRA"
  },
  "14525": {
    stateCode: "NY",
    stateName: "New York",
    city: "PAVILION"
  },
  "14526": {
    stateCode: "NY",
    stateName: "New York",
    city: "PENFIELD"
  },
  "14527": {
    stateCode: "NY",
    stateName: "New York",
    city: "PENN YAN"
  },
  "14530": {
    stateCode: "NY",
    stateName: "New York",
    city: "PERRY"
  },
  "14532": {
    stateCode: "NY",
    stateName: "New York",
    city: "PHELPS"
  },
  "14533": {
    stateCode: "NY",
    stateName: "New York",
    city: "PIFFARD"
  },
  "14534": {
    stateCode: "NY",
    stateName: "New York",
    city: "PITTSFORD"
  },
  "14536": {
    stateCode: "NY",
    stateName: "New York",
    city: "PORTAGEVILLE"
  },
  "14541": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROMULUS"
  },
  "14543": {
    stateCode: "NY",
    stateName: "New York",
    city: "RUSH"
  },
  "14544": {
    stateCode: "NY",
    stateName: "New York",
    city: "RUSHVILLE"
  },
  "14545": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCOTTSBURG"
  },
  "14546": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCOTTSVILLE"
  },
  "14548": {
    stateCode: "NY",
    stateName: "New York",
    city: "SHORTSVILLE"
  },
  "14550": {
    stateCode: "NY",
    stateName: "New York",
    city: "SILVER SPRINGS"
  },
  "14551": {
    stateCode: "NY",
    stateName: "New York",
    city: "SODUS"
  },
  "14555": {
    stateCode: "NY",
    stateName: "New York",
    city: "SODUS POINT"
  },
  "14559": {
    stateCode: "NY",
    stateName: "New York",
    city: "SPENCERPORT"
  },
  "14560": {
    stateCode: "NY",
    stateName: "New York",
    city: "SPRINGWATER"
  },
  "14561": {
    stateCode: "NY",
    stateName: "New York",
    city: "STANLEY"
  },
  "14564": {
    stateCode: "NY",
    stateName: "New York",
    city: "VICTOR"
  },
  "14568": {
    stateCode: "NY",
    stateName: "New York",
    city: "WALWORTH"
  },
  "14569": {
    stateCode: "NY",
    stateName: "New York",
    city: "WARSAW"
  },
  "14571": {
    stateCode: "NY",
    stateName: "New York",
    city: "WATERPORT"
  },
  "14572": {
    stateCode: "NY",
    stateName: "New York",
    city: "WAYLAND"
  },
  "14580": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEBSTER"
  },
  "14585": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST BLOOMFIELD"
  },
  "14586": {
    stateCode: "NY",
    stateName: "New York",
    city: "WEST HENRIETTA"
  },
  "14589": {
    stateCode: "NY",
    stateName: "New York",
    city: "WILLIAMSON"
  },
  "14590": {
    stateCode: "NY",
    stateName: "New York",
    city: "WOLCOTT"
  },
  "14591": {
    stateCode: "NY",
    stateName: "New York",
    city: "WYOMING"
  },
  "14604": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14605": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14606": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14607": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14608": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14609": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14610": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14611": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14612": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14613": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14614": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14615": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14616": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14617": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14618": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14619": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14620": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14621": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14622": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14623": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14624": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14625": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14626": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCHESTER"
  },
  "14701": {
    stateCode: "NY",
    stateName: "New York",
    city: "JAMESTOWN"
  },
  "14706": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALLEGANY"
  },
  "14708": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALMA"
  },
  "14709": {
    stateCode: "NY",
    stateName: "New York",
    city: "ANGELICA"
  },
  "14710": {
    stateCode: "NY",
    stateName: "New York",
    city: "ASHVILLE"
  },
  "14711": {
    stateCode: "NY",
    stateName: "New York",
    city: "BELFAST"
  },
  "14712": {
    stateCode: "NY",
    stateName: "New York",
    city: "BEMUS POINT"
  },
  "14714": {
    stateCode: "NY",
    stateName: "New York",
    city: "BLACK CREEK"
  },
  "14715": {
    stateCode: "NY",
    stateName: "New York",
    city: "BOLIVAR"
  },
  "14716": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROCTON"
  },
  "14717": {
    stateCode: "NY",
    stateName: "New York",
    city: "CANEADEA"
  },
  "14718": {
    stateCode: "NY",
    stateName: "New York",
    city: "CASSADAGA"
  },
  "14719": {
    stateCode: "NY",
    stateName: "New York",
    city: "CATTARAUGUS"
  },
  "14721": {
    stateCode: "NY",
    stateName: "New York",
    city: "CERES"
  },
  "14723": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHERRY CREEK"
  },
  "14724": {
    stateCode: "NY",
    stateName: "New York",
    city: "CLYMER"
  },
  "14726": {
    stateCode: "NY",
    stateName: "New York",
    city: "CONEWANGO VALLEY"
  },
  "14727": {
    stateCode: "NY",
    stateName: "New York",
    city: "CUBA"
  },
  "14728": {
    stateCode: "NY",
    stateName: "New York",
    city: "DEWITTVILLE"
  },
  "14729": {
    stateCode: "NY",
    stateName: "New York",
    city: "EAST OTTO"
  },
  "14731": {
    stateCode: "NY",
    stateName: "New York",
    city: "ELLICOTTVILLE"
  },
  "14733": {
    stateCode: "NY",
    stateName: "New York",
    city: "FALCONER"
  },
  "14735": {
    stateCode: "NY",
    stateName: "New York",
    city: "FILLMORE"
  },
  "14736": {
    stateCode: "NY",
    stateName: "New York",
    city: "FINDLEY LAKE"
  },
  "14737": {
    stateCode: "NY",
    stateName: "New York",
    city: "FRANKLINVILLE"
  },
  "14738": {
    stateCode: "NY",
    stateName: "New York",
    city: "FREWSBURG"
  },
  "14739": {
    stateCode: "NY",
    stateName: "New York",
    city: "FRIENDSHIP"
  },
  "14740": {
    stateCode: "NY",
    stateName: "New York",
    city: "GERRY"
  },
  "14741": {
    stateCode: "NY",
    stateName: "New York",
    city: "GREAT VALLEY"
  },
  "14743": {
    stateCode: "NY",
    stateName: "New York",
    city: "HINSDALE"
  },
  "14744": {
    stateCode: "NY",
    stateName: "New York",
    city: "HOUGHTON"
  },
  "14747": {
    stateCode: "NY",
    stateName: "New York",
    city: "KENNEDY"
  },
  "14748": {
    stateCode: "NY",
    stateName: "New York",
    city: "KILL BUCK"
  },
  "14750": {
    stateCode: "NY",
    stateName: "New York",
    city: "LAKEWOOD"
  },
  "14753": {
    stateCode: "NY",
    stateName: "New York",
    city: "LIMESTONE"
  },
  "14754": {
    stateCode: "NY",
    stateName: "New York",
    city: "LITTLE GENESEE"
  },
  "14755": {
    stateCode: "NY",
    stateName: "New York",
    city: "LITTLE VALLEY"
  },
  "14757": {
    stateCode: "NY",
    stateName: "New York",
    city: "MAYVILLE"
  },
  "14760": {
    stateCode: "NY",
    stateName: "New York",
    city: "OLEAN"
  },
  "14767": {
    stateCode: "NY",
    stateName: "New York",
    city: "PANAMA"
  },
  "14769": {
    stateCode: "NY",
    stateName: "New York",
    city: "PORTLAND"
  },
  "14770": {
    stateCode: "NY",
    stateName: "New York",
    city: "PORTVILLE"
  },
  "14772": {
    stateCode: "NY",
    stateName: "New York",
    city: "RANDOLPH"
  },
  "14775": {
    stateCode: "NY",
    stateName: "New York",
    city: "RIPLEY"
  },
  "14777": {
    stateCode: "NY",
    stateName: "New York",
    city: "RUSHFORD"
  },
  "14779": {
    stateCode: "NY",
    stateName: "New York",
    city: "SALAMANCA"
  },
  "14781": {
    stateCode: "NY",
    stateName: "New York",
    city: "SHERMAN"
  },
  "14782": {
    stateCode: "NY",
    stateName: "New York",
    city: "SINCLAIRVILLE"
  },
  "14783": {
    stateCode: "NY",
    stateName: "New York",
    city: "STEAMBURG"
  },
  "14784": {
    stateCode: "NY",
    stateName: "New York",
    city: "STOCKTON"
  },
  "14787": {
    stateCode: "NY",
    stateName: "New York",
    city: "WESTFIELD"
  },
  "14801": {
    stateCode: "NY",
    stateName: "New York",
    city: "ADDISON"
  },
  "14802": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALFRED"
  },
  "14803": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALFRED STATION"
  },
  "14804": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALMOND"
  },
  "14805": {
    stateCode: "NY",
    stateName: "New York",
    city: "ALPINE"
  },
  "14806": {
    stateCode: "NY",
    stateName: "New York",
    city: "ANDOVER"
  },
  "14807": {
    stateCode: "NY",
    stateName: "New York",
    city: "ARKPORT"
  },
  "14808": {
    stateCode: "NY",
    stateName: "New York",
    city: "ATLANTA"
  },
  "14809": {
    stateCode: "NY",
    stateName: "New York",
    city: "AVOCA"
  },
  "14810": {
    stateCode: "NY",
    stateName: "New York",
    city: "BATH"
  },
  "14812": {
    stateCode: "NY",
    stateName: "New York",
    city: "BEAVER DAMS"
  },
  "14813": {
    stateCode: "NY",
    stateName: "New York",
    city: "BELMONT"
  },
  "14814": {
    stateCode: "NY",
    stateName: "New York",
    city: "BIG FLATS"
  },
  "14815": {
    stateCode: "NY",
    stateName: "New York",
    city: "BRADFORD"
  },
  "14816": {
    stateCode: "NY",
    stateName: "New York",
    city: "BREESPORT"
  },
  "14817": {
    stateCode: "NY",
    stateName: "New York",
    city: "BROOKTONDALE"
  },
  "14818": {
    stateCode: "NY",
    stateName: "New York",
    city: "BURDETT"
  },
  "14819": {
    stateCode: "NY",
    stateName: "New York",
    city: "CAMERON"
  },
  "14820": {
    stateCode: "NY",
    stateName: "New York",
    city: "CAMERON MILLS"
  },
  "14821": {
    stateCode: "NY",
    stateName: "New York",
    city: "CAMPBELL"
  },
  "14822": {
    stateCode: "NY",
    stateName: "New York",
    city: "CANASERAGA"
  },
  "14823": {
    stateCode: "NY",
    stateName: "New York",
    city: "CANISTEO"
  },
  "14824": {
    stateCode: "NY",
    stateName: "New York",
    city: "CAYUTA"
  },
  "14825": {
    stateCode: "NY",
    stateName: "New York",
    city: "CHEMUNG"
  },
  "14826": {
    stateCode: "NY",
    stateName: "New York",
    city: "COHOCTON"
  },
  "14830": {
    stateCode: "NY",
    stateName: "New York",
    city: "CORNING"
  },
  "14836": {
    stateCode: "NY",
    stateName: "New York",
    city: "DALTON"
  },
  "14837": {
    stateCode: "NY",
    stateName: "New York",
    city: "DUNDEE"
  },
  "14838": {
    stateCode: "NY",
    stateName: "New York",
    city: "ERIN"
  },
  "14839": {
    stateCode: "NY",
    stateName: "New York",
    city: "GREENWOOD"
  },
  "14840": {
    stateCode: "NY",
    stateName: "New York",
    city: "HAMMONDSPORT"
  },
  "14841": {
    stateCode: "NY",
    stateName: "New York",
    city: "HECTOR"
  },
  "14842": {
    stateCode: "NY",
    stateName: "New York",
    city: "HIMROD"
  },
  "14843": {
    stateCode: "NY",
    stateName: "New York",
    city: "HORNELL"
  },
  "14845": {
    stateCode: "NY",
    stateName: "New York",
    city: "HORSEHEADS"
  },
  "14846": {
    stateCode: "NY",
    stateName: "New York",
    city: "HUNT"
  },
  "14847": {
    stateCode: "NY",
    stateName: "New York",
    city: "INTERLAKEN"
  },
  "14850": {
    stateCode: "NY",
    stateName: "New York",
    city: "ITHACA"
  },
  "14855": {
    stateCode: "NY",
    stateName: "New York",
    city: "JASPER"
  },
  "14858": {
    stateCode: "NY",
    stateName: "New York",
    city: "LINDLEY"
  },
  "14859": {
    stateCode: "NY",
    stateName: "New York",
    city: "LOCKWOOD"
  },
  "14860": {
    stateCode: "NY",
    stateName: "New York",
    city: "LODI"
  },
  "14861": {
    stateCode: "NY",
    stateName: "New York",
    city: "LOWMAN"
  },
  "14864": {
    stateCode: "NY",
    stateName: "New York",
    city: "MILLPORT"
  },
  "14865": {
    stateCode: "NY",
    stateName: "New York",
    city: "MONTOUR FALLS"
  },
  "14867": {
    stateCode: "NY",
    stateName: "New York",
    city: "NEWFIELD"
  },
  "14869": {
    stateCode: "NY",
    stateName: "New York",
    city: "ODESSA"
  },
  "14870": {
    stateCode: "NY",
    stateName: "New York",
    city: "PAINTED POST"
  },
  "14871": {
    stateCode: "NY",
    stateName: "New York",
    city: "PINE CITY"
  },
  "14872": {
    stateCode: "NY",
    stateName: "New York",
    city: "PINE VALLEY"
  },
  "14873": {
    stateCode: "NY",
    stateName: "New York",
    city: "PRATTSBURGH"
  },
  "14874": {
    stateCode: "NY",
    stateName: "New York",
    city: "PULTENEY"
  },
  "14877": {
    stateCode: "NY",
    stateName: "New York",
    city: "REXVILLE"
  },
  "14878": {
    stateCode: "NY",
    stateName: "New York",
    city: "ROCK STREAM"
  },
  "14879": {
    stateCode: "NY",
    stateName: "New York",
    city: "SAVONA"
  },
  "14880": {
    stateCode: "NY",
    stateName: "New York",
    city: "SCIO"
  },
  "14881": {
    stateCode: "NY",
    stateName: "New York",
    city: "SLATERVILLE SPRINGS"
  },
  "14882": {
    stateCode: "NY",
    stateName: "New York",
    city: "LANSING"
  },
  "14883": {
    stateCode: "NY",
    stateName: "New York",
    city: "SPENCER"
  },
  "14884": {
    stateCode: "NY",
    stateName: "New York",
    city: "SWAIN"
  },
  "14885": {
    stateCode: "NY",
    stateName: "New York",
    city: "TROUPSBURG"
  },
  "14886": {
    stateCode: "NY",
    stateName: "New York",
    city: "TRUMANSBURG"
  },
  "14889": {
    stateCode: "NY",
    stateName: "New York",
    city: "VAN ETTEN"
  },
  "14891": {
    stateCode: "NY",
    stateName: "New York",
    city: "WATKINS GLEN"
  },
  "14892": {
    stateCode: "NY",
    stateName: "New York",
    city: "WAVERLY"
  },
  "14894": {
    stateCode: "NY",
    stateName: "New York",
    city: "WELLSBURG"
  },
  "14895": {
    stateCode: "NY",
    stateName: "New York",
    city: "WELLSVILLE"
  },
  "14897": {
    stateCode: "NY",
    stateName: "New York",
    city: "WHITESVILLE"
  },
  "14898": {
    stateCode: "NY",
    stateName: "New York",
    city: "WOODHULL"
  },
  "14901": {
    stateCode: "NY",
    stateName: "New York",
    city: "ELMIRA"
  },
  "14903": {
    stateCode: "NY",
    stateName: "New York",
    city: "ELMIRA"
  },
  "14904": {
    stateCode: "NY",
    stateName: "New York",
    city: "ELMIRA"
  },
  "14905": {
    stateCode: "NY",
    stateName: "New York",
    city: "ELMIRA"
  },
  "15001": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALIQUIPPA"
  },
  "15003": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "AMBRIDGE"
  },
  "15004": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ATLASBURG"
  },
  "15005": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BADEN"
  },
  "15006": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BAIRDFORD"
  },
  "15007": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BAKERSTOWN"
  },
  "15009": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BEAVER"
  },
  "15010": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BEAVER FALLS"
  },
  "15012": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BELLE VERNON"
  },
  "15014": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BRACKENRIDGE"
  },
  "15015": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BRADFORDWOODS"
  },
  "15017": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BRIDGEVILLE"
  },
  "15018": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BUENA VISTA"
  },
  "15019": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BULGER"
  },
  "15020": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BUNOLA"
  },
  "15021": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BURGETTSTOWN"
  },
  "15022": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CHARLEROI"
  },
  "15024": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CHESWICK"
  },
  "15025": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLAIRTON"
  },
  "15026": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLINTON"
  },
  "15027": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CONWAY"
  },
  "15028": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COULTERS"
  },
  "15030": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CREIGHTON"
  },
  "15031": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CUDDY"
  },
  "15033": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DONORA"
  },
  "15034": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DRAVOSBURG"
  },
  "15035": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAST MC KEESPORT"
  },
  "15037": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ELIZABETH"
  },
  "15038": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ELRAMA"
  },
  "15042": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FREEDOM"
  },
  "15043": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GEORGETOWN"
  },
  "15044": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GIBSONIA"
  },
  "15045": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GLASSPORT"
  },
  "15046": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CRESCENT"
  },
  "15049": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARWICK"
  },
  "15050": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HOOKSTOWN"
  },
  "15051": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "INDIANOLA"
  },
  "15052": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "INDUSTRY"
  },
  "15053": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JOFFRE"
  },
  "15054": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LANGELOTH"
  },
  "15055": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAWRENCE"
  },
  "15056": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEETSDALE"
  },
  "15057": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MC DONALD"
  },
  "15059": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MIDLAND"
  },
  "15060": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MIDWAY"
  },
  "15061": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MONACA"
  },
  "15062": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MONESSEN"
  },
  "15063": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MONONGAHELA"
  },
  "15064": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MORGAN"
  },
  "15065": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NATRONA HEIGHTS"
  },
  "15066": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW BRIGHTON"
  },
  "15067": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW EAGLE"
  },
  "15068": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW KENSINGTON"
  },
  "15071": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "OAKDALE"
  },
  "15072": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PRICEDALE"
  },
  "15074": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROCHESTER"
  },
  "15075": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RURAL RIDGE"
  },
  "15076": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RUSSELLTON"
  },
  "15078": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SLOVAN"
  },
  "15081": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SOUTH HEIGHTS"
  },
  "15082": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STURGEON"
  },
  "15083": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SUTERSVILLE"
  },
  "15084": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TARENTUM"
  },
  "15085": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TRAFFORD"
  },
  "15086": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WARRENDALE"
  },
  "15087": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEBSTER"
  },
  "15088": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEST ELIZABETH"
  },
  "15089": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEST NEWTON"
  },
  "15090": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEXFORD"
  },
  "15101": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALLISON PARK"
  },
  "15102": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BETHEL PARK"
  },
  "15104": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BRADDOCK"
  },
  "15106": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CARNEGIE"
  },
  "15108": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CORAOPOLIS"
  },
  "15110": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DUQUESNE"
  },
  "15112": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAST PITTSBURGH"
  },
  "15116": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GLENSHAW"
  },
  "15120": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HOMESTEAD"
  },
  "15122": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEST MIFFLIN"
  },
  "15126": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "IMPERIAL"
  },
  "15129": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SOUTH PARK"
  },
  "15131": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MCKEESPORT"
  },
  "15132": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MCKEESPORT"
  },
  "15133": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MCKEESPORT"
  },
  "15135": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MCKEESPORT"
  },
  "15136": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MC KEES ROCKS"
  },
  "15137": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NORTH VERSAILLES"
  },
  "15139": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "OAKMONT"
  },
  "15140": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITCAIRN"
  },
  "15142": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PRESTO"
  },
  "15143": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SEWICKLEY"
  },
  "15144": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPRINGDALE"
  },
  "15145": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TURTLE CREEK"
  },
  "15146": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MONROEVILLE"
  },
  "15147": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "VERONA"
  },
  "15148": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WILMERDING"
  },
  "15201": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15202": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15203": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15204": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15205": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15206": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15207": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15208": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15209": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15210": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15211": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15212": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15213": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15214": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15215": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15216": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15217": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15218": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15219": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15220": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15221": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15222": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15223": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15224": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15225": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15226": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15227": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15228": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15229": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15232": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15233": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15234": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15235": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15236": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15237": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15238": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15239": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15241": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15243": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSBURGH"
  },
  "15301": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WASHINGTON"
  },
  "15310": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALEPPO"
  },
  "15311": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "AMITY"
  },
  "15312": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "AVELLA"
  },
  "15313": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BEALLSVILLE"
  },
  "15314": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BENTLEYVILLE"
  },
  "15315": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BOBTOWN"
  },
  "15316": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BRAVE"
  },
  "15317": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CANONSBURG"
  },
  "15320": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CARMICHAELS"
  },
  "15321": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CECIL"
  },
  "15322": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLARKSVILLE"
  },
  "15323": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLAYSVILLE"
  },
  "15324": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COKEBURG"
  },
  "15325": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CRUCIBLE"
  },
  "15327": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DILLINER"
  },
  "15329": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PROSPERITY"
  },
  "15330": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EIGHTY FOUR"
  },
  "15331": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ELLSWORTH"
  },
  "15332": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FINLEYVILLE"
  },
  "15333": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FREDERICKTOWN"
  },
  "15334": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GARARDS FORT"
  },
  "15337": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GRAYSVILLE"
  },
  "15338": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GREENSBORO"
  },
  "15340": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HICKORY"
  },
  "15341": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HOLBROOK"
  },
  "15342": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HOUSTON"
  },
  "15344": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JEFFERSON"
  },
  "15345": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MARIANNA"
  },
  "15346": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MATHER"
  },
  "15347": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MEADOW LANDS"
  },
  "15348": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILLSBORO"
  },
  "15349": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOUNT MORRIS"
  },
  "15350": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MUSE"
  },
  "15351": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEMACOLIN"
  },
  "15352": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW FREEPORT"
  },
  "15353": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NINEVEH"
  },
  "15357": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RICES LANDING"
  },
  "15358": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RICHEYVILLE"
  },
  "15359": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROGERSVILLE"
  },
  "15360": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SCENERY HILL"
  },
  "15361": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SOUTHVIEW"
  },
  "15362": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPRAGGS"
  },
  "15363": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STRABANE"
  },
  "15364": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SYCAMORE"
  },
  "15367": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "VENETIA"
  },
  "15368": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "VESTABURG"
  },
  "15370": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WAYNESBURG"
  },
  "15376": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEST ALEXANDER"
  },
  "15377": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEST FINLEY"
  },
  "15378": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WESTLAND"
  },
  "15379": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEST MIDDLETOWN"
  },
  "15380": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WIND RIDGE"
  },
  "15401": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "UNIONTOWN"
  },
  "15410": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ADAH"
  },
  "15411": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ADDISON"
  },
  "15412": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALLENPORT"
  },
  "15413": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALLISON"
  },
  "15417": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BROWNSVILLE"
  },
  "15419": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CALIFORNIA"
  },
  "15420": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CARDALE"
  },
  "15422": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CHESTNUT RIDGE"
  },
  "15423": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COAL CENTER"
  },
  "15424": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CONFLUENCE"
  },
  "15425": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CONNELLSVILLE"
  },
  "15427": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DAISYTOWN"
  },
  "15428": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DAWSON"
  },
  "15429": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DENBO"
  },
  "15430": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DICKERSON RUN"
  },
  "15431": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DUNBAR"
  },
  "15432": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DUNLEVY"
  },
  "15433": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAST MILLSBORO"
  },
  "15434": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ELCO"
  },
  "15436": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FAIRCHANCE"
  },
  "15437": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FARMINGTON"
  },
  "15438": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FAYETTE CITY"
  },
  "15440": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GIBBON GLADE"
  },
  "15442": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GRINDSTONE"
  },
  "15443": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HIBBS"
  },
  "15444": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HILLER"
  },
  "15445": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HOPWOOD"
  },
  "15446": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "INDIAN HEAD"
  },
  "15447": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ISABELLA"
  },
  "15448": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JACOBS CREEK"
  },
  "15450": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LA BELLE"
  },
  "15451": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAKE LYNN"
  },
  "15454": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LECKRONE"
  },
  "15455": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEISENRING"
  },
  "15456": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEMONT FURNACE"
  },
  "15458": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MC CLELLANDTOWN"
  },
  "15459": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MARKLEYSBURG"
  },
  "15460": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MARTIN"
  },
  "15461": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MASONTOWN"
  },
  "15462": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MELCROFT"
  },
  "15464": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILL RUN"
  },
  "15466": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEWELL"
  },
  "15468": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW SALEM"
  },
  "15469": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NORMALVILLE"
  },
  "15470": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "OHIOPYLE"
  },
  "15472": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "OLIVER"
  },
  "15473": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PERRYOPOLIS"
  },
  "15474": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "POINT MARION"
  },
  "15475": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "REPUBLIC"
  },
  "15476": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RONCO"
  },
  "15477": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROSCOE"
  },
  "15478": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SMITHFIELD"
  },
  "15479": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SMITHTON"
  },
  "15480": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SMOCK"
  },
  "15482": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STAR JUNCTION"
  },
  "15483": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STOCKDALE"
  },
  "15484": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ULEDI"
  },
  "15485": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "URSINA"
  },
  "15486": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "VANDERBILT"
  },
  "15489": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEST LEISENRING"
  },
  "15490": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WHITE"
  },
  "15492": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WICKHAVEN"
  },
  "15501": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SOMERSET"
  },
  "15520": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ACOSTA"
  },
  "15521": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALUM BANK"
  },
  "15522": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BEDFORD"
  },
  "15530": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BERLIN"
  },
  "15531": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BOSWELL"
  },
  "15532": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BOYNTON"
  },
  "15533": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BREEZEWOOD"
  },
  "15534": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BUFFALO MILLS"
  },
  "15535": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLEARVILLE"
  },
  "15536": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CRYSTAL SPRING"
  },
  "15537": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EVERETT"
  },
  "15538": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FAIRHOPE"
  },
  "15539": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FISHERTOWN"
  },
  "15540": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FORT HILL"
  },
  "15541": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FRIEDENS"
  },
  "15542": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GARRETT"
  },
  "15544": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GRAY"
  },
  "15545": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HYNDMAN"
  },
  "15546": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JENNERS"
  },
  "15547": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JENNERSTOWN"
  },
  "15550": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MANNS CHOICE"
  },
  "15551": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MARKLETON"
  },
  "15552": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MEYERSDALE"
  },
  "15553": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW BALTIMORE"
  },
  "15554": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW PARIS"
  },
  "15557": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROCKWOOD"
  },
  "15558": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SALISBURY"
  },
  "15559": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SCHELLSBURG"
  },
  "15560": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHANKSVILLE"
  },
  "15562": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPRINGS"
  },
  "15563": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STOYSTOWN"
  },
  "15601": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GREENSBURG"
  },
  "15610": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ACME"
  },
  "15611": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ADAMSBURG"
  },
  "15612": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALVERTON"
  },
  "15613": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "APOLLO"
  },
  "15615": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ARDARA"
  },
  "15617": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ARONA"
  },
  "15618": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "AVONMORE"
  },
  "15620": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BRADENVILLE"
  },
  "15622": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CHAMPION"
  },
  "15623": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLARIDGE"
  },
  "15624": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CRABTREE"
  },
  "15625": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DARRAGH"
  },
  "15626": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DELMONT"
  },
  "15627": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DERRY"
  },
  "15628": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DONEGAL"
  },
  "15629": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAST VANDERGRIFT"
  },
  "15631": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EVERSON"
  },
  "15632": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EXPORT"
  },
  "15633": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FORBES ROAD"
  },
  "15634": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GRAPEVILLE"
  },
  "15635": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HANNASTOWN"
  },
  "15636": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARRISON CITY"
  },
  "15637": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HERMINIE"
  },
  "15639": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HUNKER"
  },
  "15640": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HUTCHINSON"
  },
  "15641": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HYDE PARK"
  },
  "15642": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "IRWIN"
  },
  "15644": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JEANNETTE"
  },
  "15646": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JONES MILLS"
  },
  "15647": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LARIMER"
  },
  "15650": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LATROBE"
  },
  "15655": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAUGHLINTOWN"
  },
  "15656": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEECHBURG"
  },
  "15658": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LIGONIER"
  },
  "15660": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LOWBER"
  },
  "15661": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LOYALHANNA"
  },
  "15662": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LUXOR"
  },
  "15663": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MADISON"
  },
  "15665": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MANOR"
  },
  "15666": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOUNT PLEASANT"
  },
  "15668": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MURRYSVILLE"
  },
  "15670": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW ALEXANDRIA"
  },
  "15671": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW DERRY"
  },
  "15672": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW STANTON"
  },
  "15673": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NORTH APOLLO"
  },
  "15674": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NORVELT"
  },
  "15675": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PENN"
  },
  "15676": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PLEASANT UNITY"
  },
  "15677": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RECTOR"
  },
  "15678": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RILLTON"
  },
  "15679": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RUFFS DALE"
  },
  "15680": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SALINA"
  },
  "15681": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SALTSBURG"
  },
  "15683": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SCOTTDALE"
  },
  "15684": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SLICKVILLE"
  },
  "15685": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SOUTHWEST"
  },
  "15686": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPRING CHURCH"
  },
  "15687": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STAHLSTOWN"
  },
  "15688": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TARRS"
  },
  "15689": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "UNITED"
  },
  "15690": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "VANDERGRIFT"
  },
  "15692": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WESTMORELAND CITY"
  },
  "15693": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WHITNEY"
  },
  "15695": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WYANO"
  },
  "15696": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "YOUNGSTOWN"
  },
  "15697": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "YOUNGWOOD"
  },
  "15698": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "YUKON"
  },
  "15701": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "INDIANA"
  },
  "15710": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALVERDA"
  },
  "15711": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ANITA"
  },
  "15712": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ARCADIA"
  },
  "15713": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "AULTMAN"
  },
  "15714": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NORTHERN CAMBRIA"
  },
  "15715": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BIG RUN"
  },
  "15716": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BLACK LICK"
  },
  "15717": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BLAIRSVILLE"
  },
  "15721": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BURNSIDE"
  },
  "15722": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CARROLLTOWN"
  },
  "15723": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CHAMBERSVILLE"
  },
  "15724": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CHERRY TREE"
  },
  "15725": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLARKSBURG"
  },
  "15728": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLYMER"
  },
  "15729": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COMMODORE"
  },
  "15730": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COOLSPRING"
  },
  "15731": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CORAL"
  },
  "15732": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CREEKSIDE"
  },
  "15733": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DE LANCEY"
  },
  "15734": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DIXONVILLE"
  },
  "15736": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ELDERTON"
  },
  "15739": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ERNEST"
  },
  "15741": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GIPSY"
  },
  "15742": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GLEN CAMPBELL"
  },
  "15744": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HAMILTON"
  },
  "15745": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HEILWOOD"
  },
  "15747": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HOME"
  },
  "15748": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HOMER CITY"
  },
  "15752": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KENT"
  },
  "15753": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LA JOSE"
  },
  "15754": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LUCERNEMINES"
  },
  "15756": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MC INTYRE"
  },
  "15757": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MAHAFFEY"
  },
  "15759": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MARION CENTER"
  },
  "15760": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MARSTELLER"
  },
  "15761": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MENTCLE"
  },
  "15762": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NICKTOWN"
  },
  "15763": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NORTHPOINT"
  },
  "15764": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "OLIVEBURG"
  },
  "15765": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PENN RUN"
  },
  "15767": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PUNXSUTAWNEY"
  },
  "15770": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RINGGOLD"
  },
  "15771": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROCHESTER MILLS"
  },
  "15772": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROSSITER"
  },
  "15773": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SAINT BENEDICT"
  },
  "15774": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHELOCTA"
  },
  "15775": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPANGLER"
  },
  "15776": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPRANKLE MILLS"
  },
  "15777": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STARFORD"
  },
  "15778": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TIMBLIN"
  },
  "15779": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TORRANCE"
  },
  "15780": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "VALIER"
  },
  "15781": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WALSTON"
  },
  "15783": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEST LEBANON"
  },
  "15784": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WORTHVILLE"
  },
  "15801": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DU BOIS"
  },
  "15821": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BENEZETT"
  },
  "15823": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BROCKPORT"
  },
  "15824": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BROCKWAY"
  },
  "15825": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BROOKVILLE"
  },
  "15827": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BYRNEDALE"
  },
  "15828": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLARINGTON"
  },
  "15829": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CORSICA"
  },
  "15832": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DRIFTWOOD"
  },
  "15834": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EMPORIUM"
  },
  "15840": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FALLS CREEK"
  },
  "15841": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FORCE"
  },
  "15845": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JOHNSONBURG"
  },
  "15846": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KERSEY"
  },
  "15847": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KNOX DALE"
  },
  "15848": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LUTHERSBURG"
  },
  "15849": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PENFIELD"
  },
  "15851": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "REYNOLDSVILLE"
  },
  "15853": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RIDGWAY"
  },
  "15856": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROCKTON"
  },
  "15857": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SAINT MARYS"
  },
  "15860": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SIGEL"
  },
  "15861": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SINNAMAHONING"
  },
  "15863": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STUMP CREEK"
  },
  "15864": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SUMMERVILLE"
  },
  "15865": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SYKESVILLE"
  },
  "15866": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TROUTVILLE"
  },
  "15868": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEEDVILLE"
  },
  "15870": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WILCOX"
  },
  "15901": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JOHNSTOWN"
  },
  "15902": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JOHNSTOWN"
  },
  "15904": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JOHNSTOWN"
  },
  "15905": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JOHNSTOWN"
  },
  "15906": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JOHNSTOWN"
  },
  "15909": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JOHNSTOWN"
  },
  "15920": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ARMAGH"
  },
  "15923": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BOLIVAR"
  },
  "15924": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CAIRNBROOK"
  },
  "15925": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CASSANDRA"
  },
  "15926": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CENTRAL CITY"
  },
  "15927": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COLVER"
  },
  "15928": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DAVIDSVILLE"
  },
  "15929": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DILLTOWN"
  },
  "15930": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DUNLO"
  },
  "15931": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EBENSBURG"
  },
  "15934": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ELTON"
  },
  "15935": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HOLLSOPPLE"
  },
  "15936": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HOOVERSVILLE"
  },
  "15937": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JEROME"
  },
  "15938": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LILLY"
  },
  "15940": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LORETTO"
  },
  "15942": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MINERAL POINT"
  },
  "15943": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NANTY GLO"
  },
  "15944": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW FLORENCE"
  },
  "15946": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PORTAGE"
  },
  "15948": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "REVLOC"
  },
  "15949": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROBINSON"
  },
  "15952": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SALIX"
  },
  "15954": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SEWARD"
  },
  "15955": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SIDMAN"
  },
  "15956": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SOUTH FORK"
  },
  "15957": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STRONGSTOWN"
  },
  "15958": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SUMMERHILL"
  },
  "15961": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "VINTONDALE"
  },
  "15963": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WINDBER"
  },
  "16001": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BUTLER"
  },
  "16002": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BUTLER"
  },
  "16020": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BOYERS"
  },
  "16022": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BRUIN"
  },
  "16023": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CABOT"
  },
  "16024": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CALLERY"
  },
  "16025": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CHICORA"
  },
  "16027": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CONNOQUENESSING"
  },
  "16028": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAST BRADY"
  },
  "16029": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAST BUTLER"
  },
  "16030": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAU CLAIRE"
  },
  "16033": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EVANS CITY"
  },
  "16034": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FENELTON"
  },
  "16035": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FORESTVILLE"
  },
  "16036": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FOXBURG"
  },
  "16037": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARMONY"
  },
  "16038": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARRISVILLE"
  },
  "16040": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HILLIARDS"
  },
  "16041": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KARNS CITY"
  },
  "16045": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LYNDORA"
  },
  "16046": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MARS"
  },
  "16049": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PARKER"
  },
  "16050": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PETROLIA"
  },
  "16051": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PORTERSVILLE"
  },
  "16052": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PROSPECT"
  },
  "16053": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RENFREW"
  },
  "16054": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SAINT PETERSBURG"
  },
  "16055": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SARVER"
  },
  "16056": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SAXONBURG"
  },
  "16057": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SLIPPERY ROCK"
  },
  "16059": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "VALENCIA"
  },
  "16061": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEST SUNBURY"
  },
  "16063": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ZELIENOPLE"
  },
  "16066": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CRANBERRY TWP"
  },
  "16101": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW CASTLE"
  },
  "16102": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW CASTLE"
  },
  "16105": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW CASTLE"
  },
  "16110": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ADAMSVILLE"
  },
  "16111": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ATLANTIC"
  },
  "16112": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BESSEMER"
  },
  "16113": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLARK"
  },
  "16114": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLARKS MILLS"
  },
  "16115": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DARLINGTON"
  },
  "16116": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EDINBURG"
  },
  "16117": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ELLWOOD CITY"
  },
  "16120": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ENON VALLEY"
  },
  "16121": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FARRELL"
  },
  "16123": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FOMBELL"
  },
  "16124": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FREDONIA"
  },
  "16125": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GREENVILLE"
  },
  "16127": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GROVE CITY"
  },
  "16130": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HADLEY"
  },
  "16131": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARTSTOWN"
  },
  "16132": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HILLSVILLE"
  },
  "16133": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JACKSON CENTER"
  },
  "16134": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JAMESTOWN"
  },
  "16136": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KOPPEL"
  },
  "16137": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MERCER"
  },
  "16140": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW BEDFORD"
  },
  "16141": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW GALILEE"
  },
  "16142": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW WILMINGTON"
  },
  "16143": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PULASKI"
  },
  "16145": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SANDY LAKE"
  },
  "16146": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHARON"
  },
  "16148": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HERMITAGE"
  },
  "16150": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHARPSVILLE"
  },
  "16151": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHEAKLEYVILLE"
  },
  "16153": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STONEBORO"
  },
  "16154": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TRANSFER"
  },
  "16155": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "VILLA MARIA"
  },
  "16156": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "VOLANT"
  },
  "16157": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WAMPUM"
  },
  "16159": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEST MIDDLESEX"
  },
  "16160": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEST PITTSBURG"
  },
  "16161": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WHEATLAND"
  },
  "16201": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KITTANNING"
  },
  "16210": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ADRIAN"
  },
  "16212": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CADOGAN"
  },
  "16213": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CALLENSBURG"
  },
  "16214": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLARION"
  },
  "16217": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COOKSBURG"
  },
  "16218": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COWANSVILLE"
  },
  "16222": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DAYTON"
  },
  "16223": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DISTANT"
  },
  "16224": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FAIRMOUNT CITY"
  },
  "16226": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FORD CITY"
  },
  "16228": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FORD CLIFF"
  },
  "16229": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FREEPORT"
  },
  "16230": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HAWTHORN"
  },
  "16232": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KNOX"
  },
  "16233": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEEPER"
  },
  "16234": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LIMESTONE"
  },
  "16235": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LUCINDA"
  },
  "16236": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MC GRANN"
  },
  "16238": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MANORVILLE"
  },
  "16239": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MARIENVILLE"
  },
  "16240": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MAYPORT"
  },
  "16242": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW BETHLEHEM"
  },
  "16244": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NU MINE"
  },
  "16245": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "OAK RIDGE"
  },
  "16246": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PLUMVILLE"
  },
  "16248": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RIMERSBURG"
  },
  "16249": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RURAL VALLEY"
  },
  "16250": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SAGAMORE"
  },
  "16253": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SEMINOLE"
  },
  "16254": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHIPPENVILLE"
  },
  "16255": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SLIGO"
  },
  "16256": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SMICKSBURG"
  },
  "16258": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STRATTANVILLE"
  },
  "16259": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TEMPLETON"
  },
  "16260": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "VOWINCKEL"
  },
  "16262": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WORTHINGTON"
  },
  "16263": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "YATESBORO"
  },
  "16301": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "OIL CITY"
  },
  "16311": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CARLTON"
  },
  "16313": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLARENDON"
  },
  "16314": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COCHRANTON"
  },
  "16316": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CONNEAUT LAKE"
  },
  "16317": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COOPERSTOWN"
  },
  "16319": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CRANBERRY"
  },
  "16321": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAST HICKORY"
  },
  "16322": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ENDEAVOR"
  },
  "16323": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FRANKLIN"
  },
  "16326": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FRYBURG"
  },
  "16327": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GUYS MILLS"
  },
  "16329": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "IRVINE"
  },
  "16331": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KOSSUTH"
  },
  "16332": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LICKINGVILLE"
  },
  "16333": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LUDLOW"
  },
  "16334": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MARBLE"
  },
  "16335": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MEADVILLE"
  },
  "16340": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSFIELD"
  },
  "16341": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PLEASANTVILLE"
  },
  "16342": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "POLK"
  },
  "16343": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RENO"
  },
  "16344": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROUSEVILLE"
  },
  "16345": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RUSSELL"
  },
  "16346": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SENECA"
  },
  "16347": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHEFFIELD"
  },
  "16350": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SUGAR GROVE"
  },
  "16351": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TIDIOUTE"
  },
  "16352": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TIONA"
  },
  "16353": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TIONESTA"
  },
  "16354": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TITUSVILLE"
  },
  "16360": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TOWNVILLE"
  },
  "16361": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TYLERSBURG"
  },
  "16362": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "UTICA"
  },
  "16364": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "VENUS"
  },
  "16365": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WARREN"
  },
  "16370": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEST HICKORY"
  },
  "16371": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "YOUNGSVILLE"
  },
  "16372": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLINTONVILLE"
  },
  "16373": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EMLENTON"
  },
  "16374": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KENNERDELL"
  },
  "16401": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALBION"
  },
  "16402": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BEAR LAKE"
  },
  "16403": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CAMBRIDGE SPRINGS"
  },
  "16404": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CENTERVILLE"
  },
  "16405": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COLUMBUS"
  },
  "16406": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CONNEAUTVILLE"
  },
  "16407": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CORRY"
  },
  "16410": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CRANESVILLE"
  },
  "16411": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAST SPRINGFIELD"
  },
  "16412": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EDINBORO"
  },
  "16415": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FAIRVIEW"
  },
  "16416": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GARLAND"
  },
  "16417": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GIRARD"
  },
  "16420": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GRAND VALLEY"
  },
  "16421": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARBORCREEK"
  },
  "16422": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARMONSBURG"
  },
  "16423": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAKE CITY"
  },
  "16424": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LINESVILLE"
  },
  "16426": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MC KEAN"
  },
  "16427": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILL VILLAGE"
  },
  "16428": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NORTH EAST"
  },
  "16433": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SAEGERTOWN"
  },
  "16434": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPARTANSBURG"
  },
  "16435": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPRINGBORO"
  },
  "16436": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPRING CREEK"
  },
  "16438": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "UNION CITY"
  },
  "16440": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "VENANGO"
  },
  "16441": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WATERFORD"
  },
  "16442": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WATTSBURG"
  },
  "16443": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEST SPRINGFIELD"
  },
  "16501": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ERIE"
  },
  "16502": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ERIE"
  },
  "16503": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ERIE"
  },
  "16504": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ERIE"
  },
  "16505": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ERIE"
  },
  "16506": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ERIE"
  },
  "16507": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ERIE"
  },
  "16508": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ERIE"
  },
  "16509": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ERIE"
  },
  "16510": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ERIE"
  },
  "16511": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ERIE"
  },
  "16601": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALTOONA"
  },
  "16602": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALTOONA"
  },
  "16611": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALEXANDRIA"
  },
  "16613": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ASHVILLE"
  },
  "16616": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BECCARIA"
  },
  "16617": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BELLWOOD"
  },
  "16619": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BLANDBURG"
  },
  "16620": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BRISBIN"
  },
  "16621": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BROAD TOP"
  },
  "16622": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CALVIN"
  },
  "16623": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CASSVILLE"
  },
  "16624": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CHEST SPRINGS"
  },
  "16625": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLAYSBURG"
  },
  "16627": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COALPORT"
  },
  "16630": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CRESSON"
  },
  "16631": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CURRYVILLE"
  },
  "16633": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DEFIANCE"
  },
  "16634": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DUDLEY"
  },
  "16635": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DUNCANSVILLE"
  },
  "16636": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DYSART"
  },
  "16637": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAST FREEDOM"
  },
  "16638": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ENTRIKEN"
  },
  "16639": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FALLENTIMBER"
  },
  "16640": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FLINTON"
  },
  "16641": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GALLITZIN"
  },
  "16645": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GLEN HOPE"
  },
  "16646": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HASTINGS"
  },
  "16647": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HESSTON"
  },
  "16648": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HOLLIDAYSBURG"
  },
  "16650": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HOPEWELL"
  },
  "16651": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HOUTZDALE"
  },
  "16652": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HUNTINGDON"
  },
  "16655": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "IMLER"
  },
  "16656": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "IRVONA"
  },
  "16657": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JAMES CREEK"
  },
  "16659": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LOYSBURG"
  },
  "16661": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MADERA"
  },
  "16662": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MARTINSBURG"
  },
  "16663": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MORANN"
  },
  "16664": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW ENTERPRISE"
  },
  "16665": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEWRY"
  },
  "16666": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "OSCEOLA MILLS"
  },
  "16667": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "OSTERBURG"
  },
  "16668": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PATTON"
  },
  "16669": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PETERSBURG"
  },
  "16670": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "QUEEN"
  },
  "16671": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RAMEY"
  },
  "16672": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RIDDLESBURG"
  },
  "16673": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROARING SPRING"
  },
  "16674": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROBERTSDALE"
  },
  "16677": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SANDY RIDGE"
  },
  "16678": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SAXTON"
  },
  "16679": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SIX MILE RUN"
  },
  "16680": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SMITHMILL"
  },
  "16681": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SMOKERUN"
  },
  "16682": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPROUL"
  },
  "16683": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPRUCE CREEK"
  },
  "16685": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TODD"
  },
  "16686": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TYRONE"
  },
  "16689": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WATERFALL"
  },
  "16691": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WELLS TANNERY"
  },
  "16692": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WESTOVER"
  },
  "16693": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WILLIAMSBURG"
  },
  "16694": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WOOD"
  },
  "16695": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WOODBURY"
  },
  "16701": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BRADFORD"
  },
  "16720": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "AUSTIN"
  },
  "16724": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CROSBY"
  },
  "16725": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CUSTER CITY"
  },
  "16726": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CYCLONE"
  },
  "16727": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DERRICK CITY"
  },
  "16729": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DUKE CENTER"
  },
  "16730": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAST SMETHPORT"
  },
  "16731": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ELDRED"
  },
  "16732": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GIFFORD"
  },
  "16733": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HAZEL HURST"
  },
  "16734": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JAMES CITY"
  },
  "16735": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KANE"
  },
  "16738": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEWIS RUN"
  },
  "16740": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOUNT JEWETT"
  },
  "16743": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PORT ALLEGANY"
  },
  "16744": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "REW"
  },
  "16745": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RIXFORD"
  },
  "16746": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROULETTE"
  },
  "16748": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHINGLEHOUSE"
  },
  "16749": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SMETHPORT"
  },
  "16750": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TURTLEPOINT"
  },
  "16801": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STATE COLLEGE"
  },
  "16803": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STATE COLLEGE"
  },
  "16820": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "AARONSBURG"
  },
  "16822": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BEECH CREEK"
  },
  "16823": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BELLEFONTE"
  },
  "16825": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BIGLER"
  },
  "16826": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BLANCHARD"
  },
  "16827": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BOALSBURG"
  },
  "16828": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CENTRE HALL"
  },
  "16829": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLARENCE"
  },
  "16830": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLEARFIELD"
  },
  "16832": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COBURN"
  },
  "16833": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CURWENSVILLE"
  },
  "16834": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DRIFTING"
  },
  "16836": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FRENCHVILLE"
  },
  "16837": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GLEN RICHEY"
  },
  "16838": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GRAMPIAN"
  },
  "16839": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GRASSFLAT"
  },
  "16840": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HAWK RUN"
  },
  "16841": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HOWARD"
  },
  "16843": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HYDE"
  },
  "16844": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JULIAN"
  },
  "16845": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KARTHAUS"
  },
  "16847": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KYLERTOWN"
  },
  "16848": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAMAR"
  },
  "16849": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LANSE"
  },
  "16851": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEMONT"
  },
  "16852": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MADISONBURG"
  },
  "16853": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILESBURG"
  },
  "16854": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILLHEIM"
  },
  "16855": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MINERAL SPRINGS"
  },
  "16856": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MINGOVILLE"
  },
  "16858": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MORRISDALE"
  },
  "16859": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOSHANNON"
  },
  "16860": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MUNSON"
  },
  "16861": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW MILLPORT"
  },
  "16863": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "OLANTA"
  },
  "16864": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ORVISTON"
  },
  "16865": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PENNSYLVANIA FURNACE"
  },
  "16866": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILIPSBURG"
  },
  "16870": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PORT MATILDA"
  },
  "16871": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "POTTERSDALE"
  },
  "16872": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "REBERSBURG"
  },
  "16874": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SNOW SHOE"
  },
  "16875": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPRING MILLS"
  },
  "16876": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WALLACETON"
  },
  "16877": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WARRIORS MARK"
  },
  "16878": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEST DECATUR"
  },
  "16879": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WINBURNE"
  },
  "16881": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WOODLAND"
  },
  "16882": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WOODWARD"
  },
  "16901": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WELLSBORO"
  },
  "16911": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ARNOT"
  },
  "16912": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BLOSSBURG"
  },
  "16914": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COLUMBIA CROSS ROADS"
  },
  "16915": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COUDERSPORT"
  },
  "16917": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COVINGTON"
  },
  "16920": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ELKLAND"
  },
  "16921": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GAINES"
  },
  "16922": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GALETON"
  },
  "16923": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GENESEE"
  },
  "16925": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GILLETT"
  },
  "16926": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GRANVILLE SUMMIT"
  },
  "16927": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARRISON VALLEY"
  },
  "16928": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KNOXVILLE"
  },
  "16929": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAWRENCEVILLE"
  },
  "16930": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LIBERTY"
  },
  "16932": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MAINESBURG"
  },
  "16933": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MANSFIELD"
  },
  "16935": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MIDDLEBURY CENTER"
  },
  "16936": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILLERTON"
  },
  "16937": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILLS"
  },
  "16938": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MORRIS"
  },
  "16939": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MORRIS RUN"
  },
  "16940": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NELSON"
  },
  "16941": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GENESEE"
  },
  "16942": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "OSCEOLA"
  },
  "16943": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SABINSVILLE"
  },
  "16946": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TIOGA"
  },
  "16947": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TROY"
  },
  "16948": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ULYSSES"
  },
  "16950": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WESTFIELD"
  },
  "17002": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALLENSVILLE"
  },
  "17003": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ANNVILLE"
  },
  "17004": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BELLEVILLE"
  },
  "17005": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BERRYSBURG"
  },
  "17006": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BLAIN"
  },
  "17007": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BOILING SPRINGS"
  },
  "17009": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BURNHAM"
  },
  "17010": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CAMPBELLTOWN"
  },
  "17011": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CAMP HILL"
  },
  "17013": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CARLISLE"
  },
  "17016": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CORNWALL"
  },
  "17017": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DALMATIA"
  },
  "17018": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DAUPHIN"
  },
  "17019": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DILLSBURG"
  },
  "17020": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DUNCANNON"
  },
  "17021": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAST WATERFORD"
  },
  "17022": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ELIZABETHTOWN"
  },
  "17023": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ELIZABETHVILLE"
  },
  "17024": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ELLIOTTSBURG"
  },
  "17025": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ENOLA"
  },
  "17026": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FREDERICKSBURG"
  },
  "17027": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GRANTHAM"
  },
  "17028": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GRANTVILLE"
  },
  "17029": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GRANVILLE"
  },
  "17032": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HALIFAX"
  },
  "17033": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HERSHEY"
  },
  "17034": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HIGHSPIRE"
  },
  "17035": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HONEY GROVE"
  },
  "17036": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HUMMELSTOWN"
  },
  "17037": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ICKESBURG"
  },
  "17038": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JONESTOWN"
  },
  "17040": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LANDISBURG"
  },
  "17041": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAWN"
  },
  "17042": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEBANON"
  },
  "17043": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEMOYNE"
  },
  "17044": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEWISTOWN"
  },
  "17045": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LIVERPOOL"
  },
  "17046": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEBANON"
  },
  "17047": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LOYSVILLE"
  },
  "17048": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LYKENS"
  },
  "17049": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MC ALISTERVILLE"
  },
  "17051": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MC VEYTOWN"
  },
  "17052": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MAPLETON DEPOT"
  },
  "17053": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MARYSVILLE"
  },
  "17055": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MECHANICSBURG"
  },
  "17057": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MIDDLETOWN"
  },
  "17058": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MIFFLIN"
  },
  "17059": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MIFFLINTOWN"
  },
  "17060": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILL CREEK"
  },
  "17061": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILLERSBURG"
  },
  "17062": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILLERSTOWN"
  },
  "17063": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILROY"
  },
  "17064": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOUNT GRETNA"
  },
  "17065": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOUNT HOLLY SPRINGS"
  },
  "17066": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOUNT UNION"
  },
  "17067": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MYERSTOWN"
  },
  "17068": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW BLOOMFIELD"
  },
  "17069": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW BUFFALO"
  },
  "17070": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW CUMBERLAND"
  },
  "17072": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW KINGSTOWN"
  },
  "17073": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEWMANSTOWN"
  },
  "17074": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEWPORT"
  },
  "17075": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEWTON HAMILTON"
  },
  "17076": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "OAKLAND MILLS"
  },
  "17077": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ONO"
  },
  "17078": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PALMYRA"
  },
  "17080": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PILLOW"
  },
  "17081": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PLAINFIELD"
  },
  "17082": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PORT ROYAL"
  },
  "17083": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "QUENTIN"
  },
  "17084": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "REEDSVILLE"
  },
  "17086": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RICHFIELD"
  },
  "17087": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RICHLAND"
  },
  "17088": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SCHAEFFERSTOWN"
  },
  "17090": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHERMANS DALE"
  },
  "17093": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SUMMERDALE"
  },
  "17094": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "THOMPSONTOWN"
  },
  "17097": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WICONISCO"
  },
  "17098": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WILLIAMSTOWN"
  },
  "17099": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "YEAGERTOWN"
  },
  "17101": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARRISBURG"
  },
  "17102": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARRISBURG"
  },
  "17103": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARRISBURG"
  },
  "17104": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARRISBURG"
  },
  "17109": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARRISBURG"
  },
  "17110": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARRISBURG"
  },
  "17111": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARRISBURG"
  },
  "17112": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARRISBURG"
  },
  "17113": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARRISBURG"
  },
  "17201": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CHAMBERSBURG"
  },
  "17211": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ARTEMAS"
  },
  "17212": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BIG COVE TANNERY"
  },
  "17213": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BLAIRS MILLS"
  },
  "17214": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BLUE RIDGE SUMMIT"
  },
  "17215": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BURNT CABINS"
  },
  "17219": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DOYLESBURG"
  },
  "17220": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DRY RUN"
  },
  "17221": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FANNETTSBURG"
  },
  "17222": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FAYETTEVILLE"
  },
  "17223": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FORT LITTLETON"
  },
  "17224": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FORT LOUDON"
  },
  "17225": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GREENCASTLE"
  },
  "17228": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARRISONVILLE"
  },
  "17229": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HUSTONTOWN"
  },
  "17233": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MC CONNELLSBURG"
  },
  "17235": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MARION"
  },
  "17236": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MERCERSBURG"
  },
  "17237": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MONT ALTO"
  },
  "17238": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEEDMORE"
  },
  "17239": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEELYTON"
  },
  "17240": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEWBURG"
  },
  "17241": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEWVILLE"
  },
  "17243": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ORBISONIA"
  },
  "17244": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ORRSTOWN"
  },
  "17249": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROCKHILL FURNACE"
  },
  "17252": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SAINT THOMAS"
  },
  "17253": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SALTILLO"
  },
  "17254": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SCOTLAND"
  },
  "17255": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHADE GAP"
  },
  "17257": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHIPPENSBURG"
  },
  "17260": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHIRLEYSBURG"
  },
  "17262": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPRING RUN"
  },
  "17264": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "THREE SPRINGS"
  },
  "17265": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "UPPERSTRASBURG"
  },
  "17266": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WALNUT BOTTOM"
  },
  "17267": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WARFORDSBURG"
  },
  "17268": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WAYNESBORO"
  },
  "17270": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WILLIAMSON"
  },
  "17271": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WILLOW HILL"
  },
  "17301": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ABBOTTSTOWN"
  },
  "17302": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "AIRVILLE"
  },
  "17304": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ASPERS"
  },
  "17306": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BENDERSVILLE"
  },
  "17307": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BIGLERVILLE"
  },
  "17309": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BROGUE"
  },
  "17311": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CODORUS"
  },
  "17313": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DALLASTOWN"
  },
  "17314": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DELTA"
  },
  "17315": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DOVER"
  },
  "17316": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAST BERLIN"
  },
  "17317": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAST PROSPECT"
  },
  "17318": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EMIGSVILLE"
  },
  "17319": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ETTERS"
  },
  "17320": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FAIRFIELD"
  },
  "17321": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FAWN GROVE"
  },
  "17322": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FELTON"
  },
  "17324": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GARDNERS"
  },
  "17325": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GETTYSBURG"
  },
  "17327": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GLEN ROCK"
  },
  "17329": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GLENVILLE"
  },
  "17331": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HANOVER"
  },
  "17339": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEWISBERRY"
  },
  "17340": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LITTLESTOWN"
  },
  "17343": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MC KNIGHTSTOWN"
  },
  "17344": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MC SHERRYSTOWN"
  },
  "17345": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MANCHESTER"
  },
  "17347": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOUNT WOLF"
  },
  "17349": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW FREEDOM"
  },
  "17350": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW OXFORD"
  },
  "17352": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW PARK"
  },
  "17353": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ORRTANNA"
  },
  "17355": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RAILROAD"
  },
  "17356": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RED LION"
  },
  "17360": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SEVEN VALLEYS"
  },
  "17361": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHREWSBURY"
  },
  "17362": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPRING GROVE"
  },
  "17363": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STEWARTSTOWN"
  },
  "17364": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "THOMASVILLE"
  },
  "17365": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WELLSVILLE"
  },
  "17366": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WINDSOR"
  },
  "17368": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WRIGHTSVILLE"
  },
  "17370": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "YORK HAVEN"
  },
  "17372": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "YORK SPRINGS"
  },
  "17401": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "YORK"
  },
  "17402": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "YORK"
  },
  "17403": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "YORK"
  },
  "17404": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "YORK"
  },
  "17406": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "YORK"
  },
  "17407": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "YORK"
  },
  "17501": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "AKRON"
  },
  "17502": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BAINBRIDGE"
  },
  "17505": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BIRD IN HAND"
  },
  "17508": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BROWNSTOWN"
  },
  "17509": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CHRISTIANA"
  },
  "17512": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COLUMBIA"
  },
  "17516": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CONESTOGA"
  },
  "17517": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DENVER"
  },
  "17518": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DRUMORE"
  },
  "17519": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAST EARL"
  },
  "17520": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAST PETERSBURG"
  },
  "17522": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EPHRATA"
  },
  "17527": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GAP"
  },
  "17529": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GORDONVILLE"
  },
  "17532": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HOLTWOOD"
  },
  "17535": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KINZERS"
  },
  "17536": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KIRKWOOD"
  },
  "17538": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LANDISVILLE"
  },
  "17540": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEOLA"
  },
  "17543": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LITITZ"
  },
  "17545": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MANHEIM"
  },
  "17547": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MARIETTA"
  },
  "17551": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILLERSVILLE"
  },
  "17552": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOUNT JOY"
  },
  "17554": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOUNTVILLE"
  },
  "17555": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NARVON"
  },
  "17557": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW HOLLAND"
  },
  "17560": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW PROVIDENCE"
  },
  "17562": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PARADISE"
  },
  "17563": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PEACH BOTTOM"
  },
  "17565": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PEQUEA"
  },
  "17566": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "QUARRYVILLE"
  },
  "17569": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "REINHOLDS"
  },
  "17570": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RHEEMS"
  },
  "17572": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RONKS"
  },
  "17576": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SMOKETOWN"
  },
  "17578": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STEVENS"
  },
  "17579": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STRASBURG"
  },
  "17581": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TERRE HILL"
  },
  "17582": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WASHINGTON BORO"
  },
  "17584": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WILLOW STREET"
  },
  "17601": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LANCASTER"
  },
  "17602": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LANCASTER"
  },
  "17603": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LANCASTER"
  },
  "17701": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WILLIAMSPORT"
  },
  "17702": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WILLIAMSPORT"
  },
  "17721": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "AVIS"
  },
  "17724": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CANTON"
  },
  "17728": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COGAN STATION"
  },
  "17729": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CROSS FORK"
  },
  "17730": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DEWART"
  },
  "17731": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAGLES MERE"
  },
  "17737": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HUGHESVILLE"
  },
  "17738": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HYNER"
  },
  "17739": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JERSEY MILLS"
  },
  "17740": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JERSEY SHORE"
  },
  "17742": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAIRDSVILLE"
  },
  "17744": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LINDEN"
  },
  "17745": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LOCK HAVEN"
  },
  "17747": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LOGANTON"
  },
  "17748": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MC ELHATTAN"
  },
  "17749": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MC EWENSVILLE"
  },
  "17750": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MACKEYVILLE"
  },
  "17751": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILL HALL"
  },
  "17752": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MONTGOMERY"
  },
  "17754": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MONTOURSVILLE"
  },
  "17756": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MUNCY"
  },
  "17758": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MUNCY VALLEY"
  },
  "17760": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NORTH BEND"
  },
  "17762": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PICTURE ROCKS"
  },
  "17763": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RALSTON"
  },
  "17764": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RENOVO"
  },
  "17765": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROARING BRANCH"
  },
  "17767": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SALONA"
  },
  "17768": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHUNK"
  },
  "17771": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TROUT RUN"
  },
  "17772": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TURBOTVILLE"
  },
  "17774": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "UNITYVILLE"
  },
  "17776": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WATERVILLE"
  },
  "17777": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WATSONTOWN"
  },
  "17778": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WESTPORT"
  },
  "17801": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SUNBURY"
  },
  "17810": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALLENWOOD"
  },
  "17812": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BEAVER SPRINGS"
  },
  "17813": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BEAVERTOWN"
  },
  "17814": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BENTON"
  },
  "17815": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BLOOMSBURG"
  },
  "17820": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CATAWISSA"
  },
  "17821": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DANVILLE"
  },
  "17823": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DORNSIFE"
  },
  "17824": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ELYSBURG"
  },
  "17827": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FREEBURG"
  },
  "17829": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARTLETON"
  },
  "17830": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HERNDON"
  },
  "17832": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MARION HEIGHTS"
  },
  "17833": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KREAMER"
  },
  "17834": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KULPMONT"
  },
  "17835": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAURELTON"
  },
  "17836": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LECK KILL"
  },
  "17837": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEWISBURG"
  },
  "17840": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LOCUST GAP"
  },
  "17841": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MC CLURE"
  },
  "17842": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MIDDLEBURG"
  },
  "17844": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MIFFLINBURG"
  },
  "17845": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILLMONT"
  },
  "17846": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILLVILLE"
  },
  "17847": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILTON"
  },
  "17850": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MONTANDON"
  },
  "17851": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOUNT CARMEL"
  },
  "17853": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOUNT PLEASANT MILLS"
  },
  "17855": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW BERLIN"
  },
  "17856": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW COLUMBIA"
  },
  "17857": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NORTHUMBERLAND"
  },
  "17859": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ORANGEVILLE"
  },
  "17860": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PAXINOS"
  },
  "17861": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PAXTONVILLE"
  },
  "17862": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PENNS CREEK"
  },
  "17864": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PORT TREVORTON"
  },
  "17865": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "POTTS GROVE"
  },
  "17866": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COAL TOWNSHIP"
  },
  "17867": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "REBUCK"
  },
  "17868": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RIVERSIDE"
  },
  "17870": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SELINSGROVE"
  },
  "17872": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHAMOKIN"
  },
  "17878": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STILLWATER"
  },
  "17881": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TREVORTON"
  },
  "17882": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TROXELVILLE"
  },
  "17884": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WASHINGTONVILLE"
  },
  "17885": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEIKERT"
  },
  "17886": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEST MILTON"
  },
  "17887": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WHITE DEER"
  },
  "17888": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WILBURTON"
  },
  "17889": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WINFIELD"
  },
  "17901": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "POTTSVILLE"
  },
  "17920": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ARISTES"
  },
  "17921": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ASHLAND"
  },
  "17922": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "AUBURN"
  },
  "17923": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BRANCHDALE"
  },
  "17925": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BROCKTON"
  },
  "17929": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CRESSONA"
  },
  "17930": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CUMBOLA"
  },
  "17931": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FRACKVILLE"
  },
  "17933": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FRIEDENSBURG"
  },
  "17934": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GILBERTON"
  },
  "17935": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GIRARDVILLE"
  },
  "17936": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GORDON"
  },
  "17938": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HEGINS"
  },
  "17941": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KLINGERSTOWN"
  },
  "17943": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAVELLE"
  },
  "17944": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LLEWELLYN"
  },
  "17945": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LOCUSTDALE"
  },
  "17946": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LOST CREEK"
  },
  "17948": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MAHANOY CITY"
  },
  "17949": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MAHANOY PLANE"
  },
  "17951": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MAR LIN"
  },
  "17952": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MARY D"
  },
  "17953": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MIDDLEPORT"
  },
  "17954": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MINERSVILLE"
  },
  "17957": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MUIR"
  },
  "17959": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW PHILADELPHIA"
  },
  "17960": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW RINGGOLD"
  },
  "17961": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ORWIGSBURG"
  },
  "17963": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PINE GROVE"
  },
  "17964": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITMAN"
  },
  "17965": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PORT CARBON"
  },
  "17967": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RINGTOWN"
  },
  "17968": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SACRAMENTO"
  },
  "17970": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SAINT CLAIR"
  },
  "17972": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SCHUYLKILL HAVEN"
  },
  "17974": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SELTZER"
  },
  "17976": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHENANDOAH"
  },
  "17978": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPRING GLEN"
  },
  "17979": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SUMMIT STATION"
  },
  "17980": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TOWER CITY"
  },
  "17981": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TREMONT"
  },
  "17982": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TUSCARORA"
  },
  "17983": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "VALLEY VIEW"
  },
  "17985": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ZION GROVE"
  },
  "18011": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALBURTIS"
  },
  "18012": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "AQUASHICOLA"
  },
  "18013": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BANGOR"
  },
  "18014": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BATH"
  },
  "18015": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BETHLEHEM"
  },
  "18017": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BETHLEHEM"
  },
  "18018": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BETHLEHEM"
  },
  "18020": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BETHLEHEM"
  },
  "18030": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BOWMANSTOWN"
  },
  "18031": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BREINIGSVILLE"
  },
  "18032": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CATASAUQUA"
  },
  "18034": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CENTER VALLEY"
  },
  "18035": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CHERRYVILLE"
  },
  "18036": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COOPERSBURG"
  },
  "18037": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COPLAY"
  },
  "18038": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DANIELSVILLE"
  },
  "18040": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EASTON"
  },
  "18041": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAST GREENVILLE"
  },
  "18042": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EASTON"
  },
  "18045": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EASTON"
  },
  "18049": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EMMAUS"
  },
  "18051": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FOGELSVILLE"
  },
  "18052": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WHITEHALL"
  },
  "18053": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GERMANSVILLE"
  },
  "18054": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GREEN LANE"
  },
  "18055": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HELLERTOWN"
  },
  "18056": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HEREFORD"
  },
  "18058": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KUNKLETOWN"
  },
  "18059": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAURYS STATION"
  },
  "18062": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MACUNGIE"
  },
  "18063": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MARTINS CREEK"
  },
  "18064": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NAZARETH"
  },
  "18066": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW TRIPOLI"
  },
  "18067": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NORTHAMPTON"
  },
  "18069": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "OREFIELD"
  },
  "18070": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PALM"
  },
  "18071": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PALMERTON"
  },
  "18072": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PEN ARGYL"
  },
  "18073": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PENNSBURG"
  },
  "18074": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PERKIOMENVILLE"
  },
  "18076": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RED HILL"
  },
  "18077": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RIEGELSVILLE"
  },
  "18078": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SCHNECKSVILLE"
  },
  "18079": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SLATEDALE"
  },
  "18080": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SLATINGTON"
  },
  "18081": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPRINGTOWN"
  },
  "18083": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STOCKERTOWN"
  },
  "18085": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TATAMY"
  },
  "18086": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TREICHLERS"
  },
  "18087": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TREXLERTOWN"
  },
  "18088": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WALNUTPORT"
  },
  "18091": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WIND GAP"
  },
  "18092": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ZIONSVILLE"
  },
  "18101": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALLENTOWN"
  },
  "18102": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALLENTOWN"
  },
  "18103": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALLENTOWN"
  },
  "18104": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALLENTOWN"
  },
  "18106": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALLENTOWN"
  },
  "18201": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HAZLETON"
  },
  "18210": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ALBRIGHTSVILLE"
  },
  "18211": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ANDREAS"
  },
  "18214": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BARNESVILLE"
  },
  "18216": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BEAVER MEADOWS"
  },
  "18218": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COALDALE"
  },
  "18219": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CONYNGHAM"
  },
  "18220": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DELANO"
  },
  "18221": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DRIFTON"
  },
  "18222": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DRUMS"
  },
  "18224": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FREELAND"
  },
  "18225": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARLEIGH"
  },
  "18229": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JIM THORPE"
  },
  "18230": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JUNEDALE"
  },
  "18231": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KELAYRES"
  },
  "18232": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LANSFORD"
  },
  "18234": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LATTIMER MINES"
  },
  "18235": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEHIGHTON"
  },
  "18237": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MCADOO"
  },
  "18239": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILNESVILLE"
  },
  "18240": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NESQUEHONING"
  },
  "18241": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NUREMBERG"
  },
  "18242": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ONEIDA"
  },
  "18244": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PARRYVILLE"
  },
  "18245": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "QUAKAKE"
  },
  "18246": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROCK GLEN"
  },
  "18248": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHEPPTON"
  },
  "18249": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SUGARLOAF"
  },
  "18250": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SUMMIT HILL"
  },
  "18251": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SYBERTSVILLE"
  },
  "18252": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TAMAQUA"
  },
  "18254": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TRESCKOW"
  },
  "18255": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEATHERLY"
  },
  "18256": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WESTON"
  },
  "18301": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAST STROUDSBURG"
  },
  "18321": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BARTONSVILLE"
  },
  "18322": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BRODHEADSVILLE"
  },
  "18323": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BUCK HILL FALLS"
  },
  "18324": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BUSHKILL"
  },
  "18325": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CANADENSIS"
  },
  "18326": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CRESCO"
  },
  "18327": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DELAWARE WATER GAP"
  },
  "18328": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DINGMANS FERRY"
  },
  "18330": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EFFORT"
  },
  "18331": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GILBERT"
  },
  "18332": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HENRYVILLE"
  },
  "18333": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KRESGEVILLE"
  },
  "18334": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LONG POND"
  },
  "18336": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MATAMORAS"
  },
  "18337": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILFORD"
  },
  "18340": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILLRIFT"
  },
  "18341": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MINISINK HILLS"
  },
  "18343": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOUNT BETHEL"
  },
  "18344": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOUNT POCONO"
  },
  "18346": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "POCONO SUMMIT"
  },
  "18347": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "POCONO LAKE"
  },
  "18349": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "POCONO MANOR"
  },
  "18350": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "POCONO PINES"
  },
  "18351": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PORTLAND"
  },
  "18353": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SAYLORSBURG"
  },
  "18354": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SCIOTA"
  },
  "18355": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SCOTRUN"
  },
  "18356": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHAWNEE ON DELAWARE"
  },
  "18357": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SKYTOP"
  },
  "18360": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STROUDSBURG"
  },
  "18370": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SWIFTWATER"
  },
  "18372": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TANNERSVILLE"
  },
  "18403": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ARCHBALD"
  },
  "18405": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BEACH LAKE"
  },
  "18407": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CARBONDALE"
  },
  "18411": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLARKS SUMMIT"
  },
  "18413": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLIFFORD"
  },
  "18414": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DALTON"
  },
  "18415": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DAMASCUS"
  },
  "18417": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EQUINUNK"
  },
  "18419": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FACTORYVILLE"
  },
  "18420": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FLEETVILLE"
  },
  "18421": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FOREST CITY"
  },
  "18424": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GOULDSBORO"
  },
  "18425": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GREELEY"
  },
  "18426": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GREENTOWN"
  },
  "18428": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HAWLEY"
  },
  "18430": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HERRICK CENTER"
  },
  "18431": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HONESDALE"
  },
  "18433": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JERMYN"
  },
  "18434": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JESSUP"
  },
  "18435": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LACKAWAXEN"
  },
  "18436": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAKE ARIEL"
  },
  "18437": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAKE COMO"
  },
  "18438": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAKEVILLE"
  },
  "18439": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAKEWOOD"
  },
  "18441": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LENOXVILLE"
  },
  "18443": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILANVILLE"
  },
  "18444": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOSCOW"
  },
  "18445": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEWFOUNDLAND"
  },
  "18446": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NICHOLSON"
  },
  "18447": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "OLYPHANT"
  },
  "18451": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PAUPACK"
  },
  "18452": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PECKVILLE"
  },
  "18453": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PLEASANT MOUNT"
  },
  "18455": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PRESTON PARK"
  },
  "18456": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PROMPTON"
  },
  "18457": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROWLAND"
  },
  "18458": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHOHOLA"
  },
  "18459": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SOUTH CANAAN"
  },
  "18460": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SOUTH STERLING"
  },
  "18461": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STARLIGHT"
  },
  "18462": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STARRUCCA"
  },
  "18464": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TAFTON"
  },
  "18465": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "THOMPSON"
  },
  "18466": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TOBYHANNA"
  },
  "18469": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TYLER HILL"
  },
  "18470": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "UNION DALE"
  },
  "18471": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WAVERLY"
  },
  "18472": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WAYMART"
  },
  "18473": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WHITE MILLS"
  },
  "18503": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SCRANTON"
  },
  "18504": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SCRANTON"
  },
  "18505": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SCRANTON"
  },
  "18507": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOOSIC"
  },
  "18508": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SCRANTON"
  },
  "18509": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SCRANTON"
  },
  "18510": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SCRANTON"
  },
  "18512": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SCRANTON"
  },
  "18517": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TAYLOR"
  },
  "18518": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "OLD FORGE"
  },
  "18519": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DICKSON CITY"
  },
  "18601": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BEACH HAVEN"
  },
  "18602": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BEAR CREEK"
  },
  "18603": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BERWICK"
  },
  "18610": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BLAKESLEE"
  },
  "18611": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CAMBRA"
  },
  "18612": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DALLAS"
  },
  "18614": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DUSHORE"
  },
  "18615": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FALLS"
  },
  "18616": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FORKSVILLE"
  },
  "18617": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GLEN LYON"
  },
  "18618": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARVEYS LAKE"
  },
  "18619": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HILLSGROVE"
  },
  "18621": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HUNLOCK CREEK"
  },
  "18622": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HUNTINGTON MILLS"
  },
  "18623": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LACEYVILLE"
  },
  "18624": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAKE HARMONY"
  },
  "18625": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAKE WINOLA"
  },
  "18626": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAPORTE"
  },
  "18628": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LOPEZ"
  },
  "18629": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MEHOOPANY"
  },
  "18630": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MESHOPPEN"
  },
  "18631": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MIFFLINVILLE"
  },
  "18632": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILDRED"
  },
  "18634": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NANTICOKE"
  },
  "18635": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NESCOPECK"
  },
  "18636": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NOXEN"
  },
  "18640": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSTON"
  },
  "18641": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSTON"
  },
  "18642": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DURYEA"
  },
  "18643": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PITTSTON"
  },
  "18644": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WYOMING"
  },
  "18651": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PLYMOUTH"
  },
  "18653": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RANSOM"
  },
  "18655": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHICKSHINNY"
  },
  "18656": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SWEET VALLEY"
  },
  "18657": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TUNKHANNOCK"
  },
  "18660": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WAPWALLOPEN"
  },
  "18661": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WHITE HAVEN"
  },
  "18701": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WILKES BARRE"
  },
  "18702": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WILKES BARRE"
  },
  "18704": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KINGSTON"
  },
  "18705": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WILKES BARRE"
  },
  "18706": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WILKES BARRE"
  },
  "18707": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOUNTAIN TOP"
  },
  "18708": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHAVERTOWN"
  },
  "18709": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LUZERNE"
  },
  "18801": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MONTROSE"
  },
  "18810": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ATHENS"
  },
  "18812": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BRACKNEY"
  },
  "18813": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BROOKLYN"
  },
  "18814": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BURLINGTON"
  },
  "18816": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DIMOCK"
  },
  "18817": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EAST SMITHFIELD"
  },
  "18818": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FRIENDSVILLE"
  },
  "18820": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GIBSON"
  },
  "18821": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GREAT BEND"
  },
  "18822": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HALLSTEAD"
  },
  "18823": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARFORD"
  },
  "18824": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HOP BOTTOM"
  },
  "18825": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JACKSON"
  },
  "18826": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KINGSLEY"
  },
  "18827": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LANESBORO"
  },
  "18828": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAWTON"
  },
  "18829": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LE RAYSVILLE"
  },
  "18830": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LITTLE MEADOWS"
  },
  "18831": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MILAN"
  },
  "18832": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MONROETON"
  },
  "18833": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW ALBANY"
  },
  "18834": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW MILFORD"
  },
  "18837": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROME"
  },
  "18840": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SAYRE"
  },
  "18842": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SOUTH GIBSON"
  },
  "18844": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPRINGVILLE"
  },
  "18845": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STEVENSVILLE"
  },
  "18846": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SUGAR RUN"
  },
  "18847": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SUSQUEHANNA"
  },
  "18848": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TOWANDA"
  },
  "18850": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ULSTER"
  },
  "18851": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WARREN CENTER"
  },
  "18853": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WYALUSING"
  },
  "18854": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WYSOX"
  },
  "18901": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DOYLESTOWN"
  },
  "18914": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CHALFONT"
  },
  "18915": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COLMAR"
  },
  "18917": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DUBLIN"
  },
  "18920": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ERWINNA"
  },
  "18923": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FOUNTAINVILLE"
  },
  "18925": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FURLONG"
  },
  "18927": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HILLTOWN"
  },
  "18929": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JAMISON"
  },
  "18930": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KINTNERSVILLE"
  },
  "18932": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LINE LEXINGTON"
  },
  "18936": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MONTGOMERYVILLE"
  },
  "18938": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW HOPE"
  },
  "18940": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEWTOWN"
  },
  "18942": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "OTTSVILLE"
  },
  "18944": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PERKASIE"
  },
  "18947": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PIPERSVILLE"
  },
  "18951": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "QUAKERTOWN"
  },
  "18954": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RICHBORO"
  },
  "18955": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RICHLANDTOWN"
  },
  "18960": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SELLERSVILLE"
  },
  "18962": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SILVERDALE"
  },
  "18964": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SOUDERTON"
  },
  "18966": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SOUTHAMPTON"
  },
  "18969": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TELFORD"
  },
  "18970": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TRUMBAUERSVILLE"
  },
  "18972": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "UPPER BLACK EDDY"
  },
  "18974": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WARMINSTER"
  },
  "18976": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WARRINGTON"
  },
  "18977": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WASHINGTON CROSSING"
  },
  "18980": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WYCOMBE"
  },
  "19001": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ABINGTON"
  },
  "19002": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "AMBLER"
  },
  "19003": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ARDMORE"
  },
  "19004": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BALA CYNWYD"
  },
  "19006": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HUNTINGDON VALLEY"
  },
  "19007": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BRISTOL"
  },
  "19008": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BROOMALL"
  },
  "19009": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BRYN ATHYN"
  },
  "19010": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BRYN MAWR"
  },
  "19012": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CHELTENHAM"
  },
  "19013": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CHESTER"
  },
  "19014": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ASTON"
  },
  "19015": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BROOKHAVEN"
  },
  "19018": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CLIFTON HEIGHTS"
  },
  "19020": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BENSALEM"
  },
  "19021": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CROYDON"
  },
  "19022": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CRUM LYNNE"
  },
  "19023": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DARBY"
  },
  "19025": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DRESHER"
  },
  "19026": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DREXEL HILL"
  },
  "19027": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ELKINS PARK"
  },
  "19029": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ESSINGTON"
  },
  "19030": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FAIRLESS HILLS"
  },
  "19031": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FLOURTOWN"
  },
  "19032": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FOLCROFT"
  },
  "19033": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FOLSOM"
  },
  "19034": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FORT WASHINGTON"
  },
  "19035": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GLADWYNE"
  },
  "19036": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GLENOLDEN"
  },
  "19038": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GLENSIDE"
  },
  "19040": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HATBORO"
  },
  "19041": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HAVERFORD"
  },
  "19043": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HOLMES"
  },
  "19044": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HORSHAM"
  },
  "19046": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "JENKINTOWN"
  },
  "19047": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LANGHORNE"
  },
  "19050": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LANSDOWNE"
  },
  "19053": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FEASTERVILLE TREVOSE"
  },
  "19054": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEVITTOWN"
  },
  "19055": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEVITTOWN"
  },
  "19056": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEVITTOWN"
  },
  "19057": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEVITTOWN"
  },
  "19061": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MARCUS HOOK"
  },
  "19063": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MEDIA"
  },
  "19064": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPRINGFIELD"
  },
  "19066": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MERION STATION"
  },
  "19067": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MORRISVILLE"
  },
  "19070": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MORTON"
  },
  "19072": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NARBERTH"
  },
  "19073": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEWTOWN SQUARE"
  },
  "19074": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NORWOOD"
  },
  "19075": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ORELAND"
  },
  "19076": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PROSPECT PARK"
  },
  "19078": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "RIDLEY PARK"
  },
  "19079": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHARON HILL"
  },
  "19081": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SWARTHMORE"
  },
  "19082": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "UPPER DARBY"
  },
  "19083": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HAVERTOWN"
  },
  "19085": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "VILLANOVA"
  },
  "19086": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WALLINGFORD"
  },
  "19087": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WAYNE"
  },
  "19090": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WILLOW GROVE"
  },
  "19094": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WOODLYN"
  },
  "19095": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WYNCOTE"
  },
  "19096": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WYNNEWOOD"
  },
  "19102": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19103": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19104": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19106": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19107": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19111": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19113": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19114": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19115": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19116": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19118": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19119": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19120": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19121": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19122": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19123": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19124": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19125": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19126": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19127": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19128": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19129": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19130": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19131": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19132": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19133": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19134": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19135": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19136": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19137": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19138": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19139": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19140": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19141": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19142": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19143": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19144": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19145": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19146": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19147": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19148": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19149": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19150": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19151": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19152": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19153": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19154": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHILADELPHIA"
  },
  "19301": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PAOLI"
  },
  "19310": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ATGLEN"
  },
  "19311": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "AVONDALE"
  },
  "19312": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BERWYN"
  },
  "19316": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BRANDAMORE"
  },
  "19317": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CHADDS FORD"
  },
  "19319": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CHEYNEY"
  },
  "19320": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COATESVILLE"
  },
  "19330": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COCHRANVILLE"
  },
  "19333": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DEVON"
  },
  "19335": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DOWNINGTOWN"
  },
  "19341": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EXTON"
  },
  "19342": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GLEN MILLS"
  },
  "19343": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GLENMOORE"
  },
  "19344": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HONEY BROOK"
  },
  "19348": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KENNETT SQUARE"
  },
  "19350": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LANDENBERG"
  },
  "19352": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LINCOLN UNIVERSITY"
  },
  "19355": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MALVERN"
  },
  "19358": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MODENA"
  },
  "19362": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NOTTINGHAM"
  },
  "19363": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "OXFORD"
  },
  "19365": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PARKESBURG"
  },
  "19367": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "POMEROY"
  },
  "19369": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SADSBURYVILLE"
  },
  "19372": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "THORNDALE"
  },
  "19373": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "THORNTON"
  },
  "19374": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TOUGHKENAMON"
  },
  "19380": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEST CHESTER"
  },
  "19382": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEST CHESTER"
  },
  "19390": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WEST GROVE"
  },
  "19401": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NORRISTOWN"
  },
  "19403": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NORRISTOWN"
  },
  "19405": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BRIDGEPORT"
  },
  "19406": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KING OF PRUSSIA"
  },
  "19421": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BIRCHRUNVILLE"
  },
  "19422": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BLUE BELL"
  },
  "19425": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CHESTER SPRINGS"
  },
  "19426": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "COLLEGEVILLE"
  },
  "19428": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CONSHOHOCKEN"
  },
  "19435": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FREDERICK"
  },
  "19436": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GWYNEDD"
  },
  "19437": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GWYNEDD VALLEY"
  },
  "19438": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HARLEYSVILLE"
  },
  "19440": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HATFIELD"
  },
  "19444": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LAFAYETTE HILL"
  },
  "19446": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LANSDALE"
  },
  "19453": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MONT CLARE"
  },
  "19454": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NORTH WALES"
  },
  "19457": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PARKER FORD"
  },
  "19460": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PHOENIXVILLE"
  },
  "19462": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PLYMOUTH MEETING"
  },
  "19464": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "POTTSTOWN"
  },
  "19465": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "POTTSTOWN"
  },
  "19468": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROYERSFORD"
  },
  "19472": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SASSAMANSVILLE"
  },
  "19473": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SCHWENKSVILLE"
  },
  "19474": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SKIPPACK"
  },
  "19475": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPRING CITY"
  },
  "19478": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SPRING MOUNT"
  },
  "19492": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ZIEGLERVILLE"
  },
  "19501": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ADAMSTOWN"
  },
  "19503": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BALLY"
  },
  "19504": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BARTO"
  },
  "19505": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BECHTELSVILLE"
  },
  "19506": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BERNVILLE"
  },
  "19507": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BETHEL"
  },
  "19508": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BIRDSBORO"
  },
  "19510": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BLANDON"
  },
  "19511": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BOWERS"
  },
  "19512": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "BOYERTOWN"
  },
  "19516": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "CENTERPORT"
  },
  "19518": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "DOUGLASSVILLE"
  },
  "19519": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "EARLVILLE"
  },
  "19520": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ELVERSON"
  },
  "19522": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "FLEETWOOD"
  },
  "19523": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GEIGERTOWN"
  },
  "19525": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "GILBERTSVILLE"
  },
  "19526": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "HAMBURG"
  },
  "19529": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KEMPTON"
  },
  "19530": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "KUTZTOWN"
  },
  "19533": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LEESPORT"
  },
  "19534": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LENHARTSVILLE"
  },
  "19536": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "LYON STATION"
  },
  "19539": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MERTZTOWN"
  },
  "19540": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOHNTON"
  },
  "19541": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOHRSVILLE"
  },
  "19543": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MORGANTOWN"
  },
  "19544": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "MOUNT AETNA"
  },
  "19545": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "NEW BERLINVILLE"
  },
  "19547": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "OLEY"
  },
  "19549": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "PORT CLINTON"
  },
  "19550": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "REHRERSBURG"
  },
  "19551": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "ROBESONIA"
  },
  "19554": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHARTLESVILLE"
  },
  "19555": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "SHOEMAKERSVILLE"
  },
  "19559": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "STRAUSSTOWN"
  },
  "19560": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TEMPLE"
  },
  "19562": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "TOPTON"
  },
  "19565": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WERNERSVILLE"
  },
  "19567": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "WOMELSDORF"
  },
  "19601": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "READING"
  },
  "19602": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "READING"
  },
  "19604": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "READING"
  },
  "19605": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "READING"
  },
  "19606": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "READING"
  },
  "19607": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "READING"
  },
  "19608": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "READING"
  },
  "19609": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "READING"
  },
  "19610": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "READING"
  },
  "19611": {
    stateCode: "PA",
    stateName: "Pennsylvania",
    city: "READING"
  },
  "19701": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "BEAR"
  },
  "19702": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "NEWARK"
  },
  "19703": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "CLAYMONT"
  },
  "19706": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "DELAWARE CITY"
  },
  "19707": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "HOCKESSIN"
  },
  "19709": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "MIDDLETOWN"
  },
  "19710": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "MONTCHANIN"
  },
  "19711": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "NEWARK"
  },
  "19713": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "NEWARK"
  },
  "19720": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "NEW CASTLE"
  },
  "19730": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "ODESSA"
  },
  "19731": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "PORT PENN"
  },
  "19732": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "ROCKLAND"
  },
  "19733": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "SAINT GEORGES"
  },
  "19734": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "TOWNSEND"
  },
  "19736": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "YORKLYN"
  },
  "19801": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "WILMINGTON"
  },
  "19802": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "WILMINGTON"
  },
  "19803": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "WILMINGTON"
  },
  "19804": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "WILMINGTON"
  },
  "19805": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "WILMINGTON"
  },
  "19806": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "WILMINGTON"
  },
  "19807": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "WILMINGTON"
  },
  "19808": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "WILMINGTON"
  },
  "19809": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "WILMINGTON"
  },
  "19810": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "WILMINGTON"
  },
  "19901": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "DOVER"
  },
  "19904": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "DOVER"
  },
  "19930": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "BETHANY BEACH"
  },
  "19931": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "BETHEL"
  },
  "19933": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "BRIDGEVILLE"
  },
  "19934": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "CAMDEN WYOMING"
  },
  "19938": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "CLAYTON"
  },
  "19939": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "DAGSBORO"
  },
  "19940": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "DELMAR"
  },
  "19941": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "ELLENDALE"
  },
  "19943": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "FELTON"
  },
  "19944": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "FENWICK ISLAND"
  },
  "19945": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "FRANKFORD"
  },
  "19946": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "FREDERICA"
  },
  "19947": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "GEORGETOWN"
  },
  "19950": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "GREENWOOD"
  },
  "19951": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "HARBESON"
  },
  "19952": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "HARRINGTON"
  },
  "19953": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "HARTLY"
  },
  "19954": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "HOUSTON"
  },
  "19955": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "KENTON"
  },
  "19956": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "LAUREL"
  },
  "19958": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "LEWES"
  },
  "19960": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "LINCOLN"
  },
  "19962": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "MAGNOLIA"
  },
  "19963": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "MILFORD"
  },
  "19964": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "MARYDEL"
  },
  "19966": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "MILLSBORO"
  },
  "19967": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "MILLVILLE"
  },
  "19968": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "MILTON"
  },
  "19970": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "OCEAN VIEW"
  },
  "19971": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "REHOBOTH BEACH"
  },
  "19973": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "SEAFORD"
  },
  "19975": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "SELBYVILLE"
  },
  "19977": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "SMYRNA"
  },
  "19979": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "VIOLA"
  },
  "19980": {
    stateCode: "DE",
    stateName: "Delaware",
    city: "WOODSIDE"
  },
  "20001": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20002": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20003": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20004": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20005": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20006": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20007": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20008": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20009": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20010": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20011": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20012": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20015": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20016": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20017": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20018": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20019": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20020": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20024": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20032": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20036": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20037": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20105": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ALDIE"
  },
  "20106": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "AMISSVILLE"
  },
  "20109": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MANASSAS"
  },
  "20110": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MANASSAS"
  },
  "20111": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MANASSAS"
  },
  "20112": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MANASSAS"
  },
  "20115": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MARSHALL"
  },
  "20117": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MIDDLEBURG"
  },
  "20118": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MIDDLEBURG"
  },
  "20119": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CATLETT"
  },
  "20120": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CENTREVILLE"
  },
  "20121": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CENTREVILLE"
  },
  "20124": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CLIFTON"
  },
  "20129": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PAEONIAN SPRINGS"
  },
  "20130": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PARIS"
  },
  "20132": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PURCELLVILLE"
  },
  "20135": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BLUEMONT"
  },
  "20136": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BRISTOW"
  },
  "20137": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BROAD RUN"
  },
  "20138": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CALVERTON"
  },
  "20139": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CASANOVA"
  },
  "20140": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RECTORTOWN"
  },
  "20141": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ROUND HILL"
  },
  "20143": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CATHARPIN"
  },
  "20144": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DELAPLANE"
  },
  "20147": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ASHBURN"
  },
  "20148": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ASHBURN"
  },
  "20151": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHANTILLY"
  },
  "20152": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHANTILLY"
  },
  "20155": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GAINESVILLE"
  },
  "20158": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HAMILTON"
  },
  "20164": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STERLING"
  },
  "20165": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STERLING"
  },
  "20166": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STERLING"
  },
  "20169": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HAYMARKET"
  },
  "20170": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HERNDON"
  },
  "20171": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HERNDON"
  },
  "20175": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LEESBURG"
  },
  "20176": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LEESBURG"
  },
  "20180": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LOVETTSVILLE"
  },
  "20181": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NOKESVILLE"
  },
  "20184": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "UPPERVILLE"
  },
  "20186": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WARRENTON"
  },
  "20187": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WARRENTON"
  },
  "20190": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RESTON"
  },
  "20191": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RESTON"
  },
  "20194": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RESTON"
  },
  "20197": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WATERFORD"
  },
  "20198": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "THE PLAINS"
  },
  "20319": {
    stateCode: "DC",
    stateName: "District of Columbia",
    city: "WASHINGTON"
  },
  "20601": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WALDORF"
  },
  "20602": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WALDORF"
  },
  "20603": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WALDORF"
  },
  "20606": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ABELL"
  },
  "20607": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ACCOKEEK"
  },
  "20608": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "AQUASCO"
  },
  "20609": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "AVENUE"
  },
  "20611": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BEL ALTON"
  },
  "20612": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BENEDICT"
  },
  "20613": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BRANDYWINE"
  },
  "20615": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BROOMES ISLAND"
  },
  "20616": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BRYANS ROAD"
  },
  "20617": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BRYANTOWN"
  },
  "20618": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BUSHWOOD"
  },
  "20619": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CALIFORNIA"
  },
  "20620": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CALLAWAY"
  },
  "20621": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CHAPTICO"
  },
  "20622": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CHARLOTTE HALL"
  },
  "20623": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CHELTENHAM"
  },
  "20624": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CLEMENTS"
  },
  "20625": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "COBB ISLAND"
  },
  "20626": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "COLTONS POINT"
  },
  "20628": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "DAMERON"
  },
  "20629": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "DOWELL"
  },
  "20630": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "DRAYDEN"
  },
  "20632": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FAULKNER"
  },
  "20634": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GREAT MILLS"
  },
  "20636": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HOLLYWOOD"
  },
  "20637": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HUGHESVILLE"
  },
  "20639": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HUNTINGTOWN"
  },
  "20640": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "INDIAN HEAD"
  },
  "20645": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ISSUE"
  },
  "20646": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "LA PLATA"
  },
  "20650": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "LEONARDTOWN"
  },
  "20653": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "LEXINGTON PARK"
  },
  "20657": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "LUSBY"
  },
  "20658": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MARBURY"
  },
  "20659": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MECHANICSVILLE"
  },
  "20662": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "NANJEMOY"
  },
  "20664": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "NEWBURG"
  },
  "20667": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PARK HALL"
  },
  "20670": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PATUXENT RIVER"
  },
  "20674": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PINEY POINT"
  },
  "20675": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "POMFRET"
  },
  "20676": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PORT REPUBLIC"
  },
  "20677": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PORT TOBACCO"
  },
  "20678": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PRINCE FREDERICK"
  },
  "20680": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "RIDGE"
  },
  "20684": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SAINT INIGOES"
  },
  "20685": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SAINT LEONARD"
  },
  "20687": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SCOTLAND"
  },
  "20688": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SOLOMONS"
  },
  "20689": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SUNDERLAND"
  },
  "20690": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "TALL TIMBERS"
  },
  "20692": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "VALLEY LEE"
  },
  "20693": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WELCOME"
  },
  "20695": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WHITE PLAINS"
  },
  "20701": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ANNAPOLIS JUNCTION"
  },
  "20705": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BELTSVILLE"
  },
  "20706": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "LANHAM"
  },
  "20707": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "LAUREL"
  },
  "20708": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "LAUREL"
  },
  "20710": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BLADENSBURG"
  },
  "20711": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "LOTHIAN"
  },
  "20712": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MOUNT RAINIER"
  },
  "20714": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "NORTH BEACH"
  },
  "20715": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BOWIE"
  },
  "20716": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BOWIE"
  },
  "20720": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BOWIE"
  },
  "20721": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BOWIE"
  },
  "20722": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BRENTWOOD"
  },
  "20723": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "LAUREL"
  },
  "20724": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "LAUREL"
  },
  "20732": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CHESAPEAKE BEACH"
  },
  "20733": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CHURCHTON"
  },
  "20735": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CLINTON"
  },
  "20736": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "OWINGS"
  },
  "20737": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "RIVERDALE"
  },
  "20740": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "COLLEGE PARK"
  },
  "20743": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CAPITOL HEIGHTS"
  },
  "20744": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FORT WASHINGTON"
  },
  "20745": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "OXON HILL"
  },
  "20746": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SUITLAND"
  },
  "20747": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "DISTRICT HEIGHTS"
  },
  "20748": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "TEMPLE HILLS"
  },
  "20751": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "DEALE"
  },
  "20754": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "DUNKIRK"
  },
  "20755": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FORT GEORGE G MEADE"
  },
  "20758": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FRIENDSHIP"
  },
  "20759": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FULTON"
  },
  "20762": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ANDREWS AIR FORCE BASE"
  },
  "20763": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SAVAGE"
  },
  "20764": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SHADY SIDE"
  },
  "20765": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GALESVILLE"
  },
  "20769": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GLENN DALE"
  },
  "20770": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GREENBELT"
  },
  "20772": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "UPPER MARLBORO"
  },
  "20774": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "UPPER MARLBORO"
  },
  "20776": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HARWOOD"
  },
  "20777": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HIGHLAND"
  },
  "20778": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WEST RIVER"
  },
  "20779": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "TRACYS LANDING"
  },
  "20781": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HYATTSVILLE"
  },
  "20782": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HYATTSVILLE"
  },
  "20783": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HYATTSVILLE"
  },
  "20784": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HYATTSVILLE"
  },
  "20785": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HYATTSVILLE"
  },
  "20794": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "JESSUP"
  },
  "20812": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GLEN ECHO"
  },
  "20814": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BETHESDA"
  },
  "20815": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CHEVY CHASE"
  },
  "20816": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BETHESDA"
  },
  "20817": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BETHESDA"
  },
  "20818": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CABIN JOHN"
  },
  "20832": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "OLNEY"
  },
  "20833": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BROOKEVILLE"
  },
  "20837": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "POOLESVILLE"
  },
  "20838": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BARNESVILLE"
  },
  "20839": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BEALLSVILLE"
  },
  "20841": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BOYDS"
  },
  "20842": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "DICKERSON"
  },
  "20850": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ROCKVILLE"
  },
  "20851": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ROCKVILLE"
  },
  "20852": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ROCKVILLE"
  },
  "20853": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ROCKVILLE"
  },
  "20854": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "POTOMAC"
  },
  "20855": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "DERWOOD"
  },
  "20860": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SANDY SPRING"
  },
  "20861": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ASHTON"
  },
  "20862": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BRINKLOW"
  },
  "20866": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BURTONSVILLE"
  },
  "20868": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SPENCERVILLE"
  },
  "20871": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CLARKSBURG"
  },
  "20872": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "DAMASCUS"
  },
  "20874": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GERMANTOWN"
  },
  "20876": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GERMANTOWN"
  },
  "20877": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GAITHERSBURG"
  },
  "20878": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GAITHERSBURG"
  },
  "20879": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GAITHERSBURG"
  },
  "20880": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WASHINGTON GROVE"
  },
  "20882": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GAITHERSBURG"
  },
  "20886": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MONTGOMERY VILLAGE"
  },
  "20895": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "KENSINGTON"
  },
  "20896": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GARRETT PARK"
  },
  "20901": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SILVER SPRING"
  },
  "20902": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SILVER SPRING"
  },
  "20903": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SILVER SPRING"
  },
  "20904": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SILVER SPRING"
  },
  "20905": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SILVER SPRING"
  },
  "20906": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SILVER SPRING"
  },
  "20910": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SILVER SPRING"
  },
  "20912": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "TAKOMA PARK"
  },
  "21001": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ABERDEEN"
  },
  "21005": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ABERDEEN PROVING GROUND"
  },
  "21009": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ABINGDON"
  },
  "21010": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GUNPOWDER"
  },
  "21012": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ARNOLD"
  },
  "21013": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALDWIN"
  },
  "21014": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BEL AIR"
  },
  "21015": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BEL AIR"
  },
  "21017": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BELCAMP"
  },
  "21028": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CHURCHVILLE"
  },
  "21029": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CLARKSVILLE"
  },
  "21030": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "COCKEYSVILLE"
  },
  "21032": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CROWNSVILLE"
  },
  "21034": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "DARLINGTON"
  },
  "21035": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "DAVIDSONVILLE"
  },
  "21036": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "DAYTON"
  },
  "21037": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "EDGEWATER"
  },
  "21040": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "EDGEWOOD"
  },
  "21042": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ELLICOTT CITY"
  },
  "21043": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ELLICOTT CITY"
  },
  "21044": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "COLUMBIA"
  },
  "21045": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "COLUMBIA"
  },
  "21046": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "COLUMBIA"
  },
  "21047": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FALLSTON"
  },
  "21048": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FINKSBURG"
  },
  "21050": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FOREST HILL"
  },
  "21051": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FORK"
  },
  "21052": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FORT HOWARD"
  },
  "21053": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FREELAND"
  },
  "21054": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GAMBRILLS"
  },
  "21056": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GIBSON ISLAND"
  },
  "21057": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GLEN ARM"
  },
  "21060": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GLEN BURNIE"
  },
  "21061": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GLEN BURNIE"
  },
  "21071": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GLYNDON"
  },
  "21074": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HAMPSTEAD"
  },
  "21075": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ELKRIDGE"
  },
  "21076": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HANOVER"
  },
  "21077": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HARMANS"
  },
  "21078": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HAVRE DE GRACE"
  },
  "21082": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HYDES"
  },
  "21084": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "JARRETTSVILLE"
  },
  "21085": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "JOPPA"
  },
  "21087": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "KINGSVILLE"
  },
  "21090": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "LINTHICUM HEIGHTS"
  },
  "21093": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "LUTHERVILLE TIMONIUM"
  },
  "21102": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MANCHESTER"
  },
  "21104": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MARRIOTTSVILLE"
  },
  "21105": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MARYLAND LINE"
  },
  "21108": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MILLERSVILLE"
  },
  "21111": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MONKTON"
  },
  "21113": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ODENTON"
  },
  "21114": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CROFTON"
  },
  "21117": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "OWINGS MILLS"
  },
  "21120": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PARKTON"
  },
  "21122": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PASADENA"
  },
  "21128": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PERRY HALL"
  },
  "21130": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PERRYMAN"
  },
  "21131": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PHOENIX"
  },
  "21132": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PYLESVILLE"
  },
  "21133": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "RANDALLSTOWN"
  },
  "21136": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "REISTERSTOWN"
  },
  "21140": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "RIVA"
  },
  "21144": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SEVERN"
  },
  "21146": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SEVERNA PARK"
  },
  "21152": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SPARKS GLENCOE"
  },
  "21154": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "STREET"
  },
  "21155": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "UPPERCO"
  },
  "21156": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "UPPER FALLS"
  },
  "21157": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WESTMINSTER"
  },
  "21158": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WESTMINSTER"
  },
  "21160": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WHITEFORD"
  },
  "21161": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WHITE HALL"
  },
  "21162": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WHITE MARSH"
  },
  "21163": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WOODSTOCK"
  },
  "21201": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21202": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21204": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "TOWSON"
  },
  "21205": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21206": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21207": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GWYNN OAK"
  },
  "21208": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PIKESVILLE"
  },
  "21209": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21210": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21211": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21212": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21213": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21214": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21215": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21216": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21217": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21218": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21219": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SPARROWS POINT"
  },
  "21220": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MIDDLE RIVER"
  },
  "21221": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ESSEX"
  },
  "21222": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "DUNDALK"
  },
  "21223": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21224": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21225": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BROOKLYN"
  },
  "21226": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CURTIS BAY"
  },
  "21227": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HALETHORPE"
  },
  "21228": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CATONSVILLE"
  },
  "21229": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21230": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21231": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21234": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PARKVILLE"
  },
  "21236": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "NOTTINGHAM"
  },
  "21237": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ROSEDALE"
  },
  "21239": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BALTIMORE"
  },
  "21244": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WINDSOR MILL"
  },
  "21286": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "TOWSON"
  },
  "21401": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ANNAPOLIS"
  },
  "21402": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ANNAPOLIS"
  },
  "21403": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ANNAPOLIS"
  },
  "21405": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ANNAPOLIS"
  },
  "21502": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CUMBERLAND"
  },
  "21520": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ACCIDENT"
  },
  "21521": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BARTON"
  },
  "21522": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BITTINGER"
  },
  "21523": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BLOOMINGTON"
  },
  "21524": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CORRIGANVILLE"
  },
  "21528": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ECKHART MINES"
  },
  "21529": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ELLERSLIE"
  },
  "21530": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FLINTSTONE"
  },
  "21531": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FRIENDSVILLE"
  },
  "21532": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FROSTBURG"
  },
  "21536": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GRANTSVILLE"
  },
  "21538": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "KITZMILLER"
  },
  "21539": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "LONACONING"
  },
  "21540": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "LUKE"
  },
  "21541": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MC HENRY"
  },
  "21542": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MIDLAND"
  },
  "21543": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MIDLOTHIAN"
  },
  "21545": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MOUNT SAVAGE"
  },
  "21550": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "OAKLAND"
  },
  "21555": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "OLDTOWN"
  },
  "21557": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "RAWLINGS"
  },
  "21560": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SPRING GAP"
  },
  "21561": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SWANTON"
  },
  "21562": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WESTERNPORT"
  },
  "21601": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "EASTON"
  },
  "21607": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BARCLAY"
  },
  "21610": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BETTERTON"
  },
  "21612": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BOZMAN"
  },
  "21613": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CAMBRIDGE"
  },
  "21617": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CENTREVILLE"
  },
  "21619": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CHESTER"
  },
  "21620": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CHESTERTOWN"
  },
  "21622": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CHURCH CREEK"
  },
  "21623": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CHURCH HILL"
  },
  "21624": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CLAIBORNE"
  },
  "21625": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CORDOVA"
  },
  "21626": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CRAPO"
  },
  "21627": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CROCHERON"
  },
  "21628": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CRUMPTON"
  },
  "21629": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "DENTON"
  },
  "21631": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "EAST NEW MARKET"
  },
  "21632": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FEDERALSBURG"
  },
  "21634": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FISHING CREEK"
  },
  "21635": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GALENA"
  },
  "21636": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GOLDSBORO"
  },
  "21638": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GRASONVILLE"
  },
  "21639": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GREENSBORO"
  },
  "21640": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HENDERSON"
  },
  "21641": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HILLSBORO"
  },
  "21643": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HURLOCK"
  },
  "21644": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "INGLESIDE"
  },
  "21645": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "KENNEDYVILLE"
  },
  "21647": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MCDANIEL"
  },
  "21648": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MADISON"
  },
  "21649": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MARYDEL"
  },
  "21650": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MASSEY"
  },
  "21651": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MILLINGTON"
  },
  "21653": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "NEWCOMB"
  },
  "21654": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "OXFORD"
  },
  "21655": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PRESTON"
  },
  "21657": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "QUEEN ANNE"
  },
  "21658": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "QUEENSTOWN"
  },
  "21659": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "RHODESDALE"
  },
  "21660": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "RIDGELY"
  },
  "21661": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ROCK HALL"
  },
  "21662": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ROYAL OAK"
  },
  "21663": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SAINT MICHAELS"
  },
  "21664": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SECRETARY"
  },
  "21665": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SHERWOOD"
  },
  "21666": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "STEVENSVILLE"
  },
  "21667": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "STILL POND"
  },
  "21668": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SUDLERSVILLE"
  },
  "21669": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "TAYLORS ISLAND"
  },
  "21671": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "TILGHMAN"
  },
  "21672": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "TODDVILLE"
  },
  "21673": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "TRAPPE"
  },
  "21675": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WINGATE"
  },
  "21676": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WITTMAN"
  },
  "21677": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WOOLFORD"
  },
  "21678": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WORTON"
  },
  "21679": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WYE MILLS"
  },
  "21701": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FREDERICK"
  },
  "21702": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FREDERICK"
  },
  "21703": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FREDERICK"
  },
  "21704": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FREDERICK"
  },
  "21710": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ADAMSTOWN"
  },
  "21711": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BIG POOL"
  },
  "21713": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BOONSBORO"
  },
  "21716": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BRUNSWICK"
  },
  "21717": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BUCKEYSTOWN"
  },
  "21718": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BURKITTSVILLE"
  },
  "21719": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CASCADE"
  },
  "21722": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CLEAR SPRING"
  },
  "21723": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "COOKSVILLE"
  },
  "21727": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "EMMITSBURG"
  },
  "21733": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FAIRPLAY"
  },
  "21734": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FUNKSTOWN"
  },
  "21737": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GLENELG"
  },
  "21738": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GLENWOOD"
  },
  "21740": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HAGERSTOWN"
  },
  "21742": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HAGERSTOWN"
  },
  "21750": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HANCOCK"
  },
  "21754": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "IJAMSVILLE"
  },
  "21755": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "JEFFERSON"
  },
  "21756": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "KEEDYSVILLE"
  },
  "21757": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "KEYMAR"
  },
  "21758": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "KNOXVILLE"
  },
  "21762": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "LIBERTYTOWN"
  },
  "21766": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "LITTLE ORLEANS"
  },
  "21767": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MAUGANSVILLE"
  },
  "21769": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MIDDLETOWN"
  },
  "21770": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MONROVIA"
  },
  "21771": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MOUNT AIRY"
  },
  "21773": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MYERSVILLE"
  },
  "21774": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "NEW MARKET"
  },
  "21776": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "NEW WINDSOR"
  },
  "21777": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "POINT OF ROCKS"
  },
  "21778": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ROCKY RIDGE"
  },
  "21779": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ROHRERSVILLE"
  },
  "21780": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SABILLASVILLE"
  },
  "21782": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SHARPSBURG"
  },
  "21783": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SMITHSBURG"
  },
  "21784": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SYKESVILLE"
  },
  "21787": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "TANEYTOWN"
  },
  "21788": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "THURMONT"
  },
  "21790": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "TUSCARORA"
  },
  "21791": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "UNION BRIDGE"
  },
  "21793": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WALKERSVILLE"
  },
  "21794": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WEST FRIENDSHIP"
  },
  "21795": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WILLIAMSPORT"
  },
  "21797": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WOODBINE"
  },
  "21798": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WOODSBORO"
  },
  "21801": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SALISBURY"
  },
  "21804": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SALISBURY"
  },
  "21810": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ALLEN"
  },
  "21811": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BERLIN"
  },
  "21813": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BISHOPVILLE"
  },
  "21814": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "BIVALVE"
  },
  "21817": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CRISFIELD"
  },
  "21821": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "DEAL ISLAND"
  },
  "21822": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "EDEN"
  },
  "21824": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "EWELL"
  },
  "21826": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "FRUITLAND"
  },
  "21829": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GIRDLETREE"
  },
  "21830": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "HEBRON"
  },
  "21835": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "LINKWOOD"
  },
  "21837": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MARDELA SPRINGS"
  },
  "21838": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "MARION STATION"
  },
  "21840": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "NANTICOKE"
  },
  "21841": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "NEWARK"
  },
  "21842": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "OCEAN CITY"
  },
  "21849": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PARSONSBURG"
  },
  "21850": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PITTSVILLE"
  },
  "21851": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "POCOMOKE CITY"
  },
  "21853": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PRINCESS ANNE"
  },
  "21856": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "QUANTICO"
  },
  "21861": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SHARPTOWN"
  },
  "21862": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SHOWELL"
  },
  "21863": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "SNOW HILL"
  },
  "21864": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "STOCKTON"
  },
  "21865": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "TYASKIN"
  },
  "21866": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "TYLERTON"
  },
  "21867": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "UPPER FAIRMOUNT"
  },
  "21869": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "VIENNA"
  },
  "21871": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WESTOVER"
  },
  "21872": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WHALEYVILLE"
  },
  "21874": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WILLARDS"
  },
  "21875": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "DELMAR"
  },
  "21901": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "NORTH EAST"
  },
  "21903": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PERRYVILLE"
  },
  "21904": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "PORT DEPOSIT"
  },
  "21911": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "RISING SUN"
  },
  "21912": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "WARWICK"
  },
  "21913": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CECILTON"
  },
  "21914": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CHARLESTOWN"
  },
  "21915": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CHESAPEAKE CITY"
  },
  "21917": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "COLORA"
  },
  "21918": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "CONOWINGO"
  },
  "21919": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "EARLEVILLE"
  },
  "21920": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ELK MILLS"
  },
  "21921": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "ELKTON"
  },
  "21930": {
    stateCode: "MD",
    stateName: "Maryland",
    city: "GEORGETOWN"
  },
  "22003": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ANNANDALE"
  },
  "22015": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BURKE"
  },
  "22026": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DUMFRIES"
  },
  "22027": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DUNN LORING"
  },
  "22030": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FAIRFAX"
  },
  "22031": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FAIRFAX"
  },
  "22032": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FAIRFAX"
  },
  "22033": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FAIRFAX"
  },
  "22039": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FAIRFAX STATION"
  },
  "22041": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FALLS CHURCH"
  },
  "22042": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FALLS CHURCH"
  },
  "22043": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FALLS CHURCH"
  },
  "22044": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FALLS CHURCH"
  },
  "22046": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FALLS CHURCH"
  },
  "22060": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FORT BELVOIR"
  },
  "22066": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GREAT FALLS"
  },
  "22079": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LORTON"
  },
  "22101": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MC LEAN"
  },
  "22102": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MC LEAN"
  },
  "22124": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "OAKTON"
  },
  "22125": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "OCCOQUAN"
  },
  "22134": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "QUANTICO"
  },
  "22150": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SPRINGFIELD"
  },
  "22151": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SPRINGFIELD"
  },
  "22152": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SPRINGFIELD"
  },
  "22153": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SPRINGFIELD"
  },
  "22172": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "TRIANGLE"
  },
  "22180": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VIENNA"
  },
  "22181": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VIENNA"
  },
  "22182": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VIENNA"
  },
  "22191": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WOODBRIDGE"
  },
  "22192": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WOODBRIDGE"
  },
  "22193": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WOODBRIDGE"
  },
  "22201": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ARLINGTON"
  },
  "22202": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ARLINGTON"
  },
  "22203": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ARLINGTON"
  },
  "22204": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ARLINGTON"
  },
  "22205": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ARLINGTON"
  },
  "22206": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ARLINGTON"
  },
  "22207": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ARLINGTON"
  },
  "22209": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ARLINGTON"
  },
  "22211": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FT MYER"
  },
  "22213": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ARLINGTON"
  },
  "22301": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ALEXANDRIA"
  },
  "22302": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ALEXANDRIA"
  },
  "22303": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ALEXANDRIA"
  },
  "22304": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ALEXANDRIA"
  },
  "22305": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ALEXANDRIA"
  },
  "22306": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ALEXANDRIA"
  },
  "22307": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ALEXANDRIA"
  },
  "22308": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ALEXANDRIA"
  },
  "22309": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ALEXANDRIA"
  },
  "22310": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ALEXANDRIA"
  },
  "22311": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ALEXANDRIA"
  },
  "22312": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ALEXANDRIA"
  },
  "22314": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ALEXANDRIA"
  },
  "22315": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ALEXANDRIA"
  },
  "22401": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FREDERICKSBURG"
  },
  "22405": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FREDERICKSBURG"
  },
  "22406": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FREDERICKSBURG"
  },
  "22407": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FREDERICKSBURG"
  },
  "22408": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FREDERICKSBURG"
  },
  "22427": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BOWLING GREEN"
  },
  "22432": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BURGESS"
  },
  "22433": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BURR HILL"
  },
  "22435": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CALLAO"
  },
  "22436": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CARET"
  },
  "22437": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CENTER CROSS"
  },
  "22438": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHAMPLAIN"
  },
  "22443": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "COLONIAL BEACH"
  },
  "22448": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DAHLGREN"
  },
  "22454": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DUNNSVILLE"
  },
  "22460": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FARNHAM"
  },
  "22469": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HAGUE"
  },
  "22473": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HEATHSVILLE"
  },
  "22476": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HUSTLE"
  },
  "22480": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "IRVINGTON"
  },
  "22482": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "KILMARNOCK"
  },
  "22485": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "KING GEORGE"
  },
  "22488": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "KINSALE"
  },
  "22503": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LANCASTER"
  },
  "22504": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LANEVIEW"
  },
  "22507": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LIVELY"
  },
  "22508": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LOCUST GROVE"
  },
  "22509": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LORETTO"
  },
  "22511": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LOTTSBURG"
  },
  "22514": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MILFORD"
  },
  "22520": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MONTROSS"
  },
  "22529": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "OLDHAMS"
  },
  "22534": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PARTLOW"
  },
  "22535": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PORT ROYAL"
  },
  "22538": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RAPPAHANNOCK ACADEMY"
  },
  "22539": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "REEDVILLE"
  },
  "22542": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RHOADESVILLE"
  },
  "22546": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RUTHER GLEN"
  },
  "22548": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SHARPS"
  },
  "22553": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SPOTSYLVANIA"
  },
  "22554": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STAFFORD"
  },
  "22560": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "TAPPAHANNOCK"
  },
  "22567": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "UNIONVILLE"
  },
  "22572": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WARSAW"
  },
  "22576": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WEEMS"
  },
  "22578": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WHITE STONE"
  },
  "22579": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WICOMICO CHURCH"
  },
  "22580": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WOODFORD"
  },
  "22601": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WINCHESTER"
  },
  "22602": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WINCHESTER"
  },
  "22603": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WINCHESTER"
  },
  "22610": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BENTONVILLE"
  },
  "22611": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BERRYVILLE"
  },
  "22620": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BOYCE"
  },
  "22624": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CLEAR BROOK"
  },
  "22625": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CROSS JUNCTION"
  },
  "22626": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FISHERS HILL"
  },
  "22627": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FLINT HILL"
  },
  "22630": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FRONT ROYAL"
  },
  "22637": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GORE"
  },
  "22639": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HUME"
  },
  "22640": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HUNTLY"
  },
  "22641": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STRASBURG"
  },
  "22642": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LINDEN"
  },
  "22643": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MARKHAM"
  },
  "22644": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MAURERTOWN"
  },
  "22645": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MIDDLETOWN"
  },
  "22646": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MILLWOOD"
  },
  "22650": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RILEYVILLE"
  },
  "22652": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FORT VALLEY"
  },
  "22654": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STAR TANNERY"
  },
  "22655": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STEPHENS CITY"
  },
  "22656": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STEPHENSON"
  },
  "22657": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STRASBURG"
  },
  "22660": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "TOMS BROOK"
  },
  "22663": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WHITE POST"
  },
  "22664": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WOODSTOCK"
  },
  "22701": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CULPEPER"
  },
  "22709": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ARODA"
  },
  "22711": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BANCO"
  },
  "22712": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BEALETON"
  },
  "22713": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BOSTON"
  },
  "22714": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BRANDY STATION"
  },
  "22715": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BRIGHTWOOD"
  },
  "22716": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CASTLETON"
  },
  "22718": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ELKWOOD"
  },
  "22719": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ETLAN"
  },
  "22720": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GOLDVEIN"
  },
  "22722": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HAYWOOD"
  },
  "22723": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HOOD"
  },
  "22724": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "JEFFERSONTON"
  },
  "22726": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LIGNUM"
  },
  "22727": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MADISON"
  },
  "22728": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MIDLAND"
  },
  "22729": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MITCHELLS"
  },
  "22730": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "OAKPARK"
  },
  "22731": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PRATTS"
  },
  "22732": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RADIANT"
  },
  "22733": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RAPIDAN"
  },
  "22734": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "REMINGTON"
  },
  "22735": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "REVA"
  },
  "22736": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHARDSVILLE"
  },
  "22737": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RIXEYVILLE"
  },
  "22738": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ROCHELLE"
  },
  "22740": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SPERRYVILLE"
  },
  "22741": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STEVENSBURG"
  },
  "22742": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SUMERDUCK"
  },
  "22743": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SYRIA"
  },
  "22746": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VIEWTOWN"
  },
  "22747": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WASHINGTON"
  },
  "22749": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WOODVILLE"
  },
  "22801": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HARRISONBURG"
  },
  "22802": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HARRISONBURG"
  },
  "22810": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BASYE"
  },
  "22811": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BERGTON"
  },
  "22812": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BRIDGEWATER"
  },
  "22815": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BROADWAY"
  },
  "22820": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CRIDERS"
  },
  "22821": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DAYTON"
  },
  "22824": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "EDINBURG"
  },
  "22827": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ELKTON"
  },
  "22830": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FULKS RUN"
  },
  "22831": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HINTON"
  },
  "22832": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "KEEZLETOWN"
  },
  "22834": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LINVILLE"
  },
  "22835": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LURAY"
  },
  "22840": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MC GAHEYSVILLE"
  },
  "22841": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MOUNT CRAWFORD"
  },
  "22842": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MOUNT JACKSON"
  },
  "22843": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MOUNT SOLON"
  },
  "22844": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NEW MARKET"
  },
  "22845": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ORKNEY SPRINGS"
  },
  "22846": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PENN LAIRD"
  },
  "22847": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "QUICKSBURG"
  },
  "22849": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SHENANDOAH"
  },
  "22850": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SINGERS GLEN"
  },
  "22851": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STANLEY"
  },
  "22853": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "TIMBERVILLE"
  },
  "22901": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHARLOTTESVILLE"
  },
  "22902": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHARLOTTESVILLE"
  },
  "22903": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHARLOTTESVILLE"
  },
  "22911": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHARLOTTESVILLE"
  },
  "22920": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "AFTON"
  },
  "22922": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ARRINGTON"
  },
  "22923": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BARBOURSVILLE"
  },
  "22924": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BATESVILLE"
  },
  "22931": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "COVESVILLE"
  },
  "22932": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CROZET"
  },
  "22935": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DYKE"
  },
  "22936": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "EARLYSVILLE"
  },
  "22937": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ESMONT"
  },
  "22938": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FABER"
  },
  "22939": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FISHERSVILLE"
  },
  "22940": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FREE UNION"
  },
  "22942": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GORDONSVILLE"
  },
  "22943": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GREENWOOD"
  },
  "22946": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "KEENE"
  },
  "22947": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "KESWICK"
  },
  "22948": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LOCUST DALE"
  },
  "22949": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LOVINGSTON"
  },
  "22952": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LYNDHURST"
  },
  "22957": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MONTPELIER STATION"
  },
  "22958": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NELLYSFORD"
  },
  "22959": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORTH GARDEN"
  },
  "22960": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ORANGE"
  },
  "22963": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PALMYRA"
  },
  "22964": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PINEY RIVER"
  },
  "22967": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ROSELAND"
  },
  "22968": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RUCKERSVILLE"
  },
  "22969": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SCHUYLER"
  },
  "22971": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SHIPMAN"
  },
  "22972": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SOMERSET"
  },
  "22973": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STANARDSVILLE"
  },
  "22974": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "TROY"
  },
  "22976": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "TYRO"
  },
  "22980": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WAYNESBORO"
  },
  "22989": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WOODBERRY FOREST"
  },
  "23001": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ACHILLES"
  },
  "23002": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "AMELIA COURT HOUSE"
  },
  "23004": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ARVONIA"
  },
  "23005": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ASHLAND"
  },
  "23009": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "AYLETT"
  },
  "23011": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BARHAMSVILLE"
  },
  "23015": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BEAVERDAM"
  },
  "23021": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BOHANNON"
  },
  "23022": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BREMO BLUFF"
  },
  "23023": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BRUINGTON"
  },
  "23024": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BUMPASS"
  },
  "23025": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CARDINAL"
  },
  "23027": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CARTERSVILLE"
  },
  "23030": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHARLES CITY"
  },
  "23032": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHURCH VIEW"
  },
  "23035": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "COBBS CREEK"
  },
  "23038": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "COLUMBIA"
  },
  "23039": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CROZIER"
  },
  "23040": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CUMBERLAND"
  },
  "23043": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DELTAVILLE"
  },
  "23045": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DIGGS"
  },
  "23047": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DOSWELL"
  },
  "23050": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DUTTON"
  },
  "23055": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FORK UNION"
  },
  "23056": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FOSTER"
  },
  "23059": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GLEN ALLEN"
  },
  "23060": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GLEN ALLEN"
  },
  "23061": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GLOUCESTER"
  },
  "23062": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GLOUCESTER POINT"
  },
  "23063": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GOOCHLAND"
  },
  "23064": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GRIMSTEAD"
  },
  "23065": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GUM SPRING"
  },
  "23066": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GWYNN"
  },
  "23068": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HALLIEFORD"
  },
  "23069": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HANOVER"
  },
  "23070": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HARDYVILLE"
  },
  "23071": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HARTFIELD"
  },
  "23072": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HAYES"
  },
  "23075": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HIGHLAND SPRINGS"
  },
  "23076": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HUDGINS"
  },
  "23079": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "JAMAICA"
  },
  "23083": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "JETERSVILLE"
  },
  "23084": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "KENTS STORE"
  },
  "23085": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "KING AND QUEEN COURT HOUSE"
  },
  "23086": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "KING WILLIAM"
  },
  "23089": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LANEXA"
  },
  "23091": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LITTLE PLYMOUTH"
  },
  "23092": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LOCUST HILL"
  },
  "23093": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LOUISA"
  },
  "23102": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MAIDENS"
  },
  "23103": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MANAKIN SABOT"
  },
  "23106": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MANQUIN"
  },
  "23108": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MASCOT"
  },
  "23109": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MATHEWS"
  },
  "23110": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MATTAPONI"
  },
  "23111": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MECHANICSVILLE"
  },
  "23112": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MIDLOTHIAN"
  },
  "23113": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MIDLOTHIAN"
  },
  "23115": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MILLERS TAVERN"
  },
  "23116": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MECHANICSVILLE"
  },
  "23117": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MINERAL"
  },
  "23119": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MOON"
  },
  "23120": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MOSELEY"
  },
  "23123": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NEW CANTON"
  },
  "23124": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NEW KENT"
  },
  "23125": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NEW POINT"
  },
  "23126": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NEWTOWN"
  },
  "23128": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORTH"
  },
  "23129": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "OILVILLE"
  },
  "23130": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ONEMO"
  },
  "23138": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PORT HAYWOOD"
  },
  "23139": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "POWHATAN"
  },
  "23140": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PROVIDENCE FORGE"
  },
  "23141": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "QUINTON"
  },
  "23146": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ROCKVILLE"
  },
  "23148": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SAINT STEPHENS CHURCH"
  },
  "23149": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SALUDA"
  },
  "23150": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SANDSTON"
  },
  "23153": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SANDY HOOK"
  },
  "23156": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SHACKLEFORDS"
  },
  "23161": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STEVENSVILLE"
  },
  "23163": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SUSAN"
  },
  "23168": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "TOANO"
  },
  "23169": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "TOPPING"
  },
  "23175": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "URBANNA"
  },
  "23176": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WAKE"
  },
  "23177": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WALKERTON"
  },
  "23178": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WARE NECK"
  },
  "23180": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WATER VIEW"
  },
  "23181": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WEST POINT"
  },
  "23184": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WICOMICO"
  },
  "23185": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WILLIAMSBURG"
  },
  "23188": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WILLIAMSBURG"
  },
  "23192": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MONTPELIER"
  },
  "23219": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23220": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23221": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23222": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23223": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23224": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23225": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23226": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23227": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23228": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23229": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23230": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23231": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23233": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23234": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23235": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23236": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23237": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23294": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHMOND"
  },
  "23301": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ACCOMAC"
  },
  "23302": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ASSAWOMAN"
  },
  "23303": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ATLANTIC"
  },
  "23304": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BATTERY PARK"
  },
  "23306": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BELLE HAVEN"
  },
  "23307": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BIRDSNEST"
  },
  "23308": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BLOXOM"
  },
  "23310": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CAPE CHARLES"
  },
  "23314": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CARROLLTON"
  },
  "23315": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CARRSVILLE"
  },
  "23316": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHERITON"
  },
  "23320": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHESAPEAKE"
  },
  "23321": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHESAPEAKE"
  },
  "23322": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHESAPEAKE"
  },
  "23323": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHESAPEAKE"
  },
  "23324": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHESAPEAKE"
  },
  "23325": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHESAPEAKE"
  },
  "23336": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHINCOTEAGUE ISLAND"
  },
  "23337": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WALLOPS ISLAND"
  },
  "23347": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "EASTVILLE"
  },
  "23350": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "EXMORE"
  },
  "23354": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FRANKTOWN"
  },
  "23356": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GREENBACKVILLE"
  },
  "23357": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GREENBUSH"
  },
  "23358": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HACKSNECK"
  },
  "23359": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HALLWOOD"
  },
  "23389": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HARBORTON"
  },
  "23395": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HORNTOWN"
  },
  "23401": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "KELLER"
  },
  "23404": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LOCUSTVILLE"
  },
  "23405": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MACHIPONGO"
  },
  "23407": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MAPPSVILLE"
  },
  "23409": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MEARS"
  },
  "23410": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MELFA"
  },
  "23413": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NASSAWADOX"
  },
  "23415": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NEW CHURCH"
  },
  "23416": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "OAK HALL"
  },
  "23417": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ONANCOCK"
  },
  "23418": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ONLEY"
  },
  "23420": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PAINTER"
  },
  "23421": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PARKSLEY"
  },
  "23423": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "QUINBY"
  },
  "23426": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SANFORD"
  },
  "23427": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SAXIS"
  },
  "23430": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SMITHFIELD"
  },
  "23432": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SUFFOLK"
  },
  "23433": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SUFFOLK"
  },
  "23434": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SUFFOLK"
  },
  "23435": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SUFFOLK"
  },
  "23436": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SUFFOLK"
  },
  "23437": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SUFFOLK"
  },
  "23438": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SUFFOLK"
  },
  "23440": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "TANGIER"
  },
  "23441": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "TASLEY"
  },
  "23442": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "TEMPERANCEVILLE"
  },
  "23451": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VIRGINIA BEACH"
  },
  "23452": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VIRGINIA BEACH"
  },
  "23454": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VIRGINIA BEACH"
  },
  "23455": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VIRGINIA BEACH"
  },
  "23456": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VIRGINIA BEACH"
  },
  "23457": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VIRGINIA BEACH"
  },
  "23459": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VIRGINIA BEACH"
  },
  "23461": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VIRGINIA BEACH"
  },
  "23462": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VIRGINIA BEACH"
  },
  "23464": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VIRGINIA BEACH"
  },
  "23480": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WACHAPREAGUE"
  },
  "23487": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WINDSOR"
  },
  "23488": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WITHAMS"
  },
  "23502": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORFOLK"
  },
  "23503": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORFOLK"
  },
  "23504": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORFOLK"
  },
  "23505": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORFOLK"
  },
  "23507": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORFOLK"
  },
  "23508": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORFOLK"
  },
  "23509": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORFOLK"
  },
  "23510": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORFOLK"
  },
  "23513": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORFOLK"
  },
  "23517": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORFOLK"
  },
  "23518": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORFOLK"
  },
  "23521": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORFOLK"
  },
  "23523": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORFOLK"
  },
  "23601": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NEWPORT NEWS"
  },
  "23602": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NEWPORT NEWS"
  },
  "23603": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NEWPORT NEWS"
  },
  "23604": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FORT EUSTIS"
  },
  "23605": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NEWPORT NEWS"
  },
  "23606": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NEWPORT NEWS"
  },
  "23607": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NEWPORT NEWS"
  },
  "23608": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NEWPORT NEWS"
  },
  "23651": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FORT MONROE"
  },
  "23661": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HAMPTON"
  },
  "23662": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "POQUOSON"
  },
  "23663": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HAMPTON"
  },
  "23664": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HAMPTON"
  },
  "23665": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HAMPTON"
  },
  "23666": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HAMPTON"
  },
  "23669": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HAMPTON"
  },
  "23690": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "YORKTOWN"
  },
  "23691": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "YORKTOWN"
  },
  "23692": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "YORKTOWN"
  },
  "23693": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "YORKTOWN"
  },
  "23696": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SEAFORD"
  },
  "23701": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PORTSMOUTH"
  },
  "23702": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PORTSMOUTH"
  },
  "23703": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PORTSMOUTH"
  },
  "23704": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PORTSMOUTH"
  },
  "23707": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PORTSMOUTH"
  },
  "23801": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FORT LEE"
  },
  "23803": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PETERSBURG"
  },
  "23805": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PETERSBURG"
  },
  "23821": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ALBERTA"
  },
  "23824": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BLACKSTONE"
  },
  "23827": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BOYKINS"
  },
  "23828": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BRANCHVILLE"
  },
  "23829": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CAPRON"
  },
  "23830": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CARSON"
  },
  "23831": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHESTER"
  },
  "23832": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHESTERFIELD"
  },
  "23833": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHURCH ROAD"
  },
  "23834": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "COLONIAL HEIGHTS"
  },
  "23836": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHESTER"
  },
  "23837": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "COURTLAND"
  },
  "23838": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHESTERFIELD"
  },
  "23839": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DENDRON"
  },
  "23840": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DEWITT"
  },
  "23841": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DINWIDDIE"
  },
  "23842": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DISPUTANTA"
  },
  "23843": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DOLPHIN"
  },
  "23844": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DREWRYVILLE"
  },
  "23845": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "EBONY"
  },
  "23846": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ELBERON"
  },
  "23847": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "EMPORIA"
  },
  "23850": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FORD"
  },
  "23851": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FRANKLIN"
  },
  "23856": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FREEMAN"
  },
  "23857": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GASBURG"
  },
  "23860": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HOPEWELL"
  },
  "23866": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "IVOR"
  },
  "23867": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "JARRATT"
  },
  "23868": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LAWRENCEVILLE"
  },
  "23872": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MC KENNEY"
  },
  "23874": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NEWSOMS"
  },
  "23875": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PRINCE GEORGE"
  },
  "23876": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RAWLINGS"
  },
  "23878": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SEDLEY"
  },
  "23879": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SKIPPERS"
  },
  "23881": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SPRING GROVE"
  },
  "23882": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STONY CREEK"
  },
  "23883": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SURRY"
  },
  "23885": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SUTHERLAND"
  },
  "23887": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VALENTINES"
  },
  "23888": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WAKEFIELD"
  },
  "23889": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WARFIELD"
  },
  "23890": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WAVERLY"
  },
  "23893": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WHITE PLAINS"
  },
  "23894": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WILSONS"
  },
  "23897": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "YALE"
  },
  "23898": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ZUNI"
  },
  "23899": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CLAREMONT"
  },
  "23901": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FARMVILLE"
  },
  "23915": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BASKERVILLE"
  },
  "23917": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BOYDTON"
  },
  "23919": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BRACEY"
  },
  "23920": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BRODNAX"
  },
  "23921": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BUCKINGHAM"
  },
  "23922": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BURKEVILLE"
  },
  "23923": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHARLOTTE COURT HOUSE"
  },
  "23924": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHASE CITY"
  },
  "23927": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CLARKSVILLE"
  },
  "23930": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CREWE"
  },
  "23934": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CULLEN"
  },
  "23936": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DILLWYN"
  },
  "23937": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DRAKES BRANCH"
  },
  "23938": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DUNDAS"
  },
  "23939": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "EVERGREEN"
  },
  "23942": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GREEN BAY"
  },
  "23943": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HAMPDEN SYDNEY"
  },
  "23944": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "KENBRIDGE"
  },
  "23947": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "KEYSVILLE"
  },
  "23950": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LA CROSSE"
  },
  "23952": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LUNENBURG"
  },
  "23954": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MEHERRIN"
  },
  "23958": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PAMPLIN"
  },
  "23959": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PHENIX"
  },
  "23960": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PROSPECT"
  },
  "23962": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RANDOLPH"
  },
  "23963": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RED HOUSE"
  },
  "23964": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RED OAK"
  },
  "23966": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICE"
  },
  "23967": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SAXE"
  },
  "23968": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SKIPWITH"
  },
  "23970": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SOUTH HILL"
  },
  "23974": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VICTORIA"
  },
  "23976": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WYLLIESBURG"
  },
  "24012": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ROANOKE"
  },
  "24013": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ROANOKE"
  },
  "24014": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ROANOKE"
  },
  "24015": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ROANOKE"
  },
  "24016": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ROANOKE"
  },
  "24017": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ROANOKE"
  },
  "24018": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ROANOKE"
  },
  "24019": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ROANOKE"
  },
  "24053": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ARARAT"
  },
  "24054": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "AXTON"
  },
  "24055": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BASSETT"
  },
  "24058": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BELSPRING"
  },
  "24059": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BENT MOUNTAIN"
  },
  "24060": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BLACKSBURG"
  },
  "24064": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BLUE RIDGE"
  },
  "24065": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BOONES MILL"
  },
  "24066": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BUCHANAN"
  },
  "24067": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CALLAWAY"
  },
  "24069": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CASCADE"
  },
  "24070": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CATAWBA"
  },
  "24072": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHECK"
  },
  "24073": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHRISTIANSBURG"
  },
  "24076": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CLAUDVILLE"
  },
  "24077": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CLOVERDALE"
  },
  "24078": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "COLLINSVILLE"
  },
  "24079": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "COPPER HILL"
  },
  "24082": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CRITZ"
  },
  "24083": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DALEVILLE"
  },
  "24084": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DUBLIN"
  },
  "24085": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "EAGLE ROCK"
  },
  "24086": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "EGGLESTON"
  },
  "24087": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ELLISTON"
  },
  "24088": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FERRUM"
  },
  "24089": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FIELDALE"
  },
  "24090": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FINCASTLE"
  },
  "24091": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FLOYD"
  },
  "24092": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GLADE HILL"
  },
  "24093": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GLEN LYN"
  },
  "24095": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GOODVIEW"
  },
  "24101": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HARDY"
  },
  "24102": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HENRY"
  },
  "24104": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HUDDLESTON"
  },
  "24105": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "INDIAN VALLEY"
  },
  "24112": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MARTINSVILLE"
  },
  "24120": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MEADOWS OF DAN"
  },
  "24121": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MONETA"
  },
  "24122": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MONTVALE"
  },
  "24124": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NARROWS"
  },
  "24127": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NEW CASTLE"
  },
  "24128": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NEWPORT"
  },
  "24131": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PAINT BANK"
  },
  "24132": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PARROTT"
  },
  "24133": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PATRICK SPRINGS"
  },
  "24134": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PEARISBURG"
  },
  "24136": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PEMBROKE"
  },
  "24137": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PENHOOK"
  },
  "24138": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PILOT"
  },
  "24139": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PITTSVILLE"
  },
  "24141": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RADFORD"
  },
  "24142": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RADFORD"
  },
  "24147": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICH CREEK"
  },
  "24148": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RIDGEWAY"
  },
  "24149": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RINER"
  },
  "24150": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RIPPLEMEAD"
  },
  "24151": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ROCKY MOUNT"
  },
  "24153": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SALEM"
  },
  "24161": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SANDY LEVEL"
  },
  "24162": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SHAWSVILLE"
  },
  "24165": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SPENCER"
  },
  "24167": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STAFFORDSVILLE"
  },
  "24168": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STANLEYTOWN"
  },
  "24171": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STUART"
  },
  "24174": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "THAXTON"
  },
  "24175": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "TROUTVILLE"
  },
  "24176": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "UNION HALL"
  },
  "24179": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VINTON"
  },
  "24184": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WIRTZ"
  },
  "24185": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WOOLWINE"
  },
  "24201": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BRISTOL"
  },
  "24202": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BRISTOL"
  },
  "24210": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ABINGDON"
  },
  "24211": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ABINGDON"
  },
  "24215": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ANDOVER"
  },
  "24216": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "APPALACHIA"
  },
  "24217": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BEE"
  },
  "24218": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BEN HUR"
  },
  "24219": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BIG STONE GAP"
  },
  "24220": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BIRCHLEAF"
  },
  "24221": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BLACKWATER"
  },
  "24224": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CASTLEWOOD"
  },
  "24225": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CLEVELAND"
  },
  "24226": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CLINCHCO"
  },
  "24228": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CLINTWOOD"
  },
  "24230": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "COEBURN"
  },
  "24236": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DAMASCUS"
  },
  "24237": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DANTE"
  },
  "24239": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DAVENPORT"
  },
  "24243": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DRYDEN"
  },
  "24244": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DUFFIELD"
  },
  "24245": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DUNGANNON"
  },
  "24248": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "EWING"
  },
  "24250": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FORT BLACKMORE"
  },
  "24251": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GATE CITY"
  },
  "24256": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HAYSI"
  },
  "24258": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HILTONS"
  },
  "24260": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HONAKER"
  },
  "24263": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "JONESVILLE"
  },
  "24265": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "KEOKEE"
  },
  "24266": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LEBANON"
  },
  "24269": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MC CLURE"
  },
  "24270": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MENDOTA"
  },
  "24271": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NICKELSVILLE"
  },
  "24272": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORA"
  },
  "24273": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORTON"
  },
  "24277": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PENNINGTON GAP"
  },
  "24279": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "POUND"
  },
  "24280": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ROSEDALE"
  },
  "24281": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ROSE HILL"
  },
  "24282": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SAINT CHARLES"
  },
  "24283": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SAINT PAUL"
  },
  "24290": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WEBER CITY"
  },
  "24292": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WHITETOP"
  },
  "24293": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WISE"
  },
  "24301": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PULASKI"
  },
  "24311": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ATKINS"
  },
  "24312": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "AUSTINVILLE"
  },
  "24313": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BARREN SPRINGS"
  },
  "24314": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BASTIAN"
  },
  "24315": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BLAND"
  },
  "24316": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BROADFORD"
  },
  "24317": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CANA"
  },
  "24318": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CERES"
  },
  "24319": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHILHOWIE"
  },
  "24322": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CRIPPLE CREEK"
  },
  "24323": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CROCKETT"
  },
  "24324": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DRAPER"
  },
  "24325": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DUGSPUR"
  },
  "24326": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ELK CREEK"
  },
  "24327": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "EMORY"
  },
  "24328": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FANCY GAP"
  },
  "24330": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FRIES"
  },
  "24333": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GALAX"
  },
  "24340": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GLADE SPRING"
  },
  "24343": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HILLSVILLE"
  },
  "24347": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HIWASSEE"
  },
  "24348": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "INDEPENDENCE"
  },
  "24350": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "IVANHOE"
  },
  "24351": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LAMBSBURG"
  },
  "24352": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LAUREL FORK"
  },
  "24354": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MARION"
  },
  "24360": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MAX MEADOWS"
  },
  "24361": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MEADOWVIEW"
  },
  "24363": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MOUTH OF WILSON"
  },
  "24366": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ROCKY GAP"
  },
  "24368": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RURAL RETREAT"
  },
  "24370": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SALTVILLE"
  },
  "24374": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SPEEDWELL"
  },
  "24375": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SUGAR GROVE"
  },
  "24377": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "TANNERSVILLE"
  },
  "24378": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "TROUTDALE"
  },
  "24380": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WILLIS"
  },
  "24381": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WOODLAWN"
  },
  "24382": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WYTHEVILLE"
  },
  "24401": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STAUNTON"
  },
  "24412": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BACOVA"
  },
  "24413": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BLUE GRASS"
  },
  "24415": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BROWNSBURG"
  },
  "24416": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BUENA VISTA"
  },
  "24421": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHURCHVILLE"
  },
  "24422": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CLIFTON FORGE"
  },
  "24426": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "COVINGTON"
  },
  "24430": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CRAIGSVILLE"
  },
  "24431": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CRIMORA"
  },
  "24432": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DEERFIELD"
  },
  "24433": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DOE HILL"
  },
  "24435": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FAIRFIELD"
  },
  "24437": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FORT DEFIANCE"
  },
  "24439": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GOSHEN"
  },
  "24440": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GREENVILLE"
  },
  "24441": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GROTTOES"
  },
  "24442": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HEAD WATERS"
  },
  "24445": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HOT SPRINGS"
  },
  "24448": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "IRON GATE"
  },
  "24450": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LEXINGTON"
  },
  "24457": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LOW MOOR"
  },
  "24458": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MC DOWELL"
  },
  "24459": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MIDDLEBROOK"
  },
  "24460": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MILLBORO"
  },
  "24463": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MINT SPRING"
  },
  "24464": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MONTEBELLO"
  },
  "24465": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MONTEREY"
  },
  "24467": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MOUNT SIDNEY"
  },
  "24471": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PORT REPUBLIC"
  },
  "24472": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RAPHINE"
  },
  "24473": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ROCKBRIDGE BATHS"
  },
  "24474": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SELMA"
  },
  "24477": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "STUARTS DRAFT"
  },
  "24479": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SWOOPE"
  },
  "24482": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VERONA"
  },
  "24483": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VESUVIUS"
  },
  "24484": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WARM SPRINGS"
  },
  "24485": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WEST AUGUSTA"
  },
  "24486": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WEYERS CAVE"
  },
  "24487": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WILLIAMSVILLE"
  },
  "24501": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LYNCHBURG"
  },
  "24502": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LYNCHBURG"
  },
  "24503": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LYNCHBURG"
  },
  "24504": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LYNCHBURG"
  },
  "24517": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ALTAVISTA"
  },
  "24520": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ALTON"
  },
  "24521": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "AMHERST"
  },
  "24522": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "APPOMATTOX"
  },
  "24523": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BEDFORD"
  },
  "24526": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BIG ISLAND"
  },
  "24527": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BLAIRS"
  },
  "24528": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BROOKNEAL"
  },
  "24529": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BUFFALO JUNCTION"
  },
  "24530": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CALLANDS"
  },
  "24531": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CHATHAM"
  },
  "24533": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CLIFFORD"
  },
  "24534": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CLOVER"
  },
  "24536": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "COLEMAN FALLS"
  },
  "24538": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CONCORD"
  },
  "24539": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CRYSTAL HILL"
  },
  "24540": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DANVILLE"
  },
  "24541": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DANVILLE"
  },
  "24549": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DRY FORK"
  },
  "24550": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "EVINGTON"
  },
  "24551": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FOREST"
  },
  "24553": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GLADSTONE"
  },
  "24554": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GLADYS"
  },
  "24555": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GLASGOW"
  },
  "24556": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GOODE"
  },
  "24557": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GRETNA"
  },
  "24558": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HALIFAX"
  },
  "24562": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HOWARDSVILLE"
  },
  "24563": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HURT"
  },
  "24565": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "JAVA"
  },
  "24566": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "KEELING"
  },
  "24569": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LONG ISLAND"
  },
  "24571": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "LYNCH STATION"
  },
  "24572": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MADISON HEIGHTS"
  },
  "24574": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MONROE"
  },
  "24577": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NATHALIE"
  },
  "24578": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NATURAL BRIDGE"
  },
  "24579": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NATURAL BRIDGE STATION"
  },
  "24580": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NELSON"
  },
  "24581": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORWOOD"
  },
  "24586": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RINGGOLD"
  },
  "24588": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RUSTBURG"
  },
  "24589": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SCOTTSBURG"
  },
  "24590": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SCOTTSVILLE"
  },
  "24592": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SOUTH BOSTON"
  },
  "24593": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SPOUT SPRING"
  },
  "24594": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SUTHERLIN"
  },
  "24595": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SWEET BRIAR"
  },
  "24597": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VERNON HILL"
  },
  "24598": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VIRGILINA"
  },
  "24599": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WINGINA"
  },
  "24601": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "AMONATE"
  },
  "24602": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BANDY"
  },
  "24603": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BIG ROCK"
  },
  "24604": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BISHOP"
  },
  "24605": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BLUEFIELD"
  },
  "24606": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BOISSEVAIN"
  },
  "24607": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "BREAKS"
  },
  "24609": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "CEDAR BLUFF"
  },
  "24612": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "DORAN"
  },
  "24613": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "FALLS MILLS"
  },
  "24614": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "GRUNDY"
  },
  "24620": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "HURLEY"
  },
  "24622": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "JEWELL RIDGE"
  },
  "24624": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "KEEN MOUNTAIN"
  },
  "24628": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "MAXIE"
  },
  "24630": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "NORTH TAZEWELL"
  },
  "24631": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "OAKWOOD"
  },
  "24634": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "PILGRIMS KNOB"
  },
  "24635": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "POCAHONTAS"
  },
  "24637": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "POUNDING MILL"
  },
  "24639": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RAVEN"
  },
  "24641": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "RICHLANDS"
  },
  "24646": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "ROWE"
  },
  "24649": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "SWORDS CREEK"
  },
  "24651": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "TAZEWELL"
  },
  "24656": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "VANSANT"
  },
  "24657": {
    stateCode: "VA",
    stateName: "Virginia",
    city: "WHITEWOOD"
  },
  "24701": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BLUEFIELD"
  },
  "24712": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ATHENS"
  },
  "24714": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BEESON"
  },
  "24715": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BRAMWELL"
  },
  "24716": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BUD"
  },
  "24719": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "COVEL"
  },
  "24724": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FREEMAN"
  },
  "24726": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HERNDON"
  },
  "24731": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "KEGLEY"
  },
  "24733": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LASHMEET"
  },
  "24736": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MATOAKA"
  },
  "24737": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MONTCALM"
  },
  "24739": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "OAKVALE"
  },
  "24740": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PRINCETON"
  },
  "24747": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ROCK"
  },
  "24801": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WELCH"
  },
  "24808": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ANAWALT"
  },
  "24811": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "AVONDALE"
  },
  "24813": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BARTLEY"
  },
  "24815": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BERWIND"
  },
  "24816": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BIG SANDY"
  },
  "24817": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BRADSHAW"
  },
  "24818": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BRENTON"
  },
  "24822": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CLEAR FORK"
  },
  "24823": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "COAL MOUNTAIN"
  },
  "24827": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CYCLONE"
  },
  "24828": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DAVY"
  },
  "24829": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ECKMAN"
  },
  "24830": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ELBERT"
  },
  "24831": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ELKHORN"
  },
  "24836": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GARY"
  },
  "24839": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HANOVER"
  },
  "24842": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HEMPHILL"
  },
  "24843": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HENSLEY"
  },
  "24844": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "IAEGER"
  },
  "24845": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "IKES FORK"
  },
  "24846": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ISABAN"
  },
  "24848": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "JENKINJONES"
  },
  "24849": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "JESSE"
  },
  "24850": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "JOLO"
  },
  "24851": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "JUSTICE"
  },
  "24853": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "KIMBALL"
  },
  "24854": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "KOPPERSTON"
  },
  "24855": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "KYLE"
  },
  "24857": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LYNCO"
  },
  "24860": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MATHENY"
  },
  "24861": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MAYBEURY"
  },
  "24862": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MOHAWK"
  },
  "24866": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NEWHALL"
  },
  "24867": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NEW RICHMOND"
  },
  "24868": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NORTHFORK"
  },
  "24869": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NORTH SPRING"
  },
  "24870": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "OCEANA"
  },
  "24871": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PAGETON"
  },
  "24872": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PANTHER"
  },
  "24873": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PAYNESVILLE"
  },
  "24874": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PINEVILLE"
  },
  "24879": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RAYSAL"
  },
  "24880": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ROCK VIEW"
  },
  "24881": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RODERFIELD"
  },
  "24882": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SIMON"
  },
  "24884": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SQUIRE"
  },
  "24887": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SWITCHBACK"
  },
  "24888": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "THORPE"
  },
  "24892": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WAR"
  },
  "24894": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WARRIORMINE"
  },
  "24895": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WILCOE"
  },
  "24898": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WYOMING"
  },
  "24901": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LEWISBURG"
  },
  "24910": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ALDERSON"
  },
  "24915": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ARBOVALE"
  },
  "24916": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ASBURY"
  },
  "24918": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BALLARD"
  },
  "24920": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BARTOW"
  },
  "24924": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BUCKEYE"
  },
  "24925": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CALDWELL"
  },
  "24927": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CASS"
  },
  "24931": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CRAWLEY"
  },
  "24934": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DUNMORE"
  },
  "24935": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FOREST HILL"
  },
  "24938": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FRANKFORD"
  },
  "24941": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GAP MILLS"
  },
  "24943": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GRASSY MEADOWS"
  },
  "24944": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GREEN BANK"
  },
  "24945": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GREENVILLE"
  },
  "24946": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HILLSBORO"
  },
  "24951": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LINDSIDE"
  },
  "24954": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MARLINTON"
  },
  "24957": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MAXWELTON"
  },
  "24962": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PENCE SPRINGS"
  },
  "24963": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PETERSTOWN"
  },
  "24966": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RENICK"
  },
  "24970": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RONCEVERTE"
  },
  "24974": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SECONDCREEK"
  },
  "24976": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SINKS GROVE"
  },
  "24977": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SMOOT"
  },
  "24981": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "TALCOTT"
  },
  "24983": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "UNION"
  },
  "24984": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WAITEVILLE"
  },
  "24985": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WAYSIDE"
  },
  "24986": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WHITE SULPHUR SPRINGS"
  },
  "24991": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WILLIAMSBURG"
  },
  "25002": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ALLOY"
  },
  "25003": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ALUM CREEK"
  },
  "25005": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "AMMA"
  },
  "25007": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ARNETT"
  },
  "25008": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ARTIE"
  },
  "25009": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ASHFORD"
  },
  "25011": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BANCROFT"
  },
  "25015": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BELLE"
  },
  "25019": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BICKMORE"
  },
  "25021": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BIM"
  },
  "25022": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BLAIR"
  },
  "25024": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BLOOMINGROSE"
  },
  "25025": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BLOUNT"
  },
  "25028": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BOB WHITE"
  },
  "25030": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BOMONT"
  },
  "25031": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BOOMER"
  },
  "25033": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BUFFALO"
  },
  "25035": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CABIN CREEK"
  },
  "25036": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CANNELTON"
  },
  "25039": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CEDAR GROVE"
  },
  "25040": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHARLTON HEIGHTS"
  },
  "25043": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CLAY"
  },
  "25044": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CLEAR CREEK"
  },
  "25045": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CLENDENIN"
  },
  "25047": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CLOTHIER"
  },
  "25048": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "COLCORD"
  },
  "25049": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "COMFORT"
  },
  "25051": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "COSTA"
  },
  "25053": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DANVILLE"
  },
  "25054": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DAWES"
  },
  "25057": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DEEP WATER"
  },
  "25059": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DIXIE"
  },
  "25060": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DOROTHY"
  },
  "25063": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DUCK"
  },
  "25064": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DUNBAR"
  },
  "25067": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "EAST BANK"
  },
  "25070": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ELEANOR"
  },
  "25071": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ELKVIEW"
  },
  "25075": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ESKDALE"
  },
  "25076": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ETHEL"
  },
  "25081": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FOSTER"
  },
  "25082": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FRAZIERS BOTTOM"
  },
  "25083": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GALLAGHER"
  },
  "25085": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GAULEY BRIDGE"
  },
  "25086": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GLASGOW"
  },
  "25088": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GLEN"
  },
  "25090": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GLEN FERRIS"
  },
  "25102": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HANDLEY"
  },
  "25103": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HANSFORD"
  },
  "25106": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HENDERSON"
  },
  "25107": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HERNSHAW"
  },
  "25108": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HEWETT"
  },
  "25109": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HOMETOWN"
  },
  "25110": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HUGHESTON"
  },
  "25111": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "INDORE"
  },
  "25113": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "IVYDALE"
  },
  "25114": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "JEFFREY"
  },
  "25115": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "KANAWHA FALLS"
  },
  "25118": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "KIMBERLY"
  },
  "25119": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "KINCAID"
  },
  "25121": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LAKE"
  },
  "25123": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LEON"
  },
  "25124": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LIBERTY"
  },
  "25125": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LIZEMORES"
  },
  "25126": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LONDON"
  },
  "25130": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MADISON"
  },
  "25132": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MAMMOTH"
  },
  "25133": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MAYSEL"
  },
  "25134": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MIAMI"
  },
  "25136": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MONTGOMERY"
  },
  "25139": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MOUNT CARBON"
  },
  "25140": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NAOMA"
  },
  "25141": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NEBO"
  },
  "25143": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NITRO"
  },
  "25148": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ORGAS"
  },
  "25149": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "OTTAWA"
  },
  "25152": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PAGE"
  },
  "25154": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PEYTONA"
  },
  "25159": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "POCA"
  },
  "25160": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "POND GAP"
  },
  "25161": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "POWELLTON"
  },
  "25162": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PRATT"
  },
  "25164": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PROCIOUS"
  },
  "25165": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RACINE"
  },
  "25168": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RED HOUSE"
  },
  "25169": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RIDGEVIEW"
  },
  "25173": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ROBSON"
  },
  "25174": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ROCK CREEK"
  },
  "25177": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SAINT ALBANS"
  },
  "25181": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SETH"
  },
  "25183": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SHARPLES"
  },
  "25186": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SMITHERS"
  },
  "25187": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SOUTHSIDE"
  },
  "25193": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SYLVESTER"
  },
  "25202": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "TORNADO"
  },
  "25204": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "TWILIGHT"
  },
  "25205": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "UNEEDA"
  },
  "25206": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "VAN"
  },
  "25208": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WHARTON"
  },
  "25209": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WHITESVILLE"
  },
  "25211": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WIDEN"
  },
  "25213": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WINFIELD"
  },
  "25214": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WINIFREDE"
  },
  "25231": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ADVENT"
  },
  "25234": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ARNOLDSBURG"
  },
  "25235": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHLOE"
  },
  "25239": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "COTTAGEVILLE"
  },
  "25241": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "EVANS"
  },
  "25243": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GANDEEVILLE"
  },
  "25244": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GAY"
  },
  "25245": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GIVEN"
  },
  "25247": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HARTFORD"
  },
  "25248": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "KENNA"
  },
  "25251": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LEFT HAND"
  },
  "25252": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LE ROY"
  },
  "25253": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LETART"
  },
  "25259": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LOONEYVILLE"
  },
  "25260": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MASON"
  },
  "25261": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MILLSTONE"
  },
  "25262": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MILLWOOD"
  },
  "25264": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MOUNT ALTO"
  },
  "25265": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NEW HAVEN"
  },
  "25266": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NEWTON"
  },
  "25267": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NORMANTOWN"
  },
  "25268": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ORMA"
  },
  "25270": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "REEDY"
  },
  "25271": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RIPLEY"
  },
  "25275": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SANDYVILLE"
  },
  "25276": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SPENCER"
  },
  "25285": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WALLBACK"
  },
  "25286": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WALTON"
  },
  "25287": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WEST COLUMBIA"
  },
  "25301": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHARLESTON"
  },
  "25302": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHARLESTON"
  },
  "25303": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHARLESTON"
  },
  "25304": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHARLESTON"
  },
  "25306": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHARLESTON"
  },
  "25309": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHARLESTON"
  },
  "25311": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHARLESTON"
  },
  "25312": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHARLESTON"
  },
  "25313": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHARLESTON"
  },
  "25314": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHARLESTON"
  },
  "25315": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHARLESTON"
  },
  "25320": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHARLESTON"
  },
  "25401": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MARTINSBURG"
  },
  "25411": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BERKELEY SPRINGS"
  },
  "25413": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BUNKER HILL"
  },
  "25414": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHARLES TOWN"
  },
  "25419": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FALLING WATERS"
  },
  "25420": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GERRARDSTOWN"
  },
  "25421": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GLENGARY"
  },
  "25422": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GREAT CACAPON"
  },
  "25425": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HARPERS FERRY"
  },
  "25427": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HEDGESVILLE"
  },
  "25428": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "INWOOD"
  },
  "25430": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "KEARNEYSVILLE"
  },
  "25431": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LEVELS"
  },
  "25434": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PAW PAW"
  },
  "25437": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "POINTS"
  },
  "25438": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RANSON"
  },
  "25441": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RIPPON"
  },
  "25442": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SHENANDOAH JUNCTION"
  },
  "25443": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SHEPHERDSTOWN"
  },
  "25444": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SLANESVILLE"
  },
  "25446": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SUMMIT POINT"
  },
  "25501": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ALKOL"
  },
  "25502": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "APPLE GROVE"
  },
  "25503": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ASHTON"
  },
  "25504": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BARBOURSVILLE"
  },
  "25505": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BIG CREEK"
  },
  "25506": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BRANCHLAND"
  },
  "25507": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CEREDO"
  },
  "25508": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHAPMANVILLE"
  },
  "25510": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CULLODEN"
  },
  "25511": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DUNLOW"
  },
  "25512": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "EAST LYNN"
  },
  "25514": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FORT GAY"
  },
  "25515": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GALLIPOLIS FERRY"
  },
  "25517": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GENOA"
  },
  "25520": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GLENWOOD"
  },
  "25521": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GRIFFITHSVILLE"
  },
  "25523": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HAMLIN"
  },
  "25524": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HARTS"
  },
  "25526": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HURRICANE"
  },
  "25529": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "JULIAN"
  },
  "25530": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "KENOVA"
  },
  "25534": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "KIAHSVILLE"
  },
  "25535": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LAVALETTE"
  },
  "25537": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LESAGE"
  },
  "25540": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MIDKIFF"
  },
  "25541": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MILTON"
  },
  "25544": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MYRA"
  },
  "25545": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ONA"
  },
  "25547": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PECKS MILL"
  },
  "25550": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "POINT PLEASANT"
  },
  "25555": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PRICHARD"
  },
  "25557": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RANGER"
  },
  "25559": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SALT ROCK"
  },
  "25560": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SCOTT DEPOT"
  },
  "25564": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SOD"
  },
  "25565": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SPURLOCKVILLE"
  },
  "25567": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SUMERCO"
  },
  "25570": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WAYNE"
  },
  "25571": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WEST HAMLIN"
  },
  "25573": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "YAWKEY"
  },
  "25601": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LOGAN"
  },
  "25606": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ACCOVILLE"
  },
  "25607": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "AMHERSTDALE"
  },
  "25608": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BAISDEN"
  },
  "25611": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BRUNO"
  },
  "25612": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHAUNCEY"
  },
  "25617": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DAVIN"
  },
  "25621": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GILBERT"
  },
  "25624": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HENLAWSON"
  },
  "25625": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HOLDEN"
  },
  "25628": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "KISTLER"
  },
  "25630": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LORADO"
  },
  "25632": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LYBURN"
  },
  "25634": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MALLORY"
  },
  "25635": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MAN"
  },
  "25637": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MOUNT GAY"
  },
  "25638": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "OMAR"
  },
  "25639": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PEACH CREEK"
  },
  "25646": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "STOLLINGS"
  },
  "25647": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SWITZER"
  },
  "25649": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "VERDUNVILLE"
  },
  "25650": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "VERNER"
  },
  "25651": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WHARNCLIFFE"
  },
  "25652": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WHITMAN"
  },
  "25653": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WILKINSON"
  },
  "25654": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "YOLYN"
  },
  "25661": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WILLIAMSON"
  },
  "25665": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BORDERLAND"
  },
  "25666": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BREEDEN"
  },
  "25667": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHATTAROY"
  },
  "25669": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CRUM"
  },
  "25670": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DELBARTON"
  },
  "25671": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DINGESS"
  },
  "25672": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "EDGARTON"
  },
  "25674": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "KERMIT"
  },
  "25676": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LENORE"
  },
  "25678": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MATEWAN"
  },
  "25685": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NAUGATUCK"
  },
  "25688": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NORTH MATEWAN"
  },
  "25691": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RAWL"
  },
  "25692": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RED JACKET"
  },
  "25696": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "VARNEY"
  },
  "25699": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WILSONDALE"
  },
  "25701": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HUNTINGTON"
  },
  "25702": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HUNTINGTON"
  },
  "25703": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HUNTINGTON"
  },
  "25704": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HUNTINGTON"
  },
  "25705": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HUNTINGTON"
  },
  "25801": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BECKLEY"
  },
  "25812": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ANSTED"
  },
  "25813": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BEAVER"
  },
  "25817": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BOLT"
  },
  "25818": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BRADLEY"
  },
  "25820": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CAMP CREEK"
  },
  "25823": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "COAL CITY"
  },
  "25825": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "COOL RIDGE"
  },
  "25826": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CORINNE"
  },
  "25827": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CRAB ORCHARD"
  },
  "25831": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DANESE"
  },
  "25832": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DANIELS"
  },
  "25836": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ECCLES"
  },
  "25837": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "EDMOND"
  },
  "25839": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FAIRDALE"
  },
  "25840": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FAYETTEVILLE"
  },
  "25841": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FLAT TOP"
  },
  "25843": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GHENT"
  },
  "25844": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GLEN DANIEL"
  },
  "25845": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GLEN FORK"
  },
  "25846": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GLEN JEAN"
  },
  "25848": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GLEN ROGERS"
  },
  "25849": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GLEN WHITE"
  },
  "25853": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HELEN"
  },
  "25854": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HICO"
  },
  "25855": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HILLTOP"
  },
  "25857": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "JOSEPHINE"
  },
  "25862": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LANSING"
  },
  "25864": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LAYLAND"
  },
  "25865": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LESTER"
  },
  "25866": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LOCHGELLY"
  },
  "25868": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LOOKOUT"
  },
  "25870": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MABEN"
  },
  "25871": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MABSCOTT"
  },
  "25875": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MC GRAWS"
  },
  "25878": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MIDWAY"
  },
  "25879": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MINDEN"
  },
  "25880": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MOUNT HOPE"
  },
  "25882": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MULLENS"
  },
  "25901": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "OAK HILL"
  },
  "25902": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ODD"
  },
  "25904": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PAX"
  },
  "25907": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PRINCE"
  },
  "25908": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PRINCEWICK"
  },
  "25909": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PROSPERITY"
  },
  "25913": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RAVENCLIFF"
  },
  "25915": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RHODELL"
  },
  "25916": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SABINE"
  },
  "25917": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SCARBRO"
  },
  "25918": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SHADY SPRING"
  },
  "25920": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SLAB FORK"
  },
  "25921": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SOPHIA"
  },
  "25922": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SPANISHBURG"
  },
  "25928": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "STEPHENSON"
  },
  "25932": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SURVEYOR"
  },
  "25936": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "THURMOND"
  },
  "25938": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "VICTOR"
  },
  "25942": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WINONA"
  },
  "25951": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HINTON"
  },
  "25958": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHARMCO"
  },
  "25962": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RAINELLE"
  },
  "25966": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GREEN SULPHUR SPRINGS"
  },
  "25969": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "JUMPING BRANCH"
  },
  "25971": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LERONA"
  },
  "25972": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LESLIE"
  },
  "25976": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MEADOW BRIDGE"
  },
  "25977": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MEADOW CREEK"
  },
  "25978": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NIMITZ"
  },
  "25979": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PIPESTEM"
  },
  "25981": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "QUINWOOD"
  },
  "25984": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RUPERT"
  },
  "25985": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SANDSTONE"
  },
  "25986": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SPRING DALE"
  },
  "25989": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WHITE OAK"
  },
  "26003": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WHEELING"
  },
  "26030": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BEECH BOTTOM"
  },
  "26031": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BENWOOD"
  },
  "26032": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BETHANY"
  },
  "26033": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CAMERON"
  },
  "26034": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CHESTER"
  },
  "26035": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "COLLIERS"
  },
  "26036": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DALLAS"
  },
  "26037": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FOLLANSBEE"
  },
  "26038": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GLEN DALE"
  },
  "26039": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GLEN EASTON"
  },
  "26040": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MCMECHEN"
  },
  "26041": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MOUNDSVILLE"
  },
  "26047": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NEW CUMBERLAND"
  },
  "26050": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NEWELL"
  },
  "26055": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PROCTOR"
  },
  "26056": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NEW MANCHESTER"
  },
  "26059": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "TRIADELPHIA"
  },
  "26060": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "VALLEY GROVE"
  },
  "26062": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WEIRTON"
  },
  "26070": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WELLSBURG"
  },
  "26074": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WEST LIBERTY"
  },
  "26075": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WINDSOR HEIGHTS"
  },
  "26101": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PARKERSBURG"
  },
  "26104": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PARKERSBURG"
  },
  "26105": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "VIENNA"
  },
  "26133": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BELLEVILLE"
  },
  "26134": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BELMONT"
  },
  "26136": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BIG BEND"
  },
  "26137": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BIG SPRINGS"
  },
  "26138": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BROHARD"
  },
  "26141": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CRESTON"
  },
  "26142": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DAVISVILLE"
  },
  "26143": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ELIZABETH"
  },
  "26146": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FRIENDLY"
  },
  "26147": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GRANTSVILLE"
  },
  "26148": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MACFARLAN"
  },
  "26149": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MIDDLEBOURNE"
  },
  "26150": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MINERAL WELLS"
  },
  "26151": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MOUNT ZION"
  },
  "26152": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MUNDAY"
  },
  "26155": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NEW MARTINSVILLE"
  },
  "26159": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PADEN CITY"
  },
  "26160": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PALESTINE"
  },
  "26161": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PETROLEUM"
  },
  "26162": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PORTERS FALLS"
  },
  "26164": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RAVENSWOOD"
  },
  "26167": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "READER"
  },
  "26169": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ROCKPORT"
  },
  "26170": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SAINT MARYS"
  },
  "26175": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SISTERSVILLE"
  },
  "26178": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SMITHVILLE"
  },
  "26180": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WALKER"
  },
  "26181": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WASHINGTON"
  },
  "26184": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WAVERLY"
  },
  "26187": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WILLIAMSTOWN"
  },
  "26201": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BUCKHANNON"
  },
  "26202": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FENWICK"
  },
  "26203": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ERBACON"
  },
  "26205": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CRAIGSVILLE"
  },
  "26206": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "COWEN"
  },
  "26208": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CAMDEN ON GAULEY"
  },
  "26209": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SNOWSHOE"
  },
  "26210": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ADRIAN"
  },
  "26215": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CLEVELAND"
  },
  "26217": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DIANA"
  },
  "26218": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FRENCH CREEK"
  },
  "26222": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HACKER VALLEY"
  },
  "26224": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HELVETIA"
  },
  "26228": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "KANAWHA HEAD"
  },
  "26230": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PICKENS"
  },
  "26234": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ROCK CAVE"
  },
  "26236": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SELBYVILLE"
  },
  "26237": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "TALLMANSVILLE"
  },
  "26238": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "VOLGA"
  },
  "26241": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ELKINS"
  },
  "26250": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BELINGTON"
  },
  "26253": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BEVERLY"
  },
  "26254": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BOWDEN"
  },
  "26257": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "COALTON"
  },
  "26259": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DAILEY"
  },
  "26260": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DAVIS"
  },
  "26261": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RICHWOOD"
  },
  "26263": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DRYFORK"
  },
  "26264": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DURBIN"
  },
  "26266": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "UPPERGLADE"
  },
  "26267": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ELLAMORE"
  },
  "26268": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GLADY"
  },
  "26269": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HAMBLETON"
  },
  "26270": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HARMAN"
  },
  "26271": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HENDRICKS"
  },
  "26273": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HUTTONSVILLE"
  },
  "26275": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "JUNIOR"
  },
  "26276": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "KERENS"
  },
  "26278": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MABIE"
  },
  "26280": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MILL CREEK"
  },
  "26282": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MONTERVILLE"
  },
  "26283": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MONTROSE"
  },
  "26285": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NORTON"
  },
  "26287": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PARSONS"
  },
  "26288": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WEBSTER SPRINGS"
  },
  "26289": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RED CREEK"
  },
  "26291": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SLATYFORK"
  },
  "26292": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "THOMAS"
  },
  "26293": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "VALLEY BEND"
  },
  "26294": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "VALLEY HEAD"
  },
  "26296": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WHITMER"
  },
  "26298": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BERGOO"
  },
  "26301": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CLARKSBURG"
  },
  "26302": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CLARKSBURG"
  },
  "26320": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ALMA"
  },
  "26321": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ALUM BRIDGE"
  },
  "26323": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ANMOORE"
  },
  "26325": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "AUBURN"
  },
  "26327": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BEREA"
  },
  "26330": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BRIDGEPORT"
  },
  "26335": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BURNSVILLE"
  },
  "26337": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CAIRO"
  },
  "26338": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CAMDEN"
  },
  "26339": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CENTER POINT"
  },
  "26342": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "COXS MILLS"
  },
  "26343": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CRAWFORD"
  },
  "26346": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ELLENBORO"
  },
  "26347": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FLEMINGTON"
  },
  "26348": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FOLSOM"
  },
  "26349": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GALLOWAY"
  },
  "26351": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GLENVILLE"
  },
  "26354": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GRAFTON"
  },
  "26361": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GYPSY"
  },
  "26362": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HARRISVILLE"
  },
  "26366": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HAYWOOD"
  },
  "26369": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HEPZIBAH"
  },
  "26372": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HORNER"
  },
  "26374": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "INDEPENDENCE"
  },
  "26376": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "IRELAND"
  },
  "26377": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "JACKSONBURG"
  },
  "26378": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "JANE LEW"
  },
  "26384": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LINN"
  },
  "26385": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LOST CREEK"
  },
  "26386": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LUMBERPORT"
  },
  "26404": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MEADOWBROOK"
  },
  "26405": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MOATSVILLE"
  },
  "26408": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MOUNT CLARE"
  },
  "26410": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NEWBURG"
  },
  "26411": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NEW MILTON"
  },
  "26412": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ORLANDO"
  },
  "26415": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PENNSBORO"
  },
  "26416": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PHILIPPI"
  },
  "26419": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PINE GROVE"
  },
  "26421": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PULLMAN"
  },
  "26422": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "REYNOLDSVILLE"
  },
  "26424": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ROSEMONT"
  },
  "26425": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ROWLESBURG"
  },
  "26426": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SALEM"
  },
  "26430": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SAND FORK"
  },
  "26431": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SHINNSTON"
  },
  "26435": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SIMPSON"
  },
  "26436": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SMITHBURG"
  },
  "26437": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SMITHFIELD"
  },
  "26438": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SPELTER"
  },
  "26440": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "THORNTON"
  },
  "26443": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "TROY"
  },
  "26444": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "TUNNELTON"
  },
  "26447": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WALKERSVILLE"
  },
  "26448": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WALLACE"
  },
  "26451": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WEST MILFORD"
  },
  "26452": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WESTON"
  },
  "26456": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WEST UNION"
  },
  "26463": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WYATT"
  },
  "26501": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MORGANTOWN"
  },
  "26505": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MORGANTOWN"
  },
  "26508": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MORGANTOWN"
  },
  "26519": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ALBRIGHT"
  },
  "26520": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ARTHURDALE"
  },
  "26521": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BLACKSVILLE"
  },
  "26525": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BRUCETON MILLS"
  },
  "26531": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DELLSLOW"
  },
  "26534": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GRANVILLE"
  },
  "26537": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "KINGWOOD"
  },
  "26541": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MAIDSVILLE"
  },
  "26542": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MASONTOWN"
  },
  "26543": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "OSAGE"
  },
  "26544": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PENTRESS"
  },
  "26547": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "REEDSVILLE"
  },
  "26554": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FAIRMONT"
  },
  "26559": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BARRACKVILLE"
  },
  "26562": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BURTON"
  },
  "26563": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CAROLINA"
  },
  "26568": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ENTERPRISE"
  },
  "26570": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FAIRVIEW"
  },
  "26571": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FARMINGTON"
  },
  "26572": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FOUR STATES"
  },
  "26574": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GRANT TOWN"
  },
  "26575": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HUNDRED"
  },
  "26576": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "IDAMAY"
  },
  "26581": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LITTLETON"
  },
  "26582": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MANNINGTON"
  },
  "26585": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "METZ"
  },
  "26586": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MONTANA MINES"
  },
  "26587": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RACHEL"
  },
  "26588": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RIVESVILLE"
  },
  "26590": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WANA"
  },
  "26591": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WORTHINGTON"
  },
  "26601": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SUTTON"
  },
  "26610": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BIRCH RIVER"
  },
  "26611": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CEDARVILLE"
  },
  "26615": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "COPEN"
  },
  "26617": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DILLE"
  },
  "26619": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "EXCHANGE"
  },
  "26621": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FLATWOODS"
  },
  "26623": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FRAMETOWN"
  },
  "26624": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GASSAWAY"
  },
  "26627": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HEATERS"
  },
  "26629": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LITTLE BIRCH"
  },
  "26631": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NAPIER"
  },
  "26636": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ROSEDALE"
  },
  "26651": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SUMMERSVILLE"
  },
  "26656": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BELVA"
  },
  "26660": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CALVIN"
  },
  "26662": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CANVAS"
  },
  "26667": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DRENNEN"
  },
  "26671": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GILBOA"
  },
  "26676": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LEIVASY"
  },
  "26678": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MOUNT LOOKOUT"
  },
  "26679": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MOUNT NEBO"
  },
  "26680": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NALLEN"
  },
  "26681": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NETTIE"
  },
  "26684": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "POOL"
  },
  "26690": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SWISS"
  },
  "26691": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "TIOGA"
  },
  "26704": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "AUGUSTA"
  },
  "26705": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "AURORA"
  },
  "26707": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BAYARD"
  },
  "26710": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BURLINGTON"
  },
  "26711": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CAPON BRIDGE"
  },
  "26714": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "DELRAY"
  },
  "26716": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "EGLON"
  },
  "26717": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ELK GARDEN"
  },
  "26719": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FORT ASHBY"
  },
  "26720": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GORMANIA"
  },
  "26722": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "GREEN SPRING"
  },
  "26726": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "KEYSER"
  },
  "26731": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LAHMANSVILLE"
  },
  "26739": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MOUNT STORM"
  },
  "26743": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "NEW CREEK"
  },
  "26750": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PIEDMONT"
  },
  "26753": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RIDGELEY"
  },
  "26755": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RIO"
  },
  "26757": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ROMNEY"
  },
  "26761": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SHANKS"
  },
  "26763": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SPRINGFIELD"
  },
  "26764": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "TERRA ALTA"
  },
  "26767": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WILEY FORD"
  },
  "26801": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BAKER"
  },
  "26802": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BRANDYWINE"
  },
  "26804": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CIRCLEVILLE"
  },
  "26807": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FRANKLIN"
  },
  "26808": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "HIGH VIEW"
  },
  "26810": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "LOST CITY"
  },
  "26812": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MATHIAS"
  },
  "26814": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "RIVERTON"
  },
  "26815": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SUGAR GROVE"
  },
  "26817": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "BLOOMERY"
  },
  "26818": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "FISHER"
  },
  "26823": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CAPON SPRINGS"
  },
  "26833": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MAYSVILLE"
  },
  "26836": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MOOREFIELD"
  },
  "26838": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "MILAM"
  },
  "26845": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "OLD FIELDS"
  },
  "26847": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PETERSBURG"
  },
  "26851": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "WARDENSVILLE"
  },
  "26852": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "PURGITSVILLE"
  },
  "26855": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "CABINS"
  },
  "26865": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "YELLOW SPRING"
  },
  "26866": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "UPPER TRACT"
  },
  "26884": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "SENECA ROCKS"
  },
  "26886": {
    stateCode: "WV",
    stateName: "West Virginia",
    city: "ONEGO"
  },
  "27006": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ADVANCE"
  },
  "27007": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ARARAT"
  },
  "27009": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BELEWS CREEK"
  },
  "27011": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BOONVILLE"
  },
  "27012": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CLEMMONS"
  },
  "27013": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CLEVELAND"
  },
  "27014": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "COOLEEMEE"
  },
  "27016": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DANBURY"
  },
  "27017": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DOBSON"
  },
  "27018": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "EAST BEND"
  },
  "27019": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GERMANTON"
  },
  "27020": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HAMPTONVILLE"
  },
  "27021": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "KING"
  },
  "27022": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LAWSONVILLE"
  },
  "27023": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LEWISVILLE"
  },
  "27024": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LOWGAP"
  },
  "27025": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MADISON"
  },
  "27027": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MAYODAN"
  },
  "27028": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MOCKSVILLE"
  },
  "27030": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MOUNT AIRY"
  },
  "27040": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PFAFFTOWN"
  },
  "27041": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PILOT MOUNTAIN"
  },
  "27042": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PINE HALL"
  },
  "27043": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PINNACLE"
  },
  "27045": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RURAL HALL"
  },
  "27046": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SANDY RIDGE"
  },
  "27047": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SILOAM"
  },
  "27048": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "STONEVILLE"
  },
  "27050": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TOBACCOVILLE"
  },
  "27051": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WALKERTOWN"
  },
  "27052": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WALNUT COVE"
  },
  "27053": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WESTFIELD"
  },
  "27054": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WOODLEAF"
  },
  "27055": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "YADKINVILLE"
  },
  "27101": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WINSTON SALEM"
  },
  "27103": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WINSTON SALEM"
  },
  "27104": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WINSTON SALEM"
  },
  "27105": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WINSTON SALEM"
  },
  "27106": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WINSTON SALEM"
  },
  "27107": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WINSTON SALEM"
  },
  "27127": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WINSTON SALEM"
  },
  "27202": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ALTAMAHAW"
  },
  "27203": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ASHEBORO"
  },
  "27207": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BEAR CREEK"
  },
  "27208": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BENNETT"
  },
  "27209": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BISCOE"
  },
  "27212": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BLANCH"
  },
  "27214": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BROWNS SUMMIT"
  },
  "27215": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BURLINGTON"
  },
  "27217": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BURLINGTON"
  },
  "27229": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CANDOR"
  },
  "27231": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CEDAR GROVE"
  },
  "27233": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CLIMAX"
  },
  "27235": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "COLFAX"
  },
  "27239": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DENTON"
  },
  "27242": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "EAGLE SPRINGS"
  },
  "27243": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "EFLAND"
  },
  "27244": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ELON"
  },
  "27248": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FRANKLINVILLE"
  },
  "27249": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GIBSONVILLE"
  },
  "27252": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GOLDSTON"
  },
  "27253": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GRAHAM"
  },
  "27256": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GULF"
  },
  "27258": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HAW RIVER"
  },
  "27260": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HIGH POINT"
  },
  "27262": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HIGH POINT"
  },
  "27263": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HIGH POINT"
  },
  "27265": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HIGH POINT"
  },
  "27278": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HILLSBOROUGH"
  },
  "27281": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "JACKSON SPRINGS"
  },
  "27282": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "JAMESTOWN"
  },
  "27283": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "JULIAN"
  },
  "27284": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "KERNERSVILLE"
  },
  "27288": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "EDEN"
  },
  "27291": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LEASBURG"
  },
  "27292": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LEXINGTON"
  },
  "27295": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LEXINGTON"
  },
  "27298": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LIBERTY"
  },
  "27299": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LINWOOD"
  },
  "27301": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MC LEANSVILLE"
  },
  "27302": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MEBANE"
  },
  "27305": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MILTON"
  },
  "27306": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MOUNT GILEAD"
  },
  "27310": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "OAK RIDGE"
  },
  "27311": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PELHAM"
  },
  "27312": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PITTSBORO"
  },
  "27313": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PLEASANT GARDEN"
  },
  "27314": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PROSPECT HILL"
  },
  "27315": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PROVIDENCE"
  },
  "27316": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RAMSEUR"
  },
  "27317": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RANDLEMAN"
  },
  "27320": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "REIDSVILLE"
  },
  "27325": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROBBINS"
  },
  "27326": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RUFFIN"
  },
  "27330": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SANFORD"
  },
  "27341": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SEAGROVE"
  },
  "27342": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SEDALIA"
  },
  "27343": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SEMORA"
  },
  "27344": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SILER CITY"
  },
  "27349": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SNOW CAMP"
  },
  "27350": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SOPHIA"
  },
  "27355": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "STALEY"
  },
  "27356": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "STAR"
  },
  "27357": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "STOKESDALE"
  },
  "27358": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SUMMERFIELD"
  },
  "27360": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "THOMASVILLE"
  },
  "27370": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TRINITY"
  },
  "27371": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TROY"
  },
  "27376": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WEST END"
  },
  "27377": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WHITSETT"
  },
  "27379": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "YANCEYVILLE"
  },
  "27401": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GREENSBORO"
  },
  "27403": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GREENSBORO"
  },
  "27405": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GREENSBORO"
  },
  "27406": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GREENSBORO"
  },
  "27407": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GREENSBORO"
  },
  "27408": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GREENSBORO"
  },
  "27409": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GREENSBORO"
  },
  "27410": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GREENSBORO"
  },
  "27455": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GREENSBORO"
  },
  "27501": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ANGIER"
  },
  "27502": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "APEX"
  },
  "27503": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BAHAMA"
  },
  "27504": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BENSON"
  },
  "27505": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BROADWAY"
  },
  "27506": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BUIES CREEK"
  },
  "27507": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BULLOCK"
  },
  "27508": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BUNN"
  },
  "27509": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BUTNER"
  },
  "27510": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CARRBORO"
  },
  "27511": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CARY"
  },
  "27513": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CARY"
  },
  "27514": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHAPEL HILL"
  },
  "27516": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHAPEL HILL"
  },
  "27520": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CLAYTON"
  },
  "27521": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "COATS"
  },
  "27522": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CREEDMOOR"
  },
  "27524": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FOUR OAKS"
  },
  "27525": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FRANKLINTON"
  },
  "27526": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FUQUAY VARINA"
  },
  "27529": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GARNER"
  },
  "27530": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GOLDSBORO"
  },
  "27534": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GOLDSBORO"
  },
  "27536": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HENDERSON"
  },
  "27540": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HOLLY SPRINGS"
  },
  "27541": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HURDLE MILLS"
  },
  "27542": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "KENLY"
  },
  "27544": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "KITTRELL"
  },
  "27545": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "KNIGHTDALE"
  },
  "27546": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LILLINGTON"
  },
  "27549": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LOUISBURG"
  },
  "27551": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MACON"
  },
  "27553": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MANSON"
  },
  "27555": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MICRO"
  },
  "27557": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MIDDLESEX"
  },
  "27559": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MONCURE"
  },
  "27560": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MORRISVILLE"
  },
  "27562": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "NEW HILL"
  },
  "27563": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "NORLINA"
  },
  "27565": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "OXFORD"
  },
  "27568": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PINE LEVEL"
  },
  "27569": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PRINCETON"
  },
  "27570": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RIDGEWAY"
  },
  "27571": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROLESVILLE"
  },
  "27572": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROUGEMONT"
  },
  "27573": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROXBORO"
  },
  "27576": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SELMA"
  },
  "27577": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SMITHFIELD"
  },
  "27581": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "STEM"
  },
  "27582": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "STOVALL"
  },
  "27583": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TIMBERLAKE"
  },
  "27584": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TOWNSVILLE"
  },
  "27587": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WAKE FOREST"
  },
  "27589": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WARRENTON"
  },
  "27591": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WENDELL"
  },
  "27592": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WILLOW SPRING"
  },
  "27596": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "YOUNGSVILLE"
  },
  "27597": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ZEBULON"
  },
  "27601": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RALEIGH"
  },
  "27603": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RALEIGH"
  },
  "27604": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RALEIGH"
  },
  "27605": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RALEIGH"
  },
  "27606": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RALEIGH"
  },
  "27607": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RALEIGH"
  },
  "27608": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RALEIGH"
  },
  "27609": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RALEIGH"
  },
  "27610": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RALEIGH"
  },
  "27612": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RALEIGH"
  },
  "27613": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RALEIGH"
  },
  "27614": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RALEIGH"
  },
  "27615": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RALEIGH"
  },
  "27616": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RALEIGH"
  },
  "27701": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DURHAM"
  },
  "27703": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DURHAM"
  },
  "27704": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DURHAM"
  },
  "27705": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DURHAM"
  },
  "27706": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DURHAM"
  },
  "27707": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DURHAM"
  },
  "27712": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DURHAM"
  },
  "27713": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DURHAM"
  },
  "27801": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROCKY MOUNT"
  },
  "27803": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROCKY MOUNT"
  },
  "27804": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROCKY MOUNT"
  },
  "27805": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "AULANDER"
  },
  "27806": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "AURORA"
  },
  "27807": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BAILEY"
  },
  "27808": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BATH"
  },
  "27809": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BATTLEBORO"
  },
  "27810": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BELHAVEN"
  },
  "27812": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BETHEL"
  },
  "27813": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BLACK CREEK"
  },
  "27814": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BLOUNTS CREEK"
  },
  "27816": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CASTALIA"
  },
  "27817": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHOCOWINITY"
  },
  "27818": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "COMO"
  },
  "27819": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CONETOE"
  },
  "27820": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CONWAY"
  },
  "27821": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "EDWARD"
  },
  "27822": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ELM CITY"
  },
  "27823": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ENFIELD"
  },
  "27824": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ENGELHARD"
  },
  "27826": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FAIRFIELD"
  },
  "27827": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FALKLAND"
  },
  "27828": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FARMVILLE"
  },
  "27829": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FOUNTAIN"
  },
  "27830": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FREMONT"
  },
  "27831": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GARYSBURG"
  },
  "27832": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GASTON"
  },
  "27834": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GREENVILLE"
  },
  "27837": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GRIMESLAND"
  },
  "27839": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HALIFAX"
  },
  "27840": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HAMILTON"
  },
  "27841": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HASSELL"
  },
  "27842": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HENRICO"
  },
  "27843": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HOBGOOD"
  },
  "27844": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HOLLISTER"
  },
  "27845": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "JACKSON"
  },
  "27846": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "JAMESVILLE"
  },
  "27847": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "KELFORD"
  },
  "27849": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LEWISTON WOODVILLE"
  },
  "27850": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LITTLETON"
  },
  "27851": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LUCAMA"
  },
  "27852": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MACCLESFIELD"
  },
  "27853": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MARGARETTSVILLE"
  },
  "27855": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MURFREESBORO"
  },
  "27856": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "NASHVILLE"
  },
  "27857": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "OAK CITY"
  },
  "27858": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GREENVILLE"
  },
  "27860": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PANTEGO"
  },
  "27862": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PENDLETON"
  },
  "27863": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PIKEVILLE"
  },
  "27864": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PINETOPS"
  },
  "27865": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PINETOWN"
  },
  "27866": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PLEASANT HILL"
  },
  "27869": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RICH SQUARE"
  },
  "27870": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROANOKE RAPIDS"
  },
  "27871": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROBERSONVILLE"
  },
  "27872": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROXOBEL"
  },
  "27873": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SARATOGA"
  },
  "27874": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SCOTLAND NECK"
  },
  "27875": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SCRANTON"
  },
  "27876": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SEABOARD"
  },
  "27877": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SEVERN"
  },
  "27878": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SHARPSBURG"
  },
  "27880": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SIMS"
  },
  "27882": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SPRING HOPE"
  },
  "27883": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "STANTONSBURG"
  },
  "27884": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "STOKES"
  },
  "27885": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SWANQUARTER"
  },
  "27886": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TARBORO"
  },
  "27888": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WALSTONBURG"
  },
  "27889": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WASHINGTON"
  },
  "27890": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WELDON"
  },
  "27891": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WHITAKERS"
  },
  "27892": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WILLIAMSTON"
  },
  "27893": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WILSON"
  },
  "27896": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WILSON"
  },
  "27897": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WOODLAND"
  },
  "27909": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ELIZABETH CITY"
  },
  "27910": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "AHOSKIE"
  },
  "27915": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "AVON"
  },
  "27916": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "AYDLETT"
  },
  "27917": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BARCO"
  },
  "27919": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BELVIDERE"
  },
  "27920": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BUXTON"
  },
  "27921": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CAMDEN"
  },
  "27922": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "COFIELD"
  },
  "27923": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "COINJOCK"
  },
  "27924": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "COLERAIN"
  },
  "27925": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "COLUMBIA"
  },
  "27926": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CORAPEAKE"
  },
  "27927": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "COROLLA"
  },
  "27928": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CRESWELL"
  },
  "27929": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CURRITUCK"
  },
  "27932": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "EDENTON"
  },
  "27935": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "EURE"
  },
  "27936": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FRISCO"
  },
  "27937": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GATES"
  },
  "27938": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GATESVILLE"
  },
  "27939": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GRANDY"
  },
  "27941": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HARBINGER"
  },
  "27942": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HARRELLSVILLE"
  },
  "27943": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HATTERAS"
  },
  "27944": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HERTFORD"
  },
  "27946": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HOBBSVILLE"
  },
  "27947": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "JARVISBURG"
  },
  "27948": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "KILL DEVIL HILLS"
  },
  "27949": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "KITTY HAWK"
  },
  "27950": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "KNOTTS ISLAND"
  },
  "27953": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MANNS HARBOR"
  },
  "27954": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MANTEO"
  },
  "27956": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MAPLE"
  },
  "27957": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MERRY HILL"
  },
  "27958": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MOYOCK"
  },
  "27959": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "NAGS HEAD"
  },
  "27960": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "OCRACOKE"
  },
  "27962": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PLYMOUTH"
  },
  "27964": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "POINT HARBOR"
  },
  "27965": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "POPLAR BRANCH"
  },
  "27966": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "POWELLS POINT"
  },
  "27967": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "POWELLSVILLE"
  },
  "27968": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RODANTHE"
  },
  "27970": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROPER"
  },
  "27972": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SALVO"
  },
  "27973": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SHAWBORO"
  },
  "27974": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SHILOH"
  },
  "27976": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SOUTH MILLS"
  },
  "27979": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SUNBURY"
  },
  "27980": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TYNER"
  },
  "27981": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WANCHESE"
  },
  "27982": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WAVES"
  },
  "27983": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WINDSOR"
  },
  "27985": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WINFALL"
  },
  "27986": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WINTON"
  },
  "28001": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ALBEMARLE"
  },
  "28006": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ALEXIS"
  },
  "28007": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ANSONVILLE"
  },
  "28009": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BADIN"
  },
  "28012": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BELMONT"
  },
  "28016": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BESSEMER CITY"
  },
  "28017": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BOILING SPRINGS"
  },
  "28018": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BOSTIC"
  },
  "28019": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CAROLEEN"
  },
  "28020": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CASAR"
  },
  "28021": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHERRYVILLE"
  },
  "28023": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHINA GROVE"
  },
  "28025": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CONCORD"
  },
  "28027": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CONCORD"
  },
  "28031": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CORNELIUS"
  },
  "28032": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CRAMERTON"
  },
  "28033": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CROUSE"
  },
  "28034": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DALLAS"
  },
  "28036": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DAVIDSON"
  },
  "28037": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DENVER"
  },
  "28038": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "EARL"
  },
  "28040": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ELLENBORO"
  },
  "28043": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FOREST CITY"
  },
  "28052": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GASTONIA"
  },
  "28054": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GASTONIA"
  },
  "28056": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GASTONIA"
  },
  "28071": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GOLD HILL"
  },
  "28072": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GRANITE QUARRY"
  },
  "28073": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GROVER"
  },
  "28075": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HARRISBURG"
  },
  "28076": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HENRIETTA"
  },
  "28077": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HIGH SHOALS"
  },
  "28078": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HUNTERSVILLE"
  },
  "28079": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "INDIAN TRAIL"
  },
  "28080": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "IRON STATION"
  },
  "28081": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "KANNAPOLIS"
  },
  "28083": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "KANNAPOLIS"
  },
  "28086": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "KINGS MOUNTAIN"
  },
  "28088": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LANDIS"
  },
  "28089": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LATTIMORE"
  },
  "28090": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LAWNDALE"
  },
  "28091": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LILESVILLE"
  },
  "28092": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LINCOLNTON"
  },
  "28097": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LOCUST"
  },
  "28098": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LOWELL"
  },
  "28101": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MC ADENVILLE"
  },
  "28102": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MC FARLAN"
  },
  "28103": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MARSHVILLE"
  },
  "28104": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MATTHEWS"
  },
  "28105": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MATTHEWS"
  },
  "28107": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MIDLAND"
  },
  "28109": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MISENHEIMER"
  },
  "28110": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MONROE"
  },
  "28112": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MONROE"
  },
  "28114": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MOORESBORO"
  },
  "28115": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MOORESVILLE"
  },
  "28117": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MOORESVILLE"
  },
  "28119": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MORVEN"
  },
  "28120": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MOUNT HOLLY"
  },
  "28124": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MOUNT PLEASANT"
  },
  "28125": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MOUNT ULLA"
  },
  "28127": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "NEW LONDON"
  },
  "28128": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "NORWOOD"
  },
  "28129": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "OAKBORO"
  },
  "28133": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PEACHLAND"
  },
  "28134": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PINEVILLE"
  },
  "28135": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "POLKTON"
  },
  "28137": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RICHFIELD"
  },
  "28138": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROCKWELL"
  },
  "28139": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RUTHERFORDTON"
  },
  "28144": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SALISBURY"
  },
  "28146": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SALISBURY"
  },
  "28147": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SALISBURY"
  },
  "28150": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SHELBY"
  },
  "28152": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SHELBY"
  },
  "28159": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SPENCER"
  },
  "28160": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SPINDALE"
  },
  "28163": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "STANFIELD"
  },
  "28164": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "STANLEY"
  },
  "28166": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TROUTMAN"
  },
  "28167": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "UNION MILLS"
  },
  "28168": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "VALE"
  },
  "28169": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WACO"
  },
  "28170": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WADESBORO"
  },
  "28173": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WAXHAW"
  },
  "28174": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WINGATE"
  },
  "28202": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28203": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28204": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28205": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28206": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28207": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28208": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28209": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28210": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28211": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28212": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28213": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28214": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28215": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28216": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28217": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28226": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28227": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28262": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28269": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28270": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28273": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28277": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28278": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHARLOTTE"
  },
  "28301": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FAYETTEVILLE"
  },
  "28303": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FAYETTEVILLE"
  },
  "28304": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FAYETTEVILLE"
  },
  "28305": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FAYETTEVILLE"
  },
  "28306": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FAYETTEVILLE"
  },
  "28307": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FORT BRAGG"
  },
  "28311": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FAYETTEVILLE"
  },
  "28314": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FAYETTEVILLE"
  },
  "28315": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ABERDEEN"
  },
  "28318": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "AUTRYVILLE"
  },
  "28320": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BLADENBORO"
  },
  "28323": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BUNNLEVEL"
  },
  "28325": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CALYPSO"
  },
  "28326": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CAMERON"
  },
  "28327": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CARTHAGE"
  },
  "28328": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CLINTON"
  },
  "28330": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CORDOVA"
  },
  "28332": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DUBLIN"
  },
  "28333": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DUDLEY"
  },
  "28334": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DUNN"
  },
  "28337": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ELIZABETHTOWN"
  },
  "28338": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ELLERBE"
  },
  "28339": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ERWIN"
  },
  "28340": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FAIRMONT"
  },
  "28341": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FAISON"
  },
  "28342": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FALCON"
  },
  "28343": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GIBSON"
  },
  "28344": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GODWIN"
  },
  "28345": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HAMLET"
  },
  "28347": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HOFFMAN"
  },
  "28348": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HOPE MILLS"
  },
  "28349": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "KENANSVILLE"
  },
  "28350": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LAKEVIEW"
  },
  "28351": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LAUREL HILL"
  },
  "28352": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LAURINBURG"
  },
  "28356": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LINDEN"
  },
  "28357": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LUMBER BRIDGE"
  },
  "28358": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LUMBERTON"
  },
  "28360": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LUMBERTON"
  },
  "28362": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MARIETTA"
  },
  "28363": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MARSTON"
  },
  "28364": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MAXTON"
  },
  "28365": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MOUNT OLIVE"
  },
  "28366": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "NEWTON GROVE"
  },
  "28367": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "NORMAN"
  },
  "28368": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "OLIVIA"
  },
  "28369": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ORRUM"
  },
  "28371": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PARKTON"
  },
  "28372": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PEMBROKE"
  },
  "28373": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PINEBLUFF"
  },
  "28374": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PINEHURST"
  },
  "28376": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RAEFORD"
  },
  "28377": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RED SPRINGS"
  },
  "28378": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "REX"
  },
  "28379": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROCKINGHAM"
  },
  "28382": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROSEBORO"
  },
  "28383": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROWLAND"
  },
  "28384": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SAINT PAULS"
  },
  "28385": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SALEMBURG"
  },
  "28386": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SHANNON"
  },
  "28387": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SOUTHERN PINES"
  },
  "28390": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SPRING LAKE"
  },
  "28391": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "STEDMAN"
  },
  "28392": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TAR HEEL"
  },
  "28393": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TURKEY"
  },
  "28394": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "VASS"
  },
  "28395": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WADE"
  },
  "28396": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WAGRAM"
  },
  "28398": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WARSAW"
  },
  "28399": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WHITE OAK"
  },
  "28401": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WILMINGTON"
  },
  "28403": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WILMINGTON"
  },
  "28405": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WILMINGTON"
  },
  "28409": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WILMINGTON"
  },
  "28411": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WILMINGTON"
  },
  "28412": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WILMINGTON"
  },
  "28420": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ASH"
  },
  "28421": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ATKINSON"
  },
  "28422": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BOLIVIA"
  },
  "28423": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BOLTON"
  },
  "28425": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BURGAW"
  },
  "28428": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CAROLINA BEACH"
  },
  "28429": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CASTLE HAYNE"
  },
  "28430": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CERRO GORDO"
  },
  "28431": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHADBOURN"
  },
  "28432": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CLARENDON"
  },
  "28433": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CLARKTON"
  },
  "28434": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "COUNCIL"
  },
  "28435": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CURRIE"
  },
  "28436": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DELCO"
  },
  "28438": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "EVERGREEN"
  },
  "28439": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FAIR BLUFF"
  },
  "28441": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GARLAND"
  },
  "28442": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HALLSBORO"
  },
  "28443": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HAMPSTEAD"
  },
  "28444": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HARRELLS"
  },
  "28445": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HOLLY RIDGE"
  },
  "28447": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "IVANHOE"
  },
  "28448": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "KELLY"
  },
  "28449": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "KURE BEACH"
  },
  "28450": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LAKE WACCAMAW"
  },
  "28451": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LELAND"
  },
  "28452": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LONGWOOD"
  },
  "28453": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MAGNOLIA"
  },
  "28454": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MAPLE HILL"
  },
  "28455": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "NAKINA"
  },
  "28456": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RIEGELWOOD"
  },
  "28457": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROCKY POINT"
  },
  "28458": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROSE HILL"
  },
  "28460": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SNEADS FERRY"
  },
  "28461": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SOUTHPORT"
  },
  "28462": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SUPPLY"
  },
  "28463": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TABOR CITY"
  },
  "28464": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TEACHEY"
  },
  "28465": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "OAK ISLAND"
  },
  "28466": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WALLACE"
  },
  "28467": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CALABASH"
  },
  "28468": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SUNSET BEACH"
  },
  "28469": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "OCEAN ISLE BEACH"
  },
  "28470": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SHALLOTTE"
  },
  "28472": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WHITEVILLE"
  },
  "28478": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WILLARD"
  },
  "28479": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WINNABOW"
  },
  "28480": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WRIGHTSVILLE BEACH"
  },
  "28501": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "KINSTON"
  },
  "28504": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "KINSTON"
  },
  "28508": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ALBERTSON"
  },
  "28510": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ARAPAHOE"
  },
  "28511": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ATLANTIC"
  },
  "28512": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ATLANTIC BEACH"
  },
  "28513": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "AYDEN"
  },
  "28515": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BAYBORO"
  },
  "28516": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BEAUFORT"
  },
  "28518": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BEULAVILLE"
  },
  "28519": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BRIDGETON"
  },
  "28520": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CEDAR ISLAND"
  },
  "28521": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHINQUAPIN"
  },
  "28523": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "COVE CITY"
  },
  "28524": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DAVIS"
  },
  "28525": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DEEP RUN"
  },
  "28526": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DOVER"
  },
  "28527": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ERNUL"
  },
  "28528": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GLOUCESTER"
  },
  "28529": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GRANTSBORO"
  },
  "28530": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GRIFTON"
  },
  "28531": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HARKERS ISLAND"
  },
  "28532": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HAVELOCK"
  },
  "28537": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HOBUCKEN"
  },
  "28538": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HOOKERTON"
  },
  "28539": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HUBERT"
  },
  "28540": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "JACKSONVILLE"
  },
  "28543": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TARAWA TERRACE"
  },
  "28544": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MIDWAY PARK"
  },
  "28546": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "JACKSONVILLE"
  },
  "28547": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CAMP LEJEUNE"
  },
  "28551": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LA GRANGE"
  },
  "28552": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LOWLAND"
  },
  "28553": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MARSHALLBERG"
  },
  "28554": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MAURY"
  },
  "28555": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MAYSVILLE"
  },
  "28556": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MERRITT"
  },
  "28557": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MOREHEAD CITY"
  },
  "28560": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "NEW BERN"
  },
  "28562": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "NEW BERN"
  },
  "28570": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "NEWPORT"
  },
  "28571": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ORIENTAL"
  },
  "28572": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PINK HILL"
  },
  "28573": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "POLLOCKSVILLE"
  },
  "28574": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RICHLANDS"
  },
  "28575": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SALTER PATH"
  },
  "28577": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SEALEVEL"
  },
  "28578": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SEVEN SPRINGS"
  },
  "28579": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SMYRNA"
  },
  "28580": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SNOW HILL"
  },
  "28581": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "STACY"
  },
  "28582": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "STELLA"
  },
  "28584": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SWANSBORO"
  },
  "28585": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TRENTON"
  },
  "28586": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "VANCEBORO"
  },
  "28589": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WILLISTON"
  },
  "28590": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WINTERVILLE"
  },
  "28594": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "EMERALD ISLE"
  },
  "28601": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HICKORY"
  },
  "28602": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HICKORY"
  },
  "28604": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BANNER ELK"
  },
  "28605": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BLOWING ROCK"
  },
  "28606": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BOOMER"
  },
  "28607": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BOONE"
  },
  "28609": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CATAWBA"
  },
  "28610": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CLAREMONT"
  },
  "28611": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "COLLETTSVILLE"
  },
  "28612": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CONNELLYS SPRINGS"
  },
  "28613": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CONOVER"
  },
  "28615": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CRESTON"
  },
  "28616": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CROSSNORE"
  },
  "28617": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CRUMPLER"
  },
  "28618": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DEEP GAP"
  },
  "28619": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DREXEL"
  },
  "28621": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ELKIN"
  },
  "28622": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ELK PARK"
  },
  "28623": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ENNICE"
  },
  "28624": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FERGUSON"
  },
  "28625": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "STATESVILLE"
  },
  "28626": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FLEETWOOD"
  },
  "28627": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GLADE VALLEY"
  },
  "28629": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GLENDALE SPRINGS"
  },
  "28630": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GRANITE FALLS"
  },
  "28631": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GRASSY CREEK"
  },
  "28634": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HARMONY"
  },
  "28635": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HAYS"
  },
  "28636": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HIDDENITE"
  },
  "28637": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HILDEBRAN"
  },
  "28638": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HUDSON"
  },
  "28640": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "JEFFERSON"
  },
  "28642": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "JONESVILLE"
  },
  "28643": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LANSING"
  },
  "28644": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LAUREL SPRINGS"
  },
  "28645": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LENOIR"
  },
  "28646": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LINVILLE"
  },
  "28649": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MC GRADY"
  },
  "28650": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MAIDEN"
  },
  "28651": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MILLERS CREEK"
  },
  "28654": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MORAVIAN FALLS"
  },
  "28655": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MORGANTON"
  },
  "28657": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "NEWLAND"
  },
  "28658": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "NEWTON"
  },
  "28659": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "NORTH WILKESBORO"
  },
  "28660": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "OLIN"
  },
  "28662": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PINEOLA"
  },
  "28663": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PINEY CREEK"
  },
  "28665": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PURLEAR"
  },
  "28666": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ICARD"
  },
  "28668": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROARING GAP"
  },
  "28669": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROARING RIVER"
  },
  "28670": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RONDA"
  },
  "28671": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "RUTHERFORD COLLEGE"
  },
  "28673": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SHERRILLS FORD"
  },
  "28675": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SPARTA"
  },
  "28676": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "STATE ROAD"
  },
  "28677": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "STATESVILLE"
  },
  "28678": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "STONY POINT"
  },
  "28679": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SUGAR GROVE"
  },
  "28681": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TAYLORSVILLE"
  },
  "28682": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TERRELL"
  },
  "28683": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "THURMOND"
  },
  "28684": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TODD"
  },
  "28685": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TRAPHILL"
  },
  "28689": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "UNION GROVE"
  },
  "28690": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "VALDESE"
  },
  "28692": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "VILAS"
  },
  "28693": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WARRENSVILLE"
  },
  "28694": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WEST JEFFERSON"
  },
  "28697": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WILKESBORO"
  },
  "28698": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ZIONVILLE"
  },
  "28701": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ALEXANDER"
  },
  "28702": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ALMOND"
  },
  "28704": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ARDEN"
  },
  "28705": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BAKERSVILLE"
  },
  "28707": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BALSAM"
  },
  "28708": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BALSAM GROVE"
  },
  "28709": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BARNARDSVILLE"
  },
  "28710": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BAT CAVE"
  },
  "28711": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BLACK MOUNTAIN"
  },
  "28712": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BREVARD"
  },
  "28713": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BRYSON CITY"
  },
  "28714": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BURNSVILLE"
  },
  "28715": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CANDLER"
  },
  "28716": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CANTON"
  },
  "28717": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CASHIERS"
  },
  "28718": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CEDAR MOUNTAIN"
  },
  "28719": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CHEROKEE"
  },
  "28721": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CLYDE"
  },
  "28722": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "COLUMBUS"
  },
  "28723": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "CULLOWHEE"
  },
  "28725": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "DILLSBORO"
  },
  "28726": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "EAST FLAT ROCK"
  },
  "28729": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ETOWAH"
  },
  "28730": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FAIRVIEW"
  },
  "28731": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FLAT ROCK"
  },
  "28732": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FLETCHER"
  },
  "28733": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FONTANA DAM"
  },
  "28734": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "FRANKLIN"
  },
  "28735": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GERTON"
  },
  "28736": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GLENVILLE"
  },
  "28739": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HENDERSONVILLE"
  },
  "28740": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "GREEN MOUNTAIN"
  },
  "28741": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HIGHLANDS"
  },
  "28742": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HORSE SHOE"
  },
  "28743": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HOT SPRINGS"
  },
  "28745": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LAKE JUNALUSKA"
  },
  "28746": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LAKE LURE"
  },
  "28747": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LAKE TOXAWAY"
  },
  "28748": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LEICESTER"
  },
  "28749": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "LITTLE SWITZERLAND"
  },
  "28751": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MAGGIE VALLEY"
  },
  "28752": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MARION"
  },
  "28753": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MARSHALL"
  },
  "28754": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MARS HILL"
  },
  "28755": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MICAVILLE"
  },
  "28756": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MILL SPRING"
  },
  "28757": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MONTREAT"
  },
  "28758": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MOUNTAIN HOME"
  },
  "28761": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "NEBO"
  },
  "28762": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "OLD FORT"
  },
  "28763": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "OTTO"
  },
  "28765": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PENLAND"
  },
  "28766": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PENROSE"
  },
  "28768": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "PISGAH FOREST"
  },
  "28771": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROBBINSVILLE"
  },
  "28772": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ROSMAN"
  },
  "28773": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SALUDA"
  },
  "28774": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SAPPHIRE"
  },
  "28777": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SPRUCE PINE"
  },
  "28778": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SWANNANOA"
  },
  "28779": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "SYLVA"
  },
  "28781": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TOPTON"
  },
  "28782": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TRYON"
  },
  "28783": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TUCKASEGEE"
  },
  "28784": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "TUXEDO"
  },
  "28786": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WAYNESVILLE"
  },
  "28787": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WEAVERVILLE"
  },
  "28789": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WHITTIER"
  },
  "28790": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ZIRCONIA"
  },
  "28791": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HENDERSONVILLE"
  },
  "28792": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HENDERSONVILLE"
  },
  "28801": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ASHEVILLE"
  },
  "28803": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ASHEVILLE"
  },
  "28804": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ASHEVILLE"
  },
  "28805": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ASHEVILLE"
  },
  "28806": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ASHEVILLE"
  },
  "28901": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "ANDREWS"
  },
  "28902": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "BRASSTOWN"
  },
  "28904": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "HAYESVILLE"
  },
  "28905": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MARBLE"
  },
  "28906": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "MURPHY"
  },
  "28909": {
    stateCode: "NC",
    stateName: "North Carolina",
    city: "WARNE"
  },
  "29001": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ALCOLU"
  },
  "29003": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BAMBERG"
  },
  "29006": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BATESBURG"
  },
  "29009": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BETHUNE"
  },
  "29010": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BISHOPVILLE"
  },
  "29014": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BLACKSTOCK"
  },
  "29015": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BLAIR"
  },
  "29016": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BLYTHEWOOD"
  },
  "29018": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BOWMAN"
  },
  "29020": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CAMDEN"
  },
  "29030": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CAMERON"
  },
  "29031": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CARLISLE"
  },
  "29032": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CASSATT"
  },
  "29033": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CAYCE"
  },
  "29036": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CHAPIN"
  },
  "29037": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CHAPPELLS"
  },
  "29038": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "COPE"
  },
  "29039": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CORDOVA"
  },
  "29040": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "DALZELL"
  },
  "29042": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "DENMARK"
  },
  "29044": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "EASTOVER"
  },
  "29045": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ELGIN"
  },
  "29046": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ELLIOTT"
  },
  "29047": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ELLOREE"
  },
  "29048": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "EUTAWVILLE"
  },
  "29051": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GABLE"
  },
  "29052": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GADSDEN"
  },
  "29053": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GASTON"
  },
  "29054": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GILBERT"
  },
  "29055": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GREAT FALLS"
  },
  "29056": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GREELEYVILLE"
  },
  "29058": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "HEATH SPRINGS"
  },
  "29059": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "HOLLY HILL"
  },
  "29061": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "HOPKINS"
  },
  "29062": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "HORATIO"
  },
  "29063": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "IRMO"
  },
  "29065": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "JENKINSVILLE"
  },
  "29067": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "KERSHAW"
  },
  "29069": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LAMAR"
  },
  "29070": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LEESVILLE"
  },
  "29072": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LEXINGTON"
  },
  "29073": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LEXINGTON"
  },
  "29074": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LIBERTY HILL"
  },
  "29075": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LITTLE MOUNTAIN"
  },
  "29078": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LUGOFF"
  },
  "29079": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LYDIA"
  },
  "29080": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LYNCHBURG"
  },
  "29081": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "EHRHARDT"
  },
  "29082": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LODGE"
  },
  "29101": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MC BEE"
  },
  "29102": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MANNING"
  },
  "29104": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MAYESVILLE"
  },
  "29105": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MONETTA"
  },
  "29107": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "NEESES"
  },
  "29108": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "NEWBERRY"
  },
  "29111": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "NEW ZION"
  },
  "29112": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "NORTH"
  },
  "29113": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "NORWAY"
  },
  "29114": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "OLANTA"
  },
  "29115": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ORANGEBURG"
  },
  "29118": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ORANGEBURG"
  },
  "29122": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PEAK"
  },
  "29123": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PELION"
  },
  "29125": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PINEWOOD"
  },
  "29126": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "POMARIA"
  },
  "29127": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PROSPERITY"
  },
  "29128": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "REMBERT"
  },
  "29129": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "RIDGE SPRING"
  },
  "29130": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "RIDGEWAY"
  },
  "29132": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "RION"
  },
  "29133": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ROWESVILLE"
  },
  "29135": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SAINT MATTHEWS"
  },
  "29137": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SALLEY"
  },
  "29138": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SALUDA"
  },
  "29142": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SANTEE"
  },
  "29145": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SILVERSTREET"
  },
  "29146": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SPRINGFIELD"
  },
  "29148": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SUMMERTON"
  },
  "29150": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SUMTER"
  },
  "29152": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SHAW A F B"
  },
  "29153": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SUMTER"
  },
  "29154": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SUMTER"
  },
  "29160": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SWANSEA"
  },
  "29161": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "TIMMONSVILLE"
  },
  "29162": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "TURBEVILLE"
  },
  "29163": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "VANCE"
  },
  "29164": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WAGENER"
  },
  "29166": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WARD"
  },
  "29168": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WEDGEFIELD"
  },
  "29169": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WEST COLUMBIA"
  },
  "29170": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WEST COLUMBIA"
  },
  "29172": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WEST COLUMBIA"
  },
  "29175": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WESTVILLE"
  },
  "29178": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WHITMIRE"
  },
  "29180": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WINNSBORO"
  },
  "29201": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "COLUMBIA"
  },
  "29203": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "COLUMBIA"
  },
  "29204": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "COLUMBIA"
  },
  "29205": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "COLUMBIA"
  },
  "29206": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "COLUMBIA"
  },
  "29209": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "COLUMBIA"
  },
  "29210": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "COLUMBIA"
  },
  "29212": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "COLUMBIA"
  },
  "29223": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "COLUMBIA"
  },
  "29229": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "COLUMBIA"
  },
  "29301": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SPARTANBURG"
  },
  "29302": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SPARTANBURG"
  },
  "29303": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SPARTANBURG"
  },
  "29306": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SPARTANBURG"
  },
  "29307": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SPARTANBURG"
  },
  "29316": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BOILING SPRINGS"
  },
  "29320": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ARCADIA"
  },
  "29321": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BUFFALO"
  },
  "29322": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CAMPOBELLO"
  },
  "29323": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CHESNEE"
  },
  "29325": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CLINTON"
  },
  "29330": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "COWPENS"
  },
  "29332": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CROSS HILL"
  },
  "29334": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "DUNCAN"
  },
  "29335": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ENOREE"
  },
  "29338": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "FINGERVILLE"
  },
  "29340": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GAFFNEY"
  },
  "29341": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GAFFNEY"
  },
  "29349": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "INMAN"
  },
  "29351": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "JOANNA"
  },
  "29353": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "JONESVILLE"
  },
  "29355": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "KINARDS"
  },
  "29356": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LANDRUM"
  },
  "29360": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LAURENS"
  },
  "29364": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LOCKHART"
  },
  "29365": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LYMAN"
  },
  "29369": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MOORE"
  },
  "29370": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MOUNTVILLE"
  },
  "29372": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PACOLET"
  },
  "29373": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PACOLET MILLS"
  },
  "29374": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PAULINE"
  },
  "29375": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "REIDVILLE"
  },
  "29376": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ROEBUCK"
  },
  "29377": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "STARTEX"
  },
  "29379": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "UNION"
  },
  "29384": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WATERLOO"
  },
  "29385": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WELLFORD"
  },
  "29388": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WOODRUFF"
  },
  "29401": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CHARLESTON"
  },
  "29403": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CHARLESTON"
  },
  "29404": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CHARLESTON AFB"
  },
  "29405": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "NORTH CHARLESTON"
  },
  "29406": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CHARLESTON"
  },
  "29407": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CHARLESTON"
  },
  "29412": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CHARLESTON"
  },
  "29414": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CHARLESTON"
  },
  "29418": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "NORTH CHARLESTON"
  },
  "29420": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "NORTH CHARLESTON"
  },
  "29426": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ADAMS RUN"
  },
  "29429": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "AWENDAW"
  },
  "29430": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BETHERA"
  },
  "29431": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BONNEAU"
  },
  "29432": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BRANCHVILLE"
  },
  "29434": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CORDESVILLE"
  },
  "29435": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "COTTAGEVILLE"
  },
  "29436": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CROSS"
  },
  "29437": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "DORCHESTER"
  },
  "29438": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "EDISTO ISLAND"
  },
  "29439": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "FOLLY BEACH"
  },
  "29440": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GEORGETOWN"
  },
  "29445": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GOOSE CREEK"
  },
  "29446": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GREEN POND"
  },
  "29448": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "HARLEYVILLE"
  },
  "29449": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "HOLLYWOOD"
  },
  "29450": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "HUGER"
  },
  "29451": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ISLE OF PALMS"
  },
  "29452": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "JACKSONBORO"
  },
  "29453": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "JAMESTOWN"
  },
  "29455": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "JOHNS ISLAND"
  },
  "29456": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LADSON"
  },
  "29458": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MC CLELLANVILLE"
  },
  "29461": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MONCKS CORNER"
  },
  "29464": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MOUNT PLEASANT"
  },
  "29466": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MOUNT PLEASANT"
  },
  "29468": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PINEVILLE"
  },
  "29469": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PINOPOLIS"
  },
  "29470": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "RAVENEL"
  },
  "29471": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "REEVESVILLE"
  },
  "29472": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "RIDGEVILLE"
  },
  "29474": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ROUND O"
  },
  "29475": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "RUFFIN"
  },
  "29477": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SAINT GEORGE"
  },
  "29479": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SAINT STEPHEN"
  },
  "29481": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SMOAKS"
  },
  "29482": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SULLIVANS ISLAND"
  },
  "29483": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SUMMERVILLE"
  },
  "29485": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SUMMERVILLE"
  },
  "29487": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WADMALAW ISLAND"
  },
  "29488": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WALTERBORO"
  },
  "29492": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CHARLESTON"
  },
  "29493": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WILLIAMS"
  },
  "29501": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "FLORENCE"
  },
  "29505": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "FLORENCE"
  },
  "29506": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "FLORENCE"
  },
  "29510": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ANDREWS"
  },
  "29511": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "AYNOR"
  },
  "29512": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BENNETTSVILLE"
  },
  "29516": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BLENHEIM"
  },
  "29518": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CADES"
  },
  "29519": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CENTENARY"
  },
  "29520": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CHERAW"
  },
  "29525": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CLIO"
  },
  "29526": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CONWAY"
  },
  "29527": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CONWAY"
  },
  "29530": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "COWARD"
  },
  "29532": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "DARLINGTON"
  },
  "29536": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "DILLON"
  },
  "29540": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "DARLINGTON"
  },
  "29541": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "EFFINGHAM"
  },
  "29543": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "FORK"
  },
  "29544": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GALIVANTS FERRY"
  },
  "29545": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GREEN SEA"
  },
  "29546": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GRESHAM"
  },
  "29547": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "HAMER"
  },
  "29550": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "HARTSVILLE"
  },
  "29554": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "HEMINGWAY"
  },
  "29555": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "JOHNSONVILLE"
  },
  "29556": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "KINGSTREE"
  },
  "29560": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LAKE CITY"
  },
  "29563": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LAKE VIEW"
  },
  "29564": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LANE"
  },
  "29565": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LATTA"
  },
  "29566": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LITTLE RIVER"
  },
  "29567": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LITTLE ROCK"
  },
  "29568": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LONGS"
  },
  "29569": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LORIS"
  },
  "29570": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MC COLL"
  },
  "29571": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MARION"
  },
  "29572": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MYRTLE BEACH"
  },
  "29574": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MULLINS"
  },
  "29575": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MYRTLE BEACH"
  },
  "29576": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MURRELLS INLET"
  },
  "29577": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MYRTLE BEACH"
  },
  "29579": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MYRTLE BEACH"
  },
  "29580": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "NESMITH"
  },
  "29581": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "NICHOLS"
  },
  "29582": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "NORTH MYRTLE BEACH"
  },
  "29583": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PAMPLICO"
  },
  "29584": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PATRICK"
  },
  "29585": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PAWLEYS ISLAND"
  },
  "29589": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "RAINS"
  },
  "29590": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SALTERS"
  },
  "29591": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SCRANTON"
  },
  "29592": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SELLERS"
  },
  "29593": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SOCIETY HILL"
  },
  "29594": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "TATUM"
  },
  "29596": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WALLACE"
  },
  "29601": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GREENVILLE"
  },
  "29605": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GREENVILLE"
  },
  "29607": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GREENVILLE"
  },
  "29609": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GREENVILLE"
  },
  "29611": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GREENVILLE"
  },
  "29615": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GREENVILLE"
  },
  "29617": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GREENVILLE"
  },
  "29620": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ABBEVILLE"
  },
  "29621": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ANDERSON"
  },
  "29624": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ANDERSON"
  },
  "29625": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ANDERSON"
  },
  "29626": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ANDERSON"
  },
  "29627": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BELTON"
  },
  "29628": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CALHOUN FALLS"
  },
  "29630": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CENTRAL"
  },
  "29631": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CLEMSON"
  },
  "29635": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CLEVELAND"
  },
  "29638": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "DONALDS"
  },
  "29639": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "DUE WEST"
  },
  "29640": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "EASLEY"
  },
  "29642": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "EASLEY"
  },
  "29643": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "FAIR PLAY"
  },
  "29644": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "FOUNTAIN INN"
  },
  "29645": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GRAY COURT"
  },
  "29646": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GREENWOOD"
  },
  "29649": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GREENWOOD"
  },
  "29650": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GREER"
  },
  "29651": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GREER"
  },
  "29653": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "HODGES"
  },
  "29654": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "HONEA PATH"
  },
  "29655": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "IVA"
  },
  "29656": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LA FRANCE"
  },
  "29657": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LIBERTY"
  },
  "29658": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LONG CREEK"
  },
  "29659": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LOWNDESVILLE"
  },
  "29661": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MARIETTA"
  },
  "29662": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MAULDIN"
  },
  "29664": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MOUNTAIN REST"
  },
  "29665": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "NEWRY"
  },
  "29666": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "NINETY SIX"
  },
  "29667": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "NORRIS"
  },
  "29669": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PELZER"
  },
  "29670": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PENDLETON"
  },
  "29671": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PICKENS"
  },
  "29672": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SENECA"
  },
  "29673": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PIEDMONT"
  },
  "29676": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SALEM"
  },
  "29677": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SANDY SPRINGS"
  },
  "29678": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SENECA"
  },
  "29680": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SIMPSONVILLE"
  },
  "29681": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SIMPSONVILLE"
  },
  "29682": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SIX MILE"
  },
  "29683": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SLATER"
  },
  "29684": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "STARR"
  },
  "29685": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SUNSET"
  },
  "29686": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "TAMASSEE"
  },
  "29687": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "TAYLORS"
  },
  "29689": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "TOWNVILLE"
  },
  "29690": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "TRAVELERS REST"
  },
  "29691": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WALHALLA"
  },
  "29692": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WARE SHOALS"
  },
  "29693": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WESTMINSTER"
  },
  "29696": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WEST UNION"
  },
  "29697": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WILLIAMSTON"
  },
  "29702": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BLACKSBURG"
  },
  "29704": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CATAWBA"
  },
  "29706": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CHESTER"
  },
  "29708": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "FORT MILL"
  },
  "29709": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CHESTERFIELD"
  },
  "29710": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CLOVER"
  },
  "29712": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "EDGEMOOR"
  },
  "29714": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "FORT LAWN"
  },
  "29715": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "FORT MILL"
  },
  "29717": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "HICKORY GROVE"
  },
  "29718": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "JEFFERSON"
  },
  "29720": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LANCASTER"
  },
  "29724": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LANDO"
  },
  "29726": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MC CONNELLS"
  },
  "29727": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MOUNT CROGHAN"
  },
  "29728": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PAGELAND"
  },
  "29729": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "RICHBURG"
  },
  "29730": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ROCK HILL"
  },
  "29732": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ROCK HILL"
  },
  "29741": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "RUBY"
  },
  "29742": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SHARON"
  },
  "29743": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SMYRNA"
  },
  "29745": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "YORK"
  },
  "29801": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "AIKEN"
  },
  "29803": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "AIKEN"
  },
  "29805": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "AIKEN"
  },
  "29809": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "NEW ELLENTON"
  },
  "29810": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ALLENDALE"
  },
  "29812": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BARNWELL"
  },
  "29816": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BATH"
  },
  "29817": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BLACKVILLE"
  },
  "29819": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BRADLEY"
  },
  "29821": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CLARKS HILL"
  },
  "29822": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "CLEARWATER"
  },
  "29824": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "EDGEFIELD"
  },
  "29826": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ELKO"
  },
  "29827": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "FAIRFAX"
  },
  "29828": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GLOVERVILLE"
  },
  "29829": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GRANITEVILLE"
  },
  "29831": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "JACKSON"
  },
  "29832": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "JOHNSTON"
  },
  "29834": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LANGLEY"
  },
  "29835": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MC CORMICK"
  },
  "29836": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MARTIN"
  },
  "29838": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MODOC"
  },
  "29840": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "MOUNT CARMEL"
  },
  "29841": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "NORTH AUGUSTA"
  },
  "29842": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BEECH ISLAND"
  },
  "29843": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "OLAR"
  },
  "29844": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PARKSVILLE"
  },
  "29845": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PLUM BRANCH"
  },
  "29846": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SYCAMORE"
  },
  "29847": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "TRENTON"
  },
  "29848": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "TROY"
  },
  "29849": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ULMER"
  },
  "29850": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "VAUCLUSE"
  },
  "29851": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WARRENVILLE"
  },
  "29853": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WILLISTON"
  },
  "29856": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "WINDSOR"
  },
  "29860": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "NORTH AUGUSTA"
  },
  "29902": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BEAUFORT"
  },
  "29906": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BEAUFORT"
  },
  "29910": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BLUFFTON"
  },
  "29911": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "BRUNSON"
  },
  "29916": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "EARLY BRANCH"
  },
  "29918": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ESTILL"
  },
  "29920": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SAINT HELENA ISLAND"
  },
  "29921": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "FURMAN"
  },
  "29922": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GARNETT"
  },
  "29923": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "GIFFORD"
  },
  "29924": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "HAMPTON"
  },
  "29926": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "HILTON HEAD ISLAND"
  },
  "29927": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "HARDEEVILLE"
  },
  "29928": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "HILTON HEAD ISLAND"
  },
  "29929": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "ISLANDTON"
  },
  "29932": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "LURAY"
  },
  "29934": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PINELAND"
  },
  "29935": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "PORT ROYAL"
  },
  "29936": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "RIDGELAND"
  },
  "29939": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SCOTIA"
  },
  "29940": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "SEABROOK"
  },
  "29943": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "TILLMAN"
  },
  "29944": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "VARNVILLE"
  },
  "29945": {
    stateCode: "SC",
    stateName: "South Carolina",
    city: "YEMASSEE"
  },
  "30002": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "AVONDALE ESTATES"
  },
  "30004": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ALPHARETTA"
  },
  "30005": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ALPHARETTA"
  },
  "30008": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MARIETTA"
  },
  "30011": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "AUBURN"
  },
  "30012": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CONYERS"
  },
  "30013": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CONYERS"
  },
  "30014": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "COVINGTON"
  },
  "30016": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "COVINGTON"
  },
  "30017": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GRAYSON"
  },
  "30019": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DACULA"
  },
  "30021": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CLARKSTON"
  },
  "30022": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ALPHARETTA"
  },
  "30024": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SUWANEE"
  },
  "30025": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SOCIAL CIRCLE"
  },
  "30030": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DECATUR"
  },
  "30032": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DECATUR"
  },
  "30033": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DECATUR"
  },
  "30034": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DECATUR"
  },
  "30035": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DECATUR"
  },
  "30038": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LITHONIA"
  },
  "30039": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SNELLVILLE"
  },
  "30040": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CUMMING"
  },
  "30041": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CUMMING"
  },
  "30043": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LAWRENCEVILLE"
  },
  "30044": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LAWRENCEVILLE"
  },
  "30045": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LAWRENCEVILLE"
  },
  "30047": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LILBURN"
  },
  "30052": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LOGANVILLE"
  },
  "30054": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "OXFORD"
  },
  "30055": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MANSFIELD"
  },
  "30056": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "NEWBORN"
  },
  "30058": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LITHONIA"
  },
  "30060": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MARIETTA"
  },
  "30062": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MARIETTA"
  },
  "30064": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MARIETTA"
  },
  "30066": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MARIETTA"
  },
  "30067": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MARIETTA"
  },
  "30068": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MARIETTA"
  },
  "30070": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PORTERDALE"
  },
  "30071": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "NORCROSS"
  },
  "30072": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PINE LAKE"
  },
  "30075": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ROSWELL"
  },
  "30076": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ROSWELL"
  },
  "30078": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SNELLVILLE"
  },
  "30079": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SCOTTDALE"
  },
  "30080": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SMYRNA"
  },
  "30082": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SMYRNA"
  },
  "30083": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "STONE MOUNTAIN"
  },
  "30084": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TUCKER"
  },
  "30087": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "STONE MOUNTAIN"
  },
  "30088": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "STONE MOUNTAIN"
  },
  "30092": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "NORCROSS"
  },
  "30093": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "NORCROSS"
  },
  "30094": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CONYERS"
  },
  "30096": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DULUTH"
  },
  "30097": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DULUTH"
  },
  "30101": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ACWORTH"
  },
  "30102": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ACWORTH"
  },
  "30103": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ADAIRSVILLE"
  },
  "30104": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ARAGON"
  },
  "30105": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ARMUCHEE"
  },
  "30106": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "AUSTELL"
  },
  "30107": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BALL GROUND"
  },
  "30108": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BOWDON"
  },
  "30110": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BREMEN"
  },
  "30113": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BUCHANAN"
  },
  "30114": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CANTON"
  },
  "30115": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CANTON"
  },
  "30116": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CARROLLTON"
  },
  "30117": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CARROLLTON"
  },
  "30120": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CARTERSVILLE"
  },
  "30121": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CARTERSVILLE"
  },
  "30122": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LITHIA SPRINGS"
  },
  "30124": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CAVE SPRING"
  },
  "30125": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CEDARTOWN"
  },
  "30126": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MABLETON"
  },
  "30127": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "POWDER SPRINGS"
  },
  "30132": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DALLAS"
  },
  "30134": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DOUGLASVILLE"
  },
  "30135": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DOUGLASVILLE"
  },
  "30137": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "EMERSON"
  },
  "30139": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FAIRMOUNT"
  },
  "30141": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HIRAM"
  },
  "30143": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "JASPER"
  },
  "30144": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "KENNESAW"
  },
  "30145": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "KINGSTON"
  },
  "30147": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LINDALE"
  },
  "30148": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MARBLE HILL"
  },
  "30152": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "KENNESAW"
  },
  "30153": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ROCKMART"
  },
  "30157": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DALLAS"
  },
  "30161": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ROME"
  },
  "30165": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ROME"
  },
  "30168": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "AUSTELL"
  },
  "30170": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ROOPVILLE"
  },
  "30171": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "RYDAL"
  },
  "30173": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SILVER CREEK"
  },
  "30175": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TALKING ROCK"
  },
  "30176": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TALLAPOOSA"
  },
  "30177": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TATE"
  },
  "30178": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TAYLORSVILLE"
  },
  "30179": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TEMPLE"
  },
  "30180": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "VILLA RICA"
  },
  "30182": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WACO"
  },
  "30183": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WALESKA"
  },
  "30184": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WHITE"
  },
  "30185": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WHITESBURG"
  },
  "30187": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WINSTON"
  },
  "30188": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WOODSTOCK"
  },
  "30189": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WOODSTOCK"
  },
  "30204": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BARNESVILLE"
  },
  "30205": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BROOKS"
  },
  "30206": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CONCORD"
  },
  "30213": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FAIRBURN"
  },
  "30214": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FAYETTEVILLE"
  },
  "30215": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FAYETTEVILLE"
  },
  "30216": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FLOVILLA"
  },
  "30217": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FRANKLIN"
  },
  "30218": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GAY"
  },
  "30220": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GRANTVILLE"
  },
  "30222": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GREENVILLE"
  },
  "30223": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GRIFFIN"
  },
  "30224": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GRIFFIN"
  },
  "30228": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HAMPTON"
  },
  "30230": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HOGANSVILLE"
  },
  "30233": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "JACKSON"
  },
  "30234": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "JENKINSBURG"
  },
  "30236": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "JONESBORO"
  },
  "30238": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "JONESBORO"
  },
  "30240": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LAGRANGE"
  },
  "30241": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LAGRANGE"
  },
  "30248": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LOCUST GROVE"
  },
  "30250": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LOVEJOY"
  },
  "30251": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LUTHERSVILLE"
  },
  "30252": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MCDONOUGH"
  },
  "30253": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MCDONOUGH"
  },
  "30256": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MEANSVILLE"
  },
  "30257": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MILNER"
  },
  "30258": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MOLENA"
  },
  "30259": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MORELAND"
  },
  "30260": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MORROW"
  },
  "30263": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "NEWNAN"
  },
  "30265": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "NEWNAN"
  },
  "30268": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PALMETTO"
  },
  "30269": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PEACHTREE CITY"
  },
  "30273": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "REX"
  },
  "30274": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "RIVERDALE"
  },
  "30275": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SARGENT"
  },
  "30276": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SENOIA"
  },
  "30277": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SHARPSBURG"
  },
  "30281": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "STOCKBRIDGE"
  },
  "30285": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "THE ROCK"
  },
  "30286": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "THOMASTON"
  },
  "30288": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CONLEY"
  },
  "30290": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TYRONE"
  },
  "30291": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "UNION CITY"
  },
  "30292": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WILLIAMSON"
  },
  "30293": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WOODBURY"
  },
  "30294": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ELLENWOOD"
  },
  "30295": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ZEBULON"
  },
  "30296": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "RIVERDALE"
  },
  "30297": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FOREST PARK"
  },
  "30303": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30305": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30306": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30307": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30308": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30309": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30310": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30311": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30312": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30313": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30314": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30315": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30316": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30317": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30318": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30319": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30324": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30326": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30327": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30328": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30329": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30331": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30336": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30337": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30338": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30339": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30340": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30341": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30342": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30344": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30345": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30346": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30349": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30350": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30354": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30360": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATLANTA"
  },
  "30401": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SWAINSBORO"
  },
  "30410": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "AILEY"
  },
  "30411": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ALAMO"
  },
  "30412": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ALSTON"
  },
  "30413": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BARTOW"
  },
  "30414": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BELLVILLE"
  },
  "30415": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BROOKLET"
  },
  "30417": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CLAXTON"
  },
  "30420": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "COBBTOWN"
  },
  "30421": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "COLLINS"
  },
  "30423": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DAISY"
  },
  "30425": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GARFIELD"
  },
  "30426": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GIRARD"
  },
  "30427": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GLENNVILLE"
  },
  "30428": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GLENWOOD"
  },
  "30429": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HAGAN"
  },
  "30434": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LOUISVILLE"
  },
  "30436": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LYONS"
  },
  "30438": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MANASSAS"
  },
  "30439": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "METTER"
  },
  "30441": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MIDVILLE"
  },
  "30442": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MILLEN"
  },
  "30445": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MOUNT VERNON"
  },
  "30446": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "NEWINGTON"
  },
  "30447": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "NORRISTOWN"
  },
  "30448": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "NUNEZ"
  },
  "30450": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PORTAL"
  },
  "30452": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "REGISTER"
  },
  "30453": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "REIDSVILLE"
  },
  "30454": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ROCKLEDGE"
  },
  "30455": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ROCKY FORD"
  },
  "30456": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SARDIS"
  },
  "30457": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SOPERTON"
  },
  "30458": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "STATESBORO"
  },
  "30461": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "STATESBORO"
  },
  "30464": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "STILLMORE"
  },
  "30467": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SYLVANIA"
  },
  "30470": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TARRYTOWN"
  },
  "30471": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TWIN CITY"
  },
  "30473": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "UVALDA"
  },
  "30474": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "VIDALIA"
  },
  "30477": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WADLEY"
  },
  "30501": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GAINESVILLE"
  },
  "30504": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GAINESVILLE"
  },
  "30506": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GAINESVILLE"
  },
  "30507": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GAINESVILLE"
  },
  "30510": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ALTO"
  },
  "30511": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BALDWIN"
  },
  "30512": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BLAIRSVILLE"
  },
  "30513": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BLUE RIDGE"
  },
  "30516": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BOWERSVILLE"
  },
  "30517": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BRASELTON"
  },
  "30518": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BUFORD"
  },
  "30519": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BUFORD"
  },
  "30520": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CANON"
  },
  "30521": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CARNESVILLE"
  },
  "30522": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CHERRYLOG"
  },
  "30523": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CLARKESVILLE"
  },
  "30525": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CLAYTON"
  },
  "30527": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CLERMONT"
  },
  "30528": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CLEVELAND"
  },
  "30529": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "COMMERCE"
  },
  "30530": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "COMMERCE"
  },
  "30531": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CORNELIA"
  },
  "30533": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DAHLONEGA"
  },
  "30534": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DAWSONVILLE"
  },
  "30535": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DEMOREST"
  },
  "30537": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DILLARD"
  },
  "30538": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "EASTANOLLEE"
  },
  "30539": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "EAST ELLIJAY"
  },
  "30540": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ELLIJAY"
  },
  "30541": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "EPWORTH"
  },
  "30542": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FLOWERY BRANCH"
  },
  "30543": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GILLSVILLE"
  },
  "30545": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HELEN"
  },
  "30546": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HIAWASSEE"
  },
  "30547": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HOMER"
  },
  "30548": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HOSCHTON"
  },
  "30549": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "JEFFERSON"
  },
  "30552": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LAKEMONT"
  },
  "30553": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LAVONIA"
  },
  "30554": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LULA"
  },
  "30555": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MC CAYSVILLE"
  },
  "30557": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MARTIN"
  },
  "30558": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MAYSVILLE"
  },
  "30559": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MINERAL BLUFF"
  },
  "30560": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MORGANTON"
  },
  "30562": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MOUNTAIN CITY"
  },
  "30563": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MOUNT AIRY"
  },
  "30564": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MURRAYVILLE"
  },
  "30565": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "NICHOLSON"
  },
  "30566": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "OAKWOOD"
  },
  "30567": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PENDERGRASS"
  },
  "30568": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "RABUN GAP"
  },
  "30571": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SAUTEE NACOOCHEE"
  },
  "30572": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SUCHES"
  },
  "30575": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TALMO"
  },
  "30576": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TIGER"
  },
  "30577": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TOCCOA"
  },
  "30581": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WILEY"
  },
  "30582": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "YOUNG HARRIS"
  },
  "30598": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TOCCOA FALLS"
  },
  "30601": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATHENS"
  },
  "30605": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATHENS"
  },
  "30606": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATHENS"
  },
  "30607": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ATHENS"
  },
  "30619": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ARNOLDSVILLE"
  },
  "30620": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BETHLEHEM"
  },
  "30621": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BISHOP"
  },
  "30622": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BOGART"
  },
  "30624": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BOWMAN"
  },
  "30625": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BUCKHEAD"
  },
  "30627": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CARLTON"
  },
  "30628": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "COLBERT"
  },
  "30629": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "COMER"
  },
  "30630": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CRAWFORD"
  },
  "30631": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CRAWFORDVILLE"
  },
  "30633": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DANIELSVILLE"
  },
  "30634": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DEWY ROSE"
  },
  "30635": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ELBERTON"
  },
  "30638": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FARMINGTON"
  },
  "30639": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FRANKLIN SPRINGS"
  },
  "30641": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GOOD HOPE"
  },
  "30642": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GREENSBORO"
  },
  "30643": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HARTWELL"
  },
  "30646": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HULL"
  },
  "30648": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LEXINGTON"
  },
  "30650": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MADISON"
  },
  "30655": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MONROE"
  },
  "30656": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MONROE"
  },
  "30660": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "RAYLE"
  },
  "30662": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ROYSTON"
  },
  "30663": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "RUTLEDGE"
  },
  "30664": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SHARON"
  },
  "30665": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SILOAM"
  },
  "30666": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "STATHAM"
  },
  "30667": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "STEPHENS"
  },
  "30668": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TIGNALL"
  },
  "30669": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "UNION POINT"
  },
  "30671": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MAXEYS"
  },
  "30673": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WASHINGTON"
  },
  "30677": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WATKINSVILLE"
  },
  "30678": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WHITE PLAINS"
  },
  "30680": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WINDER"
  },
  "30683": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WINTERVILLE"
  },
  "30701": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CALHOUN"
  },
  "30705": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CHATSWORTH"
  },
  "30707": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CHICKAMAUGA"
  },
  "30708": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CISCO"
  },
  "30710": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "COHUTTA"
  },
  "30711": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CRANDALL"
  },
  "30720": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DALTON"
  },
  "30721": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DALTON"
  },
  "30724": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ETON"
  },
  "30725": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FLINTSTONE"
  },
  "30726": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GRAYSVILLE"
  },
  "30728": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LA FAYETTE"
  },
  "30730": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LYERLY"
  },
  "30731": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MENLO"
  },
  "30733": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PLAINVILLE"
  },
  "30734": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "RANGER"
  },
  "30735": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "RESACA"
  },
  "30736": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "RINGGOLD"
  },
  "30738": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "RISING FAWN"
  },
  "30739": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ROCK SPRING"
  },
  "30740": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ROCKY FACE"
  },
  "30741": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ROSSVILLE"
  },
  "30742": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FORT OGLETHORPE"
  },
  "30746": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SUGAR VALLEY"
  },
  "30747": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SUMMERVILLE"
  },
  "30750": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LOOKOUT MOUNTAIN"
  },
  "30752": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TRENTON"
  },
  "30753": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TRION"
  },
  "30755": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TUNNEL HILL"
  },
  "30757": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WILDWOOD"
  },
  "30802": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "APPLING"
  },
  "30803": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "AVERA"
  },
  "30805": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BLYTHE"
  },
  "30807": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CAMAK"
  },
  "30808": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DEARING"
  },
  "30809": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "EVANS"
  },
  "30810": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GIBSON"
  },
  "30813": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GROVETOWN"
  },
  "30814": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HARLEM"
  },
  "30815": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HEPHZIBAH"
  },
  "30816": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "KEYSVILLE"
  },
  "30817": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LINCOLNTON"
  },
  "30818": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MATTHEWS"
  },
  "30820": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MITCHELL"
  },
  "30821": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "NORWOOD"
  },
  "30822": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PERKINS"
  },
  "30823": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "STAPLETON"
  },
  "30824": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "THOMSON"
  },
  "30828": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WARRENTON"
  },
  "30830": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WAYNESBORO"
  },
  "30833": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WRENS"
  },
  "30901": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "AUGUSTA"
  },
  "30904": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "AUGUSTA"
  },
  "30905": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "AUGUSTA"
  },
  "30906": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "AUGUSTA"
  },
  "30907": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "AUGUSTA"
  },
  "30909": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "AUGUSTA"
  },
  "31001": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ABBEVILLE"
  },
  "31002": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ADRIAN"
  },
  "31003": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ALLENTOWN"
  },
  "31005": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BONAIRE"
  },
  "31006": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BUTLER"
  },
  "31007": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BYROMVILLE"
  },
  "31008": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BYRON"
  },
  "31009": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CADWELL"
  },
  "31011": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CHAUNCEY"
  },
  "31012": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CHESTER"
  },
  "31014": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "COCHRAN"
  },
  "31015": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CORDELE"
  },
  "31016": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CULLODEN"
  },
  "31017": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DANVILLE"
  },
  "31018": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DAVISBORO"
  },
  "31019": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DEXTER"
  },
  "31020": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DRY BRANCH"
  },
  "31021": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DUBLIN"
  },
  "31022": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DUDLEY"
  },
  "31023": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "EASTMAN"
  },
  "31024": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "EATONTON"
  },
  "31025": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ELKO"
  },
  "31027": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "EAST DUBLIN"
  },
  "31028": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CENTERVILLE"
  },
  "31029": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FORSYTH"
  },
  "31030": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FORT VALLEY"
  },
  "31031": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GORDON"
  },
  "31032": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GRAY"
  },
  "31033": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HADDOCK"
  },
  "31035": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HARRISON"
  },
  "31036": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HAWKINSVILLE"
  },
  "31037": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HELENA"
  },
  "31038": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HILLSBORO"
  },
  "31039": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HOWARD"
  },
  "31041": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "IDEAL"
  },
  "31042": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "IRWINTON"
  },
  "31044": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "JEFFERSONVILLE"
  },
  "31045": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "JEWELL"
  },
  "31046": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "JULIETTE"
  },
  "31047": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "KATHLEEN"
  },
  "31049": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "KITE"
  },
  "31050": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "KNOXVILLE"
  },
  "31051": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LILLY"
  },
  "31052": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LIZELLA"
  },
  "31054": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MC INTYRE"
  },
  "31055": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MC RAE"
  },
  "31057": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MARSHALLVILLE"
  },
  "31058": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MAUK"
  },
  "31060": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MILAN"
  },
  "31061": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MILLEDGEVILLE"
  },
  "31063": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MONTEZUMA"
  },
  "31064": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MONTICELLO"
  },
  "31065": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MONTROSE"
  },
  "31066": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MUSELLA"
  },
  "31067": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "OCONEE"
  },
  "31068": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "OGLETHORPE"
  },
  "31069": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PERRY"
  },
  "31070": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PINEHURST"
  },
  "31071": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PINEVIEW"
  },
  "31072": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PITTS"
  },
  "31075": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "RENTZ"
  },
  "31076": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "REYNOLDS"
  },
  "31077": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "RHINE"
  },
  "31078": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ROBERTA"
  },
  "31079": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ROCHELLE"
  },
  "31081": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "RUPERT"
  },
  "31082": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SANDERSVILLE"
  },
  "31083": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SCOTLAND"
  },
  "31085": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SHADY DALE"
  },
  "31087": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SPARTA"
  },
  "31088": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WARNER ROBINS"
  },
  "31089": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TENNILLE"
  },
  "31090": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TOOMSBORO"
  },
  "31091": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "UNADILLA"
  },
  "31092": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "VIENNA"
  },
  "31093": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WARNER ROBINS"
  },
  "31094": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WARTHEN"
  },
  "31096": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WRIGHTSVILLE"
  },
  "31097": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "YATESVILLE"
  },
  "31098": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WARNER ROBINS"
  },
  "31201": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MACON"
  },
  "31204": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MACON"
  },
  "31206": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MACON"
  },
  "31210": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MACON"
  },
  "31211": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MACON"
  },
  "31216": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MACON"
  },
  "31217": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MACON"
  },
  "31220": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MACON"
  },
  "31301": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ALLENHURST"
  },
  "31302": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BLOOMINGDALE"
  },
  "31303": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CLYO"
  },
  "31305": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DARIEN"
  },
  "31307": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "EDEN"
  },
  "31308": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ELLABELL"
  },
  "31309": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FLEMING"
  },
  "31312": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GUYTON"
  },
  "31313": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HINESVILLE"
  },
  "31315": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FORT STEWART"
  },
  "31316": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LUDOWICI"
  },
  "31318": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MELDRIM"
  },
  "31319": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MERIDIAN"
  },
  "31320": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MIDWAY"
  },
  "31321": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PEMBROKE"
  },
  "31322": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "POOLER"
  },
  "31323": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "RICEBORO"
  },
  "31324": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "RICHMOND HILL"
  },
  "31326": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "RINCON"
  },
  "31327": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SAPELO ISLAND"
  },
  "31328": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TYBEE ISLAND"
  },
  "31329": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SPRINGFIELD"
  },
  "31331": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TOWNSEND"
  },
  "31333": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WALTHOURVILLE"
  },
  "31401": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SAVANNAH"
  },
  "31404": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SAVANNAH"
  },
  "31405": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SAVANNAH"
  },
  "31406": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SAVANNAH"
  },
  "31407": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SAVANNAH"
  },
  "31408": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SAVANNAH"
  },
  "31410": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SAVANNAH"
  },
  "31411": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SAVANNAH"
  },
  "31415": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SAVANNAH"
  },
  "31419": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SAVANNAH"
  },
  "31501": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WAYCROSS"
  },
  "31503": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WAYCROSS"
  },
  "31510": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ALMA"
  },
  "31512": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "AMBROSE"
  },
  "31513": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BAXLEY"
  },
  "31516": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BLACKSHEAR"
  },
  "31518": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BRISTOL"
  },
  "31519": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BROXTON"
  },
  "31520": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BRUNSWICK"
  },
  "31522": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SAINT SIMONS ISLAND"
  },
  "31523": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BRUNSWICK"
  },
  "31525": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BRUNSWICK"
  },
  "31527": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "JEKYLL ISLAND"
  },
  "31532": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DENTON"
  },
  "31533": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DOUGLAS"
  },
  "31535": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DOUGLAS"
  },
  "31537": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FOLKSTON"
  },
  "31539": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HAZLEHURST"
  },
  "31542": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HOBOKEN"
  },
  "31543": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HORTENSE"
  },
  "31544": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "JACKSONVILLE"
  },
  "31545": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "JESUP"
  },
  "31546": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "JESUP"
  },
  "31548": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "KINGSLAND"
  },
  "31549": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LUMBER CITY"
  },
  "31550": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MANOR"
  },
  "31551": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MERSHON"
  },
  "31552": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MILLWOOD"
  },
  "31553": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "NAHUNTA"
  },
  "31554": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "NICHOLLS"
  },
  "31555": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ODUM"
  },
  "31557": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PATTERSON"
  },
  "31558": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SAINT MARYS"
  },
  "31560": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SCREVEN"
  },
  "31563": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SURRENCY"
  },
  "31565": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WAVERLY"
  },
  "31566": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WAYNESVILLE"
  },
  "31567": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WEST GREEN"
  },
  "31568": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WHITE OAK"
  },
  "31569": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WOODBINE"
  },
  "31601": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "VALDOSTA"
  },
  "31602": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "VALDOSTA"
  },
  "31605": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "VALDOSTA"
  },
  "31606": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "VALDOSTA"
  },
  "31620": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ADEL"
  },
  "31622": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ALAPAHA"
  },
  "31623": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ARGYLE"
  },
  "31624": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "AXSON"
  },
  "31625": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BARNEY"
  },
  "31626": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BOSTON"
  },
  "31627": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CECIL"
  },
  "31629": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DIXIE"
  },
  "31630": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DU PONT"
  },
  "31631": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FARGO"
  },
  "31632": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HAHIRA"
  },
  "31634": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HOMERVILLE"
  },
  "31635": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LAKELAND"
  },
  "31636": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LAKE PARK"
  },
  "31637": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LENOX"
  },
  "31638": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MORVEN"
  },
  "31639": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "NASHVILLE"
  },
  "31641": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "NAYLOR"
  },
  "31642": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PEARSON"
  },
  "31643": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "QUITMAN"
  },
  "31645": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "RAY CITY"
  },
  "31647": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SPARKS"
  },
  "31648": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "STATENVILLE"
  },
  "31649": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "STOCKTON"
  },
  "31650": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WILLACOOCHEE"
  },
  "31701": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ALBANY"
  },
  "31705": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ALBANY"
  },
  "31707": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ALBANY"
  },
  "31709": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "AMERICUS"
  },
  "31711": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ANDERSONVILLE"
  },
  "31712": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ARABI"
  },
  "31714": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ASHBURN"
  },
  "31716": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BACONTON"
  },
  "31720": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BARWICK"
  },
  "31722": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BERLIN"
  },
  "31730": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CAMILLA"
  },
  "31733": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CHULA"
  },
  "31735": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "COBB"
  },
  "31738": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "COOLIDGE"
  },
  "31743": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DE SOTO"
  },
  "31744": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "DOERUN"
  },
  "31747": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ELLENTON"
  },
  "31749": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ENIGMA"
  },
  "31750": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FITZGERALD"
  },
  "31756": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HARTSFIELD"
  },
  "31757": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "THOMASVILLE"
  },
  "31763": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LEESBURG"
  },
  "31764": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LESLIE"
  },
  "31765": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MEIGS"
  },
  "31768": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MOULTRIE"
  },
  "31771": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "NORMAN PARK"
  },
  "31772": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "OAKFIELD"
  },
  "31773": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "OCHLOCKNEE"
  },
  "31774": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "OCILLA"
  },
  "31775": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "OMEGA"
  },
  "31778": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PAVO"
  },
  "31779": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PELHAM"
  },
  "31780": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PLAINS"
  },
  "31781": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "POULAN"
  },
  "31783": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "REBECCA"
  },
  "31784": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SALE CITY"
  },
  "31787": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SMITHVILLE"
  },
  "31789": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SUMNER"
  },
  "31790": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SYCAMORE"
  },
  "31791": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SYLVESTER"
  },
  "31792": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "THOMASVILLE"
  },
  "31794": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TIFTON"
  },
  "31795": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TY TY"
  },
  "31796": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WARWICK"
  },
  "31798": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WRAY"
  },
  "31801": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BOX SPRINGS"
  },
  "31803": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "BUENA VISTA"
  },
  "31804": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CATAULA"
  },
  "31805": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "CUSSETA"
  },
  "31806": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ELLAVILLE"
  },
  "31807": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "ELLERSLIE"
  },
  "31808": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FORTSON"
  },
  "31810": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "GENEVA"
  },
  "31811": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "HAMILTON"
  },
  "31812": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "JUNCTION CITY"
  },
  "31814": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LOUVALE"
  },
  "31815": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "LUMPKIN"
  },
  "31816": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MANCHESTER"
  },
  "31820": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "MIDLAND"
  },
  "31821": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "OMAHA"
  },
  "31822": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PINE MOUNTAIN"
  },
  "31823": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PINE MOUNTAIN VALLEY"
  },
  "31824": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "PRESTON"
  },
  "31825": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "RICHLAND"
  },
  "31826": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "SHILOH"
  },
  "31827": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "TALBOTTON"
  },
  "31829": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "UPATOI"
  },
  "31830": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WARM SPRINGS"
  },
  "31831": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WAVERLY HALL"
  },
  "31832": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WESTON"
  },
  "31833": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WEST POINT"
  },
  "31836": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "WOODLAND"
  },
  "31901": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "COLUMBUS"
  },
  "31903": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "COLUMBUS"
  },
  "31904": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "COLUMBUS"
  },
  "31905": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "FORT BENNING"
  },
  "31906": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "COLUMBUS"
  },
  "31907": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "COLUMBUS"
  },
  "31909": {
    stateCode: "GA",
    stateName: "Georgia",
    city: "COLUMBUS"
  },
  "32008": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BRANFORD"
  },
  "32009": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BRYCEVILLE"
  },
  "32011": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CALLAHAN"
  },
  "32013": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DAY"
  },
  "32024": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKE CITY"
  },
  "32025": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKE CITY"
  },
  "32033": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ELKTON"
  },
  "32034": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FERNANDINA BEACH"
  },
  "32038": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT WHITE"
  },
  "32040": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GLEN SAINT MARY"
  },
  "32043": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GREEN COVE SPRINGS"
  },
  "32044": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HAMPTON"
  },
  "32046": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HILLIARD"
  },
  "32052": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JASPER"
  },
  "32053": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JENNINGS"
  },
  "32054": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKE BUTLER"
  },
  "32055": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKE CITY"
  },
  "32058": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAWTEY"
  },
  "32059": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LEE"
  },
  "32060": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LIVE OAK"
  },
  "32061": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LULU"
  },
  "32062": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MC ALPIN"
  },
  "32063": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MACCLENNY"
  },
  "32065": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORANGE PARK"
  },
  "32066": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MAYO"
  },
  "32068": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIDDLEBURG"
  },
  "32071": {
    stateCode: "FL",
    stateName: "Florida",
    city: "O BRIEN"
  },
  "32072": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OLUSTEE"
  },
  "32073": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORANGE PARK"
  },
  "32079": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PENNEY FARMS"
  },
  "32082": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PONTE VEDRA BEACH"
  },
  "32083": {
    stateCode: "FL",
    stateName: "Florida",
    city: "RAIFORD"
  },
  "32084": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT AUGUSTINE"
  },
  "32086": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT AUGUSTINE"
  },
  "32087": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SANDERSON"
  },
  "32091": {
    stateCode: "FL",
    stateName: "Florida",
    city: "STARKE"
  },
  "32092": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT AUGUSTINE"
  },
  "32094": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WELLBORN"
  },
  "32095": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT AUGUSTINE"
  },
  "32096": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WHITE SPRINGS"
  },
  "32097": {
    stateCode: "FL",
    stateName: "Florida",
    city: "YULEE"
  },
  "32102": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ASTOR"
  },
  "32110": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BUNNELL"
  },
  "32112": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CRESCENT CITY"
  },
  "32113": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CITRA"
  },
  "32114": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DAYTONA BEACH"
  },
  "32117": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DAYTONA BEACH"
  },
  "32118": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DAYTONA BEACH"
  },
  "32119": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DAYTONA BEACH"
  },
  "32124": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DAYTONA BEACH"
  },
  "32127": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PORT ORANGE"
  },
  "32130": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DE LEON SPRINGS"
  },
  "32131": {
    stateCode: "FL",
    stateName: "Florida",
    city: "EAST PALATKA"
  },
  "32132": {
    stateCode: "FL",
    stateName: "Florida",
    city: "EDGEWATER"
  },
  "32134": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT MC COY"
  },
  "32136": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FLAGLER BEACH"
  },
  "32137": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PALM COAST"
  },
  "32138": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GRANDIN"
  },
  "32139": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GEORGETOWN"
  },
  "32140": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FLORAHOME"
  },
  "32141": {
    stateCode: "FL",
    stateName: "Florida",
    city: "EDGEWATER"
  },
  "32145": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HASTINGS"
  },
  "32147": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOLLISTER"
  },
  "32148": {
    stateCode: "FL",
    stateName: "Florida",
    city: "INTERLACHEN"
  },
  "32157": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKE COMO"
  },
  "32159": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LADY LAKE"
  },
  "32164": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PALM COAST"
  },
  "32168": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NEW SMYRNA BEACH"
  },
  "32169": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NEW SMYRNA BEACH"
  },
  "32174": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORMOND BEACH"
  },
  "32176": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORMOND BEACH"
  },
  "32177": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PALATKA"
  },
  "32179": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OCKLAWAHA"
  },
  "32180": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PIERSON"
  },
  "32181": {
    stateCode: "FL",
    stateName: "Florida",
    city: "POMONA PARK"
  },
  "32182": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORANGE SPRINGS"
  },
  "32183": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OCKLAWAHA"
  },
  "32185": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PUTNAM HALL"
  },
  "32187": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAN MATEO"
  },
  "32189": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SATSUMA"
  },
  "32190": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SEVILLE"
  },
  "32193": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WELAKA"
  },
  "32195": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WEIRSDALE"
  },
  "32202": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32204": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32205": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32206": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32207": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32208": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32209": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32210": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32211": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32212": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32215": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32216": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32217": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32218": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32219": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32220": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32221": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32222": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32223": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32224": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32225": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32226": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32227": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32233": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ATLANTIC BEACH"
  },
  "32234": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32244": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32246": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32250": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE BEACH"
  },
  "32254": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32256": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32257": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32258": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32259": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT JOHNS"
  },
  "32266": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NEPTUNE BEACH"
  },
  "32277": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JACKSONVILLE"
  },
  "32301": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TALLAHASSEE"
  },
  "32303": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TALLAHASSEE"
  },
  "32304": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TALLAHASSEE"
  },
  "32308": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TALLAHASSEE"
  },
  "32310": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TALLAHASSEE"
  },
  "32311": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TALLAHASSEE"
  },
  "32312": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TALLAHASSEE"
  },
  "32320": {
    stateCode: "FL",
    stateName: "Florida",
    city: "APALACHICOLA"
  },
  "32321": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BRISTOL"
  },
  "32322": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CARRABELLE"
  },
  "32323": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LANARK VILLAGE"
  },
  "32324": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CHATTAHOOCHEE"
  },
  "32327": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CRAWFORDVILLE"
  },
  "32328": {
    stateCode: "FL",
    stateName: "Florida",
    city: "EASTPOINT"
  },
  "32330": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GREENSBORO"
  },
  "32331": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GREENVILLE"
  },
  "32332": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GRETNA"
  },
  "32333": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HAVANA"
  },
  "32334": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOSFORD"
  },
  "32335": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SUMATRA"
  },
  "32336": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAMONT"
  },
  "32337": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LLOYD"
  },
  "32340": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MADISON"
  },
  "32343": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIDWAY"
  },
  "32344": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MONTICELLO"
  },
  "32346": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PANACEA"
  },
  "32347": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PERRY"
  },
  "32350": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PINETTA"
  },
  "32351": {
    stateCode: "FL",
    stateName: "Florida",
    city: "QUINCY"
  },
  "32355": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT MARKS"
  },
  "32356": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SALEM"
  },
  "32357": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SHADY GROVE"
  },
  "32358": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SOPCHOPPY"
  },
  "32359": {
    stateCode: "FL",
    stateName: "Florida",
    city: "STEINHATCHEE"
  },
  "32360": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TELOGIA"
  },
  "32361": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WACISSA"
  },
  "32401": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PANAMA CITY"
  },
  "32403": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PANAMA CITY"
  },
  "32404": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PANAMA CITY"
  },
  "32405": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PANAMA CITY"
  },
  "32407": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PANAMA CITY BEACH"
  },
  "32408": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PANAMA CITY"
  },
  "32409": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PANAMA CITY"
  },
  "32410": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MEXICO BEACH"
  },
  "32413": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PANAMA CITY BEACH"
  },
  "32420": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ALFORD"
  },
  "32421": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ALTHA"
  },
  "32422": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ARGYLE"
  },
  "32423": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BASCOM"
  },
  "32424": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BLOUNTSTOWN"
  },
  "32425": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BONIFAY"
  },
  "32426": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CAMPBELLTON"
  },
  "32427": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CARYVILLE"
  },
  "32428": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CHIPLEY"
  },
  "32430": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CLARKSVILLE"
  },
  "32431": {
    stateCode: "FL",
    stateName: "Florida",
    city: "COTTONDALE"
  },
  "32432": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CYPRESS"
  },
  "32433": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DEFUNIAK SPRINGS"
  },
  "32437": {
    stateCode: "FL",
    stateName: "Florida",
    city: "EBRO"
  },
  "32438": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FOUNTAIN"
  },
  "32439": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FREEPORT"
  },
  "32440": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GRACEVILLE"
  },
  "32442": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GRAND RIDGE"
  },
  "32443": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GREENWOOD"
  },
  "32444": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LYNN HAVEN"
  },
  "32445": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MALONE"
  },
  "32446": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MARIANNA"
  },
  "32447": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MARIANNA"
  },
  "32448": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MARIANNA"
  },
  "32449": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WEWAHITCHKA"
  },
  "32455": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PONCE DE LEON"
  },
  "32456": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PORT SAINT JOE"
  },
  "32459": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SANTA ROSA BEACH"
  },
  "32460": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SNEADS"
  },
  "32462": {
    stateCode: "FL",
    stateName: "Florida",
    city: "VERNON"
  },
  "32463": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WAUSAU"
  },
  "32464": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WESTVILLE"
  },
  "32465": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WEWAHITCHKA"
  },
  "32466": {
    stateCode: "FL",
    stateName: "Florida",
    city: "YOUNGSTOWN"
  },
  "32501": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PENSACOLA"
  },
  "32503": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PENSACOLA"
  },
  "32504": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PENSACOLA"
  },
  "32505": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PENSACOLA"
  },
  "32506": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PENSACOLA"
  },
  "32507": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PENSACOLA"
  },
  "32508": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PENSACOLA"
  },
  "32514": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PENSACOLA"
  },
  "32526": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PENSACOLA"
  },
  "32531": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BAKER"
  },
  "32533": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CANTONMENT"
  },
  "32534": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PENSACOLA"
  },
  "32535": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CENTURY"
  },
  "32536": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CRESTVIEW"
  },
  "32539": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CRESTVIEW"
  },
  "32541": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DESTIN"
  },
  "32542": {
    stateCode: "FL",
    stateName: "Florida",
    city: "EGLIN AFB"
  },
  "32544": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HURLBURT FIELD"
  },
  "32547": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT WALTON BEACH"
  },
  "32548": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT WALTON BEACH"
  },
  "32561": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GULF BREEZE"
  },
  "32564": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOLT"
  },
  "32565": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JAY"
  },
  "32566": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NAVARRE"
  },
  "32567": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAUREL HILL"
  },
  "32568": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MC DAVID"
  },
  "32569": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MARY ESTHER"
  },
  "32570": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MILTON"
  },
  "32571": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MILTON"
  },
  "32577": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MOLINO"
  },
  "32578": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NICEVILLE"
  },
  "32579": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SHALIMAR"
  },
  "32580": {
    stateCode: "FL",
    stateName: "Florida",
    city: "VALPARAISO"
  },
  "32583": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MILTON"
  },
  "32601": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GAINESVILLE"
  },
  "32603": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GAINESVILLE"
  },
  "32605": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GAINESVILLE"
  },
  "32606": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GAINESVILLE"
  },
  "32607": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GAINESVILLE"
  },
  "32608": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GAINESVILLE"
  },
  "32609": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GAINESVILLE"
  },
  "32615": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ALACHUA"
  },
  "32616": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ALACHUA"
  },
  "32617": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ANTHONY"
  },
  "32618": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ARCHER"
  },
  "32619": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BELL"
  },
  "32621": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BRONSON"
  },
  "32622": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BROOKER"
  },
  "32625": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CEDAR KEY"
  },
  "32626": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CHIEFLAND"
  },
  "32628": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CROSS CITY"
  },
  "32639": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GULF HAMMOCK"
  },
  "32640": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HAWTHORNE"
  },
  "32641": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GAINESVILLE"
  },
  "32643": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HIGH SPRINGS"
  },
  "32648": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HORSESHOE BEACH"
  },
  "32653": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GAINESVILLE"
  },
  "32654": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ISLAND GROVE"
  },
  "32656": {
    stateCode: "FL",
    stateName: "Florida",
    city: "KEYSTONE HEIGHTS"
  },
  "32658": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LA CROSSE"
  },
  "32662": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LOCHLOOSA"
  },
  "32664": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MC INTOSH"
  },
  "32666": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MELROSE"
  },
  "32667": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MICANOPY"
  },
  "32668": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MORRISTON"
  },
  "32669": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NEWBERRY"
  },
  "32680": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OLD TOWN"
  },
  "32681": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORANGE LAKE"
  },
  "32683": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OTTER CREEK"
  },
  "32686": {
    stateCode: "FL",
    stateName: "Florida",
    city: "REDDICK"
  },
  "32692": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SUWANNEE"
  },
  "32693": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TRENTON"
  },
  "32694": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WALDO"
  },
  "32696": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WILLISTON"
  },
  "32697": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WORTHINGTON SPRINGS"
  },
  "32701": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ALTAMONTE SPRINGS"
  },
  "32702": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ALTOONA"
  },
  "32703": {
    stateCode: "FL",
    stateName: "Florida",
    city: "APOPKA"
  },
  "32706": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CASSADAGA"
  },
  "32707": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CASSELBERRY"
  },
  "32708": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WINTER SPRINGS"
  },
  "32709": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CHRISTMAS"
  },
  "32712": {
    stateCode: "FL",
    stateName: "Florida",
    city: "APOPKA"
  },
  "32713": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DEBARY"
  },
  "32714": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ALTAMONTE SPRINGS"
  },
  "32720": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DELAND"
  },
  "32724": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DELAND"
  },
  "32725": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DELTONA"
  },
  "32726": {
    stateCode: "FL",
    stateName: "Florida",
    city: "EUSTIS"
  },
  "32730": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CASSELBERRY"
  },
  "32732": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GENEVA"
  },
  "32735": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GRAND ISLAND"
  },
  "32736": {
    stateCode: "FL",
    stateName: "Florida",
    city: "EUSTIS"
  },
  "32738": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DELTONA"
  },
  "32744": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKE HELEN"
  },
  "32746": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKE MARY"
  },
  "32750": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LONGWOOD"
  },
  "32751": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MAITLAND"
  },
  "32754": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIMS"
  },
  "32757": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MOUNT DORA"
  },
  "32759": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OAK HILL"
  },
  "32763": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORANGE CITY"
  },
  "32764": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OSTEEN"
  },
  "32765": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OVIEDO"
  },
  "32766": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OVIEDO"
  },
  "32767": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PAISLEY"
  },
  "32771": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SANFORD"
  },
  "32773": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SANFORD"
  },
  "32775": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SCOTTSMOOR"
  },
  "32776": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SORRENTO"
  },
  "32778": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAVARES"
  },
  "32779": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LONGWOOD"
  },
  "32780": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TITUSVILLE"
  },
  "32784": {
    stateCode: "FL",
    stateName: "Florida",
    city: "UMATILLA"
  },
  "32789": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WINTER PARK"
  },
  "32792": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WINTER PARK"
  },
  "32796": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TITUSVILLE"
  },
  "32798": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ZELLWOOD"
  },
  "32801": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32803": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32804": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32805": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32806": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32807": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32808": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32809": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32810": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32811": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32812": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32817": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32818": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32819": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32820": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32821": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32822": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32824": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32825": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32826": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32827": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32828": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32829": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32831": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32832": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32833": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32835": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32836": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32837": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32839": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ORLANDO"
  },
  "32901": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MELBOURNE"
  },
  "32903": {
    stateCode: "FL",
    stateName: "Florida",
    city: "INDIALANTIC"
  },
  "32904": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MELBOURNE"
  },
  "32905": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PALM BAY"
  },
  "32907": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PALM BAY"
  },
  "32908": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PALM BAY"
  },
  "32909": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PALM BAY"
  },
  "32920": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CAPE CANAVERAL"
  },
  "32922": {
    stateCode: "FL",
    stateName: "Florida",
    city: "COCOA"
  },
  "32925": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PATRICK AFB"
  },
  "32926": {
    stateCode: "FL",
    stateName: "Florida",
    city: "COCOA"
  },
  "32927": {
    stateCode: "FL",
    stateName: "Florida",
    city: "COCOA"
  },
  "32931": {
    stateCode: "FL",
    stateName: "Florida",
    city: "COCOA BEACH"
  },
  "32934": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MELBOURNE"
  },
  "32935": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MELBOURNE"
  },
  "32937": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SATELLITE BEACH"
  },
  "32940": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MELBOURNE"
  },
  "32948": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FELLSMERE"
  },
  "32949": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GRANT"
  },
  "32950": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MALABAR"
  },
  "32951": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MELBOURNE BEACH"
  },
  "32952": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MERRITT ISLAND"
  },
  "32953": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MERRITT ISLAND"
  },
  "32955": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ROCKLEDGE"
  },
  "32958": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SEBASTIAN"
  },
  "32960": {
    stateCode: "FL",
    stateName: "Florida",
    city: "VERO BEACH"
  },
  "32962": {
    stateCode: "FL",
    stateName: "Florida",
    city: "VERO BEACH"
  },
  "32963": {
    stateCode: "FL",
    stateName: "Florida",
    city: "VERO BEACH"
  },
  "32966": {
    stateCode: "FL",
    stateName: "Florida",
    city: "VERO BEACH"
  },
  "32967": {
    stateCode: "FL",
    stateName: "Florida",
    city: "VERO BEACH"
  },
  "32968": {
    stateCode: "FL",
    stateName: "Florida",
    city: "VERO BEACH"
  },
  "32970": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WABASSO"
  },
  "32976": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SEBASTIAN"
  },
  "33001": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LONG KEY"
  },
  "33004": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DANIA"
  },
  "33009": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HALLANDALE"
  },
  "33010": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HIALEAH"
  },
  "33012": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HIALEAH"
  },
  "33013": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HIALEAH"
  },
  "33014": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HIALEAH"
  },
  "33015": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HIALEAH"
  },
  "33016": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HIALEAH"
  },
  "33018": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HIALEAH"
  },
  "33019": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOLLYWOOD"
  },
  "33020": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOLLYWOOD"
  },
  "33021": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOLLYWOOD"
  },
  "33023": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOLLYWOOD"
  },
  "33024": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOLLYWOOD"
  },
  "33025": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOLLYWOOD"
  },
  "33026": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOLLYWOOD"
  },
  "33027": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOLLYWOOD"
  },
  "33028": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOLLYWOOD"
  },
  "33029": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOLLYWOOD"
  },
  "33030": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOMESTEAD"
  },
  "33031": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOMESTEAD"
  },
  "33032": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOMESTEAD"
  },
  "33033": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOMESTEAD"
  },
  "33034": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOMESTEAD"
  },
  "33035": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOMESTEAD"
  },
  "33036": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ISLAMORADA"
  },
  "33037": {
    stateCode: "FL",
    stateName: "Florida",
    city: "KEY LARGO"
  },
  "33040": {
    stateCode: "FL",
    stateName: "Florida",
    city: "KEY WEST"
  },
  "33042": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SUMMERLAND KEY"
  },
  "33043": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BIG PINE KEY"
  },
  "33050": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MARATHON"
  },
  "33054": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OPA LOCKA"
  },
  "33055": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OPA LOCKA"
  },
  "33056": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OPA LOCKA"
  },
  "33060": {
    stateCode: "FL",
    stateName: "Florida",
    city: "POMPANO BEACH"
  },
  "33062": {
    stateCode: "FL",
    stateName: "Florida",
    city: "POMPANO BEACH"
  },
  "33063": {
    stateCode: "FL",
    stateName: "Florida",
    city: "POMPANO BEACH"
  },
  "33064": {
    stateCode: "FL",
    stateName: "Florida",
    city: "POMPANO BEACH"
  },
  "33065": {
    stateCode: "FL",
    stateName: "Florida",
    city: "POMPANO BEACH"
  },
  "33066": {
    stateCode: "FL",
    stateName: "Florida",
    city: "POMPANO BEACH"
  },
  "33067": {
    stateCode: "FL",
    stateName: "Florida",
    city: "POMPANO BEACH"
  },
  "33068": {
    stateCode: "FL",
    stateName: "Florida",
    city: "POMPANO BEACH"
  },
  "33069": {
    stateCode: "FL",
    stateName: "Florida",
    city: "POMPANO BEACH"
  },
  "33070": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAVERNIER"
  },
  "33071": {
    stateCode: "FL",
    stateName: "Florida",
    city: "POMPANO BEACH"
  },
  "33073": {
    stateCode: "FL",
    stateName: "Florida",
    city: "POMPANO BEACH"
  },
  "33076": {
    stateCode: "FL",
    stateName: "Florida",
    city: "POMPANO BEACH"
  },
  "33109": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI BEACH"
  },
  "33125": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33126": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33127": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33128": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33129": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33130": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33131": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33132": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33133": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33134": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33135": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33136": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33137": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33138": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33139": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI BEACH"
  },
  "33140": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI BEACH"
  },
  "33141": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI BEACH"
  },
  "33142": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33143": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33144": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33145": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33146": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33147": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33149": {
    stateCode: "FL",
    stateName: "Florida",
    city: "KEY BISCAYNE"
  },
  "33150": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33154": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI BEACH"
  },
  "33155": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33156": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33157": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33158": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33160": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NORTH MIAMI BEACH"
  },
  "33161": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33162": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33165": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33166": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33167": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33168": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33169": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33170": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33172": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33173": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33174": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33175": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33176": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33177": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33178": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33179": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33180": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33181": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33182": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33183": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33184": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33185": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33186": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33187": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33189": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33190": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33193": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33196": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MIAMI"
  },
  "33301": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33304": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33305": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33306": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33308": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33309": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33311": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33312": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33313": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33314": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33315": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33316": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33317": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33319": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33321": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33322": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33323": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33324": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33325": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33326": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33327": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33328": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33330": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33331": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33332": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33334": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33351": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT LAUDERDALE"
  },
  "33401": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WEST PALM BEACH"
  },
  "33403": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WEST PALM BEACH"
  },
  "33404": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WEST PALM BEACH"
  },
  "33405": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WEST PALM BEACH"
  },
  "33406": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WEST PALM BEACH"
  },
  "33407": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WEST PALM BEACH"
  },
  "33408": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NORTH PALM BEACH"
  },
  "33409": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WEST PALM BEACH"
  },
  "33410": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PALM BEACH GARDENS"
  },
  "33411": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WEST PALM BEACH"
  },
  "33412": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WEST PALM BEACH"
  },
  "33413": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WEST PALM BEACH"
  },
  "33414": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WEST PALM BEACH"
  },
  "33415": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WEST PALM BEACH"
  },
  "33417": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WEST PALM BEACH"
  },
  "33418": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PALM BEACH GARDENS"
  },
  "33426": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BOYNTON BEACH"
  },
  "33428": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BOCA RATON"
  },
  "33430": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BELLE GLADE"
  },
  "33431": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BOCA RATON"
  },
  "33432": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BOCA RATON"
  },
  "33433": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BOCA RATON"
  },
  "33434": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BOCA RATON"
  },
  "33435": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BOYNTON BEACH"
  },
  "33436": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BOYNTON BEACH"
  },
  "33437": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BOYNTON BEACH"
  },
  "33438": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CANAL POINT"
  },
  "33440": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CLEWISTON"
  },
  "33441": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DEERFIELD BEACH"
  },
  "33442": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DEERFIELD BEACH"
  },
  "33444": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DELRAY BEACH"
  },
  "33445": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DELRAY BEACH"
  },
  "33446": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DELRAY BEACH"
  },
  "33455": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOBE SOUND"
  },
  "33458": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JUPITER"
  },
  "33460": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKE WORTH"
  },
  "33461": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKE WORTH"
  },
  "33462": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKE WORTH"
  },
  "33463": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKE WORTH"
  },
  "33467": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKE WORTH"
  },
  "33469": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JUPITER"
  },
  "33470": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LOXAHATCHEE"
  },
  "33471": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MOORE HAVEN"
  },
  "33476": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PAHOKEE"
  },
  "33477": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JUPITER"
  },
  "33478": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JUPITER"
  },
  "33480": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PALM BEACH"
  },
  "33483": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DELRAY BEACH"
  },
  "33484": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DELRAY BEACH"
  },
  "33486": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BOCA RATON"
  },
  "33487": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BOCA RATON"
  },
  "33493": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SOUTH BAY"
  },
  "33496": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BOCA RATON"
  },
  "33498": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BOCA RATON"
  },
  "33510": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BRANDON"
  },
  "33511": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BRANDON"
  },
  "33513": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BUSHNELL"
  },
  "33514": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CENTER HILL"
  },
  "33521": {
    stateCode: "FL",
    stateName: "Florida",
    city: "COLEMAN"
  },
  "33523": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DADE CITY"
  },
  "33525": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DADE CITY"
  },
  "33527": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DOVER"
  },
  "33534": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GIBSONTON"
  },
  "33538": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKE PANASOFFKEE"
  },
  "33540": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ZEPHYRHILLS"
  },
  "33541": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ZEPHYRHILLS"
  },
  "33543": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WESLEY CHAPEL"
  },
  "33544": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WESLEY CHAPEL"
  },
  "33547": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LITHIA"
  },
  "33549": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LUTZ"
  },
  "33556": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ODESSA"
  },
  "33565": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PLANT CITY"
  },
  "33566": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PLANT CITY"
  },
  "33567": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PLANT CITY"
  },
  "33569": {
    stateCode: "FL",
    stateName: "Florida",
    city: "RIVERVIEW"
  },
  "33570": {
    stateCode: "FL",
    stateName: "Florida",
    city: "RUSKIN"
  },
  "33572": {
    stateCode: "FL",
    stateName: "Florida",
    city: "APOLLO BEACH"
  },
  "33573": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SUN CITY CENTER"
  },
  "33576": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAN ANTONIO"
  },
  "33584": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SEFFNER"
  },
  "33585": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SUMTERVILLE"
  },
  "33592": {
    stateCode: "FL",
    stateName: "Florida",
    city: "THONOTOSASSA"
  },
  "33594": {
    stateCode: "FL",
    stateName: "Florida",
    city: "VALRICO"
  },
  "33597": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WEBSTER"
  },
  "33598": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WIMAUMA"
  },
  "33602": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33603": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33604": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33605": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33606": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33607": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33609": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33610": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33611": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33612": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33613": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33614": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33615": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33616": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33617": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33618": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33619": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33621": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33624": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33625": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33626": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33629": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33634": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33635": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33637": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33647": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TAMPA"
  },
  "33701": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT PETERSBURG"
  },
  "33702": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT PETERSBURG"
  },
  "33703": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT PETERSBURG"
  },
  "33704": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT PETERSBURG"
  },
  "33705": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT PETERSBURG"
  },
  "33706": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT PETERSBURG"
  },
  "33707": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT PETERSBURG"
  },
  "33708": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT PETERSBURG"
  },
  "33709": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT PETERSBURG"
  },
  "33710": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT PETERSBURG"
  },
  "33711": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT PETERSBURG"
  },
  "33712": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT PETERSBURG"
  },
  "33713": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT PETERSBURG"
  },
  "33714": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT PETERSBURG"
  },
  "33715": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT PETERSBURG"
  },
  "33716": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT PETERSBURG"
  },
  "33755": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CLEARWATER"
  },
  "33756": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CLEARWATER"
  },
  "33759": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CLEARWATER"
  },
  "33760": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CLEARWATER"
  },
  "33761": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CLEARWATER"
  },
  "33762": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CLEARWATER"
  },
  "33763": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CLEARWATER"
  },
  "33764": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CLEARWATER"
  },
  "33765": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CLEARWATER"
  },
  "33767": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CLEARWATER BEACH"
  },
  "33770": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LARGO"
  },
  "33771": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LARGO"
  },
  "33772": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SEMINOLE"
  },
  "33773": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LARGO"
  },
  "33774": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LARGO"
  },
  "33776": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SEMINOLE"
  },
  "33777": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SEMINOLE"
  },
  "33778": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LARGO"
  },
  "33781": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PINELLAS PARK"
  },
  "33782": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PINELLAS PARK"
  },
  "33785": {
    stateCode: "FL",
    stateName: "Florida",
    city: "INDIAN ROCKS BEACH"
  },
  "33786": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BELLEAIR BEACH"
  },
  "33801": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKELAND"
  },
  "33803": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKELAND"
  },
  "33805": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKELAND"
  },
  "33809": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKELAND"
  },
  "33810": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKELAND"
  },
  "33811": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKELAND"
  },
  "33813": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKELAND"
  },
  "33815": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKELAND"
  },
  "33823": {
    stateCode: "FL",
    stateName: "Florida",
    city: "AUBURNDALE"
  },
  "33825": {
    stateCode: "FL",
    stateName: "Florida",
    city: "AVON PARK"
  },
  "33827": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BABSON PARK"
  },
  "33830": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BARTOW"
  },
  "33834": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BOWLING GREEN"
  },
  "33837": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DAVENPORT"
  },
  "33838": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DUNDEE"
  },
  "33839": {
    stateCode: "FL",
    stateName: "Florida",
    city: "EAGLE LAKE"
  },
  "33841": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT MEADE"
  },
  "33843": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FROSTPROOF"
  },
  "33844": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HAINES CITY"
  },
  "33847": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOMELAND"
  },
  "33848": {
    stateCode: "FL",
    stateName: "Florida",
    city: "INTERCESSION CITY"
  },
  "33849": {
    stateCode: "FL",
    stateName: "Florida",
    city: "KATHLEEN"
  },
  "33850": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKE ALFRED"
  },
  "33851": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKE HAMILTON"
  },
  "33852": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKE PLACID"
  },
  "33853": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAKE WALES"
  },
  "33857": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LORIDA"
  },
  "33860": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MULBERRY"
  },
  "33865": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ONA"
  },
  "33868": {
    stateCode: "FL",
    stateName: "Florida",
    city: "POLK CITY"
  },
  "33870": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SEBRING"
  },
  "33872": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SEBRING"
  },
  "33873": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WAUCHULA"
  },
  "33877": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WAVERLY"
  },
  "33880": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WINTER HAVEN"
  },
  "33881": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WINTER HAVEN"
  },
  "33884": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WINTER HAVEN"
  },
  "33890": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ZOLFO SPRINGS"
  },
  "33901": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT MYERS"
  },
  "33903": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NORTH FORT MYERS"
  },
  "33904": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CAPE CORAL"
  },
  "33905": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT MYERS"
  },
  "33907": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT MYERS"
  },
  "33908": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT MYERS"
  },
  "33909": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CAPE CORAL"
  },
  "33912": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT MYERS"
  },
  "33913": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT MYERS"
  },
  "33914": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CAPE CORAL"
  },
  "33916": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT MYERS"
  },
  "33917": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NORTH FORT MYERS"
  },
  "33919": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT MYERS"
  },
  "33920": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ALVA"
  },
  "33921": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BOCA GRANDE"
  },
  "33922": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BOKEELIA"
  },
  "33924": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CAPTIVA"
  },
  "33928": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ESTERO"
  },
  "33930": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FELDA"
  },
  "33931": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT MYERS BEACH"
  },
  "33935": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LABELLE"
  },
  "33936": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LEHIGH ACRES"
  },
  "33944": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PALMDALE"
  },
  "33945": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PINELAND"
  },
  "33946": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PLACIDA"
  },
  "33947": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ROTONDA WEST"
  },
  "33948": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PORT CHARLOTTE"
  },
  "33950": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PUNTA GORDA"
  },
  "33952": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PORT CHARLOTTE"
  },
  "33953": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PORT CHARLOTTE"
  },
  "33954": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PORT CHARLOTTE"
  },
  "33955": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PUNTA GORDA"
  },
  "33956": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT JAMES CITY"
  },
  "33957": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SANIBEL"
  },
  "33960": {
    stateCode: "FL",
    stateName: "Florida",
    city: "VENUS"
  },
  "33971": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LEHIGH ACRES"
  },
  "33972": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LEHIGH ACRES"
  },
  "33980": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PUNTA GORDA"
  },
  "33981": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PORT CHARLOTTE"
  },
  "33982": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PUNTA GORDA"
  },
  "33983": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PUNTA GORDA"
  },
  "33990": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CAPE CORAL"
  },
  "33991": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CAPE CORAL"
  },
  "33993": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CAPE CORAL"
  },
  "34102": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NAPLES"
  },
  "34103": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NAPLES"
  },
  "34104": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NAPLES"
  },
  "34105": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NAPLES"
  },
  "34108": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NAPLES"
  },
  "34109": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NAPLES"
  },
  "34110": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NAPLES"
  },
  "34112": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NAPLES"
  },
  "34113": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NAPLES"
  },
  "34114": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NAPLES"
  },
  "34116": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NAPLES"
  },
  "34117": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NAPLES"
  },
  "34119": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NAPLES"
  },
  "34120": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NAPLES"
  },
  "34134": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BONITA SPRINGS"
  },
  "34135": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BONITA SPRINGS"
  },
  "34137": {
    stateCode: "FL",
    stateName: "Florida",
    city: "COPELAND"
  },
  "34138": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CHOKOLOSKEE"
  },
  "34139": {
    stateCode: "FL",
    stateName: "Florida",
    city: "EVERGLADES CITY"
  },
  "34141": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OCHOPEE"
  },
  "34142": {
    stateCode: "FL",
    stateName: "Florida",
    city: "IMMOKALEE"
  },
  "34145": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MARCO ISLAND"
  },
  "34201": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BRADENTON"
  },
  "34202": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BRADENTON"
  },
  "34203": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BRADENTON"
  },
  "34205": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BRADENTON"
  },
  "34207": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BRADENTON"
  },
  "34208": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BRADENTON"
  },
  "34209": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BRADENTON"
  },
  "34210": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BRADENTON"
  },
  "34215": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CORTEZ"
  },
  "34216": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ANNA MARIA"
  },
  "34217": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BRADENTON BEACH"
  },
  "34219": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PARRISH"
  },
  "34221": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PALMETTO"
  },
  "34222": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ELLENTON"
  },
  "34223": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ENGLEWOOD"
  },
  "34224": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ENGLEWOOD"
  },
  "34228": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LONGBOAT KEY"
  },
  "34229": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OSPREY"
  },
  "34231": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SARASOTA"
  },
  "34232": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SARASOTA"
  },
  "34233": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SARASOTA"
  },
  "34234": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SARASOTA"
  },
  "34235": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SARASOTA"
  },
  "34236": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SARASOTA"
  },
  "34237": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SARASOTA"
  },
  "34238": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SARASOTA"
  },
  "34239": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SARASOTA"
  },
  "34240": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SARASOTA"
  },
  "34241": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SARASOTA"
  },
  "34242": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SARASOTA"
  },
  "34243": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SARASOTA"
  },
  "34250": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TERRA CEIA"
  },
  "34251": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MYAKKA CITY"
  },
  "34266": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ARCADIA"
  },
  "34268": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NOCATEE"
  },
  "34275": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NOKOMIS"
  },
  "34285": {
    stateCode: "FL",
    stateName: "Florida",
    city: "VENICE"
  },
  "34286": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NORTH PORT"
  },
  "34287": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NORTH PORT"
  },
  "34292": {
    stateCode: "FL",
    stateName: "Florida",
    city: "VENICE"
  },
  "34293": {
    stateCode: "FL",
    stateName: "Florida",
    city: "VENICE"
  },
  "34420": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BELLEVIEW"
  },
  "34428": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CRYSTAL RIVER"
  },
  "34429": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CRYSTAL RIVER"
  },
  "34431": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DUNNELLON"
  },
  "34432": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DUNNELLON"
  },
  "34433": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DUNNELLON"
  },
  "34434": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DUNNELLON"
  },
  "34436": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FLORAL CITY"
  },
  "34442": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HERNANDO"
  },
  "34446": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOMOSASSA"
  },
  "34448": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOMOSASSA"
  },
  "34449": {
    stateCode: "FL",
    stateName: "Florida",
    city: "INGLIS"
  },
  "34450": {
    stateCode: "FL",
    stateName: "Florida",
    city: "INVERNESS"
  },
  "34452": {
    stateCode: "FL",
    stateName: "Florida",
    city: "INVERNESS"
  },
  "34453": {
    stateCode: "FL",
    stateName: "Florida",
    city: "INVERNESS"
  },
  "34461": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LECANTO"
  },
  "34465": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BEVERLY HILLS"
  },
  "34470": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OCALA"
  },
  "34471": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OCALA"
  },
  "34472": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OCALA"
  },
  "34473": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OCALA"
  },
  "34474": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OCALA"
  },
  "34475": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OCALA"
  },
  "34476": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OCALA"
  },
  "34479": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OCALA"
  },
  "34480": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OCALA"
  },
  "34481": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OCALA"
  },
  "34482": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OCALA"
  },
  "34484": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OXFORD"
  },
  "34488": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SILVER SPRINGS"
  },
  "34491": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SUMMERFIELD"
  },
  "34498": {
    stateCode: "FL",
    stateName: "Florida",
    city: "YANKEETOWN"
  },
  "34601": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BROOKSVILLE"
  },
  "34602": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BROOKSVILLE"
  },
  "34606": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SPRING HILL"
  },
  "34607": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SPRING HILL"
  },
  "34608": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SPRING HILL"
  },
  "34609": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SPRING HILL"
  },
  "34610": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SPRING HILL"
  },
  "34613": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BROOKSVILLE"
  },
  "34614": {
    stateCode: "FL",
    stateName: "Florida",
    city: "BROOKSVILLE"
  },
  "34639": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LAND O LAKES"
  },
  "34652": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NEW PORT RICHEY"
  },
  "34653": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NEW PORT RICHEY"
  },
  "34654": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NEW PORT RICHEY"
  },
  "34655": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NEW PORT RICHEY"
  },
  "34661": {
    stateCode: "FL",
    stateName: "Florida",
    city: "NOBLETON"
  },
  "34667": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HUDSON"
  },
  "34668": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PORT RICHEY"
  },
  "34669": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HUDSON"
  },
  "34677": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OLDSMAR"
  },
  "34679": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ARIPEKA"
  },
  "34681": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CRYSTAL BEACH"
  },
  "34683": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PALM HARBOR"
  },
  "34684": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PALM HARBOR"
  },
  "34685": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PALM HARBOR"
  },
  "34689": {
    stateCode: "FL",
    stateName: "Florida",
    city: "TARPON SPRINGS"
  },
  "34690": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOLIDAY"
  },
  "34691": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOLIDAY"
  },
  "34695": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAFETY HARBOR"
  },
  "34698": {
    stateCode: "FL",
    stateName: "Florida",
    city: "DUNEDIN"
  },
  "34705": {
    stateCode: "FL",
    stateName: "Florida",
    city: "ASTATULA"
  },
  "34711": {
    stateCode: "FL",
    stateName: "Florida",
    city: "CLERMONT"
  },
  "34731": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FRUITLAND PARK"
  },
  "34734": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GOTHA"
  },
  "34736": {
    stateCode: "FL",
    stateName: "Florida",
    city: "GROVELAND"
  },
  "34737": {
    stateCode: "FL",
    stateName: "Florida",
    city: "HOWEY IN THE HILLS"
  },
  "34739": {
    stateCode: "FL",
    stateName: "Florida",
    city: "KENANSVILLE"
  },
  "34741": {
    stateCode: "FL",
    stateName: "Florida",
    city: "KISSIMMEE"
  },
  "34743": {
    stateCode: "FL",
    stateName: "Florida",
    city: "KISSIMMEE"
  },
  "34744": {
    stateCode: "FL",
    stateName: "Florida",
    city: "KISSIMMEE"
  },
  "34746": {
    stateCode: "FL",
    stateName: "Florida",
    city: "KISSIMMEE"
  },
  "34747": {
    stateCode: "FL",
    stateName: "Florida",
    city: "KISSIMMEE"
  },
  "34748": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LEESBURG"
  },
  "34753": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MASCOTTE"
  },
  "34756": {
    stateCode: "FL",
    stateName: "Florida",
    city: "MONTVERDE"
  },
  "34758": {
    stateCode: "FL",
    stateName: "Florida",
    city: "KISSIMMEE"
  },
  "34759": {
    stateCode: "FL",
    stateName: "Florida",
    city: "KISSIMMEE"
  },
  "34760": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OAKLAND"
  },
  "34761": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OCOEE"
  },
  "34762": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OKAHUMPKA"
  },
  "34769": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT CLOUD"
  },
  "34771": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT CLOUD"
  },
  "34772": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT CLOUD"
  },
  "34773": {
    stateCode: "FL",
    stateName: "Florida",
    city: "SAINT CLOUD"
  },
  "34785": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WILDWOOD"
  },
  "34786": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WINDERMERE"
  },
  "34787": {
    stateCode: "FL",
    stateName: "Florida",
    city: "WINTER GARDEN"
  },
  "34788": {
    stateCode: "FL",
    stateName: "Florida",
    city: "LEESBURG"
  },
  "34797": {
    stateCode: "FL",
    stateName: "Florida",
    city: "YALAHA"
  },
  "34945": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT PIERCE"
  },
  "34946": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT PIERCE"
  },
  "34947": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT PIERCE"
  },
  "34949": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT PIERCE"
  },
  "34950": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT PIERCE"
  },
  "34951": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT PIERCE"
  },
  "34952": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PORT SAINT LUCIE"
  },
  "34953": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PORT SAINT LUCIE"
  },
  "34956": {
    stateCode: "FL",
    stateName: "Florida",
    city: "INDIANTOWN"
  },
  "34957": {
    stateCode: "FL",
    stateName: "Florida",
    city: "JENSEN BEACH"
  },
  "34972": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OKEECHOBEE"
  },
  "34974": {
    stateCode: "FL",
    stateName: "Florida",
    city: "OKEECHOBEE"
  },
  "34981": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT PIERCE"
  },
  "34982": {
    stateCode: "FL",
    stateName: "Florida",
    city: "FORT PIERCE"
  },
  "34983": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PORT SAINT LUCIE"
  },
  "34984": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PORT SAINT LUCIE"
  },
  "34986": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PORT SAINT LUCIE"
  },
  "34987": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PORT SAINT LUCIE"
  },
  "34990": {
    stateCode: "FL",
    stateName: "Florida",
    city: "PALM CITY"
  },
  "34994": {
    stateCode: "FL",
    stateName: "Florida",
    city: "STUART"
  },
  "34996": {
    stateCode: "FL",
    stateName: "Florida",
    city: "STUART"
  },
  "34997": {
    stateCode: "FL",
    stateName: "Florida",
    city: "STUART"
  },
  "35004": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOODY"
  },
  "35005": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ADAMSVILLE"
  },
  "35006": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ADGER"
  },
  "35007": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ALABASTER"
  },
  "35010": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ALEXANDER CITY"
  },
  "35014": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ALPINE"
  },
  "35016": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ARAB"
  },
  "35019": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BAILEYTON"
  },
  "35020": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BESSEMER"
  },
  "35022": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BESSEMER"
  },
  "35023": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BESSEMER"
  },
  "35031": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BLOUNTSVILLE"
  },
  "35033": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BREMEN"
  },
  "35034": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BRENT"
  },
  "35035": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BRIERFIELD"
  },
  "35036": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BROOKSIDE"
  },
  "35040": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CALERA"
  },
  "35042": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CENTREVILLE"
  },
  "35043": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CHELSEA"
  },
  "35044": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CHILDERSBURG"
  },
  "35045": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CLANTON"
  },
  "35046": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CLANTON"
  },
  "35049": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CLEVELAND"
  },
  "35051": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "COLUMBIANA"
  },
  "35052": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "COOK SPRINGS"
  },
  "35053": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CRANE HILL"
  },
  "35054": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CROPWELL"
  },
  "35055": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CULLMAN"
  },
  "35057": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CULLMAN"
  },
  "35058": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CULLMAN"
  },
  "35060": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DOCENA"
  },
  "35061": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DOLOMITE"
  },
  "35062": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DORA"
  },
  "35063": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "EMPIRE"
  },
  "35064": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FAIRFIELD"
  },
  "35068": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FULTONDALE"
  },
  "35070": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GARDEN CITY"
  },
  "35071": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GARDENDALE"
  },
  "35072": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GOODWATER"
  },
  "35073": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GRAYSVILLE"
  },
  "35074": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GREEN POND"
  },
  "35077": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HANCEVILLE"
  },
  "35078": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HARPERSVILLE"
  },
  "35079": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HAYDEN"
  },
  "35080": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HELENA"
  },
  "35082": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HOLLINS"
  },
  "35083": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HOLLY POND"
  },
  "35085": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "JEMISON"
  },
  "35087": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "JOPPA"
  },
  "35089": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "KELLYTON"
  },
  "35091": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "KIMBERLY"
  },
  "35094": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LEEDS"
  },
  "35096": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LINCOLN"
  },
  "35097": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LOCUST FORK"
  },
  "35098": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LOGAN"
  },
  "35111": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MC CALLA"
  },
  "35112": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MARGARET"
  },
  "35114": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MAYLENE"
  },
  "35115": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MONTEVALLO"
  },
  "35116": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MORRIS"
  },
  "35117": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOUNT OLIVE"
  },
  "35118": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MULGA"
  },
  "35119": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "NEW CASTLE"
  },
  "35120": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ODENVILLE"
  },
  "35121": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ONEONTA"
  },
  "35124": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PELHAM"
  },
  "35125": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PELL CITY"
  },
  "35126": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PINSON"
  },
  "35127": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PLEASANT GROVE"
  },
  "35128": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PELL CITY"
  },
  "35130": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "QUINTON"
  },
  "35131": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "RAGLAND"
  },
  "35133": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "REMLAP"
  },
  "35135": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "RIVERSIDE"
  },
  "35136": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ROCKFORD"
  },
  "35139": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SAYRE"
  },
  "35143": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SHELBY"
  },
  "35146": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SPRINGVILLE"
  },
  "35147": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "STERRETT"
  },
  "35148": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SUMITON"
  },
  "35149": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SYCAMORE"
  },
  "35150": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SYLACAUGA"
  },
  "35151": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SYLACAUGA"
  },
  "35160": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TALLADEGA"
  },
  "35171": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "THORSBY"
  },
  "35172": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TRAFFORD"
  },
  "35173": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TRUSSVILLE"
  },
  "35175": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "UNION GROVE"
  },
  "35176": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "VANDIVER"
  },
  "35178": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "VINCENT"
  },
  "35179": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "VINEMONT"
  },
  "35180": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WARRIOR"
  },
  "35183": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WEOGUFKA"
  },
  "35184": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WEST BLOCTON"
  },
  "35186": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WILSONVILLE"
  },
  "35188": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WOODSTOCK"
  },
  "35203": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35204": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35205": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35206": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35207": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35208": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35209": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35210": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35211": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35212": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35213": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35214": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35215": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35216": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35217": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35218": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35221": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35222": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35223": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35224": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35226": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35228": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35233": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35234": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35235": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35242": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35243": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35244": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BIRMINGHAM"
  },
  "35401": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TUSCALOOSA"
  },
  "35404": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TUSCALOOSA"
  },
  "35405": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TUSCALOOSA"
  },
  "35406": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TUSCALOOSA"
  },
  "35441": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "AKRON"
  },
  "35442": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ALICEVILLE"
  },
  "35443": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BOLIGEE"
  },
  "35444": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BROOKWOOD"
  },
  "35446": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BUHL"
  },
  "35447": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CARROLLTON"
  },
  "35452": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "COKER"
  },
  "35453": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "COTTONDALE"
  },
  "35456": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DUNCANVILLE"
  },
  "35457": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ECHOLA"
  },
  "35458": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ELROD"
  },
  "35459": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "EMELLE"
  },
  "35460": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "EPES"
  },
  "35461": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ETHELSVILLE"
  },
  "35462": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "EUTAW"
  },
  "35463": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FOSTERS"
  },
  "35464": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GAINESVILLE"
  },
  "35466": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GORDO"
  },
  "35469": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "KNOXVILLE"
  },
  "35470": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LIVINGSTON"
  },
  "35471": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MC SHAN"
  },
  "35473": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "NORTHPORT"
  },
  "35474": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOUNDVILLE"
  },
  "35475": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "NORTHPORT"
  },
  "35476": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "NORTHPORT"
  },
  "35477": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PANOLA"
  },
  "35480": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "RALPH"
  },
  "35481": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "REFORM"
  },
  "35490": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "VANCE"
  },
  "35501": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "JASPER"
  },
  "35503": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "JASPER"
  },
  "35504": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "JASPER"
  },
  "35540": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ADDISON"
  },
  "35541": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ARLEY"
  },
  "35542": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BANKSTON"
  },
  "35543": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BEAR CREEK"
  },
  "35544": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BEAVERTON"
  },
  "35545": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BELK"
  },
  "35546": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BERRY"
  },
  "35548": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BRILLIANT"
  },
  "35549": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CARBON HILL"
  },
  "35550": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CORDOVA"
  },
  "35552": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DETROIT"
  },
  "35553": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DOUBLE SPRINGS"
  },
  "35554": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ELDRIDGE"
  },
  "35555": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FAYETTE"
  },
  "35559": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GLEN ALLEN"
  },
  "35563": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GUIN"
  },
  "35564": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HACKLEBURG"
  },
  "35565": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HALEYVILLE"
  },
  "35570": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HAMILTON"
  },
  "35571": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HODGES"
  },
  "35572": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HOUSTON"
  },
  "35574": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "KENNEDY"
  },
  "35575": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LYNN"
  },
  "35576": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MILLPORT"
  },
  "35578": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "NAUVOO"
  },
  "35579": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "OAKMAN"
  },
  "35580": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PARRISH"
  },
  "35581": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PHIL CAMPBELL"
  },
  "35582": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "RED BAY"
  },
  "35584": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SIPSEY"
  },
  "35585": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SPRUCE PINE"
  },
  "35586": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SULLIGENT"
  },
  "35587": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TOWNLEY"
  },
  "35592": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "VERNON"
  },
  "35593": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "VINA"
  },
  "35594": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WINFIELD"
  },
  "35601": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DECATUR"
  },
  "35603": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DECATUR"
  },
  "35610": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ANDERSON"
  },
  "35611": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ATHENS"
  },
  "35613": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ATHENS"
  },
  "35614": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ATHENS"
  },
  "35616": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CHEROKEE"
  },
  "35618": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "COURTLAND"
  },
  "35619": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DANVILLE"
  },
  "35620": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ELKMONT"
  },
  "35621": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "EVA"
  },
  "35622": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FALKVILLE"
  },
  "35630": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FLORENCE"
  },
  "35633": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FLORENCE"
  },
  "35634": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FLORENCE"
  },
  "35640": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HARTSELLE"
  },
  "35643": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HILLSBORO"
  },
  "35645": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "KILLEN"
  },
  "35646": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LEIGHTON"
  },
  "35647": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LESTER"
  },
  "35648": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LEXINGTON"
  },
  "35649": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOORESVILLE"
  },
  "35650": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOULTON"
  },
  "35651": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOUNT HOPE"
  },
  "35652": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ROGERSVILLE"
  },
  "35653": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "RUSSELLVILLE"
  },
  "35654": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "RUSSELLVILLE"
  },
  "35660": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SHEFFIELD"
  },
  "35661": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MUSCLE SHOALS"
  },
  "35670": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SOMERVILLE"
  },
  "35671": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TANNER"
  },
  "35672": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TOWN CREEK"
  },
  "35673": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TRINITY"
  },
  "35674": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TUSCUMBIA"
  },
  "35677": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WATERLOO"
  },
  "35739": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ARDMORE"
  },
  "35740": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BRIDGEPORT"
  },
  "35741": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BROWNSBORO"
  },
  "35744": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DUTTON"
  },
  "35745": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ESTILLFORK"
  },
  "35746": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FACKLER"
  },
  "35747": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GRANT"
  },
  "35748": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GURLEY"
  },
  "35749": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HARVEST"
  },
  "35750": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HAZEL GREEN"
  },
  "35751": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HOLLYTREE"
  },
  "35752": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HOLLYWOOD"
  },
  "35754": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LACEYS SPRING"
  },
  "35755": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LANGSTON"
  },
  "35756": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MADISON"
  },
  "35757": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MADISON"
  },
  "35758": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MADISON"
  },
  "35759": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MERIDIANVILLE"
  },
  "35760": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "NEW HOPE"
  },
  "35761": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "NEW MARKET"
  },
  "35763": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "OWENS CROSS ROADS"
  },
  "35764": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PAINT ROCK"
  },
  "35765": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PISGAH"
  },
  "35766": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PRINCETON"
  },
  "35768": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SCOTTSBORO"
  },
  "35769": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SCOTTSBORO"
  },
  "35771": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SECTION"
  },
  "35772": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "STEVENSON"
  },
  "35773": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TONEY"
  },
  "35774": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TRENTON"
  },
  "35775": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "VALHERMOSO SPRINGS"
  },
  "35776": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WOODVILLE"
  },
  "35801": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HUNTSVILLE"
  },
  "35802": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HUNTSVILLE"
  },
  "35803": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HUNTSVILLE"
  },
  "35805": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HUNTSVILLE"
  },
  "35806": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HUNTSVILLE"
  },
  "35808": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HUNTSVILLE"
  },
  "35810": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HUNTSVILLE"
  },
  "35811": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HUNTSVILLE"
  },
  "35816": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HUNTSVILLE"
  },
  "35824": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HUNTSVILLE"
  },
  "35901": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GADSDEN"
  },
  "35903": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GADSDEN"
  },
  "35904": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GADSDEN"
  },
  "35905": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GADSDEN"
  },
  "35906": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "RAINBOW CITY"
  },
  "35907": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GADSDEN"
  },
  "35950": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ALBERTVILLE"
  },
  "35951": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ALBERTVILLE"
  },
  "35952": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ALTOONA"
  },
  "35953": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ASHVILLE"
  },
  "35954": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ATTALLA"
  },
  "35956": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BOAZ"
  },
  "35957": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BOAZ"
  },
  "35958": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BRYANT"
  },
  "35959": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CEDAR BLUFF"
  },
  "35960": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CENTRE"
  },
  "35961": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "COLLINSVILLE"
  },
  "35962": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CROSSVILLE"
  },
  "35963": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DAWSON"
  },
  "35966": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FLAT ROCK"
  },
  "35967": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FORT PAYNE"
  },
  "35968": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FORT PAYNE"
  },
  "35971": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FYFFE"
  },
  "35972": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GALLANT"
  },
  "35973": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GAYLESVILLE"
  },
  "35974": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GERALDINE"
  },
  "35975": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GROVEOAK"
  },
  "35976": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GUNTERSVILLE"
  },
  "35978": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HENAGAR"
  },
  "35979": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HIGDON"
  },
  "35980": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HORTON"
  },
  "35981": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "IDER"
  },
  "35983": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LEESBURG"
  },
  "35984": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MENTONE"
  },
  "35986": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "RAINSVILLE"
  },
  "35987": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "STEELE"
  },
  "35988": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SYLVANIA"
  },
  "35989": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "VALLEY HEAD"
  },
  "35990": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WALNUT GROVE"
  },
  "36003": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "AUTAUGAVILLE"
  },
  "36005": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BANKS"
  },
  "36006": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BILLINGSLEY"
  },
  "36009": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BRANTLEY"
  },
  "36010": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BRUNDIDGE"
  },
  "36013": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CECIL"
  },
  "36016": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CLAYTON"
  },
  "36017": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CLIO"
  },
  "36020": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "COOSADA"
  },
  "36022": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DEATSVILLE"
  },
  "36024": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ECLECTIC"
  },
  "36025": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ELMORE"
  },
  "36026": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "EQUALITY"
  },
  "36027": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "EUFAULA"
  },
  "36028": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DOZIER"
  },
  "36029": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FITZPATRICK"
  },
  "36030": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FOREST HOME"
  },
  "36031": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FORT DAVIS"
  },
  "36032": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FORT DEPOSIT"
  },
  "36033": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GEORGIANA"
  },
  "36034": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GLENWOOD"
  },
  "36035": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GOSHEN"
  },
  "36036": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GRADY"
  },
  "36037": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GREENVILLE"
  },
  "36039": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HARDAWAY"
  },
  "36040": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HAYNEVILLE"
  },
  "36041": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HIGHLAND HOME"
  },
  "36042": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HONORAVILLE"
  },
  "36043": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HOPE HULL"
  },
  "36046": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LAPINE"
  },
  "36047": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LETOHATCHEE"
  },
  "36048": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LOUISVILLE"
  },
  "36049": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LUVERNE"
  },
  "36051": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MARBURY"
  },
  "36052": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MATHEWS"
  },
  "36053": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MIDWAY"
  },
  "36054": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MILLBROOK"
  },
  "36064": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PIKE ROAD"
  },
  "36065": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PINE LEVEL"
  },
  "36066": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PRATTVILLE"
  },
  "36067": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PRATTVILLE"
  },
  "36069": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "RAMER"
  },
  "36071": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "RUTLEDGE"
  },
  "36075": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SHORTER"
  },
  "36078": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TALLASSEE"
  },
  "36079": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TROY"
  },
  "36080": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TITUS"
  },
  "36081": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TROY"
  },
  "36083": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TUSKEGEE"
  },
  "36088": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TUSKEGEE INSTITUTE"
  },
  "36089": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "UNION SPRINGS"
  },
  "36091": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "VERBENA"
  },
  "36092": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WETUMPKA"
  },
  "36093": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WETUMPKA"
  },
  "36104": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MONTGOMERY"
  },
  "36105": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MONTGOMERY"
  },
  "36106": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MONTGOMERY"
  },
  "36107": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MONTGOMERY"
  },
  "36108": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MONTGOMERY"
  },
  "36109": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MONTGOMERY"
  },
  "36110": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MONTGOMERY"
  },
  "36111": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MONTGOMERY"
  },
  "36113": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MONTGOMERY"
  },
  "36115": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MONTGOMERY"
  },
  "36116": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MONTGOMERY"
  },
  "36117": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MONTGOMERY"
  },
  "36201": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ANNISTON"
  },
  "36203": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "OXFORD"
  },
  "36205": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ANNISTON"
  },
  "36206": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ANNISTON"
  },
  "36207": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ANNISTON"
  },
  "36250": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ALEXANDRIA"
  },
  "36251": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ASHLAND"
  },
  "36255": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CRAGFORD"
  },
  "36256": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DAVISTON"
  },
  "36258": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DELTA"
  },
  "36260": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "EASTABOGA"
  },
  "36262": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FRUITHURST"
  },
  "36263": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GRAHAM"
  },
  "36264": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HEFLIN"
  },
  "36265": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "JACKSONVILLE"
  },
  "36266": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LINEVILLE"
  },
  "36267": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MILLERVILLE"
  },
  "36268": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MUNFORD"
  },
  "36269": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MUSCADINE"
  },
  "36271": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "OHATCHEE"
  },
  "36272": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PIEDMONT"
  },
  "36273": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "RANBURNE"
  },
  "36274": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ROANOKE"
  },
  "36276": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WADLEY"
  },
  "36277": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WEAVER"
  },
  "36278": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WEDOWEE"
  },
  "36279": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WELLINGTON"
  },
  "36280": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WOODLAND"
  },
  "36301": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DOTHAN"
  },
  "36303": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DOTHAN"
  },
  "36305": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DOTHAN"
  },
  "36310": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ABBEVILLE"
  },
  "36311": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ARITON"
  },
  "36312": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ASHFORD"
  },
  "36313": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BELLWOOD"
  },
  "36314": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BLACK"
  },
  "36316": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CHANCELLOR"
  },
  "36317": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CLOPTON"
  },
  "36318": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "COFFEE SPRINGS"
  },
  "36319": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "COLUMBIA"
  },
  "36320": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "COTTONWOOD"
  },
  "36322": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DALEVILLE"
  },
  "36323": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ELBA"
  },
  "36330": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ENTERPRISE"
  },
  "36340": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GENEVA"
  },
  "36343": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GORDON"
  },
  "36344": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HARTFORD"
  },
  "36345": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HEADLAND"
  },
  "36346": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "JACK"
  },
  "36350": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MIDLAND CITY"
  },
  "36351": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "NEW BROCKTON"
  },
  "36352": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "NEWTON"
  },
  "36353": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "NEWVILLE"
  },
  "36360": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "OZARK"
  },
  "36362": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FORT RUCKER"
  },
  "36370": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PANSEY"
  },
  "36371": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PINCKARD"
  },
  "36373": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SHORTERVILLE"
  },
  "36374": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SKIPPERVILLE"
  },
  "36375": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SLOCOMB"
  },
  "36376": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WEBB"
  },
  "36401": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "EVERGREEN"
  },
  "36420": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ANDALUSIA"
  },
  "36425": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BEATRICE"
  },
  "36426": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BREWTON"
  },
  "36432": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CASTLEBERRY"
  },
  "36435": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "COY"
  },
  "36436": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DICKINSON"
  },
  "36441": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FLOMATON"
  },
  "36442": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FLORALA"
  },
  "36444": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FRANKLIN"
  },
  "36445": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FRISCO CITY"
  },
  "36446": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FULTON"
  },
  "36451": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GROVE HILL"
  },
  "36453": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "KINSTON"
  },
  "36455": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LOCKHART"
  },
  "36456": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MC KENZIE"
  },
  "36460": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MONROEVILLE"
  },
  "36467": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "OPP"
  },
  "36470": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PERDUE HILL"
  },
  "36471": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PETERMAN"
  },
  "36473": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "RANGE"
  },
  "36474": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "RED LEVEL"
  },
  "36475": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "REPTON"
  },
  "36476": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "RIVER FALLS"
  },
  "36477": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SAMSON"
  },
  "36480": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "URIAH"
  },
  "36481": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "VREDENBURGH"
  },
  "36482": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WHATLEY"
  },
  "36483": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WING"
  },
  "36501": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ALMA"
  },
  "36502": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ATMORE"
  },
  "36505": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "AXIS"
  },
  "36507": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BAY MINETTE"
  },
  "36509": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BAYOU LA BATRE"
  },
  "36511": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BON SECOUR"
  },
  "36513": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CALVERT"
  },
  "36515": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CARLTON"
  },
  "36518": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CHATOM"
  },
  "36521": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CHUNCHULA"
  },
  "36522": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CITRONELLE"
  },
  "36523": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CODEN"
  },
  "36524": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "COFFEEVILLE"
  },
  "36525": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CREOLA"
  },
  "36526": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DAPHNE"
  },
  "36527": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SPANISH FORT"
  },
  "36528": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DAUPHIN ISLAND"
  },
  "36529": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DEER PARK"
  },
  "36530": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ELBERTA"
  },
  "36532": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FAIRHOPE"
  },
  "36535": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FOLEY"
  },
  "36538": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FRANKVILLE"
  },
  "36539": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FRUITDALE"
  },
  "36540": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GAINESTOWN"
  },
  "36541": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GRAND BAY"
  },
  "36542": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GULF SHORES"
  },
  "36544": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "IRVINGTON"
  },
  "36545": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "JACKSON"
  },
  "36548": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LEROY"
  },
  "36549": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LILLIAN"
  },
  "36550": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LITTLE RIVER"
  },
  "36551": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LOXLEY"
  },
  "36553": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MC INTOSH"
  },
  "36556": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MALCOLM"
  },
  "36558": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MILLRY"
  },
  "36559": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MONTROSE"
  },
  "36560": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOUNT VERNON"
  },
  "36561": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ORANGE BEACH"
  },
  "36562": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PERDIDO"
  },
  "36564": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "POINT CLEAR"
  },
  "36567": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ROBERTSDALE"
  },
  "36569": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SAINT STEPHENS"
  },
  "36571": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SARALAND"
  },
  "36572": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SATSUMA"
  },
  "36574": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SEMINOLE"
  },
  "36575": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SEMMES"
  },
  "36576": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SILVERHILL"
  },
  "36578": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "STAPLETON"
  },
  "36579": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "STOCKTON"
  },
  "36580": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SUMMERDALE"
  },
  "36581": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SUNFLOWER"
  },
  "36582": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "THEODORE"
  },
  "36583": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TIBBIE"
  },
  "36584": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "VINEGAR BEND"
  },
  "36585": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WAGARVILLE"
  },
  "36587": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WILMER"
  },
  "36602": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOBILE"
  },
  "36603": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOBILE"
  },
  "36604": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOBILE"
  },
  "36605": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOBILE"
  },
  "36606": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOBILE"
  },
  "36607": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOBILE"
  },
  "36608": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOBILE"
  },
  "36609": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOBILE"
  },
  "36610": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOBILE"
  },
  "36611": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOBILE"
  },
  "36612": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOBILE"
  },
  "36613": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "EIGHT MILE"
  },
  "36617": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOBILE"
  },
  "36618": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOBILE"
  },
  "36619": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOBILE"
  },
  "36693": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOBILE"
  },
  "36695": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MOBILE"
  },
  "36701": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SELMA"
  },
  "36703": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SELMA"
  },
  "36720": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ALBERTA"
  },
  "36722": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ARLINGTON"
  },
  "36723": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BOYKIN"
  },
  "36726": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CAMDEN"
  },
  "36727": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CAMPBELL"
  },
  "36728": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CATHERINE"
  },
  "36732": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DEMOPOLIS"
  },
  "36736": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DIXONS MILLS"
  },
  "36738": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FAUNSDALE"
  },
  "36740": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FORKLAND"
  },
  "36742": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GALLION"
  },
  "36744": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GREENSBORO"
  },
  "36748": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LINDEN"
  },
  "36749": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "JONES"
  },
  "36750": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MAPLESVILLE"
  },
  "36751": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LOWER PEACH TREE"
  },
  "36752": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LOWNDESBORO"
  },
  "36754": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MAGNOLIA"
  },
  "36756": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MARION"
  },
  "36758": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PLANTERSVILLE"
  },
  "36759": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MARION JUNCTION"
  },
  "36761": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MINTER"
  },
  "36763": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "MYRTLEWOOD"
  },
  "36765": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "NEWBERN"
  },
  "36767": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "ORRVILLE"
  },
  "36768": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PINE APPLE"
  },
  "36769": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PINE HILL"
  },
  "36773": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SAFFORD"
  },
  "36775": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SARDIS"
  },
  "36776": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SAWYERVILLE"
  },
  "36782": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SWEET WATER"
  },
  "36783": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "THOMASTON"
  },
  "36784": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "THOMASVILLE"
  },
  "36785": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TYLER"
  },
  "36786": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "UNIONTOWN"
  },
  "36790": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "STANTON"
  },
  "36792": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "RANDOLPH"
  },
  "36793": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LAWLEY"
  },
  "36801": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "OPELIKA"
  },
  "36804": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "OPELIKA"
  },
  "36830": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "AUBURN"
  },
  "36832": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "AUBURN"
  },
  "36850": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CAMP HILL"
  },
  "36852": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CUSSETA"
  },
  "36853": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "DADEVILLE"
  },
  "36854": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "VALLEY"
  },
  "36855": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FIVE POINTS"
  },
  "36856": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "FORT MITCHELL"
  },
  "36858": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HATCHECHUBBEE"
  },
  "36860": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "HURTSBORO"
  },
  "36861": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "JACKSONS GAP"
  },
  "36862": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LAFAYETTE"
  },
  "36863": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LANETT"
  },
  "36866": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "NOTASULGA"
  },
  "36867": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PHENIX CITY"
  },
  "36869": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PHENIX CITY"
  },
  "36870": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PHENIX CITY"
  },
  "36871": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PITTSVIEW"
  },
  "36874": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SALEM"
  },
  "36875": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SEALE"
  },
  "36877": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SMITHS STATION"
  },
  "36879": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WAVERLY"
  },
  "36901": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BELLAMY"
  },
  "36904": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "BUTLER"
  },
  "36907": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "CUBA"
  },
  "36908": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "GILBERTOWN"
  },
  "36910": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "JACHIN"
  },
  "36912": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "LISMAN"
  },
  "36915": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "NEEDHAM"
  },
  "36916": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "PENNINGTON"
  },
  "36919": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "SILAS"
  },
  "36921": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "TOXEY"
  },
  "36922": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "WARD"
  },
  "36925": {
    stateCode: "AL",
    stateName: "Alabama",
    city: "YORK"
  },
  "37010": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ADAMS"
  },
  "37012": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ALEXANDRIA"
  },
  "37013": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ANTIOCH"
  },
  "37014": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ARRINGTON"
  },
  "37015": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ASHLAND CITY"
  },
  "37016": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "AUBURNTOWN"
  },
  "37018": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BEECHGROVE"
  },
  "37019": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BELFAST"
  },
  "37020": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BELL BUCKLE"
  },
  "37022": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BETHPAGE"
  },
  "37023": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BIG ROCK"
  },
  "37025": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BON AQUA"
  },
  "37026": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BRADYVILLE"
  },
  "37027": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BRENTWOOD"
  },
  "37028": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BUMPUS MILLS"
  },
  "37029": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BURNS"
  },
  "37030": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CARTHAGE"
  },
  "37031": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CASTALIAN SPRINGS"
  },
  "37032": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CEDAR HILL"
  },
  "37033": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CENTERVILLE"
  },
  "37034": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHAPEL HILL"
  },
  "37035": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHAPMANSBORO"
  },
  "37036": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHARLOTTE"
  },
  "37037": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHRISTIANA"
  },
  "37040": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CLARKSVILLE"
  },
  "37042": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CLARKSVILLE"
  },
  "37043": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CLARKSVILLE"
  },
  "37046": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "COLLEGE GROVE"
  },
  "37047": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CORNERSVILLE"
  },
  "37048": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "COTTONTOWN"
  },
  "37049": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CROSS PLAINS"
  },
  "37050": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CUMBERLAND CITY"
  },
  "37051": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CUMBERLAND FURNACE"
  },
  "37052": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CUNNINGHAM"
  },
  "37055": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DICKSON"
  },
  "37057": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DIXON SPRINGS"
  },
  "37058": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DOVER"
  },
  "37059": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DOWELLTOWN"
  },
  "37060": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "EAGLEVILLE"
  },
  "37061": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ERIN"
  },
  "37062": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "FAIRVIEW"
  },
  "37064": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "FRANKLIN"
  },
  "37066": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GALLATIN"
  },
  "37067": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "FRANKLIN"
  },
  "37069": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "FRANKLIN"
  },
  "37072": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GOODLETTSVILLE"
  },
  "37073": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GREENBRIER"
  },
  "37074": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HARTSVILLE"
  },
  "37075": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HENDERSONVILLE"
  },
  "37076": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HERMITAGE"
  },
  "37078": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HURRICANE MILLS"
  },
  "37079": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "INDIAN MOUND"
  },
  "37080": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "JOELTON"
  },
  "37082": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KINGSTON SPRINGS"
  },
  "37083": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LAFAYETTE"
  },
  "37085": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LASCASSAS"
  },
  "37086": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LA VERGNE"
  },
  "37087": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LEBANON"
  },
  "37090": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LEBANON"
  },
  "37091": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LEWISBURG"
  },
  "37095": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LIBERTY"
  },
  "37096": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LINDEN"
  },
  "37097": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LOBELVILLE"
  },
  "37098": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LYLES"
  },
  "37101": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MC EWEN"
  },
  "37110": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MC MINNVILLE"
  },
  "37115": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MADISON"
  },
  "37118": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MILTON"
  },
  "37122": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MOUNT JULIET"
  },
  "37127": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MURFREESBORO"
  },
  "37128": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MURFREESBORO"
  },
  "37129": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MURFREESBORO"
  },
  "37130": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MURFREESBORO"
  },
  "37134": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NEW JOHNSONVILLE"
  },
  "37135": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NOLENSVILLE"
  },
  "37137": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NUNNELLY"
  },
  "37138": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "OLD HICKORY"
  },
  "37140": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ONLY"
  },
  "37141": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ORLINDA"
  },
  "37142": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PALMYRA"
  },
  "37143": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PEGRAM"
  },
  "37144": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PETERSBURG"
  },
  "37145": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PLEASANT SHADE"
  },
  "37146": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PLEASANT VIEW"
  },
  "37148": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PORTLAND"
  },
  "37149": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "READYVILLE"
  },
  "37150": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "RED BOILING SPRINGS"
  },
  "37151": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "RIDDLETON"
  },
  "37152": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "RIDGETOP"
  },
  "37153": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ROCKVALE"
  },
  "37160": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SHELBYVILLE"
  },
  "37166": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SMITHVILLE"
  },
  "37167": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SMYRNA"
  },
  "37171": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SOUTHSIDE"
  },
  "37172": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SPRINGFIELD"
  },
  "37174": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SPRING HILL"
  },
  "37175": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "STEWART"
  },
  "37178": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TENNESSEE RIDGE"
  },
  "37179": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "THOMPSONS STATION"
  },
  "37180": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "UNIONVILLE"
  },
  "37181": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "VANLEER"
  },
  "37183": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WARTRACE"
  },
  "37184": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WATERTOWN"
  },
  "37185": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WAVERLY"
  },
  "37186": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WESTMORELAND"
  },
  "37187": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WHITE BLUFF"
  },
  "37188": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WHITE HOUSE"
  },
  "37189": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WHITES CREEK"
  },
  "37190": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WOODBURY"
  },
  "37191": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WOODLAWN"
  },
  "37201": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37203": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37204": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37205": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37206": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37207": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37208": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37209": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37210": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37211": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37212": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37214": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37215": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37216": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37217": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37218": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37219": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37220": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37221": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37228": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NASHVILLE"
  },
  "37301": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ALTAMONT"
  },
  "37302": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "APISON"
  },
  "37303": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ATHENS"
  },
  "37305": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BEERSHEBA SPRINGS"
  },
  "37306": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BELVIDERE"
  },
  "37307": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BENTON"
  },
  "37308": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BIRCHWOOD"
  },
  "37309": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CALHOUN"
  },
  "37310": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHARLESTON"
  },
  "37311": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CLEVELAND"
  },
  "37312": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CLEVELAND"
  },
  "37313": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "COALMONT"
  },
  "37316": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CONASAUGA"
  },
  "37317": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "COPPERHILL"
  },
  "37318": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "COWAN"
  },
  "37321": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DAYTON"
  },
  "37322": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DECATUR"
  },
  "37323": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CLEVELAND"
  },
  "37324": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DECHERD"
  },
  "37325": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DELANO"
  },
  "37326": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DUCKTOWN"
  },
  "37327": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DUNLAP"
  },
  "37328": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ELORA"
  },
  "37329": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ENGLEWOOD"
  },
  "37330": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ESTILL SPRINGS"
  },
  "37331": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ETOWAH"
  },
  "37332": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "EVENSVILLE"
  },
  "37333": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "FARNER"
  },
  "37334": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "FAYETTEVILLE"
  },
  "37335": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "FLINTVILLE"
  },
  "37336": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GEORGETOWN"
  },
  "37337": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GRANDVIEW"
  },
  "37338": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GRAYSVILLE"
  },
  "37339": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GRUETLI LAAGER"
  },
  "37340": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GUILD"
  },
  "37341": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HARRISON"
  },
  "37342": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HILLSBORO"
  },
  "37343": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HIXSON"
  },
  "37345": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HUNTLAND"
  },
  "37347": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "JASPER"
  },
  "37348": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KELSO"
  },
  "37350": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LOOKOUT MOUNTAIN"
  },
  "37351": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LUPTON CITY"
  },
  "37352": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LYNCHBURG"
  },
  "37353": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MC DONALD"
  },
  "37354": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MADISONVILLE"
  },
  "37355": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MANCHESTER"
  },
  "37356": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MONTEAGLE"
  },
  "37357": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MORRISON"
  },
  "37359": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MULBERRY"
  },
  "37360": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NORMANDY"
  },
  "37361": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "OCOEE"
  },
  "37362": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "OLD FORT"
  },
  "37363": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "OOLTEWAH"
  },
  "37365": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PALMER"
  },
  "37366": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PELHAM"
  },
  "37367": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PIKEVILLE"
  },
  "37369": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "RELIANCE"
  },
  "37370": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "RICEVILLE"
  },
  "37373": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SALE CREEK"
  },
  "37374": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SEQUATCHIE"
  },
  "37375": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SEWANEE"
  },
  "37376": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SHERWOOD"
  },
  "37377": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SIGNAL MOUNTAIN"
  },
  "37379": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SODDY DAISY"
  },
  "37380": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SOUTH PITTSBURG"
  },
  "37381": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SPRING CITY"
  },
  "37385": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TELLICO PLAINS"
  },
  "37387": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TRACY CITY"
  },
  "37388": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TULLAHOMA"
  },
  "37391": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TURTLETOWN"
  },
  "37394": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "VIOLA"
  },
  "37396": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WHITESIDE"
  },
  "37397": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WHITWELL"
  },
  "37398": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WINCHESTER"
  },
  "37402": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHATTANOOGA"
  },
  "37403": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHATTANOOGA"
  },
  "37404": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHATTANOOGA"
  },
  "37405": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHATTANOOGA"
  },
  "37406": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHATTANOOGA"
  },
  "37407": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHATTANOOGA"
  },
  "37408": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHATTANOOGA"
  },
  "37409": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHATTANOOGA"
  },
  "37410": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHATTANOOGA"
  },
  "37411": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHATTANOOGA"
  },
  "37412": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHATTANOOGA"
  },
  "37415": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHATTANOOGA"
  },
  "37416": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHATTANOOGA"
  },
  "37419": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHATTANOOGA"
  },
  "37421": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHATTANOOGA"
  },
  "37601": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "JOHNSON CITY"
  },
  "37604": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "JOHNSON CITY"
  },
  "37614": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "JOHNSON CITY"
  },
  "37615": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "JOHNSON CITY"
  },
  "37616": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "AFTON"
  },
  "37617": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BLOUNTVILLE"
  },
  "37618": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BLUFF CITY"
  },
  "37620": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BRISTOL"
  },
  "37640": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BUTLER"
  },
  "37641": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHUCKEY"
  },
  "37642": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHURCH HILL"
  },
  "37643": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ELIZABETHTON"
  },
  "37645": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MOUNT CARMEL"
  },
  "37650": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ERWIN"
  },
  "37656": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "FALL BRANCH"
  },
  "37657": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "FLAG POND"
  },
  "37658": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HAMPTON"
  },
  "37659": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "JONESBOROUGH"
  },
  "37660": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KINGSPORT"
  },
  "37663": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KINGSPORT"
  },
  "37664": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KINGSPORT"
  },
  "37665": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KINGSPORT"
  },
  "37680": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LAUREL BLOOMERY"
  },
  "37681": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LIMESTONE"
  },
  "37683": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MOUNTAIN CITY"
  },
  "37684": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MOUNTAIN HOME"
  },
  "37686": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PINEY FLATS"
  },
  "37687": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ROAN MOUNTAIN"
  },
  "37688": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SHADY VALLEY"
  },
  "37690": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TELFORD"
  },
  "37691": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TRADE"
  },
  "37692": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "UNICOI"
  },
  "37694": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WATAUGA"
  },
  "37701": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ALCOA"
  },
  "37705": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ANDERSONVILLE"
  },
  "37708": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BEAN STATION"
  },
  "37709": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BLAINE"
  },
  "37710": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BRICEVILLE"
  },
  "37711": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BULLS GAP"
  },
  "37713": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BYBEE"
  },
  "37714": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CARYVILLE"
  },
  "37715": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CLAIRFIELD"
  },
  "37716": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CLINTON"
  },
  "37719": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "COALFIELD"
  },
  "37721": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CORRYTON"
  },
  "37722": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "COSBY"
  },
  "37723": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CRAB ORCHARD"
  },
  "37724": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CUMBERLAND GAP"
  },
  "37725": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DANDRIDGE"
  },
  "37726": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DEER LODGE"
  },
  "37727": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DEL RIO"
  },
  "37729": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DUFF"
  },
  "37730": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "EAGAN"
  },
  "37731": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "EIDSON"
  },
  "37732": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ELGIN"
  },
  "37737": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "FRIENDSVILLE"
  },
  "37738": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GATLINBURG"
  },
  "37742": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GREENBACK"
  },
  "37743": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GREENEVILLE"
  },
  "37745": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GREENEVILLE"
  },
  "37748": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HARRIMAN"
  },
  "37752": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HARROGATE"
  },
  "37753": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HARTFORD"
  },
  "37754": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HEISKELL"
  },
  "37755": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HELENWOOD"
  },
  "37756": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HUNTSVILLE"
  },
  "37757": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "JACKSBORO"
  },
  "37760": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "JEFFERSON CITY"
  },
  "37762": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "JELLICO"
  },
  "37763": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KINGSTON"
  },
  "37764": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KODAK"
  },
  "37765": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KYLES FORD"
  },
  "37766": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LA FOLLETTE"
  },
  "37769": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LAKE CITY"
  },
  "37770": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LANCING"
  },
  "37771": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LENOIR CITY"
  },
  "37772": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LENOIR CITY"
  },
  "37774": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LOUDON"
  },
  "37777": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LOUISVILLE"
  },
  "37779": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LUTTRELL"
  },
  "37801": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MARYVILLE"
  },
  "37803": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MARYVILLE"
  },
  "37804": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MARYVILLE"
  },
  "37806": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MASCOT"
  },
  "37807": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MAYNARDVILLE"
  },
  "37809": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MIDWAY"
  },
  "37810": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MOHAWK"
  },
  "37811": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MOORESBURG"
  },
  "37813": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MORRISTOWN"
  },
  "37814": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MORRISTOWN"
  },
  "37818": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MOSHEIM"
  },
  "37819": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NEWCOMB"
  },
  "37820": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NEW MARKET"
  },
  "37821": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NEWPORT"
  },
  "37825": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NEW TAZEWELL"
  },
  "37826": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NIOTA"
  },
  "37828": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NORRIS"
  },
  "37829": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "OAKDALE"
  },
  "37830": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "OAK RIDGE"
  },
  "37840": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "OLIVER SPRINGS"
  },
  "37841": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ONEIDA"
  },
  "37843": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PARROTTSVILLE"
  },
  "37845": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PETROS"
  },
  "37846": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PHILADELPHIA"
  },
  "37847": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PIONEER"
  },
  "37848": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "POWDER SPRINGS"
  },
  "37849": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "POWELL"
  },
  "37852": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ROBBINS"
  },
  "37853": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ROCKFORD"
  },
  "37854": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ROCKWOOD"
  },
  "37857": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ROGERSVILLE"
  },
  "37860": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "RUSSELLVILLE"
  },
  "37861": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "RUTLEDGE"
  },
  "37862": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SEVIERVILLE"
  },
  "37863": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PIGEON FORGE"
  },
  "37865": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SEYMOUR"
  },
  "37866": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SHARPS CHAPEL"
  },
  "37869": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SNEEDVILLE"
  },
  "37870": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SPEEDWELL"
  },
  "37871": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "STRAWBERRY PLAINS"
  },
  "37872": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SUNBRIGHT"
  },
  "37873": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SURGOINSVILLE"
  },
  "37874": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SWEETWATER"
  },
  "37876": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SEVIERVILLE"
  },
  "37877": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TALBOTT"
  },
  "37878": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TALLASSEE"
  },
  "37879": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TAZEWELL"
  },
  "37880": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TEN MILE"
  },
  "37881": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "THORN HILL"
  },
  "37882": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TOWNSEND"
  },
  "37885": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "VONORE"
  },
  "37886": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WALLAND"
  },
  "37887": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WARTBURG"
  },
  "37888": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WASHBURN"
  },
  "37890": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WHITE PINE"
  },
  "37891": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WHITESBURG"
  },
  "37892": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WINFIELD"
  },
  "37902": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KNOXVILLE"
  },
  "37909": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KNOXVILLE"
  },
  "37912": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KNOXVILLE"
  },
  "37914": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KNOXVILLE"
  },
  "37915": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KNOXVILLE"
  },
  "37916": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KNOXVILLE"
  },
  "37917": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KNOXVILLE"
  },
  "37918": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KNOXVILLE"
  },
  "37919": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KNOXVILLE"
  },
  "37920": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KNOXVILLE"
  },
  "37921": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KNOXVILLE"
  },
  "37922": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KNOXVILLE"
  },
  "37923": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KNOXVILLE"
  },
  "37924": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KNOXVILLE"
  },
  "37931": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KNOXVILLE"
  },
  "37932": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KNOXVILLE"
  },
  "37938": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KNOXVILLE"
  },
  "38001": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ALAMO"
  },
  "38002": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ARLINGTON"
  },
  "38004": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ATOKA"
  },
  "38006": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BELLS"
  },
  "38008": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BOLIVAR"
  },
  "38011": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BRIGHTON"
  },
  "38012": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BROWNSVILLE"
  },
  "38015": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BURLISON"
  },
  "38017": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "COLLIERVILLE"
  },
  "38018": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CORDOVA"
  },
  "38019": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "COVINGTON"
  },
  "38021": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CROCKETT MILLS"
  },
  "38023": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DRUMMONDS"
  },
  "38024": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DYERSBURG"
  },
  "38028": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "EADS"
  },
  "38030": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "FINLEY"
  },
  "38034": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "FRIENDSHIP"
  },
  "38036": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GALLAWAY"
  },
  "38037": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GATES"
  },
  "38039": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GRAND JUNCTION"
  },
  "38040": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HALLS"
  },
  "38041": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HENNING"
  },
  "38042": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HICKORY VALLEY"
  },
  "38044": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HORNSBY"
  },
  "38046": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LA GRANGE"
  },
  "38047": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LENOX"
  },
  "38049": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MASON"
  },
  "38050": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MAURY CITY"
  },
  "38052": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MIDDLETON"
  },
  "38053": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MILLINGTON"
  },
  "38057": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MOSCOW"
  },
  "38058": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MUNFORD"
  },
  "38059": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "NEWBERN"
  },
  "38060": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "OAKLAND"
  },
  "38061": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "POCAHONTAS"
  },
  "38063": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "RIPLEY"
  },
  "38066": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ROSSVILLE"
  },
  "38067": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SAULSBURY"
  },
  "38068": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SOMERVILLE"
  },
  "38069": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "STANTON"
  },
  "38075": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WHITEVILLE"
  },
  "38076": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WILLISTON"
  },
  "38079": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TIPTONVILLE"
  },
  "38080": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "RIDGELY"
  },
  "38103": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38104": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38105": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38106": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38107": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38108": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38109": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38111": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38112": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38114": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38115": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38116": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38117": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38118": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38119": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38120": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38122": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38125": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38126": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38127": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38128": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38132": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38133": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38134": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38135": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38138": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GERMANTOWN"
  },
  "38139": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GERMANTOWN"
  },
  "38141": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38157": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEMPHIS"
  },
  "38201": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MC KENZIE"
  },
  "38220": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ATWOOD"
  },
  "38221": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BIG SANDY"
  },
  "38222": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BUCHANAN"
  },
  "38224": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "COTTAGE GROVE"
  },
  "38225": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DRESDEN"
  },
  "38226": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DUKEDOM"
  },
  "38229": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GLEASON"
  },
  "38230": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GREENFIELD"
  },
  "38231": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HENRY"
  },
  "38232": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HORNBEAK"
  },
  "38233": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "KENTON"
  },
  "38235": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MC LEMORESVILLE"
  },
  "38236": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MANSFIELD"
  },
  "38237": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MARTIN"
  },
  "38240": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "OBION"
  },
  "38241": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PALMERSVILLE"
  },
  "38242": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PARIS"
  },
  "38251": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PURYEAR"
  },
  "38253": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "RIVES"
  },
  "38255": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SHARON"
  },
  "38256": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SPRINGVILLE"
  },
  "38257": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SOUTH FULTON"
  },
  "38258": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TREZEVANT"
  },
  "38259": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TRIMBLE"
  },
  "38260": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TROY"
  },
  "38261": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "UNION CITY"
  },
  "38301": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "JACKSON"
  },
  "38305": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "JACKSON"
  },
  "38310": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ADAMSVILLE"
  },
  "38311": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BATH SPRINGS"
  },
  "38313": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BEECH BLUFF"
  },
  "38315": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BETHEL SPRINGS"
  },
  "38316": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BRADFORD"
  },
  "38317": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BRUCETON"
  },
  "38318": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BUENA VISTA"
  },
  "38320": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CAMDEN"
  },
  "38321": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CEDAR GROVE"
  },
  "38326": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "COUNCE"
  },
  "38327": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CRUMP"
  },
  "38328": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DARDEN"
  },
  "38329": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DECATURVILLE"
  },
  "38330": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DYER"
  },
  "38332": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ENVILLE"
  },
  "38333": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "EVA"
  },
  "38334": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "FINGER"
  },
  "38337": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GADSDEN"
  },
  "38339": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GUYS"
  },
  "38340": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HENDERSON"
  },
  "38341": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HOLLADAY"
  },
  "38342": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HOLLOW ROCK"
  },
  "38343": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HUMBOLDT"
  },
  "38344": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HUNTINGDON"
  },
  "38345": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HURON"
  },
  "38347": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "JACKS CREEK"
  },
  "38348": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LAVINIA"
  },
  "38351": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LEXINGTON"
  },
  "38352": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LURAY"
  },
  "38355": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEDINA"
  },
  "38356": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MEDON"
  },
  "38357": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MICHIE"
  },
  "38358": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MILAN"
  },
  "38359": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MILLEDGEVILLE"
  },
  "38361": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MORRIS CHAPEL"
  },
  "38362": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "OAKFIELD"
  },
  "38363": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PARSONS"
  },
  "38366": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PINSON"
  },
  "38367": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "RAMER"
  },
  "38368": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "REAGAN"
  },
  "38369": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "RUTHERFORD"
  },
  "38370": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SALTILLO"
  },
  "38371": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SARDIS"
  },
  "38372": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SAVANNAH"
  },
  "38374": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SCOTTS HILL"
  },
  "38375": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SELMER"
  },
  "38376": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SHILOH"
  },
  "38378": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SPRING CREEK"
  },
  "38379": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "STANTONVILLE"
  },
  "38380": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SUGAR TREE"
  },
  "38381": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TOONE"
  },
  "38382": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TRENTON"
  },
  "38387": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WESTPORT"
  },
  "38388": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WILDERSVILLE"
  },
  "38390": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "YUMA"
  },
  "38391": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DENMARK"
  },
  "38392": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MERCER"
  },
  "38401": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "COLUMBIA"
  },
  "38425": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CLIFTON"
  },
  "38449": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ARDMORE"
  },
  "38450": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "COLLINWOOD"
  },
  "38451": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CULLEOKA"
  },
  "38452": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CYPRESS INN"
  },
  "38453": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DELLROSE"
  },
  "38454": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DUCK RIVER"
  },
  "38456": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ETHRIDGE"
  },
  "38457": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "FIVE POINTS"
  },
  "38459": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "FRANKEWING"
  },
  "38460": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GOODSPRING"
  },
  "38461": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HAMPSHIRE"
  },
  "38462": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HOHENWALD"
  },
  "38463": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "IRON CITY"
  },
  "38464": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LAWRENCEBURG"
  },
  "38468": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LEOMA"
  },
  "38469": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LORETTO"
  },
  "38471": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LUTTS"
  },
  "38472": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LYNNVILLE"
  },
  "38473": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MINOR HILL"
  },
  "38474": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MOUNT PLEASANT"
  },
  "38475": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "OLIVEHILL"
  },
  "38476": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PRIMM SPRINGS"
  },
  "38477": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PROSPECT"
  },
  "38478": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PULASKI"
  },
  "38481": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SAINT JOSEPH"
  },
  "38482": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SANTA FE"
  },
  "38483": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SUMMERTOWN"
  },
  "38485": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WAYNESBORO"
  },
  "38486": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WESTPOINT"
  },
  "38487": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WILLIAMSPORT"
  },
  "38488": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "TAFT"
  },
  "38501": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "COOKEVILLE"
  },
  "38504": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ALLARDT"
  },
  "38506": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "COOKEVILLE"
  },
  "38541": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ALLONS"
  },
  "38542": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ALLRED"
  },
  "38543": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ALPINE"
  },
  "38544": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BAXTER"
  },
  "38545": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BLOOMINGTON SPRINGS"
  },
  "38547": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BRUSH CREEK"
  },
  "38548": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BUFFALO VALLEY"
  },
  "38549": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "BYRDSTOWN"
  },
  "38551": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CELINA"
  },
  "38552": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CHESTNUT MOUND"
  },
  "38553": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CLARKRANGE"
  },
  "38554": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CRAWFORD"
  },
  "38555": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CROSSVILLE"
  },
  "38556": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "JAMESTOWN"
  },
  "38558": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "CROSSVILLE"
  },
  "38559": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "DOYLE"
  },
  "38560": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ELMWOOD"
  },
  "38562": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GAINESBORO"
  },
  "38563": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GORDONSVILLE"
  },
  "38564": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GRANVILLE"
  },
  "38565": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "GRIMSLEY"
  },
  "38567": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HICKMAN"
  },
  "38568": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "HILHAM"
  },
  "38569": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LANCASTER"
  },
  "38570": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "LIVINGSTON"
  },
  "38573": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MONROE"
  },
  "38574": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MONTEREY"
  },
  "38575": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "MOSS"
  },
  "38577": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "PALL MALL"
  },
  "38579": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "QUEBECK"
  },
  "38580": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "RICKMAN"
  },
  "38581": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "ROCK ISLAND"
  },
  "38582": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SILVER POINT"
  },
  "38583": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SPARTA"
  },
  "38585": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "SPENCER"
  },
  "38587": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WALLING"
  },
  "38588": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WHITLEYVILLE"
  },
  "38589": {
    stateCode: "TN",
    stateName: "Tennessee",
    city: "WILDER"
  },
  "38601": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ABBEVILLE"
  },
  "38603": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ASHLAND"
  },
  "38606": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BATESVILLE"
  },
  "38609": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BELEN"
  },
  "38610": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BLUE MOUNTAIN"
  },
  "38611": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BYHALIA"
  },
  "38614": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CLARKSDALE"
  },
  "38617": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "COAHOMA"
  },
  "38618": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "COLDWATER"
  },
  "38619": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "COMO"
  },
  "38620": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "COURTLAND"
  },
  "38621": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CRENSHAW"
  },
  "38622": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CROWDER"
  },
  "38623": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "DARLING"
  },
  "38625": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "DUMAS"
  },
  "38626": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "DUNDEE"
  },
  "38627": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ETTA"
  },
  "38629": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "FALKNER"
  },
  "38630": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "FARRELL"
  },
  "38631": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "FRIARS POINT"
  },
  "38632": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "HERNANDO"
  },
  "38633": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "HICKORY FLAT"
  },
  "38635": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "HOLLY SPRINGS"
  },
  "38637": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "HORN LAKE"
  },
  "38639": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "JONESTOWN"
  },
  "38641": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LAKE CORMORANT"
  },
  "38642": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LAMAR"
  },
  "38643": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LAMBERT"
  },
  "38644": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LULA"
  },
  "38645": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LYON"
  },
  "38646": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MARKS"
  },
  "38647": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MICHIGAN CITY"
  },
  "38649": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MOUNT PLEASANT"
  },
  "38650": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MYRTLE"
  },
  "38651": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "NESBIT"
  },
  "38652": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "NEW ALBANY"
  },
  "38654": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "OLIVE BRANCH"
  },
  "38655": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "OXFORD"
  },
  "38658": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "POPE"
  },
  "38659": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "POTTS CAMP"
  },
  "38661": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "RED BANKS"
  },
  "38663": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "RIPLEY"
  },
  "38664": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ROBINSONVILLE"
  },
  "38665": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SARAH"
  },
  "38666": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SARDIS"
  },
  "38668": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SENATOBIA"
  },
  "38669": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SHERARD"
  },
  "38670": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SLEDGE"
  },
  "38671": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SOUTHAVEN"
  },
  "38672": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SOUTHAVEN"
  },
  "38673": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "TAYLOR"
  },
  "38674": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "TIPLERSVILLE"
  },
  "38676": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "TUNICA"
  },
  "38677": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "UNIVERSITY"
  },
  "38680": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "WALLS"
  },
  "38683": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "WALNUT"
  },
  "38685": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "WATERFORD"
  },
  "38701": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GREENVILLE"
  },
  "38703": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GREENVILLE"
  },
  "38720": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ALLIGATOR"
  },
  "38721": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ANGUILLA"
  },
  "38722": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ARCOLA"
  },
  "38723": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "AVON"
  },
  "38725": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BENOIT"
  },
  "38726": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BEULAH"
  },
  "38730": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BOYLE"
  },
  "38732": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CLEVELAND"
  },
  "38736": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "DODDSVILLE"
  },
  "38737": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "DREW"
  },
  "38738": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PARCHMAN"
  },
  "38739": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "DUBLIN"
  },
  "38740": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "DUNCAN"
  },
  "38744": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GLEN ALLAN"
  },
  "38745": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GRACE"
  },
  "38746": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GUNNISON"
  },
  "38748": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "HOLLANDALE"
  },
  "38751": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "INDIANOLA"
  },
  "38753": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "INVERNESS"
  },
  "38754": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ISOLA"
  },
  "38756": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LELAND"
  },
  "38759": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MERIGOLD"
  },
  "38760": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "METCALFE"
  },
  "38761": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MOORHEAD"
  },
  "38762": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MOUND BAYOU"
  },
  "38764": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PACE"
  },
  "38765": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PANTHER BURN"
  },
  "38767": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "RENA LARA"
  },
  "38768": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ROME"
  },
  "38769": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ROSEDALE"
  },
  "38771": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "RULEVILLE"
  },
  "38772": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SCOTT"
  },
  "38773": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SHAW"
  },
  "38774": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SHELBY"
  },
  "38778": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SUNFLOWER"
  },
  "38801": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "TUPELO"
  },
  "38804": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "TUPELO"
  },
  "38821": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "AMORY"
  },
  "38824": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BALDWYN"
  },
  "38826": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BELDEN"
  },
  "38827": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BELMONT"
  },
  "38828": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BLUE SPRINGS"
  },
  "38829": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BOONEVILLE"
  },
  "38833": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BURNSVILLE"
  },
  "38834": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CORINTH"
  },
  "38838": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "DENNIS"
  },
  "38841": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ECRU"
  },
  "38843": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "FULTON"
  },
  "38844": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GATTMAN"
  },
  "38846": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GLEN"
  },
  "38847": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GOLDEN"
  },
  "38848": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GREENWOOD SPRINGS"
  },
  "38849": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GUNTOWN"
  },
  "38850": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "HOULKA"
  },
  "38851": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "HOUSTON"
  },
  "38852": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "IUKA"
  },
  "38855": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MANTACHIE"
  },
  "38856": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MARIETTA"
  },
  "38857": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MOOREVILLE"
  },
  "38858": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "NETTLETON"
  },
  "38859": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "NEW SITE"
  },
  "38860": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "OKOLONA"
  },
  "38862": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PLANTERSVILLE"
  },
  "38863": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PONTOTOC"
  },
  "38864": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "RANDOLPH"
  },
  "38865": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "RIENZI"
  },
  "38866": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SALTILLO"
  },
  "38868": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SHANNON"
  },
  "38869": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SHERMAN"
  },
  "38870": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SMITHVILLE"
  },
  "38871": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "THAXTON"
  },
  "38873": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "TISHOMINGO"
  },
  "38874": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "TOCCOPOLA"
  },
  "38876": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "TREMONT"
  },
  "38878": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "VARDAMAN"
  },
  "38879": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "VERONA"
  },
  "38880": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "WHEELER"
  },
  "38901": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GRENADA"
  },
  "38913": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BANNER"
  },
  "38914": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BIG CREEK"
  },
  "38915": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BRUCE"
  },
  "38916": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CALHOUN CITY"
  },
  "38917": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CARROLLTON"
  },
  "38920": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CASCILLA"
  },
  "38921": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CHARLESTON"
  },
  "38922": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "COFFEEVILLE"
  },
  "38923": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "COILA"
  },
  "38924": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CRUGER"
  },
  "38925": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "DUCK HILL"
  },
  "38927": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ENID"
  },
  "38928": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GLENDORA"
  },
  "38929": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GORE SPRINGS"
  },
  "38930": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GREENWOOD"
  },
  "38940": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "HOLCOMB"
  },
  "38941": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ITTA BENA"
  },
  "38943": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MC CARLEY"
  },
  "38944": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MINTER CITY"
  },
  "38946": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MORGAN CITY"
  },
  "38947": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "NORTH CARROLLTON"
  },
  "38948": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "OAKLAND"
  },
  "38949": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PARIS"
  },
  "38950": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PHILIPP"
  },
  "38951": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PITTSBORO"
  },
  "38952": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SCHLATER"
  },
  "38953": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SCOBEY"
  },
  "38954": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SIDON"
  },
  "38957": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SUMNER"
  },
  "38959": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SWIFTOWN"
  },
  "38961": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "TILLATOBA"
  },
  "38962": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "TIPPO"
  },
  "38963": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "TUTWILER"
  },
  "38964": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "VANCE"
  },
  "38965": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "WATER VALLEY"
  },
  "38966": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "WEBB"
  },
  "38967": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "WINONA"
  },
  "39038": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BELZONI"
  },
  "39039": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BENTON"
  },
  "39040": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BENTONIA"
  },
  "39041": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BOLTON"
  },
  "39042": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BRANDON"
  },
  "39044": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BRAXTON"
  },
  "39045": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CAMDEN"
  },
  "39046": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CANTON"
  },
  "39047": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BRANDON"
  },
  "39051": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CARTHAGE"
  },
  "39054": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CARY"
  },
  "39056": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CLINTON"
  },
  "39057": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CONEHATTA"
  },
  "39059": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CRYSTAL SPRINGS"
  },
  "39061": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "DELTA CITY"
  },
  "39062": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "D LO"
  },
  "39063": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "DURANT"
  },
  "39066": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "EDWARDS"
  },
  "39067": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ETHEL"
  },
  "39069": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "FAYETTE"
  },
  "39071": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "FLORA"
  },
  "39073": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "FLORENCE"
  },
  "39074": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "FOREST"
  },
  "39078": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GEORGETOWN"
  },
  "39079": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GOODMAN"
  },
  "39082": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "HARRISVILLE"
  },
  "39083": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "HAZLEHURST"
  },
  "39086": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "HERMANVILLE"
  },
  "39088": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "HOLLY BLUFF"
  },
  "39090": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "KOSCIUSKO"
  },
  "39092": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LAKE"
  },
  "39094": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LENA"
  },
  "39095": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LEXINGTON"
  },
  "39096": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LORMAN"
  },
  "39097": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LOUISE"
  },
  "39098": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LUDLOW"
  },
  "39107": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MC ADAMS"
  },
  "39108": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MC COOL"
  },
  "39109": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MADDEN"
  },
  "39110": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MADISON"
  },
  "39111": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MAGEE"
  },
  "39113": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MAYERSVILLE"
  },
  "39114": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MENDENHALL"
  },
  "39115": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MIDNIGHT"
  },
  "39116": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MIZE"
  },
  "39117": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MORTON"
  },
  "39119": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MOUNT OLIVE"
  },
  "39120": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "NATCHEZ"
  },
  "39140": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "NEWHEBRON"
  },
  "39144": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PATTISON"
  },
  "39145": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PELAHATCHIE"
  },
  "39146": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PICKENS"
  },
  "39148": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PINEY WOODS"
  },
  "39149": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PINOLA"
  },
  "39150": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PORT GIBSON"
  },
  "39151": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PUCKETT"
  },
  "39152": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PULASKI"
  },
  "39153": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "RALEIGH"
  },
  "39154": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "RAYMOND"
  },
  "39156": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "REDWOOD"
  },
  "39157": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "RIDGELAND"
  },
  "39159": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ROLLING FORK"
  },
  "39160": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SALLIS"
  },
  "39161": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SANDHILL"
  },
  "39162": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SATARTIA"
  },
  "39166": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SILVER CITY"
  },
  "39168": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "TAYLORSVILLE"
  },
  "39169": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "TCHULA"
  },
  "39170": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "TERRY"
  },
  "39174": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "TOUGALOO"
  },
  "39175": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "UTICA"
  },
  "39176": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "VAIDEN"
  },
  "39177": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "VALLEY PARK"
  },
  "39179": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "VAUGHAN"
  },
  "39180": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "VICKSBURG"
  },
  "39183": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "VICKSBURG"
  },
  "39189": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "WALNUT GROVE"
  },
  "39191": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "WESSON"
  },
  "39192": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "WEST"
  },
  "39194": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "YAZOO CITY"
  },
  "39201": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "JACKSON"
  },
  "39202": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "JACKSON"
  },
  "39203": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "JACKSON"
  },
  "39204": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "JACKSON"
  },
  "39206": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "JACKSON"
  },
  "39208": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PEARL"
  },
  "39209": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "JACKSON"
  },
  "39211": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "JACKSON"
  },
  "39212": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "JACKSON"
  },
  "39213": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "JACKSON"
  },
  "39216": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "JACKSON"
  },
  "39218": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "RICHLAND"
  },
  "39301": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MERIDIAN"
  },
  "39305": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MERIDIAN"
  },
  "39307": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MERIDIAN"
  },
  "39320": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BAILEY"
  },
  "39322": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BUCKATUNNA"
  },
  "39323": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CHUNKY"
  },
  "39325": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "COLLINSVILLE"
  },
  "39326": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "DALEVILLE"
  },
  "39327": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "DECATUR"
  },
  "39328": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "DE KALB"
  },
  "39330": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ENTERPRISE"
  },
  "39332": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "HICKORY"
  },
  "39335": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LAUDERDALE"
  },
  "39336": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LAWRENCE"
  },
  "39337": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LITTLE ROCK"
  },
  "39338": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LOUIN"
  },
  "39339": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LOUISVILLE"
  },
  "39341": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MACON"
  },
  "39345": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "NEWTON"
  },
  "39346": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "NOXAPATER"
  },
  "39347": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PACHUTA"
  },
  "39348": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PAULDING"
  },
  "39350": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PHILADELPHIA"
  },
  "39352": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PORTERVILLE"
  },
  "39354": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PRESTON"
  },
  "39355": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "QUITMAN"
  },
  "39356": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ROSE HILL"
  },
  "39358": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SCOOBA"
  },
  "39359": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SEBASTOPOL"
  },
  "39360": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SHUBUTA"
  },
  "39361": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SHUQUALAK"
  },
  "39362": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "STATE LINE"
  },
  "39363": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "STONEWALL"
  },
  "39364": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "TOOMSUBA"
  },
  "39365": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "UNION"
  },
  "39366": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "VOSSBURG"
  },
  "39367": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "WAYNESBORO"
  },
  "39401": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "HATTIESBURG"
  },
  "39402": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "HATTIESBURG"
  },
  "39421": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BASSFIELD"
  },
  "39422": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BAY SPRINGS"
  },
  "39423": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BEAUMONT"
  },
  "39425": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BROOKLYN"
  },
  "39426": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CARRIERE"
  },
  "39427": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CARSON"
  },
  "39428": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "COLLINS"
  },
  "39429": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "COLUMBIA"
  },
  "39436": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "EASTABUCHIE"
  },
  "39437": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ELLISVILLE"
  },
  "39439": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "HEIDELBERG"
  },
  "39440": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LAUREL"
  },
  "39443": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LAUREL"
  },
  "39451": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LEAKESVILLE"
  },
  "39452": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LUCEDALE"
  },
  "39455": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LUMBERTON"
  },
  "39456": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MC LAIN"
  },
  "39459": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MOSELLE"
  },
  "39461": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "NEELY"
  },
  "39462": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "NEW AUGUSTA"
  },
  "39464": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "OVETT"
  },
  "39465": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PETAL"
  },
  "39466": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PICAYUNE"
  },
  "39470": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "POPLARVILLE"
  },
  "39474": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PRENTISS"
  },
  "39475": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PURVIS"
  },
  "39476": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "RICHTON"
  },
  "39477": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SANDERSVILLE"
  },
  "39478": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SANDY HOOK"
  },
  "39479": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SEMINARY"
  },
  "39480": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SOSO"
  },
  "39481": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "STRINGER"
  },
  "39482": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SUMRALL"
  },
  "39483": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "FOXWORTH"
  },
  "39501": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GULFPORT"
  },
  "39503": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GULFPORT"
  },
  "39507": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GULFPORT"
  },
  "39520": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BAY SAINT LOUIS"
  },
  "39525": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "DIAMONDHEAD"
  },
  "39530": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BILOXI"
  },
  "39531": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BILOXI"
  },
  "39532": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BILOXI"
  },
  "39553": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GAUTIER"
  },
  "39556": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "KILN"
  },
  "39560": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LONG BEACH"
  },
  "39561": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MC HENRY"
  },
  "39562": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MOSS POINT"
  },
  "39563": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MOSS POINT"
  },
  "39564": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "OCEAN SPRINGS"
  },
  "39565": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "VANCLEAVE"
  },
  "39567": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PASCAGOULA"
  },
  "39571": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PASS CHRISTIAN"
  },
  "39572": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PEARLINGTON"
  },
  "39573": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PERKINSTON"
  },
  "39574": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SAUCIER"
  },
  "39576": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "WAVELAND"
  },
  "39577": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "WIGGINS"
  },
  "39581": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PASCAGOULA"
  },
  "39601": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BROOKHAVEN"
  },
  "39629": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BOGUE CHITTO"
  },
  "39630": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BUDE"
  },
  "39631": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CENTREVILLE"
  },
  "39633": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CROSBY"
  },
  "39635": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "FERNWOOD"
  },
  "39638": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "GLOSTER"
  },
  "39641": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "JAYESS"
  },
  "39643": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "KOKOMO"
  },
  "39645": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "LIBERTY"
  },
  "39647": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MC CALL CREEK"
  },
  "39648": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MCCOMB"
  },
  "39652": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MAGNOLIA"
  },
  "39653": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MEADVILLE"
  },
  "39654": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MONTICELLO"
  },
  "39656": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "OAK VALE"
  },
  "39657": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "OSYKA"
  },
  "39661": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ROXIE"
  },
  "39662": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "RUTH"
  },
  "39663": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SILVER CREEK"
  },
  "39664": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SMITHDALE"
  },
  "39665": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SONTAG"
  },
  "39666": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "SUMMIT"
  },
  "39667": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "TYLERTOWN"
  },
  "39668": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "UNION CHURCH"
  },
  "39669": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "WOODVILLE"
  },
  "39701": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "COLUMBUS"
  },
  "39702": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "COLUMBUS"
  },
  "39704": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "COLUMBUS"
  },
  "39705": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "COLUMBUS"
  },
  "39730": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ABERDEEN"
  },
  "39735": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ACKERMAN"
  },
  "39736": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "ARTESIA"
  },
  "39739": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "BROOKSVILLE"
  },
  "39740": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CALEDONIA"
  },
  "39741": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CEDARBLUFF"
  },
  "39743": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "CRAWFORD"
  },
  "39744": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "EUPORA"
  },
  "39745": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "FRENCH CAMP"
  },
  "39746": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "HAMILTON"
  },
  "39747": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "KILMICHAEL"
  },
  "39750": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MABEN"
  },
  "39751": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MANTEE"
  },
  "39752": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "MATHISTON"
  },
  "39755": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PHEBA"
  },
  "39756": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "PRAIRIE"
  },
  "39759": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "STARKVILLE"
  },
  "39766": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "STEENS"
  },
  "39767": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "STEWART"
  },
  "39769": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "STURGIS"
  },
  "39771": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "WALTHALL"
  },
  "39772": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "WEIR"
  },
  "39773": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "WEST POINT"
  },
  "39776": {
    stateCode: "MS",
    stateName: "Mississippi",
    city: "WOODLAND"
  },
  "40003": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BAGDAD"
  },
  "40004": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BARDSTOWN"
  },
  "40006": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BEDFORD"
  },
  "40007": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BETHLEHEM"
  },
  "40008": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BLOOMFIELD"
  },
  "40009": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BRADFORDSVILLE"
  },
  "40010": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BUCKNER"
  },
  "40011": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CAMPBELLSBURG"
  },
  "40012": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CHAPLIN"
  },
  "40013": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "COXS CREEK"
  },
  "40014": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CRESTWOOD"
  },
  "40019": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "EMINENCE"
  },
  "40020": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FAIRFIELD"
  },
  "40022": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FINCHVILLE"
  },
  "40023": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FISHERVILLE"
  },
  "40026": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GOSHEN"
  },
  "40031": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LA GRANGE"
  },
  "40033": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEBANON"
  },
  "40036": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOCKPORT"
  },
  "40037": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LORETTO"
  },
  "40040": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MACKVILLE"
  },
  "40045": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MILTON"
  },
  "40046": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MOUNT EDEN"
  },
  "40047": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MOUNT WASHINGTON"
  },
  "40050": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "NEW CASTLE"
  },
  "40051": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "NEW HAVEN"
  },
  "40052": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "NEW HOPE"
  },
  "40055": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PENDLETON"
  },
  "40056": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PEWEE VALLEY"
  },
  "40057": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PLEASUREVILLE"
  },
  "40058": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PORT ROYAL"
  },
  "40059": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PROSPECT"
  },
  "40060": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "RAYWICK"
  },
  "40062": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SAINT FRANCIS"
  },
  "40065": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SHELBYVILLE"
  },
  "40067": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SIMPSONVILLE"
  },
  "40068": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SMITHFIELD"
  },
  "40069": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SPRINGFIELD"
  },
  "40070": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SULPHUR"
  },
  "40071": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "TAYLORSVILLE"
  },
  "40076": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WADDY"
  },
  "40077": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WESTPORT"
  },
  "40078": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WILLISBURG"
  },
  "40104": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BATTLETOWN"
  },
  "40107": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BOSTON"
  },
  "40108": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BRANDENBURG"
  },
  "40109": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BROOKS"
  },
  "40111": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CLOVERPORT"
  },
  "40115": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CUSTER"
  },
  "40117": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "EKRON"
  },
  "40118": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FAIRDALE"
  },
  "40119": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FALLS OF ROUGH"
  },
  "40121": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FORT KNOX"
  },
  "40140": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GARFIELD"
  },
  "40142": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GUSTON"
  },
  "40143": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HARDINSBURG"
  },
  "40144": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HARNED"
  },
  "40145": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HUDSON"
  },
  "40146": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "IRVINGTON"
  },
  "40150": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEBANON JUNCTION"
  },
  "40152": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MC DANIELS"
  },
  "40155": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MULDRAUGH"
  },
  "40157": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PAYNEVILLE"
  },
  "40160": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "RADCLIFF"
  },
  "40162": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "RINEYVILLE"
  },
  "40165": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SHEPHERDSVILLE"
  },
  "40170": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "STEPHENSPORT"
  },
  "40171": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "UNION STAR"
  },
  "40175": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "VINE GROVE"
  },
  "40176": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WEBSTER"
  },
  "40177": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WEST POINT"
  },
  "40178": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WESTVIEW"
  },
  "40202": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40203": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40204": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40205": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40206": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40207": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40208": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40209": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40210": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40211": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40212": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40213": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40214": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40215": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40216": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40217": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40218": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40219": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40220": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40222": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40223": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40228": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40229": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40241": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40242": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40243": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40245": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40258": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40272": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40291": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40299": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISVILLE"
  },
  "40310": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BURGIN"
  },
  "40311": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CARLISLE"
  },
  "40312": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CLAY CITY"
  },
  "40313": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CLEARFIELD"
  },
  "40316": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DENNISTON"
  },
  "40322": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FRENCHBURG"
  },
  "40324": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GEORGETOWN"
  },
  "40328": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GRAVEL SWITCH"
  },
  "40330": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HARRODSBURG"
  },
  "40334": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HOPE"
  },
  "40336": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "IRVINE"
  },
  "40337": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "JEFFERSONVILLE"
  },
  "40339": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "KEENE"
  },
  "40342": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LAWRENCEBURG"
  },
  "40346": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MEANS"
  },
  "40347": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MIDWAY"
  },
  "40348": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MILLERSBURG"
  },
  "40350": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MOOREFIELD"
  },
  "40351": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MOREHEAD"
  },
  "40353": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MOUNT STERLING"
  },
  "40355": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "NEW LIBERTY"
  },
  "40356": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "NICHOLASVILLE"
  },
  "40358": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "OLYMPIA"
  },
  "40359": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "OWENTON"
  },
  "40360": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "OWINGSVILLE"
  },
  "40361": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PARIS"
  },
  "40363": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PERRY PARK"
  },
  "40370": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SADIEVILLE"
  },
  "40371": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SALT LICK"
  },
  "40372": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SALVISA"
  },
  "40374": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SHARPSBURG"
  },
  "40376": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SLADE"
  },
  "40379": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "STAMPING GROUND"
  },
  "40380": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "STANTON"
  },
  "40383": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "VERSAILLES"
  },
  "40385": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WACO"
  },
  "40387": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WELLINGTON"
  },
  "40390": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WILMORE"
  },
  "40391": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WINCHESTER"
  },
  "40402": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ANNVILLE"
  },
  "40403": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BEREA"
  },
  "40409": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BRODHEAD"
  },
  "40419": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CRAB ORCHARD"
  },
  "40422": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DANVILLE"
  },
  "40437": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HUSTONVILLE"
  },
  "40440": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "JUNCTION CITY"
  },
  "40442": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "KINGS MOUNTAIN"
  },
  "40444": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LANCASTER"
  },
  "40445": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LIVINGSTON"
  },
  "40447": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MC KEE"
  },
  "40448": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MC KINNEY"
  },
  "40456": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MOUNT VERNON"
  },
  "40460": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ORLANDO"
  },
  "40461": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PAINT LICK"
  },
  "40464": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PARKSVILLE"
  },
  "40468": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PERRYVILLE"
  },
  "40472": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "RAVENNA"
  },
  "40475": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "RICHMOND"
  },
  "40481": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SANDGAP"
  },
  "40484": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "STANFORD"
  },
  "40486": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "TYNER"
  },
  "40488": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WANETA"
  },
  "40489": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WAYNESBURG"
  },
  "40502": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEXINGTON"
  },
  "40503": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEXINGTON"
  },
  "40504": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEXINGTON"
  },
  "40505": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEXINGTON"
  },
  "40507": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEXINGTON"
  },
  "40508": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEXINGTON"
  },
  "40509": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEXINGTON"
  },
  "40510": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEXINGTON"
  },
  "40511": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEXINGTON"
  },
  "40513": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEXINGTON"
  },
  "40514": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEXINGTON"
  },
  "40515": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEXINGTON"
  },
  "40516": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEXINGTON"
  },
  "40517": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEXINGTON"
  },
  "40601": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FRANKFORT"
  },
  "40701": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CORBIN"
  },
  "40729": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "EAST BERNSTADT"
  },
  "40730": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "EMLYN"
  },
  "40734": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GRAY"
  },
  "40737": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "KEAVY"
  },
  "40740": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LILY"
  },
  "40741": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LONDON"
  },
  "40744": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LONDON"
  },
  "40759": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ROCKHOLDS"
  },
  "40763": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SILER"
  },
  "40769": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WILLIAMSBURG"
  },
  "40771": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WOODBINE"
  },
  "40801": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "AGES BROOKSIDE"
  },
  "40803": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ASHER"
  },
  "40806": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BAXTER"
  },
  "40807": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BENHAM"
  },
  "40808": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BIG LAUREL"
  },
  "40810": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BLEDSOE"
  },
  "40813": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CALVIN"
  },
  "40815": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CAWOOD"
  },
  "40816": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CHAPPELL"
  },
  "40818": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "COALGOOD"
  },
  "40819": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "COLDIRON"
  },
  "40820": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CRANKS"
  },
  "40823": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CUMBERLAND"
  },
  "40824": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DAYHOIT"
  },
  "40826": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "EOLIA"
  },
  "40827": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ESSIE"
  },
  "40828": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "EVARTS"
  },
  "40830": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GULSTON"
  },
  "40831": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HARLAN"
  },
  "40840": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HELTON"
  },
  "40843": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HOLMES MILL"
  },
  "40844": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HOSKINSTON"
  },
  "40845": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HULEN"
  },
  "40847": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "KENVIR"
  },
  "40849": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEJUNIOR"
  },
  "40854": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOYALL"
  },
  "40855": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LYNCH"
  },
  "40856": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MIRACLE"
  },
  "40858": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MOZELLE"
  },
  "40862": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PARTRIDGE"
  },
  "40863": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PATHFORK"
  },
  "40865": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PUTNEY"
  },
  "40868": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "STINNETT"
  },
  "40870": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "TOTZ"
  },
  "40873": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WALLINS CREEK"
  },
  "40874": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WARBRANCH"
  },
  "40902": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ARJAY"
  },
  "40903": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ARTEMUS"
  },
  "40906": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BARBOURVILLE"
  },
  "40913": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BEVERLY"
  },
  "40914": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BIG CREEK"
  },
  "40915": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BIMBLE"
  },
  "40921": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BRYANTS STORE"
  },
  "40923": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CANNON"
  },
  "40927": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CLOSPLINT"
  },
  "40930": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DEWITT"
  },
  "40935": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FLAT LICK"
  },
  "40940": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FRAKES"
  },
  "40941": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GARRARD"
  },
  "40943": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GIRDLER"
  },
  "40946": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GREEN ROAD"
  },
  "40949": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HEIDRICK"
  },
  "40953": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HINKLE"
  },
  "40958": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "KETTLE ISLAND"
  },
  "40962": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MANCHESTER"
  },
  "40965": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MIDDLESBORO"
  },
  "40972": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ONEIDA"
  },
  "40977": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PINEVILLE"
  },
  "40979": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ROARK"
  },
  "40982": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SCALF"
  },
  "40983": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SEXTONS CREEK"
  },
  "40988": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "STONEY FORK"
  },
  "40995": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "TROSPER"
  },
  "40997": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WALKER"
  },
  "41001": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ALEXANDRIA"
  },
  "41002": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "AUGUSTA"
  },
  "41003": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BERRY"
  },
  "41004": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BROOKSVILLE"
  },
  "41005": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BURLINGTON"
  },
  "41006": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BUTLER"
  },
  "41007": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CALIFORNIA"
  },
  "41008": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CARROLLTON"
  },
  "41010": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CORINTH"
  },
  "41011": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "COVINGTON"
  },
  "41014": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "COVINGTON"
  },
  "41015": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LATONIA"
  },
  "41016": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "COVINGTON"
  },
  "41017": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FT MITCHELL"
  },
  "41018": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ERLANGER"
  },
  "41030": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CRITTENDEN"
  },
  "41031": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CYNTHIANA"
  },
  "41033": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DE MOSSVILLE"
  },
  "41034": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DOVER"
  },
  "41035": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DRY RIDGE"
  },
  "41039": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "EWING"
  },
  "41040": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FALMOUTH"
  },
  "41041": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FLEMINGSBURG"
  },
  "41042": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FLORENCE"
  },
  "41043": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FOSTER"
  },
  "41044": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GERMANTOWN"
  },
  "41045": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GHENT"
  },
  "41046": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GLENCOE"
  },
  "41048": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HEBRON"
  },
  "41049": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HILLSBORO"
  },
  "41051": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "INDEPENDENCE"
  },
  "41052": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "JONESVILLE"
  },
  "41055": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MAYSLICK"
  },
  "41056": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MAYSVILLE"
  },
  "41059": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MELBOURNE"
  },
  "41063": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MORNING VIEW"
  },
  "41064": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MOUNT OLIVET"
  },
  "41071": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "NEWPORT"
  },
  "41073": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BELLEVUE"
  },
  "41074": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DAYTON"
  },
  "41075": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FORT THOMAS"
  },
  "41076": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "NEWPORT"
  },
  "41080": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PETERSBURG"
  },
  "41083": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SANDERS"
  },
  "41085": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SILVER GROVE"
  },
  "41086": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SPARTA"
  },
  "41091": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "UNION"
  },
  "41092": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "VERONA"
  },
  "41093": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WALLINGFORD"
  },
  "41094": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WALTON"
  },
  "41095": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WARSAW"
  },
  "41097": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WILLIAMSTOWN"
  },
  "41098": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WORTHVILLE"
  },
  "41101": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ASHLAND"
  },
  "41102": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ASHLAND"
  },
  "41121": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ARGILLITE"
  },
  "41124": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BLAINE"
  },
  "41129": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CATLETTSBURG"
  },
  "41132": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DENTON"
  },
  "41135": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "EMERSON"
  },
  "41139": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FLATWOODS"
  },
  "41141": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GARRISON"
  },
  "41142": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GRAHN"
  },
  "41143": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GRAYSON"
  },
  "41144": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GREENUP"
  },
  "41146": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HITCHINS"
  },
  "41149": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ISONVILLE"
  },
  "41159": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MARTHA"
  },
  "41164": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "OLIVE HILL"
  },
  "41166": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "QUINCY"
  },
  "41168": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "RUSH"
  },
  "41169": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "RUSSELL"
  },
  "41171": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SANDY HOOK"
  },
  "41174": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SOUTH PORTSMOUTH"
  },
  "41175": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SOUTH SHORE"
  },
  "41179": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "VANCEBURG"
  },
  "41180": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WEBBVILLE"
  },
  "41183": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WORTHINGTON"
  },
  "41189": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "TOLLESBORO"
  },
  "41201": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ADAMS"
  },
  "41203": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BEAUTY"
  },
  "41204": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BOONS CAMP"
  },
  "41214": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DEBORD"
  },
  "41216": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "EAST POINT"
  },
  "41219": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FLATGAP"
  },
  "41222": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HAGERHILL"
  },
  "41224": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "INEZ"
  },
  "41226": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "KEATON"
  },
  "41230": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOUISA"
  },
  "41231": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOVELY"
  },
  "41232": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOWMANSVILLE"
  },
  "41234": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MEALLY"
  },
  "41238": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "OIL SPRINGS"
  },
  "41240": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PAINTSVILLE"
  },
  "41250": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PILGRIM"
  },
  "41254": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "RIVER"
  },
  "41255": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SITKA"
  },
  "41256": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "STAFFORDSVILLE"
  },
  "41257": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "STAMBAUGH"
  },
  "41260": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "THELMA"
  },
  "41262": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "TOMAHAWK"
  },
  "41263": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "TUTOR KEY"
  },
  "41264": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ULYSSES"
  },
  "41265": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "VAN LEAR"
  },
  "41267": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WARFIELD"
  },
  "41268": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WEST VAN LEAR"
  },
  "41274": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WITTENSVILLE"
  },
  "41301": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CAMPTON"
  },
  "41310": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BAYS"
  },
  "41311": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BEATTYVILLE"
  },
  "41314": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BOONEVILLE"
  },
  "41317": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CLAYHOLE"
  },
  "41332": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HAZEL GREEN"
  },
  "41339": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "JACKSON"
  },
  "41348": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOST CREEK"
  },
  "41360": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PINE RIDGE"
  },
  "41364": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "RICETOWN"
  },
  "41365": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ROGERS"
  },
  "41367": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ROWDY"
  },
  "41385": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "VANCLEVE"
  },
  "41386": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "VINCENT"
  },
  "41390": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WHICK"
  },
  "41397": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ZOE"
  },
  "41408": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CANNEL CITY"
  },
  "41421": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ELKFORK"
  },
  "41425": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "EZEL"
  },
  "41426": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FALCON"
  },
  "41464": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ROYALTON"
  },
  "41465": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SALYERSVILLE"
  },
  "41472": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WEST LIBERTY"
  },
  "41501": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PIKEVILLE"
  },
  "41503": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SOUTH WILLIAMSON"
  },
  "41512": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ASHCAMP"
  },
  "41513": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BELCHER"
  },
  "41514": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BELFRY"
  },
  "41519": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CANADA"
  },
  "41522": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ELKHORN CITY"
  },
  "41524": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FEDSCREEK"
  },
  "41527": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FOREST HILLS"
  },
  "41528": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FREEBURN"
  },
  "41531": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HARDY"
  },
  "41534": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HELLIER"
  },
  "41535": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HUDDY"
  },
  "41537": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "JENKINS"
  },
  "41538": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "JONANCY"
  },
  "41539": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "KIMPER"
  },
  "41540": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LICK CREEK"
  },
  "41543": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MC ANDREWS"
  },
  "41544": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MC CARR"
  },
  "41547": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MAJESTIC"
  },
  "41548": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MOUTHCARD"
  },
  "41549": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MYRA"
  },
  "41553": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PHELPS"
  },
  "41554": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PHYLLIS"
  },
  "41555": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PINSONFORK"
  },
  "41557": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "RACCOON"
  },
  "41558": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "RANSOM"
  },
  "41559": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "REGINA"
  },
  "41560": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ROBINSON CREEK"
  },
  "41562": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SHELBIANA"
  },
  "41563": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SHELBY GAP"
  },
  "41564": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SIDNEY"
  },
  "41566": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "STEELE"
  },
  "41567": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "STONE"
  },
  "41568": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "STOPOVER"
  },
  "41571": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "VARNEY"
  },
  "41572": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "VIRGIE"
  },
  "41601": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ALLEN"
  },
  "41602": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "AUXIER"
  },
  "41603": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BANNER"
  },
  "41604": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BEAVER"
  },
  "41605": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BETSY LAYNE"
  },
  "41606": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BEVINSVILLE"
  },
  "41607": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BLUE RIVER"
  },
  "41612": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BYPRO"
  },
  "41615": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DANA"
  },
  "41616": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DAVID"
  },
  "41619": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DRIFT"
  },
  "41621": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DWALE"
  },
  "41622": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "EASTERN"
  },
  "41630": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GARRETT"
  },
  "41631": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GRETHEL"
  },
  "41632": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GUNLOCK"
  },
  "41635": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HAROLD"
  },
  "41636": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HI HAT"
  },
  "41640": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HUEYSVILLE"
  },
  "41642": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "IVEL"
  },
  "41645": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LANGLEY"
  },
  "41647": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MC DOWELL"
  },
  "41649": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MARTIN"
  },
  "41650": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MELVIN"
  },
  "41651": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MINNIE"
  },
  "41653": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PRESTONSBURG"
  },
  "41655": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PRINTER"
  },
  "41659": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "STANVILLE"
  },
  "41660": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "TEABERRY"
  },
  "41663": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "TRAM"
  },
  "41666": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WAYLAND"
  },
  "41667": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WEEKSBURY"
  },
  "41669": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WHEELWRIGHT"
  },
  "41701": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HAZARD"
  },
  "41712": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ARY"
  },
  "41714": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BEAR BRANCH"
  },
  "41719": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BONNYMAN"
  },
  "41721": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BUCKHORN"
  },
  "41722": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BULAN"
  },
  "41723": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BUSY"
  },
  "41725": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CARRIE"
  },
  "41727": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CHAVIES"
  },
  "41729": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "COMBS"
  },
  "41731": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CORNETTSVILLE"
  },
  "41735": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DELPHIA"
  },
  "41736": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DICE"
  },
  "41739": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DWARF"
  },
  "41740": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "EMMALENA"
  },
  "41745": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GAYS CREEK"
  },
  "41746": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HAPPY"
  },
  "41749": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HYDEN"
  },
  "41751": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "JEFF"
  },
  "41754": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "KRYPTON"
  },
  "41759": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SASSAFRAS"
  },
  "41760": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SCUDDY"
  },
  "41762": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SIZEROCK"
  },
  "41763": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SLEMP"
  },
  "41764": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SMILAX"
  },
  "41766": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "THOUSANDSTICKS"
  },
  "41772": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "VEST"
  },
  "41773": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "VICCO"
  },
  "41774": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "VIPER"
  },
  "41775": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WENDOVER"
  },
  "41776": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WOOTON"
  },
  "41777": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "YEADDISS"
  },
  "41778": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "YERKES"
  },
  "41804": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BLACKEY"
  },
  "41810": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CROMONA"
  },
  "41812": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DEANE"
  },
  "41815": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ERMINE"
  },
  "41817": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GARNER"
  },
  "41819": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GORDON"
  },
  "41821": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HALLIE"
  },
  "41822": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HINDMAN"
  },
  "41824": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ISOM"
  },
  "41825": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "JACKHORN"
  },
  "41826": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "JEREMIAH"
  },
  "41828": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "KITE"
  },
  "41831": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEBURN"
  },
  "41832": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LETCHER"
  },
  "41833": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LINEFORK"
  },
  "41834": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LITTCARR"
  },
  "41835": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MC ROBERTS"
  },
  "41836": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MALLIE"
  },
  "41837": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MAYKING"
  },
  "41838": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MILLSTONE"
  },
  "41839": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MOUSIE"
  },
  "41840": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "NEON"
  },
  "41843": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PINE TOP"
  },
  "41844": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PIPPA PASSES"
  },
  "41845": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PREMIUM"
  },
  "41847": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "REDFOX"
  },
  "41849": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SECO"
  },
  "41855": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "THORNTON"
  },
  "41858": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WHITESBURG"
  },
  "41859": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DEMA"
  },
  "41861": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "RAVEN"
  },
  "41862": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "TOPMOST"
  },
  "42001": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PADUCAH"
  },
  "42003": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PADUCAH"
  },
  "42020": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ALMO"
  },
  "42021": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ARLINGTON"
  },
  "42022": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BANDANA"
  },
  "42023": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BARDWELL"
  },
  "42024": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BARLOW"
  },
  "42025": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BENTON"
  },
  "42027": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BOAZ"
  },
  "42028": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BURNA"
  },
  "42029": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CALVERT CITY"
  },
  "42031": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CLINTON"
  },
  "42032": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "COLUMBUS"
  },
  "42035": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CUNNINGHAM"
  },
  "42036": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DEXTER"
  },
  "42038": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "EDDYVILLE"
  },
  "42039": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FANCY FARM"
  },
  "42040": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FARMINGTON"
  },
  "42041": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FULTON"
  },
  "42044": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GILBERTSVILLE"
  },
  "42045": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GRAND RIVERS"
  },
  "42047": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HAMPTON"
  },
  "42048": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HARDIN"
  },
  "42049": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HAZEL"
  },
  "42050": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HICKMAN"
  },
  "42051": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HICKORY"
  },
  "42053": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "KEVIL"
  },
  "42054": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "KIRKSEY"
  },
  "42055": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "KUTTAWA"
  },
  "42056": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LA CENTER"
  },
  "42058": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEDBETTER"
  },
  "42060": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOVELACEVILLE"
  },
  "42061": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LOWES"
  },
  "42064": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MARION"
  },
  "42066": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MAYFIELD"
  },
  "42069": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MELBER"
  },
  "42071": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MURRAY"
  },
  "42076": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "NEW CONCORD"
  },
  "42078": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SALEM"
  },
  "42079": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SEDALIA"
  },
  "42081": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SMITHLAND"
  },
  "42082": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SYMSONIA"
  },
  "42083": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "TILINE"
  },
  "42084": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "TOLU"
  },
  "42085": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WATER VALLEY"
  },
  "42086": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WEST PADUCAH"
  },
  "42087": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WICKLIFFE"
  },
  "42088": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WINGO"
  },
  "42101": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BOWLING GREEN"
  },
  "42103": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BOWLING GREEN"
  },
  "42104": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BOWLING GREEN"
  },
  "42120": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ADOLPHUS"
  },
  "42122": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ALVATON"
  },
  "42123": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "AUSTIN"
  },
  "42127": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CAVE CITY"
  },
  "42129": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "EDMONTON"
  },
  "42131": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ETOILE"
  },
  "42133": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FOUNTAIN RUN"
  },
  "42134": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FRANKLIN"
  },
  "42140": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GAMALIEL"
  },
  "42141": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GLASGOW"
  },
  "42151": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HESTAND"
  },
  "42153": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HOLLAND"
  },
  "42154": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "KNOB LICK"
  },
  "42157": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MOUNT HERMON"
  },
  "42159": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "OAKLAND"
  },
  "42160": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PARK CITY"
  },
  "42164": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SCOTTSVILLE"
  },
  "42166": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SUMMER SHADE"
  },
  "42167": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "TOMPKINSVILLE"
  },
  "42170": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WOODBURN"
  },
  "42171": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SMITHS GROVE"
  },
  "42202": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ADAIRVILLE"
  },
  "42204": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ALLENSVILLE"
  },
  "42206": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "AUBURN"
  },
  "42207": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BEE SPRING"
  },
  "42210": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BROWNSVILLE"
  },
  "42211": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CADIZ"
  },
  "42214": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CENTER"
  },
  "42215": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CERULEAN"
  },
  "42217": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CROFTON"
  },
  "42220": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ELKTON"
  },
  "42223": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FORT CAMPBELL"
  },
  "42232": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GRACEY"
  },
  "42234": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GUTHRIE"
  },
  "42236": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HERNDON"
  },
  "42240": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HOPKINSVILLE"
  },
  "42254": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LA FAYETTE"
  },
  "42256": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEWISBURG"
  },
  "42259": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MAMMOTH CAVE"
  },
  "42261": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MORGANTOWN"
  },
  "42262": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "OAK GROVE"
  },
  "42265": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "OLMSTEAD"
  },
  "42266": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PEMBROKE"
  },
  "42273": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ROCHESTER"
  },
  "42274": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ROCKFIELD"
  },
  "42275": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ROUNDHILL"
  },
  "42276": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "RUSSELLVILLE"
  },
  "42280": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SHARON GROVE"
  },
  "42285": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SWEEDEN"
  },
  "42286": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "TRENTON"
  },
  "42287": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WELCHS CREEK"
  },
  "42301": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "OWENSBORO"
  },
  "42303": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "OWENSBORO"
  },
  "42320": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BEAVER DAM"
  },
  "42321": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BEECH CREEK"
  },
  "42323": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BEECHMONT"
  },
  "42324": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BELTON"
  },
  "42325": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BREMEN"
  },
  "42326": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BROWDER"
  },
  "42327": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CALHOUN"
  },
  "42328": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CENTERTOWN"
  },
  "42330": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CENTRAL CITY"
  },
  "42332": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CLEATON"
  },
  "42333": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CROMWELL"
  },
  "42337": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DRAKESBORO"
  },
  "42338": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DUNDEE"
  },
  "42339": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DUNMOR"
  },
  "42343": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FORDSVILLE"
  },
  "42344": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GRAHAM"
  },
  "42345": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GREENVILLE"
  },
  "42347": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HARTFORD"
  },
  "42348": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HAWESVILLE"
  },
  "42349": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HORSE BRANCH"
  },
  "42350": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ISLAND"
  },
  "42351": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEWISPORT"
  },
  "42352": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LIVERMORE"
  },
  "42354": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MC HENRY"
  },
  "42355": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MACEO"
  },
  "42361": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "OLATON"
  },
  "42366": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PHILPOT"
  },
  "42368": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "REYNOLDS STATION"
  },
  "42369": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ROCKPORT"
  },
  "42370": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ROSINE"
  },
  "42371": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "RUMSEY"
  },
  "42372": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SACRAMENTO"
  },
  "42374": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SOUTH CARROLLTON"
  },
  "42376": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "UTICA"
  },
  "42378": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WHITESVILLE"
  },
  "42403": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BLACKFORD"
  },
  "42404": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CLAY"
  },
  "42406": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CORYDON"
  },
  "42408": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DAWSON SPRINGS"
  },
  "42409": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DIXON"
  },
  "42410": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "EARLINGTON"
  },
  "42411": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FREDONIA"
  },
  "42413": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HANSON"
  },
  "42420": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HENDERSON"
  },
  "42431": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MADISONVILLE"
  },
  "42436": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MANITOU"
  },
  "42437": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MORGANFIELD"
  },
  "42440": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MORTONS GAP"
  },
  "42441": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "NEBO"
  },
  "42442": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "NORTONVILLE"
  },
  "42444": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "POOLE"
  },
  "42445": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PRINCETON"
  },
  "42450": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PROVIDENCE"
  },
  "42451": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "REED"
  },
  "42452": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ROBARDS"
  },
  "42453": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SAINT CHARLES"
  },
  "42455": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SEBREE"
  },
  "42456": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SLAUGHTERS"
  },
  "42457": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SMITH MILLS"
  },
  "42458": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SPOTTSVILLE"
  },
  "42459": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "STURGIS"
  },
  "42461": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "UNIONTOWN"
  },
  "42462": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WAVERLY"
  },
  "42463": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WHEATCROFT"
  },
  "42464": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WHITE PLAINS"
  },
  "42501": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SOMERSET"
  },
  "42503": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SOMERSET"
  },
  "42516": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BETHELRIDGE"
  },
  "42518": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BRONSTON"
  },
  "42519": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BURNSIDE"
  },
  "42528": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DUNNVILLE"
  },
  "42533": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "FERGUSON"
  },
  "42539": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LIBERTY"
  },
  "42541": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MIDDLEBURG"
  },
  "42544": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "NANCY"
  },
  "42553": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SCIENCE HILL"
  },
  "42565": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WINDSOR"
  },
  "42566": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "YOSEMITE"
  },
  "42567": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "EUBANK"
  },
  "42602": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ALBANY"
  },
  "42603": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ALPHA"
  },
  "42629": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "JAMESTOWN"
  },
  "42631": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MARSHES SIDING"
  },
  "42633": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MONTICELLO"
  },
  "42634": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PARKERS LAKE"
  },
  "42635": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "PINE KNOT"
  },
  "42638": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "REVELO"
  },
  "42642": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "RUSSELL SPRINGS"
  },
  "42647": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "STEARNS"
  },
  "42649": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "STRUNK"
  },
  "42653": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WHITLEY CITY"
  },
  "42701": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ELIZABETHTOWN"
  },
  "42712": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BIG CLIFTY"
  },
  "42713": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BONNIEVILLE"
  },
  "42715": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BREEDING"
  },
  "42716": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BUFFALO"
  },
  "42717": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "BURKESVILLE"
  },
  "42718": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CAMPBELLSVILLE"
  },
  "42721": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CANEYVILLE"
  },
  "42722": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CANMER"
  },
  "42724": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CECILIA"
  },
  "42726": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CLARKSON"
  },
  "42728": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "COLUMBIA"
  },
  "42729": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "CUB RUN"
  },
  "42731": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "DUBRE"
  },
  "42732": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "EASTVIEW"
  },
  "42733": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "ELK HORN"
  },
  "42740": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GLENDALE"
  },
  "42741": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GLENS FORK"
  },
  "42742": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GRADYVILLE"
  },
  "42743": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "GREENSBURG"
  },
  "42746": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HARDYVILLE"
  },
  "42748": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HODGENVILLE"
  },
  "42749": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "HORSE CAVE"
  },
  "42753": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "KNIFLEY"
  },
  "42754": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "LEITCHFIELD"
  },
  "42757": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MAGNOLIA"
  },
  "42759": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MARROWBONE"
  },
  "42762": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MILLWOOD"
  },
  "42765": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "MUNFORDVILLE"
  },
  "42776": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SONORA"
  },
  "42782": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "SUMMERSVILLE"
  },
  "42784": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "UPTON"
  },
  "42788": {
    stateCode: "KY",
    stateName: "Kentucky",
    city: "WHITE MILLS"
  },
  "43001": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ALEXANDRIA"
  },
  "43002": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AMLIN"
  },
  "43003": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ASHLEY"
  },
  "43004": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BLACKLICK"
  },
  "43005": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BLADENSBURG"
  },
  "43006": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BRINKHAVEN"
  },
  "43008": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BUCKEYE LAKE"
  },
  "43009": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CABLE"
  },
  "43010": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CATAWBA"
  },
  "43011": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CENTERBURG"
  },
  "43013": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CROTON"
  },
  "43014": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DANVILLE"
  },
  "43015": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DELAWARE"
  },
  "43016": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DUBLIN"
  },
  "43017": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DUBLIN"
  },
  "43018": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ETNA"
  },
  "43019": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FREDERICKTOWN"
  },
  "43021": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GALENA"
  },
  "43022": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GAMBIER"
  },
  "43023": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GRANVILLE"
  },
  "43025": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HEBRON"
  },
  "43026": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HILLIARD"
  },
  "43028": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HOWARD"
  },
  "43029": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "IRWIN"
  },
  "43031": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "JOHNSTOWN"
  },
  "43032": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "KILBOURNE"
  },
  "43033": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "KIRKERSVILLE"
  },
  "43035": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LEWIS CENTER"
  },
  "43036": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MAGNETIC SPRINGS"
  },
  "43037": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MARTINSBURG"
  },
  "43040": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MARYSVILLE"
  },
  "43044": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MECHANICSBURG"
  },
  "43045": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MILFORD CENTER"
  },
  "43046": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MILLERSPORT"
  },
  "43050": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MOUNT VERNON"
  },
  "43054": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW ALBANY"
  },
  "43055": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEWARK"
  },
  "43056": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HEATH"
  },
  "43060": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORTH LEWISBURG"
  },
  "43061": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OSTRANDER"
  },
  "43062": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PATASKALA"
  },
  "43064": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PLAIN CITY"
  },
  "43065": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "POWELL"
  },
  "43066": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RADNOR"
  },
  "43067": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RAYMOND"
  },
  "43068": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "REYNOLDSBURG"
  },
  "43070": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ROSEWOOD"
  },
  "43071": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SAINT LOUISVILLE"
  },
  "43072": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SAINT PARIS"
  },
  "43074": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SUNBURY"
  },
  "43076": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "THORNVILLE"
  },
  "43077": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "UNIONVILLE CENTER"
  },
  "43078": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "URBANA"
  },
  "43080": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "UTICA"
  },
  "43081": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WESTERVILLE"
  },
  "43082": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WESTERVILLE"
  },
  "43084": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WOODSTOCK"
  },
  "43085": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43101": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ADELPHI"
  },
  "43102": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AMANDA"
  },
  "43103": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ASHVILLE"
  },
  "43105": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BALTIMORE"
  },
  "43106": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BLOOMINGBURG"
  },
  "43107": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BREMEN"
  },
  "43109": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BRICE"
  },
  "43110": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CANAL WINCHESTER"
  },
  "43111": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CARBON HILL"
  },
  "43112": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CARROLL"
  },
  "43113": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CIRCLEVILLE"
  },
  "43115": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLARKSBURG"
  },
  "43116": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COMMERCIAL POINT"
  },
  "43119": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GALLOWAY"
  },
  "43123": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GROVE CITY"
  },
  "43125": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GROVEPORT"
  },
  "43126": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HARRISBURG"
  },
  "43127": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HAYDENVILLE"
  },
  "43128": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "JEFFERSONVILLE"
  },
  "43130": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LANCASTER"
  },
  "43135": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LAURELVILLE"
  },
  "43136": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LITHOPOLIS"
  },
  "43137": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LOCKBOURNE"
  },
  "43138": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LOGAN"
  },
  "43140": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LONDON"
  },
  "43142": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MILLEDGEVILLE"
  },
  "43143": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MOUNT STERLING"
  },
  "43144": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MURRAY CITY"
  },
  "43145": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW HOLLAND"
  },
  "43146": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ORIENT"
  },
  "43147": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PICKERINGTON"
  },
  "43148": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PLEASANTVILLE"
  },
  "43149": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ROCKBRIDGE"
  },
  "43150": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RUSHVILLE"
  },
  "43151": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SEDALIA"
  },
  "43152": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SOUTH BLOOMINGVILLE"
  },
  "43153": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SOUTH SOLON"
  },
  "43154": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "STOUTSVILLE"
  },
  "43155": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SUGAR GROVE"
  },
  "43156": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TARLTON"
  },
  "43157": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "THURSTON"
  },
  "43158": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "UNION FURNACE"
  },
  "43160": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WASHINGTON COURT HOUSE"
  },
  "43162": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WEST JEFFERSON"
  },
  "43164": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WILLIAMSPORT"
  },
  "43201": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43202": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43203": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43204": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43205": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43206": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43207": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43209": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43210": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43211": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43212": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43213": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43214": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43215": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43217": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43219": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43220": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43221": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43222": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43223": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43224": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43227": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43228": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43229": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43230": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43231": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43232": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43235": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43240": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS"
  },
  "43302": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MARION"
  },
  "43310": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BELLE CENTER"
  },
  "43311": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BELLEFONTAINE"
  },
  "43314": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CALEDONIA"
  },
  "43315": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CARDINGTON"
  },
  "43316": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CAREY"
  },
  "43317": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CHESTERVILLE"
  },
  "43318": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DE GRAFF"
  },
  "43319": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "EAST LIBERTY"
  },
  "43320": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "EDISON"
  },
  "43321": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FULTON"
  },
  "43322": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GREEN CAMP"
  },
  "43323": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HARPSTER"
  },
  "43324": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HUNTSVILLE"
  },
  "43326": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "KENTON"
  },
  "43331": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LAKEVIEW"
  },
  "43332": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LA RUE"
  },
  "43333": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LEWISTOWN"
  },
  "43334": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MARENGO"
  },
  "43336": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MIDDLEBURG"
  },
  "43337": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MORRAL"
  },
  "43338": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MOUNT GILEAD"
  },
  "43340": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MOUNT VICTORY"
  },
  "43341": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW BLOOMINGTON"
  },
  "43342": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PROSPECT"
  },
  "43343": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "QUINCY"
  },
  "43344": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RICHWOOD"
  },
  "43345": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RIDGEWAY"
  },
  "43347": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RUSHSYLVANIA"
  },
  "43348": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RUSSELLS POINT"
  },
  "43350": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SPARTA"
  },
  "43351": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "UPPER SANDUSKY"
  },
  "43356": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WALDO"
  },
  "43357": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WEST LIBERTY"
  },
  "43358": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WEST MANSFIELD"
  },
  "43359": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WHARTON"
  },
  "43360": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ZANESFIELD"
  },
  "43402": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BOWLING GREEN"
  },
  "43406": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BRADNER"
  },
  "43407": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BURGOON"
  },
  "43408": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLAY CENTER"
  },
  "43410": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLYDE"
  },
  "43412": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CURTICE"
  },
  "43413": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CYGNET"
  },
  "43416": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ELMORE"
  },
  "43420": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FREMONT"
  },
  "43430": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GENOA"
  },
  "43431": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GIBSONBURG"
  },
  "43432": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GRAYTOWN"
  },
  "43435": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HELENA"
  },
  "43437": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "JERRY CITY"
  },
  "43438": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "KELLEYS ISLAND"
  },
  "43439": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LACARNE"
  },
  "43440": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LAKESIDE MARBLEHEAD"
  },
  "43442": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LINDSEY"
  },
  "43443": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LUCKEY"
  },
  "43445": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MARTIN"
  },
  "43447": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MILLBURY"
  },
  "43449": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OAK HARBOR"
  },
  "43450": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PEMBERVILLE"
  },
  "43451": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PORTAGE"
  },
  "43452": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PORT CLINTON"
  },
  "43456": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PUT IN BAY"
  },
  "43457": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RISINGSUN"
  },
  "43458": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ROCKY RIDGE"
  },
  "43460": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ROSSFORD"
  },
  "43462": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RUDOLPH"
  },
  "43464": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "VICKERY"
  },
  "43465": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WALBRIDGE"
  },
  "43466": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WAYNE"
  },
  "43467": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WEST MILLGROVE"
  },
  "43468": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WILLISTON"
  },
  "43469": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WOODVILLE"
  },
  "43501": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ALVORDTON"
  },
  "43502": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ARCHBOLD"
  },
  "43504": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BERKEY"
  },
  "43506": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BRYAN"
  },
  "43511": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CUSTAR"
  },
  "43512": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DEFIANCE"
  },
  "43515": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DELTA"
  },
  "43516": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DESHLER"
  },
  "43517": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "EDGERTON"
  },
  "43518": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "EDON"
  },
  "43521": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FAYETTE"
  },
  "43522": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GRAND RAPIDS"
  },
  "43524": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HAMLER"
  },
  "43525": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HASKINS"
  },
  "43526": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HICKSVILLE"
  },
  "43527": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HOLGATE"
  },
  "43528": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HOLLAND"
  },
  "43529": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HOYTVILLE"
  },
  "43531": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "KUNKLE"
  },
  "43532": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LIBERTY CENTER"
  },
  "43533": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LYONS"
  },
  "43534": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MC CLURE"
  },
  "43535": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MALINTA"
  },
  "43536": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MARK CENTER"
  },
  "43537": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MAUMEE"
  },
  "43540": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "METAMORA"
  },
  "43541": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MILTON CENTER"
  },
  "43542": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MONCLOVA"
  },
  "43543": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MONTPELIER"
  },
  "43545": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NAPOLEON"
  },
  "43547": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEAPOLIS"
  },
  "43548": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW BAVARIA"
  },
  "43549": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEY"
  },
  "43551": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PERRYSBURG"
  },
  "43553": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PETTISVILLE"
  },
  "43554": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PIONEER"
  },
  "43555": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RIDGEVILLE CORNERS"
  },
  "43556": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SHERWOOD"
  },
  "43557": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "STRYKER"
  },
  "43558": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SWANTON"
  },
  "43560": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SYLVANIA"
  },
  "43565": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TONTOGANY"
  },
  "43566": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WATERVILLE"
  },
  "43567": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WAUSEON"
  },
  "43569": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WESTON"
  },
  "43570": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WEST UNITY"
  },
  "43571": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WHITEHOUSE"
  },
  "43604": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TOLEDO"
  },
  "43605": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TOLEDO"
  },
  "43606": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TOLEDO"
  },
  "43607": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TOLEDO"
  },
  "43608": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TOLEDO"
  },
  "43609": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TOLEDO"
  },
  "43610": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TOLEDO"
  },
  "43611": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TOLEDO"
  },
  "43612": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TOLEDO"
  },
  "43613": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TOLEDO"
  },
  "43614": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TOLEDO"
  },
  "43615": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TOLEDO"
  },
  "43616": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OREGON"
  },
  "43617": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TOLEDO"
  },
  "43618": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OREGON"
  },
  "43619": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORTHWOOD"
  },
  "43620": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TOLEDO"
  },
  "43623": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TOLEDO"
  },
  "43701": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ZANESVILLE"
  },
  "43713": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BARNESVILLE"
  },
  "43716": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BEALLSVILLE"
  },
  "43717": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BELLE VALLEY"
  },
  "43718": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BELMONT"
  },
  "43719": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BETHESDA"
  },
  "43720": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BLUE ROCK"
  },
  "43721": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BROWNSVILLE"
  },
  "43722": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BUFFALO"
  },
  "43723": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BYESVILLE"
  },
  "43724": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CALDWELL"
  },
  "43725": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CAMBRIDGE"
  },
  "43727": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CHANDLERSVILLE"
  },
  "43728": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CHESTERHILL"
  },
  "43730": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CORNING"
  },
  "43731": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CROOKSVILLE"
  },
  "43732": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CUMBERLAND"
  },
  "43734": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DUNCAN FALLS"
  },
  "43735": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "EAST FULTONHAM"
  },
  "43738": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FULTONHAM"
  },
  "43739": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GLENFORD"
  },
  "43740": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GRATIOT"
  },
  "43746": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HOPEWELL"
  },
  "43747": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "JERUSALEM"
  },
  "43748": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "JUNCTION CITY"
  },
  "43749": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "KIMBOLTON"
  },
  "43754": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LEWISVILLE"
  },
  "43755": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LORE CITY"
  },
  "43756": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MCCONNELSVILLE"
  },
  "43758": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MALTA"
  },
  "43760": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MOUNT PERRY"
  },
  "43762": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW CONCORD"
  },
  "43764": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW LEXINGTON"
  },
  "43766": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW STRAITSVILLE"
  },
  "43767": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORWICH"
  },
  "43768": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OLD WASHINGTON"
  },
  "43771": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PHILO"
  },
  "43772": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PLEASANT CITY"
  },
  "43773": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "QUAKER CITY"
  },
  "43777": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ROSEVILLE"
  },
  "43778": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SALESVILLE"
  },
  "43779": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SARAHSVILLE"
  },
  "43780": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SENECAVILLE"
  },
  "43782": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SHAWNEE"
  },
  "43783": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SOMERSET"
  },
  "43787": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "STOCKPORT"
  },
  "43788": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SUMMERFIELD"
  },
  "43791": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WHITE COTTAGE"
  },
  "43793": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WOODSFIELD"
  },
  "43802": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ADAMSVILLE"
  },
  "43804": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BALTIC"
  },
  "43811": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CONESVILLE"
  },
  "43812": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COSHOCTON"
  },
  "43821": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DRESDEN"
  },
  "43822": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FRAZEYSBURG"
  },
  "43824": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FRESNO"
  },
  "43830": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NASHPORT"
  },
  "43832": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEWCOMERSTOWN"
  },
  "43837": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PORT WASHINGTON"
  },
  "43840": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "STONE CREEK"
  },
  "43842": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TRINWAY"
  },
  "43843": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WALHONDING"
  },
  "43844": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WARSAW"
  },
  "43845": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WEST LAFAYETTE"
  },
  "43901": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ADENA"
  },
  "43902": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ALLEDONIA"
  },
  "43903": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AMSTERDAM"
  },
  "43905": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BARTON"
  },
  "43906": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BELLAIRE"
  },
  "43907": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CADIZ"
  },
  "43908": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BERGHOLZ"
  },
  "43909": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BLAINE"
  },
  "43910": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BLOOMINGDALE"
  },
  "43912": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BRIDGEPORT"
  },
  "43913": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BRILLIANT"
  },
  "43914": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CAMERON"
  },
  "43915": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLARINGTON"
  },
  "43917": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DILLONVALE"
  },
  "43920": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "EAST LIVERPOOL"
  },
  "43925": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "EAST SPRINGFIELD"
  },
  "43926": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "EMPIRE"
  },
  "43927": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FAIRPOINT"
  },
  "43928": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GLENCOE"
  },
  "43930": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HAMMONDSVILLE"
  },
  "43932": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "IRONDALE"
  },
  "43933": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "JACOBSBURG"
  },
  "43934": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LANSING"
  },
  "43935": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MARTINS FERRY"
  },
  "43938": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MINGO JUNCTION"
  },
  "43939": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MOUNT PLEASANT"
  },
  "43940": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEFFS"
  },
  "43942": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "POWHATAN POINT"
  },
  "43943": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RAYLAND"
  },
  "43944": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RICHMOND"
  },
  "43945": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SALINEVILLE"
  },
  "43946": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SARDIS"
  },
  "43947": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SHADYSIDE"
  },
  "43948": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SMITHFIELD"
  },
  "43950": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SAINT CLAIRSVILLE"
  },
  "43951": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LAFFERTY"
  },
  "43952": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "STEUBENVILLE"
  },
  "43953": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "STEUBENVILLE"
  },
  "43961": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "STRATTON"
  },
  "43962": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SUMMITVILLE"
  },
  "43963": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TILTONSVILLE"
  },
  "43964": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TORONTO"
  },
  "43967": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WARNOCK"
  },
  "43968": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WELLSVILLE"
  },
  "43971": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "YORKVILLE"
  },
  "43972": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BANNOCK"
  },
  "43973": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FREEPORT"
  },
  "43974": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HARRISVILLE"
  },
  "43976": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HOPEDALE"
  },
  "43977": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FLUSHING"
  },
  "43981": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW ATHENS"
  },
  "43983": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PIEDMONT"
  },
  "43986": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "JEWETT"
  },
  "43988": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SCIO"
  },
  "44001": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AMHERST"
  },
  "44003": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ANDOVER"
  },
  "44004": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ASHTABULA"
  },
  "44010": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AUSTINBURG"
  },
  "44011": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AVON"
  },
  "44012": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AVON LAKE"
  },
  "44017": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BEREA"
  },
  "44021": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BURTON"
  },
  "44022": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CHAGRIN FALLS"
  },
  "44023": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CHAGRIN FALLS"
  },
  "44024": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CHARDON"
  },
  "44026": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CHESTERLAND"
  },
  "44028": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBIA STATION"
  },
  "44030": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CONNEAUT"
  },
  "44032": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DORSET"
  },
  "44035": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ELYRIA"
  },
  "44039": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORTH RIDGEVILLE"
  },
  "44040": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GATES MILLS"
  },
  "44041": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GENEVA"
  },
  "44044": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GRAFTON"
  },
  "44046": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HUNTSBURG"
  },
  "44047": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "JEFFERSON"
  },
  "44048": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "KINGSVILLE"
  },
  "44049": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "KIPTON"
  },
  "44050": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LAGRANGE"
  },
  "44052": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LORAIN"
  },
  "44053": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LORAIN"
  },
  "44054": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SHEFFIELD LAKE"
  },
  "44055": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LORAIN"
  },
  "44056": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MACEDONIA"
  },
  "44057": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MADISON"
  },
  "44060": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MENTOR"
  },
  "44062": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MIDDLEFIELD"
  },
  "44064": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MONTVILLE"
  },
  "44065": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEWBURY"
  },
  "44067": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORTHFIELD"
  },
  "44070": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORTH OLMSTED"
  },
  "44072": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NOVELTY"
  },
  "44074": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OBERLIN"
  },
  "44076": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ORWELL"
  },
  "44077": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PAINESVILLE"
  },
  "44080": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PARKMAN"
  },
  "44081": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PERRY"
  },
  "44082": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PIERPONT"
  },
  "44084": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ROCK CREEK"
  },
  "44085": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ROME"
  },
  "44086": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "THOMPSON"
  },
  "44087": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TWINSBURG"
  },
  "44089": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "VERMILION"
  },
  "44090": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WELLINGTON"
  },
  "44092": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WICKLIFFE"
  },
  "44093": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WILLIAMSFIELD"
  },
  "44094": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WILLOUGHBY"
  },
  "44095": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "EASTLAKE"
  },
  "44099": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WINDSOR"
  },
  "44102": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44103": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44104": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44105": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44106": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44107": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LAKEWOOD"
  },
  "44108": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44109": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44110": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44111": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44112": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44113": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44114": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44115": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44116": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ROCKY RIVER"
  },
  "44117": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "EUCLID"
  },
  "44118": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44119": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44120": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44121": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44122": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BEACHWOOD"
  },
  "44123": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "EUCLID"
  },
  "44124": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44125": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44126": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44127": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44128": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44129": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44130": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44131": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "INDEPENDENCE"
  },
  "44132": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "EUCLID"
  },
  "44133": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORTH ROYALTON"
  },
  "44134": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44135": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44136": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "STRONGSVILLE"
  },
  "44137": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MAPLE HEIGHTS"
  },
  "44138": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OLMSTED FALLS"
  },
  "44139": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SOLON"
  },
  "44140": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BAY VILLAGE"
  },
  "44141": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BRECKSVILLE"
  },
  "44142": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BROOK PARK"
  },
  "44143": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44144": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVELAND"
  },
  "44145": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WESTLAKE"
  },
  "44146": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BEDFORD"
  },
  "44147": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BROADVIEW HEIGHTS"
  },
  "44201": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ATWATER"
  },
  "44202": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AURORA"
  },
  "44203": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BARBERTON"
  },
  "44212": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BRUNSWICK"
  },
  "44214": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BURBANK"
  },
  "44215": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CHIPPEWA LAKE"
  },
  "44216": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLINTON"
  },
  "44217": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CRESTON"
  },
  "44221": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CUYAHOGA FALLS"
  },
  "44223": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CUYAHOGA FALLS"
  },
  "44224": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "STOW"
  },
  "44230": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DOYLESTOWN"
  },
  "44231": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GARRETTSVILLE"
  },
  "44233": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HINCKLEY"
  },
  "44234": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HIRAM"
  },
  "44235": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HOMERVILLE"
  },
  "44236": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HUDSON"
  },
  "44240": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "KENT"
  },
  "44241": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "STREETSBORO"
  },
  "44250": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LAKEMORE"
  },
  "44251": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WESTFIELD CENTER"
  },
  "44253": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LITCHFIELD"
  },
  "44254": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LODI"
  },
  "44255": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MANTUA"
  },
  "44256": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MEDINA"
  },
  "44260": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MOGADORE"
  },
  "44262": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MUNROE FALLS"
  },
  "44264": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PENINSULA"
  },
  "44266": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RAVENNA"
  },
  "44270": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RITTMAN"
  },
  "44272": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ROOTSTOWN"
  },
  "44273": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SEVILLE"
  },
  "44275": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SPENCER"
  },
  "44276": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "STERLING"
  },
  "44278": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TALLMADGE"
  },
  "44280": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "VALLEY CITY"
  },
  "44281": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WADSWORTH"
  },
  "44286": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RICHFIELD"
  },
  "44287": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WEST SALEM"
  },
  "44288": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WINDHAM"
  },
  "44301": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AKRON"
  },
  "44302": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AKRON"
  },
  "44303": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AKRON"
  },
  "44304": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AKRON"
  },
  "44305": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AKRON"
  },
  "44306": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AKRON"
  },
  "44307": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AKRON"
  },
  "44308": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AKRON"
  },
  "44310": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AKRON"
  },
  "44311": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AKRON"
  },
  "44312": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AKRON"
  },
  "44313": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AKRON"
  },
  "44314": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AKRON"
  },
  "44319": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AKRON"
  },
  "44320": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AKRON"
  },
  "44321": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AKRON"
  },
  "44322": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AKRON"
  },
  "44333": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AKRON"
  },
  "44401": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BERLIN CENTER"
  },
  "44402": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BRISTOLVILLE"
  },
  "44403": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BROOKFIELD"
  },
  "44404": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BURGHILL"
  },
  "44405": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CAMPBELL"
  },
  "44406": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CANFIELD"
  },
  "44408": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBIANA"
  },
  "44410": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CORTLAND"
  },
  "44411": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DEERFIELD"
  },
  "44412": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DIAMOND"
  },
  "44413": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "EAST PALESTINE"
  },
  "44417": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FARMDALE"
  },
  "44418": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FOWLER"
  },
  "44420": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GIRARD"
  },
  "44423": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HANOVERTON"
  },
  "44425": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HUBBARD"
  },
  "44427": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "KENSINGTON"
  },
  "44428": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "KINSMAN"
  },
  "44429": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LAKE MILTON"
  },
  "44430": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LEAVITTSBURG"
  },
  "44431": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LEETONIA"
  },
  "44432": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LISBON"
  },
  "44436": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LOWELLVILLE"
  },
  "44437": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MC DONALD"
  },
  "44438": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MASURY"
  },
  "44440": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MINERAL RIDGE"
  },
  "44441": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEGLEY"
  },
  "44442": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW MIDDLETOWN"
  },
  "44443": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW SPRINGFIELD"
  },
  "44444": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEWTON FALLS"
  },
  "44445": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW WATERFORD"
  },
  "44446": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NILES"
  },
  "44449": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORTH BENTON"
  },
  "44450": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORTH BLOOMFIELD"
  },
  "44451": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORTH JACKSON"
  },
  "44452": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORTH LIMA"
  },
  "44454": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PETERSBURG"
  },
  "44455": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ROGERS"
  },
  "44460": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SALEM"
  },
  "44470": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SOUTHINGTON"
  },
  "44471": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "STRUTHERS"
  },
  "44473": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "VIENNA"
  },
  "44481": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WARREN"
  },
  "44483": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WARREN"
  },
  "44484": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WARREN"
  },
  "44485": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WARREN"
  },
  "44490": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WASHINGTONVILLE"
  },
  "44491": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WEST FARMINGTON"
  },
  "44502": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "YOUNGSTOWN"
  },
  "44503": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "YOUNGSTOWN"
  },
  "44504": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "YOUNGSTOWN"
  },
  "44505": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "YOUNGSTOWN"
  },
  "44506": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "YOUNGSTOWN"
  },
  "44507": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "YOUNGSTOWN"
  },
  "44509": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "YOUNGSTOWN"
  },
  "44510": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "YOUNGSTOWN"
  },
  "44511": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "YOUNGSTOWN"
  },
  "44512": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "YOUNGSTOWN"
  },
  "44514": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "YOUNGSTOWN"
  },
  "44515": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "YOUNGSTOWN"
  },
  "44601": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ALLIANCE"
  },
  "44606": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "APPLE CREEK"
  },
  "44608": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BEACH CITY"
  },
  "44609": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BELOIT"
  },
  "44610": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BERLIN"
  },
  "44611": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BIG PRAIRIE"
  },
  "44612": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BOLIVAR"
  },
  "44613": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BREWSTER"
  },
  "44614": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CANAL FULTON"
  },
  "44615": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CARROLLTON"
  },
  "44618": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DALTON"
  },
  "44619": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAMASCUS"
  },
  "44620": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DELLROY"
  },
  "44621": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DENNISON"
  },
  "44622": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DOVER"
  },
  "44624": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DUNDEE"
  },
  "44625": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "EAST ROCHESTER"
  },
  "44626": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "EAST SPARTA"
  },
  "44627": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FREDERICKSBURG"
  },
  "44628": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GLENMONT"
  },
  "44629": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GNADENHUTTEN"
  },
  "44632": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HARTVILLE"
  },
  "44633": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HOLMESVILLE"
  },
  "44634": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HOMEWORTH"
  },
  "44637": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "KILLBUCK"
  },
  "44638": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LAKEVILLE"
  },
  "44640": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LIMAVILLE"
  },
  "44641": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LOUISVILLE"
  },
  "44643": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MAGNOLIA"
  },
  "44644": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MALVERN"
  },
  "44645": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MARSHALLVILLE"
  },
  "44646": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MASSILLON"
  },
  "44647": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MASSILLON"
  },
  "44651": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MECHANICSTOWN"
  },
  "44653": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MIDVALE"
  },
  "44654": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MILLERSBURG"
  },
  "44656": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MINERAL CITY"
  },
  "44657": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MINERVA"
  },
  "44659": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MOUNT EATON"
  },
  "44661": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NASHVILLE"
  },
  "44662": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NAVARRE"
  },
  "44663": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW PHILADELPHIA"
  },
  "44666": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORTH LAWRENCE"
  },
  "44667": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ORRVILLE"
  },
  "44669": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PARIS"
  },
  "44670": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ROBERTSVILLE"
  },
  "44671": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SANDYVILLE"
  },
  "44672": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SEBRING"
  },
  "44675": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SHERRODSVILLE"
  },
  "44676": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SHREVE"
  },
  "44677": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SMITHVILLE"
  },
  "44678": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SOMERDALE"
  },
  "44680": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "STRASBURG"
  },
  "44681": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SUGARCREEK"
  },
  "44682": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TUSCARAWAS"
  },
  "44683": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "UHRICHSVILLE"
  },
  "44685": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "UNIONTOWN"
  },
  "44688": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WAYNESBURG"
  },
  "44689": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WILMOT"
  },
  "44690": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WINESBURG"
  },
  "44691": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WOOSTER"
  },
  "44693": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DEERSVILLE"
  },
  "44695": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BOWERSTON"
  },
  "44697": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ZOAR"
  },
  "44699": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TIPPECANOE"
  },
  "44702": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CANTON"
  },
  "44703": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CANTON"
  },
  "44704": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CANTON"
  },
  "44705": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CANTON"
  },
  "44706": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CANTON"
  },
  "44707": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CANTON"
  },
  "44708": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CANTON"
  },
  "44709": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CANTON"
  },
  "44710": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CANTON"
  },
  "44714": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CANTON"
  },
  "44718": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CANTON"
  },
  "44720": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORTH CANTON"
  },
  "44721": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CANTON"
  },
  "44730": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "EAST CANTON"
  },
  "44802": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ALVADA"
  },
  "44804": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ARCADIA"
  },
  "44805": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ASHLAND"
  },
  "44807": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ATTICA"
  },
  "44809": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BASCOM"
  },
  "44811": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BELLEVUE"
  },
  "44813": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BELLVILLE"
  },
  "44814": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BERLIN HEIGHTS"
  },
  "44815": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BETTSVILLE"
  },
  "44816": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BIRMINGHAM"
  },
  "44817": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BLOOMDALE"
  },
  "44818": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BLOOMVILLE"
  },
  "44820": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BUCYRUS"
  },
  "44822": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BUTLER"
  },
  "44824": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CASTALIA"
  },
  "44825": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CHATFIELD"
  },
  "44826": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLLINS"
  },
  "44827": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CRESTLINE"
  },
  "44828": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FLAT ROCK"
  },
  "44830": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FOSTORIA"
  },
  "44833": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GALION"
  },
  "44836": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GREEN SPRINGS"
  },
  "44837": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GREENWICH"
  },
  "44838": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HAYESVILLE"
  },
  "44839": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HURON"
  },
  "44840": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "JEROMESVILLE"
  },
  "44841": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "KANSAS"
  },
  "44842": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LOUDONVILLE"
  },
  "44843": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LUCAS"
  },
  "44844": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MC CUTCHENVILLE"
  },
  "44846": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MILAN"
  },
  "44847": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MONROEVILLE"
  },
  "44849": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEVADA"
  },
  "44850": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW HAVEN"
  },
  "44851": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW LONDON"
  },
  "44853": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW RIEGEL"
  },
  "44854": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW WASHINGTON"
  },
  "44855": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORTH FAIRFIELD"
  },
  "44856": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORTH ROBINSON"
  },
  "44857": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORWALK"
  },
  "44859": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NOVA"
  },
  "44860": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OCEOLA"
  },
  "44861": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OLD FORT"
  },
  "44864": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PERRYSVILLE"
  },
  "44865": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PLYMOUTH"
  },
  "44866": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "POLK"
  },
  "44867": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "REPUBLIC"
  },
  "44870": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SANDUSKY"
  },
  "44874": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SAVANNAH"
  },
  "44875": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SHELBY"
  },
  "44878": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SHILOH"
  },
  "44880": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SULLIVAN"
  },
  "44882": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SYCAMORE"
  },
  "44883": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TIFFIN"
  },
  "44887": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TIRO"
  },
  "44889": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WAKEMAN"
  },
  "44890": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WILLARD"
  },
  "44902": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MANSFIELD"
  },
  "44903": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MANSFIELD"
  },
  "44904": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MANSFIELD"
  },
  "44905": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MANSFIELD"
  },
  "44906": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MANSFIELD"
  },
  "44907": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MANSFIELD"
  },
  "45001": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ADDYSTON"
  },
  "45002": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLEVES"
  },
  "45003": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLLEGE CORNER"
  },
  "45005": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FRANKLIN"
  },
  "45011": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HAMILTON"
  },
  "45013": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HAMILTON"
  },
  "45014": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FAIRFIELD"
  },
  "45015": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HAMILTON"
  },
  "45030": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HARRISON"
  },
  "45032": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HARVEYSBURG"
  },
  "45033": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HOOVEN"
  },
  "45034": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "KINGS MILLS"
  },
  "45036": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LEBANON"
  },
  "45039": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MAINEVILLE"
  },
  "45040": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MASON"
  },
  "45041": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MIAMITOWN"
  },
  "45042": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MIDDLETOWN"
  },
  "45044": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MIDDLETOWN"
  },
  "45050": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MONROE"
  },
  "45052": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORTH BEND"
  },
  "45053": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OKEANA"
  },
  "45054": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OREGONIA"
  },
  "45056": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OXFORD"
  },
  "45062": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SEVEN MILE"
  },
  "45064": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SOMERVILLE"
  },
  "45065": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SOUTH LEBANON"
  },
  "45066": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SPRINGBORO"
  },
  "45067": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TRENTON"
  },
  "45068": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WAYNESVILLE"
  },
  "45069": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WEST CHESTER"
  },
  "45070": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WEST ELKTON"
  },
  "45101": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ABERDEEN"
  },
  "45102": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AMELIA"
  },
  "45103": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BATAVIA"
  },
  "45106": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BETHEL"
  },
  "45107": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BLANCHESTER"
  },
  "45111": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CAMP DENNISON"
  },
  "45112": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CHILO"
  },
  "45113": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLARKSVILLE"
  },
  "45114": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CUBA"
  },
  "45115": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DECATUR"
  },
  "45118": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FAYETTEVILLE"
  },
  "45120": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FELICITY"
  },
  "45121": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GEORGETOWN"
  },
  "45122": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GOSHEN"
  },
  "45123": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GREENFIELD"
  },
  "45130": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HAMERSVILLE"
  },
  "45131": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HIGGINSPORT"
  },
  "45132": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HIGHLAND"
  },
  "45133": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HILLSBORO"
  },
  "45135": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LEESBURG"
  },
  "45140": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LOVELAND"
  },
  "45142": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LYNCHBURG"
  },
  "45144": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MANCHESTER"
  },
  "45146": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MARTINSVILLE"
  },
  "45148": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MIDLAND"
  },
  "45150": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MILFORD"
  },
  "45152": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MORROW"
  },
  "45153": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MOSCOW"
  },
  "45154": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MOUNT ORAB"
  },
  "45155": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MOWRYSTOWN"
  },
  "45156": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEVILLE"
  },
  "45157": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW RICHMOND"
  },
  "45159": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW VIENNA"
  },
  "45160": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OWENSVILLE"
  },
  "45162": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PLEASANT PLAIN"
  },
  "45164": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PORT WILLIAM"
  },
  "45167": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RIPLEY"
  },
  "45168": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RUSSELLVILLE"
  },
  "45169": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SABINA"
  },
  "45171": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SARDINIA"
  },
  "45172": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SINKING SPRING"
  },
  "45174": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TERRACE PARK"
  },
  "45176": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WILLIAMSBURG"
  },
  "45177": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WILMINGTON"
  },
  "45202": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45203": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45204": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45205": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45206": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45207": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45208": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45209": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45211": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45212": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45213": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45214": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45215": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45216": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45217": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45218": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45219": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45220": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45223": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45224": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45225": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45226": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45227": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45229": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45230": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45231": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45232": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45233": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45236": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45237": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45238": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45239": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45240": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45241": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45242": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45243": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45244": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45245": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45246": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45247": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45248": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45249": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45251": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45252": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45255": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CINCINNATI"
  },
  "45301": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ALPHA"
  },
  "45302": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ANNA"
  },
  "45303": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ANSONIA"
  },
  "45304": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ARCANUM"
  },
  "45305": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BELLBROOK"
  },
  "45306": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BOTKINS"
  },
  "45307": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BOWERSVILLE"
  },
  "45308": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BRADFORD"
  },
  "45309": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BROOKVILLE"
  },
  "45310": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BURKETTSVILLE"
  },
  "45311": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CAMDEN"
  },
  "45312": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CASSTOWN"
  },
  "45314": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CEDARVILLE"
  },
  "45315": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLAYTON"
  },
  "45316": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLIFTON"
  },
  "45317": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CONOVER"
  },
  "45318": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COVINGTON"
  },
  "45320": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "EATON"
  },
  "45321": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ELDORADO"
  },
  "45322": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ENGLEWOOD"
  },
  "45323": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ENON"
  },
  "45324": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FAIRBORN"
  },
  "45325": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FARMERSVILLE"
  },
  "45326": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FLETCHER"
  },
  "45327": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GERMANTOWN"
  },
  "45328": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GETTYSBURG"
  },
  "45330": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GRATIS"
  },
  "45331": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GREENVILLE"
  },
  "45332": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HOLLANSBURG"
  },
  "45333": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HOUSTON"
  },
  "45334": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "JACKSON CENTER"
  },
  "45335": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "JAMESTOWN"
  },
  "45336": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "KETTLERSVILLE"
  },
  "45337": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LAURA"
  },
  "45338": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LEWISBURG"
  },
  "45339": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LUDLOW FALLS"
  },
  "45340": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MAPLEWOOD"
  },
  "45341": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MEDWAY"
  },
  "45342": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MIAMISBURG"
  },
  "45344": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW CARLISLE"
  },
  "45345": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW LEBANON"
  },
  "45346": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW MADISON"
  },
  "45347": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW PARIS"
  },
  "45348": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW WESTON"
  },
  "45349": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORTH HAMPTON"
  },
  "45351": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OSGOOD"
  },
  "45352": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PALESTINE"
  },
  "45353": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PEMBERTON"
  },
  "45354": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PHILLIPSBURG"
  },
  "45356": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PIQUA"
  },
  "45359": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PLEASANT HILL"
  },
  "45360": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PORT JEFFERSON"
  },
  "45362": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ROSSBURG"
  },
  "45363": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RUSSIA"
  },
  "45365": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SIDNEY"
  },
  "45368": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SOUTH CHARLESTON"
  },
  "45369": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SOUTH VIENNA"
  },
  "45370": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SPRING VALLEY"
  },
  "45371": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TIPP CITY"
  },
  "45373": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TROY"
  },
  "45377": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "VANDALIA"
  },
  "45378": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "VERONA"
  },
  "45380": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "VERSAILLES"
  },
  "45381": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WEST ALEXANDRIA"
  },
  "45382": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WEST MANCHESTER"
  },
  "45383": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WEST MILTON"
  },
  "45384": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WILBERFORCE"
  },
  "45385": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "XENIA"
  },
  "45387": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "YELLOW SPRINGS"
  },
  "45388": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "YORKSHIRE"
  },
  "45389": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CHRISTIANSBURG"
  },
  "45390": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "UNION CITY"
  },
  "45402": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45403": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45404": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45405": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45406": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45408": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45409": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45410": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45414": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45415": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45416": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45417": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45418": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45419": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45420": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45424": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45426": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45427": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45429": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45430": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45431": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45432": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45433": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45434": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45439": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45440": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45449": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45458": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45459": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DAYTON"
  },
  "45502": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SPRINGFIELD"
  },
  "45503": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SPRINGFIELD"
  },
  "45504": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SPRINGFIELD"
  },
  "45505": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SPRINGFIELD"
  },
  "45506": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SPRINGFIELD"
  },
  "45601": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CHILLICOTHE"
  },
  "45612": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BAINBRIDGE"
  },
  "45613": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BEAVER"
  },
  "45614": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BIDWELL"
  },
  "45616": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BLUE CREEK"
  },
  "45619": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CHESAPEAKE"
  },
  "45620": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CHESHIRE"
  },
  "45621": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COALTON"
  },
  "45622": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CREOLA"
  },
  "45623": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CROWN CITY"
  },
  "45624": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CYNTHIANA"
  },
  "45628": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FRANKFORT"
  },
  "45629": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FRANKLIN FURNACE"
  },
  "45631": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GALLIPOLIS"
  },
  "45634": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HAMDEN"
  },
  "45638": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "IRONTON"
  },
  "45640": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "JACKSON"
  },
  "45642": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "JASPER"
  },
  "45644": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "KINGSTON"
  },
  "45645": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "KITTS HILL"
  },
  "45646": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LATHAM"
  },
  "45647": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LONDONDERRY"
  },
  "45648": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LUCASVILLE"
  },
  "45650": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LYNX"
  },
  "45651": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MC ARTHUR"
  },
  "45652": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MC DERMOTT"
  },
  "45653": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MINFORD"
  },
  "45654": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW PLYMOUTH"
  },
  "45656": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OAK HILL"
  },
  "45657": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OTWAY"
  },
  "45658": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PATRIOT"
  },
  "45659": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PEDRO"
  },
  "45660": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PEEBLES"
  },
  "45661": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PIKETON"
  },
  "45662": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PORTSMOUTH"
  },
  "45663": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WEST PORTSMOUTH"
  },
  "45669": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PROCTORVILLE"
  },
  "45671": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RARDEN"
  },
  "45672": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RAY"
  },
  "45673": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RICHMOND DALE"
  },
  "45674": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RIO GRANDE"
  },
  "45678": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SCOTTOWN"
  },
  "45679": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SEAMAN"
  },
  "45680": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SOUTH POINT"
  },
  "45681": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SOUTH SALEM"
  },
  "45682": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SOUTH WEBSTER"
  },
  "45684": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "STOUT"
  },
  "45685": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "THURMAN"
  },
  "45686": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "VINTON"
  },
  "45688": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WATERLOO"
  },
  "45690": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WAVERLY"
  },
  "45692": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WELLSTON"
  },
  "45693": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WEST UNION"
  },
  "45694": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WHEELERSBURG"
  },
  "45695": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WILKESVILLE"
  },
  "45696": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WILLOW WOOD"
  },
  "45697": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WINCHESTER"
  },
  "45698": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ZALESKI"
  },
  "45701": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ATHENS"
  },
  "45710": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ALBANY"
  },
  "45711": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "AMESVILLE"
  },
  "45712": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BARLOW"
  },
  "45713": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BARTLETT"
  },
  "45714": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BELPRE"
  },
  "45715": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BEVERLY"
  },
  "45716": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BUCHTEL"
  },
  "45719": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CHAUNCEY"
  },
  "45723": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COOLVILLE"
  },
  "45724": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CUTLER"
  },
  "45727": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DEXTER CITY"
  },
  "45729": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FLEMING"
  },
  "45732": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GLOUSTER"
  },
  "45734": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GRAYSVILLE"
  },
  "45735": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GUYSVILLE"
  },
  "45740": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "JACKSONVILLE"
  },
  "45741": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LANGSVILLE"
  },
  "45742": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LITTLE HOCKING"
  },
  "45743": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LONG BOTTOM"
  },
  "45744": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LOWELL"
  },
  "45745": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LOWER SALEM"
  },
  "45746": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MACKSBURG"
  },
  "45750": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MARIETTA"
  },
  "45760": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MIDDLEPORT"
  },
  "45761": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MILLFIELD"
  },
  "45764": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NELSONVILLE"
  },
  "45766": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW MARSHFIELD"
  },
  "45767": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW MATAMORAS"
  },
  "45768": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEWPORT"
  },
  "45769": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "POMEROY"
  },
  "45770": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PORTLAND"
  },
  "45771": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RACINE"
  },
  "45772": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "REEDSVILLE"
  },
  "45773": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RENO"
  },
  "45775": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RUTLAND"
  },
  "45776": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SHADE"
  },
  "45778": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "STEWART"
  },
  "45779": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SYRACUSE"
  },
  "45780": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "THE PLAINS"
  },
  "45782": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "TRIMBLE"
  },
  "45784": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "VINCENT"
  },
  "45786": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WATERFORD"
  },
  "45788": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WHIPPLE"
  },
  "45789": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WINGETT RUN"
  },
  "45801": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LIMA"
  },
  "45804": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LIMA"
  },
  "45805": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LIMA"
  },
  "45806": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LIMA"
  },
  "45807": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LIMA"
  },
  "45808": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BEAVERDAM"
  },
  "45809": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GOMER"
  },
  "45810": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ADA"
  },
  "45812": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ALGER"
  },
  "45813": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ANTWERP"
  },
  "45814": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ARLINGTON"
  },
  "45816": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BENTON RIDGE"
  },
  "45817": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BLUFFTON"
  },
  "45819": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "BUCKLAND"
  },
  "45820": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CAIRO"
  },
  "45821": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CECIL"
  },
  "45822": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CELINA"
  },
  "45827": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CLOVERDALE"
  },
  "45828": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLDWATER"
  },
  "45830": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "COLUMBUS GROVE"
  },
  "45831": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CONTINENTAL"
  },
  "45832": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "CONVOY"
  },
  "45833": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DELPHOS"
  },
  "45835": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DOLA"
  },
  "45836": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DUNKIRK"
  },
  "45837": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "DUPONT"
  },
  "45838": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ELGIN"
  },
  "45840": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FINDLAY"
  },
  "45841": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "JENERA"
  },
  "45843": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FOREST"
  },
  "45844": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FORT JENNINGS"
  },
  "45845": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FORT LORAMIE"
  },
  "45846": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "FORT RECOVERY"
  },
  "45849": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "GROVER HILL"
  },
  "45850": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HARROD"
  },
  "45851": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "HAVILAND"
  },
  "45853": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "KALIDA"
  },
  "45855": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LATTY"
  },
  "45856": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "LEIPSIC"
  },
  "45858": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MC COMB"
  },
  "45859": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MC GUFFEY"
  },
  "45860": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MARIA STEIN"
  },
  "45861": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MELROSE"
  },
  "45862": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MENDON"
  },
  "45863": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MIDDLE POINT"
  },
  "45865": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MINSTER"
  },
  "45866": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MONTEZUMA"
  },
  "45867": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MOUNT BLANCHARD"
  },
  "45868": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "MOUNT CORY"
  },
  "45869": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW BREMEN"
  },
  "45870": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW HAMPSHIRE"
  },
  "45871": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NEW KNOXVILLE"
  },
  "45872": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "NORTH BALTIMORE"
  },
  "45873": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OAKWOOD"
  },
  "45874": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OHIO CITY"
  },
  "45875": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OTTAWA"
  },
  "45876": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "OTTOVILLE"
  },
  "45877": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PANDORA"
  },
  "45879": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PAULDING"
  },
  "45880": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "PAYNE"
  },
  "45881": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "RAWSON"
  },
  "45882": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "ROCKFORD"
  },
  "45883": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SAINT HENRY"
  },
  "45885": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SAINT MARYS"
  },
  "45886": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SCOTT"
  },
  "45887": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "SPENCERVILLE"
  },
  "45888": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "UNIOPOLIS"
  },
  "45889": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "VAN BUREN"
  },
  "45890": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "VANLUE"
  },
  "45891": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "VAN WERT"
  },
  "45894": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "VENEDOCIA"
  },
  "45895": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WAPAKONETA"
  },
  "45896": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WAYNESFIELD"
  },
  "45897": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WILLIAMSTOWN"
  },
  "45898": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WILLSHIRE"
  },
  "45899": {
    stateCode: "OH",
    stateName: "Ohio",
    city: "WREN"
  },
  "46001": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ALEXANDRIA"
  },
  "46011": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ANDERSON"
  },
  "46012": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ANDERSON"
  },
  "46013": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ANDERSON"
  },
  "46016": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ANDERSON"
  },
  "46017": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ANDERSON"
  },
  "46030": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ARCADIA"
  },
  "46031": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ATLANTA"
  },
  "46032": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CARMEL"
  },
  "46033": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CARMEL"
  },
  "46034": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CICERO"
  },
  "46035": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "COLFAX"
  },
  "46036": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ELWOOD"
  },
  "46038": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FISHERS"
  },
  "46039": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FOREST"
  },
  "46040": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORTVILLE"
  },
  "46041": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FRANKFORT"
  },
  "46044": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FRANKTON"
  },
  "46045": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GOLDSMITH"
  },
  "46047": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HOBBS"
  },
  "46048": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INGALLS"
  },
  "46049": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "KEMPTON"
  },
  "46050": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "KIRKLIN"
  },
  "46051": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LAPEL"
  },
  "46052": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LEBANON"
  },
  "46055": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MC CORDSVILLE"
  },
  "46056": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MARKLEVILLE"
  },
  "46057": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MICHIGANTOWN"
  },
  "46058": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MULBERRY"
  },
  "46060": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NOBLESVILLE"
  },
  "46063": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ORESTES"
  },
  "46064": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PENDLETON"
  },
  "46065": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ROSSVILLE"
  },
  "46068": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SHARPSVILLE"
  },
  "46069": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SHERIDAN"
  },
  "46070": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SUMMITVILLE"
  },
  "46071": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "THORNTOWN"
  },
  "46072": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "TIPTON"
  },
  "46074": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WESTFIELD"
  },
  "46075": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WHITESTOWN"
  },
  "46076": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WINDFALL"
  },
  "46077": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ZIONSVILLE"
  },
  "46102": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ADVANCE"
  },
  "46103": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "AMO"
  },
  "46104": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ARLINGTON"
  },
  "46105": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BAINBRIDGE"
  },
  "46106": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BARGERSVILLE"
  },
  "46107": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BEECH GROVE"
  },
  "46110": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BOGGSTOWN"
  },
  "46111": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BROOKLYN"
  },
  "46112": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BROWNSBURG"
  },
  "46113": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CAMBY"
  },
  "46115": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CARTHAGE"
  },
  "46117": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CHARLOTTESVILLE"
  },
  "46118": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CLAYTON"
  },
  "46120": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CLOVERDALE"
  },
  "46121": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "COATESVILLE"
  },
  "46122": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DANVILLE"
  },
  "46123": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "AVON"
  },
  "46124": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "EDINBURGH"
  },
  "46125": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "EMINENCE"
  },
  "46126": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FAIRLAND"
  },
  "46127": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FALMOUTH"
  },
  "46128": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FILLMORE"
  },
  "46130": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FOUNTAINTOWN"
  },
  "46131": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FRANKLIN"
  },
  "46133": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GLENWOOD"
  },
  "46135": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GREENCASTLE"
  },
  "46140": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GREENFIELD"
  },
  "46142": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GREENWOOD"
  },
  "46143": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GREENWOOD"
  },
  "46144": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GWYNNEVILLE"
  },
  "46147": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "JAMESTOWN"
  },
  "46148": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "KNIGHTSTOWN"
  },
  "46149": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LIZTON"
  },
  "46150": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MANILLA"
  },
  "46151": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MARTINSVILLE"
  },
  "46155": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MAYS"
  },
  "46156": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MILROY"
  },
  "46157": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MONROVIA"
  },
  "46158": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MOORESVILLE"
  },
  "46160": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MORGANTOWN"
  },
  "46161": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MORRISTOWN"
  },
  "46162": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEEDHAM"
  },
  "46163": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEW PALESTINE"
  },
  "46164": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NINEVEH"
  },
  "46165": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NORTH SALEM"
  },
  "46166": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PARAGON"
  },
  "46167": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PITTSBORO"
  },
  "46168": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PLAINFIELD"
  },
  "46171": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "REELSVILLE"
  },
  "46172": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ROACHDALE"
  },
  "46173": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "RUSHVILLE"
  },
  "46175": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "RUSSELLVILLE"
  },
  "46176": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SHELBYVILLE"
  },
  "46180": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "STILESVILLE"
  },
  "46181": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "TRAFALGAR"
  },
  "46182": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WALDRON"
  },
  "46184": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WHITELAND"
  },
  "46186": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WILKINSON"
  },
  "46201": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46202": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46203": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46204": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46205": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46208": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46214": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46216": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46217": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46218": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46219": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46220": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46221": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46222": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46224": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46225": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46226": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46227": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46228": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46229": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46231": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46234": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46235": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46236": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46237": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46239": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46240": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46241": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46250": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46254": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46256": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46259": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46260": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46268": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46278": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46280": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46290": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "INDIANAPOLIS"
  },
  "46301": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BEVERLY SHORES"
  },
  "46303": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CEDAR LAKE"
  },
  "46304": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CHESTERTON"
  },
  "46307": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CROWN POINT"
  },
  "46310": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DEMOTTE"
  },
  "46311": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DYER"
  },
  "46312": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "EAST CHICAGO"
  },
  "46319": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GRIFFITH"
  },
  "46320": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HAMMOND"
  },
  "46321": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MUNSTER"
  },
  "46322": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HIGHLAND"
  },
  "46323": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HAMMOND"
  },
  "46324": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HAMMOND"
  },
  "46327": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HAMMOND"
  },
  "46340": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HANNA"
  },
  "46341": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HEBRON"
  },
  "46342": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HOBART"
  },
  "46345": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "KINGSBURY"
  },
  "46346": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "KINGSFORD HEIGHTS"
  },
  "46347": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "KOUTS"
  },
  "46348": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LA CROSSE"
  },
  "46349": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LAKE VILLAGE"
  },
  "46350": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LA PORTE"
  },
  "46356": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LOWELL"
  },
  "46360": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MICHIGAN CITY"
  },
  "46365": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MILL CREEK"
  },
  "46366": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NORTH JUDSON"
  },
  "46368": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PORTAGE"
  },
  "46371": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ROLLING PRAIRIE"
  },
  "46373": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SAINT JOHN"
  },
  "46374": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SAN PIERRE"
  },
  "46375": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SCHERERVILLE"
  },
  "46376": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SCHNEIDER"
  },
  "46377": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SHELBY"
  },
  "46379": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SUMAVA RESORTS"
  },
  "46381": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "THAYER"
  },
  "46382": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "UNION MILLS"
  },
  "46383": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "VALPARAISO"
  },
  "46385": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "VALPARAISO"
  },
  "46390": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WANATAH"
  },
  "46391": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WESTVILLE"
  },
  "46392": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WHEATFIELD"
  },
  "46393": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WHEELER"
  },
  "46394": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WHITING"
  },
  "46402": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GARY"
  },
  "46403": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GARY"
  },
  "46404": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GARY"
  },
  "46405": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LAKE STATION"
  },
  "46406": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GARY"
  },
  "46407": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GARY"
  },
  "46408": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GARY"
  },
  "46409": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GARY"
  },
  "46410": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MERRILLVILLE"
  },
  "46501": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ARGOS"
  },
  "46502": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ATWOOD"
  },
  "46504": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BOURBON"
  },
  "46506": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BREMEN"
  },
  "46507": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BRISTOL"
  },
  "46508": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BURKET"
  },
  "46510": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CLAYPOOL"
  },
  "46511": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CULVER"
  },
  "46513": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DONALDSON"
  },
  "46514": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ELKHART"
  },
  "46516": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ELKHART"
  },
  "46517": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ELKHART"
  },
  "46524": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ETNA GREEN"
  },
  "46526": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GOSHEN"
  },
  "46528": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GOSHEN"
  },
  "46530": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GRANGER"
  },
  "46531": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GROVERTOWN"
  },
  "46532": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HAMLET"
  },
  "46534": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "KNOX"
  },
  "46536": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LAKEVILLE"
  },
  "46537": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LAPAZ"
  },
  "46538": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LEESBURG"
  },
  "46539": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MENTONE"
  },
  "46540": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MIDDLEBURY"
  },
  "46542": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MILFORD"
  },
  "46543": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MILLERSBURG"
  },
  "46544": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MISHAWAKA"
  },
  "46545": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MISHAWAKA"
  },
  "46550": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NAPPANEE"
  },
  "46552": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEW CARLISLE"
  },
  "46553": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEW PARIS"
  },
  "46554": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NORTH LIBERTY"
  },
  "46555": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NORTH WEBSTER"
  },
  "46556": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NOTRE DAME"
  },
  "46561": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "OSCEOLA"
  },
  "46562": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PIERCETON"
  },
  "46563": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PLYMOUTH"
  },
  "46565": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SHIPSHEWANA"
  },
  "46567": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SYRACUSE"
  },
  "46570": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "TIPPECANOE"
  },
  "46571": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "TOPEKA"
  },
  "46573": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WAKARUSA"
  },
  "46574": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WALKERTON"
  },
  "46580": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WARSAW"
  },
  "46582": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WARSAW"
  },
  "46590": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WINONA LAKE"
  },
  "46595": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WYATT"
  },
  "46601": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SOUTH BEND"
  },
  "46613": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SOUTH BEND"
  },
  "46614": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SOUTH BEND"
  },
  "46615": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SOUTH BEND"
  },
  "46616": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SOUTH BEND"
  },
  "46617": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SOUTH BEND"
  },
  "46619": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SOUTH BEND"
  },
  "46628": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SOUTH BEND"
  },
  "46635": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SOUTH BEND"
  },
  "46637": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SOUTH BEND"
  },
  "46701": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ALBION"
  },
  "46702": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ANDREWS"
  },
  "46703": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ANGOLA"
  },
  "46705": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ASHLEY"
  },
  "46706": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "AUBURN"
  },
  "46710": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "AVILLA"
  },
  "46711": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BERNE"
  },
  "46714": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BLUFFTON"
  },
  "46721": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BUTLER"
  },
  "46723": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CHURUBUSCO"
  },
  "46725": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "COLUMBIA CITY"
  },
  "46730": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CORUNNA"
  },
  "46731": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CRAIGVILLE"
  },
  "46732": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CROMWELL"
  },
  "46733": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DECATUR"
  },
  "46737": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FREMONT"
  },
  "46738": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GARRETT"
  },
  "46740": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GENEVA"
  },
  "46741": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GRABILL"
  },
  "46742": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HAMILTON"
  },
  "46743": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HARLAN"
  },
  "46745": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HOAGLAND"
  },
  "46746": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HOWE"
  },
  "46747": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HUDSON"
  },
  "46748": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HUNTERTOWN"
  },
  "46750": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HUNTINGTON"
  },
  "46755": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "KENDALLVILLE"
  },
  "46759": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "KEYSTONE"
  },
  "46760": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "KIMMELL"
  },
  "46761": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LAGRANGE"
  },
  "46763": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LAOTTO"
  },
  "46764": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LARWILL"
  },
  "46765": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LEO"
  },
  "46766": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LIBERTY CENTER"
  },
  "46767": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LIGONIER"
  },
  "46770": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MARKLE"
  },
  "46772": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MONROE"
  },
  "46773": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MONROEVILLE"
  },
  "46774": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEW HAVEN"
  },
  "46776": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ORLAND"
  },
  "46777": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "OSSIAN"
  },
  "46779": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PLEASANT LAKE"
  },
  "46781": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PONETO"
  },
  "46783": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ROANOKE"
  },
  "46784": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ROME CITY"
  },
  "46785": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SAINT JOE"
  },
  "46787": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SOUTH WHITLEY"
  },
  "46788": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SPENCERVILLE"
  },
  "46791": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "UNIONDALE"
  },
  "46792": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WARREN"
  },
  "46793": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WATERLOO"
  },
  "46794": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WAWAKA"
  },
  "46795": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WOLCOTTVILLE"
  },
  "46797": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WOODBURN"
  },
  "46798": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "YODER"
  },
  "46799": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ZANESVILLE"
  },
  "46802": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORT WAYNE"
  },
  "46803": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORT WAYNE"
  },
  "46804": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORT WAYNE"
  },
  "46805": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORT WAYNE"
  },
  "46806": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORT WAYNE"
  },
  "46807": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORT WAYNE"
  },
  "46808": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORT WAYNE"
  },
  "46809": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORT WAYNE"
  },
  "46814": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORT WAYNE"
  },
  "46815": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORT WAYNE"
  },
  "46816": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORT WAYNE"
  },
  "46818": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORT WAYNE"
  },
  "46819": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORT WAYNE"
  },
  "46825": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORT WAYNE"
  },
  "46835": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORT WAYNE"
  },
  "46845": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORT WAYNE"
  },
  "46901": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "KOKOMO"
  },
  "46902": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "KOKOMO"
  },
  "46910": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "AKRON"
  },
  "46911": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "AMBOY"
  },
  "46913": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BRINGHURST"
  },
  "46914": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BUNKER HILL"
  },
  "46917": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CAMDEN"
  },
  "46919": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CONVERSE"
  },
  "46920": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CUTLER"
  },
  "46921": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DEEDSVILLE"
  },
  "46923": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DELPHI"
  },
  "46926": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DENVER"
  },
  "46928": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FAIRMOUNT"
  },
  "46929": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FLORA"
  },
  "46930": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FOWLERTON"
  },
  "46931": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FULTON"
  },
  "46932": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GALVESTON"
  },
  "46933": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GAS CITY"
  },
  "46936": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GREENTOWN"
  },
  "46938": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "JONESBORO"
  },
  "46939": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "KEWANNA"
  },
  "46940": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LA FONTAINE"
  },
  "46941": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LAGRO"
  },
  "46943": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LAKETON"
  },
  "46946": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LIBERTY MILLS"
  },
  "46947": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LOGANSPORT"
  },
  "46950": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LUCERNE"
  },
  "46951": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MACY"
  },
  "46952": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MARION"
  },
  "46953": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MARION"
  },
  "46957": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MATTHEWS"
  },
  "46958": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MEXICO"
  },
  "46959": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MIAMI"
  },
  "46960": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MONTEREY"
  },
  "46961": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEW WAVERLY"
  },
  "46962": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NORTH MANCHESTER"
  },
  "46968": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ORA"
  },
  "46970": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PERU"
  },
  "46974": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ROANN"
  },
  "46975": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ROCHESTER"
  },
  "46978": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ROYAL CENTER"
  },
  "46979": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "RUSSIAVILLE"
  },
  "46982": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SILVER LAKE"
  },
  "46985": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "STAR CITY"
  },
  "46986": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SWAYZEE"
  },
  "46987": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SWEETSER"
  },
  "46988": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "TWELVE MILE"
  },
  "46989": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "UPLAND"
  },
  "46990": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "URBANA"
  },
  "46991": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "VAN BUREN"
  },
  "46992": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WABASH"
  },
  "46994": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WALTON"
  },
  "46996": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WINAMAC"
  },
  "46998": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "YOUNG AMERICA"
  },
  "47001": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "AURORA"
  },
  "47003": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WEST COLLEGE CORNER"
  },
  "47006": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BATESVILLE"
  },
  "47010": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BATH"
  },
  "47011": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BENNINGTON"
  },
  "47012": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BROOKVILLE"
  },
  "47016": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CEDAR GROVE"
  },
  "47017": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CROSS PLAINS"
  },
  "47018": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DILLSBORO"
  },
  "47019": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "EAST ENTERPRISE"
  },
  "47020": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FLORENCE"
  },
  "47021": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FRIENDSHIP"
  },
  "47022": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GUILFORD"
  },
  "47023": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HOLTON"
  },
  "47024": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LAUREL"
  },
  "47025": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LAWRENCEBURG"
  },
  "47030": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "METAMORA"
  },
  "47031": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MILAN"
  },
  "47032": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MOORES HILL"
  },
  "47034": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NAPOLEON"
  },
  "47035": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEW TRENTON"
  },
  "47036": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "OLDENBURG"
  },
  "47037": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "OSGOOD"
  },
  "47038": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PATRIOT"
  },
  "47040": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "RISING SUN"
  },
  "47041": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SUNMAN"
  },
  "47042": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "VERSAILLES"
  },
  "47043": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "VEVAY"
  },
  "47060": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WEST HARRISON"
  },
  "47102": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "AUSTIN"
  },
  "47106": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BORDEN"
  },
  "47108": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CAMPBELLSBURG"
  },
  "47111": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CHARLESTOWN"
  },
  "47112": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CORYDON"
  },
  "47114": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CRANDALL"
  },
  "47115": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DEPAUW"
  },
  "47116": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ECKERTY"
  },
  "47117": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ELIZABETH"
  },
  "47118": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ENGLISH"
  },
  "47119": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FLOYDS KNOBS"
  },
  "47120": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FREDERICKSBURG"
  },
  "47122": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GEORGETOWN"
  },
  "47123": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GRANTSBURG"
  },
  "47124": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GREENVILLE"
  },
  "47125": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HARDINSBURG"
  },
  "47126": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HENRYVILLE"
  },
  "47129": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CLARKSVILLE"
  },
  "47130": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "JEFFERSONVILLE"
  },
  "47135": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LACONIA"
  },
  "47136": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LANESVILLE"
  },
  "47137": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LEAVENWORTH"
  },
  "47138": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LEXINGTON"
  },
  "47139": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LITTLE YORK"
  },
  "47140": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MARENGO"
  },
  "47141": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MARYSVILLE"
  },
  "47142": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MAUCKPORT"
  },
  "47143": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MEMPHIS"
  },
  "47145": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MILLTOWN"
  },
  "47147": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NABB"
  },
  "47150": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEW ALBANY"
  },
  "47160": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEW MIDDLETOWN"
  },
  "47161": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEW SALISBURY"
  },
  "47162": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEW WASHINGTON"
  },
  "47163": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "OTISCO"
  },
  "47164": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PALMYRA"
  },
  "47165": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PEKIN"
  },
  "47166": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "RAMSEY"
  },
  "47167": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SALEM"
  },
  "47170": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SCOTTSBURG"
  },
  "47172": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SELLERSBURG"
  },
  "47174": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SULPHUR"
  },
  "47175": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "TASWELL"
  },
  "47177": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "UNDERWOOD"
  },
  "47201": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "COLUMBUS"
  },
  "47203": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "COLUMBUS"
  },
  "47220": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BROWNSTOWN"
  },
  "47223": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BUTLERVILLE"
  },
  "47224": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CANAAN"
  },
  "47226": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CLIFFORD"
  },
  "47227": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "COMMISKEY"
  },
  "47229": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CROTHERSVILLE"
  },
  "47230": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DEPUTY"
  },
  "47231": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DUPONT"
  },
  "47232": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ELIZABETHTOWN"
  },
  "47234": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FLAT ROCK"
  },
  "47235": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FREETOWN"
  },
  "47240": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GREENSBURG"
  },
  "47243": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HANOVER"
  },
  "47244": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HARTSVILLE"
  },
  "47246": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HOPE"
  },
  "47247": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "JONESVILLE"
  },
  "47250": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MADISON"
  },
  "47260": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MEDORA"
  },
  "47263": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEW POINT"
  },
  "47264": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NORMAN"
  },
  "47265": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NORTH VERNON"
  },
  "47270": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PARIS CROSSING"
  },
  "47272": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SAINT PAUL"
  },
  "47273": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SCIPIO"
  },
  "47274": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SEYMOUR"
  },
  "47280": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "TAYLORSVILLE"
  },
  "47281": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "VALLONIA"
  },
  "47282": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "VERNON"
  },
  "47283": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WESTPORT"
  },
  "47302": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MUNCIE"
  },
  "47303": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MUNCIE"
  },
  "47304": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MUNCIE"
  },
  "47305": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MUNCIE"
  },
  "47320": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ALBANY"
  },
  "47325": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BROWNSVILLE"
  },
  "47326": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BRYANT"
  },
  "47327": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CAMBRIDGE CITY"
  },
  "47330": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CENTERVILLE"
  },
  "47331": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CONNERSVILLE"
  },
  "47334": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DALEVILLE"
  },
  "47336": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DUNKIRK"
  },
  "47337": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DUNREITH"
  },
  "47338": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "EATON"
  },
  "47339": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ECONOMY"
  },
  "47340": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FARMLAND"
  },
  "47341": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FOUNTAIN CITY"
  },
  "47342": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GASTON"
  },
  "47344": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GREENSBORO"
  },
  "47345": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GREENS FORK"
  },
  "47346": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HAGERSTOWN"
  },
  "47348": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HARTFORD CITY"
  },
  "47351": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "KENNARD"
  },
  "47352": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LEWISVILLE"
  },
  "47353": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LIBERTY"
  },
  "47354": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LOSANTVILLE"
  },
  "47355": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LYNN"
  },
  "47356": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MIDDLETOWN"
  },
  "47357": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MILTON"
  },
  "47358": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MODOC"
  },
  "47359": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MONTPELIER"
  },
  "47360": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MOORELAND"
  },
  "47361": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MOUNT SUMMIT"
  },
  "47362": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEW CASTLE"
  },
  "47368": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PARKER CITY"
  },
  "47369": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PENNVILLE"
  },
  "47371": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PORTLAND"
  },
  "47373": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "REDKEY"
  },
  "47374": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "RICHMOND"
  },
  "47380": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "RIDGEVILLE"
  },
  "47381": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SALAMONIA"
  },
  "47382": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SARATOGA"
  },
  "47383": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SELMA"
  },
  "47384": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SHIRLEY"
  },
  "47385": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SPICELAND"
  },
  "47386": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SPRINGPORT"
  },
  "47387": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "STRAUGHN"
  },
  "47390": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "UNION CITY"
  },
  "47392": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WEBSTER"
  },
  "47393": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WILLIAMSBURG"
  },
  "47394": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WINCHESTER"
  },
  "47396": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "YORKTOWN"
  },
  "47401": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BLOOMINGTON"
  },
  "47403": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BLOOMINGTON"
  },
  "47404": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BLOOMINGTON"
  },
  "47408": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BLOOMINGTON"
  },
  "47420": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "AVOCA"
  },
  "47421": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BEDFORD"
  },
  "47424": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BLOOMFIELD"
  },
  "47427": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "COAL CITY"
  },
  "47429": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ELLETTSVILLE"
  },
  "47430": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORT RITNER"
  },
  "47431": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FREEDOM"
  },
  "47432": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FRENCH LICK"
  },
  "47433": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GOSPORT"
  },
  "47436": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HELTONVILLE"
  },
  "47438": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "JASONVILLE"
  },
  "47441": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LINTON"
  },
  "47443": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LYONS"
  },
  "47445": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MIDLAND"
  },
  "47446": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MITCHELL"
  },
  "47448": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NASHVILLE"
  },
  "47449": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEWBERRY"
  },
  "47451": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "OOLITIC"
  },
  "47452": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ORLEANS"
  },
  "47453": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "OWENSBURG"
  },
  "47454": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PAOLI"
  },
  "47455": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PATRICKSBURG"
  },
  "47456": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "QUINCY"
  },
  "47459": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SOLSBERRY"
  },
  "47460": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SPENCER"
  },
  "47462": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SPRINGVILLE"
  },
  "47464": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "STINESVILLE"
  },
  "47465": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SWITZ CITY"
  },
  "47467": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "TUNNELTON"
  },
  "47468": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "UNIONVILLE"
  },
  "47469": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WEST BADEN SPRINGS"
  },
  "47470": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WILLIAMS"
  },
  "47471": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WORTHINGTON"
  },
  "47501": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WASHINGTON"
  },
  "47512": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BICKNELL"
  },
  "47513": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BIRDSEYE"
  },
  "47514": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BRANCHVILLE"
  },
  "47515": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BRISTOW"
  },
  "47516": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BRUCEVILLE"
  },
  "47519": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CANNELBURG"
  },
  "47520": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CANNELTON"
  },
  "47521": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CELESTINE"
  },
  "47522": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CRANE"
  },
  "47523": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DALE"
  },
  "47524": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DECKER"
  },
  "47525": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DERBY"
  },
  "47527": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DUBOIS"
  },
  "47528": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "EDWARDSPORT"
  },
  "47529": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ELNORA"
  },
  "47531": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "EVANSTON"
  },
  "47532": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FERDINAND"
  },
  "47535": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FREELANDVILLE"
  },
  "47536": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FULDA"
  },
  "47537": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GENTRYVILLE"
  },
  "47541": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HOLLAND"
  },
  "47542": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HUNTINGBURG"
  },
  "47545": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "IRELAND"
  },
  "47546": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "JASPER"
  },
  "47550": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LAMAR"
  },
  "47551": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LEOPOLD"
  },
  "47552": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LINCOLN CITY"
  },
  "47553": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LOOGOOTEE"
  },
  "47557": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MONROE CITY"
  },
  "47558": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MONTGOMERY"
  },
  "47561": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "OAKTOWN"
  },
  "47562": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ODON"
  },
  "47564": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "OTWELL"
  },
  "47567": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PETERSBURG"
  },
  "47568": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PLAINVILLE"
  },
  "47574": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ROME"
  },
  "47575": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SAINT ANTHONY"
  },
  "47576": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SAINT CROIX"
  },
  "47577": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SAINT MEINRAD"
  },
  "47578": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SANDBORN"
  },
  "47579": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SANTA CLAUS"
  },
  "47580": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SCHNELLVILLE"
  },
  "47581": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SHOALS"
  },
  "47584": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SPURGEON"
  },
  "47585": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "STENDAL"
  },
  "47586": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "TELL CITY"
  },
  "47588": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "TROY"
  },
  "47590": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "VELPEN"
  },
  "47591": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "VINCENNES"
  },
  "47596": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WESTPHALIA"
  },
  "47597": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WHEATLAND"
  },
  "47598": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WINSLOW"
  },
  "47601": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BOONVILLE"
  },
  "47610": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CHANDLER"
  },
  "47611": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CHRISNEY"
  },
  "47612": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CYNTHIANA"
  },
  "47613": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ELBERFELD"
  },
  "47615": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GRANDVIEW"
  },
  "47616": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GRIFFIN"
  },
  "47619": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LYNNVILLE"
  },
  "47620": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MOUNT VERNON"
  },
  "47630": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEWBURGH"
  },
  "47631": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEW HARMONY"
  },
  "47633": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "POSEYVILLE"
  },
  "47634": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "RICHLAND"
  },
  "47635": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ROCKPORT"
  },
  "47637": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "TENNYSON"
  },
  "47638": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WADESVILLE"
  },
  "47639": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HAUBSTADT"
  },
  "47640": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HAZLETON"
  },
  "47647": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BUCKSKIN"
  },
  "47648": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FORT BRANCH"
  },
  "47649": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FRANCISCO"
  },
  "47654": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MACKEY"
  },
  "47660": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "OAKLAND CITY"
  },
  "47665": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "OWENSVILLE"
  },
  "47666": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PATOKA"
  },
  "47670": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PRINCETON"
  },
  "47683": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SOMERVILLE"
  },
  "47708": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "EVANSVILLE"
  },
  "47710": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "EVANSVILLE"
  },
  "47711": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "EVANSVILLE"
  },
  "47712": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "EVANSVILLE"
  },
  "47713": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "EVANSVILLE"
  },
  "47714": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "EVANSVILLE"
  },
  "47715": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "EVANSVILLE"
  },
  "47720": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "EVANSVILLE"
  },
  "47725": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "EVANSVILLE"
  },
  "47802": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "TERRE HAUTE"
  },
  "47803": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "TERRE HAUTE"
  },
  "47804": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "TERRE HAUTE"
  },
  "47805": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "TERRE HAUTE"
  },
  "47807": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "TERRE HAUTE"
  },
  "47832": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BLOOMINGDALE"
  },
  "47833": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BOWLING GREEN"
  },
  "47834": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BRAZIL"
  },
  "47836": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BRIDGETON"
  },
  "47837": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CARBON"
  },
  "47838": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CARLISLE"
  },
  "47840": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CENTERPOINT"
  },
  "47841": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CLAY CITY"
  },
  "47842": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CLINTON"
  },
  "47846": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CORY"
  },
  "47847": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DANA"
  },
  "47848": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DUGGER"
  },
  "47849": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FAIRBANKS"
  },
  "47850": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FARMERSBURG"
  },
  "47853": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HARMONY"
  },
  "47854": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HILLSDALE"
  },
  "47855": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HYMERA"
  },
  "47856": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "JUDSON"
  },
  "47858": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LEWIS"
  },
  "47859": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MARSHALL"
  },
  "47860": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MECCA"
  },
  "47861": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MEROM"
  },
  "47862": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MONTEZUMA"
  },
  "47865": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PAXTON"
  },
  "47866": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PIMENTO"
  },
  "47868": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "POLAND"
  },
  "47872": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ROCKVILLE"
  },
  "47874": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ROSEDALE"
  },
  "47879": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SHELBURN"
  },
  "47881": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "STAUNTON"
  },
  "47882": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "SULLIVAN"
  },
  "47884": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "UNIVERSAL"
  },
  "47885": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WEST TERRE HAUTE"
  },
  "47901": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LAFAYETTE"
  },
  "47904": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LAFAYETTE"
  },
  "47905": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LAFAYETTE"
  },
  "47906": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WEST LAFAYETTE"
  },
  "47909": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LAFAYETTE"
  },
  "47916": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ALAMO"
  },
  "47917": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "AMBIA"
  },
  "47918": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ATTICA"
  },
  "47920": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BATTLE GROUND"
  },
  "47921": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BOSWELL"
  },
  "47922": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BROOK"
  },
  "47923": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BROOKSTON"
  },
  "47924": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BUCK CREEK"
  },
  "47925": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BUFFALO"
  },
  "47926": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "BURNETTSVILLE"
  },
  "47928": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CAYUGA"
  },
  "47929": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CHALMERS"
  },
  "47930": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CLARKS HILL"
  },
  "47932": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "COVINGTON"
  },
  "47933": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "CRAWFORDSVILLE"
  },
  "47940": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DARLINGTON"
  },
  "47941": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "DAYTON"
  },
  "47942": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "EARL PARK"
  },
  "47943": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FAIR OAKS"
  },
  "47944": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FOWLER"
  },
  "47946": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "FRANCESVILLE"
  },
  "47948": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "GOODLAND"
  },
  "47949": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "HILLSBORO"
  },
  "47950": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "IDAVILLE"
  },
  "47951": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "KENTLAND"
  },
  "47952": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "KINGMAN"
  },
  "47954": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LADOGA"
  },
  "47955": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "LINDEN"
  },
  "47957": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MEDARYVILLE"
  },
  "47958": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MELLOTT"
  },
  "47959": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MONON"
  },
  "47960": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MONTICELLO"
  },
  "47963": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MOROCCO"
  },
  "47964": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "MOUNT AYR"
  },
  "47965": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEW MARKET"
  },
  "47966": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEWPORT"
  },
  "47967": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEW RICHMOND"
  },
  "47968": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEW ROSS"
  },
  "47969": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "NEWTOWN"
  },
  "47970": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "OTTERBEIN"
  },
  "47971": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "OXFORD"
  },
  "47974": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PERRYSVILLE"
  },
  "47975": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "PINE VILLAGE"
  },
  "47977": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "REMINGTON"
  },
  "47978": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "RENSSELAER"
  },
  "47980": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "REYNOLDS"
  },
  "47981": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "ROMNEY"
  },
  "47982": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "STATE LINE"
  },
  "47983": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "STOCKWELL"
  },
  "47986": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "TEMPLETON"
  },
  "47987": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "VEEDERSBURG"
  },
  "47989": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WAVELAND"
  },
  "47990": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WAYNETOWN"
  },
  "47991": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WEST LEBANON"
  },
  "47992": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WESTPOINT"
  },
  "47993": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WILLIAMSPORT"
  },
  "47994": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WINGATE"
  },
  "47995": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "WOLCOTT"
  },
  "47997": {
    stateCode: "IN",
    stateName: "Indiana",
    city: "YEOMAN"
  },
  "48001": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ALGONAC"
  },
  "48002": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ALLENTON"
  },
  "48003": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ALMONT"
  },
  "48005": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ARMADA"
  },
  "48006": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "AVOCA"
  },
  "48009": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BIRMINGHAM"
  },
  "48014": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CAPAC"
  },
  "48015": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CENTER LINE"
  },
  "48017": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CLAWSON"
  },
  "48021": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EASTPOINTE"
  },
  "48022": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EMMETT"
  },
  "48023": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FAIR HAVEN"
  },
  "48025": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FRANKLIN"
  },
  "48026": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FRASER"
  },
  "48027": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GOODELLS"
  },
  "48028": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HARSENS ISLAND"
  },
  "48030": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HAZEL PARK"
  },
  "48032": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "JEDDO"
  },
  "48034": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SOUTHFIELD"
  },
  "48035": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CLINTON TOWNSHIP"
  },
  "48036": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CLINTON TOWNSHIP"
  },
  "48038": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CLINTON TOWNSHIP"
  },
  "48039": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MARINE CITY"
  },
  "48040": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MARYSVILLE"
  },
  "48041": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MEMPHIS"
  },
  "48042": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MACOMB"
  },
  "48043": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MOUNT CLEMENS"
  },
  "48044": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MACOMB"
  },
  "48045": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HARRISON TOWNSHIP"
  },
  "48047": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NEW BALTIMORE"
  },
  "48048": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NEW HAVEN"
  },
  "48049": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NORTH STREET"
  },
  "48050": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NEW HAVEN"
  },
  "48051": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NEW BALTIMORE"
  },
  "48054": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EAST CHINA"
  },
  "48059": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FORT GRATIOT"
  },
  "48060": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PORT HURON"
  },
  "48062": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "RICHMOND"
  },
  "48063": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "COLUMBUS"
  },
  "48064": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CASCO"
  },
  "48065": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ROMEO"
  },
  "48066": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ROSEVILLE"
  },
  "48067": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ROYAL OAK"
  },
  "48069": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PLEASANT RIDGE"
  },
  "48070": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HUNTINGTON WOODS"
  },
  "48071": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MADISON HEIGHTS"
  },
  "48072": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BERKLEY"
  },
  "48073": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ROYAL OAK"
  },
  "48074": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SMITHS CREEK"
  },
  "48075": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SOUTHFIELD"
  },
  "48076": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SOUTHFIELD"
  },
  "48079": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAINT CLAIR"
  },
  "48080": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAINT CLAIR SHORES"
  },
  "48081": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAINT CLAIR SHORES"
  },
  "48082": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAINT CLAIR SHORES"
  },
  "48083": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TROY"
  },
  "48084": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TROY"
  },
  "48089": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WARREN"
  },
  "48091": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WARREN"
  },
  "48092": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WARREN"
  },
  "48093": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WARREN"
  },
  "48094": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WASHINGTON"
  },
  "48095": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WASHINGTON"
  },
  "48096": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "RAY"
  },
  "48097": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "YALE"
  },
  "48098": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TROY"
  },
  "48101": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ALLEN PARK"
  },
  "48103": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ANN ARBOR"
  },
  "48104": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ANN ARBOR"
  },
  "48105": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ANN ARBOR"
  },
  "48108": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ANN ARBOR"
  },
  "48109": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ANN ARBOR"
  },
  "48111": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BELLEVILLE"
  },
  "48114": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BRIGHTON"
  },
  "48116": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BRIGHTON"
  },
  "48117": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CARLETON"
  },
  "48118": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CHELSEA"
  },
  "48120": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DEARBORN"
  },
  "48122": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MELVINDALE"
  },
  "48124": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DEARBORN"
  },
  "48125": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DEARBORN HEIGHTS"
  },
  "48126": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DEARBORN"
  },
  "48127": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DEARBORN HEIGHTS"
  },
  "48128": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DEARBORN"
  },
  "48130": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DEXTER"
  },
  "48131": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DUNDEE"
  },
  "48133": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ERIE"
  },
  "48134": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FLAT ROCK"
  },
  "48135": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GARDEN CITY"
  },
  "48137": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GREGORY"
  },
  "48138": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GROSSE ILE"
  },
  "48139": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HAMBURG"
  },
  "48140": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "IDA"
  },
  "48141": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "INKSTER"
  },
  "48143": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LAKELAND"
  },
  "48144": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LAMBERTVILLE"
  },
  "48145": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LA SALLE"
  },
  "48146": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LINCOLN PARK"
  },
  "48150": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LIVONIA"
  },
  "48152": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LIVONIA"
  },
  "48154": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LIVONIA"
  },
  "48157": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LUNA PIER"
  },
  "48158": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MANCHESTER"
  },
  "48159": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MAYBEE"
  },
  "48160": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MILAN"
  },
  "48161": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MONROE"
  },
  "48162": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MONROE"
  },
  "48164": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NEW BOSTON"
  },
  "48165": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NEW HUDSON"
  },
  "48166": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NEWPORT"
  },
  "48167": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NORTHVILLE"
  },
  "48169": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PINCKNEY"
  },
  "48170": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PLYMOUTH"
  },
  "48173": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ROCKWOOD"
  },
  "48174": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ROMULUS"
  },
  "48176": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SALINE"
  },
  "48178": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SOUTH LYON"
  },
  "48179": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SOUTH ROCKWOOD"
  },
  "48180": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TAYLOR"
  },
  "48182": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TEMPERANCE"
  },
  "48183": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TRENTON"
  },
  "48184": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WAYNE"
  },
  "48185": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WESTLAND"
  },
  "48186": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WESTLAND"
  },
  "48187": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CANTON"
  },
  "48188": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CANTON"
  },
  "48189": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WHITMORE LAKE"
  },
  "48190": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WHITTAKER"
  },
  "48191": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WILLIS"
  },
  "48192": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WYANDOTTE"
  },
  "48195": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SOUTHGATE"
  },
  "48197": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "YPSILANTI"
  },
  "48198": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "YPSILANTI"
  },
  "48201": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48202": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48203": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HIGHLAND PARK"
  },
  "48204": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48205": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48206": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48207": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48208": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48209": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48210": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48211": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48212": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HAMTRAMCK"
  },
  "48213": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48214": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48215": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48216": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48217": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48218": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "RIVER ROUGE"
  },
  "48219": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48220": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FERNDALE"
  },
  "48221": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48223": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48224": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48225": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HARPER WOODS"
  },
  "48226": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48227": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48228": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48229": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ECORSE"
  },
  "48230": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GROSSE POINTE"
  },
  "48234": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48235": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48236": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GROSSE POINTE"
  },
  "48237": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "OAK PARK"
  },
  "48238": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DETROIT"
  },
  "48239": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "REDFORD"
  },
  "48240": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "REDFORD"
  },
  "48301": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BLOOMFIELD HILLS"
  },
  "48302": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BLOOMFIELD HILLS"
  },
  "48304": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BLOOMFIELD HILLS"
  },
  "48306": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ROCHESTER"
  },
  "48307": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ROCHESTER"
  },
  "48309": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ROCHESTER"
  },
  "48310": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "STERLING HEIGHTS"
  },
  "48312": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "STERLING HEIGHTS"
  },
  "48313": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "STERLING HEIGHTS"
  },
  "48314": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "STERLING HEIGHTS"
  },
  "48315": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "UTICA"
  },
  "48316": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "UTICA"
  },
  "48317": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "UTICA"
  },
  "48320": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KEEGO HARBOR"
  },
  "48322": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WEST BLOOMFIELD"
  },
  "48323": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WEST BLOOMFIELD"
  },
  "48324": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WEST BLOOMFIELD"
  },
  "48326": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "AUBURN HILLS"
  },
  "48327": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WATERFORD"
  },
  "48328": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WATERFORD"
  },
  "48329": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WATERFORD"
  },
  "48331": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FARMINGTON"
  },
  "48334": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FARMINGTON"
  },
  "48335": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FARMINGTON"
  },
  "48336": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FARMINGTON"
  },
  "48340": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PONTIAC"
  },
  "48341": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PONTIAC"
  },
  "48342": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PONTIAC"
  },
  "48346": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CLARKSTON"
  },
  "48348": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CLARKSTON"
  },
  "48350": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DAVISBURG"
  },
  "48353": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HARTLAND"
  },
  "48356": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HIGHLAND"
  },
  "48357": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HIGHLAND"
  },
  "48359": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LAKE ORION"
  },
  "48360": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LAKE ORION"
  },
  "48362": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LAKE ORION"
  },
  "48363": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "OAKLAND"
  },
  "48367": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LEONARD"
  },
  "48370": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "OXFORD"
  },
  "48371": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "OXFORD"
  },
  "48374": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NOVI"
  },
  "48375": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NOVI"
  },
  "48377": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NOVI"
  },
  "48380": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MILFORD"
  },
  "48381": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MILFORD"
  },
  "48382": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "COMMERCE TOWNSHIP"
  },
  "48383": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WHITE LAKE"
  },
  "48386": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WHITE LAKE"
  },
  "48390": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WALLED LAKE"
  },
  "48393": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WIXOM"
  },
  "48401": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "APPLEGATE"
  },
  "48412": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ATTICA"
  },
  "48413": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BAD AXE"
  },
  "48414": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BANCROFT"
  },
  "48415": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BIRCH RUN"
  },
  "48416": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BROWN CITY"
  },
  "48417": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BURT"
  },
  "48418": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BYRON"
  },
  "48419": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CARSONVILLE"
  },
  "48420": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CLIO"
  },
  "48421": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "COLUMBIAVILLE"
  },
  "48422": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CROSWELL"
  },
  "48423": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DAVISON"
  },
  "48426": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DECKER"
  },
  "48427": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DECKERVILLE"
  },
  "48428": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DRYDEN"
  },
  "48429": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DURAND"
  },
  "48430": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FENTON"
  },
  "48432": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FILION"
  },
  "48433": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FLUSHING"
  },
  "48434": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FORESTVILLE"
  },
  "48435": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FOSTORIA"
  },
  "48436": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GAINES"
  },
  "48437": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GENESEE"
  },
  "48438": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GOODRICH"
  },
  "48439": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRAND BLANC"
  },
  "48440": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HADLEY"
  },
  "48441": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HARBOR BEACH"
  },
  "48442": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HOLLY"
  },
  "48444": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "IMLAY CITY"
  },
  "48445": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KINDE"
  },
  "48446": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LAPEER"
  },
  "48449": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LENNON"
  },
  "48450": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LEXINGTON"
  },
  "48451": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LINDEN"
  },
  "48453": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MARLETTE"
  },
  "48454": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MELVIN"
  },
  "48455": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "METAMORA"
  },
  "48456": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MINDEN CITY"
  },
  "48457": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MONTROSE"
  },
  "48458": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MOUNT MORRIS"
  },
  "48460": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NEW LOTHROP"
  },
  "48461": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NORTH BRANCH"
  },
  "48462": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ORTONVILLE"
  },
  "48463": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "OTISVILLE"
  },
  "48464": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "OTTER LAKE"
  },
  "48465": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PALMS"
  },
  "48466": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PECK"
  },
  "48467": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PORT AUSTIN"
  },
  "48468": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PORT HOPE"
  },
  "48469": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PORT SANILAC"
  },
  "48470": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "RUTH"
  },
  "48471": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SANDUSKY"
  },
  "48472": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SNOVER"
  },
  "48473": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SWARTZ CREEK"
  },
  "48475": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "UBLY"
  },
  "48476": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "VERNON"
  },
  "48502": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FLINT"
  },
  "48503": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FLINT"
  },
  "48504": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FLINT"
  },
  "48505": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FLINT"
  },
  "48506": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FLINT"
  },
  "48507": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FLINT"
  },
  "48509": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BURTON"
  },
  "48519": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BURTON"
  },
  "48529": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BURTON"
  },
  "48532": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FLINT"
  },
  "48601": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAGINAW"
  },
  "48602": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAGINAW"
  },
  "48603": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAGINAW"
  },
  "48604": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAGINAW"
  },
  "48607": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAGINAW"
  },
  "48609": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAGINAW"
  },
  "48610": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ALGER"
  },
  "48611": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "AUBURN"
  },
  "48612": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BEAVERTON"
  },
  "48613": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BENTLEY"
  },
  "48614": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BRANT"
  },
  "48615": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BRECKENRIDGE"
  },
  "48616": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CHESANING"
  },
  "48617": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CLARE"
  },
  "48618": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "COLEMAN"
  },
  "48619": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "COMINS"
  },
  "48620": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EDENVILLE"
  },
  "48621": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FAIRVIEW"
  },
  "48622": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FARWELL"
  },
  "48623": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FREELAND"
  },
  "48624": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GLADWIN"
  },
  "48625": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HARRISON"
  },
  "48626": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HEMLOCK"
  },
  "48627": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HIGGINS LAKE"
  },
  "48628": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HOPE"
  },
  "48629": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HOUGHTON LAKE"
  },
  "48630": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HOUGHTON LAKE HEIGHTS"
  },
  "48631": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KAWKAWLIN"
  },
  "48632": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LAKE"
  },
  "48633": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LAKE GEORGE"
  },
  "48634": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LINWOOD"
  },
  "48635": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LUPTON"
  },
  "48636": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LUZERNE"
  },
  "48637": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MERRILL"
  },
  "48640": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MIDLAND"
  },
  "48642": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MIDLAND"
  },
  "48647": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MIO"
  },
  "48649": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "OAKLEY"
  },
  "48650": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PINCONNING"
  },
  "48651": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PRUDENVILLE"
  },
  "48652": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "RHODES"
  },
  "48653": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ROSCOMMON"
  },
  "48654": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ROSE CITY"
  },
  "48655": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAINT CHARLES"
  },
  "48656": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAINT HELEN"
  },
  "48657": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SANFORD"
  },
  "48658": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "STANDISH"
  },
  "48659": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "STERLING"
  },
  "48661": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WEST BRANCH"
  },
  "48662": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WHEELER"
  },
  "48701": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "AKRON"
  },
  "48703": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "AU GRES"
  },
  "48705": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BARTON CITY"
  },
  "48706": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BAY CITY"
  },
  "48708": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BAY CITY"
  },
  "48720": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BAY PORT"
  },
  "48721": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BLACK RIVER"
  },
  "48722": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BRIDGEPORT"
  },
  "48723": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CARO"
  },
  "48724": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CARROLLTON"
  },
  "48725": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CASEVILLE"
  },
  "48726": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CASS CITY"
  },
  "48727": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CLIFFORD"
  },
  "48728": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CURRAN"
  },
  "48729": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DEFORD"
  },
  "48730": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EAST TAWAS"
  },
  "48731": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ELKTON"
  },
  "48732": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ESSEXVILLE"
  },
  "48733": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FAIRGROVE"
  },
  "48734": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FRANKENMUTH"
  },
  "48735": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GAGETOWN"
  },
  "48737": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GLENNIE"
  },
  "48738": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GREENBUSH"
  },
  "48739": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HALE"
  },
  "48740": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HARRISVILLE"
  },
  "48741": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KINGSTON"
  },
  "48742": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LINCOLN"
  },
  "48743": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LONG LAKE"
  },
  "48744": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MAYVILLE"
  },
  "48745": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MIKADO"
  },
  "48746": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MILLINGTON"
  },
  "48747": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MUNGER"
  },
  "48748": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NATIONAL CITY"
  },
  "48749": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "OMER"
  },
  "48750": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "OSCODA"
  },
  "48754": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "OWENDALE"
  },
  "48755": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PIGEON"
  },
  "48756": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PRESCOTT"
  },
  "48757": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "REESE"
  },
  "48759": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SEBEWAING"
  },
  "48760": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SILVERWOOD"
  },
  "48761": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SOUTH BRANCH"
  },
  "48762": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SPRUCE"
  },
  "48763": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TAWAS CITY"
  },
  "48765": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TURNER"
  },
  "48766": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TWINING"
  },
  "48767": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "UNIONVILLE"
  },
  "48768": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "VASSAR"
  },
  "48770": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WHITTEMORE"
  },
  "48801": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ALMA"
  },
  "48806": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ASHLEY"
  },
  "48807": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BANNISTER"
  },
  "48808": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BATH"
  },
  "48809": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BELDING"
  },
  "48811": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CARSON CITY"
  },
  "48813": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CHARLOTTE"
  },
  "48815": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CLARKSVILLE"
  },
  "48817": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CORUNNA"
  },
  "48818": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CRYSTAL"
  },
  "48819": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DANSVILLE"
  },
  "48820": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DEWITT"
  },
  "48821": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DIMONDALE"
  },
  "48822": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EAGLE"
  },
  "48823": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EAST LANSING"
  },
  "48827": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EATON RAPIDS"
  },
  "48829": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EDMORE"
  },
  "48830": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ELM HALL"
  },
  "48831": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ELSIE"
  },
  "48832": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ELWELL"
  },
  "48834": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FENWICK"
  },
  "48835": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FOWLER"
  },
  "48836": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FOWLERVILLE"
  },
  "48837": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRAND LEDGE"
  },
  "48838": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GREENVILLE"
  },
  "48840": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HASLETT"
  },
  "48841": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HENDERSON"
  },
  "48842": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HOLT"
  },
  "48843": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HOWELL"
  },
  "48845": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HUBBARDSTON"
  },
  "48846": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "IONIA"
  },
  "48847": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ITHACA"
  },
  "48848": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LAINGSBURG"
  },
  "48849": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LAKE ODESSA"
  },
  "48850": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LAKEVIEW"
  },
  "48851": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LYONS"
  },
  "48852": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MCBRIDES"
  },
  "48853": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MAPLE RAPIDS"
  },
  "48854": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MASON"
  },
  "48856": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MIDDLETON"
  },
  "48857": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MORRICE"
  },
  "48858": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MOUNT PLEASANT"
  },
  "48860": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MUIR"
  },
  "48861": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MULLIKEN"
  },
  "48864": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "OKEMOS"
  },
  "48865": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ORLEANS"
  },
  "48866": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "OVID"
  },
  "48867": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "OWOSSO"
  },
  "48871": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PERRINTON"
  },
  "48872": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PERRY"
  },
  "48873": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PEWAMO"
  },
  "48874": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "POMPEII"
  },
  "48875": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PORTLAND"
  },
  "48876": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "POTTERVILLE"
  },
  "48877": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "RIVERDALE"
  },
  "48878": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ROSEBUSH"
  },
  "48879": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAINT JOHNS"
  },
  "48880": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAINT LOUIS"
  },
  "48881": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SARANAC"
  },
  "48883": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SHEPHERD"
  },
  "48884": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SHERIDAN"
  },
  "48885": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SIDNEY"
  },
  "48886": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SIX LAKES"
  },
  "48888": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "STANTON"
  },
  "48889": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SUMNER"
  },
  "48890": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SUNFIELD"
  },
  "48891": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "VESTABURG"
  },
  "48892": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WEBBERVILLE"
  },
  "48893": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WEIDMAN"
  },
  "48894": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WESTPHALIA"
  },
  "48895": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WILLIAMSTON"
  },
  "48896": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WINN"
  },
  "48897": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WOODLAND"
  },
  "48906": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LANSING"
  },
  "48910": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LANSING"
  },
  "48911": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LANSING"
  },
  "48912": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LANSING"
  },
  "48915": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LANSING"
  },
  "48917": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LANSING"
  },
  "48933": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LANSING"
  },
  "49001": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KALAMAZOO"
  },
  "49002": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PORTAGE"
  },
  "49004": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KALAMAZOO"
  },
  "49006": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KALAMAZOO"
  },
  "49007": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KALAMAZOO"
  },
  "49008": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KALAMAZOO"
  },
  "49009": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KALAMAZOO"
  },
  "49010": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ALLEGAN"
  },
  "49011": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ATHENS"
  },
  "49012": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "AUGUSTA"
  },
  "49013": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BANGOR"
  },
  "49014": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BATTLE CREEK"
  },
  "49015": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BATTLE CREEK"
  },
  "49017": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BATTLE CREEK"
  },
  "49021": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BELLEVUE"
  },
  "49022": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BENTON HARBOR"
  },
  "49024": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PORTAGE"
  },
  "49026": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BLOOMINGDALE"
  },
  "49027": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BREEDSVILLE"
  },
  "49028": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BRONSON"
  },
  "49029": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BURLINGTON"
  },
  "49030": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BURR OAK"
  },
  "49031": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CASSOPOLIS"
  },
  "49032": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CENTREVILLE"
  },
  "49033": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CERESCO"
  },
  "49034": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CLIMAX"
  },
  "49036": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "COLDWATER"
  },
  "49038": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "COLOMA"
  },
  "49040": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "COLON"
  },
  "49042": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CONSTANTINE"
  },
  "49043": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "COVERT"
  },
  "49045": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DECATUR"
  },
  "49046": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DELTON"
  },
  "49047": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DOWAGIAC"
  },
  "49050": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DOWLING"
  },
  "49051": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EAST LEROY"
  },
  "49052": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FULTON"
  },
  "49053": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GALESBURG"
  },
  "49055": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GOBLES"
  },
  "49056": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRAND JUNCTION"
  },
  "49057": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HARTFORD"
  },
  "49058": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HASTINGS"
  },
  "49060": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HICKORY CORNERS"
  },
  "49061": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "JONES"
  },
  "49064": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LAWRENCE"
  },
  "49065": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LAWTON"
  },
  "49066": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LEONIDAS"
  },
  "49067": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MARCELLUS"
  },
  "49068": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MARSHALL"
  },
  "49070": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MARTIN"
  },
  "49071": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MATTAWAN"
  },
  "49072": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MENDON"
  },
  "49073": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NASHVILLE"
  },
  "49075": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NOTTAWA"
  },
  "49076": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "OLIVET"
  },
  "49078": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "OTSEGO"
  },
  "49079": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PAW PAW"
  },
  "49080": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PLAINWELL"
  },
  "49082": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "QUINCY"
  },
  "49083": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "RICHLAND"
  },
  "49085": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAINT JOSEPH"
  },
  "49087": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SCHOOLCRAFT"
  },
  "49088": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SCOTTS"
  },
  "49089": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SHERWOOD"
  },
  "49090": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SOUTH HAVEN"
  },
  "49091": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "STURGIS"
  },
  "49092": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TEKONSHA"
  },
  "49093": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "THREE RIVERS"
  },
  "49094": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "UNION CITY"
  },
  "49095": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "VANDALIA"
  },
  "49096": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "VERMONTVILLE"
  },
  "49097": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "VICKSBURG"
  },
  "49098": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WATERVLIET"
  },
  "49099": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WHITE PIGEON"
  },
  "49101": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BARODA"
  },
  "49102": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BERRIEN CENTER"
  },
  "49103": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BERRIEN SPRINGS"
  },
  "49106": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BRIDGMAN"
  },
  "49107": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BUCHANAN"
  },
  "49111": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EAU CLAIRE"
  },
  "49112": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EDWARDSBURG"
  },
  "49113": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GALIEN"
  },
  "49115": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HARBERT"
  },
  "49116": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LAKESIDE"
  },
  "49117": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NEW BUFFALO"
  },
  "49119": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NEW TROY"
  },
  "49120": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NILES"
  },
  "49125": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAWYER"
  },
  "49126": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SODUS"
  },
  "49127": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "STEVENSVILLE"
  },
  "49128": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "THREE OAKS"
  },
  "49129": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "UNION PIER"
  },
  "49130": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "UNION"
  },
  "49201": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "JACKSON"
  },
  "49202": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "JACKSON"
  },
  "49203": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "JACKSON"
  },
  "49220": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ADDISON"
  },
  "49221": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ADRIAN"
  },
  "49224": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ALBION"
  },
  "49227": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ALLEN"
  },
  "49228": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BLISSFIELD"
  },
  "49229": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BRITTON"
  },
  "49230": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BROOKLYN"
  },
  "49232": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CAMDEN"
  },
  "49233": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CEMENT CITY"
  },
  "49234": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CLARKLAKE"
  },
  "49235": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CLAYTON"
  },
  "49236": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CLINTON"
  },
  "49237": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CONCORD"
  },
  "49238": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DEERFIELD"
  },
  "49240": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRASS LAKE"
  },
  "49241": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HANOVER"
  },
  "49242": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HILLSDALE"
  },
  "49245": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HOMER"
  },
  "49246": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HORTON"
  },
  "49247": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HUDSON"
  },
  "49248": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "JASPER"
  },
  "49249": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "JEROME"
  },
  "49250": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "JONESVILLE"
  },
  "49251": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LESLIE"
  },
  "49252": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LITCHFIELD"
  },
  "49253": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MANITOU BEACH"
  },
  "49254": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MICHIGAN CENTER"
  },
  "49255": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MONTGOMERY"
  },
  "49256": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MORENCI"
  },
  "49259": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MUNITH"
  },
  "49262": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NORTH ADAMS"
  },
  "49263": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NORVELL"
  },
  "49264": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ONONDAGA"
  },
  "49265": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ONSTED"
  },
  "49266": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "OSSEO"
  },
  "49267": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "OTTAWA LAKE"
  },
  "49268": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PALMYRA"
  },
  "49269": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PARMA"
  },
  "49270": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PETERSBURG"
  },
  "49271": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PITTSFORD"
  },
  "49272": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PLEASANT LAKE"
  },
  "49274": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "READING"
  },
  "49276": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "RIGA"
  },
  "49277": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "RIVES JUNCTION"
  },
  "49279": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAND CREEK"
  },
  "49281": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SOMERSET"
  },
  "49282": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SOMERSET CENTER"
  },
  "49283": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SPRING ARBOR"
  },
  "49284": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SPRINGPORT"
  },
  "49285": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "STOCKBRIDGE"
  },
  "49286": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TECUMSEH"
  },
  "49287": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TIPTON"
  },
  "49288": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WALDRON"
  },
  "49289": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WESTON"
  },
  "49301": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ADA"
  },
  "49302": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ALTO"
  },
  "49303": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BAILEY"
  },
  "49304": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BALDWIN"
  },
  "49305": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BARRYTON"
  },
  "49306": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BELMONT"
  },
  "49307": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BIG RAPIDS"
  },
  "49309": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BITELY"
  },
  "49310": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BLANCHARD"
  },
  "49312": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BROHMAN"
  },
  "49315": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BYRON CENTER"
  },
  "49316": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CALEDONIA"
  },
  "49318": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CASNOVIA"
  },
  "49319": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CEDAR SPRINGS"
  },
  "49321": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "COMSTOCK PARK"
  },
  "49322": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CORAL"
  },
  "49323": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DORR"
  },
  "49325": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FREEPORT"
  },
  "49326": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GOWEN"
  },
  "49327": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRANT"
  },
  "49328": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HOPKINS"
  },
  "49329": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HOWARD CITY"
  },
  "49330": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KENT CITY"
  },
  "49331": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LOWELL"
  },
  "49332": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MECOSTA"
  },
  "49333": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MIDDLEVILLE"
  },
  "49335": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MOLINE"
  },
  "49336": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MORLEY"
  },
  "49337": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NEWAYGO"
  },
  "49338": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PARIS"
  },
  "49339": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PIERSON"
  },
  "49340": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "REMUS"
  },
  "49341": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ROCKFORD"
  },
  "49342": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "RODNEY"
  },
  "49343": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAND LAKE"
  },
  "49344": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SHELBYVILLE"
  },
  "49345": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SPARTA"
  },
  "49346": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "STANWOOD"
  },
  "49347": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TRUFANT"
  },
  "49348": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WAYLAND"
  },
  "49349": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WHITE CLOUD"
  },
  "49401": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ALLENDALE"
  },
  "49402": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BRANCH"
  },
  "49403": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CONKLIN"
  },
  "49404": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "COOPERSVILLE"
  },
  "49405": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CUSTER"
  },
  "49406": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DOUGLAS"
  },
  "49408": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FENNVILLE"
  },
  "49410": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FOUNTAIN"
  },
  "49411": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FREE SOIL"
  },
  "49412": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FREMONT"
  },
  "49415": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FRUITPORT"
  },
  "49417": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRAND HAVEN"
  },
  "49418": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRANDVILLE"
  },
  "49419": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HAMILTON"
  },
  "49420": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HART"
  },
  "49421": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HESPERIA"
  },
  "49423": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HOLLAND"
  },
  "49424": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HOLLAND"
  },
  "49425": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HOLTON"
  },
  "49426": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HUDSONVILLE"
  },
  "49428": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "JENISON"
  },
  "49431": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LUDINGTON"
  },
  "49434": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MACATAWA"
  },
  "49435": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MARNE"
  },
  "49436": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MEARS"
  },
  "49437": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MONTAGUE"
  },
  "49440": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MUSKEGON"
  },
  "49441": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MUSKEGON"
  },
  "49442": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MUSKEGON"
  },
  "49444": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MUSKEGON"
  },
  "49445": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MUSKEGON"
  },
  "49446": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NEW ERA"
  },
  "49448": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NUNICA"
  },
  "49449": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PENTWATER"
  },
  "49450": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PULLMAN"
  },
  "49451": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "RAVENNA"
  },
  "49452": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ROTHBURY"
  },
  "49453": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAUGATUCK"
  },
  "49454": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SCOTTVILLE"
  },
  "49455": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SHELBY"
  },
  "49456": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SPRING LAKE"
  },
  "49457": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TWIN LAKE"
  },
  "49458": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WALHALLA"
  },
  "49459": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WALKERVILLE"
  },
  "49460": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WEST OLIVE"
  },
  "49461": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WHITEHALL"
  },
  "49464": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ZEELAND"
  },
  "49503": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRAND RAPIDS"
  },
  "49504": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRAND RAPIDS"
  },
  "49505": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRAND RAPIDS"
  },
  "49506": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRAND RAPIDS"
  },
  "49507": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRAND RAPIDS"
  },
  "49508": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRAND RAPIDS"
  },
  "49509": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WYOMING"
  },
  "49512": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRAND RAPIDS"
  },
  "49525": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRAND RAPIDS"
  },
  "49544": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRAND RAPIDS"
  },
  "49546": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRAND RAPIDS"
  },
  "49548": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRAND RAPIDS"
  },
  "49601": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CADILLAC"
  },
  "49611": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ALBA"
  },
  "49612": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ALDEN"
  },
  "49613": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ARCADIA"
  },
  "49614": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BEAR LAKE"
  },
  "49615": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BELLAIRE"
  },
  "49616": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BENZONIA"
  },
  "49617": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BEULAH"
  },
  "49618": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BOON"
  },
  "49619": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BRETHREN"
  },
  "49620": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BUCKLEY"
  },
  "49621": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CEDAR"
  },
  "49622": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CENTRAL LAKE"
  },
  "49623": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CHASE"
  },
  "49625": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "COPEMISH"
  },
  "49626": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EASTLAKE"
  },
  "49627": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EASTPORT"
  },
  "49628": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ELBERTA"
  },
  "49629": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ELK RAPIDS"
  },
  "49630": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EMPIRE"
  },
  "49631": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EVART"
  },
  "49632": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FALMOUTH"
  },
  "49633": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FIFE LAKE"
  },
  "49634": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FILER CITY"
  },
  "49635": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FRANKFORT"
  },
  "49636": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GLEN ARBOR"
  },
  "49637": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRAWN"
  },
  "49638": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HARRIETTA"
  },
  "49639": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HERSEY"
  },
  "49640": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HONOR"
  },
  "49642": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "IDLEWILD"
  },
  "49643": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "INTERLOCHEN"
  },
  "49644": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "IRONS"
  },
  "49645": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KALEVA"
  },
  "49646": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KALKASKA"
  },
  "49648": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KEWADIN"
  },
  "49649": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KINGSLEY"
  },
  "49650": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LAKE ANN"
  },
  "49651": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LAKE CITY"
  },
  "49653": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LAKE LEELANAU"
  },
  "49654": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LELAND"
  },
  "49655": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LEROY"
  },
  "49656": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LUTHER"
  },
  "49657": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MC BAIN"
  },
  "49659": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MANCELONA"
  },
  "49660": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MANISTEE"
  },
  "49663": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MANTON"
  },
  "49664": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MAPLE CITY"
  },
  "49665": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MARION"
  },
  "49666": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MAYFIELD"
  },
  "49667": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MERRITT"
  },
  "49668": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MESICK"
  },
  "49670": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NORTHPORT"
  },
  "49675": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ONEKAMA"
  },
  "49676": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "RAPID CITY"
  },
  "49677": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "REED CITY"
  },
  "49679": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SEARS"
  },
  "49680": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SOUTH BOARDMAN"
  },
  "49682": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SUTTONS BAY"
  },
  "49683": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "THOMPSONVILLE"
  },
  "49684": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TRAVERSE CITY"
  },
  "49686": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TRAVERSE CITY"
  },
  "49688": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TUSTIN"
  },
  "49689": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WELLSTON"
  },
  "49690": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WILLIAMSBURG"
  },
  "49701": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MACKINAW CITY"
  },
  "49705": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "AFTON"
  },
  "49706": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ALANSON"
  },
  "49707": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ALPENA"
  },
  "49709": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ATLANTA"
  },
  "49710": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BARBEAU"
  },
  "49712": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BOYNE CITY"
  },
  "49713": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BOYNE FALLS"
  },
  "49715": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BRIMLEY"
  },
  "49716": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BRUTUS"
  },
  "49718": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CARP LAKE"
  },
  "49719": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CEDARVILLE"
  },
  "49720": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CHARLEVOIX"
  },
  "49721": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CHEBOYGAN"
  },
  "49722": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CONWAY"
  },
  "49724": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DAFTER"
  },
  "49725": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DE TOUR VILLAGE"
  },
  "49726": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DRUMMOND ISLAND"
  },
  "49727": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EAST JORDAN"
  },
  "49728": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ECKERMAN"
  },
  "49729": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ELLSWORTH"
  },
  "49730": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ELMIRA"
  },
  "49733": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FREDERIC"
  },
  "49735": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GAYLORD"
  },
  "49736": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GOETZVILLE"
  },
  "49738": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRAYLING"
  },
  "49740": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HARBOR SPRINGS"
  },
  "49743": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HAWKS"
  },
  "49744": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HERRON"
  },
  "49745": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HESSEL"
  },
  "49746": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HILLMAN"
  },
  "49747": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HUBBARD LAKE"
  },
  "49748": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HULBERT"
  },
  "49749": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "INDIAN RIVER"
  },
  "49751": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "JOHANNESBURG"
  },
  "49752": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KINROSS"
  },
  "49753": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LACHINE"
  },
  "49755": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LEVERING"
  },
  "49756": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LEWISTON"
  },
  "49757": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MACKINAC ISLAND"
  },
  "49759": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MILLERSBURG"
  },
  "49760": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MORAN"
  },
  "49761": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MULLETT LAKE"
  },
  "49762": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NAUBINWAY"
  },
  "49764": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ODEN"
  },
  "49765": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ONAWAY"
  },
  "49766": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "OSSINEKE"
  },
  "49768": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PARADISE"
  },
  "49769": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PELLSTON"
  },
  "49770": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PETOSKEY"
  },
  "49774": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PICKFORD"
  },
  "49775": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "POINTE AUX PINS"
  },
  "49776": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "POSEN"
  },
  "49777": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PRESQUE ISLE"
  },
  "49779": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ROGERS CITY"
  },
  "49780": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "RUDYARD"
  },
  "49781": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAINT IGNACE"
  },
  "49782": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BEAVER ISLAND"
  },
  "49783": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAULT SAINTE MARIE"
  },
  "49788": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KINCHELOE"
  },
  "49791": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TOPINABEE"
  },
  "49792": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TOWER"
  },
  "49793": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TROUT LAKE"
  },
  "49795": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "VANDERBILT"
  },
  "49796": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WALLOON LAKE"
  },
  "49799": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WOLVERINE"
  },
  "49801": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "IRON MOUNTAIN"
  },
  "49802": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KINGSFORD"
  },
  "49805": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ALLOUEZ"
  },
  "49806": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "AU TRAIN"
  },
  "49807": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BARK RIVER"
  },
  "49808": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BIG BAY"
  },
  "49812": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CARNEY"
  },
  "49814": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CHAMPION"
  },
  "49815": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CHANNING"
  },
  "49816": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CHATHAM"
  },
  "49817": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "COOKS"
  },
  "49818": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CORNELL"
  },
  "49820": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CURTIS"
  },
  "49821": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DAGGETT"
  },
  "49822": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DEERTON"
  },
  "49825": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EBEN JUNCTION"
  },
  "49826": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "RUMELY"
  },
  "49827": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ENGADINE"
  },
  "49829": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ESCANABA"
  },
  "49831": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FELCH"
  },
  "49833": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LITTLE LAKE"
  },
  "49834": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "FOSTER CITY"
  },
  "49835": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GARDEN"
  },
  "49836": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GERMFASK"
  },
  "49837": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GLADSTONE"
  },
  "49838": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GOULD CITY"
  },
  "49839": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GRAND MARAIS"
  },
  "49840": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GULLIVER"
  },
  "49841": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GWINN"
  },
  "49847": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HERMANSVILLE"
  },
  "49848": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "INGALLS"
  },
  "49849": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ISHPEMING"
  },
  "49852": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LORETTO"
  },
  "49853": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MC MILLAN"
  },
  "49854": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MANISTIQUE"
  },
  "49855": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MARQUETTE"
  },
  "49858": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MENOMINEE"
  },
  "49861": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MICHIGAMME"
  },
  "49862": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MUNISING"
  },
  "49863": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NADEAU"
  },
  "49864": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NAHMA"
  },
  "49866": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NEGAUNEE"
  },
  "49868": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NEWBERRY"
  },
  "49870": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NORWAY"
  },
  "49871": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PALMER"
  },
  "49872": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PERKINS"
  },
  "49873": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PERRONVILLE"
  },
  "49874": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "POWERS"
  },
  "49876": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "QUINNESEC"
  },
  "49877": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "RALPH"
  },
  "49878": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "RAPID RIVER"
  },
  "49879": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "REPUBLIC"
  },
  "49880": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ROCK"
  },
  "49881": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SAGOLA"
  },
  "49883": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SENEY"
  },
  "49884": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SHINGLETON"
  },
  "49885": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SKANDIA"
  },
  "49886": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SPALDING"
  },
  "49887": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "STEPHENSON"
  },
  "49891": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TRENARY"
  },
  "49892": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "VULCAN"
  },
  "49893": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WALLACE"
  },
  "49894": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WELLS"
  },
  "49895": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WETMORE"
  },
  "49896": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WILSON"
  },
  "49901": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "AHMEEK"
  },
  "49902": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ALPHA"
  },
  "49903": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "AMASA"
  },
  "49905": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ATLANTIC MINE"
  },
  "49908": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BARAGA"
  },
  "49910": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BERGLAND"
  },
  "49911": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BESSEMER"
  },
  "49912": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "BRUCE CROSSING"
  },
  "49913": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CALUMET"
  },
  "49915": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CASPIAN"
  },
  "49916": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CHASSELL"
  },
  "49917": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "COPPER CITY"
  },
  "49918": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "COPPER HARBOR"
  },
  "49919": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "COVINGTON"
  },
  "49920": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "CRYSTAL FALLS"
  },
  "49921": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DODGEVILLE"
  },
  "49922": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "DOLLAR BAY"
  },
  "49925": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "EWEN"
  },
  "49927": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GAASTRA"
  },
  "49929": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "GREENLAND"
  },
  "49930": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HANCOCK"
  },
  "49931": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HOUGHTON"
  },
  "49934": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "HUBBELL"
  },
  "49935": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "IRON RIVER"
  },
  "49938": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "IRONWOOD"
  },
  "49942": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "KEARSARGE"
  },
  "49945": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LAKE LINDEN"
  },
  "49946": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "LANSE"
  },
  "49947": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MARENISCO"
  },
  "49948": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MASS CITY"
  },
  "49950": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "MOHAWK"
  },
  "49952": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "NISULA"
  },
  "49953": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ONTONAGON"
  },
  "49955": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PAINESDALE"
  },
  "49958": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "PELKIE"
  },
  "49959": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "RAMSAY"
  },
  "49960": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "ROCKLAND"
  },
  "49961": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SIDNAW"
  },
  "49962": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SKANEE"
  },
  "49963": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "SOUTH RANGE"
  },
  "49964": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "STAMBAUGH"
  },
  "49965": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TOIVOLA"
  },
  "49967": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "TROUT CREEK"
  },
  "49968": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WAKEFIELD"
  },
  "49969": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WATERSMEET"
  },
  "49970": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WATTON"
  },
  "49971": {
    stateCode: "MI",
    stateName: "Michigan",
    city: "WHITE PINE"
  },
  "50001": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ACKWORTH"
  },
  "50002": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ADAIR"
  },
  "50003": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ADEL"
  },
  "50005": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ALBION"
  },
  "50006": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ALDEN"
  },
  "50007": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ALLEMAN"
  },
  "50008": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ALLERTON"
  },
  "50009": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ALTOONA"
  },
  "50010": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "AMES"
  },
  "50014": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "AMES"
  },
  "50020": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ANITA"
  },
  "50021": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ANKENY"
  },
  "50022": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ATLANTIC"
  },
  "50025": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "AUDUBON"
  },
  "50026": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BAGLEY"
  },
  "50027": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BARNES CITY"
  },
  "50028": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BAXTER"
  },
  "50029": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BAYARD"
  },
  "50032": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BERWICK"
  },
  "50033": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BEVINGTON"
  },
  "50034": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BLAIRSBURG"
  },
  "50035": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BONDURANT"
  },
  "50036": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BOONE"
  },
  "50038": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BOONEVILLE"
  },
  "50039": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BOUTON"
  },
  "50040": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BOXHOLM"
  },
  "50041": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BRADFORD"
  },
  "50042": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BRAYTON"
  },
  "50044": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BUSSEY"
  },
  "50046": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CAMBRIDGE"
  },
  "50047": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CARLISLE"
  },
  "50048": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CASEY"
  },
  "50049": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CHARITON"
  },
  "50050": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CHURDAN"
  },
  "50051": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CLEMONS"
  },
  "50052": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CLIO"
  },
  "50054": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "COLFAX"
  },
  "50055": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "COLLINS"
  },
  "50056": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "COLO"
  },
  "50057": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "COLUMBIA"
  },
  "50058": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "COON RAPIDS"
  },
  "50059": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "COOPER"
  },
  "50060": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CORYDON"
  },
  "50061": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CUMMING"
  },
  "50062": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DALLAS"
  },
  "50063": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DALLAS CENTER"
  },
  "50064": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DANA"
  },
  "50065": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DAVIS CITY"
  },
  "50066": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DAWSON"
  },
  "50067": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DECATUR"
  },
  "50068": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DERBY"
  },
  "50069": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DE SOTO"
  },
  "50070": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DEXTER"
  },
  "50071": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DOWS"
  },
  "50072": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "EARLHAM"
  },
  "50073": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ELKHART"
  },
  "50074": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ELLSTON"
  },
  "50075": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ELLSWORTH"
  },
  "50076": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "EXIRA"
  },
  "50078": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FERGUSON"
  },
  "50101": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GALT"
  },
  "50102": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GARDEN CITY"
  },
  "50103": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GARDEN GROVE"
  },
  "50104": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GIBSON"
  },
  "50105": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GILBERT"
  },
  "50106": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GILMAN"
  },
  "50107": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GRAND JUNCTION"
  },
  "50108": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GRAND RIVER"
  },
  "50109": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GRANGER"
  },
  "50110": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GRAY"
  },
  "50111": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GRIMES"
  },
  "50112": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GRINNELL"
  },
  "50115": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GUTHRIE CENTER"
  },
  "50116": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HAMILTON"
  },
  "50117": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HAMLIN"
  },
  "50118": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HARTFORD"
  },
  "50119": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HARVEY"
  },
  "50120": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HAVERHILL"
  },
  "50122": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HUBBARD"
  },
  "50123": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HUMESTON"
  },
  "50124": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HUXLEY"
  },
  "50125": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "INDIANOLA"
  },
  "50126": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "IOWA FALLS"
  },
  "50128": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "JAMAICA"
  },
  "50129": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "JEFFERSON"
  },
  "50130": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "JEWELL"
  },
  "50131": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "JOHNSTON"
  },
  "50132": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KAMRAR"
  },
  "50133": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KELLERTON"
  },
  "50134": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KELLEY"
  },
  "50135": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KELLOGG"
  },
  "50136": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KESWICK"
  },
  "50137": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KILLDUFF"
  },
  "50138": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KNOXVILLE"
  },
  "50139": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LACONA"
  },
  "50140": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LAMONI"
  },
  "50141": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LAUREL"
  },
  "50142": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LE GRAND"
  },
  "50143": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LEIGHTON"
  },
  "50144": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LEON"
  },
  "50145": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LIBERTY CENTER"
  },
  "50146": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LINDEN"
  },
  "50147": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LINEVILLE"
  },
  "50148": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LISCOMB"
  },
  "50149": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LORIMOR"
  },
  "50150": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LOVILIA"
  },
  "50151": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LUCAS"
  },
  "50152": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LUTHER"
  },
  "50153": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LYNNVILLE"
  },
  "50154": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MC CALLSBURG"
  },
  "50155": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MACKSBURG"
  },
  "50156": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MADRID"
  },
  "50157": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MALCOM"
  },
  "50158": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MARSHALLTOWN"
  },
  "50160": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MARTENSDALE"
  },
  "50161": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MAXWELL"
  },
  "50162": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MELBOURNE"
  },
  "50164": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MENLO"
  },
  "50165": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MILLERTON"
  },
  "50166": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MILO"
  },
  "50167": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MINBURN"
  },
  "50168": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MINGO"
  },
  "50169": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MITCHELLVILLE"
  },
  "50170": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MONROE"
  },
  "50171": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MONTEZUMA"
  },
  "50173": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MONTOUR"
  },
  "50174": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MURRAY"
  },
  "50201": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NEVADA"
  },
  "50206": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NEW PROVIDENCE"
  },
  "50207": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NEW SHARON"
  },
  "50208": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NEWTON"
  },
  "50210": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NEW VIRGINIA"
  },
  "50211": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NORWALK"
  },
  "50212": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OGDEN"
  },
  "50213": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OSCEOLA"
  },
  "50214": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OTLEY"
  },
  "50216": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PANORA"
  },
  "50217": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PATON"
  },
  "50218": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PATTERSON"
  },
  "50219": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PELLA"
  },
  "50220": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PERRY"
  },
  "50222": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PERU"
  },
  "50223": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PILOT MOUND"
  },
  "50225": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PLEASANTVILLE"
  },
  "50226": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "POLK CITY"
  },
  "50227": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "POPEJOY"
  },
  "50228": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PRAIRIE CITY"
  },
  "50229": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PROLE"
  },
  "50230": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RADCLIFFE"
  },
  "50231": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RANDALL"
  },
  "50232": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "REASNOR"
  },
  "50233": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "REDFIELD"
  },
  "50234": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RHODES"
  },
  "50235": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RIPPEY"
  },
  "50236": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ROLAND"
  },
  "50237": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RUNNELLS"
  },
  "50238": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RUSSELL"
  },
  "50239": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SAINT ANTHONY"
  },
  "50240": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SAINT CHARLES"
  },
  "50241": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SAINT MARYS"
  },
  "50242": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SEARSBORO"
  },
  "50243": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SHELDAHL"
  },
  "50244": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SLATER"
  },
  "50246": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "STANHOPE"
  },
  "50247": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "STATE CENTER"
  },
  "50248": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "STORY CITY"
  },
  "50249": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "STRATFORD"
  },
  "50250": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "STUART"
  },
  "50251": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SULLY"
  },
  "50252": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SWAN"
  },
  "50254": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "THAYER"
  },
  "50256": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "TRACY"
  },
  "50257": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "TRURO"
  },
  "50258": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "UNION"
  },
  "50261": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "VAN METER"
  },
  "50262": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "VAN WERT"
  },
  "50263": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WAUKEE"
  },
  "50264": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WELDON"
  },
  "50265": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WEST DES MOINES"
  },
  "50266": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WEST DES MOINES"
  },
  "50268": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WHAT CHEER"
  },
  "50269": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WHITTEN"
  },
  "50271": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WILLIAMS"
  },
  "50272": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WILLIAMSON"
  },
  "50273": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WINTERSET"
  },
  "50274": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WIOTA"
  },
  "50275": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WOODBURN"
  },
  "50276": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WOODWARD"
  },
  "50277": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "YALE"
  },
  "50278": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ZEARING"
  },
  "50309": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DES MOINES"
  },
  "50310": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DES MOINES"
  },
  "50311": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DES MOINES"
  },
  "50312": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DES MOINES"
  },
  "50313": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DES MOINES"
  },
  "50314": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DES MOINES"
  },
  "50315": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DES MOINES"
  },
  "50316": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DES MOINES"
  },
  "50317": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DES MOINES"
  },
  "50320": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DES MOINES"
  },
  "50321": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DES MOINES"
  },
  "50322": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "URBANDALE"
  },
  "50323": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "URBANDALE"
  },
  "50325": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CLIVE"
  },
  "50401": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MASON CITY"
  },
  "50420": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ALEXANDER"
  },
  "50421": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BELMOND"
  },
  "50423": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BRITT"
  },
  "50424": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BUFFALO CENTER"
  },
  "50426": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CARPENTER"
  },
  "50428": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CLEAR LAKE"
  },
  "50430": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CORWITH"
  },
  "50431": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "COULTER"
  },
  "50432": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CRYSTAL LAKE"
  },
  "50433": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DOUGHERTY"
  },
  "50434": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FERTILE"
  },
  "50435": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FLOYD"
  },
  "50436": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FOREST CITY"
  },
  "50438": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GARNER"
  },
  "50439": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GOODELL"
  },
  "50440": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GRAFTON"
  },
  "50441": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HAMPTON"
  },
  "50444": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HANLONTOWN"
  },
  "50446": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "JOICE"
  },
  "50447": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KANAWHA"
  },
  "50448": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KENSETT"
  },
  "50449": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KLEMME"
  },
  "50450": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LAKE MILLS"
  },
  "50451": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LAKOTA"
  },
  "50452": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LATIMER"
  },
  "50453": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LELAND"
  },
  "50454": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LITTLE CEDAR"
  },
  "50455": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MC INTIRE"
  },
  "50456": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MANLY"
  },
  "50457": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MESERVEY"
  },
  "50458": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NORA SPRINGS"
  },
  "50459": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NORTHWOOD"
  },
  "50460": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ORCHARD"
  },
  "50461": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OSAGE"
  },
  "50464": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PLYMOUTH"
  },
  "50465": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RAKE"
  },
  "50466": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RICEVILLE"
  },
  "50468": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ROCKFORD"
  },
  "50469": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ROCKWELL"
  },
  "50470": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ROWAN"
  },
  "50471": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RUDD"
  },
  "50472": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SAINT ANSGAR"
  },
  "50473": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SCARVILLE"
  },
  "50475": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SHEFFIELD"
  },
  "50476": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "STACYVILLE"
  },
  "50477": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SWALEDALE"
  },
  "50478": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "THOMPSON"
  },
  "50479": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "THORNTON"
  },
  "50480": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "TITONKA"
  },
  "50482": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "VENTURA"
  },
  "50483": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WESLEY"
  },
  "50484": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WODEN"
  },
  "50501": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FORT DODGE"
  },
  "50510": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ALBERT CITY"
  },
  "50511": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ALGONA"
  },
  "50514": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ARMSTRONG"
  },
  "50515": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "AYRSHIRE"
  },
  "50516": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BADGER"
  },
  "50517": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BANCROFT"
  },
  "50518": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BARNUM"
  },
  "50519": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BODE"
  },
  "50520": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BRADGATE"
  },
  "50521": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BURNSIDE"
  },
  "50522": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BURT"
  },
  "50523": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CALLENDER"
  },
  "50524": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CLARE"
  },
  "50525": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CLARION"
  },
  "50527": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CURLEW"
  },
  "50528": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CYLINDER"
  },
  "50529": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DAKOTA CITY"
  },
  "50530": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DAYTON"
  },
  "50531": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DOLLIVER"
  },
  "50532": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DUNCOMBE"
  },
  "50533": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "EAGLE GROVE"
  },
  "50535": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "EARLY"
  },
  "50536": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "EMMETSBURG"
  },
  "50538": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FARNHAMVILLE"
  },
  "50539": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FENTON"
  },
  "50540": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FONDA"
  },
  "50541": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GILMORE CITY"
  },
  "50542": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GOLDFIELD"
  },
  "50543": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GOWRIE"
  },
  "50544": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HARCOURT"
  },
  "50545": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HARDY"
  },
  "50546": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HAVELOCK"
  },
  "50548": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HUMBOLDT"
  },
  "50551": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "JOLLEY"
  },
  "50552": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KNIERIM"
  },
  "50554": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LAURENS"
  },
  "50556": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LEDYARD"
  },
  "50557": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LEHIGH"
  },
  "50558": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LIVERMORE"
  },
  "50559": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LONE ROCK"
  },
  "50560": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LU VERNE"
  },
  "50561": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LYTTON"
  },
  "50562": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MALLARD"
  },
  "50563": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MANSON"
  },
  "50565": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MARATHON"
  },
  "50566": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MOORLAND"
  },
  "50567": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NEMAHA"
  },
  "50568": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NEWELL"
  },
  "50569": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OTHO"
  },
  "50570": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OTTOSEN"
  },
  "50571": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PALMER"
  },
  "50573": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PLOVER"
  },
  "50574": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "POCAHONTAS"
  },
  "50575": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "POMEROY"
  },
  "50576": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "REMBRANDT"
  },
  "50577": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RENWICK"
  },
  "50578": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RINGSTED"
  },
  "50579": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ROCKWELL CITY"
  },
  "50581": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ROLFE"
  },
  "50582": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RUTLAND"
  },
  "50583": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SAC CITY"
  },
  "50585": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SIOUX RAPIDS"
  },
  "50586": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SOMERS"
  },
  "50588": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "STORM LAKE"
  },
  "50590": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SWEA CITY"
  },
  "50591": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "THOR"
  },
  "50592": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "TRUESDALE"
  },
  "50593": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "VARINA"
  },
  "50594": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "VINCENT"
  },
  "50595": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WEBSTER CITY"
  },
  "50597": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WEST BEND"
  },
  "50598": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WHITTEMORE"
  },
  "50599": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WOOLSTOCK"
  },
  "50601": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ACKLEY"
  },
  "50602": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ALLISON"
  },
  "50603": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ALTA VISTA"
  },
  "50604": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "APLINGTON"
  },
  "50605": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "AREDALE"
  },
  "50606": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ARLINGTON"
  },
  "50607": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "AURORA"
  },
  "50609": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BEAMAN"
  },
  "50611": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BRISTOW"
  },
  "50612": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BUCKINGHAM"
  },
  "50613": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CEDAR FALLS"
  },
  "50616": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CHARLES CITY"
  },
  "50619": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CLARKSVILLE"
  },
  "50621": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CONRAD"
  },
  "50622": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DENVER"
  },
  "50623": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DEWAR"
  },
  "50624": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DIKE"
  },
  "50625": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DUMONT"
  },
  "50626": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DUNKERTON"
  },
  "50627": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ELDORA"
  },
  "50628": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ELMA"
  },
  "50629": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FAIRBANK"
  },
  "50630": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FREDERICKSBURG"
  },
  "50631": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FREDERIKA"
  },
  "50632": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GARWIN"
  },
  "50633": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GENEVA"
  },
  "50634": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GILBERTVILLE"
  },
  "50635": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GLADBROOK"
  },
  "50636": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GREENE"
  },
  "50638": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GRUNDY CENTER"
  },
  "50641": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HAZLETON"
  },
  "50642": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HOLLAND"
  },
  "50643": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HUDSON"
  },
  "50644": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "INDEPENDENCE"
  },
  "50645": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "IONIA"
  },
  "50647": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "JANESVILLE"
  },
  "50648": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "JESUP"
  },
  "50649": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KESLEY"
  },
  "50650": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LAMONT"
  },
  "50651": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LA PORTE CITY"
  },
  "50652": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LINCOLN"
  },
  "50653": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MARBLE ROCK"
  },
  "50654": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MASONVILLE"
  },
  "50655": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MAYNARD"
  },
  "50658": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NASHUA"
  },
  "50659": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NEW HAMPTON"
  },
  "50660": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NEW HARTFORD"
  },
  "50662": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OELWEIN"
  },
  "50665": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PARKERSBURG"
  },
  "50666": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PLAINFIELD"
  },
  "50667": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RAYMOND"
  },
  "50668": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "READLYN"
  },
  "50669": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "REINBECK"
  },
  "50670": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SHELL ROCK"
  },
  "50671": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "STANLEY"
  },
  "50672": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "STEAMBOAT ROCK"
  },
  "50673": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "STOUT"
  },
  "50674": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SUMNER"
  },
  "50675": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "TRAER"
  },
  "50676": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "TRIPOLI"
  },
  "50677": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WAVERLY"
  },
  "50680": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WELLSBURG"
  },
  "50681": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WESTGATE"
  },
  "50682": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WINTHROP"
  },
  "50701": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WATERLOO"
  },
  "50702": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WATERLOO"
  },
  "50703": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WATERLOO"
  },
  "50706": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WATERLOO"
  },
  "50707": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "EVANSDALE"
  },
  "50801": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CRESTON"
  },
  "50830": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "AFTON"
  },
  "50833": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BEDFORD"
  },
  "50835": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BENTON"
  },
  "50836": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BLOCKTON"
  },
  "50837": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BRIDGEWATER"
  },
  "50840": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CLEARFIELD"
  },
  "50841": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CORNING"
  },
  "50843": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CUMBERLAND"
  },
  "50845": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DIAGONAL"
  },
  "50846": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FONTANELLE"
  },
  "50847": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GRANT"
  },
  "50848": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GRAVITY"
  },
  "50849": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GREENFIELD"
  },
  "50851": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LENOX"
  },
  "50853": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MASSENA"
  },
  "50854": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MOUNT AYR"
  },
  "50857": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NODAWAY"
  },
  "50858": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ORIENT"
  },
  "50859": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PRESCOTT"
  },
  "50860": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "REDDING"
  },
  "50861": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SHANNON CITY"
  },
  "50862": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SHARPSBURG"
  },
  "50863": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "TINGLEY"
  },
  "50864": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "VILLISCA"
  },
  "51001": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "AKRON"
  },
  "51002": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ALTA"
  },
  "51003": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ALTON"
  },
  "51004": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ANTHON"
  },
  "51005": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "AURELIA"
  },
  "51006": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BATTLE CREEK"
  },
  "51007": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BRONSON"
  },
  "51008": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BRUNSVILLE"
  },
  "51010": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CASTANA"
  },
  "51011": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CHATSWORTH"
  },
  "51012": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CHEROKEE"
  },
  "51014": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CLEGHORN"
  },
  "51016": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CORRECTIONVILLE"
  },
  "51018": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CUSHING"
  },
  "51019": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DANBURY"
  },
  "51020": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GALVA"
  },
  "51022": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GRANVILLE"
  },
  "51023": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HAWARDEN"
  },
  "51024": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HINTON"
  },
  "51025": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HOLSTEIN"
  },
  "51026": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HORNICK"
  },
  "51027": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "IRETON"
  },
  "51028": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KINGSLEY"
  },
  "51029": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LARRABEE"
  },
  "51030": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LAWTON"
  },
  "51031": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LE MARS"
  },
  "51033": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LINN GROVE"
  },
  "51034": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MAPLETON"
  },
  "51035": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MARCUS"
  },
  "51036": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MAURICE"
  },
  "51037": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MERIDEN"
  },
  "51038": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MERRILL"
  },
  "51039": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MOVILLE"
  },
  "51040": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ONAWA"
  },
  "51041": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ORANGE CITY"
  },
  "51044": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OTO"
  },
  "51045": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OYENS"
  },
  "51046": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PAULLINA"
  },
  "51047": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PETERSON"
  },
  "51048": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PIERSON"
  },
  "51049": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "QUIMBY"
  },
  "51050": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "REMSEN"
  },
  "51051": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RODNEY"
  },
  "51052": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SALIX"
  },
  "51053": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SCHALLER"
  },
  "51054": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SERGEANT BLUFF"
  },
  "51055": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SLOAN"
  },
  "51056": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SMITHLAND"
  },
  "51058": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SUTHERLAND"
  },
  "51060": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "UTE"
  },
  "51061": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WASHTA"
  },
  "51062": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WESTFIELD"
  },
  "51063": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WHITING"
  },
  "51101": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SIOUX CITY"
  },
  "51103": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SIOUX CITY"
  },
  "51104": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SIOUX CITY"
  },
  "51105": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SIOUX CITY"
  },
  "51106": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SIOUX CITY"
  },
  "51108": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SIOUX CITY"
  },
  "51109": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SIOUX CITY"
  },
  "51111": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SIOUX CITY"
  },
  "51201": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SHELDON"
  },
  "51230": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ALVORD"
  },
  "51231": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ARCHER"
  },
  "51232": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ASHTON"
  },
  "51234": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BOYDEN"
  },
  "51235": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DOON"
  },
  "51237": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GEORGE"
  },
  "51238": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HOSPERS"
  },
  "51239": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HULL"
  },
  "51240": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "INWOOD"
  },
  "51241": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LARCHWOOD"
  },
  "51242": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LESTER"
  },
  "51243": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LITTLE ROCK"
  },
  "51244": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MATLOCK"
  },
  "51245": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PRIMGHAR"
  },
  "51246": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ROCK RAPIDS"
  },
  "51247": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ROCK VALLEY"
  },
  "51248": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SANBORN"
  },
  "51249": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SIBLEY"
  },
  "51250": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SIOUX CENTER"
  },
  "51301": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SPENCER"
  },
  "51331": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ARNOLDS PARK"
  },
  "51333": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DICKENS"
  },
  "51334": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ESTHERVILLE"
  },
  "51338": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "EVERLY"
  },
  "51341": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GILLETT GROVE"
  },
  "51342": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GRAETTINGER"
  },
  "51343": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GREENVILLE"
  },
  "51345": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HARRIS"
  },
  "51346": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HARTLEY"
  },
  "51347": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LAKE PARK"
  },
  "51350": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MELVIN"
  },
  "51351": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MILFORD"
  },
  "51354": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OCHEYEDAN"
  },
  "51355": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OKOBOJI"
  },
  "51357": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ROYAL"
  },
  "51358": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RUTHVEN"
  },
  "51360": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SPIRIT LAKE"
  },
  "51363": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SUPERIOR"
  },
  "51364": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "TERRIL"
  },
  "51365": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WALLINGFORD"
  },
  "51366": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WEBB"
  },
  "51401": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CARROLL"
  },
  "51430": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ARCADIA"
  },
  "51431": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ARTHUR"
  },
  "51433": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "AUBURN"
  },
  "51436": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BREDA"
  },
  "51439": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CHARTER OAK"
  },
  "51440": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DEDHAM"
  },
  "51441": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DELOIT"
  },
  "51442": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DENISON"
  },
  "51443": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GLIDDEN"
  },
  "51444": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HALBUR"
  },
  "51445": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "IDA GROVE"
  },
  "51446": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "IRWIN"
  },
  "51447": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KIRKMAN"
  },
  "51448": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KIRON"
  },
  "51449": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LAKE CITY"
  },
  "51450": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LAKE VIEW"
  },
  "51451": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LANESBORO"
  },
  "51452": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LIDDERDALE"
  },
  "51453": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LOHRVILLE"
  },
  "51454": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MANILLA"
  },
  "51455": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MANNING"
  },
  "51458": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ODEBOLT"
  },
  "51459": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RALSTON"
  },
  "51461": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SCHLESWIG"
  },
  "51462": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SCRANTON"
  },
  "51463": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "TEMPLETON"
  },
  "51465": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "VAIL"
  },
  "51466": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WALL LAKE"
  },
  "51467": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WESTSIDE"
  },
  "51501": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "COUNCIL BLUFFS"
  },
  "51502": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "COUNCIL BLUFFS"
  },
  "51503": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "COUNCIL BLUFFS"
  },
  "51510": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CARTER LAKE"
  },
  "51520": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ARION"
  },
  "51521": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "AVOCA"
  },
  "51523": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BLENCOE"
  },
  "51525": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CARSON"
  },
  "51526": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CRESCENT"
  },
  "51527": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DEFIANCE"
  },
  "51528": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DOW CITY"
  },
  "51529": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DUNLAP"
  },
  "51530": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "EARLING"
  },
  "51531": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ELK HORN"
  },
  "51532": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ELLIOTT"
  },
  "51533": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "EMERSON"
  },
  "51534": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GLENWOOD"
  },
  "51535": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GRISWOLD"
  },
  "51536": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HANCOCK"
  },
  "51537": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HARLAN"
  },
  "51540": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HASTINGS"
  },
  "51541": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HENDERSON"
  },
  "51542": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HONEY CREEK"
  },
  "51543": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KIMBALLTON"
  },
  "51544": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LEWIS"
  },
  "51545": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LITTLE SIOUX"
  },
  "51546": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LOGAN"
  },
  "51548": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MC CLELLAND"
  },
  "51549": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MACEDONIA"
  },
  "51550": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MAGNOLIA"
  },
  "51551": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MALVERN"
  },
  "51552": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MARNE"
  },
  "51553": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MINDEN"
  },
  "51554": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MINEOLA"
  },
  "51555": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MISSOURI VALLEY"
  },
  "51556": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MODALE"
  },
  "51557": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MONDAMIN"
  },
  "51558": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MOORHEAD"
  },
  "51559": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NEOLA"
  },
  "51560": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OAKLAND"
  },
  "51561": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PACIFIC JUNCTION"
  },
  "51562": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PANAMA"
  },
  "51563": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PERSIA"
  },
  "51564": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PISGAH"
  },
  "51565": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PORTSMOUTH"
  },
  "51566": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RED OAK"
  },
  "51570": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SHELBY"
  },
  "51571": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SILVER CITY"
  },
  "51572": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SOLDIER"
  },
  "51573": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "STANTON"
  },
  "51575": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "TREYNOR"
  },
  "51576": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "UNDERWOOD"
  },
  "51577": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WALNUT"
  },
  "51578": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WESTPHALIA"
  },
  "51579": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WOODBINE"
  },
  "51601": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SHENANDOAH"
  },
  "51630": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BLANCHARD"
  },
  "51631": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BRADDYVILLE"
  },
  "51632": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CLARINDA"
  },
  "51636": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "COIN"
  },
  "51637": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "COLLEGE SPRINGS"
  },
  "51638": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ESSEX"
  },
  "51639": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FARRAGUT"
  },
  "51640": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HAMBURG"
  },
  "51645": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "IMOGENE"
  },
  "51646": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NEW MARKET"
  },
  "51647": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NORTHBORO"
  },
  "51648": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PERCIVAL"
  },
  "51649": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RANDOLPH"
  },
  "51650": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RIVERTON"
  },
  "51652": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SIDNEY"
  },
  "51653": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "TABOR"
  },
  "51654": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "THURMAN"
  },
  "52001": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DUBUQUE"
  },
  "52002": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DUBUQUE"
  },
  "52003": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DUBUQUE"
  },
  "52030": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ANDREW"
  },
  "52031": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BELLEVUE"
  },
  "52032": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BERNARD"
  },
  "52033": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CASCADE"
  },
  "52035": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "COLESBURG"
  },
  "52036": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DELAWARE"
  },
  "52037": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DELMAR"
  },
  "52038": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DUNDEE"
  },
  "52039": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DURANGO"
  },
  "52040": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DYERSVILLE"
  },
  "52041": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "EARLVILLE"
  },
  "52042": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "EDGEWOOD"
  },
  "52043": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ELKADER"
  },
  "52044": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ELKPORT"
  },
  "52045": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "EPWORTH"
  },
  "52046": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FARLEY"
  },
  "52047": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FARMERSBURG"
  },
  "52048": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GARBER"
  },
  "52049": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GARNAVILLO"
  },
  "52050": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GREELEY"
  },
  "52052": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GUTTENBERG"
  },
  "52053": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HOLY CROSS"
  },
  "52054": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LA MOTTE"
  },
  "52056": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LUXEMBURG"
  },
  "52057": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MANCHESTER"
  },
  "52060": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MAQUOKETA"
  },
  "52064": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MILES"
  },
  "52065": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NEW VIENNA"
  },
  "52066": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NORTH BUENA VISTA"
  },
  "52068": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PEOSTA"
  },
  "52069": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PRESTON"
  },
  "52070": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SABULA"
  },
  "52071": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SAINT DONATUS"
  },
  "52072": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SAINT OLAF"
  },
  "52073": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SHERRILL"
  },
  "52074": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SPRAGUEVILLE"
  },
  "52075": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SPRINGBROOK"
  },
  "52076": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "STRAWBERRY POINT"
  },
  "52077": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "VOLGA"
  },
  "52078": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WORTHINGTON"
  },
  "52079": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ZWINGLE"
  },
  "52101": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DECORAH"
  },
  "52132": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CALMAR"
  },
  "52133": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CASTALIA"
  },
  "52134": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CHESTER"
  },
  "52135": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CLERMONT"
  },
  "52136": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CRESCO"
  },
  "52140": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DORCHESTER"
  },
  "52141": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ELGIN"
  },
  "52142": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FAYETTE"
  },
  "52144": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FORT ATKINSON"
  },
  "52146": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HARPERS FERRY"
  },
  "52147": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HAWKEYE"
  },
  "52151": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LANSING"
  },
  "52154": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LAWLER"
  },
  "52155": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LIME SPRINGS"
  },
  "52156": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LUANA"
  },
  "52157": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MC GREGOR"
  },
  "52158": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MARQUETTE"
  },
  "52159": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MONONA"
  },
  "52160": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NEW ALBIN"
  },
  "52161": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OSSIAN"
  },
  "52162": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "POSTVILLE"
  },
  "52163": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PROTIVIN"
  },
  "52164": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RANDALIA"
  },
  "52165": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RIDGEWAY"
  },
  "52166": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SAINT LUCAS"
  },
  "52168": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SPILLVILLE"
  },
  "52169": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WADENA"
  },
  "52170": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WATERVILLE"
  },
  "52171": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WAUCOMA"
  },
  "52172": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WAUKON"
  },
  "52175": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WEST UNION"
  },
  "52201": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "AINSWORTH"
  },
  "52202": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ALBURNETT"
  },
  "52203": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "AMANA"
  },
  "52205": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ANAMOSA"
  },
  "52206": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ATKINS"
  },
  "52207": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BALDWIN"
  },
  "52208": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BELLE PLAINE"
  },
  "52209": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BLAIRSTOWN"
  },
  "52210": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BRANDON"
  },
  "52211": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BROOKLYN"
  },
  "52212": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CENTER JUNCTION"
  },
  "52213": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CENTER POINT"
  },
  "52214": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CENTRAL CITY"
  },
  "52215": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CHELSEA"
  },
  "52216": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CLARENCE"
  },
  "52217": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CLUTIER"
  },
  "52218": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "COGGON"
  },
  "52219": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PRAIRIEBURG"
  },
  "52220": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CONROY"
  },
  "52221": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GUERNSEY"
  },
  "52222": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DEEP RIVER"
  },
  "52223": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DELHI"
  },
  "52224": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DYSART"
  },
  "52225": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ELBERON"
  },
  "52227": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ELY"
  },
  "52228": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FAIRFAX"
  },
  "52229": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GARRISON"
  },
  "52231": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HARPER"
  },
  "52232": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HARTWICK"
  },
  "52233": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HIAWATHA"
  },
  "52235": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HILLS"
  },
  "52236": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HOMESTEAD"
  },
  "52237": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HOPKINTON"
  },
  "52240": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "IOWA CITY"
  },
  "52241": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CORALVILLE"
  },
  "52245": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "IOWA CITY"
  },
  "52246": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "IOWA CITY"
  },
  "52247": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KALONA"
  },
  "52248": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KEOTA"
  },
  "52249": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KEYSTONE"
  },
  "52251": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LADORA"
  },
  "52253": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LISBON"
  },
  "52254": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LOST NATION"
  },
  "52255": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LOWDEN"
  },
  "52257": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LUZERNE"
  },
  "52301": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MARENGO"
  },
  "52302": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MARION"
  },
  "52305": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MARTELLE"
  },
  "52306": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MECHANICSVILLE"
  },
  "52307": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MIDDLE AMANA"
  },
  "52308": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MILLERSBURG"
  },
  "52309": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MONMOUTH"
  },
  "52310": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MONTICELLO"
  },
  "52312": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MORLEY"
  },
  "52313": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MOUNT AUBURN"
  },
  "52314": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MOUNT VERNON"
  },
  "52315": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NEWHALL"
  },
  "52316": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NORTH ENGLISH"
  },
  "52317": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NORTH LIBERTY"
  },
  "52318": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NORWAY"
  },
  "52320": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OLIN"
  },
  "52321": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ONSLOW"
  },
  "52322": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OXFORD"
  },
  "52323": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OXFORD JUNCTION"
  },
  "52324": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PALO"
  },
  "52325": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PARNELL"
  },
  "52326": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "QUASQUETON"
  },
  "52327": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RIVERSIDE"
  },
  "52328": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ROBINS"
  },
  "52329": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ROWLEY"
  },
  "52330": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RYAN"
  },
  "52332": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SHELLSBURG"
  },
  "52333": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SOLON"
  },
  "52334": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SOUTH AMANA"
  },
  "52335": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SOUTH ENGLISH"
  },
  "52336": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SPRINGVILLE"
  },
  "52337": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "STANWOOD"
  },
  "52338": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SWISHER"
  },
  "52339": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "TAMA"
  },
  "52340": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "TIFFIN"
  },
  "52341": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "TODDVILLE"
  },
  "52342": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "TOLEDO"
  },
  "52345": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "URBANA"
  },
  "52346": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "VAN HORNE"
  },
  "52347": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "VICTOR"
  },
  "52348": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "VINING"
  },
  "52349": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "VINTON"
  },
  "52351": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WALFORD"
  },
  "52352": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WALKER"
  },
  "52353": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WASHINGTON"
  },
  "52354": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WATKINS"
  },
  "52355": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WEBSTER"
  },
  "52356": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WELLMAN"
  },
  "52358": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WEST BRANCH"
  },
  "52359": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WEST CHESTER"
  },
  "52361": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WILLIAMSBURG"
  },
  "52362": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WYOMING"
  },
  "52401": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CEDAR RAPIDS"
  },
  "52402": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CEDAR RAPIDS"
  },
  "52403": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CEDAR RAPIDS"
  },
  "52404": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CEDAR RAPIDS"
  },
  "52405": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CEDAR RAPIDS"
  },
  "52411": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CEDAR RAPIDS"
  },
  "52501": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OTTUMWA"
  },
  "52530": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "AGENCY"
  },
  "52531": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ALBIA"
  },
  "52533": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BATAVIA"
  },
  "52534": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BEACON"
  },
  "52535": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BIRMINGHAM"
  },
  "52536": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BLAKESBURG"
  },
  "52537": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BLOOMFIELD"
  },
  "52540": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BRIGHTON"
  },
  "52542": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CANTRIL"
  },
  "52543": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CEDAR"
  },
  "52544": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CENTERVILLE"
  },
  "52548": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CHILLICOTHE"
  },
  "52549": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CINCINNATI"
  },
  "52550": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DELTA"
  },
  "52551": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DOUDS"
  },
  "52552": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DRAKESVILLE"
  },
  "52553": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "EDDYVILLE"
  },
  "52554": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ELDON"
  },
  "52555": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "EXLINE"
  },
  "52556": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FAIRFIELD"
  },
  "52560": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FLORIS"
  },
  "52561": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FREMONT"
  },
  "52562": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HAYESVILLE"
  },
  "52563": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HEDRICK"
  },
  "52565": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KEOSAUQUA"
  },
  "52566": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KIRKVILLE"
  },
  "52567": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LIBERTYVILLE"
  },
  "52568": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MARTINSBURG"
  },
  "52569": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MELROSE"
  },
  "52570": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MILTON"
  },
  "52571": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MORAVIA"
  },
  "52572": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MOULTON"
  },
  "52573": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MOUNT STERLING"
  },
  "52574": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MYSTIC"
  },
  "52576": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OLLIE"
  },
  "52577": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OSKALOOSA"
  },
  "52580": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PACKWOOD"
  },
  "52581": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PLANO"
  },
  "52583": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PROMISE CITY"
  },
  "52584": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PULASKI"
  },
  "52585": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "RICHLAND"
  },
  "52586": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ROSE HILL"
  },
  "52588": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SELMA"
  },
  "52590": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SEYMOUR"
  },
  "52591": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SIGOURNEY"
  },
  "52593": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "UDELL"
  },
  "52594": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "UNIONVILLE"
  },
  "52595": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "UNIVERSITY PARK"
  },
  "52601": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BURLINGTON"
  },
  "52619": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ARGYLE"
  },
  "52620": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BONAPARTE"
  },
  "52621": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CRAWFORDSVILLE"
  },
  "52623": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DANVILLE"
  },
  "52624": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DENMARK"
  },
  "52625": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DONNELLSON"
  },
  "52626": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FARMINGTON"
  },
  "52627": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FORT MADISON"
  },
  "52630": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HILLSBORO"
  },
  "52631": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "HOUGHTON"
  },
  "52632": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "KEOKUK"
  },
  "52635": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LOCKRIDGE"
  },
  "52637": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MEDIAPOLIS"
  },
  "52638": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MIDDLETOWN"
  },
  "52639": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MONTROSE"
  },
  "52640": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MORNING SUN"
  },
  "52641": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MOUNT PLEASANT"
  },
  "52644": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MOUNT UNION"
  },
  "52645": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NEW LONDON"
  },
  "52646": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OAKVILLE"
  },
  "52647": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "OLDS"
  },
  "52649": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SALEM"
  },
  "52650": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SPERRY"
  },
  "52651": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "STOCKPORT"
  },
  "52652": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "SWEDESBURG"
  },
  "52653": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WAPELLO"
  },
  "52654": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WAYLAND"
  },
  "52655": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WEST BURLINGTON"
  },
  "52656": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WEST POINT"
  },
  "52658": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WEVER"
  },
  "52659": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WINFIELD"
  },
  "52660": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "YARMOUTH"
  },
  "52701": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ANDOVER"
  },
  "52720": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ATALISSA"
  },
  "52721": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BENNETT"
  },
  "52722": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BETTENDORF"
  },
  "52726": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BLUE GRASS"
  },
  "52727": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BRYANT"
  },
  "52728": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "BUFFALO"
  },
  "52729": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CALAMUS"
  },
  "52730": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CAMANCHE"
  },
  "52731": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CHARLOTTE"
  },
  "52732": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CLINTON"
  },
  "52737": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "COLUMBUS CITY"
  },
  "52738": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "COLUMBUS JUNCTION"
  },
  "52739": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "CONESVILLE"
  },
  "52742": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DE WITT"
  },
  "52745": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DIXON"
  },
  "52746": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DONAHUE"
  },
  "52747": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DURANT"
  },
  "52748": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "ELDRIDGE"
  },
  "52749": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "FRUITLAND"
  },
  "52750": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GOOSE LAKE"
  },
  "52751": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GRAND MOUND"
  },
  "52752": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "GRANDVIEW"
  },
  "52753": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LE CLAIRE"
  },
  "52754": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LETTS"
  },
  "52755": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LONE TREE"
  },
  "52756": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LONG GROVE"
  },
  "52757": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "LOW MOOR"
  },
  "52758": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MC CAUSLAND"
  },
  "52760": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MOSCOW"
  },
  "52761": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "MUSCATINE"
  },
  "52765": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NEW LIBERTY"
  },
  "52766": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "NICHOLS"
  },
  "52767": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PLEASANT VALLEY"
  },
  "52768": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "PRINCETON"
  },
  "52769": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "STOCKTON"
  },
  "52772": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "TIPTON"
  },
  "52773": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WALCOTT"
  },
  "52774": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WELTON"
  },
  "52776": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WEST LIBERTY"
  },
  "52777": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WHEATLAND"
  },
  "52778": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "WILTON"
  },
  "52801": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DAVENPORT"
  },
  "52802": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DAVENPORT"
  },
  "52803": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DAVENPORT"
  },
  "52804": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DAVENPORT"
  },
  "52806": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DAVENPORT"
  },
  "52807": {
    stateCode: "IA",
    stateName: "Iowa",
    city: "DAVENPORT"
  },
  "53001": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ADELL"
  },
  "53002": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ALLENTON"
  },
  "53003": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ASHIPPUN"
  },
  "53004": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BELGIUM"
  },
  "53005": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BROOKFIELD"
  },
  "53006": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BROWNSVILLE"
  },
  "53007": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BUTLER"
  },
  "53010": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CAMPBELLSPORT"
  },
  "53011": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CASCADE"
  },
  "53012": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CEDARBURG"
  },
  "53013": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CEDAR GROVE"
  },
  "53014": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CHILTON"
  },
  "53015": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CLEVELAND"
  },
  "53016": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CLYMAN"
  },
  "53017": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "COLGATE"
  },
  "53018": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DELAFIELD"
  },
  "53019": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "EDEN"
  },
  "53020": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ELKHART LAKE"
  },
  "53021": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FREDONIA"
  },
  "53022": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GERMANTOWN"
  },
  "53023": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GLENBEULAH"
  },
  "53024": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GRAFTON"
  },
  "53027": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HARTFORD"
  },
  "53029": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HARTLAND"
  },
  "53032": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HORICON"
  },
  "53033": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HUBERTUS"
  },
  "53034": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HUSTISFORD"
  },
  "53035": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "IRON RIDGE"
  },
  "53036": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "IXONIA"
  },
  "53037": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "JACKSON"
  },
  "53038": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "JOHNSON CREEK"
  },
  "53039": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "JUNEAU"
  },
  "53040": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "KEWASKUM"
  },
  "53042": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "KIEL"
  },
  "53044": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "KOHLER"
  },
  "53045": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BROOKFIELD"
  },
  "53046": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LANNON"
  },
  "53047": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LEBANON"
  },
  "53048": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LOMIRA"
  },
  "53049": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MALONE"
  },
  "53050": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MAYVILLE"
  },
  "53051": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MENOMONEE FALLS"
  },
  "53057": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MOUNT CALVARY"
  },
  "53058": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NASHOTAH"
  },
  "53059": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NEOSHO"
  },
  "53061": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NEW HOLSTEIN"
  },
  "53063": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NEWTON"
  },
  "53065": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OAKFIELD"
  },
  "53066": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OCONOMOWOC"
  },
  "53069": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OKAUCHEE"
  },
  "53070": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OOSTBURG"
  },
  "53072": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PEWAUKEE"
  },
  "53073": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PLYMOUTH"
  },
  "53074": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PORT WASHINGTON"
  },
  "53075": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RANDOM LAKE"
  },
  "53076": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RICHFIELD"
  },
  "53078": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RUBICON"
  },
  "53079": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SAINT CLOUD"
  },
  "53080": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SAUKVILLE"
  },
  "53081": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SHEBOYGAN"
  },
  "53083": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SHEBOYGAN"
  },
  "53085": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SHEBOYGAN FALLS"
  },
  "53086": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SLINGER"
  },
  "53088": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "STOCKBRIDGE"
  },
  "53089": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SUSSEX"
  },
  "53090": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WEST BEND"
  },
  "53091": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "THERESA"
  },
  "53092": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "THIENSVILLE"
  },
  "53093": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WALDO"
  },
  "53094": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WATERTOWN"
  },
  "53095": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WEST BEND"
  },
  "53097": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MEQUON"
  },
  "53098": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WATERTOWN"
  },
  "53103": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BIG BEND"
  },
  "53104": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BRISTOL"
  },
  "53105": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BURLINGTON"
  },
  "53108": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CALEDONIA"
  },
  "53110": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CUDAHY"
  },
  "53114": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DARIEN"
  },
  "53115": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DELAVAN"
  },
  "53118": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DOUSMAN"
  },
  "53119": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "EAGLE"
  },
  "53120": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "EAST TROY"
  },
  "53121": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ELKHORN"
  },
  "53122": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ELM GROVE"
  },
  "53125": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FONTANA"
  },
  "53126": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FRANKSVILLE"
  },
  "53128": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GENOA CITY"
  },
  "53129": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GREENDALE"
  },
  "53130": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HALES CORNERS"
  },
  "53132": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FRANKLIN"
  },
  "53137": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HELENVILLE"
  },
  "53139": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "KANSASVILLE"
  },
  "53140": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "KENOSHA"
  },
  "53142": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "KENOSHA"
  },
  "53143": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "KENOSHA"
  },
  "53144": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "KENOSHA"
  },
  "53146": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NEW BERLIN"
  },
  "53147": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LAKE GENEVA"
  },
  "53148": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LYONS"
  },
  "53149": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MUKWONAGO"
  },
  "53150": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MUSKEGO"
  },
  "53151": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NEW BERLIN"
  },
  "53153": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NORTH PRAIRIE"
  },
  "53154": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OAK CREEK"
  },
  "53156": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PALMYRA"
  },
  "53158": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PLEASANT PRAIRIE"
  },
  "53167": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ROCHESTER"
  },
  "53168": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SALEM"
  },
  "53170": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SILVER LAKE"
  },
  "53172": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SOUTH MILWAUKEE"
  },
  "53176": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SPRINGFIELD"
  },
  "53177": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "STURTEVANT"
  },
  "53178": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SULLIVAN"
  },
  "53179": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "TREVOR"
  },
  "53181": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "TWIN LAKES"
  },
  "53182": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "UNION GROVE"
  },
  "53183": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WALES"
  },
  "53184": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WALWORTH"
  },
  "53185": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WATERFORD"
  },
  "53186": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WAUKESHA"
  },
  "53188": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WAUKESHA"
  },
  "53189": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WAUKESHA"
  },
  "53190": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WHITEWATER"
  },
  "53191": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WILLIAMS BAY"
  },
  "53192": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WILMOT"
  },
  "53195": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ZENDA"
  },
  "53202": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53203": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53204": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53205": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53206": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53207": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53208": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53209": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53210": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53211": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53212": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53213": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53214": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53215": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53216": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53217": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53218": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53219": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53220": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53221": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53222": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53223": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53224": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53225": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53226": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53227": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53228": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53233": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53235": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILWAUKEE"
  },
  "53402": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RACINE"
  },
  "53403": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RACINE"
  },
  "53404": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RACINE"
  },
  "53405": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RACINE"
  },
  "53406": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RACINE"
  },
  "53502": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ALBANY"
  },
  "53503": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ARENA"
  },
  "53504": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ARGYLE"
  },
  "53505": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "AVALON"
  },
  "53506": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "AVOCA"
  },
  "53507": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BARNEVELD"
  },
  "53508": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BELLEVILLE"
  },
  "53510": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BELMONT"
  },
  "53511": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BELOIT"
  },
  "53515": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BLACK EARTH"
  },
  "53516": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BLANCHARDVILLE"
  },
  "53517": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BLUE MOUNDS"
  },
  "53518": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BLUE RIVER"
  },
  "53520": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BRODHEAD"
  },
  "53521": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BROOKLYN"
  },
  "53522": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BROWNTOWN"
  },
  "53523": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CAMBRIDGE"
  },
  "53525": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CLINTON"
  },
  "53526": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "COBB"
  },
  "53527": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "COTTAGE GROVE"
  },
  "53528": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CROSS PLAINS"
  },
  "53529": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DANE"
  },
  "53530": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DARLINGTON"
  },
  "53531": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DEERFIELD"
  },
  "53532": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DE FOREST"
  },
  "53533": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DODGEVILLE"
  },
  "53534": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "EDGERTON"
  },
  "53536": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "EVANSVILLE"
  },
  "53537": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FOOTVILLE"
  },
  "53538": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FORT ATKINSON"
  },
  "53540": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GOTHAM"
  },
  "53541": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GRATIOT"
  },
  "53543": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HIGHLAND"
  },
  "53544": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HOLLANDALE"
  },
  "53545": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "JANESVILLE"
  },
  "53546": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "JANESVILLE"
  },
  "53549": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "JEFFERSON"
  },
  "53550": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "JUDA"
  },
  "53551": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LAKE MILLS"
  },
  "53553": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LINDEN"
  },
  "53554": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LIVINGSTON"
  },
  "53555": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LODI"
  },
  "53556": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LONE ROCK"
  },
  "53557": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LOWELL"
  },
  "53558": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MC FARLAND"
  },
  "53559": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MARSHALL"
  },
  "53560": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MAZOMANIE"
  },
  "53561": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MERRIMAC"
  },
  "53562": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MIDDLETON"
  },
  "53563": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILTON"
  },
  "53565": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MINERAL POINT"
  },
  "53566": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MONROE"
  },
  "53569": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MONTFORT"
  },
  "53570": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MONTICELLO"
  },
  "53571": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MORRISONVILLE"
  },
  "53572": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MOUNT HOREB"
  },
  "53573": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MUSCODA"
  },
  "53574": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NEW GLARUS"
  },
  "53575": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OREGON"
  },
  "53576": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ORFORDVILLE"
  },
  "53577": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PLAIN"
  },
  "53578": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PRAIRIE DU SAC"
  },
  "53579": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "REESEVILLE"
  },
  "53580": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "REWEY"
  },
  "53581": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RICHLAND CENTER"
  },
  "53582": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RIDGEWAY"
  },
  "53583": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SAUK CITY"
  },
  "53585": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SHARON"
  },
  "53586": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SHULLSBURG"
  },
  "53587": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SOUTH WAYNE"
  },
  "53588": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SPRING GREEN"
  },
  "53589": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "STOUGHTON"
  },
  "53590": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SUN PRAIRIE"
  },
  "53593": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "VERONA"
  },
  "53594": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WATERLOO"
  },
  "53597": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WAUNAKEE"
  },
  "53598": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WINDSOR"
  },
  "53703": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MADISON"
  },
  "53704": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MADISON"
  },
  "53705": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MADISON"
  },
  "53706": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MADISON"
  },
  "53711": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MADISON"
  },
  "53713": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MADISON"
  },
  "53714": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MADISON"
  },
  "53715": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MADISON"
  },
  "53716": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MADISON"
  },
  "53717": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MADISON"
  },
  "53718": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MADISON"
  },
  "53719": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MADISON"
  },
  "53801": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BAGLEY"
  },
  "53803": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BENTON"
  },
  "53804": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BLOOMINGTON"
  },
  "53805": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BOSCOBEL"
  },
  "53806": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CASSVILLE"
  },
  "53807": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CUBA CITY"
  },
  "53808": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DICKEYVILLE"
  },
  "53809": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FENNIMORE"
  },
  "53810": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GLEN HAVEN"
  },
  "53811": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HAZEL GREEN"
  },
  "53813": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LANCASTER"
  },
  "53816": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MOUNT HOPE"
  },
  "53817": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PATCH GROVE"
  },
  "53818": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PLATTEVILLE"
  },
  "53820": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "POTOSI"
  },
  "53821": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PRAIRIE DU CHIEN"
  },
  "53825": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "STITZER"
  },
  "53826": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WAUZEKA"
  },
  "53827": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WOODMAN"
  },
  "53901": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PORTAGE"
  },
  "53910": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ADAMS"
  },
  "53911": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ARLINGTON"
  },
  "53913": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BARABOO"
  },
  "53916": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BEAVER DAM"
  },
  "53919": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BRANDON"
  },
  "53920": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BRIGGSVILLE"
  },
  "53922": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BURNETT"
  },
  "53923": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CAMBRIA"
  },
  "53924": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CAZENOVIA"
  },
  "53925": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "COLUMBUS"
  },
  "53926": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DALTON"
  },
  "53928": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DOYLESTOWN"
  },
  "53929": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ELROY"
  },
  "53930": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ENDEAVOR"
  },
  "53931": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FAIRWATER"
  },
  "53932": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FALL RIVER"
  },
  "53933": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FOX LAKE"
  },
  "53934": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FRIENDSHIP"
  },
  "53935": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FRIESLAND"
  },
  "53936": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GRAND MARSH"
  },
  "53937": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HILLPOINT"
  },
  "53939": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "KINGSTON"
  },
  "53940": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LAKE DELTON"
  },
  "53941": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LA VALLE"
  },
  "53943": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LOGANVILLE"
  },
  "53944": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LYNDON STATION"
  },
  "53946": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MARKESAN"
  },
  "53947": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MARQUETTE"
  },
  "53948": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MAUSTON"
  },
  "53949": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MONTELLO"
  },
  "53950": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NEW LISBON"
  },
  "53951": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NORTH FREEDOM"
  },
  "53952": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OXFORD"
  },
  "53953": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PACKWAUKEE"
  },
  "53954": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PARDEEVILLE"
  },
  "53955": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "POYNETTE"
  },
  "53956": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RANDOLPH"
  },
  "53959": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "REEDSBURG"
  },
  "53960": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RIO"
  },
  "53961": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ROCK SPRINGS"
  },
  "53962": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "UNION CENTER"
  },
  "53963": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WAUPUN"
  },
  "53964": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WESTFIELD"
  },
  "53965": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WISCONSIN DELLS"
  },
  "53968": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WONEWOC"
  },
  "53969": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WYOCENA"
  },
  "54001": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "AMERY"
  },
  "54002": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BALDWIN"
  },
  "54003": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BELDENVILLE"
  },
  "54004": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CLAYTON"
  },
  "54005": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CLEAR LAKE"
  },
  "54006": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CUSHING"
  },
  "54007": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DEER PARK"
  },
  "54009": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DRESSER"
  },
  "54011": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ELLSWORTH"
  },
  "54013": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GLENWOOD CITY"
  },
  "54014": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HAGER CITY"
  },
  "54015": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HAMMOND"
  },
  "54016": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HUDSON"
  },
  "54017": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NEW RICHMOND"
  },
  "54020": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OSCEOLA"
  },
  "54021": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PRESCOTT"
  },
  "54022": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RIVER FALLS"
  },
  "54023": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ROBERTS"
  },
  "54024": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SAINT CROIX FALLS"
  },
  "54025": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SOMERSET"
  },
  "54026": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "STAR PRAIRIE"
  },
  "54027": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WILSON"
  },
  "54028": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WOODVILLE"
  },
  "54082": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HOULTON"
  },
  "54101": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ABRAMS"
  },
  "54102": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "AMBERG"
  },
  "54103": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ARMSTRONG CREEK"
  },
  "54104": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ATHELSTANE"
  },
  "54106": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BLACK CREEK"
  },
  "54107": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BONDUEL"
  },
  "54110": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BRILLION"
  },
  "54111": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CECIL"
  },
  "54112": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "COLEMAN"
  },
  "54113": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "COMBINED LOCKS"
  },
  "54114": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CRIVITZ"
  },
  "54115": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DE PERE"
  },
  "54119": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DUNBAR"
  },
  "54120": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FENCE"
  },
  "54121": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FLORENCE"
  },
  "54123": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FOREST JUNCTION"
  },
  "54124": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GILLETT"
  },
  "54125": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GOODMAN"
  },
  "54126": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GREENLEAF"
  },
  "54127": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GREEN VALLEY"
  },
  "54128": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GRESHAM"
  },
  "54129": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HILBERT"
  },
  "54130": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "KAUKAUNA"
  },
  "54135": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "KESHENA"
  },
  "54136": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "KIMBERLY"
  },
  "54137": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "KRAKOW"
  },
  "54138": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LAKEWOOD"
  },
  "54139": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LENA"
  },
  "54140": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LITTLE CHUTE"
  },
  "54141": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LITTLE SUAMICO"
  },
  "54143": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MARINETTE"
  },
  "54149": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MOUNTAIN"
  },
  "54150": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NEOPIT"
  },
  "54151": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NIAGARA"
  },
  "54152": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NICHOLS"
  },
  "54153": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OCONTO"
  },
  "54154": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OCONTO FALLS"
  },
  "54155": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ONEIDA"
  },
  "54156": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PEMBINE"
  },
  "54157": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PESHTIGO"
  },
  "54159": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PORTERFIELD"
  },
  "54160": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "POTTER"
  },
  "54161": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "POUND"
  },
  "54162": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PULASKI"
  },
  "54165": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SEYMOUR"
  },
  "54166": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SHAWANO"
  },
  "54169": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SHERWOOD"
  },
  "54170": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SHIOCTON"
  },
  "54171": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SOBIESKI"
  },
  "54173": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SUAMICO"
  },
  "54174": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SURING"
  },
  "54175": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "TOWNSEND"
  },
  "54177": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WAUSAUKEE"
  },
  "54180": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WRIGHTSTOWN"
  },
  "54201": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ALGOMA"
  },
  "54202": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BAILEYS HARBOR"
  },
  "54204": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BRUSSELS"
  },
  "54205": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CASCO"
  },
  "54207": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "COLLINS"
  },
  "54208": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DENMARK"
  },
  "54209": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "EGG HARBOR"
  },
  "54210": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ELLISON BAY"
  },
  "54211": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "EPHRAIM"
  },
  "54212": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FISH CREEK"
  },
  "54213": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FORESTVILLE"
  },
  "54214": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FRANCIS CREEK"
  },
  "54215": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "KELLNERSVILLE"
  },
  "54216": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "KEWAUNEE"
  },
  "54217": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LUXEMBURG"
  },
  "54220": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MANITOWOC"
  },
  "54227": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MARIBEL"
  },
  "54228": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MISHICOT"
  },
  "54229": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NEW FRANKEN"
  },
  "54230": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "REEDSVILLE"
  },
  "54232": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SAINT NAZIANZ"
  },
  "54234": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SISTER BAY"
  },
  "54235": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "STURGEON BAY"
  },
  "54240": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "TISCH MILLS"
  },
  "54241": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "TWO RIVERS"
  },
  "54245": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "VALDERS"
  },
  "54246": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WASHINGTON ISLAND"
  },
  "54247": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WHITELAW"
  },
  "54301": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GREEN BAY"
  },
  "54302": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GREEN BAY"
  },
  "54303": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GREEN BAY"
  },
  "54304": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GREEN BAY"
  },
  "54311": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GREEN BAY"
  },
  "54313": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GREEN BAY"
  },
  "54401": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WAUSAU"
  },
  "54403": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WAUSAU"
  },
  "54405": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ABBOTSFORD"
  },
  "54406": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "AMHERST"
  },
  "54407": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "AMHERST JUNCTION"
  },
  "54408": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ANIWA"
  },
  "54409": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ANTIGO"
  },
  "54410": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ARPIN"
  },
  "54411": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ATHENS"
  },
  "54412": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "AUBURNDALE"
  },
  "54413": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BABCOCK"
  },
  "54414": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BIRNAMWOOD"
  },
  "54416": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BOWLER"
  },
  "54417": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BROKAW"
  },
  "54418": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BRYANT"
  },
  "54420": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CHILI"
  },
  "54421": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "COLBY"
  },
  "54422": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CURTISS"
  },
  "54423": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CUSTER"
  },
  "54424": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DEERBROOK"
  },
  "54425": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DORCHESTER"
  },
  "54426": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "EDGAR"
  },
  "54427": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ELAND"
  },
  "54428": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ELCHO"
  },
  "54430": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ELTON"
  },
  "54433": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GILMAN"
  },
  "54435": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GLEASON"
  },
  "54436": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GRANTON"
  },
  "54437": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GREENWOOD"
  },
  "54440": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HATLEY"
  },
  "54441": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HEWITT"
  },
  "54442": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "IRMA"
  },
  "54443": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "JUNCTION CITY"
  },
  "54446": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LOYAL"
  },
  "54447": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LUBLIN"
  },
  "54448": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MARATHON"
  },
  "54449": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MARSHFIELD"
  },
  "54451": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MEDFORD"
  },
  "54452": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MERRILL"
  },
  "54454": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILLADORE"
  },
  "54455": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MOSINEE"
  },
  "54456": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NEILLSVILLE"
  },
  "54457": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NEKOOSA"
  },
  "54459": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OGEMA"
  },
  "54460": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OWEN"
  },
  "54462": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PEARSON"
  },
  "54463": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PELICAN LAKE"
  },
  "54465": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PICKEREL"
  },
  "54466": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PITTSVILLE"
  },
  "54467": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PLOVER"
  },
  "54469": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PORT EDWARDS"
  },
  "54470": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RIB LAKE"
  },
  "54471": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RINGLE"
  },
  "54473": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ROSHOLT"
  },
  "54474": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ROTHSCHILD"
  },
  "54475": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RUDOLPH"
  },
  "54476": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SCHOFIELD"
  },
  "54479": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SPENCER"
  },
  "54480": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "STETSONVILLE"
  },
  "54481": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "STEVENS POINT"
  },
  "54484": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "STRATFORD"
  },
  "54485": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SUMMIT LAKE"
  },
  "54486": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "TIGERTON"
  },
  "54487": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "TOMAHAWK"
  },
  "54488": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "UNITY"
  },
  "54489": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "VESPER"
  },
  "54490": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WESTBORO"
  },
  "54491": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WHITE LAKE"
  },
  "54493": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WILLARD"
  },
  "54494": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WISCONSIN RAPIDS"
  },
  "54495": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WISCONSIN RAPIDS"
  },
  "54498": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WITHEE"
  },
  "54499": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WITTENBERG"
  },
  "54501": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RHINELANDER"
  },
  "54511": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ARGONNE"
  },
  "54512": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BOULDER JUNCTION"
  },
  "54513": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BRANTWOOD"
  },
  "54514": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BUTTERNUT"
  },
  "54515": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CATAWBA"
  },
  "54517": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CLAM LAKE"
  },
  "54519": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CONOVER"
  },
  "54520": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CRANDON"
  },
  "54521": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "EAGLE RIVER"
  },
  "54524": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FIFIELD"
  },
  "54525": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GILE"
  },
  "54526": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GLEN FLORA"
  },
  "54527": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GLIDDEN"
  },
  "54529": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HARSHAW"
  },
  "54530": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HAWKINS"
  },
  "54531": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HAZELHURST"
  },
  "54534": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HURLEY"
  },
  "54536": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "IRON BELT"
  },
  "54537": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "KENNAN"
  },
  "54538": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LAC DU FLAMBEAU"
  },
  "54539": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LAKE TOMAHAWK"
  },
  "54540": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LAND O LAKES"
  },
  "54541": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LAONA"
  },
  "54542": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LONG LAKE"
  },
  "54543": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MC NAUGHTON"
  },
  "54545": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MANITOWISH WATERS"
  },
  "54546": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MELLEN"
  },
  "54547": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MERCER"
  },
  "54548": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MINOCQUA"
  },
  "54550": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MONTREAL"
  },
  "54552": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PARK FALLS"
  },
  "54554": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PHELPS"
  },
  "54555": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PHILLIPS"
  },
  "54556": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PRENTICE"
  },
  "54557": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PRESQUE ISLE"
  },
  "54558": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SAINT GERMAIN"
  },
  "54559": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SAXON"
  },
  "54560": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SAYNER"
  },
  "54561": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "STAR LAKE"
  },
  "54562": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "THREE LAKES"
  },
  "54563": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "TONY"
  },
  "54564": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "TRIPOLI"
  },
  "54565": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "UPSON"
  },
  "54566": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WABENO"
  },
  "54568": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WOODRUFF"
  },
  "54601": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LA CROSSE"
  },
  "54603": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LA CROSSE"
  },
  "54610": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ALMA"
  },
  "54611": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ALMA CENTER"
  },
  "54612": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ARCADIA"
  },
  "54613": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ARKDALE"
  },
  "54614": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BANGOR"
  },
  "54615": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BLACK RIVER FALLS"
  },
  "54616": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BLAIR"
  },
  "54618": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CAMP DOUGLAS"
  },
  "54619": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CASHTON"
  },
  "54621": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CHASEBURG"
  },
  "54622": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "COCHRANE"
  },
  "54623": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "COON VALLEY"
  },
  "54624": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DE SOTO"
  },
  "54625": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DODGE"
  },
  "54626": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "EASTMAN"
  },
  "54627": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ETTRICK"
  },
  "54628": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FERRYVILLE"
  },
  "54629": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FOUNTAIN CITY"
  },
  "54630": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GALESVILLE"
  },
  "54631": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GAYS MILLS"
  },
  "54632": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GENOA"
  },
  "54634": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HILLSBORO"
  },
  "54635": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HIXTON"
  },
  "54636": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HOLMEN"
  },
  "54637": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HUSTLER"
  },
  "54638": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "KENDALL"
  },
  "54639": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LA FARGE"
  },
  "54640": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LYNXVILLE"
  },
  "54642": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MELROSE"
  },
  "54644": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MINDORO"
  },
  "54645": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MOUNT STERLING"
  },
  "54646": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NECEDAH"
  },
  "54648": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NORWALK"
  },
  "54650": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ONALASKA"
  },
  "54651": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ONTARIO"
  },
  "54652": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "READSTOWN"
  },
  "54653": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ROCKLAND"
  },
  "54654": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SENECA"
  },
  "54655": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SOLDIERS GROVE"
  },
  "54656": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SPARTA"
  },
  "54657": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "STEUBEN"
  },
  "54658": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "STODDARD"
  },
  "54659": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "TAYLOR"
  },
  "54660": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "TOMAH"
  },
  "54661": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "TREMPEALEAU"
  },
  "54664": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "VIOLA"
  },
  "54665": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "VIROQUA"
  },
  "54666": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WARRENS"
  },
  "54667": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WESTBY"
  },
  "54669": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WEST SALEM"
  },
  "54670": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WILTON"
  },
  "54701": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "EAU CLAIRE"
  },
  "54703": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "EAU CLAIRE"
  },
  "54720": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ALTOONA"
  },
  "54721": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ARKANSAW"
  },
  "54722": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "AUGUSTA"
  },
  "54723": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BAY CITY"
  },
  "54724": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BLOOMER"
  },
  "54725": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BOYCEVILLE"
  },
  "54726": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BOYD"
  },
  "54727": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CADOTT"
  },
  "54728": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CHETEK"
  },
  "54729": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CHIPPEWA FALLS"
  },
  "54730": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "COLFAX"
  },
  "54731": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CONRATH"
  },
  "54732": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CORNELL"
  },
  "54733": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DALLAS"
  },
  "54734": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DOWNING"
  },
  "54736": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DURAND"
  },
  "54737": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "EAU GALLE"
  },
  "54738": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ELEVA"
  },
  "54739": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ELK MOUND"
  },
  "54740": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ELMWOOD"
  },
  "54741": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FAIRCHILD"
  },
  "54742": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FALL CREEK"
  },
  "54745": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HOLCOMBE"
  },
  "54746": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HUMBIRD"
  },
  "54747": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "INDEPENDENCE"
  },
  "54748": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "JIM FALLS"
  },
  "54749": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "KNAPP"
  },
  "54750": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MAIDEN ROCK"
  },
  "54751": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MENOMONIE"
  },
  "54754": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MERRILLAN"
  },
  "54755": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MONDOVI"
  },
  "54756": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NELSON"
  },
  "54757": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NEW AUBURN"
  },
  "54758": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OSSEO"
  },
  "54759": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PEPIN"
  },
  "54760": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PIGEON FALLS"
  },
  "54761": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PLUM CITY"
  },
  "54762": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PRAIRIE FARM"
  },
  "54763": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RIDGELAND"
  },
  "54766": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SHELDON"
  },
  "54767": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SPRING VALLEY"
  },
  "54768": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "STANLEY"
  },
  "54769": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "STOCKHOLM"
  },
  "54770": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "STRUM"
  },
  "54771": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "THORP"
  },
  "54772": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WHEELER"
  },
  "54773": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WHITEHALL"
  },
  "54801": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SPOONER"
  },
  "54805": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ALMENA"
  },
  "54806": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ASHLAND"
  },
  "54810": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BALSAM LAKE"
  },
  "54812": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BARRON"
  },
  "54813": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BARRONETT"
  },
  "54814": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BAYFIELD"
  },
  "54817": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BIRCHWOOD"
  },
  "54819": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BRUCE"
  },
  "54820": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BRULE"
  },
  "54821": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CABLE"
  },
  "54822": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CAMERON"
  },
  "54824": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CENTURIA"
  },
  "54826": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "COMSTOCK"
  },
  "54827": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CORNUCOPIA"
  },
  "54828": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "COUDERAY"
  },
  "54829": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CUMBERLAND"
  },
  "54830": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DANBURY"
  },
  "54832": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "DRUMMOND"
  },
  "54834": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "EDGEWATER"
  },
  "54835": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "EXELAND"
  },
  "54836": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FOXBORO"
  },
  "54837": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FREDERIC"
  },
  "54838": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GORDON"
  },
  "54839": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GRAND VIEW"
  },
  "54840": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GRANTSBURG"
  },
  "54841": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HAUGEN"
  },
  "54843": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HAYWARD"
  },
  "54844": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HERBSTER"
  },
  "54845": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HERTEL"
  },
  "54846": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HIGH BRIDGE"
  },
  "54847": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "IRON RIVER"
  },
  "54848": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LADYSMITH"
  },
  "54849": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LAKE NEBAGAMON"
  },
  "54850": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LA POINTE"
  },
  "54853": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LUCK"
  },
  "54854": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MAPLE"
  },
  "54855": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MARENGO"
  },
  "54856": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MASON"
  },
  "54857": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MIKANA"
  },
  "54858": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MILLTOWN"
  },
  "54859": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MINONG"
  },
  "54861": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ODANAH"
  },
  "54862": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OJIBWA"
  },
  "54864": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "POPLAR"
  },
  "54865": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PORT WING"
  },
  "54867": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RADISSON"
  },
  "54868": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RICE LAKE"
  },
  "54870": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SARONA"
  },
  "54871": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SHELL LAKE"
  },
  "54872": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SIREN"
  },
  "54873": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SOLON SPRINGS"
  },
  "54874": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SOUTH RANGE"
  },
  "54875": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SPRINGBROOK"
  },
  "54876": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "STONE LAKE"
  },
  "54880": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SUPERIOR"
  },
  "54888": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "TREGO"
  },
  "54889": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "TURTLE LAKE"
  },
  "54891": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WASHBURN"
  },
  "54893": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WEBSTER"
  },
  "54895": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WEYERHAEUSER"
  },
  "54896": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WINTER"
  },
  "54901": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OSHKOSH"
  },
  "54902": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OSHKOSH"
  },
  "54904": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OSHKOSH"
  },
  "54909": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ALMOND"
  },
  "54911": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "APPLETON"
  },
  "54913": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "APPLETON"
  },
  "54914": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "APPLETON"
  },
  "54915": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "APPLETON"
  },
  "54921": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BANCROFT"
  },
  "54922": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BEAR CREEK"
  },
  "54923": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BERLIN"
  },
  "54927": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "BUTTE DES MORTS"
  },
  "54928": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CAROLINE"
  },
  "54929": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "CLINTONVILLE"
  },
  "54930": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "COLOMA"
  },
  "54932": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ELDORADO"
  },
  "54933": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "EMBARRASS"
  },
  "54934": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "EUREKA"
  },
  "54935": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FOND DU LAC"
  },
  "54937": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FOND DU LAC"
  },
  "54940": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "FREMONT"
  },
  "54941": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GREEN LAKE"
  },
  "54942": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "GREENVILLE"
  },
  "54943": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HANCOCK"
  },
  "54944": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "HORTONVILLE"
  },
  "54945": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "IOLA"
  },
  "54947": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LARSEN"
  },
  "54948": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "LEOPOLIS"
  },
  "54949": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MANAWA"
  },
  "54950": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MARION"
  },
  "54952": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "MENASHA"
  },
  "54956": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NEENAH"
  },
  "54960": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NESHKORO"
  },
  "54961": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "NEW LONDON"
  },
  "54962": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OGDENSBURG"
  },
  "54963": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "OMRO"
  },
  "54964": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PICKETT"
  },
  "54965": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PINE RIVER"
  },
  "54966": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PLAINFIELD"
  },
  "54967": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "POY SIPPI"
  },
  "54968": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "PRINCETON"
  },
  "54970": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "REDGRANITE"
  },
  "54971": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "RIPON"
  },
  "54974": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "ROSENDALE"
  },
  "54976": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SAXEVILLE"
  },
  "54977": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "SCANDINAVIA"
  },
  "54978": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "TILLEDA"
  },
  "54979": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "VAN DYNE"
  },
  "54980": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WAUKAU"
  },
  "54981": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WAUPACA"
  },
  "54982": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WAUTOMA"
  },
  "54983": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WEYAUWEGA"
  },
  "54984": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WILD ROSE"
  },
  "54986": {
    stateCode: "WI",
    stateName: "Wisconsin",
    city: "WINNECONNE"
  },
  "55001": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "AFTON"
  },
  "55003": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BAYPORT"
  },
  "55005": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BETHEL"
  },
  "55006": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BRAHAM"
  },
  "55007": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BROOK PARK"
  },
  "55008": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CAMBRIDGE"
  },
  "55009": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CANNON FALLS"
  },
  "55010": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CASTLE ROCK"
  },
  "55011": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CEDAR"
  },
  "55012": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CENTER CITY"
  },
  "55013": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CHISAGO CITY"
  },
  "55014": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CIRCLE PINES"
  },
  "55016": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "COTTAGE GROVE"
  },
  "55017": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DALBO"
  },
  "55018": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DENNISON"
  },
  "55019": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DUNDAS"
  },
  "55020": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ELKO"
  },
  "55021": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FARIBAULT"
  },
  "55024": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FARMINGTON"
  },
  "55025": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FOREST LAKE"
  },
  "55026": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FRONTENAC"
  },
  "55027": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GOODHUE"
  },
  "55030": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GRASSTON"
  },
  "55031": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HAMPTON"
  },
  "55032": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HARRIS"
  },
  "55033": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HASTINGS"
  },
  "55036": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HENRIETTE"
  },
  "55037": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HINCKLEY"
  },
  "55038": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HUGO"
  },
  "55040": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ISANTI"
  },
  "55041": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LAKE CITY"
  },
  "55042": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LAKE ELMO"
  },
  "55043": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LAKELAND"
  },
  "55044": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LAKEVILLE"
  },
  "55045": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LINDSTROM"
  },
  "55046": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LONSDALE"
  },
  "55047": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MARINE ON SAINT CROIX"
  },
  "55049": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MEDFORD"
  },
  "55051": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MORA"
  },
  "55052": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MORRISTOWN"
  },
  "55053": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NERSTRAND"
  },
  "55055": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NEWPORT"
  },
  "55056": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NORTH BRANCH"
  },
  "55057": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NORTHFIELD"
  },
  "55060": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "OWATONNA"
  },
  "55063": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PINE CITY"
  },
  "55065": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "RANDOLPH"
  },
  "55066": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "RED WING"
  },
  "55068": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ROSEMOUNT"
  },
  "55069": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "RUSH CITY"
  },
  "55070": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT FRANCIS"
  },
  "55071": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL PARK"
  },
  "55072": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SANDSTONE"
  },
  "55073": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SCANDIA"
  },
  "55074": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SHAFER"
  },
  "55075": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SOUTH SAINT PAUL"
  },
  "55076": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "INVER GROVE HEIGHTS"
  },
  "55077": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "INVER GROVE HEIGHTS"
  },
  "55079": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "STACY"
  },
  "55080": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "STANCHFIELD"
  },
  "55082": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "STILLWATER"
  },
  "55084": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TAYLORS FALLS"
  },
  "55085": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "VERMILLION"
  },
  "55087": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WARSAW"
  },
  "55088": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WEBSTER"
  },
  "55089": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WELCH"
  },
  "55090": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WILLERNIE"
  },
  "55092": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WYOMING"
  },
  "55101": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55102": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55103": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55104": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55105": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55106": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55107": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55108": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55109": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55110": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55112": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55113": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55114": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55115": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55116": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55117": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55118": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55119": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55120": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55121": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55122": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55123": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55124": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55125": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55126": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55127": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55128": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55129": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PAUL"
  },
  "55150": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MENDOTA"
  },
  "55301": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ALBERTVILLE"
  },
  "55302": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ANNANDALE"
  },
  "55303": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ANOKA"
  },
  "55304": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ANDOVER"
  },
  "55305": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HOPKINS"
  },
  "55306": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BURNSVILLE"
  },
  "55307": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ARLINGTON"
  },
  "55308": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BECKER"
  },
  "55309": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BIG LAKE"
  },
  "55310": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BIRD ISLAND"
  },
  "55311": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "OSSEO"
  },
  "55312": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BROWNTON"
  },
  "55313": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BUFFALO"
  },
  "55314": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BUFFALO LAKE"
  },
  "55315": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CARVER"
  },
  "55316": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CHAMPLIN"
  },
  "55317": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CHANHASSEN"
  },
  "55318": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CHASKA"
  },
  "55319": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CLEAR LAKE"
  },
  "55320": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CLEARWATER"
  },
  "55321": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "COKATO"
  },
  "55322": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "COLOGNE"
  },
  "55324": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DARWIN"
  },
  "55325": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DASSEL"
  },
  "55327": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DAYTON"
  },
  "55328": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DELANO"
  },
  "55329": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EDEN VALLEY"
  },
  "55330": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ELK RIVER"
  },
  "55331": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EXCELSIOR"
  },
  "55332": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FAIRFAX"
  },
  "55333": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FRANKLIN"
  },
  "55334": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GAYLORD"
  },
  "55335": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GIBBON"
  },
  "55336": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GLENCOE"
  },
  "55337": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BURNSVILLE"
  },
  "55338": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GREEN ISLE"
  },
  "55339": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HAMBURG"
  },
  "55340": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HAMEL"
  },
  "55341": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HANOVER"
  },
  "55342": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HECTOR"
  },
  "55343": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HOPKINS"
  },
  "55344": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EDEN PRAIRIE"
  },
  "55345": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNETONKA"
  },
  "55346": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EDEN PRAIRIE"
  },
  "55347": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EDEN PRAIRIE"
  },
  "55349": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HOWARD LAKE"
  },
  "55350": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HUTCHINSON"
  },
  "55352": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "JORDAN"
  },
  "55353": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KIMBALL"
  },
  "55354": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LESTER PRAIRIE"
  },
  "55355": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LITCHFIELD"
  },
  "55356": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LONG LAKE"
  },
  "55357": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LORETTO"
  },
  "55358": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MAPLE LAKE"
  },
  "55359": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MAPLE PLAIN"
  },
  "55360": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MAYER"
  },
  "55362": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MONTICELLO"
  },
  "55363": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MONTROSE"
  },
  "55364": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MOUND"
  },
  "55367": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NEW GERMANY"
  },
  "55368": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NORWOOD YOUNG AMERICA"
  },
  "55369": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "OSSEO"
  },
  "55370": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PLATO"
  },
  "55371": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PRINCETON"
  },
  "55372": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PRIOR LAKE"
  },
  "55373": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ROCKFORD"
  },
  "55374": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ROGERS"
  },
  "55375": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT BONIFACIUS"
  },
  "55376": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT MICHAEL"
  },
  "55378": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAVAGE"
  },
  "55379": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SHAKOPEE"
  },
  "55381": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SILVER LAKE"
  },
  "55382": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SOUTH HAVEN"
  },
  "55384": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SPRING PARK"
  },
  "55385": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "STEWART"
  },
  "55386": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "VICTORIA"
  },
  "55387": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WACONIA"
  },
  "55388": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WATERTOWN"
  },
  "55389": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WATKINS"
  },
  "55390": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WAVERLY"
  },
  "55391": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WAYZATA"
  },
  "55395": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WINSTED"
  },
  "55396": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WINTHROP"
  },
  "55397": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "YOUNG AMERICA"
  },
  "55398": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ZIMMERMAN"
  },
  "55401": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55402": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55403": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55404": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55405": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55406": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55407": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55408": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55409": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55410": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55411": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55412": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55413": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55414": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55415": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55416": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55417": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55418": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55419": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55420": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55421": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55422": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55423": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55424": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55425": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55426": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55427": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55428": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55429": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55430": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55431": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55432": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55433": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55434": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55435": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55436": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55437": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55438": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55439": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55441": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55442": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55443": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55444": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55445": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55446": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55447": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55448": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55449": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55454": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55455": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEAPOLIS"
  },
  "55601": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BEAVER BAY"
  },
  "55602": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BRIMSON"
  },
  "55603": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FINLAND"
  },
  "55604": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GRAND MARAIS"
  },
  "55605": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GRAND PORTAGE"
  },
  "55606": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HOVLAND"
  },
  "55607": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ISABELLA"
  },
  "55609": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KNIFE RIVER"
  },
  "55612": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LUTSEN"
  },
  "55613": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SCHROEDER"
  },
  "55614": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SILVER BAY"
  },
  "55615": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TOFTE"
  },
  "55616": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TWO HARBORS"
  },
  "55702": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ALBORN"
  },
  "55703": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ANGORA"
  },
  "55704": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ASKOV"
  },
  "55705": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "AURORA"
  },
  "55706": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BABBITT"
  },
  "55707": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BARNUM"
  },
  "55708": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BIWABIK"
  },
  "55709": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BOVEY"
  },
  "55710": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BRITT"
  },
  "55711": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BROOKSTON"
  },
  "55712": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BRUNO"
  },
  "55713": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BUHL"
  },
  "55716": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CALUMET"
  },
  "55717": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CANYON"
  },
  "55718": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CARLTON"
  },
  "55719": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CHISHOLM"
  },
  "55720": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CLOQUET"
  },
  "55721": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "COHASSET"
  },
  "55722": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "COLERAINE"
  },
  "55723": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "COOK"
  },
  "55724": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "COTTON"
  },
  "55725": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CRANE LAKE"
  },
  "55726": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CROMWELL"
  },
  "55731": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ELY"
  },
  "55732": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EMBARRASS"
  },
  "55733": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ESKO"
  },
  "55734": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EVELETH"
  },
  "55735": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FINLAYSON"
  },
  "55736": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FLOODWOOD"
  },
  "55738": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FORBES"
  },
  "55741": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GILBERT"
  },
  "55742": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GOODLAND"
  },
  "55744": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GRAND RAPIDS"
  },
  "55746": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HIBBING"
  },
  "55748": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HILL CITY"
  },
  "55749": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HOLYOKE"
  },
  "55750": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HOYT LAKES"
  },
  "55751": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "IRON"
  },
  "55752": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "JACOBSON"
  },
  "55753": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KEEWATIN"
  },
  "55756": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KERRICK"
  },
  "55757": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KETTLE RIVER"
  },
  "55758": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KINNEY"
  },
  "55760": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MCGREGOR"
  },
  "55763": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MAKINEN"
  },
  "55764": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MARBLE"
  },
  "55765": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MEADOWLANDS"
  },
  "55767": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MOOSE LAKE"
  },
  "55768": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MOUNTAIN IRON"
  },
  "55769": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NASHWAUK"
  },
  "55771": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ORR"
  },
  "55775": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PENGILLY"
  },
  "55779": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAGINAW"
  },
  "55780": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAWYER"
  },
  "55781": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SIDE LAKE"
  },
  "55782": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SOUDAN"
  },
  "55783": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "STURGEON LAKE"
  },
  "55784": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SWAN RIVER"
  },
  "55785": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SWATARA"
  },
  "55786": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TACONITE"
  },
  "55787": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TAMARACK"
  },
  "55790": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TOWER"
  },
  "55792": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "VIRGINIA"
  },
  "55793": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WARBA"
  },
  "55795": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WILLOW RIVER"
  },
  "55796": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WINTON"
  },
  "55797": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WRENSHALL"
  },
  "55798": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WRIGHT"
  },
  "55802": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DULUTH"
  },
  "55803": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DULUTH"
  },
  "55804": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DULUTH"
  },
  "55805": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DULUTH"
  },
  "55806": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DULUTH"
  },
  "55807": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DULUTH"
  },
  "55808": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DULUTH"
  },
  "55810": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DULUTH"
  },
  "55811": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DULUTH"
  },
  "55812": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DULUTH"
  },
  "55901": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ROCHESTER"
  },
  "55902": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ROCHESTER"
  },
  "55904": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ROCHESTER"
  },
  "55906": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ROCHESTER"
  },
  "55909": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ADAMS"
  },
  "55910": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ALTURA"
  },
  "55912": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "AUSTIN"
  },
  "55917": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BLOOMING PRAIRIE"
  },
  "55918": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BROWNSDALE"
  },
  "55919": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BROWNSVILLE"
  },
  "55920": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BYRON"
  },
  "55921": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CALEDONIA"
  },
  "55922": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CANTON"
  },
  "55923": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CHATFIELD"
  },
  "55924": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CLAREMONT"
  },
  "55925": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DAKOTA"
  },
  "55926": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DEXTER"
  },
  "55927": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DODGE CENTER"
  },
  "55929": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DOVER"
  },
  "55931": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EITZEN"
  },
  "55932": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ELGIN"
  },
  "55933": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ELKTON"
  },
  "55934": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EYOTA"
  },
  "55935": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FOUNTAIN"
  },
  "55936": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GRAND MEADOW"
  },
  "55939": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HARMONY"
  },
  "55940": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HAYFIELD"
  },
  "55941": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HOKAH"
  },
  "55943": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HOUSTON"
  },
  "55944": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KASSON"
  },
  "55945": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KELLOGG"
  },
  "55946": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KENYON"
  },
  "55947": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LA CRESCENT"
  },
  "55949": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LANESBORO"
  },
  "55950": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LANSING"
  },
  "55951": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LE ROY"
  },
  "55952": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LEWISTON"
  },
  "55953": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LYLE"
  },
  "55954": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MABEL"
  },
  "55955": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MANTORVILLE"
  },
  "55956": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MAZEPPA"
  },
  "55957": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MILLVILLE"
  },
  "55959": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNESOTA CITY"
  },
  "55960": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ORONOCO"
  },
  "55961": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "OSTRANDER"
  },
  "55962": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PETERSON"
  },
  "55963": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PINE ISLAND"
  },
  "55964": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PLAINVIEW"
  },
  "55965": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PRESTON"
  },
  "55967": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "RACINE"
  },
  "55969": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ROLLINGSTONE"
  },
  "55970": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ROSE CREEK"
  },
  "55971": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "RUSHFORD"
  },
  "55972": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT CHARLES"
  },
  "55973": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SARGEANT"
  },
  "55974": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SPRING GROVE"
  },
  "55975": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SPRING VALLEY"
  },
  "55976": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "STEWARTVILLE"
  },
  "55977": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TAOPI"
  },
  "55979": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "UTICA"
  },
  "55981": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WABASHA"
  },
  "55982": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WALTHAM"
  },
  "55983": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WANAMINGO"
  },
  "55985": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WEST CONCORD"
  },
  "55987": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WINONA"
  },
  "55988": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "STOCKTON"
  },
  "55990": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WYKOFF"
  },
  "55991": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ZUMBRO FALLS"
  },
  "55992": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ZUMBROTA"
  },
  "56001": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MANKATO"
  },
  "56003": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MANKATO"
  },
  "56007": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ALBERT LEA"
  },
  "56009": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ALDEN"
  },
  "56010": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "AMBOY"
  },
  "56011": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BELLE PLAINE"
  },
  "56013": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BLUE EARTH"
  },
  "56014": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BRICELYN"
  },
  "56016": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CLARKS GROVE"
  },
  "56017": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CLEVELAND"
  },
  "56019": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "COMFREY"
  },
  "56020": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CONGER"
  },
  "56021": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "COURTLAND"
  },
  "56022": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DARFUR"
  },
  "56023": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DELAVAN"
  },
  "56024": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EAGLE LAKE"
  },
  "56025": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EASTON"
  },
  "56026": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ELLENDALE"
  },
  "56027": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ELMORE"
  },
  "56028": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ELYSIAN"
  },
  "56029": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EMMONS"
  },
  "56031": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FAIRMONT"
  },
  "56032": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FREEBORN"
  },
  "56033": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FROST"
  },
  "56034": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GARDEN CITY"
  },
  "56035": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GENEVA"
  },
  "56036": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GLENVILLE"
  },
  "56037": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GOOD THUNDER"
  },
  "56039": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GRANADA"
  },
  "56041": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HANSKA"
  },
  "56042": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HARTLAND"
  },
  "56043": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HAYWARD"
  },
  "56044": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HENDERSON"
  },
  "56045": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HOLLANDALE"
  },
  "56048": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "JANESVILLE"
  },
  "56050": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KASOTA"
  },
  "56051": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KIESTER"
  },
  "56052": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KILKENNY"
  },
  "56054": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LAFAYETTE"
  },
  "56055": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LAKE CRYSTAL"
  },
  "56056": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LA SALLE"
  },
  "56057": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LE CENTER"
  },
  "56058": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LE SUEUR"
  },
  "56060": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LEWISVILLE"
  },
  "56062": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MADELIA"
  },
  "56063": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MADISON LAKE"
  },
  "56065": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MAPLETON"
  },
  "56068": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNESOTA LAKE"
  },
  "56069": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MONTGOMERY"
  },
  "56071": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NEW PRAGUE"
  },
  "56072": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NEW RICHLAND"
  },
  "56073": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NEW ULM"
  },
  "56074": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NICOLLET"
  },
  "56075": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NORTHROP"
  },
  "56078": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PEMBERTON"
  },
  "56080": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT CLAIR"
  },
  "56081": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT JAMES"
  },
  "56082": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT PETER"
  },
  "56083": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SANBORN"
  },
  "56085": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SLEEPY EYE"
  },
  "56087": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SPRINGFIELD"
  },
  "56088": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TRUMAN"
  },
  "56089": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TWIN LAKES"
  },
  "56090": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "VERNON CENTER"
  },
  "56091": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WALDORF"
  },
  "56093": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WASECA"
  },
  "56096": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WATERVILLE"
  },
  "56097": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WELLS"
  },
  "56098": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WINNEBAGO"
  },
  "56101": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WINDOM"
  },
  "56110": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ADRIAN"
  },
  "56111": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ALPHA"
  },
  "56113": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ARCO"
  },
  "56114": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "AVOCA"
  },
  "56115": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BALATON"
  },
  "56116": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BEAVER CREEK"
  },
  "56117": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BIGELOW"
  },
  "56118": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BINGHAM LAKE"
  },
  "56119": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BREWSTER"
  },
  "56120": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BUTTERFIELD"
  },
  "56121": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CEYLON"
  },
  "56122": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CHANDLER"
  },
  "56123": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CURRIE"
  },
  "56127": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DUNNELL"
  },
  "56128": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EDGERTON"
  },
  "56129": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ELLSWORTH"
  },
  "56131": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FULDA"
  },
  "56132": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GARVIN"
  },
  "56134": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HARDWICK"
  },
  "56136": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HENDRICKS"
  },
  "56137": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HERON LAKE"
  },
  "56138": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HILLS"
  },
  "56139": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HOLLAND"
  },
  "56140": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "IHLEN"
  },
  "56141": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "IONA"
  },
  "56142": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "IVANHOE"
  },
  "56143": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "JACKSON"
  },
  "56144": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "JASPER"
  },
  "56145": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "JEFFERS"
  },
  "56147": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KENNETH"
  },
  "56149": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LAKE BENTON"
  },
  "56150": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LAKEFIELD"
  },
  "56151": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LAKE WILSON"
  },
  "56152": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LAMBERTON"
  },
  "56153": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LEOTA"
  },
  "56155": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LISMORE"
  },
  "56156": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LUVERNE"
  },
  "56157": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LYND"
  },
  "56158": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MAGNOLIA"
  },
  "56159": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MOUNTAIN LAKE"
  },
  "56160": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ODIN"
  },
  "56161": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "OKABENA"
  },
  "56162": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ORMSBY"
  },
  "56164": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PIPESTONE"
  },
  "56165": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "READING"
  },
  "56166": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "REVERE"
  },
  "56167": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ROUND LAKE"
  },
  "56168": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "RUSHMORE"
  },
  "56169": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "RUSSELL"
  },
  "56170": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "RUTHTON"
  },
  "56171": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SHERBURN"
  },
  "56172": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SLAYTON"
  },
  "56173": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "STEEN"
  },
  "56174": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "STORDEN"
  },
  "56175": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TRACY"
  },
  "56176": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TRIMONT"
  },
  "56177": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TROSKY"
  },
  "56178": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TYLER"
  },
  "56180": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WALNUT GROVE"
  },
  "56181": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WELCOME"
  },
  "56183": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WESTBROOK"
  },
  "56185": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WILMONT"
  },
  "56186": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WOODSTOCK"
  },
  "56187": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WORTHINGTON"
  },
  "56201": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WILLMAR"
  },
  "56207": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ALBERTA"
  },
  "56208": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "APPLETON"
  },
  "56209": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ATWATER"
  },
  "56211": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BEARDSLEY"
  },
  "56212": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BELLINGHAM"
  },
  "56214": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BELVIEW"
  },
  "56215": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BENSON"
  },
  "56216": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BLOMKEST"
  },
  "56218": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BOYD"
  },
  "56219": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BROWNS VALLEY"
  },
  "56220": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CANBY"
  },
  "56221": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CHOKIO"
  },
  "56222": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CLARA CITY"
  },
  "56223": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CLARKFIELD"
  },
  "56224": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CLEMENTS"
  },
  "56225": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CLINTON"
  },
  "56226": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CLONTARF"
  },
  "56227": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CORRELL"
  },
  "56228": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "COSMOS"
  },
  "56229": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "COTTONWOOD"
  },
  "56230": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DANUBE"
  },
  "56231": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DANVERS"
  },
  "56232": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DAWSON"
  },
  "56235": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DONNELLY"
  },
  "56236": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DUMONT"
  },
  "56237": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ECHO"
  },
  "56239": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GHENT"
  },
  "56240": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GRACEVILLE"
  },
  "56241": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GRANITE FALLS"
  },
  "56243": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GROVE CITY"
  },
  "56244": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HANCOCK"
  },
  "56245": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HANLEY FALLS"
  },
  "56248": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HERMAN"
  },
  "56249": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HOLLOWAY"
  },
  "56251": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KANDIYOHI"
  },
  "56252": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KERKHOVEN"
  },
  "56253": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LAKE LILLIAN"
  },
  "56255": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LUCAN"
  },
  "56256": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MADISON"
  },
  "56257": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MARIETTA"
  },
  "56258": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MARSHALL"
  },
  "56260": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MAYNARD"
  },
  "56262": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MILAN"
  },
  "56263": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MILROY"
  },
  "56264": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MINNEOTA"
  },
  "56265": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MONTEVIDEO"
  },
  "56266": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MORGAN"
  },
  "56267": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MORRIS"
  },
  "56270": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MORTON"
  },
  "56271": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MURDOCK"
  },
  "56273": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NEW LONDON"
  },
  "56274": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NORCROSS"
  },
  "56276": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ODESSA"
  },
  "56277": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "OLIVIA"
  },
  "56278": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ORTONVILLE"
  },
  "56279": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PENNOCK"
  },
  "56280": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PORTER"
  },
  "56281": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PRINSBURG"
  },
  "56282": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "RAYMOND"
  },
  "56283": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "REDWOOD FALLS"
  },
  "56284": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "RENVILLE"
  },
  "56285": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SACRED HEART"
  },
  "56287": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SEAFORTH"
  },
  "56288": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SPICER"
  },
  "56289": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SUNBURG"
  },
  "56291": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TAUNTON"
  },
  "56292": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "VESTA"
  },
  "56293": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WABASSO"
  },
  "56294": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WANDA"
  },
  "56295": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WATSON"
  },
  "56296": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WHEATON"
  },
  "56297": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WOOD LAKE"
  },
  "56301": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT CLOUD"
  },
  "56303": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT CLOUD"
  },
  "56304": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT CLOUD"
  },
  "56307": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ALBANY"
  },
  "56308": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ALEXANDRIA"
  },
  "56309": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ASHBY"
  },
  "56310": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "AVON"
  },
  "56311": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BARRETT"
  },
  "56312": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BELGRADE"
  },
  "56313": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BOCK"
  },
  "56314": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BOWLUS"
  },
  "56315": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BRANDON"
  },
  "56316": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BROOTEN"
  },
  "56318": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BURTRUM"
  },
  "56319": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CARLOS"
  },
  "56320": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "COLD SPRING"
  },
  "56323": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CYRUS"
  },
  "56324": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DALTON"
  },
  "56325": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ELROSA"
  },
  "56326": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EVANSVILLE"
  },
  "56327": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FARWELL"
  },
  "56328": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FLENSBURG"
  },
  "56329": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FOLEY"
  },
  "56330": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FORESTON"
  },
  "56331": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FREEPORT"
  },
  "56332": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GARFIELD"
  },
  "56334": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GLENWOOD"
  },
  "56335": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GREENWALD"
  },
  "56336": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GREY EAGLE"
  },
  "56338": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HILLMAN"
  },
  "56339": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HOFFMAN"
  },
  "56340": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HOLDINGFORD"
  },
  "56342": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ISLE"
  },
  "56343": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KENSINGTON"
  },
  "56345": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LITTLE FALLS"
  },
  "56347": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LONG PRAIRIE"
  },
  "56349": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LOWRY"
  },
  "56350": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MC GRATH"
  },
  "56352": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MELROSE"
  },
  "56353": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MILACA"
  },
  "56354": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MILTONA"
  },
  "56355": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NELSON"
  },
  "56356": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NEW MUNICH"
  },
  "56357": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "OAK PARK"
  },
  "56358": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "OGILVIE"
  },
  "56359": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ONAMIA"
  },
  "56360": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "OSAKIS"
  },
  "56361": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PARKERS PRAIRIE"
  },
  "56362": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PAYNESVILLE"
  },
  "56364": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PIERZ"
  },
  "56367": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "RICE"
  },
  "56368": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "RICHMOND"
  },
  "56369": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ROCKVILLE"
  },
  "56371": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ROSCOE"
  },
  "56373": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ROYALTON"
  },
  "56374": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT JOSEPH"
  },
  "56375": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT STEPHEN"
  },
  "56376": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT MARTIN"
  },
  "56377": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SARTELL"
  },
  "56378": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAUK CENTRE"
  },
  "56379": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAUK RAPIDS"
  },
  "56381": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "STARBUCK"
  },
  "56382": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SWANVILLE"
  },
  "56384": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "UPSALA"
  },
  "56385": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "VILLARD"
  },
  "56386": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WAHKON"
  },
  "56387": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WAITE PARK"
  },
  "56401": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BRAINERD"
  },
  "56425": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BAXTER"
  },
  "56431": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "AITKIN"
  },
  "56433": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "AKELEY"
  },
  "56434": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ALDRICH"
  },
  "56435": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BACKUS"
  },
  "56436": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BENEDICT"
  },
  "56437": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BERTHA"
  },
  "56438": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BROWERVILLE"
  },
  "56440": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CLARISSA"
  },
  "56441": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CROSBY"
  },
  "56442": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CROSSLAKE"
  },
  "56443": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CUSHING"
  },
  "56444": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DEERWOOD"
  },
  "56446": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EAGLE BEND"
  },
  "56447": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EMILY"
  },
  "56448": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FIFTY LAKES"
  },
  "56449": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FORT RIPLEY"
  },
  "56450": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GARRISON"
  },
  "56452": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HACKENSACK"
  },
  "56453": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HEWITT"
  },
  "56455": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "IRONTON"
  },
  "56456": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "JENKINS"
  },
  "56458": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LAKE GEORGE"
  },
  "56461": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LAPORTE"
  },
  "56464": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MENAHGA"
  },
  "56465": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MERRIFIELD"
  },
  "56466": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MOTLEY"
  },
  "56467": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NEVIS"
  },
  "56468": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NISSWA"
  },
  "56469": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PALISADE"
  },
  "56470": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PARK RAPIDS"
  },
  "56472": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PEQUOT LAKES"
  },
  "56473": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PILLAGER"
  },
  "56474": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PINE RIVER"
  },
  "56475": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "RANDALL"
  },
  "56477": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SEBEKA"
  },
  "56479": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "STAPLES"
  },
  "56481": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "VERNDALE"
  },
  "56482": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WADENA"
  },
  "56484": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WALKER"
  },
  "56501": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DETROIT LAKES"
  },
  "56510": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ADA"
  },
  "56511": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "AUDUBON"
  },
  "56514": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BARNESVILLE"
  },
  "56515": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BATTLE LAKE"
  },
  "56516": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BEJOU"
  },
  "56517": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BELTRAMI"
  },
  "56518": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BLUFFTON"
  },
  "56519": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BORUP"
  },
  "56520": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BRECKENRIDGE"
  },
  "56521": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CALLAWAY"
  },
  "56522": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CAMPBELL"
  },
  "56523": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CLIMAX"
  },
  "56524": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CLITHERALL"
  },
  "56525": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "COMSTOCK"
  },
  "56527": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DEER CREEK"
  },
  "56528": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DENT"
  },
  "56529": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DILWORTH"
  },
  "56531": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ELBOW LAKE"
  },
  "56533": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ELIZABETH"
  },
  "56534": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ERHARD"
  },
  "56535": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ERSKINE"
  },
  "56536": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FELTON"
  },
  "56537": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FERGUS FALLS"
  },
  "56540": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FERTILE"
  },
  "56542": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FOSSTON"
  },
  "56543": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FOXHOME"
  },
  "56544": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FRAZEE"
  },
  "56545": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GARY"
  },
  "56546": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GEORGETOWN"
  },
  "56547": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GLYNDON"
  },
  "56548": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HALSTAD"
  },
  "56549": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HAWLEY"
  },
  "56550": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HENDRUM"
  },
  "56551": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HENNING"
  },
  "56552": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HITTERDAL"
  },
  "56553": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KENT"
  },
  "56554": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LAKE PARK"
  },
  "56556": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MCINTOSH"
  },
  "56557": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MAHNOMEN"
  },
  "56560": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MOORHEAD"
  },
  "56565": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NASHUA"
  },
  "56566": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NAYTAHWAUSH"
  },
  "56567": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NEW YORK MILLS"
  },
  "56568": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NIELSVILLE"
  },
  "56569": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "OGEMA"
  },
  "56570": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "OSAGE"
  },
  "56571": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "OTTERTAIL"
  },
  "56572": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PELICAN RAPIDS"
  },
  "56573": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PERHAM"
  },
  "56574": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PERLEY"
  },
  "56575": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PONSFORD"
  },
  "56576": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "RICHVILLE"
  },
  "56578": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ROCHERT"
  },
  "56579": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ROTHSAY"
  },
  "56580": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SABIN"
  },
  "56581": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SHELLY"
  },
  "56583": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TINTAH"
  },
  "56584": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TWIN VALLEY"
  },
  "56585": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ULEN"
  },
  "56586": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "UNDERWOOD"
  },
  "56587": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "VERGAS"
  },
  "56588": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "VINING"
  },
  "56589": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WAUBUN"
  },
  "56590": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WENDELL"
  },
  "56592": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WINGER"
  },
  "56594": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WOLVERTON"
  },
  "56601": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BEMIDJI"
  },
  "56621": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BAGLEY"
  },
  "56623": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BAUDETTE"
  },
  "56626": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BENA"
  },
  "56627": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BIG FALLS"
  },
  "56628": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BIGFORK"
  },
  "56629": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BIRCHDALE"
  },
  "56630": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BLACKDUCK"
  },
  "56633": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CASS LAKE"
  },
  "56634": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CLEARBROOK"
  },
  "56636": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DEER RIVER"
  },
  "56637": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TALMOON"
  },
  "56639": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EFFIE"
  },
  "56641": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FEDERAL DAM"
  },
  "56644": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GONVICK"
  },
  "56646": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GULLY"
  },
  "56647": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HINES"
  },
  "56649": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "INTERNATIONAL FALLS"
  },
  "56650": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KELLIHER"
  },
  "56651": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LENGBY"
  },
  "56652": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LEONARD"
  },
  "56653": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LITTLEFORK"
  },
  "56654": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LOMAN"
  },
  "56655": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LONGVILLE"
  },
  "56657": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MARCELL"
  },
  "56659": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MAX"
  },
  "56660": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MIZPAH"
  },
  "56661": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NORTHOME"
  },
  "56662": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "OUTING"
  },
  "56663": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PENNINGTON"
  },
  "56666": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PONEMAH"
  },
  "56667": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PUPOSKY"
  },
  "56669": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KABETOGAMA"
  },
  "56670": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "REDBY"
  },
  "56671": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "REDLAKE"
  },
  "56672": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "REMER"
  },
  "56673": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ROOSEVELT"
  },
  "56676": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SHEVLIN"
  },
  "56678": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SOLWAY"
  },
  "56680": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SPRING LAKE"
  },
  "56681": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SQUAW LAKE"
  },
  "56683": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TENSTRIKE"
  },
  "56684": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "TRAIL"
  },
  "56685": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WASKISH"
  },
  "56686": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WILLIAMS"
  },
  "56687": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WILTON"
  },
  "56688": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WIRT"
  },
  "56701": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "THIEF RIVER FALLS"
  },
  "56710": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ALVARADO"
  },
  "56711": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ANGLE INLET"
  },
  "56713": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ARGYLE"
  },
  "56714": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BADGER"
  },
  "56715": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "BROOKS"
  },
  "56716": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "CROOKSTON"
  },
  "56720": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "DONALDSON"
  },
  "56721": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EAST GRAND FORKS"
  },
  "56722": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "EUCLID"
  },
  "56723": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "FISHER"
  },
  "56724": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GATZKE"
  },
  "56725": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GOODRIDGE"
  },
  "56726": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GREENBUSH"
  },
  "56727": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "GRYGLA"
  },
  "56728": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HALLOCK"
  },
  "56729": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HALMA"
  },
  "56731": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "HUMBOLDT"
  },
  "56732": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KARLSTAD"
  },
  "56733": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "KENNEDY"
  },
  "56734": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LAKE BRONSON"
  },
  "56735": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "LANCASTER"
  },
  "56736": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MENTOR"
  },
  "56737": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "MIDDLE RIVER"
  },
  "56738": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "NEWFOLDEN"
  },
  "56741": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "OAK ISLAND"
  },
  "56742": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "OKLEE"
  },
  "56744": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "OSLO"
  },
  "56748": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "PLUMMER"
  },
  "56750": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "RED LAKE FALLS"
  },
  "56751": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "ROSEAU"
  },
  "56754": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT HILAIRE"
  },
  "56755": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SAINT VINCENT"
  },
  "56756": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "SALOL"
  },
  "56757": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "STEPHEN"
  },
  "56758": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "STRANDQUIST"
  },
  "56759": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "STRATHCONA"
  },
  "56760": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "VIKING"
  },
  "56761": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WANNASKA"
  },
  "56762": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WARREN"
  },
  "56763": {
    stateCode: "MN",
    stateName: "Minnesota",
    city: "WARROAD"
  },
  "57001": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ALCESTER"
  },
  "57002": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "AURORA"
  },
  "57003": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BALTIC"
  },
  "57004": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BERESFORD"
  },
  "57005": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BRANDON"
  },
  "57006": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BROOKINGS"
  },
  "57010": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BURBANK"
  },
  "57012": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CANISTOTA"
  },
  "57013": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CANTON"
  },
  "57014": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CENTERVILLE"
  },
  "57015": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CHANCELLOR"
  },
  "57016": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CHESTER"
  },
  "57017": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "COLMAN"
  },
  "57018": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "COLTON"
  },
  "57020": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CROOKS"
  },
  "57021": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "DAVIS"
  },
  "57022": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "DELL RAPIDS"
  },
  "57024": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "EGAN"
  },
  "57025": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ELK POINT"
  },
  "57026": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ELKTON"
  },
  "57027": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "FAIRVIEW"
  },
  "57028": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "FLANDREAU"
  },
  "57029": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "FREEMAN"
  },
  "57030": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "GARRETSON"
  },
  "57031": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "GAYVILLE"
  },
  "57032": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HARRISBURG"
  },
  "57033": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HARTFORD"
  },
  "57034": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HUDSON"
  },
  "57035": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HUMBOLDT"
  },
  "57036": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HURLEY"
  },
  "57037": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "IRENE"
  },
  "57038": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "JEFFERSON"
  },
  "57039": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LENNOX"
  },
  "57040": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LESTERVILLE"
  },
  "57041": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LYONS"
  },
  "57042": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MADISON"
  },
  "57043": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MARION"
  },
  "57045": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MENNO"
  },
  "57046": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MISSION HILL"
  },
  "57047": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MONROE"
  },
  "57048": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MONTROSE"
  },
  "57049": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "NORTH SIOUX CITY"
  },
  "57050": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "NUNDA"
  },
  "57051": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "OLDHAM"
  },
  "57052": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "OLIVET"
  },
  "57053": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "PARKER"
  },
  "57054": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "RAMONA"
  },
  "57055": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "RENNER"
  },
  "57057": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "RUTLAND"
  },
  "57058": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SALEM"
  },
  "57059": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SCOTLAND"
  },
  "57061": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SINAI"
  },
  "57062": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SPRINGFIELD"
  },
  "57063": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "TABOR"
  },
  "57064": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "TEA"
  },
  "57065": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "TRENT"
  },
  "57066": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "TYNDALL"
  },
  "57067": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "UTICA"
  },
  "57068": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "VALLEY SPRINGS"
  },
  "57069": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "VERMILLION"
  },
  "57070": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "VIBORG"
  },
  "57071": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "VOLGA"
  },
  "57072": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "VOLIN"
  },
  "57073": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WAKONDA"
  },
  "57075": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WENTWORTH"
  },
  "57076": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WINFRED"
  },
  "57077": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WORTHING"
  },
  "57078": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "YANKTON"
  },
  "57103": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SIOUX FALLS"
  },
  "57104": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SIOUX FALLS"
  },
  "57105": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SIOUX FALLS"
  },
  "57106": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SIOUX FALLS"
  },
  "57107": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SIOUX FALLS"
  },
  "57108": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SIOUX FALLS"
  },
  "57110": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SIOUX FALLS"
  },
  "57201": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WATERTOWN"
  },
  "57212": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ARLINGTON"
  },
  "57213": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ASTORIA"
  },
  "57214": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BADGER"
  },
  "57216": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BIG STONE CITY"
  },
  "57217": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BRADLEY"
  },
  "57218": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BRANDT"
  },
  "57219": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BRISTOL"
  },
  "57220": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BRUCE"
  },
  "57221": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BRYANT"
  },
  "57223": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CASTLEWOOD"
  },
  "57224": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CLAIRE CITY"
  },
  "57225": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CLARK"
  },
  "57226": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CLEAR LAKE"
  },
  "57227": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CORONA"
  },
  "57231": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "DE SMET"
  },
  "57232": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "EDEN"
  },
  "57233": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ERWIN"
  },
  "57234": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ESTELLINE"
  },
  "57235": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "FLORENCE"
  },
  "57236": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "GARDEN CITY"
  },
  "57237": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "GARY"
  },
  "57238": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "GOODWIN"
  },
  "57239": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "GRENVILLE"
  },
  "57241": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HAYTI"
  },
  "57242": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HAZEL"
  },
  "57243": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HENRY"
  },
  "57245": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "KRANZBURG"
  },
  "57246": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LABOLT"
  },
  "57247": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LAKE CITY"
  },
  "57248": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LAKE NORDEN"
  },
  "57249": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LAKE PRESTON"
  },
  "57251": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MARVIN"
  },
  "57252": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MILBANK"
  },
  "57255": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "NEW EFFINGTON"
  },
  "57256": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ORTLEY"
  },
  "57257": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "PEEVER"
  },
  "57258": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "RAYMOND"
  },
  "57259": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "REVILLO"
  },
  "57260": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ROSHOLT"
  },
  "57261": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ROSLYN"
  },
  "57262": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SISSETON"
  },
  "57263": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SOUTH SHORE"
  },
  "57264": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "STOCKHOLM"
  },
  "57265": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "STRANDBURG"
  },
  "57266": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SUMMIT"
  },
  "57268": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "TORONTO"
  },
  "57269": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "TWIN BROOKS"
  },
  "57270": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "VEBLEN"
  },
  "57271": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "VIENNA"
  },
  "57272": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WALLACE"
  },
  "57273": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WAUBAY"
  },
  "57274": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WEBSTER"
  },
  "57276": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WHITE"
  },
  "57278": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WILLOW LAKE"
  },
  "57279": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WILMOT"
  },
  "57301": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MITCHELL"
  },
  "57311": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ALEXANDRIA"
  },
  "57312": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ALPENA"
  },
  "57313": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ARMOUR"
  },
  "57314": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ARTESIAN"
  },
  "57315": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "AVON"
  },
  "57317": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BONESTEEL"
  },
  "57319": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BRIDGEWATER"
  },
  "57321": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CANOVA"
  },
  "57322": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CARPENTER"
  },
  "57323": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CARTHAGE"
  },
  "57324": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CAVOUR"
  },
  "57325": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CHAMBERLAIN"
  },
  "57328": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CORSICA"
  },
  "57329": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "DANTE"
  },
  "57330": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "DELMONT"
  },
  "57331": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "DIMOCK"
  },
  "57332": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "EMERY"
  },
  "57334": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ETHAN"
  },
  "57335": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "FAIRFAX"
  },
  "57337": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "FEDORA"
  },
  "57339": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "FORT THOMPSON"
  },
  "57340": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "FULTON"
  },
  "57341": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "GANN VALLEY"
  },
  "57342": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "GEDDES"
  },
  "57344": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HARRISON"
  },
  "57345": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HIGHMORE"
  },
  "57346": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "STEPHAN"
  },
  "57348": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HITCHCOCK"
  },
  "57349": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HOWARD"
  },
  "57350": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HURON"
  },
  "57353": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "IROQUOIS"
  },
  "57355": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "KIMBALL"
  },
  "57356": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LAKE ANDES"
  },
  "57358": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LANE"
  },
  "57359": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LETCHER"
  },
  "57361": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MARTY"
  },
  "57362": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MILLER"
  },
  "57363": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MOUNT VERNON"
  },
  "57364": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "NEW HOLLAND"
  },
  "57365": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "OACOMA"
  },
  "57366": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "PARKSTON"
  },
  "57367": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "PICKSTOWN"
  },
  "57368": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "PLANKINTON"
  },
  "57369": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "PLATTE"
  },
  "57370": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "PUKWANA"
  },
  "57371": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "REE HEIGHTS"
  },
  "57373": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SAINT LAWRENCE"
  },
  "57374": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SPENCER"
  },
  "57375": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "STICKNEY"
  },
  "57376": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "TRIPP"
  },
  "57379": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "VIRGIL"
  },
  "57380": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WAGNER"
  },
  "57381": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WESSINGTON"
  },
  "57382": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WESSINGTON SPRINGS"
  },
  "57383": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WHITE LAKE"
  },
  "57384": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WOLSEY"
  },
  "57385": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WOONSOCKET"
  },
  "57386": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "YALE"
  },
  "57401": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ABERDEEN"
  },
  "57420": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "AKASKA"
  },
  "57421": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "AMHERST"
  },
  "57422": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ANDOVER"
  },
  "57424": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ASHTON"
  },
  "57426": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BARNARD"
  },
  "57427": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BATH"
  },
  "57428": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BOWDLE"
  },
  "57430": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BRITTON"
  },
  "57432": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CLAREMONT"
  },
  "57433": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "COLUMBIA"
  },
  "57434": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CONDE"
  },
  "57435": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CRESBARD"
  },
  "57436": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "DOLAND"
  },
  "57437": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "EUREKA"
  },
  "57438": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "FAULKTON"
  },
  "57440": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "FRANKFORT"
  },
  "57441": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "FREDERICK"
  },
  "57442": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "GETTYSBURG"
  },
  "57445": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "GROTON"
  },
  "57446": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HECLA"
  },
  "57448": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HOSMER"
  },
  "57449": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HOUGHTON"
  },
  "57450": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HOVEN"
  },
  "57451": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "IPSWICH"
  },
  "57452": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "JAVA"
  },
  "57454": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LANGFORD"
  },
  "57455": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LEBANON"
  },
  "57456": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LEOLA"
  },
  "57457": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LONGLAKE"
  },
  "57460": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MANSFIELD"
  },
  "57461": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MELLETTE"
  },
  "57465": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "NORTHVILLE"
  },
  "57466": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ONAKA"
  },
  "57467": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ORIENT"
  },
  "57468": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "PIERPONT"
  },
  "57469": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "REDFIELD"
  },
  "57470": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ROCKHAM"
  },
  "57471": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ROSCOE"
  },
  "57472": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SELBY"
  },
  "57473": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SENECA"
  },
  "57474": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "STRATFORD"
  },
  "57475": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "TOLSTOY"
  },
  "57476": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "TULARE"
  },
  "57477": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "TURTON"
  },
  "57479": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WARNER"
  },
  "57481": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WESTPORT"
  },
  "57501": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "PIERRE"
  },
  "57520": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "AGAR"
  },
  "57521": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BELVIDERE"
  },
  "57522": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BLUNT"
  },
  "57523": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BURKE"
  },
  "57528": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "COLOME"
  },
  "57529": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "DALLAS"
  },
  "57531": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "DRAPER"
  },
  "57532": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "FORT PIERRE"
  },
  "57533": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "GREGORY"
  },
  "57534": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HAMILL"
  },
  "57536": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HARROLD"
  },
  "57537": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HAYES"
  },
  "57538": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HERRICK"
  },
  "57540": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HOLABIRD"
  },
  "57541": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "IDEAL"
  },
  "57543": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "KADOKA"
  },
  "57544": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "KENNEBEC"
  },
  "57547": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LONG VALLEY"
  },
  "57548": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LOWER BRULE"
  },
  "57551": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MARTIN"
  },
  "57552": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MIDLAND"
  },
  "57553": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MILESVILLE"
  },
  "57555": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MISSION"
  },
  "57559": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MURDO"
  },
  "57560": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "NORRIS"
  },
  "57562": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "OKATON"
  },
  "57563": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "OKREEK"
  },
  "57564": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ONIDA"
  },
  "57566": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "PARMELEE"
  },
  "57567": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "PHILIP"
  },
  "57568": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "PRESHO"
  },
  "57569": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "RELIANCE"
  },
  "57570": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ROSEBUD"
  },
  "57571": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SAINT CHARLES"
  },
  "57572": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SAINT FRANCIS"
  },
  "57574": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "TUTHILL"
  },
  "57576": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "VIVIAN"
  },
  "57577": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WANBLEE"
  },
  "57579": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WHITE RIVER"
  },
  "57580": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WINNER"
  },
  "57584": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WITTEN"
  },
  "57585": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WOOD"
  },
  "57601": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MOBRIDGE"
  },
  "57620": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BISON"
  },
  "57621": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BULLHEAD"
  },
  "57622": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CHERRY CREEK"
  },
  "57623": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "DUPREE"
  },
  "57625": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "EAGLE BUTTE"
  },
  "57626": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "FAITH"
  },
  "57631": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "GLENHAM"
  },
  "57632": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HERREID"
  },
  "57633": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ISABEL"
  },
  "57634": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "KELDRON"
  },
  "57636": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LANTRY"
  },
  "57638": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LEMMON"
  },
  "57640": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LODGEPOLE"
  },
  "57641": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MC INTOSH"
  },
  "57642": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MC LAUGHLIN"
  },
  "57644": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MEADOW"
  },
  "57645": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MORRISTOWN"
  },
  "57646": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MOUND CITY"
  },
  "57648": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "POLLOCK"
  },
  "57649": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "PRAIRIE CITY"
  },
  "57650": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "RALPH"
  },
  "57651": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "REVA"
  },
  "57652": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "RIDGEVIEW"
  },
  "57656": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "TIMBER LAKE"
  },
  "57657": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "TRAIL CITY"
  },
  "57658": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WAKPALA"
  },
  "57660": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WATAUGA"
  },
  "57661": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WHITEHORSE"
  },
  "57701": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "RAPID CITY"
  },
  "57702": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "RAPID CITY"
  },
  "57703": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "RAPID CITY"
  },
  "57706": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ELLSWORTH AFB"
  },
  "57714": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ALLEN"
  },
  "57716": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BATESLAND"
  },
  "57717": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BELLE FOURCHE"
  },
  "57718": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BLACK HAWK"
  },
  "57719": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BOX ELDER"
  },
  "57720": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BUFFALO"
  },
  "57722": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "BUFFALO GAP"
  },
  "57724": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CAMP CROOK"
  },
  "57725": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CAPUTA"
  },
  "57730": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "CUSTER"
  },
  "57732": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "DEADWOOD"
  },
  "57735": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "EDGEMONT"
  },
  "57737": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ENNING"
  },
  "57738": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "FAIRBURN"
  },
  "57744": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HERMOSA"
  },
  "57745": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HILL CITY"
  },
  "57747": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HOT SPRINGS"
  },
  "57748": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "HOWES"
  },
  "57750": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "INTERIOR"
  },
  "57751": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "KEYSTONE"
  },
  "57752": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "KYLE"
  },
  "57754": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LEAD"
  },
  "57755": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "LUDLOW"
  },
  "57756": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MANDERSON"
  },
  "57758": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "MUD BUTTE"
  },
  "57759": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "NEMO"
  },
  "57760": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "NEWELL"
  },
  "57761": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "NEW UNDERWOOD"
  },
  "57762": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "NISLAND"
  },
  "57763": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "OELRICHS"
  },
  "57764": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "OGLALA"
  },
  "57766": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "ORAL"
  },
  "57767": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "OWANKA"
  },
  "57769": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "PIEDMONT"
  },
  "57770": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "PINE RIDGE"
  },
  "57772": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "PORCUPINE"
  },
  "57773": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "PRINGLE"
  },
  "57775": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "QUINN"
  },
  "57779": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SAINT ONGE"
  },
  "57780": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SCENIC"
  },
  "57783": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SPEARFISH"
  },
  "57785": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "STURGIS"
  },
  "57787": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "UNION CENTER"
  },
  "57788": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "VALE"
  },
  "57790": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WALL"
  },
  "57791": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WASTA"
  },
  "57792": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WHITE OWL"
  },
  "57793": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WHITEWOOD"
  },
  "57794": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "WOUNDED KNEE"
  },
  "57799": {
    stateCode: "SD",
    stateName: "South Dakota",
    city: "SPEARFISH"
  },
  "58001": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ABERCROMBIE"
  },
  "58004": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "AMENIA"
  },
  "58005": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ARGUSVILLE"
  },
  "58006": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ARTHUR"
  },
  "58007": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "AYR"
  },
  "58008": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BARNEY"
  },
  "58009": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BLANCHARD"
  },
  "58011": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BUFFALO"
  },
  "58012": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CASSELTON"
  },
  "58013": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CAYUGA"
  },
  "58015": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CHRISTINE"
  },
  "58016": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CLIFFORD"
  },
  "58017": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "COGSWELL"
  },
  "58018": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "COLFAX"
  },
  "58021": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "DAVENPORT"
  },
  "58027": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ENDERLIN"
  },
  "58029": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ERIE"
  },
  "58030": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FAIRMOUNT"
  },
  "58031": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FINGAL"
  },
  "58032": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FORMAN"
  },
  "58033": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FORT RANSOM"
  },
  "58035": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GALESBURG"
  },
  "58036": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GARDNER"
  },
  "58038": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GRANDIN"
  },
  "58040": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GWINNER"
  },
  "58041": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HANKINSON"
  },
  "58042": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HARWOOD"
  },
  "58043": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HAVANA"
  },
  "58045": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HILLSBORO"
  },
  "58046": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HOPE"
  },
  "58047": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HORACE"
  },
  "58048": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HUNTER"
  },
  "58049": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "KATHRYN"
  },
  "58051": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "KINDRED"
  },
  "58052": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "LEONARD"
  },
  "58053": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "LIDGERWOOD"
  },
  "58054": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "LISBON"
  },
  "58056": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "LUVERNE"
  },
  "58057": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MCLEOD"
  },
  "58058": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MANTADOR"
  },
  "58059": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MAPLETON"
  },
  "58060": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MILNOR"
  },
  "58061": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MOORETON"
  },
  "58062": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "NOME"
  },
  "58063": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ORISKA"
  },
  "58064": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "PAGE"
  },
  "58067": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "RUTLAND"
  },
  "58068": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SHELDON"
  },
  "58069": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "STIRUM"
  },
  "58071": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "TOWER CITY"
  },
  "58072": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "VALLEY CITY"
  },
  "58075": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WAHPETON"
  },
  "58077": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WALCOTT"
  },
  "58078": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WEST FARGO"
  },
  "58079": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WHEATLAND"
  },
  "58081": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WYNDMERE"
  },
  "58102": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FARGO"
  },
  "58103": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FARGO"
  },
  "58104": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FARGO"
  },
  "58201": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GRAND FORKS"
  },
  "58203": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GRAND FORKS"
  },
  "58204": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GRAND FORKS AFB"
  },
  "58210": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ADAMS"
  },
  "58212": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ANETA"
  },
  "58214": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ARVILLA"
  },
  "58216": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BATHGATE"
  },
  "58218": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BUXTON"
  },
  "58219": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CALEDONIA"
  },
  "58220": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CAVALIER"
  },
  "58222": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CRYSTAL"
  },
  "58223": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CUMMINGS"
  },
  "58224": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "DAHLEN"
  },
  "58225": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "DRAYTON"
  },
  "58227": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "EDINBURG"
  },
  "58228": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "EMERADO"
  },
  "58229": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FAIRDALE"
  },
  "58230": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FINLEY"
  },
  "58231": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FORDVILLE"
  },
  "58233": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FOREST RIVER"
  },
  "58235": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GILBY"
  },
  "58236": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GLASSTON"
  },
  "58237": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GRAFTON"
  },
  "58238": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HAMILTON"
  },
  "58239": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HANNAH"
  },
  "58240": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HATTON"
  },
  "58241": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HENSEL"
  },
  "58243": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HOOPLE"
  },
  "58244": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "INKSTER"
  },
  "58249": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "LANGDON"
  },
  "58250": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "LANKIN"
  },
  "58251": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "LARIMORE"
  },
  "58254": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MCVILLE"
  },
  "58255": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MAIDA"
  },
  "58256": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MANVEL"
  },
  "58257": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MAYVILLE"
  },
  "58258": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MEKINOCK"
  },
  "58259": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MICHIGAN"
  },
  "58260": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MILTON"
  },
  "58261": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MINTO"
  },
  "58262": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MOUNTAIN"
  },
  "58265": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "NECHE"
  },
  "58266": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "NIAGARA"
  },
  "58267": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "NORTHWOOD"
  },
  "58269": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "OSNABROCK"
  },
  "58270": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "PARK RIVER"
  },
  "58271": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "PEMBINA"
  },
  "58272": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "PETERSBURG"
  },
  "58273": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "PISEK"
  },
  "58274": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "PORTLAND"
  },
  "58275": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "REYNOLDS"
  },
  "58276": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SAINT THOMAS"
  },
  "58277": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SHARON"
  },
  "58278": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "THOMPSON"
  },
  "58281": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WALES"
  },
  "58282": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WALHALLA"
  },
  "58301": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "DEVILS LAKE"
  },
  "58310": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "AGATE"
  },
  "58311": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ALSEN"
  },
  "58313": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BALTA"
  },
  "58316": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BELCOURT"
  },
  "58317": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BISBEE"
  },
  "58318": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BOTTINEAU"
  },
  "58321": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BROCKET"
  },
  "58323": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CALVIN"
  },
  "58324": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CANDO"
  },
  "58325": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CHURCHS FERRY"
  },
  "58327": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CRARY"
  },
  "58329": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "DUNSEITH"
  },
  "58330": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "EDMORE"
  },
  "58331": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "EGELAND"
  },
  "58332": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ESMOND"
  },
  "58335": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FORT TOTTEN"
  },
  "58338": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HAMPDEN"
  },
  "58341": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HARVEY"
  },
  "58343": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "KNOX"
  },
  "58344": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "LAKOTA"
  },
  "58345": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "LAWTON"
  },
  "58346": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "LEEDS"
  },
  "58348": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MADDOCK"
  },
  "58351": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MINNEWAUKAN"
  },
  "58352": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MUNICH"
  },
  "58353": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MYLO"
  },
  "58355": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "NEKOMA"
  },
  "58356": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "NEW ROCKFORD"
  },
  "58357": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "OBERON"
  },
  "58361": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "PEKIN"
  },
  "58362": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "PENN"
  },
  "58363": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "PERTH"
  },
  "58365": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ROCKLAKE"
  },
  "58366": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ROLETTE"
  },
  "58367": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ROLLA"
  },
  "58368": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "RUGBY"
  },
  "58369": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SAINT JOHN"
  },
  "58370": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SAINT MICHAEL"
  },
  "58372": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SARLES"
  },
  "58374": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SHEYENNE"
  },
  "58377": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "STARKWEATHER"
  },
  "58379": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "TOKIO"
  },
  "58380": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "TOLNA"
  },
  "58381": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WARWICK"
  },
  "58382": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WEBSTER"
  },
  "58384": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WILLOW CITY"
  },
  "58385": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WOLFORD"
  },
  "58386": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "YORK"
  },
  "58401": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "JAMESTOWN"
  },
  "58413": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ASHLEY"
  },
  "58415": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BERLIN"
  },
  "58416": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BINFORD"
  },
  "58418": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BOWDON"
  },
  "58420": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BUCHANAN"
  },
  "58421": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CARRINGTON"
  },
  "58422": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CATHAY"
  },
  "58423": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CHASELEY"
  },
  "58424": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CLEVELAND"
  },
  "58425": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "COOPERSTOWN"
  },
  "58426": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "COURTENAY"
  },
  "58428": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "DAWSON"
  },
  "58429": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "DAZEY"
  },
  "58430": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "DENHOFF"
  },
  "58431": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "DICKEY"
  },
  "58433": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "EDGELEY"
  },
  "58436": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ELLENDALE"
  },
  "58438": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FESSENDEN"
  },
  "58439": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FORBES"
  },
  "58440": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FREDONIA"
  },
  "58441": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FULLERTON"
  },
  "58442": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GACKLE"
  },
  "58443": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GLENFIELD"
  },
  "58444": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GOODRICH"
  },
  "58445": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GRACE CITY"
  },
  "58448": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HANNAFORD"
  },
  "58451": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HURDSFIELD"
  },
  "58454": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "JUD"
  },
  "58455": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "KENSAL"
  },
  "58456": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "KULM"
  },
  "58458": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "LAMOURE"
  },
  "58460": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "LEHR"
  },
  "58461": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "LITCHVILLE"
  },
  "58463": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MCCLUSKY"
  },
  "58464": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MCHENRY"
  },
  "58466": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MARION"
  },
  "58467": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MEDINA"
  },
  "58472": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MONTPELIER"
  },
  "58474": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "OAKES"
  },
  "58475": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "PETTIBONE"
  },
  "58476": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "PINGREE"
  },
  "58477": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "REGAN"
  },
  "58478": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ROBINSON"
  },
  "58479": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ROGERS"
  },
  "58480": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SANBORN"
  },
  "58481": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SPIRITWOOD"
  },
  "58482": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "STEELE"
  },
  "58483": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "STREETER"
  },
  "58484": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SUTTON"
  },
  "58486": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SYKESTON"
  },
  "58487": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "TAPPEN"
  },
  "58488": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "TUTTLE"
  },
  "58490": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "VERONA"
  },
  "58492": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WIMBLEDON"
  },
  "58494": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WING"
  },
  "58495": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WISHEK"
  },
  "58496": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WOODWORTH"
  },
  "58497": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "YPSILANTI"
  },
  "58501": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BISMARCK"
  },
  "58504": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BISMARCK"
  },
  "58520": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ALMONT"
  },
  "58521": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BALDWIN"
  },
  "58523": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BEULAH"
  },
  "58524": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BRADDOCK"
  },
  "58528": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CANNON BALL"
  },
  "58529": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CARSON"
  },
  "58530": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CENTER"
  },
  "58531": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "COLEHARBOR"
  },
  "58532": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "DRISCOLL"
  },
  "58533": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ELGIN"
  },
  "58535": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FLASHER"
  },
  "58538": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FORT YATES"
  },
  "58540": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GARRISON"
  },
  "58541": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GOLDEN VALLEY"
  },
  "58542": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HAGUE"
  },
  "58544": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HAZELTON"
  },
  "58545": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HAZEN"
  },
  "58549": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "KINTYRE"
  },
  "58552": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "LINTON"
  },
  "58554": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MANDAN"
  },
  "58558": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MENOKEN"
  },
  "58559": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MERCER"
  },
  "58560": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MOFFIT"
  },
  "58561": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "NAPOLEON"
  },
  "58562": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "NEW LEIPZIG"
  },
  "58563": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "NEW SALEM"
  },
  "58564": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "RALEIGH"
  },
  "58565": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "RIVERDALE"
  },
  "58566": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SAINT ANTHONY"
  },
  "58568": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SELFRIDGE"
  },
  "58569": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SHIELDS"
  },
  "58570": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SOLEN"
  },
  "58571": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "STANTON"
  },
  "58572": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "STERLING"
  },
  "58573": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "STRASBURG"
  },
  "58575": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "TURTLE LAKE"
  },
  "58576": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "UNDERWOOD"
  },
  "58577": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WASHBURN"
  },
  "58579": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WILTON"
  },
  "58580": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ZAP"
  },
  "58581": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ZEELAND"
  },
  "58601": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "DICKINSON"
  },
  "58620": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "AMIDON"
  },
  "58621": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BEACH"
  },
  "58622": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BELFIELD"
  },
  "58623": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BOWMAN"
  },
  "58625": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "DODGE"
  },
  "58626": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "DUNN CENTER"
  },
  "58627": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FAIRFIELD"
  },
  "58630": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GLADSTONE"
  },
  "58631": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GLEN ULLIN"
  },
  "58632": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GOLVA"
  },
  "58634": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GRASSY BUTTE"
  },
  "58636": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HALLIDAY"
  },
  "58638": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HEBRON"
  },
  "58639": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "HETTINGER"
  },
  "58640": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "KILLDEER"
  },
  "58641": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "LEFOR"
  },
  "58642": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MANNING"
  },
  "58643": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MARMARTH"
  },
  "58645": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MEDORA"
  },
  "58646": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MOTT"
  },
  "58647": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "NEW ENGLAND"
  },
  "58649": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "REEDER"
  },
  "58650": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "REGENT"
  },
  "58651": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "RHAME"
  },
  "58652": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "RICHARDTON"
  },
  "58653": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SCRANTON"
  },
  "58654": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SENTINEL BUTTE"
  },
  "58655": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SOUTH HEART"
  },
  "58656": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "TAYLOR"
  },
  "58701": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MINOT"
  },
  "58703": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MINOT"
  },
  "58704": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MINOT AFB"
  },
  "58710": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ANAMOOSE"
  },
  "58711": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ANTLER"
  },
  "58712": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BALFOUR"
  },
  "58713": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BANTRY"
  },
  "58716": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BENEDICT"
  },
  "58718": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BERTHOLD"
  },
  "58721": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BOWBELLS"
  },
  "58722": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BURLINGTON"
  },
  "58723": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "BUTTE"
  },
  "58725": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CARPIO"
  },
  "58727": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "COLUMBUS"
  },
  "58730": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CROSBY"
  },
  "58731": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "DEERING"
  },
  "58733": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "DES LACS"
  },
  "58734": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "DONNYBROOK"
  },
  "58735": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "DOUGLAS"
  },
  "58736": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "DRAKE"
  },
  "58737": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FLAXTON"
  },
  "58740": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GLENBURN"
  },
  "58741": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GRANVILLE"
  },
  "58744": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "KARLSRUHE"
  },
  "58746": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "KENMARE"
  },
  "58748": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "KRAMER"
  },
  "58750": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "LANSFORD"
  },
  "58752": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "LIGNITE"
  },
  "58755": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MCGREGOR"
  },
  "58756": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MAKOTI"
  },
  "58757": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MANDAREE"
  },
  "58758": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MARTIN"
  },
  "58759": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MAX"
  },
  "58760": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MAXBASS"
  },
  "58761": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "MOHALL"
  },
  "58762": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "NEWBURG"
  },
  "58763": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "NEW TOWN"
  },
  "58765": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "NOONAN"
  },
  "58768": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "NORWICH"
  },
  "58769": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "PALERMO"
  },
  "58770": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "PARSHALL"
  },
  "58771": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "PLAZA"
  },
  "58772": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "PORTAL"
  },
  "58773": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "POWERS LAKE"
  },
  "58775": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ROSEGLEN"
  },
  "58776": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ROSS"
  },
  "58778": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "RUSO"
  },
  "58779": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "RYDER"
  },
  "58781": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SAWYER"
  },
  "58782": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SHERWOOD"
  },
  "58783": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SOURIS"
  },
  "58784": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "STANLEY"
  },
  "58785": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "SURREY"
  },
  "58787": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "TOLLEY"
  },
  "58788": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "TOWNER"
  },
  "58789": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "UPHAM"
  },
  "58790": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "VELVA"
  },
  "58792": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "VOLTAIRE"
  },
  "58793": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WESTHOPE"
  },
  "58794": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WHITE EARTH"
  },
  "58795": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WILDROSE"
  },
  "58801": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WILLISTON"
  },
  "58830": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ALAMO"
  },
  "58831": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ALEXANDER"
  },
  "58833": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "AMBROSE"
  },
  "58835": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ARNEGARD"
  },
  "58838": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "CARTWRIGHT"
  },
  "58843": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "EPPING"
  },
  "58844": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "FORTUNA"
  },
  "58845": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "GRENORA"
  },
  "58847": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "KEENE"
  },
  "58849": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "RAY"
  },
  "58852": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "TIOGA"
  },
  "58853": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "TRENTON"
  },
  "58854": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "WATFORD CITY"
  },
  "58856": {
    stateCode: "ND",
    stateName: "North Dakota",
    city: "ZAHL"
  },
  "59001": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ABSAROKEE"
  },
  "59002": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ACTON"
  },
  "59003": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ASHLAND"
  },
  "59006": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BALLANTINE"
  },
  "59007": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BEARCREEK"
  },
  "59008": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BELFRY"
  },
  "59010": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BIGHORN"
  },
  "59011": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BIG TIMBER"
  },
  "59012": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BIRNEY"
  },
  "59013": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BOYD"
  },
  "59014": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BRIDGER"
  },
  "59015": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BROADVIEW"
  },
  "59016": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BUSBY"
  },
  "59018": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CLYDE PARK"
  },
  "59019": {
    stateCode: "MT",
    stateName: "Montana",
    city: "COLUMBUS"
  },
  "59020": {
    stateCode: "MT",
    stateName: "Montana",
    city: "COOKE CITY"
  },
  "59022": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CROW AGENCY"
  },
  "59024": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CUSTER"
  },
  "59025": {
    stateCode: "MT",
    stateName: "Montana",
    city: "DECKER"
  },
  "59026": {
    stateCode: "MT",
    stateName: "Montana",
    city: "EDGAR"
  },
  "59027": {
    stateCode: "MT",
    stateName: "Montana",
    city: "EMIGRANT"
  },
  "59028": {
    stateCode: "MT",
    stateName: "Montana",
    city: "FISHTAIL"
  },
  "59029": {
    stateCode: "MT",
    stateName: "Montana",
    city: "FROMBERG"
  },
  "59030": {
    stateCode: "MT",
    stateName: "Montana",
    city: "GARDINER"
  },
  "59031": {
    stateCode: "MT",
    stateName: "Montana",
    city: "GARRYOWEN"
  },
  "59032": {
    stateCode: "MT",
    stateName: "Montana",
    city: "GRASS RANGE"
  },
  "59033": {
    stateCode: "MT",
    stateName: "Montana",
    city: "GREYCLIFF"
  },
  "59034": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HARDIN"
  },
  "59035": {
    stateCode: "MT",
    stateName: "Montana",
    city: "YELLOWTAIL"
  },
  "59036": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HARLOWTON"
  },
  "59037": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HUNTLEY"
  },
  "59038": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HYSHAM"
  },
  "59039": {
    stateCode: "MT",
    stateName: "Montana",
    city: "INGOMAR"
  },
  "59041": {
    stateCode: "MT",
    stateName: "Montana",
    city: "JOLIET"
  },
  "59043": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LAME DEER"
  },
  "59044": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LAUREL"
  },
  "59046": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LAVINA"
  },
  "59047": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LIVINGSTON"
  },
  "59050": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LODGE GRASS"
  },
  "59052": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MC LEOD"
  },
  "59053": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MARTINSDALE"
  },
  "59054": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MELSTONE"
  },
  "59055": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MELVILLE"
  },
  "59057": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MOLT"
  },
  "59058": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MOSBY"
  },
  "59059": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MUSSELSHELL"
  },
  "59061": {
    stateCode: "MT",
    stateName: "Montana",
    city: "NYE"
  },
  "59062": {
    stateCode: "MT",
    stateName: "Montana",
    city: "OTTER"
  },
  "59063": {
    stateCode: "MT",
    stateName: "Montana",
    city: "PARK CITY"
  },
  "59064": {
    stateCode: "MT",
    stateName: "Montana",
    city: "POMPEYS PILLAR"
  },
  "59065": {
    stateCode: "MT",
    stateName: "Montana",
    city: "PRAY"
  },
  "59066": {
    stateCode: "MT",
    stateName: "Montana",
    city: "PRYOR"
  },
  "59067": {
    stateCode: "MT",
    stateName: "Montana",
    city: "RAPELJE"
  },
  "59068": {
    stateCode: "MT",
    stateName: "Montana",
    city: "RED LODGE"
  },
  "59069": {
    stateCode: "MT",
    stateName: "Montana",
    city: "REED POINT"
  },
  "59070": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ROBERTS"
  },
  "59071": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ROSCOE"
  },
  "59072": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ROUNDUP"
  },
  "59074": {
    stateCode: "MT",
    stateName: "Montana",
    city: "RYEGATE"
  },
  "59075": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SAINT XAVIER"
  },
  "59076": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SANDERS"
  },
  "59077": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SAND SPRINGS"
  },
  "59078": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SHAWMUT"
  },
  "59079": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SHEPHERD"
  },
  "59081": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SILVER GATE"
  },
  "59085": {
    stateCode: "MT",
    stateName: "Montana",
    city: "TWO DOT"
  },
  "59086": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WILSALL"
  },
  "59087": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WINNETT"
  },
  "59088": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WORDEN"
  },
  "59089": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WYOLA"
  },
  "59101": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BILLINGS"
  },
  "59102": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BILLINGS"
  },
  "59105": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BILLINGS"
  },
  "59106": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BILLINGS"
  },
  "59201": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WOLF POINT"
  },
  "59211": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ANTELOPE"
  },
  "59212": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BAINVILLE"
  },
  "59213": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BROCKTON"
  },
  "59214": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BROCKWAY"
  },
  "59215": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CIRCLE"
  },
  "59217": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CRANE"
  },
  "59218": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CULBERTSON"
  },
  "59219": {
    stateCode: "MT",
    stateName: "Montana",
    city: "DAGMAR"
  },
  "59221": {
    stateCode: "MT",
    stateName: "Montana",
    city: "FAIRVIEW"
  },
  "59222": {
    stateCode: "MT",
    stateName: "Montana",
    city: "FLAXVILLE"
  },
  "59223": {
    stateCode: "MT",
    stateName: "Montana",
    city: "FORT PECK"
  },
  "59225": {
    stateCode: "MT",
    stateName: "Montana",
    city: "FRAZER"
  },
  "59226": {
    stateCode: "MT",
    stateName: "Montana",
    city: "FROID"
  },
  "59230": {
    stateCode: "MT",
    stateName: "Montana",
    city: "GLASGOW"
  },
  "59231": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SAINT MARIE"
  },
  "59241": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HINSDALE"
  },
  "59242": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HOMESTEAD"
  },
  "59243": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LAMBERT"
  },
  "59244": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LARSLAN"
  },
  "59247": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MEDICINE LAKE"
  },
  "59248": {
    stateCode: "MT",
    stateName: "Montana",
    city: "NASHUA"
  },
  "59250": {
    stateCode: "MT",
    stateName: "Montana",
    city: "OPHEIM"
  },
  "59252": {
    stateCode: "MT",
    stateName: "Montana",
    city: "OUTLOOK"
  },
  "59253": {
    stateCode: "MT",
    stateName: "Montana",
    city: "PEERLESS"
  },
  "59254": {
    stateCode: "MT",
    stateName: "Montana",
    city: "PLENTYWOOD"
  },
  "59255": {
    stateCode: "MT",
    stateName: "Montana",
    city: "POPLAR"
  },
  "59257": {
    stateCode: "MT",
    stateName: "Montana",
    city: "REDSTONE"
  },
  "59258": {
    stateCode: "MT",
    stateName: "Montana",
    city: "RESERVE"
  },
  "59259": {
    stateCode: "MT",
    stateName: "Montana",
    city: "RICHEY"
  },
  "59260": {
    stateCode: "MT",
    stateName: "Montana",
    city: "RICHLAND"
  },
  "59261": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SACO"
  },
  "59262": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SAVAGE"
  },
  "59263": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SCOBEY"
  },
  "59270": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SIDNEY"
  },
  "59274": {
    stateCode: "MT",
    stateName: "Montana",
    city: "VIDA"
  },
  "59275": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WESTBY"
  },
  "59276": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WHITETAIL"
  },
  "59301": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MILES CITY"
  },
  "59311": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ALZADA"
  },
  "59313": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BAKER"
  },
  "59314": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BIDDLE"
  },
  "59315": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BLOOMFIELD"
  },
  "59317": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BROADUS"
  },
  "59318": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BRUSETT"
  },
  "59322": {
    stateCode: "MT",
    stateName: "Montana",
    city: "COHAGEN"
  },
  "59323": {
    stateCode: "MT",
    stateName: "Montana",
    city: "COLSTRIP"
  },
  "59324": {
    stateCode: "MT",
    stateName: "Montana",
    city: "EKALAKA"
  },
  "59326": {
    stateCode: "MT",
    stateName: "Montana",
    city: "FALLON"
  },
  "59327": {
    stateCode: "MT",
    stateName: "Montana",
    city: "FORSYTH"
  },
  "59330": {
    stateCode: "MT",
    stateName: "Montana",
    city: "GLENDIVE"
  },
  "59332": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HAMMOND"
  },
  "59333": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HATHAWAY"
  },
  "59336": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ISMAY"
  },
  "59337": {
    stateCode: "MT",
    stateName: "Montana",
    city: "JORDAN"
  },
  "59338": {
    stateCode: "MT",
    stateName: "Montana",
    city: "KINSEY"
  },
  "59339": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LINDSAY"
  },
  "59343": {
    stateCode: "MT",
    stateName: "Montana",
    city: "OLIVE"
  },
  "59344": {
    stateCode: "MT",
    stateName: "Montana",
    city: "PLEVNA"
  },
  "59347": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ROSEBUD"
  },
  "59349": {
    stateCode: "MT",
    stateName: "Montana",
    city: "TERRY"
  },
  "59351": {
    stateCode: "MT",
    stateName: "Montana",
    city: "VOLBORG"
  },
  "59353": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WIBAUX"
  },
  "59354": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WILLARD"
  },
  "59401": {
    stateCode: "MT",
    stateName: "Montana",
    city: "GREAT FALLS"
  },
  "59404": {
    stateCode: "MT",
    stateName: "Montana",
    city: "GREAT FALLS"
  },
  "59405": {
    stateCode: "MT",
    stateName: "Montana",
    city: "GREAT FALLS"
  },
  "59410": {
    stateCode: "MT",
    stateName: "Montana",
    city: "AUGUSTA"
  },
  "59411": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BABB"
  },
  "59412": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BELT"
  },
  "59414": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BLACK EAGLE"
  },
  "59416": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BRADY"
  },
  "59417": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BROWNING"
  },
  "59419": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BYNUM"
  },
  "59420": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CARTER"
  },
  "59421": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CASCADE"
  },
  "59422": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CHOTEAU"
  },
  "59424": {
    stateCode: "MT",
    stateName: "Montana",
    city: "COFFEE CREEK"
  },
  "59425": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CONRAD"
  },
  "59427": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CUT BANK"
  },
  "59430": {
    stateCode: "MT",
    stateName: "Montana",
    city: "DENTON"
  },
  "59432": {
    stateCode: "MT",
    stateName: "Montana",
    city: "DUPUYER"
  },
  "59433": {
    stateCode: "MT",
    stateName: "Montana",
    city: "DUTTON"
  },
  "59434": {
    stateCode: "MT",
    stateName: "Montana",
    city: "EAST GLACIER PARK"
  },
  "59436": {
    stateCode: "MT",
    stateName: "Montana",
    city: "FAIRFIELD"
  },
  "59440": {
    stateCode: "MT",
    stateName: "Montana",
    city: "FLOWEREE"
  },
  "59441": {
    stateCode: "MT",
    stateName: "Montana",
    city: "FOREST GROVE"
  },
  "59442": {
    stateCode: "MT",
    stateName: "Montana",
    city: "FORT BENTON"
  },
  "59443": {
    stateCode: "MT",
    stateName: "Montana",
    city: "FORT SHAW"
  },
  "59444": {
    stateCode: "MT",
    stateName: "Montana",
    city: "GALATA"
  },
  "59446": {
    stateCode: "MT",
    stateName: "Montana",
    city: "GERALDINE"
  },
  "59447": {
    stateCode: "MT",
    stateName: "Montana",
    city: "GEYSER"
  },
  "59448": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HEART BUTTE"
  },
  "59450": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HIGHWOOD"
  },
  "59451": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HILGER"
  },
  "59452": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HOBSON"
  },
  "59453": {
    stateCode: "MT",
    stateName: "Montana",
    city: "JUDITH GAP"
  },
  "59454": {
    stateCode: "MT",
    stateName: "Montana",
    city: "KEVIN"
  },
  "59456": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LEDGER"
  },
  "59457": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LEWISTOWN"
  },
  "59460": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LOMA"
  },
  "59462": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MOCCASIN"
  },
  "59463": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MONARCH"
  },
  "59464": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MOORE"
  },
  "59465": {
    stateCode: "MT",
    stateName: "Montana",
    city: "NEIHART"
  },
  "59466": {
    stateCode: "MT",
    stateName: "Montana",
    city: "OILMONT"
  },
  "59467": {
    stateCode: "MT",
    stateName: "Montana",
    city: "PENDROY"
  },
  "59468": {
    stateCode: "MT",
    stateName: "Montana",
    city: "POWER"
  },
  "59469": {
    stateCode: "MT",
    stateName: "Montana",
    city: "RAYNESFORD"
  },
  "59471": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ROY"
  },
  "59472": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SAND COULEE"
  },
  "59474": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SHELBY"
  },
  "59477": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SIMMS"
  },
  "59479": {
    stateCode: "MT",
    stateName: "Montana",
    city: "STANFORD"
  },
  "59480": {
    stateCode: "MT",
    stateName: "Montana",
    city: "STOCKETT"
  },
  "59482": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SUNBURST"
  },
  "59483": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SUN RIVER"
  },
  "59484": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SWEET GRASS"
  },
  "59485": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ULM"
  },
  "59486": {
    stateCode: "MT",
    stateName: "Montana",
    city: "VALIER"
  },
  "59487": {
    stateCode: "MT",
    stateName: "Montana",
    city: "VAUGHN"
  },
  "59501": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HAVRE"
  },
  "59520": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BIG SANDY"
  },
  "59521": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BOX ELDER"
  },
  "59522": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CHESTER"
  },
  "59523": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CHINOOK"
  },
  "59524": {
    stateCode: "MT",
    stateName: "Montana",
    city: "DODSON"
  },
  "59525": {
    stateCode: "MT",
    stateName: "Montana",
    city: "GILDFORD"
  },
  "59526": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HARLEM"
  },
  "59527": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HAYS"
  },
  "59528": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HINGHAM"
  },
  "59529": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HOGELAND"
  },
  "59530": {
    stateCode: "MT",
    stateName: "Montana",
    city: "INVERNESS"
  },
  "59531": {
    stateCode: "MT",
    stateName: "Montana",
    city: "JOPLIN"
  },
  "59532": {
    stateCode: "MT",
    stateName: "Montana",
    city: "KREMLIN"
  },
  "59535": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LLOYD"
  },
  "59537": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LORING"
  },
  "59538": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MALTA"
  },
  "59540": {
    stateCode: "MT",
    stateName: "Montana",
    city: "RUDYARD"
  },
  "59542": {
    stateCode: "MT",
    stateName: "Montana",
    city: "TURNER"
  },
  "59544": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WHITEWATER"
  },
  "59545": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WHITLASH"
  },
  "59546": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ZORTMAN"
  },
  "59547": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ZURICH"
  },
  "59601": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HELENA"
  },
  "59602": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HELENA"
  },
  "59631": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BASIN"
  },
  "59632": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BOULDER"
  },
  "59633": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CANYON CREEK"
  },
  "59634": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CLANCY"
  },
  "59635": {
    stateCode: "MT",
    stateName: "Montana",
    city: "EAST HELENA"
  },
  "59636": {
    stateCode: "MT",
    stateName: "Montana",
    city: "FORT HARRISON"
  },
  "59638": {
    stateCode: "MT",
    stateName: "Montana",
    city: "JEFFERSON CITY"
  },
  "59639": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LINCOLN"
  },
  "59640": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MARYSVILLE"
  },
  "59642": {
    stateCode: "MT",
    stateName: "Montana",
    city: "RINGLING"
  },
  "59643": {
    stateCode: "MT",
    stateName: "Montana",
    city: "TOSTON"
  },
  "59644": {
    stateCode: "MT",
    stateName: "Montana",
    city: "TOWNSEND"
  },
  "59645": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WHITE SULPHUR SPRINGS"
  },
  "59648": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WOLF CREEK"
  },
  "59701": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BUTTE"
  },
  "59710": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ALDER"
  },
  "59711": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ANACONDA"
  },
  "59713": {
    stateCode: "MT",
    stateName: "Montana",
    city: "AVON"
  },
  "59714": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BELGRADE"
  },
  "59715": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BOZEMAN"
  },
  "59716": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BIG SKY"
  },
  "59718": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BOZEMAN"
  },
  "59720": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CAMERON"
  },
  "59721": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CARDWELL"
  },
  "59722": {
    stateCode: "MT",
    stateName: "Montana",
    city: "DEER LODGE"
  },
  "59725": {
    stateCode: "MT",
    stateName: "Montana",
    city: "DILLON"
  },
  "59727": {
    stateCode: "MT",
    stateName: "Montana",
    city: "DIVIDE"
  },
  "59728": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ELLISTON"
  },
  "59729": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ENNIS"
  },
  "59730": {
    stateCode: "MT",
    stateName: "Montana",
    city: "GALLATIN GATEWAY"
  },
  "59731": {
    stateCode: "MT",
    stateName: "Montana",
    city: "GARRISON"
  },
  "59733": {
    stateCode: "MT",
    stateName: "Montana",
    city: "GOLD CREEK"
  },
  "59735": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HARRISON"
  },
  "59736": {
    stateCode: "MT",
    stateName: "Montana",
    city: "JACKSON"
  },
  "59739": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LIMA"
  },
  "59740": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MC ALLISTER"
  },
  "59741": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MANHATTAN"
  },
  "59743": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MELROSE"
  },
  "59745": {
    stateCode: "MT",
    stateName: "Montana",
    city: "NORRIS"
  },
  "59746": {
    stateCode: "MT",
    stateName: "Montana",
    city: "POLARIS"
  },
  "59747": {
    stateCode: "MT",
    stateName: "Montana",
    city: "PONY"
  },
  "59748": {
    stateCode: "MT",
    stateName: "Montana",
    city: "RAMSAY"
  },
  "59749": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SHERIDAN"
  },
  "59750": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BUTTE"
  },
  "59751": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SILVER STAR"
  },
  "59752": {
    stateCode: "MT",
    stateName: "Montana",
    city: "THREE FORKS"
  },
  "59754": {
    stateCode: "MT",
    stateName: "Montana",
    city: "TWIN BRIDGES"
  },
  "59755": {
    stateCode: "MT",
    stateName: "Montana",
    city: "VIRGINIA CITY"
  },
  "59756": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WARM SPRINGS"
  },
  "59758": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WEST YELLOWSTONE"
  },
  "59759": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WHITEHALL"
  },
  "59761": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WISDOM"
  },
  "59762": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WISE RIVER"
  },
  "59801": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MISSOULA"
  },
  "59802": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MISSOULA"
  },
  "59803": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MISSOULA"
  },
  "59804": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MISSOULA"
  },
  "59808": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MISSOULA"
  },
  "59820": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ALBERTON"
  },
  "59821": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ARLEE"
  },
  "59823": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BONNER"
  },
  "59824": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CHARLO"
  },
  "59825": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CLINTON"
  },
  "59826": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CONDON"
  },
  "59827": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CONNER"
  },
  "59828": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CORVALLIS"
  },
  "59829": {
    stateCode: "MT",
    stateName: "Montana",
    city: "DARBY"
  },
  "59831": {
    stateCode: "MT",
    stateName: "Montana",
    city: "DIXON"
  },
  "59832": {
    stateCode: "MT",
    stateName: "Montana",
    city: "DRUMMOND"
  },
  "59833": {
    stateCode: "MT",
    stateName: "Montana",
    city: "FLORENCE"
  },
  "59834": {
    stateCode: "MT",
    stateName: "Montana",
    city: "FRENCHTOWN"
  },
  "59837": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HALL"
  },
  "59840": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HAMILTON"
  },
  "59841": {
    stateCode: "MT",
    stateName: "Montana",
    city: "PINESDALE"
  },
  "59842": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HAUGAN"
  },
  "59843": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HELMVILLE"
  },
  "59844": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HERON"
  },
  "59845": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HOT SPRINGS"
  },
  "59846": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HUSON"
  },
  "59847": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LOLO"
  },
  "59848": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LONEPINE"
  },
  "59851": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MILLTOWN"
  },
  "59853": {
    stateCode: "MT",
    stateName: "Montana",
    city: "NOXON"
  },
  "59854": {
    stateCode: "MT",
    stateName: "Montana",
    city: "OVANDO"
  },
  "59855": {
    stateCode: "MT",
    stateName: "Montana",
    city: "PABLO"
  },
  "59858": {
    stateCode: "MT",
    stateName: "Montana",
    city: "PHILIPSBURG"
  },
  "59859": {
    stateCode: "MT",
    stateName: "Montana",
    city: "PLAINS"
  },
  "59860": {
    stateCode: "MT",
    stateName: "Montana",
    city: "POLSON"
  },
  "59864": {
    stateCode: "MT",
    stateName: "Montana",
    city: "RONAN"
  },
  "59865": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SAINT IGNATIUS"
  },
  "59866": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SAINT REGIS"
  },
  "59867": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SALTESE"
  },
  "59868": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SEELEY LAKE"
  },
  "59870": {
    stateCode: "MT",
    stateName: "Montana",
    city: "STEVENSVILLE"
  },
  "59871": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SULA"
  },
  "59872": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SUPERIOR"
  },
  "59873": {
    stateCode: "MT",
    stateName: "Montana",
    city: "THOMPSON FALLS"
  },
  "59874": {
    stateCode: "MT",
    stateName: "Montana",
    city: "TROUT CREEK"
  },
  "59875": {
    stateCode: "MT",
    stateName: "Montana",
    city: "VICTOR"
  },
  "59901": {
    stateCode: "MT",
    stateName: "Montana",
    city: "KALISPELL"
  },
  "59910": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BIG ARM"
  },
  "59911": {
    stateCode: "MT",
    stateName: "Montana",
    city: "BIGFORK"
  },
  "59912": {
    stateCode: "MT",
    stateName: "Montana",
    city: "COLUMBIA FALLS"
  },
  "59913": {
    stateCode: "MT",
    stateName: "Montana",
    city: "CORAM"
  },
  "59914": {
    stateCode: "MT",
    stateName: "Montana",
    city: "DAYTON"
  },
  "59915": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ELMO"
  },
  "59916": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ESSEX"
  },
  "59917": {
    stateCode: "MT",
    stateName: "Montana",
    city: "EUREKA"
  },
  "59918": {
    stateCode: "MT",
    stateName: "Montana",
    city: "FORTINE"
  },
  "59919": {
    stateCode: "MT",
    stateName: "Montana",
    city: "HUNGRY HORSE"
  },
  "59920": {
    stateCode: "MT",
    stateName: "Montana",
    city: "KILA"
  },
  "59921": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LAKE MC DONALD"
  },
  "59922": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LAKESIDE"
  },
  "59923": {
    stateCode: "MT",
    stateName: "Montana",
    city: "LIBBY"
  },
  "59925": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MARION"
  },
  "59926": {
    stateCode: "MT",
    stateName: "Montana",
    city: "MARTIN CITY"
  },
  "59927": {
    stateCode: "MT",
    stateName: "Montana",
    city: "OLNEY"
  },
  "59928": {
    stateCode: "MT",
    stateName: "Montana",
    city: "POLEBRIDGE"
  },
  "59929": {
    stateCode: "MT",
    stateName: "Montana",
    city: "PROCTOR"
  },
  "59930": {
    stateCode: "MT",
    stateName: "Montana",
    city: "REXFORD"
  },
  "59931": {
    stateCode: "MT",
    stateName: "Montana",
    city: "ROLLINS"
  },
  "59932": {
    stateCode: "MT",
    stateName: "Montana",
    city: "SOMERS"
  },
  "59933": {
    stateCode: "MT",
    stateName: "Montana",
    city: "STRYKER"
  },
  "59934": {
    stateCode: "MT",
    stateName: "Montana",
    city: "TREGO"
  },
  "59935": {
    stateCode: "MT",
    stateName: "Montana",
    city: "TROY"
  },
  "59936": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WEST GLACIER"
  },
  "59937": {
    stateCode: "MT",
    stateName: "Montana",
    city: "WHITEFISH"
  },
  "60002": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ANTIOCH"
  },
  "60004": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ARLINGTON HEIGHTS"
  },
  "60005": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ARLINGTON HEIGHTS"
  },
  "60007": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELK GROVE VILLAGE"
  },
  "60008": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROLLING MEADOWS"
  },
  "60010": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BARRINGTON"
  },
  "60012": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CRYSTAL LAKE"
  },
  "60013": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CARY"
  },
  "60014": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CRYSTAL LAKE"
  },
  "60015": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DEERFIELD"
  },
  "60016": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DES PLAINES"
  },
  "60018": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DES PLAINES"
  },
  "60020": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FOX LAKE"
  },
  "60021": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FOX RIVER GROVE"
  },
  "60022": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GLENCOE"
  },
  "60025": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GLENVIEW"
  },
  "60029": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GOLF"
  },
  "60030": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GRAYSLAKE"
  },
  "60031": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GURNEE"
  },
  "60033": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HARVARD"
  },
  "60034": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HEBRON"
  },
  "60035": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HIGHLAND PARK"
  },
  "60037": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FORT SHERIDAN"
  },
  "60040": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HIGHWOOD"
  },
  "60041": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "INGLESIDE"
  },
  "60042": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ISLAND LAKE"
  },
  "60043": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KENILWORTH"
  },
  "60044": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LAKE BLUFF"
  },
  "60045": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LAKE FOREST"
  },
  "60046": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LAKE VILLA"
  },
  "60047": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LAKE ZURICH"
  },
  "60048": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LIBERTYVILLE"
  },
  "60050": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MCHENRY"
  },
  "60053": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MORTON GROVE"
  },
  "60056": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MOUNT PROSPECT"
  },
  "60060": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MUNDELEIN"
  },
  "60061": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VERNON HILLS"
  },
  "60062": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NORTHBROOK"
  },
  "60064": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NORTH CHICAGO"
  },
  "60067": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PALATINE"
  },
  "60068": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PARK RIDGE"
  },
  "60069": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LINCOLNSHIRE"
  },
  "60070": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PROSPECT HEIGHTS"
  },
  "60071": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RICHMOND"
  },
  "60072": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RINGWOOD"
  },
  "60073": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROUND LAKE"
  },
  "60074": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PALATINE"
  },
  "60076": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SKOKIE"
  },
  "60077": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SKOKIE"
  },
  "60081": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SPRING GROVE"
  },
  "60083": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WADSWORTH"
  },
  "60084": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WAUCONDA"
  },
  "60085": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WAUKEGAN"
  },
  "60087": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WAUKEGAN"
  },
  "60088": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GREAT LAKES"
  },
  "60089": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BUFFALO GROVE"
  },
  "60090": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WHEELING"
  },
  "60091": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WILMETTE"
  },
  "60093": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WINNETKA"
  },
  "60096": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WINTHROP HARBOR"
  },
  "60097": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WONDER LAKE"
  },
  "60098": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WOODSTOCK"
  },
  "60099": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ZION"
  },
  "60101": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ADDISON"
  },
  "60102": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALGONQUIN"
  },
  "60103": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BARTLETT"
  },
  "60104": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BELLWOOD"
  },
  "60106": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BENSENVILLE"
  },
  "60107": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STREAMWOOD"
  },
  "60108": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BLOOMINGDALE"
  },
  "60109": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BURLINGTON"
  },
  "60110": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CARPENTERSVILLE"
  },
  "60111": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CLARE"
  },
  "60112": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CORTLAND"
  },
  "60113": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CRESTON"
  },
  "60115": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DEKALB"
  },
  "60118": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DUNDEE"
  },
  "60119": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELBURN"
  },
  "60120": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELGIN"
  },
  "60123": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELGIN"
  },
  "60126": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELMHURST"
  },
  "60129": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ESMOND"
  },
  "60130": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FOREST PARK"
  },
  "60131": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FRANKLIN PARK"
  },
  "60134": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GENEVA"
  },
  "60135": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GENOA"
  },
  "60136": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GILBERTS"
  },
  "60137": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GLEN ELLYN"
  },
  "60139": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GLENDALE HEIGHTS"
  },
  "60140": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HAMPSHIRE"
  },
  "60142": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HUNTLEY"
  },
  "60143": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ITASCA"
  },
  "60145": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KINGSTON"
  },
  "60146": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KIRKLAND"
  },
  "60148": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LOMBARD"
  },
  "60150": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MALTA"
  },
  "60151": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MAPLE PARK"
  },
  "60152": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MARENGO"
  },
  "60153": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MAYWOOD"
  },
  "60154": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WESTCHESTER"
  },
  "60155": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BROADVIEW"
  },
  "60157": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MEDINAH"
  },
  "60160": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MELROSE PARK"
  },
  "60162": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HILLSIDE"
  },
  "60163": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BERKELEY"
  },
  "60164": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MELROSE PARK"
  },
  "60165": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STONE PARK"
  },
  "60171": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RIVER GROVE"
  },
  "60172": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROSELLE"
  },
  "60173": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SCHAUMBURG"
  },
  "60174": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SAINT CHARLES"
  },
  "60175": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SAINT CHARLES"
  },
  "60176": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SCHILLER PARK"
  },
  "60177": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SOUTH ELGIN"
  },
  "60178": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SYCAMORE"
  },
  "60180": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "UNION"
  },
  "60181": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VILLA PARK"
  },
  "60184": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WAYNE"
  },
  "60185": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WEST CHICAGO"
  },
  "60187": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WHEATON"
  },
  "60188": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CAROL STREAM"
  },
  "60190": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WINFIELD"
  },
  "60191": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WOOD DALE"
  },
  "60192": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HOFFMAN ESTATES"
  },
  "60193": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SCHAUMBURG"
  },
  "60194": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SCHAUMBURG"
  },
  "60195": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SCHAUMBURG"
  },
  "60201": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EVANSTON"
  },
  "60202": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EVANSTON"
  },
  "60203": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EVANSTON"
  },
  "60301": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OAK PARK"
  },
  "60302": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OAK PARK"
  },
  "60304": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OAK PARK"
  },
  "60305": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RIVER FOREST"
  },
  "60401": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BEECHER"
  },
  "60402": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BERWYN"
  },
  "60406": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BLUE ISLAND"
  },
  "60407": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BRACEVILLE"
  },
  "60408": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BRAIDWOOD"
  },
  "60409": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CALUMET CITY"
  },
  "60410": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHANNAHON"
  },
  "60411": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO HEIGHTS"
  },
  "60415": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO RIDGE"
  },
  "60416": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COAL CITY"
  },
  "60417": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CRETE"
  },
  "60419": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DOLTON"
  },
  "60420": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DWIGHT"
  },
  "60421": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELWOOD"
  },
  "60422": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FLOSSMOOR"
  },
  "60423": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FRANKFORT"
  },
  "60424": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GARDNER"
  },
  "60425": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GLENWOOD"
  },
  "60426": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HARVEY"
  },
  "60429": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HAZEL CREST"
  },
  "60430": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HOMEWOOD"
  },
  "60431": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "JOLIET"
  },
  "60432": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "JOLIET"
  },
  "60433": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "JOLIET"
  },
  "60435": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "JOLIET"
  },
  "60436": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "JOLIET"
  },
  "60437": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KINSMAN"
  },
  "60438": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LANSING"
  },
  "60439": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LEMONT"
  },
  "60440": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BOLINGBROOK"
  },
  "60441": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LOCKPORT"
  },
  "60442": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MANHATTAN"
  },
  "60443": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MATTESON"
  },
  "60444": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MAZON"
  },
  "60445": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MIDLOTHIAN"
  },
  "60446": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROMEOVILLE"
  },
  "60447": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MINOOKA"
  },
  "60448": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MOKENA"
  },
  "60449": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MONEE"
  },
  "60450": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MORRIS"
  },
  "60451": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEW LENOX"
  },
  "60452": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OAK FOREST"
  },
  "60453": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OAK LAWN"
  },
  "60455": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BRIDGEVIEW"
  },
  "60456": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HOMETOWN"
  },
  "60457": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HICKORY HILLS"
  },
  "60458": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "JUSTICE"
  },
  "60459": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BURBANK"
  },
  "60460": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ODELL"
  },
  "60461": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OLYMPIA FIELDS"
  },
  "60462": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ORLAND PARK"
  },
  "60463": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PALOS HEIGHTS"
  },
  "60464": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PALOS PARK"
  },
  "60465": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PALOS HILLS"
  },
  "60466": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PARK FOREST"
  },
  "60467": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ORLAND PARK"
  },
  "60468": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PEOTONE"
  },
  "60469": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "POSEN"
  },
  "60470": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RANSOM"
  },
  "60471": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RICHTON PARK"
  },
  "60472": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROBBINS"
  },
  "60473": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SOUTH HOLLAND"
  },
  "60474": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SOUTH WILMINGTON"
  },
  "60475": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STEGER"
  },
  "60476": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "THORNTON"
  },
  "60477": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TINLEY PARK"
  },
  "60478": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COUNTRY CLUB HILLS"
  },
  "60479": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VERONA"
  },
  "60480": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WILLOW SPRINGS"
  },
  "60481": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WILMINGTON"
  },
  "60482": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WORTH"
  },
  "60490": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BOLINGBROOK"
  },
  "60501": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SUMMIT ARGO"
  },
  "60504": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "AURORA"
  },
  "60505": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "AURORA"
  },
  "60506": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "AURORA"
  },
  "60510": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BATAVIA"
  },
  "60511": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BIG ROCK"
  },
  "60512": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BRISTOL"
  },
  "60513": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BROOKFIELD"
  },
  "60514": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CLARENDON HILLS"
  },
  "60515": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DOWNERS GROVE"
  },
  "60516": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DOWNERS GROVE"
  },
  "60517": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WOODRIDGE"
  },
  "60518": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EARLVILLE"
  },
  "60519": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EOLA"
  },
  "60520": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HINCKLEY"
  },
  "60521": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HINSDALE"
  },
  "60523": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OAK BROOK"
  },
  "60525": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LA GRANGE"
  },
  "60526": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LA GRANGE PARK"
  },
  "60530": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LEE"
  },
  "60531": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LELAND"
  },
  "60532": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LISLE"
  },
  "60534": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LYONS"
  },
  "60536": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MILLBROOK"
  },
  "60537": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MILLINGTON"
  },
  "60538": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MONTGOMERY"
  },
  "60540": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NAPERVILLE"
  },
  "60541": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEWARK"
  },
  "60542": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NORTH AURORA"
  },
  "60543": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OSWEGO"
  },
  "60544": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PLAINFIELD"
  },
  "60545": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PLANO"
  },
  "60546": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RIVERSIDE"
  },
  "60548": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SANDWICH"
  },
  "60549": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SERENA"
  },
  "60550": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SHABBONA"
  },
  "60551": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SHERIDAN"
  },
  "60552": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SOMONAUK"
  },
  "60553": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STEWARD"
  },
  "60554": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SUGAR GROVE"
  },
  "60555": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WARRENVILLE"
  },
  "60556": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WATERMAN"
  },
  "60557": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WEDRON"
  },
  "60558": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WESTERN SPRINGS"
  },
  "60559": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WESTMONT"
  },
  "60560": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "YORKVILLE"
  },
  "60561": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DARIEN"
  },
  "60563": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NAPERVILLE"
  },
  "60564": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NAPERVILLE"
  },
  "60565": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NAPERVILLE"
  },
  "60601": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60602": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60603": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60604": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60605": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60606": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60607": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60608": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60609": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60610": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60611": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60612": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60613": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60614": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60615": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60616": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60617": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60618": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60619": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60620": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60621": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60622": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60623": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60624": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60625": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60626": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60628": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60629": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60630": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60631": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60632": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60633": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60634": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60636": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60637": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60638": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60639": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60640": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60641": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60643": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60644": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60645": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60646": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60647": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60649": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60651": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60652": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60653": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60655": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60656": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60657": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60659": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60660": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60661": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHICAGO"
  },
  "60706": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HARWOOD HEIGHTS"
  },
  "60707": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELMWOOD PARK"
  },
  "60712": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LINCOLNWOOD"
  },
  "60714": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NILES"
  },
  "60803": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALSIP"
  },
  "60804": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CICERO"
  },
  "60805": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EVERGREEN PARK"
  },
  "60827": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RIVERDALE"
  },
  "60901": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KANKAKEE"
  },
  "60910": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "AROMA PARK"
  },
  "60911": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ASHKUM"
  },
  "60912": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BEAVERVILLE"
  },
  "60913": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BONFIELD"
  },
  "60914": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BOURBONNAIS"
  },
  "60915": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BRADLEY"
  },
  "60917": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BUCKINGHAM"
  },
  "60918": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BUCKLEY"
  },
  "60919": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CABERY"
  },
  "60920": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CAMPUS"
  },
  "60921": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHATSWORTH"
  },
  "60922": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHEBANSE"
  },
  "60924": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CISSNA PARK"
  },
  "60926": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CLAYTONVILLE"
  },
  "60927": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CLIFTON"
  },
  "60928": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CRESCENT CITY"
  },
  "60929": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CULLOM"
  },
  "60930": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DANFORTH"
  },
  "60931": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DONOVAN"
  },
  "60932": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EAST LYNN"
  },
  "60933": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELLIOTT"
  },
  "60934": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EMINGTON"
  },
  "60935": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ESSEX"
  },
  "60936": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GIBSON CITY"
  },
  "60938": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GILMAN"
  },
  "60940": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GRANT PARK"
  },
  "60941": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HERSCHER"
  },
  "60942": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HOOPESTON"
  },
  "60945": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "IROQUOIS"
  },
  "60946": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KEMPTON"
  },
  "60948": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LODA"
  },
  "60949": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LUDLOW"
  },
  "60950": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MANTENO"
  },
  "60951": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MARTINTON"
  },
  "60952": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MELVIN"
  },
  "60953": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MILFORD"
  },
  "60954": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MOMENCE"
  },
  "60955": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ONARGA"
  },
  "60957": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PAXTON"
  },
  "60959": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PIPER CITY"
  },
  "60960": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RANKIN"
  },
  "60961": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "REDDICK"
  },
  "60962": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROBERTS"
  },
  "60963": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROSSVILLE"
  },
  "60964": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SAINT ANNE"
  },
  "60966": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SHELDON"
  },
  "60967": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STOCKLAND"
  },
  "60968": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "THAWVILLE"
  },
  "60969": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "UNION HILL"
  },
  "60970": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WATSEKA"
  },
  "60973": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WELLINGTON"
  },
  "60974": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WOODLAND"
  },
  "61001": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "APPLE RIVER"
  },
  "61006": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ASHTON"
  },
  "61007": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BAILEYVILLE"
  },
  "61008": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BELVIDERE"
  },
  "61010": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BYRON"
  },
  "61011": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CALEDONIA"
  },
  "61012": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CAPRON"
  },
  "61013": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CEDARVILLE"
  },
  "61014": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHADWICK"
  },
  "61015": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHANA"
  },
  "61016": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHERRY VALLEY"
  },
  "61018": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DAKOTA"
  },
  "61019": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DAVIS"
  },
  "61020": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DAVIS JUNCTION"
  },
  "61021": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DIXON"
  },
  "61024": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DURAND"
  },
  "61025": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EAST DUBUQUE"
  },
  "61028": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELIZABETH"
  },
  "61030": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FORRESTON"
  },
  "61031": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FRANKLIN GROVE"
  },
  "61032": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FREEPORT"
  },
  "61036": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GALENA"
  },
  "61037": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GALT"
  },
  "61038": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GARDEN PRAIRIE"
  },
  "61039": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GERMAN VALLEY"
  },
  "61041": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HANOVER"
  },
  "61042": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HARMON"
  },
  "61044": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KENT"
  },
  "61046": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LANARK"
  },
  "61047": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LEAF RIVER"
  },
  "61048": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LENA"
  },
  "61049": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LINDENWOOD"
  },
  "61050": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MC CONNELL"
  },
  "61051": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MILLEDGEVILLE"
  },
  "61052": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MONROE CENTER"
  },
  "61053": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MOUNT CARROLL"
  },
  "61054": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MOUNT MORRIS"
  },
  "61057": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NACHUSA"
  },
  "61059": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NORA"
  },
  "61060": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ORANGEVILLE"
  },
  "61061": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OREGON"
  },
  "61062": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PEARL CITY"
  },
  "61063": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PECATONICA"
  },
  "61064": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "POLO"
  },
  "61065": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "POPLAR GROVE"
  },
  "61067": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RIDOTT"
  },
  "61068": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROCHELLE"
  },
  "61070": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROCK CITY"
  },
  "61071": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROCK FALLS"
  },
  "61072": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROCKTON"
  },
  "61073": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROSCOE"
  },
  "61074": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SAVANNA"
  },
  "61075": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SCALES MOUND"
  },
  "61077": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SEWARD"
  },
  "61078": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SHANNON"
  },
  "61080": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SOUTH BELOIT"
  },
  "61081": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STERLING"
  },
  "61084": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STILLMAN VALLEY"
  },
  "61085": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STOCKTON"
  },
  "61087": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WARREN"
  },
  "61088": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WINNEBAGO"
  },
  "61089": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WINSLOW"
  },
  "61091": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WOOSUNG"
  },
  "61101": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROCKFORD"
  },
  "61102": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROCKFORD"
  },
  "61103": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROCKFORD"
  },
  "61104": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROCKFORD"
  },
  "61107": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROCKFORD"
  },
  "61108": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROCKFORD"
  },
  "61109": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROCKFORD"
  },
  "61111": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LOVES PARK"
  },
  "61112": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROCKFORD"
  },
  "61114": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROCKFORD"
  },
  "61115": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MACHESNEY PARK"
  },
  "61201": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROCK ISLAND"
  },
  "61230": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALBANY"
  },
  "61231": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALEDO"
  },
  "61232": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ANDALUSIA"
  },
  "61233": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ANDOVER"
  },
  "61234": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ANNAWAN"
  },
  "61235": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ATKINSON"
  },
  "61236": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BARSTOW"
  },
  "61238": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CAMBRIDGE"
  },
  "61239": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CARBON CLIFF"
  },
  "61240": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COAL VALLEY"
  },
  "61241": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COLONA"
  },
  "61242": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CORDOVA"
  },
  "61243": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DEER GROVE"
  },
  "61244": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EAST MOLINE"
  },
  "61250": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ERIE"
  },
  "61251": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FENTON"
  },
  "61252": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FULTON"
  },
  "61254": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GENESEO"
  },
  "61256": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HAMPTON"
  },
  "61257": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HILLSDALE"
  },
  "61259": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ILLINOIS CITY"
  },
  "61260": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "JOY"
  },
  "61261": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LYNDON"
  },
  "61262": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LYNN CENTER"
  },
  "61263": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MATHERVILLE"
  },
  "61264": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MILAN"
  },
  "61265": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MOLINE"
  },
  "61270": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MORRISON"
  },
  "61272": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEW BOSTON"
  },
  "61273": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ORION"
  },
  "61274": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OSCO"
  },
  "61275": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PORT BYRON"
  },
  "61276": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PREEMPTION"
  },
  "61277": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PROPHETSTOWN"
  },
  "61279": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "REYNOLDS"
  },
  "61281": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SHERRARD"
  },
  "61282": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SILVIS"
  },
  "61283": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TAMPICO"
  },
  "61284": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TAYLOR RIDGE"
  },
  "61285": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "THOMSON"
  },
  "61301": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LA SALLE"
  },
  "61310": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "AMBOY"
  },
  "61311": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ANCONA"
  },
  "61312": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ARLINGTON"
  },
  "61313": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BLACKSTONE"
  },
  "61314": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BUDA"
  },
  "61315": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BUREAU"
  },
  "61316": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CEDAR POINT"
  },
  "61317": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHERRY"
  },
  "61318": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COMPTON"
  },
  "61319": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CORNELL"
  },
  "61320": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DALZELL"
  },
  "61321": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DANA"
  },
  "61322": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DEPUE"
  },
  "61323": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DOVER"
  },
  "61324": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELDENA"
  },
  "61325": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GRAND RIDGE"
  },
  "61326": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GRANVILLE"
  },
  "61327": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HENNEPIN"
  },
  "61328": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KASBEER"
  },
  "61329": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LADD"
  },
  "61330": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LA MOILLE"
  },
  "61331": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LEE CENTER"
  },
  "61332": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LEONORE"
  },
  "61333": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LONG POINT"
  },
  "61334": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LOSTANT"
  },
  "61335": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MC NABB"
  },
  "61336": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MAGNOLIA"
  },
  "61337": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MALDEN"
  },
  "61338": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MANLIUS"
  },
  "61340": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MARK"
  },
  "61341": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MARSEILLES"
  },
  "61342": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MENDOTA"
  },
  "61344": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MINERAL"
  },
  "61345": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEPONSET"
  },
  "61346": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEW BEDFORD"
  },
  "61348": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OGLESBY"
  },
  "61349": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OHIO"
  },
  "61350": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OTTAWA"
  },
  "61353": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PAW PAW"
  },
  "61354": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PERU"
  },
  "61356": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PRINCETON"
  },
  "61358": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RUTLAND"
  },
  "61359": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SEATONVILLE"
  },
  "61360": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SENECA"
  },
  "61361": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SHEFFIELD"
  },
  "61362": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SPRING VALLEY"
  },
  "61363": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STANDARD"
  },
  "61364": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STREATOR"
  },
  "61367": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SUBLETTE"
  },
  "61368": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TISKILWA"
  },
  "61369": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TOLUCA"
  },
  "61370": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TONICA"
  },
  "61372": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TROY GROVE"
  },
  "61373": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "UTICA"
  },
  "61374": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VAN ORIN"
  },
  "61375": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VARNA"
  },
  "61376": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WALNUT"
  },
  "61377": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WENONA"
  },
  "61378": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WEST BROOKLYN"
  },
  "61379": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WYANET"
  },
  "61401": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GALESBURG"
  },
  "61410": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ABINGDON"
  },
  "61411": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ADAIR"
  },
  "61412": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALEXIS"
  },
  "61413": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALPHA"
  },
  "61414": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALTONA"
  },
  "61415": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "AVON"
  },
  "61416": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BARDOLPH"
  },
  "61417": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BERWICK"
  },
  "61418": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BIGGSVILLE"
  },
  "61419": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BISHOP HILL"
  },
  "61420": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BLANDINSVILLE"
  },
  "61421": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BRADFORD"
  },
  "61422": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BUSHNELL"
  },
  "61423": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CAMERON"
  },
  "61424": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CAMP GROVE"
  },
  "61425": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CARMAN"
  },
  "61426": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CASTLETON"
  },
  "61427": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CUBA"
  },
  "61428": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DAHINDA"
  },
  "61430": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EAST GALESBURG"
  },
  "61431": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELLISVILLE"
  },
  "61432": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FAIRVIEW"
  },
  "61434": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GALVA"
  },
  "61435": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GERLAW"
  },
  "61436": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GILSON"
  },
  "61437": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GLADSTONE"
  },
  "61438": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GOOD HOPE"
  },
  "61439": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HENDERSON"
  },
  "61440": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "INDUSTRY"
  },
  "61441": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "IPAVA"
  },
  "61442": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KEITHSBURG"
  },
  "61443": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KEWANEE"
  },
  "61447": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KIRKWOOD"
  },
  "61448": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KNOXVILLE"
  },
  "61449": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LA FAYETTE"
  },
  "61450": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LA HARPE"
  },
  "61451": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LAURA"
  },
  "61452": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LITTLETON"
  },
  "61453": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LITTLE YORK"
  },
  "61454": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LOMAX"
  },
  "61455": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MACOMB"
  },
  "61458": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MAQUON"
  },
  "61459": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MARIETTA"
  },
  "61460": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MEDIA"
  },
  "61462": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MONMOUTH"
  },
  "61465": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEW WINDSOR"
  },
  "61466": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NORTH HENDERSON"
  },
  "61467": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ONEIDA"
  },
  "61468": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OPHIEM"
  },
  "61469": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OQUAWKA"
  },
  "61470": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PRAIRIE CITY"
  },
  "61471": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RARITAN"
  },
  "61472": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RIO"
  },
  "61473": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROSEVILLE"
  },
  "61474": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SAINT AUGUSTINE"
  },
  "61475": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SCIOTA"
  },
  "61476": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SEATON"
  },
  "61477": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SMITHFIELD"
  },
  "61478": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SMITHSHIRE"
  },
  "61479": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SPEER"
  },
  "61480": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STRONGHURST"
  },
  "61482": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TABLE GROVE"
  },
  "61483": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TOULON"
  },
  "61484": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VERMONT"
  },
  "61485": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VICTORIA"
  },
  "61486": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VIOLA"
  },
  "61488": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WATAGA"
  },
  "61489": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WILLIAMSFIELD"
  },
  "61490": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WOODHULL"
  },
  "61491": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WYOMING"
  },
  "61501": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ASTORIA"
  },
  "61516": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BENSON"
  },
  "61517": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BRIMFIELD"
  },
  "61519": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BRYANT"
  },
  "61520": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CANTON"
  },
  "61523": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHILLICOTHE"
  },
  "61524": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DUNFERMLINE"
  },
  "61525": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DUNLAP"
  },
  "61526": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EDELSTEIN"
  },
  "61528": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EDWARDS"
  },
  "61529": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELMWOOD"
  },
  "61530": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EUREKA"
  },
  "61531": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FARMINGTON"
  },
  "61532": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FOREST CITY"
  },
  "61533": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GLASFORD"
  },
  "61534": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GREEN VALLEY"
  },
  "61535": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GROVELAND"
  },
  "61536": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HANNA CITY"
  },
  "61537": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HENRY"
  },
  "61539": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KINGSTON MINES"
  },
  "61540": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LACON"
  },
  "61541": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LA ROSE"
  },
  "61542": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LEWISTOWN"
  },
  "61543": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LIVERPOOL"
  },
  "61544": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LONDON MILLS"
  },
  "61545": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LOWPOINT"
  },
  "61546": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MANITO"
  },
  "61547": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MAPLETON"
  },
  "61548": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "METAMORA"
  },
  "61550": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MORTON"
  },
  "61552": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MOSSVILLE"
  },
  "61554": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PEKIN"
  },
  "61559": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PRINCEVILLE"
  },
  "61560": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PUTNAM"
  },
  "61561": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROANOKE"
  },
  "61563": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SAINT DAVID"
  },
  "61564": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SOUTH PEKIN"
  },
  "61565": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SPARLAND"
  },
  "61567": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TOPEKA"
  },
  "61568": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TREMONT"
  },
  "61569": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TRIVOLI"
  },
  "61570": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WASHBURN"
  },
  "61571": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WASHINGTON"
  },
  "61572": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "YATES CITY"
  },
  "61602": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PEORIA"
  },
  "61603": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PEORIA"
  },
  "61604": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PEORIA"
  },
  "61605": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PEORIA"
  },
  "61606": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PEORIA"
  },
  "61607": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PEORIA"
  },
  "61610": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CREVE COEUR"
  },
  "61611": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EAST PEORIA"
  },
  "61614": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PEORIA"
  },
  "61615": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PEORIA"
  },
  "61701": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BLOOMINGTON"
  },
  "61704": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BLOOMINGTON"
  },
  "61720": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ANCHOR"
  },
  "61721": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ARMINGTON"
  },
  "61722": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ARROWSMITH"
  },
  "61723": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ATLANTA"
  },
  "61724": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BELLFLOWER"
  },
  "61725": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CARLOCK"
  },
  "61726": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHENOA"
  },
  "61727": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CLINTON"
  },
  "61728": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COLFAX"
  },
  "61729": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CONGERVILLE"
  },
  "61730": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COOKSVILLE"
  },
  "61731": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CROPSEY"
  },
  "61732": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DANVERS"
  },
  "61733": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DEER CREEK"
  },
  "61734": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DELAVAN"
  },
  "61735": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DEWITT"
  },
  "61736": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DOWNS"
  },
  "61737": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELLSWORTH"
  },
  "61738": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EL PASO"
  },
  "61739": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FAIRBURY"
  },
  "61740": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FLANAGAN"
  },
  "61741": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FORREST"
  },
  "61742": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GOODFIELD"
  },
  "61743": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GRAYMONT"
  },
  "61744": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GRIDLEY"
  },
  "61745": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HEYWORTH"
  },
  "61747": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HOPEDALE"
  },
  "61748": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HUDSON"
  },
  "61749": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KENNEY"
  },
  "61750": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LANE"
  },
  "61752": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LE ROY"
  },
  "61753": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LEXINGTON"
  },
  "61754": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MC LEAN"
  },
  "61755": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MACKINAW"
  },
  "61756": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MAROA"
  },
  "61758": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MERNA"
  },
  "61759": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MINIER"
  },
  "61760": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MINONK"
  },
  "61761": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NORMAL"
  },
  "61764": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PONTIAC"
  },
  "61769": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SAUNEMIN"
  },
  "61770": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SAYBROOK"
  },
  "61771": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SECOR"
  },
  "61772": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SHIRLEY"
  },
  "61773": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SIBLEY"
  },
  "61774": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STANFORD"
  },
  "61775": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STRAWN"
  },
  "61776": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TOWANDA"
  },
  "61777": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WAPELLA"
  },
  "61778": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WAYNESVILLE"
  },
  "61801": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "URBANA"
  },
  "61802": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "URBANA"
  },
  "61810": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALLERTON"
  },
  "61811": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALVIN"
  },
  "61812": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ARMSTRONG"
  },
  "61813": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BEMENT"
  },
  "61814": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BISMARCK"
  },
  "61815": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BONDVILLE"
  },
  "61816": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BROADLANDS"
  },
  "61817": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CATLIN"
  },
  "61818": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CERRO GORDO"
  },
  "61820": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHAMPAIGN"
  },
  "61821": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHAMPAIGN"
  },
  "61822": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHAMPAIGN"
  },
  "61830": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CISCO"
  },
  "61831": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COLLISON"
  },
  "61832": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DANVILLE"
  },
  "61833": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TILTON"
  },
  "61834": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DANVILLE"
  },
  "61839": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DE LAND"
  },
  "61840": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DEWEY"
  },
  "61841": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FAIRMOUNT"
  },
  "61842": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FARMER CITY"
  },
  "61843": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FISHER"
  },
  "61844": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FITHIAN"
  },
  "61845": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FOOSLAND"
  },
  "61846": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GEORGETOWN"
  },
  "61847": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GIFFORD"
  },
  "61848": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HENNING"
  },
  "61849": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HOMER"
  },
  "61850": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "INDIANOLA"
  },
  "61851": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "IVESDALE"
  },
  "61852": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LONGVIEW"
  },
  "61853": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MAHOMET"
  },
  "61854": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MANSFIELD"
  },
  "61855": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MILMINE"
  },
  "61856": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MONTICELLO"
  },
  "61857": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MUNCIE"
  },
  "61858": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OAKWOOD"
  },
  "61859": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OGDEN"
  },
  "61862": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PENFIELD"
  },
  "61863": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PESOTUM"
  },
  "61864": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PHILO"
  },
  "61865": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "POTOMAC"
  },
  "61866": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RANTOUL"
  },
  "61870": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RIDGE FARM"
  },
  "61871": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROYAL"
  },
  "61872": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SADORUS"
  },
  "61873": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SAINT JOSEPH"
  },
  "61874": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SAVOY"
  },
  "61875": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SEYMOUR"
  },
  "61876": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SIDELL"
  },
  "61877": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SIDNEY"
  },
  "61878": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "THOMASBORO"
  },
  "61880": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TOLONO"
  },
  "61882": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WELDON"
  },
  "61883": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WESTVILLE"
  },
  "61884": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WHITE HEATH"
  },
  "61910": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ARCOLA"
  },
  "61911": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ARTHUR"
  },
  "61912": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ASHMORE"
  },
  "61913": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ATWOOD"
  },
  "61914": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BETHANY"
  },
  "61917": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BROCTON"
  },
  "61919": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CAMARGO"
  },
  "61920": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHARLESTON"
  },
  "61924": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHRISMAN"
  },
  "61925": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DALTON CITY"
  },
  "61928": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GAYS"
  },
  "61929": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HAMMOND"
  },
  "61930": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HINDSBORO"
  },
  "61931": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HUMBOLDT"
  },
  "61932": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HUME"
  },
  "61933": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KANSAS"
  },
  "61936": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LA PLACE"
  },
  "61937": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LOVINGTON"
  },
  "61938": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MATTOON"
  },
  "61940": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "METCALF"
  },
  "61941": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MURDOCK"
  },
  "61942": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEWMAN"
  },
  "61943": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OAKLAND"
  },
  "61944": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PARIS"
  },
  "61949": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "REDMON"
  },
  "61951": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SULLIVAN"
  },
  "61953": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TUSCOLA"
  },
  "61956": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VILLA GROVE"
  },
  "61957": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WINDSOR"
  },
  "62001": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALHAMBRA"
  },
  "62002": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALTON"
  },
  "62006": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BATCHTOWN"
  },
  "62009": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BENLD"
  },
  "62010": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BETHALTO"
  },
  "62011": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BINGHAM"
  },
  "62012": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BRIGHTON"
  },
  "62013": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BRUSSELS"
  },
  "62014": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BUNKER HILL"
  },
  "62015": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BUTLER"
  },
  "62016": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CARROLLTON"
  },
  "62017": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COFFEEN"
  },
  "62018": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COTTAGE HILLS"
  },
  "62019": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DONNELLSON"
  },
  "62021": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DORSEY"
  },
  "62022": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DOW"
  },
  "62023": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EAGARVILLE"
  },
  "62024": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EAST ALTON"
  },
  "62025": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EDWARDSVILLE"
  },
  "62027": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELDRED"
  },
  "62028": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELSAH"
  },
  "62030": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FIDELITY"
  },
  "62031": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FIELDON"
  },
  "62032": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FILLMORE"
  },
  "62033": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GILLESPIE"
  },
  "62034": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GLEN CARBON"
  },
  "62035": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GODFREY"
  },
  "62036": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GOLDEN EAGLE"
  },
  "62037": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GRAFTON"
  },
  "62040": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GRANITE CITY"
  },
  "62044": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GREENFIELD"
  },
  "62045": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HAMBURG"
  },
  "62046": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HAMEL"
  },
  "62047": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HARDIN"
  },
  "62048": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HARTFORD"
  },
  "62049": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HILLSBORO"
  },
  "62050": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HILLVIEW"
  },
  "62051": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "IRVING"
  },
  "62052": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "JERSEYVILLE"
  },
  "62053": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KAMPSVILLE"
  },
  "62054": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KANE"
  },
  "62056": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LITCHFIELD"
  },
  "62058": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LIVINGSTON"
  },
  "62059": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LOVEJOY"
  },
  "62060": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MADISON"
  },
  "62061": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MARINE"
  },
  "62062": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MARYVILLE"
  },
  "62063": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MEDORA"
  },
  "62065": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MICHAEL"
  },
  "62067": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MORO"
  },
  "62069": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MOUNT OLIVE"
  },
  "62070": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MOZIER"
  },
  "62074": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEW DOUGLAS"
  },
  "62075": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NOKOMIS"
  },
  "62077": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PANAMA"
  },
  "62078": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PATTERSON"
  },
  "62079": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PIASA"
  },
  "62080": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RAMSEY"
  },
  "62081": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROCKBRIDGE"
  },
  "62082": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROODHOUSE"
  },
  "62083": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROSAMOND"
  },
  "62084": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROXANA"
  },
  "62085": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SAWYERVILLE"
  },
  "62086": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SORENTO"
  },
  "62087": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SOUTH ROXANA"
  },
  "62088": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STAUNTON"
  },
  "62089": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TAYLOR SPRINGS"
  },
  "62090": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VENICE"
  },
  "62091": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WALSHVILLE"
  },
  "62092": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WHITE HALL"
  },
  "62093": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WILSONVILLE"
  },
  "62094": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WITT"
  },
  "62095": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WOOD RIVER"
  },
  "62097": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WORDEN"
  },
  "62201": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EAST SAINT LOUIS"
  },
  "62203": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EAST SAINT LOUIS"
  },
  "62204": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EAST SAINT LOUIS"
  },
  "62205": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EAST SAINT LOUIS"
  },
  "62206": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EAST SAINT LOUIS"
  },
  "62207": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EAST SAINT LOUIS"
  },
  "62208": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FAIRVIEW HEIGHTS"
  },
  "62214": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ADDIEVILLE"
  },
  "62215": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALBERS"
  },
  "62216": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "AVISTON"
  },
  "62217": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BALDWIN"
  },
  "62218": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BARTELSO"
  },
  "62219": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BECKEMEYER"
  },
  "62220": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BELLEVILLE"
  },
  "62221": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BELLEVILLE"
  },
  "62223": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BELLEVILLE"
  },
  "62225": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SCOTT AIR FORCE BASE"
  },
  "62226": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BELLEVILLE"
  },
  "62230": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BREESE"
  },
  "62231": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CARLYLE"
  },
  "62232": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CASEYVILLE"
  },
  "62233": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHESTER"
  },
  "62234": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COLLINSVILLE"
  },
  "62236": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COLUMBIA"
  },
  "62237": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COULTERVILLE"
  },
  "62238": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CUTLER"
  },
  "62239": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DUPO"
  },
  "62240": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EAST CARONDELET"
  },
  "62241": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELLIS GROVE"
  },
  "62242": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EVANSVILLE"
  },
  "62243": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FREEBURG"
  },
  "62244": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FULTS"
  },
  "62245": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GERMANTOWN"
  },
  "62246": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GREENVILLE"
  },
  "62249": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HIGHLAND"
  },
  "62250": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HOFFMAN"
  },
  "62253": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KEYESPORT"
  },
  "62254": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LEBANON"
  },
  "62255": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LENZBURG"
  },
  "62257": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MARISSA"
  },
  "62258": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MASCOUTAH"
  },
  "62260": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MILLSTADT"
  },
  "62261": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MODOC"
  },
  "62262": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MULBERRY GROVE"
  },
  "62263": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NASHVILLE"
  },
  "62264": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEW ATHENS"
  },
  "62265": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEW BADEN"
  },
  "62266": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEW MEMPHIS"
  },
  "62268": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OAKDALE"
  },
  "62269": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "O FALLON"
  },
  "62271": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OKAWVILLE"
  },
  "62272": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PERCY"
  },
  "62273": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PIERRON"
  },
  "62274": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PINCKNEYVILLE"
  },
  "62275": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "POCAHONTAS"
  },
  "62277": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PRAIRIE DU ROCHER"
  },
  "62278": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RED BUD"
  },
  "62280": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROCKWOOD"
  },
  "62281": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SAINT JACOB"
  },
  "62284": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SMITHBORO"
  },
  "62285": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SMITHTON"
  },
  "62286": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SPARTA"
  },
  "62288": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STEELEVILLE"
  },
  "62289": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SUMMERFIELD"
  },
  "62292": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TILDEN"
  },
  "62293": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TRENTON"
  },
  "62294": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TROY"
  },
  "62295": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VALMEYER"
  },
  "62297": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WALSH"
  },
  "62298": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WATERLOO"
  },
  "62301": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "QUINCY"
  },
  "62311": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "AUGUSTA"
  },
  "62312": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BARRY"
  },
  "62313": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BASCO"
  },
  "62314": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BAYLIS"
  },
  "62316": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BOWEN"
  },
  "62319": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CAMDEN"
  },
  "62320": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CAMP POINT"
  },
  "62321": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CARTHAGE"
  },
  "62323": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHAMBERSBURG"
  },
  "62324": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CLAYTON"
  },
  "62325": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COATSBURG"
  },
  "62326": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COLCHESTER"
  },
  "62330": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DALLAS CITY"
  },
  "62334": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELVASTON"
  },
  "62336": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FERRIS"
  },
  "62338": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FOWLER"
  },
  "62339": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GOLDEN"
  },
  "62340": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GRIGGSVILLE"
  },
  "62341": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HAMILTON"
  },
  "62343": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HULL"
  },
  "62344": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HUNTSVILLE"
  },
  "62345": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KINDERHOOK"
  },
  "62346": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LA PRAIRIE"
  },
  "62347": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LIBERTY"
  },
  "62348": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LIMA"
  },
  "62349": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LORAINE"
  },
  "62351": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MENDON"
  },
  "62352": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MILTON"
  },
  "62353": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MOUNT STERLING"
  },
  "62354": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NAUVOO"
  },
  "62355": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEBO"
  },
  "62356": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEW CANTON"
  },
  "62357": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEW SALEM"
  },
  "62358": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NIOTA"
  },
  "62359": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PALOMA"
  },
  "62360": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PAYSON"
  },
  "62361": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PEARL"
  },
  "62362": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PERRY"
  },
  "62363": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PITTSFIELD"
  },
  "62365": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PLAINVILLE"
  },
  "62366": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PLEASANT HILL"
  },
  "62367": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PLYMOUTH"
  },
  "62370": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROCKPORT"
  },
  "62373": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SUTTER"
  },
  "62374": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TENNESSEE"
  },
  "62375": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TIMEWELL"
  },
  "62376": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "URSA"
  },
  "62378": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VERSAILLES"
  },
  "62379": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WARSAW"
  },
  "62380": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WEST POINT"
  },
  "62401": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EFFINGHAM"
  },
  "62410": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALLENDALE"
  },
  "62411": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALTAMONT"
  },
  "62413": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ANNAPOLIS"
  },
  "62414": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BEECHER CITY"
  },
  "62417": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BRIDGEPORT"
  },
  "62418": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BROWNSTOWN"
  },
  "62419": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CALHOUN"
  },
  "62420": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CASEY"
  },
  "62421": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CLAREMONT"
  },
  "62422": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COWDEN"
  },
  "62423": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DENNISON"
  },
  "62424": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DIETERICH"
  },
  "62425": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DUNDAS"
  },
  "62426": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EDGEWOOD"
  },
  "62427": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FLAT ROCK"
  },
  "62428": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GREENUP"
  },
  "62431": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HERRICK"
  },
  "62432": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HIDALGO"
  },
  "62433": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HUTSONVILLE"
  },
  "62434": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "INGRAHAM"
  },
  "62436": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "JEWETT"
  },
  "62438": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LAKEWOOD"
  },
  "62439": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LAWRENCEVILLE"
  },
  "62440": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LERNA"
  },
  "62441": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MARSHALL"
  },
  "62442": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MARTINSVILLE"
  },
  "62443": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MASON"
  },
  "62444": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MODE"
  },
  "62445": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MONTROSE"
  },
  "62446": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MOUNT ERIE"
  },
  "62447": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEOGA"
  },
  "62448": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEWTON"
  },
  "62449": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OBLONG"
  },
  "62450": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OLNEY"
  },
  "62451": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PALESTINE"
  },
  "62452": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PARKERSBURG"
  },
  "62454": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROBINSON"
  },
  "62458": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SAINT ELMO"
  },
  "62459": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SAINTE MARIE"
  },
  "62460": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SAINT FRANCISVILLE"
  },
  "62461": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SHUMWAY"
  },
  "62462": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SIGEL"
  },
  "62463": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STEWARDSON"
  },
  "62465": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STRASBURG"
  },
  "62466": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SUMNER"
  },
  "62467": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TEUTOPOLIS"
  },
  "62468": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TOLEDO"
  },
  "62469": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TRILLA"
  },
  "62471": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VANDALIA"
  },
  "62473": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WATSON"
  },
  "62474": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WESTFIELD"
  },
  "62475": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WEST LIBERTY"
  },
  "62476": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WEST SALEM"
  },
  "62477": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WEST UNION"
  },
  "62478": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WEST YORK"
  },
  "62479": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WHEELER"
  },
  "62480": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WILLOW HILL"
  },
  "62481": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "YALE"
  },
  "62501": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ARGENTA"
  },
  "62510": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ASSUMPTION"
  },
  "62512": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BEASON"
  },
  "62513": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BLUE MOUND"
  },
  "62514": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BOODY"
  },
  "62515": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BUFFALO"
  },
  "62517": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BULPITT"
  },
  "62518": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHESTNUT"
  },
  "62519": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CORNLAND"
  },
  "62520": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DAWSON"
  },
  "62521": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DECATUR"
  },
  "62522": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DECATUR"
  },
  "62523": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DECATUR"
  },
  "62526": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DECATUR"
  },
  "62530": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DIVERNON"
  },
  "62531": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EDINBURG"
  },
  "62532": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELWIN"
  },
  "62533": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FARMERSVILLE"
  },
  "62534": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FINDLAY"
  },
  "62535": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FORSYTH"
  },
  "62536": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GLENARM"
  },
  "62537": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HARRISTOWN"
  },
  "62538": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HARVEL"
  },
  "62539": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ILLIOPOLIS"
  },
  "62540": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KINCAID"
  },
  "62541": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LAKE FORK"
  },
  "62543": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LATHAM"
  },
  "62544": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MACON"
  },
  "62545": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MECHANICSBURG"
  },
  "62546": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MORRISONVILLE"
  },
  "62547": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MOUNT AUBURN"
  },
  "62548": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MOUNT PULASKI"
  },
  "62549": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MT ZION"
  },
  "62550": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MOWEAQUA"
  },
  "62551": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NIANTIC"
  },
  "62553": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OCONEE"
  },
  "62554": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OREANA"
  },
  "62555": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OWANECO"
  },
  "62556": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PALMER"
  },
  "62557": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PANA"
  },
  "62558": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PAWNEE"
  },
  "62560": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RAYMOND"
  },
  "62561": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RIVERTON"
  },
  "62563": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROCHESTER"
  },
  "62565": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SHELBYVILLE"
  },
  "62567": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STONINGTON"
  },
  "62568": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TAYLORVILLE"
  },
  "62570": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TOVEY"
  },
  "62571": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TOWER HILL"
  },
  "62572": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WAGGONER"
  },
  "62573": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WARRENSBURG"
  },
  "62601": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALEXANDER"
  },
  "62610": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALSEY"
  },
  "62611": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ARENZVILLE"
  },
  "62612": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ASHLAND"
  },
  "62613": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ATHENS"
  },
  "62615": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "AUBURN"
  },
  "62617": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BATH"
  },
  "62618": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BEARDSTOWN"
  },
  "62621": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BLUFFS"
  },
  "62624": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BROWNING"
  },
  "62625": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CANTRALL"
  },
  "62626": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CARLINVILLE"
  },
  "62627": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHANDLERVILLE"
  },
  "62628": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHAPIN"
  },
  "62629": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHATHAM"
  },
  "62630": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHESTERFIELD"
  },
  "62631": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CONCORD"
  },
  "62633": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EASTON"
  },
  "62634": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELKHART"
  },
  "62635": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EMDEN"
  },
  "62638": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FRANKLIN"
  },
  "62639": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FREDERICK"
  },
  "62640": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GIRARD"
  },
  "62642": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GREENVIEW"
  },
  "62643": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HARTSBURG"
  },
  "62644": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HAVANA"
  },
  "62649": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HETTICK"
  },
  "62650": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "JACKSONVILLE"
  },
  "62655": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KILBOURNE"
  },
  "62656": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LINCOLN"
  },
  "62661": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LOAMI"
  },
  "62663": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MANCHESTER"
  },
  "62664": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MASON CITY"
  },
  "62665": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MEREDOSIA"
  },
  "62666": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MIDDLETOWN"
  },
  "62667": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MODESTO"
  },
  "62668": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MURRAYVILLE"
  },
  "62670": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEW BERLIN"
  },
  "62671": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEW HOLLAND"
  },
  "62672": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NILWOOD"
  },
  "62673": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OAKFORD"
  },
  "62674": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PALMYRA"
  },
  "62675": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PETERSBURG"
  },
  "62677": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PLEASANT PLAINS"
  },
  "62681": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RUSHVILLE"
  },
  "62682": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SAN JOSE"
  },
  "62684": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SHERMAN"
  },
  "62685": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SHIPMAN"
  },
  "62688": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TALLULA"
  },
  "62689": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "THAYER"
  },
  "62690": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VIRDEN"
  },
  "62691": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VIRGINIA"
  },
  "62692": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WAVERLY"
  },
  "62693": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WILLIAMSVILLE"
  },
  "62694": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WINCHESTER"
  },
  "62695": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WOODSON"
  },
  "62701": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SPRINGFIELD"
  },
  "62702": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SPRINGFIELD"
  },
  "62703": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SPRINGFIELD"
  },
  "62704": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SPRINGFIELD"
  },
  "62707": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SPRINGFIELD"
  },
  "62801": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CENTRALIA"
  },
  "62803": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HOYLETON"
  },
  "62806": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALBION"
  },
  "62807": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALMA"
  },
  "62808": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ASHLEY"
  },
  "62809": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BARNHILL"
  },
  "62810": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BELLE RIVE"
  },
  "62811": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BELLMONT"
  },
  "62812": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BENTON"
  },
  "62814": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BLUFORD"
  },
  "62815": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BONE GAP"
  },
  "62816": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BONNIE"
  },
  "62817": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BROUGHTON"
  },
  "62818": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BROWNS"
  },
  "62819": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BUCKNER"
  },
  "62820": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BURNT PRAIRIE"
  },
  "62821": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CARMI"
  },
  "62822": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CHRISTOPHER"
  },
  "62823": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CISNE"
  },
  "62824": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CLAY CITY"
  },
  "62825": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COELLO"
  },
  "62827": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CROSSVILLE"
  },
  "62828": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DAHLGREN"
  },
  "62829": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DALE"
  },
  "62830": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DIX"
  },
  "62831": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DU BOIS"
  },
  "62832": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DU QUOIN"
  },
  "62833": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELLERY"
  },
  "62835": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ENFIELD"
  },
  "62836": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EWING"
  },
  "62837": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FAIRFIELD"
  },
  "62838": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FARINA"
  },
  "62839": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "FLORA"
  },
  "62842": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GEFF"
  },
  "62843": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GOLDEN GATE"
  },
  "62844": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GRAYVILLE"
  },
  "62846": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "INA"
  },
  "62848": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "IRVINGTON"
  },
  "62849": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "IUKA"
  },
  "62850": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "JOHNSONVILLE"
  },
  "62851": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KEENES"
  },
  "62852": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KEENSBURG"
  },
  "62853": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KELL"
  },
  "62854": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KINMUNDY"
  },
  "62857": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LOOGOOTEE"
  },
  "62858": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "LOUISVILLE"
  },
  "62859": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MC LEANSBORO"
  },
  "62860": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MACEDONIA"
  },
  "62861": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MAUNIE"
  },
  "62862": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MILL SHOALS"
  },
  "62863": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MOUNT CARMEL"
  },
  "62864": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MOUNT VERNON"
  },
  "62865": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MULKEYTOWN"
  },
  "62867": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEW HAVEN"
  },
  "62868": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NOBLE"
  },
  "62869": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NORRIS CITY"
  },
  "62870": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ODIN"
  },
  "62871": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OMAHA"
  },
  "62872": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OPDYKE"
  },
  "62874": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ORIENT"
  },
  "62875": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PATOKA"
  },
  "62876": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RADOM"
  },
  "62877": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RICHVIEW"
  },
  "62878": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RINARD"
  },
  "62879": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SAILOR SPRINGS"
  },
  "62880": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SAINT PETER"
  },
  "62881": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SALEM"
  },
  "62882": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SANDOVAL"
  },
  "62883": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SCHELLER"
  },
  "62884": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SESSER"
  },
  "62885": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SHOBONIER"
  },
  "62886": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SIMS"
  },
  "62887": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SPRINGERTON"
  },
  "62888": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TAMAROA"
  },
  "62889": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TEXICO"
  },
  "62890": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "THOMPSONVILLE"
  },
  "62891": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VALIER"
  },
  "62892": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VERNON"
  },
  "62893": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WALNUT HILL"
  },
  "62894": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WALTONVILLE"
  },
  "62895": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WAYNE CITY"
  },
  "62896": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WEST FRANKFORT"
  },
  "62897": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WHITTINGTON"
  },
  "62898": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WOODLAWN"
  },
  "62899": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "XENIA"
  },
  "62901": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CARBONDALE"
  },
  "62905": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ALTO PASS"
  },
  "62906": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ANNA"
  },
  "62907": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "AVA"
  },
  "62908": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BELKNAP"
  },
  "62910": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BROOKPORT"
  },
  "62912": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "BUNCOMBE"
  },
  "62914": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CAIRO"
  },
  "62915": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CAMBRIA"
  },
  "62916": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CAMPBELL HILL"
  },
  "62917": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CARRIER MILLS"
  },
  "62918": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CARTERVILLE"
  },
  "62919": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CAVE IN ROCK"
  },
  "62920": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COBDEN"
  },
  "62921": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "COLP"
  },
  "62922": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CREAL SPRINGS"
  },
  "62923": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "CYPRESS"
  },
  "62924": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DE SOTO"
  },
  "62926": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DONGOLA"
  },
  "62927": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "DOWELL"
  },
  "62928": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EDDYVILLE"
  },
  "62930": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELDORADO"
  },
  "62931": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELIZABETHTOWN"
  },
  "62932": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ELKVILLE"
  },
  "62933": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ENERGY"
  },
  "62934": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "EQUALITY"
  },
  "62935": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GALATIA"
  },
  "62938": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GOLCONDA"
  },
  "62939": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GOREVILLE"
  },
  "62940": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GORHAM"
  },
  "62941": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GRAND CHAIN"
  },
  "62942": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GRAND TOWER"
  },
  "62943": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "GRANTSBURG"
  },
  "62946": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HARRISBURG"
  },
  "62947": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HEROD"
  },
  "62948": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HERRIN"
  },
  "62949": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "HURST"
  },
  "62950": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "JACOB"
  },
  "62951": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "JOHNSTON CITY"
  },
  "62952": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "JONESBORO"
  },
  "62953": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "JOPPA"
  },
  "62954": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "JUNCTION"
  },
  "62956": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "KARNAK"
  },
  "62957": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MC CLURE"
  },
  "62958": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MAKANDA"
  },
  "62959": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MARION"
  },
  "62960": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "METROPOLIS"
  },
  "62962": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MILLER CITY"
  },
  "62963": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MOUND CITY"
  },
  "62964": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MOUNDS"
  },
  "62966": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "MURPHYSBORO"
  },
  "62967": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "NEW BURNSIDE"
  },
  "62969": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OLIVE BRANCH"
  },
  "62970": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OLMSTED"
  },
  "62972": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "OZARK"
  },
  "62974": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PITTSBURG"
  },
  "62975": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "POMONA"
  },
  "62976": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "PULASKI"
  },
  "62977": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RALEIGH"
  },
  "62979": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "RIDGWAY"
  },
  "62982": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROSICLARE"
  },
  "62983": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ROYALTON"
  },
  "62984": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SHAWNEETOWN"
  },
  "62985": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "SIMPSON"
  },
  "62987": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "STONEFORT"
  },
  "62988": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "TAMMS"
  },
  "62990": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "THEBES"
  },
  "62992": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ULLIN"
  },
  "62993": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "UNITY"
  },
  "62994": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VERGENNES"
  },
  "62995": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VIENNA"
  },
  "62996": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "VILLA RIDGE"
  },
  "62997": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WILLISVILLE"
  },
  "62998": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "WOLF LAKE"
  },
  "62999": {
    stateCode: "IL",
    stateName: "Illinois",
    city: "ZEIGLER"
  },
  "63005": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CHESTERFIELD"
  },
  "63010": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ARNOLD"
  },
  "63011": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BALLWIN"
  },
  "63012": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BARNHART"
  },
  "63013": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BEAUFORT"
  },
  "63014": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BERGER"
  },
  "63015": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CATAWISSA"
  },
  "63016": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CEDAR HILL"
  },
  "63017": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CHESTERFIELD"
  },
  "63019": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CRYSTAL CITY"
  },
  "63020": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DE SOTO"
  },
  "63021": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BALLWIN"
  },
  "63023": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DITTMER"
  },
  "63025": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EUREKA"
  },
  "63026": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FENTON"
  },
  "63028": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FESTUS"
  },
  "63030": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FLETCHER"
  },
  "63031": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FLORISSANT"
  },
  "63033": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FLORISSANT"
  },
  "63034": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FLORISSANT"
  },
  "63036": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FRENCH VILLAGE"
  },
  "63037": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GERALD"
  },
  "63038": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GLENCOE"
  },
  "63039": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GRAY SUMMIT"
  },
  "63040": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GROVER"
  },
  "63041": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GRUBVILLE"
  },
  "63042": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HAZELWOOD"
  },
  "63043": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MARYLAND HEIGHTS"
  },
  "63044": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BRIDGETON"
  },
  "63048": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HERCULANEUM"
  },
  "63049": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HIGH RIDGE"
  },
  "63050": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HILLSBORO"
  },
  "63051": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HOUSE SPRINGS"
  },
  "63052": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "IMPERIAL"
  },
  "63055": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LABADIE"
  },
  "63056": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LESLIE"
  },
  "63060": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LONEDELL"
  },
  "63061": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LUEBBERING"
  },
  "63068": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NEW HAVEN"
  },
  "63069": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PACIFIC"
  },
  "63070": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PEVELY"
  },
  "63071": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RICHWOODS"
  },
  "63072": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ROBERTSVILLE"
  },
  "63074": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT ANN"
  },
  "63077": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT CLAIR"
  },
  "63080": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SULLIVAN"
  },
  "63084": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "UNION"
  },
  "63087": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "VALLES MINES"
  },
  "63088": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "VALLEY PARK"
  },
  "63089": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "VILLA RIDGE"
  },
  "63090": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WASHINGTON"
  },
  "63091": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ROSEBUD"
  },
  "63101": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63102": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63103": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63104": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63105": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63106": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63107": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63108": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63109": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63110": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63111": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63112": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63113": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63114": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63115": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63116": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63117": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63118": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63119": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63120": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63121": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63122": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63123": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63124": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63125": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63126": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63127": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63128": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63129": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63130": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63131": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63132": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63133": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63134": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63135": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63136": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63137": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63138": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63139": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63140": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63141": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63143": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63144": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63146": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63147": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT LOUIS"
  },
  "63301": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT CHARLES"
  },
  "63303": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT CHARLES"
  },
  "63304": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT CHARLES"
  },
  "63330": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ANNADA"
  },
  "63332": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "AUGUSTA"
  },
  "63333": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BELLFLOWER"
  },
  "63334": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BOWLING GREEN"
  },
  "63336": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CLARKSVILLE"
  },
  "63339": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CURRYVILLE"
  },
  "63341": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DEFIANCE"
  },
  "63343": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ELSBERRY"
  },
  "63344": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EOLIA"
  },
  "63345": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FARBER"
  },
  "63347": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FOLEY"
  },
  "63348": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FORISTELL"
  },
  "63349": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HAWK POINT"
  },
  "63350": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HIGH HILL"
  },
  "63351": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "JONESBURG"
  },
  "63352": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LADDONIA"
  },
  "63353": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LOUISIANA"
  },
  "63357": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MARTHASVILLE"
  },
  "63359": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MIDDLETOWN"
  },
  "63361": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MONTGOMERY CITY"
  },
  "63362": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MOSCOW MILLS"
  },
  "63363": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NEW FLORENCE"
  },
  "63366": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "O FALLON"
  },
  "63367": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LAKE SAINT LOUIS"
  },
  "63369": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "OLD MONROE"
  },
  "63370": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "OLNEY"
  },
  "63373": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PORTAGE DES SIOUX"
  },
  "63376": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT PETERS"
  },
  "63377": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SILEX"
  },
  "63379": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "TROY"
  },
  "63381": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "TRUXTON"
  },
  "63382": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "VANDALIA"
  },
  "63383": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WARRENTON"
  },
  "63384": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WELLSVILLE"
  },
  "63385": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WENTZVILLE"
  },
  "63386": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WEST ALTON"
  },
  "63387": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WHITESIDE"
  },
  "63388": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WILLIAMSBURG"
  },
  "63389": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WINFIELD"
  },
  "63390": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WRIGHT CITY"
  },
  "63401": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HANNIBAL"
  },
  "63430": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ALEXANDRIA"
  },
  "63431": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ANABEL"
  },
  "63432": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ARBELA"
  },
  "63433": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ASHBURN"
  },
  "63434": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BETHEL"
  },
  "63435": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CANTON"
  },
  "63436": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CENTER"
  },
  "63437": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CLARENCE"
  },
  "63438": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DURHAM"
  },
  "63439": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EMDEN"
  },
  "63440": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EWING"
  },
  "63441": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FRANKFORD"
  },
  "63443": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HUNNEWELL"
  },
  "63445": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KAHOKA"
  },
  "63446": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KNOX CITY"
  },
  "63447": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LA BELLE"
  },
  "63448": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LA GRANGE"
  },
  "63450": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LENTNER"
  },
  "63451": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LEONARD"
  },
  "63452": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LEWISTOWN"
  },
  "63453": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LURAY"
  },
  "63454": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MAYWOOD"
  },
  "63456": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MONROE CITY"
  },
  "63457": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MONTICELLO"
  },
  "63458": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NEWARK"
  },
  "63459": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NEW LONDON"
  },
  "63460": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NOVELTY"
  },
  "63461": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PALMYRA"
  },
  "63462": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PERRY"
  },
  "63463": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PHILADELPHIA"
  },
  "63464": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PLEVNA"
  },
  "63465": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "REVERE"
  },
  "63468": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SHELBINA"
  },
  "63469": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SHELBYVILLE"
  },
  "63471": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "TAYLOR"
  },
  "63472": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WAYLAND"
  },
  "63473": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WILLIAMSTOWN"
  },
  "63474": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WYACONDA"
  },
  "63501": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KIRKSVILLE"
  },
  "63530": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ATLANTA"
  },
  "63531": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BARING"
  },
  "63532": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BEVIER"
  },
  "63533": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BRASHEAR"
  },
  "63534": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CALLAO"
  },
  "63535": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "COATSVILLE"
  },
  "63536": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DOWNING"
  },
  "63537": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EDINA"
  },
  "63538": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ELMER"
  },
  "63539": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ETHEL"
  },
  "63540": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GIBBS"
  },
  "63541": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GLENWOOD"
  },
  "63543": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GORIN"
  },
  "63544": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GREEN CASTLE"
  },
  "63545": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GREEN CITY"
  },
  "63546": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GREENTOP"
  },
  "63547": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HURDLAND"
  },
  "63548": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LANCASTER"
  },
  "63549": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LA PLATA"
  },
  "63551": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LIVONIA"
  },
  "63552": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MACON"
  },
  "63555": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MEMPHIS"
  },
  "63556": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MILAN"
  },
  "63557": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NEW BOSTON"
  },
  "63558": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NEW CAMBRIA"
  },
  "63559": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NOVINGER"
  },
  "63560": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "POLLOCK"
  },
  "63561": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "QUEEN CITY"
  },
  "63563": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RUTLEDGE"
  },
  "63565": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "UNIONVILLE"
  },
  "63566": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WINIGAN"
  },
  "63567": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WORTHINGTON"
  },
  "63601": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PARK HILLS"
  },
  "63620": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ANNAPOLIS"
  },
  "63621": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ARCADIA"
  },
  "63622": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BELGRADE"
  },
  "63623": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BELLEVIEW"
  },
  "63624": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BISMARCK"
  },
  "63625": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BLACK"
  },
  "63626": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BLACKWELL"
  },
  "63627": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BLOOMSDALE"
  },
  "63628": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BONNE TERRE"
  },
  "63629": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BUNKER"
  },
  "63630": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CADET"
  },
  "63631": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CALEDONIA"
  },
  "63633": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CENTERVILLE"
  },
  "63636": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DES ARC"
  },
  "63637": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DOE RUN"
  },
  "63638": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ELLINGTON"
  },
  "63640": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FARMINGTON"
  },
  "63645": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FREDERICKTOWN"
  },
  "63648": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "IRONDALE"
  },
  "63650": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "IRONTON"
  },
  "63653": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LEADWOOD"
  },
  "63654": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LESTERVILLE"
  },
  "63655": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MARQUAND"
  },
  "63656": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MIDDLE BROOK"
  },
  "63660": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MINERAL POINT"
  },
  "63662": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PATTON"
  },
  "63663": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PILOT KNOB"
  },
  "63664": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "POTOSI"
  },
  "63665": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "REDFORD"
  },
  "63670": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINTE GENEVIEVE"
  },
  "63673": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT MARY"
  },
  "63674": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "TIFF"
  },
  "63675": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "VULCAN"
  },
  "63701": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CAPE GIRARDEAU"
  },
  "63703": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CAPE GIRARDEAU"
  },
  "63730": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ADVANCE"
  },
  "63732": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ALTENBURG"
  },
  "63735": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BELL CITY"
  },
  "63736": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BENTON"
  },
  "63738": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BROWNWOOD"
  },
  "63739": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BURFORDVILLE"
  },
  "63740": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CHAFFEE"
  },
  "63742": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "COMMERCE"
  },
  "63743": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DAISY"
  },
  "63747": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FRIEDHEIM"
  },
  "63748": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FROHNA"
  },
  "63750": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GIPSY"
  },
  "63751": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GLENALLEN"
  },
  "63755": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "JACKSON"
  },
  "63758": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KELSO"
  },
  "63760": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LEOPOLD"
  },
  "63763": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MC GEE"
  },
  "63764": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MARBLE HILL"
  },
  "63766": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MILLERSVILLE"
  },
  "63767": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MORLEY"
  },
  "63769": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "OAK RIDGE"
  },
  "63770": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "OLD APPLETON"
  },
  "63771": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ORAN"
  },
  "63774": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PERKINS"
  },
  "63775": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PERRYVILLE"
  },
  "63780": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SCOTT CITY"
  },
  "63781": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SEDGEWICKVILLE"
  },
  "63782": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "STURDIVANT"
  },
  "63783": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "UNIONTOWN"
  },
  "63784": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "VANDUSER"
  },
  "63785": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WHITEWATER"
  },
  "63787": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ZALMA"
  },
  "63801": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SIKESTON"
  },
  "63820": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ANNISTON"
  },
  "63821": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ARBYRD"
  },
  "63822": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BERNIE"
  },
  "63823": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BERTRAND"
  },
  "63824": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BLODGETT"
  },
  "63825": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BLOOMFIELD"
  },
  "63826": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BRAGGADOCIO"
  },
  "63827": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BRAGG CITY"
  },
  "63828": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CANALOU"
  },
  "63829": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CARDWELL"
  },
  "63830": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CARUTHERSVILLE"
  },
  "63833": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CATRON"
  },
  "63834": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CHARLESTON"
  },
  "63837": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CLARKTON"
  },
  "63839": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "COOTER"
  },
  "63841": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DEXTER"
  },
  "63845": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EAST PRAIRIE"
  },
  "63846": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ESSEX"
  },
  "63847": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GIBSON"
  },
  "63848": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GIDEON"
  },
  "63849": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GOBLER"
  },
  "63850": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GRAYRIDGE"
  },
  "63851": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HAYTI"
  },
  "63852": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HOLCOMB"
  },
  "63853": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HOLLAND"
  },
  "63855": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HORNERSVILLE"
  },
  "63857": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KENNETT"
  },
  "63860": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KEWANEE"
  },
  "63862": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LILBOURN"
  },
  "63863": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MALDEN"
  },
  "63866": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MARSTON"
  },
  "63867": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MATTHEWS"
  },
  "63868": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MOREHOUSE"
  },
  "63869": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NEW MADRID"
  },
  "63870": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PARMA"
  },
  "63873": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PORTAGEVILLE"
  },
  "63874": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RISCO"
  },
  "63876": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SENATH"
  },
  "63877": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "STEELE"
  },
  "63878": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "TALLAPOOSA"
  },
  "63879": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WARDELL"
  },
  "63880": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WHITEOAK"
  },
  "63882": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WYATT"
  },
  "63901": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "POPLAR BLUFF"
  },
  "63932": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BROSELEY"
  },
  "63933": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CAMPBELL"
  },
  "63934": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CLUBB"
  },
  "63935": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DONIPHAN"
  },
  "63936": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DUDLEY"
  },
  "63937": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ELLSINORE"
  },
  "63938": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FAGUS"
  },
  "63939": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FAIRDEALING"
  },
  "63940": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FISK"
  },
  "63941": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FREMONT"
  },
  "63942": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GATEWOOD"
  },
  "63943": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GRANDIN"
  },
  "63944": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GREENVILLE"
  },
  "63945": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HARVIELL"
  },
  "63951": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LOWNDES"
  },
  "63952": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MILL SPRING"
  },
  "63953": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NAYLOR"
  },
  "63954": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NEELYVILLE"
  },
  "63955": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "OXLY"
  },
  "63956": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PATTERSON"
  },
  "63957": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PIEDMONT"
  },
  "63960": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PUXICO"
  },
  "63961": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "QULIN"
  },
  "63963": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SHOOK"
  },
  "63964": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SILVA"
  },
  "63965": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "VAN BUREN"
  },
  "63966": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WAPPAPELLO"
  },
  "63967": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WILLIAMSVILLE"
  },
  "64001": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ALMA"
  },
  "64011": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BATES CITY"
  },
  "64012": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BELTON"
  },
  "64014": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BLUE SPRINGS"
  },
  "64015": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BLUE SPRINGS"
  },
  "64016": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BUCKNER"
  },
  "64017": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CAMDEN"
  },
  "64018": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CAMDEN POINT"
  },
  "64019": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CENTERVIEW"
  },
  "64020": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CONCORDIA"
  },
  "64021": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CORDER"
  },
  "64022": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DOVER"
  },
  "64024": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EXCELSIOR SPRINGS"
  },
  "64029": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GRAIN VALLEY"
  },
  "64030": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GRANDVIEW"
  },
  "64034": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GREENWOOD"
  },
  "64035": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HARDIN"
  },
  "64036": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HENRIETTA"
  },
  "64037": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HIGGINSVILLE"
  },
  "64040": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HOLDEN"
  },
  "64048": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HOLT"
  },
  "64050": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "INDEPENDENCE"
  },
  "64052": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "INDEPENDENCE"
  },
  "64053": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "INDEPENDENCE"
  },
  "64054": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "INDEPENDENCE"
  },
  "64055": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "INDEPENDENCE"
  },
  "64056": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "INDEPENDENCE"
  },
  "64057": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "INDEPENDENCE"
  },
  "64058": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "INDEPENDENCE"
  },
  "64060": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KEARNEY"
  },
  "64061": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KINGSVILLE"
  },
  "64062": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LAWSON"
  },
  "64063": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LEES SUMMIT"
  },
  "64064": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LEES SUMMIT"
  },
  "64066": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LEVASY"
  },
  "64067": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LEXINGTON"
  },
  "64068": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LIBERTY"
  },
  "64070": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LONE JACK"
  },
  "64071": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MAYVIEW"
  },
  "64072": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MISSOURI CITY"
  },
  "64074": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NAPOLEON"
  },
  "64075": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "OAK GROVE"
  },
  "64076": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ODESSA"
  },
  "64077": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ORRICK"
  },
  "64078": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PECULIAR"
  },
  "64079": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PLATTE CITY"
  },
  "64080": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PLEASANT HILL"
  },
  "64081": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LEES SUMMIT"
  },
  "64082": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LEES SUMMIT"
  },
  "64083": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RAYMORE"
  },
  "64084": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RAYVILLE"
  },
  "64085": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RICHMOND"
  },
  "64086": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LEES SUMMIT"
  },
  "64088": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SIBLEY"
  },
  "64089": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SMITHVILLE"
  },
  "64090": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "STRASBURG"
  },
  "64093": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WARRENSBURG"
  },
  "64096": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WAVERLY"
  },
  "64097": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WELLINGTON"
  },
  "64098": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WESTON"
  },
  "64101": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64105": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64106": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64108": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64109": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64110": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64111": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64112": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64113": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64114": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64116": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64117": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64118": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64119": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64120": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64123": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64124": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64125": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64126": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64127": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64128": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64129": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64130": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64131": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64132": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64133": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64134": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64136": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64137": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64138": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64139": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64145": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64146": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64147": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64149": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64150": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RIVERSIDE"
  },
  "64151": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64152": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64153": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64154": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64155": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64156": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64157": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64158": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64161": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64163": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64164": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64165": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64166": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64167": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64192": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KANSAS CITY"
  },
  "64401": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "AGENCY"
  },
  "64402": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ALBANY"
  },
  "64421": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "AMAZONIA"
  },
  "64422": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "AMITY"
  },
  "64423": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BARNARD"
  },
  "64424": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BETHANY"
  },
  "64426": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BLYTHEDALE"
  },
  "64427": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BOLCKOW"
  },
  "64428": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BURLINGTON JUNCTION"
  },
  "64429": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CAMERON"
  },
  "64430": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CLARKSDALE"
  },
  "64431": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CLEARMONT"
  },
  "64432": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CLYDE"
  },
  "64433": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CONCEPTION"
  },
  "64434": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CONCEPTION JUNCTION"
  },
  "64436": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "COSBY"
  },
  "64437": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CRAIG"
  },
  "64438": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DARLINGTON"
  },
  "64439": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DEARBORN"
  },
  "64440": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DE KALB"
  },
  "64441": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DENVER"
  },
  "64442": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EAGLEVILLE"
  },
  "64443": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EASTON"
  },
  "64444": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EDGERTON"
  },
  "64445": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ELMO"
  },
  "64446": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FAIRFAX"
  },
  "64448": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FAUCETT"
  },
  "64449": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FILLMORE"
  },
  "64451": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FOREST CITY"
  },
  "64453": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GENTRY"
  },
  "64454": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GOWER"
  },
  "64455": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GRAHAM"
  },
  "64456": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GRANT CITY"
  },
  "64457": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GUILFORD"
  },
  "64458": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HATFIELD"
  },
  "64459": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HELENA"
  },
  "64461": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HOPKINS"
  },
  "64463": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KING CITY"
  },
  "64465": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LATHROP"
  },
  "64466": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MAITLAND"
  },
  "64467": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MARTINSVILLE"
  },
  "64468": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MARYVILLE"
  },
  "64469": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MAYSVILLE"
  },
  "64470": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MOUND CITY"
  },
  "64471": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NEW HAMPTON"
  },
  "64473": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "OREGON"
  },
  "64474": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "OSBORN"
  },
  "64475": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PARNELL"
  },
  "64476": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PICKERING"
  },
  "64477": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PLATTSBURG"
  },
  "64479": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RAVENWOOD"
  },
  "64480": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "REA"
  },
  "64481": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RIDGEWAY"
  },
  "64482": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ROCK PORT"
  },
  "64483": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ROSENDALE"
  },
  "64484": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RUSHVILLE"
  },
  "64485": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAVANNAH"
  },
  "64486": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SHERIDAN"
  },
  "64487": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SKIDMORE"
  },
  "64489": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "STANBERRY"
  },
  "64490": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "STEWARTSVILLE"
  },
  "64491": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "TARKIO"
  },
  "64492": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "TRIMBLE"
  },
  "64493": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "TURNEY"
  },
  "64494": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "UNION STAR"
  },
  "64496": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WATSON"
  },
  "64497": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WEATHERBY"
  },
  "64498": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WESTBORO"
  },
  "64499": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WORTH"
  },
  "64501": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT JOSEPH"
  },
  "64503": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT JOSEPH"
  },
  "64504": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT JOSEPH"
  },
  "64505": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT JOSEPH"
  },
  "64506": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT JOSEPH"
  },
  "64507": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT JOSEPH"
  },
  "64601": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CHILLICOTHE"
  },
  "64620": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ALTAMONT"
  },
  "64622": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BOGARD"
  },
  "64623": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BOSWORTH"
  },
  "64624": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BRAYMER"
  },
  "64625": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BRECKENRIDGE"
  },
  "64628": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BROOKFIELD"
  },
  "64630": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BROWNING"
  },
  "64631": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BUCKLIN"
  },
  "64632": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CAINSVILLE"
  },
  "64633": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CARROLLTON"
  },
  "64635": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CHULA"
  },
  "64636": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "COFFEY"
  },
  "64637": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "COWGILL"
  },
  "64638": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DAWN"
  },
  "64639": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DE WITT"
  },
  "64640": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GALLATIN"
  },
  "64641": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GALT"
  },
  "64642": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GILMAN CITY"
  },
  "64643": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HALE"
  },
  "64644": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HAMILTON"
  },
  "64645": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HARRIS"
  },
  "64646": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HUMPHREYS"
  },
  "64647": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "JAMESON"
  },
  "64648": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "JAMESPORT"
  },
  "64649": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KIDDER"
  },
  "64650": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KINGSTON"
  },
  "64651": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LACLEDE"
  },
  "64652": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LAREDO"
  },
  "64653": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LINNEUS"
  },
  "64655": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LUCERNE"
  },
  "64656": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LUDLOW"
  },
  "64657": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MC FALL"
  },
  "64658": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MARCELINE"
  },
  "64659": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MEADVILLE"
  },
  "64660": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MENDON"
  },
  "64661": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MERCER"
  },
  "64664": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MOORESVILLE"
  },
  "64667": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NEWTOWN"
  },
  "64668": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NORBORNE"
  },
  "64670": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PATTONSBURG"
  },
  "64671": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "POLO"
  },
  "64672": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "POWERSVILLE"
  },
  "64673": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PRINCETON"
  },
  "64674": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PURDIN"
  },
  "64676": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ROTHVILLE"
  },
  "64679": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SPICKARD"
  },
  "64681": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SUMNER"
  },
  "64682": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "TINA"
  },
  "64683": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "TRENTON"
  },
  "64686": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "UTICA"
  },
  "64688": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WHEELING"
  },
  "64689": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WINSTON"
  },
  "64701": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HARRISONVILLE"
  },
  "64720": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ADRIAN"
  },
  "64722": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "AMORET"
  },
  "64723": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "AMSTERDAM"
  },
  "64724": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "APPLETON CITY"
  },
  "64725": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ARCHIE"
  },
  "64726": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BLAIRSTOWN"
  },
  "64728": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BRONAUGH"
  },
  "64730": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BUTLER"
  },
  "64733": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CHILHOWEE"
  },
  "64734": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CLEVELAND"
  },
  "64735": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CLINTON"
  },
  "64738": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "COLLINS"
  },
  "64739": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CREIGHTON"
  },
  "64740": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DEEPWATER"
  },
  "64741": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DEERFIELD"
  },
  "64742": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DREXEL"
  },
  "64743": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EAST LYNNE"
  },
  "64744": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EL DORADO SPRINGS"
  },
  "64745": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FOSTER"
  },
  "64746": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FREEMAN"
  },
  "64747": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GARDEN CITY"
  },
  "64748": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GOLDEN CITY"
  },
  "64750": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HARWOOD"
  },
  "64752": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HUME"
  },
  "64755": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "JASPER"
  },
  "64756": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "JERICO SPRINGS"
  },
  "64759": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LAMAR"
  },
  "64761": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LEETON"
  },
  "64762": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LIBERAL"
  },
  "64763": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LOWRY CITY"
  },
  "64767": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MILO"
  },
  "64769": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MINDENMINES"
  },
  "64770": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MONTROSE"
  },
  "64771": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MOUNDVILLE"
  },
  "64772": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NEVADA"
  },
  "64776": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "OSCEOLA"
  },
  "64778": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RICHARDS"
  },
  "64779": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RICH HILL"
  },
  "64780": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ROCKVILLE"
  },
  "64781": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ROSCOE"
  },
  "64783": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SCHELL CITY"
  },
  "64784": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SHELDON"
  },
  "64788": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "URICH"
  },
  "64790": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WALKER"
  },
  "64801": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "JOPLIN"
  },
  "64804": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "JOPLIN"
  },
  "64830": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ALBA"
  },
  "64831": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ANDERSON"
  },
  "64832": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ASBURY"
  },
  "64833": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "AVILLA"
  },
  "64834": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CARL JUNCTION"
  },
  "64835": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CARTERVILLE"
  },
  "64836": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CARTHAGE"
  },
  "64840": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DIAMOND"
  },
  "64841": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DUENWEG"
  },
  "64842": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FAIRVIEW"
  },
  "64843": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GOODMAN"
  },
  "64844": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GRANBY"
  },
  "64847": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LANAGAN"
  },
  "64848": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LA RUSSELL"
  },
  "64849": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NECK CITY"
  },
  "64850": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NEOSHO"
  },
  "64854": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NOEL"
  },
  "64855": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ORONOGO"
  },
  "64856": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PINEVILLE"
  },
  "64857": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PURCELL"
  },
  "64858": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RACINE"
  },
  "64859": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "REEDS"
  },
  "64861": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ROCKY COMFORT"
  },
  "64862": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SARCOXIE"
  },
  "64863": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SOUTH WEST CITY"
  },
  "64865": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SENECA"
  },
  "64866": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "STARK CITY"
  },
  "64867": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "STELLA"
  },
  "64870": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WEBB CITY"
  },
  "64873": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WENTWORTH"
  },
  "64874": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WHEATON"
  },
  "65001": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ARGYLE"
  },
  "65010": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ASHLAND"
  },
  "65011": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BARNETT"
  },
  "65013": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BELLE"
  },
  "65014": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BLAND"
  },
  "65016": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BONNOTS MILL"
  },
  "65017": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BRUMLEY"
  },
  "65018": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CALIFORNIA"
  },
  "65020": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CAMDENTON"
  },
  "65023": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CENTERTOWN"
  },
  "65024": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CHAMOIS"
  },
  "65025": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CLARKSBURG"
  },
  "65026": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ELDON"
  },
  "65032": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EUGENE"
  },
  "65034": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FORTUNA"
  },
  "65035": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FREEBURG"
  },
  "65036": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GASCONADE"
  },
  "65037": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GRAVOIS MILLS"
  },
  "65039": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HARTSBURG"
  },
  "65040": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HENLEY"
  },
  "65041": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HERMANN"
  },
  "65043": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HOLTS SUMMIT"
  },
  "65046": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "JAMESTOWN"
  },
  "65047": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KAISER"
  },
  "65048": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KOELTZTOWN"
  },
  "65049": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LAKE OZARK"
  },
  "65050": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LATHAM"
  },
  "65051": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LINN"
  },
  "65052": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LINN CREEK"
  },
  "65053": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LOHMAN"
  },
  "65054": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LOOSE CREEK"
  },
  "65058": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "META"
  },
  "65059": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MOKANE"
  },
  "65061": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MORRISON"
  },
  "65062": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MOUNT STERLING"
  },
  "65063": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NEW BLOOMFIELD"
  },
  "65064": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "OLEAN"
  },
  "65065": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "OSAGE BEACH"
  },
  "65066": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "OWENSVILLE"
  },
  "65067": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PORTLAND"
  },
  "65068": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PRAIRIE HOME"
  },
  "65069": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RHINELAND"
  },
  "65072": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ROCKY MOUNT"
  },
  "65074": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RUSSELLVILLE"
  },
  "65075": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT ELIZABETH"
  },
  "65076": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT THOMAS"
  },
  "65077": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "STEEDMAN"
  },
  "65078": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "STOVER"
  },
  "65079": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SUNRISE BEACH"
  },
  "65080": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "TEBBETTS"
  },
  "65081": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "TIPTON"
  },
  "65082": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "TUSCUMBIA"
  },
  "65083": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ULMAN"
  },
  "65084": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "VERSAILLES"
  },
  "65085": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WESTPHALIA"
  },
  "65101": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "JEFFERSON CITY"
  },
  "65109": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "JEFFERSON CITY"
  },
  "65201": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "COLUMBIA"
  },
  "65202": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "COLUMBIA"
  },
  "65203": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "COLUMBIA"
  },
  "65230": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ARMSTRONG"
  },
  "65231": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "AUXVASSE"
  },
  "65232": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BENTON CITY"
  },
  "65233": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BOONVILLE"
  },
  "65236": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BRUNSWICK"
  },
  "65237": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BUNCETON"
  },
  "65239": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CAIRO"
  },
  "65240": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CENTRALIA"
  },
  "65243": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CLARK"
  },
  "65244": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CLIFTON HILL"
  },
  "65246": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DALTON"
  },
  "65247": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EXCELLO"
  },
  "65248": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FAYETTE"
  },
  "65250": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FRANKLIN"
  },
  "65251": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FULTON"
  },
  "65254": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GLASGOW"
  },
  "65255": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HALLSVILLE"
  },
  "65256": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HARRISBURG"
  },
  "65257": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HIGBEE"
  },
  "65258": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HOLLIDAY"
  },
  "65259": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HUNTSVILLE"
  },
  "65260": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "JACKSONVILLE"
  },
  "65261": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KEYTESVILLE"
  },
  "65262": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KINGDOM CITY"
  },
  "65263": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MADISON"
  },
  "65264": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MARTINSBURG"
  },
  "65265": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MEXICO"
  },
  "65270": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MOBERLY"
  },
  "65274": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NEW FRANKLIN"
  },
  "65275": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PARIS"
  },
  "65276": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PILOT GROVE"
  },
  "65278": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RENICK"
  },
  "65279": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ROCHEPORT"
  },
  "65280": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RUSH HILL"
  },
  "65281": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SALISBURY"
  },
  "65282": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SANTA FE"
  },
  "65283": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "STOUTSVILLE"
  },
  "65284": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "STURGEON"
  },
  "65285": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "THOMPSON"
  },
  "65286": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "TRIPLETT"
  },
  "65287": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WOOLDRIDGE"
  },
  "65301": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SEDALIA"
  },
  "65305": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WHITEMAN AIR FORCE BASE"
  },
  "65320": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ARROW ROCK"
  },
  "65321": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BLACKBURN"
  },
  "65322": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BLACKWATER"
  },
  "65323": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CALHOUN"
  },
  "65324": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CLIMAX SPRINGS"
  },
  "65325": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "COLE CAMP"
  },
  "65326": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EDWARDS"
  },
  "65327": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EMMA"
  },
  "65329": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FLORENCE"
  },
  "65330": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GILLIAM"
  },
  "65332": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GREEN RIDGE"
  },
  "65333": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HOUSTONIA"
  },
  "65334": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HUGHESVILLE"
  },
  "65335": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "IONIA"
  },
  "65336": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KNOB NOSTER"
  },
  "65337": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LA MONTE"
  },
  "65338": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LINCOLN"
  },
  "65339": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MALTA BEND"
  },
  "65340": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MARSHALL"
  },
  "65344": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MIAMI"
  },
  "65345": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MORA"
  },
  "65347": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NELSON"
  },
  "65348": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "OTTERVILLE"
  },
  "65349": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SLATER"
  },
  "65350": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SMITHTON"
  },
  "65351": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SWEET SPRINGS"
  },
  "65354": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SYRACUSE"
  },
  "65355": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WARSAW"
  },
  "65360": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WINDSOR"
  },
  "65401": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ROLLA"
  },
  "65436": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BEULAH"
  },
  "65438": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BIRCH TREE"
  },
  "65439": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BIXBY"
  },
  "65440": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BOSS"
  },
  "65441": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BOURBON"
  },
  "65443": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BRINKTOWN"
  },
  "65444": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BUCYRUS"
  },
  "65446": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CHERRYVILLE"
  },
  "65449": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "COOK STA"
  },
  "65452": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CROCKER"
  },
  "65453": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CUBA"
  },
  "65456": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DAVISVILLE"
  },
  "65457": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DEVILS ELBOW"
  },
  "65459": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DIXON"
  },
  "65461": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DUKE"
  },
  "65462": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EDGAR SPRINGS"
  },
  "65463": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ELDRIDGE"
  },
  "65464": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ELK CREEK"
  },
  "65466": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EMINENCE"
  },
  "65468": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EUNICE"
  },
  "65470": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FALCON"
  },
  "65473": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FORT LEONARD WOOD"
  },
  "65479": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HARTSHORN"
  },
  "65483": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HOUSTON"
  },
  "65484": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HUGGINS"
  },
  "65486": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "IBERIA"
  },
  "65501": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "JADWIN"
  },
  "65529": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "JEROME"
  },
  "65532": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LAKE SPRING"
  },
  "65534": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LAQUEY"
  },
  "65535": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LEASBURG"
  },
  "65536": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LEBANON"
  },
  "65541": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LENOX"
  },
  "65542": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LICKING"
  },
  "65543": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LYNCHBURG"
  },
  "65548": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MOUNTAIN VIEW"
  },
  "65550": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NEWBURG"
  },
  "65552": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PLATO"
  },
  "65555": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RAYMONDVILLE"
  },
  "65556": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RICHLAND"
  },
  "65557": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ROBY"
  },
  "65559": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SAINT JAMES"
  },
  "65560": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SALEM"
  },
  "65564": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SOLO"
  },
  "65565": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "STEELVILLE"
  },
  "65566": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "VIBURNUM"
  },
  "65567": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "STOUTLAND"
  },
  "65570": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SUCCESS"
  },
  "65571": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SUMMERSVILLE"
  },
  "65580": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "VICHY"
  },
  "65582": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "VIENNA"
  },
  "65583": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WAYNESVILLE"
  },
  "65588": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WINONA"
  },
  "65589": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "YUKON"
  },
  "65590": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LONG LANE"
  },
  "65591": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MONTREAL"
  },
  "65601": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ALDRICH"
  },
  "65603": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ARCOLA"
  },
  "65604": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ASH GROVE"
  },
  "65605": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "AURORA"
  },
  "65606": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ALTON"
  },
  "65608": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "AVA"
  },
  "65609": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BAKERSFIELD"
  },
  "65610": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BILLINGS"
  },
  "65611": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BLUE EYE"
  },
  "65612": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BOIS D ARC"
  },
  "65613": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BOLIVAR"
  },
  "65614": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BRADLEYVILLE"
  },
  "65616": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BRANSON"
  },
  "65617": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BRIGHTON"
  },
  "65618": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BRIXEY"
  },
  "65619": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BROOKLINE"
  },
  "65620": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BRUNER"
  },
  "65622": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BUFFALO"
  },
  "65623": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "BUTTERFIELD"
  },
  "65624": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CAPE FAIR"
  },
  "65625": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CASSVILLE"
  },
  "65626": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CAULFIELD"
  },
  "65627": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CEDARCREEK"
  },
  "65629": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CHADWICK"
  },
  "65630": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CHESTNUTRIDGE"
  },
  "65631": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CLEVER"
  },
  "65632": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CONWAY"
  },
  "65633": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CRANE"
  },
  "65634": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CROSS TIMBERS"
  },
  "65635": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DADEVILLE"
  },
  "65637": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DORA"
  },
  "65638": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DRURY"
  },
  "65640": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "DUNNEGAN"
  },
  "65641": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EAGLE ROCK"
  },
  "65644": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ELKLAND"
  },
  "65646": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EVERTON"
  },
  "65647": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "EXETER"
  },
  "65648": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FAIR GROVE"
  },
  "65649": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FAIR PLAY"
  },
  "65650": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FLEMINGTON"
  },
  "65652": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FORDLAND"
  },
  "65653": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "FORSYTH"
  },
  "65655": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GAINESVILLE"
  },
  "65656": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GALENA"
  },
  "65657": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GARRISON"
  },
  "65658": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GOLDEN"
  },
  "65660": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GRAFF"
  },
  "65661": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GREENFIELD"
  },
  "65662": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "GROVESPRING"
  },
  "65663": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HALF WAY"
  },
  "65664": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HALLTOWN"
  },
  "65667": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HARTVILLE"
  },
  "65668": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HERMITAGE"
  },
  "65669": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HIGHLANDVILLE"
  },
  "65672": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HOLLISTER"
  },
  "65674": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HUMANSVILLE"
  },
  "65675": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "HURLEY"
  },
  "65676": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ISABELLA"
  },
  "65679": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KIRBYVILLE"
  },
  "65680": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KISSEE MILLS"
  },
  "65681": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LAMPE"
  },
  "65682": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LOCKWOOD"
  },
  "65685": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "LOUISBURG"
  },
  "65686": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KIMBERLING CITY"
  },
  "65689": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "CABOOL"
  },
  "65690": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "COUCH"
  },
  "65692": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "KOSHKONONG"
  },
  "65701": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MC CLURG"
  },
  "65702": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MACOMB"
  },
  "65704": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MANSFIELD"
  },
  "65705": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MARIONVILLE"
  },
  "65706": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MARSHFIELD"
  },
  "65707": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MILLER"
  },
  "65708": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MONETT"
  },
  "65710": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MORRISVILLE"
  },
  "65711": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MOUNTAIN GROVE"
  },
  "65712": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MOUNT VERNON"
  },
  "65713": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NIANGUA"
  },
  "65714": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NIXA"
  },
  "65715": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NOBLE"
  },
  "65717": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "NORWOOD"
  },
  "65720": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "OLDFIELD"
  },
  "65721": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "OZARK"
  },
  "65722": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PHILLIPSBURG"
  },
  "65723": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PIERCE CITY"
  },
  "65724": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PITTSBURG"
  },
  "65725": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PLEASANT HOPE"
  },
  "65727": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "POLK"
  },
  "65728": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PONCE DE LEON"
  },
  "65729": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PONTIAC"
  },
  "65730": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "POWELL"
  },
  "65731": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "POWERSITE"
  },
  "65732": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PRESTON"
  },
  "65733": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PROTEM"
  },
  "65734": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PURDY"
  },
  "65735": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "QUINCY"
  },
  "65737": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "REEDS SPRING"
  },
  "65738": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "REPUBLIC"
  },
  "65739": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RIDGEDALE"
  },
  "65740": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ROCKAWAY BEACH"
  },
  "65742": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ROGERSVILLE"
  },
  "65744": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "RUETER"
  },
  "65745": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SELIGMAN"
  },
  "65746": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SEYMOUR"
  },
  "65747": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SHELL KNOB"
  },
  "65752": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SOUTH GREENFIELD"
  },
  "65753": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SPARTA"
  },
  "65754": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SPOKANE"
  },
  "65755": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SQUIRES"
  },
  "65756": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "STOTTS CITY"
  },
  "65757": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "STRAFFORD"
  },
  "65759": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "TANEYVILLE"
  },
  "65760": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "TECUMSEH"
  },
  "65761": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "THEODOSIA"
  },
  "65762": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "THORNFIELD"
  },
  "65764": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "TUNAS"
  },
  "65766": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "UDALL"
  },
  "65767": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "URBANA"
  },
  "65768": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "VANZANT"
  },
  "65769": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "VERONA"
  },
  "65770": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WALNUT GROVE"
  },
  "65771": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WALNUT SHADE"
  },
  "65772": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WASHBURN"
  },
  "65773": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WASOLA"
  },
  "65774": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WEAUBLEAU"
  },
  "65775": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WEST PLAINS"
  },
  "65777": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MOODY"
  },
  "65778": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MYRTLE"
  },
  "65779": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WHEATLAND"
  },
  "65781": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WILLARD"
  },
  "65783": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WINDYVILLE"
  },
  "65784": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ZANONI"
  },
  "65785": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "STOCKTON"
  },
  "65786": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "MACKS CREEK"
  },
  "65787": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "ROACH"
  },
  "65788": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "PEACE VALLEY"
  },
  "65789": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "POMONA"
  },
  "65790": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "POTTERSVILLE"
  },
  "65791": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "THAYER"
  },
  "65793": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "WILLOW SPRINGS"
  },
  "65802": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SPRINGFIELD"
  },
  "65803": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SPRINGFIELD"
  },
  "65804": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SPRINGFIELD"
  },
  "65806": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SPRINGFIELD"
  },
  "65807": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SPRINGFIELD"
  },
  "65809": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SPRINGFIELD"
  },
  "65810": {
    stateCode: "MO",
    stateName: "Missouri",
    city: "SPRINGFIELD"
  },
  "66002": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ATCHISON"
  },
  "66006": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BALDWIN CITY"
  },
  "66007": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BASEHOR"
  },
  "66008": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BENDENA"
  },
  "66010": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BLUE MOUND"
  },
  "66012": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BONNER SPRINGS"
  },
  "66013": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BUCYRUS"
  },
  "66014": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CENTERVILLE"
  },
  "66015": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "COLONY"
  },
  "66016": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CUMMINGS"
  },
  "66017": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DENTON"
  },
  "66018": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DE SOTO"
  },
  "66019": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CLEARVIEW CITY"
  },
  "66020": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "EASTON"
  },
  "66021": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "EDGERTON"
  },
  "66023": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "EFFINGHAM"
  },
  "66024": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ELWOOD"
  },
  "66025": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "EUDORA"
  },
  "66026": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "FONTANA"
  },
  "66027": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "FORT LEAVENWORTH"
  },
  "66030": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GARDNER"
  },
  "66032": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GARNETT"
  },
  "66033": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GREELEY"
  },
  "66035": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HIGHLAND"
  },
  "66039": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KINCAID"
  },
  "66040": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LACYGNE"
  },
  "66041": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LANCASTER"
  },
  "66042": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LANE"
  },
  "66043": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LANSING"
  },
  "66044": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LAWRENCE"
  },
  "66046": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LAWRENCE"
  },
  "66047": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LAWRENCE"
  },
  "66048": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LEAVENWORTH"
  },
  "66049": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LAWRENCE"
  },
  "66050": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LECOMPTON"
  },
  "66052": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LINWOOD"
  },
  "66053": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LOUISBURG"
  },
  "66054": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MC LOUTH"
  },
  "66056": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MOUND CITY"
  },
  "66058": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MUSCOTAH"
  },
  "66060": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NORTONVILLE"
  },
  "66061": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OLATHE"
  },
  "66062": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OLATHE"
  },
  "66064": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OSAWATOMIE"
  },
  "66066": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OSKALOOSA"
  },
  "66067": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OTTAWA"
  },
  "66070": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OZAWKIE"
  },
  "66071": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PAOLA"
  },
  "66072": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PARKER"
  },
  "66073": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PERRY"
  },
  "66075": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PLEASANTON"
  },
  "66076": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "POMONA"
  },
  "66077": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "POTTER"
  },
  "66078": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PRINCETON"
  },
  "66079": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "RANTOUL"
  },
  "66080": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "RICHMOND"
  },
  "66083": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SPRING HILL"
  },
  "66085": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "STILWELL"
  },
  "66086": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TONGANOXIE"
  },
  "66087": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TROY"
  },
  "66088": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "VALLEY FALLS"
  },
  "66090": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WATHENA"
  },
  "66091": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WELDA"
  },
  "66092": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WELLSVILLE"
  },
  "66093": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WESTPHALIA"
  },
  "66094": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WHITE CLOUD"
  },
  "66095": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WILLIAMSBURG"
  },
  "66097": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WINCHESTER"
  },
  "66101": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KANSAS CITY"
  },
  "66102": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KANSAS CITY"
  },
  "66103": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KANSAS CITY"
  },
  "66104": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KANSAS CITY"
  },
  "66105": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KANSAS CITY"
  },
  "66106": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KANSAS CITY"
  },
  "66109": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KANSAS CITY"
  },
  "66111": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KANSAS CITY"
  },
  "66112": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KANSAS CITY"
  },
  "66202": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MISSION"
  },
  "66203": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SHAWNEE"
  },
  "66204": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OVERLAND PARK"
  },
  "66205": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MISSION"
  },
  "66206": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LEAWOOD"
  },
  "66207": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OVERLAND PARK"
  },
  "66208": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PRAIRIE VILLAGE"
  },
  "66209": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LEAWOOD"
  },
  "66210": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OVERLAND PARK"
  },
  "66211": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LEAWOOD"
  },
  "66212": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OVERLAND PARK"
  },
  "66213": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OVERLAND PARK"
  },
  "66214": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OVERLAND PARK"
  },
  "66215": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LENEXA"
  },
  "66216": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SHAWNEE"
  },
  "66217": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SHAWNEE"
  },
  "66218": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SHAWNEE"
  },
  "66219": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LENEXA"
  },
  "66220": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LENEXA"
  },
  "66221": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OVERLAND PARK"
  },
  "66223": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OVERLAND PARK"
  },
  "66224": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OVERLAND PARK"
  },
  "66226": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SHAWNEE"
  },
  "66227": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LENEXA"
  },
  "66401": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ALMA"
  },
  "66402": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "AUBURN"
  },
  "66403": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "AXTELL"
  },
  "66404": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BAILEYVILLE"
  },
  "66406": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BEATTIE"
  },
  "66407": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BELVUE"
  },
  "66408": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BERN"
  },
  "66409": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BERRYTON"
  },
  "66411": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BLUE RAPIDS"
  },
  "66412": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BREMEN"
  },
  "66413": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BURLINGAME"
  },
  "66414": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CARBONDALE"
  },
  "66415": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CENTRALIA"
  },
  "66416": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CIRCLEVILLE"
  },
  "66417": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CORNING"
  },
  "66418": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DELIA"
  },
  "66419": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DENISON"
  },
  "66422": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "EMMETT"
  },
  "66423": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ESKRIDGE"
  },
  "66424": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "EVEREST"
  },
  "66425": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "FAIRVIEW"
  },
  "66427": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "FRANKFORT"
  },
  "66428": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GOFF"
  },
  "66429": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GRANTVILLE"
  },
  "66431": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HARVEYVILLE"
  },
  "66432": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HAVENSVILLE"
  },
  "66434": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HIAWATHA"
  },
  "66436": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HOLTON"
  },
  "66438": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HOME"
  },
  "66439": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HORTON"
  },
  "66440": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HOYT"
  },
  "66441": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "JUNCTION CITY"
  },
  "66442": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "FORT RILEY"
  },
  "66449": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LEONARDVILLE"
  },
  "66451": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LYNDON"
  },
  "66501": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MC FARLAND"
  },
  "66502": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MANHATTAN"
  },
  "66503": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MANHATTAN"
  },
  "66507": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MAPLE HILL"
  },
  "66508": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MARYSVILLE"
  },
  "66509": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MAYETTA"
  },
  "66510": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MELVERN"
  },
  "66512": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MERIDEN"
  },
  "66514": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MILFORD"
  },
  "66515": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MORRILL"
  },
  "66516": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NETAWAKA"
  },
  "66517": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OGDEN"
  },
  "66518": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OKETO"
  },
  "66520": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OLSBURG"
  },
  "66521": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ONAGA"
  },
  "66522": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ONEIDA"
  },
  "66523": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OSAGE CITY"
  },
  "66524": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OVERBROOK"
  },
  "66526": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PAXICO"
  },
  "66527": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "POWHATTAN"
  },
  "66528": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "QUENEMO"
  },
  "66531": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "RILEY"
  },
  "66532": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ROBINSON"
  },
  "66533": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ROSSVILLE"
  },
  "66534": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SABETHA"
  },
  "66535": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SAINT GEORGE"
  },
  "66536": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SAINT MARYS"
  },
  "66537": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SCRANTON"
  },
  "66538": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SENECA"
  },
  "66539": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SILVER LAKE"
  },
  "66540": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SOLDIER"
  },
  "66541": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SUMMERFIELD"
  },
  "66542": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TECUMSEH"
  },
  "66543": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "VASSAR"
  },
  "66544": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "VERMILLION"
  },
  "66546": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WAKARUSA"
  },
  "66547": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WAMEGO"
  },
  "66548": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WATERVILLE"
  },
  "66549": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WESTMORELAND"
  },
  "66550": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WETMORE"
  },
  "66552": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WHITING"
  },
  "66554": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "RANDOLPH"
  },
  "66603": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TOPEKA"
  },
  "66604": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TOPEKA"
  },
  "66605": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TOPEKA"
  },
  "66606": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TOPEKA"
  },
  "66607": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TOPEKA"
  },
  "66608": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TOPEKA"
  },
  "66609": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TOPEKA"
  },
  "66610": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TOPEKA"
  },
  "66611": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TOPEKA"
  },
  "66612": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TOPEKA"
  },
  "66614": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TOPEKA"
  },
  "66615": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TOPEKA"
  },
  "66616": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TOPEKA"
  },
  "66617": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TOPEKA"
  },
  "66618": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TOPEKA"
  },
  "66619": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TOPEKA"
  },
  "66701": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "FORT SCOTT"
  },
  "66710": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ALTOONA"
  },
  "66711": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ARCADIA"
  },
  "66712": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ARMA"
  },
  "66713": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BAXTER SPRINGS"
  },
  "66714": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BENEDICT"
  },
  "66716": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BRONSON"
  },
  "66717": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BUFFALO"
  },
  "66720": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CHANUTE"
  },
  "66724": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CHEROKEE"
  },
  "66725": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "COLUMBUS"
  },
  "66732": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ELSMORE"
  },
  "66733": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ERIE"
  },
  "66734": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "FARLINGTON"
  },
  "66735": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "FRANKLIN"
  },
  "66736": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "FREDONIA"
  },
  "66738": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "FULTON"
  },
  "66739": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GALENA"
  },
  "66740": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GALESBURG"
  },
  "66741": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GARLAND"
  },
  "66743": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GIRARD"
  },
  "66746": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HEPLER"
  },
  "66748": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HUMBOLDT"
  },
  "66749": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "IOLA"
  },
  "66751": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LA HARPE"
  },
  "66753": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MC CUNE"
  },
  "66754": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MAPLETON"
  },
  "66755": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MORAN"
  },
  "66756": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MULBERRY"
  },
  "66757": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NEODESHA"
  },
  "66758": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NEOSHO FALLS"
  },
  "66759": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NEW ALBANY"
  },
  "66760": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OPOLIS"
  },
  "66761": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PIQUA"
  },
  "66762": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PITTSBURG"
  },
  "66763": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "FRONTENAC"
  },
  "66767": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PRESCOTT"
  },
  "66769": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "REDFIELD"
  },
  "66770": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "RIVERTON"
  },
  "66771": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SAINT PAUL"
  },
  "66772": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SAVONBURG"
  },
  "66773": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SCAMMON"
  },
  "66775": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "STARK"
  },
  "66776": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "THAYER"
  },
  "66777": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TORONTO"
  },
  "66778": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TREECE"
  },
  "66779": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "UNIONTOWN"
  },
  "66780": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WALNUT"
  },
  "66781": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WEIR"
  },
  "66782": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WEST MINERAL"
  },
  "66783": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "YATES CENTER"
  },
  "66801": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "EMPORIA"
  },
  "66830": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ADMIRE"
  },
  "66833": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ALLEN"
  },
  "66834": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ALTA VISTA"
  },
  "66835": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "AMERICUS"
  },
  "66838": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BURDICK"
  },
  "66839": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BURLINGTON"
  },
  "66840": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BURNS"
  },
  "66842": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CASSODAY"
  },
  "66843": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CEDAR POINT"
  },
  "66845": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "COTTONWOOD FALLS"
  },
  "66846": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "COUNCIL GROVE"
  },
  "66849": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DWIGHT"
  },
  "66850": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ELMDALE"
  },
  "66851": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "FLORENCE"
  },
  "66852": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GRIDLEY"
  },
  "66853": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HAMILTON"
  },
  "66854": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HARTFORD"
  },
  "66856": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LEBO"
  },
  "66857": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LE ROY"
  },
  "66858": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LINCOLNVILLE"
  },
  "66859": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LOST SPRINGS"
  },
  "66860": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MADISON"
  },
  "66861": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MARION"
  },
  "66862": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MATFIELD GREEN"
  },
  "66863": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NEAL"
  },
  "66864": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NEOSHO RAPIDS"
  },
  "66865": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OLPE"
  },
  "66866": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PEABODY"
  },
  "66868": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "READING"
  },
  "66869": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "STRONG CITY"
  },
  "66870": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "VIRGIL"
  },
  "66871": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WAVERLY"
  },
  "66872": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WHITE CITY"
  },
  "66901": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CONCORDIA"
  },
  "66930": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "AGENDA"
  },
  "66932": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ATHOL"
  },
  "66933": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BARNES"
  },
  "66935": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BELLEVILLE"
  },
  "66936": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BURR OAK"
  },
  "66937": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CLIFTON"
  },
  "66938": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CLYDE"
  },
  "66939": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "COURTLAND"
  },
  "66940": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CUBA"
  },
  "66941": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ESBON"
  },
  "66942": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "FORMOSO"
  },
  "66943": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GREENLEAF"
  },
  "66944": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HADDAM"
  },
  "66945": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HANOVER"
  },
  "66946": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HOLLENBERG"
  },
  "66948": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "JAMESTOWN"
  },
  "66949": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "JEWELL"
  },
  "66951": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KENSINGTON"
  },
  "66952": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LEBANON"
  },
  "66953": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LINN"
  },
  "66955": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MAHASKA"
  },
  "66956": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MANKATO"
  },
  "66958": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MORROWVILLE"
  },
  "66959": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MUNDEN"
  },
  "66960": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NARKA"
  },
  "66962": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PALMER"
  },
  "66963": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "RANDALL"
  },
  "66964": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "REPUBLIC"
  },
  "66966": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SCANDIA"
  },
  "66967": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SMITH CENTER"
  },
  "66968": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WASHINGTON"
  },
  "66970": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WEBBER"
  },
  "67001": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ANDALE"
  },
  "67002": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ANDOVER"
  },
  "67003": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ANTHONY"
  },
  "67004": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ARGONIA"
  },
  "67005": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ARKANSAS CITY"
  },
  "67008": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ATLANTA"
  },
  "67009": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ATTICA"
  },
  "67010": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "AUGUSTA"
  },
  "67012": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BEAUMONT"
  },
  "67013": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BELLE PLAINE"
  },
  "67016": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BENTLEY"
  },
  "67017": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BENTON"
  },
  "67018": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BLUFF CITY"
  },
  "67019": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BURDEN"
  },
  "67020": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BURRTON"
  },
  "67021": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BYERS"
  },
  "67022": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CALDWELL"
  },
  "67023": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CAMBRIDGE"
  },
  "67024": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CEDAR VALE"
  },
  "67025": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CHENEY"
  },
  "67026": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CLEARWATER"
  },
  "67028": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "COATS"
  },
  "67029": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "COLDWATER"
  },
  "67030": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "COLWICH"
  },
  "67031": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CONWAY SPRINGS"
  },
  "67035": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CUNNINGHAM"
  },
  "67036": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DANVILLE"
  },
  "67037": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DERBY"
  },
  "67038": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DEXTER"
  },
  "67039": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DOUGLASS"
  },
  "67041": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ELBING"
  },
  "67042": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "EL DORADO"
  },
  "67045": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "EUREKA"
  },
  "67047": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "FALL RIVER"
  },
  "67049": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "FREEPORT"
  },
  "67050": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GARDEN PLAIN"
  },
  "67051": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GEUDA SPRINGS"
  },
  "67052": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GODDARD"
  },
  "67053": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GOESSEL"
  },
  "67054": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GREENSBURG"
  },
  "67056": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HALSTEAD"
  },
  "67057": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HARDTNER"
  },
  "67058": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HARPER"
  },
  "67059": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HAVILAND"
  },
  "67060": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HAYSVILLE"
  },
  "67061": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HAZELTON"
  },
  "67062": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HESSTON"
  },
  "67063": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HILLSBORO"
  },
  "67065": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ISABEL"
  },
  "67066": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "IUKA"
  },
  "67067": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KECHI"
  },
  "67068": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KINGMAN"
  },
  "67070": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KIOWA"
  },
  "67071": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LAKE CITY"
  },
  "67072": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LATHAM"
  },
  "67073": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LEHIGH"
  },
  "67074": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LEON"
  },
  "67101": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MAIZE"
  },
  "67102": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MAPLE CITY"
  },
  "67103": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MAYFIELD"
  },
  "67104": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MEDICINE LODGE"
  },
  "67105": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MILAN"
  },
  "67106": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MILTON"
  },
  "67107": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MOUNDRIDGE"
  },
  "67108": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MOUNT HOPE"
  },
  "67109": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MULLINVILLE"
  },
  "67110": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MULVANE"
  },
  "67111": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MURDOCK"
  },
  "67112": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NASHVILLE"
  },
  "67114": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NEWTON"
  },
  "67117": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NORTH NEWTON"
  },
  "67118": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NORWICH"
  },
  "67119": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OXFORD"
  },
  "67120": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PECK"
  },
  "67122": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PIEDMONT"
  },
  "67123": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "POTWIN"
  },
  "67124": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PRATT"
  },
  "67127": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PROTECTION"
  },
  "67131": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ROCK"
  },
  "67132": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ROSALIA"
  },
  "67133": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ROSE HILL"
  },
  "67134": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SAWYER"
  },
  "67135": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SEDGWICK"
  },
  "67137": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SEVERY"
  },
  "67138": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SHARON"
  },
  "67140": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SOUTH HAVEN"
  },
  "67142": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SPIVEY"
  },
  "67143": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SUN CITY"
  },
  "67144": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TOWANDA"
  },
  "67146": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "UDALL"
  },
  "67147": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "VALLEY CENTER"
  },
  "67149": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "VIOLA"
  },
  "67150": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WALDRON"
  },
  "67151": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WALTON"
  },
  "67152": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WELLINGTON"
  },
  "67154": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WHITEWATER"
  },
  "67155": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WILMORE"
  },
  "67156": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WINFIELD"
  },
  "67159": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ZENDA"
  },
  "67202": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67203": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67204": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67205": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67206": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67207": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67208": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67209": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67210": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67211": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67212": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67213": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67214": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67215": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67216": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67217": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67218": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67219": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67220": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67226": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67230": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67235": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WICHITA"
  },
  "67301": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "INDEPENDENCE"
  },
  "67330": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ALTAMONT"
  },
  "67332": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BARTLETT"
  },
  "67333": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CANEY"
  },
  "67334": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CHAUTAUQUA"
  },
  "67335": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CHERRYVALE"
  },
  "67336": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CHETOPA"
  },
  "67337": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "COFFEYVILLE"
  },
  "67340": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DEARING"
  },
  "67341": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DENNIS"
  },
  "67342": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "EDNA"
  },
  "67344": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ELK CITY"
  },
  "67345": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ELK FALLS"
  },
  "67346": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GRENOLA"
  },
  "67347": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HAVANA"
  },
  "67349": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HOWARD"
  },
  "67351": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LIBERTY"
  },
  "67352": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LONGTON"
  },
  "67353": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MOLINE"
  },
  "67354": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MOUND VALLEY"
  },
  "67355": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NIOTAZE"
  },
  "67356": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OSWEGO"
  },
  "67357": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PARSONS"
  },
  "67360": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PERU"
  },
  "67361": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SEDAN"
  },
  "67363": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SYCAMORE"
  },
  "67364": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TYRO"
  },
  "67401": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SALINA"
  },
  "67410": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ABILENE"
  },
  "67416": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ASSARIA"
  },
  "67417": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "AURORA"
  },
  "67418": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BARNARD"
  },
  "67420": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BELOIT"
  },
  "67422": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BENNINGTON"
  },
  "67423": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BEVERLY"
  },
  "67425": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BROOKVILLE"
  },
  "67427": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BUSHTON"
  },
  "67428": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CANTON"
  },
  "67430": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CAWKER CITY"
  },
  "67431": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CHAPMAN"
  },
  "67432": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CLAY CENTER"
  },
  "67436": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DELPHOS"
  },
  "67437": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DOWNS"
  },
  "67438": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DURHAM"
  },
  "67439": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ELLSWORTH"
  },
  "67441": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ENTERPRISE"
  },
  "67442": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "FALUN"
  },
  "67443": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GALVA"
  },
  "67444": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GENESEO"
  },
  "67445": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GLASCO"
  },
  "67446": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GLEN ELDER"
  },
  "67447": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GREEN"
  },
  "67448": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GYPSUM"
  },
  "67449": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HERINGTON"
  },
  "67450": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HOLYROOD"
  },
  "67451": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HOPE"
  },
  "67452": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HUNTER"
  },
  "67454": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KANOPOLIS"
  },
  "67455": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LINCOLN"
  },
  "67456": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LINDSBORG"
  },
  "67457": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LITTLE RIVER"
  },
  "67458": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LONGFORD"
  },
  "67459": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LORRAINE"
  },
  "67460": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MCPHERSON"
  },
  "67464": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MARQUETTE"
  },
  "67466": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MILTONVALE"
  },
  "67467": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MINNEAPOLIS"
  },
  "67468": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MORGANVILLE"
  },
  "67470": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NEW CAMBRIA"
  },
  "67473": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OSBORNE"
  },
  "67474": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PORTIS"
  },
  "67475": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "RAMONA"
  },
  "67478": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SIMPSON"
  },
  "67480": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SOLOMON"
  },
  "67481": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SYLVAN GROVE"
  },
  "67482": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TALMAGE"
  },
  "67483": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TAMPA"
  },
  "67484": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TESCOTT"
  },
  "67485": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TIPTON"
  },
  "67487": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WAKEFIELD"
  },
  "67490": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WILSON"
  },
  "67491": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WINDOM"
  },
  "67492": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WOODBINE"
  },
  "67501": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HUTCHINSON"
  },
  "67502": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HUTCHINSON"
  },
  "67505": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SOUTH HUTCHINSON"
  },
  "67510": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ABBYVILLE"
  },
  "67511": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ALBERT"
  },
  "67512": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ALDEN"
  },
  "67513": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ALEXANDER"
  },
  "67514": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ARLINGTON"
  },
  "67515": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ARNOLD"
  },
  "67516": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BAZINE"
  },
  "67518": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BEELER"
  },
  "67519": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BELPRE"
  },
  "67520": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BISON"
  },
  "67521": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BROWNELL"
  },
  "67522": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BUHLER"
  },
  "67523": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BURDETT"
  },
  "67524": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CHASE"
  },
  "67525": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CLAFLIN"
  },
  "67526": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ELLINWOOD"
  },
  "67529": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GARFIELD"
  },
  "67530": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GREAT BEND"
  },
  "67543": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HAVEN"
  },
  "67544": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HOISINGTON"
  },
  "67545": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HUDSON"
  },
  "67546": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "INMAN"
  },
  "67547": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KINSLEY"
  },
  "67548": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LA CROSSE"
  },
  "67550": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LARNED"
  },
  "67552": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LEWIS"
  },
  "67553": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LIEBENTHAL"
  },
  "67554": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LYONS"
  },
  "67556": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MC CRACKEN"
  },
  "67557": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MACKSVILLE"
  },
  "67559": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NEKOMA"
  },
  "67560": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NESS CITY"
  },
  "67561": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NICKERSON"
  },
  "67563": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OFFERLE"
  },
  "67564": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OLMITZ"
  },
  "67565": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OTIS"
  },
  "67566": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PARTRIDGE"
  },
  "67567": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PAWNEE ROCK"
  },
  "67568": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PLEVNA"
  },
  "67570": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PRETTY PRAIRIE"
  },
  "67572": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "RANSOM"
  },
  "67573": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "RAYMOND"
  },
  "67574": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ROZEL"
  },
  "67575": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "RUSH CENTER"
  },
  "67576": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SAINT JOHN"
  },
  "67578": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "STAFFORD"
  },
  "67579": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "STERLING"
  },
  "67581": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SYLVIA"
  },
  "67583": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TURON"
  },
  "67584": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "UTICA"
  },
  "67601": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HAYS"
  },
  "67621": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "AGRA"
  },
  "67622": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ALMENA"
  },
  "67623": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ALTON"
  },
  "67625": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BOGUE"
  },
  "67626": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BUNKER HILL"
  },
  "67628": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CEDAR"
  },
  "67629": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CLAYTON"
  },
  "67631": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "COLLYER"
  },
  "67632": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DAMAR"
  },
  "67634": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DORRANCE"
  },
  "67635": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DRESDEN"
  },
  "67637": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ELLIS"
  },
  "67638": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GAYLORD"
  },
  "67639": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GLADE"
  },
  "67640": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GORHAM"
  },
  "67642": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HILL CITY"
  },
  "67643": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "JENNINGS"
  },
  "67644": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KIRWIN"
  },
  "67645": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LENORA"
  },
  "67646": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LOGAN"
  },
  "67647": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LONG ISLAND"
  },
  "67648": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LUCAS"
  },
  "67649": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LURAY"
  },
  "67650": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MORLAND"
  },
  "67651": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NATOMA"
  },
  "67653": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NORCATUR"
  },
  "67654": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "NORTON"
  },
  "67656": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OGALLAH"
  },
  "67657": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PALCO"
  },
  "67658": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PARADISE"
  },
  "67659": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PENOKEE"
  },
  "67660": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PFEIFER"
  },
  "67661": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PHILLIPSBURG"
  },
  "67663": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PLAINVILLE"
  },
  "67664": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PRAIRIE VIEW"
  },
  "67665": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "RUSSELL"
  },
  "67667": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SCHOENCHEN"
  },
  "67669": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "STOCKTON"
  },
  "67671": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "VICTORIA"
  },
  "67672": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WAKEENEY"
  },
  "67673": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WALDO"
  },
  "67674": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WALKER"
  },
  "67675": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WOODSTON"
  },
  "67701": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "COLBY"
  },
  "67730": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ATWOOD"
  },
  "67731": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BIRD CITY"
  },
  "67732": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BREWSTER"
  },
  "67733": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "EDSON"
  },
  "67734": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GEM"
  },
  "67735": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GOODLAND"
  },
  "67736": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GOVE"
  },
  "67737": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GRAINFIELD"
  },
  "67738": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GRINNELL"
  },
  "67739": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HERNDON"
  },
  "67740": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HOXIE"
  },
  "67741": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KANORADO"
  },
  "67743": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LEVANT"
  },
  "67744": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LUDELL"
  },
  "67745": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MC DONALD"
  },
  "67747": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MONUMENT"
  },
  "67748": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OAKLEY"
  },
  "67749": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "OBERLIN"
  },
  "67751": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PARK"
  },
  "67752": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "QUINTER"
  },
  "67753": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "REXFORD"
  },
  "67756": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SAINT FRANCIS"
  },
  "67757": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SELDEN"
  },
  "67758": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SHARON SPRINGS"
  },
  "67761": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WALLACE"
  },
  "67762": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WESKAN"
  },
  "67764": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WINONA"
  },
  "67801": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DODGE CITY"
  },
  "67831": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ASHLAND"
  },
  "67834": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "BUCKLIN"
  },
  "67835": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "CIMARRON"
  },
  "67836": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "COOLIDGE"
  },
  "67837": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "COPELAND"
  },
  "67838": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DEERFIELD"
  },
  "67839": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "DIGHTON"
  },
  "67840": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ENGLEWOOD"
  },
  "67841": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ENSIGN"
  },
  "67842": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "FORD"
  },
  "67844": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "FOWLER"
  },
  "67846": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "GARDEN CITY"
  },
  "67849": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HANSTON"
  },
  "67850": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HEALY"
  },
  "67851": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HOLCOMB"
  },
  "67853": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "INGALLS"
  },
  "67854": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "JETMORE"
  },
  "67855": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "JOHNSON"
  },
  "67857": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KENDALL"
  },
  "67859": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "KISMET"
  },
  "67860": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LAKIN"
  },
  "67861": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LEOTI"
  },
  "67862": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MANTER"
  },
  "67863": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MARIENTHAL"
  },
  "67864": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MEADE"
  },
  "67865": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MINNEOLA"
  },
  "67867": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MONTEZUMA"
  },
  "67868": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PIERCEVILLE"
  },
  "67869": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "PLAINS"
  },
  "67870": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SATANTA"
  },
  "67871": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SCOTT CITY"
  },
  "67876": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SPEARVILLE"
  },
  "67877": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SUBLETTE"
  },
  "67878": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "SYRACUSE"
  },
  "67879": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "TRIBUNE"
  },
  "67880": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ULYSSES"
  },
  "67882": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "WRIGHT"
  },
  "67901": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "LIBERAL"
  },
  "67950": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ELKHART"
  },
  "67951": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "HUGOTON"
  },
  "67952": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "MOSCOW"
  },
  "67953": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "RICHFIELD"
  },
  "67954": {
    stateCode: "KS",
    stateName: "Kansas",
    city: "ROLLA"
  },
  "68001": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ABIE"
  },
  "68002": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ARLINGTON"
  },
  "68003": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ASHLAND"
  },
  "68004": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BANCROFT"
  },
  "68005": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BELLEVUE"
  },
  "68007": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BENNINGTON"
  },
  "68008": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BLAIR"
  },
  "68010": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BOYS TOWN"
  },
  "68014": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BRUNO"
  },
  "68015": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CEDAR BLUFFS"
  },
  "68016": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CEDAR CREEK"
  },
  "68017": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CERESCO"
  },
  "68018": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "COLON"
  },
  "68019": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CRAIG"
  },
  "68020": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DECATUR"
  },
  "68022": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ELKHORN"
  },
  "68023": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "FORT CALHOUN"
  },
  "68025": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "FREMONT"
  },
  "68028": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GRETNA"
  },
  "68029": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HERMAN"
  },
  "68030": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HOMER"
  },
  "68031": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HOOPER"
  },
  "68033": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ITHACA"
  },
  "68034": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "KENNARD"
  },
  "68036": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINWOOD"
  },
  "68037": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LOUISVILLE"
  },
  "68038": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LYONS"
  },
  "68039": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MACY"
  },
  "68040": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MALMO"
  },
  "68041": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MEAD"
  },
  "68042": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MEMPHIS"
  },
  "68044": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "NICKERSON"
  },
  "68045": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OAKLAND"
  },
  "68046": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PAPILLION"
  },
  "68047": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PENDER"
  },
  "68048": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PLATTSMOUTH"
  },
  "68050": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PRAGUE"
  },
  "68055": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ROSALIE"
  },
  "68057": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SCRIBNER"
  },
  "68058": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SOUTH BEND"
  },
  "68059": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SPRINGFIELD"
  },
  "68061": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "TEKAMAH"
  },
  "68062": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "THURSTON"
  },
  "68063": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "UEHLING"
  },
  "68064": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "VALLEY"
  },
  "68065": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "VALPARAISO"
  },
  "68066": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WAHOO"
  },
  "68067": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WALTHILL"
  },
  "68068": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WASHINGTON"
  },
  "68069": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WATERLOO"
  },
  "68070": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WESTON"
  },
  "68071": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WINNEBAGO"
  },
  "68073": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "YUTAN"
  },
  "68102": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68104": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68105": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68106": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68107": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68108": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68110": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68111": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68112": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68113": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OFFUTT A F B"
  },
  "68114": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68116": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68117": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68118": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68122": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68123": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BELLEVUE"
  },
  "68124": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68127": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68128": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LA VISTA"
  },
  "68130": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68131": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68132": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68133": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PAPILLION"
  },
  "68134": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68135": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68136": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68137": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68138": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68142": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68144": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68147": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BELLEVUE"
  },
  "68152": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68154": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68157": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68164": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OMAHA"
  },
  "68301": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ADAMS"
  },
  "68303": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ALEXANDRIA"
  },
  "68304": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ALVO"
  },
  "68305": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "AUBURN"
  },
  "68307": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "AVOCA"
  },
  "68309": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BARNESTON"
  },
  "68310": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BEATRICE"
  },
  "68313": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BEAVER CROSSING"
  },
  "68314": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BEE"
  },
  "68315": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BELVIDERE"
  },
  "68316": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BENEDICT"
  },
  "68317": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BENNET"
  },
  "68318": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BLUE SPRINGS"
  },
  "68319": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BRADSHAW"
  },
  "68320": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BROCK"
  },
  "68321": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BROWNVILLE"
  },
  "68322": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BRUNING"
  },
  "68323": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BURCHARD"
  },
  "68324": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BURR"
  },
  "68325": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BYRON"
  },
  "68326": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CARLETON"
  },
  "68327": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CHESTER"
  },
  "68328": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CLATONIA"
  },
  "68329": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "COOK"
  },
  "68330": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CORDOVA"
  },
  "68331": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CORTLAND"
  },
  "68332": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CRAB ORCHARD"
  },
  "68333": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CRETE"
  },
  "68335": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DAVENPORT"
  },
  "68336": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DAVEY"
  },
  "68337": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DAWSON"
  },
  "68338": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DAYKIN"
  },
  "68339": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DENTON"
  },
  "68340": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DESHLER"
  },
  "68341": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DE WITT"
  },
  "68342": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DILLER"
  },
  "68343": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DORCHESTER"
  },
  "68344": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DOUGLAS"
  },
  "68345": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DU BOIS"
  },
  "68346": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DUNBAR"
  },
  "68347": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "EAGLE"
  },
  "68348": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ELK CREEK"
  },
  "68349": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ELMWOOD"
  },
  "68350": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ENDICOTT"
  },
  "68351": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "EXETER"
  },
  "68352": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "FAIRBURY"
  },
  "68354": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "FAIRMONT"
  },
  "68355": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "FALLS CITY"
  },
  "68357": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "FILLEY"
  },
  "68358": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "FIRTH"
  },
  "68359": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "FRIEND"
  },
  "68360": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GARLAND"
  },
  "68361": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GENEVA"
  },
  "68362": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GILEAD"
  },
  "68364": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GOEHNER"
  },
  "68365": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GRAFTON"
  },
  "68366": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GREENWOOD"
  },
  "68367": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GRESHAM"
  },
  "68368": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HALLAM"
  },
  "68370": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HEBRON"
  },
  "68371": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HENDERSON"
  },
  "68372": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HICKMAN"
  },
  "68375": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HUBBELL"
  },
  "68376": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HUMBOLDT"
  },
  "68377": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "JANSEN"
  },
  "68378": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "JOHNSON"
  },
  "68380": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LEWISTON"
  },
  "68381": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LIBERTY"
  },
  "68401": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MC COOL JUNCTION"
  },
  "68402": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MALCOLM"
  },
  "68403": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MANLEY"
  },
  "68404": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MARTELL"
  },
  "68405": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MILFORD"
  },
  "68406": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MILLIGAN"
  },
  "68407": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MURDOCK"
  },
  "68409": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MURRAY"
  },
  "68410": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "NEBRASKA CITY"
  },
  "68413": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "NEHAWKA"
  },
  "68414": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "NEMAHA"
  },
  "68415": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ODELL"
  },
  "68416": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OHIOWA"
  },
  "68417": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OTOE"
  },
  "68418": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PALMYRA"
  },
  "68419": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PANAMA"
  },
  "68420": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PAWNEE CITY"
  },
  "68421": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PERU"
  },
  "68422": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PICKRELL"
  },
  "68423": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PLEASANT DALE"
  },
  "68424": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PLYMOUTH"
  },
  "68428": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "RAYMOND"
  },
  "68430": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ROCA"
  },
  "68431": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "RULO"
  },
  "68433": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SALEM"
  },
  "68434": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SEWARD"
  },
  "68436": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SHICKLEY"
  },
  "68437": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SHUBERT"
  },
  "68439": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "STAPLEHURST"
  },
  "68440": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "STEELE CITY"
  },
  "68441": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "STEINAUER"
  },
  "68442": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "STELLA"
  },
  "68443": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "STERLING"
  },
  "68444": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "STRANG"
  },
  "68445": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SWANTON"
  },
  "68446": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SYRACUSE"
  },
  "68447": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "TABLE ROCK"
  },
  "68448": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "TALMAGE"
  },
  "68450": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "TECUMSEH"
  },
  "68452": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ONG"
  },
  "68453": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "TOBIAS"
  },
  "68454": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "UNADILLA"
  },
  "68455": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "UNION"
  },
  "68456": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "UTICA"
  },
  "68457": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "VERDON"
  },
  "68458": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "VIRGINIA"
  },
  "68460": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WACO"
  },
  "68461": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WALTON"
  },
  "68462": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WAVERLY"
  },
  "68463": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WEEPING WATER"
  },
  "68464": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WESTERN"
  },
  "68465": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WILBER"
  },
  "68466": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WYMORE"
  },
  "68467": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "YORK"
  },
  "68502": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68503": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68504": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68505": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68506": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68507": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68508": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68510": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68512": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68516": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68517": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68520": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68521": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68522": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68523": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68524": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68526": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68527": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68528": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68532": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINCOLN"
  },
  "68601": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "COLUMBUS"
  },
  "68620": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ALBION"
  },
  "68621": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "AMES"
  },
  "68622": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BARTLETT"
  },
  "68623": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BELGRADE"
  },
  "68624": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BELLWOOD"
  },
  "68626": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BRAINARD"
  },
  "68627": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CEDAR RAPIDS"
  },
  "68628": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CLARKS"
  },
  "68629": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CLARKSON"
  },
  "68631": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CRESTON"
  },
  "68632": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DAVID CITY"
  },
  "68633": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DODGE"
  },
  "68634": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DUNCAN"
  },
  "68635": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DWIGHT"
  },
  "68636": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ELGIN"
  },
  "68637": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ERICSON"
  },
  "68638": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "FULLERTON"
  },
  "68640": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GENOA"
  },
  "68641": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HOWELLS"
  },
  "68642": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HUMPHREY"
  },
  "68643": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LEIGH"
  },
  "68644": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LINDSAY"
  },
  "68647": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MONROE"
  },
  "68648": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MORSE BLUFF"
  },
  "68649": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "NORTH BEND"
  },
  "68651": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OSCEOLA"
  },
  "68652": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PETERSBURG"
  },
  "68653": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PLATTE CENTER"
  },
  "68654": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "POLK"
  },
  "68655": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PRIMROSE"
  },
  "68658": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "RISING CITY"
  },
  "68659": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ROGERS"
  },
  "68660": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SAINT EDWARD"
  },
  "68661": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SCHUYLER"
  },
  "68662": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SHELBY"
  },
  "68663": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SILVER CREEK"
  },
  "68664": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SNYDER"
  },
  "68665": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SPALDING"
  },
  "68666": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "STROMSBURG"
  },
  "68667": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SURPRISE"
  },
  "68669": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ULYSSES"
  },
  "68701": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "NORFOLK"
  },
  "68710": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ALLEN"
  },
  "68711": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "AMELIA"
  },
  "68713": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ATKINSON"
  },
  "68714": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BASSETT"
  },
  "68715": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BATTLE CREEK"
  },
  "68716": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BEEMER"
  },
  "68717": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BELDEN"
  },
  "68718": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BLOOMFIELD"
  },
  "68719": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BRISTOW"
  },
  "68720": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BRUNSWICK"
  },
  "68722": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BUTTE"
  },
  "68723": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CARROLL"
  },
  "68724": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CENTER"
  },
  "68725": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CHAMBERS"
  },
  "68726": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CLEARWATER"
  },
  "68727": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "COLERIDGE"
  },
  "68728": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CONCORD"
  },
  "68729": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CREIGHTON"
  },
  "68730": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CROFTON"
  },
  "68731": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DAKOTA CITY"
  },
  "68732": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DIXON"
  },
  "68733": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "EMERSON"
  },
  "68734": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "EMMET"
  },
  "68735": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "EWING"
  },
  "68736": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "FORDYCE"
  },
  "68739": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HARTINGTON"
  },
  "68740": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HOSKINS"
  },
  "68741": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HUBBARD"
  },
  "68742": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "INMAN"
  },
  "68743": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "JACKSON"
  },
  "68745": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LAUREL"
  },
  "68746": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LYNCH"
  },
  "68747": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MCLEAN"
  },
  "68748": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MADISON"
  },
  "68752": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MEADOW GROVE"
  },
  "68753": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MILLS"
  },
  "68755": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "NAPER"
  },
  "68756": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "NELIGH"
  },
  "68757": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "NEWCASTLE"
  },
  "68758": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "NEWMAN GROVE"
  },
  "68759": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "NEWPORT"
  },
  "68760": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "NIOBRARA"
  },
  "68761": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OAKDALE"
  },
  "68763": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ONEILL"
  },
  "68764": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ORCHARD"
  },
  "68765": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OSMOND"
  },
  "68766": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PAGE"
  },
  "68767": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PIERCE"
  },
  "68768": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PILGER"
  },
  "68769": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PLAINVIEW"
  },
  "68770": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PONCA"
  },
  "68771": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "RANDOLPH"
  },
  "68773": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ROYAL"
  },
  "68774": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SAINT HELENA"
  },
  "68776": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SOUTH SIOUX CITY"
  },
  "68777": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SPENCER"
  },
  "68778": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SPRINGVIEW"
  },
  "68779": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "STANTON"
  },
  "68780": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "STUART"
  },
  "68781": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "TILDEN"
  },
  "68783": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "VERDIGRE"
  },
  "68784": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WAKEFIELD"
  },
  "68785": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WATERBURY"
  },
  "68786": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WAUSA"
  },
  "68787": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WAYNE"
  },
  "68788": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WEST POINT"
  },
  "68789": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WINNETOON"
  },
  "68790": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WINSIDE"
  },
  "68791": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WISNER"
  },
  "68792": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WYNOT"
  },
  "68801": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GRAND ISLAND"
  },
  "68803": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GRAND ISLAND"
  },
  "68810": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ALDA"
  },
  "68812": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "AMHERST"
  },
  "68813": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ANSELMO"
  },
  "68814": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ANSLEY"
  },
  "68815": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ARCADIA"
  },
  "68816": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ARCHER"
  },
  "68817": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ASHTON"
  },
  "68818": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "AURORA"
  },
  "68820": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BOELUS"
  },
  "68821": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BREWSTER"
  },
  "68822": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BROKEN BOW"
  },
  "68823": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BURWELL"
  },
  "68824": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CAIRO"
  },
  "68825": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CALLAWAY"
  },
  "68826": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CENTRAL CITY"
  },
  "68827": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CHAPMAN"
  },
  "68828": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "COMSTOCK"
  },
  "68831": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DANNEBROG"
  },
  "68832": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DONIPHAN"
  },
  "68833": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DUNNING"
  },
  "68834": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "EDDYVILLE"
  },
  "68835": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ELBA"
  },
  "68836": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ELM CREEK"
  },
  "68837": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ELYRIA"
  },
  "68838": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "FARWELL"
  },
  "68840": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GIBBON"
  },
  "68841": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GILTNER"
  },
  "68842": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GREELEY"
  },
  "68843": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HAMPTON"
  },
  "68844": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HAZARD"
  },
  "68845": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "KEARNEY"
  },
  "68846": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HORDVILLE"
  },
  "68847": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "KEARNEY"
  },
  "68850": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LEXINGTON"
  },
  "68852": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LITCHFIELD"
  },
  "68853": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LOUP CITY"
  },
  "68854": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MARQUETTE"
  },
  "68855": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MASON CITY"
  },
  "68856": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MERNA"
  },
  "68858": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MILLER"
  },
  "68859": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "NORTH LOUP"
  },
  "68860": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OCONTO"
  },
  "68861": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ODESSA"
  },
  "68862": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ORD"
  },
  "68863": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OVERTON"
  },
  "68864": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PALMER"
  },
  "68865": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PHILLIPS"
  },
  "68866": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PLEASANTON"
  },
  "68869": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "RAVENNA"
  },
  "68870": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "RIVERDALE"
  },
  "68871": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ROCKVILLE"
  },
  "68872": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SAINT LIBORY"
  },
  "68873": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SAINT PAUL"
  },
  "68874": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SARGENT"
  },
  "68875": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SCOTIA"
  },
  "68876": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SHELTON"
  },
  "68878": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SUMNER"
  },
  "68879": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "TAYLOR"
  },
  "68881": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WESTERVILLE"
  },
  "68882": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WOLBACH"
  },
  "68883": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WOOD RIVER"
  },
  "68901": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HASTINGS"
  },
  "68920": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ALMA"
  },
  "68922": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ARAPAHOE"
  },
  "68923": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ATLANTA"
  },
  "68924": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "AXTELL"
  },
  "68925": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "AYR"
  },
  "68926": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BEAVER CITY"
  },
  "68927": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BERTRAND"
  },
  "68928": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BLADEN"
  },
  "68929": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BLOOMINGTON"
  },
  "68930": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BLUE HILL"
  },
  "68932": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CAMPBELL"
  },
  "68933": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CLAY CENTER"
  },
  "68934": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DEWEESE"
  },
  "68935": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "EDGAR"
  },
  "68936": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "EDISON"
  },
  "68937": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ELWOOD"
  },
  "68938": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "FAIRFIELD"
  },
  "68939": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "FRANKLIN"
  },
  "68940": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "FUNK"
  },
  "68941": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GLENVIL"
  },
  "68942": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GUIDE ROCK"
  },
  "68943": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HARDY"
  },
  "68944": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HARVARD"
  },
  "68945": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HEARTWELL"
  },
  "68946": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HENDLEY"
  },
  "68947": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HILDRETH"
  },
  "68948": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HOLBROOK"
  },
  "68949": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HOLDREGE"
  },
  "68950": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HOLSTEIN"
  },
  "68952": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "INAVALE"
  },
  "68954": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "INLAND"
  },
  "68955": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "JUNIATA"
  },
  "68956": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "KENESAW"
  },
  "68957": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LAWRENCE"
  },
  "68958": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LOOMIS"
  },
  "68959": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MINDEN"
  },
  "68960": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "NAPONEE"
  },
  "68961": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "NELSON"
  },
  "68964": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OAK"
  },
  "68966": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ORLEANS"
  },
  "68967": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OXFORD"
  },
  "68970": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "RED CLOUD"
  },
  "68971": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "REPUBLICAN CITY"
  },
  "68972": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "RIVERTON"
  },
  "68973": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ROSELAND"
  },
  "68974": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "RUSKIN"
  },
  "68975": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SARONVILLE"
  },
  "68976": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SMITHFIELD"
  },
  "68977": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "STAMFORD"
  },
  "68978": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SUPERIOR"
  },
  "68979": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SUTTON"
  },
  "68980": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "TRUMBULL"
  },
  "68981": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "UPLAND"
  },
  "68982": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WILCOX"
  },
  "69001": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MC COOK"
  },
  "69020": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BARTLEY"
  },
  "69021": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BENKELMAN"
  },
  "69022": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CAMBRIDGE"
  },
  "69023": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CHAMPION"
  },
  "69024": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CULBERTSON"
  },
  "69025": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CURTIS"
  },
  "69026": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DANBURY"
  },
  "69027": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ENDERS"
  },
  "69028": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "EUSTIS"
  },
  "69029": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "FARNAM"
  },
  "69030": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HAIGLER"
  },
  "69032": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HAYES CENTER"
  },
  "69033": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "IMPERIAL"
  },
  "69034": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "INDIANOLA"
  },
  "69036": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LEBANON"
  },
  "69037": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MAX"
  },
  "69038": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MAYWOOD"
  },
  "69039": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MOOREFIELD"
  },
  "69040": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PALISADE"
  },
  "69041": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PARKS"
  },
  "69042": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "STOCKVILLE"
  },
  "69043": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "STRATTON"
  },
  "69044": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "TRENTON"
  },
  "69045": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WAUNETA"
  },
  "69046": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WILSONVILLE"
  },
  "69101": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "NORTH PLATTE"
  },
  "69120": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ARNOLD"
  },
  "69121": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ARTHUR"
  },
  "69122": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BIG SPRINGS"
  },
  "69123": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BRADY"
  },
  "69125": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BROADWATER"
  },
  "69127": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BRULE"
  },
  "69128": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BUSHNELL"
  },
  "69129": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CHAPPELL"
  },
  "69130": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "COZAD"
  },
  "69131": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DALTON"
  },
  "69132": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DICKENS"
  },
  "69133": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "DIX"
  },
  "69134": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ELSIE"
  },
  "69135": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ELSMERE"
  },
  "69138": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GOTHENBURG"
  },
  "69140": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GRANT"
  },
  "69141": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GURLEY"
  },
  "69142": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HALSEY"
  },
  "69143": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HERSHEY"
  },
  "69144": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "KEYSTONE"
  },
  "69145": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "KIMBALL"
  },
  "69146": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LEMOYNE"
  },
  "69147": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LEWELLEN"
  },
  "69148": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LISCO"
  },
  "69149": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LODGEPOLE"
  },
  "69150": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MADRID"
  },
  "69151": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MAXWELL"
  },
  "69152": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MULLEN"
  },
  "69153": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OGALLALA"
  },
  "69154": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "OSHKOSH"
  },
  "69155": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PAXTON"
  },
  "69156": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "POTTER"
  },
  "69157": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "PURDUM"
  },
  "69161": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SENECA"
  },
  "69162": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SIDNEY"
  },
  "69163": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "STAPLETON"
  },
  "69165": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SUTHERLAND"
  },
  "69166": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "THEDFORD"
  },
  "69167": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "TRYON"
  },
  "69168": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "VENANGO"
  },
  "69169": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WALLACE"
  },
  "69170": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WELLFLEET"
  },
  "69201": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "VALENTINE"
  },
  "69210": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "AINSWORTH"
  },
  "69211": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CODY"
  },
  "69212": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CROOKSTON"
  },
  "69214": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "JOHNSTOWN"
  },
  "69216": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "KILGORE"
  },
  "69217": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LONG PINE"
  },
  "69218": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MERRIMAN"
  },
  "69219": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "NENZEL"
  },
  "69220": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SPARKS"
  },
  "69221": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WOOD LAKE"
  },
  "69301": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ALLIANCE"
  },
  "69331": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ANGORA"
  },
  "69333": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ASHBY"
  },
  "69334": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BAYARD"
  },
  "69335": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BINGHAM"
  },
  "69336": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "BRIDGEPORT"
  },
  "69337": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CHADRON"
  },
  "69339": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "CRAWFORD"
  },
  "69340": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "ELLSWORTH"
  },
  "69341": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GERING"
  },
  "69343": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "GORDON"
  },
  "69345": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HARRISBURG"
  },
  "69346": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HARRISON"
  },
  "69347": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HAY SPRINGS"
  },
  "69348": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HEMINGFORD"
  },
  "69350": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "HYANNIS"
  },
  "69351": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LAKESIDE"
  },
  "69352": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "LYMAN"
  },
  "69354": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MARSLAND"
  },
  "69355": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MELBETA"
  },
  "69356": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MINATARE"
  },
  "69357": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MITCHELL"
  },
  "69358": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "MORRILL"
  },
  "69360": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "RUSHVILLE"
  },
  "69361": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "SCOTTSBLUFF"
  },
  "69365": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WHITECLAY"
  },
  "69366": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WHITMAN"
  },
  "69367": {
    stateCode: "NE",
    stateName: "Nebraska",
    city: "WHITNEY"
  },
  "70001": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "METAIRIE"
  },
  "70002": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "METAIRIE"
  },
  "70003": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "METAIRIE"
  },
  "70005": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "METAIRIE"
  },
  "70006": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "METAIRIE"
  },
  "70030": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DES ALLEMANDS"
  },
  "70031": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "AMA"
  },
  "70032": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ARABI"
  },
  "70036": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BARATARIA"
  },
  "70037": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BELLE CHASSE"
  },
  "70038": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BOOTHVILLE"
  },
  "70039": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BOUTTE"
  },
  "70040": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BRAITHWAITE"
  },
  "70041": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BURAS"
  },
  "70043": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CHALMETTE"
  },
  "70047": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DESTREHAN"
  },
  "70049": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "EDGARD"
  },
  "70050": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "EMPIRE"
  },
  "70051": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GARYVILLE"
  },
  "70052": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GRAMERCY"
  },
  "70053": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GRETNA"
  },
  "70056": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GRETNA"
  },
  "70057": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HAHNVILLE"
  },
  "70058": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HARVEY"
  },
  "70062": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "KENNER"
  },
  "70065": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "KENNER"
  },
  "70067": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LAFITTE"
  },
  "70068": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LA PLACE"
  },
  "70070": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LULING"
  },
  "70071": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LUTCHER"
  },
  "70072": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MARRERO"
  },
  "70075": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MERAUX"
  },
  "70076": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MOUNT AIRY"
  },
  "70079": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NORCO"
  },
  "70080": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PARADIS"
  },
  "70082": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "POINTE A LA HACHE"
  },
  "70083": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PORT SULPHUR"
  },
  "70084": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "RESERVE"
  },
  "70085": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SAINT BERNARD"
  },
  "70086": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SAINT JAMES"
  },
  "70087": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SAINT ROSE"
  },
  "70090": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "VACHERIE"
  },
  "70091": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "VENICE"
  },
  "70092": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "VIOLET"
  },
  "70094": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "WESTWEGO"
  },
  "70112": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70113": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70114": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70115": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70116": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70117": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70118": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70119": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70121": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70122": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70123": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70124": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70125": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70126": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70127": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70128": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70129": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70130": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70131": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ORLEANS"
  },
  "70301": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "THIBODAUX"
  },
  "70339": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PIERRE PART"
  },
  "70341": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BELLE ROSE"
  },
  "70342": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BERWICK"
  },
  "70343": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BOURG"
  },
  "70344": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CHAUVIN"
  },
  "70345": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CUT OFF"
  },
  "70346": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DONALDSONVILLE"
  },
  "70353": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DULAC"
  },
  "70354": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GALLIANO"
  },
  "70355": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GHEENS"
  },
  "70356": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GIBSON"
  },
  "70357": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GOLDEN MEADOW"
  },
  "70358": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GRAND ISLE"
  },
  "70359": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GRAY"
  },
  "70360": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HOUMA"
  },
  "70363": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HOUMA"
  },
  "70364": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HOUMA"
  },
  "70372": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LABADIEVILLE"
  },
  "70373": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LAROSE"
  },
  "70374": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LOCKPORT"
  },
  "70375": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MATHEWS"
  },
  "70377": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MONTEGUT"
  },
  "70380": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MORGAN CITY"
  },
  "70390": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NAPOLEONVILLE"
  },
  "70391": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PAINCOURTVILLE"
  },
  "70392": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PATTERSON"
  },
  "70393": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PLATTENVILLE"
  },
  "70394": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "RACELAND"
  },
  "70395": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SCHRIEVER"
  },
  "70397": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "THERIOT"
  },
  "70401": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HAMMOND"
  },
  "70403": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HAMMOND"
  },
  "70420": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ABITA SPRINGS"
  },
  "70422": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "AMITE"
  },
  "70426": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ANGIE"
  },
  "70427": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BOGALUSA"
  },
  "70431": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BUSH"
  },
  "70433": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "COVINGTON"
  },
  "70435": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "COVINGTON"
  },
  "70436": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "FLUKER"
  },
  "70437": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "FOLSOM"
  },
  "70438": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "FRANKLINTON"
  },
  "70441": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GREENSBURG"
  },
  "70442": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HUSSER"
  },
  "70443": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "INDEPENDENCE"
  },
  "70444": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "KENTWOOD"
  },
  "70445": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LACOMBE"
  },
  "70446": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LORANGER"
  },
  "70447": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MADISONVILLE"
  },
  "70448": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MANDEVILLE"
  },
  "70449": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MAUREPAS"
  },
  "70450": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MOUNT HERMON"
  },
  "70451": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NATALBANY"
  },
  "70452": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PEARL RIVER"
  },
  "70453": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PINE GROVE"
  },
  "70454": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PONCHATOULA"
  },
  "70455": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ROBERT"
  },
  "70456": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ROSELAND"
  },
  "70458": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SLIDELL"
  },
  "70460": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SLIDELL"
  },
  "70461": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SLIDELL"
  },
  "70462": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SPRINGFIELD"
  },
  "70463": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SUN"
  },
  "70464": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "TALISHEEK"
  },
  "70465": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "TANGIPAHOA"
  },
  "70466": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "TICKFAW"
  },
  "70471": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MANDEVILLE"
  },
  "70501": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LAFAYETTE"
  },
  "70503": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LAFAYETTE"
  },
  "70506": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LAFAYETTE"
  },
  "70507": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LAFAYETTE"
  },
  "70508": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LAFAYETTE"
  },
  "70510": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ABBEVILLE"
  },
  "70512": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ARNAUDVILLE"
  },
  "70513": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "AVERY ISLAND"
  },
  "70514": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BALDWIN"
  },
  "70515": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BASILE"
  },
  "70516": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BRANCH"
  },
  "70517": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BREAUX BRIDGE"
  },
  "70518": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BROUSSARD"
  },
  "70520": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CARENCRO"
  },
  "70521": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CECILIA"
  },
  "70523": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CHARENTON"
  },
  "70524": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CHATAIGNIER"
  },
  "70525": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CHURCH POINT"
  },
  "70526": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CROWLEY"
  },
  "70528": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DELCAMBRE"
  },
  "70529": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DUSON"
  },
  "70531": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "EGAN"
  },
  "70532": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ELTON"
  },
  "70533": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ERATH"
  },
  "70534": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ESTHERWOOD"
  },
  "70535": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "EUNICE"
  },
  "70537": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "EVANGELINE"
  },
  "70538": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "FRANKLIN"
  },
  "70540": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GARDEN CITY"
  },
  "70541": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GRAND COTEAU"
  },
  "70542": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GUEYDAN"
  },
  "70543": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "IOTA"
  },
  "70544": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "JEANERETTE"
  },
  "70546": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "JENNINGS"
  },
  "70548": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "KAPLAN"
  },
  "70549": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LAKE ARTHUR"
  },
  "70550": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LAWTELL"
  },
  "70552": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LOREAUVILLE"
  },
  "70554": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MAMOU"
  },
  "70555": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MAURICE"
  },
  "70556": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MERMENTAU"
  },
  "70558": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MILTON"
  },
  "70559": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MORSE"
  },
  "70560": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW IBERIA"
  },
  "70563": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW IBERIA"
  },
  "70570": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "OPELOUSAS"
  },
  "70576": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PINE PRAIRIE"
  },
  "70577": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PORT BARRE"
  },
  "70578": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "RAYNE"
  },
  "70580": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "REDDELL"
  },
  "70581": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ROANOKE"
  },
  "70582": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SAINT MARTINVILLE"
  },
  "70583": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SCOTT"
  },
  "70584": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SUNSET"
  },
  "70585": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "TURKEY CREEK"
  },
  "70586": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "VILLE PLATTE"
  },
  "70589": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "WASHINGTON"
  },
  "70591": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "WELSH"
  },
  "70592": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "YOUNGSVILLE"
  },
  "70601": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LAKE CHARLES"
  },
  "70605": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LAKE CHARLES"
  },
  "70607": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LAKE CHARLES"
  },
  "70611": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LAKE CHARLES"
  },
  "70615": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LAKE CHARLES"
  },
  "70630": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BELL CITY"
  },
  "70631": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CAMERON"
  },
  "70632": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CREOLE"
  },
  "70633": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DEQUINCY"
  },
  "70634": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DERIDDER"
  },
  "70637": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DRY CREEK"
  },
  "70638": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ELIZABETH"
  },
  "70639": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "EVANS"
  },
  "70640": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "FENTON"
  },
  "70643": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GRAND CHENIER"
  },
  "70644": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GRANT"
  },
  "70645": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HACKBERRY"
  },
  "70646": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HAYES"
  },
  "70647": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "IOWA"
  },
  "70648": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "KINDER"
  },
  "70650": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LACASSINE"
  },
  "70651": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LEBLANC"
  },
  "70652": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LONGVILLE"
  },
  "70653": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MERRYVILLE"
  },
  "70654": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MITTIE"
  },
  "70655": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "OBERLIN"
  },
  "70656": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PITKIN"
  },
  "70657": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "RAGLEY"
  },
  "70658": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "REEVES"
  },
  "70659": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ROSEPINE"
  },
  "70660": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SINGER"
  },
  "70661": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "STARKS"
  },
  "70662": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SUGARTOWN"
  },
  "70663": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SULPHUR"
  },
  "70665": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SULPHUR"
  },
  "70668": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "VINTON"
  },
  "70669": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "WESTLAKE"
  },
  "70706": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DENHAM SPRINGS"
  },
  "70710": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ADDIS"
  },
  "70711": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ALBANY"
  },
  "70712": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ANGOLA"
  },
  "70714": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BAKER"
  },
  "70715": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BATCHELOR"
  },
  "70719": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BRUSLY"
  },
  "70721": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CARVILLE"
  },
  "70722": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CLINTON"
  },
  "70723": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CONVENT"
  },
  "70725": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DARROW"
  },
  "70726": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DENHAM SPRINGS"
  },
  "70729": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ERWINVILLE"
  },
  "70730": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ETHEL"
  },
  "70732": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "FORDOCHE"
  },
  "70733": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "FRENCH SETTLEMENT"
  },
  "70734": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GEISMAR"
  },
  "70736": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GLYNN"
  },
  "70737": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GONZALES"
  },
  "70739": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GREENWELL SPRINGS"
  },
  "70740": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GROSSE TETE"
  },
  "70743": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HESTER"
  },
  "70744": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HOLDEN"
  },
  "70747": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "INNIS"
  },
  "70748": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "JACKSON"
  },
  "70749": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "JARREAU"
  },
  "70750": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "KROTZ SPRINGS"
  },
  "70752": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LAKELAND"
  },
  "70753": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LETTSWORTH"
  },
  "70754": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LIVINGSTON"
  },
  "70755": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LIVONIA"
  },
  "70756": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LOTTIE"
  },
  "70757": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MARINGOUIN"
  },
  "70759": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MORGANZA"
  },
  "70760": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEW ROADS"
  },
  "70761": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NORWOOD"
  },
  "70762": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "OSCAR"
  },
  "70763": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PAULINA"
  },
  "70764": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PLAQUEMINE"
  },
  "70767": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PORT ALLEN"
  },
  "70769": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PRAIRIEVILLE"
  },
  "70770": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PRIDE"
  },
  "70772": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ROSEDALE"
  },
  "70773": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ROUGON"
  },
  "70774": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SAINT AMANT"
  },
  "70775": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SAINT FRANCISVILLE"
  },
  "70776": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SAINT GABRIEL"
  },
  "70777": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SLAUGHTER"
  },
  "70778": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SORRENTO"
  },
  "70780": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SUNSHINE"
  },
  "70782": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "TUNICA"
  },
  "70783": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "VENTRESS"
  },
  "70785": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "WALKER"
  },
  "70787": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "WEYANOKE"
  },
  "70788": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "WHITE CASTLE"
  },
  "70789": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "WILSON"
  },
  "70791": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ZACHARY"
  },
  "70801": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BATON ROUGE"
  },
  "70802": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BATON ROUGE"
  },
  "70805": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BATON ROUGE"
  },
  "70806": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BATON ROUGE"
  },
  "70807": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BATON ROUGE"
  },
  "70808": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BATON ROUGE"
  },
  "70809": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BATON ROUGE"
  },
  "70810": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BATON ROUGE"
  },
  "70811": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BATON ROUGE"
  },
  "70812": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BATON ROUGE"
  },
  "70814": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BATON ROUGE"
  },
  "70815": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BATON ROUGE"
  },
  "70816": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BATON ROUGE"
  },
  "70817": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BATON ROUGE"
  },
  "70818": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BATON ROUGE"
  },
  "70819": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BATON ROUGE"
  },
  "70820": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BATON ROUGE"
  },
  "71001": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ARCADIA"
  },
  "71002": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ASHLAND"
  },
  "71003": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ATHENS"
  },
  "71004": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BELCHER"
  },
  "71006": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BENTON"
  },
  "71007": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BETHANY"
  },
  "71008": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BIENVILLE"
  },
  "71009": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BLANCHARD"
  },
  "71016": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CASTOR"
  },
  "71018": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "COTTON VALLEY"
  },
  "71019": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "COUSHATTA"
  },
  "71021": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CULLEN"
  },
  "71023": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DOYLINE"
  },
  "71024": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DUBBERLY"
  },
  "71027": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "FRIERSON"
  },
  "71028": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GIBSLAND"
  },
  "71029": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GILLIAM"
  },
  "71030": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GLOSTER"
  },
  "71031": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GOLDONNA"
  },
  "71032": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GRAND CANE"
  },
  "71033": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GREENWOOD"
  },
  "71034": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HALL SUMMIT"
  },
  "71037": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HAUGHTON"
  },
  "71038": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HAYNESVILLE"
  },
  "71039": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HEFLIN"
  },
  "71040": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HOMER"
  },
  "71043": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HOSSTON"
  },
  "71044": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "IDA"
  },
  "71045": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "JAMESTOWN"
  },
  "71046": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "KEATCHIE"
  },
  "71047": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "KEITHVILLE"
  },
  "71048": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LISBON"
  },
  "71049": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LOGANSPORT"
  },
  "71051": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ELM GROVE"
  },
  "71052": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MANSFIELD"
  },
  "71055": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MINDEN"
  },
  "71060": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MOORINGSPORT"
  },
  "71061": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "OIL CITY"
  },
  "71063": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PELICAN"
  },
  "71064": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PLAIN DEALING"
  },
  "71065": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PLEASANT HILL"
  },
  "71066": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "POWHATAN"
  },
  "71067": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PRINCETON"
  },
  "71068": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "RINGGOLD"
  },
  "71069": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "RODESSA"
  },
  "71070": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SALINE"
  },
  "71071": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SAREPTA"
  },
  "71072": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SHONGALOO"
  },
  "71073": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SIBLEY"
  },
  "71075": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SPRINGHILL"
  },
  "71078": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "STONEWALL"
  },
  "71079": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SUMMERFIELD"
  },
  "71082": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "VIVIAN"
  },
  "71101": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SHREVEPORT"
  },
  "71103": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SHREVEPORT"
  },
  "71104": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SHREVEPORT"
  },
  "71105": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SHREVEPORT"
  },
  "71106": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SHREVEPORT"
  },
  "71107": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SHREVEPORT"
  },
  "71108": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SHREVEPORT"
  },
  "71109": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SHREVEPORT"
  },
  "71110": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BARKSDALE AFB"
  },
  "71111": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BOSSIER CITY"
  },
  "71112": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BOSSIER CITY"
  },
  "71115": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SHREVEPORT"
  },
  "71118": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SHREVEPORT"
  },
  "71119": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SHREVEPORT"
  },
  "71129": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SHREVEPORT"
  },
  "71201": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MONROE"
  },
  "71202": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MONROE"
  },
  "71203": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MONROE"
  },
  "71218": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ARCHIBALD"
  },
  "71219": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BASKIN"
  },
  "71220": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BASTROP"
  },
  "71222": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BERNICE"
  },
  "71223": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BONITA"
  },
  "71225": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CALHOUN"
  },
  "71226": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CHATHAM"
  },
  "71227": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CHOUDRANT"
  },
  "71229": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "COLLINSTON"
  },
  "71232": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DELHI"
  },
  "71234": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DOWNSVILLE"
  },
  "71235": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DUBACH"
  },
  "71237": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "EPPS"
  },
  "71238": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "EROS"
  },
  "71241": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "FARMERVILLE"
  },
  "71243": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "FORT NECESSITY"
  },
  "71245": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GRAMBLING"
  },
  "71247": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HODGE"
  },
  "71250": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "JONES"
  },
  "71251": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "JONESBORO"
  },
  "71253": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "KILBOURNE"
  },
  "71254": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LAKE PROVIDENCE"
  },
  "71256": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LILLIE"
  },
  "71259": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MANGHAM"
  },
  "71260": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MARION"
  },
  "71261": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MER ROUGE"
  },
  "71263": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "OAK GROVE"
  },
  "71264": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "OAK RIDGE"
  },
  "71266": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PIONEER"
  },
  "71268": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "QUITMAN"
  },
  "71269": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "RAYVILLE"
  },
  "71270": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "RUSTON"
  },
  "71275": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SIMSBORO"
  },
  "71276": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SONDHEIMER"
  },
  "71277": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SPEARSVILLE"
  },
  "71280": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "STERLINGTON"
  },
  "71282": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "TALLULAH"
  },
  "71286": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "TRANSYLVANIA"
  },
  "71291": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "WEST MONROE"
  },
  "71292": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "WEST MONROE"
  },
  "71295": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "WINNSBORO"
  },
  "71301": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ALEXANDRIA"
  },
  "71302": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ALEXANDRIA"
  },
  "71303": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ALEXANDRIA"
  },
  "71316": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ACME"
  },
  "71322": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BUNKIE"
  },
  "71323": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CENTER POINT"
  },
  "71325": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CHENEYVILLE"
  },
  "71326": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CLAYTON"
  },
  "71327": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "COTTONPORT"
  },
  "71328": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DEVILLE"
  },
  "71330": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ECHO"
  },
  "71331": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "EFFIE"
  },
  "71333": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "EVERGREEN"
  },
  "71334": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "FERRIDAY"
  },
  "71336": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GILBERT"
  },
  "71339": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HAMBURG"
  },
  "71340": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HARRISONBURG"
  },
  "71341": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HESSMER"
  },
  "71342": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "JENA"
  },
  "71343": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "JONESVILLE"
  },
  "71345": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LEBEAU"
  },
  "71346": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LECOMPTE"
  },
  "71350": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MANSURA"
  },
  "71351": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MARKSVILLE"
  },
  "71353": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MELVILLE"
  },
  "71354": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MONTEREY"
  },
  "71355": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MOREAUVILLE"
  },
  "71356": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MORROW"
  },
  "71357": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEWELLTON"
  },
  "71358": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PALMETTO"
  },
  "71360": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PINEVILLE"
  },
  "71362": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PLAUCHEVILLE"
  },
  "71366": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SAINT JOSEPH"
  },
  "71367": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SAINT LANDRY"
  },
  "71368": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SICILY ISLAND"
  },
  "71369": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SIMMESPORT"
  },
  "71371": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "TROUT"
  },
  "71373": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "VIDALIA"
  },
  "71375": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "WATERPROOF"
  },
  "71377": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "WILDSVILLE"
  },
  "71378": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "WISNER"
  },
  "71401": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "AIMWELL"
  },
  "71403": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ANACOCO"
  },
  "71404": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ATLANTA"
  },
  "71406": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BELMONT"
  },
  "71407": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BENTLEY"
  },
  "71409": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "BOYCE"
  },
  "71410": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CALVIN"
  },
  "71411": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CAMPTI"
  },
  "71414": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CLARENCE"
  },
  "71415": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CLARKS"
  },
  "71416": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CLOUTIERVILLE"
  },
  "71417": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "COLFAX"
  },
  "71418": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "COLUMBIA"
  },
  "71419": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "CONVERSE"
  },
  "71422": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DODSON"
  },
  "71423": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "DRY PRONG"
  },
  "71424": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ELMER"
  },
  "71425": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ENTERPRISE"
  },
  "71426": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "FISHER"
  },
  "71427": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "FLATWOODS"
  },
  "71429": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "FLORIEN"
  },
  "71430": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "FOREST HILL"
  },
  "71432": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GEORGETOWN"
  },
  "71433": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GLENMORA"
  },
  "71434": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GORUM"
  },
  "71435": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "GRAYSON"
  },
  "71438": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HINESTON"
  },
  "71439": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "HORNBECK"
  },
  "71441": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "KELLY"
  },
  "71446": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LEESVILLE"
  },
  "71447": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "LENA"
  },
  "71449": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MANY"
  },
  "71450": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MARTHAVILLE"
  },
  "71452": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MELROSE"
  },
  "71454": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MONTGOMERY"
  },
  "71455": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "MORA"
  },
  "71456": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NATCHEZ"
  },
  "71457": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NATCHITOCHES"
  },
  "71459": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "FORT POLK"
  },
  "71461": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NEWLLANO"
  },
  "71462": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "NOBLE"
  },
  "71463": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "OAKDALE"
  },
  "71465": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "OLLA"
  },
  "71466": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "OTIS"
  },
  "71467": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "POLLOCK"
  },
  "71468": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "PROVENCAL"
  },
  "71469": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ROBELINE"
  },
  "71472": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SIEPER"
  },
  "71473": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SIKES"
  },
  "71474": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "SIMPSON"
  },
  "71479": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "TULLOS"
  },
  "71480": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "URANIA"
  },
  "71483": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "WINNFIELD"
  },
  "71485": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "WOODWORTH"
  },
  "71486": {
    stateCode: "LA",
    stateName: "Louisiana",
    city: "ZWOLLE"
  },
  "71601": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PINE BLUFF"
  },
  "71602": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WHITE HALL"
  },
  "71603": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PINE BLUFF"
  },
  "71630": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ARKANSAS CITY"
  },
  "71631": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BANKS"
  },
  "71635": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CROSSETT"
  },
  "71638": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DERMOTT"
  },
  "71639": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DUMAS"
  },
  "71640": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "EUDORA"
  },
  "71642": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FOUNTAIN HILL"
  },
  "71643": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GOULD"
  },
  "71644": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GRADY"
  },
  "71646": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HAMBURG"
  },
  "71647": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HERMITAGE"
  },
  "71651": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "JERSEY"
  },
  "71652": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "KINGSLAND"
  },
  "71653": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LAKE VILLAGE"
  },
  "71654": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MC GEHEE"
  },
  "71655": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MONTICELLO"
  },
  "71658": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MONTROSE"
  },
  "71659": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MOSCOW"
  },
  "71660": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "NEW EDINBURG"
  },
  "71661": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PARKDALE"
  },
  "71663": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PORTLAND"
  },
  "71665": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "RISON"
  },
  "71666": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MC GEHEE"
  },
  "71667": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "STAR CITY"
  },
  "71670": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "TILLAR"
  },
  "71671": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WARREN"
  },
  "71674": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WATSON"
  },
  "71675": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WILMAR"
  },
  "71676": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WILMOT"
  },
  "71677": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WINCHESTER"
  },
  "71701": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CAMDEN"
  },
  "71720": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BEARDEN"
  },
  "71722": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BLUFF CITY"
  },
  "71724": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CALION"
  },
  "71725": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CARTHAGE"
  },
  "71726": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CHIDESTER"
  },
  "71730": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "EL DORADO"
  },
  "71740": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "EMERSON"
  },
  "71742": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FORDYCE"
  },
  "71743": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GURDON"
  },
  "71744": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HAMPTON"
  },
  "71745": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HARRELL"
  },
  "71747": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HUTTIG"
  },
  "71749": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "JUNCTION CITY"
  },
  "71751": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LOUANN"
  },
  "71752": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MC NEIL"
  },
  "71753": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MAGNOLIA"
  },
  "71758": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MOUNT HOLLY"
  },
  "71759": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "NORPHLET"
  },
  "71762": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SMACKOVER"
  },
  "71763": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SPARKMAN"
  },
  "71764": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "STEPHENS"
  },
  "71765": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "STRONG"
  },
  "71766": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "THORNTON"
  },
  "71770": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WALDO"
  },
  "71801": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HOPE"
  },
  "71820": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ALLEENE"
  },
  "71822": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ASHDOWN"
  },
  "71823": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BEN LOMOND"
  },
  "71825": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BLEVINS"
  },
  "71826": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BRADLEY"
  },
  "71827": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BUCKNER"
  },
  "71832": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DE QUEEN"
  },
  "71833": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DIERKS"
  },
  "71834": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DODDRIDGE"
  },
  "71835": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "EMMET"
  },
  "71836": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FOREMAN"
  },
  "71837": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FOUKE"
  },
  "71838": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FULTON"
  },
  "71839": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GARLAND CITY"
  },
  "71841": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GILLHAM"
  },
  "71842": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HORATIO"
  },
  "71845": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LEWISVILLE"
  },
  "71846": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LOCKESBURG"
  },
  "71847": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MC CASKILL"
  },
  "71851": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MINERAL SPRINGS"
  },
  "71852": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "NASHVILLE"
  },
  "71853": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "OGDEN"
  },
  "71854": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "TEXARKANA"
  },
  "71855": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "OZAN"
  },
  "71857": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PRESCOTT"
  },
  "71858": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ROSSTON"
  },
  "71859": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SARATOGA"
  },
  "71860": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "STAMPS"
  },
  "71861": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "TAYLOR"
  },
  "71862": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WASHINGTON"
  },
  "71865": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WILTON"
  },
  "71866": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WINTHROP"
  },
  "71901": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HOT SPRINGS NATIONAL PARK"
  },
  "71909": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HOT SPRINGS VILLAGE"
  },
  "71913": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HOT SPRINGS NATIONAL PARK"
  },
  "71921": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "AMITY"
  },
  "71922": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ANTOINE"
  },
  "71923": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ARKADELPHIA"
  },
  "71929": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BISMARCK"
  },
  "71933": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BONNERDALE"
  },
  "71935": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CADDO GAP"
  },
  "71937": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "COVE"
  },
  "71940": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DELIGHT"
  },
  "71941": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DONALDSON"
  },
  "71943": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GLENWOOD"
  },
  "71944": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GRANNIS"
  },
  "71945": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HATFIELD"
  },
  "71949": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "JESSIEVILLE"
  },
  "71950": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "KIRBY"
  },
  "71952": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LANGLEY"
  },
  "71953": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MENA"
  },
  "71956": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MOUNTAIN PINE"
  },
  "71957": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MOUNT IDA"
  },
  "71958": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MURFREESBORO"
  },
  "71959": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "NEWHOPE"
  },
  "71960": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "NORMAN"
  },
  "71961": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ODEN"
  },
  "71962": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "OKOLONA"
  },
  "71964": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PEARCY"
  },
  "71965": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PENCIL BLUFF"
  },
  "71968": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ROYAL"
  },
  "71969": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SIMS"
  },
  "71970": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "STORY"
  },
  "71971": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "UMPIRE"
  },
  "71972": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "VANDERVOORT"
  },
  "71973": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WICKES"
  },
  "72001": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ADONA"
  },
  "72002": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ALEXANDER"
  },
  "72003": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ALMYRA"
  },
  "72004": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ALTHEIMER"
  },
  "72005": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "AMAGON"
  },
  "72006": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "AUGUSTA"
  },
  "72007": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "AUSTIN"
  },
  "72010": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BALD KNOB"
  },
  "72011": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BAUXITE"
  },
  "72012": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BEEBE"
  },
  "72013": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BEE BRANCH"
  },
  "72014": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BEEDEVILLE"
  },
  "72015": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BENTON"
  },
  "72016": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BIGELOW"
  },
  "72017": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BISCOE"
  },
  "72020": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BRADFORD"
  },
  "72021": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BRINKLEY"
  },
  "72022": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BRYANT"
  },
  "72023": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CABOT"
  },
  "72024": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CARLISLE"
  },
  "72025": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CASA"
  },
  "72026": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CASSCOE"
  },
  "72027": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CENTER RIDGE"
  },
  "72029": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CLARENDON"
  },
  "72030": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CLEVELAND"
  },
  "72031": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CLINTON"
  },
  "72032": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CONWAY"
  },
  "72036": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "COTTON PLANT"
  },
  "72038": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CROCKETTS BLUFF"
  },
  "72039": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DAMASCUS"
  },
  "72040": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DES ARC"
  },
  "72041": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DE VALLS BLUFF"
  },
  "72042": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DE WITT"
  },
  "72043": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DIAZ"
  },
  "72044": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "EDGEMONT"
  },
  "72045": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "EL PASO"
  },
  "72046": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ENGLAND"
  },
  "72047": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ENOLA"
  },
  "72048": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ETHEL"
  },
  "72051": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FOX"
  },
  "72053": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "COLLEGE STATION"
  },
  "72055": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GILLETT"
  },
  "72057": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GRAPEVINE"
  },
  "72058": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GREENBRIER"
  },
  "72060": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GRIFFITHVILLE"
  },
  "72061": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GUY"
  },
  "72063": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HATTIEVILLE"
  },
  "72064": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HAZEN"
  },
  "72065": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HENSLEY"
  },
  "72067": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HIGDEN"
  },
  "72068": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HIGGINSON"
  },
  "72069": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HOLLY GROVE"
  },
  "72070": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HOUSTON"
  },
  "72072": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HUMNOKE"
  },
  "72073": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HUMPHREY"
  },
  "72074": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HUNTER"
  },
  "72075": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "JACKSONPORT"
  },
  "72076": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "JACKSONVILLE"
  },
  "72079": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "JEFFERSON"
  },
  "72080": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "JERUSALEM"
  },
  "72081": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "JUDSONIA"
  },
  "72082": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "KENSETT"
  },
  "72083": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "KEO"
  },
  "72084": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LEOLA"
  },
  "72085": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LETONA"
  },
  "72086": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LONOKE"
  },
  "72087": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LONSDALE"
  },
  "72088": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FAIRFIELD BAY"
  },
  "72099": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LITTLE ROCK AIR FORCE BASE"
  },
  "72101": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MC CRORY"
  },
  "72102": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MC RAE"
  },
  "72103": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MABELVALE"
  },
  "72104": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MALVERN"
  },
  "72105": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "JONES MILL"
  },
  "72106": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MAYFLOWER"
  },
  "72107": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MENIFEE"
  },
  "72108": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MONROE"
  },
  "72110": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MORRILTON"
  },
  "72111": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MOUNT VERNON"
  },
  "72112": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "NEWPORT"
  },
  "72113": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MAUMELLE"
  },
  "72114": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "NORTH LITTLE ROCK"
  },
  "72116": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "NORTH LITTLE ROCK"
  },
  "72117": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "NORTH LITTLE ROCK"
  },
  "72118": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "NORTH LITTLE ROCK"
  },
  "72120": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SHERWOOD"
  },
  "72121": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PANGBURN"
  },
  "72122": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PARON"
  },
  "72123": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PATTERSON"
  },
  "72125": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PERRY"
  },
  "72126": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PERRYVILLE"
  },
  "72127": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PLUMERVILLE"
  },
  "72128": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "POYEN"
  },
  "72129": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PRATTSVILLE"
  },
  "72130": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PRIM"
  },
  "72131": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "QUITMAN"
  },
  "72132": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "REDFIELD"
  },
  "72133": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "REYDELL"
  },
  "72134": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ROE"
  },
  "72135": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ROLAND"
  },
  "72136": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ROMANCE"
  },
  "72137": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ROSE BUD"
  },
  "72139": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "RUSSELL"
  },
  "72140": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SAINT CHARLES"
  },
  "72141": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SCOTLAND"
  },
  "72142": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SCOTT"
  },
  "72143": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SEARCY"
  },
  "72150": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SHERIDAN"
  },
  "72152": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SHERRILL"
  },
  "72153": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SHIRLEY"
  },
  "72156": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SOLGOHACHIA"
  },
  "72157": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SPRINGFIELD"
  },
  "72160": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "STUTTGART"
  },
  "72165": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "THIDA"
  },
  "72166": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "TICHNOR"
  },
  "72167": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "TRASKWOOD"
  },
  "72168": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "TUCKER"
  },
  "72169": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "TUPELO"
  },
  "72170": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ULM"
  },
  "72173": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "VILONIA"
  },
  "72175": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WABBASEKA"
  },
  "72176": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WARD"
  },
  "72179": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WILBURN"
  },
  "72180": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WOODSON"
  },
  "72181": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WOOSTER"
  },
  "72201": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LITTLE ROCK"
  },
  "72202": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LITTLE ROCK"
  },
  "72204": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LITTLE ROCK"
  },
  "72205": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LITTLE ROCK"
  },
  "72206": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LITTLE ROCK"
  },
  "72207": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LITTLE ROCK"
  },
  "72209": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LITTLE ROCK"
  },
  "72210": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LITTLE ROCK"
  },
  "72211": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LITTLE ROCK"
  },
  "72212": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LITTLE ROCK"
  },
  "72223": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LITTLE ROCK"
  },
  "72227": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LITTLE ROCK"
  },
  "72301": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WEST MEMPHIS"
  },
  "72311": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "AUBREY"
  },
  "72315": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BLYTHEVILLE"
  },
  "72320": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BRICKEYS"
  },
  "72321": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BURDETTE"
  },
  "72322": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CALDWELL"
  },
  "72324": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CHERRY VALLEY"
  },
  "72326": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "COLT"
  },
  "72327": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CRAWFORDSVILLE"
  },
  "72328": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CRUMROD"
  },
  "72329": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DRIVER"
  },
  "72330": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DYESS"
  },
  "72331": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "EARLE"
  },
  "72332": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "EDMONDSON"
  },
  "72333": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ELAINE"
  },
  "72335": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FORREST CITY"
  },
  "72338": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FRENCHMANS BAYOU"
  },
  "72339": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GILMORE"
  },
  "72340": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GOODWIN"
  },
  "72341": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HAYNES"
  },
  "72342": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HELENA"
  },
  "72346": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HETH"
  },
  "72347": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HICKORY RIDGE"
  },
  "72348": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HUGHES"
  },
  "72350": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "JOINER"
  },
  "72351": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "KEISER"
  },
  "72353": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LAMBROOK"
  },
  "72354": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LEPANTO"
  },
  "72355": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LEXA"
  },
  "72358": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LUXORA"
  },
  "72359": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MADISON"
  },
  "72360": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MARIANNA"
  },
  "72364": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MARION"
  },
  "72365": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MARKED TREE"
  },
  "72366": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MARVELL"
  },
  "72367": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MELLWOOD"
  },
  "72368": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MORO"
  },
  "72369": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ONEIDA"
  },
  "72370": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "OSCEOLA"
  },
  "72372": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PALESTINE"
  },
  "72373": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PARKIN"
  },
  "72374": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "POPLAR GROVE"
  },
  "72376": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PROCTOR"
  },
  "72377": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "RIVERVALE"
  },
  "72379": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SNOW LAKE"
  },
  "72383": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "TURNER"
  },
  "72384": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "TURRELL"
  },
  "72386": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "TYRONZA"
  },
  "72387": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "VANNDALE"
  },
  "72389": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WABASH"
  },
  "72390": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WEST HELENA"
  },
  "72392": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WHEATLEY"
  },
  "72394": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WIDENER"
  },
  "72395": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WILSON"
  },
  "72396": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WYNNE"
  },
  "72401": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "JONESBORO"
  },
  "72404": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "JONESBORO"
  },
  "72410": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ALICIA"
  },
  "72411": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BAY"
  },
  "72412": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BEECH GROVE"
  },
  "72413": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BIGGERS"
  },
  "72414": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BLACK OAK"
  },
  "72415": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BLACK ROCK"
  },
  "72416": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BONO"
  },
  "72417": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BROOKLAND"
  },
  "72419": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CARAWAY"
  },
  "72421": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CASH"
  },
  "72422": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CORNING"
  },
  "72424": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DATTO"
  },
  "72425": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DELAPLAINE"
  },
  "72426": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DELL"
  },
  "72427": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "EGYPT"
  },
  "72428": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ETOWAH"
  },
  "72429": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FISHER"
  },
  "72430": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GREENWAY"
  },
  "72431": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GRUBBS"
  },
  "72432": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HARRISBURG"
  },
  "72433": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HOXIE"
  },
  "72434": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "IMBODEN"
  },
  "72435": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "KNOBEL"
  },
  "72436": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LAFE"
  },
  "72437": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LAKE CITY"
  },
  "72438": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LEACHVILLE"
  },
  "72440": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LYNN"
  },
  "72441": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MC DOUGAL"
  },
  "72442": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MANILA"
  },
  "72443": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MARMADUKE"
  },
  "72444": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MAYNARD"
  },
  "72447": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MONETTE"
  },
  "72449": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "O KEAN"
  },
  "72450": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PARAGOULD"
  },
  "72453": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PEACH ORCHARD"
  },
  "72454": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PIGGOTT"
  },
  "72455": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "POCAHONTAS"
  },
  "72456": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "POLLARD"
  },
  "72457": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PORTIA"
  },
  "72458": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "POWHATAN"
  },
  "72459": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "RAVENDEN"
  },
  "72460": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "RAVENDEN SPRINGS"
  },
  "72461": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "RECTOR"
  },
  "72462": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "REYNO"
  },
  "72464": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SAINT FRANCIS"
  },
  "72466": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SMITHVILLE"
  },
  "72467": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "STATE UNIVERSITY"
  },
  "72469": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "STRAWBERRY"
  },
  "72470": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SUCCESS"
  },
  "72471": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SWIFTON"
  },
  "72472": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "TRUMANN"
  },
  "72473": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "TUCKERMAN"
  },
  "72475": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WALDENBURG"
  },
  "72476": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WALNUT RIDGE"
  },
  "72478": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WARM SPRINGS"
  },
  "72479": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WEINER"
  },
  "72482": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WILLIFORD"
  },
  "72501": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BATESVILLE"
  },
  "72512": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HORSESHOE BEND"
  },
  "72513": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ASH FLAT"
  },
  "72515": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BEXAR"
  },
  "72517": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BROCKWELL"
  },
  "72519": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CALICO ROCK"
  },
  "72520": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CAMP"
  },
  "72521": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CAVE CITY"
  },
  "72522": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CHARLOTTE"
  },
  "72523": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CONCORD"
  },
  "72524": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CORD"
  },
  "72525": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CHEROKEE VILLAGE"
  },
  "72526": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CUSHMAN"
  },
  "72527": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DESHA"
  },
  "72528": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DOLPH"
  },
  "72529": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CHEROKEE VILLAGE"
  },
  "72530": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DRASCO"
  },
  "72531": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ELIZABETH"
  },
  "72532": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "EVENING SHADE"
  },
  "72533": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FIFTY SIX"
  },
  "72534": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FLORAL"
  },
  "72536": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FRANKLIN"
  },
  "72537": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GAMALIEL"
  },
  "72538": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GEPP"
  },
  "72539": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GLENCOE"
  },
  "72540": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GUION"
  },
  "72542": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HARDY"
  },
  "72543": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HEBER SPRINGS"
  },
  "72544": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HENDERSON"
  },
  "72550": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LOCUST GROVE"
  },
  "72553": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MAGNESS"
  },
  "72554": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MAMMOTH SPRING"
  },
  "72555": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MARCELLA"
  },
  "72556": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MELBOURNE"
  },
  "72560": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MOUNTAIN VIEW"
  },
  "72561": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MOUNT PLEASANT"
  },
  "72562": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "NEWARK"
  },
  "72564": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "OIL TROUGH"
  },
  "72565": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "OXFORD"
  },
  "72566": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PINEVILLE"
  },
  "72567": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PLEASANT GROVE"
  },
  "72568": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PLEASANT PLAINS"
  },
  "72569": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "POUGHKEEPSIE"
  },
  "72571": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ROSIE"
  },
  "72572": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SAFFELL"
  },
  "72573": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SAGE"
  },
  "72576": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SALEM"
  },
  "72577": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SIDNEY"
  },
  "72578": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "STURKIE"
  },
  "72579": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SULPHUR ROCK"
  },
  "72581": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "TUMBLING SHOALS"
  },
  "72583": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "VIOLA"
  },
  "72584": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "VIOLET HILL"
  },
  "72585": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WIDEMAN"
  },
  "72587": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WISEMAN"
  },
  "72601": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HARRISON"
  },
  "72611": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ALPENA"
  },
  "72616": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BERRYVILLE"
  },
  "72617": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BIG FLAT"
  },
  "72619": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BULL SHOALS"
  },
  "72623": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CLARKRIDGE"
  },
  "72624": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "COMPTON"
  },
  "72626": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "COTTER"
  },
  "72628": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DEER"
  },
  "72629": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DENNARD"
  },
  "72630": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DIAMOND CITY"
  },
  "72631": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "EUREKA SPRINGS"
  },
  "72632": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "EUREKA SPRINGS"
  },
  "72633": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "EVERTON"
  },
  "72634": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FLIPPIN"
  },
  "72635": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GASSVILLE"
  },
  "72638": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GREEN FOREST"
  },
  "72639": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HARRIET"
  },
  "72640": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HASTY"
  },
  "72641": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "JASPER"
  },
  "72642": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LAKEVIEW"
  },
  "72644": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LEAD HILL"
  },
  "72645": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LESLIE"
  },
  "72648": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MARBLE FALLS"
  },
  "72650": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MARSHALL"
  },
  "72651": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MIDWAY"
  },
  "72653": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MOUNTAIN HOME"
  },
  "72655": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MOUNT JUDEA"
  },
  "72658": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "NORFORK"
  },
  "72660": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "OAK GROVE"
  },
  "72661": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "OAKLAND"
  },
  "72662": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "OMAHA"
  },
  "72663": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ONIA"
  },
  "72666": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PARTHENON"
  },
  "72668": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PEEL"
  },
  "72669": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PINDALL"
  },
  "72672": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PYATT"
  },
  "72675": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SAINT JOE"
  },
  "72677": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SUMMIT"
  },
  "72679": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "TILLY"
  },
  "72680": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "TIMBO"
  },
  "72682": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "VALLEY SPRINGS"
  },
  "72683": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "VENDOR"
  },
  "72685": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WESTERN GROVE"
  },
  "72686": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WITTS SPRINGS"
  },
  "72687": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "YELLVILLE"
  },
  "72701": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FAYETTEVILLE"
  },
  "72703": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FAYETTEVILLE"
  },
  "72704": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FAYETTEVILLE"
  },
  "72711": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "AVOCA"
  },
  "72712": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BENTONVILLE"
  },
  "72714": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BELLA VISTA"
  },
  "72715": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BELLA VISTA"
  },
  "72717": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CANEHILL"
  },
  "72718": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CAVE SPRINGS"
  },
  "72719": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CENTERTON"
  },
  "72721": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "COMBS"
  },
  "72722": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DECATUR"
  },
  "72727": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ELKINS"
  },
  "72729": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "EVANSVILLE"
  },
  "72730": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FARMINGTON"
  },
  "72732": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GARFIELD"
  },
  "72734": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GENTRY"
  },
  "72736": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GRAVETTE"
  },
  "72738": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HINDSVILLE"
  },
  "72739": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HIWASSE"
  },
  "72740": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HUNTSVILLE"
  },
  "72742": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "KINGSTON"
  },
  "72744": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LINCOLN"
  },
  "72745": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LOWELL"
  },
  "72747": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MAYSVILLE"
  },
  "72749": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MORROW"
  },
  "72751": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PEA RIDGE"
  },
  "72752": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PETTIGREW"
  },
  "72753": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PRAIRIE GROVE"
  },
  "72756": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ROGERS"
  },
  "72758": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ROGERS"
  },
  "72760": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SAINT PAUL"
  },
  "72761": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SILOAM SPRINGS"
  },
  "72762": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SPRINGDALE"
  },
  "72764": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SPRINGDALE"
  },
  "72768": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SULPHUR SPRINGS"
  },
  "72769": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SUMMERS"
  },
  "72773": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WESLEY"
  },
  "72774": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WEST FORK"
  },
  "72776": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WITTER"
  },
  "72801": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "RUSSELLVILLE"
  },
  "72802": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "RUSSELLVILLE"
  },
  "72820": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ALIX"
  },
  "72821": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ALTUS"
  },
  "72823": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ATKINS"
  },
  "72824": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BELLEVILLE"
  },
  "72826": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BLUE MOUNTAIN"
  },
  "72827": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BLUFFTON"
  },
  "72828": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BRIGGSVILLE"
  },
  "72829": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CENTERVILLE"
  },
  "72830": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CLARKSVILLE"
  },
  "72832": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "COAL HILL"
  },
  "72833": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DANVILLE"
  },
  "72834": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DARDANELLE"
  },
  "72835": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DELAWARE"
  },
  "72837": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DOVER"
  },
  "72838": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GRAVELLY"
  },
  "72839": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HAGARVILLE"
  },
  "72840": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HARTMAN"
  },
  "72841": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HARVEY"
  },
  "72842": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HAVANA"
  },
  "72843": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HECTOR"
  },
  "72845": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "KNOXVILLE"
  },
  "72846": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LAMAR"
  },
  "72847": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LONDON"
  },
  "72851": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "NEW BLAINE"
  },
  "72852": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "OARK"
  },
  "72853": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "OLA"
  },
  "72854": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "OZONE"
  },
  "72855": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PARIS"
  },
  "72856": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PELSOR"
  },
  "72857": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PLAINVIEW"
  },
  "72858": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "POTTSVILLE"
  },
  "72860": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ROVER"
  },
  "72863": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SCRANTON"
  },
  "72865": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "SUBIACO"
  },
  "72901": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FORT SMITH"
  },
  "72903": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FORT SMITH"
  },
  "72904": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FORT SMITH"
  },
  "72908": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FORT SMITH"
  },
  "72916": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "FORT SMITH"
  },
  "72921": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "ALMA"
  },
  "72923": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BARLING"
  },
  "72926": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BOLES"
  },
  "72927": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BOONEVILLE"
  },
  "72928": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "BRANCH"
  },
  "72930": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CECIL"
  },
  "72932": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CEDARVILLE"
  },
  "72933": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CHARLESTON"
  },
  "72934": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "CHESTER"
  },
  "72935": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "DYER"
  },
  "72936": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "GREENWOOD"
  },
  "72937": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HACKETT"
  },
  "72938": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HARTFORD"
  },
  "72940": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "HUNTINGTON"
  },
  "72941": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "LAVACA"
  },
  "72943": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MAGAZINE"
  },
  "72944": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MANSFIELD"
  },
  "72945": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MIDLAND"
  },
  "72946": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MOUNTAINBURG"
  },
  "72947": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "MULBERRY"
  },
  "72948": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "NATURAL DAM"
  },
  "72949": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "OZARK"
  },
  "72950": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "PARKS"
  },
  "72951": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "RATCLIFF"
  },
  "72952": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "RUDY"
  },
  "72955": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "UNIONTOWN"
  },
  "72956": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "VAN BUREN"
  },
  "72958": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WALDRON"
  },
  "72959": {
    stateCode: "AR",
    stateName: "Arkansas",
    city: "WINSLOW"
  },
  "73002": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ALEX"
  },
  "73003": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "EDMOND"
  },
  "73004": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "AMBER"
  },
  "73005": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ANADARKO"
  },
  "73006": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "APACHE"
  },
  "73007": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ARCADIA"
  },
  "73008": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BETHANY"
  },
  "73009": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BINGER"
  },
  "73010": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BLANCHARD"
  },
  "73011": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BRADLEY"
  },
  "73013": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "EDMOND"
  },
  "73014": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CALUMET"
  },
  "73015": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CARNEGIE"
  },
  "73016": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CASHION"
  },
  "73017": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CEMENT"
  },
  "73018": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CHICKASHA"
  },
  "73020": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CHOCTAW"
  },
  "73021": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "COLONY"
  },
  "73022": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CONCHO"
  },
  "73024": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CORN"
  },
  "73026": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "NORMAN"
  },
  "73027": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "COYLE"
  },
  "73028": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CRESCENT"
  },
  "73029": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CYRIL"
  },
  "73030": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DAVIS"
  },
  "73031": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DIBBLE"
  },
  "73032": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DOUGHERTY"
  },
  "73033": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "EAKLY"
  },
  "73034": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "EDMOND"
  },
  "73036": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "EL RENO"
  },
  "73038": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FORT COBB"
  },
  "73040": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GEARY"
  },
  "73041": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GOTEBO"
  },
  "73042": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GRACEMONT"
  },
  "73043": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GREENFIELD"
  },
  "73044": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GUTHRIE"
  },
  "73045": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HARRAH"
  },
  "73047": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HINTON"
  },
  "73048": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HYDRO"
  },
  "73049": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "JONES"
  },
  "73050": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LANGSTON"
  },
  "73051": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LEXINGTON"
  },
  "73052": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LINDSAY"
  },
  "73053": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LOOKEBA"
  },
  "73054": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LUTHER"
  },
  "73055": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MARLOW"
  },
  "73056": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MARSHALL"
  },
  "73057": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MAYSVILLE"
  },
  "73058": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MERIDIAN"
  },
  "73059": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MINCO"
  },
  "73061": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MORRISON"
  },
  "73062": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MOUNTAIN VIEW"
  },
  "73063": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MULHALL"
  },
  "73064": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MUSTANG"
  },
  "73065": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "NEWCASTLE"
  },
  "73066": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "NICOMA PARK"
  },
  "73067": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "NINNEKAH"
  },
  "73068": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "NOBLE"
  },
  "73069": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "NORMAN"
  },
  "73071": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "NORMAN"
  },
  "73072": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "NORMAN"
  },
  "73073": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ORLANDO"
  },
  "73074": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PAOLI"
  },
  "73075": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PAULS VALLEY"
  },
  "73077": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PERRY"
  },
  "73078": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PIEDMONT"
  },
  "73079": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "POCASSET"
  },
  "73080": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PURCELL"
  },
  "73082": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "RUSH SPRINGS"
  },
  "73084": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SPENCER"
  },
  "73086": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SULPHUR"
  },
  "73089": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TUTTLE"
  },
  "73090": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "UNION CITY"
  },
  "73092": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "VERDEN"
  },
  "73093": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WASHINGTON"
  },
  "73095": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WAYNE"
  },
  "73096": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WEATHERFORD"
  },
  "73097": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WHEATLAND"
  },
  "73098": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WYNNEWOOD"
  },
  "73099": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "YUKON"
  },
  "73102": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73103": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73104": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73105": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73106": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73107": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73108": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73109": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73110": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73111": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73112": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73114": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73115": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73116": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73117": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73118": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73119": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73120": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73121": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73122": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73127": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73128": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73129": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73130": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73131": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73132": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73134": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73135": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73139": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73141": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73142": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73145": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73149": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73150": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73151": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73159": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73160": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73162": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73165": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73169": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73170": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73173": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73179": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKLAHOMA CITY"
  },
  "73401": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ARDMORE"
  },
  "73425": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "COUNTYLINE"
  },
  "73430": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BURNEYVILLE"
  },
  "73432": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "COLEMAN"
  },
  "73433": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ELMORE CITY"
  },
  "73434": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FOSTER"
  },
  "73435": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FOX"
  },
  "73437": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GRAHAM"
  },
  "73438": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HEALDTON"
  },
  "73439": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "KINGSTON"
  },
  "73440": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LEBANON"
  },
  "73441": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LEON"
  },
  "73442": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LOCO"
  },
  "73443": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LONE GROVE"
  },
  "73444": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HENNEPIN"
  },
  "73446": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MADILL"
  },
  "73447": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MANNSVILLE"
  },
  "73448": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MARIETTA"
  },
  "73449": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MEAD"
  },
  "73450": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MILBURN"
  },
  "73453": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OVERBROOK"
  },
  "73455": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "RAVIA"
  },
  "73456": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "RINGLING"
  },
  "73458": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SPRINGER"
  },
  "73459": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "THACKERVILLE"
  },
  "73460": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TISHOMINGO"
  },
  "73461": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WAPANUCKA"
  },
  "73463": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WILSON"
  },
  "73481": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "RATLIFF CITY"
  },
  "73487": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TATUMS"
  },
  "73488": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TUSSY"
  },
  "73491": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "VELMA"
  },
  "73501": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LAWTON"
  },
  "73503": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FORT SILL"
  },
  "73505": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LAWTON"
  },
  "73507": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LAWTON"
  },
  "73520": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ADDINGTON"
  },
  "73521": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ALTUS"
  },
  "73526": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BLAIR"
  },
  "73527": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CACHE"
  },
  "73528": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CHATTANOOGA"
  },
  "73529": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "COMANCHE"
  },
  "73530": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DAVIDSON"
  },
  "73531": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DEVOL"
  },
  "73532": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DUKE"
  },
  "73533": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DUNCAN"
  },
  "73537": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ELDORADO"
  },
  "73538": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ELGIN"
  },
  "73539": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ELMER"
  },
  "73540": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FAXON"
  },
  "73541": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FLETCHER"
  },
  "73542": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FREDERICK"
  },
  "73543": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GERONIMO"
  },
  "73544": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GOULD"
  },
  "73546": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GRANDFIELD"
  },
  "73547": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GRANITE"
  },
  "73548": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HASTINGS"
  },
  "73549": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HEADRICK"
  },
  "73550": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HOLLIS"
  },
  "73551": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HOLLISTER"
  },
  "73552": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "INDIAHOMA"
  },
  "73553": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LOVELAND"
  },
  "73554": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MANGUM"
  },
  "73555": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MANITOU"
  },
  "73556": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MARTHA"
  },
  "73557": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MEDICINE PARK"
  },
  "73559": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MOUNTAIN PARK"
  },
  "73560": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OLUSTEE"
  },
  "73561": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OSCAR"
  },
  "73562": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "RANDLETT"
  },
  "73564": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ROOSEVELT"
  },
  "73565": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "RYAN"
  },
  "73566": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SNYDER"
  },
  "73567": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "STERLING"
  },
  "73568": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TEMPLE"
  },
  "73569": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TERRAL"
  },
  "73570": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TIPTON"
  },
  "73571": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "VINSON"
  },
  "73572": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WALTERS"
  },
  "73573": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WAURIKA"
  },
  "73601": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CLINTON"
  },
  "73620": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ARAPAHO"
  },
  "73622": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BESSIE"
  },
  "73624": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BURNS FLAT"
  },
  "73625": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BUTLER"
  },
  "73626": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CANUTE"
  },
  "73627": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CARTER"
  },
  "73628": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CHEYENNE"
  },
  "73632": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CORDELL"
  },
  "73638": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CRAWFORD"
  },
  "73639": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CUSTER CITY"
  },
  "73641": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DILL CITY"
  },
  "73642": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DURHAM"
  },
  "73644": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ELK CITY"
  },
  "73645": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ERICK"
  },
  "73646": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FAY"
  },
  "73647": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FOSS"
  },
  "73650": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HAMMON"
  },
  "73651": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HOBART"
  },
  "73654": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LEEDEY"
  },
  "73655": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LONE WOLF"
  },
  "73658": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OAKWOOD"
  },
  "73659": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PUTNAM"
  },
  "73660": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "REYDON"
  },
  "73661": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ROCKY"
  },
  "73662": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SAYRE"
  },
  "73663": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SEILING"
  },
  "73664": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SENTINEL"
  },
  "73666": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SWEETWATER"
  },
  "73667": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TALOGA"
  },
  "73668": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TEXOLA"
  },
  "73669": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "THOMAS"
  },
  "73673": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WILLOW"
  },
  "73701": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ENID"
  },
  "73703": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ENID"
  },
  "73716": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ALINE"
  },
  "73717": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ALVA"
  },
  "73718": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "AMES"
  },
  "73719": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "AMORITA"
  },
  "73720": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BISON"
  },
  "73722": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BURLINGTON"
  },
  "73724": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CANTON"
  },
  "73726": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CARMEN"
  },
  "73727": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CARRIER"
  },
  "73728": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CHEROKEE"
  },
  "73729": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CLEO SPRINGS"
  },
  "73730": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "COVINGTON"
  },
  "73731": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DACOMA"
  },
  "73733": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DOUGLAS"
  },
  "73734": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DOVER"
  },
  "73735": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DRUMMOND"
  },
  "73736": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FAIRMONT"
  },
  "73737": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FAIRVIEW"
  },
  "73738": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GARBER"
  },
  "73739": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GOLTRY"
  },
  "73741": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HELENA"
  },
  "73742": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HENNESSEY"
  },
  "73743": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HILLSDALE"
  },
  "73744": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HITCHCOCK"
  },
  "73746": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HOPETON"
  },
  "73747": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ISABELLA"
  },
  "73749": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "JET"
  },
  "73750": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "KINGFISHER"
  },
  "73753": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "KREMLIN"
  },
  "73754": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LAHOMA"
  },
  "73755": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LONGDALE"
  },
  "73756": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LOYAL"
  },
  "73757": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LUCIEN"
  },
  "73758": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MANCHESTER"
  },
  "73759": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MEDFORD"
  },
  "73760": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MENO"
  },
  "73761": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "NASH"
  },
  "73762": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKARCHE"
  },
  "73763": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKEENE"
  },
  "73764": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OMEGA"
  },
  "73766": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "POND CREEK"
  },
  "73768": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "RINGWOOD"
  },
  "73771": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WAKITA"
  },
  "73772": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WATONGA"
  },
  "73773": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WAUKOMIS"
  },
  "73801": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WOODWARD"
  },
  "73802": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WOODWARD"
  },
  "73832": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ARNETT"
  },
  "73834": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BUFFALO"
  },
  "73835": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CAMARGO"
  },
  "73838": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CHESTER"
  },
  "73840": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FARGO"
  },
  "73841": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FORT SUPPLY"
  },
  "73842": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FREEDOM"
  },
  "73843": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GAGE"
  },
  "73844": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GATE"
  },
  "73848": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LAVERNE"
  },
  "73851": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MAY"
  },
  "73852": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MOORELAND"
  },
  "73853": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MUTUAL"
  },
  "73855": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ROSSTON"
  },
  "73857": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SHARON"
  },
  "73858": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SHATTUCK"
  },
  "73859": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "VICI"
  },
  "73860": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WAYNOKA"
  },
  "73901": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ADAMS"
  },
  "73931": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BALKO"
  },
  "73932": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BEAVER"
  },
  "73933": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BOISE CITY"
  },
  "73937": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FELT"
  },
  "73938": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FORGAN"
  },
  "73939": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GOODWELL"
  },
  "73942": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GUYMON"
  },
  "73944": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HARDESTY"
  },
  "73945": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HOOKER"
  },
  "73946": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "KENTON"
  },
  "73947": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "KEYES"
  },
  "73949": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TEXHOMA"
  },
  "73950": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TURPIN"
  },
  "73951": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TYRONE"
  },
  "74001": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "AVANT"
  },
  "74002": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BARNSDALL"
  },
  "74003": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BARTLESVILLE"
  },
  "74006": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BARTLESVILLE"
  },
  "74008": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BIXBY"
  },
  "74010": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BRISTOW"
  },
  "74011": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BROKEN ARROW"
  },
  "74012": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BROKEN ARROW"
  },
  "74014": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BROKEN ARROW"
  },
  "74015": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CATOOSA"
  },
  "74016": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CHELSEA"
  },
  "74017": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CLAREMORE"
  },
  "74020": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CLEVELAND"
  },
  "74021": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "COLLINSVILLE"
  },
  "74022": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "COPAN"
  },
  "74023": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CUSHING"
  },
  "74026": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DAVENPORT"
  },
  "74027": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DELAWARE"
  },
  "74028": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DEPEW"
  },
  "74029": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DEWEY"
  },
  "74030": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DRUMRIGHT"
  },
  "74032": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GLENCOE"
  },
  "74033": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GLENPOOL"
  },
  "74034": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HALLETT"
  },
  "74035": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HOMINY"
  },
  "74036": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "INOLA"
  },
  "74037": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "JENKS"
  },
  "74038": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "JENNINGS"
  },
  "74039": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "KELLYVILLE"
  },
  "74041": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "KIEFER"
  },
  "74042": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LENAPAH"
  },
  "74044": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MANNFORD"
  },
  "74045": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MARAMEC"
  },
  "74047": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MOUNDS"
  },
  "74048": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "NOWATA"
  },
  "74050": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OAKHURST"
  },
  "74051": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OCHELATA"
  },
  "74052": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OILTON"
  },
  "74053": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OOLOGAH"
  },
  "74054": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OSAGE"
  },
  "74055": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OWASSO"
  },
  "74056": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PAWHUSKA"
  },
  "74058": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PAWNEE"
  },
  "74059": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PERKINS"
  },
  "74060": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PRUE"
  },
  "74061": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "RAMONA"
  },
  "74062": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "RIPLEY"
  },
  "74063": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SAND SPRINGS"
  },
  "74066": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SAPULPA"
  },
  "74068": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SHAMROCK"
  },
  "74070": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SKIATOOK"
  },
  "74071": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SLICK"
  },
  "74072": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "S COFFEYVILLE"
  },
  "74073": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SPERRY"
  },
  "74074": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "STILLWATER"
  },
  "74075": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "STILLWATER"
  },
  "74079": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "STROUD"
  },
  "74080": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TALALA"
  },
  "74081": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TERLTON"
  },
  "74083": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WANN"
  },
  "74084": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WYNONA"
  },
  "74085": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "YALE"
  },
  "74103": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74104": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74105": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74106": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74107": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74108": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74110": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74112": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74114": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74115": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74116": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74117": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74119": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74120": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74126": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74127": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74128": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74129": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74130": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74131": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74132": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74133": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74134": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74135": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74136": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74137": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74145": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74146": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TULSA"
  },
  "74301": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "VINITA"
  },
  "74330": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ADAIR"
  },
  "74331": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "AFTON"
  },
  "74332": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BIG CABIN"
  },
  "74333": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BLUEJACKET"
  },
  "74335": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CARDIN"
  },
  "74337": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CHOUTEAU"
  },
  "74338": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "COLCORD"
  },
  "74339": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "COMMERCE"
  },
  "74340": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DISNEY"
  },
  "74342": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "EUCHA"
  },
  "74343": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FAIRLAND"
  },
  "74344": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GROVE"
  },
  "74346": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "JAY"
  },
  "74347": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "KANSAS"
  },
  "74349": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "KETCHUM"
  },
  "74350": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LANGLEY"
  },
  "74352": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LOCUST GROVE"
  },
  "74354": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MIAMI"
  },
  "74358": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "NORTH MIAMI"
  },
  "74360": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PICHER"
  },
  "74361": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PRYOR"
  },
  "74363": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "QUAPAW"
  },
  "74364": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ROSE"
  },
  "74365": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SALINA"
  },
  "74366": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SPAVINAW"
  },
  "74367": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "STRANG"
  },
  "74368": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TWIN OAKS"
  },
  "74369": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WELCH"
  },
  "74370": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WYANDOTTE"
  },
  "74401": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MUSKOGEE"
  },
  "74403": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MUSKOGEE"
  },
  "74421": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BEGGS"
  },
  "74422": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BOYNTON"
  },
  "74423": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BRAGGS"
  },
  "74425": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CANADIAN"
  },
  "74426": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CHECOTAH"
  },
  "74427": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "COOKSON"
  },
  "74428": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "COUNCIL HILL"
  },
  "74429": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "COWETA"
  },
  "74430": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CROWDER"
  },
  "74431": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DEWAR"
  },
  "74432": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "EUFAULA"
  },
  "74434": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FORT GIBSON"
  },
  "74435": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GORE"
  },
  "74436": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HASKELL"
  },
  "74437": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HENRYETTA"
  },
  "74438": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HITCHITA"
  },
  "74440": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HOYT"
  },
  "74441": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HULBERT"
  },
  "74442": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "INDIANOLA"
  },
  "74444": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MOODYS"
  },
  "74445": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MORRIS"
  },
  "74446": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKAY"
  },
  "74447": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKMULGEE"
  },
  "74450": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKTAHA"
  },
  "74451": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PARK HILL"
  },
  "74452": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PEGGS"
  },
  "74454": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PORTER"
  },
  "74455": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PORUM"
  },
  "74456": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PRESTON"
  },
  "74457": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PROCTOR"
  },
  "74459": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "RENTIESVILLE"
  },
  "74460": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SCHULTER"
  },
  "74461": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "STIDHAM"
  },
  "74462": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "STIGLER"
  },
  "74463": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TAFT"
  },
  "74464": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TAHLEQUAH"
  },
  "74467": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WAGONER"
  },
  "74468": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WAINWRIGHT"
  },
  "74469": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WARNER"
  },
  "74470": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WEBBERS FALLS"
  },
  "74471": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WELLING"
  },
  "74472": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WHITEFIELD"
  },
  "74501": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MCALESTER"
  },
  "74521": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ALBION"
  },
  "74522": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ALDERSON"
  },
  "74523": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ANTLERS"
  },
  "74525": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ATOKA"
  },
  "74528": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BLANCO"
  },
  "74530": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BROMIDE"
  },
  "74531": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CALVIN"
  },
  "74533": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CANEY"
  },
  "74534": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CENTRAHOMA"
  },
  "74535": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CLARITA"
  },
  "74536": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CLAYTON"
  },
  "74538": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "COALGATE"
  },
  "74540": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DAISY"
  },
  "74543": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FINLEY"
  },
  "74545": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GOWEN"
  },
  "74546": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HAILEYVILLE"
  },
  "74547": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HARTSHORNE"
  },
  "74549": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "KIAMICHI CHRISTIAN MISSION"
  },
  "74552": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "KINTA"
  },
  "74553": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "KIOWA"
  },
  "74554": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "KREBS"
  },
  "74555": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LANE"
  },
  "74556": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LEHIGH"
  },
  "74557": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MOYERS"
  },
  "74558": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "NASHOBA"
  },
  "74560": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PITTSBURG"
  },
  "74561": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "QUINTON"
  },
  "74562": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "RATTAN"
  },
  "74563": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "RED OAK"
  },
  "74565": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SAVANNA"
  },
  "74567": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SNOW"
  },
  "74569": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "STRINGTOWN"
  },
  "74570": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "STUART"
  },
  "74571": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TALIHINA"
  },
  "74572": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TUPELO"
  },
  "74574": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TUSKAHOMA"
  },
  "74576": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WARDVILLE"
  },
  "74577": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WHITESBORO"
  },
  "74578": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WILBURTON"
  },
  "74601": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PONCA CITY"
  },
  "74604": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PONCA CITY"
  },
  "74630": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BILLINGS"
  },
  "74631": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BLACKWELL"
  },
  "74632": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BRAMAN"
  },
  "74633": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BURBANK"
  },
  "74636": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DEER CREEK"
  },
  "74637": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FAIRFAX"
  },
  "74640": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HUNTER"
  },
  "74641": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "KAW CITY"
  },
  "74643": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LAMONT"
  },
  "74644": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MARLAND"
  },
  "74646": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "NARDIN"
  },
  "74647": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "NEWKIRK"
  },
  "74650": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "RALSTON"
  },
  "74651": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "RED ROCK"
  },
  "74652": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SHIDLER"
  },
  "74653": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TONKAWA"
  },
  "74701": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DURANT"
  },
  "74720": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ACHILLE"
  },
  "74721": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ALBANY"
  },
  "74722": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BATTIEST"
  },
  "74723": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BENNINGTON"
  },
  "74724": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BETHEL"
  },
  "74726": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BOKCHITO"
  },
  "74727": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BOSWELL"
  },
  "74728": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BROKEN BOW"
  },
  "74729": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CADDO"
  },
  "74730": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CALERA"
  },
  "74731": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CARTWRIGHT"
  },
  "74733": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "COLBERT"
  },
  "74734": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "EAGLETOWN"
  },
  "74735": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FORT TOWSON"
  },
  "74736": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GARVIN"
  },
  "74737": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GOLDEN"
  },
  "74738": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GRANT"
  },
  "74740": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HAWORTH"
  },
  "74741": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HENDRIX"
  },
  "74743": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HUGO"
  },
  "74745": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "IDABEL"
  },
  "74747": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "KEMP"
  },
  "74748": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "KENEFIC"
  },
  "74750": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MILLERTON"
  },
  "74753": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PLATTER"
  },
  "74754": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "RINGOLD"
  },
  "74755": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "RUFE"
  },
  "74756": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SAWYER"
  },
  "74759": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SOPER"
  },
  "74760": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SPENCERVILLE"
  },
  "74761": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SWINK"
  },
  "74764": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "VALLIANT"
  },
  "74766": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WRIGHT CITY"
  },
  "74801": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SHAWNEE"
  },
  "74804": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SHAWNEE"
  },
  "74820": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ADA"
  },
  "74824": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "AGRA"
  },
  "74825": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ALLEN"
  },
  "74826": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ASHER"
  },
  "74827": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ATWOOD"
  },
  "74829": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BOLEY"
  },
  "74830": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BOWLEGS"
  },
  "74831": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BYARS"
  },
  "74832": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CARNEY"
  },
  "74833": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CASTLE"
  },
  "74834": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CHANDLER"
  },
  "74836": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CONNERVILLE"
  },
  "74837": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CROMWELL"
  },
  "74839": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "DUSTIN"
  },
  "74840": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "EARLSBORO"
  },
  "74842": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FITTSTOWN"
  },
  "74843": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FITZHUGH"
  },
  "74844": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FRANCIS"
  },
  "74845": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HANNA"
  },
  "74848": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HOLDENVILLE"
  },
  "74849": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "KONAWA"
  },
  "74850": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LAMAR"
  },
  "74851": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MCLOUD"
  },
  "74852": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MACOMB"
  },
  "74854": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MAUD"
  },
  "74855": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MEEKER"
  },
  "74856": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MILL CREEK"
  },
  "74857": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "NEWALLA"
  },
  "74859": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "OKEMAH"
  },
  "74860": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PADEN"
  },
  "74864": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PRAGUE"
  },
  "74865": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ROFF"
  },
  "74866": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SAINT LOUIS"
  },
  "74867": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SASAKWA"
  },
  "74868": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SEMINOLE"
  },
  "74869": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SPARKS"
  },
  "74871": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "STONEWALL"
  },
  "74872": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "STRATFORD"
  },
  "74873": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TECUMSEH"
  },
  "74875": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "TRYON"
  },
  "74878": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WANETTE"
  },
  "74880": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WELEETKA"
  },
  "74881": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WELLSTON"
  },
  "74883": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WETUMKA"
  },
  "74884": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WEWOKA"
  },
  "74901": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ARKOMA"
  },
  "74902": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "POCOLA"
  },
  "74930": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BOKOSHE"
  },
  "74931": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "BUNCH"
  },
  "74932": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "CAMERON"
  },
  "74935": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "FANSHAWE"
  },
  "74936": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "GANS"
  },
  "74937": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HEAVENER"
  },
  "74939": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HODGEN"
  },
  "74940": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "HOWE"
  },
  "74941": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "KEOTA"
  },
  "74942": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LEFLORE"
  },
  "74943": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "LEQUIRE"
  },
  "74944": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MCCURTAIN"
  },
  "74945": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MARBLE CITY"
  },
  "74946": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MOFFETT"
  },
  "74947": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MONROE"
  },
  "74948": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MULDROW"
  },
  "74949": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "MUSE"
  },
  "74951": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "PANAMA"
  },
  "74953": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "POTEAU"
  },
  "74954": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "ROLAND"
  },
  "74955": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SALLISAW"
  },
  "74956": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SHADY POINT"
  },
  "74957": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SMITHVILLE"
  },
  "74959": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "SPIRO"
  },
  "74960": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "STILWELL"
  },
  "74962": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "VIAN"
  },
  "74963": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WATSON"
  },
  "74964": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WATTS"
  },
  "74965": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WESTVILLE"
  },
  "74966": {
    stateCode: "OK",
    stateName: "Oklahoma",
    city: "WISTER"
  },
  "75001": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ADDISON"
  },
  "75002": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ALLEN"
  },
  "75006": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CARROLLTON"
  },
  "75007": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CARROLLTON"
  },
  "75009": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CELINA"
  },
  "75010": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CARROLLTON"
  },
  "75013": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ALLEN"
  },
  "75019": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COPPELL"
  },
  "75020": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DENISON"
  },
  "75021": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DENISON"
  },
  "75022": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FLOWER MOUND"
  },
  "75023": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PLANO"
  },
  "75024": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PLANO"
  },
  "75025": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PLANO"
  },
  "75028": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FLOWER MOUND"
  },
  "75032": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROCKWALL"
  },
  "75034": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FRISCO"
  },
  "75035": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FRISCO"
  },
  "75038": {
    stateCode: "TX",
    stateName: "Texas",
    city: "IRVING"
  },
  "75039": {
    stateCode: "TX",
    stateName: "Texas",
    city: "IRVING"
  },
  "75040": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GARLAND"
  },
  "75041": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GARLAND"
  },
  "75042": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GARLAND"
  },
  "75043": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GARLAND"
  },
  "75044": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GARLAND"
  },
  "75048": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SACHSE"
  },
  "75050": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GRAND PRAIRIE"
  },
  "75051": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GRAND PRAIRIE"
  },
  "75052": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GRAND PRAIRIE"
  },
  "75056": {
    stateCode: "TX",
    stateName: "Texas",
    city: "THE COLONY"
  },
  "75057": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LEWISVILLE"
  },
  "75058": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GUNTER"
  },
  "75060": {
    stateCode: "TX",
    stateName: "Texas",
    city: "IRVING"
  },
  "75061": {
    stateCode: "TX",
    stateName: "Texas",
    city: "IRVING"
  },
  "75062": {
    stateCode: "TX",
    stateName: "Texas",
    city: "IRVING"
  },
  "75063": {
    stateCode: "TX",
    stateName: "Texas",
    city: "IRVING"
  },
  "75065": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LAKE DALLAS"
  },
  "75067": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LEWISVILLE"
  },
  "75068": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LITTLE ELM"
  },
  "75069": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MCKINNEY"
  },
  "75070": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MCKINNEY"
  },
  "75074": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PLANO"
  },
  "75075": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PLANO"
  },
  "75076": {
    stateCode: "TX",
    stateName: "Texas",
    city: "POTTSBORO"
  },
  "75077": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LEWISVILLE"
  },
  "75078": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PROSPER"
  },
  "75080": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RICHARDSON"
  },
  "75081": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RICHARDSON"
  },
  "75082": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RICHARDSON"
  },
  "75087": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROCKWALL"
  },
  "75088": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROWLETT"
  },
  "75089": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROWLETT"
  },
  "75090": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SHERMAN"
  },
  "75092": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SHERMAN"
  },
  "75093": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PLANO"
  },
  "75094": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PLANO"
  },
  "75098": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WYLIE"
  },
  "75101": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BARDWELL"
  },
  "75102": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BARRY"
  },
  "75103": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CANTON"
  },
  "75104": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CEDAR HILL"
  },
  "75105": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CHATFIELD"
  },
  "75110": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORSICANA"
  },
  "75114": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CRANDALL"
  },
  "75115": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DESOTO"
  },
  "75116": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DUNCANVILLE"
  },
  "75117": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EDGEWOOD"
  },
  "75119": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ENNIS"
  },
  "75121": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COPEVILLE"
  },
  "75124": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EUSTACE"
  },
  "75125": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FERRIS"
  },
  "75126": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORNEY"
  },
  "75127": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FRUITVALE"
  },
  "75132": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FATE"
  },
  "75134": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LANCASTER"
  },
  "75135": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CADDO MILLS"
  },
  "75137": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DUNCANVILLE"
  },
  "75140": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GRAND SALINE"
  },
  "75141": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HUTCHINS"
  },
  "75142": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KAUFMAN"
  },
  "75143": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KEMP"
  },
  "75144": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KERENS"
  },
  "75146": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LANCASTER"
  },
  "75147": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MABANK"
  },
  "75148": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MALAKOFF"
  },
  "75149": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MESQUITE"
  },
  "75150": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MESQUITE"
  },
  "75152": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PALMER"
  },
  "75153": {
    stateCode: "TX",
    stateName: "Texas",
    city: "POWELL"
  },
  "75154": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RED OAK"
  },
  "75155": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RICE"
  },
  "75158": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SCURRY"
  },
  "75159": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SEAGOVILLE"
  },
  "75160": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TERRELL"
  },
  "75161": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TERRELL"
  },
  "75163": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TRINIDAD"
  },
  "75164": {
    stateCode: "TX",
    stateName: "Texas",
    city: "JOSEPHINE"
  },
  "75165": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WAXAHACHIE"
  },
  "75166": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LAVON"
  },
  "75167": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WAXAHACHIE"
  },
  "75169": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WILLS POINT"
  },
  "75172": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WILMER"
  },
  "75173": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NEVADA"
  },
  "75180": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MESQUITE"
  },
  "75181": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MESQUITE"
  },
  "75182": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SUNNYVALE"
  },
  "75189": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROYSE CITY"
  },
  "75201": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75202": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75203": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75204": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75205": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75206": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75207": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75208": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75209": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75210": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75211": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75212": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75214": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75215": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75216": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75217": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75218": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75219": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75220": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75223": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75224": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75225": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75226": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75227": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75228": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75229": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75230": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75231": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75232": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75233": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75234": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75235": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75236": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75237": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75238": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75240": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75241": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75243": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75244": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75246": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75247": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75248": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75249": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75251": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75252": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75253": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75287": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALLAS"
  },
  "75401": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GREENVILLE"
  },
  "75402": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GREENVILLE"
  },
  "75407": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PRINCETON"
  },
  "75409": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ANNA"
  },
  "75410": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ALBA"
  },
  "75411": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARTHUR CITY"
  },
  "75412": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BAGWELL"
  },
  "75413": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BAILEY"
  },
  "75414": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BELLS"
  },
  "75415": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BEN FRANKLIN"
  },
  "75416": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BLOSSOM"
  },
  "75417": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BOGATA"
  },
  "75418": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BONHAM"
  },
  "75420": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRASHEAR"
  },
  "75421": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BROOKSTON"
  },
  "75422": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CAMPBELL"
  },
  "75423": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CELESTE"
  },
  "75424": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BLUE RIDGE"
  },
  "75426": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CLARKSVILLE"
  },
  "75428": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COMMERCE"
  },
  "75431": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COMO"
  },
  "75432": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COOPER"
  },
  "75433": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CUMBY"
  },
  "75434": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CUNNINGHAM"
  },
  "75435": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DEPORT"
  },
  "75436": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DETROIT"
  },
  "75437": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DIKE"
  },
  "75438": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DODD CITY"
  },
  "75439": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ECTOR"
  },
  "75440": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EMORY"
  },
  "75441": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ENLOE"
  },
  "75442": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FARMERSVILLE"
  },
  "75444": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GOLDEN"
  },
  "75446": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HONEY GROVE"
  },
  "75447": {
    stateCode: "TX",
    stateName: "Texas",
    city: "IVANHOE"
  },
  "75448": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KLONDIKE"
  },
  "75449": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LADONIA"
  },
  "75450": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LAKE CREEK"
  },
  "75451": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LEESBURG"
  },
  "75452": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LEONARD"
  },
  "75453": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LONE OAK"
  },
  "75454": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MELISSA"
  },
  "75455": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MOUNT PLEASANT"
  },
  "75457": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MOUNT VERNON"
  },
  "75459": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOWE"
  },
  "75460": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PARIS"
  },
  "75462": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PARIS"
  },
  "75468": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PATTONVILLE"
  },
  "75469": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PECAN GAP"
  },
  "75470": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PETTY"
  },
  "75471": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PICKTON"
  },
  "75472": {
    stateCode: "TX",
    stateName: "Texas",
    city: "POINT"
  },
  "75473": {
    stateCode: "TX",
    stateName: "Texas",
    city: "POWDERLY"
  },
  "75474": {
    stateCode: "TX",
    stateName: "Texas",
    city: "QUINLAN"
  },
  "75475": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RANDOLPH"
  },
  "75476": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RAVENNA"
  },
  "75477": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROXTON"
  },
  "75478": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SALTILLO"
  },
  "75479": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAVOY"
  },
  "75480": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SCROGGINS"
  },
  "75481": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SULPHUR BLUFF"
  },
  "75482": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SULPHUR SPRINGS"
  },
  "75485": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WESTMINSTER"
  },
  "75486": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SUMNER"
  },
  "75487": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TALCO"
  },
  "75488": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TELEPHONE"
  },
  "75489": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TOM BEAN"
  },
  "75490": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TRENTON"
  },
  "75491": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WHITEWRIGHT"
  },
  "75492": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WINDOM"
  },
  "75493": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WINFIELD"
  },
  "75494": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WINNSBORO"
  },
  "75495": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VAN ALSTYNE"
  },
  "75496": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WOLFE CITY"
  },
  "75497": {
    stateCode: "TX",
    stateName: "Texas",
    city: "YANTIS"
  },
  "75501": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TEXARKANA"
  },
  "75503": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TEXARKANA"
  },
  "75550": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ANNONA"
  },
  "75551": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ATLANTA"
  },
  "75554": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AVERY"
  },
  "75555": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BIVINS"
  },
  "75556": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BLOOMBURG"
  },
  "75558": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COOKVILLE"
  },
  "75559": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DE KALB"
  },
  "75560": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DOUGLASSVILLE"
  },
  "75561": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOOKS"
  },
  "75562": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KILDARE"
  },
  "75563": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LINDEN"
  },
  "75564": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LODI"
  },
  "75565": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MC LEOD"
  },
  "75566": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MARIETTA"
  },
  "75567": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MAUD"
  },
  "75568": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NAPLES"
  },
  "75569": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NASH"
  },
  "75570": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NEW BOSTON"
  },
  "75571": {
    stateCode: "TX",
    stateName: "Texas",
    city: "OMAHA"
  },
  "75572": {
    stateCode: "TX",
    stateName: "Texas",
    city: "QUEEN CITY"
  },
  "75573": {
    stateCode: "TX",
    stateName: "Texas",
    city: "REDWATER"
  },
  "75574": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SIMMS"
  },
  "75601": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LONGVIEW"
  },
  "75602": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LONGVIEW"
  },
  "75603": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LONGVIEW"
  },
  "75604": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LONGVIEW"
  },
  "75605": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LONGVIEW"
  },
  "75630": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AVINGER"
  },
  "75631": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BECKVILLE"
  },
  "75633": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CARTHAGE"
  },
  "75636": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CASON"
  },
  "75638": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DAINGERFIELD"
  },
  "75639": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DE BERRY"
  },
  "75640": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DIANA"
  },
  "75641": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EASTON"
  },
  "75643": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GARY"
  },
  "75644": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GILMER"
  },
  "75647": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GLADEWATER"
  },
  "75650": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HALLSVILLE"
  },
  "75651": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HARLETON"
  },
  "75652": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HENDERSON"
  },
  "75654": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HENDERSON"
  },
  "75656": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HUGHES SPRINGS"
  },
  "75657": {
    stateCode: "TX",
    stateName: "Texas",
    city: "JEFFERSON"
  },
  "75661": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KARNACK"
  },
  "75662": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KILGORE"
  },
  "75667": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LANEVILLE"
  },
  "75668": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LONE STAR"
  },
  "75669": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LONG BRANCH"
  },
  "75670": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MARSHALL"
  },
  "75672": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MARSHALL"
  },
  "75680": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MINDEN"
  },
  "75681": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MOUNT ENTERPRISE"
  },
  "75683": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ORE CITY"
  },
  "75684": {
    stateCode: "TX",
    stateName: "Texas",
    city: "OVERTON"
  },
  "75686": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PITTSBURG"
  },
  "75691": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TATUM"
  },
  "75692": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WASKOM"
  },
  "75693": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WHITE OAK"
  },
  "75701": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TYLER"
  },
  "75702": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TYLER"
  },
  "75703": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TYLER"
  },
  "75704": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TYLER"
  },
  "75705": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TYLER"
  },
  "75706": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TYLER"
  },
  "75707": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TYLER"
  },
  "75708": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TYLER"
  },
  "75709": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TYLER"
  },
  "75750": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARP"
  },
  "75751": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ATHENS"
  },
  "75754": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BEN WHEELER"
  },
  "75755": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BIG SANDY"
  },
  "75756": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BROWNSBORO"
  },
  "75757": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BULLARD"
  },
  "75758": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CHANDLER"
  },
  "75759": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CUNEY"
  },
  "75760": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CUSHING"
  },
  "75762": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FLINT"
  },
  "75763": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FRANKSTON"
  },
  "75764": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GALLATIN"
  },
  "75765": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HAWKINS"
  },
  "75766": {
    stateCode: "TX",
    stateName: "Texas",
    city: "JACKSONVILLE"
  },
  "75770": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LARUE"
  },
  "75771": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LINDALE"
  },
  "75773": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MINEOLA"
  },
  "75778": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MURCHISON"
  },
  "75782": {
    stateCode: "TX",
    stateName: "Texas",
    city: "POYNOR"
  },
  "75783": {
    stateCode: "TX",
    stateName: "Texas",
    city: "QUITMAN"
  },
  "75784": {
    stateCode: "TX",
    stateName: "Texas",
    city: "REKLAW"
  },
  "75785": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RUSK"
  },
  "75788": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SACUL"
  },
  "75789": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TROUP"
  },
  "75790": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VAN"
  },
  "75791": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WHITEHOUSE"
  },
  "75792": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WINONA"
  },
  "75801": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PALESTINE"
  },
  "75831": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BUFFALO"
  },
  "75833": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CENTERVILLE"
  },
  "75835": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CROCKETT"
  },
  "75838": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DONIE"
  },
  "75839": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ELKHART"
  },
  "75840": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FAIRFIELD"
  },
  "75844": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GRAPELAND"
  },
  "75845": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GROVETON"
  },
  "75846": {
    stateCode: "TX",
    stateName: "Texas",
    city: "JEWETT"
  },
  "75847": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KENNARD"
  },
  "75848": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KIRVIN"
  },
  "75849": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LATEXO"
  },
  "75850": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LEONA"
  },
  "75851": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LOVELADY"
  },
  "75852": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MIDWAY"
  },
  "75853": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MONTALBA"
  },
  "75855": {
    stateCode: "TX",
    stateName: "Texas",
    city: "OAKWOOD"
  },
  "75856": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PENNINGTON"
  },
  "75858": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RATCLIFF"
  },
  "75859": {
    stateCode: "TX",
    stateName: "Texas",
    city: "STREETMAN"
  },
  "75860": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TEAGUE"
  },
  "75861": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TENNESSEE COLONY"
  },
  "75862": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TRINITY"
  },
  "75901": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUFKIN"
  },
  "75904": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUFKIN"
  },
  "75925": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ALTO"
  },
  "75926": {
    stateCode: "TX",
    stateName: "Texas",
    city: "APPLE SPRINGS"
  },
  "75928": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BON WIER"
  },
  "75929": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BROADDUS"
  },
  "75930": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRONSON"
  },
  "75931": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BROOKELAND"
  },
  "75932": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BURKEVILLE"
  },
  "75933": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CALL"
  },
  "75934": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CAMDEN"
  },
  "75935": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CENTER"
  },
  "75936": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CHESTER"
  },
  "75937": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CHIRENO"
  },
  "75938": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COLMESNEIL"
  },
  "75939": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORRIGAN"
  },
  "75941": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DIBOLL"
  },
  "75942": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DOUCETTE"
  },
  "75943": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DOUGLASS"
  },
  "75944": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ETOILE"
  },
  "75946": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GARRISON"
  },
  "75948": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HEMPHILL"
  },
  "75949": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HUNTINGTON"
  },
  "75951": {
    stateCode: "TX",
    stateName: "Texas",
    city: "JASPER"
  },
  "75954": {
    stateCode: "TX",
    stateName: "Texas",
    city: "JOAQUIN"
  },
  "75956": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KIRBYVILLE"
  },
  "75959": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MILAM"
  },
  "75960": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MOSCOW"
  },
  "75961": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NACOGDOCHES"
  },
  "75964": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NACOGDOCHES"
  },
  "75966": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NEWTON"
  },
  "75968": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PINELAND"
  },
  "75969": {
    stateCode: "TX",
    stateName: "Texas",
    city: "POLLOK"
  },
  "75972": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN AUGUSTINE"
  },
  "75973": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SHELBYVILLE"
  },
  "75974": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TENAHA"
  },
  "75975": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TIMPSON"
  },
  "75976": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WELLS"
  },
  "75977": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WIERGATE"
  },
  "75978": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WODEN"
  },
  "75979": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WOODVILLE"
  },
  "75980": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ZAVALLA"
  },
  "76001": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARLINGTON"
  },
  "76002": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARLINGTON"
  },
  "76006": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARLINGTON"
  },
  "76008": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ALEDO"
  },
  "76009": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ALVARADO"
  },
  "76010": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARLINGTON"
  },
  "76011": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARLINGTON"
  },
  "76012": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARLINGTON"
  },
  "76013": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARLINGTON"
  },
  "76014": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARLINGTON"
  },
  "76015": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARLINGTON"
  },
  "76016": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARLINGTON"
  },
  "76017": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARLINGTON"
  },
  "76018": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARLINGTON"
  },
  "76020": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AZLE"
  },
  "76021": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BEDFORD"
  },
  "76022": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BEDFORD"
  },
  "76023": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BOYD"
  },
  "76028": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BURLESON"
  },
  "76031": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CLEBURNE"
  },
  "76034": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COLLEYVILLE"
  },
  "76035": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CRESSON"
  },
  "76036": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CROWLEY"
  },
  "76039": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EULESS"
  },
  "76040": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EULESS"
  },
  "76041": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORRESTON"
  },
  "76043": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GLEN ROSE"
  },
  "76044": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GODLEY"
  },
  "76048": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GRANBURY"
  },
  "76049": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GRANBURY"
  },
  "76050": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GRANDVIEW"
  },
  "76051": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GRAPEVINE"
  },
  "76052": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HASLET"
  },
  "76053": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HURST"
  },
  "76054": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HURST"
  },
  "76055": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ITASCA"
  },
  "76058": {
    stateCode: "TX",
    stateName: "Texas",
    city: "JOSHUA"
  },
  "76059": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KEENE"
  },
  "76060": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KENNEDALE"
  },
  "76061": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LILLIAN"
  },
  "76063": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MANSFIELD"
  },
  "76064": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MAYPEARL"
  },
  "76065": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MIDLOTHIAN"
  },
  "76066": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MILLSAP"
  },
  "76067": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MINERAL WELLS"
  },
  "76070": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NEMO"
  },
  "76071": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NEWARK"
  },
  "76073": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PARADISE"
  },
  "76077": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RAINBOW"
  },
  "76078": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RHOME"
  },
  "76082": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SPRINGTOWN"
  },
  "76084": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VENUS"
  },
  "76086": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WEATHERFORD"
  },
  "76087": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WEATHERFORD"
  },
  "76088": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WEATHERFORD"
  },
  "76092": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SOUTHLAKE"
  },
  "76093": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RIO VISTA"
  },
  "76102": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76103": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76104": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76105": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76106": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76107": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76108": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76109": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76110": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76111": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76112": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76114": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76115": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76116": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76117": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HALTOM CITY"
  },
  "76118": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76119": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76120": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76123": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76126": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76127": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NAVAL AIR STATION/ JRB"
  },
  "76131": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76132": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76133": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76134": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76135": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76137": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76140": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76148": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76155": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76177": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76179": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT WORTH"
  },
  "76180": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NORTH RICHLAND HILLS"
  },
  "76201": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DENTON"
  },
  "76205": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DENTON"
  },
  "76207": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DENTON"
  },
  "76208": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DENTON"
  },
  "76225": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ALVORD"
  },
  "76226": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARGYLE"
  },
  "76227": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUBREY"
  },
  "76228": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BELLEVUE"
  },
  "76230": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BOWIE"
  },
  "76233": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COLLINSVILLE"
  },
  "76234": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DECATUR"
  },
  "76238": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ERA"
  },
  "76239": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORESTBURG"
  },
  "76240": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GAINESVILLE"
  },
  "76245": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GORDONVILLE"
  },
  "76246": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GREENWOOD"
  },
  "76247": {
    stateCode: "TX",
    stateName: "Texas",
    city: "JUSTIN"
  },
  "76248": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KELLER"
  },
  "76249": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KRUM"
  },
  "76250": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LINDSAY"
  },
  "76251": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MONTAGUE"
  },
  "76252": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MUENSTER"
  },
  "76253": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MYRA"
  },
  "76255": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NOCONA"
  },
  "76258": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PILOT POINT"
  },
  "76259": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PONDER"
  },
  "76261": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RINGGOLD"
  },
  "76262": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROANOKE"
  },
  "76264": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SADLER"
  },
  "76265": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAINT JO"
  },
  "76266": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SANGER"
  },
  "76268": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SOUTHMAYD"
  },
  "76270": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SUNSET"
  },
  "76271": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TIOGA"
  },
  "76272": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VALLEY VIEW"
  },
  "76273": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WHITESBORO"
  },
  "76301": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WICHITA FALLS"
  },
  "76302": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WICHITA FALLS"
  },
  "76305": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WICHITA FALLS"
  },
  "76306": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WICHITA FALLS"
  },
  "76308": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WICHITA FALLS"
  },
  "76309": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WICHITA FALLS"
  },
  "76310": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WICHITA FALLS"
  },
  "76311": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SHEPPARD AFB"
  },
  "76351": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARCHER CITY"
  },
  "76354": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BURKBURNETT"
  },
  "76357": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BYERS"
  },
  "76360": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ELECTRA"
  },
  "76363": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GOREE"
  },
  "76364": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HARROLD"
  },
  "76365": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HENRIETTA"
  },
  "76366": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOLLIDAY"
  },
  "76367": {
    stateCode: "TX",
    stateName: "Texas",
    city: "IOWA PARK"
  },
  "76370": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MEGARGEL"
  },
  "76371": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MUNDAY"
  },
  "76372": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NEWCASTLE"
  },
  "76373": {
    stateCode: "TX",
    stateName: "Texas",
    city: "OKLAUNION"
  },
  "76374": {
    stateCode: "TX",
    stateName: "Texas",
    city: "OLNEY"
  },
  "76377": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PETROLIA"
  },
  "76379": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SCOTLAND"
  },
  "76380": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SEYMOUR"
  },
  "76384": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VERNON"
  },
  "76388": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WEINERT"
  },
  "76389": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WINDTHORST"
  },
  "76401": {
    stateCode: "TX",
    stateName: "Texas",
    city: "STEPHENVILLE"
  },
  "76424": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRECKENRIDGE"
  },
  "76426": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRIDGEPORT"
  },
  "76427": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRYSON"
  },
  "76429": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CADDO"
  },
  "76430": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ALBANY"
  },
  "76431": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CHICO"
  },
  "76432": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BLANKET"
  },
  "76433": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BLUFF DALE"
  },
  "76435": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CARBON"
  },
  "76436": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CARLTON"
  },
  "76437": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CISCO"
  },
  "76442": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COMANCHE"
  },
  "76443": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CROSS PLAINS"
  },
  "76444": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DE LEON"
  },
  "76445": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DESDEMONA"
  },
  "76446": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DUBLIN"
  },
  "76448": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EASTLAND"
  },
  "76449": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GRAFORD"
  },
  "76450": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GRAHAM"
  },
  "76452": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ENERGY"
  },
  "76453": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GORDON"
  },
  "76454": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GORMAN"
  },
  "76455": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GUSTINE"
  },
  "76457": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HICO"
  },
  "76458": {
    stateCode: "TX",
    stateName: "Texas",
    city: "JACKSBORO"
  },
  "76459": {
    stateCode: "TX",
    stateName: "Texas",
    city: "JERMYN"
  },
  "76460": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LOVING"
  },
  "76462": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LIPAN"
  },
  "76463": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MINGUS"
  },
  "76464": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MORAN"
  },
  "76466": {
    stateCode: "TX",
    stateName: "Texas",
    city: "OLDEN"
  },
  "76468": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PROCTOR"
  },
  "76469": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PUTNAM"
  },
  "76470": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RANGER"
  },
  "76471": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RISING STAR"
  },
  "76472": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SANTO"
  },
  "76474": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SIDNEY"
  },
  "76475": {
    stateCode: "TX",
    stateName: "Texas",
    city: "STRAWN"
  },
  "76476": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TOLAR"
  },
  "76481": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SOUTH BEND"
  },
  "76483": {
    stateCode: "TX",
    stateName: "Texas",
    city: "THROCKMORTON"
  },
  "76484": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PALO PINTO"
  },
  "76486": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PERRIN"
  },
  "76487": {
    stateCode: "TX",
    stateName: "Texas",
    city: "POOLVILLE"
  },
  "76490": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WHITT"
  },
  "76491": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WOODSON"
  },
  "76501": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TEMPLE"
  },
  "76502": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TEMPLE"
  },
  "76504": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TEMPLE"
  },
  "76511": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BARTLETT"
  },
  "76513": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BELTON"
  },
  "76518": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BUCKHOLTS"
  },
  "76519": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BURLINGTON"
  },
  "76520": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CAMERON"
  },
  "76522": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COPPERAS COVE"
  },
  "76523": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DAVILLA"
  },
  "76524": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EDDY"
  },
  "76525": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EVANT"
  },
  "76527": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FLORENCE"
  },
  "76528": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GATESVILLE"
  },
  "76530": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GRANGER"
  },
  "76531": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HAMILTON"
  },
  "76534": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOLLAND"
  },
  "76537": {
    stateCode: "TX",
    stateName: "Texas",
    city: "JARRELL"
  },
  "76538": {
    stateCode: "TX",
    stateName: "Texas",
    city: "JONESBORO"
  },
  "76539": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KEMPNER"
  },
  "76541": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KILLEEN"
  },
  "76542": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KILLEEN"
  },
  "76543": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KILLEEN"
  },
  "76544": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KILLEEN"
  },
  "76548": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HARKER HEIGHTS"
  },
  "76549": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KILLEEN"
  },
  "76550": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LAMPASAS"
  },
  "76554": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LITTLE RIVER ACADEMY"
  },
  "76556": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MILANO"
  },
  "76557": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MOODY"
  },
  "76559": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NOLANVILLE"
  },
  "76561": {
    stateCode: "TX",
    stateName: "Texas",
    city: "OGLESBY"
  },
  "76565": {
    stateCode: "TX",
    stateName: "Texas",
    city: "POTTSVILLE"
  },
  "76566": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PURMELA"
  },
  "76567": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROCKDALE"
  },
  "76569": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROGERS"
  },
  "76570": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROSEBUD"
  },
  "76571": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SALADO"
  },
  "76574": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TAYLOR"
  },
  "76577": {
    stateCode: "TX",
    stateName: "Texas",
    city: "THORNDALE"
  },
  "76578": {
    stateCode: "TX",
    stateName: "Texas",
    city: "THRALL"
  },
  "76579": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TROY"
  },
  "76621": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ABBOTT"
  },
  "76622": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AQUILLA"
  },
  "76624": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AXTELL"
  },
  "76626": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BLOOMING GROVE"
  },
  "76627": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BLUM"
  },
  "76628": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRANDON"
  },
  "76629": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BREMOND"
  },
  "76630": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRUCEVILLE"
  },
  "76631": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BYNUM"
  },
  "76632": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CHILTON"
  },
  "76633": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CHINA SPRING"
  },
  "76634": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CLIFTON"
  },
  "76635": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COOLIDGE"
  },
  "76636": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COVINGTON"
  },
  "76637": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CRANFILLS GAP"
  },
  "76638": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CRAWFORD"
  },
  "76639": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DAWSON"
  },
  "76640": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ELM MOTT"
  },
  "76641": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FROST"
  },
  "76642": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GROESBECK"
  },
  "76643": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HEWITT"
  },
  "76645": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HILLSBORO"
  },
  "76648": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HUBBARD"
  },
  "76649": {
    stateCode: "TX",
    stateName: "Texas",
    city: "IREDELL"
  },
  "76651": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ITALY"
  },
  "76652": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KOPPERL"
  },
  "76653": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KOSSE"
  },
  "76655": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LORENA"
  },
  "76656": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LOTT"
  },
  "76657": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MC GREGOR"
  },
  "76660": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MALONE"
  },
  "76661": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MARLIN"
  },
  "76664": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MART"
  },
  "76665": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MERIDIAN"
  },
  "76666": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MERTENS"
  },
  "76667": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MEXIA"
  },
  "76670": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MILFORD"
  },
  "76671": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MORGAN"
  },
  "76673": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MOUNT CALM"
  },
  "76676": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PENELOPE"
  },
  "76678": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PRAIRIE HILL"
  },
  "76679": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PURDON"
  },
  "76680": {
    stateCode: "TX",
    stateName: "Texas",
    city: "REAGAN"
  },
  "76681": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RICHLAND"
  },
  "76682": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RIESEL"
  },
  "76685": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SATIN"
  },
  "76686": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TEHUACANA"
  },
  "76687": {
    stateCode: "TX",
    stateName: "Texas",
    city: "THORNTON"
  },
  "76689": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VALLEY MILLS"
  },
  "76690": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WALNUT SPRINGS"
  },
  "76691": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WEST"
  },
  "76692": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WHITNEY"
  },
  "76693": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WORTHAM"
  },
  "76701": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WACO"
  },
  "76704": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WACO"
  },
  "76705": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WACO"
  },
  "76706": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WACO"
  },
  "76707": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WACO"
  },
  "76708": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WACO"
  },
  "76710": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WACO"
  },
  "76711": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WACO"
  },
  "76712": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WOODWAY"
  },
  "76801": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BROWNWOOD"
  },
  "76802": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EARLY"
  },
  "76821": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BALLINGER"
  },
  "76823": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BANGS"
  },
  "76824": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BEND"
  },
  "76825": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRADY"
  },
  "76827": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BROOKESMITH"
  },
  "76828": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BURKETT"
  },
  "76831": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CASTELL"
  },
  "76832": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CHEROKEE"
  },
  "76834": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COLEMAN"
  },
  "76837": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EDEN"
  },
  "76841": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT MC KAVETT"
  },
  "76842": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FREDONIA"
  },
  "76844": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GOLDTHWAITE"
  },
  "76845": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GOULDBUSK"
  },
  "76848": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HEXT"
  },
  "76849": {
    stateCode: "TX",
    stateName: "Texas",
    city: "JUNCTION"
  },
  "76852": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LOHN"
  },
  "76853": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LOMETA"
  },
  "76854": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LONDON"
  },
  "76856": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MASON"
  },
  "76857": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MAY"
  },
  "76858": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MELVIN"
  },
  "76859": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MENARD"
  },
  "76861": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MILES"
  },
  "76862": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MILLERSVIEW"
  },
  "76864": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MULLIN"
  },
  "76865": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NORTON"
  },
  "76866": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PAINT ROCK"
  },
  "76869": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PONTOTOC"
  },
  "76870": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PRIDDY"
  },
  "76871": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RICHLAND SPRINGS"
  },
  "76872": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROCHELLE"
  },
  "76873": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROCKWOOD"
  },
  "76874": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROOSEVELT"
  },
  "76875": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROWENA"
  },
  "76877": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN SABA"
  },
  "76878": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SANTA ANNA"
  },
  "76882": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TALPA"
  },
  "76884": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VALERA"
  },
  "76885": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VALLEY SPRING"
  },
  "76887": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VOCA"
  },
  "76888": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VOSS"
  },
  "76890": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ZEPHYR"
  },
  "76901": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANGELO"
  },
  "76903": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANGELO"
  },
  "76904": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANGELO"
  },
  "76905": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANGELO"
  },
  "76930": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BARNHART"
  },
  "76932": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BIG LAKE"
  },
  "76933": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRONTE"
  },
  "76934": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CARLSBAD"
  },
  "76935": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CHRISTOVAL"
  },
  "76936": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ELDORADO"
  },
  "76937": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EOLA"
  },
  "76940": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MERETA"
  },
  "76941": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MERTZON"
  },
  "76943": {
    stateCode: "TX",
    stateName: "Texas",
    city: "OZONA"
  },
  "76945": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROBERT LEE"
  },
  "76949": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SILVER"
  },
  "76950": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SONORA"
  },
  "76951": {
    stateCode: "TX",
    stateName: "Texas",
    city: "STERLING CITY"
  },
  "76953": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TENNYSON"
  },
  "76955": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VANCOURT"
  },
  "76958": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WATER VALLEY"
  },
  "77002": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77003": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77004": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77005": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77006": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77007": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77008": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77009": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77010": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77011": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77012": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77013": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77014": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77015": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77016": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77017": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77018": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77019": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77020": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77021": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77022": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77023": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77024": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77025": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77026": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77027": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77028": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77029": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77030": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77031": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77032": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77033": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77034": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77035": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77036": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77037": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77038": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77039": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77040": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77041": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77042": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77043": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77044": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77045": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77046": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77047": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77048": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77049": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77050": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77051": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77053": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77054": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77055": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77056": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77057": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77058": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77059": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77060": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77061": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77062": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77063": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77064": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77065": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77066": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77067": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77068": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77069": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77070": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77071": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77072": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77073": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77074": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77075": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77076": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77077": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77078": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77079": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77080": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77081": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77082": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77083": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77084": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77085": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77086": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77087": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77088": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77089": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77090": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77091": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77092": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77093": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77094": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77095": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77096": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77098": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77099": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOUSTON"
  },
  "77301": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CONROE"
  },
  "77302": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CONROE"
  },
  "77303": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CONROE"
  },
  "77304": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CONROE"
  },
  "77306": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CONROE"
  },
  "77316": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MONTGOMERY"
  },
  "77318": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WILLIS"
  },
  "77320": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HUNTSVILLE"
  },
  "77326": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ACE"
  },
  "77327": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CLEVELAND"
  },
  "77331": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COLDSPRING"
  },
  "77333": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DOBBIN"
  },
  "77334": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DODGE"
  },
  "77335": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GOODRICH"
  },
  "77336": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HUFFMAN"
  },
  "77338": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HUMBLE"
  },
  "77339": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KINGWOOD"
  },
  "77340": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HUNTSVILLE"
  },
  "77345": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KINGWOOD"
  },
  "77346": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HUMBLE"
  },
  "77351": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LIVINGSTON"
  },
  "77354": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MAGNOLIA"
  },
  "77355": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MAGNOLIA"
  },
  "77356": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MONTGOMERY"
  },
  "77357": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NEW CANEY"
  },
  "77358": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NEW WAVERLY"
  },
  "77359": {
    stateCode: "TX",
    stateName: "Texas",
    city: "OAKHURST"
  },
  "77360": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ONALASKA"
  },
  "77362": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PINEHURST"
  },
  "77363": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PLANTERSVILLE"
  },
  "77364": {
    stateCode: "TX",
    stateName: "Texas",
    city: "POINTBLANK"
  },
  "77365": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PORTER"
  },
  "77367": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RIVERSIDE"
  },
  "77368": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROMAYOR"
  },
  "77369": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RYE"
  },
  "77371": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SHEPHERD"
  },
  "77372": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SPLENDORA"
  },
  "77373": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SPRING"
  },
  "77374": {
    stateCode: "TX",
    stateName: "Texas",
    city: "THICKET"
  },
  "77375": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TOMBALL"
  },
  "77376": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VOTAW"
  },
  "77378": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WILLIS"
  },
  "77379": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SPRING"
  },
  "77380": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SPRING"
  },
  "77381": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SPRING"
  },
  "77382": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SPRING"
  },
  "77384": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CONROE"
  },
  "77385": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CONROE"
  },
  "77386": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SPRING"
  },
  "77388": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SPRING"
  },
  "77389": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SPRING"
  },
  "77396": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HUMBLE"
  },
  "77401": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BELLAIRE"
  },
  "77412": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ALTAIR"
  },
  "77414": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BAY CITY"
  },
  "77415": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CEDAR LANE"
  },
  "77417": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BEASLEY"
  },
  "77418": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BELLVILLE"
  },
  "77419": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BLESSING"
  },
  "77420": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BOLING"
  },
  "77422": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRAZORIA"
  },
  "77423": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BROOKSHIRE"
  },
  "77426": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CHAPPELL HILL"
  },
  "77428": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COLLEGEPORT"
  },
  "77429": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CYPRESS"
  },
  "77430": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DAMON"
  },
  "77432": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DANEVANG"
  },
  "77433": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CYPRESS"
  },
  "77434": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EAGLE LAKE"
  },
  "77435": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EAST BERNARD"
  },
  "77437": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL CAMPO"
  },
  "77440": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ELMATON"
  },
  "77441": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FULSHEAR"
  },
  "77442": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GARWOOD"
  },
  "77443": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GLEN FLORA"
  },
  "77444": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GUY"
  },
  "77445": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HEMPSTEAD"
  },
  "77446": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PRAIRIE VIEW"
  },
  "77447": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOCKLEY"
  },
  "77448": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HUNGERFORD"
  },
  "77449": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KATY"
  },
  "77450": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KATY"
  },
  "77451": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KENDLETON"
  },
  "77454": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LISSIE"
  },
  "77455": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LOUISE"
  },
  "77456": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MARKHAM"
  },
  "77457": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MATAGORDA"
  },
  "77458": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MIDFIELD"
  },
  "77459": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MISSOURI CITY"
  },
  "77461": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NEEDVILLE"
  },
  "77463": {
    stateCode: "TX",
    stateName: "Texas",
    city: "OLD OCEAN"
  },
  "77464": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ORCHARD"
  },
  "77465": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PALACIOS"
  },
  "77466": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PATTISON"
  },
  "77467": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PIERCE"
  },
  "77468": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PLEDGER"
  },
  "77469": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RICHMOND"
  },
  "77470": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROCK ISLAND"
  },
  "77471": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROSENBERG"
  },
  "77473": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN FELIPE"
  },
  "77474": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SEALY"
  },
  "77475": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SHERIDAN"
  },
  "77476": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SIMONTON"
  },
  "77477": {
    stateCode: "TX",
    stateName: "Texas",
    city: "STAFFORD"
  },
  "77478": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SUGAR LAND"
  },
  "77479": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SUGAR LAND"
  },
  "77480": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SWEENY"
  },
  "77481": {
    stateCode: "TX",
    stateName: "Texas",
    city: "THOMPSONS"
  },
  "77482": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VAN VLECK"
  },
  "77483": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WADSWORTH"
  },
  "77484": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WALLER"
  },
  "77485": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WALLIS"
  },
  "77486": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WEST COLUMBIA"
  },
  "77488": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WHARTON"
  },
  "77489": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MISSOURI CITY"
  },
  "77493": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KATY"
  },
  "77494": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KATY"
  },
  "77502": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PASADENA"
  },
  "77503": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PASADENA"
  },
  "77504": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PASADENA"
  },
  "77505": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PASADENA"
  },
  "77506": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PASADENA"
  },
  "77510": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SANTA FE"
  },
  "77511": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ALVIN"
  },
  "77514": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ANAHUAC"
  },
  "77515": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ANGLETON"
  },
  "77517": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SANTA FE"
  },
  "77518": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BACLIFF"
  },
  "77519": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BATSON"
  },
  "77520": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BAYTOWN"
  },
  "77521": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BAYTOWN"
  },
  "77530": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CHANNELVIEW"
  },
  "77531": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CLUTE"
  },
  "77532": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CROSBY"
  },
  "77533": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DAISETTA"
  },
  "77534": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DANBURY"
  },
  "77535": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DAYTON"
  },
  "77536": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DEER PARK"
  },
  "77538": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DEVERS"
  },
  "77539": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DICKINSON"
  },
  "77541": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FREEPORT"
  },
  "77545": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FRESNO"
  },
  "77546": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FRIENDSWOOD"
  },
  "77547": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GALENA PARK"
  },
  "77550": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GALVESTON"
  },
  "77551": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GALVESTON"
  },
  "77554": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GALVESTON"
  },
  "77560": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HANKAMER"
  },
  "77561": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HARDIN"
  },
  "77562": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HIGHLANDS"
  },
  "77563": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HITCHCOCK"
  },
  "77564": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HULL"
  },
  "77565": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KEMAH"
  },
  "77566": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LAKE JACKSON"
  },
  "77568": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LA MARQUE"
  },
  "77571": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LA PORTE"
  },
  "77573": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LEAGUE CITY"
  },
  "77575": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LIBERTY"
  },
  "77577": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LIVERPOOL"
  },
  "77578": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MANVEL"
  },
  "77581": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PEARLAND"
  },
  "77582": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RAYWOOD"
  },
  "77583": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROSHARON"
  },
  "77584": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PEARLAND"
  },
  "77585": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SARATOGA"
  },
  "77586": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SEABROOK"
  },
  "77587": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SOUTH HOUSTON"
  },
  "77590": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TEXAS CITY"
  },
  "77591": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TEXAS CITY"
  },
  "77597": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WALLISVILLE"
  },
  "77598": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WEBSTER"
  },
  "77611": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRIDGE CITY"
  },
  "77612": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BUNA"
  },
  "77613": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CHINA"
  },
  "77614": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DEWEYVILLE"
  },
  "77615": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EVADALE"
  },
  "77616": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FRED"
  },
  "77617": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GILCHRIST"
  },
  "77619": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GROVES"
  },
  "77622": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HAMSHIRE"
  },
  "77623": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HIGH ISLAND"
  },
  "77624": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HILLISTER"
  },
  "77625": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KOUNTZE"
  },
  "77627": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NEDERLAND"
  },
  "77629": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NOME"
  },
  "77630": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ORANGE"
  },
  "77632": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ORANGE"
  },
  "77640": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PORT ARTHUR"
  },
  "77642": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PORT ARTHUR"
  },
  "77650": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PORT BOLIVAR"
  },
  "77651": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PORT NECHES"
  },
  "77655": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SABINE PASS"
  },
  "77656": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SILSBEE"
  },
  "77657": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUMBERTON"
  },
  "77659": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SOUR LAKE"
  },
  "77660": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SPURGER"
  },
  "77662": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VIDOR"
  },
  "77663": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VILLAGE MILLS"
  },
  "77664": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WARREN"
  },
  "77665": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WINNIE"
  },
  "77701": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BEAUMONT"
  },
  "77702": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BEAUMONT"
  },
  "77703": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BEAUMONT"
  },
  "77705": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BEAUMONT"
  },
  "77706": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BEAUMONT"
  },
  "77707": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BEAUMONT"
  },
  "77708": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BEAUMONT"
  },
  "77713": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BEAUMONT"
  },
  "77801": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRYAN"
  },
  "77802": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRYAN"
  },
  "77803": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRYAN"
  },
  "77807": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRYAN"
  },
  "77808": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRYAN"
  },
  "77830": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ANDERSON"
  },
  "77831": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BEDIAS"
  },
  "77833": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRENHAM"
  },
  "77835": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BURTON"
  },
  "77836": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CALDWELL"
  },
  "77837": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CALVERT"
  },
  "77840": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COLLEGE STATION"
  },
  "77845": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COLLEGE STATION"
  },
  "77853": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DIME BOX"
  },
  "77855": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FLYNN"
  },
  "77856": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FRANKLIN"
  },
  "77857": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GAUSE"
  },
  "77859": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HEARNE"
  },
  "77861": {
    stateCode: "TX",
    stateName: "Texas",
    city: "IOLA"
  },
  "77864": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MADISONVILLE"
  },
  "77865": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MARQUEZ"
  },
  "77866": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MILLICAN"
  },
  "77867": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MUMFORD"
  },
  "77868": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NAVASOTA"
  },
  "77870": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NEW BADEN"
  },
  "77871": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NORMANGEE"
  },
  "77872": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NORTH ZULCH"
  },
  "77873": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RICHARDS"
  },
  "77876": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SHIRO"
  },
  "77878": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SNOOK"
  },
  "77879": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SOMERVILLE"
  },
  "77880": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WASHINGTON"
  },
  "77882": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WHEELOCK"
  },
  "77901": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VICTORIA"
  },
  "77904": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VICTORIA"
  },
  "77905": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VICTORIA"
  },
  "77950": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTWELL"
  },
  "77951": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BLOOMINGTON"
  },
  "77954": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CUERO"
  },
  "77957": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EDNA"
  },
  "77960": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FANNIN"
  },
  "77961": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FRANCITAS"
  },
  "77962": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GANADO"
  },
  "77963": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GOLIAD"
  },
  "77964": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HALLETTSVILLE"
  },
  "77968": {
    stateCode: "TX",
    stateName: "Texas",
    city: "INEZ"
  },
  "77969": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LA SALLE"
  },
  "77970": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LA WARD"
  },
  "77971": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LOLITA"
  },
  "77973": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MCFADDIN"
  },
  "77974": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MEYERSVILLE"
  },
  "77975": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MOULTON"
  },
  "77977": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PLACEDO"
  },
  "77978": {
    stateCode: "TX",
    stateName: "Texas",
    city: "POINT COMFORT"
  },
  "77979": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PORT LAVACA"
  },
  "77982": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PORT O CONNOR"
  },
  "77983": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SEADRIFT"
  },
  "77984": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SHINER"
  },
  "77988": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TELFERNER"
  },
  "77990": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TIVOLI"
  },
  "77991": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VANDERBILT"
  },
  "77994": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WESTHOFF"
  },
  "77995": {
    stateCode: "TX",
    stateName: "Texas",
    city: "YOAKUM"
  },
  "78001": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARTESIA WELLS"
  },
  "78002": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ATASCOSA"
  },
  "78003": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BANDERA"
  },
  "78004": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BERGHEIM"
  },
  "78005": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BIGFOOT"
  },
  "78006": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BOERNE"
  },
  "78007": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CALLIHAM"
  },
  "78008": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CAMPBELLTON"
  },
  "78009": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CASTROVILLE"
  },
  "78010": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CENTER POINT"
  },
  "78011": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CHARLOTTE"
  },
  "78012": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CHRISTINE"
  },
  "78013": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COMFORT"
  },
  "78014": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COTULLA"
  },
  "78015": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BOERNE"
  },
  "78016": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DEVINE"
  },
  "78017": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DILLEY"
  },
  "78019": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ENCINAL"
  },
  "78021": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FOWLERTON"
  },
  "78022": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GEORGE WEST"
  },
  "78023": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HELOTES"
  },
  "78024": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HUNT"
  },
  "78025": {
    stateCode: "TX",
    stateName: "Texas",
    city: "INGRAM"
  },
  "78026": {
    stateCode: "TX",
    stateName: "Texas",
    city: "JOURDANTON"
  },
  "78027": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KENDALIA"
  },
  "78028": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KERRVILLE"
  },
  "78039": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LA COSTE"
  },
  "78040": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LAREDO"
  },
  "78041": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LAREDO"
  },
  "78043": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LAREDO"
  },
  "78045": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LAREDO"
  },
  "78046": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LAREDO"
  },
  "78050": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LEMING"
  },
  "78052": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LYTLE"
  },
  "78055": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MEDINA"
  },
  "78056": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MICO"
  },
  "78057": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MOORE"
  },
  "78058": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MOUNTAIN HOME"
  },
  "78059": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NATALIA"
  },
  "78061": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PEARSALL"
  },
  "78063": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PIPE CREEK"
  },
  "78064": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PLEASANTON"
  },
  "78065": {
    stateCode: "TX",
    stateName: "Texas",
    city: "POTEET"
  },
  "78066": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RIO MEDINA"
  },
  "78069": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SOMERSET"
  },
  "78070": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SPRING BRANCH"
  },
  "78071": {
    stateCode: "TX",
    stateName: "Texas",
    city: "THREE RIVERS"
  },
  "78072": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TILDEN"
  },
  "78073": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VON ORMY"
  },
  "78074": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WARING"
  },
  "78075": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WHITSETT"
  },
  "78076": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ZAPATA"
  },
  "78101": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ADKINS"
  },
  "78102": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BEEVILLE"
  },
  "78107": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BERCLAIR"
  },
  "78108": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CIBOLO"
  },
  "78109": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CONVERSE"
  },
  "78112": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ELMENDORF"
  },
  "78113": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FALLS CITY"
  },
  "78114": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FLORESVILLE"
  },
  "78116": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GILLETT"
  },
  "78117": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HOBSON"
  },
  "78118": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KARNES CITY"
  },
  "78119": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KENEDY"
  },
  "78121": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LA VERNIA"
  },
  "78122": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LEESVILLE"
  },
  "78123": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MC QUEENEY"
  },
  "78124": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MARION"
  },
  "78125": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MINERAL"
  },
  "78130": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NEW BRAUNFELS"
  },
  "78132": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NEW BRAUNFELS"
  },
  "78133": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CANYON LAKE"
  },
  "78140": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NIXON"
  },
  "78141": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NORDHEIM"
  },
  "78142": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NORMANNA"
  },
  "78143": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PANDORA"
  },
  "78144": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PANNA MARIA"
  },
  "78145": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PAWNEE"
  },
  "78146": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PETTUS"
  },
  "78147": {
    stateCode: "TX",
    stateName: "Texas",
    city: "POTH"
  },
  "78148": {
    stateCode: "TX",
    stateName: "Texas",
    city: "UNIVERSAL CITY"
  },
  "78151": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RUNGE"
  },
  "78152": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAINT HEDWIG"
  },
  "78154": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SCHERTZ"
  },
  "78155": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SEGUIN"
  },
  "78159": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SMILEY"
  },
  "78160": {
    stateCode: "TX",
    stateName: "Texas",
    city: "STOCKDALE"
  },
  "78161": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SUTHERLAND SPRINGS"
  },
  "78163": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BULVERDE"
  },
  "78164": {
    stateCode: "TX",
    stateName: "Texas",
    city: "YORKTOWN"
  },
  "78201": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78202": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78203": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78204": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78205": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78207": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78208": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78209": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78210": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78211": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78212": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78213": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78214": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78215": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78216": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78217": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78218": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78219": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78220": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78221": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78222": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78223": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78224": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78225": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78226": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78227": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78228": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78229": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78230": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78231": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78232": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78233": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78234": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78235": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78236": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78237": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78238": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78239": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78240": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78242": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78244": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78245": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78247": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78248": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78249": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78250": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78251": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78252": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78253": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78254": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78255": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78256": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78257": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78258": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78259": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78260": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78261": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78263": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78264": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78266": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ANTONIO"
  },
  "78330": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AGUA DULCE"
  },
  "78332": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ALICE"
  },
  "78336": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARANSAS PASS"
  },
  "78338": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ARMSTRONG"
  },
  "78339": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BANQUETE"
  },
  "78340": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BAYSIDE"
  },
  "78343": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BISHOP"
  },
  "78344": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRUNI"
  },
  "78347": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CHAPMAN RANCH"
  },
  "78349": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CONCEPCION"
  },
  "78351": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DRISCOLL"
  },
  "78352": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EDROY"
  },
  "78353": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ENCINO"
  },
  "78355": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FALFURRIAS"
  },
  "78357": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FREER"
  },
  "78358": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FULTON"
  },
  "78359": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GREGORY"
  },
  "78361": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HEBBRONVILLE"
  },
  "78362": {
    stateCode: "TX",
    stateName: "Texas",
    city: "INGLESIDE"
  },
  "78363": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KINGSVILLE"
  },
  "78364": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KINGSVILLE"
  },
  "78368": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MATHIS"
  },
  "78369": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MIRANDO CITY"
  },
  "78370": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ODEM"
  },
  "78371": {
    stateCode: "TX",
    stateName: "Texas",
    city: "OILTON"
  },
  "78372": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ORANGE GROVE"
  },
  "78373": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PORT ARANSAS"
  },
  "78374": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PORTLAND"
  },
  "78375": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PREMONT"
  },
  "78376": {
    stateCode: "TX",
    stateName: "Texas",
    city: "REALITOS"
  },
  "78377": {
    stateCode: "TX",
    stateName: "Texas",
    city: "REFUGIO"
  },
  "78379": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RIVIERA"
  },
  "78380": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROBSTOWN"
  },
  "78382": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROCKPORT"
  },
  "78383": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SANDIA"
  },
  "78384": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN DIEGO"
  },
  "78385": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SARITA"
  },
  "78387": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SINTON"
  },
  "78389": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SKIDMORE"
  },
  "78390": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TAFT"
  },
  "78391": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TYNAN"
  },
  "78393": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WOODSBORO"
  },
  "78401": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORPUS CHRISTI"
  },
  "78402": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORPUS CHRISTI"
  },
  "78404": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORPUS CHRISTI"
  },
  "78405": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORPUS CHRISTI"
  },
  "78406": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORPUS CHRISTI"
  },
  "78407": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORPUS CHRISTI"
  },
  "78408": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORPUS CHRISTI"
  },
  "78409": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORPUS CHRISTI"
  },
  "78410": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORPUS CHRISTI"
  },
  "78411": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORPUS CHRISTI"
  },
  "78412": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORPUS CHRISTI"
  },
  "78413": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORPUS CHRISTI"
  },
  "78414": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORPUS CHRISTI"
  },
  "78415": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORPUS CHRISTI"
  },
  "78416": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORPUS CHRISTI"
  },
  "78417": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORPUS CHRISTI"
  },
  "78418": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CORPUS CHRISTI"
  },
  "78501": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MCALLEN"
  },
  "78503": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MCALLEN"
  },
  "78504": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MCALLEN"
  },
  "78516": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ALAMO"
  },
  "78520": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BROWNSVILLE"
  },
  "78521": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BROWNSVILLE"
  },
  "78526": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BROWNSVILLE"
  },
  "78537": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DONNA"
  },
  "78538": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EDCOUCH"
  },
  "78539": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EDINBURG"
  },
  "78543": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ELSA"
  },
  "78545": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FALCON HEIGHTS"
  },
  "78547": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GARCIASVILLE"
  },
  "78548": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GRULLA"
  },
  "78549": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HARGILL"
  },
  "78550": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HARLINGEN"
  },
  "78552": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HARLINGEN"
  },
  "78557": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HIDALGO"
  },
  "78558": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LA BLANCA"
  },
  "78559": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LA FERIA"
  },
  "78560": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LA JOYA"
  },
  "78562": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LA VILLA"
  },
  "78563": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LINN"
  },
  "78565": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LOS EBANOS"
  },
  "78566": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LOS FRESNOS"
  },
  "78569": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LYFORD"
  },
  "78570": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MERCEDES"
  },
  "78572": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MISSION"
  },
  "78575": {
    stateCode: "TX",
    stateName: "Texas",
    city: "OLMITO"
  },
  "78576": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PENITAS"
  },
  "78577": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PHARR"
  },
  "78578": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PORT ISABEL"
  },
  "78579": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PROGRESO"
  },
  "78580": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RAYMONDVILLE"
  },
  "78582": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RIO GRANDE CITY"
  },
  "78583": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RIO HONDO"
  },
  "78584": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROMA"
  },
  "78586": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN BENITO"
  },
  "78588": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ISIDRO"
  },
  "78589": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN JUAN"
  },
  "78592": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SANTA MARIA"
  },
  "78593": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SANTA ROSA"
  },
  "78594": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SEBASTIAN"
  },
  "78595": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SULLIVAN CITY"
  },
  "78596": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WESLACO"
  },
  "78597": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SOUTH PADRE ISLAND"
  },
  "78598": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PORT MANSFIELD"
  },
  "78602": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BASTROP"
  },
  "78605": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BERTRAM"
  },
  "78606": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BLANCO"
  },
  "78607": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BLUFFTON"
  },
  "78608": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRIGGS"
  },
  "78609": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BUCHANAN DAM"
  },
  "78610": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BUDA"
  },
  "78611": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BURNET"
  },
  "78612": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CEDAR CREEK"
  },
  "78613": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CEDAR PARK"
  },
  "78614": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COST"
  },
  "78615": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COUPLAND"
  },
  "78616": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALE"
  },
  "78617": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DEL VALLE"
  },
  "78618": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DOSS"
  },
  "78619": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DRIFTWOOD"
  },
  "78620": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DRIPPING SPRINGS"
  },
  "78621": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ELGIN"
  },
  "78622": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FENTRESS"
  },
  "78623": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FISCHER"
  },
  "78624": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FREDERICKSBURG"
  },
  "78626": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GEORGETOWN"
  },
  "78628": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GEORGETOWN"
  },
  "78629": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GONZALES"
  },
  "78631": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HARPER"
  },
  "78632": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HARWOOD"
  },
  "78634": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HUTTO"
  },
  "78635": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HYE"
  },
  "78636": {
    stateCode: "TX",
    stateName: "Texas",
    city: "JOHNSON CITY"
  },
  "78638": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KINGSBURY"
  },
  "78639": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KINGSLAND"
  },
  "78640": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KYLE"
  },
  "78641": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LEANDER"
  },
  "78642": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LIBERTY HILL"
  },
  "78643": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LLANO"
  },
  "78644": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LOCKHART"
  },
  "78645": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LEANDER"
  },
  "78648": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LULING"
  },
  "78650": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MC DADE"
  },
  "78652": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MANCHACA"
  },
  "78653": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MANOR"
  },
  "78654": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MARBLE FALLS"
  },
  "78655": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MARTINDALE"
  },
  "78656": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MAXWELL"
  },
  "78657": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HORSESHOE BAY"
  },
  "78658": {
    stateCode: "TX",
    stateName: "Texas",
    city: "OTTINE"
  },
  "78659": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PAIGE"
  },
  "78660": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PFLUGERVILLE"
  },
  "78661": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PRAIRIE LEA"
  },
  "78662": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RED ROCK"
  },
  "78663": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROUND MOUNTAIN"
  },
  "78664": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROUND ROCK"
  },
  "78666": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN MARCOS"
  },
  "78669": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SPICEWOOD"
  },
  "78670": {
    stateCode: "TX",
    stateName: "Texas",
    city: "STAPLES"
  },
  "78671": {
    stateCode: "TX",
    stateName: "Texas",
    city: "STONEWALL"
  },
  "78672": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TOW"
  },
  "78675": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WILLOW CITY"
  },
  "78676": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WIMBERLEY"
  },
  "78681": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROUND ROCK"
  },
  "78701": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78702": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78703": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78704": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78705": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78717": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78719": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78721": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78722": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78723": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78724": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78725": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78726": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78727": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78728": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78729": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78730": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78731": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78732": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78733": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78734": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78735": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78736": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78737": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78738": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78739": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78741": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78742": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78744": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78745": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78746": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78747": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78748": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78749": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78750": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78751": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78752": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78753": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78754": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78756": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78757": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78758": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78759": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AUSTIN"
  },
  "78801": {
    stateCode: "TX",
    stateName: "Texas",
    city: "UVALDE"
  },
  "78827": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ASHERTON"
  },
  "78828": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BARKSDALE"
  },
  "78829": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BATESVILLE"
  },
  "78830": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BIG WELLS"
  },
  "78832": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRACKETTVILLE"
  },
  "78833": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CAMP WOOD"
  },
  "78834": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CARRIZO SPRINGS"
  },
  "78836": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CATARINA"
  },
  "78837": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COMSTOCK"
  },
  "78838": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CONCAN"
  },
  "78839": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CRYSTAL CITY"
  },
  "78840": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DEL RIO"
  },
  "78850": {
    stateCode: "TX",
    stateName: "Texas",
    city: "D HANIS"
  },
  "78851": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DRYDEN"
  },
  "78852": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EAGLE PASS"
  },
  "78860": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL INDIO"
  },
  "78861": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HONDO"
  },
  "78870": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KNIPPA"
  },
  "78872": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LA PRYOR"
  },
  "78873": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LEAKEY"
  },
  "78877": {
    stateCode: "TX",
    stateName: "Texas",
    city: "QUEMADO"
  },
  "78879": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RIO FRIO"
  },
  "78880": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROCKSPRINGS"
  },
  "78881": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SABINAL"
  },
  "78883": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TARPLEY"
  },
  "78884": {
    stateCode: "TX",
    stateName: "Texas",
    city: "UTOPIA"
  },
  "78885": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VANDERPOOL"
  },
  "78886": {
    stateCode: "TX",
    stateName: "Texas",
    city: "YANCEY"
  },
  "78932": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CARMINE"
  },
  "78933": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CAT SPRING"
  },
  "78934": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COLUMBUS"
  },
  "78935": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ALLEYTON"
  },
  "78938": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ELLINGER"
  },
  "78940": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FAYETTEVILLE"
  },
  "78941": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FLATONIA"
  },
  "78942": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GIDDINGS"
  },
  "78943": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GLIDDEN"
  },
  "78944": {
    stateCode: "TX",
    stateName: "Texas",
    city: "INDUSTRY"
  },
  "78945": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LA GRANGE"
  },
  "78946": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LEDBETTER"
  },
  "78947": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LEXINGTON"
  },
  "78948": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LINCOLN"
  },
  "78949": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MULDOON"
  },
  "78950": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NEW ULM"
  },
  "78953": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROSANKY"
  },
  "78954": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROUND TOP"
  },
  "78956": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SCHULENBURG"
  },
  "78957": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SMITHVILLE"
  },
  "78959": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WAELDER"
  },
  "78960": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WARDA"
  },
  "78962": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WEIMAR"
  },
  "78963": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WEST POINT"
  },
  "79001": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ADRIAN"
  },
  "79002": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ALANREED"
  },
  "79003": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ALLISON"
  },
  "79005": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BOOKER"
  },
  "79007": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BORGER"
  },
  "79009": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BOVINA"
  },
  "79010": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BOYS RANCH"
  },
  "79011": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BRISCOE"
  },
  "79012": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BUSHLAND"
  },
  "79013": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CACTUS"
  },
  "79014": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CANADIAN"
  },
  "79015": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CANYON"
  },
  "79018": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CHANNING"
  },
  "79019": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CLAUDE"
  },
  "79021": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COTTON CENTER"
  },
  "79022": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DALHART"
  },
  "79024": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DARROUZETT"
  },
  "79025": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DAWN"
  },
  "79027": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DIMMITT"
  },
  "79029": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DUMAS"
  },
  "79031": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EARTH"
  },
  "79032": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EDMONSON"
  },
  "79033": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FARNSWORTH"
  },
  "79034": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FOLLETT"
  },
  "79035": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FRIONA"
  },
  "79036": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FRITCH"
  },
  "79039": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GROOM"
  },
  "79040": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GRUVER"
  },
  "79041": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HALE CENTER"
  },
  "79042": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HAPPY"
  },
  "79043": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HART"
  },
  "79044": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HARTLEY"
  },
  "79045": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HEREFORD"
  },
  "79046": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HIGGINS"
  },
  "79051": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KERRICK"
  },
  "79052": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KRESS"
  },
  "79053": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LAZBUDDIE"
  },
  "79054": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LEFORS"
  },
  "79056": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LIPSCOMB"
  },
  "79057": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MCLEAN"
  },
  "79059": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MIAMI"
  },
  "79061": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MOBEETIE"
  },
  "79062": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MORSE"
  },
  "79063": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NAZARETH"
  },
  "79064": {
    stateCode: "TX",
    stateName: "Texas",
    city: "OLTON"
  },
  "79065": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PAMPA"
  },
  "79068": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PANHANDLE"
  },
  "79070": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PERRYTON"
  },
  "79072": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PLAINVIEW"
  },
  "79078": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SANFORD"
  },
  "79079": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SHAMROCK"
  },
  "79080": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SKELLYTOWN"
  },
  "79081": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SPEARMAN"
  },
  "79082": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SPRINGLAKE"
  },
  "79083": {
    stateCode: "TX",
    stateName: "Texas",
    city: "STINNETT"
  },
  "79084": {
    stateCode: "TX",
    stateName: "Texas",
    city: "STRATFORD"
  },
  "79086": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SUNRAY"
  },
  "79087": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TEXLINE"
  },
  "79088": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TULIA"
  },
  "79091": {
    stateCode: "TX",
    stateName: "Texas",
    city: "UMBARGER"
  },
  "79092": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VEGA"
  },
  "79093": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WAKA"
  },
  "79095": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WELLINGTON"
  },
  "79096": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WHEELER"
  },
  "79097": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WHITE DEER"
  },
  "79098": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WILDORADO"
  },
  "79101": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AMARILLO"
  },
  "79102": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AMARILLO"
  },
  "79103": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AMARILLO"
  },
  "79104": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AMARILLO"
  },
  "79106": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AMARILLO"
  },
  "79107": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AMARILLO"
  },
  "79108": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AMARILLO"
  },
  "79109": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AMARILLO"
  },
  "79110": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AMARILLO"
  },
  "79111": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AMARILLO"
  },
  "79118": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AMARILLO"
  },
  "79119": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AMARILLO"
  },
  "79121": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AMARILLO"
  },
  "79124": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AMARILLO"
  },
  "79201": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CHILDRESS"
  },
  "79220": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AFTON"
  },
  "79223": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CEE VEE"
  },
  "79225": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CHILLICOTHE"
  },
  "79226": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CLARENDON"
  },
  "79227": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CROWELL"
  },
  "79229": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DICKENS"
  },
  "79230": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DODSON"
  },
  "79231": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DOUGHERTY"
  },
  "79233": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ESTELLINE"
  },
  "79234": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FLOMOT"
  },
  "79235": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FLOYDADA"
  },
  "79236": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GUTHRIE"
  },
  "79237": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HEDLEY"
  },
  "79239": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LAKEVIEW"
  },
  "79240": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LELIA LAKE"
  },
  "79241": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LOCKNEY"
  },
  "79243": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MCADOO"
  },
  "79244": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MATADOR"
  },
  "79245": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MEMPHIS"
  },
  "79247": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ODELL"
  },
  "79248": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PADUCAH"
  },
  "79250": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PETERSBURG"
  },
  "79251": {
    stateCode: "TX",
    stateName: "Texas",
    city: "QUAIL"
  },
  "79252": {
    stateCode: "TX",
    stateName: "Texas",
    city: "QUANAH"
  },
  "79255": {
    stateCode: "TX",
    stateName: "Texas",
    city: "QUITAQUE"
  },
  "79256": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROARING SPRINGS"
  },
  "79257": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SILVERTON"
  },
  "79259": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TELL"
  },
  "79261": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TURKEY"
  },
  "79311": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ABERNATHY"
  },
  "79312": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AMHERST"
  },
  "79313": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ANTON"
  },
  "79314": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BLEDSOE"
  },
  "79316": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BROWNFIELD"
  },
  "79320": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BULA"
  },
  "79322": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CROSBYTON"
  },
  "79323": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DENVER CITY"
  },
  "79324": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ENOCHS"
  },
  "79325": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FARWELL"
  },
  "79326": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FIELDTON"
  },
  "79329": {
    stateCode: "TX",
    stateName: "Texas",
    city: "IDALOU"
  },
  "79330": {
    stateCode: "TX",
    stateName: "Texas",
    city: "JUSTICEBURG"
  },
  "79331": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LAMESA"
  },
  "79336": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LEVELLAND"
  },
  "79339": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LITTLEFIELD"
  },
  "79342": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LOOP"
  },
  "79343": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LORENZO"
  },
  "79344": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MAPLE"
  },
  "79345": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MEADOW"
  },
  "79346": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MORTON"
  },
  "79347": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MULESHOE"
  },
  "79350": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NEW DEAL"
  },
  "79351": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ODONNELL"
  },
  "79353": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PEP"
  },
  "79355": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PLAINS"
  },
  "79356": {
    stateCode: "TX",
    stateName: "Texas",
    city: "POST"
  },
  "79357": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RALLS"
  },
  "79358": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROPESVILLE"
  },
  "79359": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SEAGRAVES"
  },
  "79360": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SEMINOLE"
  },
  "79363": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SHALLOWATER"
  },
  "79364": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SLATON"
  },
  "79366": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RANSOM CANYON"
  },
  "79367": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SMYER"
  },
  "79369": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SPADE"
  },
  "79370": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SPUR"
  },
  "79371": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SUDAN"
  },
  "79372": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SUNDOWN"
  },
  "79373": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TAHOKA"
  },
  "79376": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TOKIO"
  },
  "79377": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WELCH"
  },
  "79378": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WELLMAN"
  },
  "79379": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WHITEFACE"
  },
  "79380": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WHITHARRAL"
  },
  "79381": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WILSON"
  },
  "79382": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WOLFFORTH"
  },
  "79383": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NEW HOME"
  },
  "79401": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUBBOCK"
  },
  "79403": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUBBOCK"
  },
  "79404": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUBBOCK"
  },
  "79405": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUBBOCK"
  },
  "79406": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUBBOCK"
  },
  "79407": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUBBOCK"
  },
  "79410": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUBBOCK"
  },
  "79411": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUBBOCK"
  },
  "79412": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUBBOCK"
  },
  "79413": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUBBOCK"
  },
  "79414": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUBBOCK"
  },
  "79415": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUBBOCK"
  },
  "79416": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUBBOCK"
  },
  "79423": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUBBOCK"
  },
  "79424": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUBBOCK"
  },
  "79501": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ANSON"
  },
  "79502": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ASPERMONT"
  },
  "79503": {
    stateCode: "TX",
    stateName: "Texas",
    city: "AVOCA"
  },
  "79504": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BAIRD"
  },
  "79505": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BENJAMIN"
  },
  "79506": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BLACKWELL"
  },
  "79508": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BUFFALO GAP"
  },
  "79510": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CLYDE"
  },
  "79511": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COAHOMA"
  },
  "79512": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COLORADO CITY"
  },
  "79517": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FLUVANNA"
  },
  "79518": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GIRARD"
  },
  "79519": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GOLDSBORO"
  },
  "79520": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HAMLIN"
  },
  "79521": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HASKELL"
  },
  "79525": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HAWLEY"
  },
  "79526": {
    stateCode: "TX",
    stateName: "Texas",
    city: "HERMLEIGH"
  },
  "79527": {
    stateCode: "TX",
    stateName: "Texas",
    city: "IRA"
  },
  "79528": {
    stateCode: "TX",
    stateName: "Texas",
    city: "JAYTON"
  },
  "79529": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KNOX CITY"
  },
  "79530": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LAWN"
  },
  "79532": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LORAINE"
  },
  "79533": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LUEDERS"
  },
  "79534": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MC CAULLEY"
  },
  "79535": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MARYNEAL"
  },
  "79536": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MERKEL"
  },
  "79537": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NOLAN"
  },
  "79538": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NOVICE"
  },
  "79539": {
    stateCode: "TX",
    stateName: "Texas",
    city: "O BRIEN"
  },
  "79540": {
    stateCode: "TX",
    stateName: "Texas",
    city: "OLD GLORY"
  },
  "79541": {
    stateCode: "TX",
    stateName: "Texas",
    city: "OVALO"
  },
  "79543": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROBY"
  },
  "79544": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROCHESTER"
  },
  "79545": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROSCOE"
  },
  "79546": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ROTAN"
  },
  "79547": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RULE"
  },
  "79548": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RULE"
  },
  "79549": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SNYDER"
  },
  "79553": {
    stateCode: "TX",
    stateName: "Texas",
    city: "STAMFORD"
  },
  "79556": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SWEETWATER"
  },
  "79560": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SYLVESTER"
  },
  "79561": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TRENT"
  },
  "79562": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TUSCOLA"
  },
  "79563": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TYE"
  },
  "79565": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WESTBROOK"
  },
  "79566": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WINGATE"
  },
  "79567": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WINTERS"
  },
  "79601": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ABILENE"
  },
  "79602": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ABILENE"
  },
  "79603": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ABILENE"
  },
  "79605": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ABILENE"
  },
  "79606": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ABILENE"
  },
  "79607": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DYESS AFB"
  },
  "79701": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MIDLAND"
  },
  "79703": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MIDLAND"
  },
  "79705": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MIDLAND"
  },
  "79706": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MIDLAND"
  },
  "79707": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MIDLAND"
  },
  "79713": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ACKERLY"
  },
  "79714": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ANDREWS"
  },
  "79718": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BALMORHEA"
  },
  "79719": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BARSTOW"
  },
  "79720": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BIG SPRING"
  },
  "79730": {
    stateCode: "TX",
    stateName: "Texas",
    city: "COYANOSA"
  },
  "79731": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CRANE"
  },
  "79733": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORSAN"
  },
  "79734": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT DAVIS"
  },
  "79735": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT STOCKTON"
  },
  "79738": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GAIL"
  },
  "79739": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GARDEN CITY"
  },
  "79740": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GIRVIN"
  },
  "79741": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GOLDSMITH"
  },
  "79742": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GRANDFALLS"
  },
  "79743": {
    stateCode: "TX",
    stateName: "Texas",
    city: "IMPERIAL"
  },
  "79744": {
    stateCode: "TX",
    stateName: "Texas",
    city: "IRAAN"
  },
  "79745": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KERMIT"
  },
  "79748": {
    stateCode: "TX",
    stateName: "Texas",
    city: "KNOTT"
  },
  "79749": {
    stateCode: "TX",
    stateName: "Texas",
    city: "LENORAH"
  },
  "79752": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MC CAMEY"
  },
  "79754": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MENTONE"
  },
  "79755": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MIDKIFF"
  },
  "79756": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MONAHANS"
  },
  "79758": {
    stateCode: "TX",
    stateName: "Texas",
    city: "GARDENDALE"
  },
  "79759": {
    stateCode: "TX",
    stateName: "Texas",
    city: "NOTREES"
  },
  "79761": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ODESSA"
  },
  "79762": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ODESSA"
  },
  "79763": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ODESSA"
  },
  "79764": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ODESSA"
  },
  "79765": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ODESSA"
  },
  "79766": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ODESSA"
  },
  "79772": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PECOS"
  },
  "79776": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PENWELL"
  },
  "79777": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PYOTE"
  },
  "79778": {
    stateCode: "TX",
    stateName: "Texas",
    city: "RANKIN"
  },
  "79780": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SARAGOSA"
  },
  "79781": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SHEFFIELD"
  },
  "79782": {
    stateCode: "TX",
    stateName: "Texas",
    city: "STANTON"
  },
  "79783": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TARZAN"
  },
  "79785": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TOYAH"
  },
  "79788": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WICKETT"
  },
  "79789": {
    stateCode: "TX",
    stateName: "Texas",
    city: "WINK"
  },
  "79821": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ANTHONY"
  },
  "79830": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ALPINE"
  },
  "79831": {
    stateCode: "TX",
    stateName: "Texas",
    city: "ALPINE"
  },
  "79834": {
    stateCode: "TX",
    stateName: "Texas",
    city: "BIG BEND NATIONAL PARK"
  },
  "79835": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CANUTILLO"
  },
  "79836": {
    stateCode: "TX",
    stateName: "Texas",
    city: "CLINT"
  },
  "79837": {
    stateCode: "TX",
    stateName: "Texas",
    city: "DELL CITY"
  },
  "79838": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FABENS"
  },
  "79839": {
    stateCode: "TX",
    stateName: "Texas",
    city: "FORT HANCOCK"
  },
  "79842": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MARATHON"
  },
  "79843": {
    stateCode: "TX",
    stateName: "Texas",
    city: "MARFA"
  },
  "79845": {
    stateCode: "TX",
    stateName: "Texas",
    city: "PRESIDIO"
  },
  "79846": {
    stateCode: "TX",
    stateName: "Texas",
    city: "REDFORD"
  },
  "79847": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SALT FLAT"
  },
  "79848": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SANDERSON"
  },
  "79849": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SAN ELIZARIO"
  },
  "79851": {
    stateCode: "TX",
    stateName: "Texas",
    city: "SIERRA BLANCA"
  },
  "79852": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TERLINGUA"
  },
  "79853": {
    stateCode: "TX",
    stateName: "Texas",
    city: "TORNILLO"
  },
  "79854": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VALENTINE"
  },
  "79855": {
    stateCode: "TX",
    stateName: "Texas",
    city: "VAN HORN"
  },
  "79901": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79902": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79903": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79904": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79905": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79906": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79907": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79908": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79912": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79915": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79922": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79924": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79925": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79927": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79930": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79932": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79934": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79935": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79936": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "79938": {
    stateCode: "TX",
    stateName: "Texas",
    city: "EL PASO"
  },
  "80002": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ARVADA"
  },
  "80003": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ARVADA"
  },
  "80004": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ARVADA"
  },
  "80005": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ARVADA"
  },
  "80007": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ARVADA"
  },
  "80010": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "AURORA"
  },
  "80011": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "AURORA"
  },
  "80012": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "AURORA"
  },
  "80013": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "AURORA"
  },
  "80014": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "AURORA"
  },
  "80015": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "AURORA"
  },
  "80016": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "AURORA"
  },
  "80017": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "AURORA"
  },
  "80018": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "AURORA"
  },
  "80019": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "AURORA"
  },
  "80020": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BROOMFIELD"
  },
  "80021": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BROOMFIELD"
  },
  "80022": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COMMERCE CITY"
  },
  "80024": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DUPONT"
  },
  "80025": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ELDORADO SPRINGS"
  },
  "80026": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LAFAYETTE"
  },
  "80027": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LOUISVILLE"
  },
  "80030": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WESTMINSTER"
  },
  "80031": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WESTMINSTER"
  },
  "80033": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WHEAT RIDGE"
  },
  "80101": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "AGATE"
  },
  "80102": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BENNETT"
  },
  "80103": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BYERS"
  },
  "80104": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CASTLE ROCK"
  },
  "80105": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DEER TRAIL"
  },
  "80106": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ELBERT"
  },
  "80107": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ELIZABETH"
  },
  "80110": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ENGLEWOOD"
  },
  "80111": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ENGLEWOOD"
  },
  "80112": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ENGLEWOOD"
  },
  "80116": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FRANKTOWN"
  },
  "80117": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "KIOWA"
  },
  "80118": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LARKSPUR"
  },
  "80120": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LITTLETON"
  },
  "80121": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LITTLETON"
  },
  "80122": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LITTLETON"
  },
  "80123": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LITTLETON"
  },
  "80124": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LITTLETON"
  },
  "80125": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LITTLETON"
  },
  "80126": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LITTLETON"
  },
  "80127": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LITTLETON"
  },
  "80128": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LITTLETON"
  },
  "80132": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MONUMENT"
  },
  "80133": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PALMER LAKE"
  },
  "80134": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PARKER"
  },
  "80135": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SEDALIA"
  },
  "80136": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "STRASBURG"
  },
  "80137": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WATKINS"
  },
  "80138": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PARKER"
  },
  "80202": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80203": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80204": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80205": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80206": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80207": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80209": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80210": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80211": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80212": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80214": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80215": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80216": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80218": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80219": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80220": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80221": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80222": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80223": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80224": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80226": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80227": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80228": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80229": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80230": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80231": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80232": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80233": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80234": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80235": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80236": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80237": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80239": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80241": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80246": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80249": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80260": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DENVER"
  },
  "80301": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BOULDER"
  },
  "80302": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BOULDER"
  },
  "80303": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BOULDER"
  },
  "80304": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BOULDER"
  },
  "80401": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GOLDEN"
  },
  "80403": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GOLDEN"
  },
  "80420": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ALMA"
  },
  "80421": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BAILEY"
  },
  "80422": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BLACK HAWK"
  },
  "80423": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BOND"
  },
  "80424": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BRECKENRIDGE"
  },
  "80425": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BUFFALO CREEK"
  },
  "80426": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BURNS"
  },
  "80427": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CENTRAL CITY"
  },
  "80428": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CLARK"
  },
  "80430": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COALMONT"
  },
  "80432": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COMO"
  },
  "80433": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CONIFER"
  },
  "80434": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COWDREY"
  },
  "80435": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DILLON"
  },
  "80436": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DUMONT"
  },
  "80438": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "EMPIRE"
  },
  "80439": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "EVERGREEN"
  },
  "80440": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FAIRPLAY"
  },
  "80442": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FRASER"
  },
  "80443": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FRISCO"
  },
  "80444": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GEORGETOWN"
  },
  "80446": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GRANBY"
  },
  "80447": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GRAND LAKE"
  },
  "80448": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GRANT"
  },
  "80449": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "HARTSEL"
  },
  "80451": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "HOT SULPHUR SPRINGS"
  },
  "80452": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "IDAHO SPRINGS"
  },
  "80454": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "INDIAN HILLS"
  },
  "80455": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "JAMESTOWN"
  },
  "80456": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "JEFFERSON"
  },
  "80457": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "KITTREDGE"
  },
  "80459": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "KREMMLING"
  },
  "80461": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LEADVILLE"
  },
  "80463": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MC COY"
  },
  "80465": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MORRISON"
  },
  "80466": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "NEDERLAND"
  },
  "80467": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "OAK CREEK"
  },
  "80468": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PARSHALL"
  },
  "80469": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PHIPPSBURG"
  },
  "80470": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PINE"
  },
  "80473": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "RAND"
  },
  "80474": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ROLLINSVILLE"
  },
  "80476": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SILVER PLUME"
  },
  "80478": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "TABERNASH"
  },
  "80479": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "TOPONAS"
  },
  "80480": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WALDEN"
  },
  "80481": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WARD"
  },
  "80482": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WINTER PARK"
  },
  "80483": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "YAMPA"
  },
  "80487": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "STEAMBOAT SPRINGS"
  },
  "80498": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SILVERTHORNE"
  },
  "80501": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LONGMONT"
  },
  "80503": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LONGMONT"
  },
  "80504": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LONGMONT"
  },
  "80510": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ALLENSPARK"
  },
  "80512": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BELLVUE"
  },
  "80513": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BERTHOUD"
  },
  "80514": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DACONO"
  },
  "80515": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DRAKE"
  },
  "80516": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ERIE"
  },
  "80517": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ESTES PARK"
  },
  "80520": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FIRESTONE"
  },
  "80521": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FORT COLLINS"
  },
  "80524": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FORT COLLINS"
  },
  "80525": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FORT COLLINS"
  },
  "80526": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FORT COLLINS"
  },
  "80528": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FORT COLLINS"
  },
  "80530": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FREDERICK"
  },
  "80532": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GLEN HAVEN"
  },
  "80534": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "JOHNSTOWN"
  },
  "80535": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LAPORTE"
  },
  "80536": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LIVERMORE"
  },
  "80537": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LOVELAND"
  },
  "80538": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LOVELAND"
  },
  "80540": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LYONS"
  },
  "80542": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MEAD"
  },
  "80543": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MILLIKEN"
  },
  "80545": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "RED FEATHER LAKES"
  },
  "80547": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "TIMNATH"
  },
  "80549": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WELLINGTON"
  },
  "80550": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WINDSOR"
  },
  "80601": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BRIGHTON"
  },
  "80610": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "AULT"
  },
  "80611": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BRIGGSDALE"
  },
  "80612": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CARR"
  },
  "80615": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "EATON"
  },
  "80620": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "EVANS"
  },
  "80621": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FORT LUPTON"
  },
  "80623": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GILCREST"
  },
  "80624": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GILL"
  },
  "80631": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GREELEY"
  },
  "80634": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GREELEY"
  },
  "80640": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "HENDERSON"
  },
  "80642": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "HUDSON"
  },
  "80643": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "KEENESBURG"
  },
  "80644": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "KERSEY"
  },
  "80645": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LA SALLE"
  },
  "80648": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "NUNN"
  },
  "80649": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ORCHARD"
  },
  "80650": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PIERCE"
  },
  "80651": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PLATTEVILLE"
  },
  "80652": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ROGGEN"
  },
  "80653": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WELDONA"
  },
  "80654": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WIGGINS"
  },
  "80701": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FORT MORGAN"
  },
  "80705": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LOG LANE VILLAGE"
  },
  "80720": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "AKRON"
  },
  "80721": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "AMHERST"
  },
  "80722": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ATWOOD"
  },
  "80723": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BRUSH"
  },
  "80726": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CROOK"
  },
  "80727": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ECKLEY"
  },
  "80728": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FLEMING"
  },
  "80729": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GROVER"
  },
  "80731": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "HAXTUN"
  },
  "80733": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "HILLROSE"
  },
  "80734": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "HOLYOKE"
  },
  "80735": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "IDALIA"
  },
  "80736": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ILIFF"
  },
  "80737": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "JULESBURG"
  },
  "80740": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LINDON"
  },
  "80741": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MERINO"
  },
  "80742": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "NEW RAYMER"
  },
  "80743": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "OTIS"
  },
  "80744": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "OVID"
  },
  "80745": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PADRONI"
  },
  "80747": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PEETZ"
  },
  "80749": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SEDGWICK"
  },
  "80750": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SNYDER"
  },
  "80751": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "STERLING"
  },
  "80754": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "STONEHAM"
  },
  "80755": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "VERNON"
  },
  "80757": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WOODROW"
  },
  "80758": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WRAY"
  },
  "80759": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "YUMA"
  },
  "80801": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ANTON"
  },
  "80802": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ARAPAHOE"
  },
  "80804": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ARRIBA"
  },
  "80805": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BETHUNE"
  },
  "80807": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BURLINGTON"
  },
  "80808": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CALHAN"
  },
  "80809": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CASCADE"
  },
  "80810": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CHEYENNE WELLS"
  },
  "80812": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COPE"
  },
  "80813": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CRIPPLE CREEK"
  },
  "80814": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DIVIDE"
  },
  "80815": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FLAGLER"
  },
  "80816": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FLORISSANT"
  },
  "80817": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FOUNTAIN"
  },
  "80818": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GENOA"
  },
  "80819": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GREEN MOUNTAIN FALLS"
  },
  "80820": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GUFFEY"
  },
  "80821": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "HUGO"
  },
  "80822": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "JOES"
  },
  "80823": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "KARVAL"
  },
  "80824": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "KIRK"
  },
  "80825": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "KIT CARSON"
  },
  "80827": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LAKE GEORGE"
  },
  "80828": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LIMON"
  },
  "80829": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MANITOU SPRINGS"
  },
  "80830": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MATHESON"
  },
  "80831": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PEYTON"
  },
  "80832": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "RAMAH"
  },
  "80833": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "RUSH"
  },
  "80834": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SEIBERT"
  },
  "80835": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SIMLA"
  },
  "80836": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "STRATTON"
  },
  "80840": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "U S A F ACADEMY"
  },
  "80860": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "VICTOR"
  },
  "80861": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "VONA"
  },
  "80862": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WILD HORSE"
  },
  "80863": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WOODLAND PARK"
  },
  "80864": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "YODER"
  },
  "80866": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WOODLAND PARK"
  },
  "80903": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80904": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80905": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80906": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80907": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80908": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80909": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80910": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80911": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80913": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80915": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80916": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80917": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80918": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80919": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80920": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80921": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80922": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80925": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80926": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80928": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80929": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "80930": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLORADO SPRINGS"
  },
  "81001": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PUEBLO"
  },
  "81003": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PUEBLO"
  },
  "81004": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PUEBLO"
  },
  "81005": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PUEBLO"
  },
  "81006": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PUEBLO"
  },
  "81007": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PUEBLO"
  },
  "81008": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PUEBLO"
  },
  "81020": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "AGUILAR"
  },
  "81021": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ARLINGTON"
  },
  "81022": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "AVONDALE"
  },
  "81023": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BEULAH"
  },
  "81024": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BONCARBO"
  },
  "81025": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BOONE"
  },
  "81027": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BRANSON"
  },
  "81029": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CAMPO"
  },
  "81030": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CHERAW"
  },
  "81033": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CROWLEY"
  },
  "81036": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "EADS"
  },
  "81039": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FOWLER"
  },
  "81040": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GARDNER"
  },
  "81041": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GRANADA"
  },
  "81043": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "HARTMAN"
  },
  "81044": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "HASTY"
  },
  "81045": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "HASWELL"
  },
  "81047": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "HOLLY"
  },
  "81049": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "KIM"
  },
  "81050": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LA JUNTA"
  },
  "81052": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LAMAR"
  },
  "81054": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LAS ANIMAS"
  },
  "81055": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LA VETA"
  },
  "81057": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MC CLAVE"
  },
  "81058": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MANZANOLA"
  },
  "81059": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MODEL"
  },
  "81062": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "OLNEY SPRINGS"
  },
  "81063": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ORDWAY"
  },
  "81064": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PRITCHETT"
  },
  "81067": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ROCKY FORD"
  },
  "81069": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "RYE"
  },
  "81071": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SHERIDAN LAKE"
  },
  "81073": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SPRINGFIELD"
  },
  "81076": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SUGAR CITY"
  },
  "81077": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SWINK"
  },
  "81081": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "TRINCHERA"
  },
  "81082": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "TRINIDAD"
  },
  "81084": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "TWO BUTTES"
  },
  "81087": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "VILAS"
  },
  "81089": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WALSENBURG"
  },
  "81090": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WALSH"
  },
  "81091": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WESTON"
  },
  "81092": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WILEY"
  },
  "81101": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ALAMOSA"
  },
  "81120": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ANTONITO"
  },
  "81121": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ARBOLES"
  },
  "81122": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BAYFIELD"
  },
  "81123": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BLANCA"
  },
  "81124": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CAPULIN"
  },
  "81125": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CENTER"
  },
  "81126": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CHAMA"
  },
  "81127": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CHIMNEY ROCK"
  },
  "81128": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CHROMO"
  },
  "81130": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CREEDE"
  },
  "81131": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CRESTONE"
  },
  "81132": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DEL NORTE"
  },
  "81133": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FORT GARLAND"
  },
  "81136": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "HOOPER"
  },
  "81137": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "IGNACIO"
  },
  "81140": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LA JARA"
  },
  "81141": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MANASSA"
  },
  "81143": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MOFFAT"
  },
  "81144": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MONTE VISTA"
  },
  "81146": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MOSCA"
  },
  "81147": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PAGOSA SPRINGS"
  },
  "81148": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ROMEO"
  },
  "81149": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SAGUACHE"
  },
  "81151": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SANFORD"
  },
  "81152": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SAN LUIS"
  },
  "81153": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SAN PABLO"
  },
  "81154": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SOUTH FORK"
  },
  "81155": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "VILLA GROVE"
  },
  "81201": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SALIDA"
  },
  "81210": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ALMONT"
  },
  "81211": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BUENA VISTA"
  },
  "81212": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CANON CITY"
  },
  "81220": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CIMARRON"
  },
  "81221": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COAL CREEK"
  },
  "81222": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COALDALE"
  },
  "81223": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COTOPAXI"
  },
  "81224": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CRESTED BUTTE"
  },
  "81225": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CRESTED BUTTE"
  },
  "81226": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FLORENCE"
  },
  "81230": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GUNNISON"
  },
  "81233": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "HOWARD"
  },
  "81235": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LAKE CITY"
  },
  "81236": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "NATHROP"
  },
  "81239": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PARLIN"
  },
  "81240": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PENROSE"
  },
  "81241": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PITKIN"
  },
  "81243": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "POWDERHORN"
  },
  "81244": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ROCKVALE"
  },
  "81248": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SARGENTS"
  },
  "81251": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "TWIN LAKES"
  },
  "81252": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WESTCLIFFE"
  },
  "81253": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WETMORE"
  },
  "81301": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DURANGO"
  },
  "81320": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CAHONE"
  },
  "81321": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CORTEZ"
  },
  "81323": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DOLORES"
  },
  "81324": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DOVE CREEK"
  },
  "81325": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "EGNAR"
  },
  "81326": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "HESPERUS"
  },
  "81327": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LEWIS"
  },
  "81328": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MANCOS"
  },
  "81330": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MESA VERDE NATIONAL PARK"
  },
  "81331": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PLEASANT VIEW"
  },
  "81332": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "RICO"
  },
  "81334": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "TOWAOC"
  },
  "81335": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "YELLOW JACKET"
  },
  "81401": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MONTROSE"
  },
  "81410": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "AUSTIN"
  },
  "81411": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BEDROCK"
  },
  "81413": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CEDAREDGE"
  },
  "81415": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CRAWFORD"
  },
  "81416": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DELTA"
  },
  "81418": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ECKERT"
  },
  "81419": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "HOTCHKISS"
  },
  "81422": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "NATURITA"
  },
  "81423": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "NORWOOD"
  },
  "81424": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "NUCLA"
  },
  "81425": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "OLATHE"
  },
  "81426": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "OPHIR"
  },
  "81427": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "OURAY"
  },
  "81428": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PAONIA"
  },
  "81430": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PLACERVILLE"
  },
  "81431": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "REDVALE"
  },
  "81432": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "RIDGWAY"
  },
  "81433": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SILVERTON"
  },
  "81434": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SOMERSET"
  },
  "81435": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "TELLURIDE"
  },
  "81501": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GRAND JUNCTION"
  },
  "81503": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GRAND JUNCTION"
  },
  "81504": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GRAND JUNCTION"
  },
  "81505": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GRAND JUNCTION"
  },
  "81506": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GRAND JUNCTION"
  },
  "81520": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CLIFTON"
  },
  "81521": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "FRUITA"
  },
  "81522": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GATEWAY"
  },
  "81523": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GLADE PARK"
  },
  "81524": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "LOMA"
  },
  "81525": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MACK"
  },
  "81526": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PALISADE"
  },
  "81527": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WHITEWATER"
  },
  "81601": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GLENWOOD SPRINGS"
  },
  "81610": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DINOSAUR"
  },
  "81611": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "ASPEN"
  },
  "81615": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SNOWMASS VILLAGE"
  },
  "81620": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "AVON"
  },
  "81621": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "BASALT"
  },
  "81623": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CARBONDALE"
  },
  "81624": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "COLLBRAN"
  },
  "81625": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "CRAIG"
  },
  "81630": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "DE BEQUE"
  },
  "81631": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "EAGLE"
  },
  "81632": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "EDWARDS"
  },
  "81635": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "PARACHUTE"
  },
  "81637": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "GYPSUM"
  },
  "81638": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "HAMILTON"
  },
  "81639": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "HAYDEN"
  },
  "81640": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MAYBELL"
  },
  "81641": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MEEKER"
  },
  "81642": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MEREDITH"
  },
  "81643": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MESA"
  },
  "81645": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MINTURN"
  },
  "81646": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "MOLINA"
  },
  "81647": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "NEW CASTLE"
  },
  "81648": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "RANGELY"
  },
  "81649": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "RED CLIFF"
  },
  "81650": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "RIFLE"
  },
  "81652": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SILT"
  },
  "81653": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SLATER"
  },
  "81654": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "SNOWMASS"
  },
  "81655": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WOLCOTT"
  },
  "81656": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "WOODY CREEK"
  },
  "81657": {
    stateCode: "CO",
    stateName: "Colorado",
    city: "VAIL"
  },
  "82001": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "CHEYENNE"
  },
  "82007": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "CHEYENNE"
  },
  "82009": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "CHEYENNE"
  },
  "82050": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "ALBIN"
  },
  "82051": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "BOSLER"
  },
  "82052": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "BUFORD"
  },
  "82053": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "BURNS"
  },
  "82054": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "CARPENTER"
  },
  "82055": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "CENTENNIAL"
  },
  "82058": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "GARRETT"
  },
  "82059": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "GRANITE CANON"
  },
  "82060": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "HILLSDALE"
  },
  "82061": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "HORSE CREEK"
  },
  "82063": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "JELM"
  },
  "82070": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "LARAMIE"
  },
  "82072": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "LARAMIE"
  },
  "82081": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "MERIDEN"
  },
  "82082": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "PINE BLUFFS"
  },
  "82083": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "ROCK RIVER"
  },
  "82084": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "TIE SIDING"
  },
  "82190": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "YELLOWSTONE NATIONAL PARK"
  },
  "82201": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "WHEATLAND"
  },
  "82210": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "CHUGWATER"
  },
  "82212": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "FORT LARAMIE"
  },
  "82213": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "GLENDO"
  },
  "82214": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "GUERNSEY"
  },
  "82215": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "HARTVILLE"
  },
  "82217": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "HAWK SPRINGS"
  },
  "82218": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "HUNTLEY"
  },
  "82219": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "JAY EM"
  },
  "82221": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "LAGRANGE"
  },
  "82222": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "LANCE CREEK"
  },
  "82223": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "LINGLE"
  },
  "82224": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "LOST SPRINGS"
  },
  "82225": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "LUSK"
  },
  "82227": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "MANVILLE"
  },
  "82240": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "TORRINGTON"
  },
  "82242": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "VAN TASSELL"
  },
  "82243": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "VETERAN"
  },
  "82244": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "YODER"
  },
  "82301": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "RAWLINS"
  },
  "82310": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "JEFFREY CITY"
  },
  "82321": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "BAGGS"
  },
  "82322": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "BAIROIL"
  },
  "82323": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "DIXON"
  },
  "82324": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "ELK MOUNTAIN"
  },
  "82325": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "ENCAMPMENT"
  },
  "82327": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "HANNA"
  },
  "82329": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "MEDICINE BOW"
  },
  "82331": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "SARATOGA"
  },
  "82332": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "SAVERY"
  },
  "82335": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "WALCOTT"
  },
  "82336": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "WAMSUTTER"
  },
  "82401": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "WORLAND"
  },
  "82410": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "BASIN"
  },
  "82411": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "BURLINGTON"
  },
  "82412": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "BYRON"
  },
  "82414": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "CODY"
  },
  "82420": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "COWLEY"
  },
  "82421": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "DEAVER"
  },
  "82422": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "EMBLEM"
  },
  "82423": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "FRANNIE"
  },
  "82426": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "GREYBULL"
  },
  "82428": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "HYATTVILLE"
  },
  "82430": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "KIRBY"
  },
  "82431": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "LOVELL"
  },
  "82432": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "MANDERSON"
  },
  "82433": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "MEETEETSE"
  },
  "82434": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "OTTO"
  },
  "82435": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "POWELL"
  },
  "82440": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "RALSTON"
  },
  "82441": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "SHELL"
  },
  "82442": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "TEN SLEEP"
  },
  "82443": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "THERMOPOLIS"
  },
  "82501": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "RIVERTON"
  },
  "82510": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "ARAPAHOE"
  },
  "82512": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "CROWHEART"
  },
  "82513": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "DUBOIS"
  },
  "82514": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "FORT WASHAKIE"
  },
  "82515": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "HUDSON"
  },
  "82516": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "KINNEAR"
  },
  "82520": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "LANDER"
  },
  "82523": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "PAVILLION"
  },
  "82601": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "CASPER"
  },
  "82604": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "CASPER"
  },
  "82609": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "CASPER"
  },
  "82620": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "ALCOVA"
  },
  "82633": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "DOUGLAS"
  },
  "82635": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "EDGERTON"
  },
  "82636": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "EVANSVILLE"
  },
  "82637": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "GLENROCK"
  },
  "82639": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "KAYCEE"
  },
  "82640": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "LINCH"
  },
  "82642": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "LYSITE"
  },
  "82643": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "MIDWEST"
  },
  "82644": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "MILLS"
  },
  "82649": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "SHOSHONI"
  },
  "82701": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "NEWCASTLE"
  },
  "82710": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "ALADDIN"
  },
  "82711": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "ALVA"
  },
  "82712": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "BEULAH"
  },
  "82714": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "DEVILS TOWER"
  },
  "82716": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "GILLETTE"
  },
  "82717": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "GILLETTE"
  },
  "82718": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "GILLETTE"
  },
  "82720": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "HULETT"
  },
  "82721": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "MOORCROFT"
  },
  "82723": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "OSAGE"
  },
  "82725": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "RECLUSE"
  },
  "82727": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "ROZET"
  },
  "82729": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "SUNDANCE"
  },
  "82730": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "UPTON"
  },
  "82731": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "WESTON"
  },
  "82732": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "WRIGHT"
  },
  "82801": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "SHERIDAN"
  },
  "82831": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "ARVADA"
  },
  "82832": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "BANNER"
  },
  "82833": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "BIG HORN"
  },
  "82834": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "BUFFALO"
  },
  "82835": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "CLEARMONT"
  },
  "82836": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "DAYTON"
  },
  "82837": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "LEITER"
  },
  "82838": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "PARKMAN"
  },
  "82839": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "RANCHESTER"
  },
  "82842": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "STORY"
  },
  "82844": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "WOLF"
  },
  "82845": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "WYARNO"
  },
  "82901": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "ROCK SPRINGS"
  },
  "82922": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "BONDURANT"
  },
  "82923": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "BOULDER"
  },
  "82925": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "CORA"
  },
  "82929": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "LITTLE AMERICA"
  },
  "82930": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "EVANSTON"
  },
  "82932": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "FARSON"
  },
  "82933": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "FORT BRIDGER"
  },
  "82934": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "GRANGER"
  },
  "82935": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "GREEN RIVER"
  },
  "82936": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "LONETREE"
  },
  "82937": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "LYMAN"
  },
  "82938": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "MC KINNON"
  },
  "82939": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "MOUNTAIN VIEW"
  },
  "82941": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "PINEDALE"
  },
  "82942": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "POINT OF ROCKS"
  },
  "82943": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "RELIANCE"
  },
  "82944": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "ROBERTSON"
  },
  "82945": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "SUPERIOR"
  },
  "83001": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "JACKSON"
  },
  "83011": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "KELLY"
  },
  "83012": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "MOOSE"
  },
  "83013": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "MORAN"
  },
  "83014": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "WILSON"
  },
  "83101": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "KEMMERER"
  },
  "83110": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "AFTON"
  },
  "83111": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "AUBURN"
  },
  "83112": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "BEDFORD"
  },
  "83113": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "BIG PINEY"
  },
  "83114": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "COKEVILLE"
  },
  "83115": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "DANIEL"
  },
  "83116": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "DIAMONDVILLE"
  },
  "83118": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "ETNA"
  },
  "83119": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "FAIRVIEW"
  },
  "83120": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "FREEDOM"
  },
  "83121": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "FRONTIER"
  },
  "83122": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "GROVER"
  },
  "83123": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "LA BARGE"
  },
  "83124": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "OPAL"
  },
  "83126": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "SMOOT"
  },
  "83127": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "THAYNE"
  },
  "83128": {
    stateCode: "WY",
    stateName: "Wyoming",
    city: "ALPINE"
  },
  "83201": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "POCATELLO"
  },
  "83202": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "POCATELLO"
  },
  "83203": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "FORT HALL"
  },
  "83204": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "POCATELLO"
  },
  "83210": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "ABERDEEN"
  },
  "83211": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "AMERICAN FALLS"
  },
  "83212": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "ARBON"
  },
  "83213": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "ARCO"
  },
  "83214": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "ARIMO"
  },
  "83215": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "ATOMIC CITY"
  },
  "83217": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BANCROFT"
  },
  "83218": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BASALT"
  },
  "83220": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BERN"
  },
  "83221": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BLACKFOOT"
  },
  "83223": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BLOOMINGTON"
  },
  "83226": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CHALLIS"
  },
  "83227": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CLAYTON"
  },
  "83228": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CLIFTON"
  },
  "83232": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "DAYTON"
  },
  "83234": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "DOWNEY"
  },
  "83235": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "ELLIS"
  },
  "83236": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "FIRTH"
  },
  "83237": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "FRANKLIN"
  },
  "83238": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "GENEVA"
  },
  "83239": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "GEORGETOWN"
  },
  "83241": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "GRACE"
  },
  "83243": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "HOLBROOK"
  },
  "83244": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "HOWE"
  },
  "83245": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "INKOM"
  },
  "83246": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "LAVA HOT SPRINGS"
  },
  "83250": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MCCAMMON"
  },
  "83251": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MACKAY"
  },
  "83252": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MALAD CITY"
  },
  "83253": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MAY"
  },
  "83254": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MONTPELIER"
  },
  "83255": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MOORE"
  },
  "83261": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "PARIS"
  },
  "83262": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "PINGREE"
  },
  "83263": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "PRESTON"
  },
  "83271": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "ROCKLAND"
  },
  "83272": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SAINT CHARLES"
  },
  "83274": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SHELLEY"
  },
  "83276": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SODA SPRINGS"
  },
  "83277": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SPRINGFIELD"
  },
  "83278": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "STANLEY"
  },
  "83281": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SWANLAKE"
  },
  "83283": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "THATCHER"
  },
  "83285": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "WAYAN"
  },
  "83286": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "WESTON"
  },
  "83287": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "FISH HAVEN"
  },
  "83301": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "TWIN FALLS"
  },
  "83302": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "ROGERSON"
  },
  "83311": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "ALBION"
  },
  "83312": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "ALMO"
  },
  "83313": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BELLEVUE"
  },
  "83314": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BLISS"
  },
  "83316": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BUHL"
  },
  "83318": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BURLEY"
  },
  "83320": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CAREY"
  },
  "83321": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CASTLEFORD"
  },
  "83322": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CORRAL"
  },
  "83323": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "DECLO"
  },
  "83324": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "DIETRICH"
  },
  "83325": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "EDEN"
  },
  "83327": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "FAIRFIELD"
  },
  "83328": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "FILER"
  },
  "83330": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "GOODING"
  },
  "83332": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "HAGERMAN"
  },
  "83333": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "HAILEY"
  },
  "83334": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "HANSEN"
  },
  "83335": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "HAZELTON"
  },
  "83336": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "HEYBURN"
  },
  "83337": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "HILL CITY"
  },
  "83338": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "JEROME"
  },
  "83340": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "KETCHUM"
  },
  "83341": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "KIMBERLY"
  },
  "83342": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MALTA"
  },
  "83344": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MURTAUGH"
  },
  "83346": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "OAKLEY"
  },
  "83347": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "PAUL"
  },
  "83348": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "PICABO"
  },
  "83349": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "RICHFIELD"
  },
  "83350": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "RUPERT"
  },
  "83352": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SHOSHONE"
  },
  "83353": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SUN VALLEY"
  },
  "83354": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SUN VALLEY"
  },
  "83355": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "WENDELL"
  },
  "83401": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "IDAHO FALLS"
  },
  "83402": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "IDAHO FALLS"
  },
  "83404": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "IDAHO FALLS"
  },
  "83406": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "IDAHO FALLS"
  },
  "83420": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "ASHTON"
  },
  "83421": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CHESTER"
  },
  "83422": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "DRIGGS"
  },
  "83423": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "DUBOIS"
  },
  "83424": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "FELT"
  },
  "83425": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "HAMER"
  },
  "83427": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "IONA"
  },
  "83428": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "IRWIN"
  },
  "83429": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "ISLAND PARK"
  },
  "83431": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "LEWISVILLE"
  },
  "83433": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MACKS INN"
  },
  "83434": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MENAN"
  },
  "83435": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MONTEVIEW"
  },
  "83436": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "NEWDALE"
  },
  "83440": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "REXBURG"
  },
  "83442": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "RIGBY"
  },
  "83443": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "RIRIE"
  },
  "83444": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "ROBERTS"
  },
  "83445": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SAINT ANTHONY"
  },
  "83446": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SPENCER"
  },
  "83448": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SUGAR CITY"
  },
  "83449": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SWAN VALLEY"
  },
  "83450": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "TERRETON"
  },
  "83451": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "TETON"
  },
  "83452": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "TETONIA"
  },
  "83455": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "VICTOR"
  },
  "83462": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CARMEN"
  },
  "83463": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "GIBBONSVILLE"
  },
  "83464": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "LEADORE"
  },
  "83465": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "LEMHI"
  },
  "83466": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "NORTH FORK"
  },
  "83467": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SALMON"
  },
  "83468": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "TENDOY"
  },
  "83469": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SHOUP"
  },
  "83501": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "LEWISTON"
  },
  "83520": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "AHSAHKA"
  },
  "83522": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "COTTONWOOD"
  },
  "83523": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CRAIGMONT"
  },
  "83524": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CULDESAC"
  },
  "83525": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "ELK CITY"
  },
  "83526": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "FERDINAND"
  },
  "83530": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "GRANGEVILLE"
  },
  "83533": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "GREENCREEK"
  },
  "83535": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "JULIAETTA"
  },
  "83536": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "KAMIAH"
  },
  "83537": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "KENDRICK"
  },
  "83539": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "KOOSKIA"
  },
  "83540": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "LAPWAI"
  },
  "83541": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "LENORE"
  },
  "83542": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "LUCILE"
  },
  "83543": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "NEZPERCE"
  },
  "83544": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "OROFINO"
  },
  "83545": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "PECK"
  },
  "83546": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "PIERCE"
  },
  "83547": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "POLLOCK"
  },
  "83548": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "REUBENS"
  },
  "83549": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "RIGGINS"
  },
  "83552": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "STITES"
  },
  "83553": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "WEIPPE"
  },
  "83554": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "WHITE BIRD"
  },
  "83555": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "WINCHESTER"
  },
  "83601": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "ATLANTA"
  },
  "83602": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BANKS"
  },
  "83604": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BRUNEAU"
  },
  "83605": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CALDWELL"
  },
  "83607": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CALDWELL"
  },
  "83610": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CAMBRIDGE"
  },
  "83611": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CASCADE"
  },
  "83612": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "COUNCIL"
  },
  "83615": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "DONNELLY"
  },
  "83616": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "EAGLE"
  },
  "83617": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "EMMETT"
  },
  "83619": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "FRUITLAND"
  },
  "83622": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "GARDEN VALLEY"
  },
  "83623": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "GLENNS FERRY"
  },
  "83624": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "GRAND VIEW"
  },
  "83626": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "GREENLEAF"
  },
  "83627": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "HAMMETT"
  },
  "83628": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "HOMEDALE"
  },
  "83629": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "HORSESHOE BEND"
  },
  "83631": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "IDAHO CITY"
  },
  "83632": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "INDIAN VALLEY"
  },
  "83633": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "KING HILL"
  },
  "83634": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "KUNA"
  },
  "83637": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "LOWMAN"
  },
  "83638": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MCCALL"
  },
  "83639": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MARSING"
  },
  "83641": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MELBA"
  },
  "83642": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MERIDIAN"
  },
  "83643": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MESA"
  },
  "83644": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MIDDLETON"
  },
  "83645": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MIDVALE"
  },
  "83647": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MOUNTAIN HOME"
  },
  "83648": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MOUNTAIN HOME A F B"
  },
  "83650": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MURPHY"
  },
  "83651": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "NAMPA"
  },
  "83654": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "NEW MEADOWS"
  },
  "83655": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "NEW PLYMOUTH"
  },
  "83656": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "NOTUS"
  },
  "83657": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "OLA"
  },
  "83660": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "PARMA"
  },
  "83661": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "PAYETTE"
  },
  "83666": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "PLACERVILLE"
  },
  "83669": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "STAR"
  },
  "83670": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SWEET"
  },
  "83671": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "WARREN"
  },
  "83672": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "WEISER"
  },
  "83676": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "WILDER"
  },
  "83677": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "YELLOW PINE"
  },
  "83686": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "NAMPA"
  },
  "83687": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "NAMPA"
  },
  "83702": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BOISE"
  },
  "83703": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BOISE"
  },
  "83704": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BOISE"
  },
  "83705": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BOISE"
  },
  "83706": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BOISE"
  },
  "83709": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BOISE"
  },
  "83712": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BOISE"
  },
  "83713": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BOISE"
  },
  "83714": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "GARDEN CITY"
  },
  "83716": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BOISE"
  },
  "83801": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "ATHOL"
  },
  "83802": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "AVERY"
  },
  "83803": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BAYVIEW"
  },
  "83804": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BLANCHARD"
  },
  "83805": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BONNERS FERRY"
  },
  "83806": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "BOVILL"
  },
  "83808": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CALDER"
  },
  "83809": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CAREYWOOD"
  },
  "83810": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CATALDO"
  },
  "83811": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CLARK FORK"
  },
  "83812": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "CLARKIA"
  },
  "83813": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "COCOLALLA"
  },
  "83814": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "COEUR D ALENE"
  },
  "83815": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "COEUR D ALENE"
  },
  "83821": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "COOLIN"
  },
  "83822": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "OLDTOWN"
  },
  "83823": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "DEARY"
  },
  "83824": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "DESMET"
  },
  "83826": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "EASTPORT"
  },
  "83827": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "ELK RIVER"
  },
  "83830": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "FERNWOOD"
  },
  "83832": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "GENESEE"
  },
  "83833": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "HARRISON"
  },
  "83834": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "HARVARD"
  },
  "83835": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "HAYDEN"
  },
  "83836": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "HOPE"
  },
  "83837": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "KELLOGG"
  },
  "83839": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "KINGSTON"
  },
  "83841": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "LACLEDE"
  },
  "83842": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MEDIMONT"
  },
  "83843": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MOSCOW"
  },
  "83845": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MOYIE SPRINGS"
  },
  "83846": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MULLAN"
  },
  "83847": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "NAPLES"
  },
  "83848": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "NORDMAN"
  },
  "83849": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "OSBURN"
  },
  "83850": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "PINEHURST"
  },
  "83851": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "PLUMMER"
  },
  "83852": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "PONDERAY"
  },
  "83853": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "PORTHILL"
  },
  "83854": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "POST FALLS"
  },
  "83855": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "POTLATCH"
  },
  "83856": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "PRIEST RIVER"
  },
  "83857": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "PRINCETON"
  },
  "83858": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "RATHDRUM"
  },
  "83860": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SAGLE"
  },
  "83861": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SAINT MARIES"
  },
  "83864": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SANDPOINT"
  },
  "83866": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SANTA"
  },
  "83867": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SILVERTON"
  },
  "83868": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SMELTERVILLE"
  },
  "83869": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "SPIRIT LAKE"
  },
  "83870": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "TENSED"
  },
  "83871": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "TROY"
  },
  "83872": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "VIOLA"
  },
  "83873": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "WALLACE"
  },
  "83874": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "MURRAY"
  },
  "83876": {
    stateCode: "ID",
    stateName: "Idaho",
    city: "WORLEY"
  },
  "84001": {
    stateCode: "UT",
    stateName: "Utah",
    city: "ALTAMONT"
  },
  "84002": {
    stateCode: "UT",
    stateName: "Utah",
    city: "ALTONAH"
  },
  "84003": {
    stateCode: "UT",
    stateName: "Utah",
    city: "AMERICAN FORK"
  },
  "84004": {
    stateCode: "UT",
    stateName: "Utah",
    city: "ALPINE"
  },
  "84006": {
    stateCode: "UT",
    stateName: "Utah",
    city: "BINGHAM CANYON"
  },
  "84007": {
    stateCode: "UT",
    stateName: "Utah",
    city: "BLUEBELL"
  },
  "84010": {
    stateCode: "UT",
    stateName: "Utah",
    city: "BOUNTIFUL"
  },
  "84013": {
    stateCode: "UT",
    stateName: "Utah",
    city: "CEDAR VALLEY"
  },
  "84014": {
    stateCode: "UT",
    stateName: "Utah",
    city: "CENTERVILLE"
  },
  "84015": {
    stateCode: "UT",
    stateName: "Utah",
    city: "CLEARFIELD"
  },
  "84017": {
    stateCode: "UT",
    stateName: "Utah",
    city: "COALVILLE"
  },
  "84018": {
    stateCode: "UT",
    stateName: "Utah",
    city: "CROYDON"
  },
  "84020": {
    stateCode: "UT",
    stateName: "Utah",
    city: "DRAPER"
  },
  "84021": {
    stateCode: "UT",
    stateName: "Utah",
    city: "DUCHESNE"
  },
  "84022": {
    stateCode: "UT",
    stateName: "Utah",
    city: "DUGWAY"
  },
  "84023": {
    stateCode: "UT",
    stateName: "Utah",
    city: "DUTCH JOHN"
  },
  "84024": {
    stateCode: "UT",
    stateName: "Utah",
    city: "ECHO"
  },
  "84025": {
    stateCode: "UT",
    stateName: "Utah",
    city: "FARMINGTON"
  },
  "84026": {
    stateCode: "UT",
    stateName: "Utah",
    city: "FORT DUCHESNE"
  },
  "84027": {
    stateCode: "UT",
    stateName: "Utah",
    city: "FRUITLAND"
  },
  "84028": {
    stateCode: "UT",
    stateName: "Utah",
    city: "GARDEN CITY"
  },
  "84029": {
    stateCode: "UT",
    stateName: "Utah",
    city: "GRANTSVILLE"
  },
  "84031": {
    stateCode: "UT",
    stateName: "Utah",
    city: "HANNA"
  },
  "84032": {
    stateCode: "UT",
    stateName: "Utah",
    city: "HEBER CITY"
  },
  "84033": {
    stateCode: "UT",
    stateName: "Utah",
    city: "HENEFER"
  },
  "84034": {
    stateCode: "UT",
    stateName: "Utah",
    city: "IBAPAH"
  },
  "84035": {
    stateCode: "UT",
    stateName: "Utah",
    city: "JENSEN"
  },
  "84036": {
    stateCode: "UT",
    stateName: "Utah",
    city: "KAMAS"
  },
  "84037": {
    stateCode: "UT",
    stateName: "Utah",
    city: "KAYSVILLE"
  },
  "84038": {
    stateCode: "UT",
    stateName: "Utah",
    city: "LAKETOWN"
  },
  "84039": {
    stateCode: "UT",
    stateName: "Utah",
    city: "LAPOINT"
  },
  "84040": {
    stateCode: "UT",
    stateName: "Utah",
    city: "LAYTON"
  },
  "84041": {
    stateCode: "UT",
    stateName: "Utah",
    city: "LAYTON"
  },
  "84042": {
    stateCode: "UT",
    stateName: "Utah",
    city: "LINDON"
  },
  "84043": {
    stateCode: "UT",
    stateName: "Utah",
    city: "LEHI"
  },
  "84044": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MAGNA"
  },
  "84046": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MANILA"
  },
  "84047": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MIDVALE"
  },
  "84049": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MIDWAY"
  },
  "84050": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MORGAN"
  },
  "84051": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MOUNTAIN HOME"
  },
  "84052": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MYTON"
  },
  "84053": {
    stateCode: "UT",
    stateName: "Utah",
    city: "NEOLA"
  },
  "84054": {
    stateCode: "UT",
    stateName: "Utah",
    city: "NORTH SALT LAKE"
  },
  "84055": {
    stateCode: "UT",
    stateName: "Utah",
    city: "OAKLEY"
  },
  "84056": {
    stateCode: "UT",
    stateName: "Utah",
    city: "HILL AFB"
  },
  "84057": {
    stateCode: "UT",
    stateName: "Utah",
    city: "OREM"
  },
  "84058": {
    stateCode: "UT",
    stateName: "Utah",
    city: "OREM"
  },
  "84060": {
    stateCode: "UT",
    stateName: "Utah",
    city: "PARK CITY"
  },
  "84061": {
    stateCode: "UT",
    stateName: "Utah",
    city: "PEOA"
  },
  "84062": {
    stateCode: "UT",
    stateName: "Utah",
    city: "PLEASANT GROVE"
  },
  "84063": {
    stateCode: "UT",
    stateName: "Utah",
    city: "RANDLETT"
  },
  "84064": {
    stateCode: "UT",
    stateName: "Utah",
    city: "RANDOLPH"
  },
  "84065": {
    stateCode: "UT",
    stateName: "Utah",
    city: "RIVERTON"
  },
  "84066": {
    stateCode: "UT",
    stateName: "Utah",
    city: "ROOSEVELT"
  },
  "84067": {
    stateCode: "UT",
    stateName: "Utah",
    city: "ROY"
  },
  "84069": {
    stateCode: "UT",
    stateName: "Utah",
    city: "RUSH VALLEY"
  },
  "84070": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SANDY"
  },
  "84071": {
    stateCode: "UT",
    stateName: "Utah",
    city: "STOCKTON"
  },
  "84072": {
    stateCode: "UT",
    stateName: "Utah",
    city: "TABIONA"
  },
  "84073": {
    stateCode: "UT",
    stateName: "Utah",
    city: "TALMAGE"
  },
  "84074": {
    stateCode: "UT",
    stateName: "Utah",
    city: "TOOELE"
  },
  "84075": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SYRACUSE"
  },
  "84076": {
    stateCode: "UT",
    stateName: "Utah",
    city: "TRIDELL"
  },
  "84078": {
    stateCode: "UT",
    stateName: "Utah",
    city: "VERNAL"
  },
  "84080": {
    stateCode: "UT",
    stateName: "Utah",
    city: "VERNON"
  },
  "84082": {
    stateCode: "UT",
    stateName: "Utah",
    city: "WALLSBURG"
  },
  "84083": {
    stateCode: "UT",
    stateName: "Utah",
    city: "WENDOVER"
  },
  "84084": {
    stateCode: "UT",
    stateName: "Utah",
    city: "WEST JORDAN"
  },
  "84085": {
    stateCode: "UT",
    stateName: "Utah",
    city: "WHITEROCKS"
  },
  "84086": {
    stateCode: "UT",
    stateName: "Utah",
    city: "WOODRUFF"
  },
  "84087": {
    stateCode: "UT",
    stateName: "Utah",
    city: "WOODS CROSS"
  },
  "84088": {
    stateCode: "UT",
    stateName: "Utah",
    city: "WEST JORDAN"
  },
  "84092": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SANDY"
  },
  "84093": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SANDY"
  },
  "84094": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SANDY"
  },
  "84095": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SOUTH JORDAN"
  },
  "84097": {
    stateCode: "UT",
    stateName: "Utah",
    city: "OREM"
  },
  "84098": {
    stateCode: "UT",
    stateName: "Utah",
    city: "PARK CITY"
  },
  "84101": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84102": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84103": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84104": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84105": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84106": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84107": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84108": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84109": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84111": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84112": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84115": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84116": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84117": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84118": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84119": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84120": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84121": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84123": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84124": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84128": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALT LAKE CITY"
  },
  "84301": {
    stateCode: "UT",
    stateName: "Utah",
    city: "BEAR RIVER CITY"
  },
  "84302": {
    stateCode: "UT",
    stateName: "Utah",
    city: "BRIGHAM CITY"
  },
  "84304": {
    stateCode: "UT",
    stateName: "Utah",
    city: "CACHE JUNCTION"
  },
  "84305": {
    stateCode: "UT",
    stateName: "Utah",
    city: "CLARKSTON"
  },
  "84306": {
    stateCode: "UT",
    stateName: "Utah",
    city: "COLLINSTON"
  },
  "84307": {
    stateCode: "UT",
    stateName: "Utah",
    city: "CORINNE"
  },
  "84309": {
    stateCode: "UT",
    stateName: "Utah",
    city: "DEWEYVILLE"
  },
  "84310": {
    stateCode: "UT",
    stateName: "Utah",
    city: "EDEN"
  },
  "84311": {
    stateCode: "UT",
    stateName: "Utah",
    city: "FIELDING"
  },
  "84312": {
    stateCode: "UT",
    stateName: "Utah",
    city: "GARLAND"
  },
  "84313": {
    stateCode: "UT",
    stateName: "Utah",
    city: "GROUSE CREEK"
  },
  "84314": {
    stateCode: "UT",
    stateName: "Utah",
    city: "HONEYVILLE"
  },
  "84315": {
    stateCode: "UT",
    stateName: "Utah",
    city: "HOOPER"
  },
  "84316": {
    stateCode: "UT",
    stateName: "Utah",
    city: "HOWELL"
  },
  "84317": {
    stateCode: "UT",
    stateName: "Utah",
    city: "HUNTSVILLE"
  },
  "84318": {
    stateCode: "UT",
    stateName: "Utah",
    city: "HYDE PARK"
  },
  "84319": {
    stateCode: "UT",
    stateName: "Utah",
    city: "HYRUM"
  },
  "84320": {
    stateCode: "UT",
    stateName: "Utah",
    city: "LEWISTON"
  },
  "84321": {
    stateCode: "UT",
    stateName: "Utah",
    city: "LOGAN"
  },
  "84324": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MANTUA"
  },
  "84325": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MENDON"
  },
  "84326": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MILLVILLE"
  },
  "84327": {
    stateCode: "UT",
    stateName: "Utah",
    city: "NEWTON"
  },
  "84328": {
    stateCode: "UT",
    stateName: "Utah",
    city: "PARADISE"
  },
  "84329": {
    stateCode: "UT",
    stateName: "Utah",
    city: "PARK VALLEY"
  },
  "84330": {
    stateCode: "UT",
    stateName: "Utah",
    city: "PLYMOUTH"
  },
  "84331": {
    stateCode: "UT",
    stateName: "Utah",
    city: "PORTAGE"
  },
  "84332": {
    stateCode: "UT",
    stateName: "Utah",
    city: "PROVIDENCE"
  },
  "84333": {
    stateCode: "UT",
    stateName: "Utah",
    city: "RICHMOND"
  },
  "84334": {
    stateCode: "UT",
    stateName: "Utah",
    city: "RIVERSIDE"
  },
  "84335": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SMITHFIELD"
  },
  "84336": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SNOWVILLE"
  },
  "84337": {
    stateCode: "UT",
    stateName: "Utah",
    city: "TREMONTON"
  },
  "84338": {
    stateCode: "UT",
    stateName: "Utah",
    city: "TRENTON"
  },
  "84339": {
    stateCode: "UT",
    stateName: "Utah",
    city: "WELLSVILLE"
  },
  "84340": {
    stateCode: "UT",
    stateName: "Utah",
    city: "WILLARD"
  },
  "84341": {
    stateCode: "UT",
    stateName: "Utah",
    city: "LOGAN"
  },
  "84401": {
    stateCode: "UT",
    stateName: "Utah",
    city: "OGDEN"
  },
  "84403": {
    stateCode: "UT",
    stateName: "Utah",
    city: "OGDEN"
  },
  "84404": {
    stateCode: "UT",
    stateName: "Utah",
    city: "OGDEN"
  },
  "84405": {
    stateCode: "UT",
    stateName: "Utah",
    city: "OGDEN"
  },
  "84414": {
    stateCode: "UT",
    stateName: "Utah",
    city: "OGDEN"
  },
  "84501": {
    stateCode: "UT",
    stateName: "Utah",
    city: "PRICE"
  },
  "84510": {
    stateCode: "UT",
    stateName: "Utah",
    city: "ANETH"
  },
  "84511": {
    stateCode: "UT",
    stateName: "Utah",
    city: "BLANDING"
  },
  "84512": {
    stateCode: "UT",
    stateName: "Utah",
    city: "BLUFF"
  },
  "84513": {
    stateCode: "UT",
    stateName: "Utah",
    city: "CASTLE DALE"
  },
  "84516": {
    stateCode: "UT",
    stateName: "Utah",
    city: "CLAWSON"
  },
  "84518": {
    stateCode: "UT",
    stateName: "Utah",
    city: "CLEVELAND"
  },
  "84520": {
    stateCode: "UT",
    stateName: "Utah",
    city: "EAST CARBON"
  },
  "84521": {
    stateCode: "UT",
    stateName: "Utah",
    city: "ELMO"
  },
  "84522": {
    stateCode: "UT",
    stateName: "Utah",
    city: "EMERY"
  },
  "84523": {
    stateCode: "UT",
    stateName: "Utah",
    city: "FERRON"
  },
  "84525": {
    stateCode: "UT",
    stateName: "Utah",
    city: "GREEN RIVER"
  },
  "84526": {
    stateCode: "UT",
    stateName: "Utah",
    city: "HELPER"
  },
  "84528": {
    stateCode: "UT",
    stateName: "Utah",
    city: "HUNTINGTON"
  },
  "84529": {
    stateCode: "UT",
    stateName: "Utah",
    city: "KENILWORTH"
  },
  "84530": {
    stateCode: "UT",
    stateName: "Utah",
    city: "LA SAL"
  },
  "84531": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MEXICAN HAT"
  },
  "84532": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MOAB"
  },
  "84533": {
    stateCode: "UT",
    stateName: "Utah",
    city: "LAKE POWELL"
  },
  "84534": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MONTEZUMA CREEK"
  },
  "84535": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MONTICELLO"
  },
  "84536": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MONUMENT VALLEY"
  },
  "84537": {
    stateCode: "UT",
    stateName: "Utah",
    city: "ORANGEVILLE"
  },
  "84539": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SUNNYSIDE"
  },
  "84540": {
    stateCode: "UT",
    stateName: "Utah",
    city: "THOMPSON"
  },
  "84542": {
    stateCode: "UT",
    stateName: "Utah",
    city: "WELLINGTON"
  },
  "84601": {
    stateCode: "UT",
    stateName: "Utah",
    city: "PROVO"
  },
  "84604": {
    stateCode: "UT",
    stateName: "Utah",
    city: "PROVO"
  },
  "84606": {
    stateCode: "UT",
    stateName: "Utah",
    city: "PROVO"
  },
  "84620": {
    stateCode: "UT",
    stateName: "Utah",
    city: "AURORA"
  },
  "84621": {
    stateCode: "UT",
    stateName: "Utah",
    city: "AXTELL"
  },
  "84622": {
    stateCode: "UT",
    stateName: "Utah",
    city: "CENTERFIELD"
  },
  "84623": {
    stateCode: "UT",
    stateName: "Utah",
    city: "CHESTER"
  },
  "84624": {
    stateCode: "UT",
    stateName: "Utah",
    city: "DELTA"
  },
  "84626": {
    stateCode: "UT",
    stateName: "Utah",
    city: "ELBERTA"
  },
  "84627": {
    stateCode: "UT",
    stateName: "Utah",
    city: "EPHRAIM"
  },
  "84628": {
    stateCode: "UT",
    stateName: "Utah",
    city: "EUREKA"
  },
  "84629": {
    stateCode: "UT",
    stateName: "Utah",
    city: "FAIRVIEW"
  },
  "84630": {
    stateCode: "UT",
    stateName: "Utah",
    city: "FAYETTE"
  },
  "84631": {
    stateCode: "UT",
    stateName: "Utah",
    city: "FILLMORE"
  },
  "84632": {
    stateCode: "UT",
    stateName: "Utah",
    city: "FOUNTAIN GREEN"
  },
  "84633": {
    stateCode: "UT",
    stateName: "Utah",
    city: "GOSHEN"
  },
  "84634": {
    stateCode: "UT",
    stateName: "Utah",
    city: "GUNNISON"
  },
  "84635": {
    stateCode: "UT",
    stateName: "Utah",
    city: "HINCKLEY"
  },
  "84636": {
    stateCode: "UT",
    stateName: "Utah",
    city: "HOLDEN"
  },
  "84637": {
    stateCode: "UT",
    stateName: "Utah",
    city: "KANOSH"
  },
  "84638": {
    stateCode: "UT",
    stateName: "Utah",
    city: "LEAMINGTON"
  },
  "84639": {
    stateCode: "UT",
    stateName: "Utah",
    city: "LEVAN"
  },
  "84640": {
    stateCode: "UT",
    stateName: "Utah",
    city: "LYNNDYL"
  },
  "84642": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MANTI"
  },
  "84643": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MAYFIELD"
  },
  "84645": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MONA"
  },
  "84646": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MORONI"
  },
  "84647": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MOUNT PLEASANT"
  },
  "84648": {
    stateCode: "UT",
    stateName: "Utah",
    city: "NEPHI"
  },
  "84649": {
    stateCode: "UT",
    stateName: "Utah",
    city: "OAK CITY"
  },
  "84651": {
    stateCode: "UT",
    stateName: "Utah",
    city: "PAYSON"
  },
  "84652": {
    stateCode: "UT",
    stateName: "Utah",
    city: "REDMOND"
  },
  "84653": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALEM"
  },
  "84654": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SALINA"
  },
  "84655": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SANTAQUIN"
  },
  "84656": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SCIPIO"
  },
  "84657": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SIGURD"
  },
  "84660": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SPANISH FORK"
  },
  "84662": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SPRING CITY"
  },
  "84663": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SPRINGVILLE"
  },
  "84664": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MAPLETON"
  },
  "84667": {
    stateCode: "UT",
    stateName: "Utah",
    city: "WALES"
  },
  "84701": {
    stateCode: "UT",
    stateName: "Utah",
    city: "RICHFIELD"
  },
  "84710": {
    stateCode: "UT",
    stateName: "Utah",
    city: "ALTON"
  },
  "84711": {
    stateCode: "UT",
    stateName: "Utah",
    city: "ANNABELLA"
  },
  "84712": {
    stateCode: "UT",
    stateName: "Utah",
    city: "ANTIMONY"
  },
  "84713": {
    stateCode: "UT",
    stateName: "Utah",
    city: "BEAVER"
  },
  "84714": {
    stateCode: "UT",
    stateName: "Utah",
    city: "BERYL"
  },
  "84715": {
    stateCode: "UT",
    stateName: "Utah",
    city: "BICKNELL"
  },
  "84716": {
    stateCode: "UT",
    stateName: "Utah",
    city: "BOULDER"
  },
  "84718": {
    stateCode: "UT",
    stateName: "Utah",
    city: "CANNONVILLE"
  },
  "84719": {
    stateCode: "UT",
    stateName: "Utah",
    city: "BRIAN HEAD"
  },
  "84720": {
    stateCode: "UT",
    stateName: "Utah",
    city: "CEDAR CITY"
  },
  "84722": {
    stateCode: "UT",
    stateName: "Utah",
    city: "CENTRAL"
  },
  "84723": {
    stateCode: "UT",
    stateName: "Utah",
    city: "CIRCLEVILLE"
  },
  "84724": {
    stateCode: "UT",
    stateName: "Utah",
    city: "ELSINORE"
  },
  "84725": {
    stateCode: "UT",
    stateName: "Utah",
    city: "ENTERPRISE"
  },
  "84726": {
    stateCode: "UT",
    stateName: "Utah",
    city: "ESCALANTE"
  },
  "84728": {
    stateCode: "UT",
    stateName: "Utah",
    city: "GARRISON"
  },
  "84729": {
    stateCode: "UT",
    stateName: "Utah",
    city: "GLENDALE"
  },
  "84730": {
    stateCode: "UT",
    stateName: "Utah",
    city: "GLENWOOD"
  },
  "84731": {
    stateCode: "UT",
    stateName: "Utah",
    city: "GREENVILLE"
  },
  "84732": {
    stateCode: "UT",
    stateName: "Utah",
    city: "GREENWICH"
  },
  "84733": {
    stateCode: "UT",
    stateName: "Utah",
    city: "GUNLOCK"
  },
  "84734": {
    stateCode: "UT",
    stateName: "Utah",
    city: "HANKSVILLE"
  },
  "84735": {
    stateCode: "UT",
    stateName: "Utah",
    city: "HATCH"
  },
  "84736": {
    stateCode: "UT",
    stateName: "Utah",
    city: "HENRIEVILLE"
  },
  "84737": {
    stateCode: "UT",
    stateName: "Utah",
    city: "HURRICANE"
  },
  "84738": {
    stateCode: "UT",
    stateName: "Utah",
    city: "IVINS"
  },
  "84739": {
    stateCode: "UT",
    stateName: "Utah",
    city: "JOSEPH"
  },
  "84740": {
    stateCode: "UT",
    stateName: "Utah",
    city: "JUNCTION"
  },
  "84741": {
    stateCode: "UT",
    stateName: "Utah",
    city: "KANAB"
  },
  "84743": {
    stateCode: "UT",
    stateName: "Utah",
    city: "KINGSTON"
  },
  "84744": {
    stateCode: "UT",
    stateName: "Utah",
    city: "KOOSHAREM"
  },
  "84745": {
    stateCode: "UT",
    stateName: "Utah",
    city: "LA VERKIN"
  },
  "84747": {
    stateCode: "UT",
    stateName: "Utah",
    city: "LOA"
  },
  "84749": {
    stateCode: "UT",
    stateName: "Utah",
    city: "LYMAN"
  },
  "84750": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MARYSVALE"
  },
  "84751": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MILFORD"
  },
  "84752": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MINERSVILLE"
  },
  "84753": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MODENA"
  },
  "84754": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MONROE"
  },
  "84755": {
    stateCode: "UT",
    stateName: "Utah",
    city: "MOUNT CARMEL"
  },
  "84757": {
    stateCode: "UT",
    stateName: "Utah",
    city: "NEW HARMONY"
  },
  "84758": {
    stateCode: "UT",
    stateName: "Utah",
    city: "ORDERVILLE"
  },
  "84759": {
    stateCode: "UT",
    stateName: "Utah",
    city: "PANGUITCH"
  },
  "84760": {
    stateCode: "UT",
    stateName: "Utah",
    city: "PARAGONAH"
  },
  "84761": {
    stateCode: "UT",
    stateName: "Utah",
    city: "PAROWAN"
  },
  "84762": {
    stateCode: "UT",
    stateName: "Utah",
    city: "DUCK CREEK VILLAGE"
  },
  "84764": {
    stateCode: "UT",
    stateName: "Utah",
    city: "BRYCE"
  },
  "84765": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SANTA CLARA"
  },
  "84766": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SEVIER"
  },
  "84770": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SAINT GEORGE"
  },
  "84772": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SUMMIT"
  },
  "84773": {
    stateCode: "UT",
    stateName: "Utah",
    city: "TEASDALE"
  },
  "84774": {
    stateCode: "UT",
    stateName: "Utah",
    city: "TOQUERVILLE"
  },
  "84775": {
    stateCode: "UT",
    stateName: "Utah",
    city: "TORREY"
  },
  "84776": {
    stateCode: "UT",
    stateName: "Utah",
    city: "TROPIC"
  },
  "84779": {
    stateCode: "UT",
    stateName: "Utah",
    city: "VIRGIN"
  },
  "84780": {
    stateCode: "UT",
    stateName: "Utah",
    city: "WASHINGTON"
  },
  "84781": {
    stateCode: "UT",
    stateName: "Utah",
    city: "PINE VALLEY"
  },
  "84782": {
    stateCode: "UT",
    stateName: "Utah",
    city: "VEYO"
  },
  "84783": {
    stateCode: "UT",
    stateName: "Utah",
    city: "DAMMERON VALLEY"
  },
  "84790": {
    stateCode: "UT",
    stateName: "Utah",
    city: "SAINT GEORGE"
  },
  "85003": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85004": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85006": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85007": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85008": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85009": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85012": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85013": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85014": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85015": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85016": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85017": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85018": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85019": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85020": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85021": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85022": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85023": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85024": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85027": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85028": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85029": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85031": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85032": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85033": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85034": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85035": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85037": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85040": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85041": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85043": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85044": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85045": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85048": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85050": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85051": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85053": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85054": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85085": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85086": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PHOENIX"
  },
  "85087": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "NEW RIVER"
  },
  "85201": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MESA"
  },
  "85202": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MESA"
  },
  "85203": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MESA"
  },
  "85204": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MESA"
  },
  "85205": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MESA"
  },
  "85206": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MESA"
  },
  "85207": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MESA"
  },
  "85208": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MESA"
  },
  "85210": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MESA"
  },
  "85212": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MESA"
  },
  "85213": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MESA"
  },
  "85215": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MESA"
  },
  "85219": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "APACHE JUNCTION"
  },
  "85220": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "APACHE JUNCTION"
  },
  "85221": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "BAPCHULE"
  },
  "85222": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CASA GRANDE"
  },
  "85223": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "ARIZONA CITY"
  },
  "85224": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CHANDLER"
  },
  "85225": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CHANDLER"
  },
  "85226": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CHANDLER"
  },
  "85228": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "COOLIDGE"
  },
  "85230": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CASA GRANDE"
  },
  "85231": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "ELOY"
  },
  "85232": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "FLORENCE"
  },
  "85233": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GILBERT"
  },
  "85234": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GILBERT"
  },
  "85235": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "HAYDEN"
  },
  "85236": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "HIGLEY"
  },
  "85237": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "KEARNY"
  },
  "85239": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MARICOPA"
  },
  "85241": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PICACHO"
  },
  "85242": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "QUEEN CREEK"
  },
  "85245": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "RED ROCK"
  },
  "85247": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SACATON"
  },
  "85248": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CHANDLER"
  },
  "85249": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CHANDLER"
  },
  "85250": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SCOTTSDALE"
  },
  "85251": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SCOTTSDALE"
  },
  "85253": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PARADISE VALLEY"
  },
  "85254": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SCOTTSDALE"
  },
  "85255": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SCOTTSDALE"
  },
  "85256": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SCOTTSDALE"
  },
  "85257": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SCOTTSDALE"
  },
  "85258": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SCOTTSDALE"
  },
  "85259": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SCOTTSDALE"
  },
  "85260": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SCOTTSDALE"
  },
  "85262": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SCOTTSDALE"
  },
  "85263": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "RIO VERDE"
  },
  "85264": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "FORT MCDOWELL"
  },
  "85268": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "FOUNTAIN HILLS"
  },
  "85272": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "STANFIELD"
  },
  "85273": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SUPERIOR"
  },
  "85281": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TEMPE"
  },
  "85282": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TEMPE"
  },
  "85283": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TEMPE"
  },
  "85284": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TEMPE"
  },
  "85292": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "WINKELMAN"
  },
  "85296": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GILBERT"
  },
  "85301": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GLENDALE"
  },
  "85302": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GLENDALE"
  },
  "85303": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GLENDALE"
  },
  "85304": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GLENDALE"
  },
  "85305": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GLENDALE"
  },
  "85306": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GLENDALE"
  },
  "85307": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GLENDALE"
  },
  "85308": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GLENDALE"
  },
  "85310": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GLENDALE"
  },
  "85320": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "AGUILA"
  },
  "85321": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "AJO"
  },
  "85322": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "ARLINGTON"
  },
  "85323": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "AVONDALE"
  },
  "85324": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "BLACK CANYON CITY"
  },
  "85325": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "BOUSE"
  },
  "85326": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "BUCKEYE"
  },
  "85328": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CIBOLA"
  },
  "85329": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CASHION"
  },
  "85331": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CAVE CREEK"
  },
  "85332": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CONGRESS"
  },
  "85333": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "DATELAND"
  },
  "85334": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "EHRENBERG"
  },
  "85335": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "EL MIRAGE"
  },
  "85336": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GADSDEN"
  },
  "85337": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GILA BEND"
  },
  "85338": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GOODYEAR"
  },
  "85339": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "LAVEEN"
  },
  "85340": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "LITCHFIELD PARK"
  },
  "85342": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MORRISTOWN"
  },
  "85344": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PARKER"
  },
  "85345": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PEORIA"
  },
  "85346": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "QUARTZSITE"
  },
  "85347": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "ROLL"
  },
  "85348": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SALOME"
  },
  "85349": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SAN LUIS"
  },
  "85350": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SOMERTON"
  },
  "85351": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SUN CITY"
  },
  "85353": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TOLLESON"
  },
  "85354": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TONOPAH"
  },
  "85355": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "WADDELL"
  },
  "85356": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "WELLTON"
  },
  "85357": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "WENDEN"
  },
  "85360": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "WIKIEUP"
  },
  "85361": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "WITTMANN"
  },
  "85362": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "YARNELL"
  },
  "85363": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "YOUNGTOWN"
  },
  "85364": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "YUMA"
  },
  "85365": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "YUMA"
  },
  "85367": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "YUMA"
  },
  "85373": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SUN CITY"
  },
  "85374": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SURPRISE"
  },
  "85375": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SUN CITY WEST"
  },
  "85379": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SURPRISE"
  },
  "85381": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PEORIA"
  },
  "85382": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PEORIA"
  },
  "85390": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "WICKENBURG"
  },
  "85501": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GLOBE"
  },
  "85530": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "BYLAS"
  },
  "85531": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CENTRAL"
  },
  "85533": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CLIFTON"
  },
  "85534": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "DUNCAN"
  },
  "85535": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "EDEN"
  },
  "85536": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "FORT THOMAS"
  },
  "85539": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MIAMI"
  },
  "85540": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MORENCI"
  },
  "85541": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PAYSON"
  },
  "85542": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PERIDOT"
  },
  "85543": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PIMA"
  },
  "85544": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PINE"
  },
  "85545": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "ROOSEVELT"
  },
  "85546": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SAFFORD"
  },
  "85550": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SAN CARLOS"
  },
  "85552": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "THATCHER"
  },
  "85553": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TONTO BASIN"
  },
  "85554": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "YOUNG"
  },
  "85601": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "ARIVACA"
  },
  "85602": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "BENSON"
  },
  "85603": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "BISBEE"
  },
  "85605": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "BOWIE"
  },
  "85606": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "COCHISE"
  },
  "85607": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "DOUGLAS"
  },
  "85608": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "DOUGLAS"
  },
  "85609": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "DRAGOON"
  },
  "85610": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "ELFRIDA"
  },
  "85611": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "ELGIN"
  },
  "85613": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "FORT HUACHUCA"
  },
  "85614": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GREEN VALLEY"
  },
  "85615": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "HEREFORD"
  },
  "85616": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "HUACHUCA CITY"
  },
  "85617": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MC NEAL"
  },
  "85618": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MAMMOTH"
  },
  "85619": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MOUNT LEMMON"
  },
  "85621": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "NOGALES"
  },
  "85623": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "ORACLE"
  },
  "85624": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PATAGONIA"
  },
  "85625": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PEARCE"
  },
  "85627": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "POMERENE"
  },
  "85629": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SAHUARITA"
  },
  "85630": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SAINT DAVID"
  },
  "85631": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SAN MANUEL"
  },
  "85632": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SAN SIMON"
  },
  "85633": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SASABE"
  },
  "85634": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SELLS"
  },
  "85635": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SIERRA VISTA"
  },
  "85637": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SONOITA"
  },
  "85638": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TOMBSTONE"
  },
  "85639": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TOPAWA"
  },
  "85640": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUMACACORI"
  },
  "85641": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "VAIL"
  },
  "85643": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "WILLCOX"
  },
  "85645": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "AMADO"
  },
  "85646": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUBAC"
  },
  "85648": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "RIO RICO"
  },
  "85650": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SIERRA VISTA"
  },
  "85653": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MARANA"
  },
  "85654": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "RILLITO"
  },
  "85701": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85704": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85705": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85706": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85708": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85710": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85711": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85712": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85713": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85714": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85715": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85716": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85718": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85719": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85730": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85735": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85736": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85737": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85739": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85741": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85742": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85743": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85745": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85746": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85747": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85748": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85749": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85750": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUCSON"
  },
  "85901": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SHOW LOW"
  },
  "85911": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CIBECUE"
  },
  "85920": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "ALPINE"
  },
  "85922": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "BLUE"
  },
  "85923": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CLAY SPRINGS"
  },
  "85924": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CONCHO"
  },
  "85925": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "EAGAR"
  },
  "85926": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "FORT APACHE"
  },
  "85927": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GREER"
  },
  "85928": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "HEBER"
  },
  "85929": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "LAKESIDE"
  },
  "85930": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MCNARY"
  },
  "85931": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "FOREST LAKES"
  },
  "85932": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "NUTRIOSO"
  },
  "85933": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "OVERGAARD"
  },
  "85934": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PINEDALE"
  },
  "85935": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PINETOP"
  },
  "85936": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SAINT JOHNS"
  },
  "85937": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SNOWFLAKE"
  },
  "85938": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SPRINGERVILLE"
  },
  "85939": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TAYLOR"
  },
  "85940": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "VERNON"
  },
  "85941": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "WHITERIVER"
  },
  "85942": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "WOODRUFF"
  },
  "86001": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "FLAGSTAFF"
  },
  "86004": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "FLAGSTAFF"
  },
  "86015": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "BELLEMONT"
  },
  "86016": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GRAY MOUNTAIN"
  },
  "86017": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MUNDS PARK"
  },
  "86018": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PARKS"
  },
  "86020": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CAMERON"
  },
  "86021": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "COLORADO CITY"
  },
  "86022": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "FREDONIA"
  },
  "86023": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GRAND CANYON"
  },
  "86024": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "HAPPY JACK"
  },
  "86025": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "HOLBROOK"
  },
  "86030": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "HOTEVILLA"
  },
  "86031": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "INDIAN WELLS"
  },
  "86032": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "JOSEPH CITY"
  },
  "86033": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "KAYENTA"
  },
  "86034": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "KEAMS CANYON"
  },
  "86035": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "LEUPP"
  },
  "86036": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MARBLE CANYON"
  },
  "86039": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "KYKOTSMOVI VILLAGE"
  },
  "86040": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PAGE"
  },
  "86042": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "POLACCA"
  },
  "86043": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SECOND MESA"
  },
  "86044": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TONALEA"
  },
  "86045": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TUBA CITY"
  },
  "86046": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "WILLIAMS"
  },
  "86047": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "WINSLOW"
  },
  "86052": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "NORTH RIM"
  },
  "86053": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "KAIBETO"
  },
  "86054": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SHONTO"
  },
  "86301": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PRESCOTT"
  },
  "86303": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PRESCOTT"
  },
  "86305": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PRESCOTT"
  },
  "86314": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PRESCOTT VALLEY"
  },
  "86320": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "ASH FORK"
  },
  "86321": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "BAGDAD"
  },
  "86322": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CAMP VERDE"
  },
  "86323": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CHINO VALLEY"
  },
  "86324": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CLARKDALE"
  },
  "86325": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CORNVILLE"
  },
  "86326": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "COTTONWOOD"
  },
  "86327": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "DEWEY"
  },
  "86331": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "JEROME"
  },
  "86332": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "KIRKLAND"
  },
  "86333": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MAYER"
  },
  "86334": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PAULDEN"
  },
  "86335": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "RIMROCK"
  },
  "86336": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SEDONA"
  },
  "86337": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SELIGMAN"
  },
  "86338": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SKULL VALLEY"
  },
  "86343": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CROWN KING"
  },
  "86351": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SEDONA"
  },
  "86401": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "KINGMAN"
  },
  "86403": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "LAKE HAVASU CITY"
  },
  "86404": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "LAKE HAVASU CITY"
  },
  "86406": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "LAKE HAVASU CITY"
  },
  "86413": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GOLDEN VALLEY"
  },
  "86426": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "FORT MOHAVE"
  },
  "86429": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "BULLHEAD CITY"
  },
  "86430": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "BULLHEAD CITY"
  },
  "86431": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CHLORIDE"
  },
  "86432": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "LITTLEFIELD"
  },
  "86433": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "OATMAN"
  },
  "86434": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PEACH SPRINGS"
  },
  "86435": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SUPAI"
  },
  "86436": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TOPOCK"
  },
  "86437": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "VALENTINE"
  },
  "86438": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "YUCCA"
  },
  "86440": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MOHAVE VALLEY"
  },
  "86441": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "DOLAN SPRINGS"
  },
  "86442": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "BULLHEAD CITY"
  },
  "86444": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MEADVIEW"
  },
  "86502": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CHAMBERS"
  },
  "86503": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "CHINLE"
  },
  "86504": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "FORT DEFIANCE"
  },
  "86505": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "GANADO"
  },
  "86506": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "HOUCK"
  },
  "86507": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "LUKACHUKAI"
  },
  "86508": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "LUPTON"
  },
  "86510": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "PINON"
  },
  "86511": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SAINT MICHAELS"
  },
  "86512": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "SANDERS"
  },
  "86514": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TEEC NOS POS"
  },
  "86515": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "WINDOW ROCK"
  },
  "86520": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "BLUE GAP"
  },
  "86535": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "DENNEHOTSO"
  },
  "86538": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "MANY FARMS"
  },
  "86540": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "NAZLINI"
  },
  "86544": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "RED VALLEY"
  },
  "86545": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "ROCK POINT"
  },
  "86547": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "ROUND ROCK"
  },
  "86556": {
    stateCode: "AZ",
    stateName: "Arizona",
    city: "TSAILE"
  },
  "87001": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALGODONES"
  },
  "87002": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "BELEN"
  },
  "87004": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "BERNALILLO"
  },
  "87005": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "BLUEWATER"
  },
  "87006": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "BOSQUE"
  },
  "87007": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CASA BLANCA"
  },
  "87008": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CEDAR CREST"
  },
  "87010": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CERRILLOS"
  },
  "87011": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CLAUNCH"
  },
  "87012": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "COYOTE"
  },
  "87013": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CUBA"
  },
  "87014": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CUBERO"
  },
  "87015": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "EDGEWOOD"
  },
  "87016": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ESTANCIA"
  },
  "87017": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "GALLINA"
  },
  "87018": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "COUNSELOR"
  },
  "87020": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "GRANTS"
  },
  "87022": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ISLETA"
  },
  "87023": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "JARALES"
  },
  "87024": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "JEMEZ PUEBLO"
  },
  "87025": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "JEMEZ SPRINGS"
  },
  "87026": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LAGUNA"
  },
  "87027": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LA JARA"
  },
  "87028": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LA JOYA"
  },
  "87029": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LINDRITH"
  },
  "87031": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LOS LUNAS"
  },
  "87032": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MC INTOSH"
  },
  "87034": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "PUEBLO OF ACOMA"
  },
  "87035": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MORIARTY"
  },
  "87036": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MOUNTAINAIR"
  },
  "87037": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "NAGEEZI"
  },
  "87038": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "NEW LAGUNA"
  },
  "87040": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "PAGUATE"
  },
  "87041": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "PENA BLANCA"
  },
  "87042": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "PERALTA"
  },
  "87043": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "PLACITAS"
  },
  "87044": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "PONDEROSA"
  },
  "87045": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "PREWITT"
  },
  "87047": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SANDIA PARK"
  },
  "87048": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CORRALES"
  },
  "87049": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SAN FIDEL"
  },
  "87052": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SANTO DOMINGO PUEBLO"
  },
  "87053": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SAN YSIDRO"
  },
  "87056": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "STANLEY"
  },
  "87059": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TIJERAS"
  },
  "87060": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TOME"
  },
  "87061": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TORREON"
  },
  "87062": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "VEGUITA"
  },
  "87063": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "WILLARD"
  },
  "87064": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "YOUNGSVILLE"
  },
  "87068": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "BOSQUE FARMS"
  },
  "87070": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CLINES CORNERS"
  },
  "87072": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "COCHITI PUEBLO"
  },
  "87083": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "COCHITI LAKE"
  },
  "87102": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALBUQUERQUE"
  },
  "87104": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALBUQUERQUE"
  },
  "87105": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALBUQUERQUE"
  },
  "87106": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALBUQUERQUE"
  },
  "87107": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALBUQUERQUE"
  },
  "87108": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALBUQUERQUE"
  },
  "87109": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALBUQUERQUE"
  },
  "87110": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALBUQUERQUE"
  },
  "87111": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALBUQUERQUE"
  },
  "87112": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALBUQUERQUE"
  },
  "87113": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALBUQUERQUE"
  },
  "87114": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALBUQUERQUE"
  },
  "87116": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALBUQUERQUE"
  },
  "87120": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALBUQUERQUE"
  },
  "87121": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALBUQUERQUE"
  },
  "87122": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALBUQUERQUE"
  },
  "87123": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALBUQUERQUE"
  },
  "87124": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "RIO RANCHO"
  },
  "87301": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "GALLUP"
  },
  "87305": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "GALLUP"
  },
  "87310": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "BRIMHALL"
  },
  "87311": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CHURCH ROCK"
  },
  "87312": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CONTINENTAL DIVIDE"
  },
  "87313": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CROWNPOINT"
  },
  "87315": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "FENCE LAKE"
  },
  "87316": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "FORT WINGATE"
  },
  "87317": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "GAMERCO"
  },
  "87319": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MENTMORE"
  },
  "87320": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MEXICAN SPRINGS"
  },
  "87321": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "RAMAH"
  },
  "87322": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "REHOBOTH"
  },
  "87323": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "THOREAU"
  },
  "87325": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TOHATCHI"
  },
  "87326": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "VANDERWAGEN"
  },
  "87327": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ZUNI"
  },
  "87328": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "NAVAJO"
  },
  "87347": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "JAMESTOWN"
  },
  "87364": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SHEEP SPRINGS"
  },
  "87375": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "YATAHEY"
  },
  "87401": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "FARMINGTON"
  },
  "87402": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "FARMINGTON"
  },
  "87410": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "AZTEC"
  },
  "87412": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "BLANCO"
  },
  "87413": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "BLOOMFIELD"
  },
  "87415": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "FLORA VISTA"
  },
  "87416": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "FRUITLAND"
  },
  "87417": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "KIRTLAND"
  },
  "87418": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LA PLATA"
  },
  "87419": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "NAVAJO DAM"
  },
  "87420": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SHIPROCK"
  },
  "87421": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "WATERFLOW"
  },
  "87455": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "NEWCOMB"
  },
  "87461": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SANOSTEE"
  },
  "87501": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SANTA FE"
  },
  "87505": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SANTA FE"
  },
  "87510": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ABIQUIU"
  },
  "87511": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALCALDE"
  },
  "87512": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "AMALIA"
  },
  "87513": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ARROYO HONDO"
  },
  "87514": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ARROYO SECO"
  },
  "87515": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CANJILON"
  },
  "87516": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CANONES"
  },
  "87517": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CARSON"
  },
  "87518": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CEBOLLA"
  },
  "87519": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CERRO"
  },
  "87520": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CHAMA"
  },
  "87521": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CHAMISAL"
  },
  "87522": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CHIMAYO"
  },
  "87524": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "COSTILLA"
  },
  "87525": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TAOS SKI VALLEY"
  },
  "87527": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "DIXON"
  },
  "87528": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "DULCE"
  },
  "87529": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "EL PRADO"
  },
  "87530": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "EL RITO"
  },
  "87531": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "EMBUDO"
  },
  "87532": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ESPANOLA"
  },
  "87533": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ESPANOLA"
  },
  "87535": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "GLORIETA"
  },
  "87537": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "HERNANDEZ"
  },
  "87539": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LA MADERA"
  },
  "87540": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LAMY"
  },
  "87543": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LLANO"
  },
  "87544": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LOS ALAMOS"
  },
  "87548": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MEDANALES"
  },
  "87549": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "OJO CALIENTE"
  },
  "87551": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LOS OJOS"
  },
  "87552": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "PECOS"
  },
  "87553": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "PENASCO"
  },
  "87556": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "QUESTA"
  },
  "87557": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "RANCHOS DE TAOS"
  },
  "87558": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "RED RIVER"
  },
  "87560": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "RIBERA"
  },
  "87562": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ROWE"
  },
  "87564": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SAN CRISTOBAL"
  },
  "87565": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SAN JOSE"
  },
  "87566": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SAN JUAN PUEBLO"
  },
  "87567": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SANTA CRUZ"
  },
  "87569": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SERAFINA"
  },
  "87571": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TAOS"
  },
  "87573": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TERERRO"
  },
  "87575": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TIERRA AMARILLA"
  },
  "87577": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TRES PIEDRAS"
  },
  "87578": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TRUCHAS"
  },
  "87579": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "VADITO"
  },
  "87580": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "VALDEZ"
  },
  "87581": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "VALLECITOS"
  },
  "87582": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "VELARDE"
  },
  "87583": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "VILLANUEVA"
  },
  "87701": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LAS VEGAS"
  },
  "87710": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ANGEL FIRE"
  },
  "87711": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ANTON CHICO"
  },
  "87712": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "BUENA VISTA"
  },
  "87713": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CHACON"
  },
  "87714": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CIMARRON"
  },
  "87715": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CLEVELAND"
  },
  "87718": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "EAGLE NEST"
  },
  "87722": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "GUADALUPITA"
  },
  "87723": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "HOLMAN"
  },
  "87724": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LA LOMA"
  },
  "87728": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MAXWELL"
  },
  "87729": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MIAMI"
  },
  "87730": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MILLS"
  },
  "87731": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MONTEZUMA"
  },
  "87732": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MORA"
  },
  "87733": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MOSQUERO"
  },
  "87734": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "OCATE"
  },
  "87735": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "OJO FELIZ"
  },
  "87736": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "RAINSVILLE"
  },
  "87740": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "RATON"
  },
  "87742": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ROCIADA"
  },
  "87743": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ROY"
  },
  "87745": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SAPELLO"
  },
  "87747": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SPRINGER"
  },
  "87750": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "VALMORA"
  },
  "87752": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "WAGON MOUND"
  },
  "87753": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "WATROUS"
  },
  "87801": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SOCORRO"
  },
  "87820": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ARAGON"
  },
  "87821": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "DATIL"
  },
  "87823": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LEMITAR"
  },
  "87824": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LUNA"
  },
  "87825": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MAGDALENA"
  },
  "87827": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "PIE TOWN"
  },
  "87828": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "POLVADERA"
  },
  "87829": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "QUEMADO"
  },
  "87830": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "RESERVE"
  },
  "87831": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SAN ACACIA"
  },
  "87832": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SAN ANTONIO"
  },
  "87901": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TRUTH OR CONSEQUENCES"
  },
  "87930": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ARREY"
  },
  "87931": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CABALLO"
  },
  "87933": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "DERRY"
  },
  "87935": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ELEPHANT BUTTE"
  },
  "87936": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "GARFIELD"
  },
  "87937": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "HATCH"
  },
  "87939": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MONTICELLO"
  },
  "87940": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "RINCON"
  },
  "87941": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SALEM"
  },
  "87942": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "WILLIAMSBURG"
  },
  "87943": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "WINSTON"
  },
  "88001": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LAS CRUCES"
  },
  "88002": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "WHITE SANDS MISSILE RANGE"
  },
  "88003": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LAS CRUCES"
  },
  "88005": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LAS CRUCES"
  },
  "88008": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SANTA TERESA"
  },
  "88009": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "PLAYAS"
  },
  "88011": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LAS CRUCES"
  },
  "88012": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LAS CRUCES"
  },
  "88020": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ANIMAS"
  },
  "88021": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ANTHONY"
  },
  "88022": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ARENAS VALLEY"
  },
  "88023": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "BAYARD"
  },
  "88024": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "BERINO"
  },
  "88025": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "BUCKHORN"
  },
  "88026": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SANTA CLARA"
  },
  "88027": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CHAMBERINO"
  },
  "88029": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "COLUMBUS"
  },
  "88030": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "DEMING"
  },
  "88033": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "FAIRACRES"
  },
  "88036": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "FORT BAYARD"
  },
  "88038": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "GILA"
  },
  "88039": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "GLENWOOD"
  },
  "88040": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "HACHITA"
  },
  "88041": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "HANOVER"
  },
  "88042": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "HILLSBORO"
  },
  "88043": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "HURLEY"
  },
  "88044": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LA MESA"
  },
  "88045": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LORDSBURG"
  },
  "88047": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MESILLA PARK"
  },
  "88048": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MESQUITE"
  },
  "88049": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MIMBRES"
  },
  "88051": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MULE CREEK"
  },
  "88052": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ORGAN"
  },
  "88053": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "PINOS ALTOS"
  },
  "88055": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "REDROCK"
  },
  "88056": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "RODEO"
  },
  "88061": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SILVER CITY"
  },
  "88063": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SUNLAND PARK"
  },
  "88065": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TYRONE"
  },
  "88072": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "VADO"
  },
  "88101": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CLOVIS"
  },
  "88112": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "BROADVIEW"
  },
  "88113": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CAUSEY"
  },
  "88114": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CROSSROADS"
  },
  "88115": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "DORA"
  },
  "88116": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ELIDA"
  },
  "88118": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "FLOYD"
  },
  "88119": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "FORT SUMNER"
  },
  "88120": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "GRADY"
  },
  "88121": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "HOUSE"
  },
  "88124": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MELROSE"
  },
  "88125": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MILNESAND"
  },
  "88126": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "PEP"
  },
  "88130": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "PORTALES"
  },
  "88132": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ROGERS"
  },
  "88134": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TAIBAN"
  },
  "88135": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TEXICO"
  },
  "88136": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "YESO"
  },
  "88201": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ROSWELL"
  },
  "88210": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ARTESIA"
  },
  "88213": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CAPROCK"
  },
  "88220": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CARLSBAD"
  },
  "88230": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "DEXTER"
  },
  "88231": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "EUNICE"
  },
  "88232": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "HAGERMAN"
  },
  "88240": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "HOBBS"
  },
  "88242": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "HOBBS"
  },
  "88250": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "HOPE"
  },
  "88252": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "JAL"
  },
  "88253": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LAKE ARTHUR"
  },
  "88254": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LAKEWOOD"
  },
  "88255": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LOCO HILLS"
  },
  "88256": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LOVING"
  },
  "88260": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LOVINGTON"
  },
  "88262": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MC DONALD"
  },
  "88263": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MALAGA"
  },
  "88265": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MONUMENT"
  },
  "88267": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TATUM"
  },
  "88268": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "WHITES CITY"
  },
  "88301": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CARRIZOZO"
  },
  "88310": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALAMOGORDO"
  },
  "88312": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ALTO"
  },
  "88314": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "BENT"
  },
  "88316": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CAPITAN"
  },
  "88317": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CLOUDCROFT"
  },
  "88318": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CORONA"
  },
  "88321": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "ENCINO"
  },
  "88323": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "FORT STANTON"
  },
  "88324": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "GLENCOE"
  },
  "88325": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "HIGH ROLLS MOUNTAIN PARK"
  },
  "88330": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "HOLLOMAN AIR FORCE BASE"
  },
  "88336": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "HONDO"
  },
  "88337": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LA LUZ"
  },
  "88338": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LINCOLN"
  },
  "88339": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MAYHILL"
  },
  "88340": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MESCALERO"
  },
  "88341": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "NOGAL"
  },
  "88343": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "PICACHO"
  },
  "88344": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "PINON"
  },
  "88345": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "RUIDOSO"
  },
  "88346": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "RUIDOSO DOWNS"
  },
  "88347": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SACRAMENTO"
  },
  "88348": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SAN PATRICIO"
  },
  "88350": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TIMBERON"
  },
  "88351": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TINNIE"
  },
  "88352": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TULAROSA"
  },
  "88353": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "VAUGHN"
  },
  "88354": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "WEED"
  },
  "88401": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "TUCUMCARI"
  },
  "88410": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "AMISTAD"
  },
  "88411": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "BARD"
  },
  "88414": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CAPULIN"
  },
  "88415": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CLAYTON"
  },
  "88416": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CONCHAS DAM"
  },
  "88417": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "CUERVO"
  },
  "88418": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "DES MOINES"
  },
  "88419": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "FOLSOM"
  },
  "88421": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "GARITA"
  },
  "88422": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "GLADSTONE"
  },
  "88424": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "GRENVILLE"
  },
  "88426": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "LOGAN"
  },
  "88427": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "MC ALISTER"
  },
  "88430": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "NARA VISA"
  },
  "88431": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "NEWKIRK"
  },
  "88434": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SAN JON"
  },
  "88435": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SANTA ROSA"
  },
  "88436": {
    stateCode: "NM",
    stateName: "New Mexico",
    city: "SEDAN"
  },
  "89001": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "ALAMO"
  },
  "89003": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "BEATTY"
  },
  "89004": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "BLUE DIAMOND"
  },
  "89005": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "BOULDER CITY"
  },
  "89007": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "BUNKERVILLE"
  },
  "89008": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "CALIENTE"
  },
  "89010": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "DYER"
  },
  "89011": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "HENDERSON"
  },
  "89012": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "HENDERSON"
  },
  "89013": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "GOLDFIELD"
  },
  "89014": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "HENDERSON"
  },
  "89015": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "HENDERSON"
  },
  "89017": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "HIKO"
  },
  "89018": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "INDIAN SPRINGS"
  },
  "89019": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "JEAN"
  },
  "89020": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "AMARGOSA VALLEY"
  },
  "89021": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LOGANDALE"
  },
  "89022": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "MANHATTAN"
  },
  "89024": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "MESQUITE"
  },
  "89025": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "MOAPA"
  },
  "89027": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "MESQUITE"
  },
  "89028": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAUGHLIN"
  },
  "89029": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAUGHLIN"
  },
  "89030": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "NORTH LAS VEGAS"
  },
  "89031": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "NORTH LAS VEGAS"
  },
  "89032": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "NORTH LAS VEGAS"
  },
  "89039": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "CAL NEV ARI"
  },
  "89040": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "OVERTON"
  },
  "89042": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "PANACA"
  },
  "89043": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "PIOCHE"
  },
  "89046": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "SEARCHLIGHT"
  },
  "89047": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "SILVERPEAK"
  },
  "89048": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "PAHRUMP"
  },
  "89049": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "TONOPAH"
  },
  "89052": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "HENDERSON"
  },
  "89101": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89102": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89103": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89104": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89106": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89107": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89108": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89109": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89110": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89113": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89115": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89117": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89118": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89119": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89120": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89121": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89122": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89123": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89124": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89128": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89129": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89130": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89131": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89134": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89135": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89139": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89141": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89142": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89143": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89144": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89145": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89146": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89147": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89148": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89149": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89156": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAS VEGAS"
  },
  "89191": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "NELLIS AFB"
  },
  "89301": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "ELY"
  },
  "89310": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "AUSTIN"
  },
  "89311": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "BAKER"
  },
  "89314": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "DUCKWATER"
  },
  "89316": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "EUREKA"
  },
  "89317": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LUND"
  },
  "89318": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "MC GILL"
  },
  "89403": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "DAYTON"
  },
  "89404": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "DENIO"
  },
  "89405": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "EMPIRE"
  },
  "89406": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "FALLON"
  },
  "89408": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "FERNLEY"
  },
  "89409": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "GABBS"
  },
  "89410": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "GARDNERVILLE"
  },
  "89411": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "GENOA"
  },
  "89413": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "GLENBROOK"
  },
  "89414": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "GOLCONDA"
  },
  "89415": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "HAWTHORNE"
  },
  "89418": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "IMLAY"
  },
  "89419": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LOVELOCK"
  },
  "89420": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LUNING"
  },
  "89421": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "MC DERMITT"
  },
  "89422": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "MINA"
  },
  "89423": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "MINDEN"
  },
  "89424": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "NIXON"
  },
  "89425": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "OROVADA"
  },
  "89426": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "PARADISE VALLEY"
  },
  "89427": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "SCHURZ"
  },
  "89428": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "SILVER CITY"
  },
  "89429": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "SILVER SPRINGS"
  },
  "89430": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "SMITH"
  },
  "89431": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "SPARKS"
  },
  "89433": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "SUN VALLEY"
  },
  "89434": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "SPARKS"
  },
  "89436": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "SPARKS"
  },
  "89439": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "VERDI"
  },
  "89440": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "VIRGINIA CITY"
  },
  "89442": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "WADSWORTH"
  },
  "89444": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "WELLINGTON"
  },
  "89445": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "WINNEMUCCA"
  },
  "89447": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "YERINGTON"
  },
  "89448": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "ZEPHYR COVE"
  },
  "89449": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "STATELINE"
  },
  "89451": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "INCLINE VILLAGE"
  },
  "89501": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "RENO"
  },
  "89502": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "RENO"
  },
  "89503": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "RENO"
  },
  "89506": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "RENO"
  },
  "89509": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "RENO"
  },
  "89510": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "RENO"
  },
  "89511": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "RENO"
  },
  "89512": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "RENO"
  },
  "89523": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "RENO"
  },
  "89701": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "CARSON CITY"
  },
  "89703": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "CARSON CITY"
  },
  "89704": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "WASHOE VALLEY"
  },
  "89705": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "CARSON CITY"
  },
  "89706": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "CARSON CITY"
  },
  "89801": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "ELKO"
  },
  "89803": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "ELKO"
  },
  "89815": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "SPRING CREEK"
  },
  "89820": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "BATTLE MOUNTAIN"
  },
  "89821": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "CRESCENT VALLEY"
  },
  "89822": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "CARLIN"
  },
  "89823": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "DEETH"
  },
  "89824": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "HALLECK"
  },
  "89825": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "JACKPOT"
  },
  "89826": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "JARBIDGE"
  },
  "89828": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "LAMOILLE"
  },
  "89830": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "MONTELLO"
  },
  "89831": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "MOUNTAIN CITY"
  },
  "89832": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "OWYHEE"
  },
  "89833": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "RUBY VALLEY"
  },
  "89834": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "TUSCARORA"
  },
  "89835": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "WELLS"
  },
  "89883": {
    stateCode: "NV",
    stateName: "Nevada",
    city: "WEST WENDOVER"
  },
  "90001": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90002": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90003": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90004": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90005": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90006": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90007": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90008": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90010": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90011": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90012": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90013": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90014": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90015": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90016": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90017": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90018": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90019": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90020": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90021": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90022": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90023": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90024": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90025": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90026": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90027": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90028": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90029": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90031": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90032": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90033": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90034": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90035": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90036": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90037": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90038": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90039": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90040": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90041": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90042": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90043": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90044": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90045": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90046": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90047": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90048": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90049": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90056": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90057": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90058": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90059": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90061": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90062": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90063": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90064": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90065": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90066": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90067": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90068": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90069": {
    stateCode: "CA",
    stateName: "California",
    city: "WEST HOLLYWOOD"
  },
  "90077": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ANGELES"
  },
  "90201": {
    stateCode: "CA",
    stateName: "California",
    city: "BELL"
  },
  "90210": {
    stateCode: "CA",
    stateName: "California",
    city: "BEVERLY HILLS"
  },
  "90211": {
    stateCode: "CA",
    stateName: "California",
    city: "BEVERLY HILLS"
  },
  "90212": {
    stateCode: "CA",
    stateName: "California",
    city: "BEVERLY HILLS"
  },
  "90220": {
    stateCode: "CA",
    stateName: "California",
    city: "COMPTON"
  },
  "90221": {
    stateCode: "CA",
    stateName: "California",
    city: "COMPTON"
  },
  "90222": {
    stateCode: "CA",
    stateName: "California",
    city: "COMPTON"
  },
  "90230": {
    stateCode: "CA",
    stateName: "California",
    city: "CULVER CITY"
  },
  "90232": {
    stateCode: "CA",
    stateName: "California",
    city: "CULVER CITY"
  },
  "90240": {
    stateCode: "CA",
    stateName: "California",
    city: "DOWNEY"
  },
  "90241": {
    stateCode: "CA",
    stateName: "California",
    city: "DOWNEY"
  },
  "90242": {
    stateCode: "CA",
    stateName: "California",
    city: "DOWNEY"
  },
  "90245": {
    stateCode: "CA",
    stateName: "California",
    city: "EL SEGUNDO"
  },
  "90247": {
    stateCode: "CA",
    stateName: "California",
    city: "GARDENA"
  },
  "90248": {
    stateCode: "CA",
    stateName: "California",
    city: "GARDENA"
  },
  "90249": {
    stateCode: "CA",
    stateName: "California",
    city: "GARDENA"
  },
  "90250": {
    stateCode: "CA",
    stateName: "California",
    city: "HAWTHORNE"
  },
  "90254": {
    stateCode: "CA",
    stateName: "California",
    city: "HERMOSA BEACH"
  },
  "90255": {
    stateCode: "CA",
    stateName: "California",
    city: "HUNTINGTON PARK"
  },
  "90260": {
    stateCode: "CA",
    stateName: "California",
    city: "LAWNDALE"
  },
  "90262": {
    stateCode: "CA",
    stateName: "California",
    city: "LYNWOOD"
  },
  "90265": {
    stateCode: "CA",
    stateName: "California",
    city: "MALIBU"
  },
  "90266": {
    stateCode: "CA",
    stateName: "California",
    city: "MANHATTAN BEACH"
  },
  "90270": {
    stateCode: "CA",
    stateName: "California",
    city: "MAYWOOD"
  },
  "90272": {
    stateCode: "CA",
    stateName: "California",
    city: "PACIFIC PALISADES"
  },
  "90274": {
    stateCode: "CA",
    stateName: "California",
    city: "PALOS VERDES PENINSULA"
  },
  "90275": {
    stateCode: "CA",
    stateName: "California",
    city: "RANCHO PALOS VERDES"
  },
  "90277": {
    stateCode: "CA",
    stateName: "California",
    city: "REDONDO BEACH"
  },
  "90278": {
    stateCode: "CA",
    stateName: "California",
    city: "REDONDO BEACH"
  },
  "90280": {
    stateCode: "CA",
    stateName: "California",
    city: "SOUTH GATE"
  },
  "90290": {
    stateCode: "CA",
    stateName: "California",
    city: "TOPANGA"
  },
  "90291": {
    stateCode: "CA",
    stateName: "California",
    city: "VENICE"
  },
  "90292": {
    stateCode: "CA",
    stateName: "California",
    city: "MARINA DEL REY"
  },
  "90293": {
    stateCode: "CA",
    stateName: "California",
    city: "PLAYA DEL REY"
  },
  "90301": {
    stateCode: "CA",
    stateName: "California",
    city: "INGLEWOOD"
  },
  "90302": {
    stateCode: "CA",
    stateName: "California",
    city: "INGLEWOOD"
  },
  "90303": {
    stateCode: "CA",
    stateName: "California",
    city: "INGLEWOOD"
  },
  "90304": {
    stateCode: "CA",
    stateName: "California",
    city: "INGLEWOOD"
  },
  "90305": {
    stateCode: "CA",
    stateName: "California",
    city: "INGLEWOOD"
  },
  "90401": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA MONICA"
  },
  "90402": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA MONICA"
  },
  "90403": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA MONICA"
  },
  "90404": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA MONICA"
  },
  "90405": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA MONICA"
  },
  "90501": {
    stateCode: "CA",
    stateName: "California",
    city: "TORRANCE"
  },
  "90502": {
    stateCode: "CA",
    stateName: "California",
    city: "TORRANCE"
  },
  "90503": {
    stateCode: "CA",
    stateName: "California",
    city: "TORRANCE"
  },
  "90504": {
    stateCode: "CA",
    stateName: "California",
    city: "TORRANCE"
  },
  "90505": {
    stateCode: "CA",
    stateName: "California",
    city: "TORRANCE"
  },
  "90601": {
    stateCode: "CA",
    stateName: "California",
    city: "WHITTIER"
  },
  "90602": {
    stateCode: "CA",
    stateName: "California",
    city: "WHITTIER"
  },
  "90603": {
    stateCode: "CA",
    stateName: "California",
    city: "WHITTIER"
  },
  "90604": {
    stateCode: "CA",
    stateName: "California",
    city: "WHITTIER"
  },
  "90605": {
    stateCode: "CA",
    stateName: "California",
    city: "WHITTIER"
  },
  "90606": {
    stateCode: "CA",
    stateName: "California",
    city: "WHITTIER"
  },
  "90620": {
    stateCode: "CA",
    stateName: "California",
    city: "BUENA PARK"
  },
  "90621": {
    stateCode: "CA",
    stateName: "California",
    city: "BUENA PARK"
  },
  "90623": {
    stateCode: "CA",
    stateName: "California",
    city: "LA PALMA"
  },
  "90630": {
    stateCode: "CA",
    stateName: "California",
    city: "CYPRESS"
  },
  "90631": {
    stateCode: "CA",
    stateName: "California",
    city: "LA HABRA"
  },
  "90638": {
    stateCode: "CA",
    stateName: "California",
    city: "LA MIRADA"
  },
  "90640": {
    stateCode: "CA",
    stateName: "California",
    city: "MONTEBELLO"
  },
  "90650": {
    stateCode: "CA",
    stateName: "California",
    city: "NORWALK"
  },
  "90660": {
    stateCode: "CA",
    stateName: "California",
    city: "PICO RIVERA"
  },
  "90670": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA FE SPRINGS"
  },
  "90680": {
    stateCode: "CA",
    stateName: "California",
    city: "STANTON"
  },
  "90701": {
    stateCode: "CA",
    stateName: "California",
    city: "ARTESIA"
  },
  "90703": {
    stateCode: "CA",
    stateName: "California",
    city: "CERRITOS"
  },
  "90704": {
    stateCode: "CA",
    stateName: "California",
    city: "AVALON"
  },
  "90706": {
    stateCode: "CA",
    stateName: "California",
    city: "BELLFLOWER"
  },
  "90710": {
    stateCode: "CA",
    stateName: "California",
    city: "HARBOR CITY"
  },
  "90712": {
    stateCode: "CA",
    stateName: "California",
    city: "LAKEWOOD"
  },
  "90713": {
    stateCode: "CA",
    stateName: "California",
    city: "LAKEWOOD"
  },
  "90715": {
    stateCode: "CA",
    stateName: "California",
    city: "LAKEWOOD"
  },
  "90716": {
    stateCode: "CA",
    stateName: "California",
    city: "HAWAIIAN GARDENS"
  },
  "90717": {
    stateCode: "CA",
    stateName: "California",
    city: "LOMITA"
  },
  "90720": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ALAMITOS"
  },
  "90723": {
    stateCode: "CA",
    stateName: "California",
    city: "PARAMOUNT"
  },
  "90731": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN PEDRO"
  },
  "90732": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN PEDRO"
  },
  "90740": {
    stateCode: "CA",
    stateName: "California",
    city: "SEAL BEACH"
  },
  "90742": {
    stateCode: "CA",
    stateName: "California",
    city: "SUNSET BEACH"
  },
  "90743": {
    stateCode: "CA",
    stateName: "California",
    city: "SURFSIDE"
  },
  "90744": {
    stateCode: "CA",
    stateName: "California",
    city: "WILMINGTON"
  },
  "90745": {
    stateCode: "CA",
    stateName: "California",
    city: "CARSON"
  },
  "90746": {
    stateCode: "CA",
    stateName: "California",
    city: "CARSON"
  },
  "90802": {
    stateCode: "CA",
    stateName: "California",
    city: "LONG BEACH"
  },
  "90803": {
    stateCode: "CA",
    stateName: "California",
    city: "LONG BEACH"
  },
  "90804": {
    stateCode: "CA",
    stateName: "California",
    city: "LONG BEACH"
  },
  "90805": {
    stateCode: "CA",
    stateName: "California",
    city: "LONG BEACH"
  },
  "90806": {
    stateCode: "CA",
    stateName: "California",
    city: "LONG BEACH"
  },
  "90807": {
    stateCode: "CA",
    stateName: "California",
    city: "LONG BEACH"
  },
  "90808": {
    stateCode: "CA",
    stateName: "California",
    city: "LONG BEACH"
  },
  "90810": {
    stateCode: "CA",
    stateName: "California",
    city: "LONG BEACH"
  },
  "90813": {
    stateCode: "CA",
    stateName: "California",
    city: "LONG BEACH"
  },
  "90814": {
    stateCode: "CA",
    stateName: "California",
    city: "LONG BEACH"
  },
  "90815": {
    stateCode: "CA",
    stateName: "California",
    city: "LONG BEACH"
  },
  "91001": {
    stateCode: "CA",
    stateName: "California",
    city: "ALTADENA"
  },
  "91006": {
    stateCode: "CA",
    stateName: "California",
    city: "ARCADIA"
  },
  "91007": {
    stateCode: "CA",
    stateName: "California",
    city: "ARCADIA"
  },
  "91010": {
    stateCode: "CA",
    stateName: "California",
    city: "DUARTE"
  },
  "91011": {
    stateCode: "CA",
    stateName: "California",
    city: "LA CANADA FLINTRIDGE"
  },
  "91016": {
    stateCode: "CA",
    stateName: "California",
    city: "MONROVIA"
  },
  "91020": {
    stateCode: "CA",
    stateName: "California",
    city: "MONTROSE"
  },
  "91024": {
    stateCode: "CA",
    stateName: "California",
    city: "SIERRA MADRE"
  },
  "91030": {
    stateCode: "CA",
    stateName: "California",
    city: "SOUTH PASADENA"
  },
  "91040": {
    stateCode: "CA",
    stateName: "California",
    city: "SUNLAND"
  },
  "91042": {
    stateCode: "CA",
    stateName: "California",
    city: "TUJUNGA"
  },
  "91101": {
    stateCode: "CA",
    stateName: "California",
    city: "PASADENA"
  },
  "91103": {
    stateCode: "CA",
    stateName: "California",
    city: "PASADENA"
  },
  "91104": {
    stateCode: "CA",
    stateName: "California",
    city: "PASADENA"
  },
  "91105": {
    stateCode: "CA",
    stateName: "California",
    city: "PASADENA"
  },
  "91106": {
    stateCode: "CA",
    stateName: "California",
    city: "PASADENA"
  },
  "91107": {
    stateCode: "CA",
    stateName: "California",
    city: "PASADENA"
  },
  "91108": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN MARINO"
  },
  "91201": {
    stateCode: "CA",
    stateName: "California",
    city: "GLENDALE"
  },
  "91202": {
    stateCode: "CA",
    stateName: "California",
    city: "GLENDALE"
  },
  "91203": {
    stateCode: "CA",
    stateName: "California",
    city: "GLENDALE"
  },
  "91204": {
    stateCode: "CA",
    stateName: "California",
    city: "GLENDALE"
  },
  "91205": {
    stateCode: "CA",
    stateName: "California",
    city: "GLENDALE"
  },
  "91206": {
    stateCode: "CA",
    stateName: "California",
    city: "GLENDALE"
  },
  "91207": {
    stateCode: "CA",
    stateName: "California",
    city: "GLENDALE"
  },
  "91208": {
    stateCode: "CA",
    stateName: "California",
    city: "GLENDALE"
  },
  "91214": {
    stateCode: "CA",
    stateName: "California",
    city: "LA CRESCENTA"
  },
  "91301": {
    stateCode: "CA",
    stateName: "California",
    city: "AGOURA HILLS"
  },
  "91302": {
    stateCode: "CA",
    stateName: "California",
    city: "CALABASAS"
  },
  "91303": {
    stateCode: "CA",
    stateName: "California",
    city: "CANOGA PARK"
  },
  "91304": {
    stateCode: "CA",
    stateName: "California",
    city: "CANOGA PARK"
  },
  "91306": {
    stateCode: "CA",
    stateName: "California",
    city: "WINNETKA"
  },
  "91307": {
    stateCode: "CA",
    stateName: "California",
    city: "WEST HILLS"
  },
  "91311": {
    stateCode: "CA",
    stateName: "California",
    city: "CHATSWORTH"
  },
  "91316": {
    stateCode: "CA",
    stateName: "California",
    city: "ENCINO"
  },
  "91320": {
    stateCode: "CA",
    stateName: "California",
    city: "NEWBURY PARK"
  },
  "91321": {
    stateCode: "CA",
    stateName: "California",
    city: "NEWHALL"
  },
  "91324": {
    stateCode: "CA",
    stateName: "California",
    city: "NORTHRIDGE"
  },
  "91325": {
    stateCode: "CA",
    stateName: "California",
    city: "NORTHRIDGE"
  },
  "91326": {
    stateCode: "CA",
    stateName: "California",
    city: "PORTER RANCH"
  },
  "91331": {
    stateCode: "CA",
    stateName: "California",
    city: "PACOIMA"
  },
  "91335": {
    stateCode: "CA",
    stateName: "California",
    city: "RESEDA"
  },
  "91340": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FERNANDO"
  },
  "91342": {
    stateCode: "CA",
    stateName: "California",
    city: "SYLMAR"
  },
  "91343": {
    stateCode: "CA",
    stateName: "California",
    city: "NORTH HILLS"
  },
  "91344": {
    stateCode: "CA",
    stateName: "California",
    city: "GRANADA HILLS"
  },
  "91345": {
    stateCode: "CA",
    stateName: "California",
    city: "MISSION HILLS"
  },
  "91350": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA CLARITA"
  },
  "91351": {
    stateCode: "CA",
    stateName: "California",
    city: "CANYON COUNTRY"
  },
  "91352": {
    stateCode: "CA",
    stateName: "California",
    city: "SUN VALLEY"
  },
  "91354": {
    stateCode: "CA",
    stateName: "California",
    city: "VALENCIA"
  },
  "91355": {
    stateCode: "CA",
    stateName: "California",
    city: "VALENCIA"
  },
  "91356": {
    stateCode: "CA",
    stateName: "California",
    city: "TARZANA"
  },
  "91360": {
    stateCode: "CA",
    stateName: "California",
    city: "THOUSAND OAKS"
  },
  "91361": {
    stateCode: "CA",
    stateName: "California",
    city: "WESTLAKE VILLAGE"
  },
  "91362": {
    stateCode: "CA",
    stateName: "California",
    city: "THOUSAND OAKS"
  },
  "91364": {
    stateCode: "CA",
    stateName: "California",
    city: "WOODLAND HILLS"
  },
  "91367": {
    stateCode: "CA",
    stateName: "California",
    city: "WOODLAND HILLS"
  },
  "91377": {
    stateCode: "CA",
    stateName: "California",
    city: "OAK PARK"
  },
  "91381": {
    stateCode: "CA",
    stateName: "California",
    city: "STEVENSON RANCH"
  },
  "91384": {
    stateCode: "CA",
    stateName: "California",
    city: "CASTAIC"
  },
  "91401": {
    stateCode: "CA",
    stateName: "California",
    city: "VAN NUYS"
  },
  "91402": {
    stateCode: "CA",
    stateName: "California",
    city: "PANORAMA CITY"
  },
  "91403": {
    stateCode: "CA",
    stateName: "California",
    city: "SHERMAN OAKS"
  },
  "91405": {
    stateCode: "CA",
    stateName: "California",
    city: "VAN NUYS"
  },
  "91406": {
    stateCode: "CA",
    stateName: "California",
    city: "VAN NUYS"
  },
  "91411": {
    stateCode: "CA",
    stateName: "California",
    city: "VAN NUYS"
  },
  "91423": {
    stateCode: "CA",
    stateName: "California",
    city: "SHERMAN OAKS"
  },
  "91436": {
    stateCode: "CA",
    stateName: "California",
    city: "ENCINO"
  },
  "91501": {
    stateCode: "CA",
    stateName: "California",
    city: "BURBANK"
  },
  "91502": {
    stateCode: "CA",
    stateName: "California",
    city: "BURBANK"
  },
  "91504": {
    stateCode: "CA",
    stateName: "California",
    city: "BURBANK"
  },
  "91505": {
    stateCode: "CA",
    stateName: "California",
    city: "BURBANK"
  },
  "91506": {
    stateCode: "CA",
    stateName: "California",
    city: "BURBANK"
  },
  "91601": {
    stateCode: "CA",
    stateName: "California",
    city: "NORTH HOLLYWOOD"
  },
  "91602": {
    stateCode: "CA",
    stateName: "California",
    city: "NORTH HOLLYWOOD"
  },
  "91604": {
    stateCode: "CA",
    stateName: "California",
    city: "STUDIO CITY"
  },
  "91605": {
    stateCode: "CA",
    stateName: "California",
    city: "NORTH HOLLYWOOD"
  },
  "91606": {
    stateCode: "CA",
    stateName: "California",
    city: "NORTH HOLLYWOOD"
  },
  "91607": {
    stateCode: "CA",
    stateName: "California",
    city: "VALLEY VILLAGE"
  },
  "91701": {
    stateCode: "CA",
    stateName: "California",
    city: "RANCHO CUCAMONGA"
  },
  "91702": {
    stateCode: "CA",
    stateName: "California",
    city: "AZUSA"
  },
  "91706": {
    stateCode: "CA",
    stateName: "California",
    city: "BALDWIN PARK"
  },
  "91709": {
    stateCode: "CA",
    stateName: "California",
    city: "CHINO HILLS"
  },
  "91710": {
    stateCode: "CA",
    stateName: "California",
    city: "CHINO"
  },
  "91711": {
    stateCode: "CA",
    stateName: "California",
    city: "CLAREMONT"
  },
  "91722": {
    stateCode: "CA",
    stateName: "California",
    city: "COVINA"
  },
  "91723": {
    stateCode: "CA",
    stateName: "California",
    city: "COVINA"
  },
  "91724": {
    stateCode: "CA",
    stateName: "California",
    city: "COVINA"
  },
  "91730": {
    stateCode: "CA",
    stateName: "California",
    city: "RANCHO CUCAMONGA"
  },
  "91731": {
    stateCode: "CA",
    stateName: "California",
    city: "EL MONTE"
  },
  "91732": {
    stateCode: "CA",
    stateName: "California",
    city: "EL MONTE"
  },
  "91733": {
    stateCode: "CA",
    stateName: "California",
    city: "SOUTH EL MONTE"
  },
  "91737": {
    stateCode: "CA",
    stateName: "California",
    city: "RANCHO CUCAMONGA"
  },
  "91739": {
    stateCode: "CA",
    stateName: "California",
    city: "RANCHO CUCAMONGA"
  },
  "91740": {
    stateCode: "CA",
    stateName: "California",
    city: "GLENDORA"
  },
  "91741": {
    stateCode: "CA",
    stateName: "California",
    city: "GLENDORA"
  },
  "91744": {
    stateCode: "CA",
    stateName: "California",
    city: "LA PUENTE"
  },
  "91745": {
    stateCode: "CA",
    stateName: "California",
    city: "HACIENDA HEIGHTS"
  },
  "91746": {
    stateCode: "CA",
    stateName: "California",
    city: "LA PUENTE"
  },
  "91748": {
    stateCode: "CA",
    stateName: "California",
    city: "ROWLAND HEIGHTS"
  },
  "91750": {
    stateCode: "CA",
    stateName: "California",
    city: "LA VERNE"
  },
  "91752": {
    stateCode: "CA",
    stateName: "California",
    city: "MIRA LOMA"
  },
  "91754": {
    stateCode: "CA",
    stateName: "California",
    city: "MONTEREY PARK"
  },
  "91755": {
    stateCode: "CA",
    stateName: "California",
    city: "MONTEREY PARK"
  },
  "91759": {
    stateCode: "CA",
    stateName: "California",
    city: "MT BALDY"
  },
  "91761": {
    stateCode: "CA",
    stateName: "California",
    city: "ONTARIO"
  },
  "91762": {
    stateCode: "CA",
    stateName: "California",
    city: "ONTARIO"
  },
  "91763": {
    stateCode: "CA",
    stateName: "California",
    city: "MONTCLAIR"
  },
  "91764": {
    stateCode: "CA",
    stateName: "California",
    city: "ONTARIO"
  },
  "91765": {
    stateCode: "CA",
    stateName: "California",
    city: "DIAMOND BAR"
  },
  "91766": {
    stateCode: "CA",
    stateName: "California",
    city: "POMONA"
  },
  "91767": {
    stateCode: "CA",
    stateName: "California",
    city: "POMONA"
  },
  "91768": {
    stateCode: "CA",
    stateName: "California",
    city: "POMONA"
  },
  "91770": {
    stateCode: "CA",
    stateName: "California",
    city: "ROSEMEAD"
  },
  "91773": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIMAS"
  },
  "91775": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN GABRIEL"
  },
  "91776": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN GABRIEL"
  },
  "91780": {
    stateCode: "CA",
    stateName: "California",
    city: "TEMPLE CITY"
  },
  "91784": {
    stateCode: "CA",
    stateName: "California",
    city: "UPLAND"
  },
  "91786": {
    stateCode: "CA",
    stateName: "California",
    city: "UPLAND"
  },
  "91789": {
    stateCode: "CA",
    stateName: "California",
    city: "WALNUT"
  },
  "91790": {
    stateCode: "CA",
    stateName: "California",
    city: "WEST COVINA"
  },
  "91791": {
    stateCode: "CA",
    stateName: "California",
    city: "WEST COVINA"
  },
  "91792": {
    stateCode: "CA",
    stateName: "California",
    city: "WEST COVINA"
  },
  "91801": {
    stateCode: "CA",
    stateName: "California",
    city: "ALHAMBRA"
  },
  "91803": {
    stateCode: "CA",
    stateName: "California",
    city: "ALHAMBRA"
  },
  "91901": {
    stateCode: "CA",
    stateName: "California",
    city: "ALPINE"
  },
  "91902": {
    stateCode: "CA",
    stateName: "California",
    city: "BONITA"
  },
  "91905": {
    stateCode: "CA",
    stateName: "California",
    city: "BOULEVARD"
  },
  "91906": {
    stateCode: "CA",
    stateName: "California",
    city: "CAMPO"
  },
  "91910": {
    stateCode: "CA",
    stateName: "California",
    city: "CHULA VISTA"
  },
  "91911": {
    stateCode: "CA",
    stateName: "California",
    city: "CHULA VISTA"
  },
  "91913": {
    stateCode: "CA",
    stateName: "California",
    city: "CHULA VISTA"
  },
  "91914": {
    stateCode: "CA",
    stateName: "California",
    city: "CHULA VISTA"
  },
  "91915": {
    stateCode: "CA",
    stateName: "California",
    city: "CHULA VISTA"
  },
  "91916": {
    stateCode: "CA",
    stateName: "California",
    city: "DESCANSO"
  },
  "91917": {
    stateCode: "CA",
    stateName: "California",
    city: "DULZURA"
  },
  "91931": {
    stateCode: "CA",
    stateName: "California",
    city: "GUATAY"
  },
  "91932": {
    stateCode: "CA",
    stateName: "California",
    city: "IMPERIAL BEACH"
  },
  "91934": {
    stateCode: "CA",
    stateName: "California",
    city: "JACUMBA"
  },
  "91935": {
    stateCode: "CA",
    stateName: "California",
    city: "JAMUL"
  },
  "91941": {
    stateCode: "CA",
    stateName: "California",
    city: "LA MESA"
  },
  "91942": {
    stateCode: "CA",
    stateName: "California",
    city: "LA MESA"
  },
  "91945": {
    stateCode: "CA",
    stateName: "California",
    city: "LEMON GROVE"
  },
  "91948": {
    stateCode: "CA",
    stateName: "California",
    city: "MOUNT LAGUNA"
  },
  "91950": {
    stateCode: "CA",
    stateName: "California",
    city: "NATIONAL CITY"
  },
  "91962": {
    stateCode: "CA",
    stateName: "California",
    city: "PINE VALLEY"
  },
  "91963": {
    stateCode: "CA",
    stateName: "California",
    city: "POTRERO"
  },
  "91977": {
    stateCode: "CA",
    stateName: "California",
    city: "SPRING VALLEY"
  },
  "91978": {
    stateCode: "CA",
    stateName: "California",
    city: "SPRING VALLEY"
  },
  "91980": {
    stateCode: "CA",
    stateName: "California",
    city: "TECATE"
  },
  "92003": {
    stateCode: "CA",
    stateName: "California",
    city: "BONSALL"
  },
  "92004": {
    stateCode: "CA",
    stateName: "California",
    city: "BORREGO SPRINGS"
  },
  "92007": {
    stateCode: "CA",
    stateName: "California",
    city: "CARDIFF BY THE SEA"
  },
  "92008": {
    stateCode: "CA",
    stateName: "California",
    city: "CARLSBAD"
  },
  "92009": {
    stateCode: "CA",
    stateName: "California",
    city: "CARLSBAD"
  },
  "92014": {
    stateCode: "CA",
    stateName: "California",
    city: "DEL MAR"
  },
  "92019": {
    stateCode: "CA",
    stateName: "California",
    city: "EL CAJON"
  },
  "92020": {
    stateCode: "CA",
    stateName: "California",
    city: "EL CAJON"
  },
  "92021": {
    stateCode: "CA",
    stateName: "California",
    city: "EL CAJON"
  },
  "92024": {
    stateCode: "CA",
    stateName: "California",
    city: "ENCINITAS"
  },
  "92025": {
    stateCode: "CA",
    stateName: "California",
    city: "ESCONDIDO"
  },
  "92026": {
    stateCode: "CA",
    stateName: "California",
    city: "ESCONDIDO"
  },
  "92027": {
    stateCode: "CA",
    stateName: "California",
    city: "ESCONDIDO"
  },
  "92028": {
    stateCode: "CA",
    stateName: "California",
    city: "FALLBROOK"
  },
  "92029": {
    stateCode: "CA",
    stateName: "California",
    city: "ESCONDIDO"
  },
  "92036": {
    stateCode: "CA",
    stateName: "California",
    city: "JULIAN"
  },
  "92037": {
    stateCode: "CA",
    stateName: "California",
    city: "LA JOLLA"
  },
  "92040": {
    stateCode: "CA",
    stateName: "California",
    city: "LAKESIDE"
  },
  "92054": {
    stateCode: "CA",
    stateName: "California",
    city: "OCEANSIDE"
  },
  "92056": {
    stateCode: "CA",
    stateName: "California",
    city: "OCEANSIDE"
  },
  "92057": {
    stateCode: "CA",
    stateName: "California",
    city: "OCEANSIDE"
  },
  "92059": {
    stateCode: "CA",
    stateName: "California",
    city: "PALA"
  },
  "92060": {
    stateCode: "CA",
    stateName: "California",
    city: "PALOMAR MOUNTAIN"
  },
  "92061": {
    stateCode: "CA",
    stateName: "California",
    city: "PAUMA VALLEY"
  },
  "92064": {
    stateCode: "CA",
    stateName: "California",
    city: "POWAY"
  },
  "92065": {
    stateCode: "CA",
    stateName: "California",
    city: "RAMONA"
  },
  "92066": {
    stateCode: "CA",
    stateName: "California",
    city: "RANCHITA"
  },
  "92067": {
    stateCode: "CA",
    stateName: "California",
    city: "RANCHO SANTA FE"
  },
  "92069": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN MARCOS"
  },
  "92070": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA YSABEL"
  },
  "92071": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTEE"
  },
  "92075": {
    stateCode: "CA",
    stateName: "California",
    city: "SOLANA BEACH"
  },
  "92078": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN MARCOS"
  },
  "92082": {
    stateCode: "CA",
    stateName: "California",
    city: "VALLEY CENTER"
  },
  "92083": {
    stateCode: "CA",
    stateName: "California",
    city: "VISTA"
  },
  "92084": {
    stateCode: "CA",
    stateName: "California",
    city: "VISTA"
  },
  "92086": {
    stateCode: "CA",
    stateName: "California",
    city: "WARNER SPRINGS"
  },
  "92091": {
    stateCode: "CA",
    stateName: "California",
    city: "RANCHO SANTA FE"
  },
  "92101": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92102": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92103": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92104": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92105": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92106": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92107": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92108": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92109": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92110": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92111": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92113": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92114": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92115": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92116": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92117": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92118": {
    stateCode: "CA",
    stateName: "California",
    city: "CORONADO"
  },
  "92119": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92120": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92121": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92122": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92123": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92124": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92126": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92127": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92128": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92129": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92130": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92131": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92139": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92154": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN DIEGO"
  },
  "92173": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN YSIDRO"
  },
  "92201": {
    stateCode: "CA",
    stateName: "California",
    city: "INDIO"
  },
  "92203": {
    stateCode: "CA",
    stateName: "California",
    city: "INDIO"
  },
  "92210": {
    stateCode: "CA",
    stateName: "California",
    city: "INDIAN WELLS"
  },
  "92211": {
    stateCode: "CA",
    stateName: "California",
    city: "PALM DESERT"
  },
  "92220": {
    stateCode: "CA",
    stateName: "California",
    city: "BANNING"
  },
  "92223": {
    stateCode: "CA",
    stateName: "California",
    city: "BEAUMONT"
  },
  "92225": {
    stateCode: "CA",
    stateName: "California",
    city: "BLYTHE"
  },
  "92227": {
    stateCode: "CA",
    stateName: "California",
    city: "BRAWLEY"
  },
  "92230": {
    stateCode: "CA",
    stateName: "California",
    city: "CABAZON"
  },
  "92231": {
    stateCode: "CA",
    stateName: "California",
    city: "CALEXICO"
  },
  "92233": {
    stateCode: "CA",
    stateName: "California",
    city: "CALIPATRIA"
  },
  "92234": {
    stateCode: "CA",
    stateName: "California",
    city: "CATHEDRAL CITY"
  },
  "92236": {
    stateCode: "CA",
    stateName: "California",
    city: "COACHELLA"
  },
  "92239": {
    stateCode: "CA",
    stateName: "California",
    city: "DESERT CENTER"
  },
  "92240": {
    stateCode: "CA",
    stateName: "California",
    city: "DESERT HOT SPRINGS"
  },
  "92241": {
    stateCode: "CA",
    stateName: "California",
    city: "DESERT HOT SPRINGS"
  },
  "92242": {
    stateCode: "CA",
    stateName: "California",
    city: "EARP"
  },
  "92243": {
    stateCode: "CA",
    stateName: "California",
    city: "EL CENTRO"
  },
  "92249": {
    stateCode: "CA",
    stateName: "California",
    city: "HEBER"
  },
  "92250": {
    stateCode: "CA",
    stateName: "California",
    city: "HOLTVILLE"
  },
  "92251": {
    stateCode: "CA",
    stateName: "California",
    city: "IMPERIAL"
  },
  "92252": {
    stateCode: "CA",
    stateName: "California",
    city: "JOSHUA TREE"
  },
  "92253": {
    stateCode: "CA",
    stateName: "California",
    city: "LA QUINTA"
  },
  "92254": {
    stateCode: "CA",
    stateName: "California",
    city: "MECCA"
  },
  "92256": {
    stateCode: "CA",
    stateName: "California",
    city: "MORONGO VALLEY"
  },
  "92257": {
    stateCode: "CA",
    stateName: "California",
    city: "NILAND"
  },
  "92258": {
    stateCode: "CA",
    stateName: "California",
    city: "NORTH PALM SPRINGS"
  },
  "92259": {
    stateCode: "CA",
    stateName: "California",
    city: "OCOTILLO"
  },
  "92260": {
    stateCode: "CA",
    stateName: "California",
    city: "PALM DESERT"
  },
  "92262": {
    stateCode: "CA",
    stateName: "California",
    city: "PALM SPRINGS"
  },
  "92264": {
    stateCode: "CA",
    stateName: "California",
    city: "PALM SPRINGS"
  },
  "92266": {
    stateCode: "CA",
    stateName: "California",
    city: "PALO VERDE"
  },
  "92267": {
    stateCode: "CA",
    stateName: "California",
    city: "PARKER DAM"
  },
  "92268": {
    stateCode: "CA",
    stateName: "California",
    city: "PIONEERTOWN"
  },
  "92270": {
    stateCode: "CA",
    stateName: "California",
    city: "RANCHO MIRAGE"
  },
  "92273": {
    stateCode: "CA",
    stateName: "California",
    city: "SEELEY"
  },
  "92274": {
    stateCode: "CA",
    stateName: "California",
    city: "THERMAL"
  },
  "92275": {
    stateCode: "CA",
    stateName: "California",
    city: "SALTON CITY"
  },
  "92276": {
    stateCode: "CA",
    stateName: "California",
    city: "THOUSAND PALMS"
  },
  "92277": {
    stateCode: "CA",
    stateName: "California",
    city: "TWENTYNINE PALMS"
  },
  "92278": {
    stateCode: "CA",
    stateName: "California",
    city: "TWENTYNINE PALMS"
  },
  "92280": {
    stateCode: "CA",
    stateName: "California",
    city: "VIDAL"
  },
  "92281": {
    stateCode: "CA",
    stateName: "California",
    city: "WESTMORLAND"
  },
  "92282": {
    stateCode: "CA",
    stateName: "California",
    city: "WHITE WATER"
  },
  "92283": {
    stateCode: "CA",
    stateName: "California",
    city: "WINTERHAVEN"
  },
  "92284": {
    stateCode: "CA",
    stateName: "California",
    city: "YUCCA VALLEY"
  },
  "92285": {
    stateCode: "CA",
    stateName: "California",
    city: "LANDERS"
  },
  "92301": {
    stateCode: "CA",
    stateName: "California",
    city: "ADELANTO"
  },
  "92304": {
    stateCode: "CA",
    stateName: "California",
    city: "AMBOY"
  },
  "92305": {
    stateCode: "CA",
    stateName: "California",
    city: "ANGELUS OAKS"
  },
  "92307": {
    stateCode: "CA",
    stateName: "California",
    city: "APPLE VALLEY"
  },
  "92308": {
    stateCode: "CA",
    stateName: "California",
    city: "APPLE VALLEY"
  },
  "92309": {
    stateCode: "CA",
    stateName: "California",
    city: "BAKER"
  },
  "92310": {
    stateCode: "CA",
    stateName: "California",
    city: "FORT IRWIN"
  },
  "92311": {
    stateCode: "CA",
    stateName: "California",
    city: "BARSTOW"
  },
  "92313": {
    stateCode: "CA",
    stateName: "California",
    city: "GRAND TERRACE"
  },
  "92314": {
    stateCode: "CA",
    stateName: "California",
    city: "BIG BEAR CITY"
  },
  "92315": {
    stateCode: "CA",
    stateName: "California",
    city: "BIG BEAR LAKE"
  },
  "92316": {
    stateCode: "CA",
    stateName: "California",
    city: "BLOOMINGTON"
  },
  "92317": {
    stateCode: "CA",
    stateName: "California",
    city: "BLUE JAY"
  },
  "92318": {
    stateCode: "CA",
    stateName: "California",
    city: "BRYN MAWR"
  },
  "92320": {
    stateCode: "CA",
    stateName: "California",
    city: "CALIMESA"
  },
  "92321": {
    stateCode: "CA",
    stateName: "California",
    city: "CEDAR GLEN"
  },
  "92323": {
    stateCode: "CA",
    stateName: "California",
    city: "CIMA"
  },
  "92324": {
    stateCode: "CA",
    stateName: "California",
    city: "COLTON"
  },
  "92325": {
    stateCode: "CA",
    stateName: "California",
    city: "CRESTLINE"
  },
  "92327": {
    stateCode: "CA",
    stateName: "California",
    city: "DAGGETT"
  },
  "92328": {
    stateCode: "CA",
    stateName: "California",
    city: "DEATH VALLEY"
  },
  "92332": {
    stateCode: "CA",
    stateName: "California",
    city: "ESSEX"
  },
  "92333": {
    stateCode: "CA",
    stateName: "California",
    city: "FAWNSKIN"
  },
  "92335": {
    stateCode: "CA",
    stateName: "California",
    city: "FONTANA"
  },
  "92336": {
    stateCode: "CA",
    stateName: "California",
    city: "FONTANA"
  },
  "92337": {
    stateCode: "CA",
    stateName: "California",
    city: "FONTANA"
  },
  "92338": {
    stateCode: "CA",
    stateName: "California",
    city: "LUDLOW"
  },
  "92339": {
    stateCode: "CA",
    stateName: "California",
    city: "FOREST FALLS"
  },
  "92341": {
    stateCode: "CA",
    stateName: "California",
    city: "GREEN VALLEY LAKE"
  },
  "92342": {
    stateCode: "CA",
    stateName: "California",
    city: "HELENDALE"
  },
  "92345": {
    stateCode: "CA",
    stateName: "California",
    city: "HESPERIA"
  },
  "92346": {
    stateCode: "CA",
    stateName: "California",
    city: "HIGHLAND"
  },
  "92347": {
    stateCode: "CA",
    stateName: "California",
    city: "HINKLEY"
  },
  "92352": {
    stateCode: "CA",
    stateName: "California",
    city: "LAKE ARROWHEAD"
  },
  "92354": {
    stateCode: "CA",
    stateName: "California",
    city: "LOMA LINDA"
  },
  "92356": {
    stateCode: "CA",
    stateName: "California",
    city: "LUCERNE VALLEY"
  },
  "92358": {
    stateCode: "CA",
    stateName: "California",
    city: "LYTLE CREEK"
  },
  "92359": {
    stateCode: "CA",
    stateName: "California",
    city: "MENTONE"
  },
  "92363": {
    stateCode: "CA",
    stateName: "California",
    city: "NEEDLES"
  },
  "92364": {
    stateCode: "CA",
    stateName: "California",
    city: "NIPTON"
  },
  "92365": {
    stateCode: "CA",
    stateName: "California",
    city: "NEWBERRY SPRINGS"
  },
  "92368": {
    stateCode: "CA",
    stateName: "California",
    city: "ORO GRANDE"
  },
  "92371": {
    stateCode: "CA",
    stateName: "California",
    city: "PHELAN"
  },
  "92372": {
    stateCode: "CA",
    stateName: "California",
    city: "PINON HILLS"
  },
  "92373": {
    stateCode: "CA",
    stateName: "California",
    city: "REDLANDS"
  },
  "92374": {
    stateCode: "CA",
    stateName: "California",
    city: "REDLANDS"
  },
  "92376": {
    stateCode: "CA",
    stateName: "California",
    city: "RIALTO"
  },
  "92377": {
    stateCode: "CA",
    stateName: "California",
    city: "RIALTO"
  },
  "92382": {
    stateCode: "CA",
    stateName: "California",
    city: "RUNNING SPRINGS"
  },
  "92384": {
    stateCode: "CA",
    stateName: "California",
    city: "SHOSHONE"
  },
  "92386": {
    stateCode: "CA",
    stateName: "California",
    city: "SUGARLOAF"
  },
  "92389": {
    stateCode: "CA",
    stateName: "California",
    city: "TECOPA"
  },
  "92392": {
    stateCode: "CA",
    stateName: "California",
    city: "VICTORVILLE"
  },
  "92394": {
    stateCode: "CA",
    stateName: "California",
    city: "VICTORVILLE"
  },
  "92397": {
    stateCode: "CA",
    stateName: "California",
    city: "WRIGHTWOOD"
  },
  "92398": {
    stateCode: "CA",
    stateName: "California",
    city: "YERMO"
  },
  "92399": {
    stateCode: "CA",
    stateName: "California",
    city: "YUCAIPA"
  },
  "92401": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN BERNARDINO"
  },
  "92404": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN BERNARDINO"
  },
  "92405": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN BERNARDINO"
  },
  "92407": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN BERNARDINO"
  },
  "92408": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN BERNARDINO"
  },
  "92410": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN BERNARDINO"
  },
  "92411": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN BERNARDINO"
  },
  "92501": {
    stateCode: "CA",
    stateName: "California",
    city: "RIVERSIDE"
  },
  "92503": {
    stateCode: "CA",
    stateName: "California",
    city: "RIVERSIDE"
  },
  "92504": {
    stateCode: "CA",
    stateName: "California",
    city: "RIVERSIDE"
  },
  "92505": {
    stateCode: "CA",
    stateName: "California",
    city: "RIVERSIDE"
  },
  "92506": {
    stateCode: "CA",
    stateName: "California",
    city: "RIVERSIDE"
  },
  "92507": {
    stateCode: "CA",
    stateName: "California",
    city: "RIVERSIDE"
  },
  "92508": {
    stateCode: "CA",
    stateName: "California",
    city: "RIVERSIDE"
  },
  "92509": {
    stateCode: "CA",
    stateName: "California",
    city: "RIVERSIDE"
  },
  "92518": {
    stateCode: "CA",
    stateName: "California",
    city: "MARCH AIR RESERVE BASE"
  },
  "92530": {
    stateCode: "CA",
    stateName: "California",
    city: "LAKE ELSINORE"
  },
  "92532": {
    stateCode: "CA",
    stateName: "California",
    city: "LAKE ELSINORE"
  },
  "92536": {
    stateCode: "CA",
    stateName: "California",
    city: "AGUANGA"
  },
  "92539": {
    stateCode: "CA",
    stateName: "California",
    city: "ANZA"
  },
  "92543": {
    stateCode: "CA",
    stateName: "California",
    city: "HEMET"
  },
  "92544": {
    stateCode: "CA",
    stateName: "California",
    city: "HEMET"
  },
  "92545": {
    stateCode: "CA",
    stateName: "California",
    city: "HEMET"
  },
  "92548": {
    stateCode: "CA",
    stateName: "California",
    city: "HOMELAND"
  },
  "92549": {
    stateCode: "CA",
    stateName: "California",
    city: "IDYLLWILD"
  },
  "92551": {
    stateCode: "CA",
    stateName: "California",
    city: "MORENO VALLEY"
  },
  "92553": {
    stateCode: "CA",
    stateName: "California",
    city: "MORENO VALLEY"
  },
  "92555": {
    stateCode: "CA",
    stateName: "California",
    city: "MORENO VALLEY"
  },
  "92557": {
    stateCode: "CA",
    stateName: "California",
    city: "MORENO VALLEY"
  },
  "92561": {
    stateCode: "CA",
    stateName: "California",
    city: "MOUNTAIN CENTER"
  },
  "92562": {
    stateCode: "CA",
    stateName: "California",
    city: "MURRIETA"
  },
  "92563": {
    stateCode: "CA",
    stateName: "California",
    city: "MURRIETA"
  },
  "92567": {
    stateCode: "CA",
    stateName: "California",
    city: "NUEVO"
  },
  "92570": {
    stateCode: "CA",
    stateName: "California",
    city: "PERRIS"
  },
  "92571": {
    stateCode: "CA",
    stateName: "California",
    city: "PERRIS"
  },
  "92582": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JACINTO"
  },
  "92583": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JACINTO"
  },
  "92584": {
    stateCode: "CA",
    stateName: "California",
    city: "MENIFEE"
  },
  "92585": {
    stateCode: "CA",
    stateName: "California",
    city: "SUN CITY"
  },
  "92586": {
    stateCode: "CA",
    stateName: "California",
    city: "SUN CITY"
  },
  "92587": {
    stateCode: "CA",
    stateName: "California",
    city: "SUN CITY"
  },
  "92590": {
    stateCode: "CA",
    stateName: "California",
    city: "TEMECULA"
  },
  "92591": {
    stateCode: "CA",
    stateName: "California",
    city: "TEMECULA"
  },
  "92592": {
    stateCode: "CA",
    stateName: "California",
    city: "TEMECULA"
  },
  "92595": {
    stateCode: "CA",
    stateName: "California",
    city: "WILDOMAR"
  },
  "92596": {
    stateCode: "CA",
    stateName: "California",
    city: "WINCHESTER"
  },
  "92602": {
    stateCode: "CA",
    stateName: "California",
    city: "IRVINE"
  },
  "92604": {
    stateCode: "CA",
    stateName: "California",
    city: "IRVINE"
  },
  "92606": {
    stateCode: "CA",
    stateName: "California",
    city: "IRVINE"
  },
  "92610": {
    stateCode: "CA",
    stateName: "California",
    city: "FOOTHILL RANCH"
  },
  "92612": {
    stateCode: "CA",
    stateName: "California",
    city: "IRVINE"
  },
  "92614": {
    stateCode: "CA",
    stateName: "California",
    city: "IRVINE"
  },
  "92618": {
    stateCode: "CA",
    stateName: "California",
    city: "IRVINE"
  },
  "92620": {
    stateCode: "CA",
    stateName: "California",
    city: "IRVINE"
  },
  "92624": {
    stateCode: "CA",
    stateName: "California",
    city: "CAPISTRANO BEACH"
  },
  "92625": {
    stateCode: "CA",
    stateName: "California",
    city: "CORONA DEL MAR"
  },
  "92626": {
    stateCode: "CA",
    stateName: "California",
    city: "COSTA MESA"
  },
  "92627": {
    stateCode: "CA",
    stateName: "California",
    city: "COSTA MESA"
  },
  "92629": {
    stateCode: "CA",
    stateName: "California",
    city: "DANA POINT"
  },
  "92630": {
    stateCode: "CA",
    stateName: "California",
    city: "LAKE FOREST"
  },
  "92646": {
    stateCode: "CA",
    stateName: "California",
    city: "HUNTINGTON BEACH"
  },
  "92647": {
    stateCode: "CA",
    stateName: "California",
    city: "HUNTINGTON BEACH"
  },
  "92648": {
    stateCode: "CA",
    stateName: "California",
    city: "HUNTINGTON BEACH"
  },
  "92649": {
    stateCode: "CA",
    stateName: "California",
    city: "HUNTINGTON BEACH"
  },
  "92651": {
    stateCode: "CA",
    stateName: "California",
    city: "LAGUNA BEACH"
  },
  "92653": {
    stateCode: "CA",
    stateName: "California",
    city: "LAGUNA HILLS"
  },
  "92655": {
    stateCode: "CA",
    stateName: "California",
    city: "MIDWAY CITY"
  },
  "92656": {
    stateCode: "CA",
    stateName: "California",
    city: "ALISO VIEJO"
  },
  "92657": {
    stateCode: "CA",
    stateName: "California",
    city: "NEWPORT COAST"
  },
  "92660": {
    stateCode: "CA",
    stateName: "California",
    city: "NEWPORT BEACH"
  },
  "92661": {
    stateCode: "CA",
    stateName: "California",
    city: "NEWPORT BEACH"
  },
  "92662": {
    stateCode: "CA",
    stateName: "California",
    city: "NEWPORT BEACH"
  },
  "92663": {
    stateCode: "CA",
    stateName: "California",
    city: "NEWPORT BEACH"
  },
  "92672": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN CLEMENTE"
  },
  "92673": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN CLEMENTE"
  },
  "92675": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JUAN CAPISTRANO"
  },
  "92676": {
    stateCode: "CA",
    stateName: "California",
    city: "SILVERADO"
  },
  "92677": {
    stateCode: "CA",
    stateName: "California",
    city: "LAGUNA NIGUEL"
  },
  "92679": {
    stateCode: "CA",
    stateName: "California",
    city: "TRABUCO CANYON"
  },
  "92683": {
    stateCode: "CA",
    stateName: "California",
    city: "WESTMINSTER"
  },
  "92688": {
    stateCode: "CA",
    stateName: "California",
    city: "RANCHO SANTA MARGARITA"
  },
  "92691": {
    stateCode: "CA",
    stateName: "California",
    city: "MISSION VIEJO"
  },
  "92692": {
    stateCode: "CA",
    stateName: "California",
    city: "MISSION VIEJO"
  },
  "92694": {
    stateCode: "CA",
    stateName: "California",
    city: "LADERA RANCH"
  },
  "92701": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA ANA"
  },
  "92703": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA ANA"
  },
  "92704": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA ANA"
  },
  "92705": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA ANA"
  },
  "92706": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA ANA"
  },
  "92707": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA ANA"
  },
  "92708": {
    stateCode: "CA",
    stateName: "California",
    city: "FOUNTAIN VALLEY"
  },
  "92780": {
    stateCode: "CA",
    stateName: "California",
    city: "TUSTIN"
  },
  "92782": {
    stateCode: "CA",
    stateName: "California",
    city: "TUSTIN"
  },
  "92801": {
    stateCode: "CA",
    stateName: "California",
    city: "ANAHEIM"
  },
  "92802": {
    stateCode: "CA",
    stateName: "California",
    city: "ANAHEIM"
  },
  "92804": {
    stateCode: "CA",
    stateName: "California",
    city: "ANAHEIM"
  },
  "92805": {
    stateCode: "CA",
    stateName: "California",
    city: "ANAHEIM"
  },
  "92806": {
    stateCode: "CA",
    stateName: "California",
    city: "ANAHEIM"
  },
  "92807": {
    stateCode: "CA",
    stateName: "California",
    city: "ANAHEIM"
  },
  "92808": {
    stateCode: "CA",
    stateName: "California",
    city: "ANAHEIM"
  },
  "92821": {
    stateCode: "CA",
    stateName: "California",
    city: "BREA"
  },
  "92823": {
    stateCode: "CA",
    stateName: "California",
    city: "BREA"
  },
  "92831": {
    stateCode: "CA",
    stateName: "California",
    city: "FULLERTON"
  },
  "92832": {
    stateCode: "CA",
    stateName: "California",
    city: "FULLERTON"
  },
  "92833": {
    stateCode: "CA",
    stateName: "California",
    city: "FULLERTON"
  },
  "92835": {
    stateCode: "CA",
    stateName: "California",
    city: "FULLERTON"
  },
  "92840": {
    stateCode: "CA",
    stateName: "California",
    city: "GARDEN GROVE"
  },
  "92841": {
    stateCode: "CA",
    stateName: "California",
    city: "GARDEN GROVE"
  },
  "92843": {
    stateCode: "CA",
    stateName: "California",
    city: "GARDEN GROVE"
  },
  "92844": {
    stateCode: "CA",
    stateName: "California",
    city: "GARDEN GROVE"
  },
  "92845": {
    stateCode: "CA",
    stateName: "California",
    city: "GARDEN GROVE"
  },
  "92860": {
    stateCode: "CA",
    stateName: "California",
    city: "NORCO"
  },
  "92861": {
    stateCode: "CA",
    stateName: "California",
    city: "VILLA PARK"
  },
  "92865": {
    stateCode: "CA",
    stateName: "California",
    city: "ORANGE"
  },
  "92866": {
    stateCode: "CA",
    stateName: "California",
    city: "ORANGE"
  },
  "92867": {
    stateCode: "CA",
    stateName: "California",
    city: "ORANGE"
  },
  "92868": {
    stateCode: "CA",
    stateName: "California",
    city: "ORANGE"
  },
  "92869": {
    stateCode: "CA",
    stateName: "California",
    city: "ORANGE"
  },
  "92870": {
    stateCode: "CA",
    stateName: "California",
    city: "PLACENTIA"
  },
  "92879": {
    stateCode: "CA",
    stateName: "California",
    city: "CORONA"
  },
  "92880": {
    stateCode: "CA",
    stateName: "California",
    city: "CORONA"
  },
  "92881": {
    stateCode: "CA",
    stateName: "California",
    city: "CORONA"
  },
  "92882": {
    stateCode: "CA",
    stateName: "California",
    city: "CORONA"
  },
  "92883": {
    stateCode: "CA",
    stateName: "California",
    city: "CORONA"
  },
  "92886": {
    stateCode: "CA",
    stateName: "California",
    city: "YORBA LINDA"
  },
  "92887": {
    stateCode: "CA",
    stateName: "California",
    city: "YORBA LINDA"
  },
  "93001": {
    stateCode: "CA",
    stateName: "California",
    city: "VENTURA"
  },
  "93003": {
    stateCode: "CA",
    stateName: "California",
    city: "VENTURA"
  },
  "93004": {
    stateCode: "CA",
    stateName: "California",
    city: "VENTURA"
  },
  "93010": {
    stateCode: "CA",
    stateName: "California",
    city: "CAMARILLO"
  },
  "93012": {
    stateCode: "CA",
    stateName: "California",
    city: "CAMARILLO"
  },
  "93013": {
    stateCode: "CA",
    stateName: "California",
    city: "CARPINTERIA"
  },
  "93015": {
    stateCode: "CA",
    stateName: "California",
    city: "FILLMORE"
  },
  "93021": {
    stateCode: "CA",
    stateName: "California",
    city: "MOORPARK"
  },
  "93022": {
    stateCode: "CA",
    stateName: "California",
    city: "OAK VIEW"
  },
  "93023": {
    stateCode: "CA",
    stateName: "California",
    city: "OJAI"
  },
  "93030": {
    stateCode: "CA",
    stateName: "California",
    city: "OXNARD"
  },
  "93033": {
    stateCode: "CA",
    stateName: "California",
    city: "OXNARD"
  },
  "93035": {
    stateCode: "CA",
    stateName: "California",
    city: "OXNARD"
  },
  "93040": {
    stateCode: "CA",
    stateName: "California",
    city: "PIRU"
  },
  "93041": {
    stateCode: "CA",
    stateName: "California",
    city: "PORT HUENEME"
  },
  "93060": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA PAULA"
  },
  "93063": {
    stateCode: "CA",
    stateName: "California",
    city: "SIMI VALLEY"
  },
  "93065": {
    stateCode: "CA",
    stateName: "California",
    city: "SIMI VALLEY"
  },
  "93066": {
    stateCode: "CA",
    stateName: "California",
    city: "SOMIS"
  },
  "93067": {
    stateCode: "CA",
    stateName: "California",
    city: "SUMMERLAND"
  },
  "93101": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA BARBARA"
  },
  "93103": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA BARBARA"
  },
  "93105": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA BARBARA"
  },
  "93108": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA BARBARA"
  },
  "93109": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA BARBARA"
  },
  "93110": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA BARBARA"
  },
  "93111": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA BARBARA"
  },
  "93117": {
    stateCode: "CA",
    stateName: "California",
    city: "GOLETA"
  },
  "93201": {
    stateCode: "CA",
    stateName: "California",
    city: "ALPAUGH"
  },
  "93202": {
    stateCode: "CA",
    stateName: "California",
    city: "ARMONA"
  },
  "93203": {
    stateCode: "CA",
    stateName: "California",
    city: "ARVIN"
  },
  "93204": {
    stateCode: "CA",
    stateName: "California",
    city: "AVENAL"
  },
  "93205": {
    stateCode: "CA",
    stateName: "California",
    city: "BODFISH"
  },
  "93206": {
    stateCode: "CA",
    stateName: "California",
    city: "BUTTONWILLOW"
  },
  "93207": {
    stateCode: "CA",
    stateName: "California",
    city: "CALIFORNIA HOT SPRINGS"
  },
  "93208": {
    stateCode: "CA",
    stateName: "California",
    city: "CAMP NELSON"
  },
  "93210": {
    stateCode: "CA",
    stateName: "California",
    city: "COALINGA"
  },
  "93212": {
    stateCode: "CA",
    stateName: "California",
    city: "CORCORAN"
  },
  "93215": {
    stateCode: "CA",
    stateName: "California",
    city: "DELANO"
  },
  "93218": {
    stateCode: "CA",
    stateName: "California",
    city: "DUCOR"
  },
  "93219": {
    stateCode: "CA",
    stateName: "California",
    city: "EARLIMART"
  },
  "93221": {
    stateCode: "CA",
    stateName: "California",
    city: "EXETER"
  },
  "93222": {
    stateCode: "CA",
    stateName: "California",
    city: "FRAZIER PARK"
  },
  "93223": {
    stateCode: "CA",
    stateName: "California",
    city: "FARMERSVILLE"
  },
  "93224": {
    stateCode: "CA",
    stateName: "California",
    city: "FELLOWS"
  },
  "93225": {
    stateCode: "CA",
    stateName: "California",
    city: "FRAZIER PARK"
  },
  "93226": {
    stateCode: "CA",
    stateName: "California",
    city: "GLENNVILLE"
  },
  "93230": {
    stateCode: "CA",
    stateName: "California",
    city: "HANFORD"
  },
  "93234": {
    stateCode: "CA",
    stateName: "California",
    city: "HURON"
  },
  "93235": {
    stateCode: "CA",
    stateName: "California",
    city: "IVANHOE"
  },
  "93238": {
    stateCode: "CA",
    stateName: "California",
    city: "KERNVILLE"
  },
  "93239": {
    stateCode: "CA",
    stateName: "California",
    city: "KETTLEMAN CITY"
  },
  "93240": {
    stateCode: "CA",
    stateName: "California",
    city: "LAKE ISABELLA"
  },
  "93241": {
    stateCode: "CA",
    stateName: "California",
    city: "LAMONT"
  },
  "93242": {
    stateCode: "CA",
    stateName: "California",
    city: "LATON"
  },
  "93243": {
    stateCode: "CA",
    stateName: "California",
    city: "LEBEC"
  },
  "93244": {
    stateCode: "CA",
    stateName: "California",
    city: "LEMON COVE"
  },
  "93245": {
    stateCode: "CA",
    stateName: "California",
    city: "LEMOORE"
  },
  "93247": {
    stateCode: "CA",
    stateName: "California",
    city: "LINDSAY"
  },
  "93249": {
    stateCode: "CA",
    stateName: "California",
    city: "LOST HILLS"
  },
  "93250": {
    stateCode: "CA",
    stateName: "California",
    city: "MC FARLAND"
  },
  "93251": {
    stateCode: "CA",
    stateName: "California",
    city: "MC KITTRICK"
  },
  "93252": {
    stateCode: "CA",
    stateName: "California",
    city: "MARICOPA"
  },
  "93254": {
    stateCode: "CA",
    stateName: "California",
    city: "NEW CUYAMA"
  },
  "93255": {
    stateCode: "CA",
    stateName: "California",
    city: "ONYX"
  },
  "93256": {
    stateCode: "CA",
    stateName: "California",
    city: "PIXLEY"
  },
  "93257": {
    stateCode: "CA",
    stateName: "California",
    city: "PORTERVILLE"
  },
  "93260": {
    stateCode: "CA",
    stateName: "California",
    city: "POSEY"
  },
  "93261": {
    stateCode: "CA",
    stateName: "California",
    city: "RICHGROVE"
  },
  "93262": {
    stateCode: "CA",
    stateName: "California",
    city: "SEQUOIA NATIONAL PARK"
  },
  "93263": {
    stateCode: "CA",
    stateName: "California",
    city: "SHAFTER"
  },
  "93265": {
    stateCode: "CA",
    stateName: "California",
    city: "SPRINGVILLE"
  },
  "93266": {
    stateCode: "CA",
    stateName: "California",
    city: "STRATFORD"
  },
  "93267": {
    stateCode: "CA",
    stateName: "California",
    city: "STRATHMORE"
  },
  "93268": {
    stateCode: "CA",
    stateName: "California",
    city: "TAFT"
  },
  "93270": {
    stateCode: "CA",
    stateName: "California",
    city: "TERRA BELLA"
  },
  "93271": {
    stateCode: "CA",
    stateName: "California",
    city: "THREE RIVERS"
  },
  "93272": {
    stateCode: "CA",
    stateName: "California",
    city: "TIPTON"
  },
  "93274": {
    stateCode: "CA",
    stateName: "California",
    city: "TULARE"
  },
  "93276": {
    stateCode: "CA",
    stateName: "California",
    city: "TUPMAN"
  },
  "93277": {
    stateCode: "CA",
    stateName: "California",
    city: "VISALIA"
  },
  "93280": {
    stateCode: "CA",
    stateName: "California",
    city: "WASCO"
  },
  "93282": {
    stateCode: "CA",
    stateName: "California",
    city: "WAUKENA"
  },
  "93283": {
    stateCode: "CA",
    stateName: "California",
    city: "WELDON"
  },
  "93285": {
    stateCode: "CA",
    stateName: "California",
    city: "WOFFORD HEIGHTS"
  },
  "93286": {
    stateCode: "CA",
    stateName: "California",
    city: "WOODLAKE"
  },
  "93287": {
    stateCode: "CA",
    stateName: "California",
    city: "WOODY"
  },
  "93291": {
    stateCode: "CA",
    stateName: "California",
    city: "VISALIA"
  },
  "93292": {
    stateCode: "CA",
    stateName: "California",
    city: "VISALIA"
  },
  "93301": {
    stateCode: "CA",
    stateName: "California",
    city: "BAKERSFIELD"
  },
  "93304": {
    stateCode: "CA",
    stateName: "California",
    city: "BAKERSFIELD"
  },
  "93305": {
    stateCode: "CA",
    stateName: "California",
    city: "BAKERSFIELD"
  },
  "93306": {
    stateCode: "CA",
    stateName: "California",
    city: "BAKERSFIELD"
  },
  "93307": {
    stateCode: "CA",
    stateName: "California",
    city: "BAKERSFIELD"
  },
  "93308": {
    stateCode: "CA",
    stateName: "California",
    city: "BAKERSFIELD"
  },
  "93309": {
    stateCode: "CA",
    stateName: "California",
    city: "BAKERSFIELD"
  },
  "93311": {
    stateCode: "CA",
    stateName: "California",
    city: "BAKERSFIELD"
  },
  "93312": {
    stateCode: "CA",
    stateName: "California",
    city: "BAKERSFIELD"
  },
  "93313": {
    stateCode: "CA",
    stateName: "California",
    city: "BAKERSFIELD"
  },
  "93401": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN LUIS OBISPO"
  },
  "93402": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS OSOS"
  },
  "93405": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN LUIS OBISPO"
  },
  "93420": {
    stateCode: "CA",
    stateName: "California",
    city: "ARROYO GRANDE"
  },
  "93422": {
    stateCode: "CA",
    stateName: "California",
    city: "ATASCADERO"
  },
  "93424": {
    stateCode: "CA",
    stateName: "California",
    city: "AVILA BEACH"
  },
  "93426": {
    stateCode: "CA",
    stateName: "California",
    city: "BRADLEY"
  },
  "93427": {
    stateCode: "CA",
    stateName: "California",
    city: "BUELLTON"
  },
  "93428": {
    stateCode: "CA",
    stateName: "California",
    city: "CAMBRIA"
  },
  "93429": {
    stateCode: "CA",
    stateName: "California",
    city: "CASMALIA"
  },
  "93430": {
    stateCode: "CA",
    stateName: "California",
    city: "CAYUCOS"
  },
  "93432": {
    stateCode: "CA",
    stateName: "California",
    city: "CRESTON"
  },
  "93433": {
    stateCode: "CA",
    stateName: "California",
    city: "GROVER BEACH"
  },
  "93434": {
    stateCode: "CA",
    stateName: "California",
    city: "GUADALUPE"
  },
  "93436": {
    stateCode: "CA",
    stateName: "California",
    city: "LOMPOC"
  },
  "93437": {
    stateCode: "CA",
    stateName: "California",
    city: "LOMPOC"
  },
  "93440": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ALAMOS"
  },
  "93441": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS OLIVOS"
  },
  "93442": {
    stateCode: "CA",
    stateName: "California",
    city: "MORRO BAY"
  },
  "93444": {
    stateCode: "CA",
    stateName: "California",
    city: "NIPOMO"
  },
  "93445": {
    stateCode: "CA",
    stateName: "California",
    city: "OCEANO"
  },
  "93446": {
    stateCode: "CA",
    stateName: "California",
    city: "PASO ROBLES"
  },
  "93449": {
    stateCode: "CA",
    stateName: "California",
    city: "PISMO BEACH"
  },
  "93450": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN ARDO"
  },
  "93451": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN MIGUEL"
  },
  "93452": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN SIMEON"
  },
  "93453": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA MARGARITA"
  },
  "93454": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA MARIA"
  },
  "93455": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA MARIA"
  },
  "93458": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA MARIA"
  },
  "93460": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA YNEZ"
  },
  "93461": {
    stateCode: "CA",
    stateName: "California",
    city: "SHANDON"
  },
  "93463": {
    stateCode: "CA",
    stateName: "California",
    city: "SOLVANG"
  },
  "93465": {
    stateCode: "CA",
    stateName: "California",
    city: "TEMPLETON"
  },
  "93501": {
    stateCode: "CA",
    stateName: "California",
    city: "MOJAVE"
  },
  "93505": {
    stateCode: "CA",
    stateName: "California",
    city: "CALIFORNIA CITY"
  },
  "93510": {
    stateCode: "CA",
    stateName: "California",
    city: "ACTON"
  },
  "93512": {
    stateCode: "CA",
    stateName: "California",
    city: "BENTON"
  },
  "93513": {
    stateCode: "CA",
    stateName: "California",
    city: "BIG PINE"
  },
  "93514": {
    stateCode: "CA",
    stateName: "California",
    city: "BISHOP"
  },
  "93516": {
    stateCode: "CA",
    stateName: "California",
    city: "BORON"
  },
  "93517": {
    stateCode: "CA",
    stateName: "California",
    city: "BRIDGEPORT"
  },
  "93518": {
    stateCode: "CA",
    stateName: "California",
    city: "CALIENTE"
  },
  "93522": {
    stateCode: "CA",
    stateName: "California",
    city: "DARWIN"
  },
  "93523": {
    stateCode: "CA",
    stateName: "California",
    city: "EDWARDS"
  },
  "93526": {
    stateCode: "CA",
    stateName: "California",
    city: "INDEPENDENCE"
  },
  "93527": {
    stateCode: "CA",
    stateName: "California",
    city: "INYOKERN"
  },
  "93528": {
    stateCode: "CA",
    stateName: "California",
    city: "JOHANNESBURG"
  },
  "93529": {
    stateCode: "CA",
    stateName: "California",
    city: "JUNE LAKE"
  },
  "93530": {
    stateCode: "CA",
    stateName: "California",
    city: "KEELER"
  },
  "93531": {
    stateCode: "CA",
    stateName: "California",
    city: "KEENE"
  },
  "93532": {
    stateCode: "CA",
    stateName: "California",
    city: "LAKE HUGHES"
  },
  "93534": {
    stateCode: "CA",
    stateName: "California",
    city: "LANCASTER"
  },
  "93535": {
    stateCode: "CA",
    stateName: "California",
    city: "LANCASTER"
  },
  "93536": {
    stateCode: "CA",
    stateName: "California",
    city: "LANCASTER"
  },
  "93541": {
    stateCode: "CA",
    stateName: "California",
    city: "LEE VINING"
  },
  "93543": {
    stateCode: "CA",
    stateName: "California",
    city: "LITTLEROCK"
  },
  "93544": {
    stateCode: "CA",
    stateName: "California",
    city: "LLANO"
  },
  "93545": {
    stateCode: "CA",
    stateName: "California",
    city: "LONE PINE"
  },
  "93546": {
    stateCode: "CA",
    stateName: "California",
    city: "MAMMOTH LAKES"
  },
  "93549": {
    stateCode: "CA",
    stateName: "California",
    city: "OLANCHA"
  },
  "93550": {
    stateCode: "CA",
    stateName: "California",
    city: "PALMDALE"
  },
  "93551": {
    stateCode: "CA",
    stateName: "California",
    city: "PALMDALE"
  },
  "93552": {
    stateCode: "CA",
    stateName: "California",
    city: "PALMDALE"
  },
  "93553": {
    stateCode: "CA",
    stateName: "California",
    city: "PEARBLOSSOM"
  },
  "93554": {
    stateCode: "CA",
    stateName: "California",
    city: "RANDSBURG"
  },
  "93555": {
    stateCode: "CA",
    stateName: "California",
    city: "RIDGECREST"
  },
  "93560": {
    stateCode: "CA",
    stateName: "California",
    city: "ROSAMOND"
  },
  "93561": {
    stateCode: "CA",
    stateName: "California",
    city: "TEHACHAPI"
  },
  "93562": {
    stateCode: "CA",
    stateName: "California",
    city: "TRONA"
  },
  "93563": {
    stateCode: "CA",
    stateName: "California",
    city: "VALYERMO"
  },
  "93591": {
    stateCode: "CA",
    stateName: "California",
    city: "PALMDALE"
  },
  "93601": {
    stateCode: "CA",
    stateName: "California",
    city: "AHWAHNEE"
  },
  "93602": {
    stateCode: "CA",
    stateName: "California",
    city: "AUBERRY"
  },
  "93603": {
    stateCode: "CA",
    stateName: "California",
    city: "BADGER"
  },
  "93604": {
    stateCode: "CA",
    stateName: "California",
    city: "BASS LAKE"
  },
  "93605": {
    stateCode: "CA",
    stateName: "California",
    city: "BIG CREEK"
  },
  "93606": {
    stateCode: "CA",
    stateName: "California",
    city: "BIOLA"
  },
  "93608": {
    stateCode: "CA",
    stateName: "California",
    city: "CANTUA CREEK"
  },
  "93609": {
    stateCode: "CA",
    stateName: "California",
    city: "CARUTHERS"
  },
  "93610": {
    stateCode: "CA",
    stateName: "California",
    city: "CHOWCHILLA"
  },
  "93611": {
    stateCode: "CA",
    stateName: "California",
    city: "CLOVIS"
  },
  "93612": {
    stateCode: "CA",
    stateName: "California",
    city: "CLOVIS"
  },
  "93614": {
    stateCode: "CA",
    stateName: "California",
    city: "COARSEGOLD"
  },
  "93615": {
    stateCode: "CA",
    stateName: "California",
    city: "CUTLER"
  },
  "93616": {
    stateCode: "CA",
    stateName: "California",
    city: "DEL REY"
  },
  "93618": {
    stateCode: "CA",
    stateName: "California",
    city: "DINUBA"
  },
  "93620": {
    stateCode: "CA",
    stateName: "California",
    city: "DOS PALOS"
  },
  "93621": {
    stateCode: "CA",
    stateName: "California",
    city: "DUNLAP"
  },
  "93622": {
    stateCode: "CA",
    stateName: "California",
    city: "FIREBAUGH"
  },
  "93623": {
    stateCode: "CA",
    stateName: "California",
    city: "FISH CAMP"
  },
  "93624": {
    stateCode: "CA",
    stateName: "California",
    city: "FIVE POINTS"
  },
  "93625": {
    stateCode: "CA",
    stateName: "California",
    city: "FOWLER"
  },
  "93626": {
    stateCode: "CA",
    stateName: "California",
    city: "FRIANT"
  },
  "93627": {
    stateCode: "CA",
    stateName: "California",
    city: "HELM"
  },
  "93628": {
    stateCode: "CA",
    stateName: "California",
    city: "HUME"
  },
  "93630": {
    stateCode: "CA",
    stateName: "California",
    city: "KERMAN"
  },
  "93631": {
    stateCode: "CA",
    stateName: "California",
    city: "KINGSBURG"
  },
  "93634": {
    stateCode: "CA",
    stateName: "California",
    city: "LAKESHORE"
  },
  "93635": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS BANOS"
  },
  "93637": {
    stateCode: "CA",
    stateName: "California",
    city: "MADERA"
  },
  "93638": {
    stateCode: "CA",
    stateName: "California",
    city: "MADERA"
  },
  "93640": {
    stateCode: "CA",
    stateName: "California",
    city: "MENDOTA"
  },
  "93641": {
    stateCode: "CA",
    stateName: "California",
    city: "MIRAMONTE"
  },
  "93643": {
    stateCode: "CA",
    stateName: "California",
    city: "NORTH FORK"
  },
  "93644": {
    stateCode: "CA",
    stateName: "California",
    city: "OAKHURST"
  },
  "93645": {
    stateCode: "CA",
    stateName: "California",
    city: "O NEALS"
  },
  "93646": {
    stateCode: "CA",
    stateName: "California",
    city: "ORANGE COVE"
  },
  "93647": {
    stateCode: "CA",
    stateName: "California",
    city: "OROSI"
  },
  "93648": {
    stateCode: "CA",
    stateName: "California",
    city: "PARLIER"
  },
  "93650": {
    stateCode: "CA",
    stateName: "California",
    city: "FRESNO"
  },
  "93651": {
    stateCode: "CA",
    stateName: "California",
    city: "PRATHER"
  },
  "93652": {
    stateCode: "CA",
    stateName: "California",
    city: "RAISIN CITY"
  },
  "93653": {
    stateCode: "CA",
    stateName: "California",
    city: "RAYMOND"
  },
  "93654": {
    stateCode: "CA",
    stateName: "California",
    city: "REEDLEY"
  },
  "93656": {
    stateCode: "CA",
    stateName: "California",
    city: "RIVERDALE"
  },
  "93657": {
    stateCode: "CA",
    stateName: "California",
    city: "SANGER"
  },
  "93660": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOAQUIN"
  },
  "93662": {
    stateCode: "CA",
    stateName: "California",
    city: "SELMA"
  },
  "93664": {
    stateCode: "CA",
    stateName: "California",
    city: "SHAVER LAKE"
  },
  "93665": {
    stateCode: "CA",
    stateName: "California",
    city: "SOUTH DOS PALOS"
  },
  "93666": {
    stateCode: "CA",
    stateName: "California",
    city: "SULTANA"
  },
  "93667": {
    stateCode: "CA",
    stateName: "California",
    city: "TOLLHOUSE"
  },
  "93668": {
    stateCode: "CA",
    stateName: "California",
    city: "TRANQUILLITY"
  },
  "93669": {
    stateCode: "CA",
    stateName: "California",
    city: "WISHON"
  },
  "93673": {
    stateCode: "CA",
    stateName: "California",
    city: "TRAVER"
  },
  "93675": {
    stateCode: "CA",
    stateName: "California",
    city: "SQUAW VALLEY"
  },
  "93701": {
    stateCode: "CA",
    stateName: "California",
    city: "FRESNO"
  },
  "93702": {
    stateCode: "CA",
    stateName: "California",
    city: "FRESNO"
  },
  "93703": {
    stateCode: "CA",
    stateName: "California",
    city: "FRESNO"
  },
  "93704": {
    stateCode: "CA",
    stateName: "California",
    city: "FRESNO"
  },
  "93705": {
    stateCode: "CA",
    stateName: "California",
    city: "FRESNO"
  },
  "93706": {
    stateCode: "CA",
    stateName: "California",
    city: "FRESNO"
  },
  "93710": {
    stateCode: "CA",
    stateName: "California",
    city: "FRESNO"
  },
  "93711": {
    stateCode: "CA",
    stateName: "California",
    city: "FRESNO"
  },
  "93720": {
    stateCode: "CA",
    stateName: "California",
    city: "FRESNO"
  },
  "93721": {
    stateCode: "CA",
    stateName: "California",
    city: "FRESNO"
  },
  "93722": {
    stateCode: "CA",
    stateName: "California",
    city: "FRESNO"
  },
  "93725": {
    stateCode: "CA",
    stateName: "California",
    city: "FRESNO"
  },
  "93726": {
    stateCode: "CA",
    stateName: "California",
    city: "FRESNO"
  },
  "93727": {
    stateCode: "CA",
    stateName: "California",
    city: "FRESNO"
  },
  "93728": {
    stateCode: "CA",
    stateName: "California",
    city: "FRESNO"
  },
  "93901": {
    stateCode: "CA",
    stateName: "California",
    city: "SALINAS"
  },
  "93905": {
    stateCode: "CA",
    stateName: "California",
    city: "SALINAS"
  },
  "93906": {
    stateCode: "CA",
    stateName: "California",
    city: "SALINAS"
  },
  "93907": {
    stateCode: "CA",
    stateName: "California",
    city: "SALINAS"
  },
  "93908": {
    stateCode: "CA",
    stateName: "California",
    city: "SALINAS"
  },
  "93920": {
    stateCode: "CA",
    stateName: "California",
    city: "BIG SUR"
  },
  "93921": {
    stateCode: "CA",
    stateName: "California",
    city: "CARMEL BY THE SEA"
  },
  "93923": {
    stateCode: "CA",
    stateName: "California",
    city: "CARMEL"
  },
  "93924": {
    stateCode: "CA",
    stateName: "California",
    city: "CARMEL VALLEY"
  },
  "93925": {
    stateCode: "CA",
    stateName: "California",
    city: "CHUALAR"
  },
  "93926": {
    stateCode: "CA",
    stateName: "California",
    city: "GONZALES"
  },
  "93927": {
    stateCode: "CA",
    stateName: "California",
    city: "GREENFIELD"
  },
  "93928": {
    stateCode: "CA",
    stateName: "California",
    city: "JOLON"
  },
  "93930": {
    stateCode: "CA",
    stateName: "California",
    city: "KING CITY"
  },
  "93932": {
    stateCode: "CA",
    stateName: "California",
    city: "LOCKWOOD"
  },
  "93933": {
    stateCode: "CA",
    stateName: "California",
    city: "MARINA"
  },
  "93940": {
    stateCode: "CA",
    stateName: "California",
    city: "MONTEREY"
  },
  "93950": {
    stateCode: "CA",
    stateName: "California",
    city: "PACIFIC GROVE"
  },
  "93953": {
    stateCode: "CA",
    stateName: "California",
    city: "PEBBLE BEACH"
  },
  "93954": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN LUCAS"
  },
  "93955": {
    stateCode: "CA",
    stateName: "California",
    city: "SEASIDE"
  },
  "93960": {
    stateCode: "CA",
    stateName: "California",
    city: "SOLEDAD"
  },
  "93962": {
    stateCode: "CA",
    stateName: "California",
    city: "SPRECKELS"
  },
  "94002": {
    stateCode: "CA",
    stateName: "California",
    city: "BELMONT"
  },
  "94005": {
    stateCode: "CA",
    stateName: "California",
    city: "BRISBANE"
  },
  "94010": {
    stateCode: "CA",
    stateName: "California",
    city: "BURLINGAME"
  },
  "94014": {
    stateCode: "CA",
    stateName: "California",
    city: "DALY CITY"
  },
  "94015": {
    stateCode: "CA",
    stateName: "California",
    city: "DALY CITY"
  },
  "94019": {
    stateCode: "CA",
    stateName: "California",
    city: "HALF MOON BAY"
  },
  "94020": {
    stateCode: "CA",
    stateName: "California",
    city: "LA HONDA"
  },
  "94021": {
    stateCode: "CA",
    stateName: "California",
    city: "LOMA MAR"
  },
  "94022": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ALTOS"
  },
  "94024": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS ALTOS"
  },
  "94025": {
    stateCode: "CA",
    stateName: "California",
    city: "MENLO PARK"
  },
  "94027": {
    stateCode: "CA",
    stateName: "California",
    city: "ATHERTON"
  },
  "94028": {
    stateCode: "CA",
    stateName: "California",
    city: "PORTOLA VALLEY"
  },
  "94030": {
    stateCode: "CA",
    stateName: "California",
    city: "MILLBRAE"
  },
  "94037": {
    stateCode: "CA",
    stateName: "California",
    city: "MONTARA"
  },
  "94038": {
    stateCode: "CA",
    stateName: "California",
    city: "MOSS BEACH"
  },
  "94040": {
    stateCode: "CA",
    stateName: "California",
    city: "MOUNTAIN VIEW"
  },
  "94041": {
    stateCode: "CA",
    stateName: "California",
    city: "MOUNTAIN VIEW"
  },
  "94043": {
    stateCode: "CA",
    stateName: "California",
    city: "MOUNTAIN VIEW"
  },
  "94044": {
    stateCode: "CA",
    stateName: "California",
    city: "PACIFICA"
  },
  "94060": {
    stateCode: "CA",
    stateName: "California",
    city: "PESCADERO"
  },
  "94061": {
    stateCode: "CA",
    stateName: "California",
    city: "REDWOOD CITY"
  },
  "94062": {
    stateCode: "CA",
    stateName: "California",
    city: "REDWOOD CITY"
  },
  "94063": {
    stateCode: "CA",
    stateName: "California",
    city: "REDWOOD CITY"
  },
  "94065": {
    stateCode: "CA",
    stateName: "California",
    city: "REDWOOD CITY"
  },
  "94066": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN BRUNO"
  },
  "94070": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN CARLOS"
  },
  "94074": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN GREGORIO"
  },
  "94080": {
    stateCode: "CA",
    stateName: "California",
    city: "SOUTH SAN FRANCISCO"
  },
  "94086": {
    stateCode: "CA",
    stateName: "California",
    city: "SUNNYVALE"
  },
  "94087": {
    stateCode: "CA",
    stateName: "California",
    city: "SUNNYVALE"
  },
  "94089": {
    stateCode: "CA",
    stateName: "California",
    city: "SUNNYVALE"
  },
  "94102": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94103": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94104": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94105": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94107": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94108": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94109": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94110": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94111": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94112": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94114": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94115": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94116": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94117": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94118": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94121": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94122": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94123": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94124": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94127": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94129": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94130": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94131": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94132": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94133": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94134": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN FRANCISCO"
  },
  "94301": {
    stateCode: "CA",
    stateName: "California",
    city: "PALO ALTO"
  },
  "94303": {
    stateCode: "CA",
    stateName: "California",
    city: "PALO ALTO"
  },
  "94304": {
    stateCode: "CA",
    stateName: "California",
    city: "PALO ALTO"
  },
  "94305": {
    stateCode: "CA",
    stateName: "California",
    city: "STANFORD"
  },
  "94306": {
    stateCode: "CA",
    stateName: "California",
    city: "PALO ALTO"
  },
  "94401": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN MATEO"
  },
  "94402": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN MATEO"
  },
  "94403": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN MATEO"
  },
  "94404": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN MATEO"
  },
  "94501": {
    stateCode: "CA",
    stateName: "California",
    city: "ALAMEDA"
  },
  "94502": {
    stateCode: "CA",
    stateName: "California",
    city: "ALAMEDA"
  },
  "94506": {
    stateCode: "CA",
    stateName: "California",
    city: "DANVILLE"
  },
  "94507": {
    stateCode: "CA",
    stateName: "California",
    city: "ALAMO"
  },
  "94508": {
    stateCode: "CA",
    stateName: "California",
    city: "ANGWIN"
  },
  "94509": {
    stateCode: "CA",
    stateName: "California",
    city: "ANTIOCH"
  },
  "94510": {
    stateCode: "CA",
    stateName: "California",
    city: "BENICIA"
  },
  "94511": {
    stateCode: "CA",
    stateName: "California",
    city: "BETHEL ISLAND"
  },
  "94512": {
    stateCode: "CA",
    stateName: "California",
    city: "BIRDS LANDING"
  },
  "94513": {
    stateCode: "CA",
    stateName: "California",
    city: "BRENTWOOD"
  },
  "94514": {
    stateCode: "CA",
    stateName: "California",
    city: "BYRON"
  },
  "94515": {
    stateCode: "CA",
    stateName: "California",
    city: "CALISTOGA"
  },
  "94517": {
    stateCode: "CA",
    stateName: "California",
    city: "CLAYTON"
  },
  "94518": {
    stateCode: "CA",
    stateName: "California",
    city: "CONCORD"
  },
  "94519": {
    stateCode: "CA",
    stateName: "California",
    city: "CONCORD"
  },
  "94520": {
    stateCode: "CA",
    stateName: "California",
    city: "CONCORD"
  },
  "94521": {
    stateCode: "CA",
    stateName: "California",
    city: "CONCORD"
  },
  "94523": {
    stateCode: "CA",
    stateName: "California",
    city: "PLEASANT HILL"
  },
  "94525": {
    stateCode: "CA",
    stateName: "California",
    city: "CROCKETT"
  },
  "94526": {
    stateCode: "CA",
    stateName: "California",
    city: "DANVILLE"
  },
  "94530": {
    stateCode: "CA",
    stateName: "California",
    city: "EL CERRITO"
  },
  "94533": {
    stateCode: "CA",
    stateName: "California",
    city: "FAIRFIELD"
  },
  "94535": {
    stateCode: "CA",
    stateName: "California",
    city: "TRAVIS AFB"
  },
  "94536": {
    stateCode: "CA",
    stateName: "California",
    city: "FREMONT"
  },
  "94538": {
    stateCode: "CA",
    stateName: "California",
    city: "FREMONT"
  },
  "94539": {
    stateCode: "CA",
    stateName: "California",
    city: "FREMONT"
  },
  "94541": {
    stateCode: "CA",
    stateName: "California",
    city: "HAYWARD"
  },
  "94542": {
    stateCode: "CA",
    stateName: "California",
    city: "HAYWARD"
  },
  "94544": {
    stateCode: "CA",
    stateName: "California",
    city: "HAYWARD"
  },
  "94545": {
    stateCode: "CA",
    stateName: "California",
    city: "HAYWARD"
  },
  "94546": {
    stateCode: "CA",
    stateName: "California",
    city: "CASTRO VALLEY"
  },
  "94547": {
    stateCode: "CA",
    stateName: "California",
    city: "HERCULES"
  },
  "94549": {
    stateCode: "CA",
    stateName: "California",
    city: "LAFAYETTE"
  },
  "94550": {
    stateCode: "CA",
    stateName: "California",
    city: "LIVERMORE"
  },
  "94552": {
    stateCode: "CA",
    stateName: "California",
    city: "CASTRO VALLEY"
  },
  "94553": {
    stateCode: "CA",
    stateName: "California",
    city: "MARTINEZ"
  },
  "94555": {
    stateCode: "CA",
    stateName: "California",
    city: "FREMONT"
  },
  "94556": {
    stateCode: "CA",
    stateName: "California",
    city: "MORAGA"
  },
  "94558": {
    stateCode: "CA",
    stateName: "California",
    city: "NAPA"
  },
  "94559": {
    stateCode: "CA",
    stateName: "California",
    city: "NAPA"
  },
  "94560": {
    stateCode: "CA",
    stateName: "California",
    city: "NEWARK"
  },
  "94561": {
    stateCode: "CA",
    stateName: "California",
    city: "OAKLEY"
  },
  "94563": {
    stateCode: "CA",
    stateName: "California",
    city: "ORINDA"
  },
  "94564": {
    stateCode: "CA",
    stateName: "California",
    city: "PINOLE"
  },
  "94565": {
    stateCode: "CA",
    stateName: "California",
    city: "PITTSBURG"
  },
  "94566": {
    stateCode: "CA",
    stateName: "California",
    city: "PLEASANTON"
  },
  "94567": {
    stateCode: "CA",
    stateName: "California",
    city: "POPE VALLEY"
  },
  "94568": {
    stateCode: "CA",
    stateName: "California",
    city: "DUBLIN"
  },
  "94569": {
    stateCode: "CA",
    stateName: "California",
    city: "PORT COSTA"
  },
  "94571": {
    stateCode: "CA",
    stateName: "California",
    city: "RIO VISTA"
  },
  "94572": {
    stateCode: "CA",
    stateName: "California",
    city: "RODEO"
  },
  "94574": {
    stateCode: "CA",
    stateName: "California",
    city: "SAINT HELENA"
  },
  "94576": {
    stateCode: "CA",
    stateName: "California",
    city: "DEER PARK"
  },
  "94577": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN LEANDRO"
  },
  "94578": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN LEANDRO"
  },
  "94579": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN LEANDRO"
  },
  "94580": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN LORENZO"
  },
  "94583": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN RAMON"
  },
  "94585": {
    stateCode: "CA",
    stateName: "California",
    city: "SUISUN CITY"
  },
  "94586": {
    stateCode: "CA",
    stateName: "California",
    city: "SUNOL"
  },
  "94587": {
    stateCode: "CA",
    stateName: "California",
    city: "UNION CITY"
  },
  "94588": {
    stateCode: "CA",
    stateName: "California",
    city: "PLEASANTON"
  },
  "94589": {
    stateCode: "CA",
    stateName: "California",
    city: "VALLEJO"
  },
  "94590": {
    stateCode: "CA",
    stateName: "California",
    city: "VALLEJO"
  },
  "94591": {
    stateCode: "CA",
    stateName: "California",
    city: "VALLEJO"
  },
  "94592": {
    stateCode: "CA",
    stateName: "California",
    city: "VALLEJO"
  },
  "94595": {
    stateCode: "CA",
    stateName: "California",
    city: "WALNUT CREEK"
  },
  "94596": {
    stateCode: "CA",
    stateName: "California",
    city: "WALNUT CREEK"
  },
  "94598": {
    stateCode: "CA",
    stateName: "California",
    city: "WALNUT CREEK"
  },
  "94599": {
    stateCode: "CA",
    stateName: "California",
    city: "YOUNTVILLE"
  },
  "94601": {
    stateCode: "CA",
    stateName: "California",
    city: "OAKLAND"
  },
  "94602": {
    stateCode: "CA",
    stateName: "California",
    city: "OAKLAND"
  },
  "94603": {
    stateCode: "CA",
    stateName: "California",
    city: "OAKLAND"
  },
  "94605": {
    stateCode: "CA",
    stateName: "California",
    city: "OAKLAND"
  },
  "94606": {
    stateCode: "CA",
    stateName: "California",
    city: "OAKLAND"
  },
  "94607": {
    stateCode: "CA",
    stateName: "California",
    city: "OAKLAND"
  },
  "94608": {
    stateCode: "CA",
    stateName: "California",
    city: "EMERYVILLE"
  },
  "94609": {
    stateCode: "CA",
    stateName: "California",
    city: "OAKLAND"
  },
  "94610": {
    stateCode: "CA",
    stateName: "California",
    city: "OAKLAND"
  },
  "94611": {
    stateCode: "CA",
    stateName: "California",
    city: "OAKLAND"
  },
  "94612": {
    stateCode: "CA",
    stateName: "California",
    city: "OAKLAND"
  },
  "94618": {
    stateCode: "CA",
    stateName: "California",
    city: "OAKLAND"
  },
  "94619": {
    stateCode: "CA",
    stateName: "California",
    city: "OAKLAND"
  },
  "94621": {
    stateCode: "CA",
    stateName: "California",
    city: "OAKLAND"
  },
  "94702": {
    stateCode: "CA",
    stateName: "California",
    city: "BERKELEY"
  },
  "94703": {
    stateCode: "CA",
    stateName: "California",
    city: "BERKELEY"
  },
  "94704": {
    stateCode: "CA",
    stateName: "California",
    city: "BERKELEY"
  },
  "94705": {
    stateCode: "CA",
    stateName: "California",
    city: "BERKELEY"
  },
  "94706": {
    stateCode: "CA",
    stateName: "California",
    city: "ALBANY"
  },
  "94707": {
    stateCode: "CA",
    stateName: "California",
    city: "BERKELEY"
  },
  "94708": {
    stateCode: "CA",
    stateName: "California",
    city: "BERKELEY"
  },
  "94709": {
    stateCode: "CA",
    stateName: "California",
    city: "BERKELEY"
  },
  "94710": {
    stateCode: "CA",
    stateName: "California",
    city: "BERKELEY"
  },
  "94801": {
    stateCode: "CA",
    stateName: "California",
    city: "RICHMOND"
  },
  "94803": {
    stateCode: "CA",
    stateName: "California",
    city: "EL SOBRANTE"
  },
  "94804": {
    stateCode: "CA",
    stateName: "California",
    city: "RICHMOND"
  },
  "94805": {
    stateCode: "CA",
    stateName: "California",
    city: "RICHMOND"
  },
  "94806": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN PABLO"
  },
  "94901": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN RAFAEL"
  },
  "94903": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN RAFAEL"
  },
  "94904": {
    stateCode: "CA",
    stateName: "California",
    city: "GREENBRAE"
  },
  "94920": {
    stateCode: "CA",
    stateName: "California",
    city: "BELVEDERE TIBURON"
  },
  "94922": {
    stateCode: "CA",
    stateName: "California",
    city: "BODEGA"
  },
  "94923": {
    stateCode: "CA",
    stateName: "California",
    city: "BODEGA BAY"
  },
  "94924": {
    stateCode: "CA",
    stateName: "California",
    city: "BOLINAS"
  },
  "94925": {
    stateCode: "CA",
    stateName: "California",
    city: "CORTE MADERA"
  },
  "94928": {
    stateCode: "CA",
    stateName: "California",
    city: "ROHNERT PARK"
  },
  "94929": {
    stateCode: "CA",
    stateName: "California",
    city: "DILLON BEACH"
  },
  "94930": {
    stateCode: "CA",
    stateName: "California",
    city: "FAIRFAX"
  },
  "94931": {
    stateCode: "CA",
    stateName: "California",
    city: "COTATI"
  },
  "94933": {
    stateCode: "CA",
    stateName: "California",
    city: "FOREST KNOLLS"
  },
  "94937": {
    stateCode: "CA",
    stateName: "California",
    city: "INVERNESS"
  },
  "94938": {
    stateCode: "CA",
    stateName: "California",
    city: "LAGUNITAS"
  },
  "94939": {
    stateCode: "CA",
    stateName: "California",
    city: "LARKSPUR"
  },
  "94940": {
    stateCode: "CA",
    stateName: "California",
    city: "MARSHALL"
  },
  "94941": {
    stateCode: "CA",
    stateName: "California",
    city: "MILL VALLEY"
  },
  "94945": {
    stateCode: "CA",
    stateName: "California",
    city: "NOVATO"
  },
  "94946": {
    stateCode: "CA",
    stateName: "California",
    city: "NICASIO"
  },
  "94947": {
    stateCode: "CA",
    stateName: "California",
    city: "NOVATO"
  },
  "94949": {
    stateCode: "CA",
    stateName: "California",
    city: "NOVATO"
  },
  "94950": {
    stateCode: "CA",
    stateName: "California",
    city: "OLEMA"
  },
  "94951": {
    stateCode: "CA",
    stateName: "California",
    city: "PENNGROVE"
  },
  "94952": {
    stateCode: "CA",
    stateName: "California",
    city: "PETALUMA"
  },
  "94954": {
    stateCode: "CA",
    stateName: "California",
    city: "PETALUMA"
  },
  "94956": {
    stateCode: "CA",
    stateName: "California",
    city: "POINT REYES STATION"
  },
  "94960": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN ANSELMO"
  },
  "94963": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN GERONIMO"
  },
  "94964": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN QUENTIN"
  },
  "94965": {
    stateCode: "CA",
    stateName: "California",
    city: "SAUSALITO"
  },
  "94970": {
    stateCode: "CA",
    stateName: "California",
    city: "STINSON BEACH"
  },
  "94971": {
    stateCode: "CA",
    stateName: "California",
    city: "TOMALES"
  },
  "94972": {
    stateCode: "CA",
    stateName: "California",
    city: "VALLEY FORD"
  },
  "94973": {
    stateCode: "CA",
    stateName: "California",
    city: "WOODACRE"
  },
  "95002": {
    stateCode: "CA",
    stateName: "California",
    city: "ALVISO"
  },
  "95003": {
    stateCode: "CA",
    stateName: "California",
    city: "APTOS"
  },
  "95004": {
    stateCode: "CA",
    stateName: "California",
    city: "AROMAS"
  },
  "95005": {
    stateCode: "CA",
    stateName: "California",
    city: "BEN LOMOND"
  },
  "95006": {
    stateCode: "CA",
    stateName: "California",
    city: "BOULDER CREEK"
  },
  "95007": {
    stateCode: "CA",
    stateName: "California",
    city: "BROOKDALE"
  },
  "95008": {
    stateCode: "CA",
    stateName: "California",
    city: "CAMPBELL"
  },
  "95010": {
    stateCode: "CA",
    stateName: "California",
    city: "CAPITOLA"
  },
  "95012": {
    stateCode: "CA",
    stateName: "California",
    city: "CASTROVILLE"
  },
  "95014": {
    stateCode: "CA",
    stateName: "California",
    city: "CUPERTINO"
  },
  "95017": {
    stateCode: "CA",
    stateName: "California",
    city: "DAVENPORT"
  },
  "95018": {
    stateCode: "CA",
    stateName: "California",
    city: "FELTON"
  },
  "95019": {
    stateCode: "CA",
    stateName: "California",
    city: "FREEDOM"
  },
  "95020": {
    stateCode: "CA",
    stateName: "California",
    city: "GILROY"
  },
  "95023": {
    stateCode: "CA",
    stateName: "California",
    city: "HOLLISTER"
  },
  "95030": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS GATOS"
  },
  "95032": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS GATOS"
  },
  "95033": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS GATOS"
  },
  "95035": {
    stateCode: "CA",
    stateName: "California",
    city: "MILPITAS"
  },
  "95037": {
    stateCode: "CA",
    stateName: "California",
    city: "MORGAN HILL"
  },
  "95039": {
    stateCode: "CA",
    stateName: "California",
    city: "MOSS LANDING"
  },
  "95041": {
    stateCode: "CA",
    stateName: "California",
    city: "MOUNT HERMON"
  },
  "95043": {
    stateCode: "CA",
    stateName: "California",
    city: "PAICINES"
  },
  "95045": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JUAN BAUTISTA"
  },
  "95046": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN MARTIN"
  },
  "95050": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA CLARA"
  },
  "95051": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA CLARA"
  },
  "95054": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA CLARA"
  },
  "95060": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA CRUZ"
  },
  "95062": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA CRUZ"
  },
  "95064": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA CRUZ"
  },
  "95065": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA CRUZ"
  },
  "95066": {
    stateCode: "CA",
    stateName: "California",
    city: "SCOTTS VALLEY"
  },
  "95070": {
    stateCode: "CA",
    stateName: "California",
    city: "SARATOGA"
  },
  "95073": {
    stateCode: "CA",
    stateName: "California",
    city: "SOQUEL"
  },
  "95076": {
    stateCode: "CA",
    stateName: "California",
    city: "WATSONVILLE"
  },
  "95110": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95111": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95112": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95113": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95116": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95117": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95118": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95119": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95120": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95121": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95122": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95123": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95124": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95125": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95126": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95127": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95128": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95129": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95130": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95131": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95132": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95133": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95134": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95135": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95136": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95138": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95139": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95140": {
    stateCode: "CA",
    stateName: "California",
    city: "MOUNT HAMILTON"
  },
  "95148": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN JOSE"
  },
  "95202": {
    stateCode: "CA",
    stateName: "California",
    city: "STOCKTON"
  },
  "95203": {
    stateCode: "CA",
    stateName: "California",
    city: "STOCKTON"
  },
  "95204": {
    stateCode: "CA",
    stateName: "California",
    city: "STOCKTON"
  },
  "95205": {
    stateCode: "CA",
    stateName: "California",
    city: "STOCKTON"
  },
  "95206": {
    stateCode: "CA",
    stateName: "California",
    city: "STOCKTON"
  },
  "95207": {
    stateCode: "CA",
    stateName: "California",
    city: "STOCKTON"
  },
  "95209": {
    stateCode: "CA",
    stateName: "California",
    city: "STOCKTON"
  },
  "95210": {
    stateCode: "CA",
    stateName: "California",
    city: "STOCKTON"
  },
  "95212": {
    stateCode: "CA",
    stateName: "California",
    city: "STOCKTON"
  },
  "95215": {
    stateCode: "CA",
    stateName: "California",
    city: "STOCKTON"
  },
  "95219": {
    stateCode: "CA",
    stateName: "California",
    city: "STOCKTON"
  },
  "95220": {
    stateCode: "CA",
    stateName: "California",
    city: "ACAMPO"
  },
  "95221": {
    stateCode: "CA",
    stateName: "California",
    city: "ALTAVILLE"
  },
  "95222": {
    stateCode: "CA",
    stateName: "California",
    city: "ANGELS CAMP"
  },
  "95223": {
    stateCode: "CA",
    stateName: "California",
    city: "ARNOLD"
  },
  "95224": {
    stateCode: "CA",
    stateName: "California",
    city: "AVERY"
  },
  "95225": {
    stateCode: "CA",
    stateName: "California",
    city: "BURSON"
  },
  "95226": {
    stateCode: "CA",
    stateName: "California",
    city: "CAMPO SECO"
  },
  "95227": {
    stateCode: "CA",
    stateName: "California",
    city: "CLEMENTS"
  },
  "95228": {
    stateCode: "CA",
    stateName: "California",
    city: "COPPEROPOLIS"
  },
  "95230": {
    stateCode: "CA",
    stateName: "California",
    city: "FARMINGTON"
  },
  "95231": {
    stateCode: "CA",
    stateName: "California",
    city: "FRENCH CAMP"
  },
  "95232": {
    stateCode: "CA",
    stateName: "California",
    city: "GLENCOE"
  },
  "95233": {
    stateCode: "CA",
    stateName: "California",
    city: "HATHAWAY PINES"
  },
  "95236": {
    stateCode: "CA",
    stateName: "California",
    city: "LINDEN"
  },
  "95237": {
    stateCode: "CA",
    stateName: "California",
    city: "LOCKEFORD"
  },
  "95240": {
    stateCode: "CA",
    stateName: "California",
    city: "LODI"
  },
  "95242": {
    stateCode: "CA",
    stateName: "California",
    city: "LODI"
  },
  "95245": {
    stateCode: "CA",
    stateName: "California",
    city: "MOKELUMNE HILL"
  },
  "95246": {
    stateCode: "CA",
    stateName: "California",
    city: "MOUNTAIN RANCH"
  },
  "95247": {
    stateCode: "CA",
    stateName: "California",
    city: "MURPHYS"
  },
  "95249": {
    stateCode: "CA",
    stateName: "California",
    city: "SAN ANDREAS"
  },
  "95250": {
    stateCode: "CA",
    stateName: "California",
    city: "SHEEP RANCH"
  },
  "95251": {
    stateCode: "CA",
    stateName: "California",
    city: "VALLECITO"
  },
  "95252": {
    stateCode: "CA",
    stateName: "California",
    city: "VALLEY SPRINGS"
  },
  "95254": {
    stateCode: "CA",
    stateName: "California",
    city: "WALLACE"
  },
  "95255": {
    stateCode: "CA",
    stateName: "California",
    city: "WEST POINT"
  },
  "95257": {
    stateCode: "CA",
    stateName: "California",
    city: "WILSEYVILLE"
  },
  "95258": {
    stateCode: "CA",
    stateName: "California",
    city: "WOODBRIDGE"
  },
  "95301": {
    stateCode: "CA",
    stateName: "California",
    city: "ATWATER"
  },
  "95303": {
    stateCode: "CA",
    stateName: "California",
    city: "BALLICO"
  },
  "95305": {
    stateCode: "CA",
    stateName: "California",
    city: "BIG OAK FLAT"
  },
  "95306": {
    stateCode: "CA",
    stateName: "California",
    city: "CATHEYS VALLEY"
  },
  "95307": {
    stateCode: "CA",
    stateName: "California",
    city: "CERES"
  },
  "95310": {
    stateCode: "CA",
    stateName: "California",
    city: "COLUMBIA"
  },
  "95311": {
    stateCode: "CA",
    stateName: "California",
    city: "COULTERVILLE"
  },
  "95312": {
    stateCode: "CA",
    stateName: "California",
    city: "CRESSEY"
  },
  "95313": {
    stateCode: "CA",
    stateName: "California",
    city: "CROWS LANDING"
  },
  "95315": {
    stateCode: "CA",
    stateName: "California",
    city: "DELHI"
  },
  "95316": {
    stateCode: "CA",
    stateName: "California",
    city: "DENAIR"
  },
  "95317": {
    stateCode: "CA",
    stateName: "California",
    city: "EL NIDO"
  },
  "95318": {
    stateCode: "CA",
    stateName: "California",
    city: "EL PORTAL"
  },
  "95320": {
    stateCode: "CA",
    stateName: "California",
    city: "ESCALON"
  },
  "95321": {
    stateCode: "CA",
    stateName: "California",
    city: "GROVELAND"
  },
  "95322": {
    stateCode: "CA",
    stateName: "California",
    city: "GUSTINE"
  },
  "95323": {
    stateCode: "CA",
    stateName: "California",
    city: "HICKMAN"
  },
  "95324": {
    stateCode: "CA",
    stateName: "California",
    city: "HILMAR"
  },
  "95325": {
    stateCode: "CA",
    stateName: "California",
    city: "HORNITOS"
  },
  "95326": {
    stateCode: "CA",
    stateName: "California",
    city: "HUGHSON"
  },
  "95327": {
    stateCode: "CA",
    stateName: "California",
    city: "JAMESTOWN"
  },
  "95328": {
    stateCode: "CA",
    stateName: "California",
    city: "KEYES"
  },
  "95329": {
    stateCode: "CA",
    stateName: "California",
    city: "LA GRANGE"
  },
  "95330": {
    stateCode: "CA",
    stateName: "California",
    city: "LATHROP"
  },
  "95333": {
    stateCode: "CA",
    stateName: "California",
    city: "LE GRAND"
  },
  "95334": {
    stateCode: "CA",
    stateName: "California",
    city: "LIVINGSTON"
  },
  "95335": {
    stateCode: "CA",
    stateName: "California",
    city: "LONG BARN"
  },
  "95336": {
    stateCode: "CA",
    stateName: "California",
    city: "MANTECA"
  },
  "95337": {
    stateCode: "CA",
    stateName: "California",
    city: "MANTECA"
  },
  "95338": {
    stateCode: "CA",
    stateName: "California",
    city: "MARIPOSA"
  },
  "95340": {
    stateCode: "CA",
    stateName: "California",
    city: "MERCED"
  },
  "95345": {
    stateCode: "CA",
    stateName: "California",
    city: "MIDPINES"
  },
  "95346": {
    stateCode: "CA",
    stateName: "California",
    city: "MI WUK VILLAGE"
  },
  "95348": {
    stateCode: "CA",
    stateName: "California",
    city: "MERCED"
  },
  "95350": {
    stateCode: "CA",
    stateName: "California",
    city: "MODESTO"
  },
  "95351": {
    stateCode: "CA",
    stateName: "California",
    city: "MODESTO"
  },
  "95354": {
    stateCode: "CA",
    stateName: "California",
    city: "MODESTO"
  },
  "95355": {
    stateCode: "CA",
    stateName: "California",
    city: "MODESTO"
  },
  "95356": {
    stateCode: "CA",
    stateName: "California",
    city: "MODESTO"
  },
  "95357": {
    stateCode: "CA",
    stateName: "California",
    city: "MODESTO"
  },
  "95358": {
    stateCode: "CA",
    stateName: "California",
    city: "MODESTO"
  },
  "95360": {
    stateCode: "CA",
    stateName: "California",
    city: "NEWMAN"
  },
  "95361": {
    stateCode: "CA",
    stateName: "California",
    city: "OAKDALE"
  },
  "95363": {
    stateCode: "CA",
    stateName: "California",
    city: "PATTERSON"
  },
  "95364": {
    stateCode: "CA",
    stateName: "California",
    city: "PINECREST"
  },
  "95365": {
    stateCode: "CA",
    stateName: "California",
    city: "PLANADA"
  },
  "95366": {
    stateCode: "CA",
    stateName: "California",
    city: "RIPON"
  },
  "95367": {
    stateCode: "CA",
    stateName: "California",
    city: "RIVERBANK"
  },
  "95368": {
    stateCode: "CA",
    stateName: "California",
    city: "SALIDA"
  },
  "95369": {
    stateCode: "CA",
    stateName: "California",
    city: "SNELLING"
  },
  "95370": {
    stateCode: "CA",
    stateName: "California",
    city: "SONORA"
  },
  "95372": {
    stateCode: "CA",
    stateName: "California",
    city: "SOULSBYVILLE"
  },
  "95374": {
    stateCode: "CA",
    stateName: "California",
    city: "STEVINSON"
  },
  "95376": {
    stateCode: "CA",
    stateName: "California",
    city: "TRACY"
  },
  "95379": {
    stateCode: "CA",
    stateName: "California",
    city: "TUOLUMNE"
  },
  "95380": {
    stateCode: "CA",
    stateName: "California",
    city: "TURLOCK"
  },
  "95382": {
    stateCode: "CA",
    stateName: "California",
    city: "TURLOCK"
  },
  "95383": {
    stateCode: "CA",
    stateName: "California",
    city: "TWAIN HARTE"
  },
  "95385": {
    stateCode: "CA",
    stateName: "California",
    city: "VERNALIS"
  },
  "95386": {
    stateCode: "CA",
    stateName: "California",
    city: "WATERFORD"
  },
  "95387": {
    stateCode: "CA",
    stateName: "California",
    city: "WESTLEY"
  },
  "95388": {
    stateCode: "CA",
    stateName: "California",
    city: "WINTON"
  },
  "95389": {
    stateCode: "CA",
    stateName: "California",
    city: "YOSEMITE NATIONAL PARK"
  },
  "95401": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA ROSA"
  },
  "95403": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA ROSA"
  },
  "95404": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA ROSA"
  },
  "95405": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA ROSA"
  },
  "95407": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA ROSA"
  },
  "95409": {
    stateCode: "CA",
    stateName: "California",
    city: "SANTA ROSA"
  },
  "95410": {
    stateCode: "CA",
    stateName: "California",
    city: "ALBION"
  },
  "95412": {
    stateCode: "CA",
    stateName: "California",
    city: "ANNAPOLIS"
  },
  "95415": {
    stateCode: "CA",
    stateName: "California",
    city: "BOONVILLE"
  },
  "95417": {
    stateCode: "CA",
    stateName: "California",
    city: "BRANSCOMB"
  },
  "95419": {
    stateCode: "CA",
    stateName: "California",
    city: "CAMP MEEKER"
  },
  "95420": {
    stateCode: "CA",
    stateName: "California",
    city: "CASPAR"
  },
  "95421": {
    stateCode: "CA",
    stateName: "California",
    city: "CAZADERO"
  },
  "95422": {
    stateCode: "CA",
    stateName: "California",
    city: "CLEARLAKE"
  },
  "95423": {
    stateCode: "CA",
    stateName: "California",
    city: "CLEARLAKE OAKS"
  },
  "95424": {
    stateCode: "CA",
    stateName: "California",
    city: "CLEARLAKE PARK"
  },
  "95425": {
    stateCode: "CA",
    stateName: "California",
    city: "CLOVERDALE"
  },
  "95426": {
    stateCode: "CA",
    stateName: "California",
    city: "COBB"
  },
  "95427": {
    stateCode: "CA",
    stateName: "California",
    city: "COMPTCHE"
  },
  "95428": {
    stateCode: "CA",
    stateName: "California",
    city: "COVELO"
  },
  "95429": {
    stateCode: "CA",
    stateName: "California",
    city: "DOS RIOS"
  },
  "95430": {
    stateCode: "CA",
    stateName: "California",
    city: "DUNCANS MILLS"
  },
  "95432": {
    stateCode: "CA",
    stateName: "California",
    city: "ELK"
  },
  "95436": {
    stateCode: "CA",
    stateName: "California",
    city: "FORESTVILLE"
  },
  "95437": {
    stateCode: "CA",
    stateName: "California",
    city: "FORT BRAGG"
  },
  "95439": {
    stateCode: "CA",
    stateName: "California",
    city: "FULTON"
  },
  "95441": {
    stateCode: "CA",
    stateName: "California",
    city: "GEYSERVILLE"
  },
  "95442": {
    stateCode: "CA",
    stateName: "California",
    city: "GLEN ELLEN"
  },
  "95443": {
    stateCode: "CA",
    stateName: "California",
    city: "GLENHAVEN"
  },
  "95444": {
    stateCode: "CA",
    stateName: "California",
    city: "GRATON"
  },
  "95445": {
    stateCode: "CA",
    stateName: "California",
    city: "GUALALA"
  },
  "95446": {
    stateCode: "CA",
    stateName: "California",
    city: "GUERNEVILLE"
  },
  "95448": {
    stateCode: "CA",
    stateName: "California",
    city: "HEALDSBURG"
  },
  "95449": {
    stateCode: "CA",
    stateName: "California",
    city: "HOPLAND"
  },
  "95450": {
    stateCode: "CA",
    stateName: "California",
    city: "JENNER"
  },
  "95451": {
    stateCode: "CA",
    stateName: "California",
    city: "KELSEYVILLE"
  },
  "95452": {
    stateCode: "CA",
    stateName: "California",
    city: "KENWOOD"
  },
  "95453": {
    stateCode: "CA",
    stateName: "California",
    city: "LAKEPORT"
  },
  "95454": {
    stateCode: "CA",
    stateName: "California",
    city: "LAYTONVILLE"
  },
  "95456": {
    stateCode: "CA",
    stateName: "California",
    city: "LITTLE RIVER"
  },
  "95457": {
    stateCode: "CA",
    stateName: "California",
    city: "LOWER LAKE"
  },
  "95458": {
    stateCode: "CA",
    stateName: "California",
    city: "LUCERNE"
  },
  "95459": {
    stateCode: "CA",
    stateName: "California",
    city: "MANCHESTER"
  },
  "95460": {
    stateCode: "CA",
    stateName: "California",
    city: "MENDOCINO"
  },
  "95461": {
    stateCode: "CA",
    stateName: "California",
    city: "MIDDLETOWN"
  },
  "95462": {
    stateCode: "CA",
    stateName: "California",
    city: "MONTE RIO"
  },
  "95463": {
    stateCode: "CA",
    stateName: "California",
    city: "NAVARRO"
  },
  "95464": {
    stateCode: "CA",
    stateName: "California",
    city: "NICE"
  },
  "95465": {
    stateCode: "CA",
    stateName: "California",
    city: "OCCIDENTAL"
  },
  "95466": {
    stateCode: "CA",
    stateName: "California",
    city: "PHILO"
  },
  "95468": {
    stateCode: "CA",
    stateName: "California",
    city: "POINT ARENA"
  },
  "95469": {
    stateCode: "CA",
    stateName: "California",
    city: "POTTER VALLEY"
  },
  "95470": {
    stateCode: "CA",
    stateName: "California",
    city: "REDWOOD VALLEY"
  },
  "95471": {
    stateCode: "CA",
    stateName: "California",
    city: "RIO NIDO"
  },
  "95472": {
    stateCode: "CA",
    stateName: "California",
    city: "SEBASTOPOL"
  },
  "95476": {
    stateCode: "CA",
    stateName: "California",
    city: "SONOMA"
  },
  "95480": {
    stateCode: "CA",
    stateName: "California",
    city: "STEWARTS POINT"
  },
  "95481": {
    stateCode: "CA",
    stateName: "California",
    city: "TALMAGE"
  },
  "95482": {
    stateCode: "CA",
    stateName: "California",
    city: "UKIAH"
  },
  "95485": {
    stateCode: "CA",
    stateName: "California",
    city: "UPPER LAKE"
  },
  "95488": {
    stateCode: "CA",
    stateName: "California",
    city: "WESTPORT"
  },
  "95490": {
    stateCode: "CA",
    stateName: "California",
    city: "WILLITS"
  },
  "95492": {
    stateCode: "CA",
    stateName: "California",
    city: "WINDSOR"
  },
  "95493": {
    stateCode: "CA",
    stateName: "California",
    city: "WITTER SPRINGS"
  },
  "95494": {
    stateCode: "CA",
    stateName: "California",
    city: "YORKVILLE"
  },
  "95497": {
    stateCode: "CA",
    stateName: "California",
    city: "THE SEA RANCH"
  },
  "95501": {
    stateCode: "CA",
    stateName: "California",
    city: "EUREKA"
  },
  "95503": {
    stateCode: "CA",
    stateName: "California",
    city: "EUREKA"
  },
  "95511": {
    stateCode: "CA",
    stateName: "California",
    city: "ALDERPOINT"
  },
  "95514": {
    stateCode: "CA",
    stateName: "California",
    city: "BLOCKSBURG"
  },
  "95519": {
    stateCode: "CA",
    stateName: "California",
    city: "MCKINLEYVILLE"
  },
  "95521": {
    stateCode: "CA",
    stateName: "California",
    city: "ARCATA"
  },
  "95524": {
    stateCode: "CA",
    stateName: "California",
    city: "BAYSIDE"
  },
  "95525": {
    stateCode: "CA",
    stateName: "California",
    city: "BLUE LAKE"
  },
  "95526": {
    stateCode: "CA",
    stateName: "California",
    city: "BRIDGEVILLE"
  },
  "95527": {
    stateCode: "CA",
    stateName: "California",
    city: "BURNT RANCH"
  },
  "95528": {
    stateCode: "CA",
    stateName: "California",
    city: "CARLOTTA"
  },
  "95531": {
    stateCode: "CA",
    stateName: "California",
    city: "CRESCENT CITY"
  },
  "95536": {
    stateCode: "CA",
    stateName: "California",
    city: "FERNDALE"
  },
  "95537": {
    stateCode: "CA",
    stateName: "California",
    city: "FIELDS LANDING"
  },
  "95540": {
    stateCode: "CA",
    stateName: "California",
    city: "FORTUNA"
  },
  "95542": {
    stateCode: "CA",
    stateName: "California",
    city: "GARBERVILLE"
  },
  "95543": {
    stateCode: "CA",
    stateName: "California",
    city: "GASQUET"
  },
  "95545": {
    stateCode: "CA",
    stateName: "California",
    city: "HONEYDEW"
  },
  "95546": {
    stateCode: "CA",
    stateName: "California",
    city: "HOOPA"
  },
  "95547": {
    stateCode: "CA",
    stateName: "California",
    city: "HYDESVILLE"
  },
  "95548": {
    stateCode: "CA",
    stateName: "California",
    city: "KLAMATH"
  },
  "95549": {
    stateCode: "CA",
    stateName: "California",
    city: "KNEELAND"
  },
  "95550": {
    stateCode: "CA",
    stateName: "California",
    city: "KORBEL"
  },
  "95551": {
    stateCode: "CA",
    stateName: "California",
    city: "LOLETA"
  },
  "95552": {
    stateCode: "CA",
    stateName: "California",
    city: "MAD RIVER"
  },
  "95553": {
    stateCode: "CA",
    stateName: "California",
    city: "MIRANDA"
  },
  "95554": {
    stateCode: "CA",
    stateName: "California",
    city: "MYERS FLAT"
  },
  "95555": {
    stateCode: "CA",
    stateName: "California",
    city: "ORICK"
  },
  "95556": {
    stateCode: "CA",
    stateName: "California",
    city: "ORLEANS"
  },
  "95558": {
    stateCode: "CA",
    stateName: "California",
    city: "PETROLIA"
  },
  "95559": {
    stateCode: "CA",
    stateName: "California",
    city: "PHILLIPSVILLE"
  },
  "95560": {
    stateCode: "CA",
    stateName: "California",
    city: "REDWAY"
  },
  "95562": {
    stateCode: "CA",
    stateName: "California",
    city: "RIO DELL"
  },
  "95563": {
    stateCode: "CA",
    stateName: "California",
    city: "SALYER"
  },
  "95564": {
    stateCode: "CA",
    stateName: "California",
    city: "SAMOA"
  },
  "95565": {
    stateCode: "CA",
    stateName: "California",
    city: "SCOTIA"
  },
  "95567": {
    stateCode: "CA",
    stateName: "California",
    city: "SMITH RIVER"
  },
  "95568": {
    stateCode: "CA",
    stateName: "California",
    city: "SOMES BAR"
  },
  "95569": {
    stateCode: "CA",
    stateName: "California",
    city: "REDCREST"
  },
  "95570": {
    stateCode: "CA",
    stateName: "California",
    city: "TRINIDAD"
  },
  "95571": {
    stateCode: "CA",
    stateName: "California",
    city: "WEOTT"
  },
  "95573": {
    stateCode: "CA",
    stateName: "California",
    city: "WILLOW CREEK"
  },
  "95585": {
    stateCode: "CA",
    stateName: "California",
    city: "LEGGETT"
  },
  "95587": {
    stateCode: "CA",
    stateName: "California",
    city: "PIERCY"
  },
  "95589": {
    stateCode: "CA",
    stateName: "California",
    city: "WHITETHORN"
  },
  "95595": {
    stateCode: "CA",
    stateName: "California",
    city: "ZENIA"
  },
  "95601": {
    stateCode: "CA",
    stateName: "California",
    city: "AMADOR CITY"
  },
  "95602": {
    stateCode: "CA",
    stateName: "California",
    city: "AUBURN"
  },
  "95603": {
    stateCode: "CA",
    stateName: "California",
    city: "AUBURN"
  },
  "95605": {
    stateCode: "CA",
    stateName: "California",
    city: "WEST SACRAMENTO"
  },
  "95606": {
    stateCode: "CA",
    stateName: "California",
    city: "BROOKS"
  },
  "95607": {
    stateCode: "CA",
    stateName: "California",
    city: "CAPAY"
  },
  "95608": {
    stateCode: "CA",
    stateName: "California",
    city: "CARMICHAEL"
  },
  "95610": {
    stateCode: "CA",
    stateName: "California",
    city: "CITRUS HEIGHTS"
  },
  "95612": {
    stateCode: "CA",
    stateName: "California",
    city: "CLARKSBURG"
  },
  "95613": {
    stateCode: "CA",
    stateName: "California",
    city: "COLOMA"
  },
  "95614": {
    stateCode: "CA",
    stateName: "California",
    city: "COOL"
  },
  "95615": {
    stateCode: "CA",
    stateName: "California",
    city: "COURTLAND"
  },
  "95616": {
    stateCode: "CA",
    stateName: "California",
    city: "DAVIS"
  },
  "95618": {
    stateCode: "CA",
    stateName: "California",
    city: "DAVIS"
  },
  "95619": {
    stateCode: "CA",
    stateName: "California",
    city: "DIAMOND SPRINGS"
  },
  "95620": {
    stateCode: "CA",
    stateName: "California",
    city: "DIXON"
  },
  "95621": {
    stateCode: "CA",
    stateName: "California",
    city: "CITRUS HEIGHTS"
  },
  "95623": {
    stateCode: "CA",
    stateName: "California",
    city: "EL DORADO"
  },
  "95624": {
    stateCode: "CA",
    stateName: "California",
    city: "ELK GROVE"
  },
  "95625": {
    stateCode: "CA",
    stateName: "California",
    city: "ELMIRA"
  },
  "95626": {
    stateCode: "CA",
    stateName: "California",
    city: "ELVERTA"
  },
  "95627": {
    stateCode: "CA",
    stateName: "California",
    city: "ESPARTO"
  },
  "95628": {
    stateCode: "CA",
    stateName: "California",
    city: "FAIR OAKS"
  },
  "95629": {
    stateCode: "CA",
    stateName: "California",
    city: "FIDDLETOWN"
  },
  "95630": {
    stateCode: "CA",
    stateName: "California",
    city: "FOLSOM"
  },
  "95631": {
    stateCode: "CA",
    stateName: "California",
    city: "FORESTHILL"
  },
  "95632": {
    stateCode: "CA",
    stateName: "California",
    city: "GALT"
  },
  "95633": {
    stateCode: "CA",
    stateName: "California",
    city: "GARDEN VALLEY"
  },
  "95634": {
    stateCode: "CA",
    stateName: "California",
    city: "GEORGETOWN"
  },
  "95635": {
    stateCode: "CA",
    stateName: "California",
    city: "GREENWOOD"
  },
  "95636": {
    stateCode: "CA",
    stateName: "California",
    city: "GRIZZLY FLATS"
  },
  "95637": {
    stateCode: "CA",
    stateName: "California",
    city: "GUINDA"
  },
  "95638": {
    stateCode: "CA",
    stateName: "California",
    city: "HERALD"
  },
  "95639": {
    stateCode: "CA",
    stateName: "California",
    city: "HOOD"
  },
  "95640": {
    stateCode: "CA",
    stateName: "California",
    city: "IONE"
  },
  "95641": {
    stateCode: "CA",
    stateName: "California",
    city: "ISLETON"
  },
  "95642": {
    stateCode: "CA",
    stateName: "California",
    city: "JACKSON"
  },
  "95645": {
    stateCode: "CA",
    stateName: "California",
    city: "KNIGHTS LANDING"
  },
  "95646": {
    stateCode: "CA",
    stateName: "California",
    city: "KIRKWOOD"
  },
  "95648": {
    stateCode: "CA",
    stateName: "California",
    city: "LINCOLN"
  },
  "95650": {
    stateCode: "CA",
    stateName: "California",
    city: "LOOMIS"
  },
  "95651": {
    stateCode: "CA",
    stateName: "California",
    city: "LOTUS"
  },
  "95653": {
    stateCode: "CA",
    stateName: "California",
    city: "MADISON"
  },
  "95655": {
    stateCode: "CA",
    stateName: "California",
    city: "MATHER"
  },
  "95658": {
    stateCode: "CA",
    stateName: "California",
    city: "NEWCASTLE"
  },
  "95659": {
    stateCode: "CA",
    stateName: "California",
    city: "NICOLAUS"
  },
  "95660": {
    stateCode: "CA",
    stateName: "California",
    city: "NORTH HIGHLANDS"
  },
  "95661": {
    stateCode: "CA",
    stateName: "California",
    city: "ROSEVILLE"
  },
  "95662": {
    stateCode: "CA",
    stateName: "California",
    city: "ORANGEVALE"
  },
  "95663": {
    stateCode: "CA",
    stateName: "California",
    city: "PENRYN"
  },
  "95664": {
    stateCode: "CA",
    stateName: "California",
    city: "PILOT HILL"
  },
  "95665": {
    stateCode: "CA",
    stateName: "California",
    city: "PINE GROVE"
  },
  "95666": {
    stateCode: "CA",
    stateName: "California",
    city: "PIONEER"
  },
  "95667": {
    stateCode: "CA",
    stateName: "California",
    city: "PLACERVILLE"
  },
  "95668": {
    stateCode: "CA",
    stateName: "California",
    city: "PLEASANT GROVE"
  },
  "95669": {
    stateCode: "CA",
    stateName: "California",
    city: "PLYMOUTH"
  },
  "95670": {
    stateCode: "CA",
    stateName: "California",
    city: "RANCHO CORDOVA"
  },
  "95672": {
    stateCode: "CA",
    stateName: "California",
    city: "RESCUE"
  },
  "95673": {
    stateCode: "CA",
    stateName: "California",
    city: "RIO LINDA"
  },
  "95674": {
    stateCode: "CA",
    stateName: "California",
    city: "RIO OSO"
  },
  "95675": {
    stateCode: "CA",
    stateName: "California",
    city: "RIVER PINES"
  },
  "95676": {
    stateCode: "CA",
    stateName: "California",
    city: "ROBBINS"
  },
  "95677": {
    stateCode: "CA",
    stateName: "California",
    city: "ROCKLIN"
  },
  "95678": {
    stateCode: "CA",
    stateName: "California",
    city: "ROSEVILLE"
  },
  "95681": {
    stateCode: "CA",
    stateName: "California",
    city: "SHERIDAN"
  },
  "95682": {
    stateCode: "CA",
    stateName: "California",
    city: "SHINGLE SPRINGS"
  },
  "95683": {
    stateCode: "CA",
    stateName: "California",
    city: "SLOUGHHOUSE"
  },
  "95684": {
    stateCode: "CA",
    stateName: "California",
    city: "SOMERSET"
  },
  "95685": {
    stateCode: "CA",
    stateName: "California",
    city: "SUTTER CREEK"
  },
  "95686": {
    stateCode: "CA",
    stateName: "California",
    city: "THORNTON"
  },
  "95687": {
    stateCode: "CA",
    stateName: "California",
    city: "VACAVILLE"
  },
  "95688": {
    stateCode: "CA",
    stateName: "California",
    city: "VACAVILLE"
  },
  "95689": {
    stateCode: "CA",
    stateName: "California",
    city: "VOLCANO"
  },
  "95690": {
    stateCode: "CA",
    stateName: "California",
    city: "WALNUT GROVE"
  },
  "95691": {
    stateCode: "CA",
    stateName: "California",
    city: "WEST SACRAMENTO"
  },
  "95692": {
    stateCode: "CA",
    stateName: "California",
    city: "WHEATLAND"
  },
  "95693": {
    stateCode: "CA",
    stateName: "California",
    city: "WILTON"
  },
  "95694": {
    stateCode: "CA",
    stateName: "California",
    city: "WINTERS"
  },
  "95695": {
    stateCode: "CA",
    stateName: "California",
    city: "WOODLAND"
  },
  "95701": {
    stateCode: "CA",
    stateName: "California",
    city: "ALTA"
  },
  "95703": {
    stateCode: "CA",
    stateName: "California",
    city: "APPLEGATE"
  },
  "95709": {
    stateCode: "CA",
    stateName: "California",
    city: "CAMINO"
  },
  "95713": {
    stateCode: "CA",
    stateName: "California",
    city: "COLFAX"
  },
  "95714": {
    stateCode: "CA",
    stateName: "California",
    city: "DUTCH FLAT"
  },
  "95715": {
    stateCode: "CA",
    stateName: "California",
    city: "EMIGRANT GAP"
  },
  "95717": {
    stateCode: "CA",
    stateName: "California",
    city: "GOLD RUN"
  },
  "95720": {
    stateCode: "CA",
    stateName: "California",
    city: "KYBURZ"
  },
  "95722": {
    stateCode: "CA",
    stateName: "California",
    city: "MEADOW VISTA"
  },
  "95726": {
    stateCode: "CA",
    stateName: "California",
    city: "POLLOCK PINES"
  },
  "95728": {
    stateCode: "CA",
    stateName: "California",
    city: "SODA SPRINGS"
  },
  "95735": {
    stateCode: "CA",
    stateName: "California",
    city: "TWIN BRIDGES"
  },
  "95736": {
    stateCode: "CA",
    stateName: "California",
    city: "WEIMAR"
  },
  "95742": {
    stateCode: "CA",
    stateName: "California",
    city: "RANCHO CORDOVA"
  },
  "95746": {
    stateCode: "CA",
    stateName: "California",
    city: "GRANITE BAY"
  },
  "95747": {
    stateCode: "CA",
    stateName: "California",
    city: "ROSEVILLE"
  },
  "95758": {
    stateCode: "CA",
    stateName: "California",
    city: "ELK GROVE"
  },
  "95762": {
    stateCode: "CA",
    stateName: "California",
    city: "EL DORADO HILLS"
  },
  "95765": {
    stateCode: "CA",
    stateName: "California",
    city: "ROCKLIN"
  },
  "95776": {
    stateCode: "CA",
    stateName: "California",
    city: "WOODLAND"
  },
  "95814": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95815": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95816": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95817": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95818": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95819": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95820": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95821": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95822": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95823": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95824": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95825": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95826": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95827": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95828": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95829": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95830": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95831": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95832": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95833": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95834": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95835": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95837": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95838": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95841": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95842": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95843": {
    stateCode: "CA",
    stateName: "California",
    city: "ANTELOPE"
  },
  "95864": {
    stateCode: "CA",
    stateName: "California",
    city: "SACRAMENTO"
  },
  "95901": {
    stateCode: "CA",
    stateName: "California",
    city: "MARYSVILLE"
  },
  "95903": {
    stateCode: "CA",
    stateName: "California",
    city: "BEALE AFB"
  },
  "95910": {
    stateCode: "CA",
    stateName: "California",
    city: "ALLEGHANY"
  },
  "95912": {
    stateCode: "CA",
    stateName: "California",
    city: "ARBUCKLE"
  },
  "95913": {
    stateCode: "CA",
    stateName: "California",
    city: "ARTOIS"
  },
  "95914": {
    stateCode: "CA",
    stateName: "California",
    city: "BANGOR"
  },
  "95916": {
    stateCode: "CA",
    stateName: "California",
    city: "BERRY CREEK"
  },
  "95917": {
    stateCode: "CA",
    stateName: "California",
    city: "BIGGS"
  },
  "95918": {
    stateCode: "CA",
    stateName: "California",
    city: "BROWNS VALLEY"
  },
  "95919": {
    stateCode: "CA",
    stateName: "California",
    city: "BROWNSVILLE"
  },
  "95920": {
    stateCode: "CA",
    stateName: "California",
    city: "BUTTE CITY"
  },
  "95922": {
    stateCode: "CA",
    stateName: "California",
    city: "CAMPTONVILLE"
  },
  "95923": {
    stateCode: "CA",
    stateName: "California",
    city: "CANYON DAM"
  },
  "95925": {
    stateCode: "CA",
    stateName: "California",
    city: "CHALLENGE"
  },
  "95926": {
    stateCode: "CA",
    stateName: "California",
    city: "CHICO"
  },
  "95928": {
    stateCode: "CA",
    stateName: "California",
    city: "CHICO"
  },
  "95930": {
    stateCode: "CA",
    stateName: "California",
    city: "CLIPPER MILLS"
  },
  "95932": {
    stateCode: "CA",
    stateName: "California",
    city: "COLUSA"
  },
  "95934": {
    stateCode: "CA",
    stateName: "California",
    city: "CRESCENT MILLS"
  },
  "95935": {
    stateCode: "CA",
    stateName: "California",
    city: "DOBBINS"
  },
  "95936": {
    stateCode: "CA",
    stateName: "California",
    city: "DOWNIEVILLE"
  },
  "95938": {
    stateCode: "CA",
    stateName: "California",
    city: "DURHAM"
  },
  "95939": {
    stateCode: "CA",
    stateName: "California",
    city: "ELK CREEK"
  },
  "95941": {
    stateCode: "CA",
    stateName: "California",
    city: "FORBESTOWN"
  },
  "95942": {
    stateCode: "CA",
    stateName: "California",
    city: "FOREST RANCH"
  },
  "95943": {
    stateCode: "CA",
    stateName: "California",
    city: "GLENN"
  },
  "95944": {
    stateCode: "CA",
    stateName: "California",
    city: "GOODYEARS BAR"
  },
  "95945": {
    stateCode: "CA",
    stateName: "California",
    city: "GRASS VALLEY"
  },
  "95946": {
    stateCode: "CA",
    stateName: "California",
    city: "PENN VALLEY"
  },
  "95947": {
    stateCode: "CA",
    stateName: "California",
    city: "GREENVILLE"
  },
  "95948": {
    stateCode: "CA",
    stateName: "California",
    city: "GRIDLEY"
  },
  "95949": {
    stateCode: "CA",
    stateName: "California",
    city: "GRASS VALLEY"
  },
  "95950": {
    stateCode: "CA",
    stateName: "California",
    city: "GRIMES"
  },
  "95951": {
    stateCode: "CA",
    stateName: "California",
    city: "HAMILTON CITY"
  },
  "95953": {
    stateCode: "CA",
    stateName: "California",
    city: "LIVE OAK"
  },
  "95954": {
    stateCode: "CA",
    stateName: "California",
    city: "MAGALIA"
  },
  "95955": {
    stateCode: "CA",
    stateName: "California",
    city: "MAXWELL"
  },
  "95956": {
    stateCode: "CA",
    stateName: "California",
    city: "MEADOW VALLEY"
  },
  "95957": {
    stateCode: "CA",
    stateName: "California",
    city: "MERIDIAN"
  },
  "95959": {
    stateCode: "CA",
    stateName: "California",
    city: "NEVADA CITY"
  },
  "95960": {
    stateCode: "CA",
    stateName: "California",
    city: "NORTH SAN JUAN"
  },
  "95961": {
    stateCode: "CA",
    stateName: "California",
    city: "OLIVEHURST"
  },
  "95962": {
    stateCode: "CA",
    stateName: "California",
    city: "OREGON HOUSE"
  },
  "95963": {
    stateCode: "CA",
    stateName: "California",
    city: "ORLAND"
  },
  "95965": {
    stateCode: "CA",
    stateName: "California",
    city: "OROVILLE"
  },
  "95966": {
    stateCode: "CA",
    stateName: "California",
    city: "OROVILLE"
  },
  "95968": {
    stateCode: "CA",
    stateName: "California",
    city: "PALERMO"
  },
  "95969": {
    stateCode: "CA",
    stateName: "California",
    city: "PARADISE"
  },
  "95970": {
    stateCode: "CA",
    stateName: "California",
    city: "PRINCETON"
  },
  "95971": {
    stateCode: "CA",
    stateName: "California",
    city: "QUINCY"
  },
  "95972": {
    stateCode: "CA",
    stateName: "California",
    city: "RACKERBY"
  },
  "95973": {
    stateCode: "CA",
    stateName: "California",
    city: "CHICO"
  },
  "95974": {
    stateCode: "CA",
    stateName: "California",
    city: "RICHVALE"
  },
  "95975": {
    stateCode: "CA",
    stateName: "California",
    city: "ROUGH AND READY"
  },
  "95977": {
    stateCode: "CA",
    stateName: "California",
    city: "SMARTVILLE"
  },
  "95978": {
    stateCode: "CA",
    stateName: "California",
    city: "STIRLING CITY"
  },
  "95979": {
    stateCode: "CA",
    stateName: "California",
    city: "STONYFORD"
  },
  "95981": {
    stateCode: "CA",
    stateName: "California",
    city: "STRAWBERRY VALLEY"
  },
  "95982": {
    stateCode: "CA",
    stateName: "California",
    city: "SUTTER"
  },
  "95983": {
    stateCode: "CA",
    stateName: "California",
    city: "TAYLORSVILLE"
  },
  "95984": {
    stateCode: "CA",
    stateName: "California",
    city: "TWAIN"
  },
  "95986": {
    stateCode: "CA",
    stateName: "California",
    city: "WASHINGTON"
  },
  "95987": {
    stateCode: "CA",
    stateName: "California",
    city: "WILLIAMS"
  },
  "95988": {
    stateCode: "CA",
    stateName: "California",
    city: "WILLOWS"
  },
  "95991": {
    stateCode: "CA",
    stateName: "California",
    city: "YUBA CITY"
  },
  "95993": {
    stateCode: "CA",
    stateName: "California",
    city: "YUBA CITY"
  },
  "96001": {
    stateCode: "CA",
    stateName: "California",
    city: "REDDING"
  },
  "96002": {
    stateCode: "CA",
    stateName: "California",
    city: "REDDING"
  },
  "96003": {
    stateCode: "CA",
    stateName: "California",
    city: "REDDING"
  },
  "96006": {
    stateCode: "CA",
    stateName: "California",
    city: "ADIN"
  },
  "96007": {
    stateCode: "CA",
    stateName: "California",
    city: "ANDERSON"
  },
  "96008": {
    stateCode: "CA",
    stateName: "California",
    city: "BELLA VISTA"
  },
  "96009": {
    stateCode: "CA",
    stateName: "California",
    city: "BIEBER"
  },
  "96010": {
    stateCode: "CA",
    stateName: "California",
    city: "BIG BAR"
  },
  "96011": {
    stateCode: "CA",
    stateName: "California",
    city: "BIG BEND"
  },
  "96013": {
    stateCode: "CA",
    stateName: "California",
    city: "BURNEY"
  },
  "96014": {
    stateCode: "CA",
    stateName: "California",
    city: "CALLAHAN"
  },
  "96015": {
    stateCode: "CA",
    stateName: "California",
    city: "CANBY"
  },
  "96016": {
    stateCode: "CA",
    stateName: "California",
    city: "CASSEL"
  },
  "96017": {
    stateCode: "CA",
    stateName: "California",
    city: "CASTELLA"
  },
  "96019": {
    stateCode: "CA",
    stateName: "California",
    city: "SHASTA LAKE"
  },
  "96020": {
    stateCode: "CA",
    stateName: "California",
    city: "CHESTER"
  },
  "96021": {
    stateCode: "CA",
    stateName: "California",
    city: "CORNING"
  },
  "96022": {
    stateCode: "CA",
    stateName: "California",
    city: "COTTONWOOD"
  },
  "96023": {
    stateCode: "CA",
    stateName: "California",
    city: "DORRIS"
  },
  "96024": {
    stateCode: "CA",
    stateName: "California",
    city: "DOUGLAS CITY"
  },
  "96025": {
    stateCode: "CA",
    stateName: "California",
    city: "DUNSMUIR"
  },
  "96027": {
    stateCode: "CA",
    stateName: "California",
    city: "ETNA"
  },
  "96028": {
    stateCode: "CA",
    stateName: "California",
    city: "FALL RIVER MILLS"
  },
  "96029": {
    stateCode: "CA",
    stateName: "California",
    city: "FLOURNOY"
  },
  "96031": {
    stateCode: "CA",
    stateName: "California",
    city: "FORKS OF SALMON"
  },
  "96032": {
    stateCode: "CA",
    stateName: "California",
    city: "FORT JONES"
  },
  "96033": {
    stateCode: "CA",
    stateName: "California",
    city: "FRENCH GULCH"
  },
  "96034": {
    stateCode: "CA",
    stateName: "California",
    city: "GAZELLE"
  },
  "96035": {
    stateCode: "CA",
    stateName: "California",
    city: "GERBER"
  },
  "96037": {
    stateCode: "CA",
    stateName: "California",
    city: "GREENVIEW"
  },
  "96038": {
    stateCode: "CA",
    stateName: "California",
    city: "GRENADA"
  },
  "96039": {
    stateCode: "CA",
    stateName: "California",
    city: "HAPPY CAMP"
  },
  "96040": {
    stateCode: "CA",
    stateName: "California",
    city: "HAT CREEK"
  },
  "96041": {
    stateCode: "CA",
    stateName: "California",
    city: "HAYFORK"
  },
  "96044": {
    stateCode: "CA",
    stateName: "California",
    city: "HORNBROOK"
  },
  "96046": {
    stateCode: "CA",
    stateName: "California",
    city: "HYAMPOM"
  },
  "96047": {
    stateCode: "CA",
    stateName: "California",
    city: "IGO"
  },
  "96048": {
    stateCode: "CA",
    stateName: "California",
    city: "JUNCTION CITY"
  },
  "96050": {
    stateCode: "CA",
    stateName: "California",
    city: "KLAMATH RIVER"
  },
  "96051": {
    stateCode: "CA",
    stateName: "California",
    city: "LAKEHEAD"
  },
  "96052": {
    stateCode: "CA",
    stateName: "California",
    city: "LEWISTON"
  },
  "96054": {
    stateCode: "CA",
    stateName: "California",
    city: "LOOKOUT"
  },
  "96055": {
    stateCode: "CA",
    stateName: "California",
    city: "LOS MOLINOS"
  },
  "96056": {
    stateCode: "CA",
    stateName: "California",
    city: "MCARTHUR"
  },
  "96057": {
    stateCode: "CA",
    stateName: "California",
    city: "MCCLOUD"
  },
  "96058": {
    stateCode: "CA",
    stateName: "California",
    city: "MACDOEL"
  },
  "96059": {
    stateCode: "CA",
    stateName: "California",
    city: "MANTON"
  },
  "96061": {
    stateCode: "CA",
    stateName: "California",
    city: "MILL CREEK"
  },
  "96062": {
    stateCode: "CA",
    stateName: "California",
    city: "MILLVILLE"
  },
  "96063": {
    stateCode: "CA",
    stateName: "California",
    city: "MINERAL"
  },
  "96064": {
    stateCode: "CA",
    stateName: "California",
    city: "MONTAGUE"
  },
  "96065": {
    stateCode: "CA",
    stateName: "California",
    city: "MONTGOMERY CREEK"
  },
  "96067": {
    stateCode: "CA",
    stateName: "California",
    city: "MOUNT SHASTA"
  },
  "96068": {
    stateCode: "CA",
    stateName: "California",
    city: "NUBIEBER"
  },
  "96069": {
    stateCode: "CA",
    stateName: "California",
    city: "OAK RUN"
  },
  "96071": {
    stateCode: "CA",
    stateName: "California",
    city: "OLD STATION"
  },
  "96073": {
    stateCode: "CA",
    stateName: "California",
    city: "PALO CEDRO"
  },
  "96074": {
    stateCode: "CA",
    stateName: "California",
    city: "PASKENTA"
  },
  "96075": {
    stateCode: "CA",
    stateName: "California",
    city: "PAYNES CREEK"
  },
  "96076": {
    stateCode: "CA",
    stateName: "California",
    city: "PLATINA"
  },
  "96080": {
    stateCode: "CA",
    stateName: "California",
    city: "RED BLUFF"
  },
  "96084": {
    stateCode: "CA",
    stateName: "California",
    city: "ROUND MOUNTAIN"
  },
  "96085": {
    stateCode: "CA",
    stateName: "California",
    city: "SCOTT BAR"
  },
  "96086": {
    stateCode: "CA",
    stateName: "California",
    city: "SEIAD VALLEY"
  },
  "96087": {
    stateCode: "CA",
    stateName: "California",
    city: "SHASTA"
  },
  "96088": {
    stateCode: "CA",
    stateName: "California",
    city: "SHINGLETOWN"
  },
  "96090": {
    stateCode: "CA",
    stateName: "California",
    city: "TEHAMA"
  },
  "96091": {
    stateCode: "CA",
    stateName: "California",
    city: "TRINITY CENTER"
  },
  "96092": {
    stateCode: "CA",
    stateName: "California",
    city: "VINA"
  },
  "96093": {
    stateCode: "CA",
    stateName: "California",
    city: "WEAVERVILLE"
  },
  "96094": {
    stateCode: "CA",
    stateName: "California",
    city: "WEED"
  },
  "96096": {
    stateCode: "CA",
    stateName: "California",
    city: "WHITMORE"
  },
  "96097": {
    stateCode: "CA",
    stateName: "California",
    city: "YREKA"
  },
  "96101": {
    stateCode: "CA",
    stateName: "California",
    city: "ALTURAS"
  },
  "96103": {
    stateCode: "CA",
    stateName: "California",
    city: "BLAIRSDEN-GRAEAGLE"
  },
  "96104": {
    stateCode: "CA",
    stateName: "California",
    city: "CEDARVILLE"
  },
  "96105": {
    stateCode: "CA",
    stateName: "California",
    city: "CHILCOOT"
  },
  "96106": {
    stateCode: "CA",
    stateName: "California",
    city: "CLIO"
  },
  "96107": {
    stateCode: "CA",
    stateName: "California",
    city: "COLEVILLE"
  },
  "96108": {
    stateCode: "CA",
    stateName: "California",
    city: "DAVIS CREEK"
  },
  "96109": {
    stateCode: "CA",
    stateName: "California",
    city: "DOYLE"
  },
  "96110": {
    stateCode: "CA",
    stateName: "California",
    city: "EAGLEVILLE"
  },
  "96112": {
    stateCode: "CA",
    stateName: "California",
    city: "FORT BIDWELL"
  },
  "96113": {
    stateCode: "CA",
    stateName: "California",
    city: "HERLONG"
  },
  "96114": {
    stateCode: "CA",
    stateName: "California",
    city: "JANESVILLE"
  },
  "96115": {
    stateCode: "CA",
    stateName: "California",
    city: "LAKE CITY"
  },
  "96116": {
    stateCode: "CA",
    stateName: "California",
    city: "LIKELY"
  },
  "96117": {
    stateCode: "CA",
    stateName: "California",
    city: "LITCHFIELD"
  },
  "96118": {
    stateCode: "CA",
    stateName: "California",
    city: "LOYALTON"
  },
  "96119": {
    stateCode: "CA",
    stateName: "California",
    city: "MADELINE"
  },
  "96120": {
    stateCode: "CA",
    stateName: "California",
    city: "MARKLEEVILLE"
  },
  "96121": {
    stateCode: "CA",
    stateName: "California",
    city: "MILFORD"
  },
  "96122": {
    stateCode: "CA",
    stateName: "California",
    city: "PORTOLA"
  },
  "96123": {
    stateCode: "CA",
    stateName: "California",
    city: "RAVENDALE"
  },
  "96124": {
    stateCode: "CA",
    stateName: "California",
    city: "CALPINE"
  },
  "96125": {
    stateCode: "CA",
    stateName: "California",
    city: "SIERRA CITY"
  },
  "96126": {
    stateCode: "CA",
    stateName: "California",
    city: "SIERRAVILLE"
  },
  "96128": {
    stateCode: "CA",
    stateName: "California",
    city: "STANDISH"
  },
  "96130": {
    stateCode: "CA",
    stateName: "California",
    city: "SUSANVILLE"
  },
  "96132": {
    stateCode: "CA",
    stateName: "California",
    city: "TERMO"
  },
  "96133": {
    stateCode: "CA",
    stateName: "California",
    city: "TOPAZ"
  },
  "96134": {
    stateCode: "CA",
    stateName: "California",
    city: "TULELAKE"
  },
  "96136": {
    stateCode: "CA",
    stateName: "California",
    city: "WENDEL"
  },
  "96137": {
    stateCode: "CA",
    stateName: "California",
    city: "WESTWOOD"
  },
  "96140": {
    stateCode: "CA",
    stateName: "California",
    city: "CARNELIAN BAY"
  },
  "96141": {
    stateCode: "CA",
    stateName: "California",
    city: "HOMEWOOD"
  },
  "96142": {
    stateCode: "CA",
    stateName: "California",
    city: "TAHOMA"
  },
  "96143": {
    stateCode: "CA",
    stateName: "California",
    city: "KINGS BEACH"
  },
  "96145": {
    stateCode: "CA",
    stateName: "California",
    city: "TAHOE CITY"
  },
  "96146": {
    stateCode: "CA",
    stateName: "California",
    city: "OLYMPIC VALLEY"
  },
  "96148": {
    stateCode: "CA",
    stateName: "California",
    city: "TAHOE VISTA"
  },
  "96150": {
    stateCode: "CA",
    stateName: "California",
    city: "SOUTH LAKE TAHOE"
  },
  "96161": {
    stateCode: "CA",
    stateName: "California",
    city: "TRUCKEE"
  },
  "96701": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "AIEA"
  },
  "96703": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "ANAHOLA"
  },
  "96704": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "CAPTAIN COOK"
  },
  "96705": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "ELEELE"
  },
  "96706": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "EWA BEACH"
  },
  "96707": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KAPOLEI"
  },
  "96708": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HAIKU"
  },
  "96710": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HAKALAU"
  },
  "96712": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HALEIWA"
  },
  "96713": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HANA"
  },
  "96719": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HAWI"
  },
  "96720": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HILO"
  },
  "96722": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "PRINCEVILLE"
  },
  "96725": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HOLUALOA"
  },
  "96727": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HONOKAA"
  },
  "96728": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HONOMU"
  },
  "96729": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HOOLEHUA"
  },
  "96730": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KAAAWA"
  },
  "96731": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KAHUKU"
  },
  "96732": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KAHULUI"
  },
  "96734": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KAILUA"
  },
  "96737": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "OCEAN VIEW"
  },
  "96738": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "WAIKOLOA"
  },
  "96740": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KAILUA KONA"
  },
  "96741": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KALAHEO"
  },
  "96742": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KALAUPAPA"
  },
  "96743": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KAMUELA"
  },
  "96744": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KANEOHE"
  },
  "96746": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KAPAA"
  },
  "96747": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KAUMAKANI"
  },
  "96748": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KAUNAKAKAI"
  },
  "96749": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KEAAU"
  },
  "96750": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KEALAKEKUA"
  },
  "96751": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KEALIA"
  },
  "96753": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KIHEI"
  },
  "96754": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KILAUEA"
  },
  "96755": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KAPAAU"
  },
  "96756": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KOLOA"
  },
  "96757": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KUALAPUU"
  },
  "96759": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KUNIA"
  },
  "96760": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KURTISTOWN"
  },
  "96761": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "LAHAINA"
  },
  "96762": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "LAIE"
  },
  "96763": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "LANAI CITY"
  },
  "96764": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "LAUPAHOEHOE"
  },
  "96766": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "LIHUE"
  },
  "96768": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "MAKAWAO"
  },
  "96769": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "MAKAWELI"
  },
  "96770": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "MAUNALOA"
  },
  "96773": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "NINOLE"
  },
  "96774": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "OOKALA"
  },
  "96776": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "PAAUILO"
  },
  "96777": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "PAHALA"
  },
  "96778": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "PAHOA"
  },
  "96779": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "PAIA"
  },
  "96780": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "PAPAALOA"
  },
  "96781": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "PAPAIKOU"
  },
  "96782": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "PEARL CITY"
  },
  "96783": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "PEPEEKEO"
  },
  "96785": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "VOLCANO"
  },
  "96786": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "WAHIAWA"
  },
  "96789": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "MILILANI"
  },
  "96790": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "KULA"
  },
  "96791": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "WAIALUA"
  },
  "96792": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "WAIANAE"
  },
  "96793": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "WAILUKU"
  },
  "96795": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "WAIMANALO"
  },
  "96797": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "WAIPAHU"
  },
  "96813": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HONOLULU"
  },
  "96814": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HONOLULU"
  },
  "96815": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HONOLULU"
  },
  "96816": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HONOLULU"
  },
  "96817": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HONOLULU"
  },
  "96818": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HONOLULU"
  },
  "96819": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HONOLULU"
  },
  "96821": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HONOLULU"
  },
  "96822": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HONOLULU"
  },
  "96825": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HONOLULU"
  },
  "96826": {
    stateCode: "HI",
    stateName: "Hawaii",
    city: "HONOLULU"
  },
  "97001": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ANTELOPE"
  },
  "97002": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "AURORA"
  },
  "97004": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BEAVERCREEK"
  },
  "97005": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BEAVERTON"
  },
  "97006": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BEAVERTON"
  },
  "97007": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BEAVERTON"
  },
  "97008": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BEAVERTON"
  },
  "97009": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BORING"
  },
  "97011": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BRIGHTWOOD"
  },
  "97013": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CANBY"
  },
  "97014": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CASCADE LOCKS"
  },
  "97015": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CLACKAMAS"
  },
  "97016": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CLATSKANIE"
  },
  "97017": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "COLTON"
  },
  "97018": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "COLUMBIA CITY"
  },
  "97019": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CORBETT"
  },
  "97020": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "DONALD"
  },
  "97021": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "DUFUR"
  },
  "97022": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "EAGLE CREEK"
  },
  "97023": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ESTACADA"
  },
  "97024": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "FAIRVIEW"
  },
  "97026": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GERVAIS"
  },
  "97027": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GLADSTONE"
  },
  "97028": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GOVERNMENT CAMP"
  },
  "97029": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GRASS VALLEY"
  },
  "97030": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GRESHAM"
  },
  "97031": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "HOOD RIVER"
  },
  "97032": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "HUBBARD"
  },
  "97033": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "KENT"
  },
  "97034": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "LAKE OSWEGO"
  },
  "97035": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "LAKE OSWEGO"
  },
  "97037": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MAUPIN"
  },
  "97038": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MOLALLA"
  },
  "97039": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MORO"
  },
  "97040": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MOSIER"
  },
  "97041": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MOUNT HOOD PARKDALE"
  },
  "97042": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MULINO"
  },
  "97045": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "OREGON CITY"
  },
  "97048": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "RAINIER"
  },
  "97049": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "RHODODENDRON"
  },
  "97050": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "RUFUS"
  },
  "97051": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SAINT HELENS"
  },
  "97053": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WARREN"
  },
  "97054": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "DEER ISLAND"
  },
  "97055": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SANDY"
  },
  "97056": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SCAPPOOSE"
  },
  "97058": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "THE DALLES"
  },
  "97060": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "TROUTDALE"
  },
  "97062": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "TUALATIN"
  },
  "97063": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "TYGH VALLEY"
  },
  "97064": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "VERNONIA"
  },
  "97065": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WASCO"
  },
  "97067": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WELCHES"
  },
  "97068": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WEST LINN"
  },
  "97070": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WILSONVILLE"
  },
  "97071": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WOODBURN"
  },
  "97080": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GRESHAM"
  },
  "97101": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "AMITY"
  },
  "97102": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ARCH CAPE"
  },
  "97103": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ASTORIA"
  },
  "97106": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BANKS"
  },
  "97107": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BAY CITY"
  },
  "97108": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BEAVER"
  },
  "97109": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BUXTON"
  },
  "97110": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CANNON BEACH"
  },
  "97111": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CARLTON"
  },
  "97112": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CLOVERDALE"
  },
  "97113": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CORNELIUS"
  },
  "97114": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "DAYTON"
  },
  "97115": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "DUNDEE"
  },
  "97116": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "FOREST GROVE"
  },
  "97117": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GALES CREEK"
  },
  "97118": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GARIBALDI"
  },
  "97119": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GASTON"
  },
  "97121": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "HAMMOND"
  },
  "97122": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "HEBO"
  },
  "97123": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "HILLSBORO"
  },
  "97124": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "HILLSBORO"
  },
  "97125": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MANNING"
  },
  "97127": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "LAFAYETTE"
  },
  "97128": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MCMINNVILLE"
  },
  "97130": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MANZANITA"
  },
  "97131": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "NEHALEM"
  },
  "97132": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "NEWBERG"
  },
  "97133": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "NORTH PLAINS"
  },
  "97134": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "OCEANSIDE"
  },
  "97135": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PACIFIC CITY"
  },
  "97136": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ROCKAWAY BEACH"
  },
  "97137": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SAINT PAUL"
  },
  "97138": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SEASIDE"
  },
  "97140": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SHERWOOD"
  },
  "97141": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "TILLAMOOK"
  },
  "97143": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "NETARTS"
  },
  "97144": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "TIMBER"
  },
  "97145": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "TOLOVANA PARK"
  },
  "97146": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WARRENTON"
  },
  "97147": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WHEELER"
  },
  "97148": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "YAMHILL"
  },
  "97149": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "NESKOWIN"
  },
  "97201": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97202": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97203": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97204": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97205": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97206": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97209": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97210": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97211": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97212": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97213": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97214": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97215": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97216": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97217": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97218": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97219": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97220": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97221": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97222": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97223": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97224": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97225": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97227": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97229": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97230": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97231": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97232": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97233": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97236": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97266": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97267": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORTLAND"
  },
  "97301": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SALEM"
  },
  "97302": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SALEM"
  },
  "97303": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SALEM"
  },
  "97304": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SALEM"
  },
  "97305": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SALEM"
  },
  "97306": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SALEM"
  },
  "97321": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ALBANY"
  },
  "97324": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ALSEA"
  },
  "97325": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "AUMSVILLE"
  },
  "97326": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BLODGETT"
  },
  "97327": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BROWNSVILLE"
  },
  "97329": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CASCADIA"
  },
  "97330": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CORVALLIS"
  },
  "97331": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CORVALLIS"
  },
  "97333": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CORVALLIS"
  },
  "97338": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "DALLAS"
  },
  "97341": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "DEPOE BAY"
  },
  "97342": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "DETROIT"
  },
  "97343": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "EDDYVILLE"
  },
  "97344": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "FALLS CITY"
  },
  "97345": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "FOSTER"
  },
  "97346": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GATES"
  },
  "97347": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GRAND RONDE"
  },
  "97348": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "HALSEY"
  },
  "97350": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "IDANHA"
  },
  "97351": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "INDEPENDENCE"
  },
  "97352": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "JEFFERSON"
  },
  "97355": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "LEBANON"
  },
  "97357": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "LOGSDEN"
  },
  "97358": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "LYONS"
  },
  "97360": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MILL CITY"
  },
  "97361": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MONMOUTH"
  },
  "97362": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MOUNT ANGEL"
  },
  "97364": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "NEOTSU"
  },
  "97365": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "NEWPORT"
  },
  "97366": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SOUTH BEACH"
  },
  "97367": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "LINCOLN CITY"
  },
  "97368": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "OTIS"
  },
  "97370": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PHILOMATH"
  },
  "97371": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "RICKREALL"
  },
  "97374": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SCIO"
  },
  "97375": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SCOTTS MILLS"
  },
  "97376": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SEAL ROCK"
  },
  "97377": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SHEDD"
  },
  "97378": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SHERIDAN"
  },
  "97380": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SILETZ"
  },
  "97381": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SILVERTON"
  },
  "97383": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "STAYTON"
  },
  "97385": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SUBLIMITY"
  },
  "97386": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SWEET HOME"
  },
  "97389": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "TANGENT"
  },
  "97390": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "TIDEWATER"
  },
  "97391": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "TOLEDO"
  },
  "97392": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "TURNER"
  },
  "97394": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WALDPORT"
  },
  "97396": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WILLAMINA"
  },
  "97401": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "EUGENE"
  },
  "97402": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "EUGENE"
  },
  "97403": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "EUGENE"
  },
  "97404": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "EUGENE"
  },
  "97405": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "EUGENE"
  },
  "97406": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "AGNESS"
  },
  "97408": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "EUGENE"
  },
  "97410": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "AZALEA"
  },
  "97411": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BANDON"
  },
  "97412": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BLACHLY"
  },
  "97413": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BLUE RIVER"
  },
  "97414": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BROADBENT"
  },
  "97415": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BROOKINGS"
  },
  "97416": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CAMAS VALLEY"
  },
  "97417": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CANYONVILLE"
  },
  "97419": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CHESHIRE"
  },
  "97420": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "COOS BAY"
  },
  "97423": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "COQUILLE"
  },
  "97424": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "COTTAGE GROVE"
  },
  "97425": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CRESCENT LAKE"
  },
  "97426": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CRESWELL"
  },
  "97427": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CULP CREEK"
  },
  "97429": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "DAYS CREEK"
  },
  "97430": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "DEADWOOD"
  },
  "97431": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "DEXTER"
  },
  "97434": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "DORENA"
  },
  "97435": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "DRAIN"
  },
  "97436": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ELKTON"
  },
  "97437": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ELMIRA"
  },
  "97438": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "FALL CREEK"
  },
  "97439": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "FLORENCE"
  },
  "97441": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GARDINER"
  },
  "97442": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GLENDALE"
  },
  "97443": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GLIDE"
  },
  "97444": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GOLD BEACH"
  },
  "97446": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "HARRISBURG"
  },
  "97447": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "IDLEYLD PARK"
  },
  "97448": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "JUNCTION CITY"
  },
  "97449": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "LAKESIDE"
  },
  "97450": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "LANGLOIS"
  },
  "97451": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "LORANE"
  },
  "97452": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "LOWELL"
  },
  "97453": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MAPLETON"
  },
  "97454": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MARCOLA"
  },
  "97455": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PLEASANT HILL"
  },
  "97456": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MONROE"
  },
  "97457": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MYRTLE CREEK"
  },
  "97458": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MYRTLE POINT"
  },
  "97459": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "NORTH BEND"
  },
  "97461": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "NOTI"
  },
  "97462": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "OAKLAND"
  },
  "97463": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "OAKRIDGE"
  },
  "97465": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PORT ORFORD"
  },
  "97466": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "POWERS"
  },
  "97467": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "REEDSPORT"
  },
  "97469": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "RIDDLE"
  },
  "97470": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ROSEBURG"
  },
  "97473": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SCOTTSBURG"
  },
  "97476": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SIXES"
  },
  "97477": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SPRINGFIELD"
  },
  "97478": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SPRINGFIELD"
  },
  "97479": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SUTHERLIN"
  },
  "97480": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SWISSHOME"
  },
  "97481": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "TENMILE"
  },
  "97484": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "TILLER"
  },
  "97486": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "UMPQUA"
  },
  "97487": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "VENETA"
  },
  "97488": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "VIDA"
  },
  "97489": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WALTERVILLE"
  },
  "97490": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WALTON"
  },
  "97492": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WESTFIR"
  },
  "97493": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WESTLAKE"
  },
  "97496": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WINSTON"
  },
  "97497": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WOLF CREEK"
  },
  "97498": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "YACHATS"
  },
  "97499": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "YONCALLA"
  },
  "97501": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MEDFORD"
  },
  "97502": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CENTRAL POINT"
  },
  "97503": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WHITE CITY"
  },
  "97504": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MEDFORD"
  },
  "97520": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ASHLAND"
  },
  "97522": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BUTTE FALLS"
  },
  "97523": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CAVE JUNCTION"
  },
  "97524": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "EAGLE POINT"
  },
  "97525": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GOLD HILL"
  },
  "97526": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GRANTS PASS"
  },
  "97527": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GRANTS PASS"
  },
  "97530": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "JACKSONVILLE"
  },
  "97531": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "KERBY"
  },
  "97532": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MERLIN"
  },
  "97534": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "O BRIEN"
  },
  "97535": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PHOENIX"
  },
  "97536": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PROSPECT"
  },
  "97537": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ROGUE RIVER"
  },
  "97538": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SELMA"
  },
  "97539": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SHADY COVE"
  },
  "97540": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "TALENT"
  },
  "97541": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "TRAIL"
  },
  "97543": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WILDERVILLE"
  },
  "97544": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WILLIAMS"
  },
  "97601": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "KLAMATH FALLS"
  },
  "97603": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "KLAMATH FALLS"
  },
  "97604": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CRATER LAKE"
  },
  "97620": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ADEL"
  },
  "97621": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BEATTY"
  },
  "97622": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BLY"
  },
  "97623": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BONANZA"
  },
  "97624": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CHILOQUIN"
  },
  "97625": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "DAIRY"
  },
  "97626": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "FORT KLAMATH"
  },
  "97627": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "KENO"
  },
  "97630": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "LAKEVIEW"
  },
  "97632": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MALIN"
  },
  "97633": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MERRILL"
  },
  "97634": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MIDLAND"
  },
  "97635": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "NEW PINE CREEK"
  },
  "97636": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PAISLEY"
  },
  "97637": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PLUSH"
  },
  "97638": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SILVER LAKE"
  },
  "97639": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SPRAGUE RIVER"
  },
  "97640": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SUMMER LAKE"
  },
  "97641": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CHRISTMAS VALLEY"
  },
  "97701": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BEND"
  },
  "97702": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BEND"
  },
  "97707": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BEND"
  },
  "97710": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "FIELDS"
  },
  "97711": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ASHWOOD"
  },
  "97712": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BROTHERS"
  },
  "97720": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BURNS"
  },
  "97721": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PRINCETON"
  },
  "97722": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "DIAMOND"
  },
  "97730": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CAMP SHERMAN"
  },
  "97731": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CHEMULT"
  },
  "97732": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CRANE"
  },
  "97733": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CRESCENT"
  },
  "97734": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CULVER"
  },
  "97735": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "FORT ROCK"
  },
  "97736": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "FRENCHGLEN"
  },
  "97737": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "GILCHRIST"
  },
  "97738": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "HINES"
  },
  "97739": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "LA PINE"
  },
  "97741": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MADRAS"
  },
  "97750": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MITCHELL"
  },
  "97751": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PAULINA"
  },
  "97752": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "POST"
  },
  "97753": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "POWELL BUTTE"
  },
  "97754": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PRINEVILLE"
  },
  "97756": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "REDMOND"
  },
  "97758": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "RILEY"
  },
  "97759": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SISTERS"
  },
  "97760": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "TERREBONNE"
  },
  "97761": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WARM SPRINGS"
  },
  "97801": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PENDLETON"
  },
  "97810": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ADAMS"
  },
  "97812": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ARLINGTON"
  },
  "97813": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ATHENA"
  },
  "97814": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BAKER CITY"
  },
  "97817": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BATES"
  },
  "97818": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BOARDMAN"
  },
  "97819": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BRIDGEPORT"
  },
  "97820": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CANYON CITY"
  },
  "97823": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "CONDON"
  },
  "97824": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "COVE"
  },
  "97825": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "DAYVILLE"
  },
  "97826": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ECHO"
  },
  "97827": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ELGIN"
  },
  "97828": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ENTERPRISE"
  },
  "97830": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "FOSSIL"
  },
  "97833": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "HAINES"
  },
  "97834": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "HALFWAY"
  },
  "97835": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "HELIX"
  },
  "97836": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "HEPPNER"
  },
  "97837": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "HEREFORD"
  },
  "97838": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "HERMISTON"
  },
  "97839": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "LEXINGTON"
  },
  "97840": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "OXBOW"
  },
  "97841": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "IMBLER"
  },
  "97842": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "IMNAHA"
  },
  "97843": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "IONE"
  },
  "97844": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "IRRIGON"
  },
  "97845": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "JOHN DAY"
  },
  "97846": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "JOSEPH"
  },
  "97848": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "KIMBERLY"
  },
  "97850": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "LA GRANDE"
  },
  "97856": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "LONG CREEK"
  },
  "97857": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "LOSTINE"
  },
  "97859": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MEACHAM"
  },
  "97862": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MILTON FREEWATER"
  },
  "97864": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MONUMENT"
  },
  "97865": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "MOUNT VERNON"
  },
  "97867": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "NORTH POWDER"
  },
  "97868": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PILOT ROCK"
  },
  "97869": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "PRAIRIE CITY"
  },
  "97870": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "RICHLAND"
  },
  "97873": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SENECA"
  },
  "97874": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SPRAY"
  },
  "97875": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "STANFIELD"
  },
  "97876": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SUMMERVILLE"
  },
  "97877": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "SUMPTER"
  },
  "97880": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "UKIAH"
  },
  "97882": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "UMATILLA"
  },
  "97883": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "UNION"
  },
  "97884": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "UNITY"
  },
  "97885": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WALLOWA"
  },
  "97886": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WESTON"
  },
  "97901": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ADRIAN"
  },
  "97903": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "BROGAN"
  },
  "97904": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "DREWSEY"
  },
  "97905": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "DURKEE"
  },
  "97906": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "HARPER"
  },
  "97907": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "HUNTINGTON"
  },
  "97908": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "IRONSIDE"
  },
  "97909": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "JAMIESON"
  },
  "97910": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "JORDAN VALLEY"
  },
  "97911": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "JUNTURA"
  },
  "97913": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "NYSSA"
  },
  "97914": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "ONTARIO"
  },
  "97918": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "VALE"
  },
  "97920": {
    stateCode: "OR",
    stateName: "Oregon",
    city: "WESTFALL"
  },
  "98001": {
    stateCode: "WA",
    stateName: "Washington",
    city: "AUBURN"
  },
  "98002": {
    stateCode: "WA",
    stateName: "Washington",
    city: "AUBURN"
  },
  "98003": {
    stateCode: "WA",
    stateName: "Washington",
    city: "FEDERAL WAY"
  },
  "98004": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BELLEVUE"
  },
  "98005": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BELLEVUE"
  },
  "98006": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BELLEVUE"
  },
  "98007": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BELLEVUE"
  },
  "98008": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BELLEVUE"
  },
  "98010": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BLACK DIAMOND"
  },
  "98011": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BOTHELL"
  },
  "98012": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BOTHELL"
  },
  "98014": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CARNATION"
  },
  "98019": {
    stateCode: "WA",
    stateName: "Washington",
    city: "DUVALL"
  },
  "98020": {
    stateCode: "WA",
    stateName: "Washington",
    city: "EDMONDS"
  },
  "98021": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BOTHELL"
  },
  "98022": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ENUMCLAW"
  },
  "98023": {
    stateCode: "WA",
    stateName: "Washington",
    city: "FEDERAL WAY"
  },
  "98024": {
    stateCode: "WA",
    stateName: "Washington",
    city: "FALL CITY"
  },
  "98025": {
    stateCode: "WA",
    stateName: "Washington",
    city: "HOBART"
  },
  "98026": {
    stateCode: "WA",
    stateName: "Washington",
    city: "EDMONDS"
  },
  "98027": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ISSAQUAH"
  },
  "98028": {
    stateCode: "WA",
    stateName: "Washington",
    city: "KENMORE"
  },
  "98029": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ISSAQUAH"
  },
  "98031": {
    stateCode: "WA",
    stateName: "Washington",
    city: "KENT"
  },
  "98032": {
    stateCode: "WA",
    stateName: "Washington",
    city: "KENT"
  },
  "98033": {
    stateCode: "WA",
    stateName: "Washington",
    city: "KIRKLAND"
  },
  "98034": {
    stateCode: "WA",
    stateName: "Washington",
    city: "KIRKLAND"
  },
  "98036": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LYNNWOOD"
  },
  "98037": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LYNNWOOD"
  },
  "98038": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MAPLE VALLEY"
  },
  "98039": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MEDINA"
  },
  "98040": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MERCER ISLAND"
  },
  "98042": {
    stateCode: "WA",
    stateName: "Washington",
    city: "KENT"
  },
  "98043": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MOUNTLAKE TERRACE"
  },
  "98045": {
    stateCode: "WA",
    stateName: "Washington",
    city: "NORTH BEND"
  },
  "98047": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PACIFIC"
  },
  "98051": {
    stateCode: "WA",
    stateName: "Washington",
    city: "RAVENSDALE"
  },
  "98052": {
    stateCode: "WA",
    stateName: "Washington",
    city: "REDMOND"
  },
  "98053": {
    stateCode: "WA",
    stateName: "Washington",
    city: "REDMOND"
  },
  "98055": {
    stateCode: "WA",
    stateName: "Washington",
    city: "RENTON"
  },
  "98056": {
    stateCode: "WA",
    stateName: "Washington",
    city: "RENTON"
  },
  "98058": {
    stateCode: "WA",
    stateName: "Washington",
    city: "RENTON"
  },
  "98059": {
    stateCode: "WA",
    stateName: "Washington",
    city: "RENTON"
  },
  "98065": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SNOQUALMIE"
  },
  "98068": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SNOQUALMIE PASS"
  },
  "98070": {
    stateCode: "WA",
    stateName: "Washington",
    city: "VASHON"
  },
  "98072": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WOODINVILLE"
  },
  "98092": {
    stateCode: "WA",
    stateName: "Washington",
    city: "AUBURN"
  },
  "98101": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98102": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98103": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98104": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98105": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98106": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98107": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98108": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98109": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98110": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BAINBRIDGE ISLAND"
  },
  "98112": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98115": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98116": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98117": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98118": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98119": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98121": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98122": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98125": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98126": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98133": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98134": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98136": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98144": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98146": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98148": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98155": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98166": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98168": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98177": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98178": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98188": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98198": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98199": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEATTLE"
  },
  "98201": {
    stateCode: "WA",
    stateName: "Washington",
    city: "EVERETT"
  },
  "98203": {
    stateCode: "WA",
    stateName: "Washington",
    city: "EVERETT"
  },
  "98204": {
    stateCode: "WA",
    stateName: "Washington",
    city: "EVERETT"
  },
  "98205": {
    stateCode: "WA",
    stateName: "Washington",
    city: "EVERETT"
  },
  "98208": {
    stateCode: "WA",
    stateName: "Washington",
    city: "EVERETT"
  },
  "98220": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ACME"
  },
  "98221": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ANACORTES"
  },
  "98222": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BLAKELY ISLAND"
  },
  "98223": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ARLINGTON"
  },
  "98224": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BARING"
  },
  "98225": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BELLINGHAM"
  },
  "98226": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BELLINGHAM"
  },
  "98230": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BLAINE"
  },
  "98232": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BOW"
  },
  "98233": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BURLINGTON"
  },
  "98235": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CLEARLAKE"
  },
  "98236": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CLINTON"
  },
  "98237": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CONCRETE"
  },
  "98238": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CONWAY"
  },
  "98239": {
    stateCode: "WA",
    stateName: "Washington",
    city: "COUPEVILLE"
  },
  "98240": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CUSTER"
  },
  "98241": {
    stateCode: "WA",
    stateName: "Washington",
    city: "DARRINGTON"
  },
  "98243": {
    stateCode: "WA",
    stateName: "Washington",
    city: "DEER HARBOR"
  },
  "98244": {
    stateCode: "WA",
    stateName: "Washington",
    city: "DEMING"
  },
  "98245": {
    stateCode: "WA",
    stateName: "Washington",
    city: "EASTSOUND"
  },
  "98247": {
    stateCode: "WA",
    stateName: "Washington",
    city: "EVERSON"
  },
  "98248": {
    stateCode: "WA",
    stateName: "Washington",
    city: "FERNDALE"
  },
  "98249": {
    stateCode: "WA",
    stateName: "Washington",
    city: "FREELAND"
  },
  "98250": {
    stateCode: "WA",
    stateName: "Washington",
    city: "FRIDAY HARBOR"
  },
  "98251": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GOLD BAR"
  },
  "98252": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GRANITE FALLS"
  },
  "98253": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GREENBANK"
  },
  "98256": {
    stateCode: "WA",
    stateName: "Washington",
    city: "INDEX"
  },
  "98257": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LA CONNER"
  },
  "98258": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LAKE STEVENS"
  },
  "98260": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LANGLEY"
  },
  "98261": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LOPEZ ISLAND"
  },
  "98262": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LUMMI ISLAND"
  },
  "98264": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LYNDEN"
  },
  "98267": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MARBLEMOUNT"
  },
  "98270": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MARYSVILLE"
  },
  "98271": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MARYSVILLE"
  },
  "98272": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MONROE"
  },
  "98273": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MOUNT VERNON"
  },
  "98274": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MOUNT VERNON"
  },
  "98275": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MUKILTEO"
  },
  "98277": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OAK HARBOR"
  },
  "98279": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OLGA"
  },
  "98280": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ORCAS"
  },
  "98281": {
    stateCode: "WA",
    stateName: "Washington",
    city: "POINT ROBERTS"
  },
  "98283": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ROCKPORT"
  },
  "98284": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEDRO WOOLLEY"
  },
  "98286": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SHAW ISLAND"
  },
  "98288": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SKYKOMISH"
  },
  "98290": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SNOHOMISH"
  },
  "98292": {
    stateCode: "WA",
    stateName: "Washington",
    city: "STANWOOD"
  },
  "98294": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SULTAN"
  },
  "98295": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SUMAS"
  },
  "98296": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SNOHOMISH"
  },
  "98297": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WALDRON"
  },
  "98303": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ANDERSON ISLAND"
  },
  "98304": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ASHFORD"
  },
  "98305": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BEAVER"
  },
  "98310": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BREMERTON"
  },
  "98311": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BREMERTON"
  },
  "98312": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BREMERTON"
  },
  "98315": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SILVERDALE"
  },
  "98320": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BRINNON"
  },
  "98321": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BUCKLEY"
  },
  "98323": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CARBONADO"
  },
  "98325": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CHIMACUM"
  },
  "98326": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CLALLAM BAY"
  },
  "98327": {
    stateCode: "WA",
    stateName: "Washington",
    city: "DUPONT"
  },
  "98328": {
    stateCode: "WA",
    stateName: "Washington",
    city: "EATONVILLE"
  },
  "98329": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GIG HARBOR"
  },
  "98330": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ELBE"
  },
  "98331": {
    stateCode: "WA",
    stateName: "Washington",
    city: "FORKS"
  },
  "98332": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GIG HARBOR"
  },
  "98333": {
    stateCode: "WA",
    stateName: "Washington",
    city: "FOX ISLAND"
  },
  "98335": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GIG HARBOR"
  },
  "98336": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GLENOMA"
  },
  "98337": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BREMERTON"
  },
  "98338": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GRAHAM"
  },
  "98339": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PORT HADLOCK"
  },
  "98340": {
    stateCode: "WA",
    stateName: "Washington",
    city: "HANSVILLE"
  },
  "98342": {
    stateCode: "WA",
    stateName: "Washington",
    city: "INDIANOLA"
  },
  "98345": {
    stateCode: "WA",
    stateName: "Washington",
    city: "KEYPORT"
  },
  "98346": {
    stateCode: "WA",
    stateName: "Washington",
    city: "KINGSTON"
  },
  "98349": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LAKEBAY"
  },
  "98350": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LA PUSH"
  },
  "98351": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LONGBRANCH"
  },
  "98353": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MANCHESTER"
  },
  "98354": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MILTON"
  },
  "98355": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MINERAL"
  },
  "98356": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MORTON"
  },
  "98357": {
    stateCode: "WA",
    stateName: "Washington",
    city: "NEAH BAY"
  },
  "98358": {
    stateCode: "WA",
    stateName: "Washington",
    city: "NORDLAND"
  },
  "98359": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OLALLA"
  },
  "98360": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ORTING"
  },
  "98361": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PACKWOOD"
  },
  "98362": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PORT ANGELES"
  },
  "98363": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PORT ANGELES"
  },
  "98364": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PORT GAMBLE"
  },
  "98365": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PORT LUDLOW"
  },
  "98366": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PORT ORCHARD"
  },
  "98367": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PORT ORCHARD"
  },
  "98368": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PORT TOWNSEND"
  },
  "98370": {
    stateCode: "WA",
    stateName: "Washington",
    city: "POULSBO"
  },
  "98371": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PUYALLUP"
  },
  "98372": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PUYALLUP"
  },
  "98373": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PUYALLUP"
  },
  "98374": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PUYALLUP"
  },
  "98375": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PUYALLUP"
  },
  "98376": {
    stateCode: "WA",
    stateName: "Washington",
    city: "QUILCENE"
  },
  "98377": {
    stateCode: "WA",
    stateName: "Washington",
    city: "RANDLE"
  },
  "98380": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEABECK"
  },
  "98381": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEKIU"
  },
  "98382": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEQUIM"
  },
  "98383": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SILVERDALE"
  },
  "98385": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SOUTH PRAIRIE"
  },
  "98387": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SPANAWAY"
  },
  "98388": {
    stateCode: "WA",
    stateName: "Washington",
    city: "STEILACOOM"
  },
  "98390": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SUMNER"
  },
  "98392": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SUQUAMISH"
  },
  "98394": {
    stateCode: "WA",
    stateName: "Washington",
    city: "VAUGHN"
  },
  "98396": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WILKESON"
  },
  "98402": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TACOMA"
  },
  "98403": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TACOMA"
  },
  "98404": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TACOMA"
  },
  "98405": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TACOMA"
  },
  "98406": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TACOMA"
  },
  "98407": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TACOMA"
  },
  "98408": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TACOMA"
  },
  "98409": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TACOMA"
  },
  "98421": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TACOMA"
  },
  "98422": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TACOMA"
  },
  "98424": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TACOMA"
  },
  "98433": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TACOMA"
  },
  "98439": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LAKEWOOD"
  },
  "98443": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TACOMA"
  },
  "98444": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TACOMA"
  },
  "98445": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TACOMA"
  },
  "98446": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TACOMA"
  },
  "98465": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TACOMA"
  },
  "98466": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TACOMA"
  },
  "98467": {
    stateCode: "WA",
    stateName: "Washington",
    city: "UNIVERSITY PLACE"
  },
  "98498": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LAKEWOOD"
  },
  "98499": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LAKEWOOD"
  },
  "98501": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OLYMPIA"
  },
  "98502": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OLYMPIA"
  },
  "98503": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LACEY"
  },
  "98506": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OLYMPIA"
  },
  "98512": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OLYMPIA"
  },
  "98513": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OLYMPIA"
  },
  "98516": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OLYMPIA"
  },
  "98520": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ABERDEEN"
  },
  "98524": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ALLYN"
  },
  "98526": {
    stateCode: "WA",
    stateName: "Washington",
    city: "AMANDA PARK"
  },
  "98527": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BAY CENTER"
  },
  "98528": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BELFAIR"
  },
  "98530": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BUCODA"
  },
  "98531": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CENTRALIA"
  },
  "98532": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CHEHALIS"
  },
  "98533": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CINEBAR"
  },
  "98535": {
    stateCode: "WA",
    stateName: "Washington",
    city: "COPALIS BEACH"
  },
  "98536": {
    stateCode: "WA",
    stateName: "Washington",
    city: "COPALIS CROSSING"
  },
  "98537": {
    stateCode: "WA",
    stateName: "Washington",
    city: "COSMOPOLIS"
  },
  "98538": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CURTIS"
  },
  "98539": {
    stateCode: "WA",
    stateName: "Washington",
    city: "DOTY"
  },
  "98541": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ELMA"
  },
  "98542": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ETHEL"
  },
  "98546": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GRAPEVIEW"
  },
  "98547": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GRAYLAND"
  },
  "98548": {
    stateCode: "WA",
    stateName: "Washington",
    city: "HOODSPORT"
  },
  "98550": {
    stateCode: "WA",
    stateName: "Washington",
    city: "HOQUIAM"
  },
  "98552": {
    stateCode: "WA",
    stateName: "Washington",
    city: "HUMPTULIPS"
  },
  "98555": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LILLIWAUP"
  },
  "98557": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MCCLEARY"
  },
  "98559": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MALONE"
  },
  "98560": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MATLOCK"
  },
  "98562": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MOCLIPS"
  },
  "98563": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MONTESANO"
  },
  "98564": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MOSSYROCK"
  },
  "98565": {
    stateCode: "WA",
    stateName: "Washington",
    city: "NAPAVINE"
  },
  "98568": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OAKVILLE"
  },
  "98569": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OCEAN SHORES"
  },
  "98570": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ONALASKA"
  },
  "98571": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PACIFIC BEACH"
  },
  "98572": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PE ELL"
  },
  "98575": {
    stateCode: "WA",
    stateName: "Washington",
    city: "QUINAULT"
  },
  "98576": {
    stateCode: "WA",
    stateName: "Washington",
    city: "RAINIER"
  },
  "98577": {
    stateCode: "WA",
    stateName: "Washington",
    city: "RAYMOND"
  },
  "98579": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ROCHESTER"
  },
  "98580": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ROY"
  },
  "98581": {
    stateCode: "WA",
    stateName: "Washington",
    city: "RYDERWOOD"
  },
  "98582": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SALKUM"
  },
  "98584": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SHELTON"
  },
  "98585": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SILVER CREEK"
  },
  "98586": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SOUTH BEND"
  },
  "98587": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TAHOLAH"
  },
  "98588": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TAHUYA"
  },
  "98589": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TENINO"
  },
  "98590": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TOKELAND"
  },
  "98591": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TOLEDO"
  },
  "98592": {
    stateCode: "WA",
    stateName: "Washington",
    city: "UNION"
  },
  "98593": {
    stateCode: "WA",
    stateName: "Washington",
    city: "VADER"
  },
  "98595": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WESTPORT"
  },
  "98596": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WINLOCK"
  },
  "98597": {
    stateCode: "WA",
    stateName: "Washington",
    city: "YELM"
  },
  "98601": {
    stateCode: "WA",
    stateName: "Washington",
    city: "AMBOY"
  },
  "98602": {
    stateCode: "WA",
    stateName: "Washington",
    city: "APPLETON"
  },
  "98603": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ARIEL"
  },
  "98604": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BATTLE GROUND"
  },
  "98605": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BINGEN"
  },
  "98606": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BRUSH PRAIRIE"
  },
  "98607": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CAMAS"
  },
  "98610": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CARSON"
  },
  "98611": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CASTLE ROCK"
  },
  "98612": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CATHLAMET"
  },
  "98613": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CENTERVILLE"
  },
  "98614": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CHINOOK"
  },
  "98616": {
    stateCode: "WA",
    stateName: "Washington",
    city: "COUGAR"
  },
  "98617": {
    stateCode: "WA",
    stateName: "Washington",
    city: "DALLESPORT"
  },
  "98619": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GLENWOOD"
  },
  "98620": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GOLDENDALE"
  },
  "98621": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GRAYS RIVER"
  },
  "98624": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ILWACO"
  },
  "98625": {
    stateCode: "WA",
    stateName: "Washington",
    city: "KALAMA"
  },
  "98626": {
    stateCode: "WA",
    stateName: "Washington",
    city: "KELSO"
  },
  "98628": {
    stateCode: "WA",
    stateName: "Washington",
    city: "KLICKITAT"
  },
  "98629": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LA CENTER"
  },
  "98631": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LONG BEACH"
  },
  "98632": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LONGVIEW"
  },
  "98635": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LYLE"
  },
  "98638": {
    stateCode: "WA",
    stateName: "Washington",
    city: "NASELLE"
  },
  "98639": {
    stateCode: "WA",
    stateName: "Washington",
    city: "NORTH BONNEVILLE"
  },
  "98640": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OCEAN PARK"
  },
  "98641": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OYSTERVILLE"
  },
  "98642": {
    stateCode: "WA",
    stateName: "Washington",
    city: "RIDGEFIELD"
  },
  "98643": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ROSBURG"
  },
  "98644": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SEAVIEW"
  },
  "98645": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SILVERLAKE"
  },
  "98647": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SKAMOKAWA"
  },
  "98648": {
    stateCode: "WA",
    stateName: "Washington",
    city: "STEVENSON"
  },
  "98649": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TOUTLE"
  },
  "98650": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TROUT LAKE"
  },
  "98651": {
    stateCode: "WA",
    stateName: "Washington",
    city: "UNDERWOOD"
  },
  "98660": {
    stateCode: "WA",
    stateName: "Washington",
    city: "VANCOUVER"
  },
  "98661": {
    stateCode: "WA",
    stateName: "Washington",
    city: "VANCOUVER"
  },
  "98662": {
    stateCode: "WA",
    stateName: "Washington",
    city: "VANCOUVER"
  },
  "98663": {
    stateCode: "WA",
    stateName: "Washington",
    city: "VANCOUVER"
  },
  "98664": {
    stateCode: "WA",
    stateName: "Washington",
    city: "VANCOUVER"
  },
  "98665": {
    stateCode: "WA",
    stateName: "Washington",
    city: "VANCOUVER"
  },
  "98670": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WAHKIACUS"
  },
  "98671": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WASHOUGAL"
  },
  "98672": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WHITE SALMON"
  },
  "98673": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WISHRAM"
  },
  "98674": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WOODLAND"
  },
  "98675": {
    stateCode: "WA",
    stateName: "Washington",
    city: "YACOLT"
  },
  "98682": {
    stateCode: "WA",
    stateName: "Washington",
    city: "VANCOUVER"
  },
  "98683": {
    stateCode: "WA",
    stateName: "Washington",
    city: "VANCOUVER"
  },
  "98684": {
    stateCode: "WA",
    stateName: "Washington",
    city: "VANCOUVER"
  },
  "98685": {
    stateCode: "WA",
    stateName: "Washington",
    city: "VANCOUVER"
  },
  "98686": {
    stateCode: "WA",
    stateName: "Washington",
    city: "VANCOUVER"
  },
  "98801": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WENATCHEE"
  },
  "98802": {
    stateCode: "WA",
    stateName: "Washington",
    city: "EAST WENATCHEE"
  },
  "98811": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ARDENVOIR"
  },
  "98812": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BREWSTER"
  },
  "98813": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BRIDGEPORT"
  },
  "98814": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CARLTON"
  },
  "98815": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CASHMERE"
  },
  "98816": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CHELAN"
  },
  "98817": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CHELAN FALLS"
  },
  "98819": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CONCONULLY"
  },
  "98821": {
    stateCode: "WA",
    stateName: "Washington",
    city: "DRYDEN"
  },
  "98822": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ENTIAT"
  },
  "98823": {
    stateCode: "WA",
    stateName: "Washington",
    city: "EPHRATA"
  },
  "98826": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LEAVENWORTH"
  },
  "98827": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LOOMIS"
  },
  "98828": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MALAGA"
  },
  "98829": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MALOTT"
  },
  "98830": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MANSFIELD"
  },
  "98831": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MANSON"
  },
  "98832": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MARLIN"
  },
  "98833": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MAZAMA"
  },
  "98834": {
    stateCode: "WA",
    stateName: "Washington",
    city: "METHOW"
  },
  "98836": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MONITOR"
  },
  "98837": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MOSES LAKE"
  },
  "98840": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OKANOGAN"
  },
  "98841": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OMAK"
  },
  "98843": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ORONDO"
  },
  "98844": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OROVILLE"
  },
  "98845": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PALISADES"
  },
  "98846": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PATEROS"
  },
  "98847": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PESHASTIN"
  },
  "98848": {
    stateCode: "WA",
    stateName: "Washington",
    city: "QUINCY"
  },
  "98849": {
    stateCode: "WA",
    stateName: "Washington",
    city: "RIVERSIDE"
  },
  "98850": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ROCK ISLAND"
  },
  "98851": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SOAP LAKE"
  },
  "98852": {
    stateCode: "WA",
    stateName: "Washington",
    city: "STEHEKIN"
  },
  "98853": {
    stateCode: "WA",
    stateName: "Washington",
    city: "STRATFORD"
  },
  "98855": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TONASKET"
  },
  "98856": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TWISP"
  },
  "98857": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WARDEN"
  },
  "98858": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WATERVILLE"
  },
  "98859": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WAUCONDA"
  },
  "98860": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WILSON CREEK"
  },
  "98862": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WINTHROP"
  },
  "98901": {
    stateCode: "WA",
    stateName: "Washington",
    city: "YAKIMA"
  },
  "98902": {
    stateCode: "WA",
    stateName: "Washington",
    city: "YAKIMA"
  },
  "98903": {
    stateCode: "WA",
    stateName: "Washington",
    city: "YAKIMA"
  },
  "98908": {
    stateCode: "WA",
    stateName: "Washington",
    city: "YAKIMA"
  },
  "98922": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CLE ELUM"
  },
  "98923": {
    stateCode: "WA",
    stateName: "Washington",
    city: "COWICHE"
  },
  "98925": {
    stateCode: "WA",
    stateName: "Washington",
    city: "EASTON"
  },
  "98926": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ELLENSBURG"
  },
  "98930": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GRANDVIEW"
  },
  "98932": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GRANGER"
  },
  "98933": {
    stateCode: "WA",
    stateName: "Washington",
    city: "HARRAH"
  },
  "98934": {
    stateCode: "WA",
    stateName: "Washington",
    city: "KITTITAS"
  },
  "98935": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MABTON"
  },
  "98936": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MOXEE"
  },
  "98937": {
    stateCode: "WA",
    stateName: "Washington",
    city: "NACHES"
  },
  "98938": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OUTLOOK"
  },
  "98940": {
    stateCode: "WA",
    stateName: "Washington",
    city: "RONALD"
  },
  "98941": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ROSLYN"
  },
  "98942": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SELAH"
  },
  "98943": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SOUTH CLE ELUM"
  },
  "98944": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SUNNYSIDE"
  },
  "98946": {
    stateCode: "WA",
    stateName: "Washington",
    city: "THORP"
  },
  "98947": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TIETON"
  },
  "98948": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TOPPENISH"
  },
  "98950": {
    stateCode: "WA",
    stateName: "Washington",
    city: "VANTAGE"
  },
  "98951": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WAPATO"
  },
  "98952": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WHITE SWAN"
  },
  "98953": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ZILLAH"
  },
  "99001": {
    stateCode: "WA",
    stateName: "Washington",
    city: "AIRWAY HEIGHTS"
  },
  "99003": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CHATTAROY"
  },
  "99004": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CHENEY"
  },
  "99005": {
    stateCode: "WA",
    stateName: "Washington",
    city: "COLBERT"
  },
  "99006": {
    stateCode: "WA",
    stateName: "Washington",
    city: "DEER PARK"
  },
  "99008": {
    stateCode: "WA",
    stateName: "Washington",
    city: "EDWALL"
  },
  "99009": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ELK"
  },
  "99011": {
    stateCode: "WA",
    stateName: "Washington",
    city: "FAIRCHILD AIR FORCE BASE"
  },
  "99012": {
    stateCode: "WA",
    stateName: "Washington",
    city: "FAIRFIELD"
  },
  "99013": {
    stateCode: "WA",
    stateName: "Washington",
    city: "FORD"
  },
  "99016": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GREENACRES"
  },
  "99017": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LAMONT"
  },
  "99018": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LATAH"
  },
  "99019": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LIBERTY LAKE"
  },
  "99020": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MARSHALL"
  },
  "99021": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MEAD"
  },
  "99022": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MEDICAL LAKE"
  },
  "99023": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MICA"
  },
  "99025": {
    stateCode: "WA",
    stateName: "Washington",
    city: "NEWMAN LAKE"
  },
  "99026": {
    stateCode: "WA",
    stateName: "Washington",
    city: "NINE MILE FALLS"
  },
  "99027": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OTIS ORCHARDS"
  },
  "99029": {
    stateCode: "WA",
    stateName: "Washington",
    city: "REARDAN"
  },
  "99030": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ROCKFORD"
  },
  "99031": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SPANGLE"
  },
  "99032": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SPRAGUE"
  },
  "99033": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TEKOA"
  },
  "99034": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TUMTUM"
  },
  "99036": {
    stateCode: "WA",
    stateName: "Washington",
    city: "VALLEYFORD"
  },
  "99037": {
    stateCode: "WA",
    stateName: "Washington",
    city: "VERADALE"
  },
  "99040": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WELLPINIT"
  },
  "99101": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ADDY"
  },
  "99102": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ALBION"
  },
  "99103": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ALMIRA"
  },
  "99105": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BENGE"
  },
  "99109": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CHEWELAH"
  },
  "99110": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CLAYTON"
  },
  "99111": {
    stateCode: "WA",
    stateName: "Washington",
    city: "COLFAX"
  },
  "99113": {
    stateCode: "WA",
    stateName: "Washington",
    city: "COLTON"
  },
  "99114": {
    stateCode: "WA",
    stateName: "Washington",
    city: "COLVILLE"
  },
  "99115": {
    stateCode: "WA",
    stateName: "Washington",
    city: "COULEE CITY"
  },
  "99116": {
    stateCode: "WA",
    stateName: "Washington",
    city: "COULEE DAM"
  },
  "99117": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CRESTON"
  },
  "99118": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CURLEW"
  },
  "99119": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CUSICK"
  },
  "99121": {
    stateCode: "WA",
    stateName: "Washington",
    city: "DANVILLE"
  },
  "99122": {
    stateCode: "WA",
    stateName: "Washington",
    city: "DAVENPORT"
  },
  "99123": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ELECTRIC CITY"
  },
  "99124": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ELMER CITY"
  },
  "99125": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ENDICOTT"
  },
  "99126": {
    stateCode: "WA",
    stateName: "Washington",
    city: "EVANS"
  },
  "99128": {
    stateCode: "WA",
    stateName: "Washington",
    city: "FARMINGTON"
  },
  "99129": {
    stateCode: "WA",
    stateName: "Washington",
    city: "FRUITLAND"
  },
  "99130": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GARFIELD"
  },
  "99131": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GIFFORD"
  },
  "99133": {
    stateCode: "WA",
    stateName: "Washington",
    city: "GRAND COULEE"
  },
  "99134": {
    stateCode: "WA",
    stateName: "Washington",
    city: "HARRINGTON"
  },
  "99135": {
    stateCode: "WA",
    stateName: "Washington",
    city: "HARTLINE"
  },
  "99136": {
    stateCode: "WA",
    stateName: "Washington",
    city: "HAY"
  },
  "99137": {
    stateCode: "WA",
    stateName: "Washington",
    city: "HUNTERS"
  },
  "99138": {
    stateCode: "WA",
    stateName: "Washington",
    city: "INCHELIUM"
  },
  "99139": {
    stateCode: "WA",
    stateName: "Washington",
    city: "IONE"
  },
  "99140": {
    stateCode: "WA",
    stateName: "Washington",
    city: "KELLER"
  },
  "99141": {
    stateCode: "WA",
    stateName: "Washington",
    city: "KETTLE FALLS"
  },
  "99143": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LACROSSE"
  },
  "99146": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LAURIER"
  },
  "99148": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LOON LAKE"
  },
  "99149": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MALDEN"
  },
  "99150": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MALO"
  },
  "99151": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MARCUS"
  },
  "99152": {
    stateCode: "WA",
    stateName: "Washington",
    city: "METALINE"
  },
  "99153": {
    stateCode: "WA",
    stateName: "Washington",
    city: "METALINE FALLS"
  },
  "99154": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MOHLER"
  },
  "99155": {
    stateCode: "WA",
    stateName: "Washington",
    city: "NESPELEM"
  },
  "99156": {
    stateCode: "WA",
    stateName: "Washington",
    city: "NEWPORT"
  },
  "99157": {
    stateCode: "WA",
    stateName: "Washington",
    city: "NORTHPORT"
  },
  "99158": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OAKESDALE"
  },
  "99159": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ODESSA"
  },
  "99160": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ORIENT"
  },
  "99161": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PALOUSE"
  },
  "99163": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PULLMAN"
  },
  "99166": {
    stateCode: "WA",
    stateName: "Washington",
    city: "REPUBLIC"
  },
  "99167": {
    stateCode: "WA",
    stateName: "Washington",
    city: "RICE"
  },
  "99169": {
    stateCode: "WA",
    stateName: "Washington",
    city: "RITZVILLE"
  },
  "99170": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ROSALIA"
  },
  "99171": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SAINT JOHN"
  },
  "99173": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SPRINGDALE"
  },
  "99176": {
    stateCode: "WA",
    stateName: "Washington",
    city: "THORNTON"
  },
  "99179": {
    stateCode: "WA",
    stateName: "Washington",
    city: "UNIONTOWN"
  },
  "99180": {
    stateCode: "WA",
    stateName: "Washington",
    city: "USK"
  },
  "99181": {
    stateCode: "WA",
    stateName: "Washington",
    city: "VALLEY"
  },
  "99185": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WILBUR"
  },
  "99201": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SPOKANE"
  },
  "99202": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SPOKANE"
  },
  "99203": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SPOKANE"
  },
  "99204": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SPOKANE"
  },
  "99205": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SPOKANE"
  },
  "99206": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SPOKANE"
  },
  "99207": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SPOKANE"
  },
  "99208": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SPOKANE"
  },
  "99212": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SPOKANE"
  },
  "99216": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SPOKANE"
  },
  "99217": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SPOKANE"
  },
  "99218": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SPOKANE"
  },
  "99223": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SPOKANE"
  },
  "99224": {
    stateCode: "WA",
    stateName: "Washington",
    city: "SPOKANE"
  },
  "99301": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PASCO"
  },
  "99320": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BENTON CITY"
  },
  "99321": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BEVERLY"
  },
  "99322": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BICKLETON"
  },
  "99323": {
    stateCode: "WA",
    stateName: "Washington",
    city: "BURBANK"
  },
  "99324": {
    stateCode: "WA",
    stateName: "Washington",
    city: "COLLEGE PLACE"
  },
  "99326": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CONNELL"
  },
  "99328": {
    stateCode: "WA",
    stateName: "Washington",
    city: "DAYTON"
  },
  "99329": {
    stateCode: "WA",
    stateName: "Washington",
    city: "DIXIE"
  },
  "99330": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ELTOPIA"
  },
  "99335": {
    stateCode: "WA",
    stateName: "Washington",
    city: "KAHLOTUS"
  },
  "99336": {
    stateCode: "WA",
    stateName: "Washington",
    city: "KENNEWICK"
  },
  "99337": {
    stateCode: "WA",
    stateName: "Washington",
    city: "KENNEWICK"
  },
  "99338": {
    stateCode: "WA",
    stateName: "Washington",
    city: "KENNEWICK"
  },
  "99341": {
    stateCode: "WA",
    stateName: "Washington",
    city: "LIND"
  },
  "99343": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MESA"
  },
  "99344": {
    stateCode: "WA",
    stateName: "Washington",
    city: "OTHELLO"
  },
  "99345": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PATERSON"
  },
  "99346": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PLYMOUTH"
  },
  "99347": {
    stateCode: "WA",
    stateName: "Washington",
    city: "POMEROY"
  },
  "99348": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PRESCOTT"
  },
  "99349": {
    stateCode: "WA",
    stateName: "Washington",
    city: "MATTAWA"
  },
  "99350": {
    stateCode: "WA",
    stateName: "Washington",
    city: "PROSSER"
  },
  "99352": {
    stateCode: "WA",
    stateName: "Washington",
    city: "RICHLAND"
  },
  "99353": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WEST RICHLAND"
  },
  "99356": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ROOSEVELT"
  },
  "99357": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ROYAL CITY"
  },
  "99359": {
    stateCode: "WA",
    stateName: "Washington",
    city: "STARBUCK"
  },
  "99360": {
    stateCode: "WA",
    stateName: "Washington",
    city: "TOUCHET"
  },
  "99361": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WAITSBURG"
  },
  "99362": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WALLA WALLA"
  },
  "99363": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WALLULA"
  },
  "99371": {
    stateCode: "WA",
    stateName: "Washington",
    city: "WASHTUCNA"
  },
  "99401": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ANATONE"
  },
  "99402": {
    stateCode: "WA",
    stateName: "Washington",
    city: "ASOTIN"
  },
  "99403": {
    stateCode: "WA",
    stateName: "Washington",
    city: "CLARKSTON"
  },
  "99501": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ANCHORAGE"
  },
  "99502": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ANCHORAGE"
  },
  "99503": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ANCHORAGE"
  },
  "99504": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ANCHORAGE"
  },
  "99505": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "FORT RICHARDSON"
  },
  "99506": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ELMENDORF AFB"
  },
  "99507": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ANCHORAGE"
  },
  "99508": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ANCHORAGE"
  },
  "99515": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ANCHORAGE"
  },
  "99516": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ANCHORAGE"
  },
  "99517": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ANCHORAGE"
  },
  "99518": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ANCHORAGE"
  },
  "99540": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "INDIAN"
  },
  "99547": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ATKA"
  },
  "99548": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "CHIGNIK LAKE"
  },
  "99549": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "PORT HEIDEN"
  },
  "99550": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "PORT LIONS"
  },
  "99551": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "AKIACHAK"
  },
  "99552": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "AKIAK"
  },
  "99553": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "AKUTAN"
  },
  "99554": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ALAKANUK"
  },
  "99555": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ALEKNAGIK"
  },
  "99556": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ANCHOR POINT"
  },
  "99557": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ANIAK"
  },
  "99558": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ANVIK"
  },
  "99559": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "BETHEL"
  },
  "99561": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "CHEFORNAK"
  },
  "99563": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "CHEVAK"
  },
  "99564": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "CHIGNIK"
  },
  "99565": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "CHIGNIK LAGOON"
  },
  "99566": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "CHITINA"
  },
  "99567": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "CHUGIAK"
  },
  "99568": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "CLAM GULCH"
  },
  "99569": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "CLARKS POINT"
  },
  "99571": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "COLD BAY"
  },
  "99572": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "COOPER LANDING"
  },
  "99573": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "COPPER CENTER"
  },
  "99574": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "CORDOVA"
  },
  "99575": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "CROOKED CREEK"
  },
  "99576": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "DILLINGHAM"
  },
  "99577": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "EAGLE RIVER"
  },
  "99578": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "EEK"
  },
  "99579": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "EGEGIK"
  },
  "99580": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "EKWOK"
  },
  "99581": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "EMMONAK"
  },
  "99583": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "FALSE PASS"
  },
  "99585": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "MARSHALL"
  },
  "99586": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "GAKONA"
  },
  "99587": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "GIRDWOOD"
  },
  "99588": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "GLENNALLEN"
  },
  "99589": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "GOODNEWS BAY"
  },
  "99590": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "GRAYLING"
  },
  "99591": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "SAINT GEORGE ISLAND"
  },
  "99602": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "HOLY CROSS"
  },
  "99603": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "HOMER"
  },
  "99604": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "HOOPER BAY"
  },
  "99605": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "HOPE"
  },
  "99606": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ILIAMNA"
  },
  "99607": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KALSKAG"
  },
  "99608": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KARLUK"
  },
  "99609": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KASIGLUK"
  },
  "99610": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KASILOF"
  },
  "99611": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KENAI"
  },
  "99612": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KING COVE"
  },
  "99613": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KING SALMON"
  },
  "99614": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KIPNUK"
  },
  "99615": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KODIAK"
  },
  "99620": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KOTLIK"
  },
  "99621": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KWETHLUK"
  },
  "99622": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KWIGILLINGOK"
  },
  "99624": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "LARSEN BAY"
  },
  "99625": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "LEVELOCK"
  },
  "99626": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "LOWER KALSKAG"
  },
  "99627": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "MC GRATH"
  },
  "99628": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "MANOKOTAK"
  },
  "99630": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "MEKORYUK"
  },
  "99631": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "MOOSE PASS"
  },
  "99632": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "MOUNTAIN VILLAGE"
  },
  "99633": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "NAKNEK"
  },
  "99634": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "NAPAKIAK"
  },
  "99635": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "NIKISKI"
  },
  "99636": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "NEW STUYAHOK"
  },
  "99637": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "TOKSOOK BAY"
  },
  "99638": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "NIKOLSKI"
  },
  "99639": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "NINILCHIK"
  },
  "99640": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "NONDALTON"
  },
  "99641": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "NUNAPITCHUK"
  },
  "99643": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "OLD HARBOR"
  },
  "99644": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "OUZINKIE"
  },
  "99645": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "PALMER"
  },
  "99647": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "PEDRO BAY"
  },
  "99648": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "PERRYVILLE"
  },
  "99649": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "PILOT POINT"
  },
  "99650": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "PILOT STATION"
  },
  "99651": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "PLATINUM"
  },
  "99652": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "BIG LAKE"
  },
  "99653": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "PORT ALSWORTH"
  },
  "99654": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "WASILLA"
  },
  "99655": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "QUINHAGAK"
  },
  "99656": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "RED DEVIL"
  },
  "99657": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "RUSSIAN MISSION"
  },
  "99658": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "SAINT MARYS"
  },
  "99659": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "SAINT MICHAEL"
  },
  "99660": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "SAINT PAUL ISLAND"
  },
  "99661": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "SAND POINT"
  },
  "99662": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "SCAMMON BAY"
  },
  "99663": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "SELDOVIA"
  },
  "99664": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "SEWARD"
  },
  "99665": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "SHAGELUK"
  },
  "99666": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "NUNAM IQUA"
  },
  "99667": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "SKWENTNA"
  },
  "99668": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "SLEETMUTE"
  },
  "99669": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "SOLDOTNA"
  },
  "99670": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "SOUTH NAKNEK"
  },
  "99671": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "STEBBINS"
  },
  "99672": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "STERLING"
  },
  "99674": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "SUTTON"
  },
  "99675": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "TAKOTNA"
  },
  "99676": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "TALKEETNA"
  },
  "99677": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "TATITLEK"
  },
  "99678": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "TOGIAK"
  },
  "99679": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "TULUKSAK"
  },
  "99680": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "TUNTUTULIAK"
  },
  "99681": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "TUNUNAK"
  },
  "99682": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "TYONEK"
  },
  "99683": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "TRAPPER CREEK"
  },
  "99684": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "UNALAKLEET"
  },
  "99685": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "UNALASKA"
  },
  "99686": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "VALDEZ"
  },
  "99687": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "WASILLA"
  },
  "99688": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "WILLOW"
  },
  "99689": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "YAKUTAT"
  },
  "99690": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "NIGHTMUTE"
  },
  "99691": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "NIKOLAI"
  },
  "99692": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "DUTCH HARBOR"
  },
  "99693": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "WHITTIER"
  },
  "99694": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "HOUSTON"
  },
  "99695": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ANCHORAGE"
  },
  "99697": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KODIAK"
  },
  "99701": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "FAIRBANKS"
  },
  "99702": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "EIELSON AFB"
  },
  "99703": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "FORT WAINWRIGHT"
  },
  "99704": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "CLEAR"
  },
  "99705": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "NORTH POLE"
  },
  "99709": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "FAIRBANKS"
  },
  "99712": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "FAIRBANKS"
  },
  "99714": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "SALCHA"
  },
  "99720": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ALLAKAKET"
  },
  "99721": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ANAKTUVUK PASS"
  },
  "99722": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ARCTIC VILLAGE"
  },
  "99723": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "BARROW"
  },
  "99724": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "BEAVER"
  },
  "99725": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ESTER"
  },
  "99726": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "BETTLES FIELD"
  },
  "99727": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "BUCKLAND"
  },
  "99729": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "CANTWELL"
  },
  "99730": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "CENTRAL"
  },
  "99732": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "CHICKEN"
  },
  "99733": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "CIRCLE"
  },
  "99736": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "DEERING"
  },
  "99737": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "DELTA JUNCTION"
  },
  "99738": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "EAGLE"
  },
  "99739": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ELIM"
  },
  "99740": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "FORT YUKON"
  },
  "99741": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "GALENA"
  },
  "99742": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "GAMBELL"
  },
  "99743": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "HEALY"
  },
  "99744": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "ANDERSON"
  },
  "99745": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "HUGHES"
  },
  "99746": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "HUSLIA"
  },
  "99747": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KAKTOVIK"
  },
  "99748": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KALTAG"
  },
  "99749": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KIANA"
  },
  "99750": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KIVALINA"
  },
  "99751": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KOBUK"
  },
  "99752": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KOTZEBUE"
  },
  "99753": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KOYUK"
  },
  "99754": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KOYUKUK"
  },
  "99755": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "DENALI NATIONAL PARK"
  },
  "99756": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "MANLEY HOT SPRINGS"
  },
  "99757": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "LAKE MINCHUMINA"
  },
  "99758": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "MINTO"
  },
  "99833": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "PETERSBURG"
  },
  "99835": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "SITKA"
  },
  "99840": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "SKAGWAY"
  },
  "99841": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "TENAKEE SPRINGS"
  },
  "99850": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "JUNEAU"
  },
  "99901": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KETCHIKAN"
  },
  "99903": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "MEYERS CHUCK"
  },
  "99918": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "COFFMAN COVE"
  },
  "99919": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "THORNE BAY"
  },
  "99921": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "CRAIG"
  },
  "99922": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "HYDABURG"
  },
  "99923": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "HYDER"
  },
  "99925": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KLAWOCK"
  },
  "99926": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "METLAKATLA"
  },
  "99927": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "POINT BAKER"
  },
  "99929": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "WRANGELL"
  },
  "99950": {
    stateCode: "AK",
    stateName: "Alaska",
    city: "KETCHIKAN"
  }
} as const;

export interface ZipCodeMeta {
  stateCode: string
  stateName: string
  city: string
}

export const ZIPCODES: Record<string, ZipCodeMeta> = data;

export type SupportedZipCode = keyof typeof data;

// Helper function to check if the zipcode is supported by our calculator.
export function isSupportedZipCode(
  zip: string | null | undefined
): zip is SupportedZipCode {
  return typeof zip === 'string' && zip in data;
}
