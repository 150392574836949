export const scalarOrNull = (v: number | string | null | undefined) => {
  if (v === undefined) {
    return null;
  } else {
    return v;
  }
}


export const almostEqual = (v1: number | null | undefined, v2: number | null | undefined, tol: number = 0.1) => {
  if (!v1 || !v2) {
    return false;
  }
  return Math.abs(v1 - v2) < tol;
}


export const makeStoredDisplayName = (first: string, last: string) => {
  const firstSanitized = first.replace(/[^a-zA-Z ]/g, "");
  const lastSanitized = last.replace(/[^a-zA-Z ]/g, "");

  return `${firstSanitized}:${lastSanitized}`;
}


export const parseStoredDisplayName = (stored: string) => {
  const arr = stored.split(":");
  return {
    firstName: arr[0],
    lastName: arr[1]
  };
}
