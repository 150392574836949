import { NavLink } from 'react-router-dom';
import { createStyles, Group, rem, useMantineTheme, Drawer, Stack, Text, ActionIcon, Divider } from '@mantine/core';
import { IconBook, IconBook2, IconBowl, IconBuilding, IconLogin, IconPlant, IconUsers } from '@tabler/icons-react';
import { URL_WIKI_HOME } from '../../../utils/links';
import { ENABLE_CALCULATOR } from '../../../utils/flags';

const HEADER_HEIGHT = rem(70);


const useStyles = createStyles((theme) => ({
  drawer: {
  },

  drawerContent: {
    backgroundColor: theme.colors.teal[9],
  },

  mobileLinkHeader: {
    fontWeight: 'bold',
    color: 'black',
    textDecoration: 'none'
  },
  mobileLinkDescription: {
    color: theme.colors.gray[6],
    textDecoration: 'none',
    fontWeight: 400
  }
}));


function MobileNavLink(props: { header: string, description: string, to: string, avatar: any, close: () => void }) {
  const {classes} = useStyles();
  return (
    <Group>
      <NavLink to={props.to} style={{textDecoration: 'none'}} onClick={props.close}>
        <Stack spacing={0}>
          <Text className={classes.mobileLinkHeader}>{props.header}</Text>
          <Text className={classes.mobileLinkDescription}>{props.description}</Text>
        </Stack>
      </NavLink>
    </Group>
  );
}


export default function MobileDrawer({ opened, close }: { opened: boolean, close: () => void }) {
  const {classes} = useStyles();
  const theme = useMantineTheme();
  const iconColor = "white";

  return (
    <Drawer.Root
      className={classes.drawer}
      opened={opened}
      onClose={close}
      size={`calc(100% - ${HEADER_HEIGHT})`}
      padding={"md"}
      zIndex={1000}
      position='bottom'
    >
      <Drawer.Overlay sx={{backgroundColor: theme.colors.teal[9]}}/>
      <Drawer.Content p={"xs"}>
        <Drawer.Body>
          <Stack spacing={"lg"}>
          <MobileNavLink to="/our-approach" avatar={<IconBowl size={"1.2rem"} color={iconColor}/>} header="🌾 Our approach" description="How we're making food a climate solution" close={close}/>
          <MobileNavLink to="/take-action" avatar={<IconPlant size={"1.2rem"} color={iconColor}/>} header="🌎 Take action" description="Calculate and offset your emissions" close={close}/>
          <MobileNavLink to="/for-companies" avatar={<IconBuilding size={"1.2rem"} color={iconColor}/>} header="🏢 For companies" description="Make a climate contribution" close={close}/>
          <MobileNavLink to="/about-us" avatar={<IconUsers size={"1.2rem"} color={iconColor}/>} header="👋🏽 About us" description="Our team and mission" close={close}/>
          <MobileNavLink to={URL_WIKI_HOME} avatar={<IconBook2 size={"1.2rem"} color={iconColor}/>} header="📖 Wiki" description="Resources and more information" close={close}/>
          <MobileNavLink to="/references" avatar={<IconBook size={"1.2rem"} color={iconColor}/>} header="📓 References" description="Citations and further reading" close={close}/>
          <Divider size="sm"/>
          {

            ENABLE_CALCULATOR ?
            <MobileNavLink to="/dashboard" avatar={<IconLogin size={"1.2rem"} color={iconColor}/>} header="🌲 Impact Dashboard" description="Track your climate impact" close={close}/>
            : undefined
          }
          {
            ENABLE_CALCULATOR ?
            <MobileNavLink to="/calculator" avatar={<IconLogin size={"1.2rem"} color={iconColor}/>} header="📏 Footprint calculator" description="Calculate your carbon footprint" close={close}/>
            : undefined
          }
          {
            ENABLE_CALCULATOR ?
            <MobileNavLink to="/logout" avatar={<IconLogin size={"1.2rem"} color={iconColor}/>} header="Log out" description="Sign out of your account" close={close}/>
            : undefined
          }
          </Stack>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  )
}
