import { Group, Stack, Text, createStyles } from "@mantine/core";
import CalculatorButton from "../../../components/calculator/CalculatorButton";
import { useGetCalculatorInputsQuery, useUpdateCalculatorInputsMutation } from "../../../api/calculator";
import { selectCurrentUserId, useAppSelector } from "../../../redux/hooks";
import ArrowNumberInput from "../../../components/calculator/ArrowNumberInput";


const useStyles = createStyles((theme) => ({
}));


export default function SelectPets() {
  const {classes} = useStyles();
  const userId = useAppSelector(selectCurrentUserId) as string;

  const {data} = useGetCalculatorInputsQuery(userId);
  const [updateCalculator] = useUpdateCalculatorInputsMutation();

  const inputs = data?.inputs;
  const hasPets = !!inputs?.userIncludePets;
  const cats = inputs?.numPetsCats || 0;
  const dogs = inputs?.numPetsNormalDogs || 0;
  const largeDogs = inputs?.numPetsLargeDogs || 0;

  const setHasPets = (include: boolean) => {
    if (include !== inputs?.userIncludePets) {
      updateCalculator({userId, inputs: { userIncludePets: include }});
    }
  }

  const updatePets = (key: "numPetsCats" | "numPetsNormalDogs" | "numPetsLargeDogs", value: number | "") => {
    updateCalculator({userId, inputs: { [key]: (value === "") ? 0 : value }});
  }

  return (
    <Stack>
      <Group>
        <CalculatorButton selected={inputs?.userIncludePets === true} onClick={() => setHasPets(true)}>
          Yes
        </CalculatorButton>
        <CalculatorButton selected={inputs?.userIncludePets === false} onClick={() => setHasPets(false)}>
          No
        </CalculatorButton>
      </Group>
      {
        hasPets ?
        <>
          <Group grow>
            <Text fw={600}>Cats</Text>
            <ArrowNumberInput
              value={cats}
              defaultValue={0}
              min={0}
              max={10}
              onChange={(value) => updatePets("numPetsCats", value)}
            />
          </Group>
          <Group grow>
            <Text fw={600}>Dogs</Text>
            <ArrowNumberInput
              value={dogs}
              defaultValue={0}
              min={0}
              max={10}
              onChange={(value) => updatePets("numPetsNormalDogs", value)}
            />
          </Group>
          <Group grow>
            <Text fw={600}>Large dogs (over 20kg)</Text>
            <ArrowNumberInput
              value={largeDogs}
              defaultValue={0}
              min={0}
              max={10}
              onChange={(value) => updatePets("numPetsLargeDogs", value)}
            />
          </Group>
        </>
        : undefined
      }
    </Stack>
  );
}
