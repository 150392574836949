import {
  createStyles,
  Container,
  Accordion,
  Text,
  rem,
  Space,
  Anchor,
} from '@mantine/core';
import HeaderTitle from '../../shared/HeaderTitle';


const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    color: theme.black,
  },

  item: {
    backgroundColor: theme.white,
    borderBottom: 0,
    borderRadius: theme.radius.sm,
    boxShadow: theme.shadows.sm,
    overflow: 'hidden',
  },

  label: {
    fontSize: theme.fontSizes.md,
    fontWeight: 600,
  },

  content: {
    paddingLeft: rem(4),
    lineHeight: 1.6,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing.md,

    [theme.fn.smallerThan('sm')]: {
      display: 'block',
      width: '100%',
    },
  },
}));


export default function HomeFAQ() {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <Container size="sm">
        <HeaderTitle header={"Frequently Asked Questions"}/>
        <Space h="xl"/>
        <Accordion
          chevronPosition="left"
          defaultValue="reset-password"
          chevronSize={50}
          variant={"separated"}
        >
          {/* --- */}
          <Accordion.Item className={classes.item} value="focus-on-food">
            <Accordion.Control>
              <Text className={classes.label}>Why do you focus on the food system?</Text>
            </Accordion.Control>
            <Accordion.Panel>
              <Text className={classes.content}>
                The food sector is one of the most under-funded areas of climate change mitigation,
                despite the fact that it represents one-third of global emissions. Food also uses
                half of Earth's habitable land and is the largest driver of deforestation. In order to
                reduce emissions, protect natural ecosystems, and scale up nature-based carbon removal,
                we need to reduce the demand for emission-intensive and land-intensive foods. Put simply,
                we can't solve climate change without addressing food.
                <br/><br/>
                Within the food sector, we focus on promoting sustainable diets because of the
                massive impact this solution could have. Sustainable diets can cut food emissions in
                half, use one-quarter of the land, reduce freshwater use, reduce pandemic risk,
                improve human health, and reduce the number of animals living in factory farms.
              </Text>
            </Accordion.Panel>
          </Accordion.Item>

          {/* --- */}
          <Accordion.Item className={classes.item} value="where-are-food-emissions-coming-from">
            <Accordion.Control>
              <Text className={classes.label}>Where are food emissions coming from?</Text>
            </Accordion.Control>
            <Accordion.Panel>
              <Text className={classes.content}>
                While every food has an environmental impact, animal-based foods are significantly
                more resource and emission-intensive.
                <br/><br/>
                Animal-based foods account for twice the emissions of plant-based foods, despite providing
                only 18% of the world's calories and 37% of its protein. Emissions from livestock are largely
                due to deforestation to make space for pasture and feed crops, as well as methane emissions
                from ruminant animals. Among plant-based foods, rice is a notable contributor due to
                its methane emissions, though this is much lower than ruminant animals.
              </Text>
            </Accordion.Panel>
          </Accordion.Item>

          {/* --- */}
          <Accordion.Item className={classes.item} value="food-and-land">
            <Accordion.Control>
              <Text className={classes.label}>Why do you combine sustainable food with restoration and conservation?</Text>
            </Accordion.Control>
            <Accordion.Panel>
              <Text className={classes.content}>
                The latest IPCC report stresses that we need rapid emissions reductions <i>and</i> carbon
                removal to avoid the worst impacts of climate change (<Anchor href="https://www.ipcc.ch/report/ar6/syr/" rel="noreferrer" target="_blank">report</Anchor> and <Anchor href="https://www.carbon-direct.com/insights/ipcc-report-carbon-removal-is-now-required-to-meet-climate-mitigation-targets" rel="noreferrer" target="_blank">blog post</Anchor>).
                Fortunately, <strong>shifting to plant-based diets</strong> can help us achieve both goals.
                By switching to less emission-intensive and land-intensive foods, we can reduce food
                emissions and free up land for natural carbon removal.{" "}
                Currently, <Anchor href="https://www.landgap.org/" rel="noreferrer" target="_blank">we don't have enough land available to meet carbon removal targets</Anchor>; most
                of it is occupied by pasture and feed crops for livestock. <strong>Nature-based carbon removal must be
                paired with commensurate reductions in land use to ensure that it does not compete
                with food, livelihoods, and other important land uses.</strong> To our knowledge, we're
                the first company to address this by pairing sustainable diets with nature-based projects.
              </Text>
            </Accordion.Panel>
          </Accordion.Item>

          {/* --- */}
          <Accordion.Item className={classes.item} value="are-you-a-nonprofit">
            <Accordion.Control>
              <Text className={classes.label}>Are you a nonprofit?</Text>
            </Accordion.Control>
            <Accordion.Panel>
              <Text className={classes.content}>
                We are a for-profit, public benefit corporation (PBC), which means that social and
                environmental impact are part of our company charter. Incorporating as a PBC allows
                us to raise outside capital and scale our impact as quickly as possible. You can
                learn more about us <Anchor href="/about-us">here</Anchor>.
              </Text>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Container>
    </div>
  );
}
