let API_BASE_URL = 'http://127.0.0.1:8000';
if (process.env.REACT_APP_ENV === "production") {
  API_BASE_URL = "https://api.refarm.dev";
} else if (process.env.REACT_APP_ENV === "staging") {
  API_BASE_URL = "https://api.staging.refarm.dev";
}

export function getApiBaseUrl() {
  return API_BASE_URL;
}
