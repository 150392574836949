import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { merge } from 'lodash';
import { RootState } from '../store';
import { getApiBaseUrl } from './config';
import { GetSubscriptionPlansResult } from './shared';

export type CalculationType = "individual" | "household" | null;
export type DietPattern = "vegan" | "vegetarian" | "neither" | null;

export interface UserCar {
  id: string
  index: number
  name: string | null
  type: "gas" | "electric" | "hybrid"
  fuelEconomyMpg: number
  milesPerWeek: number
}

export interface CalculatorInputs {
  userRoleEducating: boolean | null
  userRoleReducingEmissions: boolean | null
  userRoleVoting: boolean | null
  userRoleWorking: boolean | null
  userRoleDonating: boolean | null
  userRoleNotSure: boolean | null

  country: string | null
  countryCode: string | null
  zipcode: string | null

  calculationType: CalculationType
  householdAdults: number | null
  householdChildren: number | null

  numCars: number | null
  cars: {[id: string]: UserCar};

  useCustomFlights: boolean | null
  numShortHaulFlights: number | null
  numMediumHaulFlights: number | null
  numLongHaulFlights: number | null

  dietPattern: DietPattern | null
  dietRedMeatServingsPerWeek: number | null
  dietDairyServingsPerWeek: number | null
  dietSeafoodServingsPerWeek: number | null
  dietOtherAnimalServingsPerWeek: number | null

  usesBus: boolean | null;
  usesTransitRail: boolean | null
  usesInterCityRail: boolean | null
  usesWalkOrBike: boolean | null

  busMiPerWeek: number | null
  transitRailMiPerWeek: number | null
  interCityRailMiPerYear: number | null

  homeSizeFt2: number | null

  monthlySpendingServicesUsd: number | null

  monthlySpendingGoodsClothesUsd: number | null
  monthlySpendingGoodsFurnitureAndAppliancesUsd: number | null
  monthlySpendingGoodsOtherUsd: number | null

  userIncludePets: boolean | null
  numPetsCats : number | null
  numPetsNormalDogs : number | null
  numPetsLargeDogs: number | null

  electricityKwhPerMonth: number | null
  naturalGasThermsPerMonth: number | null
  homeRenewablePercent: number | null
}


export interface CalculatorDefaults {
  busMiPerWeek: number
  transitRailMiPerWeek: number
  interCityRailMiPerYear: number

  homeSizeFt2: number

  monthlySpendingServicesUsd: number

  monthlySpendingGoodsClothesUsd: number
  monthlySpendingGoodsFurnitureAndAppliancesUsd: number
  monthlySpendingGoodsOtherUsd: number

  electricityKwhPerMonth: number
  electricityUsdPerKwh: number
  naturalGasThermsPerMonth: number
  naturalGasUsdPerTherm: number

  flightsPerYear: number
  flightsMiPerYear: number
}


export interface FootprintBreakdown {
  electricity: number
  transport: number
  diet: number
  naturalGas: number
  home: number
  pets: number
  goods: number
  services: number
  total: number
}


interface CalculatorResults {
  country: string | null
  countryCode: string | null
  userTonsPerYear: FootprintBreakdown | null
  countryTonsPerYear: FootprintBreakdown | null
  compareCountries: {country: string, value: number}[]
  landUseHa: number | null
}


interface ReduceAction {
  label: string
  impactTonsCo2: number
}


// Choose the API base URL depending on the environment:
const BASE_URL = `${getApiBaseUrl()}/v1/`;

export const calculatorApi = createApi({
  reducerPath: 'calculatorApi',
  tagTypes: ["CalculatorState", "CalculatorInputs", "CalculatorResults", "CalculatorDefaults", "ReduceActions", "SubscriptionPlans"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.currentUser.accessToken;
      headers.set('authorization', `Bearer ${token || "NONE"}`);
      return headers;
    }
  }),
  endpoints: (builder) => ({

    getCalculatorInputs: builder.query<{inputs: CalculatorInputs}, string>({
      query: (userId: string) => ({
        url: `/calculator/inputs`,
      }),
      // providesTags: [{"CalculatorState", id: user}]
      providesTags: (result, error, arg) => [{type: "CalculatorInputs", id: arg}]
    }),

    getCalculatorResults: builder.query<{results: CalculatorResults}, string>({
      query: (userId: string) => ({
        url: `/calculator/results`,
      }),
      providesTags: (result, error, arg) => [{type: "CalculatorResults", id: arg}]
    }),

    getRefreshedCalculatorResults: builder.query<{results: CalculatorResults}, string>({
      query: (userId: string) => ({
        url: `/calculator/results?forceRefresh=true`,
      }),
      providesTags: (result, error, arg) => [{type: "CalculatorResults", id: arg}]
    }),

    getCalculatorDefaults: builder.query<{defaults: CalculatorDefaults}, string>({
      query: (userId: string) => ({
        url: `/calculator/defaults`,
      }),
      providesTags: (result, error, arg) => [{type: "CalculatorDefaults", id: arg}]
    }),

    getSubscriptionPlans: builder.query<GetSubscriptionPlansResult, {userId: string}>({
      query: (args) => ({
        url: `/calculator/list-subscription-plans`,
      }),
      providesTags: (result, error, arg) => [{ type: "SubscriptionPlans", id: arg.userId }]
    }),

    resetCalculator: builder.mutation<void, {userId: string}>({
      query: ({ userId }) => ({
        url: `/calculator/reset`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => {
        return [
          { type: "CalculatorResults", id: arg.userId },
          { type: "ReduceActions", id: arg.userId },
          { type: "SubscriptionPlans", id: arg.userId },
          { type: "CalculatorInputs", id: arg.userId },
          { type: "CalculatorResults", id: arg.userId },
        ];
      }
    }),

    updateCalculatorInputs: builder.mutation<void, {userId: string, inputs: Partial<CalculatorInputs>}>({
      query: ({ userId, inputs }) => ({
        url: `/calculator/inputs`,
        method: 'PATCH',
        body: inputs,
      }),
      // @ts-ignore
      invalidatesTags: (result, error, arg) => {
        const serverWillModifyInputs = arg.inputs.numCars;
        const defaultsMayChange =
          arg.inputs.countryCode || arg.inputs.zipcode ||
          arg.inputs.householdAdults || arg.inputs.householdChildren ||
          arg.inputs.homeSizeFt2;

        return [
          { type: "CalculatorResults", id: arg.userId },
          { type: "ReduceActions", id: arg.userId },
          { type: "SubscriptionPlans", id: arg.userId },
          ...(serverWillModifyInputs ? [{ type: "CalculatorInputs", id: arg.userId }] : []),
          ...(defaultsMayChange ? [{ type: "CalculatorDefaults", id: arg.userId }] : []),
        ];
      },
      async onQueryStarted({ userId, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          calculatorApi.util.updateQueryData("getCalculatorInputs", userId, (draft) => {
            // Need to deeply merge here:
            merge(draft, patch);
          })
        )
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),

    getReduceActions: builder.query<ReduceAction[], string>({
      query: (userId: string) => ({
        url: `/calculator/calculator-reduce-actions`
      }),
      providesTags: (result, error, arg) => [{ type: "ReduceActions", id: arg }]
    })
  }),
})


export const {
  useGetCalculatorInputsQuery,
  useGetCalculatorResultsQuery,
  useGetCalculatorDefaultsQuery,
  useUpdateCalculatorInputsMutation,
  useGetRefreshedCalculatorResultsQuery,
  useGetReduceActionsQuery,
  useGetSubscriptionPlansQuery,
  useResetCalculatorMutation,
} = calculatorApi
