import { Container, createStyles, Stack, useMantineTheme } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { CreditWaitlistBanner } from "../../components/public/Action/Waitlist";
import PortfolioSection from "../../components/public/Approach/PortfolioSection";
import HowItWorks from "../../components/public/Home/HowItWorks";
import HeaderTitle from "../../components/shared/HeaderTitle";
import DifferentTabs from "../../components/public/Approach/DifferentTabs";
import UrgencySection from "../../components/public/Home/UrgencySection";
import { useEffect } from "react";


const useStyles = createStyles((theme) => ({
  imageContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: -1,
    display: "block",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
  },

  contentContainer: {
    paddingTop: '10vh',
    paddingBottom: '10vh',
  },

  topImageContainer: {
    backgroundImage: "url(/assets/approach/forest_with_crops.jpeg)",
    backgroundPosition: "bottom",
    filter: "brightness(80%)"
  },

  darkSection: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
    backgroundColor: theme.colors.teal[9],
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',

    [theme.fn.smallerThan("sm")]: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    }
  },
  lightSection: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
    backgroundColor: 'white',
    [theme.fn.smallerThan("sm")]: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    }
  },
  graySection: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
    backgroundColor: theme.colors.gray[0],
    [theme.fn.smallerThan("sm")]: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    }
  },
  grayGradientSection: {
    paddingTop: theme.spacing.lg,
    paddingBottom: theme.spacing.lg,
    background: 'linear-gradient(180deg, rgba(231, 243, 241, 0.00) 0%, #E7F3F1 100%)',
  },

  citationWhite: {
    color: 'white',
    fontSize: 10,
  },
  citationDark: {
    color: theme.colors.gray[6],
    fontSize: 10,
  },
}));


function TakeActionRoute() {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const theme = useMantineTheme();

  // Always scroll to the top of this page.
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <>
      <Container fluid pos={"relative"}>
        <div className={cx(classes.imageContainer, classes.topImageContainer)}/>
        <div className={classes.contentContainer}>
          <Container size="md">
            <Stack align="center" spacing={60}>
              <CreditWaitlistBanner/>
            </Stack>
          </Container>
        </div>
      </Container>

      <Container className={classes.lightSection} fluid style={{}}>
        <Container size="md">
          <HeaderTitle
            header="Our Approach"
            title="Fund sustainable food, ecosystem restoration, and conservation with one carbon credit."
          />
          <PortfolioSection includeButton={true}/>
        </Container>
      </Container>

      <Container fluid className={classes.graySection}>
        <HeaderTitle
          header={"A new type of carbon credit"}
          title={"What makes our carbon credits different?"}
          align="center"
        />
        <DifferentTabs/>
      </Container>

      <Container className={classes.lightSection} fluid style={{}}>
        <HowItWorks/>
      </Container>
      {/* ------ */}

      <UrgencySection/>
    </>
  );
}

export default TakeActionRoute;
