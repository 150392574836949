import { useState } from "react";

import { useForm } from "@mantine/form";

import { Alert, Button, Group, PasswordInput, Stack, TextInput, createStyles, useMantineTheme } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

import { getAuth, linkWithCredential, EmailAuthProvider, updateProfile, signInWithCredential } from "firebase/auth";
import { makeStoredDisplayName } from "../../utils/helpers";
import { selectCurrentUser, selectIsLoggedIn, useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setCurrentUser } from "../../redux/userSlice";
import { Navigate } from "react-router-dom";


const useStyles = createStyles((theme) => ({
  muted: {
    color: theme.colors.gray[7],
    fontSize: "14px",
  },
  dark: {
    color: theme.colors.gray[8]
  }
}));


export default function CreateAccountForm(props: { handleSuccess?: () => void }) {
  const theme = useMantineTheme();
  const {classes, cx} = useStyles();

  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  // https://firebase.google.com/docs/auth/web/start
  // Creating a user will also update the `auth.currentUser` state.
  const handleSubmit = (values: typeof form.values) => {
    const auth = getAuth();

    if (auth.currentUser && auth.currentUser.isAnonymous) {
      setLoading(true);

      console.debug("@ Linking email to the current anonymous user");
      const credential = EmailAuthProvider.credential(values.email, values.password);

      linkWithCredential(auth.currentUser, credential)
        .then((userCredential) => {
          const user = userCredential.user;
          console.debug("@ linkWithCredential");
          console.debug(user);
          setLoading(false);

          if (props.handleSuccess) {
            props.handleSuccess();
          }

          updateProfile(
            userCredential.user, {
            displayName: makeStoredDisplayName(values.firstName, values.lastName)
          });

          user.getIdTokenResult().then((value) => {
            dispatch(setCurrentUser({
              userId: user.uid,
              accessToken: value.token,
              tokenExpires: new Date(value.expirationTime).valueOf(),
              isAnonymous: user.isAnonymous,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
            }));
          }).catch((err) => {
            console.debug("@ Error while getting user token");
            console.error(err);
          });
        })
        .catch((error) => {
          const errorCode = error.code;
          console.error(errorCode);
          setErrorCode(errorCode);
          setLoading(false);
        });
    } else {
      console.debug("@ User is already logged in, doing nothing.");
      console.debug(auth.currentUser);
    }
  };

  // Called when bad form data is submitted.
  const handleError = (errors: any) => {
  };

  const regexValidEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  const form = useForm({
    initialValues: { email: '', firstName: '', lastName: '', password: '' },

    validate: {
      email: (value) => (regexValidEmail.test(value) ? null : 'Please enter a valid email'),
      firstName: (value) => ((value.length >= 1) ? null : 'Please enter a first name'),
      lastName: (value) => ((value.length >= 1) ? null : 'Please enter a last name'),
      password: (value) => ((value.length >= 8) ? null : 'Must be at least 8 characters')
    },
  });

  if (isLoggedIn) {
    console.debug("@ User already logged in, redirecting to dashboard");
    return <Navigate to="/dashboard"/>
  }

  const styles = {
    label: {
      color: theme.colors.gray[8]
    },
    input: {
      color: theme.colors.gray[8]
    }
  };

  const readableErrors = {
    "auth/email-already-in-use": "That email is already in use - try logging in instead."
  };

  const errorAlert = <Alert variant="light" color="orange" icon={<IconAlertCircle/>} p={"xs"}>
    {(errorCode && errorCode in readableErrors) ? readableErrors[errorCode as keyof typeof readableErrors] : "Something went wrong"}
  </Alert>;

  return (
    <form onSubmit={form.onSubmit(handleSubmit, handleError)}>
      <Stack spacing={"lg"}>
        <Group grow>
          <TextInput size="md" placeholder="First" {...form.getInputProps('firstName')} label="First name" withAsterisk className={cx(classes.dark)} styles={styles}/>
          <TextInput size="md" placeholder="Last" {...form.getInputProps('lastName')} label="Last name" withAsterisk className={cx(classes.dark)} styles={styles}/>
        </Group>
        <TextInput size="md" placeholder="Email" {...form.getInputProps('email')} label="Email" withAsterisk className={cx(classes.dark)} styles={styles}/>
        <PasswordInput size="md" {...form.getInputProps('password')} label="Password" withAsterisk className={cx(classes.dark)} styles={styles}/>
        <Button
          size='md'
          type="submit"
          loading={loading}
        >
          Continue
        </Button>
        { errorCode ? errorAlert : undefined }
      </Stack>
    </form>
  );
}
