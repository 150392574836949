import { useEffect, useRef } from 'react';
import { Box, ScrollArea, Space, Stepper, createStyles, useMantineTheme } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { Icon, IconCheck, IconCircleCheck } from '@tabler/icons-react';


const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.md,
    // zIndex: 0,
  },

  steps: {
    alignItems: 'center',
  },

  verticalSeparator: {
    display: 'none',
  },

  verticalSeparatorActive: {
    display: 'none',
  },

  stepIcon: {
    transition: 'transform 0.1s ease',
    '&[data-progress]': {
      transform: 'scale(1.3)'
    }
  },

  step: {
    overflow: 'visible',
    opacity: 0.3,

    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing.lg,

    '&[data-progress]': {
      opacity: 1.0,
    },
  },

  stepBody: {
    marginLeft: 0,
    padding: theme.spacing.sm,
    maxWidth: 120,
  },

  stepLabel: {
    textAlign: 'center',
    fontSize: theme.fontSizes.xs
  },

  blurBox: {
    height: '24px',
    width: '140px',
    position: 'absolute',
    top: 0,
    background: 'linear-gradient(rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%)',
    zIndex: 1
  }
}));


export interface StepData {
  slug: string
  label: string
  title: string | JSX.Element
  description: string
  icon: Icon
  element: React.ReactElement
  completed?: boolean
}


function CompletedIcon() {
  return (
    <IconCheck size="1.4rem" data-completed color='white' stroke={4}/>
  );
}


export default function CalculatorStepper(props: { stepData: StepData[], stepIndex: number, width?: number }) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const viewport = useRef<HTMLDivElement>(null);

  const steps = props.stepData.map((value, index) =>
    <Stepper.Step
      label={value.label}
      icon={value.completed ? <CompletedIcon/> : <value.icon size={"1.2rem"}/>}
      completedIcon={value.completed ? <CompletedIcon/> : <value.icon size={"1.2rem"}/>}
      key={value.label}
      id={`stepper-step-${index}`}
    >
    </Stepper.Step>
  );

  const setStep = (newStepIndex: number) => {
    const newSlug = props.stepData[newStepIndex].slug;
    navigate(`/calculator/${newSlug}`);
  }

  // Scroll to the step with a given index.
  const scrollToStep = (stepIndex: number, behavior: 'smooth' | 'auto') => {
    if (!viewport.current) {
      return;
    }
    const el = document.getElementById(`stepper-step-${stepIndex}`);
    viewport.current.scrollTo({ top: (el?.offsetTop || 0) - 24, behavior});
  }

  useEffect(() => {
    scrollToStep(props.stepIndex, 'smooth')
  }, [props.stepIndex]);

  // Check if we've gone too far and limit if so.
  const onScrollPositionChange = (e: { x: number, y: number }) => {
    const maxElement = document.getElementById(`stepper-step-${steps.length - 1}`);

    if (maxElement && (e.y > maxElement.offsetTop)) {
      scrollToStep(steps.length - 1, 'auto');
    }
  }

  return (
    <ScrollArea
      viewportRef={viewport}
      type={"never"}
      h={"80vh"}
      w={props.width || 150}
      onScrollPositionChange={onScrollPositionChange}
      sx={{overflowX: 'hidden', overflowY: 'scroll'}}
    >
      <Box className={classes.blurBox}/>
      <Space h={24}/>
      <Stepper
        classNames={classes}
        active={props.stepIndex}
        onStepClick={(clickedStep: number) => {
          scrollToStep(clickedStep, 'smooth');
          setStep(clickedStep);
        }}
        styles={{
          stepIcon: {
            backgroundColor: theme.colors.gray[0],
            color: theme.colors.gray[9],
            borderColor: "transparent",
            "&[data-completed]": {
              backgroundColor: theme.colors.gray[0],
              borderColor: "transparent",
            },
            // https://www.smashingmagazine.com/2021/06/has-native-css-parent-selector/
            ":has(svg[data-completed])": {
              backgroundColor: theme.colors.teal[6],
            }
          },
          stepCompletedIcon: {
            color: theme.colors.gray[9],
          }
        }}
        orientation="vertical"
      >
        {steps}
      </Stepper>
    </ScrollArea>
  );
}
