import { useState } from "react";
import { Select, createStyles } from "@mantine/core";

import { almostEqual } from "../../../utils/helpers";
import { NaturalGasUnit, convertNaturalGasUnits } from "../utils/units";
import { selectCurrentUserId, useAppSelector } from "../../../redux/hooks";
import { useGetCalculatorDefaultsQuery, useGetCalculatorInputsQuery, useUpdateCalculatorInputsMutation } from "../../../api/calculator";
import CustomNumberInput from "../../../components/calculator/CustomNumberInput";
import MultipleChoice, { IChoiceData, isCustomNumericOptionSelected } from "../../../components/calculator/MultipleChoice";


const useStyles = createStyles((theme) => ({
  select: {
    input: {
      paddingLeft: "1rem",
      display: "flex",
      border: `2px solid ${theme.colors.gray[4]}`,
      transitionDuration: ".2s",
      color: theme.colors.gray[9],
    }
  },
}));


export default function SelectNaturalGas(props: { goToNext?: () => void }) {
  const {classes} = useStyles();

  const userId = useAppSelector(selectCurrentUserId) as string;

  const [units, setUnits] = useState<NaturalGasUnit | "$">("$");

  const {data} = useGetCalculatorInputsQuery(userId);
  const [updateCalculator] = useUpdateCalculatorInputsMutation();

  const defaultsApi = useGetCalculatorDefaultsQuery(userId);
  const loading = defaultsApi.isLoading || defaultsApi.isFetching || !userId;

  const usdPerTherm = defaultsApi.data?.defaults.naturalGasUsdPerTherm || 1.53476;

  const inputs = data?.inputs;
  const userValueTherms = inputs?.naturalGasThermsPerMonth || null;
  const storedGasUnit = "therms";
  const userValue = (userValueTherms === null) ? null :
    (units === "$" ?
      userValueTherms * usdPerTherm :
      convertNaturalGasUnits(userValueTherms, storedGasUnit, units)
    );

  const setUserValue = (value: number | string | null) => {
    if (value === null) return;
    const valueTherms = units === "$" ? (value as number / usdPerTherm) : convertNaturalGasUnits(value as number, units, "therms");
    updateCalculator({userId, inputs: { naturalGasThermsPerMonth: valueTherms as number }});
  };

  const sharedOnClick = (data: IChoiceData) => {
    setUserValue(data.value);

    if (props.goToNext) {
      props.goToNext();
    }
  }

  const meanValueTherms = defaultsApi.data?.defaults.naturalGasThermsPerMonth || 30;
  const meanValue = (units === "$") ?
    meanValueTherms * usdPerTherm :
    convertNaturalGasUnits(meanValueTherms, storedGasUnit, units);
  const lowValue = meanValue * 0.5;
  const highValue = meanValue * 1.5;

  const choices: IChoiceData[] = [
    {
      value: lowValue,
      label: "A little",
      detail: `${lowValue.toLocaleString("default", { maximumFractionDigits: 0})} ${units}/month`,
      selected: almostEqual(userValue, lowValue, 0.1),
      onClick: sharedOnClick
    },
    {
      value: meanValue,
      label: "Average",
      detail: `${meanValue.toLocaleString("default", { maximumFractionDigits: 0})} ${units}/month`,
      selected: almostEqual(userValue, meanValue, 0.1),
      onClick: sharedOnClick
    },
    {
      value: highValue,
      label: "A lot",
      detail: `${highValue.toLocaleString("default", { maximumFractionDigits: 0})} ${units}/month`,
      selected: almostEqual(userValue, highValue, 0.1),
      onClick: sharedOnClick
    }
  ];

  const customIsSelected = isCustomNumericOptionSelected(choices, userValue, 0.1);

  const customInput =
    <CustomNumberInput
      onChange={setUserValue}
      units={`${units}/month`}
      initialValue={customIsSelected ? userValue : null}
      selected={customIsSelected}
      min={0}
      max={10000}
    />;

  return (
    <>
      <MultipleChoice
        choices={choices}
        customInputElement={customInput}
        loading={loading}
      />
      <Select
        className={classes.select}
        size="lg"
        radius="md"
        label="Choose units"
        value={units}
        onChange={(value: string | null) => {
          if (!value) return;
          setUnits(value as NaturalGasUnit | "$")
        }}
        data={[
          { value: 'therms', label: 'therms/month' },
          { value: 'MMBtu', label: 'MMBtu/month' },
          { value: "m³", label: "m³/month" },
          { value: 'Ccf', label: 'Ccf/month' },
          { value: '$', label: '$/month' },
        ]}
      />
    </>
  );
}
