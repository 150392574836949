const data = {
  USA: {
    countryName: "United States of America",
    alpha2: "US",
    currencyCode: "USD",
    emoji: "🇺🇸"
  },
  CAN: {
    countryName: "Canada",
    alpha2: "CA",
    currencyCode: "CAD",
    emoji: "🇨🇦"
  },
  GBR: {
    countryName: "United Kingdom",
    alpha2: "GB",
    currencyCode: "GBP",
    emoji: "🇬🇧"
  },
  AUS: {
    countryName: "Australia",
    alpha2: "AU",
    currencyCode: "AUD",
    emoji: "🇦🇺"
  },
  AUT: {
    countryName: "Austria",
    alpha2: "AT",
    currencyCode: "EUR",
    emoji: "🇦🇹"
  },
  BEL: {
    countryName: "Belgium",
    alpha2: "BE",
    currencyCode: "EUR",
    emoji: "🇧🇪"
  },
  CZE: {
    countryName: "Czech Republic",
    alpha2: "CZ",
    currencyCode: "EUR",
    emoji: "🇨🇿"
  },
  FIN: {
    countryName: "Finland",
    alpha2: "FI",
    currencyCode: "EUR",
    emoji: "🇫🇮"
  },
  FRA: {
    countryName: "France",
    alpha2: "FR",
    currencyCode: "EUR",
    emoji: "🇫🇷"
  },
  DEU: {
    countryName: "Germany",
    alpha2: "DE",
    currencyCode: "EUR",
    emoji: "🇩🇪"
  },
  HUN: {
    countryName: "Hungary",
    alpha2: "HU",
    currencyCode: "EUR",
    emoji: "🇭🇺"
  },
  IRL: {
    countryName: "Ireland",
    alpha2: "IE",
    currencyCode: "EUR",
    emoji: "🇮🇪"
  },
  ITA: {
    countryName: "Italy",
    alpha2: "IT",
    currencyCode: "EUR",
    emoji: "🇮🇹"
  },
  KOR: {
    countryName: "South Korea",
    alpha2: "KR",
    currencyCode: null,
    emoji: "🇰🇷"
  },
  NLD: {
    countryName: "Netherlands",
    alpha2: "NL",
    currencyCode: "EUR",
    emoji: "🇳🇱"
  },
  NZL: {
    countryName: "New Zealand",
    alpha2: "NZ",
    currencyCode: "NZD",
    emoji: "🇳🇿"
  },
  NOR: {
    countryName: "Norway",
    alpha2: "NO",
    currencyCode: "EUR",
    emoji: "🇳🇴"
  },
  POL: {
    countryName: "Poland",
    alpha2: "PL",
    currencyCode: null,
    emoji: "🇵🇱"
  },
  PRT: {
    countryName: "Portugal",
    alpha2: "PT",
    currencyCode: "EUR",
    emoji: "🇵🇹"
  },
  SVK: {
    countryName: "Slovakia",
    alpha2: "SK",
    currencyCode: "EUR",
    emoji: "🇸🇰"
  },
  ESP: {
    countryName: "Spain",
    alpha2: "ES",
    currencyCode: "EUR",
    emoji: "🇪🇸"
  },
  SWE: {
    countryName: "Sweden",
    alpha2: "SE",
    currencyCode: "EUR",
    emoji: "🇸🇪"
  },
  CHE: {
    countryName: "Switzerland",
    alpha2: "CH",
    currencyCode: "CHF",
    emoji: "🇨🇭"
  },
  TUR: {
    countryName: "Turkey",
    alpha2: "TR",
    currencyCode: null,
    emoji: "🇹🇷"
  },
  EST: {
    countryName: "Estonia",
    alpha2: "EE",
    currencyCode: "EUR",
    emoji: "🇪🇪"
  },
  ISR: {
    countryName: "Israel",
    alpha2: "IL",
    currencyCode: null,
    emoji: "🇮🇱"
  },
  SVN: {
    countryName: "Slovenia",
    alpha2: "SI",
    currencyCode: "EUR",
    emoji: "🇸🇮"
  },
  LVA: {
    countryName: "Latvia",
    alpha2: "LV",
    currencyCode: "EUR",
    emoji: "🇱🇻"
  },
  LTU: {
    countryName: "Lithuania",
    alpha2: "LT",
    currencyCode: "EUR",
    emoji: "🇱🇹"
  },
  CRI: {
    countryName: "Costa Rica",
    alpha2: "CR",
    currencyCode: null,
    emoji: "🇨🇷"
  },
  ABW: {
    countryName: "Aruba",
    alpha2: "AW",
    currencyCode: null,
    emoji: "🇦🇼"
  },
  AFG: {
    countryName: "Afghanistan",
    alpha2: "AF",
    currencyCode: null,
    emoji: "🇦🇫"
  },
  AGO: {
    countryName: "Angola",
    alpha2: "AO",
    currencyCode: null,
    emoji: "🇦🇴"
  },
  ALB: {
    countryName: "Albania",
    alpha2: "AL",
    currencyCode: "EUR",
    emoji: "🇦🇱"
  },
  ARE: {
    countryName: "United Arab Emirates",
    alpha2: "AE",
    currencyCode: null,
    emoji: "🇦🇪"
  },
  ARG: {
    countryName: "Argentina",
    alpha2: "AR",
    currencyCode: null,
    emoji: "🇦🇷"
  },
  ARM: {
    countryName: "Armenia",
    alpha2: "AM",
    currencyCode: null,
    emoji: "🇦🇲"
  },
  ASM: {
    countryName: "American Samoa",
    alpha2: "AS",
    currencyCode: "USD",
    emoji: "🇦🇸"
  },
  ATG: {
    countryName: "Antigua and Barbuda",
    alpha2: "AG",
    currencyCode: null,
    emoji: "🇦🇬"
  },
  AZE: {
    countryName: "Azerbaijan",
    alpha2: "AZ",
    currencyCode: null,
    emoji: "🇦🇿"
  },
  BDI: {
    countryName: "Burundi",
    alpha2: "BI",
    currencyCode: null,
    emoji: "🇧🇮"
  },
  BEN: {
    countryName: "Benin",
    alpha2: "BJ",
    currencyCode: null,
    emoji: "🇧🇯"
  },
  BFA: {
    countryName: "Burkina Faso",
    alpha2: "BF",
    currencyCode: null,
    emoji: "🇧🇫"
  },
  BGD: {
    countryName: "Bangladesh",
    alpha2: "BD",
    currencyCode: null,
    emoji: "🇧🇩"
  },
  BGR: {
    countryName: "Bulgaria",
    alpha2: "BG",
    currencyCode: "EUR",
    emoji: "🇧🇬"
  },
  BHR: {
    countryName: "Bahrain",
    alpha2: "BH",
    currencyCode: null,
    emoji: "🇧🇭"
  },
  BHS: {
    countryName: "Bahamas",
    alpha2: "BS",
    currencyCode: "USD",
    emoji: "🇧🇸"
  },
  BIH: {
    countryName: "Bosnia and Herzegovina",
    alpha2: "BA",
    currencyCode: null,
    emoji: "🇧🇦"
  },
  BLR: {
    countryName: "Belarus",
    alpha2: "BY",
    currencyCode: "EUR",
    emoji: "🇧🇾"
  },
  BLZ: {
    countryName: "Belize",
    alpha2: "BZ",
    currencyCode: null,
    emoji: "🇧🇿"
  },
  BMU: {
    countryName: "Bermuda",
    alpha2: "BM",
    currencyCode: null,
    emoji: "🇧🇲"
  },
  BOL: {
    countryName: "Bolivia",
    alpha2: "BO",
    currencyCode: null,
    emoji: "🇧🇴"
  },
  BRA: {
    countryName: "Brazil",
    alpha2: "BR",
    currencyCode: null,
    emoji: "🇧🇷"
  },
  BRB: {
    countryName: "Barbados",
    alpha2: "BB",
    currencyCode: null,
    emoji: "🇧🇧"
  },
  BRN: {
    countryName: "Brunei",
    alpha2: "BN",
    currencyCode: null,
    emoji: "🇧🇳"
  },
  BTN: {
    countryName: "Bhutan",
    alpha2: "BT",
    currencyCode: null,
    emoji: "🇧🇹"
  },
  BWA: {
    countryName: "Botswana",
    alpha2: "BW",
    currencyCode: null,
    emoji: "🇧🇼"
  },
  CHL: {
    countryName: "Chile",
    alpha2: "CL",
    currencyCode: null,
    emoji: "🇨🇱"
  },
  CHN: {
    countryName: "China",
    alpha2: "CN",
    currencyCode: null,
    emoji: "🇨🇳"
  },
  CIV: {
    countryName: "Cote d'Ivoire",
    alpha2: "CI",
    currencyCode: null,
    emoji: "🇨🇮"
  },
  CMR: {
    countryName: "Cameroon",
    alpha2: "CM",
    currencyCode: null,
    emoji: "🇨🇲"
  },
  COD: {
    countryName: "Congo Dem. Rep.",
    alpha2: "CD",
    currencyCode: null,
    emoji: "🇨🇩"
  },
  COG: {
    countryName: "Congo Rep.",
    alpha2: "CG",
    currencyCode: null,
    emoji: "🇨🇬"
  },
  COL: {
    countryName: "Colombia",
    alpha2: "CO",
    currencyCode: null,
    emoji: "🇨🇴"
  },
  COM: {
    countryName: "Comoros",
    alpha2: "KM",
    currencyCode: null,
    emoji: "🇰🇲"
  },
  CPV: {
    countryName: "Cabo Verde",
    alpha2: "CV",
    currencyCode: null,
    emoji: "🇨🇻"
  },
  CUB: {
    countryName: "Cuba",
    alpha2: "CU",
    currencyCode: null,
    emoji: "🇨🇺"
  },
  CYM: {
    countryName: "Cayman Islands",
    alpha2: "KY",
    currencyCode: null,
    emoji: "🇰🇾"
  },
  CYP: {
    countryName: "Cyprus",
    alpha2: "CY",
    currencyCode: "EUR",
    emoji: "🇨🇾"
  },
  DJI: {
    countryName: "Djibouti",
    alpha2: "DJ",
    currencyCode: null,
    emoji: "🇩🇯"
  },
  DMA: {
    countryName: "Dominica",
    alpha2: "DM",
    currencyCode: null,
    emoji: "🇩🇲"
  },
  DNK: {
    countryName: "Denmark",
    alpha2: "DK",
    currencyCode: "EUR",
    emoji: "🇩🇰"
  },
  DOM: {
    countryName: "Dominican Republic",
    alpha2: "DO",
    currencyCode: null,
    emoji: "🇩🇴"
  },
  DZA: {
    countryName: "Algeria",
    alpha2: "DZ",
    currencyCode: null,
    emoji: "🇩🇿"
  },
  ECU: {
    countryName: "Ecuador",
    alpha2: "EC",
    currencyCode: "USD",
    emoji: "🇪🇨"
  },
  EGY: {
    countryName: "Egypt",
    alpha2: "EG",
    currencyCode: null,
    emoji: "🇪🇬"
  },
  ERI: {
    countryName: "Eritrea",
    alpha2: "ER",
    currencyCode: null,
    emoji: "🇪🇷"
  },
  ETH: {
    countryName: "Ethiopia",
    alpha2: "ET",
    currencyCode: null,
    emoji: "🇪🇹"
  },
  FJI: {
    countryName: "Fiji",
    alpha2: "FJ",
    currencyCode: null,
    emoji: "🇫🇯"
  },
  GAB: {
    countryName: "Gabon",
    alpha2: "GA",
    currencyCode: null,
    emoji: "🇬🇦"
  },
  GEO: {
    countryName: "Georgia",
    alpha2: "GE",
    currencyCode: null,
    emoji: "🇬🇪"
  },
  GHA: {
    countryName: "Ghana",
    alpha2: "GH",
    currencyCode: null,
    emoji: "🇬🇭"
  },
  GIB: {
    countryName: "Gibraltar",
    alpha2: "GI",
    currencyCode: "GBP",
    emoji: "🇬🇮"
  },
  GIN: {
    countryName: "Guinea",
    alpha2: "GN",
    currencyCode: null,
    emoji: "🇬🇳"
  },
  GMB: {
    countryName: "Gambia",
    alpha2: "GM",
    currencyCode: null,
    emoji: "🇬🇲"
  },
  GNB: {
    countryName: "Guinea-Bissau",
    alpha2: "GW",
    currencyCode: null,
    emoji: "🇬🇼"
  },
  GNQ: {
    countryName: "Equatorial Guinea",
    alpha2: "GQ",
    currencyCode: null,
    emoji: "🇬🇶"
  },
  GRC: {
    countryName: "Greece",
    alpha2: "GR",
    currencyCode: "EUR",
    emoji: "🇬🇷"
  },
  GRD: {
    countryName: "Grenada",
    alpha2: "GD",
    currencyCode: null,
    emoji: "🇬🇩"
  },
  GTM: {
    countryName: "Guatemala",
    alpha2: "GT",
    currencyCode: null,
    emoji: "🇬🇹"
  },
  GUM: {
    countryName: "Guam",
    alpha2: "GU",
    currencyCode: "USD",
    emoji: "🇬🇺"
  },
  GUY: {
    countryName: "Guyana",
    alpha2: "GY",
    currencyCode: null,
    emoji: "🇬🇾"
  },
  HKG: {
    countryName: "Hong Kong SAR",
    alpha2: "HK",
    currencyCode: null,
    emoji: "🇭🇰"
  },
  HND: {
    countryName: "Honduras",
    alpha2: "HN",
    currencyCode: null,
    emoji: "🇭🇳"
  },
  HRV: {
    countryName: "Croatia",
    alpha2: "HR",
    currencyCode: "EUR",
    emoji: "🇭🇷"
  },
  HTI: {
    countryName: "Haiti",
    alpha2: "HT",
    currencyCode: null,
    emoji: "🇭🇹"
  },
  IDN: {
    countryName: "Indonesia",
    alpha2: "ID",
    currencyCode: null,
    emoji: "🇮🇩"
  },
  IND: {
    countryName: "India",
    alpha2: "IN",
    currencyCode: null,
    emoji: "🇮🇳"
  },
  IRN: {
    countryName: "Iran",
    alpha2: "IR",
    currencyCode: null,
    emoji: "🇮🇷"
  },
  IRQ: {
    countryName: "Iraq",
    alpha2: "IQ",
    currencyCode: null,
    emoji: "🇮🇶"
  },
  ISL: {
    countryName: "Iceland",
    alpha2: "IS",
    currencyCode: null,
    emoji: "🇮🇸"
  },
  JAM: {
    countryName: "Jamaica",
    alpha2: "JM",
    currencyCode: null,
    emoji: "🇯🇲"
  },
  JOR: {
    countryName: "Jordan",
    alpha2: "JO",
    currencyCode: null,
    emoji: "🇯🇴"
  },
  JPN: {
    countryName: "Japan",
    alpha2: "JP",
    currencyCode: "JPY",
    emoji: "🇯🇵"
  },
  KAZ: {
    countryName: "Kazakhstan",
    alpha2: "KZ",
    currencyCode: null,
    emoji: "🇰🇿"
  },
  KEN: {
    countryName: "Kenya",
    alpha2: "KE",
    currencyCode: null,
    emoji: "🇰🇪"
  },
  KGZ: {
    countryName: "Kyrgyz Republic",
    alpha2: "KG",
    currencyCode: null,
    emoji: "🇰🇬"
  },
  KHM: {
    countryName: "Cambodia",
    alpha2: "KH",
    currencyCode: null,
    emoji: "🇰🇭"
  },
  KIR: {
    countryName: "Kiribati",
    alpha2: "KI",
    currencyCode: "AUD",
    emoji: "🇰🇮"
  },
  KNA: {
    countryName: "Saint Kitts and Nevis",
    alpha2: "KN",
    currencyCode: null,
    emoji: "🇰🇳"
  },
  KWT: {
    countryName: "Kuwait",
    alpha2: "KW",
    currencyCode: null,
    emoji: "🇰🇼"
  },
  LAO: {
    countryName: "Lao PDR",
    alpha2: "LA",
    currencyCode: null,
    emoji: "🇱🇦"
  },
  LBN: {
    countryName: "Lebanon",
    alpha2: "LB",
    currencyCode: null,
    emoji: "🇱🇧"
  },
  LBR: {
    countryName: "Liberia",
    alpha2: "LR",
    currencyCode: null,
    emoji: "🇱🇷"
  },
  LBY: {
    countryName: "Libya",
    alpha2: "LY",
    currencyCode: null,
    emoji: "🇱🇾"
  },
  LCA: {
    countryName: "Saint Lucia",
    alpha2: "LC",
    currencyCode: null,
    emoji: "🇱🇨"
  },
  LKA: {
    countryName: "Sri Lanka",
    alpha2: "LK",
    currencyCode: null,
    emoji: "🇱🇰"
  },
  LSO: {
    countryName: "Lesotho",
    alpha2: "LS",
    currencyCode: null,
    emoji: "🇱🇸"
  },
  LUX: {
    countryName: "Luxembourg",
    alpha2: "LU",
    currencyCode: "EUR",
    emoji: "🇱🇺"
  },
  MAC: {
    countryName: "Macao SAR",
    alpha2: "MO",
    currencyCode: null,
    emoji: "🇲🇴"
  },
  MAR: {
    countryName: "Morocco",
    alpha2: "MA",
    currencyCode: null,
    emoji: "🇲🇦"
  },
  MDA: {
    countryName: "Moldova",
    alpha2: "MD",
    currencyCode: "EUR",
    emoji: "🇲🇩"
  },
  MDG: {
    countryName: "Madagascar",
    alpha2: "MG",
    currencyCode: null,
    emoji: "🇲🇬"
  },
  MDV: {
    countryName: "Maldives",
    alpha2: "MV",
    currencyCode: null,
    emoji: "🇲🇻"
  },
  MEX: {
    countryName: "Mexico",
    alpha2: "MX",
    currencyCode: "MXN",
    emoji: "🇲🇽"
  },
  MHL: {
    countryName: "Marshall Islands",
    alpha2: "MH",
    currencyCode: "USD",
    emoji: "🇲🇭"
  },
  MKD: {
    countryName: "North Macedonia",
    alpha2: "MK",
    currencyCode: "EUR",
    emoji: "🇲🇰"
  },
  MLI: {
    countryName: "Mali",
    alpha2: "ML",
    currencyCode: null,
    emoji: "🇲🇱"
  },
  MLT: {
    countryName: "Malta",
    alpha2: "MT",
    currencyCode: "EUR",
    emoji: "🇲🇹"
  },
  MMR: {
    countryName: "Myanmar",
    alpha2: "MM",
    currencyCode: null,
    emoji: "🇲🇲"
  },
  MNG: {
    countryName: "Mongolia",
    alpha2: "MN",
    currencyCode: null,
    emoji: "🇲🇳"
  },
  MNP: {
    countryName: "Northern Mariana Islands",
    alpha2: "MP",
    currencyCode: "USD",
    emoji: "🇲🇵"
  },
  MOZ: {
    countryName: "Mozambique",
    alpha2: "MZ",
    currencyCode: null,
    emoji: "🇲🇿"
  },
  MRT: {
    countryName: "Mauritania",
    alpha2: "MR",
    currencyCode: null,
    emoji: "🇲🇷"
  },
  MUS: {
    countryName: "Mauritius",
    alpha2: "MU",
    currencyCode: null,
    emoji: "🇲🇺"
  },
  MWI: {
    countryName: "Malawi",
    alpha2: "MW",
    currencyCode: null,
    emoji: "🇲🇼"
  },
  MYS: {
    countryName: "Malaysia",
    alpha2: "MY",
    currencyCode: null,
    emoji: "🇲🇾"
  },
  NAM: {
    countryName: "Namibia",
    alpha2: "NA",
    currencyCode: null,
    emoji: "🇳🇦"
  },
  NCL: {
    countryName: "New Caledonia",
    alpha2: "NC",
    currencyCode: null,
    emoji: "🇳🇨"
  },
  NER: {
    countryName: "Niger",
    alpha2: "NE",
    currencyCode: null,
    emoji: "🇳🇪"
  },
  NGA: {
    countryName: "Nigeria",
    alpha2: "NG",
    currencyCode: null,
    emoji: "🇳🇬"
  },
  NIC: {
    countryName: "Nicaragua",
    alpha2: "NI",
    currencyCode: null,
    emoji: "🇳🇮"
  },
  NPL: {
    countryName: "Nepal",
    alpha2: "NP",
    currencyCode: null,
    emoji: "🇳🇵"
  },
  OMN: {
    countryName: "Oman",
    alpha2: "OM",
    currencyCode: null,
    emoji: "🇴🇲"
  },
  PAK: {
    countryName: "Pakistan",
    alpha2: "PK",
    currencyCode: null,
    emoji: "🇵🇰"
  },
  PAN: {
    countryName: "Panama",
    alpha2: "PA",
    currencyCode: "USD",
    emoji: "🇵🇦"
  },
  PER: {
    countryName: "Peru",
    alpha2: "PE",
    currencyCode: null,
    emoji: "🇵🇪"
  },
  PHL: {
    countryName: "Philippines",
    alpha2: "PH",
    currencyCode: null,
    emoji: "🇵🇭"
  },
  PNG: {
    countryName: "Papua New Guinea",
    alpha2: "PG",
    currencyCode: null,
    emoji: "🇵🇬"
  },
  PRI: {
    countryName: "Puerto Rico",
    alpha2: "PR",
    currencyCode: "USD",
    emoji: "🇵🇷"
  },
  PRK: {
    countryName: "North Korea",
    alpha2: "KP",
    currencyCode: null,
    emoji: "🇰🇵"
  },
  PRY: {
    countryName: "Paraguay",
    alpha2: "PY",
    currencyCode: null,
    emoji: "🇵🇾"
  },
  PYF: {
    countryName: "French Polynesia",
    alpha2: "PF",
    currencyCode: null,
    emoji: "🇵🇫"
  },
  QAT: {
    countryName: "Qatar",
    alpha2: "QA",
    currencyCode: null,
    emoji: "🇶🇦"
  },
  ROU: {
    countryName: "Romania",
    alpha2: "RO",
    currencyCode: "EUR",
    emoji: "🇷🇴"
  },
  RUS: {
    countryName: "Russia",
    alpha2: "RU",
    currencyCode: null,
    emoji: "🇷🇺"
  },
  RWA: {
    countryName: "Rwanda",
    alpha2: "RW",
    currencyCode: null,
    emoji: "🇷🇼"
  },
  SAU: {
    countryName: "Saudi Arabia",
    alpha2: "SA",
    currencyCode: null,
    emoji: "🇸🇦"
  },
  SDN: {
    countryName: "Sudan",
    alpha2: "SD",
    currencyCode: null,
    emoji: "🇸🇩"
  },
  SEN: {
    countryName: "Senegal",
    alpha2: "SN",
    currencyCode: null,
    emoji: "🇸🇳"
  },
  SGP: {
    countryName: "Singapore",
    alpha2: "SG",
    currencyCode: null,
    emoji: "🇸🇬"
  },
  SLB: {
    countryName: "Solomon Islands",
    alpha2: "SB",
    currencyCode: null,
    emoji: "🇸🇧"
  },
  SLE: {
    countryName: "Sierra Leone",
    alpha2: "SL",
    currencyCode: null,
    emoji: "🇸🇱"
  },
  SLV: {
    countryName: "El Salvador",
    alpha2: "SV",
    currencyCode: "USD",
    emoji: "🇸🇻"
  },
  SOM: {
    countryName: "Somalia",
    alpha2: "SO",
    currencyCode: "USD",
    emoji: "🇸🇴"
  },
  SRB: {
    countryName: "Serbia",
    alpha2: "RS",
    currencyCode: null,
    emoji: "🇷🇸"
  },
  STP: {
    countryName: "Sao Tome and Principe",
    alpha2: "ST",
    currencyCode: null,
    emoji: "🇸🇹"
  },
  SUR: {
    countryName: "Suriname",
    alpha2: "SR",
    currencyCode: null,
    emoji: "🇸🇷"
  },
  SWZ: {
    countryName: "Eswatini",
    alpha2: "SZ",
    currencyCode: null,
    emoji: "🇸🇿"
  },
  SYC: {
    countryName: "Seychelles",
    alpha2: "SC",
    currencyCode: null,
    emoji: "🇸🇨"
  },
  SYR: {
    countryName: "Syria",
    alpha2: "SY",
    currencyCode: null,
    emoji: "🇸🇾"
  },
  TCA: {
    countryName: "Turks and Caicos Islands",
    alpha2: "TC",
    currencyCode: "USD",
    emoji: "🇹🇨"
  },
  TCD: {
    countryName: "Chad",
    alpha2: "TD",
    currencyCode: null,
    emoji: "🇹🇩"
  },
  TGO: {
    countryName: "Togo",
    alpha2: "TG",
    currencyCode: null,
    emoji: "🇹🇬"
  },
  THA: {
    countryName: "Thailand",
    alpha2: "TH",
    currencyCode: null,
    emoji: "🇹🇭"
  },
  TJK: {
    countryName: "Tajikistan",
    alpha2: "TJ",
    currencyCode: null,
    emoji: "🇹🇯"
  },
  TKM: {
    countryName: "Turkmenistan",
    alpha2: "TM",
    currencyCode: null,
    emoji: "🇹🇲"
  },
  TLS: {
    countryName: "Timor-Leste",
    alpha2: "TL",
    currencyCode: "USD",
    emoji: "🇹🇱"
  },
  TON: {
    countryName: "Tonga",
    alpha2: "TO",
    currencyCode: null,
    emoji: "🇹🇴"
  },
  TTO: {
    countryName: "Trinidad and Tobago",
    alpha2: "TT",
    currencyCode: null,
    emoji: "🇹🇹"
  },
  TUN: {
    countryName: "Tunisia",
    alpha2: "TN",
    currencyCode: null,
    emoji: "🇹🇳"
  },
  TUV: {
    countryName: "Tuvalu",
    alpha2: "TV",
    currencyCode: "AUD",
    emoji: "🇹🇻"
  },
  TZA: {
    countryName: "Tanzania",
    alpha2: "TZ",
    currencyCode: null,
    emoji: "🇹🇿"
  },
  UGA: {
    countryName: "Uganda",
    alpha2: "UG",
    currencyCode: null,
    emoji: "🇺🇬"
  },
  UKR: {
    countryName: "Ukraine",
    alpha2: "UA",
    currencyCode: null,
    emoji: "🇺🇦"
  },
  URY: {
    countryName: "Uruguay",
    alpha2: "UY",
    currencyCode: null,
    emoji: "🇺🇾"
  },
  UZB: {
    countryName: "Uzbekistan",
    alpha2: "UZ",
    currencyCode: null,
    emoji: "🇺🇿"
  },
  VEN: {
    countryName: "Venezuela",
    alpha2: "VE",
    currencyCode: null,
    emoji: "🇻🇪"
  },
  VGB: {
    countryName: "British Virgin Islands",
    alpha2: "VG",
    currencyCode: "USD",
    emoji: "🇻🇬"
  },
  VIR: {
    countryName: "Virgin Islands (U.S.)",
    alpha2: "VI",
    currencyCode: "USD",
    emoji: "🇻🇮"
  },
  VNM: {
    countryName: "Vietnam",
    alpha2: "VN",
    currencyCode: null,
    emoji: "🇻🇳"
  },
  VUT: {
    countryName: "Vanuatu",
    alpha2: "VU",
    currencyCode: null,
    emoji: "🇻🇺"
  },
  WSM: {
    countryName: "Samoa",
    alpha2: "WS",
    currencyCode: null,
    emoji: "🇼🇸"
  },
  YEM: {
    countryName: "Yemen",
    alpha2: "YE",
    currencyCode: null,
    emoji: "🇾🇪"
  },
  ZAF: {
    countryName: "South africa",
    alpha2: "ZA",
    currencyCode: null,
    emoji: "🇿🇦"
  },
  ZMB: {
    countryName: "Zambia",
    alpha2: "ZM",
    currencyCode: null,
    emoji: "🇿🇲"
  },
  ZWE: {
    countryName: "Zimbabwe",
    alpha2: "ZW",
    currencyCode: null,
    emoji: "🇿🇼"
  }
} as const;

export interface Country {
  countryName: string;
  alpha2: string;
  emoji: string;
  currencyCode: string | null;
}

export const COUNTRIES: Record<string, Country> = data;

export type SupportedCountryCode = keyof typeof data;
export type SupportedCountry = (typeof data)[keyof typeof data];

export type IsoAlpha2 = SupportedCountry['alpha2'];

// Helper function to check if the country code is supported by our calculator.
export function isSupportedCountryCode(
  countryCode: string | null | undefined
): countryCode is SupportedCountryCode {
  return typeof countryCode === 'string' && countryCode in data;
}
