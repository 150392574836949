import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { getApiBaseUrl } from './config';

// Choose the API base URL depending on the environment:
const BASE_URL = `${getApiBaseUrl()}/automation`;

export const automationApi = createApi({
  reducerPath: 'automationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.currentUser.accessToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    emailFootprintResultsLink: builder.mutation<void, {email: string}>({
      query: (body) => ({
        url: `/email-footprint-results-link`,
        method: 'POST',
        body: body,
      }),
    }),
  }),
})


export const {
  useEmailFootprintResultsLinkMutation
} = automationApi
