import { createStyles, Stack, Image, Divider, Text, Grid, useMantineTheme, Box, Anchor, Spoiler, Button } from "@mantine/core";

import { TabSection } from "../../shared/TabSection/TabSection";
import { useMediaQuery } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  subtitle: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 600
  },
  citation: {
    fontSize: 10,
    p: {
      margin: 0,
      padding: "0.25rem"
    }
  },
  cardImage: {
    // height: "100%",
    [theme.fn.smallerThan("lg")]: {
      height: "200px",
      // aspectRatio: "0.5",
    },
    // root: {height: "100%"}, figure: {height: "100%"},
    // imageWrapper: {height: "100%"}
  }
}));


function FullHeightImage(props: { src: string }) {
  const theme = useMantineTheme();
  const {classes} = useStyles();
  return (
    <Image
      src={props.src}
      radius={"sm"}
      className={classes.cardImage}
      height={"100%"}
      mah={560}
      sx={{
        [theme.fn.largerThan("md")]: {
          filter: "drop-shadow(0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.1))"
        }
      }}
      styles={{
        root: {height: "100%"}, figure: {height: "100%"},
        imageWrapper: {height: "100%"}
      }}
    />
  );
}


const RootCauseSection = () => {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <Box>
      <Grid gutter={"xl"}>
        <Grid.Col lg={6} order={1} orderLg={2}>
          <FullHeightImage src="/assets/webp/buffet2.webp"/>
        </Grid.Col>
        <Grid.Col lg={6} order={2} orderLg={1}>
            <Stack>
              <Text className={classes.subtitle}>We address the largest driver of food emissions, deforestation, and ecosystem loss – consumption of animal products.</Text>
              <Spoiler
                maxHeight={mobile ? 120 : 1000}
                showLabel={<Text variant="subtle" fw={600} pt={"sm"}>Show more</Text>}
                hideLabel={<Text variant="subtle" fw={600} pt={"sm"}>Hide</Text>}
              >
              <Stack>
              <Text>To make meaningful and lasting progress, we need to shift toward sustainable diets that are
                within <Anchor href="https://eatforum.org/eat-lancet-commission/eat-lancet-commission-summary-report/">planetary boundaries</Anchor>.
              </Text>
              <Text>We help food providers overcome barriers to plant-based adoption, such as training staff, purchasing new equipment, or hiring a culinary
                team to ensure that the new meals are a success. Food providers have an outsized impact on the food system, since they can make
                changes that affect billions of meals each year.
              </Text>
              <Text>Research shows that introducing consumers to plant-based options has positive spillovers even after the project ends. Spreading the word about climate change and sustainable food systems is core to our approach.</Text>
              <Divider size="sm"/>
              <Text className={cx(classes.citation)} align="left" c="gray">
                <p>Lohmann, P.M. Long-lasting impact of information on meat consumption. Nat Food 4, 205–206 (2023). https://doi.org/10.1038/s43016-023-00721-0</p>
                <p>Jalil, A.J., Tasoff, J. & Bustamante, A.V. Low-cost climate-change informational intervention reduces meat consumption among students for 3 years. Nat Food 4, 218–222 (2023). https://doi.org/10.1038/s43016-023-00712-1</p>
              </Text>
              </Stack>
              </Spoiler>
            </Stack>
        </Grid.Col>
      </Grid>
    </Box>
  )
}


const LandUseSection = () => {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <Box>
      <Grid gutter="xl">
        <Grid.Col lg={6} order={2} orderLg={1}>
          <Stack>
            <Text className={classes.subtitle}>For every hectare of land that we free up through
            sustainable diets, we restore or conserve one hectare of land.
            </Text>
            <Spoiler
                maxHeight={mobile ? 120 : 1000}
                showLabel={<Text variant="subtle" fw={600} pt={"sm"}>Show more</Text>}
                hideLabel={<Text variant="subtle" fw={600} pt={"sm"}>Hide</Text>}
              >
            <Stack>
            <Text>Conservation and restoration projects often encounter the
              issue of <Anchor href="https://www.ecosystemmarketplace.com/articles/shades-of-reddwe-have-to-talk-about-leakage/">leakage</Anchor>, where
              protecting or re-planting trees in one area causes another plot to be cut down.
              This is because the underlying driver of deforestation, such as ranching or logging, has not been addressed.
            </Text>
            <Text>
              <strong>We've developed the first carbon credit that pairs projects that restore and conserve land with projects that reduce land use. </strong>
              This mitigates leakage, so you can be confident that your carbon credit has the impact it claims. Furthermore, our "land-neutral" approach
              ensures that carbon removal doesn’t compete with other uses of land that local communities depend on.</Text>
            <Divider size="sm"/>
            <Text className={cx(classes.citation)} align="left" c="gray">
              <p>Oxfam International (2021). Tightening the net: Net zero climate targets – implications for land and food equity.</p>
            </Text>
            </Stack>
            </Spoiler>
          </Stack>
        </Grid.Col>
        <Grid.Col lg={6} order={1} orderLg={2}>
          <FullHeightImage src="/assets/webp/forest2.webp"/>
        </Grid.Col>
      </Grid>
    </Box>
  );
}


const CoBenefitsSection = () => {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <Box>
      <Grid gutter="xl">
        <Grid.Col lg={6} order={1} orderLg={2}>
          <FullHeightImage src="/assets/webp/table_vertical.webp"/>
        </Grid.Col>
        <Grid.Col lg={6} order={2} orderLg={1}>
          <Stack>
            <Text className={classes.subtitle}>Our carbon credits improve access to healthy foods around the world, and reduce the number of animals in factory farms.</Text>
            <Spoiler
                maxHeight={mobile ? 120 : 1000}
                showLabel={<Text variant="subtle" fw={600} pt={"sm"}>Show more</Text>}
                hideLabel={<Text variant="subtle" fw={600} pt={"sm"}>Hide</Text>}
              >
            <Stack>
            <Text>Just like the clean energy transition, a clean food transition can improve human health, create jobs, reduce pandemic risk,
              and make progress on a host of other environmental issues, like air and water pollution.
            </Text>
            <Text>We're committed to a sustainable and <i>just</i> food transition that makes
            progress along each of these axes. Our crediting methodology requires that
            new meals provide equal or better nutritional value than the baseline. Furthermore, we require
            that food providers don't reduce emissions at the cost of animal welfare – for example, by replacing beef with chicken.
            </Text>
            <Divider size="sm"/>
            <Text className={cx(classes.citation)} align="left" c="gray">
              <p>Lovvorn, Jonathan, Clean Food: The Next Clean Energy Revolution (April 1, 2018). Yale Law & Policy Review, Vol. 36, 2018, Available at SSRN: https://ssrn.com/abstract=3194886</p>
              <p>Linder, Ann et al. Animal Markets and Zoonotic Diseases in the United States. Center for Animal and Environmental Protection, New York University.</p>
              <p>The EAT-Lancet Commission Summary Report. https://eatforum.org/eat-lancet-commission</p>
            </Text>
            </Stack>
            </Spoiler>
          </Stack>
        </Grid.Col>
      </Grid>
    </Box>
  )
}


const data = [
  {
    demo: RootCauseSection,
    header: "1",
    name: 'Addressing the root of the problem',
    description: 'We promote sustainable food consumption, which is the most effective way to reduce emissions and deforestation.',
  },
  {
    demo: LandUseSection,
    header: "2",
    name: 'Freeing up land for restoration',
    description: "Our sustainable food projects free up land so that it can be restored and protected.",
  },
  {
    demo: CoBenefitsSection,
    header: "3",
    name: 'Co-benefits',
    description: 'Climate action in the food system is a win for people, animals, and the planet.',
  },
];


export default function DifferentTabs() {
  const theme = useMantineTheme();
  return (
    <TabSection
      data={data}
      backgroundColor={theme.colors.gray[0]}
      colorScheme="light"
      tabSize={220}
    />
  )
}
