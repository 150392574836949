import { useMantineTheme } from "@mantine/core";

import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useGetReduceActionsQuery } from "../../../api/calculator";
import { selectCurrentUserId, useAppSelector } from "../../../redux/hooks";
import { clone } from "lodash";


export default function WaysToReduceChart(props: { yourFootprintTonsCo2: number | null, width: number | null }) {
  const theme = useMantineTheme();

  const userId = useAppSelector(selectCurrentUserId) as string;
  const {data, isLoading, isFetching, isError} = useGetReduceActionsQuery(userId);

  const sortable = clone(data || []);

  const sorted = sortable.sort((a, b) => (b.impactTonsCo2 - a.impactTonsCo2));
  const categories = ["Offsetting", ...sorted?.map(v => v.label)];
  const values = [props.yourFootprintTonsCo2, ...sorted?.map(v => v.impactTonsCo2)];
  const colors = [theme.colors.teal[6], ...sorted.map(v => theme.colors.gray[4])];

  const loading = isLoading || isFetching || !userId;

  const options: Highcharts.Options = {
    title: {
      text: undefined
    },

    yAxis: {
      title: {
        text: "Annual emission reduction (tons CO₂)",
        style: {fontWeight: "normal"},
      },
      labels: {
        enabled: false,
      },
      gridLineColor: "transparent",
    },

    xAxis: {
      categories: categories
    },

    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          color: 'black',
          style: {fontWeight: '500', textOutline: 'none', fontSize: "14px"},
          inside: true,
          format: "{point.y:.2f}"
        },
        pointPadding: 0.05,
        groupPadding: 0.05,
        colorByPoint: true,
      },
    },

    tooltip: {
      format: "{point.series.name}: {point.y:.2f}"
    },

    series: [{
      type: 'bar',
      name: 'tons CO2',
      data: values,
      colors: colors,
    }],
    legend: {
      enabled: false,
    },
    chart: {
      type: 'bar',
      height: 400,
      width: props.width,
      style: {
        fontFamily: "Inter,Proxima Nova, proxima-nova"
      },
      backgroundColor: "transparent",
    },
    credits: {
      enabled: false,
    }
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      loading={loading}
    />
  );
}
