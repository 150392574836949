import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { getApiBaseUrl } from './config';

// Choose the API base URL depending on the environment:
const BASE_URL = `${getApiBaseUrl()}/v1/tracking/`;

type EventType = "user/reached-path"


export interface ContinuePath {
  pathname: string
}


export interface TrackEvent {
  type: EventType
  payload: any
}

export interface UserReachedPathEvent extends TrackEvent {
  type: "user/reached-path"
  payload: ContinuePath
}


export const trackingApi = createApi({
  reducerPath: 'trackingApi',
  tagTypes: ['ContinuePath'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.currentUser.accessToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    postUserReachedPath: builder.mutation<void, {userId: string, step: ContinuePath}>({
      query: (body) => ({
        url: `/event`,
        method: 'POST',
        body: {
          type: "user/reached-path",
          payload: body.step
        },
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: "ContinuePath", id: arg.userId }]
      },
    }),
    getContinuePath: builder.query<ContinuePath | null, {userId: string}>({
      query: (args) => ({
        url: `/continue`,
      }),
      providesTags: (result, error, arg) => [{ type: "ContinuePath", id: arg.userId }]
    }),
  }),
})


export const {
  useGetContinuePathQuery,
  usePostUserReachedPathMutation
} = trackingApi
