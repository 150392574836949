import { Box, Button, Group, NumberInput, Text, createStyles, useMantineTheme } from "@mantine/core";
import { useEffect, useState } from "react";
import CalculatorButton from "./CalculatorButton";
import { useDisclosure } from "@mantine/hooks";
import { IconCheck } from "@tabler/icons-react";


const useStyles = createStyles((theme) => ({
  outer: {
    border: "2px solid lightgray",
    borderRadius: theme.radius.md,
  },
  input: {
    width: "70%",
  },
  checkButton: {
    marginLeft: "auto"
  },
  selected: {
    borderColor: theme.colors.teal[6],
  },
  extra: {
    fontSize: "0.9rem",
    fontWeight: 400,
    textAlign: "right",
    marginLeft: "auto",
    color: theme.colors.gray[6],
  },
  buttonInnerGroup: {
    width: "100%"
  }
}));


const CustomNumberInput = (
  props: {
    onChange?: (value: number) => void,
    units?: string,
    initialValue?: number | null,
    selected: boolean,
    min?: number,
    max?: number,
    precision?: number,
  }
) => {
  const {classes, cx} = useStyles();
  const [value, setValue] = useState<number | "">("");
  const [opened, { open, close }] = useDisclosure(false);
  const theme = useMantineTheme();

  // Called when a user has entered a value and clicks "Done".
  const onSubmit = () => {
    if (value !== "") {
      if (props.onChange) {
        props.onChange(value);
      }
    }
    close();
  }

  // If the user has set a custom option and saved one, we want to persist that.
  useEffect(() => {
    if (props.initialValue) {
      setValue(props.initialValue);
    }
  }, [props.initialValue]);

  const button = <CalculatorButton
    selected={props.selected}
    onClick={() => open()}
  >
    <Group className={classes.buttonInnerGroup}>
      <Text>{value === "" ? "Custom" : `${value.toLocaleString("default", { maximumFractionDigits: 1 })}`}</Text>
      <Text className={classes.extra}>{props.units || ""}</Text>
    </Group>
  </CalculatorButton>;

  const input = <Box>
    <Group grow>
      <NumberInput
        size="lg"
        value={value}
        onChange={setValue}
        min={props.min}
        max={props.max}
        precision={props.precision === undefined ? 3 : props.precision}
        hideControls
        className={cx(classes.outer, classes.input, classes.selected)}
        styles={{input: {border: "none", borderRadius: theme.radius.md}}}
        autoFocus
      />
      <Button
        leftIcon={<IconCheck/>}
        className={classes.checkButton}
        onClick={() => onSubmit()}
        size="lg"
        radius="md"
        w={200}
      >
        Enter
      </Button>
    </Group>
  </Box>

  return opened ? input : button;
}


export default CustomNumberInput;
