import { createStyles, Text, Title, Grid, Stack, Badge, Image, Card } from '@mantine/core';


const useStyles = createStyles((theme) => ({
  title: {
    fontWeight: 500
  },

  category: {
    fontWeight: 700,
    textTransform: 'uppercase',
  },

  description: {
    color: theme.colors.gray[7],
    fontWeight: 500,
    textAlign: "center"
  },

  crt: {
    color: theme.colors.teal[6],
    fontWeight: 600,
    fontSize: theme.fontSizes.xl,
  },

  bracket: {
    width: `calc(66.67% + 2*${theme.spacing.sm})`,
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  }
}));


interface CardProps {
  image: string;
  title: string;
  category: string;
  description: string;
}


function PortfolioCard({ image, title, category, description }: CardProps) {
  const { classes } = useStyles();

  return (
    <Card shadow="md" padding="lg" radius="md" withBorder>
      <Card.Section>
        <Image
          src={image}
          height={180}
        />
      </Card.Section>
      <Stack align="center" justify="center" spacing={"md"} pt={"lg"}>
        <Badge color='teal'>
          {category}
        </Badge>
        <Title className={classes.title} order={3}>
          {title}
        </Title>
        <Text className={classes.description}>
          {description}
        </Text>
      </Stack>
    </Card>
  )
}


export default function PortfolioSection(props: { includeButton: boolean }) {
  const {classes} = useStyles();

  return (
    <Stack align="center">
      <img src="/assets/svg/bracket3.svg" className={classes.bracket}/>
      <Grid gutter={"md"}>
        <Grid.Col sm={4}>
          <PortfolioCard
            image="/assets/portfolio/alimentando.jpg"
            title="Sustainable Meals"
            description="
              Help food providers adopt healthy, climate-friendly, plant-based options.
            "
            category="Reduce food emissions"
          />
        </Grid.Col>
        <Grid.Col sm={4}>
          <PortfolioCard
            image="/assets/portfolio/reforestation3.jpeg"
            title="Land Restoration"
            description="
              Restore land and ecosystems that have been impacted by agriculture.
            "
            category="Remove carbon"
          />
        </Grid.Col>
        <Grid.Col sm={4}>
          <PortfolioCard
            image="/assets/portfolio/rainforest.jpeg"
            title="Conservation"
            description="
              Ensure that vital carbon sinks and habitats stay protected.
            "
            category="Protect ecosystems"
          />
        </Grid.Col>
      </Grid>
      <img src="/assets/svg/bracket3.svg" className={classes.bracket} style={{transform: "rotate(180deg)"}}/>
      <Text className={classes.crt} align='center'>1 Climate Refarm Tonne (CRT)</Text>
      <Text className={classes.description}>Reduces emissions by 1 metric ton of CO₂</Text>
      <Image src="/assets/svg/tonne.svg" style={{minWidth: 150, maxWidth: 700}}/>
    </Stack>
  );
}
