import { Title, Container, createStyles, Button, Stack, Text, Space, useMantineTheme } from "@mantine/core";
import { useNavigate } from "react-router-dom";


const useStyles = createStyles((theme) => ({
  grayGradientSection: {
    paddingTop: theme.spacing.lg,
    paddingBottom: theme.spacing.lg,
    background: 'linear-gradient(180deg, rgba(231, 243, 241, 0.00) 0%, #E7F3F1 100%)',
  },
  citationDark: {
    color: theme.colors.gray[6],
    fontSize: 10,
  },
}));


export default function UrgencySection(props: { onTakeAction?: () => void }) {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const {classes} = useStyles();

  const onTakeAction = () => {
    if (props.onTakeAction) {
      props.onTakeAction();
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      navigate("/take-action");
    }
  }

  return (
    <Container className={classes.grayGradientSection} fluid>
      <Container size="sm">
        <Stack align="center" spacing={"xl"}>
          <Title color={theme.colors.gray[9]} align="center" fw={500}>
            We have less than 7 years left to limit global warming to 1.5°C.
            Every ton of CO₂ matters!
          </Title>
          <Button color="yellow" size="md" style={{maxWidth: 300}} onClick={onTakeAction}>Be part of the solution</Button>
          <Text className={classes.citationDark} align="center">
            <p>IPCC. Special Report: Global Warming of 1.5C. https://www.ipcc.ch/sr15/</p>
            <p>Climate Clock. https://climateclock.world/science</p>
          </Text>
        </Stack>
        <Space h={"xl"}/>
      </Container>
    </Container>
  );
}
