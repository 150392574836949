import { createStyles, Paper, Text, Stack, Loader, Transition, Group, useMantineTheme, Anchor, Alert } from "@mantine/core";
import { useGetCalculatorResultsQuery } from "../../api/calculator";
import { selectCurrentUserId, useAppSelector } from "../../redux/hooks";
import { URL_WIKI_HOME } from "../../utils/links";
import { COUNTRIES } from "../../routes/calculator/utils/countries";
import { IconInfoCircleFilled } from "@tabler/icons-react";


const useStyles = createStyles((theme) => ({
  root: {
    padding: `calc(${theme.spacing.xl} * 1.5)`,
  },
  paper: {
    backgroundColor: theme.colors.gray[1],
  },
  label: {
    color: theme.colors.gray[9]
  },
  number: {
    lineHeight: 1.0,
    transitionDuration: "0.5s",
    fontSize: "4rem",
    fontWeight: 600,
    color: theme.colors.gray[9],
  },
  stepDescription: {
    color: theme.colors.gray[7]
  },
}));


export default function FootprintCard() {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const userId = useAppSelector(selectCurrentUserId) as string;
  const {data, isLoading, isFetching} = useGetCalculatorResultsQuery(userId);

  const results = data?.results;
  const loading = isLoading || isFetching;

  const countryName = data?.results.country;
  const countryCode = data?.results.countryCode;

  const tonsCo2PerYear = (countryCode && results?.userTonsPerYear?.total) || "";

  const emoji = countryCode ? COUNTRIES[countryCode].emoji : null;

  if (!countryCode && !loading) {
    return (
      <Alert color="yellow" icon={<IconInfoCircleFilled/>}>
        <Text fw={600}>You'll need to enter a country before we can calculate your footprint.</Text>
      </Alert>
    );
  }

  return (
    <Paper p="lg" radius="sm" className={classes.paper}>
      {
        (countryName && emoji) ?
        <Group grow>
          <Text fz="sm" color={"dimmed"} fw={500} align="center">
            {emoji} {countryName}
          </Text>
        </Group> : undefined
      }
      <Text fw={700} fz="lg" className={classes.label} align="center" pb={"sm"}>
        Your carbon footprint
      </Text>
      <Stack spacing={0} align="center" pos="relative">
        {
          loading ?
            <Loader size={"lg"} height={"4rem"}/> :
            <Transition mounted={!loading} transition="fade" duration={1000} timingFunction="ease">
              {(styles) => <div style={styles}>
              <Text className={classes.number}>
                {tonsCo2PerYear.toLocaleString("default", { maximumFractionDigits: 1, minimumFractionDigits: 1 })}
              </Text>
              </div>}
            </Transition>
        }
        <Text c={theme.colors.gray[6]} fz="md" fw={400}>
          tons of CO₂ per year
        </Text>
      </Stack>
      {/* {
        country ?
        <Text c="dimmed" fz="sm" mt="md" align="center">
          That's{' '}
          <Text component="span" c={diff < 0 ? 'teal' : 'red'} fw={700}>
            13% {diff > 0 ? 'higher' : 'lower'}
          </Text>{' '}
          than the {country} average
        </Text> : undefined
      } */}
    </Paper>
  );
}
