import { useGetCalculatorInputsQuery, useUpdateCalculatorInputsMutation } from "../../../api/calculator";
import CustomNumberInput from "../../../components/calculator/CustomNumberInput";
import MultipleChoice, { IChoiceData, isCustomOptionSelected } from "../../../components/calculator/MultipleChoice";
import { selectCurrentUserId, useAppSelector } from "../../../redux/hooks";


export default function SelectFoodServings(
  props: {
    category:
      "dietRedMeatServingsPerWeek" | "dietDairyServingsPerWeek" |
      "dietSeafoodServingsPerWeek" | "dietOtherAnimalServingsPerWeek",
    goToNext?: () => void
  }
) {
  const userId = useAppSelector(selectCurrentUserId) as string;

  const {data} = useGetCalculatorInputsQuery(userId);
  const [updateCalculator] = useUpdateCalculatorInputsMutation();

  const inputs = data?.inputs;
  const userValue = inputs ? inputs[props.category as keyof typeof inputs] : null;

  const setUserValue = (value: number | string | null) => {
    updateCalculator({userId, inputs: { [props.category]: value as number }});
  }

  const sharedOnClick = (data: IChoiceData) => {
    setUserValue(data.value);
    if (props.goToNext) {
      props.goToNext();
    }
  }

  const choices = [
    ...(['dietRedMeatServingsPerWeek', 'dietSeafoodServingsPerWeek'].includes(props.category) ? [{
      value: 0,
      label: "Never",
      detail: "0 servings/week",
      selected: userValue === 0,
      onClick: sharedOnClick
    }] : []),
    {
      value: 3,
      label: "A few times per week",
      detail: "3 servings/week",
      selected: userValue === 3,
      onClick: sharedOnClick
    },
    {
      value: 7,
      label: "Every day",
      detail: "7 servings/week",
      selected: userValue === 7,
      onClick: sharedOnClick
    },
    ...(['dietDairyServingsPerWeek', 'dietOtherAnimalServingsPerWeek'].includes(props.category) ? [{
      value: 21,
      label: "Many times a day",
      detail: "21 servings/week",
      selected: userValue === 21,
      onClick: sharedOnClick
    }] : []),
  ];

  const customIsSelected = isCustomOptionSelected(choices, userValue as number | null);

  const customInput =
    <CustomNumberInput
      onChange={setUserValue}
      units={"servings/week"}
      initialValue={customIsSelected ? userValue as number : null}
      selected={customIsSelected}
      min={0}
      max={100}
    />;

  return (
    <MultipleChoice
      choices={choices}
      customInputElement={customInput}
    />
  );
}
