import { selectCurrentUserId, useAppSelector, useTrackUserReachedContinuePath } from '../../redux/hooks';

import { createStyles, Group, Text, Container, Grid, Stack, Button, Title, Space, Progress, Box, MediaQuery, useMantineTheme, ActionIcon, Modal } from '@mantine/core';
import {
  Icon,
  IconArmchair, IconArrowLeft, IconArrowRight, IconArrowsRight, IconBulb, IconBus, IconCar,
  IconChefHat, IconDeviceTv, IconDog, IconEggs, IconFish, IconFlame, IconHammer,
  IconHome, IconMap, IconMapPin, IconMeat, IconMilk, IconPlane, IconRefresh, IconShirt,
  IconTrain, IconZoomQuestion
} from '@tabler/icons-react';

import { Navigate, useParams } from "react-router-dom";

import { scalarOrNull } from '../../utils/helpers';
import { UserCar, useGetCalculatorInputsQuery, useResetCalculatorMutation } from '../../api/calculator';
import CalculatorStepper from '../../components/calculator/Stepper';
import FootprintCard from '../../components/calculator/FootprintCard';
import MoreInformationCard from '../../components/calculator/MoreInformationCard';
import TopHeader from "../../components/shared/TopHeader/TopHeader";
import SelectCarParams from './steps/SelectCarParams';
import SelectCars from './steps/SelectCars';
import SelectClimateRoles from './steps/SelectClimateRoles';
import SelectCountry from './steps/SelectCountry';
import SelectDiet from './steps/SelectDiet';
import SelectElectricity from './steps/SelectElectricity';
import SelectFlights from './steps/SelectFlights';
import SelectFoodServings from './steps/SelectFoodServings';
import SelectHomeSize from './steps/SelectHomeSize';
// import SelectHousehold from './steps/SelectHousehold';
import SelectNaturalGas from './steps/SelectNaturalGas';
import SelectPets from './steps/SelectPets';
import SelectPurchases from './steps/SelectPurchases';
// import SelectRenewables from './steps/SelectRenewables';
import SelectTransportModeDistance from './steps/SelectTransportModeDistance';
import SelectTransportModes from './steps/SelectTransportModes';
import SelectZipCode from './steps/SelectZipCode';
import CalculatorFinished from './CalculatorFinished';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useNavigateTop } from '../../utils/hooks';
import { useListStripeSubscriptionsQuery } from '../../api/payment';

const stepperWidth = 140;
const cardWidth = 250;
// https://mantine.dev/core/container/
const containerWidth = 960; // md
const gutterWidthOneSide = 12;


const useStyles = createStyles((theme) => ({
  container: {
    paddingTop: "2rem",
    [theme.fn.smallerThan("sm")]: {
      paddingTop: 0
    }
  },

  root: {
    padding: `calc(${theme.spacing.xl} * 1.5)`,
  },

  description: {
    color: theme.colors.gray[7],
    fontSize: theme.fontSizes.md,
  },

  bottomControls: {
    width: `calc(min(${containerWidth}px, 100%) - ${stepperWidth + gutterWidthOneSide*4}px + 0.5*(100% - min(${containerWidth}px, 100%)))`,
    padding: theme.spacing.xl,
    position: 'fixed',
    bottom: 0,
    right: 0,
    backgroundColor: "white",
    borderTop: `1px solid ${theme.colors.gray[4]}`,

    [theme.fn.smallerThan('xs')]: {
      width: `calc(100% - 2*${theme.spacing.xl})`,
    }
  },

  cardColumn: {
  },

  transparentTop: {
    opacity: 0.5,
  },

  title: {
    fontFamily: `Inter,Proxima Nova, proxima-nova, ${theme.fontFamily}`,
    fontWeight: 600,
    color: theme.colors.gray[9],
    fontSize: "1.9rem",
    [theme.fn.smallerThan("sm")]: {
      fontSize: "1.5rem"
    }
  }
}));


function CalculatorSection(props: {
  title: string | JSX.Element, description: string, element?: JSX.Element, icon?: Icon
}) {
  const {classes} = useStyles();
  return (
    <Stack sx={{maxWidth: 500}} pb={120}>
      {/* Show the icon in the calculator section when on mobile. */}
      {/* <MediaQuery largerThan="xs" styles={{display: 'none'}}>
        {
          props.icon ? <props.icon size="1.8rem"/> : undefined
        }
      </MediaQuery> */}
      <Stack spacing={"xs"}>
      <Title className={classes.title}>
        {props.title}
      </Title>
      <Text className={classes.description}>
        {props.description}
      </Text>
      </Stack>
      {props.element}
    </Stack>
  );
}


interface StepData {
  slug: string
  label: string
  title: string | JSX.Element
  description: string
  icon: Icon
  element: JSX.Element
  completed?: boolean
}


function Calculator() {
  const {classes} = useStyles();
  const theme = useMantineTheme();

  const navigate = useNavigateTop();
  const params = useParams();

  useTrackUserReachedContinuePath();

  const [opened, { open, close }] = useDisclosure(false);

  const userId = useAppSelector(selectCurrentUserId) as string;

  const {data} = useGetCalculatorInputsQuery(userId);
  const [reset, resetState] = useResetCalculatorMutation();

  const inputs = data?.inputs;

  const didEnterCountry = inputs?.countryCode !== null;

  const shouldShowZipCode = inputs?.countryCode === "USA";
  const dietPattern = scalarOrNull(inputs?.dietPattern);

  const householdMode = inputs?.calculationType === "household";
  const doYouOrDoesYourHousehold = householdMode ? "does your household" : "do you";

  const cars = inputs?.cars || {};
  // Only show the number of cars that the user has selected, and mask any extras.
  const validCars = Object.entries(cars)
    .filter(([_, car]) => car.index < (inputs?.numCars || 0))
    .map(v => v[1])
    .sort((a: UserCar, b: UserCar) => a.index - b.index);

  const customCarSteps: StepData[] = validCars.map(car => ({
      slug: `car-${car.id}`,
      label: `Car ${car.index + 1}`,
      title: `Car ${car.index + 1}`,
      description: "Fill out details about this car to get a more accurate emissions estimate.",
      icon: IconCar,
      element: <SelectCarParams carId={car.id}/>,
      completed: car.type !== null && car.milesPerWeek !== null && (car.type === "electric" || car.fuelEconomyMpg !== null)
    }));

  const goToNextStep = () => {
    // Wait and then go to the next question.
    // NOTE(milo): Removed this behavior due to user feedback.
    setTimeout(() => setStep(stepIndex + 1), 750);
  }
  // ----
  const goToResults = () => {
    setTimeout(() => navigate("/choose-plan"), 100);
  }

  const promptResetAnswers = () => {
    open();
  }

  const resetAnswers = () => {
    reset({userId});
    navigate("/calculator");
  }

  const isCompleted = (v: null | undefined | number | boolean | string) => {
    return (v !== null) && (v !== undefined);
  }

  const didEnterUserRole = isCompleted(
    inputs?.userRoleDonating || inputs?.userRoleEducating ||
    inputs?.userRoleNotSure || inputs?.userRoleReducingEmissions ||
    inputs?.userRoleVoting || inputs?.userRoleWorking
  );

  const customDietSteps: StepData[] = [
    {
      slug: "red-meat",
      label: "Red meat",
      title: "How many servings of red meat do you eat per week?",
      description: "Red meats, like beef and lamb, have a much higher carbon footprint than other foods.",
      icon: IconMeat,
      element: <SelectFoodServings category='dietRedMeatServingsPerWeek'/>,
      completed: isCompleted(inputs?.dietRedMeatServingsPerWeek)
    },
    {
      slug: "dairy",
      label: "Dairy",
      title: "How often do you have milk, cheese, or other dairy products?",
      description: "Dairy products have relatively high emissions per serving.",
      icon: IconMilk,
      element: <SelectFoodServings category='dietDairyServingsPerWeek'/>,
      completed: isCompleted(inputs?.dietDairyServingsPerWeek)
    },
    {
      slug: "seafood",
      label: "Seafood",
      title: "How often do you have fish and seafood?",
      description: "",
      icon: IconFish,
      element: <SelectFoodServings category='dietSeafoodServingsPerWeek'/>,
      completed: isCompleted(inputs?.dietSeafoodServingsPerWeek)
    },
    {
      slug: "other-animal-products",
      label: "Other",
      title: "How often do you have chicken, pork, or eggs?",
      description: "These foods have similar emissions per serving.",
      icon: IconEggs,
      element: <SelectFoodServings category='dietOtherAnimalServingsPerWeek'/>,
      completed: isCompleted(inputs?.dietOtherAnimalServingsPerWeek)
    },
  ];

  const customTransportSteps: StepData[] = [];

  if (inputs?.usesBus) {
    customTransportSteps.push({
      slug: "transport-bus",
      label: "Bus",
      title: "How much do you travel by bus each week?",
      description: "Buses are a very efficient way to travel.",
      icon: IconBus,
      element: <SelectTransportModeDistance inputKey={"busMiPerWeek"}/>,
      completed: isCompleted(inputs?.busMiPerWeek)
    });
  }
  if (inputs?.usesTransitRail) {
    customTransportSteps.push({
      slug: "weekly-transit-rail",
      label: "Transit rail",
      title: "How much do you travel by transit rail each week?",
      description: "This mode of transportation produces a relatively small amount of emissions per mile.",
      icon: IconTrain,
      element: <SelectTransportModeDistance inputKey={"transitRailMiPerWeek"}/>,
      completed: isCompleted(inputs?.transitRailMiPerWeek)
    });
  }
  if (inputs?.usesInterCityRail) {
    customTransportSteps.push({
      slug: "weekly-inter-city-rail",
      label: "Inter-city",
      title: "How much do you travel by inter-city rail each week?",
      description: "This is more more fuel efficient than flying over long distances.",
      icon: IconTrain,
      element: <SelectTransportModeDistance inputKey={"interCityRailMiPerYear"}/>,
      completed: isCompleted(inputs?.interCityRailMiPerYear)
    });
  }

  // ----
  const calculatorSteps: StepData[] = [
    {
      slug: "role",
      label: "Role",
      title: "What do you see as your role(s) in solving the climate crisis?",
      description: "You can select multiple options. This question helps us get to know our users.",
      icon: IconZoomQuestion,
      element: <SelectClimateRoles/>,
      completed: didEnterUserRole,
    },
    {
      slug: "country",
      label: "Country",
      title: "What country do you live in?",
      description: "This allows us start with a default estimate of your carbon emissions.",
      icon: IconMap,
      element: <SelectCountry/>,
      completed: isCompleted(inputs?.countryCode)
    },
    ...(shouldShowZipCode ? [{
      slug: "zipcode",
      label: "Zipcode",
      title: "What is your zipcode?",
      description: "Average emissions vary significantly across the United States. Entering your zipcode helps us calculate a more precise estimate.",
      icon: IconMapPin,
      element: <SelectZipCode/>,
      completed: isCompleted(inputs?.zipcode)
    }] : []),
    // {
    //   slug: "household",
    //   label: "Household",
    //   title: "Would you like to calculate your individual or household emissions?",
    //   description: "You can include additional adults or children whose emissions you'd like to compensate for.",
    //   icon: IconBuildingCommunity,
    //   element: <SelectHousehold/>
    // },
    {
      slug: "cars",
      label: "Cars",
      title: `How many cars ${doYouOrDoesYourHousehold} use?`,
      description: "You can enter details about each car in the next step.",
      icon: IconCar,
      element: <SelectCars/>,
      completed: isCompleted(inputs?.numCars)
    },
    ...customCarSteps,
    {
      slug: "flights",
      label: "Flights",
      title: `How many roundtrip flights ${doYouOrDoesYourHousehold} take per year?`,
      description: "Exclude any business-related flights, since these should be included in your company's emissions.",
      icon: IconPlane,
      element: <SelectFlights/>,
      completed: inputs?.useCustomFlights !== null,
    },
    {
      slug: "diet",
      label: "Diet",
      title: "What is your diet like?",
      description: "Our food choices play a large role in climate change. Even small reductions in animal product consumption can significantly reduce food emissions.",
      icon: IconChefHat,
      element: <SelectDiet/>,
      completed: isCompleted(inputs?.dietPattern)
    },
    ...customDietSteps.filter((maybeStep) => {
      // All animal products should be included.
      if (dietPattern === null || ["reducitarian", "neither"].includes(dietPattern as string)) {
        return true;
      } else if (dietPattern === "vegetarian") {
        return ["dairy"].includes(maybeStep.slug)
      }
      return false;
    }),
    {
      slug: "public-transit",
      label: "Public transit",
      title: `What other modes of transportation ${doYouOrDoesYourHousehold} use?`,
      description: "Select all that apply.",
      icon: IconBus,
      element: <SelectTransportModes/>,
      completed: isCompleted(inputs?.usesBus) || isCompleted(inputs?.usesTransitRail) || isCompleted(inputs?.usesInterCityRail) || isCompleted(inputs?.usesWalkOrBike)
    },
    ...customTransportSteps,
    {
      slug: "home-size",
      label: "Home size",
      title: "How large is your living space?",
      description: householdMode ? "If you know the area of your home, you can enter it here." : "If you live with roommates, divide the total area by the number of roommates.",
      icon: IconHome,
      element: <SelectHomeSize/>,
      completed: isCompleted(inputs?.homeSizeFt2)
    },
    {
      slug: "pets",
      label: "Pets",
      title: "Do you have any pets?",
      description: "Most of the emissions from pets comes from their food consumption. Larger pets typically have a bigger footprint.",
      icon: IconDog,
      element: <SelectPets/>,
      completed: (inputs?.userIncludePets === false) || (inputs?.userIncludePets === true) || isCompleted(inputs?.numPetsCats) || isCompleted(inputs?.numPetsNormalDogs) || isCompleted(inputs?.numPetsLargeDogs)
    },
    {
      slug: "furniture-and-appliances",
      label: "Furnishings",
      title: <span>How much {doYouOrDoesYourHousehold} spend on furniture and appliances each <i>year</i>?</span>,
      description: "If you're not sure, you can use the average value.",
      icon: IconArmchair,
      element: <SelectPurchases category="furniture"/>,
      completed: isCompleted(inputs?.monthlySpendingGoodsFurnitureAndAppliancesUsd)
    },
    {
      slug: "clothes",
      label: "Clothes",
      title: <span>How much {doYouOrDoesYourHousehold} spend on clothes each <i>year</i>?</span>,
      description: "This includes both in-store and online purchases.",
      icon: IconShirt,
      element: <SelectPurchases category="clothes"/>,
      completed: isCompleted(inputs?.monthlySpendingGoodsClothesUsd)
    },
    {
      slug: "supplies",
      label: "Supplies",
      title: <span>How much {doYouOrDoesYourHousehold} spend on supplies and other goods each <i>month</i>?</span>,
      description: "This includes goods like books, electronics, household supplies, and any other items you buy or own.",
      icon: IconDeviceTv,
      element: <SelectPurchases category="supplies"/>,
      completed: isCompleted(inputs?.monthlySpendingGoodsOtherUsd)
    },
    {
      slug: "services",
      label: "Services",
      title: <span>How much {doYouOrDoesYourHousehold} spend on services each <i>month</i>?</span>,
      description: "This includes healthcare, internet, subscriptions, home repairs, etc. Any time you pay a person or company to do something for you counts as a service.",
      icon: IconHammer,
      element: <SelectPurchases category="services"/>,
      completed: isCompleted(inputs?.monthlySpendingServicesUsd)
    },
    {
      slug: "electricity",
      label: "Electricity",
      title: `In an average month, how much electricity ${doYouOrDoesYourHousehold} use?`,
      description: "You can enter an exact amount from your energy bill if you want.",
      icon: IconBulb,
      element: <SelectElectricity/>,
      completed: isCompleted(inputs?.electricityKwhPerMonth)
    },
    {
      slug: "natural-gas",
      label: "Natural gas",
      title: `In an average month, how much natural gas ${doYouOrDoesYourHousehold} use?`,
      description: "You can enter an exact amount from your energy bill if you want.",
      icon: IconFlame,
      element: <SelectNaturalGas/>,
      completed: isCompleted(inputs?.naturalGasThermsPerMonth)
    },
    // {
    //   slug: "renewable-energy",
    //   label: "Renewable energy",
    //   title: "Do you generate any home renewable energy?",
    //   description: "For example, if you get half of your energy from rooftop solar you would enter 50% here.",
    //   icon: IconSolarPanel,
    //   element: <SelectRenewables/>
    // },
  ];

  const setStep = (newStepIndex: number) => {
    if (newStepIndex >= calculatorSteps.length) {
      goToResults();
    } else {
      const newSlug = calculatorSteps[Math.min(newStepIndex, calculatorSteps.length - 1)].slug;
      navigate(`/calculator/${newSlug}`);
    }
  }

  let stepIndex = calculatorSteps.findIndex(({ slug }) => slug === params.stepId);
  if (stepIndex < 0) {
    stepIndex = 0;
    navigate("/calculator/country");
  }

  stepIndex = Math.min(stepIndex, calculatorSteps.length - 1);
  const stepData = calculatorSteps[stepIndex];

  const progressPercent = 100 * (stepIndex + 1) / calculatorSteps.length;
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <>
    <Container size={"md"} className={classes.container}>
      <Modal
        opened={opened}
        onClose={close}
        title={<Text fw={600}>Are you sure?</Text>}
        centered
        styles={{inner: {boxSizing: "border-box"}}}
      >
        <Stack>
          <Text>This will clear your answers and restart from the beginning.</Text>
          <Group grow>
            <Button color='blue' onClick={resetAnswers}>Yes, reset</Button>
            <Button color='gray' variant="outline" onClick={close}>No, cancel</Button>
          </Group>
        </Stack>
      </Modal>
      {/* <Space h="xl" className={classes.transparentTop}/> */}
      <Grid gutter={"xl"}>
        {/* Hide on mobile */}
        <MediaQuery smallerThan="xs" styles={{ display: 'none', width: 0 }}>
          <Grid.Col span="content">
            <CalculatorStepper
              stepData={calculatorSteps}
              stepIndex={stepIndex}
              width={stepperWidth}
            />
          </Grid.Col>
        </MediaQuery>
        <Grid.Col span="auto">
          <Space h={"xl"}/>
          <CalculatorSection
            icon={stepData.icon}
            title={stepData.title}
            description={stepData.description}
            element={stepData.element}
          />
        </Grid.Col>
        {/* Hide on tablet and mobile */}
        <MediaQuery smallerThan="md" styles={{ display: 'none', width: 0 }}>
          <Grid.Col span="content" className={classes.cardColumn}>
            <Space h={"xl"}/>
            <Box w={cardWidth}>
              <FootprintCard/>
              <MoreInformationCard/>
            </Box>
          </Grid.Col>
        </MediaQuery>
      </Grid>
      <Space h="lg"/>
    </Container>
    <Stack className={classes.bottomControls}>
      <Group spacing={mobile ? "xs" : "md"}>
        {
          mobile ?
            <>
              <ActionIcon size={"xl"} variant='filled' color='teal' w={"3rem"} onClick={() => {
                  const prevStep = Math.max(stepIndex - 1, 0)
                  setStep(prevStep);
              }}>
                <IconArrowLeft/>
              </ActionIcon>
              <ActionIcon size={"xl"} variant='filled' color='teal' w={"3rem"} onClick={() => {
                  const nextStep = Math.min(stepIndex + 1, calculatorSteps.length);
                  setStep(nextStep);
              }}>
                <IconArrowRight/>
              </ActionIcon>
              <ActionIcon size={"xl"} variant='filled' color='gray' w={"3rem"} onClick={goToResults}>
                <IconArrowsRight/>
              </ActionIcon>
              <ActionIcon size={"xl"} variant='filled' color='blue' w={"3rem"} onClick={promptResetAnswers}>
                <IconRefresh/>
              </ActionIcon>
            </> :
            <>
              <Button
                leftIcon={<IconArrowLeft/>}
                onClick={() => {
                  const prevStep = Math.max(stepIndex - 1, 0)
                  setStep(prevStep);
                }
              }>
                Back
              </Button>
              <Button rightIcon={<IconArrowRight/>} onClick={() => {
                  const nextStep = Math.min(stepIndex + 1, calculatorSteps.length);
                  setStep(nextStep);
                }
              }>
                Next
              </Button>
              <Button
                variant="outline"
                color="gray"
                size={mobile ? "sm" : "sm"}
                onClick={goToResults}
                disabled={!didEnterCountry}
              >
                Skip to results
              </Button>
              <Button
                variant="outline"
                color="blue"
                size={"sm"}
                leftIcon={<IconRefresh/>}
                onClick={promptResetAnswers}
              >
                Reset answers
              </Button>
            </>
        }
      </Group>
      <Progress color="teal" value={progressPercent} />
    </Stack>
    </>
  );
}


function CalculatorShell() {
  const params = useParams();
  const theme = useMantineTheme();

  const userId = useAppSelector(selectCurrentUserId);

  // If the user has a subscription, skip the email collection step.
  const {data} = useListStripeSubscriptionsQuery(
    { userId: userId as string }, { skip: !userId });

  const userHasSubscription = (data?.data || []).length > 0;
  if (userHasSubscription && params.stepId === "finished") {
    return <Navigate to="/choose-plan"/>
  }

  return (
    <div>
      <TopHeader
        defaultBackgroundColor="white"
        defaultTextColor={theme.colors.gray[9]}
        defaultLogoColor={theme.colors.gray[9]}
        defaultPosition="relative"
      />
      <Container fluid style={{paddingLeft: 0, paddingRight: 0}}>
        {
          params.stepId === "finished" ? <CalculatorFinished/> : <Calculator/>
        }
      </Container>
    </div>
  );
}

export default CalculatorShell;
