import { Title, Container, Space, Paper, Text, Card, Group, Grid, Stack, Alert, Badge, SimpleGrid, Divider, Skeleton, useMantineTheme, Popover, Button, Anchor } from "@mantine/core";
import { createStyles, rem } from '@mantine/core';
import { IconInfoCircleFilled } from "@tabler/icons-react";
import { selectCurrentUser, useAppSelector } from "../../redux/hooks";
import { useCalculateUserImpactQuery } from "../../api/payment";
import { useDisclosure } from "@mantine/hooks";
import { URL_WIKI_IMPACT_CALCULATION } from "../../utils/links";


const useStyles = createStyles((theme) => ({
  statTitle: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 600,
    lineHeight: 1,
  },
  statValue: {
    fontFamily: `Recoleta,${theme.fontFamily}`,
    fontSize: "3rem",
    fontWeight: 600,
    lineHeight: 1,
  },
  statSubtitle: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 500,
    lineHeight: 1.4,
  }
}));


function ImpactStat(props: { title: string, value: string, subtitle: string, loading?: boolean }) {
  const {classes} = useStyles();
  if (props.loading) {
    return (
      <Stack spacing={"sm"} mt={"lg"}>
        <Skeleton w={150} h={"1rem"}/>
        <Skeleton w={100} h={"3rem"}/>
        <Skeleton w={180} h={"1rem"}/>
      </Stack>
    )
  }
  return (
    <Stack spacing={"sm"} mt={"lg"}>
      <Text className={classes.statTitle}>{props.title}</Text>
      <Text className={classes.statValue}>{props.value}</Text>
      <Text className={classes.statSubtitle}>{props.subtitle}</Text>
    </Stack>
  )
}


function MainRoute() {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const user = useAppSelector(selectCurrentUser);
  const firstName = user.firstName;

  const welcome = firstName ? `Welcome back, ${firstName}!` : "Welcome back!";

  const {data, isLoading, isFetching} = useCalculateUserImpactQuery({ userId: user.userId as string });

  const loading = isLoading || isFetching;

  const subscriptionStart = new Date((data?.meta.earliestInvoice || 0) * 1000);

  const totalLandRestored = (
    (data?.data.land_restored_m2 || 0) + (data?.data.land_conserved_m2 || 0)
  );

  return (
    <Container size="lg" pt={"xl"} pb="xl" p={"xl"}>
      <Stack align="flex-start" spacing={"lg"}>
        <Stack spacing={"xs"}>
          <Text fw={"bold"} color="teal">{welcome}</Text>
          {
            loading ?
              <Skeleton w={200} h={"1rem"}/> :
              <Badge fullWidth={false} variant="gradient">Subscriber since {subscriptionStart.toLocaleDateString("default")}</Badge>
          }
        </Stack>
        <Stack spacing={"xs"}>
          <Title lh={1.5}>Your cumulative impact</Title>
          <Group>
            <Text fw={500}>Here's what you've achieved to-date:</Text>
          </Group>
        </Stack>
        <SimpleGrid spacing={"lg"} breakpoints={[
          { minWidth: "sm", cols: 2 },
          { minWidth: "md", cols: 4 }
        ]}>
          <ImpactStat
            title="Emissions"
            value={(data?.data.tons_co2 || 0).toLocaleString("default", { maximumFractionDigits: 2 })}
            subtitle="tons CO₂ offset"
            loading={loading}
          />
          <ImpactStat
            title="Land Use"
            value={totalLandRestored.toLocaleString("default", { maximumFractionDigits: 1 })}
            subtitle="m² of land restored and conserved"
            loading={loading}
          />
          <ImpactStat
            title="Sustainable Meals"
            value={(data?.data.meals_served || 0).toLocaleString("default", { maximumFractionDigits: 0 })}
            subtitle="sustainable meals served"
            loading={loading}
          />
          <ImpactStat
            title="Animals"
            value={(data?.data.animal_lives || 0).toLocaleString("default", { maximumFractionDigits: 0 })}
            subtitle="fewer animals in factory farms"
            loading={loading}
          />
        </SimpleGrid>
        {
          (data && data.data.tons_co2 === 0) ?
            <Alert title={"Don't see your impact yet?"}>
              <Text>If you just made a credit purchase, it might take a few minutes to be reflected here.</Text>
            </Alert> : undefined
        }
        <Alert icon={<IconInfoCircleFilled/>} title="Learn how impact is measured" color="gray">
          <Text>
            Climate Refarm calculates your personal impact based on measured, publicly-reported data
            from each of our projects. You can learn more about our impact quantification <Anchor href={URL_WIKI_IMPACT_CALCULATION} target="_blank">here</Anchor>.
          </Text>
        </Alert>
      </Stack>
    </Container>
);
}

export default MainRoute;
