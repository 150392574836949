import { Title, Container, createStyles, Stack, Text, Space, useMantineTheme, Paper } from "@mantine/core";

import EnterCountryButton from "./EnterCountryButton/EnterCountryButton";
import { useMediaQuery } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    backgroundColor: theme.colors.green[2],
    minHeight: "100vh",
    boxShadow: theme.shadows.lg,
  },

  wrapperMobile: {
    position: "relative",
    backgroundColor: theme.colors.teal[9],
    minHeight: "100vh",
    boxShadow: theme.shadows.lg,
  },

  imageContainer: {
    position: "absolute",
    display: "block",
    backgroundSize: "cover",
  },

  wavyOverlay: {
    backgroundImage: "url(/assets/banner/wavy3.svg)",
    width: "100vw",
    left: 0,
    height: "100vh",
    zIndex: 0,
    filter: "drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.1))"
  },

  wavyOverlayMobile: {
    backgroundImage: "url(/assets/banner/wavy_mobile_bottom.svg)",
    width: "100vw",
    bottom: 0,
    left: 0,
    height: "100vh",
    zIndex: 0,
    filter: "drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.1))"
  },

  foodOverlay: {
    backgroundImage: "url(/assets/banner/1.5x.v3.min.png),url(/assets/banner/1x.v3.min.png)",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 0,
    backgroundPosition: "center",
    filter: "drop-shadow(-3px -3px 3px rgba(0, 0, 0, 0.1))"
  },

  foodOverlayMobile: {
    backgroundImage: "url(/assets/banner/2x.mobile.v1.min.png),url(/assets/banner/1x.mobile.v1.min.png)",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
    backgroundPosition: "center",
    filter: "drop-shadow(-3px -3px 3px rgba(0, 0, 0, 0.1))"
  },

  contentContainer: {
    paddingLeft: "2rem",
    paddingTop: '20vh',
    paddingBottom: '10vh',
    zIndex: 2,
    position: "relative",
  },

  mobileContentContainer: {
    position: "relative"
  },
}));


function MobileBannerFood() {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();

  return (
    <>
      <Container fluid className={classes.wrapperMobile}>
        <div className={cx(classes.imageContainer, classes.wavyOverlayMobile)}/>
        <div className={cx(classes.imageContainer, classes.foodOverlayMobile)}/>
        <div className={classes.mobileContentContainer}>
          <Stack align='flex-start'>
            <Space h={"calc(60px + 1rem)"}/>
            <Title
              align="left"
              // color={theme.colors.green[1]}
              color={"white"}
              fw={600}
              sx={{
                fontSize: 'max(2.8vw, 2.2rem)',
              }}
            >
              Climate action for people, animals, and the planet
            </Title>
            <Text align="left" fz={"1.3rem"} fw={600} color={theme.colors.green[1]}>
              Offset your emissions by building a sustainable food system.
            </Text>
            <EnterCountryButton variant="white"/>
          </Stack>
        </div>
      </Container>
    </>
  )
}


function DesktopBannerFood() {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();

  return (
    <Container
      fluid
      className={classes.wrapper}
    >
      <div className={cx(classes.imageContainer, classes.wavyOverlay)}/>
      <div className={cx(classes.imageContainer, classes.foodOverlay)}/>
      <div className={classes.contentContainer}>
        <Stack spacing={32} align='flex-start'>
          <Space h={54}/>
          <Title
            align="left"
            color={"white"}
            fw={600}
            sx={{
              maxWidth: '700px',
              fontSize: 'max(3.8vw, 3rem)',
            }}
          >
            Climate action for people, animals, and the planet
          </Title>
          <Text
            color={theme.colors.green[1]}
            align="center" fz={"xl"}
            fw={600} maw={800}
          >
            Offset your emissions by building a sustainable food system.
          </Text>
          <EnterCountryButton/>
          <Space h={54}/>
        </Stack>
      </div>
    </Container>
  );
}


export default function BannerFood() {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();

  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  return mobile ? <MobileBannerFood/> : <DesktopBannerFood/>
}
