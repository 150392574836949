import { useState } from "react";

import { Group } from "@mantine/core";
import { selectCurrentUserId, useAppSelector } from "../../../redux/hooks";
import { useGetCalculatorDefaultsQuery, useGetCalculatorInputsQuery, useUpdateCalculatorInputsMutation } from "../../../api/calculator";

import CalculatorButton from "../../../components/calculator/CalculatorButton";
import CustomNumberInput from "../../../components/calculator/CustomNumberInput";
import MultipleChoice, { IChoiceData, isCustomNumericOptionSelected } from "../../../components/calculator/MultipleChoice";

import { almostEqual } from "../../../utils/helpers";


export default function SelectElectricity(props: { goToNext?: () => void }) {
  const userId = useAppSelector(selectCurrentUserId) as string;

  const [useKwh, setUseKwh] = useState(false);

  const {data} = useGetCalculatorInputsQuery(userId);
  const [updateCalculator] = useUpdateCalculatorInputsMutation();

  const defaultsApi = useGetCalculatorDefaultsQuery(userId);
  const loading = defaultsApi.isLoading || defaultsApi.isFetching || !userId;

  const inputs = data?.inputs;

  const usdPerKwh = defaultsApi.data?.defaults.electricityUsdPerKwh || 0.182;

  const userValueKwh = inputs?.electricityKwhPerMonth || null;
  const userValue = (userValueKwh === null) ? null : (useKwh ? userValueKwh : userValueKwh * usdPerKwh);

  const setUserValue = (value: number | string | null) => {
    if (useKwh) {
      updateCalculator({userId, inputs: { electricityKwhPerMonth: value as number }});
    } else {
      updateCalculator({userId, inputs: { electricityKwhPerMonth: (value as number) / usdPerKwh }});
    }
  };

  const sharedOnClick = (data: IChoiceData) => {
    setUserValue(data.value);

    if (props.goToNext) {
      props.goToNext();
    }
  }

  // TODO(milo): Set these multiples.
  const meanKwh = defaultsApi.data?.defaults.electricityKwhPerMonth || 500;
  const meanUsd = meanKwh * usdPerKwh;
  const meanValue = useKwh ? meanKwh : meanUsd
  const lowValue = meanValue * 0.5;
  const highValue = meanValue * 1.5;

  const unitsLabel = useKwh ? 'kWh/month' : '$/month';

  const choices: IChoiceData[] = [
    {
      value: lowValue,
      label: "A little",
      detail: `${lowValue.toLocaleString("default", { maximumFractionDigits: 0})} ${unitsLabel}`,
      selected: almostEqual(userValue, lowValue, 0.5),
      onClick: sharedOnClick
    },
    {
      value: meanValue,
      label: "Average",
      detail: `${meanValue.toLocaleString("default", { maximumFractionDigits: 0})} ${unitsLabel}`,
      selected: almostEqual(userValue, meanValue, 0.5),
      onClick: sharedOnClick
    },
    {
      value: highValue,
      label: "A lot",
      detail: `${highValue.toLocaleString("default", { maximumFractionDigits: 0})} ${unitsLabel}`,
      selected: almostEqual(userValue, highValue, 0.5),
      onClick: sharedOnClick
    }
  ];

  const customIsSelected = isCustomNumericOptionSelected(choices, userValue, 0.5);

  const customInput =
    <CustomNumberInput
      onChange={setUserValue}
      units={unitsLabel}
      initialValue={customIsSelected ? userValue : null}
      selected={customIsSelected}
      min={0}
      max={10000}
    />;

  return (
    <>
      <Group>
        <CalculatorButton selected={useKwh} onClick={() => setUseKwh(true)}>
          Enter kWh
        </CalculatorButton>
        <CalculatorButton selected={!useKwh} onClick={() => setUseKwh(false)}>
          Enter $
        </CalculatorButton>
      </Group>
      <MultipleChoice
        loading={loading}
        choices={choices}
        customInputElement={customInput}
      />
    </>
  );
}
