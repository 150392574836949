import {
  createStyles,
  Accordion,
  Text,
  rem,
  Space,
  Anchor,
} from '@mantine/core';
import HeaderTitle from '../../shared/HeaderTitle';


const useStyles = createStyles((theme) => ({
  wrapper: {
    color: theme.colors.gray[9],
    paddingBottom: "2rem",
  },

  item: {
    backgroundColor: theme.white,
    borderBottom: 0,
    borderRadius: theme.radius.sm,
    boxShadow: theme.shadows.sm,
    overflow: 'hidden',
  },

  label: {
    fontSize: theme.fontSizes.md,
    fontWeight: 600,
    color: theme.colors.gray[9],
  },

  content: {
    paddingLeft: rem(4),
    lineHeight: 1.6,
    color: theme.colors.gray[9],
  },
}));


export default function ForCompaniesFAQs() {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <HeaderTitle header={"Frequently Asked Questions"} align='left' color='white'/>
      <Space h="xl"/>
      <Accordion
        chevronPosition="right"
        chevronSize={50}
        variant={"separated"}
        styles={{
          chevron: {
            minWidth: "1.5rem",
            width: "1.5rem"
          }
        }}
      >
        {/* --- */}
        <Accordion.Item className={classes.item} value="sbti">
          <Accordion.Control>
            <Text className={classes.label}>Can I use your carbon credits for my science-based, net zero target?</Text>
          </Accordion.Control>
          <Accordion.Panel>
            <Text className={classes.content}>
              Under the <Anchor href="https://sciencebasedtargets.org/" target="_blank">Science-Based Targets Initiative (SBTi)</Anchor>, carbon
              credits cannot be used to meet short-term targets, and only permanent
              carbon removal credits (e.g, direct air capture) can be used to offset residual, long-term emissions.
              <br/><br/>
              <strong>However, SBTi <Anchor href="https://sciencebasedtargets.org/beyond-value-chain-mitigation" target="_blank" rel="noreferrer">strongly recommends</Anchor> that
              companies go above and beyond their net zero targets
              to invest in mitigation beyond their value chains.</strong> There is an <Anchor href="https://www.unep.org/resources/emissions-gap-report-2022" target="_blank" rel="noreferrer">enormous gap</Anchor> between
              international climate pledges and the action needed by 2030 to limit warming to 1.5C, and beyond value chain mitigation
              can help address this shortfall.
              <br/><br/>
              Funding a sustainable food transition is strongly aligned with
              the <Anchor href="https://sciencebasedtargets.org/resources/files/SBTi-Public-Consultation-on-Beyond-Value-Chain-Mitigation.pdf" target="_blank" rel="noreferrer">proposed principles</Anchor> for
              beyond value chain mitigation. The food system is significantly underfinanced, yet has the potential to address one-third of
              global emissions and deliver large-scale carbon removal through land restoration. Furthermore, it can deliver co-benefits
              for human healthy, biodiversity, water security, pandemic risk, and animal welfare.
              <br/><br/>
              If you have questions about how your company can contribute to the sustainable food transition, don't hesitate to reach out.
            </Text>
          </Accordion.Panel>
        </Accordion.Item>

        {/* --- */}
        <Accordion.Item className={classes.item} value="credit-verification">
          <Accordion.Control>
            <Text className={classes.label}>Are your credits third-party verified?</Text>
          </Accordion.Control>
          <Accordion.Panel>
            <Text className={classes.content}>
              Our portfolio consists of three project types: (1) sustainable food, (2) reforestation, and (3) conservation.
              The reforestation and conservation carbon credits in our portfolio are verified by a third-party and
              issued through a major carbon registry like Verra or Gold Standard.<br/><br/>
              Because we are the first company to develop sustainable food projects, the methodology for these projects
              is still undergoing approval. Once this process is complete, our portfolio will be 100% third-party verified.
            </Text>
          </Accordion.Panel>
        </Accordion.Item>

        {/* --- */}
        <Accordion.Item className={classes.item} value="removal-and-avoidance">
          <Accordion.Control>
            <Text className={classes.label}>What fraction of your portfolio is carbon removal vs. avoided emissions?</Text>
          </Accordion.Control>
          <Accordion.Panel>
            <Text className={classes.content}>
              The answer depends on the availability of conservation and reforestation credits in relevant project regions that meet our selection criteria.
              Generally, 1 Climate Refarm Tonne (CRT) is composed of 50-75% avoided emissions, and 25-50% nature-based carbon removal.
            </Text>
          </Accordion.Panel>
        </Accordion.Item>

        {/* --- */}
        <Accordion.Item className={classes.item} value="specific-projects">
          <Accordion.Control>
            <Text className={classes.label}>Is it possible to purchase carbon credits from a specific project?</Text>
          </Accordion.Control>
          <Accordion.Panel>
            <Text className={classes.content}>
              Yes! Please reach out if you are interested in procuring from individual projects.
            </Text>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
