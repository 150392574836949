import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { getApiBaseUrl } from './config';
import { SubscriptionPlan } from './shared';

import Stripe from 'stripe';


// Choose the API base URL depending on the environment:
const BASE_URL = `${getApiBaseUrl()}/payment/`;

interface CreateCheckoutSessionResponse {
  redirectUrl: string
}

interface CreatePaymentIntentResponse {
  clientSecret: string
}

interface CreatePaymentIntentBody {
  items?: { id: string }[]
}


export type ClimateRefarmProductId = "CLIMATE_OFFSET_PORTFOLIO";


export interface Order {
  units: number
  billing_interval: "month" | "year" | "onetime"
}

export interface StripeCustomMetadata {
  order_id: string
  user_id: string
  order: Order
}

interface ListSubscriptionsResponse {
  data: Stripe.Subscription[],
  meta: {
    stripeCustomerId: string[]
    subscriptionCount: number
  }
}

interface ListInvoicesResponse {
  data: Stripe.Invoice[],
  meta: {
    stripeCustomerId: string[],
    subscriptionCount: number
  }
}

interface UserImpactResponse {
  data: {
    tons_co2: number
    land_use_reduced_m2: number
    land_restored_m2: number
    land_conserved_m2: number
    meals_served: number
    animal_lives: number
  }
  meta: {
    earliestInvoice: number
    latestInvoice: number
  }
}


export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  tagTypes: ['SelectedSubscriptionPlan', 'ListSubscriptions', 'ListInvoices', 'UserImpact'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.currentUser.accessToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    createCheckoutSession: builder.mutation<CreateCheckoutSessionResponse, { successUrl: string, cancelUrl: string, email?: string | null }>({
      query: (body) => ({
        url: `/create-checkout-session`,
        method: 'POST',
        body: body
      }),
    }),
    updateSubscription: builder.mutation<any, void>({
      query: () => ({
        url: `/update-subscription`,
        method: 'PUT',
      }),
    }),
    createPaymentIntent: builder.mutation<CreatePaymentIntentResponse, CreatePaymentIntentBody>({
      query: (body) => ({
        url: `/create-payment-intent`,
        method: 'POST',
        body: body
      }),
    }),
    chooseSubscriptionPlan: builder.mutation<SubscriptionPlan, {userId: string, plan: SubscriptionPlan}>({
      query: (body) => ({
        url: `/choose-subscription-plan`,
        method: 'PUT',
        body: body.plan,
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: "SelectedSubscriptionPlan", id: arg.userId }]
      },
    }),
    getSelectedPlan: builder.query<SubscriptionPlan, {userId: string}>({
      query: (args) => ({
        url: `/selected-plan`,
      }),
      providesTags: (result, error, arg) => [{ type: "SelectedSubscriptionPlan", id: arg.userId }]
    }),
    listStripeSubscriptions: builder.query<ListSubscriptionsResponse, {userId: string}>({
      query: (args) => ({
        url: `/subscriptions`,
      }),
      providesTags: (result, error, arg) => [{ type: "ListSubscriptions", id: arg.userId }]
    }),
    listStripeInvoices: builder.query<ListInvoicesResponse, {userId: string}>({
      query: (args) => ({
        url: `/invoices`,
      }),
      providesTags: (result, error, arg) => [{ type: "ListInvoices", id: arg.userId }]
    }),
    calculateUserImpact: builder.query<UserImpactResponse, {userId: string}>({
      query: (args) => ({
        url: `/impact`,
      }),
      providesTags: (result, error, arg) => [{ type: "UserImpact", id: arg.userId }]
    }),
  }),
})


export const {
  useCreateCheckoutSessionMutation,
  useCreatePaymentIntentMutation,
  useChooseSubscriptionPlanMutation,
  useGetSelectedPlanQuery,
  useListStripeSubscriptionsQuery,
  useListStripeInvoicesQuery,
  useCalculateUserImpactQuery,
  useUpdateSubscriptionMutation,
} = paymentApi
