import { Title, createStyles, Stack, Text, useMantineTheme } from "@mantine/core";


const useStyles = createStyles((theme) => ({
  header: {
    textTransform: "uppercase",
    fontWeight: 600,
    fontSize: theme.fontSizes.lg,
    letterSpacing: ".06rem",
    lineHeight: 1.5,
    textAlign: "center",

    [theme.fn.smallerThan('xs')]: {
      textAlign: "left",
      width: "100%",
      fontSize: theme.fontSizes.md,
    },
  },
  title: {
    fontWeight: 500,
    fontSize: `calc(2.3 * ${theme.fontSizes.xl})`,
    lineHeight: 1.5,

    [theme.fn.smallerThan('sm')]: {
      fontSize: `calc(1.5 * ${theme.fontSizes.xl})`,
      textAlign: "left",
    },
  },
  readableWidth: {
    maxWidth: 900
  }
}));


export default function HeaderTitle(props: {
  header?: string,
  title?: string,
  color?: string
  align?: "left" | "center" | "right",
}) {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();
  return (
    <Stack spacing={0} align={props.align || "center"}>
      {
        props.header ?
        <Text
          color={props.color|| theme.colors.teal[6]}
          className={classes.header}
          align={props.align || "center"}
          w={"100%"}
          sx={{textAlign: props.align || "center"}}
        >
          {props.header}
        </Text> : undefined
      }
      {
        props.title ?
        <Title className={cx(classes.title, classes.readableWidth)} align={props.align || "center"} color={props.color|| theme.colors.gray[9]}>
          {props.title}
        </Title> : undefined
      }
    </Stack>
  )
}