import { Button, Stack, Text, Space, useMantineTheme, Paper, Group, SegmentedControl, NumberInput, Skeleton, Title } from "@mantine/core";

import { IconCheck } from "@tabler/icons-react";
import { useChooseSubscriptionPlanMutation, useListStripeSubscriptionsQuery } from "../../api/payment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectCurrentUserId, useAppSelector } from "../../redux/hooks";
import { OffsetClaim } from "../../api/shared";


function PriceInput(props: { value: number, setValue: (v: number) => void }) {
  return (
    <NumberInput
      value={props.value}
      onChange={props.setValue}
      size="xl"
      min={1}
      max={100000}
      precision={2}
      hideControls
      icon={<Stack spacing={2} align="center">
        <Text p={0} sx={{lineHeight: 1}} fw={"bold"} fz={"lg"}>$</Text>
        <Text p={0} sx={{lineHeight: 1}} fz={"sm"}>USD</Text>
      </Stack>}
      styles={{input: {textAlign: "right"}}}
    />
  );
}


function PriceDisplay(props: { price: number, loading?: boolean }) {
  const theme = useMantineTheme();
  const dollars = Math.floor(props.price);
  const cents = Math.round(100 * (props.price - dollars));
  return (
    <Group spacing={"0.25rem"} display={"flex"} align="center" sx={{alignItems: "start"}}>
      {
        props.loading ? <>
          <Skeleton w={"100px"} h="2.5rem"/>
        </> :
        <>
          <Title fz={"2.5rem"} fw={500} sx={{lineHeight: 1.0}} ff={`Inter, Proxima Nova, proxima-nova, ${theme.fontFamily}`}>
            {dollars.toLocaleString("default", { style: "currency", currency: "USD", maximumFractionDigits: 0 })}
          </Title>
          <Title color={theme.colors.gray[6]} fz={"md"} pt={"xs"} fw={500} ff={`Inter, Proxima Nova, proxima-nova, ${theme.fontFamily}`}>
            .{cents.toLocaleString("default", { minimumIntegerDigits: 2, maximumFractionDigits: 0})}
          </Title>
        </>
      }
    </Group>
  );
}


export function SubscriptionToggle(props: { value: "month" | "year", onChange: (value: "month" | "year") => void }) {
  return (
    <SegmentedControl
      value={props.value}
      onChange={props.onChange}
      radius={"md"}
      size="md"
      fw={"bold"}
      data={[
        {
          value: 'month',
          label: <Text fw={"bold"}>Monthly</Text>
        },
        {
          value: 'year',
          label: <Text fw={"bold"}>Yearly</Text>
        },
      ]}
    />
  );
}


export default function Subscription(props: {
  userId: string | undefined,
  name: string,
  monthly: boolean,
  pricePerTon: number,
  tonsPerYear: number,
  userTonsPerYear: number,
  isMostPopular?: boolean,
  custom?: boolean,
  loading?: boolean,
  claim?: OffsetClaim
}) {
  const costPerYear = props.pricePerTon * props.tonsPerYear;

  const theme = useMantineTheme();
  const navigate = useNavigate();

  const userId = useAppSelector(selectCurrentUserId);

  // If the user has a subscription, skip the email collection step.
  const {data} = useListStripeSubscriptionsQuery(
    { userId: userId as string }, { skip: !userId });

  const userHasSubscription = (data?.data || []).length > 0;

  const cost = props.monthly ? costPerYear / 12 : costPerYear;

  const [customCost, setCustomCost] = useState(cost);

  const [choosePlan, choosePlanResult] = useChooseSubscriptionPlanMutation();

  const isChoosing = choosePlanResult.isLoading;
  const didChoose = choosePlanResult.isSuccess;

  const onChoosePlan = () => {
    const chosenCostPerYear = props.custom ? customCost * (props.monthly ? 12 : 1) : costPerYear;
    const chosenTonsPerYear = chosenCostPerYear / props.pricePerTon;
    choosePlan({
      userId: props.userId || "NO_USER_ID",
      plan: {
        name: props.name,
        pricePerTon: props.pricePerTon,
        tonsPerYear: chosenTonsPerYear,
        interval: props.monthly ? "month" : "year",
        userFootprintTonsPerYear: props.userTonsPerYear,
      }
    });
  }

  // Delay for a bit and then go to the checkout page.
  useEffect(() => {
    if (choosePlanResult.isSuccess) {
      setTimeout(() => navigate("/checkout"), 500);
    }
  }, [choosePlanResult.isSuccess]);

  const customCostPerYear = props.monthly ? customCost * 12 : customCost;
  const percentOffset = 100 * ((props.custom ? customCostPerYear : costPerYear) / props.pricePerTon) / props.userTonsPerYear;

  useEffect(() => {
    setCustomCost(cost)
  }, [cost]);

  return (
    <Paper p={"xs"} bg={props.isMostPopular ? theme.colors.teal[6] : "white"} radius="md">
      {
        props.isMostPopular ?
        <Text color="white" h={"1.5rem"} fz={"xs"} fw={"bold"} align="center" transform="uppercase">
          Most popular
        </Text>
        : <Space h={"2rem"}/>
      }
      <Paper
        w={"100%"}
        p={"xl"}
        bg={theme.colors.gray[0]}
        shadow="md"
        radius="md"
        withBorder
      >
        <Stack align="center" spacing={"md"}>
          {
            props.loading ?
              <Skeleton h={"lg"} w={"150px"}/> :
              <Text fz={"lg"} fw={"bold"}>
                {props.name}
              </Text>
          }
          <Stack spacing={"0.25rem"} align="center">
            {props.custom ?
              <PriceInput value={customCost} setValue={setCustomCost}/>:
              <PriceDisplay price={cost} loading={props.loading}/>
            }
            <Text color={theme.colors.gray[6]} fz={"md"} sx={{lineHeight: 1.0}}>
              per {props.monthly ? "month" : "year"}
            </Text>
          </Stack>
          {
            props.loading ? <Skeleton h="8rem"/> :
            <Paper bg={theme.colors.gray[1]} radius="sm" p={"md"}>
              <Stack>
                <Group spacing={"0.5rem"}>
                  <IconCheck color={theme.colors.teal[6]} width={"24px"} size={"20px"}/>
                  <Text color={theme.colors.gray[8]} w={"calc(100% - 32px - 0.5rem)"} fw={"normal"} sx={{lineHeight: 1.4}}>
                    Offsets <strong style={{color: theme.colors.teal[6]}}>{percentOffset.toLocaleString("default", {maximumFractionDigits: 0})}%</strong> of your emissions
                  </Text>
                </Group>
                <Group spacing={"0.5rem"}>
                  <IconCheck color={theme.colors.teal[6]} width={"24px"} size={"20px"}/>
                  <Text color={theme.colors.gray[8]} w={"calc(100% - 32px - 0.5rem)"} fw={"normal"} sx={{lineHeight: 1.4}}>
                    {
                      props.claim ? {
                        "below_footprint": "A great way to start your climate journey",
                        "neutral": "Balances your emissions with offsets",
                        "paris_aligned": "In line with 1.5°C of warming",
                        "beyond_footprint": "Goes beyond your personal footprint"
                      }[props.claim] :
                      ""
                    }
                  </Text>
                </Group>
                {/* <Group spacing={"0.5rem"}>
                  <IconCheck color={theme.colors.teal[6]} width={"24px"} size={"20px"}/>
                  <Text color={theme.colors.gray[8]} w={"calc(100% - 32px - 0.5rem)"} fw={"normal"} sx={{lineHeight: 1.4}}>
                    Funds a porfolio of climate solutions
                  </Text>
                </Group> */}
              </Stack>
            </Paper>
          }
          <Button size="md" variant="filled" disabled={props.loading} onClick={onChoosePlan} loading={isChoosing} leftIcon={didChoose ? <IconCheck/> : undefined}>
            {userHasSubscription ? "Update plan" : "Choose plan"}
          </Button>
        </Stack>
      </Paper>
    </Paper>
  );
}
