import { Title, Container, createStyles, Stack, Image, Text, Grid, useMantineTheme, Box, Paper, Space, Button } from "@mantine/core";
import HeaderTitle from "../../shared/HeaderTitle";
import EnterCountryButton from "./EnterCountryButton/EnterCountryButton";


const useStyles = createStyles((theme) => ({
  stepNumber: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 500,
    textAlign: "left",
  },
  stepTitle: {
    fontWeight: 500,
    fontSize: `calc(1.3 * ${theme.fontSizes.xl})`,
    color: theme.colors.gray[9],

    [theme.fn.smallerThan('sm')]: {
      fontSize: `calc(1.2 * ${theme.fontSizes.xl})`,
    },
  },
  stepDescription: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 600,
    color: theme.colors.gray[9]
  },
}));


function Step(props: {
  step: number, title: string, description: string,
  image: React.ReactElement, extra?: React.ReactElement, reversed?: boolean
}) {
  const theme = useMantineTheme();
  const {classes} = useStyles();
  return (
    <Grid gutter={"sm"} gutterSm={"3rem"}>
      <Grid.Col xs={6} orderXs={props.reversed ? 2 : 1} display={"flex"} sx={{alignItems: "center"}}>
        <Stack spacing={"sm"}>
          <Text className={classes.stepNumber}>{props.step}</Text>
          <Title order={2} align="left" className={classes.stepTitle}>{props.title}</Title>
          <Text fz={"lg"} color={theme.colors.gray[8]} align="left">{props.description}</Text>
          {props.extra}
        </Stack>
      </Grid.Col>
      <Grid.Col xs={6} orderXs={props.reversed ? 1 : 2} display={"flex"} sx={{alignItems: "center"}}>
        {props.image}
      </Grid.Col>
    </Grid>
  );
}


function CalculatorImage() {
  const theme = useMantineTheme();
  return (
    <Paper bg={theme.colors.teal[9]} pl={"xl"} pt={"xl"} radius={"8px"} sx={{border: "1px solid gray"}}>
      <Image src="/assets/process/calculator2.png" radius={"6px 0px 6px 0px"}/>
    </Paper>
  );
}


function FundingImage() {
  const theme = useMantineTheme();
  return (
    <Paper radius={"8px"} sx={{border: "1px solid gray"}} pos={"relative"}>
      <Image src="/assets/process/brazil.jpg" radius={"6px"}/>
      <Box sx={{bottom: 12, right: 12, borderRadius: 8, minWidth: 240, border: "1px solid gray"}} pos={"absolute"} bg={theme.colors.gray[1]} p={"sm"} w={"50%"}>
        <Text fw={"normal"} fz={"sm"}>🇧🇷 You funded <span style={{color: theme.colors.teal[6]}}>plant-based school lunches</span> in Brazil this month!</Text>
      </Box>
    </Paper>
  );
}


function UpdateImage() {
  const theme = useMantineTheme();
  return (
    <Paper bg={theme.colors.teal[9]} pl={"xl"} pt={"xl"} radius={"8px"} sx={{border: "1px solid gray"}} pos={"relative"}>
      <Image src="/assets/process/impact.png" radius={"6px 0px 6px 0px"} sx={{boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)"}}/>
    </Paper>
  );
}


export default function HowItWorks() {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();

  return (
    <Container size="md">
      <Stack align="center" spacing={"2.5rem"}>
        <HeaderTitle
          header={"For individuals"}
          title={"You can help solve the climate crisis"}
        />
        <Step
          step={1}
          title={"Calculate your carbon footprint"}
          description={"Answer questions about your lifestyle to find out how much CO₂ you emit."}
          image={<CalculatorImage/>}
          extra={
          <Stack align="left">
            <EnterCountryButton align="left"/>
            <Text fz={"xs"} c={theme.colors.gray[6]} align="left">
              🌎 We use the latest research and data from the World Bank, FAO, IPCC, and EPA.
            </Text>
          </Stack>
          }
        />
        <Step
          step={2}
          title={"Fund climate solutions"}
          description={"Sign up to offset your emissions. Every contribution makes a difference by funding a portfolio of climate solutions."}
          image={<FundingImage/>}
          extra={<Text fz={"xs"} c={theme.colors.gray[6]} align="left">🌾🌲🌴 Your offset funds sustainable meals, restoration, and conservation.</Text>}
          reversed
        />
        <Step
          step={3}
          title={"Track your impact"}
          description={"Receive monthly updates about your impact and the projects you’ve funded."}
          image={<UpdateImage/>}
          extra={<Text fz={"xs"} c={theme.colors.gray[6]} align="left">✅ Our project data and methodology are 100% transparent.</Text>}
        />
        <Space h="lg"/>
      </Stack>
    </Container>
  )
}
