import { useMantineTheme } from "@mantine/core";

import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FootprintBreakdown } from "../../../api/calculator";
import { useMediaQuery } from "@mantine/hooks";


export default function FootprintBreakdownChart(props: {
  yourBreakdown: FootprintBreakdown | null,
  countryBreakdown: FootprintBreakdown | null,
  averageLabel: string | null
}) {
  const theme = useMantineTheme();

  const makeData = (b: FootprintBreakdown | null) => {
    return [
      ["Goods", b?.goods || 0],
      ["Services", b?.services || 0],
      ["Transport", b?.transport || 0],
      ["Energy", b?.naturalGas || 0 + (b?.electricity || 0)],
      ["Diet", b?.diet || 0],
      ["Home & Pets", b?.home || 0 + (b?.pets || 0)]
    ];
  }

  const yourData = makeData(props.yourBreakdown);
  const countryData = makeData(props.countryBreakdown);

  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const options: Highcharts.Options = {
    title: {
      text: undefined
    },

    yAxis: {
      enabled: !mobile,
      title: {
        text: mobile ? null : "tons CO₂ per year",
        style: {fontWeight: "normal"}
      },
      labels: {
        enabled: false,
      }
    },

    xAxis: {
      categories: ["Goods", "Services", "Transport", "Energy", "Diet", "Home & Pets"],
    },

    tooltip: {
      format: "{point.series.name}: {point.y:.1f}"
    },

    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          color: 'black',
          style: {fontWeight: '500', textOutline: 'none', fontSize: "12px"},
          inside: true,
          format: "{point.y:.1f}"
        },
        pointPadding: 0.05,
        groupPadding: 0.05,
      }
    },

    series: [{
      type: 'column',
      name: 'You',
      color: theme.colors.orange[4],
      data: yourData
    }, {
      type: 'column',
      name: props.averageLabel ? props.averageLabel : 'Average',
      color: theme.colors.gray[3],
      data: countryData
    }],
    legend: {
      enabled: true,
    },
    chart: {
      type: 'column',
      height: 280,
      style: {
        fontFamily: "Inter,Proxima Nova, proxima-nova"
      },
      backgroundColor: "transparent",
    },
    credits: {
      enabled: false,
    }
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
}
