import { Title, Container, createStyles, Stack, Text, Image, Paper, Box, SimpleGrid, Badge, MediaQuery, Divider, Anchor } from "@mantine/core";
import { ApproachTabs } from "./ApproachTabs";
import HeaderTitle from "../../../shared/HeaderTitle";


const useStyles = createStyles((theme) => ({
  container: {
    textAlign: "center",
    color: theme.colors.gray[9],
    paddingLeft: 0,
    paddingRight: 0,
  },
  header: {
    textTransform: "uppercase",
    fontWeight: 600,
    color: theme.colors.teal[6],
    fontSize: theme.fontSizes.lg,
    letterSpacing: ".06rem",
    lineHeight: 1.5,
  },
  title: {
    fontWeight: 500,
    fontSize: `calc(2.3 * ${theme.fontSizes.xl})`,
    lineHeight: 1.5,
    color: theme.colors.gray[9],
    [theme.fn.smallerThan('sm')]: {
      fontSize: `calc(1.3 * ${theme.fontSizes.xl})`,
    },
  },
  description: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 600,
    color: theme.colors.gray[9]
  },

  subtitle: {
    fontWeight: 500,
    [theme.fn.smallerThan('sm')]: {
      fontSize: `calc(1 * ${theme.fontSizes.xl})`,
    },
  },
  body: {
    fontSize: theme.fontSizes.xl,
  },
  statStack: {
    paddingTop: theme.spacing.lg,
  },
  statNumber: {
    lineHeight: 1,
    fontWeight: 600,
    fontSize: `calc(2 * ${theme.fontSizes.xl})`,
    color: theme.colors.gray[9],

    [theme.fn.smallerThan('sm')]: {
      fontSize: `calc(1 * ${theme.fontSizes.xl})`,
    },
  },
  statText: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 500,
    lineHeight: 1.2,
    color: theme.colors.gray[9],
    textAlign: "center",

    [theme.fn.smallerThan('sm')]: {
      fontSize: theme.fontSizes.xs,
    },
  },
  cardImage: {
    img: {
      aspectRatio: "1.4",
      objectFit: "cover",
      // borderRadius: "50%",
      border: "1px solid white"
    },
    // maxWidth: 300,
  },
  readableWidth: {
    maxWidth: 900
  },
  citation: {
    fontSize: "0.7rem",
    color: theme.colors.gray[6],
    lineHeight: 1,
  },
}));


const TaggedImage = (props: { src: string, label?: string, hideOnMobile?: boolean }) => {
  const {classes} = useStyles();
  return (
    <MediaQuery smallerThan={"xs"} styles={{display: props.hideOnMobile ? "none" : undefined}}>
    <div style={{position: "relative"}}>
      <Image src={props.src} radius="sm" className={classes.cardImage}/>
      {
        props.label ?
          <Badge pos={"absolute"} bottom={8} right={8}>{props.label}</Badge>
          : undefined
      }
    </div>
    </MediaQuery>
  )
}


const FoodExplainer = () => {
  const {classes} = useStyles();
  return (
    <Paper
      shadow="lg"
      radius="md"
      p="xl"
      sx={{textAlign: "left"}}
      withBorder
    >
      <Stack align="left">
        <SimpleGrid
          breakpoints={[
            { maxWidth: "xs", cols: 1 },
            { minWidth: "xs", cols: 3 }
          ]}
        >
          <TaggedImage src="/assets/approach/alimentando.jpg" label="🇦🇷 Argentina"/>
          <TaggedImage src="/assets/approach/brazil.jpg" label="🇧🇷 Brazil" hideOnMobile/>
          <TaggedImage src="/assets/approach/catalyst.jpg" label="🇹🇭 Thailand" hideOnMobile/>
        </SimpleGrid>
        <Text className={classes.header}>
          Climate-friendly foods
        </Text>
        <Title className={classes.subtitle} order={2}>
          34% of global emissions are from food. We make it easy, healthy, and delicious to adopt climate-friendly meals.
        </Title>
        <Text className={classes.body}>
          We work with food providers, like schools and hospitals, to add more sustainable food options to their menu.
          Our funding enables them to develop new menu options, purchase equipment, train staff, subsidize the
          cost of food, and overcome other barriers to adoption.
        </Text>
        <SimpleGrid cols={3}>
          <Stack align="center" className={classes.statStack}>
            <Title className={classes.statNumber}>50-75%</Title>
            <Text className={classes.statText}>
              less emissions per meal
            </Text>
          </Stack>
          <Stack align="center" className={classes.statStack}>
            <Title className={classes.statNumber}>76%</Title>
            <Text className={classes.statText}>
              less land use per meal
            </Text>
          </Stack>
          <Stack align="center" className={classes.statStack}>
            <Title className={classes.statNumber}>100B+</Title>
            <Text className={classes.statText}>
              meals served by food providers
            </Text>
          </Stack>
        </SimpleGrid>
        <Divider/>
        <Stack spacing={"xs"}>
          <Text className={classes.citation}>
            Poore, J., & Nemecek, T. (2018). Reducing food's environmental impacts through producers and consumers. Science. Vol 360, Issue 6392.
          </Text>
        </Stack>
      </Stack>
    </Paper>
  )
}


const LandExplainer = () => {
  const {classes} = useStyles();
  return (
    <Paper
      shadow="lg"
      radius="md"
      p="xl"
      sx={{textAlign: "left"}}
      withBorder
    >
      <Stack align="left">
        <SimpleGrid breakpoints={[
            { maxWidth: "xs", cols: 1 },
            { minWidth: "xs", cols: 3 }
          ]}
        >
          <TaggedImage src="/assets/approach/reforestation3.jpeg" hideOnMobile/>
          <TaggedImage src="/assets/approach/parrots.jpeg"/>
          <TaggedImage src="/assets/approach/mangroves2.jpeg" hideOnMobile/>
        </SimpleGrid>
        <Text className={classes.header}>
          Ecosystem restoration & conservation
        </Text>
        <Title className={classes.subtitle} order={2}>
          Food is the largest driver of deforestation and biodiversity loss. Sustainable diets free up
          land so that it can be restored to its natural state.
        </Title>
        <Text className={classes.body}>
          Our projects reduce the consumption of land-intensive animal products, allowing forests,
          grasslands, and other vital ecosystems to be restored. As natural vegetation
          regrows, it removes carbon from the atmosphere. Our credits fund restoration and
          conservation projects alongside our food projects to ensure that carbon is removed and protected.
        </Text>
        <SimpleGrid cols={2}>
          <Stack align="center" className={classes.statStack}>
            <Title className={classes.statNumber}>3.1 billion</Title>
            <Text className={classes.statText}>
              hectares of land could be restored through a plant-based food system
            </Text>
          </Stack>
          <Stack align="center" className={classes.statStack}>
            <Title className={classes.statNumber}>772 gigatons</Title>
            <Text className={classes.statText}>
              of potential CO₂ removal
            </Text>
          </Stack>
        </SimpleGrid>
        <Divider/>
        <Stack spacing={"xs"}>
          <Text className={classes.citation}>
            Ritchie, H., and Roser, M. Drivers of deforestation. Our World in Data. https://ourworldindata.org/drivers-of-deforestation.
          </Text>
          <Text className={classes.citation}>
            Poore, J., & Nemecek, T. (2018). Reducing food's environmental impacts through producers and consumers. Science. Vol 360, Issue 6392.
          </Text>
          <Text className={classes.citation}>
            Hayek, M.N., Harwatt, H., Ripple, W.J. et al. The carbon opportunity cost of animal-sourced food production on land. Nature Sustainability 4, 21-24 (2021).
          </Text>
        </Stack>
      </Stack>
    </Paper>
  )
}


const SystemicExplainer = () => {
  const {classes} = useStyles();
  return (
    <Paper
      shadow="lg"
      radius="md"
      p="xl"
      sx={{textAlign: "left"}}
      withBorder
    >
      <Stack align="left">
        <SimpleGrid cols={3}>
          <Image src="/assets/approach/policy.png" radius="sm" className={classes.cardImage}/>
          <Image src="/assets/approach/farmer.jpeg" radius="sm" className={classes.cardImage}/>
          <Image src="/assets/approach/factoryfarm.jpeg" radius="sm" className={classes.cardImage}/>
        </SimpleGrid>
        <Text className={classes.header}>
          Systemic change
        </Text>
        <Title className={classes.subtitle} order={2}>
          Food is responsible for 34% of emissions, but receives 3% of climate investment.
        </Title>
        <Text className={classes.body}>
          Even if we stopped using fossil fuels tomorrow, the food system alone would cause
          1.5C of warming. Climate scientists have identified the <Anchor href="/references">massive environmental benefits</Anchor> of
          switching to a sustainable food system, but policy and awareness are still catching up.
        </Text>
        <Text className={classes.body}>
          In addition to funding climate solutions that reduce emissions immediately, our carbon credits
          support high-impact policy, research, and educational initiatives in order to create long-term change.
        </Text>
        <Divider/>
        <Stack spacing={"xs"}>
          <Text className={classes.citation}>
            Xu, X., Sharma, P., Shu, S. et al. Global greenhouse gas emissions from animal-based foods are twice those of plant-based foods. Nat Food 2, 724–732 (2021).
          </Text>
          <Text className={classes.citation}>
            Global Alliance for the Future of Food (2022). Food and farming massively underfunded in climate plans.
          </Text>
          <Text className={classes.citation}>
            Clark, M. A., et al. (2020). Global food system emissions could preclude achieving the 1.5C and 2C climate change targets. Science, 370(6517), 705–708.
          </Text>
        </Stack>
      </Stack>
    </Paper>
  )
}


const data = [
  {
    demo: FoodExplainer,
    // icon: IconPlant,
    step: "Step 1",
    name: 'Reduce food emissions',
    description: 'We work with food providers around the world to offer more healthy, climate-friendly food options.',
  },
  {
    demo: LandExplainer,
    // icon: IconChristmasTree,
    step: "Step 2",
    name: 'Restore ecosystems',
    description: 'We fund land restoration and conservation projects in areas that have been impacted by agriculture.',
  },
  {
    demo: SystemicExplainer,
    // icon: IconBuildingSkyscraper,
    step: "Step 3",
    name: 'Drive systemic change',
    description: 'We raise awareness about the pivotal role of food in the climate crisis.',
  },
];


export default function OurApproach() {
  const {classes, cx} = useStyles();

  return (
    <Container className={classes.container} fluid>
      <Stack align="center" spacing={"2rem"}>
        <Box p={"md"}>
        <HeaderTitle
          header={"Our approach"}
          title={"We're turning the food system into a climate solution"}
        />
        <MediaQuery smallerThan={"sm"} styles={{display: "none"}}>
          <Text className={classes.description}>
            We've developed the first carbon credit that reverses food's environmental impact.
          </Text>
        </MediaQuery>
        </Box>
        <ApproachTabs data={data}/>
      </Stack>
    </Container>
  )
}
