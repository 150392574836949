import { Title, Container, createStyles, Button, Stack, Text, Divider, Space, useMantineTheme, Grid, Paper, Group, Image, Box, MediaQuery, LoadingOverlay, Anchor, Alert } from "@mantine/core";

import { COUNTRIES } from "../calculator/utils/countries";
import { IconArrowLeft } from "@tabler/icons-react";
import { selectCurrentUserId, useAppSelector, useTrackUserReachedContinuePath } from "../../redux/hooks";
import { URL_WIKI_CALCULATOR, URL_WIKI_REDUCE_FOOTPRINT } from "../../utils/links";
import { useListStripeSubscriptionsQuery } from "../../api/payment";
import { useGetSubscriptionPlansQuery } from "../../api/calculator";
import { useState } from "react";
import { useGetRefreshedCalculatorResultsQuery } from "../../api/calculator";
import { useNavigate } from "react-router-dom";
import { useScrollIntoView } from "@mantine/hooks";
import ActionFAQ from "../../components/public/Action/FAQs";
import CountryComparisonChart from "../../components/public/ChoosePlan/CountryComparisonChart";
import FootprintBreakdownChart from "../../components/public/ChoosePlan/FootprintBreakdownChart";
import WaysToReduceChart from "../../components/public/ChoosePlan/WaysToReduceChart";
import UrgencySection from "../../components/public/Home/UrgencySection";
import HeaderTitle from "../../components/shared/HeaderTitle";
import Subscription, { SubscriptionToggle } from "../../components/checkout/Subscription";
import { IconAdjustmentsDollar } from "@tabler/icons-react";


const useStyles = createStyles((theme) => ({
  title: {
    color: theme.colors.gray[8],
    fontWeight: 600,
    fontFamily: theme.fontFamily,
  },
  darkSection: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
    backgroundColor: theme.colors.teal[9],
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',

    [theme.fn.smallerThan("sm")]: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    }
  },
  lightSection: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
    backgroundColor: 'white',
    [theme.fn.smallerThan("sm")]: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    }
  },
  graySection: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
    backgroundColor: theme.colors.gray[0],
    [theme.fn.smallerThan("sm")]: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    }
  },
}));


function SourceLink() {
  const theme = useMantineTheme();
  return (
    <Text color="gray" fz={"xs"} align="center">
      <Anchor
        href={URL_WIKI_CALCULATOR}
        target="_blank"
        rel="noreferrer"
        style={{color: theme.colors.gray[6], textDecoration: 'none'}}
      >
        Source
      </Anchor>
    </Text>
  );
}


function FullHeightImage(props: { src: string, maxHeight?: string | number }) {
  return (
    <Image
      src={props.src}
      height={"100%"}
      radius={"sm"}
      sx={{filter: "drop-shadow(0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.1))", maxHeight: props.maxHeight}}
      styles={{ root: {height: "100%"}, figure: {height: "100%"}, image: {border: "none"},
      imageWrapper: {height: "100%"}}}
    />
  );
}


function PlansSection() {
  const [subscriptionType, setSubscriptionType] = useState<"month" | "year">("month");

  const navigate = useNavigate();
  useTrackUserReachedContinuePath();

  const userId = useAppSelector(selectCurrentUserId) as string;
  const {data, isLoading, isFetching, isError, error} = useGetSubscriptionPlansQuery({userId});

  if (isError) {
    // @ts-ignore
    if (error.data.detail === "errors/no-calculator-results") {
      console.debug("The user tried to access the 'choose-plan' page before completing the calculator. Redirecting!")
      navigate("/calculator")
    }
  }

  const tonsPerYear = data?.userTonsPerYear || 10;
  const defaultCustomCostPerMonth = 15;
  const loading = isLoading || isFetching || !userId;

  return (
    <>
    <Stack align="center" pb="xl">
      <ExistingPlanWarning/>
      <SubscriptionToggle value={subscriptionType} onChange={setSubscriptionType}/>
    </Stack>

    <Grid gutter={"sm"} align="center">
      <Grid.Col sm={6} md={4}>
        <Subscription
          userId={userId}
          name={data?.low.name || "Climate Starter"}
          monthly={subscriptionType === "month"}
          userTonsPerYear={tonsPerYear}
          pricePerTon={data?.low.pricePerTon || 28}
          tonsPerYear={(data?.low.tonsPerYear || 5)}
          loading={loading}
          claim={data?.low.claim}
          isMostPopular={data?.low.claim === "neutral"}
        />
      </Grid.Col>
      <Grid.Col sm={6} md={4}>
        <Subscription
          userId={userId}
          name={data?.mid.name || "Carbon Neutral"}
          monthly={subscriptionType === "month"}
          userTonsPerYear={tonsPerYear}
          pricePerTon={data?.mid.pricePerTon || 28}
          tonsPerYear={(data?.mid.tonsPerYear || 10)}
          loading={loading}
          claim={data?.mid.claim}
          isMostPopular={data?.mid.claim === "neutral"}
        />
      </Grid.Col>
      <Grid.Col sm={6} md={4}>
        <Subscription
          userId={userId}
          name={data?.high.name || "Climate Hero"}
          monthly={subscriptionType === "month"}
          userTonsPerYear={tonsPerYear}
          pricePerTon={data?.high.pricePerTon || 28}
          tonsPerYear={(data?.high.tonsPerYear || 20)}
          loading={loading}
          claim={data?.high.claim}
          isMostPopular={data?.high.claim === "neutral"}
        />
      </Grid.Col>
      <MediaQuery smallerThan="md" styles={{display: "none"}}>
        <Grid.Col span={0} md={3}>
        </Grid.Col>
      </MediaQuery>
      <Grid.Col sm={6} md={6}>
        <MediaQuery smallerThan="md" styles={{display: "none"}}>
          <Divider label={<Text fw={"bold"} fz={"lg"}>Or, choose a custom amount</Text>} w={"100%"} size={"sm"} labelPosition="center"/>
        </MediaQuery>
        <Subscription
          userId={userId}
          name="Custom Plan"
          monthly={subscriptionType === "month"}
          userTonsPerYear={tonsPerYear}
          tonsPerYear={(defaultCustomCostPerMonth * 12) / (data?.custom.pricePerTon || 28)}
          pricePerTon={data?.custom.pricePerTon || 28}
          loading={loading}
          custom={true}
        />
      </Grid.Col>
      <MediaQuery smallerThan="md" styles={{display: "none"}}>
        <Grid.Col span={0} md={3}>
        </Grid.Col>
      </MediaQuery>
    </Grid>
    </>
  );
}


function ExistingPlanWarning() {
  const userId = useAppSelector(selectCurrentUserId);

  // If the user has a subscription, skip the email collection step.
  const {data} = useListStripeSubscriptionsQuery(
    { userId: userId as string }, { skip: !userId });

  const userHasSubscription = (data?.data || []).length > 0;

  return (
    <>
      {
        userHasSubscription ?
          <Alert
            title={<Text fz={"lg"}>Would you like to update your subscription?</Text>}
            icon={<IconAdjustmentsDollar/>}
            color="blue"
            maw={600}
          >
            <Text fz="lg">
              We found an existing plan linked to your account.{" "}
              If you'd like to update it, you can choose a new plan below.
            </Text>
          </Alert> : undefined
      }
    </>
  )
}


function ChoosePlanRoute() {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const navbarHeight = 0;
  const { scrollIntoView, targetRef } = useScrollIntoView({ offset: navbarHeight });

  const userId = useAppSelector(selectCurrentUserId) as string;
  const {data, isLoading, isFetching, isError, error} = useGetRefreshedCalculatorResultsQuery(userId);

  if (isError) {
    console.error(error);
    // @ts-ignore
    if (error.data.detail === "errors/no-calculator-results") {
      console.debug("The user tried to access the calculator results page before doing the calculator. Sending them back.");
      navigate("/calculator");
    }
  }

  const results = data?.results;
  const tonsPerYear = results?.userTonsPerYear?.total || 10;
  const avgTonsPerYear = results?.countryTonsPerYear?.total || 10;
  const loading = isLoading || isFetching || !userId;

  const haPerYear = results?.landUseHa || 1;
  const tennisCourts = haPerYear / 0.026087174;
  const emoji = results?.countryCode ? COUNTRIES[results?.countryCode].emoji : null;

  // https://www.theguardian.com/environment/ng-interactive/2019/jul/19/carbon-calculator-how-taking-one-flight-emits-as-much-as-many-people-do-in-a-year
  const flightsLondonToNy = tonsPerYear / 0.986;

  // https://nepis.epa.gov/Exe/ZyNET.exe/P100JPPH.TXT?ZyActionD=ZyDocument&Client=EPA&Index=2011+Thru+2015&Docs=&Query=&Time=&EndTime=&SearchMethod=1&TocRestrict=n&Toc=&TocEntry=&QField=&QFieldYear=&QFieldMonth=&QFieldDay=&IntQFieldOp=0&ExtQFieldOp=0&XmlQuery=&File=D%3A%5Czyfiles%5CIndex%20Data%5C11thru15%5CTxt%5C00000011%5CP100JPPH.txt&User=ANONYMOUS&Password=anonymous&SortMethod=h%7C-&MaximumDocuments=1&FuzzyDegree=0&ImageQuality=r75g8/r75g8/x150y150g16/i425&Display=hpfr&DefSeekPage=x&SearchBack=ZyActionL&Back=ZyActionS&BackDesc=Results%20page&MaximumPages=1&ZyEntry=1&SeekPage=x&ZyPURL
  const kgCo2PerMile = 0.411
  const drivingMiles = (tonsPerYear * 1000) / kgCo2PerMile;

  // https://www.carsguide.com.au/car-advice/whats-the-average-weight-of-a-car-89728#:~:text=The%20average%20weight%20of%20a%20car%20in%20kg%20is%20about,9500mm)%20is%20closer%20to%202000kg.
  const carWeights = tonsPerYear / 1.9;

  const worldAverageMultiples = tonsPerYear / 5.0;

  const percentDiffFromCountry = 100 * (tonsPerYear - avgTonsPerYear) / avgTonsPerYear;

  let comparison = <strong style={{color: percentDiffFromCountry > 0 ? theme.colors.orange[4] : theme.colors.teal[6]}}>{Math.abs(percentDiffFromCountry).toLocaleString("default", { maximumFractionDigits: 0 })}% {percentDiffFromCountry > 0 ? "higher" : "lower"} than</strong>;
  if (Math.abs(percentDiffFromCountry) < 1) {
    comparison = <strong style={{color: theme.colors.gray[6]}}>the same as</strong>;
  }

  const chartWidthFull = Math.min(992 - 64*2, document.body.clientWidth - 18*2);

  return (
    <>
      <Container fluid pt={"xl"} pb={"xl"} p={0}>
        <LoadingOverlay visible={loading} overlayBlur={5}/>
        <Container size="md">
          <Button variant="white" leftIcon={<IconArrowLeft size={"1rem"}/>} size="md" onClick={() => navigate("/calculator/natural-gas")}>Back to calculator</Button>
          <Stack align="center" spacing={"xl"}>
            <Divider label={<Text fw={600} fz={"lg"} color={theme.colors.gray[8]}>Your Results</Text>} w={"min(90%, 350px)"} size={"sm"} labelPosition="center"/>
            <Title className={classes.title}>
              Your carbon footprint is <strong style={{color: theme.colors.orange[4], fontWeight: 600}}>{tonsPerYear.toLocaleString("default", {maximumFractionDigits: 1})} tons CO₂</strong> per year
            </Title>
            <Space h="xl"/>
            <Text fw={600} color={theme.colors.gray[8]} fz={"xl"}>To put that number in context:</Text>
          </Stack>
          <Space h="xl"/>
          <Grid gutterSm="xl">
            <Grid.Col sm={7}>
              <Paper shadow="sm" w={"100%"} bg={"white"} p={"xl"} withBorder>
                <Text fw={600} fz={"lg"}>🌎 Your emissions are <strong>{worldAverageMultiples.toLocaleString("default", { maximumFractionDigits: 1 })}x</strong> the world average</Text>
                <CountryComparisonChart youTonsPerYear={tonsPerYear} data={results?.compareCountries || []} loading={loading}/>
                <SourceLink/>
              </Paper>
            </Grid.Col>
            <Grid.Col sm={5} display={"flex"} sx={{alignItems: "center"}}>
              <Paper bg={"white"} shadow="sm" p={"xl"} withBorder w={"100%"}>
                <Stack spacing={"xl"} align="left">
                  <Text fw={600} fz={"lg"}>
                    Your emissions are equivalent to
                  </Text>
                  <Text align="left" fz={"md"}>🗽 <strong>{flightsLondonToNy.toLocaleString("default", { maximumFractionDigits: 1 })}</strong> roundtrip flights from London to New York</Text>
                  <Text align="left" fz={"md"}>🚗 Driving <strong>{drivingMiles.toLocaleString("default", { maximumFractionDigits: 0 })}</strong> miles in a gas car</Text>
                  <Text align="left" fz={"md"}>🚌 The weight of <strong>{carWeights.toLocaleString("default", { maximumFractionDigits: 1 })}</strong> passenger cars</Text>
                  <SourceLink/>
                </Stack>
              </Paper>
            </Grid.Col>
          </Grid>

          <Space h="xl"/>

          <Grid gutterSm="xl">
            <Grid.Col sm={8} orderSm={2}>
              <Paper shadow="sm" w={"100%"} bg={"white"} p={"xl"} withBorder>
                <Text fw={600} fz={"lg"}>{emoji} Your emissions are {comparison} the national average</Text>
                <FootprintBreakdownChart
                  yourBreakdown={results?.userTonsPerYear || null}
                  countryBreakdown={results?.countryTonsPerYear || null}
                  averageLabel={emoji ? `${emoji} Average` : "Average"}
                />
                <SourceLink/>
              </Paper>
            </Grid.Col>
            <Grid.Col sm={4} display={"flex"} sx={{alignItems: "center"}} orderSm={1}>
              <Paper bg={"white"} shadow="sm" p={"xl"} withBorder>
                <Stack spacing={"xl"} align="left">
                  <Stack spacing="xs">
                    <Text fw={600} fz={"xs"} color={theme.colors.gray[6]}>Did you know?</Text>
                    <Text fw={600} fz={"lg"} p={0}>
                      The land footprint of your food is <span>{haPerYear.toLocaleString("default", { maximumFractionDigits: 1})}</span> hectares
                    </Text>
                    {/* https://en.wikipedia.org/wiki/Football_pitch */}
                    <Text fw={"normal"} color={theme.colors.gray[6]} p={0}>
                      (That's about <strong>{tennisCourts.toLocaleString("default", { maximumFractionDigits: 0 })}</strong> tennis courts)
                    </Text>
                    <Group spacing={0}>
                      {[...Array(Math.round(tennisCourts))].map((e, i) => <Box w={"32px"} key={i}>
                        <Image key={i} src="/assets/svg/tennis.svg"/>
                      </Box>
                      )}
                    </Group>
                  </Stack>
                  <SourceLink/>
                </Stack>
              </Paper>
            </Grid.Col>
          </Grid>

          <Stack align="center">
            <Space h="3rem"/>
            <Text fw={600} color={theme.colors.gray[8]} fz={"xl"}>How you can reduce your emissions:</Text>
            <Paper shadow="sm" w={"100%"} bg={"white"} p={"xl"} withBorder>
              <Stack align="center">
                <WaysToReduceChart yourFootprintTonsCo2={results?.userTonsPerYear?.total || null} width={chartWidthFull}/>
                <Text fw={500} fz={"lg"}>
                  Check out our guide on taking action to <Anchor href={URL_WIKI_REDUCE_FOOTPRINT}>reduce your emissions</Anchor>.
                </Text>
                <SourceLink/>
              </Stack>
            </Paper>
          </Stack>

          <Stack align="center" spacing={"xl"} ref={targetRef as any}>
            <Space h={"3rem"}/>
            <HeaderTitle
              title="You can help solve the climate crisis"
            />
            <Grid>
              <Grid.Col sm={6}>
                <Paper withBorder p={"xl"} radius={"md"} bg={theme.fn.gradient({from: theme.colors.gray[0], to: theme.colors.teal[0]})} shadow="md">
                  <Stack spacing={"xs"}>
                    <Image src={"/assets/svg/step1.svg"} width={24}/>
                    <Group>
                      <Text color="teal" align="left" fz={"1.6rem"} fw={600}>
                        Reduce your footprint 🚲
                      </Text>
                    </Group>
                    <Text fw={400} fz={"xl"} color={theme.colors.gray[9]}>
                      Check out our <Anchor href={URL_WIKI_REDUCE_FOOTPRINT}>guides</Anchor> on the most effective changes you can make
                    </Text>
                  </Stack>
                </Paper>
              </Grid.Col>
              <Grid.Col sm={6}>
                <Paper withBorder p={"xl"} radius={"md"} bg={theme.fn.gradient({from: theme.colors.gray[0], to: theme.colors.teal[0]})} shadow="md">
                  <Stack spacing={"xs"}>
                    <Image src={"/assets/svg/step2.svg"} width={24}/>
                    <Group>
                      <Text color="teal" align="left" fz={"1.6rem"} fw={600}>
                        Offset your remaining emissions 🌲
                      </Text>
                    </Group>
                    <Text fw={400} fz={"xl"} color={theme.colors.gray[9]}>
                      Fund high-impact solutions around the world with a <strong style={{fontWeight: 600}}>climate subscription</strong> 👇🏽
                    </Text>
                  </Stack>
                </Paper>
              </Grid.Col>
            </Grid>
            <Space h={"1rem"}/>
          </Stack>

          <PlansSection/>
        </Container>
      </Container>

      <Container className={classes.darkSection} fluid>
        <Container size="md">
          <Stack align="center" spacing={"xl"}>
            <HeaderTitle
              header="Your Impact"
              title="How does your offset make a difference?"
              color="white"
            />
          </Stack>

          <Space h="3rem"/>

          <Grid gutter="xl">
            <Grid.Col span={6} md={3}>
              <FullHeightImage src="/assets/process/alimentando4.jpg" maxHeight={360}/>
            </Grid.Col>
            <Grid.Col span={6} md={3}>
              <FullHeightImage src="/assets/process/reforestation_tall.jpeg" maxHeight={360}/>
            </Grid.Col>
            <Grid.Col md={6} display={"flex"} sx={{alignItems: "center"}}>
              <Stack align="left">
                <Text color="white" align="left" fz={"lg"} fw={"bold"}>1</Text>
                <Title order={1} align="left" color="white" fw={500}>Scale up the climate solutions we need today</Title>
                <Divider size="xs"/>
                <Text align="left" fz={"lg"} color="white">
                  Your subscription funds a portfolio of proven climate solutions with measurable results.
                  Every ton of CO₂ takes us a step closer to our climate goals.
                </Text>
              </Stack>
            </Grid.Col>
          </Grid>

          <Space h="2rem"/>

          <Grid gutter="xl" mt={"xl"}>
            <Grid.Col span={6} md={3} orderMd={2}>
              <FullHeightImage src="/assets/webp/deforestation.webp"/>
            </Grid.Col>
            <Grid.Col span={6} md={3} orderMd={2}>
              <FullHeightImage src="/assets/webp/mangroves.webp"/>
            </Grid.Col>
            <Grid.Col md={6} orderMd={1} display={"flex"} sx={{alignItems: "center"}}>
              <Stack align="left">
                <Text color="white" align="left" fz={"lg"} fw={"bold"}>2</Text>
                <Title order={1} align="left" color="white" fw={500}>Reverse ecosystem loss</Title>
                <Divider size="xs"/>
                <Text align="left" fz={"lg"} color="white">We can’t reverse ecosystem and biodiversity loss without shifting to a sustainable food system. Your offset shifts the food system <i>and</i> actively restores ecosystems to their natural state.</Text>
              </Stack>
            </Grid.Col>
          </Grid>

          <Space h="2rem"/>

          <Grid gutter="xl" mt={"xl"}>
            <Grid.Col md={6}>
              <FullHeightImage src="/assets/process/city.png" maxHeight={300}/>
            </Grid.Col>
            <Grid.Col md={6} display={"flex"} sx={{alignItems: "center"}}>
              <Stack align="left">
                <Text color="white" align="left" fz={"lg"} fw={"bold"}>3</Text>
                <Title order={1} align="left" color="white" fw={500}>Help start a movement</Title>
                <Divider size="xs"/>
                <Text align="left" fz={"lg"} color="white">Your funding helps us spread the word to even more people and companies.</Text>
              </Stack>
            </Grid.Col>
          </Grid>

          <Stack align="center" mt={"3rem"}>
            <Button variant="white" size="md" onClick={() => scrollIntoView()}>Take action today</Button>
          </Stack>
        </Container>
      </Container>

      <ActionFAQ/>

      <Space h="3rem"/>

      <UrgencySection onTakeAction={scrollIntoView}/>
    </>
  );
}

export default ChoosePlanRoute;
