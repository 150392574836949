import { Title, Container, createStyles, Stack, Image, Divider, Text, Grid, Paper, useMantineTheme, Card, SimpleGrid, Header, Anchor, Space, MediaQuery } from "@mantine/core";
import HeaderTitle from "../../shared/HeaderTitle";
import { useMediaQuery } from "@mantine/hooks";


const useStyles = createStyles((theme) => ({
  subtitle: {
    fontWeight: 700,
    fontSize: "1.5rem",
    // color: "white",
    width: "100%",
    zIndex: 2,

    [theme.fn.smallerThan('sm')]: {
      fontSize: `calc(1 * ${theme.fontSizes.xl})`,
    },
  },
  number: {
  },
  image: {
    img: {
      aspectRatio: "1.5",
      borderRadius: theme.radius.sm,
    },
  },
  citation: {
    fontSize: 10,
    p: {
      margin: 0,
      padding: "0.25rem"
    }
  },
  white: {
    color: 'white',
  },

  statValue: {
    lineHeight: 1,
    fontWeight: 600,
    textAlign: "center",
    fontSize: `calc(2 * ${theme.fontSizes.xl})`,
    color: "white",

    [theme.fn.smallerThan('sm')]: {
      fontSize: `calc(1.4 * ${theme.fontSizes.xl})`,
      textAlign: 'left'
    },
  },
  statLabel: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 600,
    lineHeight: 1.2,
    color: "white",
    textAlign: "center",

    [theme.fn.smallerThan('sm')]: {
      // fontSize: theme.fontSizes.sm,
      textAlign: 'left'
    },
  },
  statExtra: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 400,
    lineHeight: 1.2,
    color: "white",
    textAlign: "center",

    [theme.fn.smallerThan('sm')]: {
      textAlign: 'left'
    },
  },
  statSource: {
    textAlign: "center",
    marginLeft: "auto",
    [theme.fn.smallerThan('sm')]: {
      textAlign: 'left'
    },
  },
  bigPictureTitle: {
    // fontFamily: `Inter,Proxima Nova, proxima-nova, ${theme.fontFamily}`,
    [theme.fn.smallerThan("xs")]: {
      fontSize: "1.5rem"
    }
  },
}));


interface StatProps {
  value: string
  label: string
  detail?: string
  source?: string
  img?: React.ReactNode
}


function Stat({value, label, detail, source, img} : StatProps) {
  const {classes} = useStyles();
  const isMobile = useMediaQuery('(max-width: 48em)');

  return (
    <Stack spacing={"xs"} pb={"sm"} align={isMobile ? "left" : "center"}>
      {img}
      <Space h="lg"/>
      <Title className={classes.statValue}>
        {value}
      </Title>
      <Text className={classes.statLabel}>
        {label}
      </Text>
      {
        detail ?
        <Text className={classes.statExtra}>
          {detail}
        </Text> : undefined
      }
      {
        source ? <Anchor href={source} color="white" size={"xs"}>[Source]</Anchor> : undefined
      }
    </Stack>
  );
}


export function PotentialSection() {
  const {classes, cx} = useStyles();
  return (
    <Container size="xl">
    <Grid>
      <Grid.Col lg={6} sx={{display: "flex", alignItems: "center"}}>
        <Stack>
          <HeaderTitle header="The Potential Impact" align="left"/>
          <Title lh={1.5} className={classes.bigPictureTitle}>
            Together, the emission reductions and carbon removals from adopting a plant-based food system would effectively pause global warming for 30 years.
          </Title>
          <Text className={cx(classes.citation)}>
            Eisen, Michael B., and Patrick O. Brown. "Rapid global phaseout of animal agriculture has the potential to stabilize greenhouse gas levels for 30 years and offset 68 percent of CO₂ emissions this century." PLoS Climate 1.2 (2022).
          </Text>
        </Stack>
      </Grid.Col>
      <Grid.Col lg={6}>
        <Image src="/assets/svg/rf.svg" radius="sm" maw={700}/>
      </Grid.Col>
    </Grid>
    </Container>
  )
}


export default function ImpactSection(props: { button?: React.ReactElement }) {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();

  return (
    <Container size="xl">
      <Stack align="center" spacing="2rem">
        <HeaderTitle
          color="white"
          header={"The Solution"}
          title={"Shifting to a plant-based food system is one of our best tools to fight climate change."}
        />
        <MediaQuery smallerThan={"xs"} styles={{display: "none"}}>
          <Space h="lg"/>
        </MediaQuery>
        <MediaQuery largerThan={"xs"} styles={{display: "none"}}>
          <Divider color="white" w={"100%"}/>
        </MediaQuery>
        <SimpleGrid breakpoints={[
          { minWidth: "xs", cols: 1 },
          { minWidth: "md", cols: 3 }
        ]} spacing={"xl"}>
          <Stat
            value={"6.6 billion"}
            label={"tons CO₂ reduction in food emissions"}
            detail={"(A 49% reduction)"}
            source={"https://www.science.org/doi/10.1126/science.aaq0216"}
            img={<Image className={classes.image} src="/assets/approach/crops.jpg"/>}
          />
          <Stat
            value={"3.1 billion"}
            label={"hectare reduction in land use"}
            detail={"(An area the size of Africa)"}
            source={"https://www.science.org/doi/10.1126/science.aaq0216"}
            img={<Image className={classes.image} src="/assets/approach/forest_with_crops.jpeg"/>}
          />
          <Stat
            value={"772 billion"}
            label={"tons CO₂ removal from recovering natural vegetation"}
            source={"https://www.nature.com/articles/s41893-020-00603-4"}
            img={<Image className={classes.image} src="/assets/approach/reforestation3.jpeg"/>}
          />
        </SimpleGrid>
        <MediaQuery largerThan={"xs"} styles={{display: "none"}}>
          <Divider color="white" w={"100%"}/>
        </MediaQuery>
      </Stack>
    </Container>
  )
}
