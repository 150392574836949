import { Outlet, useLocation } from 'react-router-dom';
import { Container, useMantineTheme } from '@mantine/core';

import TopHeader from '../../components/shared/TopHeader/TopHeader';
import { Footer } from '../../components/public/Footer/Footer';


function PublicShell() {
  const location = useLocation();
  const theme = useMantineTheme();

  const defaultBackgroundColors: {[k: string]: string} = {
    "/home": "transparent",
    "/our-approach": "white",
    "/take-action": "transparent",
    "/blog": "transparent",
    "/references": "transparent",
  };

  const defaultColors: {[k: string]: string} = {
    "/home": "white",
    "/references": "white",
    "/take-action": "white",
  }

  const defaultBackgroundColor = (location.pathname in defaultBackgroundColors) ? defaultBackgroundColors[location.pathname] : "white";
  const defaultColor = (location.pathname in defaultColors) ? defaultColors[location.pathname] : theme.colors.gray[9];

  const becomeStickyOnScroll = [
    "/home",
    "/about-us",
    "/our-approach"
  ].includes(location.pathname);

  return (
    <div>
      <TopHeader
        defaultTextColor={defaultColor}
        defaultLogoColor={defaultColor}
        defaultPosition={defaultBackgroundColor === "transparent" ? "absolute" : "relative"}
        becomeStickyOnScroll={becomeStickyOnScroll}
        defaultBackgroundColor={defaultBackgroundColor}
      />
      <Container fluid style={{paddingLeft: 0, paddingRight: 0}}>
        <Outlet/>
      </Container>
      <Footer/>
    </div>
  );
}

export default PublicShell;
