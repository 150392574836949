import { useGetCalculatorInputsQuery, useUpdateCalculatorInputsMutation } from "../../../api/calculator";
import MultipleChoice, { IChoiceData } from "../../../components/calculator/MultipleChoice";
import { selectCurrentUserId, useAppSelector } from "../../../redux/hooks";


export default function SelectTransportModes() {
  const userId = useAppSelector(selectCurrentUserId) as string;

  const {data} = useGetCalculatorInputsQuery(userId);
  const [updateCalculator] = useUpdateCalculatorInputsMutation();

  const inputs = data?.inputs;

  const setUserValue = (
    key: "usesBus" | "usesTransitRail" | "usesInterCityRail" | "usesWalkOrBike",
    value: boolean
  ) => {
    const inputs = {[key]: value};
    updateCalculator({userId, inputs});
  };

  const choices: IChoiceData[] = [
    { value: "bus", label: "Bus", selected: !!inputs?.usesBus, onClick: (d: IChoiceData) => setUserValue("usesBus", !d.selected) },
    { value: "transit-rail", label: "Transit rail", selected: !!inputs?.usesTransitRail, onClick: (d: IChoiceData) => setUserValue("usesTransitRail", !d.selected) },
    { value: "inter-city-rail", label: "Inter-city rail", selected: !!inputs?.usesInterCityRail, onClick: (d: IChoiceData) => setUserValue("usesInterCityRail", !d.selected) },
    { value: "uses-walk-or-bike", label: "Walk or bike", selected: !!inputs?.usesWalkOrBike, onClick: (d: IChoiceData) => setUserValue("usesWalkOrBike", !d.selected) },
  ];

  return (
    <MultipleChoice
      choices={choices}
    />
  );
}
