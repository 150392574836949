import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const waitlistGuid = '5e179eca-0e01-4a30-aa42-f2d5984ea5c3';
const mailingGuid = 'b670c9d4-38f5-413e-9986-a133b495a47a';
const companyGuid = 'ffe05a05-26fe-4cab-914f-08c74b3984f6';
const portalId = '22511660';


export const hubspotApi = createApi({
  reducerPath: 'hubspotApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.hsforms.com' }),
  endpoints: (builder) => ({
    submitCreditWaitlistForm: builder.mutation<any, { email: string, pageUri: string, pageName: string }>({
      query: (body) => ({
        url: `/submissions/v3/integration/submit/${portalId}/${waitlistGuid}`,
        method: 'POST',
        body: {
          "submittedAt": new Date().valueOf(),
          "fields": [
            {
              "objectTypeId": "0-1",
              "name": "email",
              "value": body.email
            }
          ],
          "context": {
            "pageUri": body.pageUri,
            "pageName": body.pageName,
          }
        }
      }),
    }),
    submitMailingListForm: builder.mutation<any, { firstName: string, email: string, pageUri: string, pageName: string }>({
      query: (body) => ({
        url: `/submissions/v3/integration/submit/${portalId}/${mailingGuid}`,
        method: 'POST',
        body: {
          "submittedAt": new Date().valueOf(),
          "fields": [
            {
              "objectTypeId": "0-1",
              "name": "email",
              "value": body.email
            }
          ],
          "context": {
            "pageUri": body.pageUri,
            "pageName": body.pageName,
          }
        }
      }),
    }),
    submitCompanyInterestForm: builder.mutation<any, { firstName: string, lastName: string, email: string, organization: string }>({
      query: (body) => ({
        url: `/submissions/v3/integration/submit/${portalId}/${companyGuid}`,
        method: 'POST',
        body: {
          "submittedAt": new Date().valueOf(),
          "fields": [
            {
              "objectTypeId": "0-1",
              "name": "email",
              "value": body.email
            },
            {
              "objectTypeId": "0-1",
              "name": "firstName",
              "value": body.email
            },
            {
              "objectTypeId": "0-1",
              "name": "lastName",
              "value": body.lastName
            },
            {
              "objectTypeId": "0-1",
              "name": "company",
              "value": body.organization
            }
          ]
        }
      }),
    }),
  }),
})


export const {
  useSubmitCreditWaitlistFormMutation,
  useSubmitMailingListFormMutation,
  useSubmitCompanyInterestFormMutation
} = hubspotApi
