import { useGetCalculatorInputsQuery, useUpdateCalculatorInputsMutation } from "../../../api/calculator";
import CustomNumberInput from "../../../components/calculator/CustomNumberInput";
import MultipleChoice, { IChoiceData, isCustomNumericOptionSelected } from "../../../components/calculator/MultipleChoice";
import { selectCurrentUserId, useAppSelector } from "../../../redux/hooks";
import { almostEqual } from "../../../utils/helpers";
import { convertDistanceUnits, getUserDistanceUnit } from "../utils/units";


export default function SelectTransportModeDistance(
  props: { inputKey: "busMiPerWeek" | "transitRailMiPerWeek" | "interCityRailMiPerYear", goToNext?: () => void }
) {
  const userId = useAppSelector(selectCurrentUserId) as string;

  const {data} = useGetCalculatorInputsQuery(userId);
  const [updateCalculator] = useUpdateCalculatorInputsMutation();

  const inputs = data?.inputs;
  const storedDistUnits = "miles";
  const userDistUnits = getUserDistanceUnit(inputs?.countryCode);

  const storedValue = inputs ? inputs[props.inputKey] : null;
  const userValue = storedValue ? convertDistanceUnits(storedValue, storedDistUnits, userDistUnits) : null;

  const setUserValue = (value: number | string | null) => {
    if (value === null) return;
    const convertedValue = convertDistanceUnits(value as number, userDistUnits, storedDistUnits);
    updateCalculator({userId, inputs: { [props.inputKey]: convertedValue as number }});
  };

  const sharedOnClick = (data: IChoiceData) => {
    setUserValue(data.value);

    if (props.goToNext) {
      props.goToNext();
    }
  }

  const units = {
    busMiPerWeek: `${userDistUnits}/week`,
    transitRailMiPerWeek: `${userDistUnits}/week`,
    interCityRailMiPerYear: `${userDistUnits}/year`
  }[props.inputKey];

  const busLow = convertDistanceUnits(10, storedDistUnits, userDistUnits);
  const busMid = convertDistanceUnits(20, storedDistUnits, userDistUnits);
  const busHigh = convertDistanceUnits(50, storedDistUnits, userDistUnits);

  const transitLow = convertDistanceUnits(10, storedDistUnits, userDistUnits);
  const transitMid = convertDistanceUnits(30, storedDistUnits, userDistUnits);
  const transitHigh = convertDistanceUnits(50, storedDistUnits, userDistUnits);

  const interLow = convertDistanceUnits(200, storedDistUnits, userDistUnits);
  const interMid = convertDistanceUnits(1000, storedDistUnits, userDistUnits);
  const interHigh = convertDistanceUnits(2000, storedDistUnits, userDistUnits);

  const choices: IChoiceData[] =
  {
    busMiPerWeek: [
      {
        value: busLow,
        label: "Rarely",
        detail: `${busLow.toLocaleString("default", { maximumFractionDigits: 0 })} ${units}`,
        selected: almostEqual(busLow, userValue, 0.1),
        onClick: sharedOnClick
      },
      {
        value: busMid,
        label: "Daily commute",
        detail: `${busMid.toLocaleString("default", { maximumFractionDigits: 0 })} ${units}`,
        selected: almostEqual(busMid, userValue, 0.1),
        onClick: sharedOnClick
      },
      {
        value: busHigh,
        label: "Often",
        detail: `${busHigh.toLocaleString("default", { maximumFractionDigits: 0 })} ${units}`,
        selected: almostEqual(busHigh, userValue, 0.1),
        onClick: sharedOnClick
      },
    ],
    transitRailMiPerWeek: [
      {
        value: transitLow,
        label: "Rarely",
        detail: `${transitLow.toLocaleString("default", { maximumFractionDigits: 0 })} ${units}`,
        selected: almostEqual(transitLow, userValue, 0.1),
        onClick: sharedOnClick
      },
      {
        value: transitMid,
        label: "Daily commute",
        detail: `${transitMid.toLocaleString("default", { maximumFractionDigits: 0 })} ${units}`,
        selected: almostEqual(transitMid, userValue, 0.1),
        onClick: sharedOnClick
      },
      {
        value: transitHigh,
        label: "Often",
        detail: `${transitHigh.toLocaleString("default", { maximumFractionDigits: 0 })} ${units}`,
        selected: almostEqual(transitHigh, userValue, 0.1),
        onClick: sharedOnClick
      },
    ],
    interCityRailMiPerYear: [
      {
        value: interLow,
        label: "A few short trips each year",
        detail: `${interLow.toLocaleString("default", { maximumFractionDigits: 0 })} ${units}`,
        selected: almostEqual(interLow, userValue, 0.1),
        onClick: sharedOnClick
      },
      {
        value: interMid,
        label: "Between cities often",
        detail: `${interMid.toLocaleString("default", { maximumFractionDigits: 0 })} ${units}`,
        selected: almostEqual(interMid, userValue, 0.1),
        onClick: sharedOnClick
      },
      {
        value: interHigh,
        label: "Many long trips each year",
        detail: `${interHigh.toLocaleString("default", { maximumFractionDigits: 0 })} ${units}`,
        selected: almostEqual(interHigh, userValue, 0.1),
        onClick: sharedOnClick
      },
    ]
  }[props.inputKey];

  const customIsSelected = isCustomNumericOptionSelected(choices, userValue, 0.1);

  const customInput =
    <CustomNumberInput
      onChange={setUserValue}
      units={units}
      initialValue={customIsSelected ? userValue : null}
      selected={customIsSelected}
      min={0}
      max={10000}
    />;

  return (
    <MultipleChoice
      choices={choices}
      customInputElement={customInput}
    />
  );
}
