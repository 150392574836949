import {
  createStyles,
  Title,
  Container,
  Accordion,
  Text,
  rem,
  Divider,
} from '@mantine/core';


const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: "3rem",
    paddingBottom: "3rem",
    color: theme.black,
  },

  title: {
    color: theme.colors.gray[9],
    fontWeight: 500,
    marginBottom: `calc(${theme.spacing.xl} * 2)`,
  },

  item: {
    border: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    borderRadius: theme.radius.md,
    overflow: 'hidden',
  },

  label: {
    fontSize: theme.fontSizes.md,
    fontWeight: 600,
    color: theme.black,
  },

  content: {
    paddingLeft: rem(4),
    lineHeight: 1.6,
    color: theme.colors.gray[8],
  },
  button: {
    display: 'block',
    marginTop: theme.spacing.md,

    [theme.fn.smallerThan('sm')]: {
      display: 'block',
      width: '100%',
    },
  },
}));


export default function ActionFAQ() {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <Container size="sm">
        <Title align="center" className={classes.title}>
          Frequently Asked Questions
        </Title>

        <Accordion
          chevronPosition="right"
          defaultValue="reset-password"
          chevronSize={50}
          variant="separated"
          // w={'100%'}
        >
          {/* --- */}
          <Accordion.Item className={classes.item} value="what-happens-when">
            <Accordion.Control>
              <Text className={classes.label}>How is my funding used?</Text>
            </Accordion.Control>
            <Accordion.Panel>
              <Text className={classes.content}>
                When you start a subscription or make a one-time carbon credit purchase, <strong>75%</strong> goes
                to directly to high-impact projects, and <strong>25%</strong> is reinvested in Climate Refarm to help us scale
                our impact. Our main expenditures are team salaries, up-front funding for new projects, and marketing.
                We're committed to full transparency, and will publish a yearly report on how our funding is spent.
              </Text>
            </Accordion.Panel>
          </Accordion.Item>

          {/* --- */}
          <Accordion.Item className={classes.item} value="where-does-funding-go">
            <Accordion.Control>
              <Text className={classes.label}>How do you monitor project impact?</Text>
            </Accordion.Control>
            <Accordion.Panel>
              <Text className={classes.content}>
                For sustainable food projects, we use food purchasing records to verify that there
                has been a shift from animal products to plant-based
                foods, and that the transition is going smoothly (e.g, no increase in food waste). We
                validate these food purchasing records against several other sources of project data.
                Once our project registry is up and running, you'll be able to see monitoring reports
                for each of our projects.
              </Text>
              <Divider m={"sm"}/>
              <Text className={classes.content}>
                When selecting conservation and restoration projects, we require that partner organizations
                provide (1) third-party verification of project impact, (2) transparent project documentation,
                and (3) frequent updates on project activities.
              </Text>
            </Accordion.Panel>
          </Accordion.Item>
          {/* --- */}
          <Accordion.Item className={classes.item} value="are-your-credits-verified">
            <Accordion.Control>
              <Text className={classes.label}>Are your projects verified by a third party?</Text>
            </Accordion.Control>
            <Accordion.Panel>
              <Text className={classes.content}>
                Conservation and restoration projects <i>are</i> verified by a third-party based on the best available standards for nature-based projects.
                Verification of our sustainable food projects is currently awaiting the development of Verra's <a href="https://verra.org/methodologies/methodology-for-climate-friendly-foodservice/" target="_blank" rel="noreferrer">Methodology for Climate-Friendly Foodservice</a>.
                Due to the urgent need to reduce food emissions, and the long timeline associated with developing new
                carbon crediting methodologies, Climate Refarm has decided to begin funding sustainable food projects
                before this methodology is released.
              </Text>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Container>
    </div>
  );
}
