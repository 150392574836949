import { Button, createStyles, keyframes, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";


const blink = keyframes({
  "50%": {
    borderColor: "#CED4DA"
  }
});

const useStyles = createStyles((theme) => ({
  button: {
    paddingLeft: "1rem",
    display: "flex",
    border: `2px solid ${theme.colors.gray[4]}`,
    transitionDuration: ".2s",

    color: theme.colors.gray[9],
    fontWeight: 500,

    "&:hover": {
      backgroundColor: theme.colors.gray[0]
    }
  },

  buttonLabel: {
    width: "100%",
  },

  buttonInner: {
    width: "100%"
  },

  selected: {
    // transitionDuration: ".2s",
    borderColor: theme.colors.teal[6],
    backgroundColor: theme.fn.rgba(theme.colors.teal[6], 0.15),
    "&:hover": {
      backgroundColor: theme.fn.rgba(theme.colors.teal[6], 0.25)
    },
    animation: `${blink} 200ms ease-in-out 1`,
  },
}));


const CalculatorButton = (props: {
  children: any, selected: boolean, onClick?: () => void
}) => {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <Button
      variant='outline'
      size={"lg"}
      radius="md"
      className={cx(classes.button, props.selected ? classes.selected : "")}
      onClick={props.onClick}
      classNames={{label: classes.buttonLabel, inner: classes.buttonInner}}
    >
      {props.children}
    </Button>
  )
}

export default CalculatorButton;
