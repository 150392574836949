import { Title, Container, createStyles, Stack, BackgroundImage, Badge } from "@mantine/core";


const useStyles = createStyles((theme) => ({
  topSection: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10vh',
    paddingBottom: '20vh',
    height: 'calc(50vh)'
  },
}));


function BlogRoute() {
  const { classes } = useStyles();

  return (
    <>
      <BackgroundImage src={"/assets/darkened/forest2.jpg"} component="div" className={classes.topSection}>
        <Container size="sm">
          <Stack align="center" pt={"xl"}>
            <Badge color="teal">Coming soon</Badge>
            <Title color="white" align="center" sx={{fontSize: 'max(4vw, 2.5rem)'}}>The Climate Refarm Blog</Title>
            <Title color="white" align="center" order={2} sx={{maxWidth: 600}}>Data-driven writing about climate change, food systems, and what we can do to help.</Title>
          </Stack>
        </Container>
      </BackgroundImage>
    </>
  );
}

export default BlogRoute;
