import { Button, Select, SelectItemProps, Text, Transition, createStyles, useMantineTheme } from "@mantine/core";
import { forwardRef, useState } from "react";
import { COUNTRIES } from "../../../../routes/calculator/utils/countries";
import { selectCurrentUserId, useAppSelector } from "../../../../redux/hooks";
import { useGetCalculatorInputsQuery, useUpdateCalculatorInputsMutation } from "../../../../api/calculator";
import { IconArrowRight } from "@tabler/icons-react";
import { ENABLE_CALCULATOR } from "../../../../utils/flags";
import { useNavigateTop } from "../../../../utils/hooks";
import ArrowButton from "../../../shared/ArrowButton/ArrowButton";


const useStyles = createStyles((theme) => ({
  selectedOption: {
    input: {
      borderColor: theme.colors.teal[6],
    }
  },

  // Need to target the input element for the dropdown.
  countryDropdown: {
    input: {
      border: `2px solid ${theme.colors.gray[4]}`,
      fontWeight: 400,
      paddingLeft: "1rem"
    }
  },

  loading: {
  }
}));


interface ItemProps extends SelectItemProps {
  emoji: string
  countryName: string
}


const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ value, countryName, emoji, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Text>{emoji} {countryName}</Text>
    </div>
  )
);


export default function EnterCountryButton(props: { align?: "center" | "left", variant?: string }) {
  const {classes, cx} = useStyles();
  const navigate = useNavigateTop();
  const theme = useMantineTheme();

  const userId = useAppSelector(selectCurrentUserId) as string;

  const [loading, setLoading] = useState(false);
  const {data, isLoading, isFetching} = useGetCalculatorInputsQuery(userId, { skip: !userId });
  const [updateCalculator] = useUpdateCalculatorInputsMutation();

  const inputs = data?.inputs;
  const countryCode = inputs?.countryCode || null;

  const onTakeAction = () => {
    navigate("/continue");
  }

  // Populate country options with emojis.
  const dropdownData = Object.entries(COUNTRIES)
    .map(([code, country]) => (
      {
        value: code,
        label: `${country.emoji} ${country.countryName}`,
        countryName: country.countryName,
        emoji: country.emoji
      }
    ));

  const loadingData = isLoading || isFetching || !userId;
  const didSelectCountry = countryCode !== null;
  const customValue = countryCode;
  const emoji = countryCode ? COUNTRIES[countryCode].emoji : null;

  const setCountry = (countryName: string, countryCode: string) => {
    // Skip updates that don't change the value.
    if (countryCode === inputs?.countryCode) {
      return;
    }
    setLoading(true);
    updateCalculator({ userId, inputs: { country: countryName, countryCode }});

    setTimeout(() => {
      setLoading(false);
      onTakeAction();
    }, 500);
  }

  // Either (1) the user selected a country already or (2) the calculator is disabled.
  const showTakeActionButton = (!loadingData && didSelectCountry) || (!ENABLE_CALCULATOR);

  return (
    <Transition mounted={!loadingData} transition={"fade"}>
      {
      (styles) => <div style={{...styles, marginRight: props.align === "left" ? "auto" : undefined}}>
        {
          showTakeActionButton ?
            <ArrowButton
              size="lg"
              mr={props.align === "left" ? "auto" : undefined}
              sx={{zIndex: 2}}
              variant={props.variant || "gradient"}
              leftIcon={ENABLE_CALCULATOR && emoji ? emoji : undefined}
              onClick={onTakeAction}
              loading={loading}
            >
              <span>{didSelectCountry ? "Continue" : "Get started"}</span>
            </ArrowButton> :
            <Select
              searchable
              clearable
              disabled={loading}
              value={customValue}
              placeholder="🌎 Choose your country"
              className={cx(classes.countryDropdown, loading ? loading : undefined)}
              size='lg'
              color={theme.colors.gray[9]}
              data={dropdownData}
              itemComponent={AutoCompleteItem}
              mr={props.align === "left" ? "auto" : undefined}
              sx={{minWidth: 300}}
              zIndex={2}
              styles={{wrapper: { zIndex: 2 }}}
              onChange={(code) => {
                if (!code) {
                  return;
                }
                if (!COUNTRIES[code]) {
                  console.error("Invalid country code:", code);
                  return;
                }
                setCountry(COUNTRIES[code].countryName, code);
              }}
            />
        }
      </div>
      }
    </Transition>
  );
}
