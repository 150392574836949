import { selectCurrentUserId, useAppSelector } from '../../redux/hooks';
import { useGetContinuePathQuery } from '../../api/tracking';
import { Navigate } from 'react-router-dom';
import { ENABLE_CALCULATOR } from '../../utils/flags';
import { Container, LoadingOverlay } from '@mantine/core';
import { useEffect, useState } from 'react';


export default function ContinueRoute() {
  const userId = useAppSelector(selectCurrentUserId);
  const {data, isLoading, isFetching} = useGetContinuePathQuery({ userId: userId as string }, { skip: !userId });
  const [doneWithDelay, setDoneWithDelay] = useState(false);

  // See if there's a URL that the user can resume their progress at.
  const continuePath = data?.pathname || null;
  const defaultContinuePath = ENABLE_CALCULATOR ? "/calculator" : "/take-action";

  useEffect(() => {
    setTimeout(() => setDoneWithDelay(true), 400);
  }, []);

  // The "replace" means that this temporary /continue path will be replaced in
  // the history by whatever we navigate to. That way, clicking the back button
  // doesn't take the user to /continue.
  if (isLoading || isFetching || !doneWithDelay) {
    return (
      <Container h={"calc(100vh - 60px)"}>
        <LoadingOverlay visible/>
      </Container>
    )
  } else {
    return <Navigate to={continuePath || defaultContinuePath} replace/>;
  }
}
