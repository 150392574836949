import { useGetCalculatorInputsQuery, useUpdateCalculatorInputsMutation } from "../../../api/calculator";
import MultipleChoice, { IChoiceData } from "../../../components/calculator/MultipleChoice";
import { selectCurrentUserId, useAppSelector } from "../../../redux/hooks";


export default function SelectClimateRoles() {
  const userId = useAppSelector(selectCurrentUserId) as string;

  const {data} = useGetCalculatorInputsQuery(userId);
  const [updateCalculator] = useUpdateCalculatorInputsMutation();

  const inputs = data?.inputs;

  const setUserValue = (
    key: string,
    value: boolean
  ) => {
    const inputs = {[key]: value};
    updateCalculator({userId, inputs});
  };

  const choices = [
    { value: "role-educating-others", label: "Educating others", selected: !!inputs?.userRoleEducating, onClick: (d: IChoiceData) => setUserValue("userRoleEducating", !d.selected) },
    { value: "role-reducing-my-emissions", label: "Reducing my emissions", selected: !!inputs?.userRoleReducingEmissions, onClick: (d: IChoiceData) => setUserValue("userRoleReducingEmissions", !d.selected) },
    { value: "role-voting", label: "Voting", selected: !!inputs?.userRoleVoting, onClick: (d: IChoiceData) => setUserValue("userRoleVoting", !d.selected) },
    { value: "role-working", label: "Working in climate", selected: !!inputs?.userRoleWorking, onClick: (d: IChoiceData) => setUserValue("userRoleWorking", !d.selected) },
    { value: "role-donating", label: "Donating", selected: !!inputs?.userRoleDonating, onClick: (d: IChoiceData) => setUserValue("userRoleDonating", !d.selected) },
    { value: "role-not-sure", label: "Not sure", selected: !!inputs?.userRoleNotSure, onClick: (d: IChoiceData) => setUserValue("userRoleNotSure", !d.selected) },
  ];

  return (
    <MultipleChoice
      choices={choices}
    />
  );
}
