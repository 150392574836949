import { Anchor, Blockquote, Button, Container, createStyles, Divider, Stack, Text, useMantineTheme } from "@mantine/core";
import HeaderTitle from "../../shared/HeaderTitle";
import { useNavigateTop } from "../../../utils/hooks";


const useStyles = createStyles((theme) => ({
  button: {
    width: 230,

    [theme.fn.smallerThan('sm')]: {
      marginRight: "auto"
    },
  }
}));


export default function ImpactTeaser(props: { button?: React.ReactElement }) {
  const {classes, cx} = useStyles();
  const navigate = useNavigateTop();
  const theme = useMantineTheme();

  return (
  <Container size="md">
    <Stack align="center">
      <HeaderTitle
        color="white"
        header={"The Potential Impact"}
        title={"A plant-based food system is one of our best tools to fight climate change."}
      />
      <Divider w="100%" color="white"/>
      {/* --- */}
      <Blockquote
        cite={
          <Anchor href="https://eatforum.org/eat-lancet-commission/eat-lancet-commission-summary-report/" color="white">
            – The EAT-Lancet Commission
          </Anchor>}
        color="light"
      >
        <Text color="white" fz={"xl"}>
          Food is the single strongest lever to optimize human health and environmental sustainability on Earth.
        </Text>
      </Blockquote>
      {/* --- */}
      <Blockquote cite={
        <Anchor href="https://www.science.org/doi/10.1126/science.aaq0216" color="white">
          – Joseph Poore, University of Oxford
        </Anchor>
      }
        color="light"
      >
        <Text color="white" fz={"xl"}>
        Moving from current diets to a diet that excludes animal products has transformative potential,
        reducing food’s land use by 3.1 billion hectares and food’s GHG emissions by 6.6 billion metric tons of CO₂-eq.
        </Text>
      </Blockquote>
      {/* --- */}
      <Blockquote cite={
        <Anchor href="https://journals.plos.org/climate/article?id=10.1371/journal.pclm.0000010" color="white">
          – Dr. Michael B. Eisen, UC Berkeley
        </Anchor>
      }
        color="light"
      >
        <Text color="white" fz={"xl"}>
          A rapid global phaseout of animal agriculture has the potential to stabilize greenhouse gas
          levels for 30 years, offset 68 percent of CO₂ emissions this century, ... and achieve
          half of the emission reductions needed to meet Paris Agreement GHG targets.
        </Text>
      </Blockquote>
      {/* --- */}
      <Button
        variant="white"
        size="md"
        onClick={() => navigate("/our-approach") }
        className={classes.button}
      >
        Learn about the science
      </Button>
    </Stack>
  </Container>
  )
}
