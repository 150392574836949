import React, { useState, useRef } from 'react';
import { Container, Grid, UnstyledButton, Text, Box, rem, MediaQuery, Stack, Group } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { IconForms } from '@tabler/icons-react';
import { useMediaQuery } from '@mantine/hooks';
import useStyles from './TabSection.styles';


interface TabSectionProps {
  orientation?: "horizontal" | "vertical"
  tabSizeMobile?: number
  tabSize?: number
  backgroundColor?: string
  colorScheme?: "light" | "dark"

  data: {
    demo: React.FC<any>;
    header?: string;
    icon?: typeof IconForms;
    name: string;
    description?: string;
  }[];
}


export function TabSection({
  data,
  orientation = "vertical",
  tabSizeMobile = 120,
  tabSize = 200,
  backgroundColor,
  colorScheme = "light",
}: TabSectionProps) {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { classes, theme } = useStyles({ shouldAnimate, orientation, tabSize, tabSizeMobile, backgroundColor, colorScheme });
  const animationTimeout = useRef<number>();
  const [active, setActive] = useState(0);

  const controlSize = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`) ? tabSizeMobile : tabSize;

  const handleActiveChange = (index: number) => {
    setActive(index);
    if (index !== active) {
      setShouldAnimate(true);
      window.clearTimeout(animationTimeout.current);
      animationTimeout.current = window.setTimeout(() => setShouldAnimate(false), 500);
    }
  };

  const controls = data.map((item, index) => (
    <UnstyledButton<'button'>
      key={item.name}
      onClick={() => handleActiveChange(index)}
      data-active={index === active || undefined}
      className={classes.control}
    >
      <div className={classes.controlInner}>
        {
          item.icon ?
            <item.icon size={rem(28)} stroke={1.5} className={classes.controlIcon} />
            : undefined
        }
        <div>
          {
            item.header ? <Text className={classes.controlStep}>{item.header}</Text> : undefined
          }
          <Text className={classes.controlTitle}>{item.name}</Text>
          {
            item.description ?
            <Text size="sm" className={classes.controlDescription}>
             {item.description}
            </Text> : undefined
          }
        </div>
      </div>
    </UnstyledButton>
  ));

  const ActiveDemo = data[active].demo;

  // const slideWidth = window.innerWidth * 0.8;

  const carouselSlides = data.map((data) => {
    return (
    <Carousel.Slide key={data.name}>
      <Box maw={`calc(${window.innerWidth} - 16px)`}>
        <Stack pb={"sm"} spacing={"xs"}>
          {
            data.header ? <Text className={classes.controlStep} lh={1}>{data.header}</Text> : undefined
          }
          <Text fw={600} fz={"1.5rem"} lh={1.5}>{data.name}</Text>
        </Stack>
        <data.demo/>
      </Box>
    </Carousel.Slide>
    );
  });

  return (
    <div className={classes.root}>
      {/* Hide this on mobile */}
      <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
        <Grid gutter={0} mt="md">
          <Grid.Col md={orientation === "vertical" ? 4 : 12}>
            <div className={classes.controls}>
              <Box
                className={classes.controlsIndicator}
                sx={{
                  [orientation === "vertical" ? "height" : "width"]: rem(controlSize),
                  [orientation === "horizontal" ? "height" : "width"]: "100%",
                  transform: `${orientation === "vertical" ? "translateY" : "translateX"}(${rem(controlSize * active)})`,
                }}
              />
              {controls}
            </div>
          </Grid.Col>
          <Grid.Col md={orientation === "vertical" ? 8 : 12}>
            <div className={classes.demo}>
              <ActiveDemo />
            </div>
          </Grid.Col>
        </Grid>
      </MediaQuery>
      <MediaQuery largerThan={"md"} styles={{display: "none"}}>
        <Carousel
          align={"center"}
          orientation='horizontal'
          draggable
          withIndicators
          withControls
          styles={{viewport: {}}}
          breakpoints={[
            { maxWidth: 'lg', slideSize: '90%', slideGap: "10%" },
            { maxWidth: 'sm', slideSize: 'calc(100% - 8px)', slideGap: 8 },
          ]}
        >
          {carouselSlides}
        </Carousel>
      </MediaQuery>
    </div>
  );
}
