import { Alert, Button, Container, Paper, Stack, Text, Title, useMantineTheme } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useNavigateTop } from "../../utils/hooks";


export default function CheckoutSuccess() {
  const theme = useMantineTheme();
  const navigate = useNavigateTop();
  return (
    <Container fluid
      bg={"url(/assets/svg/landscape.svg)"}
      bgsz={"cover"}
      h={"calc(100vh - 60px)"}
    >
      <Container size="sm" pt={"4rem"} pb={"4rem"}>
        <Paper radius="md" shadow="xl" p={"xl"} withBorder>
          {/* <Image src="/assets/svg/landscape.svg"/> */}
          <Stack align="center" spacing={"xl"}>
            <Title color={theme.colors.gray[9]}>Success!</Title>
            <Alert color="teal" icon={<IconCheck/>} p={"md"}>
              <Text size={"md"} fw={500}>Your order was received, and you should receive a confirmation email shortly.</Text>
            </Alert>
            <Button size="md" variant="gradient" onClick={() => navigate("/dashboard")}>View your impact dashboard</Button>
          </Stack>
        </Paper>
      </Container>
    </Container>
  );
}
