import { useGetCalculatorInputsQuery, useUpdateCalculatorInputsMutation } from "../../../api/calculator";
import CustomNumberInput from "../../../components/calculator/CustomNumberInput";
import MultipleChoice, { IChoiceData, isCustomOptionSelected } from "../../../components/calculator/MultipleChoice";
import { selectCurrentUserId, useAppSelector } from "../../../redux/hooks";


export default function SelectCars(props: { goToNext?: () => void }) {
  const userId = useAppSelector(selectCurrentUserId) as string;

  const {data} = useGetCalculatorInputsQuery(userId);
  const [updateCalculator] = useUpdateCalculatorInputsMutation();

  const inputs = data?.inputs;
  const userValue = inputs?.numCars;

  const setUserValue = (value: number | string | null) => {
    updateCalculator({userId, inputs: { numCars: value as number }});
  };

  const sharedOnClick = (data: IChoiceData) => {
    setUserValue(data.value);
  }

  const choices: IChoiceData[] = [
    { value: 0, label: "None", selected: userValue === 0, onClick: sharedOnClick },
    { value: 1, label: "1 car", selected: userValue === 1, onClick: sharedOnClick },
    { value: 2, label: "2 cars", selected: userValue === 2, onClick: sharedOnClick }
  ];

  const customIsSelected = isCustomOptionSelected(choices, userValue);

  const customInput =
    <CustomNumberInput
      onChange={setUserValue}
      units={"cars"}
      initialValue={customIsSelected ? userValue : null}
      selected={customIsSelected}
      min={0}
      max={10}
    />;

  return (
    <MultipleChoice
      choices={choices}
      customInputElement={customInput}
    />
  );
}
