import { configureStore } from '@reduxjs/toolkit';
import { hubspotApi } from './api/hubspot';
import { calculatorApi } from './api/calculator';
import userReducer from './redux/userSlice';
import { paymentApi } from './api/payment';
import { trackingApi } from './api/tracking';
import { automationApi } from './api/automation';

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [hubspotApi.reducerPath]: hubspotApi.reducer,
    [calculatorApi.reducerPath]: calculatorApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [trackingApi.reducerPath]: trackingApi.reducer,
    [automationApi.reducerPath]: automationApi.reducer,
    user: userReducer
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      hubspotApi.middleware,
      calculatorApi.middleware,
      paymentApi.middleware,
      trackingApi.middleware,
      automationApi.middleware
    ]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
