import { AppShell, Container, MediaQuery, Tabs, createStyles, useMantineTheme } from '@mantine/core';

import { useParams } from 'react-router-dom';

import Sidebar from '../../components/dashboard/Sidebar';
import TopHeader from '../../components/shared/TopHeader/TopHeader';
import { useMediaQuery } from '@mantine/hooks';
import { IconChartBar, IconReceipt, IconSettings } from '@tabler/icons-react';
import MainRoute from './MainRoute';
import OrdersRoute from './OrdersRoute';
import SettingsRoute from './SettingsRoute';
import { useNavigateTop } from '../../utils/hooks';

const useStyles = createStyles((theme) => ({
  tab: {
    fontWeight: "bold",
    color: theme.colors.gray[8]
  },
}));


function DashboardShell() {
  const theme = useMantineTheme();
  const navigate = useNavigateTop();
  const {classes} = useStyles();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const params = useParams();

  // @ts-ignore
  const tab: "main" | "orders" | "settings" = params.tab;

  return (
    <AppShell
      padding="md"
      navbar={mobile ? undefined : <Sidebar/>}
      header={
        <TopHeader
          defaultBackgroundColor="white"
          defaultLogoColor={theme.colors.gray[9]}
          defaultTextColor={theme.colors.gray[9]}
          defaultPosition="fixed"
        />
      }
      styles={(theme) => ({
      })}
    >
      <Container size="lg">
        <MediaQuery smallerThan={"sm"} styles={{display: "flex"}}>
          <Tabs
            display={"none"}
            pb={"sm"}
            pt={"sm"}
            value={tab}
            color="teal"
            onTabChange={(value) => navigate(`/dashboard/${value}`)}
          >
            <Tabs.List grow position='center'>
              <Tabs.Tab value="main" className={classes.tab} icon={<IconChartBar size={"1rem"}/>}>Impact</Tabs.Tab>
              <Tabs.Tab value="orders" className={classes.tab} icon={<IconReceipt size={"1rem"}/>}>Orders</Tabs.Tab>
              <Tabs.Tab value="settings" className={classes.tab} icon={<IconSettings size={"1rem"}/>}>Settings</Tabs.Tab>
            </Tabs.List>
          </Tabs>
        </MediaQuery>
      </Container>
      {
        {
          main: <MainRoute/>,
          orders: <OrdersRoute/>,
          settings: <SettingsRoute/>
        }[tab]
      }
    </AppShell>
  );
}

export default DashboardShell;
