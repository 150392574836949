import { initializeApp } from "firebase/app";

const prodFirebaseConfig = {
  apiKey: "AIzaSyASaU-FXpF_jGvVfp-TAp0RCksycQriMz4",
  authDomain: "refarm-web-platform.firebaseapp.com",
  projectId: "refarm-web-platform",
  storageBucket: "refarm-web-platform.appspot.com",
  messagingSenderId: "231669656296",
  appId: "1:231669656296:web:e2553b0280477d381df75d",
  measurementId: "G-5C94MVXBCH"
};

const stagingFirebaseConfig = {
  apiKey: "AIzaSyDKDhSL7_iEDNNziHGBM6RuEtXglosuvmE",
  authDomain: "refarm-web-platform-staging.firebaseapp.com",
  projectId: "refarm-web-platform-staging",
  storageBucket: "refarm-web-platform-staging.appspot.com",
  messagingSenderId: "253417877394",
  appId: "1:253417877394:web:13bf4d02b9c796f8b7db4b"
};

const firebaseApp = process.env.REACT_APP_ENV === "production"
  ? initializeApp(prodFirebaseConfig)
  : initializeApp(stagingFirebaseConfig);

export default firebaseApp;
