import { Title, Container, createStyles, Stack, Text, Grid, Image } from "@mantine/core";
import HeaderTitle from "../../shared/HeaderTitle";


const useStyles = createStyles((theme) => ({
  container: {
    textAlign: "center"
  },
  header: {
    textTransform: "uppercase",
    fontWeight: 600,
    color: theme.colors.teal[6],
    fontSize: theme.fontSizes.lg,
    letterSpacing: ".06rem",
    lineHeight: 1.5,
  },
  title: {
    fontWeight: 500,
    fontSize: `calc(2.3 * ${theme.fontSizes.xl})`,
    lineHeight: 1.5,
    color: theme.colors.gray[9],

    [theme.fn.smallerThan('sm')]: {
      fontSize: `calc(1.5 * ${theme.fontSizes.xl})`,
    },
  },
  category: {
    fontWeight: 600,
    fontSize: `calc(1.5 * ${theme.fontSizes.xl})`,
    color: theme.colors.gray[9],

    [theme.fn.smallerThan('sm')]: {
      fontSize: `calc(1.2 * ${theme.fontSizes.xl})`,
    },
  },
  description: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 300,
    color: theme.colors.gray[6]
  },
  image: {
    filter: "drop-shadow(0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.1))"
  },
  readableWidth: {
    maxWidth: 900
  }
}));


export default function WhyFood() {
  const {classes, cx} = useStyles();

  return (
    <Container size="xl" className={classes.container}>
      <Stack align="center" spacing={"2rem"}>
        <HeaderTitle
          title={"Tackle climate change, restore ecosystems, and make nutritious food more accessible."}
          header={"Why sustainable food?"}
        />
        <Grid gutter={"sm"} gutterMd={"xl"}>
          <Grid.Col sm={4}>
            <Stack spacing={"xl"}>
              <Image src="/assets/why/cattle.webp" radius="lg" className={classes.image}/>
              <Stack spacing={"xs"}>
              <Title className={classes.category}>Climate</Title>
              <Text className={classes.description}>
                Food is responsible for 34% of global emissions, which we could cut in half with sustainable diets.
              </Text>
              </Stack>
            </Stack>
          </Grid.Col>
          <Grid.Col sm={4}>
            <Stack spacing={"xl"}>
              <Image src="/assets/why/deforestation2.jpeg" radius="lg" className={classes.image}/>
              <Stack spacing={"xs"}>
              <Title className={classes.category}>Ecosystems</Title>
              <Text className={classes.description}>
                Half of Earth’s habitable land is used to grow food. A sustainable food system would free up billions of hectares for restoration.
              </Text>
              </Stack>
            </Stack>
          </Grid.Col>
          <Grid.Col sm={4}>
            <Stack spacing={"xl"}>
              <Image src="/assets/why/project.jpg" radius="lg" className={classes.image}/>
              <Stack spacing={"xs"}>
              <Title className={classes.category}>Health</Title>
              <Text className={classes.description}>
                A climate-friendly food system can make healthy, nutritious food more accessible around the world.
              </Text>
              </Stack>
            </Stack>
          </Grid.Col>
        </Grid>
      </Stack>
    </Container>
  )
}
