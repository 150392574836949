import { Stack, Text, Title } from "@mantine/core";
import { almostEqual } from "../../../utils/helpers";
import { convertDistanceUnits, convertFuelEconomyUnits, getUserDistanceUnit, getUserFuelEconomyUnit } from "../utils/units";
import { selectCurrentUserId, useAppSelector } from "../../../redux/hooks";
import { UserCar, useGetCalculatorInputsQuery, useUpdateCalculatorInputsMutation } from "../../../api/calculator";
import CustomNumberInput from "../../../components/calculator/CustomNumberInput";
import MultipleChoice, { IChoiceData, isCustomNumericOptionSelected } from "../../../components/calculator/MultipleChoice";

interface SelectCarParamsProps {
  carId: string
}

export default function SelectCarParams({ carId }: SelectCarParamsProps) {
  const userId = useAppSelector(selectCurrentUserId) as string;

  const {data} = useGetCalculatorInputsQuery(userId);
  const [updateCalculator] = useUpdateCalculatorInputsMutation();

  const cars = (data?.inputs.cars || {});
  const car = cars[carId];
  const carName = `Car ${car.index + 1}`;

  const storedDistUnits = "miles";
  const storedFeUnits = "mpg";
  const storedMpw = car?.milesPerWeek;
  const storedMpg = car?.fuelEconomyMpg;

  const inputs = data?.inputs;
  const userDistUnits = getUserDistanceUnit(inputs?.countryCode);
  const userFeUnits = getUserFuelEconomyUnit(inputs?.countryCode);
  const userMpw = convertDistanceUnits(storedMpw, storedDistUnits, userDistUnits);
  const userFe = convertFuelEconomyUnits(storedMpg, storedFeUnits, userFeUnits);

  const updateCar = (value: UserCar) => {
    updateCalculator({userId, inputs: { cars: { [carId]: value } }});
  };

  const onTypeClicked = (data: IChoiceData) => {
    updateCar({...car, type: data.value as "gas" | "hybrid" | "electric"});
  }

  const onMpgClicked = (data: IChoiceData) => {
    setUserMpg(data.value as number);
  }
  const setUserMpg = (mpg: number) => {
    const convertedMpg = convertFuelEconomyUnits(mpg, userFeUnits, storedFeUnits);
    updateCar({...car, fuelEconomyMpg: convertedMpg});
  }

  const onMpwClicked = (data: IChoiceData) => {
    setUserMpw(data.value as number);
  }
  const setUserMpw = (mpw: number) => {
    const convertedMpw = convertDistanceUnits(mpw, userDistUnits, storedDistUnits);
    updateCar({...car, milesPerWeek: convertedMpw});
  }

  const typeChoices: IChoiceData[] = [
    {
      value: "gas",
      label: "Gas",
      selected: car?.type === "gas",
      onClick: onTypeClicked
    },
    {
      value: "hybrid",
      label: "Hybrid",
      selected: car?.type === "hybrid",
      onClick: onTypeClicked
    },
    {
      value: "electric",
      label: "Electric",
      selected: car?.type === "electric",
      onClick: onTypeClicked
    }
  ];

  const lowMpg = convertFuelEconomyUnits(15, "mpg", userFeUnits);
  const avgMpg = convertFuelEconomyUnits(30.1557, "mpg", userFeUnits);

  const economyChoices: IChoiceData[] = [
    {
      value: lowMpg,
      label: "Low",
      selected: almostEqual(lowMpg, userFe, 0.1),
      detail: `${lowMpg.toLocaleString("default", { maximumFractionDigits: 1 })} ${userFeUnits}`,
      onClick: onMpgClicked
    },
    {
      value: avgMpg,
      label: "Average",
      selected: almostEqual(avgMpg, userFe, 0.1),
      detail: `${avgMpg.toLocaleString("default", { maximumFractionDigits: 1 })} ${userFeUnits}`,
      onClick: onMpgClicked
    }
  ];

  const lowMpw = convertDistanceUnits(50, "miles", userDistUnits);
  const avgMpw = convertDistanceUnits(200, "miles", userDistUnits);

  const distanceChoices: IChoiceData[] = [
    {
      value: lowMpw,
      label: "Rarely",
      selected: almostEqual(lowMpw, userMpw, 0.1),
      detail: `${lowMpw.toLocaleString("default", { maximumFractionDigits: 0 })} ${userDistUnits}/week`,
      onClick: onMpwClicked
    },
    {
      value: avgMpw,
      label: "Average",
      selected: almostEqual(avgMpw, userMpw, 0.1),
      detail: `${avgMpw.toLocaleString("default", { maximumFractionDigits: 0 })} ${userDistUnits}/week`,
      onClick: onMpwClicked
    },
  ]

  const customMpgIsSelected = isCustomNumericOptionSelected(economyChoices, userFe, 0.1);
  const customMpwIsSelected = isCustomNumericOptionSelected(distanceChoices, userMpw, 0.1);

  const customMpgInput =
    <CustomNumberInput
      onChange={setUserMpg}
      units={userFeUnits}
      initialValue={customMpgIsSelected ? userFe : null}
      selected={customMpgIsSelected}
      min={1}
      max={100}
      precision={1}
    />;

  const customMpwInput =
    <CustomNumberInput
      onChange={setUserMpw}
      units={`${userDistUnits}/week`}
      initialValue={customMpwIsSelected ? userMpw : null}
      selected={customMpwIsSelected}
      min={0}
      max={10000}
      precision={0}
    />;

  return (
    <Stack>
      <Text fw={600}>What type of car is {carName}?</Text>
      <MultipleChoice
        orientation="horizontal"
        choices={typeChoices}
      />
      {
        car.type !== "electric" ?
        <>
          <Text fw={600}>How many miles per gallon does {carName} get?</Text>
          <MultipleChoice
            orientation="horizontal"
            choices={economyChoices}
            customInputElement={customMpgInput}
          />
        </> : undefined
      }
      <Text fw={600}>How many {userDistUnits} do you drive {carName} each week?</Text>
      <MultipleChoice
        orientation="horizontal"
        choices={distanceChoices}
        customInputElement={customMpwInput}
      />
    </Stack>

  );
}
