import { createStyles, Text, Container, ActionIcon, Group, rem, useMantineTheme, Stack, Grid, Anchor, Image } from '@mantine/core';
import { URL_PROJECT_DATABASE, URL_WIKI_HOME } from '../../../utils/links';
import SubscribeForm from './SubscribeForm';
import { useMediaQuery } from '@mantine/hooks';


const useStyles = createStyles((theme) => ({
  footer: {
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    backgroundColor: theme.colors.teal[9],
  },

  logo: {
    maxWidth: rem(200),

    [theme.fn.smallerThan('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  description: {
    marginTop: rem(5),

    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
      textAlign: 'center',
    },
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  wrapper: {
    width: rem(220),
  },

  link: {
    display: 'block',
    color: "white",
    fontSize: theme.fontSizes.md,
    fontWeight: 400,
    paddingTop: rem(3),
    paddingBottom: rem(3),

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  title: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 600,
    marginBottom: `calc(${theme.spacing.xs})`,
    color: "white",
  },

  afterFooter: {
    fontFamily: `Recoleta, ${theme.fontFamily}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },

  social: {
    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
    },
  },

  icon: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  }
}));


function Resource(props: {title: string, description: string, icon?: React.ReactElement, href?: string}) {
  const {classes} = useStyles();
  return (
    <Anchor href={props.href} underline={false}>
      <Group pb={"xs"}>
        <Stack spacing={0}>
          <Text className={classes.link} lh={1.4}>{props.title}</Text>
          <Text fz={"xs"} c="white" lh={1.4}>{props.description}</Text>
        </Stack>
      </Group>
    </Anchor>
  );
}


export function Footer() {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const navigateExternal = (url: string) => {
    window.open(url);
  }

  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <footer className={classes.footer}>
      <Container size={"xl"}>
        <Grid gutter="lg">
          <Grid.Col order={2} span={6} sm={3} md={3}>
            <Text className={classes.title}>Learn more</Text>
            <Text<'a'>
              className={classes.link}
              component="a"
              href={"/our-approach"}
            >
              {"Our approach"}
            </Text>
            <Text<'a'>
              className={classes.link}
              component="a"
              href={"/about-us"}
            >
              {"About us"}
            </Text>
            <Text<'a'>
              className={classes.link}
              component="a"
              href={URL_WIKI_HOME}
            >
              {"📖 Wiki"}
            </Text>
            <Text<'a'>
              className={classes.link}
              component="a"
              href={URL_PROJECT_DATABASE}
            >
              {"🌱 Projects"}
            </Text>
            <Text<'a'>
              className={classes.link}
              component="a"
              href={"/references"}
            >
              {"📓 References"}
            </Text>
          </Grid.Col>

          <Grid.Col order={3} span={6} sm={4} md={4}>
            <Text className={classes.title}>Take action</Text>
            <Resource
              title={"🏠 For individuals"}
              description={"Calculate and offset your footprint"}
              href={"/take-action"}
            />
            <Resource
              title={"🏢 For companies"}
              description={"Make a climate contribution"}
              href="/for-companies"
            />
          </Grid.Col>

          <Grid.Col sm={5} md={5} order={1} orderSm={4}>
            <SubscribeForm/>
          </Grid.Col>
        </Grid>

      </Container>
      <Container className={classes.afterFooter} size="xl">
        <Stack spacing={0} align={mobile ? "center" : "flex-start"}>
          <Text color="white" size={"sm"}>
            © 2023 Climate Refarm, PBC
          </Text>
          <Text color="white" size={"xs"}>
            Climate action for people, animals, and the planet
          </Text>
        </Stack>

        <Group spacing={4} className={classes.social} position="right" noWrap>
          <ActionIcon size="lg" onClick={() => navigateExternal("https://twitter.com/ClimateRefarm")} className={classes.icon}>
            <Image src="/assets/icons/icons8-twitter.svg"/>
          </ActionIcon>
          <ActionIcon size="lg" onClick={() => navigateExternal("https://www.linkedin.com/company/climaterefarm/")} className={classes.icon}>
            <Image src="/assets/icons/icons8-linkedin.svg"/>
          </ActionIcon>
          <ActionIcon size="lg" onClick={() => navigateExternal("https://www.instagram.com/climaterefarm/")} className={classes.icon}>
            <Image src="/assets/icons/icons8-instagram.svg"/>
          </ActionIcon>
          <ActionIcon size="lg" onClick={() => navigateExternal("mailto:info@climaterefarm.com")} className={classes.icon}>
            <Image src="/assets/icons/mail.svg"/>
          </ActionIcon>
        </Group>
      </Container>
    </footer>
  );
}
