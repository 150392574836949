import { MediaQuery, Navbar, createStyles, getStylesRef, rem, useMantineTheme } from '@mantine/core';
import {
  IconChartBar,
  IconReceipt,
  IconLogout,
  IconSettings
} from '@tabler/icons-react';

import { NavLink } from 'react-router-dom';


const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.colors.white
  },

  link: {
    // https://mantine.dev/theming/functions/
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
    padding: `${theme.spacing.sm} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 600,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,

      [`& .${getStylesRef('icon')}`]: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },
  },

  linkIcon: {
    ref: getStylesRef('icon'),
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
    paddingRight: "1rem",
    [theme.fn.smallerThan("md")]: {
      paddingRight: 0,
    }
  },

  linkActive: {
    // Can use variant 'filled' to get solid filled color in links.
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
      [`& .${getStylesRef('icon')}`]: {
        color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
      },
    },
  },

  footer: {
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    paddingTop: theme.spacing.md,
  },
}));


export default function Sidebar() {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();

  const tabs = [
    { link: '/dashboard/main', label: 'Impact', icon: IconChartBar },
    { link: '/dashboard/orders', label: 'Orders', icon: IconReceipt },
    { link: '/dashboard/settings', label: 'Settings', icon: IconSettings },
  ];

  const links = tabs.map((item) => (
    <NavLink
      key={item.label}
      to={item.link}
      className={({ isActive, isPending }) => cx(classes.link, { [classes.linkActive]: isActive })}
    >
      <item.icon className={classes.linkIcon} size={"24px"}/>
      <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
        <span>{item.label}</span>
      </MediaQuery>
    </NavLink>
  ));

  return (
    <Navbar width={{ base: 82, md: 200 }} p="md" className={classes.navbar}>
      <Navbar.Section grow mt="xl">
        {links}
      </Navbar.Section>
      <Navbar.Section>
      <NavLink
        key="logout"
        to="/logout"
        className={({ isActive, isPending }) => cx(classes.link, { [classes.linkActive]: isActive })}
      >
        <IconLogout className={classes.linkIcon} size={"24px"}/>
        <MediaQuery smallerThan={"md"} styles={{display: "none"}}>
          <span>Logout</span>
        </MediaQuery>
      </NavLink>
      </Navbar.Section>
    </Navbar>
  );
}
