import { Title, Container, createStyles, Stack, Image, Text, Grid, Space, Divider, useMantineTheme, Group, Button, Paper, MediaQuery } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { IconArrowRight } from "@tabler/icons-react";
import { URL_CHARTER } from "../../utils/links";
import HeaderTitle from "../../components/shared/HeaderTitle";
import Triangle from "../../components/shared/Triangle";
import { useEffect } from "react";


const useStyles = createStyles((theme) => ({
  darkSection: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
    backgroundColor: theme.colors.teal[9],
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',

    [theme.fn.smallerThan("sm")]: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    }
  },
  lightSection: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
    backgroundColor: 'white',
    [theme.fn.smallerThan("sm")]: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    }
  },
  graySection: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
    backgroundColor: theme.colors.gray[0],
    [theme.fn.smallerThan("sm")]: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    }
  },
  name: {
    textAlign: 'left',
    fontWeight: 500,
    color: theme.colors.gray[9],
  },
  title: {
    color: theme.colors.teal[6],
    textAlign: 'left',
    fontWeight: 500,
  },
  email: {
    textAlign: 'left',
    fontSize: '0.8rem',
    color: theme.colors.gray[9],
  },
  bio: {
    color: theme.colors.gray[9],
    textAlign: 'left',
    fontSize: "1rem",
  },
}));


function Person(props: { name: string, title: string, email: string, bio: string, img: string, reversed?: boolean }) {
  const {classes} = useStyles();
  return (
    <Grid gutter={"sm"} gutterSm={"3rem"}>
      <Grid.Col sm={5} display={"flex"} sx={{alignItems: "center"}} orderSm={props.reversed ? 2 : 1}>
        <Image src={props.img} radius="sm" sx={{filter: "drop-shadow(0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.1))"}}/>
      </Grid.Col>
      <Grid.Col sm={7} orderSm={props.reversed ? 1 : 2} display={"flex"} sx={{alignItems: "center"}}>
        <Stack spacing={"xs"}>
          <Title className={classes.name} order={3}>{props.name}</Title>
          <Group>
            <Text className={classes.title}>{props.title}</Text>
            {/* <Text className={classes.email}>{props.email}</Text> */}
          </Group>
          <Divider size={"sm"}/>
          <Text className={classes.bio}>
            {props.bio}
          </Text>
        </Stack>
      </Grid.Col>
    </Grid>
  );
}


function Value(props: { number: number, title: string, description: string }) {
  return (
    <Grid>
      <Grid.Col span={"content"}>
        <Text w={"16px"} fz={"1rem"} fw={"bolder"} color="white">{props.number}</Text>
      </Grid.Col>
      <Grid.Col span="auto">
      <Stack w={"calc(100% - 32px - 1rem)"} spacing={0}>
        <Text color="white" fw={600} fz={"xl"}>{props.title}</Text>
        <Text color="white">{props.description}</Text>
      </Stack>
      </Grid.Col>
    </Grid>
  )
}


function AboutUsRoute() {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();

  const onGetInTouch = () => {
    window.open("mailto:info@climaterefarm.com", "_blank");
  }

  // Always scroll to the top of this page.
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <>
      <Container fluid className={classes.lightSection}>
        <Container size="md">
          <Stack align="center" spacing={"2rem"}>
            <HeaderTitle
              header="Our Mission"
              title={"We're accelerating the transition to a sustainable, equitable, and humane food system."}
            />
          </Stack>
        </Container>
        <Space h="2rem"/>
        <Container size="lg">
          <Grid>
            <MediaQuery smallerThan={"xs"} styles={{display: "none"}}>
              <Grid.Col sm={4}>
                <Image src="/assets/about/farmer.jpg" radius="sm"/>
              </Grid.Col>
            </MediaQuery>
            <Grid.Col sm={4}>
              <Image src="/assets/about/thailand.jpg" radius="sm"/>
            </Grid.Col>
            <MediaQuery smallerThan={"xs"} styles={{display: "none"}}>
              <Grid.Col sm={4}>
                <Image src="/assets/about/rainforest.jpg" radius="sm"/>
              </Grid.Col>
            </MediaQuery>
          </Grid>
        </Container>
        <Space h="xl"/>
      </Container>

      {/* <Triangle w="100vw" h="2rem" side="top" fillColor={theme.colors.teal[9]} backgroundColor={"white"}/> */}
      <Container fluid className={classes.darkSection} bg={theme.colors.teal[9]}>
        <Container size="sm">
          <Stack align="left" spacing={"1.5rem"}>
            <Title color="white" sx={{fontSize: "max(2.5vw, 2rem)"}} fw={500}>
              We're a public benefit corporation, meaning that environmental and social impact is embedded in our company charter.
            </Title>
            <Divider color="white" w={"100%"} label={<Text fw={"bold"} fz="xl" color="white">Our core values</Text>} labelPosition="center"/>

            <Value
              number={1}
              title={"Ensuring a Just Food Transition"}
              description={"We envision a world in which farmers, consumers, the environment, and animals are treated with respect. We only fund climate solutions that benefit people, animals, and the planet."}
            />

            <Value
              number={2}
              title={"Transparency & Integrity"}
              description={"We will bring much-needed transparency to carbon crediting. We're committed to reporting everything from our financials to the carbon accounting behind each of our projects."}
            />

            <Value
              number={3}
              title={"Effectiveness"}
              description={"We will focus our energy and resources on the most impactful actions. We'll continuously evaluate how we can deliver more impact and are always open to feedback."}
            />
            <Button variant="white" w={180} size="md" onClick={() => {window.open(URL_CHARTER)}}>
              Read our charter
            </Button>
          </Stack>
        </Container>
      </Container>
      {/* <Triangle w="100vw" h="2rem" side="bottom" fillColor={theme.colors.teal[9]} backgroundColor={"white"}/> */}

      {/* <WavyDivider height={"2rem"} width={"200%"} topColor={theme.colors.teal[9]} bottomColor="white" withShadow/> */}

      <Container fluid className={classes.lightSection}>
        <Container size="sm">
          <Stack spacing={"4rem"} align="center">
            {/* <Title color="teal" sx={{fontSize: 'max(3vw, 2.5rem)'}}>Meet Our Team</Title> */}
            <HeaderTitle title={"Meet Our Team"}/>
            {/* <Divider w={"80%"} size={"sm"} label={<Text fw={"bold"} fz="lg" color="black">Our Founders</Text>} labelPosition="center"/> */}

            <Person
              name="Manny Rutinel"
              title={"CEO & Co-Founder"}
              img={"/assets/team/manny.tall.jpg"}
              email="manny@climaterefarm.com"
              bio={"\
                After receiving his J.D. from Yale Law School, Manny served as an Attorney for Earthjustice's Sustainable Food and Farming Program.\
                Before law school, Manny was an economist for the U.S Army Corps of Engineers, where he also served as a First Responder in Puerto Rico\
                after Hurricane Maria. Manny holds an M.S. in Applied Economics from Johns Hopkins University and both a B.S. in Microbiology and\
                a B.A. in Economics from the University of Florida."
              }
            />

            <Person
              name="Milo Knowles"
              title={"CTO & Co-Founder"}
              img={"/assets/team/milo.tall.jpg"}
              email="milo@climaterefarm.com"
              bio={"\
                Prior to joining the team, Milo worked at a grid decarbonization software startup called Singularity Energy.\
                He brings a broad engineering background from his experience in the climate-tech, robotics, and computer vision industries, and holds degrees in Artificial Intelligence and Computer Science from MIT.\
                His interest in food systems began as a undergrad after learning about the massive environmental impact we can have through simple changes to what we eat.\
              "}
              reversed
            />

            {/*
            <Person
              name="Sebastian Quaade"
              title={"Quantitative Climate Analyst"}
              img={"/assets/team/sebastian.tall.jpg"}
              email="sebastian@climaterefarm.com"
              bio={"\
                Before joining Climate Refarm, Sebastian was a research fellow at Stanford Law School, where he used remote sensing, machine learning,\
                and causal inference methods to estimate pollution from large-scale animal agricultural facilities. Prior to that, he worked at Mercy For Animals,\
                developing prototypes for alternative food systems and conducting research to inform the organization’s policy strategy. He has also worked on \
                a number of research projects in development economics. Sebastian holds a B.A. in economics and mathematics from Yale University, and is a passionate\
                climate and animal advocate."
              }
            /> 
            */ }

            {/* <Space h={"2rem"}/> */}
            {/* <Paper withBorder shadow="sm" p={"xl"}>
              <Stack align="center">
                <Text color={theme.colors.gray[9]} fw={"bold"} fz={"xl"}>Interested in collaborating?</Text>
                <Button variant="filled" rightIcon={<IconArrowRight/>} onClick={onGetInTouch}>Get in touch</Button>
              </Stack>
            </Paper> */}
          </Stack>
        </Container>
      </Container>
    </>
  );
}

export default AboutUsRoute;
