import { Anchor, Stack, createStyles } from "@mantine/core";

import {
  Text,
  Title,
  SimpleGrid,
  TextInput,
  Button,
  Group,
  rem,
} from '@mantine/core';
import ForCompaniesFAQs from "./ForCompaniesFAQs";
import { useForm } from "@mantine/form";
import { useSubmitCompanyInterestFormMutation } from "../../../api/hubspot";
import { IconCheck } from "@tabler/icons-react";


const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: rem("400px"),
    borderRadius: theme.radius.md,
    padding: `calc(2.5*${theme.spacing.xl})`,
    // background: theme.fn.gradient({from: theme.colors.teal[9], to: theme.colors.teal[7]}),
    background: "transparent",
    color: "white",
    [theme.fn.smallerThan("md")]: {
      padding: theme.spacing.md,
    }
  },
  title: {
    lineHeight: 1,
  },
  description: {
    color: "white"
  },
  form: {
    backgroundColor: "white",
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    height: "fit-content"
  },
  input: {

  },
  inputLabel: {

  },
  control: {

  }
}));


export default function ContactForm() {
  const {classes} = useStyles();

  const regexValidEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  const [submit, status] = useSubmitCompanyInterestFormMutation();

  const form = useForm({
    initialValues: { email: '', firstName: '', lastName: '', organization: '' },

    validate: {
      email: (value) => (regexValidEmail.test(value) ? null : 'Please enter a valid email'),
      firstName: (value) => ((value.length >= 1) ? null : 'Please enter a name'),
      lastName: (value) => ((value.length >= 1) ? null : 'Please enter a name'),
      organization: (value) => ((value.length >= 1) ? null : 'Please enter an organization')
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    submit(values);
  }

  const handleError = (errors: any) => {
  };

  const loading = status.isLoading;

  return (
    <div className={classes.wrapper}>
      <SimpleGrid spacing={"sm"} cols={1} breakpoints={[
        { minWidth: "md", cols: 2, spacing: 32 }
      ]}>
        <Stack>
          <Title className={classes.title}>Start your climate contribution</Title>
          <Text className={classes.description} mt="sm" mb={30}>
            If you're interested in funding the sustainable food transition, submit your
            contact information and we'll get back to you shortly.
            Don't hesitate to <Anchor color="white" href="mailto:info@climatefarm.com" underline={true} sx={{textDecoration: "underline"}}>reach out</Anchor> if you have questions.
          </Text>
          <div className={classes.form}>
          <form onSubmit={form.onSubmit(handleSubmit, handleError)}>
            <TextInput
              label="Email"
              placeholder="you@company.com"
              required
              classNames={{ input: classes.input, label: classes.inputLabel }}
              {...form.getInputProps('email')}
            />
            <Group grow>
              <TextInput
                label="First name"
                placeholder="First"
                mt="md"
                classNames={{ input: classes.input, label: classes.inputLabel }}
                required
                {...form.getInputProps('firstName')}
              />
              <TextInput
                label="Last name"
                placeholder="Last"
                mt="md"
                classNames={{ input: classes.input, label: classes.inputLabel }}
                required
                {...form.getInputProps('lastName')}
              />
            </Group>
            <TextInput
              label="Organization"
              placeholder="Your organization"
              mt="md"
              classNames={{ input: classes.input, label: classes.inputLabel }}
              required
              {...form.getInputProps('organization')}
            />
            <Group sx={{justifyContent: "flex-end"}} mt="md">
              <Button
                className={classes.control}
                type="submit"
                loading={loading}
                rightIcon={status.isSuccess ? <IconCheck/> : undefined}
              >
                {status.isSuccess ? "Done" : "Submit"}
              </Button>
            </Group>
          </form>
        </div>
        </Stack>
        <div>
          <ForCompaniesFAQs/>
        </div>
      </SimpleGrid>
    </div>
  )
}
