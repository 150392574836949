import { useState } from "react";

import { useForm } from "@mantine/form";

import { Alert, Button, Stack, TextInput, createStyles, useMantineTheme } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

import { getAuth, sendPasswordResetEmail } from "firebase/auth";


const useStyles = createStyles((theme) => ({
  muted: {
    color: theme.colors.gray[7],
    fontSize: "14px",
  },
  dark: {
    color: theme.colors.gray[8]
  }
}));


export default function ResetPasswordForm() {
  const theme = useMantineTheme();
  const {classes, cx} = useStyles();

  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const regexValidEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  const form = useForm({
    initialValues: { email: '' },

    validate: {
      email: (value) => (regexValidEmail.test(value) ? null : 'Please enter a valid email'),
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    const auth = getAuth();
    setLoading(true);

    sendPasswordResetEmail(auth, values.email)
      .then(() => {
        console.debug("@ Sending password reset email");
        setLoading(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        console.error(errorCode);
        setErrorCode(errorCode);
        setLoading(false);
      });
  };

  const handleError = (errors: any) => {
    console.error("Login errors:");
    console.error(errors);
  };

  const styles = {
    label: {
      color: theme.colors.gray[8],
      width: "100%"
    },
    input: {
      color: theme.colors.gray[8]
    }
  };

  const readableErrors = {
    "auth/user-not-found": "We don't have an account with that email"
  };

  const errorAlert = <Alert variant="light" color="orange" icon={<IconAlertCircle/>} p={"xs"}>
    {(errorCode && errorCode in readableErrors) ? readableErrors[errorCode as keyof typeof readableErrors] : "Something went wrong"}
  </Alert>;

  return (
    <form onSubmit={form.onSubmit(handleSubmit, handleError)} style={{width: "100%"}}>
      <Stack spacing={"lg"}>
        <TextInput size="md" placeholder="Your email" {...form.getInputProps('email')} label="Email" className={cx(classes.dark)} styles={styles}/>
        <Button
          size='md'
          type="submit"
          loading={loading}
        >
          Send a reset email
        </Button>
        {errorCode ? errorAlert : undefined}
      </Stack>
    </form>
  );
}
