import { Button, Notification, Stack, Text, createStyles, useMantineTheme } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const useStyles = createStyles((theme) => ({
  lineHeight: {
    lineHeight: 1.0,
  },
  notification: {
  },
  created: {
    background: theme.fn.gradient()
  },
  updated: {
  },
  cancelled: {
  }
}));


export function UpdatedNotification() {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();
  const navigate = useNavigate();

  return (
    <Notification
      className={cx(classes.notification, classes.updated)}
      color="teal"
      title="Your subscription was updated"
      mt="lg"
      withCloseButton={false}
      styles={{
        title: {fontWeight: 600, fontSize: theme.fontSizes.lg, color: theme.colors.gray[9]},
        description: {}
      }}
    >
      <Stack align="flex-start" spacing={"8px"}>
        <Text>You should receive a confirmation email shortly.</Text>
        <Button size='xs' variant='filled' onClick={() => navigate("/dashboard")}>
          View your dashboard
        </Button>
      </Stack>
    </Notification>
  );
}


export function CancelledNotification() {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setTimeout(() => setOpened(true), 1000)
  }, []);

  return (
    <Notification
      className={cx(classes.notification, classes.updated)}
      color="red"
      title="Your checkout session was cancelled"
      mt="lg"
      withCloseButton={false}
      styles={{
        title: {fontWeight: 600, fontSize: theme.fontSizes.lg, color: theme.colors.gray[9]},
        description: {}
      }}
    >
      <Stack align="flex-start" spacing={"8px"}>
        <Text>If this was a mistake, click the checkout button again to return.</Text>
      </Stack>
    </Notification>
  );
}


export function CreatedNotification() {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();
  const navigate = useNavigate();

  return (
    <Notification
      className={cx(classes.notification, classes.created)}
      color="blue"
      title="🎉 Your order was received!"
      mt="lg"
      withCloseButton={false}
      styles={{
        title: {color: "white", fontWeight: 600, fontSize: theme.fontSizes.lg},
        description: {color: "white"}
      }}
    >
      <Stack align="flex-start" spacing={"8px"}>
        <Text>You should receive a confirmation email shortly.</Text>
        <Button size='xs' variant='white' onClick={() => navigate("/dashboard")}>
          View your dashboard
        </Button>
      </Stack>
    </Notification>
  );
}
