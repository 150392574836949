import { Container, createStyles, Button, Stack, Text, Grid, Box, useMantineTheme, Card } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import HeaderTitle from "../../shared/HeaderTitle";
import EnterCountryButton from "./EnterCountryButton/EnterCountryButton";
import { useNavigateTop } from "../../../utils/hooks";
import ArrowButton from "../../shared/ArrowButton/ArrowButton";


const useStyles = createStyles((theme) => ({
  container: {
    textAlign: "center"
  },
  cardDescription: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 400,
    color: theme.colors.gray[9],
    width: "100%",
    zIndex: 2,
  },
  cardTitle: {
    fontWeight: 700,
    fontSize: "1.8rem",
    color: theme.colors.gray[9],
    width: "100%",
    zIndex: 2,

    [theme.fn.smallerThan('sm')]: {
      fontSize: `calc(1 * ${theme.fontSizes.xl})`,
    },
  },
  readableWidth: {
    maxWidth: 900
  },
  actionCardInner: {
    padding: "3rem",
    paddingBottom: 0,
    zIndex: 2,
    [theme.fn.smallerThan("sm")]: {
      padding: "1rem",
      paddingBottom: 0,
    }
  }
}));


function ActionCard(props: { title: string, description: string, buttonLabel: string, mode: "individual" | "company" }) {
  const {classes} = useStyles();
  const navigate = useNavigateTop();
  const theme = useMantineTheme();

  const forCompanies = () => {
    navigate("/for-companies");
  }

  return (
    <Card
      p={0}
      shadow="md"
      sx={{textAlign: "left"}}
      radius="md"
      withBorder
    >
      <Stack align="center" className={classes.actionCardInner}>
        <Text className={classes.cardTitle}>
          {props.title}
        </Text>
        <Text className={classes.cardDescription}>
          {props.description}
        </Text>
        {
          props.mode === "individual" ?
            <EnterCountryButton align="left"/> :
            <ArrowButton
              mr={"auto"} sx={{zIndex: 2}} size="lg"
              onClick={forCompanies}
              variant="gradient"
            >
              Learn more
            </ArrowButton>
        }
      </Stack>
      <Box
        pos={"relative"}
        bg={props.mode === "individual" ? "url(/assets/action/brazil.jpg)" : "url(/assets/action/crops.jpg)"}
        bgsz={"cover"}
        h={300}
        w="100%"
      >
        <div style={{
          height: "20%",
          left: "-20px",
          position: "absolute",
          right: "-20px",
          top: "-9px",
          transform: "rotate(-5deg)",
          transformOrigin: "0 0",
          zIndex: 1,
          backgroundColor: "white"
        }}/>
        {
          props.mode === "individual" ?
          <Box sx={{bottom: 32, right: 32, borderRadius: 8, minWidth: 240, border: "1px solid gray"}} pos={"absolute"} bg={theme.colors.gray[1]} p={"sm"} w={"50%"}>
            <Text fw={"normal"} fz={"sm"}>🇧🇷 You funded <span style={{color: theme.colors.teal[6]}}>plant-based school lunches</span> in Brazil this month!</Text>
          </Box> : undefined
        }
      </Box>
    </Card>
  );
}


export default function CallToAction1() {
  const {classes, cx} = useStyles();

  return (
    <Container size="lg" className={classes.container}>
      <Stack align="center" spacing={"2rem"}>
        <HeaderTitle
          title={"Offset your emissions by funding the sustainable food transition."}
          header={"Be part of the solution"}
        />
        <Grid gutter={"lg"} gutterMd={"3rem"}>
          <Grid.Col sm={6}>
            <ActionCard
              title="For individuals"
              description="Calculate and offset your carbon footprint with a climate subscription."
              buttonLabel="Enter your country"
              mode="individual"
            />
          </Grid.Col>
          <Grid.Col sm={6}>
          <ActionCard
            title="For companies"
            description="Make a climate contribution with co-benefits for people, animals, and the planet."
            buttonLabel="Learn more"
            mode="company"
          />
          </Grid.Col>
        </Grid>
      </Stack>
    </Container>
  )
}
