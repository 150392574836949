import { createStyles, Text, Title, TextInput, Button, Badge } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCheck } from '@tabler/icons-react';
import { useSubmitCreditWaitlistFormMutation } from '../../../api/hubspot';


const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.radius.md,
    minHeight: 300,
    // backgroundColor: 'white'
  },

  inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  image: {
    maxWidth: '40%',

    [theme.fn.smallerThan('sm')]: {
      maxWidth: '100%',
    },
  },

  title: {
    color: 'white',
    lineHeight: 1,
    fontWeight: 500,
    fontSize: 'max(3vw, 2.2rem)',
    marginBottom: theme.spacing.md,
    marginTop: theme.spacing.sm
  },

  form: {
    width: '100%'
  },

  controls: {
    display: 'flex',
    marginTop: theme.spacing.xl,
    width: '100%'
  },

  inputWrapper: {
    width: '100%',
    flex: '1',
  },

  input: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0,
  },

  control: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));


export function CreditWaitlistBanner() {
  const { classes } = useStyles();

  const regexValidEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  const [submitForm, result] = useSubmitCreditWaitlistFormMutation();

  const form = useForm({
    initialValues: { email: '' },
    validate: {
      email: (value) => (regexValidEmail.test(value) ? null : 'Please enter a valid email'),
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    if (result.isSuccess) {
      console.warn("Already submitted, skipping")
      return;
    }
    submitForm({...values, pageName: document.title, pageUri: window.location.href});
  };

  const handleError = (errors: typeof form.errors) => {
    console.error(errors);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.inner}>
        <Badge color='primary'>Launching Soon</Badge>
        <Title className={classes.title} order={1}>
          Join the waitlist
        </Title>
        <Text c="white" align='center' fz={"xl"} fw={500}>
          Be the first to know when projects are ready for funding.
        </Text>

        <form onSubmit={form.onSubmit(handleSubmit, handleError)} className={classes.form}>
          <div className={classes.controls}>
            <TextInput size="md" classNames={{ input: classes.input, root: classes.inputWrapper }} placeholder="Your email" {...form.getInputProps('email')} />
            <Button
              loading={result.isLoading}
              leftIcon={result.isSuccess ? <IconCheck/> : null}
              className={classes.control}
              size='md'
              type="submit">
                {result.isSuccess ? "Done" : "Subscribe"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
