import { useState } from "react";

import { useForm } from "@mantine/form";

import { Alert, Button, Group, PasswordInput, Stack, Text, TextInput, createStyles, useMantineTheme } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { selectCurrentUser, selectIsLoggedIn, useAppSelector } from "../../redux/hooks";
import { Navigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  muted: {
    color: theme.colors.gray[7],
    fontSize: "14px",
  },
  dark: {
    color: theme.colors.gray[8]
  }
}));


export default function LoginForm(props: { handleSuccess?: () => void }) {
  const theme = useMantineTheme();
  const {classes, cx} = useStyles();

  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const regexValidEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  const form = useForm({
    initialValues: { email: '', password: '' },

    validate: {
      email: (value) => (regexValidEmail.test(value) ? null : 'Please enter a valid email'),
      password: (value) => ((value.length >= 8) ? null : 'Must be at least 8 characters')
    },
  });

  if (isLoggedIn) {
    console.debug("@ User already logged in, redirecting to dashboard");
    return <Navigate to="/dashboard"/>
  }

  const handleSubmit = (values: typeof form.values) => {
    const auth = getAuth();
    setLoading(true);

    signInWithEmailAndPassword(auth, values.email, values.password)
      .then((userCredential) => {
        setLoading(false);
        if (props.handleSuccess) {
          props.handleSuccess();
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        console.error(errorCode);
        setErrorCode(errorCode);
        setLoading(false);
      });
  };

  const handleError = (errors: any) => {
    console.error("Login errors:");
    console.error(errors);
  };

  const styles = {
    label: {
      color: theme.colors.gray[8],
      width: "100%"
    },
    input: {
      color: theme.colors.gray[8]
    }
  };

  const readableErrors = {
    "auth/wrong-password": "Password is incorrect",
    "auth/user-not-found": "We don't have an account with that email"
  };

  const errorAlert = <Alert variant="light" color="orange" icon={<IconAlertCircle/>} p={"xs"}>
    {(errorCode && errorCode in readableErrors) ? readableErrors[errorCode as keyof typeof readableErrors] : "Something went wrong"}
  </Alert>;

  return (
    <form onSubmit={form.onSubmit(handleSubmit, handleError)} style={{width: "100%"}}>
      <Stack spacing={"lg"}>
        <TextInput size="md" placeholder="Your email" {...form.getInputProps('email')} label="Email" className={cx(classes.dark)} styles={styles}/>
        <PasswordInput
          size="md" {...form.getInputProps('password')}
          label={
            <Group>
              <Text>Password</Text>
              <Text fz="xs" align="right" ml={"auto"}>
                <a href="/reset-password" style={{color: theme.colors.teal[6], textDecoration: "none"}}>Forgot password?</a>
              </Text>
            </Group>
          }
          className={cx(classes.dark)}
          styles={styles}
          placeholder="Your password"
        />
        <Button
          size='md'
          type="submit"
          loading={loading}
        >
          Continue
        </Button>
        {errorCode ? errorAlert : undefined}
      </Stack>
    </form>
  );
}
