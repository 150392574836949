import { Stack, Title, Text, createStyles } from "@mantine/core";
import { selectCurrentUser, useAppSelector } from "../../redux/hooks";
import { getAuth } from "firebase/auth";

const useStyles = createStyles((theme) => ({
  heading: {
    fontWeight: 600,
  }
}));


export default function DebugView() {
  const {classes} = useStyles();
  const user = useAppSelector(selectCurrentUser);
  const auth = getAuth(window.firebaseApp);

  return (
    <Stack spacing={"xs"}>
      <Text className={classes.heading}>Token</Text>
      <Text>{user.accessToken}</Text>
      <Text>Expiration: {user.tokenExpires}</Text>

      <Text className={classes.heading}>User ID</Text>
      <Text>{user.userId}</Text>

      <Text className={classes.heading}>Is the user anonymous?</Text>
      <Text>{user.isAnonymous ? "true" : "false"}</Text>

      <Text className={classes.heading}>User Info</Text>
      <Text>{user.email}</Text>
      <Text>{user.firstName}</Text>
      <Text>{user.lastName}</Text>

      <Text className={classes.heading}>React App Env</Text>
      <Text>{process.env.REACT_APP_ENV}</Text>
    </Stack>
  );
}
