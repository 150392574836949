import { Select, SelectItemProps, Stack, Text, createStyles, useMantineTheme } from "@mantine/core";
import { forwardRef } from "react";

import { COUNTRIES } from "../utils/countries";
import CalculatorButton from "../../../components/calculator/CalculatorButton";
import { useGetCalculatorInputsQuery, useUpdateCalculatorInputsMutation } from "../../../api/calculator";
import { selectCurrentUserId, useAppSelector } from "../../../redux/hooks";


const useStyles = createStyles((theme) => ({
  selectedOption: {
    input: {
      borderColor: theme.colors.teal[6],
      backgroundColor: theme.fn.rgba(theme.colors.teal[6], 0.1),
      "&:hover": {
        backgroundColor: theme.fn.rgba(theme.colors.teal[6], 0.1)
      },
    }
  },

  // Need to target the input element for the dropdown.
  countryDropdown: {
    input: {
      border: "2px solid lightgray",
      color: "#868e96",
      fontWeight: 600,
      paddingLeft: "1rem"
    }
  },

  tipText: {
    color: theme.colors.teal[6],
    fontSize: theme.fontSizes.md,
  }
}));


interface ItemProps extends SelectItemProps {
  emoji: string
  countryName: string
}


const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ value, countryName, emoji, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Text>{emoji} {countryName}</Text>
    </div>
  )
);


export default function SelectCountry() {
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();

  const userId = useAppSelector(selectCurrentUserId) as string;

  const {data} = useGetCalculatorInputsQuery(userId);
  const [updateCalculator] = useUpdateCalculatorInputsMutation();

  const inputs = data?.inputs;
  const countryCode = inputs?.countryCode;

  // Populate country options with emojis.
  const dropdownData = Object.entries(COUNTRIES)
    .map(([code, country]) => (
      {
        value: code,
        label: `${country.emoji} ${country.countryName}`,
        countryName: country.countryName,
        emoji: country.emoji
      }
    ));

  // Check if a custom option should be selected.
  const isCustomOption = countryCode && !["USA", "GBR", "CAN"].includes(countryCode);
  const customCountry = isCustomOption ? COUNTRIES[countryCode as string] : null;
  const customValue =  customCountry ? countryCode : null;

  const setCountry = (countryName: string, countryCode: string) => {
    // Skip updates that don't change the value.
    if (countryCode === inputs?.countryCode) {
      return;
    }
    updateCalculator({ userId, inputs: { country: countryName, countryCode }});
  }

  return (
    <Stack>
      <CalculatorButton selected={countryCode === "USA"} onClick={() => setCountry("United States", "USA")}>
        🇺🇸 United States
      </CalculatorButton>
      <CalculatorButton selected={countryCode === "GBR"} onClick={() => setCountry("United Kingdom", "GBR")}>
        🇬🇧 United Kingdom
      </CalculatorButton>
      <CalculatorButton selected={countryCode === "CAN"} onClick={() => setCountry("Canada", "CAN")}>
        🇨🇦 Canada
      </CalculatorButton>
      <Select
        searchable
        clearable
        value={customValue}
        placeholder="🌎 Choose another country"
        className={cx(classes.countryDropdown, isCustomOption ? classes.selectedOption : "")}
        size='lg'
        color={theme.colors.gray[6]}
        data={dropdownData}
        itemComponent={AutoCompleteItem}
        // styles={{}}
        onChange={(code) => {
          if (!code) {
            return;
          }
          if (!COUNTRIES[code]) {
            console.error("Invalid country code:", code);
            return;
          }
          setCountry(COUNTRIES[code].countryName, code);
        }}
      />
      <Text className={classes.tipText}>
       🤔<i> <strong> Tip:</strong> If you leave a question blank, we'll use the average value for your country.</i>
      </Text>
    </Stack>
  );
}
