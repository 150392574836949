import { Container, Paper, Grid, Group, Image, Stack, Text, createStyles, useMantineTheme, Divider, Space, MediaQuery } from "@mantine/core";
import { IconArrowDown, IconCheck } from "@tabler/icons-react";


const useStyles = createStyles((theme) => ({
  title: {
    fontWeight: 700,
    color: theme.colors.gray[9],
    fontSize: theme.fontSizes.xl,
  },
  description: {
    color: theme.colors.gray[9],
    fontSize: theme.fontSizes.lg,
  },
  chartPaper: {
    // minWidth: 400
  },
  legendText: {
    fontSize: '0.7rem',
    fontWeight: 500,
  },
  scaleOnHover: {
  },
  chartItem: {

  }
}));


function Legend() {
  const {classes} = useStyles();
  const theme = useMantineTheme();
  return (
    <Group>
      <Group align="center" spacing={"xs"}>
        <svg width={16} height={16}>
          <circle cx={8} cy={8} r={8} fill={theme.colors.red[4]} width={10} height={10}/>
        </svg>
        <Text className={classes.legendText}>Animal-based foods</Text>
      </Group>
      <Group align="center" spacing={"xs"}>
        <svg width={16} height={16}>
          <circle cx={8} cy={8} r={8} fill={theme.colors.teal[4]} width={10} height={10}/>
        </svg>
        <Text className={classes.legendText}>Plant-based foods</Text>
      </Group>
    </Group>
  )
}


function ExplainerDiagram() {
  const {classes, cx} = useStyles();

  return (
    <>
      <Stack align="center" spacing={"1rem"}>
        <Grid>
          <Grid.Col sm={7} display={"flex"}>
            <Stack align="left" justify="center" spacing={"xs"}>
              <Image src={"/assets/svg/step1.svg"} width={24}/>
              <Text align="left" className={classes.title}>Fund food transitions</Text>
              <Text align="left" className={classes.description}>
                We help food providers, like schools and hospitals,
                adopt more sustainable food options.
              </Text>
              <Text align="left" className={classes.description}>
                Our funding enables them to access
                the equipment, culinary consulting, and staff training they need to make changes. It
                also helps subsidize the cost of food and provides a financial incentive to
                take on a new initiative.
              </Text>
            </Stack>
          </Grid.Col>
          <Grid.Col sm={5} sx={{alignItems: "center"}} display={"flex"}>
            <Image src={"/assets/svg/cafeteria.svg"} className={cx(classes.scaleOnHover)}/>
          </Grid.Col>
        </Grid>
      </Stack>

      <MediaQuery smallerThan={"xs"} styles={{display: "none"}}>
        <Stack p={"2rem"} align="center">
          <IconArrowDown/>
        </Stack>
      </MediaQuery>

      <Stack align="center" pt={"sm"}>
        <Legend/>
        <Grid w={"100%"}>
          <Grid.Col sm={6} display={"flex"} sx={{alignItems: "center", justifyContent: "center"}}>
            <Paper className={cx(classes.chartPaper, classes.scaleOnHover)} p={0}>
              <Stack align="left">
                <Group>
                  <IconCheck color="green"/>
                  <Text className={classes.title}>Lower food emissions</Text>
                </Group>
                <Group className={cx(classes.chartItem, classes.scaleOnHover)}>
                  <Image src="/assets/svg/bar1.svg" height={24} width={200}/>
                  <Text className={classes.legendText}>Baseline</Text>
                </Group>
                <Group className={cx(classes.chartItem, classes.scaleOnHover)}>
                  <Image src="/assets/svg/bar2.svg" height={24} width={120}/>
                  <Text className={classes.legendText}>Project</Text>
                </Group>
              </Stack>
            </Paper>
          </Grid.Col>
          <Grid.Col sm={6} display={"flex"} sx={{alignItems: "center", justifyContent: "center"}}>
            <Paper className={cx(classes.chartPaper, classes.scaleOnHover)} p={0}>
              <Stack align="left">
                <Group>
                  <IconCheck color="green"/>
                  <Text className={classes.title}>Less land use required</Text>
                </Group>
                <Group align="end" spacing={"xl"} display={"flex"} sx={{justifyContent: "center"}}>
                  <Stack className={cx(classes.chartItem, classes.scaleOnHover)} align="center" spacing={4}>
                    <Image src="/assets/svg/square1.svg" height={50} width={50}/>
                    <Text className={classes.legendText}>Baseline</Text>
                  </Stack>
                  <Stack className={cx(classes.chartItem, classes.scaleOnHover)} align="center" spacing={4}>
                    <Image src="/assets/svg/square2.svg" height={30} width={30}/>
                    <Text className={classes.legendText}>Project</Text>
                  </Stack>
                </Group>
              </Stack>
            </Paper>
          </Grid.Col>
        </Grid>
      </Stack>

      <MediaQuery smallerThan={"xs"} styles={{display: "none"}}>
        <Stack p={"2rem"} align="center">
          <IconArrowDown/>
        </Stack>
      </MediaQuery>

      <Stack pt={"sm"}>
        {/* --- */}
        <Stack align="left" justify="center" style={{maxWidth: 600}}>
          <Image src={"/assets/svg/step2.svg"} width={24}/>
          <Text align="left" className={classes.title}>Restore natural ecosystems</Text>
          <Text align="left" className={classes.description}>
            Plant-based foods require a fraction of the land to grow. This land to be restored to
            its natural state, removing carbon from the atmosphere. We fund high-quality restoration projects to ensure
            that this carbon removal occurs.
          </Text>
        </Stack>
        {/* --- */}
        <Grid mt={"-2rem"}>
          <Grid.Col span={6}>
            <Image src="/assets/svg/land_use_animals.svg" radius={"sm"} style={{maxWidth: 400}} className={cx(classes.scaleOnHover)}/>
            <Text className={classes.legendText} mt={"sm"} align="center">Business As Usual</Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Image src="/assets/svg/land_use_plants.svg" radius={"sm"} style={{maxWidth: 400}} className={cx(classes.scaleOnHover)}/>
            <Text className={classes.legendText} mt={"sm"} align="center">Food Transition + Restoration</Text>
          </Grid.Col>
        </Grid>

        <Stack p={"2rem"} align="center">
          <IconArrowDown/>
        </Stack>
        {/* --- */}
        <Grid>
          <Grid.Col sm={4} display={"flex"} sx={{alignItems: "center"}}>
            <Image src={"/assets/svg/trees.svg"} className={cx(classes.scaleOnHover)} sx={{maxWidth: 200}}/>
          </Grid.Col>
          <Grid.Col sm={8} display={"flex"}>
            <Stack align="left" justify="center" style={{maxWidth: 600}}>
            <Image src={"/assets/svg/step3.svg"} width={24}/>
              <Text align="left" className={classes.title}>Protect natural carbon sinks</Text>
              <Text align="left" className={classes.description}>
                Your carbon credit funds ongoing efforts to keep vital ecosystems and carbon sinks protected.
              </Text>
              <Text align="left" className={classes.description}>
                Demand for animal products continues to rise every year, so conservation is more important than ever.
              </Text>
            </Stack>
          </Grid.Col>
        </Grid>
      </Stack>
    </>
  );
}


export default ExplainerDiagram;
