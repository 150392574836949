import { Container, createStyles, Stack, useMantineTheme } from "@mantine/core";

import { useMediaQuery } from '@mantine/hooks';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PortfolioSection from "../../components/public/Approach/PortfolioSection";
import ExplainerDiagram from "../../components/public/Home/ExplainerDiagram";
import ImpactSection, { PotentialSection } from "../../components/public/Home/ImpactSection";
import HeaderTitle from "../../components/shared/HeaderTitle";
import ProblemTabs from "../../components/public/Approach/ProblemTabs";
import ClimateOverview from "../../components/public/Approach/ClimateOverview";
import DifferentTabs from "../../components/public/Approach/DifferentTabs";

const useStyles = createStyles((theme) => ({
  subtitle: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 600,
    color: theme.colors.gray[9]
  },
  squareImage: {
    img: {
      aspectRatio: "1",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid white"
    },
    maxWidth: 300,
  },
  paddedSection: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
    paddingLeft: 0,
    paddingRight: 0,
    [theme.fn.smallerThan("sm")]: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    }
  },
  darkSection: {
    background: theme.colors.teal[9],
  },
  lightSection: {
    backgroundColor: 'white',
  },
  graySection: {
    backgroundColor: theme.colors.gray[0],
  },
  stepDescription: {
    textAlign: 'center',
  },

  citation: {
    fontSize: 10,
    p: {
      margin: 0,
      padding: "0.25rem"
    }
  },

  white: {
    color: 'white',
  },

  imageContainer: {
    position: "absolute",
    display: "block",
    backgroundSize: "cover",
  },

  wavyOverlay: {
    backgroundImage: "url(/assets/banner/wavy3.svg)",
    width: "100%",
    left: 0,
    height: "100%",
    zIndex: 0,
    filter: "drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.1))"
  },
}));


function ApproachRoute() {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();

  const onTakeAction = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    navigate("/take-action");
  }

  const navigateToTop = (url: string) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    navigate(url);
  }

  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  // Always scroll to the top of this page.
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <>
      <Container className={cx(classes.lightSection, classes.paddedSection)} fluid>
        <ClimateOverview/>
      </Container>

      <Container fluid className={cx(classes.lightSection, classes.paddedSection)} sx={{backgroundColor: theme.colors.gray[0]}}>
        <Container size="xl">
          <Stack align="center">
            <HeaderTitle
              header={"The Problem"}
              title={"We can't accomplish our climate goals without addressing the food system."}
            />
          </Stack>
          <ProblemTabs/>
        </Container>
      </Container>

      <Container className={cx(classes.darkSection, classes.paddedSection)} fluid>
        <ImpactSection/>
      </Container>

      <Container className={cx(classes.graySection, classes.paddedSection)} fluid>
        <PotentialSection/>
      </Container>

      <Container className={cx(classes.lightSection, classes.paddedSection)} fluid>
        <Container size="lg">
          <Stack align="center" spacing={"md"}>
            <HeaderTitle
              header="Our Approach"
              title={"We've developed the first carbon offset for the sustainable food transition."}
            />
            <PortfolioSection includeButton={false}/>
          </Stack>
        </Container>
      </Container>

      <Container fluid className={cx(classes.lightSection, classes.paddedSection)}>
        <Container size="lg">
          <HeaderTitle
            header="How We Fund Climate Solutions"
            title="We use carbon credits to shift the food system and reverse its damage."
          />
          <ExplainerDiagram/>
        </Container>
      </Container>

      <Container fluid className={cx(classes.graySection, classes.paddedSection)}>
        <Container size="lg">
          <HeaderTitle
            header={"A new type of carbon credit"}
            title={"What makes our carbon credits different?"}
            align="center"
          />
          <DifferentTabs/>
        </Container>
      </Container>
    </>
  );
}

export default ApproachRoute;
