import { Helmet } from 'react-helmet';

import { Navigate, RouterProvider, createBrowserRouter, useNavigate, useSearchParams } from 'react-router-dom';

import { parseStoredDisplayName } from './utils/helpers';
import { setCurrentUser } from './redux/userSlice';
import { selectCurrentUser, selectIsInitialized, selectIsLoggedIn, useAppDispatch, useAppSelector } from './redux/hooks';
import { useEffect } from 'react';
import { UserCredential, getAuth, signInAnonymously, signInWithCustomToken } from 'firebase/auth';

import AboutUsRoute from './routes/public/About';
import ApproachRoute from './routes/public/Approach';
import BlogRoute from './routes/public/Blog';
import CalculatorShell from './routes/calculator/CalculatorRoute';
import CheckoutRoute from './routes/checkout/CheckoutRoute';
import CheckoutShell from './routes/checkout/CheckoutShell';
import CheckoutSuccess from './routes/checkout/CheckoutSuccess';
import ChoosePlanRoute from './routes/checkout/ChoosePlan';
import DashboardShell from './routes/dashboard/DashboardShell';
import firebaseApp from './api/firebase';
import HomeRoute from './routes/public/Home';
import LoginPage from './routes/dashboard/Login';
import NotFound from './routes/util/Error';
import ProjectsRoute from './routes/public/Projects';
import PublicShell from './routes/public/PublicShell';
import ReferencesRoute from './routes/public/References';
import TakeActionRoute from './routes/public/TakeAction';
import ForCompaniesRoute from './routes/public/ForCompanies';
import DebugView from './routes/util/Debug';
import ContinueRoute from './routes/checkout/Continue';

const FIVE_MINUTES_IN_MS = 5 * 60 * 1000;


const Page = (props: { title?: string, children: any, canonical?: string }) => {
  return (
    <>
      <Helmet>
        {props.title ? <title>{props.title}</title> : ""}
        {props.canonical ? <link rel="canonical" href={props.canonical}/> : ""}
      </Helmet>
      {props.children}
    </>
  );
}


// Navigating to this page will log the user out, then redirect to the home page.
const Logout = () => {
  useEffect(() => {
    const auth = getAuth(firebaseApp);
    auth.signOut();
  }, []);

  return (
    <Navigate to={"/home"} replace/>
  )
}


// Checks for a `tempToken` in the URL search params. If found, we decode the
// JWT and look for a `redirectUrl` to send the user to.
const RedirectWithToken = () => {
  const [params, _] = useSearchParams();
  const tempToken = params.get("tempToken");
  const navigate = useNavigate();

  if (!tempToken) {
    return <Navigate to={"/"}/>
  }

  const auth = getAuth();
  signInWithCustomToken(auth, tempToken)
  .then((userCredential) => {
    userCredential.user.getIdTokenResult()
      .then((value) => {
        // This is a custom claim that's included when the token was created.
        const redirectUrl = value.claims.redirect_url as string | null | undefined;
        console.debug("@ Redirecting to:", redirectUrl);
        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      })
      .catch((error) => {
        console.error(error);
      })
  })
  .catch((error) => {
    const errorMessage = error.message;
    console.error(errorMessage);
    navigate("/");
  });

  return (<></>);
}


// Wrapper that checks if the user is logged in. If not, they're booted to the login page.
const RequireLogin = (props: { children: React.ReactNode }) => {
  const isInitialized = useAppSelector(selectIsInitialized);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  // If the user hasn't been loaded yet, do nothing.
  if (!isInitialized) {
    return <></>;
  }

  // If the user is anonymous, send to the login page.
  if (!isLoggedIn) {
    return (<Navigate to={"/login"}/>);
  }

  // Otherwise display the page as requested.
  return (
    <>
      {props.children}
    </>
  );
}


// Wrapper that requires that the user is at least anonymous. Any page that is
// wrapped in this is guaranteed to have a `userId`.
const RequireUser = (props: {children: React.ReactNode}) => {
  const isInitialized = useAppSelector(selectIsInitialized);

  // If the user hasn't been loaded yet, do nothing.
  if (!isInitialized) {
    return (<></>);
  }

  return (
    <>
      {props.children}
    </>
  )
}


const router = createBrowserRouter([
  // These are public pages that anyone can view.
  {
    path: "/",
    element:
    <Page title="Climate Refarm | Fund climate solutions in the food system">
      <PublicShell/>
    </Page>,
    errorElement: <NotFound/>,
    children: [
      {
        index: true,
        element: <Navigate to="/home" replace/>
      },
      {
        path: "home",
        element: <Page title="Climate Refarm | Fund climate solutions in the food system">
          <HomeRoute/>
        </Page>
      },
      {
        path: "our-approach",
        element: <Page title="Climate Refarm | Our approach">
          <ApproachRoute/>
        </Page>
      },
      {
        path: "projects",
        element: <Page title="Climate Refarm | Projects">
          <ProjectsRoute/>
        </Page>
      },
      {
        path: "blog",
        element: <Page title="Climate Refarm | Blog">
          <BlogRoute/>
        </Page>
      },
      {
        path: "take-action",
        element: <Page title="Climate Refarm | Take action">
          <TakeActionRoute/>
        </Page>
      },
      {
        path: "about-us",
        element: <Page title="Climate Refarm | About us">
          <AboutUsRoute/>
        </Page>
      },
      {
        path: "for-companies",
        element: <Page title="Climate Refarm | For companies">
          <ForCompaniesRoute/>
        </Page>
      },
      {
        path: "references",
        element: <Page title="Climate Refarm | References">
          <ReferencesRoute/>
        </Page>
      },
      {
        path: "choose-plan",
        element: <Page title="Climate Refarm | Choose a plan">
          <RequireUser>
            <ChoosePlanRoute/>
          </RequireUser>
        </Page>
      },
      {
        path: "login",
        element: <LoginPage action={'login'}/>
      },
      {
        path: "logout",
        element: <Logout/>
      },
      {
        path: "continue",
        element: <ContinueRoute/>
      },
      {
        path: "reset-password",
        element: <LoginPage action={'reset'}/>
      },
      {
        path: "create-account",
        element: <LoginPage action={'signup'}/>
      }
    ]
  },
  {
    path: "/calculator",
    children: [
      // The parent URL will effectively redirect to the index step below.
      {
        index: true,
        element: <Navigate to="/calculator/role" replace/>
      },
      // The `stepId` is passed to the rendered component with the `useParams` hook.
      {
        path: ":stepId",
        element: <Page title="Climate Refarm | Calculate your carbon footprint">
          <RequireUser>
            <CalculatorShell/>
          </RequireUser>
        </Page>,
      },
    ]
  },
  {
    path: "/checkout",
    element:
    <Page title="Climate Refarm | Fund climate solutions in the food system">
      <RequireUser>
        <CheckoutShell/>
      </RequireUser>
    </Page>,
    errorElement: <NotFound/>,
    children: [
      {
        index: true,
        element: <Navigate to="/checkout/payment" replace/>
      },
      {
        path: "payment",
        element: <Page title="Climate Refarm | Checkout">
          <CheckoutRoute/>
        </Page>,
      },
      {
        path: "payment/:status",
        element: <Page title="Climate Refarm | Checkout">
          <CheckoutRoute/>
        </Page>,
      },
      {
        path: "success",
        element: <Page title="Climate Refarm | Success!">
          <CheckoutSuccess/>
        </Page>,
      },
    ]
  },
  // These views require authentication.
  {
    path: "/dashboard",
    index: true,
    element: <Navigate to="/dashboard/main" replace/>
  },
  {
    path: "/dashboard/:tab",
    element: <RequireLogin>
      <DashboardShell/>
    </RequireLogin>,
    errorElement: <NotFound/>
  },
  {
    path: "/redirect",
    element: <RedirectWithToken/>
  },
  {
    path: "/debug",
    element: <DebugView/>
  },
]);


export default function AppRouter() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);
  // const refreshToken = useEnsureTokenUnexpired();

  const mountFirebaseAndAuth = () => {
    if (!window.firebaseApp) {
      // https://firebase.google.com/docs/web/setup
      window.firebaseApp = firebaseApp;
    }

    const auth = getAuth(firebaseApp);

    // This is a listener that receives all changes in the authentication state.
    // Note that account creation does NOT trigger a state change for some reason.
    auth.onAuthStateChanged((user) => {
      console.debug("@ Detected an auth state change");
      console.debug(user);

      // If there is no anonymous or logged in user, then create a new anonymous session.
      if (!user) {
        console.debug("@ No user found, creating an anonymous one");
        signInAnonymously(auth)
          .then((value: UserCredential) => {
            console.debug("@ Anonymous sign-in completed successfully");
          })
          .catch((error) => {
            console.error(error);
          });
      // Otherwise, update the Redux store with information about the current user.
      } else {
        console.debug("@ Existing user found, setting its accessToken");

        const { firstName, lastName } =  parseStoredDisplayName(user.displayName || "");

        // Get the user's UID and token, which will be used to authenticate API requests.
        user.getIdTokenResult(true).then((value) => {
          console.debug("@ Got access token, dispatching Redux action.")
          dispatch(setCurrentUser({
            userId: user.uid,
            accessToken: value.token,
            tokenExpires: new Date(value.expirationTime).valueOf(),
            isAnonymous: user.isAnonymous,
            email: user.email,
            firstName,
            lastName,
          }));
        }).catch((err) => {
          console.debug("@ Error while getting/setting the user token");
          console.error(err);
        });
      }
    });
  }

  // If the user comes back to this tab after a while, check if we need to refresh the token.
  window.addEventListener("focus", () => {
    const exp = user.tokenExpires;
    if (exp && (Date.now() >= (exp - FIVE_MINUTES_IN_MS))) {
      console.debug("@ Forcing a reload to get a new token");
      window.location.reload()
    }
  });

  // Do once when the component is first rendered.
  useEffect(() => {
    mountFirebaseAndAuth();
  }, []);

  return (
    <RouterProvider router={router}/>
  )
}
