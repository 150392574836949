import { createStyles, Table, Anchor, ScrollArea, rem } from '@mantine/core';


const useStyles = createStyles((theme) => ({
  progressBar: {
    '&:not(:first-of-type)': {
      borderLeft: `${rem(3)} solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white
      }`,
    },
  },
}));


interface OrderTableProps {
  data: {
    id: string
    date: string
    amount: number
    reason: string | null
    credits: number
    invoiceUrl: string | undefined | null
    status: string | null,
    currency: string
    fallbackInvoiceUrl: string,
  }[];
}


export default function OrderTable({ data }: OrderTableProps) {
  const { classes, theme } = useStyles();

  const rows = data.map((row) => {
    // https://stripe.com/docs/api/invoices/object#invoice_object-billing_reason
    const reason = {
      "subscription_update": "Subscription updated",
      "subscription_create": "Subscription created",
      "subscription_cycle": "Subscription renewed",
      "manual": "One-time purchase",
    }[row.reason || ""] || null;
    return (
      <tr key={row.id}>
        <td>
          <Anchor fz="sm" href={row.invoiceUrl || row.fallbackInvoiceUrl} target='_blank'>
            {row.date}
          </Anchor>
        </td>
        <td>
          {row.credits.toLocaleString("default", { maximumFractionDigits: 2 })} tons CO₂
        </td>
        <td>
          {row.amount.toLocaleString('default', { style: "currency", currency: row.currency })}
        </td>
        <td>
          {
            reason
          }
        </td>
      </tr>
    );
  });

  return (
    <ScrollArea>
      <Table sx={{ minWidth: 800 }} verticalSpacing="xs">
        <thead>
          <tr>
            <th>Invoice</th>
            <th>Credits Purchased</th>
            <th>Amount Charged</th>
            <th>Reason</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
}
