import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { merge } from 'lodash'

// Represents the state of the current app user.
export interface IUser {
  userId: string | null
  accessToken: string | null
  tokenExpires: number | null
  isAnonymous: boolean
  firstName: string | null
  lastName: string | null
  email: string | null
}


export interface UserState {
  currentUser: IUser
}


const initialState: UserState = {
  currentUser: {
    userId: null,
    accessToken: null,
    tokenExpires: null,
    isAnonymous: true,
    firstName: null,
    lastName: null,
    email: null
  }
}


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<Partial<IUser>>) => {
      const merged = {}
      merge(merged, state.currentUser, action.payload);
      state.currentUser = merged as IUser;
    },
  },
})

export const { setCurrentUser } = userSlice.actions
export default userSlice.reducer
