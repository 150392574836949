import { Button, Container, Group, Modal, PasswordInput, Space, Stack, Text, TextInput, Title, useMantineTheme } from "@mantine/core";
import { EmailAuthProvider, User, getAuth, reauthenticateWithCredential, updateEmail, updatePassword, updateProfile } from "firebase/auth";
import { IconArrowRight } from "@tabler/icons-react";
import { makeStoredDisplayName } from "../../utils/helpers";
import { setCurrentUser } from "../../redux/userSlice";
import { selectCurrentUser, useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useDisclosure } from "@mantine/hooks";
import { UseFormReturnType, useForm } from "@mantine/form";
import { useState } from "react";


function Editable(props: { field: string, value: string, EditModal?: any }) {
  const theme = useMantineTheme();

  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
    {/* @ts-ignore */}
    {props.EditModal ? <props.EditModal opened={opened} onClose={close}/> : undefined}
    <Stack spacing={0}>
      <Text fw={"bold"}>{props.field}</Text>
      <Group>
        <Text color={theme.colors.gray[7]}>{props.value}</Text>
        <Button size="xs" variant="subtle" radius={"md"} onClick={open}>Edit</Button>
      </Group>
    </Stack>
    </>
  )
}


function BaseEditModal(props: {
  opened: boolean,
  onClose: () => void,
  form: UseFormReturnType<any, any>,
  children: any,
  handleSubmit: (values: any) => Promise<void>
}) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values: typeof props.form.values) => {

    const auth = getAuth();
    if (!auth.currentUser) {
      return;
    }
    setLoading(true);
    props.handleSubmit(values)
      .then(() => {
        setLoading(false);
        props.onClose();
      })
  }

  // Called when bad form data is submitted.
  const handleError = (errors: any) => {
  };

  return (
    <Modal opened={props.opened} onClose={props.onClose} yOffset={0} xOffset={0} withCloseButton={false} centered p={"xl"}>
      <form onSubmit={props.form.onSubmit(handleSubmit, handleError)}>
        <Stack>
          {props.children}
          <Stack spacing={"xs"}>
            <Button size="sm" color="teal" type="submit" loading={loading}>Update</Button>
            <Button size="sm" color="gray" variant="subtle" onClick={props.onClose}>Cancel</Button>
          </Stack>
        </Stack>
      </form>
    </Modal>
  )
}


function EditNameModal(props: { opened: boolean, onClose: () => void }) {
  const dispatch = useAppDispatch();

  const validateName = (value: string) => {
    const sanitized = value.replace(/[^a-zA-Z ]/g, "");
    return sanitized.length >= 1 && sanitized.length <= 16;
  }

  const form = useForm({
    initialValues: { firstName: '', lastName: '' },

    validate: {
      firstName: (value) => (validateName(value) ? null : 'Between 1 and 16 valid characters'),
      lastName: (value) => (validateName(value) ? null : 'Between 1 and 16 valid characters')
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    return new Promise<void>((resolve, reject) => {
      const stored = makeStoredDisplayName(values.firstName, values.lastName);

      const auth = getAuth();
      if (!auth.currentUser) {
        return;
      }
      updateProfile(auth.currentUser, { displayName: stored })
        .then(() => {
          dispatch(setCurrentUser({
            firstName: values.firstName,
            lastName: values.lastName,
          }))
          resolve();
      });
    });
  }

  return (
    <BaseEditModal
      opened={props.opened}
      onClose={props.onClose}
      form={form}
      handleSubmit={handleSubmit}
    >
      <Text c={"black"}>Please avoid unusual capitalization, special characters, and numbers.</Text>
      <TextInput
        label="First name"
        size="md"
        withAsterisk
        {...form.getInputProps('firstName')}
      />
      <TextInput
        label="Last name"
        size="md"
        withAsterisk
        {...form.getInputProps('lastName')}
      />
    </BaseEditModal>
  );
}


function EditEmailModal(props: { opened: boolean, onClose: () => void }) {
  const dispatch = useAppDispatch();

  const regexValidEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  const form = useForm({
    initialValues: { email: '', password: '' },

    validate: {
      email: (value) => (regexValidEmail.test(value) ? null : 'Please enter a valid email'),
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    return new Promise<void>((resolve, reject) => {
      const auth = getAuth();
      if (!auth.currentUser || !auth.currentUser.email) {
        return;
      }
      const credential = EmailAuthProvider.credential(auth.currentUser.email, values.password);

      reauthenticateWithCredential(auth.currentUser, credential).then(() => {
        updateEmail(auth.currentUser as User, values.email)
          .then(() => {
            dispatch(setCurrentUser({ email: values.email }));
            resolve();
          });
      }, console.error);
    });
  }

  return (
    <BaseEditModal
      opened={props.opened}
      onClose={props.onClose}
      form={form}
      handleSubmit={handleSubmit}
    >
      <Text c={"black"}>Please enter a new email and your current password. We'll send you a confirmation email to verify the new email.</Text>
      <TextInput
        type="email"
        label="Email"
        size="md"
        withAsterisk
        autoComplete="email"
        {...form.getInputProps('email')}
      />
      <PasswordInput
        label="Current password"
        size="md"
        withAsterisk
        {...form.getInputProps('password')}
        autoComplete="current-password"
      />
    </BaseEditModal>
  );
}


function EditPasswordModal(props: { opened: boolean, onClose: () => void }) {
  const dispatch = useAppDispatch();

  const form = useForm({
    initialValues: { currentPassword: '', newPassword: '' },

    validate: {
      newPassword: (value) => ((value.length >= 8) ? null : 'Must have at least 8 characters'),
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    return new Promise<void>((resolve, reject) => {
      const auth = getAuth();
      if (!auth.currentUser || !auth.currentUser.email) {
        return;
      }
      const credential = EmailAuthProvider.credential(auth.currentUser.email, values.currentPassword);

      reauthenticateWithCredential(auth.currentUser, credential).then(() => {
        updatePassword(auth.currentUser as User, values.newPassword)
          .then(() => {
            resolve();
          });
      }, console.error);
    });
  }

  return (
    <BaseEditModal
      opened={props.opened}
      onClose={props.onClose}
      form={form}
      handleSubmit={handleSubmit}
    >
      <Text c={"black"}>Enter your current password and the new password you want.</Text>
      <PasswordInput
        label="Current password"
        size="md"
        withAsterisk
        {...form.getInputProps('currentPassword')}
        autoComplete="current-password"
      />
      <PasswordInput
        label="New password"
        size="md"
        withAsterisk
        {...form.getInputProps('newPassword')}
        autoComplete="new-password"
      />
    </BaseEditModal>
  );
}


export default function SettingsRoute() {
  const theme = useMantineTheme();

  const user = useAppSelector(selectCurrentUser);
  const name = (user.firstName || "New") + " " + (user.lastName || "User");
  const email = user.email || "user@test.com";

  const openStripeCustomerPortal = () => {
    window.open(`https://billing.stripe.com/p/login/9AQdSCdl7dMecsodQQ?prefilled_email=${email}`);
  }

  return (
    <Container size={"lg"}>
      <Space h={"xl"}/>
      <Stack align="flex-start" sx={{maxWidth: 500}}>
        <Title order={1}>
          Account settings
        </Title>
        <Title order={2}>Profile</Title>
        <Editable field="Name" value={name} EditModal={EditNameModal}/>
        <Editable field="Email" value={email} EditModal={EditEmailModal}/>
        <Editable field="Password" value="••••••••" EditModal={EditPasswordModal}/>
        <Title order={2}>Billing</Title>
        <Text>You can pause, cancel, or update your subscription at any time through Stripe. You can also update your payment method there.</Text>
        <Button onClick={openStripeCustomerPortal} variant="filled" size="xs" radius="md" rightIcon={<IconArrowRight size={"0.8rem"}/>}>Manage billing</Button>
      </Stack>
    </Container>
  );
}
