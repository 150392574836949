import Stripe from 'stripe';

import { Alert, Anchor, Button, Container, Group, Skeleton, Space, Stack, Text, Title, createStyles } from "@mantine/core";
import { IconAdjustments, IconAlertCircleFilled, IconArrowRight, IconClockFilled, IconHelpCircleFilled, IconInfoCircleFilled, IconPlayerPauseFilled } from "@tabler/icons-react";
import { StripeCustomMetadata, useListStripeInvoicesQuery, useListStripeSubscriptionsQuery } from "../../api/payment";
import { selectCurrentUser, useAppSelector } from "../../redux/hooks";
import OrderTable from '../../components/dashboard/OrderTable';
import { sum } from 'lodash';
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  subDetailLabel: {
    fontWeight: 600,
    color: theme.colors.gray[9],
  },
  subDetailValue: {
    fontWeight: 500,
    color: theme.colors.gray[7],
  }
}));


function NoSubscriptionAlert() {
  const navigate = useNavigate();
  const onStartSubscription = () => {
    navigate("/calculator");
  }
  return (
    <Alert icon={<IconInfoCircleFilled/>} maw={400} color="blue">
      <Stack align="flex-start" >
        You don't have an active subscription right now. You can get started by clicking below. If
        you just started a subscription, it might take a few minutes to appear.
        <Button onClick={onStartSubscription} color='blue'>Start a subscription</Button>
      </Stack>
    </Alert>
  )
}


function ActiveSubscriptions() {
  const {classes} = useStyles();

  const user = useAppSelector(selectCurrentUser);
  const navigate = useNavigate();

  const {data, isLoading, isFetching, isError} = useListStripeSubscriptionsQuery({ userId: user.userId as string });

  const active = data?.data.filter((sub) => sub.status === "active");

  const subscription = (active && active.length >= 1) ? active.at(0) : null;
  const loading = isLoading || isFetching;

  if (loading) {
    return (
      <Stack>
        <Skeleton w={300} h={"1rem"}/>
        <Skeleton w={200} h={"1rem"}/>
        <Skeleton w={200} h={"1rem"}/>
        <Skeleton w={200} h={"1rem"}/>
      </Stack>
    );
  }

  if (!subscription) {
    return <NoSubscriptionAlert/>;
  }

  const renewDate = new Date(subscription.current_period_end * 1000);
  const meta = (subscription.metadata as unknown) as StripeCustomMetadata;
  const unitsPerBillingPeriod = meta.order.units;
  const interval = meta.order.billing_interval;
  const readableInterval = {"year": "yearly", "month": "monthly"}[interval as "year" | "month"];
  const startedAt = new Date(subscription.start_date * 1000);
  const currency = subscription.currency || "USD";

  const pricePerBillingPeriod = subscription.items.data.length > 0 ?
    (subscription.items.data[0].price.unit_amount || parseFloat(subscription.items.data[0].price.unit_amount_decimal || "NaN")) / 100: NaN;

  const onManageSubscription = () => {
    const email = user.email;
    window.open(`https://billing.stripe.com/p/login/9AQdSCdl7dMecsodQQ?prefilled_email=${email}`);
  }

  let status = <Alert icon={<IconClockFilled/>} fw={600} color='teal'>
    Your {readableInterval} subscription will renew on {renewDate.toLocaleDateString("default")}
  </Alert>;
  if (subscription.cancel_at_period_end) {
    status = <Alert icon={<IconAlertCircleFilled/>} fw={600} color='orange'>
      Your {readableInterval} subscription was cancelled and will end on {renewDate.toLocaleDateString("default")}
    </Alert>;
  } else if (subscription.pause_collection) {
    status = <Alert icon={<IconPlayerPauseFilled/>} fw={600} color='yellow'>
      Your {readableInterval} subscription is currently paused, but you can resume it at any time.
    </Alert>;
  }

  return (
    <Stack align="flex-start">
      {
        (data?.data && data.data.length > 1) ?
          <Alert color="orange" icon={<IconAlertCircleFilled/>}>
            We found more than one active subscription attached to your account. This is probably
            a mistake, and you can change your subscription settings in
            your <Anchor href="/dashboard/settings">Billing Settings</Anchor>.
          </Alert> : undefined
      }
      <Stack>
        {status}
        <Button onClick={onManageSubscription} size='sm' rightIcon={<IconArrowRight size={"0.8rem"}/>}>
          Manage billing settings
        </Button>
        <Group>
          <Text className={classes.subDetailLabel}>Quantity</Text>
          <Text className={classes.subDetailValue}>
            {unitsPerBillingPeriod.toLocaleString("default", { maximumFractionDigits: 2 })} tons CO₂ per {interval === "month" ? "month" : "year"}
          </Text>
        </Group>
        <Group>
          <Text className={classes.subDetailLabel}>Price</Text>
          <Text className={classes.subDetailValue}>
            {pricePerBillingPeriod.toLocaleString("default", { style: "currency", currency: currency,  maximumFractionDigits: 2 })} per {interval === "month" ? "month" : "year"}
          </Text>
        </Group>
        <Group>
          <Text className={classes.subDetailLabel}>Started on</Text>
          <Text className={classes.subDetailValue}>{startedAt.toLocaleDateString("default")}</Text>
        </Group>
        <Alert icon={<IconAdjustments/>} fw={600} color='teal' title="Has your carbon footprint changed?" maw={400}>
          <Stack>
          <Text>
            Redo the carbon footprint calculator at any time to
            update your subscription.
          </Text>
          <Button onClick={() => navigate("/calculator")} size='sm' rightIcon={<IconArrowRight size={"0.8rem"}/>}>
            Update my footprint
          </Button>
          </Stack>
        </Alert>
      </Stack>
    </Stack>
  )
}


function OneTimePurchases() {
  return (
    <Alert color='gray'>No one-time purchases found.</Alert>
  )
}


function OrderHistory() {
  const {classes} = useStyles();
  const user = useAppSelector(selectCurrentUser);

  const {data, isLoading, isFetching, isError} = useListStripeInvoicesQuery({ userId: user.userId as string });

  const orders = (data?.data || []).map((value: Stripe.Invoice) => {
    // @ts-ignore
    const totalOrderUnits = sum(value.lines.data.map(v => (v.metadata.prorated_units || 0)));

    return {
      id: value.id,
      created: value.created,
      reason: value.billing_reason,
      date: new Date(value.created * 1000).toLocaleDateString(),
      amount: value.amount_paid / 100,
      credits: totalOrderUnits,
      status: value.status,
      invoiceUrl: value.hosted_invoice_url,
      fallbackInvoiceUrl: `https://billing.stripe.com/p/login/9AQdSCdl7dMecsodQQ?prefilled_email=${user.email}`,
      currency: value.currency || "USD",
    };
  })
  // Sort from most to least recent.
  .sort((a, b) => b.created - a.created);

  return (
    <OrderTable data={orders}/>
  )
}


function OrdersRoute() {
  const user = useAppSelector(selectCurrentUser);

  return (
    <Container size="lg">
      <Space h={"xl"}/>
      <Stack spacing={"xl"}>
        <Title order={1}>Your orders</Title>
        <Title order={2}>Subscription</Title>
        <ActiveSubscriptions/>
        <Space h="xs"/>
        <Title order={2}>One-Time Purchases</Title>
        <OneTimePurchases/>
        <Space h="xs"/>
        <Title order={2}>Order History</Title>
        <OrderHistory/>
        <Alert icon={<IconHelpCircleFilled/>}>
          Have questions about any of your orders? We're <Anchor href="mailto:info@climaterefarm.com" target='_blank'>here to help</Anchor>.
        </Alert>
      </Stack>
    </Container>
  );
}

export default OrdersRoute;
