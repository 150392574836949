import { Container, useMantineTheme } from "@mantine/core";

import { Outlet } from "react-router-dom";

import Header from "../../components/shared/TopHeader/TopHeader";


function CheckoutShell() {
  const theme = useMantineTheme();

  return (
    <div>
      <Header
        defaultBackgroundColor="white"
        defaultLogoColor={theme.colors.gray[9]}
        defaultTextColor={theme.colors.gray[9]}
        defaultPosition="relative"
      />
      <Container fluid sx={{paddingLeft: 0, paddingRight: 0, minHeight: "calc(100vh - 60px)"}} bg={theme.colors.gray[0]}>
        <Outlet/>
      </Container>
    </div>
  );
}

export default CheckoutShell;
