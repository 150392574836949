type DistanceUnit = "km" | "miles";

// MPG means imperial gallon
type FuelEconomyUnit = "l/100km" | "mpg" | "MPG";


export type NaturalGasUnit = "therms" | "m³" | "MMBtu" | "Ccf";


export const getUserDistanceUnit = (countryCode: string | null | undefined): DistanceUnit => {
  if (!countryCode) {
    return "km";
  } else {
    return ["USA", "GBR"].includes(countryCode) ? "miles" : "km";
  }
}

export const getUserFuelEconomyUnit = (countryCode: string | null | undefined): FuelEconomyUnit => {
  if (!countryCode) {
    return "l/100km";
  } else if (countryCode === "GBR") {
    return "MPG"
  } else if (countryCode === "USA") {
    return "mpg";
  } else {
    return "l/100km";
  }
}

export const getUserNaturalGasUnit = (countryCode: string | null | undefined): NaturalGasUnit => {
  if (!countryCode) {
    return "therms";
  } else {
    return "therms";
  }
}

export const convertDistanceUnits = (value: number, from: DistanceUnit, to: DistanceUnit) => {
  if (from === to) {
    return value;
  // mi to km
  } else if (to === "km") {
    return value * 1.60934;
  // km to mi
  } else {
    return value / 1.60934;
  }
}

export const convertFuelEconomyUnits = (value: number, from: FuelEconomyUnit, to: FuelEconomyUnit) => {
  if (value === undefined || value === null) {
    return value;
  } else if (from === to) {
    return value;
  // L/100km => mpg (US)
  } else if (from === "l/100km" && to === "mpg") {
    return 235.215/value;
  // mpg => L/100km
  } else if (from === "mpg" && to === "l/100km") {
    return 235.215/value;
  // L/100km => MPG (Imperial)
  } else if (from === "l/100km" && to === "MPG") {
    return 282.481/value;
  // MPG (Imperial) => k
  } else if (from === "MPG" && to === "l/100km") {
    return 282.481/value;
  } else if (from === "mpg" && to === "MPG") {
    return value * 1.201;
  } else if (from === "MPG" && to === "mpg") {
    return value * 0.832674;
  } else {
    return value;
  }
}

// https://www.eia.gov/tools/faqs/faq.php?id=45&t=8
export const convertNaturalGasUnits = (value: number, from: NaturalGasUnit, to: NaturalGasUnit) => {
  if (from === to) {
    return value;
  }
  // Convert everything to therms.
  let valueTherms = value;
  if (from === "m³") {
    // Convert m3 to ft3 to therms.
    valueTherms = value * 0.365860292;
  } else if (from === "Ccf") {
    valueTherms = value * 1.036;
  } else if (from === "MMBtu") {
    valueTherms = value * 10.0023877;
  }

  // Convert therms to the desired unit.
  if (to === "therms") {
    return valueTherms;
  } else if (to === "m³") {
    return valueTherms * 2.73328377489;
  } else if (to === "Ccf") {
    return valueTherms * 0.96525096525;
  } else if (to === "MMBtu") {
    return valueTherms * 0.09997612869;
  } else {
    return valueTherms;
  }
}
