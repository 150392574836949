import { createStyles, Text, TextInput, Button, Stack, Paper } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCheck } from '@tabler/icons-react';
import { useSubmitMailingListFormMutation } from '../../../api/hubspot';


const useStyles = createStyles((theme) => ({
  paper: {
    // backgroundColor: theme.colors.green[0],
    minWidth: 350
  },
  title: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 500,
    fontFamily: `Recoleta, ${theme.fontFamily}`,
    marginBottom: `calc(${theme.spacing.xs} / 2)`,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  },
  text: {

  }
}));


export default function SubscribeForm() {
  const { classes } = useStyles();

  const regexValidEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  const [submitForm, result] = useSubmitMailingListFormMutation();

  const form = useForm({
    initialValues: {
      email: '',
      firstName: '',
      lastName: ''
    },
    validate: {
      // firstName: (value) => (value.length > 1) ? null : 'Please enter a name',
      // lastName: (value) => (value.length > 1) ? null : 'Please enter a name',
      email: (value) => (regexValidEmail.test(value) ? null : 'Please enter a valid email'),
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    if (result.isSuccess) {
      console.warn("Already submitted, skipping")
      return;
    }
    submitForm({...values, pageName: document.title, pageUri: window.location.href});
  };

  const handleError = (errors: typeof form.errors) => {
    console.error(errors);
  };
  return (
    <Paper
      p={"xl"}
      shadow='md'
      className={classes.paper}
    >
      <form onSubmit={form.onSubmit(handleSubmit, handleError)}>
      <Stack spacing="sm" align='flex-start'>
        <Text className={classes.title} lh={1}>Subscribe to our newsletter</Text>
        <Text className={classes.text}>Stay up to date on the intersection of climate and food.</Text>
        <TextInput placeholder="Your email" {...form.getInputProps('email')} w={"100%"}/>
        <Button
          loading={result.isLoading}
          leftIcon={result.isSuccess ? <IconCheck/> : null}
          type="submit">
            {result.isSuccess ? "Received" : "Subscribe"}
        </Button>
      </Stack>
      </form>
    </Paper>
  )
}
