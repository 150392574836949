import { Text, createStyles } from "@mantine/core";
import { CalculatorDefaults, useGetCalculatorDefaultsQuery, useGetCalculatorInputsQuery, useUpdateCalculatorInputsMutation } from "../../../api/calculator";
import CustomNumberInput from "../../../components/calculator/CustomNumberInput";
import MultipleChoice, { IChoiceData, isCustomNumericOptionSelected } from "../../../components/calculator/MultipleChoice";
import { selectCurrentUserId, useAppSelector } from "../../../redux/hooks";
import { almostEqual } from "../../../utils/helpers";


const useStyles = createStyles((theme) => ({
  tipText: {
    color: theme.colors.teal[6],
  }
}));


export default function SelectPurchases(
  props: { category: "furniture" | "clothes" | "supplies" | "services", goToNext?: () => void }
) {
  const {classes} = useStyles();
  const userId = useAppSelector(selectCurrentUserId) as string;

  const {data} = useGetCalculatorInputsQuery(userId);
  const [updateCalculator] = useUpdateCalculatorInputsMutation();

  const defaultsApi = useGetCalculatorDefaultsQuery(userId);
  const loading = defaultsApi.isLoading || defaultsApi.isFetching || !userId;

  const inputs = data?.inputs;

  const key = {
    "services": "monthlySpendingServicesUsd",
    "clothes": "monthlySpendingGoodsClothesUsd",
    "furniture": "monthlySpendingGoodsFurnitureAndAppliancesUsd",
    "supplies": "monthlySpendingGoodsOtherUsd",
  }[props.category];

  const monthly = ["services", "supplies"].includes(props.category);
  const interval = monthly ? "month" : "year";

  const userValuePerMonth = inputs ? inputs[key as keyof typeof inputs] as number : null;
  const userValuePerInterval = monthly ? userValuePerMonth : (userValuePerMonth ? (userValuePerMonth * 12) : null);

  const setUserValue = (value: number | string | null) => {
    updateCalculator({userId, inputs: {[key]: value}});
  };

  const sharedOnClick = (data: IChoiceData) => {
    // Stored values are always per month.
    const storedValue = monthly ? data.value : (data.value as number / 12);
    setUserValue(storedValue);

    if (props.goToNext) {
      props.goToNext();
    }
  }

  // TODO(milo): Set these multiples.
  const meanValue = (defaultsApi.data?.defaults[key as keyof CalculatorDefaults] || 115) * (monthly ? 1 : 12);
  const lowValue = meanValue * 0.5;
  const highValue = meanValue * 1.5;

  const choices: IChoiceData[] = [
    {
      value: lowValue,
      label: "A little",
      detail: `${lowValue.toLocaleString("default", { maximumFractionDigits: 0, style: "currency", currency: "usd" })} / ${interval}`,
      selected: almostEqual(userValuePerInterval, lowValue, 0.1),
      onClick: sharedOnClick
    },
    {
      value: meanValue,
      label: "Average",
      detail: `${meanValue.toLocaleString("default", { maximumFractionDigits: 0, style: "currency", currency: "usd"})} / ${interval}`,
      selected: almostEqual(userValuePerInterval, meanValue, 0.1),
      onClick: sharedOnClick
    },
    {
      value: highValue,
      label: "A lot",
      detail: `${highValue.toLocaleString("default", { maximumFractionDigits: 0, style: "currency", currency: "usd"})} / ${interval}`,
      selected: almostEqual(userValuePerInterval, highValue, 0.1),
      onClick: sharedOnClick
    }
  ];

  const customIsSelected = isCustomNumericOptionSelected(choices, userValuePerInterval as number | null, 0.1);

  const customInput =
    <CustomNumberInput
      onChange={setUserValue}
      units={`$/${interval}`}
      initialValue={customIsSelected ? userValuePerInterval as number : null}
      selected={customIsSelected}
      min={0}
      max={50000}
    />;

  const tip = {
    "furniture": "If you purchase furniture less than once per year, give your best guess for what you'd spend in an average year.",
    "clothes": "If you purchase clothes very infrequently, you can try to calculate your monthly average based on your approximate clothing expenditure in the last year.",
    "supplies": "Don't include services or consumable goods like foods and beverages.",
    "services": "Don't include your electricity or gas bill - those are in a separate question!"
  }[props.category];

  return (
    <>
    <MultipleChoice
      loading={loading}
      choices={choices}
      customInputElement={customInput}
    />
    {
      tip.length > 0 ?
      <Text className={classes.tipText}>
      🤔<i> <strong> Tip:</strong> {tip}</i>
      </Text> : undefined
    }
    </>
  );
}
