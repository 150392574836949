import { Container, Stack, Alert, Text } from "@mantine/core"
import HeaderTitle from "../../shared/HeaderTitle"


export default function CallToAction2() {
  return (
    <Container size="md">
      <Stack spacing={32} align='center' pt="15vh" pb="15vh">
      <HeaderTitle
        header={"You can make a difference"}
        title={"Help us build a more healthy, sustainable, and humane food system."}
      />
        <Alert
          color="teal"
          title={"Did you know?"}
          sx={{justifyContent: "center", display: "flex"}}
          styles={{title: {justifyContent: "center"}}}
        >
          <Text color="black" fw={500} align="center">
            Most people can offset <b>100%</b> their carbon footprint for the cost of a few coffees each week.
          </Text>
        </Alert>
      </Stack>
    </Container>
  )
}
