import { Button, ButtonProps, createPolymorphicComponent } from "@mantine/core";
import classes from "./ArrowButton.module.css"


interface ArrowButtonProps extends ButtonProps {
  onClick?: () => void
}


export default function ArrowButton(props: ArrowButtonProps) {
  return (
    <Button
      className={classes.arrowButton}
      rightIcon={<span className={classes.arrow}/>}
      {...props}
      classNames={{
        root: classes.root,
        inner: classes.inner,
        rightIcon: classes.rightIcon,
      }}
    >
      {props.children}
    </Button>
  );
}