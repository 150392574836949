import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


export interface CompareData {
  country: string
  value: number
}


export default function CountryComparisonChart(
  props: { data: CompareData[], youTonsPerYear: number, loading: boolean }
) {
  const theme = useMantineTheme();

  const otherData = props.data.map((value: CompareData) => [value.country, value.value]);

  const data = [
    ...otherData.slice(0, otherData.length / 2),
    ["You", props.youTonsPerYear],
    ...otherData.slice(otherData.length / 2)
  ];

  const colors = data.map((value) => value[0] === "You" ? theme.colors.orange[4] : theme.colors.orange[2]);
  const categories = data.map((value) => value[0] as string);

  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const options: Highcharts.Options = {
    title: {
      text: undefined
    },

    yAxis: {
      enabled: !mobile,
      title: {
        text: mobile ? null : "tons CO₂ per year",
        style: {fontWeight: "normal"},
      },
      labels: {
        enabled: false,
      }
    },

    xAxis: {
      categories: categories,
    },

    tooltip: {
      format: "{series.xAxis.categories.(point.x)}: {point.y:.1f}"
    },

    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          color: 'black',
          style: {fontWeight: '500', textOutline: 'none', fontSize: "18px"},
          inside: true,
          format: "{point.y:.1f}"
        },
        colorByPoint: true,
        pointPadding: 0,
        groupPadding: 0.1,
      }
    },

    colors: colors,

    series: [{
      type: 'column',
      name: 'Footprint',
      color: theme.colors.teal[3],
      data: data,
    }],
    legend: {
      enabled: false,
    },
    chart: {
      type: 'column',
      height: 220,
      style: {
        fontFamily: "Inter, Proxima Nova, proxima-nova"
      },
      backgroundColor: "transparent",
    },
    credits: {
      enabled: false,
    }
  }

  return (
    <HighchartsReact
      loading={props.loading}
      highcharts={Highcharts}
      options={options}
    />
  );
}
